import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { PaperCardTitle } from 'ui/components/common/paperCard'
import { Separator } from 'ui/components/common/separator'

export const Example: FC = (props: {
	title: string | React.ReactNode
	children: React.ReactNode
}) => {
	return (
		<div>
			<Separator />
			<PaperCardTitle>{props.title}</PaperCardTitle>
			{props.children}
		</div>
	)
}
