import { l } from 'ui/lib/lodashImports'
import { createSos } from './secretaryOfState'

export interface IExampleForm {
	name: string
	dogsName?: string
	catsName?: string
}

export interface IStateComponentExamples {
	openSection: string
	selectedTab: string
	selectedFilter: string

	customDate: string
	showPopup: boolean
	showModal: boolean
	showPngDocumentViewer: boolean
	showPdfDocumentViewer: boolean

	autocompleteValue: string
	exampleForm: IExampleForm

	validationButtonIsValid: boolean
}

const initialState: IStateComponentExamples = {
	openSection: '',
	selectedTab: 'tab-1',
	selectedFilter: 'filter-1',
	customDate: '2019-04-10',
	showPopup: false,
	showModal: false,
	showPngDocumentViewer: false,
	showPdfDocumentViewer: false,
	autocompleteValue: '',
	exampleForm: {
		name: '',
		dogsName: '',
		catsName: '',
	},
	validationButtonIsValid: false,
}

const { stateManager, useSubscribe } = createSos(
	'component-examples',
	'1.0.0',
	initialState,
	{
		useLocalStorage: true,
		localStorageFields: ['openSection', 'selectedTab'],
	},
)
export { useSubscribe }

export function toggleSection(sectionName: string): void {
	stateManager.produce((ds) => {
		if (ds.openSection === sectionName) {
			ds.openSection = ''
		} else {
			ds.openSection = sectionName
		}
	})
}

export function setAutocompleteValue(newVal: string): void {
	stateManager.produce((ds) => {
		ds.autocompleteValue = newVal
	})
}

export function openPopup(): void {
	stateManager.produce((ds) => {
		ds.showPopup = true
	})
}

export function closePopup(): void {
	stateManager.produce((ds) => {
		ds.showPopup = false
	})
}

export function openModal(): void {
	stateManager.produce((ds) => {
		ds.showModal = true
	})
}

export function closeModal(): void {
	stateManager.produce((ds) => {
		ds.showModal = false
	})
}

export function setShowPngDocumentViewer(isShown: boolean): void {
	stateManager.produce((ds) => {
		ds.showPngDocumentViewer = isShown
	})
}

export function setShowPdfDocumentViewer(isShown: boolean): void {
	stateManager.produce((ds) => {
		ds.showPdfDocumentViewer = isShown
	})
}

export function selectTab(tab: string): void {
	stateManager.produce((ds) => {
		ds.selectedTab = tab
	})
}

export function selectFilter(tab: string): void {
	stateManager.produce((ds) => {
		ds.selectedFilter = tab
	})
}

export function setState(newState: Partial<IStateComponentExamples>): void {
	stateManager.setState(newState)
}

export function updateForm(changes: Partial<IExampleForm>): void {
	stateManager.produce((ds) => {
		l.assign(ds.exampleForm, changes)
	})
}
