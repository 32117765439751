import React, { useState } from 'react'
import { FC } from 'app/FunctionalComponent'
import { apiTypes } from 'ui/api'
import { Button } from 'ui/components/common/button/Button'
import { t } from 'ui/components/i18n/i18n'
import { RateRow } from 'ui/components/rates-table'
import { selectBrokerRate } from 'ui/pages/shipment-profile/broker/functions/selectBrokerRate'
import classes from 'ui/pages/shipment-profile/shared/components/rates/ShipmentProfileRateInteraction.module.scss'
import { RateBookingDetails } from 'ui/pages/shipment-profile/shared/functions/rateBookingDetailsTypes'

export const SelectBrokerRateButton: FC = (props: {
	rateRow: RateRow
	shipment: apiTypes.BrokerShipmentResponse
	markupLogic: apiTypes.MarkupLogic
	tPrefix: string
	shipmentIsRating: boolean
	brokerOffer: apiTypes.BrokerOfferResponse
	setRateBookingDetails: React.Dispatch<
		React.SetStateAction<RateBookingDetails>
	>
	rateBookingDetails: RateBookingDetails
}) => {
	const {
		rateRow,
		shipment,
		markupLogic,
		tPrefix,
		shipmentIsRating,
		brokerOffer,
		setRateBookingDetails,
		rateBookingDetails,
	} = props

	const [selectingBrokerRate, updateSelectingBrokerRate] = useState(false)

	return (
		<Button
			color='green'
			isSmall={true}
			onClick={async () => {
				updateSelectingBrokerRate(true)
				await selectBrokerRate(rateRow.rateId, shipment, markupLogic)

				const updatedRateBookingDetails = {
					...rateBookingDetails,
					providerName: rateRow.providerName,
					api: rateRow.rateBookingOptions?.api,
					email: rateRow.rateBookingOptions?.email,
					phone: rateRow.rateBookingOptions?.phone,
				}
				setRateBookingDetails(updatedRateBookingDetails)

				updateSelectingBrokerRate(false)
			}}
			bootstrapStyles={true}
			isDisabled={
				shipmentIsRating ||
				rateRow.rateId === brokerOffer?.brokerRateId ||
				brokerOffer?.clientStatus === 'retracted' ||
				shipment.brokerShipmentStatus === 'booked'
			}
			isSpinning={selectingBrokerRate}
			testId='select-rate-button'
			className={classes.ratesTableBadge}
		>
			<div className={classes.buttonText}>{t('selectBrokerRate', tPrefix)}</div>
		</Button>
	)
}
