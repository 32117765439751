import { FC } from 'app/FunctionalComponent'
import React, { useEffect } from 'react'
import {
	Breadcrumb,
	IBreadcrumbEntry,
} from 'ui/components/common/breadcrumb/Breadcrumb'
import { fireAndForget } from 'ui/lib/async'
import { Layout } from 'ui/pages/layout/Layout'
import { sosProductCatalog } from 'ui/state/'

export const ProductCatalogPage: FC = (props: { bookId: string }) => {
	// const state = sos2.useSubscription(sosProductCatalog.sos)

	useEffect(() => {
		sosProductCatalog.navigateToProductCatalog({
			bookId: props.bookId,
		})
	}, [props.bookId])

	useEffect(() => {
		fireAndForget(async () => {
			await sosProductCatalog.fetchProductPages()
			await sosProductCatalog.fetchBook()
		}, 'fetching productCodeBookCatalogs and entries')
	}, [props.bookId])

	const bread: IBreadcrumbEntry[] = [
		{
			t: 'shared.productCatalog.manageProductCatalogs',
			url: '/manage-product-catalogs',
		},
		{
			// t: 'page.productCatalog.',
			// label: state.requestProductCatalog.data
			// 	? state.requestProductCatalog.data.name
			// 	: '',
			label: 'todo',
			url: '',
		},
	]

	return (
		<Layout>
			<Breadcrumb entries={bread} />
			{/* <ProductCatalogEntryDataTable
				requestGetProducts={state.requestProductCatalogEntries}
				dataTableState={state.dataTableProductCatalog}
				requestUpdateProductPage={state.requestUpdateProductPage}
				form={state.productCatalogEntryForm}
			/> */}
			{/* <Debug
				hide={true}
				label='All pages from this book'
				data={state.requestProductCatalogEntries}
			/> */}
		</Layout>
	)
}
