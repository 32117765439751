import _ from 'lodash'
import { DateTime } from 'luxon'
import { mergeDeepLeft } from 'ramda'
import { apiRfp, apiShipments, apiTypes } from 'ui/api'
import { IRequestState } from 'ui/api/requestState'
import { sosRouter2 } from 'ui/components/common/router'
import {
	documentExists,
	getDocument,
	reload,
} from 'ui/components/common/router/windowUtils'
import { tString } from 'ui/components/i18n/i18n'
import { csvValidatorUtils } from 'ui/components/shared/csvValidator'
import { IStateCsvValidator } from 'ui/components/shared/csvValidator/csvValidatorUtils'
import { toPostalCode } from 'ui/lib/addresses/toPostalCode'
import { retry } from 'ui/lib/async'
import { asyncForEachParallel } from 'ui/lib/async/asyncForEachParallel'
import { asyncForEachSerial } from 'ui/lib/async/asyncForEachSerial'
import { batch } from 'ui/lib/batch'
import { IRfp } from 'ui/lib/books'
import { _idx } from 'ui/lib/_idx'
import { l } from 'ui/lib/lodashImports'
import { toCents, toFloat, toInteger, toWeight } from 'ui/lib/numbers/toNumber'
import { sosRfp, rfpUploadValidations } from 'ui/pages/rfp'
import { isInDev } from 'ui/theme/theme'
import { sosRfpAnalysisPage, sosUser } from '.'
import { IPayloadRow } from './IPayloadRow'
import { getRfpUrlState, rfpPageInfo } from 'ui/pages/rfp/sosRfp'
import { createLazySos2 } from 'ui/lib/state/sos2/sos2'

export interface IStateRfp {
	completedSteps: number
	totalSteps: number
	voidBatchCount: number
	createBatchCount: number
	shipmentIds: string[]
	isUploadingShipments: boolean
	isRatingShipments: boolean
	previewTableState: IStateCsvValidator
	bookedRateIds: string[]
	rfpId: string
}

export const getSos = createLazySos2<IStateRfp>('sosRfpDataUpload', 2, () => ({
	rfpProjects: { default: [], localStorage: false },
	selectedRfpProject: {
		default: { id: '', name: '', rfpIds: [], currentRfpId: '' },
		localStorage: false,
	},
	showNewRfpProjectModal: { default: false, localStorage: false },
	showRfpProjectOptions: { default: false, localStorage: false },
	isDeletingRfpProject: { default: false, localStorage: false },

	totalSteps: { default: 1, localStorage: false },
	voidBatchCount: { default: 1, localStorage: false },
	createBatchCount: { default: 0, localStorage: false },
	completedSteps: { default: 0, localStorage: false },
	shipmentIds: { default: [], localStorage: false },
	isUploadingShipments: { default: false, localStorage: false },
	isRatingShipments: { default: false, localStorage: false },
	previewTableState: {
		default: {
			columns: [],
			rawRecords: null,
			records: [],
			itemLines: [],
			errors: [],
			warnings: [],
			isLoading: false,
		},
		localStorage: false,
	},
	bookedRateIds: { default: [], localStorage: false },
	rfpId: { default: null, localStorage: false },
}))

export const rfpData: IRfp[] = []
export const rawData = { data: [] }
const parallelCreateRequestCount = 6
export const uploadBatchSize = 15 // 50 is currently the max batchSize allowed by the api for the bulk create endpoint
export const parallelRateRequestCount = isInDev() ? 1 : 6
export const ratingBatchSize = 1

export const requiredColumnsRfp: string[] = [
	'location_id',
	'origin_street1',
	'origin_city',
	'origin_state',
	'origin_zip',
	'destination_street1',
	'destination_city',
	'destination_state',
	'destination_zip',
	'total_weight',
	'handling_units',
	'class',
]

export const optionalColumnsRfp: string[] = [
	'shipment_number',
	'equipment_type',
	'origin_street2',
	'origin_street3',
	'origin_country',
	'origin_address_type',
	'destination_street2',
	'destination_street3',
	'destination_country',
	'destination_address_type',
	'weight_per_package',
	'sort_and_segregate_at_pickup',
	'sort_and_segregate_at_delivery',
	'liftgate_at_pickup',
	'liftgate_at_delivery',
	'inside_delivery',
	'inside_pickup',
	'notify_consignee',
	'appointment',
	'handling_unit_length',
	'handling_unit_height',
	'handling_unit_width',
	'handling_unit_type',
	'pickup',
	'delivery',
	'transit',
	'zone',
	'adjustments_total',
	'gross_rate',
	'base_rate',
	'fuel',
	'stop_off_charges',
	'deficit_weight_cost',
	'other',
	'discount',
	'earned_discount',
	'guaranteed',
	'payload_sync_id',
	'provider_name',
	'service_level',
	'mode',
]

export function downloadTemplateUpload(): void {
	if (documentExists) {
		const hiddenElement = getDocument().createElement('a')
		hiddenElement.href =
			'data:text/csv;charset=utf-8,' +
			encodeURI(
				requiredColumnsRfp.join(',') + ',' + optionalColumnsRfp.join(','),
			)
		hiddenElement.target = '_blank'
		hiddenElement.download = 'template.csv'
		hiddenElement.click()
	}
}

export async function processCsv(
	fileContent: string,
	requiredColumnText: string[],
	optionalColumnText: string[],
	// transformationInformation: ITransformationInformation[],
): Promise<void> {
	getSos().change((ds) => {
		ds.previewTableState.isLoading = true
		ds.previewTableState.rawRecords = []
		ds.previewTableState.records = []
		ds.previewTableState.columns = []
		ds.previewTableState.errors = null
		ds.previewTableState.itemLines = []
	})

	try {
		const csvParseResults = await csvValidatorUtils.parseCsv(
			fileContent,
			requiredColumnText,
			optionalColumnText,
			rfpUploadValidations,
		)

		getSos().change((ds) => {
			ds.previewTableState.rawRecords = csvParseResults.rawRecords
			ds.previewTableState.records = csvParseResults.records
			ds.previewTableState.columns = csvParseResults.columns
			ds.previewTableState.errors = csvParseResults.errors
			ds.previewTableState.warnings = csvParseResults.warnings
			ds.previewTableState.isLoading = false

			ds.previewTableState.errors.push(
				...l
					.filter(
						csvParseResults.records,
						(record) => record.result === 'error',
					)
					.map(
						(record, recordIdx) =>
							(record?.rawRecord?.shipment_number || recordIdx + 1) +
							': ' +
							record.errors[0],
					),
			)
		})
	} catch {
		getSos().change((ds) => {
			ds.previewTableState.columns = ['']
			ds.previewTableState.rawRecords = null
			ds.previewTableState.records = [
				{ rawRecord: '', result: 'error', warnings: [], errors: [] },
			]
			ds.previewTableState.itemLines = []
			ds.previewTableState.errors = [
				tString('fileCouldNotBeParsed', 'page.compareRfpContracts'),
			]
			ds.previewTableState.warnings = []
			ds.previewTableState.isLoading = false
		})
	}
}

export async function sendShipmentsToApi(
	fileData: apiTypes.RfpShipmentListRequest,
): Promise<IRequestState<apiTypes.RfpShipmentBulkCreateResponse>> {
	const result = await apiRfp.createRfpShipments(fileData, () => {})
	return result
}

export function createShipment(
	shipmentPayloads: IPayloadRow[],
	rfpId: string,
): apiTypes.RfpShipmentRequest {
	const singlePayload = shipmentPayloads[shipmentPayloads.length - 1]
	const shipment: apiTypes.RfpShipmentRequest = {
		rfpProjectId: getRfpUrlState().rfpProjectId,
		equipmentType: singlePayload.equipment_type || 'V',
		identifier: singlePayload.shipment_number,
		bookedRateIndex: 0,
		rfpId,
		rates: [
			{
				providerName: singlePayload.provider_name,
				serviceLevel: singlePayload.service_level,
				quoteType: 'rateAnalysis',
				scac: singlePayload.scac,
				pickup: DateTime.fromISO(singlePayload.pickup).toISO(),
				delivery: DateTime.fromISO(singlePayload.delivery).toISO(),
				transit: toInteger(singlePayload.transit),
				// zone: singlePayload.zone,
				// guaranteed: DateTime.fromISO(singlePayload.guaranteed).toISO(),
				costBreakdown: {
					adjustmentsTotal: toCents(singlePayload.adjustments_total),
					grossRate: toCents(singlePayload.gross_rate),
					baseRate: toCents(singlePayload.base_rate),
					fuel: toCents(singlePayload.fuel),
					stopOffCharges: toCents(singlePayload.stop_off_charges),
					deficitWeightCost: toCents(singlePayload.deficit_weight_cost),
					other: toCents(singlePayload.other),
					discount: toCents(singlePayload.discount),
					earnedDiscount: toCents(singlePayload.earned_discount),
					accessorials: {},
				},
				method: singlePayload.mode,
			},
		],
		locationId: singlePayload.location_id,
		shipmentStatus: 'booked',
		payloads: [],
		flags: ['rfp'],
		paymentStatus: 'paidPriorToTMSProvider',
		accessorials: {
			sortAndSegregateAtPickup: singlePayload.sort_and_segregate_at_pickup,
			sortAndSegregateAtDelivery: singlePayload.sort_and_segregate_at_delivery,
			notifyConsignee: singlePayload.notify_consignee,
			liftgateAtPickup: singlePayload.liftgate_at_pickup,
			liftgateAtDelivery: singlePayload.liftgate_at_delivery,
			insidePickup: singlePayload.inside_pickup,
			insideDelivery: singlePayload.inside_delivery,
			appointment: singlePayload.appointment,
		},
		accessorialsInfo: {
			notifyConsigneeName: singlePayload.notify_consignee_name,
			notifyConsigneePhone: singlePayload.notify_consignee_phone,
			appointmentContactName: singlePayload.appointment_contact_name,
			appointmentContactPhone: singlePayload.appointment_contact_phone,
		},
	}
	shipmentPayloads.forEach((shipmentPayload: IPayloadRow) => {
		const payload: apiTypes.ShipmentRequestPayload = {
			originStop: {
				address: {
					company: shipmentPayload.origin_company,
					name: shipmentPayload.origin_name,
					phone: shipmentPayload.origin_phone,
					email: shipmentPayload.origin_email,
					street1: shipmentPayload.origin_street1,
					street2: shipmentPayload.origin_street2,
					street3: shipmentPayload.origin_street3,
					city: shipmentPayload.origin_city,
					state: shipmentPayload.origin_state,
					zip: toPostalCode(
						shipmentPayload.origin_zip,
						shipmentPayload.origin_country,
					),
					country: shipmentPayload.origin_country,
					addressType: shipmentPayload.origin_address_type,
				},
			},
			destinationStop: {
				address: {
					company: shipmentPayload.destination_company,
					name: shipmentPayload.destination_name,
					phone: shipmentPayload.destination_phone,
					email: shipmentPayload.destination_email,
					street1: shipmentPayload.destination_street1,
					street2: shipmentPayload.destination_street2,
					street3: shipmentPayload.destination_street3,
					city: shipmentPayload.destination_city,
					state: shipmentPayload.destination_state,
					zip: toPostalCode(
						shipmentPayload.destination_zip,
						shipmentPayload.destination_country,
					),
					country: shipmentPayload.destination_country,
					addressType: shipmentPayload.destination_address_type,
				},
			},
			containers: [
				{
					class: shipmentPayload.class,
					type: shipmentPayload.handling_unit_type,
					length: toFloat(shipmentPayload.handling_unit_length) || 48,
					width: toFloat(shipmentPayload.handling_unit_width) || 48,
					height: toFloat(shipmentPayload.handling_unit_height) || 40,
					count: toInteger(shipmentPayload.handling_units) || 1,
					freightLevel: 'freight',
					containerWeightEach: 0,
					containers: [
						{
							class: shipmentPayload.class,
							type: shipmentPayload.handling_unit_type,
							packedWeight: toInteger(
								toWeight(shipmentPayload.weight_per_package) ||
									toWeight(shipmentPayload.total_weight) /
										toFloat(shipmentPayload.handling_units),
							),
							length: toFloat(shipmentPayload.handling_unit_length) || 48,
							width: toFloat(shipmentPayload.handling_unit_width) || 48,
							height: toFloat(shipmentPayload.handling_unit_height) || 40,
							count: toInteger(shipmentPayload.handling_units) || 1,
							freightLevel: 'parcel',
							goods: [
								{
									nmfcCode: shipmentPayload.nmfc_number,
									nmfcSubCode: shipmentPayload.nmfc_sub_number,
									purchaseOrder: shipmentPayload.po_number,
									salesOrder: shipmentPayload.so_number,
									weightEach: toInteger(
										toWeight(shipmentPayload.weight_per_package) ||
											toWeight(shipmentPayload.total_weight) /
												toFloat(shipmentPayload.handling_units),
									),
									description: shipmentPayload.commodity_description,
								},
							],
						},
					],
				},
			],
		}
		shipment.payloads.push(payload)
	})
	return shipment
}

export async function batchShipments(
	rawRecords,
): Promise<apiTypes.RfpShipmentListRequest[]> {
	const batches: apiTypes.RfpShipmentListRequest[] = []
	const rows: IPayloadRow[] = rawRecords
	let currentBatchOfPayloads: IPayloadRow[] = []
	let shipmentPayloads: IPayloadRow[] = []
	let currentBatch: apiTypes.RfpShipmentListRequest = { entities: [] }
	const { rfpId } = getSos().getState()
	await asyncForEachSerial(rows, async (row) => {
		if (shipmentPayloads.length > 0) {
			if (
				row.payload_sync_id &&
				row.payload_sync_id ===
					shipmentPayloads[shipmentPayloads.length - 1].payload_sync_id
			) {
				shipmentPayloads.push(row)
			} else {
				const shipmentInBatch: apiTypes.RfpShipmentRequest = createShipment(
					shipmentPayloads,
					rfpId,
				)
				if (
					shipmentPayloads.length + currentBatchOfPayloads.length <=
					uploadBatchSize
				) {
					// if there's still room in the batch, add the shipment to the batch and the payloads to the batchpayloads
					currentBatch.entities.push(shipmentInBatch)
					currentBatchOfPayloads.push(...shipmentPayloads)
				} else {
					// if there's not room, send the batch, and reset the current batch and batchpayloads
					batches.push(currentBatch)
					currentBatchOfPayloads = shipmentPayloads
					currentBatch = { entities: [shipmentInBatch] }
				}
				shipmentPayloads = [row]
			}
		} else {
			shipmentPayloads.push(row)
		}
	})
	const shipmentObject: apiTypes.RfpShipmentRequest = createShipment(
		shipmentPayloads,
		rfpId,
	)
	if (
		shipmentPayloads.length + currentBatchOfPayloads.length <=
		uploadBatchSize
	) {
		currentBatch.entities.push(shipmentObject)
		shipmentPayloads = []
	}

	// send remaining currentBatch
	batches.push(currentBatch)

	// send last shipment if it wasn't in the last batch
	if (shipmentPayloads.length > 0) {
		batches.push({ entities: [shipmentObject] })
	}
	getSos().change((ds) => {
		ds.createBatchCount = batches.length
	})
	return batches
}

export const increaseCompletedSteps = (): void => {
	const completedSteps = getSos().getState().completedSteps
	const totalSteps = getSos().getState().totalSteps
	if (completedSteps < totalSteps) {
		getSos().change((ds) => {
			ds.completedSteps++
		})
	} else {
		getSos().change((ds) => {
			ds.completedSteps = totalSteps
		})
	}
}

export const setTotalSteps = (batchSize): void => {
	getSos().change((ds) => {
		ds.totalSteps = batchSize
	})
}

const pollForElasticSearchTaskToBeComplete = async (): Promise<number> => {
	// query takes 1 because we don't actually need shipment data and are making use of the total hit count that comes back on the response object
	// if query takes 0, the api sends back 25 because that's the default and 0 is falsey
	const query = {
		skip: 0,
		take: 1,
		rfp: true,
		query: `rfpId:${getSos().getState().rfpId}`,
	}
	const entireResponse = true
	const response = await apiRfp.getRfpShipments(
		(rs: IRequestState<apiTypes.ShipmentListResponse>) => {},
		query,
		entireResponse,
	)
	if (response.data && (response.data.total || response.data.total === 0)) {
		return response.data.total
	}
}

export const getTotalSteps = (
	uploadingBatchCount: number,
	firstBatchLength: number,
	lastBatchLength: number,
): number => {
	const ratingRequestCount = Math.ceil(
		(firstBatchLength * (uploadingBatchCount - 1) + lastBatchLength) /
			ratingBatchSize,
	)
	return uploadingBatchCount + ratingRequestCount
}

export const getAllCreatedShipmentIds = async (): Promise<string[]> => {
	let shipmentIds: string[] = []
	const take = 100
	const parallelGetRequests = 6

	const query = {
		skip: 0,
		take,
		query: `rfpId:${getSos().getState().rfpId}`,
	}
	const entireResponse = true
	let response = await apiRfp.getRfpShipments(
		(rs: IRequestState<apiTypes.ShipmentListResponse>) => {},
		query,
		entireResponse,
	)
	shipmentIds = shipmentIds.concat(
		response.data.entities.map((shipment) => shipment.id),
	)

	const additionalCalls = Math.ceil(response.data.total / take) - 1
	const skips = []
	for (let i = 1; i <= additionalCalls; i++) {
		skips.push(i * take)
	}

	const skipBatches = batch(skips, parallelGetRequests)

	await asyncForEachSerial(skipBatches, async (skipBatch) => {
		await asyncForEachParallel(skipBatch, async (skip) => {
			response = await apiRfp.getRfpShipments(
				(rs: IRequestState<apiTypes.ShipmentListResponse>) => {},
				{ skip, take, query: `rfpId:${getSos().getState().rfpId}` },
				entireResponse,
			)
			shipmentIds = shipmentIds.concat(
				response.data?.entities.map((shipment) => shipment.id),
			)
		})
	})

	return shipmentIds
}

export const rateShipmentsAndReroute = async (): Promise<void> => {
	const { shipmentIds, bookedRateIds, rfpId } = getSos().getState()
	let unratedShipments: string[] = []

	const requestBatches = batch(
		shipmentIds,
		ratingBatchSize,
		parallelRateRequestCount,
	)
	await asyncForEachSerial(requestBatches, async (batches) => {
		await asyncForEachParallel(batches, async (shipmentIdsBatch: string[]) => {
			const response = await apiRfp.rateRfpShipments(
				shipmentIdsBatch,
				(rs: IRequestState<apiTypes.ShipmentListResponse>) => {},
			)
			if (response.data) {
				const bookedRateIds = response.data.entities?.map((shipment) => {
					const bookedRate =
						shipment.bookedRate ||
						_.find(shipment.rates, (rate) => rate.offerStatus === 'booked')
					return bookedRate?.id
				})

				sosRfpAnalysisPage.getCarriersAndContracts(
					_idx(() => response.data.entities) || [],
					bookedRateIds || [],
				)
				increaseCompletedSteps()
			} else if (response.error) {
				unratedShipments = unratedShipments.concat(shipmentIdsBatch)
			}
		})
	})

	// retry rating shipments that didn't rate the first time
	const rerequestBatches = batch(
		unratedShipments,
		ratingBatchSize,
		parallelRateRequestCount,
	)
	await asyncForEachSerial(rerequestBatches, async (batches) => {
		await asyncForEachParallel(batches, async (shipmentIdsBatch: string[]) => {
			const response = await apiRfp.rateRfpShipments(
				shipmentIdsBatch,
				(rs: IRequestState<apiTypes.ShipmentListResponse>) => {},
			)
			if (response.data) {
				const bookedRateIds = response.data.entities?.map((shipment) => {
					const bookedRate =
						shipment.bookedRate ||
						_.find(shipment.rates, (rate) => rate.offerStatus === 'booked')
					return bookedRate?.id
				})

				sosRfpAnalysisPage.getCarriersAndContracts(
					_idx(() => response.data.entities) || [],
					bookedRateIds || [],
				)
			}
			increaseCompletedSteps()
		})
	})

	getSos().change((ds) => {
		ds.isRatingShipments = false
	})

	// edit rfpProject to have most recent rfpId
	await sosRfp.fetchRfpProjects()
	const rfpProjectId = getRfpUrlState().rfpProjectId
	const { rfpProjects } = sosRfp.getSos().getState()

	const rfpProject = l.find(
		rfpProjects,
		(project) => project.id === rfpProjectId,
	)

	if (rfpProject) {
		const rfpProjectRequest: apiTypes.RfpProjectRequest = {
			name: rfpProject.name,
			rfpIds: rfpProject.rfpIds.concat(rfpId),
			status: 'active',
		}
		await apiRfp.updateRfpProject(() => {}, rfpProjectId, rfpProjectRequest)
	}

	if (sosRfpAnalysisPage.atRfpUpload()) {
		sosRfpAnalysisPage.navigateToRfpAnalysis({
			shipmentIds,
			bookedRateIds,
			rfpId,
			rfpProjectId,
		})
	}
}

export const manageUpload = async (
	rawRecords,
): Promise<{
	ids: string[]
	errors: string[]
}> => {
	getSos().change((ds) => {
		ds.completedSteps = 0
		ds.rfpId =
			DateTime.local().toFormat('yMMddHHmmssSSS') + sosUser.getLoggedInUserId()
	})

	const rfpProjectId = getRfpUrlState().rfpProjectId

	if (!rfpProjectId) {
		// create one
		const rfpProjectResponse = await apiRfp.createRfpProject(() => {}, {
			name: 'New RFP Project',
			rfpIds: [],
			status: 'active',
		})

		if (rfpProjectResponse.data) {
			// set set rfpProjectId as query param
			const existingParams = getRfpUrlState()
			const params = mergeDeepLeft(
				{ rfpProjectId: rfpProjectResponse.data.id },
				existingParams,
			)
			sosRouter2.navigateTo(rfpPageInfo, params)
		} else {
			getSos().change((ds) => {
				ds.previewTableState.errors = rfpProjectResponse.error
			})
		}
	}

	getSos().change((ds) => {
		ds.isUploadingShipments = true
	})

	const shipmentIds: string[] = []
	const errors: string[] = []
	const rateIds: string[] = []

	let batches: apiTypes.RfpShipmentListRequest[]
	if ('flags' in rawRecords[0]) {
		//AKA If the first rawRecords already looks like a shipment
		batches = rawRecords
	} else {
		batches = await batchShipments(rawRecords)
	}
	const requestBatches: apiTypes.BulkShipmentRequest[][] = batch(
		batches,
		parallelCreateRequestCount,
	)
	await asyncForEachSerial(requestBatches, async (batchedBatch: any) => {
		await asyncForEachParallel(
			batchedBatch,
			async (shipmentBatch: apiTypes.RfpShipmentListRequest) => {
				const response = await sendShipmentsToApi(shipmentBatch)
				if (response.data) {
					response.data.shipments.forEach((shipment) => {
						if (shipment?.id) {
							shipmentIds.push(shipment.id)
						}
						if (shipment?.bookedRate?.id) {
							rateIds.push(shipment.bookedRate.id)
						}
					})
					if (response.data.errors.length > 0) {
						response.data.errors.forEach((error) => {
							const wordsInError = error.split(' ')

							// errors come back as "item 0 returned error ...", "item 1 returned error ...", etc.
							const indexOfErrorShipment = Number(wordsInError[1])
							const shipmentNumber =
								shipmentBatch?.entities[indexOfErrorShipment]?.identifier ||
								'NO_SHIPMENT_NUMBER'

							// replace "item #" with just shipmentNumber
							wordsInError[0] = ''
							wordsInError[1] = shipmentNumber
							errors.push(wordsInError.join(' '))
						})
					}
				}
				increaseCompletedSteps()
			},
		)
	})
	if (errors.length === 0) {
		await retry(
			async () =>
				(await pollForElasticSearchTaskToBeComplete()) >= shipmentIds.length,
			4 * 60 * 60 * 1000,
			1000,
		)

		const createdShipmentIds = await getAllCreatedShipmentIds()
		getSos().change((ds) => {
			ds.isUploadingShipments = false
			ds.isRatingShipments = true
			ds.shipmentIds = _.compact(createdShipmentIds)
			ds.bookedRateIds = _.compact(rateIds)
		})
		await rateShipmentsAndReroute()
	} else {
		getSos().change((ds) => {
			ds.isUploadingShipments = false
			ds.previewTableState.errors = errors
		})
	}
	return { ids: shipmentIds, errors }
}

export const cancelProcess = (): void => {
	reload()
}

export const fromTms2_receiveShipmentIDs = async (
	TMS2shipmentIDs: string[],
): Promise<void> => {
	const RFPShipments: object[] = []
	let RFPBatches: object[][] = []
	await asyncForEachParallel(TMS2shipmentIDs, async (shipmentID: string) => {
		const existingShipment: any = (
			await apiShipments.fetchShipment(() => {}, shipmentID, false, true)
		).data
		if (existingShipment) {
			const shipment = _.cloneDeep(existingShipment)
			shipment.flags = ['rfp']
			const RFPShipmentObject: apiTypes.BulkShipmentRequestItem = {
				shipment,
				meta: {
					book: false,
					rates: false,
					providerName: shipment.bookedRate
						.providerName as apiTypes.BulkShipmentMetadata['providerName'],
					serviceLevel: shipment.bookedRate.serviceLevel,
				},
			}
			RFPShipments.push({ entities: [RFPShipmentObject] })
		}
	})
	RFPBatches = batch(RFPShipments, uploadBatchSize)
	if (RFPBatches.length) {
		await manageUpload(RFPBatches)
	}
}
// Expose to TMS2
const globe = global as any
globe.fromTms2_receiveShipmentIDs = fromTms2_receiveShipmentIDs
