import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { IRequestState } from 'ui/api/requestState'
import { t } from 'ui/components/i18n/i18n'
import { DataTable } from 'ui/components/table/DataTable'
import { DataTableUpdate } from 'ui/components/table/DataTableUpdate'
import { IDataTableHeader } from 'ui/components/table/IDataTableHeader'
import { IDataTableState } from 'ui/components/table/IDataTableState'
import { IProductCatalogForm } from 'ui/forms/formProductCatalog'
import { IProductCatalog } from 'ui/lib/books/IProductCatalog'
import { sosProductCatalog, sosProductCatalogEntry } from 'ui/state'
import { UpdateProductCatalogForm } from './UpdateProductCatalogForm'

export const ProductCatalogDataTable: FC = (props: {
	requestAddEditBook: IRequestState<IProductCatalog>
	requestGetBooks: IRequestState<IProductCatalog[]>
	dataTableState: IDataTableState<IProductCatalog>
	data: IProductCatalog[]
	form: IProductCatalogForm
}) => {
	const { requestGetBooks, requestAddEditBook } = props
	const { dataTableState } = props
	const headers: IDataTableHeader<IProductCatalog>[] = [
		{
			field: 'id',
		},
		{
			field: 'name',
		},
		{
			field: 'pageType',
		},
	]
	return (
		<div>
			<DataTable
				testId='data-table-books'
				headers={headers}
				tPrefix='page.manageProductCatalogs.bookTable'
				isLoading={requestGetBooks.isFetching || requestAddEditBook.isFetching}
				data={props.data}
				state={dataTableState}
				onEditRow={(row) => {
					sosProductCatalogEntry.booksTableSetUpdateMode('edit', row)
				}}
				onToggleHeader={(c) => {
					sosProductCatalogEntry.booksTableToggleHeader(c.field)
				}}
				onClickHeader={(header) => {
					sosProductCatalogEntry.booksTableSort(header.field)
				}}
				onRefresh={async () => {
					await sosProductCatalogEntry.getBooks()
				}}
				onClickRow={(row) => {
					sosProductCatalog.navigateToProductCatalog({ bookId: row.id })
				}}
				onAddRow={() => {
					sosProductCatalogEntry.booksTableSetUpdateMode('add')
				}}
				addLabel={t('page.manageProductCatalogs.bookTable.add')}
				rendererAdd={() => (
					<DataTableUpdate error={props.dataTableState.error}>
						<UpdateProductCatalogForm mode='add' form={props.form} />
					</DataTableUpdate>
				)}
				rendererEdit={(row) => (
					<DataTableUpdate
						error={props.dataTableState.error}
						onDeleteRow={async () => {
							await sosProductCatalogEntry.updateBook('delete')
						}}
					>
						<UpdateProductCatalogForm mode='edit' form={props.form} />
					</DataTableUpdate>
				)}
			/>
		</div>
	)
}
