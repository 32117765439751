import { sosToast } from 'common/components/toast'
import { ToastState } from 'common/components/toast/Toast'
import { apiClientCredit, apiLocation, apiTypes } from 'ui/api'
import { IRequestState } from 'ui/api/requestState'
import { TypeaheadOption } from 'ui/common/components/typeahead'
import { tString } from 'ui/components/i18n/i18n'
import { FileDropZoneData } from 'ui/components/shared/csvValidator/file-drop-zone'
import {
	createDefaultMarkup,
	createDefaultPaymentTerms,
	createDefaultCreditLimit,
	createDefaultAddress,
	createDefaultNotification,
	INotificationsForm,
} from 'ui/lib/clientConfigs'
import {
	getApiImageTypeFromImageString,
	getBase64StringFromImageString,
} from 'ui/lib/images/imageUtils'
import { l } from 'ui/lib/lodashImports'
import { toCents } from 'ui/lib/numbers/toNumber'
import { createSos2 } from 'ui/lib/state/sos2/sos2'
import { sosUser } from 'ui/state'
import { ICustomerDetailsAddressForm } from './broker/customer-details-address'
import { tPrefixClientManagement } from './broker/default-markup'
import { ICreditLimitForm } from './broker/default-markup/credit-limit'
import { splitEmail, validateEmail } from 'ui/lib/emailValidator'
import { RelationshipType } from 'ui/api/apiClientCredit'
import { isFinite } from 'lodash'

const defaultMarkupFlat = 10000
const defaultMarkupPercent = 10

export type TCustomerManagementTabs =
	| 'details'
	| 'brokerAssignments'
	| 'notifications'
	| 'accounting'

type TCustomerManagementSections =
	| 'address'
	| 'brandingLogo'
	| 'brokerAssignments'
	| 'notifications'
	| 'paymentTerms'
	| 'creditLimit'
	| 'markupRules'

interface TempStateCustomerManagement {
	brokerDefaultMarkupForm?: apiTypes.MarkupLogic
	paymentTermsForm?: apiTypes.PaymentTerms
	brokerNumber?: string
	useCustomerInfoForBOL?: boolean
	brandingImageUrl?: string
	brokerLocationId?: string
	brokerLocationName?: string
	creditLimitForm?: ICreditLimitForm
	brandingPictureData?: FileDropZoneData
	users?: apiTypes.ClientConfigUserRequest[]
	notificationsForm?: INotificationsForm
}

export interface StateCustomerManagement {
	brokerDefaultMarkupForm: apiTypes.MarkupLogic
	paymentTermsForm: apiTypes.PaymentTerms
	brokerNumber: string
	useCustomerInfoForBOL: boolean
	clientAddress: apiTypes.Address
	clientLocationId: string
	brandingImageUrl: string
	brokerLocationId?: string
	brokerLocationName?: string
	touchedTabs?: string[]
	creditLimitForm?: ICreditLimitForm
	addressForm?: ICustomerDetailsAddressForm
	brandingPictureData?: FileDropZoneData
	users?: apiTypes.ClientConfigUserRequest[]
	isPageModified?: boolean
	notificationsForm?: INotificationsForm
	tempStateCustomerManagement?: TempStateCustomerManagement
	clientCreditRelationshipId?: string
	clientCreditRelationshipType?: RelationshipType
	selectedClientConfigId?: string
}

const defaultStateCustomerManagementData: TempStateCustomerManagement = {
	brokerDefaultMarkupForm: createDefaultMarkup(),
	paymentTermsForm: createDefaultPaymentTerms(),
	brokerNumber: '',
	useCustomerInfoForBOL: false,
	brandingImageUrl: null,
	brokerLocationId: '',
	brokerLocationName: '',
	creditLimitForm: createDefaultCreditLimit(),
	brandingPictureData: null,
	users: [],
	notificationsForm: createDefaultNotification(),
}

export const sos = createSos2<StateCustomerManagement>(
	'customerManagement',
	1,
	{
		brokerDefaultMarkupForm: {
			default: createDefaultMarkup(),
		},
		paymentTermsForm: {
			default: createDefaultPaymentTerms(),
		},
		brokerNumber: {
			default: '',
		},
		useCustomerInfoForBOL: {
			default: false,
		},
		clientAddress: { default: null },
		clientLocationId: { default: null },
		brandingImageUrl: { default: null },
		brokerLocationId: {
			default: '',
		},
		brokerLocationName: {
			default: '',
		},
		touchedTabs: {
			default: [],
		},
		creditLimitForm: {
			default: createDefaultCreditLimit(),
		},
		addressForm: {
			default: createDefaultAddress(),
		},
		brandingPictureData: {
			default: null,
		},
		users: {
			default: [],
		},
		isPageModified: {
			default: false,
		},
		notificationsForm: {
			default: createDefaultNotification(),
		},
		tempStateCustomerManagement: {
			default: defaultStateCustomerManagementData,
		},
		clientCreditRelationshipId: {
			default: '',
		},
		clientCreditRelationshipType: {
			default: 'clientConfig',
		},
		selectedClientConfigId: {
			default: '',
		},
	},
)

export const saveCustomerDetails = async (): Promise<void> => {
	if (sos.getState().touchedTabs.length > 0) {
		if (sos.getState().touchedTabs.includes('notifications')) {
			if (sos.getState().notificationsForm.sendShippingNotification) {
				const validatedEmails = validateEmail(
					sos.getState().notificationsForm.shippingNotificationEmails,
					tPrefixClientManagement,
				)

				if (!validatedEmails) return
			}
		}

		const requestArray: any[] = [sosUser.updateClientConfig(sos.getState())]

		if (sos.getState().touchedTabs.includes('creditLimit')) {
			if (
				sos.getState().creditLimitForm.warningThreshold < 0 ||
				sos.getState().creditLimitForm.warningThreshold > 100
			) {
				sosToast.sendToast({
					header: tString(
						'warningThresholdErrorMessage',
						tPrefixClientManagement,
					),
					type: 'danger',
				})
				return
			} else if (
				sos.getState().creditLimitForm.emailWhenBeyondWarningLimit === true &&
				sos.getState().creditLimitForm.emailRecipients.length === 0
			) {
				sosToast.sendToast({
					header: tString(
						'emailRecepientErrorMessage',
						tPrefixClientManagement,
					),
					type: 'danger',
				})
				return
			} else {
				let validatedEmails: string[] = []

				if (sos.getState().creditLimitForm.emailWhenBeyondWarningLimit) {
					validatedEmails = validateEmail(
						sos.getState().creditLimitForm.emailRecipients,
						tPrefixClientManagement,
					)
					if (!validatedEmails) return

					// if (sos.getState().creditLimitForm.warningThreshold < 1) {
					// 	sosToast.sendToast({
					// 		header: tString(
					// 			'warningThresholdErrorMessageWarningLimitEnabled',
					// 			tPrefixClientManagement,
					// 		),
					// 		type: 'danger',
					// 	})
					// 	return
					// }
				}

				requestArray.push(updateClientCredit(sos.getState().creditLimitForm))
			}
		}

		if (
			sos.getState().touchedTabs.includes('brandingLogo') &&
			sos.getState().brandingPictureData
		) {
			requestArray.push(saveClientLogo(sos.getState().brandingPictureData))
		}

		const result = await Promise.all(requestArray)

		if (result[0].error) {
			sosToast.sendApiErrorResponseToast(
				result[0],
				tString('errorUpdatingClientConfig', tPrefixClientManagement),
			)
		}

		if (result[1]?.error) {
			const translationString = sos
				.getState()
				.touchedTabs.includes('brandingLogo')
				? 'errorUploadingImage'
				: 'errorUpdatingCreditLimit'

			sosToast.sendApiErrorResponseToast(
				result[1],
				tString(translationString, tPrefixClientManagement),
			)
		} else if (
			result[1] &&
			sos.getState().touchedTabs.includes('brandingLogo')
		) {
			sos.change((ds) => {
				ds.brandingPictureData = null
				ds.brandingImageUrl = result[1]?.data.brandingImageUrl
			})
		} else if (
			result[1] &&
			sos.getState().touchedTabs.includes('creditLimit')
		) {
			sos.change((ds) => {
				ds.clientCreditRelationshipId = result[1]?.data.relationshipId
				ds.clientCreditRelationshipType = result[1]?.data.relationshipType
			})
		}

		if (!result[0].error && !result[1]?.error) {
			sosToast.sendToast({
				type: 'success',
				header: tString(
					'successfullyUpdatedCustomerDetails',
					tPrefixClientManagement,
				),
				dataTestId: 'company-updated',
			})
		}

		if (sos.getState().touchedTabs.includes('brandingLogo')) {
			sos.change((ds) => {
				ds.tempStateCustomerManagement.useCustomerInfoForBOL =
					ds.useCustomerInfoForBOL
				ds.tempStateCustomerManagement.brandingImageUrl = ds.brandingImageUrl
				ds.tempStateCustomerManagement.brandingPictureData =
					ds.brandingPictureData

				removeToTouchedTabs('brandingLogo', ds)
			})
		}

		if (sos.getState().touchedTabs.includes('brokerAssignments')) {
			sos.change((ds) => {
				ds.tempStateCustomerManagement.brokerLocationId = ds.brokerLocationId
				ds.tempStateCustomerManagement.brokerLocationName =
					ds.brokerLocationName
				ds.tempStateCustomerManagement.users = ds.users

				removeToTouchedTabs('brokerAssignments', ds)
			})
		}

		if (sos.getState().touchedTabs.includes('notifications')) {
			sos.change((ds) => {
				ds.tempStateCustomerManagement.notificationsForm = l.cloneDeep(
					ds.notificationsForm,
				)

				removeToTouchedTabs('notifications', ds)
			})
		}

		if (sos.getState().touchedTabs.includes('paymentTerms')) {
			sos.change((ds) => {
				ds.tempStateCustomerManagement.paymentTermsForm = l.cloneDeep(
					ds.paymentTermsForm,
				)

				removeToTouchedTabs('paymentTerms', ds)
			})
		}

		if (sos.getState().touchedTabs.includes('creditLimit')) {
			sos.change((ds) => {
				ds.tempStateCustomerManagement.creditLimitForm = l.cloneDeep(
					ds.creditLimitForm,
				)

				removeToTouchedTabs('creditLimit', ds)
			})
		}

		if (sos.getState().touchedTabs.includes('markupRules')) {
			sos.change((ds) => {
				ds.tempStateCustomerManagement.brokerDefaultMarkupForm = l.cloneDeep(
					ds.brokerDefaultMarkupForm,
				)

				removeToTouchedTabs('markupRules', ds)
			})
		}

		setIsPageModified(false)
	}
}

export const discardChanges = (): void => {
	if (sos.getState().touchedTabs.length > 0) {
		if (sos.getState().touchedTabs.includes('brandingLogo')) {
			sos.change((ds) => {
				ds.useCustomerInfoForBOL =
					ds.tempStateCustomerManagement.useCustomerInfoForBOL
				ds.brandingImageUrl = ds.tempStateCustomerManagement.brandingImageUrl
				ds.brandingPictureData =
					ds.tempStateCustomerManagement.brandingPictureData

				removeToTouchedTabs('brandingLogo', ds)
			})
		}

		if (sos.getState().touchedTabs.includes('brokerAssignments')) {
			sos.change((ds) => {
				ds.brokerLocationId = ds.tempStateCustomerManagement.brokerLocationId
				ds.brokerLocationName =
					ds.tempStateCustomerManagement.brokerLocationName
				ds.users = ds.tempStateCustomerManagement.users

				removeToTouchedTabs('brokerAssignments', ds)
			})
		}

		if (sos.getState().touchedTabs.includes('notifications')) {
			sos.change((ds) => {
				ds.notificationsForm = l.cloneDeep(
					ds.tempStateCustomerManagement.notificationsForm,
				)

				removeToTouchedTabs('notifications', ds)
			})
		}

		if (sos.getState().touchedTabs.includes('paymentTerms')) {
			sos.change((ds) => {
				ds.paymentTermsForm = l.cloneDeep(
					ds.tempStateCustomerManagement.paymentTermsForm,
				)

				removeToTouchedTabs('paymentTerms', ds)
			})
		}

		if (sos.getState().touchedTabs.includes('creditLimit')) {
			sos.change((ds) => {
				ds.creditLimitForm = l.cloneDeep(
					ds.tempStateCustomerManagement.creditLimitForm,
				)

				removeToTouchedTabs('creditLimit', ds)
			})
		}

		if (sos.getState().touchedTabs.includes('markupRules')) {
			sos.change((ds) => {
				ds.brokerDefaultMarkupForm = l.cloneDeep(
					ds.tempStateCustomerManagement.brokerDefaultMarkupForm,
				)

				removeToTouchedTabs('markupRules', ds)
			})
		}

		setIsPageModified(false)
	}
}

export const setIsPageModified = (
	value: boolean,
	ds?: StateCustomerManagement,
): void => {
	if (ds) {
		ds.isPageModified = value
	} else {
		sos.change((ds) => {
			ds.isPageModified = value
		})
	}
}

export const addToTouchedTabs = (
	touchedTab: TCustomerManagementSections,
	ds?: StateCustomerManagement,
): void => {
	if (ds) {
		if (ds.touchedTabs.includes(touchedTab) === false)
			ds.touchedTabs.push(touchedTab)

		setIsPageModified(true, ds)
	}
}

export const removeToTouchedTabs = (
	touchedTab: TCustomerManagementSections,
	ds?: StateCustomerManagement,
): void => {
	if (ds) {
		const indexToRemove = ds.touchedTabs.indexOf(touchedTab)

		ds.touchedTabs.splice(indexToRemove, 1)
	}
}

export const updateBrandingPictureData = (
	brandingPictureData: FileDropZoneData,
): void => {
	sos.change((ds) => {
		ds.brandingPictureData = brandingPictureData

		addToTouchedTabs('brandingLogo', ds)
	})
}

export const updateAddressForm = (
	field: keyof ICustomerDetailsAddressForm,
	changes: any,
): void => {
	sos.change((ds) => {
		ds.addressForm[field as string] = changes

		addToTouchedTabs('address', ds)
	})
}

export const updateNoticationsForm = (
	field: keyof INotificationsForm,
	changes: any,
): void => {
	sos.change((ds) => {
		ds.notificationsForm[field as string] = changes

		addToTouchedTabs('notifications', ds)
	})
}

export const updateCreditLimitForm = (
	field: keyof ICreditLimitForm,
	changes: any,
): void => {
	sos.change((ds) => {
		ds.creditLimitForm[field as string] = changes

		if (field === 'canExceedCreditLimit') {
			ds.creditLimitForm.canExceedCreditLimit = changes === '0' ? false : true
		}
		const totalBalance =
			(ds.creditLimitForm.invoicedBalance || 0) * (1 / 100) +
			(ds.creditLimitForm.inTransitBalance || 0) * (1 / 100)
		if (field === 'creditLimit') {
			ds.creditLimitForm.creditUtilization = getCreditUtilizationValue(
				totalBalance,
				ds.creditLimitForm?.creditLimit || 0,
			)
		}
		addToTouchedTabs('creditLimit', ds)
	})
}

export const updatePaymentTermsForm = (
	field: keyof apiTypes.PaymentTerms,
	changes: any,
): void => {
	const flatDollarFields: Array<keyof apiTypes.PaymentTerms> = []
	if (flatDollarFields.includes(field)) {
		changes = toCents(changes)
	}
	sos.change((ds) => {
		ds.paymentTermsForm[field as string] = changes
		addToTouchedTabs('paymentTerms', ds)
	})
}

export const markupFormOnFormUpdate = (
	field: keyof apiTypes.MarkupLogic,
	changes: any,
): void => {
	const flatDollarFields: Array<keyof apiTypes.MarkupLogic> = [
		'markupFlat',
		'maxMarkupFlat',
		'minMarkupFlat',
	]
	if (flatDollarFields.includes(field)) {
		changes = toCents(changes)
	}
	sos.change((ds) => {
		ds.brokerDefaultMarkupForm[field as string] = changes
		if (field === 'markupType') {
			if (changes === 'flat' && !ds.brokerDefaultMarkupForm.markupFlat) {
				ds.brokerDefaultMarkupForm.markupFlat = defaultMarkupFlat
			} else if (
				changes === 'percent' &&
				!ds.brokerDefaultMarkupForm.markupPercent
			) {
				ds.brokerDefaultMarkupForm.markupPercent = defaultMarkupPercent
			}
		}
		addToTouchedTabs('markupRules', ds)
	})
}

export const setBrokerNumber = (brokerNumber): void => {
	sos.change((ds) => {
		ds.brokerNumber = brokerNumber
	})
}

export const setBrokerLocation = (option: TypeaheadOption): void => {
	sos.change((ds) => {
		ds.brokerLocationName = option.label
		ds.brokerLocationId = option.value

		addToTouchedTabs('brokerAssignments', ds)
	})
}

export const setSalesAgent = (
	value: apiTypes.ClientConfigUserRequest,
): void => {
	sos.change((ds) => {
		ds.users = [value]
		addToTouchedTabs('brokerAssignments', ds)
	})
}

export const getCreditUtilizationValue = (
	totalBalance: number,
	creditLimit: number,
): number => {
	const creditUtilizationResult = parseFloat(
		((totalBalance / (creditLimit || 0)) * 100).toFixed(2),
	)

	if (isNaN(creditUtilizationResult) || !isFinite(creditUtilizationResult)) {
		return 0
	} else {
		return creditUtilizationResult
	}
}

export const updateWithSelectedConfig = async (
	selectedConfig: apiTypes.ClientConfigResponse,
): Promise<void> => {
	sos.change((ds) => {
		ds.brokerDefaultMarkupForm = null
		ds.paymentTermsForm = null
		ds.brokerNumber = null
		// ds.useCustomerInfoForBOL = false
		ds.clientAddress = null
		// ds.clientLocationId = null
		// ds.brandingImageUrl = null
		ds.brokerLocationId = null
		ds.brokerLocationName = null
		// ds.touchedTabs = []
		// ds.creditLimitForm = createDefaultCreditLimit()
		ds.addressForm = createDefaultAddress()
		ds.brandingPictureData = null
		// ds.users = []
		ds.isPageModified = false
		ds.notificationsForm = createDefaultNotification()
	})

	if (selectedConfig) {
		const locationListResponse = await apiLocation.fetchLocations(() => {}, {
			take: 1,
			skip: 0,
			clientConfigId: selectedConfig.id,
			query: 'NOT _exists_:parentId',
		})

		let brokerLocation: apiTypes.LocationResponse
		if (selectedConfig.brokerLocationId) {
			const brokerLocationResponse = await sosUser.getLocation(
				selectedConfig.brokerLocationId,
			)

			if (brokerLocationResponse) {
				brokerLocation = brokerLocationResponse
			}
		}

		if (locationListResponse.data) {
			const location = locationListResponse.data[0]

			const clientCreditResponse = await apiClientCredit.getClientCredit(
				() => {},
				selectedConfig.id,
			)

			let clientCredit: apiClientCredit.ClientCreditResponse
			if (clientCreditResponse.data) {
				clientCredit = clientCreditResponse.data
			} else if (clientCreditResponse.error) {
				sosToast.sendApiWarningResponseToast(clientCreditResponse)
			}

			if (location) {
				sos.change((ds) => {
					ds.brokerDefaultMarkupForm =
						selectedConfig.defaultMarkupLogic || createDefaultMarkup()
					ds.paymentTermsForm =
						selectedConfig.paymentTerms || createDefaultPaymentTerms()
					ds.brokerNumber = selectedConfig.brokerNumber || ''
					ds.useCustomerInfoForBOL = selectedConfig.useClientInfoForBolHeader
					ds.clientAddress = location.defaults?.defaultPickupAddress?.address
					ds.clientLocationId = location.id
					ds.brandingImageUrl = location.brandingImageUrl
					ds.brokerLocationId = brokerLocation?.id
					ds.brokerLocationName = brokerLocation?.name

					const totalBalance =
						(clientCredit?.unpaidInvoiceTotal || 0) +
						(clientCredit?.inTransitTotal || 0)

					ds.creditLimitForm = {
						creditLimit: clientCredit ? clientCredit.creditLimit / 100 : 0,
						creditLimitCurrency: 'USD',
						totalBalance: totalBalance,
						invoicedBalance: clientCredit?.unpaidInvoiceTotal || 0,
						inTransitBalance: clientCredit?.inTransitTotal || 0,
						creditUtilization: clientCredit
							? getCreditUtilizationValue(
									totalBalance,
									clientCredit?.creditLimit || 0,
							  )
							: 0,
						canExceedCreditLimit:
							clientCredit?.allowToExceedCreditLimit || false,
						emailRecipients: clientCredit?.creditWarning.email
							? clientCredit?.creditWarning.email.join()
							: '',
						emailWhenBeyondWarningLimit:
							clientCredit?.creditWarning.enabled || false,
						warningThreshold: clientCredit?.creditWarning.percentage || 0,
						creditLimitEnabled: clientCredit?.creditLimitEnabled,
					}

					ds.addressForm = {
						customerName: ds.clientAddress.company || '-',
						customerNumber: ds.brokerNumber || '-',
						street1: ds.clientAddress.street1 || '-',
						street2: ds.clientAddress.street2 || '-',
						city: ds.clientAddress.city || '-',
						state: ds.clientAddress.state || '-',
						zipCode: ds.clientAddress.zip || '-',
						country: ds.clientAddress.country || '-',
						contactName: ds.clientAddress.name || '-',
						phone: ds.clientAddress.phone || '-',
						email: ds.clientAddress.email || '-',
						fax: ds.clientAddress.fax || '-',
					}
					ds.notificationsForm = {
						sendShippingNotification:
							selectedConfig.customerShipNotification?.sendShippingNotification,
						shippingNotificationEmails: selectedConfig.customerShipNotification?.shippingNotificationEmails.join(),
					}
					ds.users = selectedConfig.users || []

					ds.clientCreditRelationshipId = clientCredit?.relationshipId || ''
					ds.clientCreditRelationshipType =
						clientCredit?.relationshipType || 'clientConfig'
					ds.selectedClientConfigId = selectedConfig.id

					// TEMPORARY DATA STORAGE
					const selectedConfigClone = l.cloneDeep(selectedConfig)

					ds.tempStateCustomerManagement.brokerDefaultMarkupForm =
						selectedConfigClone.defaultMarkupLogic
					ds.tempStateCustomerManagement.paymentTermsForm =
						selectedConfigClone.paymentTerms
					ds.tempStateCustomerManagement.brokerNumber =
						selectedConfigClone.brokerNumber
					ds.tempStateCustomerManagement.useCustomerInfoForBOL =
						selectedConfigClone.useClientInfoForBolHeader
					ds.tempStateCustomerManagement.brandingImageUrl =
						location.brandingImageUrl
					ds.tempStateCustomerManagement.brokerLocationId = brokerLocation?.id
					ds.tempStateCustomerManagement.brokerLocationName =
						brokerLocation?.name
					ds.tempStateCustomerManagement.creditLimitForm = {
						creditLimit: clientCredit?.creditLimit || 0,
						creditLimitCurrency: 'USD',
						totalBalance: 0,
						invoicedBalance: clientCredit?.unpaidInvoiceTotal || 0,
						inTransitBalance: clientCredit?.inTransitTotal || 0,
						creditUtilization: 0,
						canExceedCreditLimit:
							clientCredit?.allowToExceedCreditLimit || false,
						emailRecipients: clientCredit?.creditWarning.email
							? clientCredit?.creditWarning.email.join()
							: '',
						emailWhenBeyondWarningLimit:
							clientCredit?.creditWarning.enabled || false,
						warningThreshold: clientCredit?.creditWarning.percentage || 0,
						creditLimitEnabled: clientCredit?.creditLimitEnabled,
					}
					// ds.tempStateCustomerManagement.brandingPictureData =
					ds.tempStateCustomerManagement.notificationsForm = {
						sendShippingNotification:
							selectedConfig.customerShipNotification?.sendShippingNotification,
						shippingNotificationEmails: selectedConfig.customerShipNotification?.shippingNotificationEmails.join(),
					}
					ds.tempStateCustomerManagement.users = selectedConfigClone.users
				})
			} else {
				sosToast.sendToast({
					type: 'danger',
					header: tString('errorFetchingClientData', tPrefixClientManagement),
					body: tString('cannotFindTopLevelLocation', tPrefixClientManagement),
				})
			}
		} else {
			sosToast.sendApiErrorResponseToast(
				locationListResponse,
				tString('errorFetchingClientData', tPrefixClientManagement),
			)
		}
	}
}

export const updateClientCredit = async (
	clientCredit: ICreditLimitForm,
): Promise<IRequestState<apiClientCredit.ClientCreditResponse>> => {
	const clientCreditRequest: apiClientCredit.ClientCreditRequest = {
		allowToExceedCreditLimit: clientCredit.canExceedCreditLimit,
		relationshipId: sos.getState().clientCreditRelationshipId
			? sos.getState().clientCreditRelationshipId
			: sos.getState().selectedClientConfigId,
		relationshipType: sos.getState().clientCreditRelationshipType
			? sos.getState().clientCreditRelationshipType
			: 'clientConfig',
		creditWarning: {
			percentage: clientCredit.warningThreshold,
			enabled: clientCredit.emailWhenBeyondWarningLimit,
			email: clientCredit.emailWhenBeyondWarningLimit
				? splitEmail(clientCredit.emailRecipients)
				: [],
		},
		creditLimit: clientCredit.creditLimit * 100,
		// creditLimitEnabled: clientCredit.creditLimitEnabled,
		creditLimitEnabled: true,
	}

	if (sos.getState().clientCreditRelationshipId) {
		return apiClientCredit.updateClientCredit(
			() => {},
			sos.getState().clientCreditRelationshipId,
			clientCreditRequest,
		)
	} else {
		return apiClientCredit.createClientCredit(() => {}, clientCreditRequest)
	}
}

export const saveClientLogo = async (
	logoData: FileDropZoneData,
): Promise<IRequestState<apiTypes.LocationResponse>> => {
	const state = sos.getState()
	if (logoData.fileContent.indexOf('data:image/') === -1) {
		sosToast.sendToast({
			type: 'danger',
			body: tString('pleaseUploadAnImage', tPrefixClientManagement),
		})
		return
	}
	const imageFormat: apiTypes.DocumentImageFormat = getApiImageTypeFromImageString(
		logoData.fileContent,
	)
	if (!imageFormat) {
		sosToast.sendToast({
			type: 'danger',
			body: tString('pleaseUploadAValidImageType', tPrefixClientManagement),
		})
		return
	}
	return apiLocation.uploadLocationLogo(
		() => {},
		state.clientLocationId,
		{
			filename: logoData.fileName,
			image: getBase64StringFromImageString(logoData.fileContent),
			format: imageFormat,
		},
		sosUser.getSos().getState().selectedClientConfig.id,
	)
}

export const sendErrorToast = (errorMessage: string): number => {
	const toastBody: ToastState = {
		header: tString('apiUpdateError', 'page.customerManagement'),
		body: errorMessage,
		type: 'danger',
		key: Date.now(),
	}
	sosToast.sendToast(toastBody)
	return toastBody.key
}

export const deleteErrorToast = (key: number): void => {
	sosToast.deleteToastByKey(key)
}

export const updateUseClientInfoForBolHeader = (value: boolean): void => {
	sos.change((ds) => {
		ds.useCustomerInfoForBOL = value
		addToTouchedTabs('brandingLogo', ds)
	})
}
