import React from 'react'
import { l } from 'ui/lib/lodashImports'
import { validation } from 'ui/lib/validation'
import { formValidator } from 'ui/lib/validation/'
import { Required } from '../common/badge/Required'
import styles from './FormVertical.module.scss'
import { IFormData } from './IFormData'

// import { validation } from 'ui/lib/validation/validation'

export function FormVerticalCustom<T>(props: {
	label: string | React.ReactNode
	htmlFor?: string
	formData: IFormData<T>
	children: React.ReactNode
	fields?: Array<keyof T>
	// required?: boolean
}): React.ReactElement {
	const { fields, formData, label } = props

	let showRequired = false
	let error = null
	l.forEach(fields, (c) => {
		const meta = props.formData.metadata[c]
		const val: any = formData.form[c]
		const isAValue = validation.isAValue(val)
		if (meta.required && !isAValue) {
			showRequired = true
		}
		if (!showRequired && !error) {
			error = formValidator.validateValue(meta, val)
		}
	})

	return (
		<React.Fragment>
			<div className={styles.formRow}>
				<div className={styles.td + ' ' + styles.labelLeft}>
					<label>{label}</label>
				</div>
				<div className={styles.td}>{props.children}</div>
				<div className={styles.td}>{showRequired && <Required />}</div>
			</div>
			{error && !showRequired && (
				<div className={styles.errorRow}>
					<div className={styles.labelLeft}>&nbsp;</div>
					<div>
						<div className={styles.error}>{error}</div>
					</div>
				</div>
			)}
		</React.Fragment>
	)
}
