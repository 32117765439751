import { FC } from 'app/FunctionalComponent'
import React, { useState } from 'react'
import { Group } from 'ui/components/common/group/Group'
import { l } from 'ui/lib/lodashImports'
import { sos2 } from 'ui/lib/state/sos2'
import { sosUser } from 'ui/state'
import { theme } from 'ui/theme'

export const Debug: FC = (props: {
	data: any
	label: string
	maxItems?: number
	hide?: boolean
}) => {
	let { data, maxItems } = props
	const { label, hide } = props

	const [open, setOpen] = useState(false)
	const state = sos2.useSubscription(sosUser.getSos())

	if (!state.devShowDebugTags || !theme.isInDev()) {
		return null
	}

	let dataType = ''

	if (hide) {
		return null
	}

	if (maxItems == null) {
		maxItems = 5
	}
	if (data && l.isArray(data)) {
		dataType = `array[${maxItems}]`
		data = l.take(data, maxItems)
	}

	if (!data) {
		data = '(no data)'
		dataType = '(no data)'
	}

	if (l.isString(data)) {
		dataType = `string`
	}
	if (l.isNumber(data)) {
		dataType = `number`
	}

	if (!open) {
		return (
			<Group
				onClick={() => {
					setOpen(true)
				}}
			>
				{label && <span>{label}: </span>} {dataType}
			</Group>
		)
	}

	if (l.isString(data) || l.isNumber(data)) {
		return (
			<Group
				onClick={() => {
					setOpen(false)
				}}
			>
				{label && <span>{label}: </span>}
				<pre>{data}</pre>
			</Group>
		)
	} else {
		return (
			<Group
				onClick={() => {
					setOpen(false)
				}}
			>
				{label}
				<pre>{JSON.stringify(data, null, 2)}</pre>
			</Group>
		)
	}
}
