import _ from 'lodash'
import { apiTypes } from 'ui/api'

export function extractContainersFromShipment(
	shipment: apiTypes.ShipmentResponse | apiTypes.ShipmentRequest,
	includeNested?: boolean,
): apiTypes.Container[] {
	const containers: apiTypes.Container[] = []
	if (!shipment) {
		return containers
	}
	_.forEach(shipment.payloads, (payload) => {
		_.forEach(payload.containers, (container) => {
			containers.push(container)
			if (includeNested) {
				extractContainersFromContainer(container, containers)
			}
		})
	})
	return containers
}

function extractContainersFromContainer(
	container: apiTypes.Container,
	containers: apiTypes.Container[],
): apiTypes.Container[] {
	containers.push(...(container.containers || []))
	_.forEach(container.containers, (nestedContainer) => {
		extractContainersFromContainer(nestedContainer, containers)
	})
	return containers
}
