import { FC } from 'app/FunctionalComponent'
import React, { useState } from 'react'
import { TabContent, TabItem2, Tabs2 } from 'ui/components/common/tabs'
import { apiTypes } from 'ui/api'
import { t } from 'ui/components/i18n/i18n'
import { Center } from 'ui/components/layout/center'
import { l } from 'ui/lib/lodashImports'
import { PrinterListTable } from '../printer-list-table/PrinterListTable'
import { ScalesTableContent } from 'ui/pages/company-management/printers-and-scales/scales-table'
import * as classes from './PrintersAndScalesTabs.module.scss'

type TPrintersAndScalesTabs = 'printers' | 'scales'

export const tPrefix = 'page.companyManagement.printersAndScales.tabs'

export const PrintersAndScalesTabs: FC = (props: {
	printerList: apiTypes.PrinterResponse[]
	setPrinterList: React.Dispatch<
		React.SetStateAction<apiTypes.PrinterResponse[]>
	>
	locationId: string
	userCredentials: apiTypes.PrintNodeCredentialsResponse
}) => {
	const [selectedTab, setSelectedTab] = useState<TPrintersAndScalesTabs>(
		'printers',
	)
	const tabs: Array<{ name: TPrintersAndScalesTabs }> = [
		{
			name: 'printers',
		},
		{
			name: 'scales',
		},
	]

	return (
		<div className={classes.printersAndScalesTabs}>
			<Tabs2>
				{l.map(tabs, (c) => (
					<TabItem2
						isSelected={selectedTab === c.name}
						key={c.name}
						onClick={() => {
							setSelectedTab(c.name)
						}}
					>
						{t(l.camelCase(c.name), tPrefix)}
					</TabItem2>
				))}
			</Tabs2>
			<div className={classes.printersAndScalesTabsContainer}>
				<TabContent
					isVisible={selectedTab === 'printers'}
					renderer={() => (
						<Center>
							<PrinterListTable
								printerList={props.printerList}
								setPrinterList={props.setPrinterList}
								locationId={props.locationId}
								userCredentials={props.userCredentials}
							/>
						</Center>
					)}
				/>
				<TabContent
					isVisible={selectedTab === 'scales'}
					renderer={() => (
						<Center>
							<ScalesTableContent userCredentials={props.userCredentials} />
						</Center>
					)}
				/>
			</div>
		</div>
	)
}
