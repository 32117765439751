import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { Button } from 'ui/components/common/button'
import { Input } from 'ui/components/common/input'
import { LinkButton } from 'ui/components/common/link'
import { reload } from 'ui/components/common/router/windowUtils'
import { SmallButton } from 'ui/components/common/small-button'
import { StackedItem } from 'ui/components/form'
import { FlexRow } from 'ui/components/layout/flexRow'
import { Spacer } from 'ui/components/layout/spacer'
import { fireAndForget } from 'ui/lib/async'
import { l } from 'ui/lib/lodashImports'
import { sos2 } from 'ui/lib/state/sos2'
import { secrets } from 'ui/secrets'
import { sosUser } from 'ui/state'
import { theme } from 'ui/theme'
import { Layout } from '../layout'

export const DevLoginPage: FC = () => {
	if (theme.isInTMS2()) {
		return null // Display nothing in TMS2
	}

	const userState = sos2.useSubscription(sosUser.getSos())

	const options: Array<{
		source: string
		bearerToken: string
		name: string
	}> = []
	l.forEach(userState.devAuthTokens, (c) => {
		options.push({
			source: 'LocalStorage',
			bearerToken: c.devAuthToken,
			name: c.name,
		})
	})
	l.forEach(secrets.getSavedBearerTokens(), (c) => {
		options.push({
			source: 'DevSecrets',
			bearerToken: c.bearerToken,
			name: c.name,
		})
	})

	return (
		<Layout>
			<FlexRow fullWidth={true}>
				<StackedItem label='Nickname' showRequired={true}>
					<Input
						width={'200px'}
						value={userState.devAuthName}
						onChangeImmediate={(newVal) => {
							sosUser.setDevAuthName(newVal)
						}}
					/>
				</StackedItem>
				<StackedItem label='Auth Key' showRequired={true}>
					<Input
						width={'600px'}
						value={userState.devAuthToken}
						onChange={(newVal) => {
							fireAndForget(async () => {
								await sosUser.setDevAuthToken(null, newVal)
							}, `set dev auth token ${newVal}`)
						}}
					/>
				</StackedItem>
			</FlexRow>
			<Spacer />
			<StackedItem label='Saved Bearer Tokens' showRequired={true}>
				<table>
					<tbody>
						{l.map(options, (c, cIdx) => (
							<tr key={cIdx}>
								<td style={{ minWidth: '100px' }}>{c.source}</td>
								<td style={{ minWidth: '100px', textAlign: 'right' }}>
									{c.name}
								</td>
								<td>
									<LinkButton
										onClick={() => {
											fireAndForget(async () => {
												await sosUser.setDevAuthToken(c.name, c.bearerToken)
												setTimeout(() => {
													reload()
												}, 1000)
											}, `set dev auth token`)
										}}
									>
										{c.bearerToken}
									</LinkButton>
								</td>
							</tr>
						))}
					</tbody>
				</table>

				<FlexRow verticalAlign='center' fullWidth={true}>
					<SmallButton
						onClick={() => {
							sosUser.addDevAuth()
						}}
					>
						Add
					</SmallButton>
					<SmallButton
						onClick={() => {
							sosUser.removeDevAuth()
						}}
					>
						Remove
					</SmallButton>
				</FlexRow>
			</StackedItem>
			<Button
				onClick={() => {
					fireAndForget(async () => {
						await sosUser.fetchUser()
					}, 'fetchUser')
				}}
			>
				Fetch user
			</Button>
		</Layout>
	)
}
