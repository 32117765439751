import { FC } from 'app/FunctionalComponent'
import React, { useEffect } from 'react'
import { Debug } from 'ui/components/dev'
import { t } from 'ui/components/i18n/i18n'
import { FlexItem, FlexRow } from 'ui/components/layout/flexRow'
import { Spacer } from 'ui/components/layout/spacer'
import { fireAndForget } from 'ui/lib/async/fireAndForget'
import { l } from 'ui/lib/lodashImports'
import { sos2 } from 'ui/lib/state/sos2'
import { CommoditiesReadOnly } from 'ui/pages/new-quote'
import * as commonClasses from 'ui/theme/common.module.scss'
import { addClassIf, FontSizes } from 'ui/theme/theme'
import { sosMakeOfferPage } from '.'
import { Layout } from '../layout'
import classes from './MakeOfferPage.module.scss'
import { MakeOfferShipmentDetails } from './MakeOfferShipmentDetails'
import { OfferDetailsCard } from './OfferDetailsCard'
import { ShipperCard } from './ShipperCard'
import { SpecialInstructionsCard } from './SpecialInstructionsCard'

export const HeaderText: FC = (props: {
	children: React.ReactNode
	large?: boolean
	bold?: boolean
	inTable?: boolean
	fontSize?: FontSizes
}) => {
	let className = classes.headerText
	className = addClassIf(props.large, classes.large, className)
	className = addClassIf(props.bold, classes.boldest, className)
	className = addClassIf(
		!l.isNil(props.fontSize),
		commonClasses[props.fontSize],
		className,
	)

	if (props.inTable) {
		return (
			<tr className={className}>
				<td>{props.children}</td>
			</tr>
		)
	} else {
		return <div className={className}>{props.children}</div>
	}
}

export const Header: FC = (props: {
	text: React.ReactNode
	children: React.ReactNode
}) => {
	return (
		<div>
			<div>{props.text}</div>

			<div>{props.children}</div>
		</div>
	)
}

const OfferStatusAlert: FC = (props: { savedRate; shipment }) => {
	let status = props.savedRate.offerStatus

	if (
		props.shipment.shipmentStatus === 'booked' &&
		props.savedRate.id !== props.shipment.bookedRate?.id
	) {
		status = 'void'
	}

	const danger = [
		'retracted',
		'declined',
		'awardRetracted',
		'canceled',
		'void',
	].includes(status)

	return status ? (
		<div className='bootstrap-wrapper'>
			<div
				className={`mt-3 alert ${
					danger ? 'alert-danger' : 'alert-success'
				} text-center`}
				role='alert'
			>
				{t(status, 'page.makeOffer.offerStatus')}
			</div>
		</div>
	) : null
}

const MakeOfferPage: FC = (props: {
	shipmentId: string
	offerId?: string
	isBroker?: boolean
}) => {
	const state = sos2.useSubscription(sosMakeOfferPage.sos)
	const { shipment, savedRate } = state
	const { shipmentId, offerId, isBroker } = props

	useEffect(() => {
		const pollIntervalMs = 5000
		let statusOnly = false
		const intervalId = setInterval(async () => {
			if (offerId) {
				// spot quote: we are not logged in and need the offerId to authenticate
				fireAndForget(async () => {
					await sosMakeOfferPage.getOffer(shipmentId, offerId, isBroker)
				}, `make offer fetching offer (${offerId}) and shipment (${shipmentId}) `)
			} else {
				// log offer: we are logged in and don't need the offerId to authenticate
				fireAndForget(async () => {
					await sosMakeOfferPage.getShipment(shipmentId, isBroker, statusOnly)
					statusOnly = true
				}, `make offer fetching shipment ${shipmentId}`)
			}
		}, pollIntervalMs)
		return () => {
			clearInterval(intervalId)
		}
	})

	return (
		<Layout>
			<div className={classes.makeOfferPage}>
				<FlexRow fullWidth={true}>
					<FlexItem>
						<div className={classes.detailsItem}>
							{!isBroker && <ShipperCard shipment={shipment} />}
							<Spacer />
							<FlexItem>
								<MakeOfferShipmentDetails
									shipment={shipment}
									fillHeight={true}
									showMap={true}
								/>
							</FlexItem>
						</div>
					</FlexItem>
					{!isBroker && (
						<FlexItem>
							<div className={classes.detailsItem}>
								<ShipperCard shipment={shipment} />
								<Spacer />
								<FlexItem>
									<SpecialInstructionsCard shipment={shipment} />
								</FlexItem>
							</div>
						</FlexItem>
					)}
				</FlexRow>
				<Spacer />
				<FlexRow fullWidth={true}>
					<CommoditiesReadOnly fullWidth={true} shipment={shipment} />
				</FlexRow>
				<OfferStatusAlert savedRate={savedRate} shipment={shipment} />
				<Spacer />
				<FlexRow fullWidth={true}>
					<OfferDetailsCard logOffer={!props.offerId} isBroker={isBroker} />
				</FlexRow>
				<Debug hide={false} data={shipment} label='Shipment' />
				<Debug hide={false} data={savedRate} label='Saved Rate' />
			</div>
		</Layout>
	)
}

export { MakeOfferPage }
