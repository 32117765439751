import { History } from 'history'
import { fireAndForget } from 'ui/lib/async'
import { navParentTo } from 'ui/lib/IframeRpc'
import { log } from 'ui/lib/log'
import { createLazySos2, setOnNavigate } from 'ui/lib/state/sos2/sos2'
import { inJestUnitTest } from 'ui/lib/testing/inJestUnitTest'
import { theme } from 'ui/theme'
import { parseHref } from './SimpleRouter'
import { getLocationHref } from './windowUtils'

let _history: History<any> = null

let _queryStringConsumed = false

export function isQueryStringConsumed(): boolean {
	return _queryStringConsumed
}
export function consumeQueryString(): void {
	_queryStringConsumed = true
}

export function setQueryString(queryString: string): boolean {
	if (inJestUnitTest()) {
		return false
	}
	log('router', 'set query string to', queryString)

	if (_history) {
		const querySplit = getLocationHref().split('?', 2)
		const hrefWithoutQueryString = querySplit[0]

		const currentQueryString =
			'?' + (querySplit.length === 2 ? querySplit[1] : '')

		if (currentQueryString === queryString) {
			// We are already here
			// log('router', 'already here, no query string alteration')
			return false
		}

		const hashSplit = hrefWithoutQueryString.split('/#', 2)
		const hash = hashSplit.length === 2 ? hashSplit[1] : ''

		_history.replace(hash + queryString)

		return true
	} else {
		log(
			'router',
			'no history installed, cannnot set query string!',
			queryString,
		)
		return false
	}
}

export interface IStateRouter {
	currentLocation: string
}
export const getSos = createLazySos2('sosRouter', 2, () => ({
	currentLocation: { default: getLocationHref() },
}))

export const setHistory = (history: History<any>): void => {
	_history = history
	history.listen((location) => {
		const { currentLocation } = getSos().getState()
		const currentPathname = parseHref(currentLocation).pathname
		const { pathname } = parseHref(location.pathname)

		if (pathname !== currentPathname) {
			getSos().change((ds) => {
				ds.currentLocation = location.pathname
			})
		}
	})
}

export const getCurrentPath = (): string => {
	const { currentLocation } = getSos().getState()
	const currentPathname = parseHref(currentLocation).pathname
	return currentPathname
}

export const navigate = (href: string, replace = false): boolean => {
	const { currentLocation } = getSos().getState()
	const currentPathname = parseHref(currentLocation).pathname
	const { pathname } = parseHref(href)

	log('sosRouter', 'nav to', href)
	if (pathname === currentPathname) {
		// We are already here, don't navigate
		log('sosRouter', 'already here, no navigation required')
		return false
	}

	if (theme.isInTMS2()) {
		fireAndForget(
			async () => await navParentTo(href, replace),
			`parent navigation ${href}`,
		)
	}

	if (!inJestUnitTest()) {
		if (!_history) {
			throw new Error('history was not set')
		}
		if (replace) {
			_history.replace(href)
		} else {
			_history.push(href)
		}
	}
}

setOnNavigate((url, qs) => {
	navigate(url)
	setQueryString(qs)
})
