import { NotFound } from 'app/AppRouter'
import { FC } from 'app/FunctionalComponent'
import React from 'react'
import {
	IRoute,
	LoginModeType,
	SimpleRouter,
} from 'ui/components/common/router'
import { LoadOptimizerLoadConsolidationPage } from './load-consolidation/LoadOptimizerLoadConsolidationPage'
import { LoadOptimizerPayloadsTable } from './payloads-table/LoadOptimizerPayloadsTable'
import { LoadOptimizerRulesTable } from './rules-table/LoadOptimizerRulesTable'

export const loadOptimizerRoutes: IRoute[] = [
	{
		isDefault: true,
		urls: ['/load-optimizer/404'],
		renderer: () => <NotFound />,
		anonymous: true,
	},
	{
		urls: ['/load-optimizer'],
		renderer: () => <LoadOptimizerPayloadsTable />,
	},
	{
		urls: ['/load-optimizer/consolidate'],
		renderer: () => <LoadOptimizerLoadConsolidationPage />,
	},
	{
		urls: ['/load-optimizer/rules'],
		renderer: () => <LoadOptimizerRulesTable />,
	},
]

export const RouterLoadOptimizer: FC = (props: {
	currentLocation: string
	loginMode: LoginModeType
}) => {
	return (
		<SimpleRouter
			routes={loadOptimizerRoutes}
			currentLocation={props.currentLocation}
			loginMode={props.loginMode}
		/>
	)
}
