import { apiCommon } from '..'
import { IRequestState } from '../requestState'
import { apiTypes } from 'ui/api'

export const getClientConfig = async (
	onProgress: (rs: IRequestState<apiTypes.ClientConfigResponse>) => void,
	clientConfigId: string,
): Promise<IRequestState<apiTypes.ClientConfigResponse>> => {
	return await apiCommon.apiFetch(
		onProgress,
		{ method: 'GET' },
		`brokers/client-configs/${clientConfigId}`,
	)
}

export const getClientConfigList = async (
	onProgress: (rs: IRequestState<apiTypes.ClientConfigListResponse>) => void,
	query?: string,
	take?: number,
	skip?: number,
): Promise<IRequestState<apiTypes.ClientConfigListResponse>> => {
	return await apiCommon.apiFetch(
		onProgress,
		{ entireResponse: true },
		'brokers/client-configs',
		{ query, take, skip },
	)
}

export const updateClientConfig = async (
	onProgress: (rs: IRequestState<apiTypes.ClientConfigResponse>) => void,
	contractId: string,
	contract: apiTypes.ClientConfigRequest,
): Promise<IRequestState<apiTypes.ClientConfigResponse>> => {
	return await apiCommon.apiFetch(
		onProgress,
		{ method: 'PUT', data: { ...contract } },
		'brokers/client-configs/' + contractId,
	)
}

export const addClient = async (
	addClientRequest: apiTypes.AddClientRequest,
	onProgress: (rs: IRequestState<apiTypes.AddClientResponse>) => void,
): Promise<IRequestState<apiTypes.AddClientResponse>> => {
	return await apiCommon.apiFetch(
		onProgress,
		{ method: 'POST', data: { ...addClientRequest } },
		'brokers/clients',
	)
}
