import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { sos2 } from 'ui/lib/state/sos2'
import { sosFeatureToggles } from 'ui/state'
import { checkFeatureToggle } from 'ui/state/sosFeatureToggles'
import { isInDev } from 'ui/theme/theme'
import { t } from '../i18n/i18n'
import classes from './RequiresFeatureToggle.module.scss'

export const RequiresFeatureToggle: FC = (props: {
	featureToggle: string
	children: React.ReactNode
	showUnauthorizedMessage?: boolean
}) => {
	const state = sos2.useSubscription(sosFeatureToggles.sos)

	React.useEffect(() => {
		checkFeatureToggle(props.featureToggle)
	}, [props.featureToggle])

	const featureToggle = state.featureToggles[props.featureToggle]

	if (!featureToggle) {
		return (
			<FeatureToggleWrapper featureToggleName={props.featureToggle}>
				<div className={classes.loading}>
					{t('featureToggleLoading', 'component.Permissions')}
				</div>
			</FeatureToggleWrapper>
		)
	} else if (featureToggle && featureToggle.enabled) {
		return (
			<FeatureToggleWrapper featureToggleName={props.featureToggle}>
				{props.children}
			</FeatureToggleWrapper>
		)
	}

	return (
		<FeatureToggleWrapper featureToggleName={props.featureToggle}>
			{props.showUnauthorizedMessage && (
				<div className={classes.notAuthorized}>
					{t('featureUnauthorized', 'component.Permissions')}
				</div>
			)}
		</FeatureToggleWrapper>
	)
}
export const FeatureToggleWrapper: FC = (props: {
	children: React.ReactNode
	featureToggleName: string
}) => {
	if (isInDev()) {
		return (
			<div className={classes.devBorder}>
				<div className={classes.featureToggleLabel}>
					Feature Toggle: {props.featureToggleName}
				</div>
				{props.children}
			</div>
		)
	}
	return <>{props.children}</>
}
