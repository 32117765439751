import { NotFound } from 'app/AppRouter'
import { FC } from 'app/FunctionalComponent'
import React from 'react'
import {
	IRoute,
	LoginModeType,
	SimpleRouter,
} from 'ui/components/common/router'
import { ManifestsPage } from './ManifestsPage'

export const manifestRoutes: IRoute[] = [
	{
		isDefault: true,
		urls: ['/manifests/404'],
		renderer: () => <NotFound />,
		anonymous: true,
	},
	{
		urls: ['/manifests'],
		renderer: () => <ManifestsPage />,
	},
]

export const RouterManifests: FC = (props: {
	currentLocation: string
	loginMode: LoginModeType
}) => {
	return (
		<SimpleRouter
			routes={manifestRoutes}
			currentLocation={props.currentLocation}
			loginMode={props.loginMode}
		/>
	)
}
