import { apiTypes } from 'ui/api'

const brokerShipmentStatusRanks: {
	[key in apiTypes.BrokerShipmentResponse['brokerShipmentStatus']]: number
} = {
	canceled: 0,
	needs_quote: 1,
	quoted: 2,
	needs_transport: 3,
	awarded: 4,
	booked: 5,
	in_transit: 6,
	delivered: 7,
	lost_bid: 8,
}

export const compareBrokerShipmentStatus = (
	status: apiTypes.BrokerShipmentResponse['brokerShipmentStatus'],
	statusToCompareAgainst: apiTypes.BrokerShipmentResponse['brokerShipmentStatus'],
): number => {
	const statusRank = brokerShipmentStatusRanks[status]
	const compareRank = brokerShipmentStatusRanks[statusToCompareAgainst]

	if (statusRank === compareRank) {
		return 0
	} else if (statusRank < compareRank) {
		return -1
	} else {
		return 1
	}
}

const shipmentStatusRanks: {
	[key in apiTypes.ShipmentResponse['shipmentStatus']]: number
} = {
	void: 0,
	preparing: 1,
	quoted: 2,
	active: 3,
	active_invites_not_sent: 3,
	active_invites_sent: 3,
	active_with_offers: 3,
	awarded: 4,
	booked: 5,
	no_longer_in_erp: 6,
}

export const compareShipmentStatus = (
	status: apiTypes.ShipmentResponse['shipmentStatus'],
	statusToCompareAgainst: apiTypes.ShipmentResponse['shipmentStatus'],
): number => {
	const statusRank = shipmentStatusRanks[status]
	const compareRank = shipmentStatusRanks[statusToCompareAgainst]

	if (statusRank === compareRank) {
		return 0
	} else if (statusRank < compareRank) {
		return -1
	} else {
		return 1
	}
}
