import React from 'react'
import { tCurrency, tDate, tCurrencyFromCents } from '../i18n/i18n'
import { DataCellRenderer } from './IDataTableHeader'
import { DateTime } from 'luxon'

interface IRow {
	[index: string]: any
}

interface ICurrency extends IRow {
	currencyCode: string
}

type IDate = IRow

export const currency: DataCellRenderer<ICurrency> = (
	data: string,
	row: ICurrency,
) => (
	<span>
		{tCurrency(Number.parseFloat(data), row.currencyCode)}
		{' ' + row.currencyCode}
	</span>
)

export const currencyFromCents: DataCellRenderer<ICurrency> = (
	data: string,
	row: ICurrency,
) => (
	<span>
		{tCurrencyFromCents(Number.parseFloat(data), row.currencyCode)}
		{' ' + row.currencyCode}
	</span>
)

export const shortDate: DataCellRenderer<IDate> = (
	data: string,
	row: IDate,
) => <span>{tDate(data, DateTime.DATE_SHORT)}</span>
