import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { AngryRedBox } from './AngryRedBox'

export const ErrorBox: FC = (props: {
	error: any
	label?: React.ReactNode
}) => {
	const { error } = props

	if (!error || error.length === 0) {
		return null
	}

	return <AngryRedBox label={props.label}>{JSON.stringify(error)}</AngryRedBox>
}
