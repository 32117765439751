import React, { RefObject, useEffect } from 'react'
import { OkCancelButtons } from 'ui/components/common/okCancelButtons'
import { tArgz } from 'ui/components/i18n/i18n'


export const BookWithPreferredRoutingButton = (props: {
	refProp?: RefObject<HTMLButtonElement>
	autoRateCarrier?: string
	autoRateService?: string
	onOk?: () => void
	isLoadingOrder?: boolean
	isLoading?: boolean
	tPrefix?: string
	isValid?: boolean
}): JSX.Element => {
	const { autoRateCarrier, tPrefix, isValid } = props

	useEffect(() => {}, [autoRateCarrier])

	let buttonTitle =
		props.autoRateCarrier && props.autoRateService
			? 'bookWithCustomerPreferredRouting'
			: 'bookWithLowestCost'

	if (!props.autoRateCarrier && !props.autoRateService) {
		buttonTitle = 'bookWithLowestRate'
	}

	return (
		<React.Fragment>
			<OkCancelButtons
				refProp={props.refProp}
				ok={tArgz(
					buttonTitle,
					{
						provider: props.autoRateCarrier || '',
						service: props.autoRateService || '',
					},
					tPrefix,
				)}
				onOk={props.onOk}
				okColor={'green'}
				isValid={!props.isLoadingOrder && (isValid === true || isValid === undefined)}
				isSpinning={props.isLoading}
				okTestId={'new-quote-parcel-book-with-customer-preferred-routing'}
			/>
		</React.Fragment>
	)
}
