import { IFormMetadataCollection } from 'ui/components/form'
import { paymentNumberTypeOptions } from 'ui/pages/provider-portal/PaymentNumberTypeOptions'

export interface IAddCustomerForm {
	proNumber: string
	paymentNumberType: string
	paymentNumber: string
}

export const addCustomerFormMetadata: IFormMetadataCollection<IAddCustomerForm> = {
	proNumber: {
		required: true,
	},
	paymentNumberType: {
		required: true,
		options: paymentNumberTypeOptions,
		defaultValue: 'check',
	},
	paymentNumber: {
		required: true,
	},
}
