// see: https://usehooks.com/useAnimation/
import { useEffect, useRef, useState } from 'react'

export function useAnimationFrame(): number {
	const [elapsedMs, setElapsedMs] = useState(0)

	const animationFrame = useRef<any>()

	useEffect(() => {
		const start = Date.now()
		const onFrame = (): void => {
			setElapsedMs(Date.now() - start)
			loop()
		}
		const loop = (): void => {
			animationFrame.current = requestAnimationFrame(onFrame)
		}
		loop()

		return () => {
			cancelAnimationFrame(animationFrame.current)
		}
	}, [])

	return elapsedMs
}
