import { FC } from 'app/FunctionalComponent'
import React, {createContext, RefObject, useEffect, useRef, useState} from 'react'
import { Col, Row } from 'react-bootstrap'
import { sosToast } from 'common/components/toast'
import {
	apiPrintNode,
	apiShipments,
	apiTmsServiceConfig,
	apiTypes,
} from 'ui/api'
import { IRequestState } from 'ui/api/requestState'
import { Button } from 'ui/components/common/button/Button'
import { Card } from 'ui/components/common/card'
import { OkCancelButtons } from 'ui/components/common/okCancelButtons'
import { sosRouter2 } from 'ui/components/common/router'
import { t, tArgz, tString } from 'ui/components/i18n/i18n'
import { Spacer } from 'ui/components/layout/spacer'
import { RequiresFeatureToggle } from 'ui/components/permissions'
import { fireAndForget } from 'ui/lib/async'
import { l } from 'ui/lib/lodashImports'
import { BillToCard } from 'ui/pages/new-quote/parcel/components/bill-to/BillToCard'
import { BillToDropdown } from 'ui/pages/new-quote/parcel/components/bill-to/BillToDropDown'
import {
	createParcelShipmentRequest,
	orderToLoad,
	shipmentToParcelForm,
	updatePackagesFromOrder,
} from 'ui/pages/new-quote/state/newQuoteUtils/shipmentMapping'
import { sosUser } from 'ui/state'
import { defaultParcelShipmentValidations } from '../../state/newQuoteUtils/validateShipment/shipmentValidations'
import { validateShipmentData } from '../../state/newQuoteUtils/validateShipment/validateShipmentUtils'
import { getParcelDemoShipmentData } from './getParcelDemoShipmentData'
import { LoadOrder } from './load-order'
import {
	loadWeightFromScale,
	NewQuotePackages,
} from './new-quote-packages/NewQuotePackages'
import { NewQuoteAccessorial } from './NewQuoteAccessorial/NewQuoteAccessorial'
import {
	emptyAccessorialForm,
	emptyAddressForm,
	emptyBillToForm,
	emptyPackageForm,
	NewQuoteAccessorialsForm,
	NewQuoteBillToForm,
	NewQuotePackageForm,
} from './newQuoteForms'
import * as classes from './NewQuoteParcel.module.scss'
import { NewQuoteParcelAddress } from './NewQuoteParcelAddress'
import { OverridePrinterLocationDropDown } from './override-printer-location'
import { OverrideScaleLocationDropDown } from './override-scale-location'
import { Modal } from 'ui/components/common/modal'
import { Icon, solidIcons } from 'ui/components/common/icon'
import { Debug } from 'ui/components/dev'
import { useOnce } from 'ui/components/hooks'
import { BookWithPreferredRoutingButton } from '../../BookWithPreferredRoutingButton'
import {GoodResponse} from 'ui/api/apiTypes'

const tPrefix = 'page.newQuote.parcel'


export interface UIGoodsResponse extends GoodResponse {
	checked: boolean
	packageId: string
}


export const NewQuoteParcel: FC = (props: {
	state: apiTypes.ShipmentResponse
}) => {
	const { state } = props

	const [pickupAddressForm, setPickupAddressForm] = useState(() =>
		l.cloneDeep(emptyAddressForm),
	)
	const [deliveryAddressForm, setDeliveryAddressForm] = useState(() =>
		l.cloneDeep(emptyAddressForm),
	)
	const [accessorialsForm, setAccessorialsForm] = useState<
		NewQuoteAccessorialsForm
	>(() => l.cloneDeep(emptyAccessorialForm))
	const [packagesForm, setPackagesForm] = useState<NewQuotePackageForm[]>(
		() => [l.cloneDeep(emptyPackageForm)],
	)
	const [billToForm, setBillToForm] = useState<NewQuoteBillToForm>(
		() => emptyBillToForm,
	)

	const [isLoading, setIsLoading] = useState(false)
	const [orderPickTicketNumber, setOrderPickTicketNumber] = useState<string>('')
	const [loadedOrder, setLoadedOrder] = useState<apiTypes.OrderResponse>(null)
	const [isLoadingOrder, setIsLoadingOrder] = useState<boolean>(false)
	const [autoRateCarrier, setAutoRateCarrier] = useState<string>(null)
	const [autoRateService, setAutoRateService] = useState<string>('')

	useEffect(() => {
		setAutoRateCarrier(billToForm.carrier)
		setAutoRateService(billToForm.serviceLevel)
	}, [billToForm])

	let currentUserLocationId: string

	if (sosUser.getSos().getState().printStation?.locationId) {
		currentUserLocationId = sosUser.getSos().getState().printStation?.locationId
	}

	const userSessionScaleId = sosUser.getSos().getState().sessionScaleId
	const [locationId, setLocationId] = useState<string>(currentUserLocationId)
	const [printerList, setPrinterList] = useState<apiTypes.PrinterResponse[]>([])
	const [shipperParcelConfig, setShipperParcelConfig] = useState<
		apiTypes.ShipperParcelConfigRequest
	>({
		allowRateShopping: true,
		automaticParcelBooking: false,
		automaticNewQuoteRerouteAfterBooking: false,
		printReturnLabelsLast: false,
	})
	const [printNodeScalesList, setPrintNodeScalesList] = useState<
		apiPrintNode.PrintNodeScalesResponse[]
	>([])
	const [printNodeScale, setPrintNodeScale] = useState<
		apiPrintNode.PrintNodeScalesResponse
	>(null)
	const [isFetchingPrintNodeScales, setIsFetchingPrintNodeScales] = useState<
		boolean
	>(true)
	const [printNodeCredentials, setPrintNodeCredentials] = useState<
		apiTypes.PrintNodeCredentialsResponse
	>(null)

	const [errorMessage, setErrorMessage] = useState<IRequestState<any>>(null)
	const [modalTitle, setModalTitle] = useState<string>('')

	const [orderNumber, setOrderNumber] = useState<string>('')
	const [goods, setGoods] = useState<UIGoodsResponse[]>([])

	const loadOrderInput = useRef(null)
	const bookWithButton = useRef<HTMLButtonElement>(null)
	const getRatesButton = useRef<HTMLButtonElement>(null)

	useOnce(() => {
		fireAndForget(async () => {
			const result = await apiTmsServiceConfig.getTmsServiceConfig()
			if (result.data) {
				setShipperParcelConfig(
					Object.assign({}, shipperParcelConfig, result.data.parcel),
				)
			}
		}, '')
	})

	useEffect(() => {
		if (userSessionScaleId) {
			if (printNodeScalesList.length > 0) {
				const scale = printNodeScalesList.find(
					(scale) => scale.computerId === userSessionScaleId,
				)

				setPrintNodeScale(scale)
			}
		}
	}, [printNodeScalesList, userSessionScaleId])

	useEffect(() => {
		if (state) {
			const mappedShipment = shipmentToParcelForm(state)
			setPackagesForm(mappedShipment.packagesForm)
			setBillToForm(mappedShipment.billToForm)
			setAccessorialsForm(mappedShipment.accessorialsForm)
			setPickupAddressForm(mappedShipment.pickupAddressForm)
			setDeliveryAddressForm(mappedShipment.deliveryAddressForm)
		}
	}, [state])

	useEffect(() => {
		if (locationId) {
			fireAndForget(async () => {
				const userCredentialsResponse = await apiPrintNode.getCredentialsList(
					() => {},
					locationId,
				)

				if (userCredentialsResponse.data?.length) {
					if (userCredentialsResponse.data[0]) {
						const credential = userCredentialsResponse.data[0]
						setPrintNodeCredentials({
							accountId: credential.accountId,
							apiKey: credential.apiKey,
							username: credential.username,
							password: credential.password,
							locationId: credential.locationId,
							id: credential.id,
						})

						apiPrintNode
							// .getPrinterList(credential.id)
							.getPrinterList(credential.id, {}, true)
							.then((response) => {
								if (response.data) {
									// setPrinterList(response.data)
									setPrinterList(response.data.entities)
								} else if (response.error) {
									sosToast.sendApiErrorResponseToast(response)
								}
							})
							.catch((error) => {
								sosToast.sendApiErrorResponseToast(error)
							})

						apiPrintNode
							.getScaleList(credential)
							.then((response) => {
								const scalesLists = l.flatMap(
									response,
									(scalesList) =>
										scalesList as apiPrintNode.PrintNodeScalesResponse[],
								)

								const filteredScalesList = scalesLists.filter(
									(scale) => scale !== undefined,
								)

								setPrintNodeScalesList(filteredScalesList)
								setIsFetchingPrintNodeScales(false)
							})
							.catch((error) => {
								sosToast.sendApiErrorResponseToast(error)
							})
					}
				} else if (userCredentialsResponse.error) {
					sosToast.sendApiErrorResponseToast(userCredentialsResponse)
					setIsFetchingPrintNodeScales(false)
				} else {
					setIsFetchingPrintNodeScales(false)
				}
			}, `Fetching LocationId`)
		} else {
			setIsFetchingPrintNodeScales(false)
		}
	}, [locationId])

	const shipmentAction = async (book = false): Promise<void> => {
		const shipment: apiTypes.ShipmentRequest = loadedOrder
			? updatePackagesFromOrder(
					packagesForm,
					pickupAddressForm,
					deliveryAddressForm,
					billToForm,
					loadedOrder,
				goods,
				autoRateService === 'International Priority DirectDistribution'
			  )
			: createParcelShipmentRequest({
					pickupAddressForm,
					deliveryAddressForm,
					accessorialsForm,
					packagesForm,
					billToForm,
					orderPickTicketNumber,
					goods
			  })

		const errors = validateShipmentData(
			shipment,
			defaultParcelShipmentValidations,
		)

		if (errors.length > 0) {
			sosToast.sendToast({
				type: 'danger',
				header: tString('validationError', 'validation.error'),
				body: (
					<div data-testid='validation-errors'>
						{l.map(errors, (c, cIdx) => (
							<div key={cIdx}>{'- ' + c}</div>
						))}
					</div>
				),
				dataTestId: 'parcel-book-fail-toast',
			})
			return
		}

		setIsLoading(true)

		const bookOnCreateParams = {
			book: book,
		}

		if (book) {
			if (autoRateCarrier) {
				bookOnCreateParams['providerName'] = autoRateCarrier
			}

			if (autoRateService) {
				bookOnCreateParams['serviceLevel'] = autoRateService
			}
		}

		let getRates = !book

		if (book) {
			getRates = !(autoRateCarrier && autoRateCarrier)
		}

		const shipmentResponse: IRequestState<apiTypes.ShipmentResponse> = await apiShipments.createShipment(
			shipment,
			getRates,
			() => {},
			bookOnCreateParams,
		)

		setIsLoading(false)

		if (shipmentResponse.error) {
			setErrorMessage(shipmentResponse)
			setModalTitle(book ? 'errorBookingShipment' : 'errorGettingRates')
			return
		}

		if (
			book &&
			shipperParcelConfig.automaticNewQuoteRerouteAfterBooking
			// && sosUser.getSos().getState().printStation?.id
		) {
			clearForm()
			sosToast.sendToast({
				type: 'success',
				header: tArgz('successfullyBooked', { orderNumber }, tPrefix),
			})

			setTimeout(() => {
				loadOrderInput.current.focus()
			}, 100)

			setOrderNumber('')
		} else {
			redirectToShipmentProfile(shipmentResponse.data.id)
		}
	}

	const clearForm = (): void => {
		setPickupAddressForm(emptyAddressForm)
		setDeliveryAddressForm(emptyAddressForm)
		setAccessorialsForm(emptyAccessorialForm)
		setPackagesForm([emptyPackageForm])
		setBillToForm(emptyBillToForm)
		setOrderPickTicketNumber('')
		setAutoRateCarrier(null)
		setAutoRateService(null)
		setOrderNumber('')
		setLoadedOrder(null)
	}

	const redirectToShipmentProfile = (shipmentId: string): void => {
		sosRouter2.navigateTo(
			{
				url: '/shipments-v3/shipment-profile/:shipmentId?rerate=1',
				params: {
					shipmentId: {
						name: 'shipmentId',
					},
				},
				queries: {},
			},
			{ shipmentId },
		)
	}

	const setParcelDemoShipment = (): void => {
		const {
			pickupAddressForm,
			deliveryAddressForm,
			packagesForm,
		} = getParcelDemoShipmentData()

		setPickupAddressForm(pickupAddressForm)
		setDeliveryAddressForm(deliveryAddressForm)
		setPackagesForm(packagesForm)
	}

	return (
		<div className={classes.newQuoteParcel}>
			<RequiresFeatureToggle featureToggle='demo-shipment-button'>
				<Button
					color={'gray'}
					onClick={setParcelDemoShipment}
					inline={true}
					isDisabled={isLoadingOrder}
					testId={'demo-shipment-button-new-parcel'}
				>
					{t('demoShipment', tPrefix)}
				</Button>
			</RequiresFeatureToggle>
			<Row className={classes.newQuoteParcelRow}>
				<Col xs={4} className={classes.newQuoteParcelCol}>
					<LoadOrder
						mode='parcel'
						inputRef={loadOrderInput}
						onLoad={async (orderResponse: apiTypes.OrderResponse) => {
							if (orderResponse === null) {
								clearForm()
								return
							}
							if (orderResponse.shipment.payloads.length > 1) {
								sosToast.sendToast({
									type: 'danger',
									header: tString('payload_overload', tPrefix),
								})
								return
							}

							setLoadedOrder(orderResponse)
							const mappedOrder = orderToLoad(orderResponse)
							setPickupAddressForm(mappedOrder.pickUpAddress)
							setDeliveryAddressForm(mappedOrder.deliveryAddress)
							setAccessorialsForm(mappedOrder.accessorials)
							setGoods(mappedOrder.goods as UIGoodsResponse[])

							if (!mappedOrder.packages[0].weight) {
								mappedOrder.packages[0].weight = await loadWeightFromScale(
									printNodeCredentials,
									setPrintNodeScalesList,
									setIsFetchingPrintNodeScales,
									printNodeScale,
								)
							}

							setPackagesForm(mappedOrder.packages)
							setBillToForm(mappedOrder.billTo)
							setOrderPickTicketNumber(mappedOrder.pickTicketNumber)
							setAutoRateCarrier(mappedOrder.autoRateCarrier)
							setAutoRateService(mappedOrder.autoRateService)
						}}
						isLoadingOrder={isLoadingOrder}
						setIsLoadingOrder={setIsLoadingOrder}
						setErrorMessage={(
							orderResponse: IRequestState<apiTypes.OrderResponse>,
						) => {
							setModalTitle('errorLoadingOrder')
							setErrorMessage(orderResponse)
						}}
						isFetchingPrintNodeScales={isFetchingPrintNodeScales}
						orderNumber={orderNumber}
						onChange={(loadedOrderNumber: string) => {
							if (loadedOrderNumber) {
								setOrderNumber(loadedOrderNumber)
							} else if (loadedOrderNumber === null) {
								clearForm()
							}
						}}
					/>
				</Col>

				<Col
					xs={8}
					className={`${classes.newQuoteParcelCol} ${classes.newQuoteParcelLoadOrderInput} ${classes.newQuoteParcelColNoPaddingRight}`}
				>
					<BillToDropdown
						value={billToForm.billToParty}
						onUpdate={(billToParty) => {
							const object = {
								billToParty,
								carrier: billToForm.carrier,
								serviceLevel: billToForm.serviceLevel,
							}
							if (billToParty === 'shipper') {
								if (loadedOrder?.shipment?.autoRateCarrier) {
									object.carrier = loadedOrder.shipment.billTo.carrierName
								}
								if (loadedOrder?.shipment?.autoRateService) {
									object.serviceLevel =
										loadedOrder.shipment.billTo.carrierService
								}
							}
							setBillToForm(Object.assign({}, billToForm, object))
						}}
						isReadOnly={isLoadingOrder}
					/>
					<OverridePrinterLocationDropDown
						onUpdate={(printerLocation) => {
							sosUser.updatePrinterScaleId(printerLocation, true)
						}}
						isReadOnly={isLoadingOrder}
						printers={printerList}
					/>
					<OverrideScaleLocationDropDown
						onUpdate={(scaleLocation) => {
							sosUser.updatePrinterScaleId(scaleLocation, false)

							const scale = printNodeScalesList.find(
								(scale) => scale.computerId === scaleLocation,
							)

							setPrintNodeScale(scale)
						}}
						isReadOnly={isLoadingOrder}
						scales={printNodeScalesList}
					/>
				</Col>
			</Row>
			<Spacer />
			<Row className={classes.newQuoteParcelRow}>
				<Col
					xs={4}
					className={`${classes.newQuoteParcelCol} ${classes.newQuoteParcelColNoPaddingRight}`}
				>
					<Card
						title={t('pickup', tPrefix)}
						color={'darkBlue'}
						fillHeight={true}
						testId={'new-quote-parcel-pickup-address-card'}
					>
						<div data-testid={'new-quote-parcel-pickup-address-form'}>
							<NewQuoteParcelAddress
								addressForm={pickupAddressForm}
								onChangeAddress={setPickupAddressForm}
								stopType={'pickup'}
								isReadOnly={isLoadingOrder}
								setLocationId={setLocationId}
							/>
						</div>
					</Card>
				</Col>
				<Col
					xs={4}
					className={`${classes.newQuoteParcelCol} ${classes.newQuoteParcelColNoPaddingRight}`}
				>
					<Card
						title={t('delivery', tPrefix)}
						color={'darkBlue'}
						fillHeight={true}
						testId={'new-quote-parcel-delivery-address-card'}
					>
						<div data-testid={'new-quote-parcel-delivery-address-form'}>
							<NewQuoteParcelAddress
								addressForm={deliveryAddressForm}
								onChangeAddress={setDeliveryAddressForm}
								stopType={'delivery'}
								isReadOnly={isLoadingOrder}
								setLocationId={setLocationId}
							/>
						</div>
					</Card>
				</Col>
				<Col xs={4} className={classes.newQuoteParcelCol}>
					<NewQuoteAccessorial
						accesorialForm={accessorialsForm}
						setAccessorialsForm={setAccessorialsForm}
						isReadOnly={isLoadingOrder}
					/>
				</Col>
			</Row>
			<Spacer />
			<Row className={classes.newQuoteParcelRow}>
				<Col>
					<NewQuotePackages
						packagesForm={packagesForm}
						setPackagesForm={setPackagesForm}
						isReadOnly={isLoadingOrder}
						overridePrintNodeScale={printNodeScale}
						printNodeScalesList={printNodeScalesList}
						orderLoaded={!!loadedOrder}
						printNodeCredentials={printNodeCredentials}
						setPrintNodeScalesList={setPrintNodeScalesList}
						setIsFetchingPrintNodeScales={setIsFetchingPrintNodeScales}
						isFetchingPrintNodeScales={isFetchingPrintNodeScales}
						bookWithButton={bookWithButton}
						getRatesButton={getRatesButton}
						goods={goods}
						updateGoods={(id: string, packageId: string) => {
							const _goods = [...goods];
							setGoods([])
							const index = _goods.findIndex((good) => good.id === id)
							_goods[index].packageId = packageId
							setGoods(_goods)
						}}
						splitGood={(id, count) => {
							const _goods = [...goods];
							setGoods([])
							const index = _goods.findIndex((good) => good.id === id)
							const goodCount = count
							const newGood = Object.assign({}, _goods[index], {
								count: _goods[index].count - goodCount,
								customsValue: _goods[index].unitValue * (_goods[index].count - goodCount),
								id: (Math.random() * 100000 + Date.now()).toString()
							})
							_goods[index].count = goodCount
							_goods[index].customsValue = _goods[index].unitValue * goodCount
							_goods.push(newGood)
							setGoods(_goods.sort((a, b) => a.description.localeCompare(b.description)))
						}}
						isIPD={autoRateService === 'International Priority DirectDistribution'}
					/>
				</Col>
			</Row>
			<Spacer />
			<Row className={classes.newQuoteParcelRow}>
				<Col>
					{billToForm.billToParty !== 'shipper' && (
						<BillToCard
							billTo={billToForm.billToParty}
							form={billToForm}
							onUpdate={setBillToForm}
							isReadOnly={isLoadingOrder}
						/>
					)}
				</Col>
			</Row>
			<Row className={classes.newQuoteParcelRow + ' bootstrap-wrapper'}>
				<Col>
					<div className='d-flex flex-row-reverse bd-highlight'>
						{shipperParcelConfig.allowRateShopping && (
							<OkCancelButtons
								refProp={getRatesButton}
								ok={tString('getRates', tPrefix)}
								onOk={() => shipmentAction()}
								okColor={'green'}
								isValid={!isLoadingOrder}
								isSpinning={isLoading}
								okTestId={'new-quote-parcel-get-quotes'}
							/>
						)}
						{shipperParcelConfig.automaticParcelBooking && (
							<BookWithPreferredRoutingButton
								refProp={bookWithButton}
								autoRateCarrier={autoRateCarrier}
								autoRateService={autoRateService}
								// onOk={getQuotesWithPreferredProviderService}
								onOk={() => shipmentAction(true)}
								isLoadingOrder={isLoadingOrder}
								isLoading={isLoading}
								tPrefix={tPrefix}
							/>
						)}
					</div>
				</Col>
			</Row>
			<Modal
				title={tString(modalTitle, tPrefix)}
				content={() => (
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							width: 500,
						}}
					>
						<Icon
							icon={solidIcons.faExclamationTriangle}
							className={classes.icon}
							color='yellow'
						/>
						<div style={{ padding: '20px 10px 30px' }}>
							{errorMessage?.error}
						</div>

						<div
							style={{
								display: 'flex',
								justifyContent: 'flex-end',
								alignItems: 'flex-end',
								width: '100%',
							}}
						>
							<OkCancelButtons
								isValid={true}
								onOk={() => {
									setErrorMessage(null)
									setModalTitle('Error Loading Order')
								}}
								okColor={'blue'}
								okTestId={'new-quote-parcel-error-modal-ok'}
							/>
						</div>
					</div>
				)}
				isOpen={errorMessage !== null}
			/>
			<Debug data={loadedOrder} label={'test'} />
		</div>
	)
}

