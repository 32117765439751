import { apiTypes } from 'ui/api'
import { toFloat } from 'ui/lib/numbers/toNumber'
import { applyDefaultMarkupLogic } from 'ui/pages/shipment-profile/broker/functions'

export interface IBrokerPrices {
	sellPrice: number
	buyPrice: number
	markupPercent: number
	profit: number
}

export const calculateBrokerPrices = (
	inputPrices: Partial<IBrokerPrices>,
	defaultMarkupLogic?: apiTypes.MarkupLogic,
): IBrokerPrices => {
	const buyPrice = inputPrices.buyPrice
	let sellPrice = inputPrices.sellPrice
	const markupPercent = inputPrices.markupPercent
	const profit = inputPrices.profit
	if (buyPrice && sellPrice) {
		return {
			buyPrice,
			sellPrice,
			markupPercent: calculateMarginPercent(buyPrice, sellPrice),
			profit: sellPrice - buyPrice,
		}
	} else if (buyPrice && markupPercent) {
		sellPrice = buyPrice * (1 + toFloat(markupPercent) / 100)
		return {
			buyPrice,
			sellPrice,
			markupPercent,
			profit: sellPrice - buyPrice,
		}
	} else if (buyPrice && profit) {
		sellPrice = buyPrice + profit
		return {
			buyPrice,
			sellPrice,
			markupPercent: calculateMarginPercent(buyPrice, sellPrice),
			profit,
		}
	} else if (buyPrice && defaultMarkupLogic) {
		const calculatedSellPrice = applyDefaultMarkupLogic(
			buyPrice,
			defaultMarkupLogic,
		)
		return {
			buyPrice,
			sellPrice: calculatedSellPrice,
			markupPercent: calculateMarginPercent(buyPrice, calculatedSellPrice),
			profit: calculatedSellPrice - buyPrice,
		}
	} else {
		return {
			buyPrice,
			sellPrice,
			markupPercent,
			profit,
		}
	}
}

export function calculateMarginPercent(
	buyPrice: number,
	sellPrice: number,
): number {
	return Math.round(((sellPrice - buyPrice) / buyPrice) * 100)
}
