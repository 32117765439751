import { ISelectOptions } from 'ui/components/common/select'
import { l } from 'ui/lib/lodashImports'
import { apiTypes } from 'ui/api'

export interface EquipmentTypeMetadata {
	label: string
	hasTarpAccessorial?: boolean
	hasRefridgeratedAccessorial?: boolean
}
export type EquipmentType = apiTypes.ShipmentRequest['equipmentType']
// | 'AC'
// | 'BT'
// | 'CN'
// | 'C'
// | 'CI'
// | 'CR'
// | 'CV'
// | 'DD'
// | 'LA'
// | 'DT'
// | 'F'
// | 'FA'
// | 'FN'
// | 'F2'
// | 'FZ'
// | 'FH'
// | 'MX'
// | 'FD'
// | 'FS'
// | 'FT'
// | 'FM'
// | 'FO'
// | 'FC'
// | 'FR'
// | 'HB'
// | 'IR'
// | 'LB'
// | 'LR'
// | 'LO'
// | 'MV'
// | 'NU'
// | 'PO'
// | 'R'
// | 'RA'
// | 'R2'
// | 'RZ'
// | 'RN'
// | 'RL'
// | 'RV'
// | 'RM'
// | 'RP'
// | 'RG'
// | 'SD'
// | 'SR'
// | 'SN'
// | 'SB'
// | 'ST'
// | 'SW'
// | 'TA'
// | 'TN'
// | 'TS'
// | 'TT'
// | 'V'
// | 'VA'
// | 'VS'
// | 'V2'
// | 'VZ'
// | 'VH'
// | 'VI'
// | 'VN'
// | 'VG'
// | 'VL'
// | 'OT'
// | 'VF'
// | 'VT'
// | 'VR'
// | 'VB'
// | 'V3'
// | 'VV'
// | 'VC'
// | 'VM'
// | 'VW'
// | 'VP'
// export const equipmentTypes:EquipmentType_[] = ['AC', 'BT', 'CN']
// export const flatbedEquipmentTypes: EquipmentType_[] = ['AC']

export const newQuoteEquipmentTypesMetadata: {
	[k in EquipmentType | 'OTHER']: EquipmentTypeMetadata
} = {
	AC: { label: 'Auto Carrier (AC)' },
	BT: { label: 'B-Train (BT)' },
	CN: { label: 'Conestoga (CN)' },
	C: { label: 'Container (C)' },
	CI: { label: 'Container Insulated (CI)' },
	CR: { label: 'Container Refrigerated (CR)' },
	CV: { label: 'Conveyor (CV)' },
	DD: { label: 'Double Drop (DD)' },
	LA: { label: 'Drop Deck Landoll (LA)' },
	DT: { label: 'Dump Trailer (DT)' },
	F: { label: 'Flatbed (F)', hasTarpAccessorial: true },
	FA: { label: 'Flatbed Air-Ride (FA)', hasTarpAccessorial: true },
	FN: { label: 'Flatbed Conestoga (FN)', hasTarpAccessorial: true },
	F2: { label: 'Flatbed Double (F2)', hasTarpAccessorial: true },
	FZ: { label: 'Flatbed HazMat (FZ)', hasTarpAccessorial: true },
	FH: { label: 'Flatbed Hotshot (FH)', hasTarpAccessorial: true },
	MX: { label: 'Flatbed Maxi (MX)', hasTarpAccessorial: true },
	FD: { label: 'Flatbed or Step Deck (FD)', hasTarpAccessorial: true },
	FS: { label: 'Flatbed w/Sides (FS)', hasTarpAccessorial: true },
	FM: { label: 'Flatbed w/Team (FM)', hasTarpAccessorial: true },
	FO: { label: 'Flatbed, Over Dimension (FO)', hasTarpAccessorial: true },
	FC: { label: 'Flatbed w/Chains (FC)', hasTarpAccessorial: true },
	FR: {
		label: 'Flatbed/Van/Reefer (FR)',
		hasTarpAccessorial: true,
		hasRefridgeratedAccessorial: true,
	},
	FT: {
		label: 'Flatbed w/Tarp (FT)',
		hasTarpAccessorial: true,
	},
	HB: { label: 'Hopper Bottom (HB)' },
	IR: {
		label: 'Insulated Van or Reefer (IR)',
		hasRefridgeratedAccessorial: true,
	},
	LB: { label: 'Lowboy (LB)' },
	LR: { label: 'Lowboy or RGN (LR)' },
	LO: { label: 'Lowboy, Over Dimension (LO)' },
	MV: { label: 'Moving Van (MV)' },
	NU: { label: 'Pneumatic (NU)' },
	PO: { label: 'Power Only (PO)' },
	R: { label: 'Reefer (R)', hasRefridgeratedAccessorial: true },
	RA: { label: 'Reefer Air-Ride (RA)', hasRefridgeratedAccessorial: true },
	R2: { label: 'Reefer Double (R2)', hasRefridgeratedAccessorial: true },
	RZ: { label: 'Reefer HazMat (RZ)', hasRefridgeratedAccessorial: true },
	RN: { label: 'Reefer Intermodal (RN)', hasRefridgeratedAccessorial: true },
	RL: { label: 'Reefer Logistics (RL)', hasRefridgeratedAccessorial: true },
	RV: { label: 'Reefer or Vented Van (RV)', hasRefridgeratedAccessorial: true },
	RM: { label: 'Reefer w/Team (RM)', hasRefridgeratedAccessorial: true },
	RP: {
		label: 'Reefer w/Pallet Exchange (RP)',
		hasRefridgeratedAccessorial: true,
	},
	RG: { label: 'Removable Gooseneck (RG)' },
	SD: { label: 'Step Deck (SD)' },
	SR: { label: 'Step Deck or RGN (SR)' },
	SN: { label: 'Step Deck Conestoga (SN)' },
	SB: { label: 'Straight Box Truck (SB)' },
	ST: { label: 'Stretch Trailer (ST)' },
	SW: { label: 'Swing Door (SW)' },
	TA: { label: 'Tanker Aluminum (TA)' },
	TN: { label: 'Tanker Intermodal (TN)' },
	TS: { label: 'Tanker Steel (TS)' },
	TT: { label: 'Truck and Trailer (TT)' },
	V: { label: 'Van (V)' },
	VA: { label: 'Van Air-Ride (VA)' },
	VS: { label: 'Van Conestoga (VS)' },
	V2: { label: 'Van Double (V2)' },
	VZ: { label: 'Van HazMat (VZ)' },
	VH: { label: 'Van Hotshot (VH)' },
	VI: { label: 'Van Insulated (VI)' },
	VN: { label: 'Van Intermodal (VN)' },
	VG: { label: 'Van Lift-Gate (VG)' },
	VL: { label: 'Van Logistics (VL)' },
	OT: { label: 'Van Open-Top (OT)' },
	VF: { label: 'Van or Flatbed (VF)', hasTarpAccessorial: true },
	VT: { label: 'Van or Flatbed w/Tarp (VT)', hasTarpAccessorial: true },
	VR: { label: 'Van or Reefer (VR)', hasRefridgeratedAccessorial: true },
	VB: { label: 'Van Roller Bed (VB)' },
	V3: { label: 'Van Triple (V3)' },
	VV: { label: 'Van Vented (VV)' },
	VC: { label: 'Van w/Curtains (VC)' },
	VM: { label: 'Van w/Team (VM)' },
	VW: { label: 'Van w/Blanket Wrap (VW)' },
	VP: { label: 'Van w/Pallet Exchange (VP)' },
	OTHER: { label: 'See Special Instructions' },
}

export const equipmentTypeOptions: ISelectOptions[] = l.map(
	newQuoteEquipmentTypesMetadata,
	(c, k) => ({ label: c.label, value: k }),
)
export const equipmentTypes: EquipmentType[] = l.keys(
	newQuoteEquipmentTypesMetadata,
) as EquipmentType[]
