import React, { useState } from 'react'
import { sosToast } from 'common/components/toast'
import { apiBroker } from 'ui/api'
import { FC } from 'ui/FunctionalComponent'
import { Modal } from 'ui/components/common/modal'
import { OkCancelButtons } from 'ui/components/common/okCancelButtons'
import { FormStackedPillSwitch, IFormData } from 'ui/components/form'
import { t, tString } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight'
import { Spacer } from 'ui/components/layout/spacer'
import { l } from 'ui/lib/lodashImports'
import * as classes from './BookCarrierModal.module.scss'
import { Center } from 'ui/components/layout/center'
import { sosShipmentProfileShipper } from 'ui/pages/shipment-profile/shipper'
import { RateBookingDetails } from 'ui/pages/shipment-profile/shared/functions/rateBookingDetailsTypes'
import { sosShipmentProfileBroker } from 'ui/pages/shipment-profile/broker'
import { sosRouter } from 'ui/components/common/router'

interface BookingCarrierForm {
	bookingOption: 'electronically' | 'manually'
}

const defaultBookCarrierForm: BookingCarrierForm = {
	bookingOption: 'electronically',
}

const spacerHeight = '10px'
const apiCallName = 'booking'

export const BookCarrierModal: FC = (props: {
	isModalOpen: boolean
	onModalClose: () => void
	tPrefix: string
	rateBookingDetails: RateBookingDetails
	setIsNotifyProviderModalOpen: React.Dispatch<React.SetStateAction<boolean>>
	setIsBooking: React.Dispatch<React.SetStateAction<boolean>>
}) => {
	const {
		isModalOpen,
		onModalClose,
		tPrefix,
		rateBookingDetails,
		setIsNotifyProviderModalOpen,
		setIsBooking,
	} = props

	const tPrefixModal = tPrefix + '.modalBookCarrier'

	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [bookingCarrierSaveForm, setBookingCarrierSaveForm] = useState<
		BookingCarrierForm
	>(defaultBookCarrierForm)

	const newQuoteParcelLink = '/shipments-v3/new-quote/parcel/'

	const onBook = async (): Promise<void> => {
		setIsLoading(true)
		setIsBooking(true)

		const method =
			bookingCarrierSaveForm.bookingOption === 'electronically'
				? 'api'
				: 'manual'

		if (rateBookingDetails.isBroker) {
			sosShipmentProfileBroker.setRunningApiCall(apiCallName)

			const bookResult = await apiBroker.brokerBook(
				() => {},
				rateBookingDetails.shipmentId,
				rateBookingDetails.buyRateId,
				method,
			)

			if (bookResult.warningsHeader) {
				sosShipmentProfileBroker.toggleBookingWarningModal(
					true,
					bookResult.data,
					bookResult.warningsHeader,
					method,
				)
				onClose()
			} else if (bookResult.data) {
				sosShipmentProfileBroker.setShipmentReference(bookResult.data)

				if (method === 'manual') {
					setIsNotifyProviderModalOpen(true)
				}
				onClose()
			} else if (bookResult.error) {
				sosToast.sendApiErrorResponseToast(
					bookResult,
					tString(`${apiCallName}Error`, tPrefixModal),
				)
				// console.log(bookResult.error)
			}

			sosShipmentProfileBroker.setRunningApiCall(apiCallName, false)
		} else {
			const bookingResponse = await sosShipmentProfileShipper.bookShipment(
				rateBookingDetails.shipmentId,
				rateBookingDetails.rateId,
				method,
			)

			if (bookingResponse.data) {
				if (method === 'manual') {
					setIsNotifyProviderModalOpen(true)
				} else {
					if (bookingResponse.data.bookedRate?.providerName) {
						sosRouter.navigate(newQuoteParcelLink)
					}
				}

				onClose()
			}
		}

		setIsLoading(false)
	}

	const onClose = (): void => {
		onModalClose()
	}

	const bookingForm: IFormData<BookingCarrierForm> = {
		form: bookingCarrierSaveForm,
		metadata: {
			bookingOption: {
				options: [
					{
						value: 'electronically',
						label: tString('electronically', tPrefixModal),
					},
					{ value: 'manually', label: tString('manually', tPrefixModal) },
				],
			},
		},
		onUpdateForm: (field: string, value: any) => {
			const updatedFormData = l.cloneDeep(bookingCarrierSaveForm)

			updatedFormData[field] = value
			setBookingCarrierSaveForm(updatedFormData)
		},
		tPrefix,
	}

	return (
		<Modal
			content={() => (
				<div
					className={classes.bookCarrierModal}
					data-testid={'book-carrier-modal'}
				>
					<Spacer height={spacerHeight} />
					<Center>{t('howDoYouWantToBook', tPrefixModal)}</Center>
					<Spacer height={spacerHeight} />
					<FormStackedPillSwitch
						formData={bookingForm}
						field={'bookingOption'}
						hideOptional={true}
						className={classes.bookCarrierFormStackedPillSwitch}
					/>
					<Spacer height={spacerHeight} />
					<AlignRight>
						<OkCancelButtons
							ok={t('book', tPrefixModal)}
							onOk={onBook}
							okColor='green'
							cancel={t('cancel', tPrefixModal)}
							onCancel={onClose}
							isSpinning={isLoading}
							isValid={true}
							okTestId={'book-carrier-modal-book-button'}
							cancelTestId={'book-carrier-modal-cancel-button'}
						/>
					</AlignRight>
				</div>
			)}
			isOpen={isModalOpen}
			onModalClose={onClose}
			closeModalX={true}
			title={t('confirmBooking', tPrefixModal)}
		/>
	)
}
