import { sosToast } from 'common/components/toast'
import { DateTime } from 'luxon'
import { apiBroker, apiProviders, apiShipments, apiTypes } from 'ui/api'
import { IRequestState } from 'ui/api/requestState'
import { sosRouter } from 'ui/components/common/router'
import { FormUpdate, IFormData } from 'ui/components/form'
import { tProviderName } from 'ui/components/i18n/commonTranslations'
import { tString } from 'ui/components/i18n/i18n'
import { IAddress } from 'ui/components/shared/address/IAddress'
import { fireAndForget } from 'ui/lib/async/fireAndForget'
import { addBusinessDays } from 'ui/lib/dates/businessDays'
import { _idx } from 'ui/lib/_idx'
import { jestFix } from 'ui/lib/jestTools/jestFix'
import { l } from 'ui/lib/lodashImports'
import { toCents, toDollarsFormatted, toInteger } from 'ui/lib/numbers/toNumber'
import {
	createDefaultRate,
	createDefaultShipment,
	shipmentUtils,
} from 'ui/lib/shipments'
import { getAllAccessorialsFromShipment } from 'ui/lib/shipments/shipmentUtils'
import { createSos2 } from 'ui/lib/state/sos2/sos2'
import { inJestUnitTest } from 'ui/lib/testing/inJestUnitTest'
import { router } from 'ui/state/router'
import { tPrefixMakeOffer } from './OfferDetailsCard'
import {
	getAccessorialsTotal,
	getFuelTotal,
	getLinehaulCostTotal,
	getTotal,
} from './sosMakeOfferPage_offerTotals'

export interface IProviderInformation {
	providerId?: string
	company?: string
	name?: string
	phone?: string
	cellPhone?: string
	email?: string
	mcNumber?: string
	usDotNumber?: string
	scac?: string
	carrier?: boolean
	freightForwarder?: boolean
	broker?: boolean
	notifyByEmail?: string
	fleet?: string
	otherCarrier?: string
	carrierQuoteNumber?: string
	truckload?: boolean
	ltlVolume?: boolean
	billTo?: {
		company?: string
		street1?: string
		street2?: string
		city?: string
		state: string
		zip?: string
		country?: string
	}
}

export interface IOfferInformation {
	transitDays?: string
	linehaulCost?: string
	linehaulCostType?: string
	fuelCost?: string
	fuelCostType?: string
	currencyCode?: string
	accessorialCharges?: apiTypes.AccessorialsCost
	otherCharge?: string
	estimatedMilesDistance?: string
	priceExpires?: string
	message?: string
	quoteNumber?: string
	carrierName?: string
}

export interface IStateManageOfferPage {
	offerId?: string
	savedRate?: apiTypes.RateResponse
	shipper: { name: string; email: string }
	shipment: apiTypes.ShipmentResponse
	showShipWith: boolean
	shipWith: 'fleet' | 'otherCarrier'
	shipmentIs: 'truckload' | 'ltlVolume'
	providerInformation: IProviderInformation
	preEditProviderInformation: IProviderInformation
	offerInformation: IOfferInformation
	connection: apiTypes.ConnectionResponse
	preEditConnection: apiTypes.ConnectionResponse
	notifyByEmail: boolean
	acceptModalOpen: boolean
	declineModalOpen: boolean
	successModalOpen: boolean
	failureModalOpen: boolean
	isMakingOffer: boolean
	isUpdatingOffer: boolean
	errorMessage: string
	editingConnection: boolean
}

export const sos = createSos2<IStateManageOfferPage>('makeOffer', 1, {
	offerId: { default: '' },
	savedRate: { default: jestFix(createDefaultRate) },
	shipper: { default: { name: '', email: '' } },
	shipment: { default: jestFix(createDefaultShipment) },
	showShipWith: { default: false },
	shipWith: { default: null },
	shipmentIs: { default: null },
	providerInformation: {
		default: { billTo: { city: '', state: '', zip: '' } },
	},
	preEditProviderInformation: {
		default: { billTo: { city: '', state: '', zip: '' } },
	},
	offerInformation: {
		default: {
			linehaulCostType: 'flat',
			fuelCostType: 'included',
			currencyCode: 'USD',
			accessorialCharges: {},
		},
	},
	connection: { default: null },
	preEditConnection: { default: null },
	notifyByEmail: { default: false },
	acceptModalOpen: { default: false },
	declineModalOpen: { default: false },
	successModalOpen: { default: false },
	failureModalOpen: { default: false },
	isMakingOffer: { default: false },
	isUpdatingOffer: { default: false },
	errorMessage: { default: null },
	editingConnection: { default: false },
})

export const navigateToMakeOfferPage = async (
	shipmentId: string,
	offerId?: string,
	isBroker?: boolean,
): Promise<void> => {
	let url = '/shipments-v3'
	if (isBroker) {
		url += '/brokers'
	}
	if (offerId) {
		url += `/make-offer/${shipmentId}/${offerId}`
	} else {
		url += `/log-offer/${shipmentId}`
	}
	sosRouter.navigate(url)
}

export const getOffer = async (
	shipmentId,
	offerId,
	isBroker: boolean,
): Promise<void> => {
	let spotQuoteShipmentResponse: IRequestState<
		apiTypes.ShipmentResponse | apiTypes.BrokerShipmentResponse
	>

	const rateOfferStatusBeforeFetch = sos.getState().savedRate?.offerStatus

	if (inJestUnitTest()) {
		return
	}
	if (isBroker) {
		spotQuoteShipmentResponse = await apiBroker.getSpotQuoteShipment(
			(rs: IRequestState<apiTypes.ShipmentResponse>) => {},
			shipmentId,
			offerId,
		)
	} else {
		spotQuoteShipmentResponse = await apiShipments.getSpotQuoteShipment(
			(rs: IRequestState<apiTypes.ShipmentResponse>) => {},
			shipmentId,
			offerId,
		)
	}

	if (spotQuoteShipmentResponse.error) {
		sosToast.sendApiErrorResponseToast(
			spotQuoteShipmentResponse.error,
			tString('fetchShipmentError', tPrefixMakeOffer),
		)
		return
	}

	const spotQuoteShipment = spotQuoteShipmentResponse.data

	const savedRate = l.find(
		spotQuoteShipment.rates,
		(rate) => rate.offerId === offerId,
	)

	sos.change((ds) => {
		ds.shipment = spotQuoteShipment
		ds.offerId = offerId
		ds.savedRate = savedRate
	})

	await getConnection(spotQuoteShipment, offerId)
	if (rateOfferStatusBeforeFetch !== savedRate?.offerStatus) {
		await fillInFormDefaults(savedRate)
	}
}

export const getConnection = async (
	shipment: apiTypes.ShipmentResponse,
	offerId: string,
): Promise<IRequestState<apiTypes.ConnectionResponse>> => {
	if (!shipment.rates) {
		return
	}
	const connectionRate: apiTypes.RateResponse = l.find(
		shipment.rates,
		(rate) => rate.offerId === offerId,
	)

	const connection = await apiShipments.getConnection(
		(rs: IRequestState<apiTypes.ConnectionResponse>) => {},
		_idx(() => connectionRate.connectionId), // connectionId
	)

	sos.change((ds) => {
		ds.connection = connection.data
	})
	return connection
}

export const getShipment = async (
	shipmentId: string,
	isBroker: boolean,
	statusOnly: boolean,
): Promise<void> => {
	let shipment
	if (isBroker) {
		shipment = await apiBroker.fetchBrokerShipment(
			(rs: IRequestState<apiTypes.ShipmentResponse>) => {},
			shipmentId,
		)
	} else {
		shipment = await apiShipments.fetchShipment(
			(rs: IRequestState<apiTypes.ShipmentResponse>) => {},
			shipmentId,
		)
	}

	if (shipment?.data) {
		sos.change((ds) => {
			if (statusOnly) {
				ds.shipment.shipmentStatus = shipment.data.shipmentStatus
			} else {
				ds.shipment = shipment.data
				ds.offerInformation.estimatedMilesDistance =
					shipment.data.estimatedDistance
			}
		})
	} else {
		sosToast.sendApiErrorResponseToast(
			shipment.error,
			tString('fetchShipmentError', tPrefixMakeOffer),
		)
	}
}

export const setProvider = (provider: apiTypes.ProviderResponse): void => {
	sos.change((ds) => {
		ds.providerInformation.company =
			tProviderName(provider?.providerName) || provider?.name
		ds.providerInformation.scac = provider.scac
		ds.providerInformation.providerId = provider.id
	})
}

export const setMcNumber = (mcNumber: string): void => {
	sos.change((ds) => {
		ds.providerInformation.mcNumber = mcNumber
	})
}

export const fillInProviderFormDefaults = (
	provider: apiTypes.ProviderResponse,
	connection?: apiTypes.ConnectionResponse,
	savedRate?: apiTypes.RateResponse,
): void => {
	const billToAddress: apiTypes.Address =
		savedRate?.billTo || connection?.billTo || provider?.billingAddress
	sos.change((ds) => {
		ds.providerInformation = {
			providerId: provider?.id,
			company:
				connection?.companyName ||
				tProviderName(provider?.providerName) ||
				provider?.name,
			name: connection?.contactName,
			phone: connection?.phone,
			cellPhone: connection?.cellPhone,
			email: connection?.email,
			mcNumber: connection?.mc,
			usDotNumber: connection?.usdot,
			scac: connection?.scac || provider?.scac,
			carrier: connection?.connectionType?.isCarrier,
			freightForwarder: connection?.connectionType?.isFreightForwarder,
			broker: connection?.connectionType?.isBroker,
			notifyByEmail: '',
			fleet: '',
			otherCarrier: '',
			carrierQuoteNumber: '',
			truckload: connection?.freightSize?.truckload,
			ltlVolume: connection?.freightSize?.volume,
			billTo: {
				company: billToAddress ? billToAddress.company : '',
				street1: billToAddress ? billToAddress.street1 : '',
				street2: billToAddress ? billToAddress.street2 : '',
				city: billToAddress ? billToAddress.city : '',
				state: billToAddress ? billToAddress.state : '',
				zip: billToAddress ? billToAddress.zip : '',
				country: billToAddress ? billToAddress.country : '',
			},
		}

		if (_idx(() => connection.connectionType.isBroker)) {
			ds.shipWith = 'otherCarrier'
		}

		if (_idx(() => connection.freightSize.truckload)) {
			ds.shipmentIs = 'truckload'
		} else if (_idx(() => connection.freightSize.volume)) {
			ds.shipmentIs = 'ltlVolume'
		}
	})
}

export const fillInFormDefaults = async (
	savedRate: apiTypes.RateResponse,
): Promise<void> => {
	sos.change((ds) => {
		ds.offerInformation = {
			transitDays: _idx(() => savedRate.transit.toString()) || '',
			linehaulCost:
				toDollarsFormatted(_idx(() => savedRate.costBreakdown.grossRate)) || '',
			linehaulCostType: 'flat',
			fuelCost:
				toDollarsFormatted(_idx(() => savedRate.costBreakdown.fuel)) || '',
			fuelCostType: 'included',
			currencyCode: savedRate.currencyCode,
			accessorialCharges: null,
			otherCharge:
				toDollarsFormatted(_idx(() => savedRate.costBreakdown.other)) || '',
			estimatedMilesDistance: ds.shipment.estimatedDistance.toString(),
			priceExpires: '', // savedRate.expirationDate, // TODO update when expirationDate is put on RateResponse
			message: savedRate.providerNote,
			quoteNumber: '',
			carrierName: savedRate.providerName,
		}
	})

	const providerId = savedRate.providerId
	const { connection } = sos.getState()
	if (providerId || connection) {
		const provider: apiTypes.ProviderResponse =
			providerId &&
			(
				await apiProviders.getProvider(
					(rs: IRequestState<apiTypes.ProviderResponse>) => {},
					providerId,
				)
			).data

		fillInProviderFormDefaults(provider, connection, savedRate)
	}
}

export const setShipWith = (shipWith: 'fleet' | 'otherCarrier'): void => {
	sos.change((ds) => {
		ds.shipWith = shipWith
	})
}

export const setShipmentIs = (shipmentIs: 'truckload' | 'ltlVolume'): void => {
	sos.change((ds) => {
		ds.shipmentIs = shipmentIs
	})
}

export const getPickupAccessorials = (shipment): string => {
	const accessorials = shipmentUtils.getPickupAccessorialsFromShipment(shipment)
	return accessorials
		.map((accessorial) => tString(accessorial, tPrefixMakeOffer))
		.join(', ')
}

export const getDeliveryAccessorials = (shipment): string => {
	const accessorials = shipmentUtils.getDeliveryAccessorialsFromShipment(
		shipment,
	)
	return accessorials
		.map((accessorial) => tString(accessorial, tPrefixMakeOffer))
		.join(', ')
}

export const getQuoteTotalCents = (
	offerInformation: IOfferInformation,
): number => {
	return getTotal(offerInformation)
}

export const getLinehaulTotalCents = (
	offerInformation: IOfferInformation,
): number => {
	return getLinehaulCostTotal(offerInformation)
}

export const getFuelTotalCents = (
	offerInformation: IOfferInformation,
): number => {
	return getFuelTotal(offerInformation)
}

export const getAccessorialsTotalWithOtherCents = (
	offerInformation: IOfferInformation,
): number => {
	const accessorialsTotal = getAccessorialsTotal(offerInformation)
	const other = toCents(offerInformation.otherCharge)
	return accessorialsTotal + other
}

export function updateProviderInformationForm(key: string, newVal: any): void {
	sos.change((ds) => {
		ds.providerInformation[key] = newVal
	})
}

export function updateConnectionInformationForm(
	key: string,
	newVal: any,
): void {
	sos.change((ds) => {
		if (ds.connection?.id) {
			ds.connection[key] = newVal
		}
	})
}

export function updateProviderAndConnection(key: string, newVal: any): void {
	updateProviderInformationForm(key, newVal)
	updateConnectionInformationForm(key, newVal)
}

export function updateProviderInformationBillToForm(
	key: keyof IAddress,
	newVal: string,
): void {
	sos.change((ds) => {
		ds.providerInformation.billTo[key] = newVal
	})
}

export function updateOfferInformationForm(
	key: keyof (IOfferInformation & IProviderInformation),
	newVal: string,
): void {
	sos.change((ds) => {
		ds.offerInformation[key] = newVal
		if (key === 'fuelCostType' && newVal === 'included') {
			ds.offerInformation.fuelCost = ''
		}
	})
}

export function getAccessorialFormData(
	shipment: apiTypes.ShipmentResponse,
): IFormData<any> {
	const accessorials = getAllAccessorialsFromShipment(shipment)
	const form = {}
	const metadata = {}
	const { offerInformation, savedRate } = sos.getState()
	l.forEach(accessorials, (accessorial) => {
		form[accessorial] =
			_idx(() => offerInformation.accessorialCharges[accessorial]) || 0
		metadata[accessorial] = {
			required: false,
			readOnly: savedRate.offerStatus !== 'active',
		}
	})

	return {
		form,
		metadata,
		onUpdateForm: updateAccessorialInformation as FormUpdate<any>,
		tPrefix: tPrefixMakeOffer,
	}
}

export function updateAccessorialInformation(
	key: keyof IOfferInformation,
	newVal: string,
): void {
	sos.change((ds) => {
		ds.offerInformation.accessorialCharges[key] = newVal
	})
}

function createRate(loggedOffer: boolean): apiTypes.RateRequest {
	const {
		shipment,
		providerInformation,
		offerInformation,
		shipmentIs,
		offerId,
		shipWith,
	} = sos.getState()
	const costTotal = getTotal(offerInformation)

	const costBreakdown: apiTypes.CostBreakdown = {
		grossRate: getLinehaulCostTotal(offerInformation),
		fuel: getFuelTotal(offerInformation),
		other: toCents(offerInformation.otherCharge),
		accessorials: offerInformation.accessorialCharges,
	}
	const transit = toInteger(offerInformation.transitDays)
	const rate: apiTypes.RateRequest = {
		offerStatus: 'offerMade',
		providerName: providerInformation.company as any, // since providerName is only assignable to 'Fedex' | 'UPS' | 'USPS'
		scac: providerInformation.scac,
		serviceLevel: null,
		pickup: shipment.pickupDate,
		transit: transit,
		delivery: addBusinessDays(
			shipment.pickupDate || DateTime.local().toISO(),
			transit,
		),
		currencyCode: offerInformation.currencyCode as any, // since currencyCode is only assignable to 'USD' | 'CAD'
		costTotal,
		costBreakdown,
		quoteType: loggedOffer ? 'loggedOffer' : 'spotQuote',
		method: shipmentIs === 'ltlVolume' ? 'volume' : 'truckload',
		providerNote: offerInformation.message,
		offerId,
		carrier: providerInformation.otherCarrier,
		providerId: providerInformation.providerId,
		mcNumber: providerInformation.mcNumber,
		quoteNumber: offerInformation.quoteNumber,
	}

	if (
		providerInformation.broker &&
		shipWith === 'otherCarrier' &&
		providerInformation.billTo.city &&
		providerInformation.billTo.state &&
		providerInformation.billTo.zip
	) {
		rate.billTo = {
			company: providerInformation.company,
			paymentType: 'thirdParty',
			...providerInformation.billTo,
		}
	}
	return rate
}

export async function makeOffer(isBroker?: boolean): Promise<void> {
	const { offerId, shipment } = sos.getState()
	sos.change((ds) => {
		ds.isMakingOffer = true
	})
	const rate: apiTypes.RateRequest = createRate(false)
	let result: IRequestState<any>
	if (isBroker) {
		result = await apiBroker.updateConnectionOffer(
			(rs: IRequestState<void>) => {},
			shipment.id,
			rate,
		)
	} else {
		result = await apiShipments.spotQuoteRate(
			(rs: IRequestState<void>) => {},
			shipment.id,
			offerId,
			rate,
		)
	}

	if (result.data) {
		sos.change((ds) => {
			ds.savedRate.offerStatus = 'offerMade'
			ds.isMakingOffer = false
			ds.errorMessage = null
		})
	} else if (result.error) {
		sos.change((ds) => {
			ds.isMakingOffer = false
			ds.failureModalOpen = true
			ds.errorMessage = result.error
		})
	}
}

export async function logOffer(isBroker?: boolean): Promise<void> {
	sos.change((ds) => {
		ds.isMakingOffer = true
	})
	const { shipment } = sos.getState()

	const rate: apiTypes.RateRequest = createRate(true)

	if (isBroker) {
		await apiBroker.logOfferRate(
			(rs: IRequestState<void>) => {},
			shipment.id,
			rate,
		)
	} else {
		await apiShipments.logOfferRate(
			(rs: IRequestState<void>) => {},
			shipment.id,
			rate,
		)
	}
	sos.change((ds) => {
		ds.isMakingOffer = false
	})
	router.navTo('/shipments-v3/shipment-profile/' + shipment.id)
}

export function cancelLogOffer(): void {
	const { shipment } = sos.getState()
	router.navTo('/shipments-v3/shipment-profile/' + shipment.id)
}

export function toggleModal(
	modal: 'accept' | 'decline' | 'success' | 'failure',
): void {
	sos.change((ds) => {
		if (modal === 'accept') {
			ds.acceptModalOpen = !ds.acceptModalOpen
		} else if (modal === 'decline') {
			ds.declineModalOpen = !ds.declineModalOpen
		} else if (modal === 'success') {
			ds.successModalOpen = !ds.successModalOpen
		} else if (modal === 'failure') {
			ds.failureModalOpen = !ds.failureModalOpen
			ds.errorMessage = null
		}
	})
}

export function setIsLoading(isLoading: boolean): void {
	sos.change((ds) => {
		ds.isMakingOffer = isLoading
	})
}

export async function setOfferStatus(
	status: apiTypes.RateResponse['offerStatus'],
	isBroker?: boolean,
): Promise<void> {
	sos.change((ds) => {
		ds.savedRate.offerStatus = status
		ds.isUpdatingOffer = true
	})

	const { shipment, savedRate, offerId } = sos.getState()

	let response: IRequestState<void>
	if (isBroker) {
		response = await apiBroker.updateConnectionOffer(
			(rs: IRequestState<void>) => {},
			shipment.id,
			savedRate,
		)
	} else {
		response = await apiShipments.spotQuoteRate(
			(rs: IRequestState<void>) => {},
			shipment.id,
			offerId,
			savedRate,
		)
	}
	if (!response.error) {
		sos.change((ds) => {
			ds.successModalOpen = true
		})
	} else {
		sos.change((ds) => {
			ds.failureModalOpen = true
			ds.errorMessage = response.error
		})
	}

	sos.change((ds) => {
		ds.declineModalOpen = false
		ds.acceptModalOpen = false
		ds.isUpdatingOffer = false
	})
}

export function toggleEditingConnections(): void {
	sos.change((ds) => {
		ds.editingConnection = !ds.editingConnection
		ds.preEditConnection = l.cloneDeep(ds.connection)
		ds.preEditProviderInformation = l.cloneDeep(ds.providerInformation)
	})
}

export async function saveConnectionInfo(): Promise<void> {
	const connection = sos.getState().connection
	fireAndForget(
		() => apiShipments.updateConnection(null, connection.id, connection),
		'Updating connection',
	)
	toggleEditingConnections()
}

export function cancelConnectionUpdate(): void {
	sos.change((ds) => {
		ds.connection = ds.preEditConnection
		ds.providerInformation = ds.preEditProviderInformation
	})
	toggleEditingConnections()
}

export function getProvider(): IProviderInformation {
	return sos.getState().providerInformation
}
