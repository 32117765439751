import { sosUser } from 'ui/state'
import { BrokerCompanyManagementTabs, ShipperCompanyManagementTabs } from '.'
import React, { ReactElement } from 'react'

export const AppCompanyManagement = (): ReactElement => {
	return sosUser.isUserBroker() ? (
		<BrokerCompanyManagementTabs />
	) : (
		<ShipperCompanyManagementTabs />
	)
}
