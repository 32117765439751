import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { Autocomplete } from '../autocomplete'
import { ISelectOptions } from './ISelectOptions'

export const Select: FC = (props: {
	name?: string
	tPrefix?: string
	value: string
	options: string[] | ISelectOptions[]
	testId?: string
	width?: string
	onChange?: (newValue) => void
	isClearable?: boolean
	readOnly?: boolean
	numListOptionsBeforeScroll?: number
	className?: string
}) => {
	return (
		<Autocomplete
			type='select'
			tPrefix={props.tPrefix}
			name={props.name}
			value={props.value}
			options={props.options}
			testId={props.testId}
			onChange={props.onChange}
			isClearable={props.isClearable}
			readOnly={props.readOnly}
			width={props.width}
			numListOptionsBeforeScroll={props.numListOptionsBeforeScroll}
			className={props.className}
		/>
	)
}
