import { IRequestState } from '../requestState'
import { apiTypes, apiCommon } from '../'

export const getMarketRate = async (
	onProgress: (rs: IRequestState<apiTypes.RateAnalysisResponse>) => void,
	requestData: apiTypes.RateAnalysisRequest,
): Promise<IRequestState<apiTypes.RateAnalysisResponse>> => {
	return apiCommon.apiFetch(
		onProgress,
		{ method: 'POST', data: requestData },
		'providers/check-market-rate',
	)
}
