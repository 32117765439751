import { apiTypes } from 'ui/api'

export interface RateBookingDetails extends apiTypes.RateBookingOptions {
	shipmentId: string
	rateId?: string
	buyRateId?: string
	providerName: string
	isBroker: boolean
}

export const defaultRateBookingDetails: RateBookingDetails = {
	shipmentId: '',
	rateId: '',
	buyRateId: '',
	providerName: '',
	isBroker: null,
	api: null,
	phone: '',
	email: '',
}
