import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { ElasticSearchPager } from 'ui/components/common/pager'
import { sosRouter } from 'ui/components/common/router'
import { Debug } from 'ui/components/dev/Debug'
import { watch } from 'ui/components/hooks'
import { Spacer } from 'ui/components/layout/spacer'
import { DataTable } from 'ui/components/table/DataTable'
import { ShipmentsListFilter } from 'ui/pages/shipments/ShipmentsListFilter'
import {
	brokerHeaders,
	tmsHeaders,
} from 'ui/pages/shipments/ShipmentsListHeaders'
import { ShipmentsListOptions } from 'ui/pages/shipments/ShipmentsListOptions'
import * as sosUser from 'ui/state/sosUser'
import { isInTMS2 } from 'ui/theme/theme'
import { sosShipmentProfileBroker } from '../shipment-profile/broker'
import { exportShipmentsList } from './exportShipmentsList'
import { ExportToCsvModal } from './ShipmentsListExportToCsvModal'
import { delayedTask, sosShipmentsList } from './state'
import { LoadingStatusType } from './state/delayedTask'

const tPrefix = 'page.shipmentsList.table'

export const ShipmentsListDataTable: FC = (props: {
	state: sosShipmentsList.IStateShipmentsList
	userState: sosUser.IStateUser
	shipmentStatusFilter: string
	loadingStatus: LoadingStatusType
	brokerMode: boolean
}) => {
	const { state, userState } = props

	const onClickRowZoom = (row): void => {
		// TODO: go to different tabs based on the row state -- booked, quoted, etc.
		if (isInTMS2()) {
			const shipmentProfileLink = '/shipment_profile/' + row.id
			sosRouter.navigate(shipmentProfileLink)
		} else {
			sosShipmentProfileBroker.navigateToShipmentProfile({
				shipmentId: row.id,
			})
		}
	}

	watch(() => {
		delayedTask.callNow(sosShipmentsList.delayedFetchShipments)
	}, [
		userState.requestUserInfo?.data?.profile?.settings
			?.canViewDataForTheseLocations,
	])

	return (
		<>
			<ShipmentsListFilter
				selectedFilter={props.shipmentStatusFilter}
				onChangeFilter={sosShipmentsList.updateShipmentStatusFilter}
			/>

			<Spacer />

			<DataTable
				testId='shipmentsList.dataTable'
				tPrefix={tPrefix}
				headers={props.brokerMode ? brokerHeaders : tmsHeaders}
				data={state.processedShipments}
				state={
					props.brokerMode
						? state.brokerShipmentListDataTableState
						: state.shipmentListDataTableState
				}
				loadingStatus={props.loadingStatus}
				isLoading={state.requestShipments.isFetching}
				onToggleHeaderActionBox={sosShipmentsList.toggleActionBox}
				onChangeFilter={sosShipmentsList.setColumnFilterValue}
				onChangeDateFilter={sosShipmentsList.updateFilterDatePickerState}
				onClickRowZoom={onClickRowZoom}
				onRefresh={() => {
					delayedTask.callNow(sosShipmentsList.delayedFetchShipments)
				}}
				onToggleSort={sosShipmentsList.toggleSort}
				onClearFilter={sosShipmentsList.clearColumnFilter}
				onResetTableHeaders={sosShipmentsList.resetTableHeaders}
				onToggleHeader={(c: { field: string }) => {
					sosShipmentsList.shipmentTableToggleHeader(c.field)
				}}
				onExport={exportShipmentsList}
				spacerCell={false}
				highlightedRows={state.selectedShipments.map((c) => c.id)}
				customSearchTemplate={
					<ShipmentsListOptions
						state={state}
						userState={userState}
						tPrefix={tPrefix}
					/>
				}
				fontSize={'fontMedium'}
			/>
			<ExportToCsvModal state={state} />
			<ElasticSearchPager
				pager={state.pager}
				onClickPage={async (pageNum) => {
					await sosShipmentsList.updateCurrentPage(pageNum)
				}}
			/>
			<Debug label='Shipments' data={state.requestShipments} />
		</>
	)
}
