import { apiTypes } from 'ui/api'
import { l } from 'ui/lib/lodashImports'
import { StopType } from 'ui/pages/new-quote/state/sosNewQuote'
import { IFormattedAccessorials } from 'ui/pages/spot-quote/FormattedAccessorials'
import { allAccessorialsArray, allAccessorialsMap } from './accessorialsData'
import { IAccessorialMetadata } from './accessorialsTypes'

export { allAccessorialsMap, allAccessorialsArray }

export const pickupAccessorials = l.filter(
	allAccessorialsArray,
	(c) => c.isPickup && !c.isInvoice,
)

export const deliveryAccessorials = l.filter(
	allAccessorialsArray,
	(c) => c.isDelivery && !c.isInvoice,
)

export const limitedAccessAccessorials = l.filter(
	allAccessorialsArray,
	(c) => c.isLimitedAccess,
)

export function getChildrenAccessorials(): IAccessorialMetadata[] {
	const children: IAccessorialMetadata[] = []
	l.forEach(allAccessorialsArray, (c) => {
		if (c.childrenAccessorials) {
			l.forEach(Object.keys(c.childrenAccessorials), (dKey) => {
				const child = c.childrenAccessorials[dKey]
				children.push(child) // violent but works
			})
		}
	})
	return children
}

export function getAccessorialKeyPath(
	accessorial: IAccessorialMetadata,
	mode?: StopType,
): string {
	let base: string = accessorial.accessorialKey
	if (mode === 'pickup') {
		base = accessorial.pickupKey || accessorial.accessorialKey
	}
	if (mode === 'delivery') {
		base = accessorial.deliveryKey || accessorial.accessorialKey
	}
	if (accessorial.nestedPath) {
		base = accessorial.nestedPath + '.' + base
	}
	return base
}

export const getAccessorialsWithInfo = (
	accessorials: apiTypes.AccessorialsRequired,
	accessorialsInfo:
		| apiTypes.DestinationAccessorialsInfo
		| apiTypes.OriginAccessorialsInfo
		| apiTypes.ShipmentAccessorialsInfo,
): IFormattedAccessorials => {
	if (!accessorials) {
		return {}
	}
	const trueAccessorialKeys = l.filter(
		Object.keys(accessorials),
		(accessorial) => accessorials[accessorial] === true,
	)

	const formattedAccessorials: {
		[accessorial: string]: { info: { [accessorialInfo: string]: string } }
	} = {}
	l.forEach(trueAccessorialKeys, (accessorial) => {
		formattedAccessorials[accessorial] = { info: {} }

		const accessorialData = l.find(
			allAccessorialsArray,
			(key) =>
				key.accessorialKey === accessorial ||
				key.pickupKey === accessorial ||
				key.deliveryKey === accessorial,
		)

		if (accessorialData.childrenAccessorials) {
			const accessorialInfos = Object.keys(accessorialData.childrenAccessorials)

			l.forEach(accessorialInfos, (infoKey) => {
				if (accessorialsInfo[infoKey]) {
					formattedAccessorials[accessorial].info[infoKey] = accessorialsInfo[
						infoKey
					].toString()
				}
			})
		}
	})

	return formattedAccessorials
}
