import { FC } from 'app/FunctionalComponent'
import React from 'react'
// import * as tableStyles from 'ui/components/table/TableStyles.module.scss'
import { l } from 'ui/lib/lodashImports'
import { theme } from 'ui/theme'

const defaultProps = {
	includeTitle: true,
}

export const MessagesList: FC = (props: {
	messages: string[]
	includeTitle?: boolean
}) => {
	if (props.messages == null || props.messages.length === 0) {
		return null
	}

	return (
		<div>
			{props.includeTitle && (
				<h3 className={theme.getTextColor('red')}>Errors</h3>
			)}
			<ul>
				{l.map(props.messages, (c, cIdx) => (
					<li key={cIdx}>{c}</li>
				))}
			</ul>
		</div>
	)
}
MessagesList.defaultProps = defaultProps
