import { FC } from 'app/FunctionalComponent'
import React, { useEffect, useState } from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import { sosToast } from 'common/components/toast'
import { apiTypes, apiPrintNode } from 'ui/api'
import { IRequestState } from 'ui/api/requestState'
import { Button } from 'ui/components/common/button'
import { FormStackedTextInput, IFormData } from 'ui/components/form'
import { useOnce } from 'ui/components/hooks'
import { t, tArgz } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight'
import { Spacer } from 'ui/components/layout/spacer'
import { l } from 'ui/lib/lodashImports'
import { validateForm } from 'ui/lib/validation/formValidator'
import { sos2 } from 'ui/lib/state/sos2'
import { sosFeatureToggles } from 'ui/state'
import { IUserCredentials } from '../PrintersAndScalesPage'
import { TypeaheadOption } from 'common/components/typeahead'
import * as classes from './PrintersAndScalesInstructions.module.scss'

const tPrefix = 'page.companyManagement.printersAndScales.instructions'

const setupInstructions: string[] = [
	'instruction1',
	'instruction2',
	'instruction3',
]

const defaultUserCredentialsForm: IUserCredentials = {
	id: null,
	username: null,
	apiKey: null,
	accountId: null,
}

export const PrintersAndScalesInstructions: FC = (props: {
	userCredentials: apiTypes.PrintNodeCredentialsResponse
	setUserCredentials: React.Dispatch<
		React.SetStateAction<apiTypes.PrintNodeCredentialsResponse>
	>
	location: TypeaheadOption
}) => {
	const { userCredentials, setUserCredentials, location } = props
	const featureToggleState = sos2.useSubscription(sosFeatureToggles.sos)
	const canUseCredentialsForm =
		featureToggleState.featureToggles['printNode-credential-access']?.enabled
	useOnce(() => {
		sosFeatureToggles.checkFeatureToggle('printNode-credential-access')
	})

	const [isSavingCredentials, setIsSavingCredentials] = useState<boolean>(false)
	const [isGeneratingCredentials, setIsGeneratingCredentials] = useState<
		boolean
	>(false)
	const [userCredentialsForm, setUserCredentialsForm] = useState<
		IUserCredentials
	>(userCredentials || defaultUserCredentialsForm)

	const formData: IFormData<IUserCredentials> = {
		form: userCredentialsForm,
		metadata: {
			id: {},
			username: { required: true },
			password: { required: true },
			apiKey: { required: true },
			accountId: { required: true },
		},
		onUpdateForm: (field: string, value: any) => {
			const updatedFormData = l.cloneDeep(userCredentialsForm)

			updatedFormData[field] = value
			setUserCredentialsForm(updatedFormData)
		},
		tPrefix,
	}

	const formIsValid = validateForm(formData.form, formData.metadata).isValid

	useEffect(() => {
		if (userCredentials) {
			const credentials = {
				id: userCredentials.id,
				username: userCredentials.username,
				password: userCredentials.password,
				apiKey: userCredentials.apiKey,
				accountId: userCredentials.accountId,
			}
			setUserCredentialsForm(credentials)
		} else {
			setUserCredentialsForm(defaultUserCredentialsForm)
		}
	}, [userCredentials])

	const saveGenerateCredentials = async (
		createAccount?: boolean,
	): Promise<void> => {
		let userCredentialsResponse: IRequestState<apiTypes.PrintNodeCredentialsResponse>
		let userCredentialsRequest: apiTypes.PrintNodeCredentialsRequest

		if (createAccount) {
			setIsGeneratingCredentials(true)
			userCredentialsRequest = {
				accountId: '',
				username: '',
				locationId: location.value,
			}
		} else {
			setIsSavingCredentials(true)
			userCredentialsRequest = {
				accountId: userCredentialsForm.accountId,
				apiKey: userCredentialsForm.apiKey,
				username: userCredentialsForm.username,
				password: userCredentialsForm.password,
				locationId: location.value,
			}
		}

		if (userCredentials?.id) {
			userCredentialsResponse = await apiPrintNode.updateCredentials(
				() => {},
				userCredentials.id,
				userCredentialsRequest,
			)
		} else {
			userCredentialsResponse = await apiPrintNode.createCredentials(
				() => {},
				userCredentialsRequest,
				createAccount,
			)
		}

		if (userCredentialsResponse?.data) {
			const credential = userCredentialsResponse.data
			const credentials = {
				id: credential.id,
				username: credential.username,
				password: credential.password,
				apiKey: credential.apiKey,
				accountId: credential.accountId,
			}
			setUserCredentialsForm(credentials)
			setUserCredentials({
				accountId: credential.accountId,
				apiKey: credential.apiKey,
				username: credential.username,
				password: credential.password,
				locationId: credential.locationId,
				id: credential.id,
			})
		} else if (userCredentialsResponse?.error) {
			sosToast.sendApiErrorResponseToast(userCredentialsResponse)
		}

		setIsSavingCredentials(false)
		setIsGeneratingCredentials(false)
	}

	return (
		<div>
			<Row>
				{canUseCredentialsForm && !userCredentials && (
					<Col xs={3}>
						<FormStackedTextInput
							formData={formData}
							field={'username'}
							testId={'printers-and-scales-instructions-username'}
							readOnly={isSavingCredentials || isGeneratingCredentials}
						/>
						<FormStackedTextInput
							formData={formData}
							field={'password'}
							testId={'printers-and-scales-instructions-password'}
							readOnly={isSavingCredentials || isGeneratingCredentials}
						/>
						<FormStackedTextInput
							formData={formData}
							field={'apiKey'}
							testId={'printers-and-scales-instructions-apikey'}
							readOnly={isSavingCredentials || isGeneratingCredentials}
						/>
						<FormStackedTextInput
							formData={formData}
							field={'accountId'}
							testId={'printers-and-scales-instructions-accountid'}
							readOnly={isSavingCredentials || isGeneratingCredentials}
						/>
						<Spacer />
						<AlignRight>
							<Button
								color={'green'}
								onClick={() => saveGenerateCredentials(false)}
								testId={'printers-and-scales-instructions-save-credentials'}
								isSpinning={isSavingCredentials}
								isDisabled={!formIsValid || isGeneratingCredentials}
							>
								{t('save', tPrefix)}
							</Button>
						</AlignRight>
					</Col>
				)}

				<Col xs={canUseCredentialsForm && !userCredentials ? 9 : 12}>
					<Card>
						<Card.Header>
							{t('printersAndScalesInstructions', tPrefix)}
						</Card.Header>
						<Card.Body>
							<Row>
								<Col>
									{setupInstructions.map((instruction, idx) => {
										return (
											<h6 key={idx} className={classes.instructionsItem}>
												{idx < 2
													? t(instruction, tPrefix)
													: tArgz(
															'instruction3',
															{ instructionLocation: location.label },
															tPrefix,
													  )}
												{idx === 0 ? (
													<a
														href='https://www.printnode.com/download/'
														target='_blank'
														rel='noopener noreferrer'
													>
														https://www.printnode.com/download/
													</a>
												) : null}
											</h6>
										)
									})}

									{userCredentials ? (
										<>
											<h5 className={classes.instructionsCredentials}>
												{t('instructionsUsername:', tPrefix)}{' '}
												{userCredentials.username}
											</h5>
											<h5 className={classes.instructionsCredentials}>
												{t('instructionsPassword:', tPrefix)}{' '}
												{userCredentials.password}
											</h5>
										</>
									) : (
										<>
											<Spacer height={'10px'} />
											<Button
												color={'green'}
												onClick={() => saveGenerateCredentials(true)}
												testId={
													'printers-and-scales-instructions-generate-credentials'
												}
												isSpinning={isGeneratingCredentials}
												isDisabled={isSavingCredentials}
											>
												{t('generateLocationUsernameAndPassword', tPrefix)}
											</Button>
										</>
									)}
								</Col>
							</Row>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</div>
	)
}
