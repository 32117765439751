import { FC } from 'app/FunctionalComponent'
import React from 'react'
import {
	Breadcrumb,
	IBreadcrumbEntry,
} from 'ui/components/common/breadcrumb/Breadcrumb'
import { Debug } from 'ui/components/dev/Debug'
import { useOnce } from 'ui/components/hooks/useOnce'
import { Spacer } from 'ui/components/layout/spacer/Spacer'
import { Layout } from 'ui/pages/layout/Layout'
import { sosPackageCatalog } from 'ui/state'
import { PackageCatalogDataTable } from './PackageCatalogDataTable'

export const ManagePackageCatalogsPage: FC = (props: {}) => {
	const state = sosPackageCatalog.useSubscribe()

	useOnce(async () => {
		await sosPackageCatalog.getBooks()
	})

	const bread: IBreadcrumbEntry[] = [
		{
			t: 'page.managePackageCatalogs.managePackageCatalogs',
		},
	]

	return (
		<Layout>
			<Breadcrumb entries={bread} />
			<Debug
				hide={true}
				label='book get all request'
				data={state.requestGetPackageCatalogs}
			/>
			<PackageCatalogDataTable
				requestGetPackageCatalogs={state.requestGetPackageCatalogs}
				requestAddEditPackageCatalog={state.requestAddEditPackageCatalog}
				data={state.dataPackageCatalogs}
				dataTableState={state.dataTablePackageCatalogs}
				form={state.formPackageCatalog}
			/>
			<Spacer />
			<Debug
				hide={true}
				label='book create request'
				data={state.requestAddEditPackageCatalog}
			/>
		</Layout>
	)
}
