import parse from 'csv-parse'
import { ITransformationInformation } from 'ui/data/lightningIntegration/lightning_inbound'
import { delay } from 'ui/lib/async/delay'
import { l } from 'ui/lib/lodashImports'
import { validation } from 'ui/lib/validation'
import { log } from 'ui/lib/log'
import { tString } from 'ui/components/i18n/i18n'

export interface ICsvRecord {
	result: 'error' | 'warning' | 'success'
	errors: string[]
	warnings: string[]
	rawRecord: any
}

export interface IStateCsvValidator {
	columns: string[]
	rawRecords: any
	records: ICsvRecord[]
	itemLines: any[]
	errors: string[]
	warnings: string[]
	isLoading: boolean
}

function _validateRecord(
	record: ICsvRecord,
	transformationInformations: ITransformationInformation[],
): void {
	l.forEach(transformationInformations, (c: ITransformationInformation) => {
		if (c.value_from_field) {
			let val
			if (c.data_cleanup_method) {
				val = c.data_cleanup_method(
					record.rawRecord[c.value_from_field],
					record.rawRecord,
				)
			} else {
				val = record.rawRecord[c.value_from_field]
			}

			if (c.required) {
				if (null == val || val === '') {
					record.errors.push(
						`Missing or invalid required field ${c.value_from_field}`,
					)
				}
			}
			if (c.data_type && val != null && val !== '') {
				if (c.data_type === 'string') {
					// No validation required, everything is a string
				}
				if (c.data_type === 'number') {
					// The doc says number but means integer
					const error = validation.mustBeAnInteger(
						val,
						`${c.value_from_field} must be an integer`,
					)
					if (error) {
						record.errors.push(error)
					}
				}
				if (c.data_type === 'lower_case_string') {
					const error = validation.mustBeLowercase(
						val,
						`${c.value_from_field} must be lowercase`,
					)
					if (error) {
						record.errors.push(error)
					}
				}
				if (c.data_type === 'boolean') {
					const error = validation.mustBeBoolean(
						val,
						`${c.value_from_field} must be true or false`,
					)
					if (error) {
						record.errors.push(error)
					}
				}
			}
		}

		if (c.save_as_object_in_array) {
			_validateRecord(record, c.override_fields)
		}
	})
}

export function validateRecord(
	record: ICsvRecord,
	transformationInformation: ITransformationInformation[],
): void {
	if (l.isEmpty(record.rawRecord) || l.every(record.rawRecord, (c) => !c)) {
		record.warnings.push('Empty line')
	} else {
		_validateRecord(record, transformationInformation)
	}
	if (record.warnings.length > 0) {
		record.result = 'warning'
	}
	if (record.errors.length > 0) {
		record.result = 'error'
	}
}

export async function parseCsv(
	// export function parseCsv(
	fileContent: string,
	requiredColumns: string[],
	optionalColumns: string[],
	transformationInformation: ITransformationInformation[],
	// onProgress?: (rs: IRequestState<IStateCsvValidator>) => void,
	// ): Promise<IStateCsvValidator> {
): Promise<{
	rawRecords: any
	records: any
	columns: any
	errors: any
	warnings: any
}> {
	return new Promise<{
		rawRecords: any
		records: any
		columns: any
		errors: any
		warnings: any
	}>((resolve, reject) => {
		let columns: string[] = []
		const rawRecords = []
		const records: ICsvRecord[] = []
		const errors: string[] = []
		const warnings: string[] = []

		// See: https://csv.js.org/parse/options/columns/
		const parser = parse(fileContent, {
			columns: (header) => {
				columns = header
				return columns
			},
			relax_column_count: true,
			skip_lines_with_error: true,
		})

		parser.on('readable', async () => {
			let rawRecord = null
			const rowsPerFrame = 10
			let rowsProcessedThisFrame = 0
			while ((rawRecord = parser.read())) {
				rawRecords.push(rawRecord)
				const record: ICsvRecord = {
					rawRecord: rawRecord,
					result: 'success',
					warnings: [],
					errors: [],
				}
				validateRecord(record, transformationInformation)
				records.push(record)
				rowsProcessedThisFrame++
				if (rowsProcessedThisFrame > rowsPerFrame) {
					await delay(0)
					rowsProcessedThisFrame = 0
				}
			}
		})

		parser.on('skip', (err) => {
			log('csv-validator', 'line error', err)
			records.push({
				rawRecord: {},
				result: 'warning',
				warnings: ['' + err],
				errors: [],
			})
		})
		parser.on('error', (err) => {
			log('csv-validator', 'parse error', err)
			records.push({
				rawRecord: {},
				result: 'error',
				warnings: [],
				errors: ['' + err],
			})
		})

		parser.on('end', () => {
			// Validate columns
			const validColumns: string[] = []
			validColumns.push(...requiredColumns)
			validColumns.push(...optionalColumns)
			columns = columns.filter((col) => col !== '')
			const extraColumns = l.difference(columns, validColumns)
			if (extraColumns.length !== 0) {
				warnings.push(
					tString('ExtraColumns', 'page.csvValidator') +
						': ' +
						l.join(extraColumns, ', '),
				)
			}
			const missingColumns = l.difference(requiredColumns, columns)
			if (missingColumns.length !== 0) {
				errors.push(
					tString('MissingColumns', 'page.csvValidator') +
						': ' +
						l.join(missingColumns, ', '),
				)
			}

			resolve({
				rawRecords,
				records,
				columns,
				errors,
				warnings,
			})
		})
		parser.end()
	})
}

function _transformToItem(
	record: ICsvRecord,
	transformed: any,
	transformationInformation: ITransformationInformation[],
): any {
	l.forEach(transformationInformation, (c: ITransformationInformation) => {
		if (c.value_from_field) {
			let inputValue = record.rawRecord[c.value_from_field]
			if (c.data_cleanup_method) {
				inputValue = c.data_cleanup_method(inputValue)
			}
			transformed[c.field] = inputValue
		}
		if (c.value) {
			transformed[c.field] = c.value
		}
		if (c.save_as_object_in_array) {
			// Recurse to build array
			const array = transformed[c.array_field_name_to_save_as] || []
			array.push(_transformToItem(record, {}, c.override_fields))
			transformed[c.array_field_name_to_save_as] = array
		}
	})
	return transformed
}

export function transformToItem(
	record: ICsvRecord,
	transformed: any,
	transformationInformation: ITransformationInformation[],
): any {
	if (!transformed) {
		transformed = {}
	}
	_transformToItem(record, transformed, transformationInformation)
	return transformed
}
