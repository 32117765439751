import {
	faAngleDoubleLeft,
	faAngleDoubleRight,
	faAngleLeft,
	faAngleRight,
	faSyncAlt,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import BootstrapTable, {
	ColumnDescription,
	PaginationOptions,
} from 'react-bootstrap-table-next'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import filterFactory from 'react-bootstrap-table2-filter'
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css'
import paginationFactory, {
	PaginationProvider,
	SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import ToolkitProvider, {
	ColumnToggle,
	CSVExport,
	Search,
} from 'react-bootstrap-table2-toolkit'
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css'
import { t } from 'ui/components/i18n/i18n'
import './Table.scss'
import {
	isOnLastPage,
	getPreviousPage,
	getNextPage,
	getLastPage,
} from './paginationUtils'
import { FC } from 'app/FunctionalComponent'

const NoDataIndication = (): React.ReactElement => (
	<div className='alert alert-dark' role='alert'>
		{t('components.marketplace.table.noData')}
	</div>
)

export interface BootstrapTableProps {
	columns: ColumnDescription[]
	data: any
	keyField: string
	isLoading: boolean
	wrapperClasses?: string
	defaultSearch?: string
	defaultSort?: string
	totalSize: number
	canExport?: boolean
	canSearch?: boolean
	canToggleColumns?: boolean
	onTableChange: (type, newState: any) => any
	onRefresh?: () => void
	onExport?: () => any
	verticalCenterCells?: boolean
	testId?: string
}

export const Table: FC = (props: BootstrapTableProps) => {
	const {
		columns,
		data,
		keyField,
		isLoading,
		wrapperClasses,
		defaultSearch,
		defaultSort,
		totalSize,
		canExport,
		canSearch,
		canToggleColumns,
		onTableChange,
		testId,
	} = props

	const paginationOptions: PaginationOptions = {
		custom: true,
		paginationSize: 25,
		sizePerPageList: [10, 25, 50],
		totalSize,
	}

	const { ToggleList } = ColumnToggle
	const { ExportCSVButton } = CSVExport
	const { SearchBar, ClearSearchButton } = Search

	return (
		<PaginationProvider pagination={paginationFactory(paginationOptions)}>
			{({ paginationProps, paginationTableProps }) => {
				return (
					<ToolkitProvider
						bootstrap4={true}
						keyField={keyField}
						data={data}
						columns={columns}
						columnToggle={true}
						exportCSV={
							canExport ? { onlyExportFiltered: true, exportAll: false } : false
						}
						search={canSearch ? { defaultSearch: defaultSearch } : false}
					>
						{(tableProps) => (
							<div className='mt-4'>
								{canSearch && (
									<div className='d-flex justify-content-start input-group-append mb-3'>
										<SearchBar
											{...tableProps.searchProps}
											className='form-control-sm'
										/>
										<ClearSearchButton
											{...tableProps.searchProps}
											className='btn btn-primary btn-sm'
										/>
									</div>
								)}
								<div className='d-flex justify-content-between'>
									{canToggleColumns && (
										<ToggleList {...tableProps.columnToggleProps} />
									)}
									{canExport && (
										<ExportCSVButton
											{...tableProps.csvProps}
											className='btn btn-primary btn-sm'
										>
											{t('exportCsv', 'components.marketplace.table')}
										</ExportCSVButton>
									)}
								</div>
								{props.onRefresh && (
									<div className='d-flex justify-content-end mb-2'>
										<button
											className='btn btn-sm btn-light'
											onClick={() =>
												paginationProps.onPageChange(paginationProps.page)
											}
										>
											<FontAwesomeIcon
												icon={faSyncAlt}
												fixedWidth
												spin={isLoading}
											/>
										</button>
									</div>
								)}
								<div className='overflow-auto' data-testid={testId}>
									<BootstrapTable
										{...tableProps.baseProps}
										{...paginationTableProps}
										remote
										striped={true}
										defaultSorted={defaultSort}
										filter={filterFactory()}
										classes='table-layout-auto mb-0'
										wrapperClasses={wrapperClasses + ' bg-white'}
										noDataIndication={() => <NoDataIndication />}
										onTableChange={(type, newState) => {
											onTableChange(type, newState)
										}}
										rowClasses={
											props.verticalCenterCells ? 'verticalCenter' : null
										}
									/>
								</div>
								<div className='d-flex justify-content-between mt-2'>
									<SizePerPageDropdownStandalone {...paginationProps} />
									<div className='d-flex'>
										<span className='align-self-center text-muted'>
											{(paginationProps.page - 1) *
												paginationProps.sizePerPage +
												1}
											{'-'}
											{Math.min(
												paginationProps.page * paginationProps.sizePerPage,
												totalSize,
											)}{' '}
											{t('components.marketplace.table.of')} {totalSize}
										</span>
										<div className='btn-group btn-group' role='toolbar'>
											<button
												type='button'
												className='btn btn-link text-muted'
												data-testid='bootstrap-table-pagination-first'
												onClick={() => paginationProps.onPageChange(1)}
												disabled={paginationProps.page <= 1}
											>
												<FontAwesomeIcon
													icon={faAngleDoubleLeft}
													size='lg'
													fixedWidth
												/>
											</button>
											<button
												type='button'
												className='btn btn-link text-muted'
												data-testid='bootstrap-table-pagination-prev'
												disabled={paginationProps.page <= 1}
												onClick={() =>
													paginationProps.onPageChange(
														getPreviousPage(paginationProps.page),
													)
												}
											>
												<FontAwesomeIcon
													icon={faAngleLeft}
													size='lg'
													fixedWidth
												/>
											</button>
											<button
												type='button'
												className='btn btn-link text-muted'
												data-testid='bootstrap-table-pagination-next'
												disabled={isOnLastPage(
													paginationProps.page,
													totalSize,
													paginationProps.sizePerPage,
												)}
												onClick={() =>
													paginationProps.onPageChange(
														getNextPage(
															paginationProps.page,
															totalSize,
															paginationProps.sizePerPage,
														),
													)
												}
											>
												<FontAwesomeIcon
													icon={faAngleRight}
													size='lg'
													fixedWidth
												/>
											</button>
											<button
												type='button'
												className='btn btn-link text-muted'
												data-testid='bootstrap-table-pagination-last'
												disabled={isOnLastPage(
													paginationProps.page,
													totalSize,
													paginationProps.sizePerPage,
												)}
												onClick={() =>
													paginationProps.onPageChange(
														getLastPage(totalSize, paginationProps.sizePerPage),
													)
												}
											>
												<FontAwesomeIcon
													icon={faAngleDoubleRight}
													size='lg'
													fixedWidth
												/>
											</button>
										</div>
									</div>
								</div>
							</div>
						)}
					</ToolkitProvider>
				)
			}}
		</PaginationProvider>
	)
}
