import { FC } from 'app/FunctionalComponent'
import { sosToast } from 'common/components/toast'
import React, { useState } from 'react'
import { apiPackageCatalog } from 'ui/api'
import { UpdateMode } from 'ui/api/apiCommon'
import { PackagePageRequest } from 'ui/api/apiTypes'
import { Button } from 'ui/components/common/button'
import { IconButton } from 'ui/components/common/icon/Icon'
import { solidIcons } from 'ui/components/common/icon/solidIcons'
import { Modal } from 'ui/components/common/modal/Modal'
import { OkCancelButtons } from 'ui/components/common/okCancelButtons'
import { FormTextInput, IFormData } from 'ui/components/form'
import { t } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight'
import { IPackageCatalogEntry } from 'ui/lib/books/IPackageCatalogEntry'
import { l } from 'ui/lib/lodashImports'
import * as classes from './NewQuotePackagingCatalog.module.scss'

const tPrefix =
	'page.newQuote.parcel.newQuotePackageForm.newQuotePackagingCatalog'

export const NewQuotePackagingCatalog: FC = (props: {
	bookId: string
	packagingPages: PackagePageRequest[]
	setPackagingPages: React.Dispatch<React.SetStateAction<PackagePageRequest[]>>
	fetchPackageCatalogs: () => void
	isModalOpen: boolean
	onModalClose: () => void
}) => {
	const {
		bookId,
		packagingPages,
		setPackagingPages,
		fetchPackageCatalogs,
		isModalOpen,
		onModalClose,
	} = props

	const [indexOfRowToEdit, setIndexOfRowToEdit] = useState<number>(null)
	const [indexOfRowToDelete, setIndexOfRowToDelete] = useState<number>(null)
	const [isAddingBox, setIsAddingBox] = useState<boolean>(false)
	const [isSaving, setIsSaving] = useState<boolean>(false)
	const [isDeleting, setIsDeleting] = useState<boolean>(false)

	const addNewBox = (): void => {
		const newPackagingPages: PackagePageRequest = {
			name: 'New Box',
			length: 12,
			width: 12,
			height: 12,
		}

		if (packagingPages) {
			packagingPages.unshift(newPackagingPages)
			setPackagingPages(packagingPages)
		} else {
			setPackagingPages([newPackagingPages])
		}

		setIndexOfRowToEdit(0)
		setIsAddingBox(true)
	}

	const deleteNewBox = (): void => {
		packagingPages.splice(indexOfRowToDelete, 1)
		setPackagingPages(packagingPages)
		setIsAddingBox(false)
		setIndexOfRowToEdit(null)
		setIndexOfRowToDelete(null)
	}

	const updatePackagingCatalog = async (
		updateMode: UpdateMode,
		indexToUpdate: number,
	): Promise<IPackageCatalogEntry> => {
		const result = await apiPackageCatalog.updatePackageCatalogEntry(
			updateMode,
			bookId,
			packagingPages[indexToUpdate],
			() => {},
		)

		if (result.data) {
			if (updateMode === 'upsert') {
				setIndexOfRowToEdit(null)
			} else if (updateMode === 'delete') {
				packagingPages.splice(indexOfRowToDelete, 1)
				setPackagingPages(packagingPages)
				setIndexOfRowToDelete(null)
			}

			fetchPackageCatalogs()
			setIsAddingBox(false)

			return result.data
		} else if (result.error) {
			sosToast.sendApiErrorResponseToast(result)
		}
	}

	return (
		<Modal
			content={() => (
				<div data-testid={'new-quote-packaging-catalog'}>
					<AlignRight>
						<Button
							color={'green'}
							className={classes.addCloseButton}
							testId={'new-quote-packaging-catalog-add-button'}
							onClick={addNewBox}
							isDisabled={isAddingBox}
						>
							{t('addBox', tPrefix)}
						</Button>
					</AlignRight>
					<div className={classes.packagingCatalogTableContainer}>
						<div className={classes.packagingCatalogTableLabel}>
							<div>{t('name', tPrefix)}</div>
							<div>{t('length', tPrefix)}</div>
							<div>{t('width', tPrefix)}</div>
							<div>{t('height', tPrefix)}</div>
						</div>

						{l.map(packagingPages, (pagesRow, idx) => {
							const formData: IFormData<PackagePageRequest> = {
								form: pagesRow,

								metadata: {
									name: {},
									length: {},
									width: {},
									height: {},
									barCode: {},
								},

								onUpdateForm: (field: string, value: any) => {
									const retreivedPackagingPages = l.cloneDeep(packagingPages)
									const updatedPackagingPages = l.cloneDeep(pagesRow)
									updatedPackagingPages[field] = value

									retreivedPackagingPages.splice(idx, 1, updatedPackagingPages)
									setPackagingPages(retreivedPackagingPages)
								},

								tPrefix,
							}

							return (
								<div
									className={classes.packagingCatalogRowContainer}
									data-testid={'new-quote-packaging-catalog-list'}
									key={idx}
								>
									<FormTextInput
										form={formData.form}
										field={'name'}
										onUpdateForm={formData.onUpdateForm}
										readOnly={idx !== indexOfRowToEdit}
									/>
									<FormTextInput
										form={formData.form}
										field={'length'}
										onUpdateForm={formData.onUpdateForm}
										readOnly={idx !== indexOfRowToEdit}
									/>
									<FormTextInput
										form={formData.form}
										field={'width'}
										onUpdateForm={formData.onUpdateForm}
										readOnly={idx !== indexOfRowToEdit}
									/>
									<FormTextInput
										form={formData.form}
										field={'height'}
										onUpdateForm={formData.onUpdateForm}
										readOnly={idx !== indexOfRowToEdit}
									/>
									<div className={classes.iconContainer}>
										{idx === indexOfRowToEdit ? (
											<Button
												color={'blue'}
												testId={'new-quote-packaging-catalog-save-button'}
												onClick={async () => {
													setIsSaving(true)
													await updatePackagingCatalog(
														'upsert',
														indexOfRowToEdit,
													)
													setIsSaving(false)
												}}
												isSpinning={isSaving}
											>
												{t('save', tPrefix)}
											</Button>
										) : (
											<IconButton
												icon={solidIcons.faPencilAlt}
												buttonClassName={classes.iconButtonContainer}
												color={'black'}
												testId={'new-quote-packaging-catalog-edit-button'}
												onClick={() => {
													setIndexOfRowToEdit(idx)
												}}
												large={true}
												disabled={isAddingBox}
											/>
										)}

										<IconButton
											icon={solidIcons.faTrash}
											buttonClassName={classes.iconButtonContainer}
											color={'red'}
											testId={'new-quote-packaging-catalog-delete-button'}
											onClick={() => {
												setIndexOfRowToDelete(idx)
											}}
											large={true}
											disabled={isAddingBox && idx !== 0}
										/>
									</div>
								</div>
							)
						})}
					</div>
					<AlignRight>
						<Button
							color={'blue'}
							className={classes.addCloseButton}
							testId={'new-quote-packaging-catalog-close-button'}
							onClick={() => {
								setIsAddingBox(false)
								setIndexOfRowToDelete(null)
								setIndexOfRowToEdit(null)
								onModalClose()
							}}
						>
							{t('close', tPrefix)}
						</Button>
					</AlignRight>
					<Modal
						content={() => (
							<div data-testid={'new-quote-packaging-catalog-delete-modal'}>
								<p>{t('deleteThisPackage?', tPrefix)}</p>
								<AlignRight>
									<OkCancelButtons
										isValid={true}
										ok={t('ok', tPrefix)}
										okColor={'green'}
										okTestId={'new-quote-packaging-catalog-delete-modal-ok'}
										isSpinning={isDeleting}
										onOk={async () => {
											setIsDeleting(true)

											if (isAddingBox) {
												deleteNewBox()
											} else {
												await updatePackagingCatalog(
													'delete',
													indexOfRowToDelete,
												)
											}

											setIsDeleting(false)
										}}
										cancel={t('cancel', tPrefix)}
										onCancel={() => {
											setIndexOfRowToDelete(null)
										}}
										cancelTestId={
											'new-quote-packaging-catalog-delete-modal-cancel'
										}
									></OkCancelButtons>
								</AlignRight>
							</div>
						)}
						isOpen={!l.isNil(indexOfRowToDelete)}
						onModalClose={() => {}}
						title={t('confirmDelete', tPrefix)}
					/>
				</div>
			)}
			isOpen={isModalOpen}
			onModalClose={onModalClose}
			title={t('packagingCatalog', tPrefix)}
		/>
	)
}
