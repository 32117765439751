import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { apiTypes } from 'ui/api'
import { IRequestState } from 'ui/api/requestState'
import { t } from 'ui/components/i18n/i18n'
import { DataTable } from 'ui/components/table/DataTable'
import { DataTableUpdate } from 'ui/components/table/DataTableUpdate'
import { IDataTableHeader } from 'ui/components/table/IDataTableHeader'
import { IDataTableState } from 'ui/components/table/IDataTableState'
import { IProductCatalogEntryForm } from 'ui/forms/formProductCatalogEntry'
import { sosProductCatalog } from 'ui/state'
import { UpdateProductCatalogEntryForm } from './UpdateProductCatalogEntryForm'

export const productCatalogHeaders: IDataTableHeader<
	apiTypes.ProductPageResponse
>[] = [
	{
		field: 'label',
	},
	{
		field: 'description',
	},
	{
		field: 'itemNumber',
	},
	{
		field: 'freightClass',
	},
	{
		field: 'nmfcCode',
	},
	{
		field: 'nmfcSubCode',
	},
	{
		field: 'productWeight',
	},
	{
		field: 'hazmat',
	},
	{
		field: 'hazmatClass',
	},
	{
		field: 'hazmatUnCode',
	},
	{
		field: 'hazmatPackingGroup',
	},
]

export const ProductCatalogEntryDataTable: FC = (props: {
	requestGetProducts: IRequestState<apiTypes.ProductPageResponse[]>
	requestUpdateProductPage: IRequestState<apiTypes.ProductPageResponse>
	dataTableState: IDataTableState<apiTypes.ProductPageResponse>
	data: apiTypes.ProductPageResponse[]
	form: IProductCatalogEntryForm
}) => {
	const { requestGetProducts, requestUpdateProductPage } = props
	const { dataTableState } = props

	return (
		<div>
			<DataTable
				testId='data-table-product-catalog'
				headers={productCatalogHeaders}
				tPrefix={'shared.productCatalog'}
				isLoading={
					requestGetProducts.isFetching || requestUpdateProductPage.isFetching
				}
				data={props.data}
				state={dataTableState}
				onAddRow={() => {
					sosProductCatalog.productsTableSetUpdateMode('add')
				}}
				addLabel={t('shared.productCatalog.addProductPage')}
				onEditRow={(row) => {
					sosProductCatalog.productsTableSetUpdateMode('edit', row)
				}}
				onToggleHeader={(c) => {
					sosProductCatalog.toggleHeader(c.field)
				}}
				onClickHeader={(header) => {
					sosProductCatalog.sort(header.field)
				}}
				onRefresh={async () => {
					await sosProductCatalog.fetchProductPages()
				}}
				rendererAdd={() => (
					<DataTableUpdate error={dataTableState.error}>
						<UpdateProductCatalogEntryForm mode='add' form={props.form} />
					</DataTableUpdate>
				)}
				rendererEdit={(row) => (
					<DataTableUpdate
						error={dataTableState.error}
						onDeleteRow={() => sosProductCatalog.updateProductPage('delete')}
					>
						<UpdateProductCatalogEntryForm mode='edit' form={props.form} />
					</DataTableUpdate>
				)}
			/>
		</div>
	)
}
