import { l } from 'ui/lib/lodashImports'
import { r } from 'ui/lib/ramdaImports'

/*
	Push a string into another string at a specific index.
 */
export function spliceString(
	string: string,
	splicee: string,
	index: number,
): string {
	return r.insert(index, splicee, l.toArray(string)).join('')
}
