import bootstrapPlugin from '@fullcalendar/bootstrap'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import FullCalendar from '@fullcalendar/react'
import { FC } from 'app/FunctionalComponent'
import { DateTime } from 'luxon'
import React, { useRef, useState } from 'react'
import { apiTypes } from 'ui/api'
import { IconButton, solidIcons } from 'ui/components/common/icon'
import { Loader } from 'ui/components/common/loader'
import {
	windowExists,
	windowOpen,
} from 'ui/components/common/router/windowUtils'
import { SmallButton } from 'ui/components/common/small-button'
import { watch } from 'ui/components/hooks'
import { t, tString } from 'ui/components/i18n/i18n'
import { Spacer } from 'ui/components/layout/spacer'
import { RequiresFeatureToggle } from 'ui/components/permissions/RequiresFeatureToggle'
import { LocationSelector } from 'ui/components/shared/location-selector'
import { ProviderSelector } from 'ui/components/shared/provider-selector/ProviderSelector'
import { tms2_common } from 'ui/lib'
import { fireAndForget } from 'ui/lib/async'
import { navParentTo } from 'ui/lib/IframeRpc'
import { sos2 } from 'ui/lib/state/sos2'
import { Layout } from 'ui/pages/layout/Layout'
import { sosUser } from 'ui/state'
import { isInTMS2 } from 'ui/theme/theme'
import { buildQuery, Flows, Modes } from '../common/controlTowerUtils'
import * as classes from './ControlTowerCalendar.module.scss'
import './FullCalendar.scss'
import { sosControlTowerCalendar } from './state'
import { fetchShipments } from './state/controlTowerCalendarUtils'

export const controlTowerCalendarTPrefix = 'page.controlTowerCalendar'

const navigateToShipmentList = async (
	date: DateTime,
	locationFilter: string,
	flowFilter: Flows,
	providerFilter: string,
	modeFilter: Modes,
): Promise<void> => {
	const { query, nestedFieldQuery } = buildQuery(
		date,
		date,
		isInTMS2()
			? await tms2_common.currently_managed_organization_ids()
			: [locationFilter],
		providerFilter,
		modeFilter,
		flowFilter,
	)

	if (isInTMS2()) {
		fireAndForget(
			async () =>
				await navParentTo(
					`/shipments-v3/shipments-list?filter=booked&externalQuery=${encodeURI(
						query,
					)}&externalNestedQuery=${encodeURI(nestedFieldQuery)}`,
					false,
					true,
				),
			'navigating parent to shipment list drill down',
		)
	} else {
		if (windowExists) {
			windowOpen(
				window.location.origin +
					`/#/shipments-v3/shipments-list?filter=booked&externalQuery=${encodeURI(
						query,
					)}&externalNestedQuery=${encodeURI(nestedFieldQuery)}`,
			)
		}
	}
}

export const ControlTowerCalendarPage: FC = (props: {}) => {
	return (
		<Layout>
			<RequiresFeatureToggle featureToggle='control-tower'>
				<ControlTowerCalendar />
			</RequiresFeatureToggle>
		</Layout>
	)
}

export const ControlTowerCalendar: FC = (props: {}) => {
	const state = sos2.useSubscription(sosControlTowerCalendar.getSos())
	const userState = sos2.useSubscription(sosUser.getSos())

	const [currentDate, setCurrentDate] = useState(DateTime.local())
	const [loadingShipments, setLoadingShipments] = useState(false)

	const [locationFilter, setLocationFilter] = useState<string>()
	const [flowFilter, setFlowFilter] = useState('' as Flows)
	const [providerFilter, setProviderFilter] = useState<string>()
	const [modeFilter, setModeFilter] = useState('' as Modes)

	const calendarRef = useRef<FullCalendar>()

	watch(() => {
		if (!loadingShipments) {
			fireAndForget(async () => {
				setLoadingShipments(true)
				await fetchShipments(
					currentDate,
					locationFilter,
					flowFilter,
					providerFilter,
					modeFilter,
				)
				setLoadingShipments(false)
			}, 'control tower calendar getting shipments')
		}
	}, [
		currentDate,
		locationFilter,
		flowFilter,
		providerFilter,
		modeFilter,
		userState.requestUserInfo?.data?.profile?.settings
			?.canViewDataForTheseLocations,
	])

	const containerClassName = classes.calendarContainer + ' fullCalendar'

	return (
		<>
			<Spacer />
			{calendarRef.current && (
				<div className={classes.interactionsContainer}>
					<div>
						<h3 className={classes.date}>
							{currentDate.toLocaleString({ month: 'long', year: 'numeric' })}
						</h3>
					</div>
					<div>
						<SmallButton
							testId='today-button'
							onClick={() => {
								const oldMonth = calendarRef.current
									.getApi()
									.getDate()
									.getMonth()
								calendarRef.current.getApi().today()

								const newDate = calendarRef.current.getApi().getDate()
								const newMonth = newDate.getMonth()
								if (oldMonth !== newMonth) {
									setCurrentDate(DateTime.fromJSDate(newDate))
								}
							}}
						>
							{t('today', controlTowerCalendarTPrefix)}
						</SmallButton>
					</div>
					<div className={classes.prevNextIcon}>
						<IconButton
							icon={solidIcons.faChevronLeft}
							iconClassName={classes.prevNextIcon}
							onClick={() => {
								calendarRef.current.getApi().prev()
								setCurrentDate(
									DateTime.fromJSDate(calendarRef.current.getApi().getDate()),
								)
							}}
							testId='prev-month-button'
						/>
					</div>
					<div className={classes.prevNextIcon}>
						<IconButton
							icon={solidIcons.faChevronRight}
							iconClassName={classes.prevNextIcon}
							onClick={() => {
								calendarRef.current.getApi().next()
								setCurrentDate(
									DateTime.fromJSDate(calendarRef.current.getApi().getDate()),
								)
							}}
							testId='next-month-button'
						/>
					</div>
					<Loader isLoading={loadingShipments} />
				</div>
			)}
			<Spacer />
			<div className={classes.filterContainer}>
				{!isInTMS2() && (
					<div>
						<LocationSelector
							onChange={(selected) => {
								setLocationFilter(selected?.value)
							}}
							includeAll={true}
							onlyShowLeafLocations={true}
						/>
					</div>
				)}
				<div className='bootstrap-wrapper'>
					<select
						data-testid='flow-select'
						value={flowFilter}
						className='form-control form-control-sm'
						onChange={(event) => setFlowFilter(event.target.value as Flows)}
					>
						<option disabled value=''>
							{tString('filterByFlow', controlTowerCalendarTPrefix)}
						</option>
						<option value='all'>
							{tString('all', controlTowerCalendarTPrefix + '.flows')}
						</option>
						<option value='inbound'>
							{tString('inbound', controlTowerCalendarTPrefix + '.flows')}
						</option>
						<option value='outbound'>
							{tString('outbound', controlTowerCalendarTPrefix + '.flows')}
						</option>
					</select>
				</div>
				<div>
					<ProviderSelector
						onChange={(selected: apiTypes.ProviderResponse) =>
							setProviderFilter(selected?.id)
						}
						tPrefix={controlTowerCalendarTPrefix}
					/>
				</div>
				<div className='bootstrap-wrapper'>
					<select
						data-testid='mode-select'
						value={modeFilter}
						className='form-control form-control-sm'
						onChange={(event) => setModeFilter(event.target.value as Modes)}
					>
						<option disabled value=''>
							{tString('filterByMode', controlTowerCalendarTPrefix)}
						</option>
						<option value='all'>
							{tString('all', controlTowerCalendarTPrefix + '.modes')}
						</option>
						<option value='ltl'>
							{tString('ltl', controlTowerCalendarTPrefix + '.modes')}
						</option>
						<option value='truckload'>
							{tString('truckload', controlTowerCalendarTPrefix + '.modes')}
						</option>
						<option value='parcel'>
							{tString('parcel', controlTowerCalendarTPrefix + '.modes')}
						</option>
					</select>
				</div>
			</div>

			<Spacer />

			<div className={containerClassName + ' bootstrap-wrapper'}>
				<FullCalendar
					ref={calendarRef}
					plugins={[dayGridPlugin, bootstrapPlugin, interactionPlugin]}
					defaultView='dayGridMonth'
					bootstrapFontAwesome={true}
					themeSystem='bootstrap'
					header={false}
					events={state.events}
					eventOrder='groupId'
					eventClick={(arg) =>
						fireAndForget(
							async () =>
								navigateToShipmentList(
									DateTime.fromJSDate(arg.event.start),
									locationFilter,
									flowFilter,
									providerFilter,
									modeFilter,
								),
							'drilling down into shipment list',
						)
					}
					dateClick={(arg) =>
						fireAndForget(
							async () =>
								navigateToShipmentList(
									DateTime.fromISO(arg.dateStr),
									locationFilter,
									flowFilter,
									providerFilter,
									modeFilter,
								),
							'drilling down into shipment list',
						)
					}
				/>
			</div>
		</>
	)
}
