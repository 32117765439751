import { FC } from 'app/FunctionalComponent'
import React, { useEffect } from 'react'
import { Checkbox } from 'ui/components/common/checkbox/Checkbox'
import {
	documentExists,
	getDocument,
} from 'ui/components/common/router/windowUtils'
import { sos2 } from 'ui/lib/state/sos2'
import { sosPageConfig } from 'ui/state/'
import { theme } from 'ui/theme'

export const PageConfigModePicker: FC = (props: {}) => {
	const state = sos2.useSubscription(sosPageConfig.sos)

	useEffect(() => {
		const listener = (ev): void => {
			if (ev.code === 'F8') {
				if (theme.isInDev()) {
					sosPageConfig.togglePageConfigMode()
				}
			}
		}
		if (documentExists) {
			getDocument().addEventListener('keyup', listener)
		}

		return () => {
			if (documentExists) {
				getDocument().removeEventListener('keyup', listener)
			}
		}
	})

	return (
		<div>
			<Checkbox
				value={state.isPageConfigMode}
				onChange={(newVal) => {
					sosPageConfig.togglePageConfigMode()
				}}
			>
				Page Config Mode
			</Checkbox>
		</div>
	)
}
