import { FC } from 'app/FunctionalComponent'
import React, { ReactElement } from 'react'
import { addClassIf } from 'ui/theme/theme'
import { l } from 'ui/lib/lodashImports'
import classes from './TitledBorderBox.module.scss'

export const TitledBorderBox: FC = (props: {
	title: ReactElement | string
	children?: ReactElement | string
	testId?: string
	className?: string
}) => {
	let classname = classes.titledBorderBox

	classname = addClassIf(!l.isNil(props.className), props.className, classname)

	return (
		<div className={classname} data-testid={props.testId}>
			<span className={classes.titledBorderBoxTitle}>{props.title}</span>
			{props.children}
		</div>
	)
}
