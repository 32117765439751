import { FC } from 'app/FunctionalComponent'
import React, { useEffect, useState } from 'react'
import { sosToast } from 'common/components/toast'
import { apiTypes, apiPrintNode } from 'ui/api'
import { IRequestState } from 'ui/api/requestState'
import { Modal } from 'ui/components/common/modal'
import { OkCancelButtons } from 'ui/components/common/okCancelButtons'
import { t } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight'
import { Spacer } from 'ui/components/layout/spacer'
import { l } from 'ui/lib/lodashImports'
import * as classes from './PrintStationConfigModal.module.scss'
import { fireAndForget } from 'ui/lib/async'
import { sosUser } from 'ui/state'
import { getCookieByKey } from 'ui/lib/cookieHelper'
import Form from 'react-bootstrap/Form'
import {
	Typeahead,
	Menu,
	MenuItem,
	Highlighter,
} from 'react-bootstrap-typeahead'
import { TypeaheadOption } from 'ui/common/components/typeahead'

interface PrintStationConfigurationForm {
	name: string
	labelPrinter: string
	officePrinter: string
	scale: string
}

const defaultPrintStationConfigurationForm: PrintStationConfigurationForm = {
	name: '',
	labelPrinter: '',
	officePrinter: '',
	scale: '',
}

export const tPrefix =
	'page.companyManagement.printersAndScales.printStations.configModal'

const spacerHeight = '10px'

export const PrintStationConfigModal: FC = (props: {
	isModalOpen: boolean
	onModalClose: (printer: apiTypes.PrinterResponse) => void
	credentials: apiTypes.PrintNodeCredentialsResponse
	printStationList: apiTypes.PrinterGroupResponse[]
	setPrintStationList: React.Dispatch<
		React.SetStateAction<apiTypes.PrinterGroupResponse[]>
	>
	selectedPrintStationConfig: apiTypes.PrinterGroupResponse
	locationId: string
}) => {
	const {
		isModalOpen,
		onModalClose,
		// printerList,
		credentials,
		setPrintStationList,
		selectedPrintStationConfig,
		locationId,
	} = props
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [
		printStationConfigurationSaveForm,
		setPrintStationConfigurationSaveForm,
	] = useState<PrintStationConfigurationForm>(
		defaultPrintStationConfigurationForm,
	)
	const [isScanningScales, setIsScanningScales] = useState<boolean>(false) // TO-DO: TO BE CONVERTED TO USECONTEXT
	const [scalesList, setScalesList] = useState<
		apiPrintNode.PrintNodeScalesResponse[]
	>([]) // TO-DO: TO BE CONVERTED TO USECONTEXT

	const [printerList, setPrinterList] = useState<apiTypes.PrinterResponse[]>([])

	// TO-DO: TO BE CONVERTED TO USECONTEXT
	const scanScalesList = async (): Promise<void> => {
		setIsScanningScales(true)

		const scalesListResponse = await apiPrintNode.getScaleList(credentials)

		if (scalesListResponse) {
			const scalesLists = l.flatMap(
				scalesListResponse,
				(scalesList) => scalesList,
			)

			const filteredScalesList = scalesLists.filter(
				(scale) => scale !== undefined,
			)

			setScalesList(filteredScalesList)
		}

		setIsScanningScales(false)
	}

	const fetchPrintersList = async (page?: number): Promise<void> => {
		const take = 100
		let skip = 0
		let finished = false

		let combinedPrinters = []
		let printerListResponse

		while (!finished) {
			printerListResponse = await apiPrintNode.getPrinterList(
				credentials.id,
				{ take: take, skip: skip },
				true,
			)

			if (printerListResponse.error || !printerListResponse.data?.entities) {
				sosToast.sendApiErrorResponseToast(printerListResponse)
				finished = true
			} else {
				combinedPrinters = [
					...combinedPrinters,
					...printerListResponse.data.entities,
				]
				if (printerListResponse.data.entities.length === take) {
					skip += take
				} else {
					finished = true
				}
			}
		}

		if (!printerListResponse.error) {
			setPrinterList(combinedPrinters)
		}
	}

	useEffect(() => {
		if (isModalOpen) {
			fireAndForget(scanScalesList, 'Fetching Scales List')
			fireAndForget(fetchPrintersList, 'Fetching Printer List')
		}

		if (selectedPrintStationConfig) {
			const form = {
				name: selectedPrintStationConfig.nickname,
				labelPrinter: '',
				officePrinter: '',
				scale: selectedPrintStationConfig.printerGroupScale?.scaleId,
			}

			selectedPrintStationConfig.printerIds.forEach((item) => {
				const savedPrinter = printerList?.find((printer) => printer.id === item)

				if (savedPrinter?.printerType === 'office') {
					form.officePrinter = item
				} else if (savedPrinter?.printerType === 'label') {
					form.labelPrinter = item
				}
			})

			setPrintStationConfigurationSaveForm(form)
		} else {
			setPrintStationConfigurationSaveForm(defaultPrintStationConfigurationForm)
		}
	}, [isModalOpen, selectedPrintStationConfig]) // eslint-disable-line react-hooks/exhaustive-deps

	const onClose = (): void => {
		setPrintStationConfigurationSaveForm(defaultPrintStationConfigurationForm)
		onModalClose(null)
	}

	const onSave = async (): Promise<void> => {
		setIsLoading(true)

		const scaleName: string = scalesList.find(
			(scale) => scale.computerId === printStationConfigurationSaveForm.scale,
		)?.deviceName

		const printerGroupRequest: apiTypes.PrinterGroupRequest = {
			nickname: printStationConfigurationSaveForm.name,
			printerIds: [],
			locationId: locationId,
			printNodeCredentialsId: credentials.id,
		}

		if (
			printStationConfigurationSaveForm.labelPrinter &&
			printStationConfigurationSaveForm.labelPrinter !== '0'
		) {
			printerGroupRequest.printerIds.push(
				printStationConfigurationSaveForm.labelPrinter,
			)
		}

		if (
			printStationConfigurationSaveForm.officePrinter &&
			printStationConfigurationSaveForm.officePrinter !== '0'
		) {
			printerGroupRequest.printerIds.push(
				printStationConfigurationSaveForm.officePrinter,
			)
		}

		if (
			printStationConfigurationSaveForm.scale &&
			printStationConfigurationSaveForm.scale !== '0'
		) {
			printerGroupRequest.printerGroupScale = {
				scaleId: printStationConfigurationSaveForm.scale,
				scaleName: scaleName,
			}
		}

		let printerGroupResponse: IRequestState<apiTypes.PrinterGroupResponse>

		if (selectedPrintStationConfig?.id) {
			printerGroupResponse = await apiPrintNode.updatePrinterGroup(
				() => {},
				printerGroupRequest,
				credentials.id,
				selectedPrintStationConfig.id,
			)
		} else {
			printerGroupResponse = await apiPrintNode.createPrinterGroup(
				() => {},
				printerGroupRequest,
				credentials.id,
			)
		}

		if (printerGroupResponse.data) {
			const selectedPrintStationCookie = getCookieByKey('x-printer-group-id')

			if (
				selectedPrintStationConfig &&
				selectedPrintStationConfig.id === selectedPrintStationCookie
			) {
				await sosUser.fetchPrintStation(selectedPrintStationConfig.id)
			}

			const printerGroupResponse = await apiPrintNode.getPrinterGroupList(
				() => {},
				credentials.id,
			)

			if (printerGroupResponse.data) {
				setPrintStationList(printerGroupResponse.data)
			} else if (printerGroupResponse.error) {
				sosToast.sendApiErrorResponseToast(printerGroupResponse)
			}

			setPrintStationConfigurationSaveForm(defaultPrintStationConfigurationForm)
			onClose()
		} else if (printerGroupResponse.error) {
			sosToast.sendApiErrorResponseToast(printerGroupResponse)
		}

		setIsLoading(false)
	}

	const handleChange = (e: React.FormEvent<HTMLInputElement>): void => {
		const name = e.currentTarget.name
		const value = e.currentTarget.value

		setPrintStationConfigurationSaveForm({
			...printStationConfigurationSaveForm,
			[name]: value,
		})
	}

	const optionNone = [{ id: '0', nickname: 'None', computerName: '' }]
	const labelPrinterListOptions: TypeaheadOption[] = optionNone
		.concat(printerList?.filter((printer) => printer?.printerType === 'label'))
		.map((printer) => ({
			value: printer?.id,
			label: printer?.nickname,
			groupId: printer?.computerName,
		}))
	const officePrinterListOptions: TypeaheadOption[] = optionNone
		.concat(printerList?.filter((printer) => printer?.printerType === 'office'))
		.map((printer) => ({
			value: printer?.id,
			label: printer?.nickname,
			groupId: printer?.computerName,
		}))
	const scaleListOptions: TypeaheadOption[] = [
		{ computerId: '0', computerName: '', deviceName: 'None' },
	]
		.concat(scalesList)
		.map((scale) => ({
			value: scale.computerId.toString(),
			label: scale.deviceName,
			groupId: scale.computerName,
		}))

	return (
		<Modal
			content={() => (
				<div className={`${classes.printStationConfigModal} bootstrap-wrapper`}>
					<Spacer height={spacerHeight} />

					<Form.Group controlId='name'>
						<Form.Label>{t('name', tPrefix)}</Form.Label>
						<Form.Control
							name='name'
							type='text'
							onChange={handleChange}
							value={printStationConfigurationSaveForm.name}
							// data-testid='printStationConfigurationSaveForm-name'
						/>
					</Form.Group>

					<Form.Group controlId='labelPrinter'>
						<Form.Label>{t('labelPrinter', tPrefix)}</Form.Label>
						<Selections
							options={labelPrinterListOptions}
							currentlySelected={printStationConfigurationSaveForm.labelPrinter}
							onChange={(selected: TypeaheadOption) => {
								if (selected) {
									setPrintStationConfigurationSaveForm({
										...printStationConfigurationSaveForm,
										labelPrinter: selected?.value,
									})
								} else {
									setPrintStationConfigurationSaveForm({
										...printStationConfigurationSaveForm,
										labelPrinter: '',
									})
								}
							}}
							placeholder='printer'
						/>
					</Form.Group>

					<Form.Group controlId='officePrinter'>
						<Form.Label>{t('officePrinter', tPrefix)}</Form.Label>
						<Selections
							options={officePrinterListOptions}
							currentlySelected={
								printStationConfigurationSaveForm.officePrinter
							}
							onChange={(selected: TypeaheadOption) => {
								if (selected) {
									setPrintStationConfigurationSaveForm({
										...printStationConfigurationSaveForm,
										officePrinter: selected?.value,
									})
								} else {
									setPrintStationConfigurationSaveForm({
										...printStationConfigurationSaveForm,
										officePrinter: '',
									})
								}
							}}
							placeholder='printer'
						/>
					</Form.Group>

					<Form.Group controlId='scale'>
						<Form.Label>{t('scale', tPrefix)}</Form.Label>
						<Selections
							options={scaleListOptions}
							currentlySelected={printStationConfigurationSaveForm.scale}
							onChange={(selected: TypeaheadOption) => {
								if (selected) {
									setPrintStationConfigurationSaveForm({
										...printStationConfigurationSaveForm,
										scale: selected?.value,
									})
								} else {
									setPrintStationConfigurationSaveForm({
										...printStationConfigurationSaveForm,
										scale: '',
									})
								}
							}}
							placeholder='scale'
						/>
					</Form.Group>

					<Spacer height={spacerHeight} />
					<AlignRight>
						<OkCancelButtons
							ok={t('save', tPrefix)}
							onOk={onSave}
							okColor='green'
							cancel={t('cancel', tPrefix)}
							onCancel={onClose}
							isSpinning={isLoading}
							isValid={printStationConfigurationSaveForm.name}
							okTestId={'print-station-config-save-button'}
							cancelTestId={'print-station-config-cancel-button'}
						/>
					</AlignRight>
				</div>
			)}
			isOpen={isModalOpen && !isScanningScales}
			onModalClose={onClose}
			closeModalX={true}
			title={t('printStationConfiguration', tPrefix)}
		/>
	)
}

const Selections = (props: {
	options: TypeaheadOption[]
	currentlySelected: string
	onChange: (option: TypeaheadOption) => void
	placeholder: string
}): JSX.Element => {
	let selected
	if (props.options) {
		selected = props.options.find(
			(option) => option.value === props.currentlySelected,
		)
	}

	return (
		<Typeahead
			defaultSelected={selected ? [selected] : []}
			id='selections'
			labelKey={(option: TypeaheadOption) => option.label}
			onChange={(selected: TypeaheadOption) => {
				if (selected) {
					props.onChange(selected[0])
				}
			}}
			options={props.options}
			placeholder={`Choose a ${props.placeholder}`}
			renderMenu={(results: TypeaheadOption[], menuProps, state) => {
				let index = 0
				const computers = l.groupBy(results, (option) => option.groupId)
				const items = Object.keys(computers)
					.sort()
					.map((computer) => (
						<React.Fragment key={computer}>
							{index !== 0 && <Menu.Divider />}
							<Menu.Header>{computer}</Menu.Header>
							{computers[computer].map((i) => {
								const item = (
									<MenuItem key={index} option={i} position={index}>
										<Highlighter search={state.text}>{i.label}</Highlighter>
									</MenuItem>
								)

								index += 1
								return item
							})}
						</React.Fragment>
					))

				return <Menu {...menuProps}>{items}</Menu>
			}}
		/>
	)
}
