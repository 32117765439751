import { FC } from 'app/FunctionalComponent'
import React from 'react'
import classes from './PaperCardTitle.module.scss'

export const PaperCardTitle: FC = (props: {
	children?: React.ReactNode
	border?: boolean
}) => {
	let className = classes.paperCardTitle
	if (props.border) {
		className += ' ' + classes.bordered
	}

	return <div className={className}>{props.children}</div>
}
