// see: https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsDataURL
// "The blob's result cannot be directly decoded as Base64 without first
// removing the Data-URL declaration preceding the Base64-encoded data.
// To retrieve only the Base64 encoded string, first remove
// data:*/*;base64, from the result."
export function dataUrlToBase64(dataUrl: string): string {
	const preamble = 'data:application/pdf;base64,'

	if (dataUrl.indexOf(preamble) !== 0) {
		throw new Error(`invalid data url ${dataUrl}`)
	}

	return dataUrl.substring(preamble.length)
}
