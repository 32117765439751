import { FC } from 'app/FunctionalComponent'
import React from 'react'
import classes from './FlexItem.module.scss'

type FlexItemAlign = 'left' | 'right' | 'center'
export const FlexItem: FC = (props: {
	minWidth?: string
	maxWidth?: string
	height?: string
	align?: FlexItemAlign
	padding?: string
	children: React.ReactNode
	fitContent?: boolean
	allowShrink?: boolean
}) => {
	let className = classes.flexItem

	if (props.fitContent) {
		className = classes.fitContent
	}

	if (props.align === 'right') {
		className += ' ' + classes.alignRight
	}
	if (props.align === 'center') {
		className += ' ' + classes.alignCenter
	}
	if (props.allowShrink) {
		className += ' ' + classes.allowShrink
	}

	return (
		<div
			className={className}
			style={{
				minWidth: props.minWidth,
				maxWidth: props.maxWidth,
				padding: props.padding,
				height: props.height,
			}}
		>
			{props.children}
		</div>
	)
}
