import { IRequestState } from 'ui/api/requestState'
import { IDataTableState } from 'ui/components/table'
import { tms2_providerPortal } from 'ui/lib'
import { fireAndForget } from 'ui/lib/async'
import { callTms2Global } from 'ui/lib/IframeRpc'
import { l } from 'ui/lib/lodashImports'
import { IProviderPortalInvoice } from 'ui/pages/provider-portal/IProviderPortalInvoice'
import {
	ProviderPortalPage,
	TPaymentStatusFilter,
} from 'ui/pages/provider-portal/providerPortalTypes'
import { IProviderSignupForm } from 'ui/pages/provider-portal/sign-up'
import { router } from './router'
import { createSos } from './secretaryOfState'

interface IStateProviderPortal {
	selectedPage: ProviderPortalPage
	paymentStatusFilter: TPaymentStatusFilter
	invoiceTableState: IDataTableState<IProviderPortalInvoice>
	invoiceTableRequestState: IRequestState<IProviderPortalInvoice[]>
	signupForm?: IProviderSignupForm
	invoiceTableRequest: IRequestState<IProviderPortalInvoice[]>
	invoiceProfileRequest: IRequestState<any>
	successMessage?: string
	errorMessage?: string
}

const initialState: IStateProviderPortal = {
	selectedPage: 'welcome',
	paymentStatusFilter: 'waitingOnInvoice',
	invoiceTableRequestState: {},
	signupForm: {
		name: '',
		email: '',
		paymentNumberType: 'check',
		paymentNumber: '',
		proNumber: '',
	},
	invoiceTableRequest: {},
	invoiceTableState: {},
	invoiceProfileRequest: {},
}

const { stateManager, useSubscribe } = createSos(
	'sosProviderPortal',
	'1.0.0',
	initialState,
	{ useLocalStorage: false },
)
export { useSubscribe }

export const navigateToProviderPortal = (params: {
	selectedPage: ProviderPortalPage
}): void => {
	stateManager.produce((ds) => {
		l.defaults(params, { selectedPage: ds.selectedPage })
		ds.selectedPage = params.selectedPage
	})
	router.navTo(`/provider-portal/${params.selectedPage}`)
}

export const loadFilteredShipments = (
	statusFilter: TPaymentStatusFilter,
): void => {
	stateManager.produce((ds) => {
		ds.paymentStatusFilter = statusFilter
		// ds.invoiceTableState.data = call to get data
	})
}

export const updateSignupForm = (
	changes: Partial<IProviderSignupForm>,
): void => {
	stateManager.produce((ds) => {
		l.assign(ds.signupForm, changes)
	})
}

export const userSignup = async (): Promise<void> => {
	const data = await tms2_providerPortal.userSignup({
		signupForm: l.omit(stateManager.getState().signupForm, 'confirmPassword'),
	})
	if (data.success) {
		stateManager.produce((ds) => {
			ds.successMessage = data.message
			ds.errorMessage = ''
		})

		fireAndForget(
			async () =>
				await callTms2Global(
					'logUserIn',
					stateManager.getState().signupForm.email,
					stateManager.getState().signupForm.password,
				),
			'provider portal logging user into TMS2',
		)
	} else {
		stateManager.produce((ds) => {
			ds.errorMessage = data.message
		})
	}
}
