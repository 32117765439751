import React from 'react'
import { FormStackedItem } from './FormStackedItem'
import { FormTextInput } from './FormTextInput'
import { IFormData } from './IFormData'
import { InputAutocompleteType, InputType } from '../common/input'
import { tString } from '../i18n/i18n'
import classes from './FormTextInput.module.scss'
import { l } from 'ui/lib/lodashImports'
import { isExactlyNaN } from 'ui/lib/numbers/isExactlyNaN'

export const FormStackedTextInput = React.memo(
	<T extends any>(props: {
		refProp?: any
		field: any
		formData: IFormData<T>
		autofocus?: boolean
		onEnter?: (value?: string, field?: string) => void
		readOnly?: boolean
		inputType?: InputType
		multiline?: boolean
		notResizable?: boolean
		rows?: number
		autocomplete?: InputAutocompleteType
		helpText?: React.ReactNode
		keepSpaceForRequired?: boolean
		label?: React.ReactNode
		testId?: string
		placeholder?: string
		hideLabel?: boolean
		hideOptional?: boolean
		expected?: string | number
		width?: string
		className?: string
	}) => {
		const { field, formData, helpText } = props
		const meta = formData.metadata[field]
		const readOnly = props.readOnly || meta.readOnly

		return (
			<FormStackedItem
				field={field}
				formData={formData}
				helpText={helpText}
				keepSpaceForRequired={props.keepSpaceForRequired}
				label={props.label}
				hideLabel={props.hideLabel}
				hideOptional={props.hideOptional || readOnly}
				className={props.className}
			>
				<FormTextInput
					refProp={props.refProp}
					inputType={props.inputType}
					name={'' + field}
					form={formData.form}
					field={field}
					onUpdateForm={formData.onUpdateForm}
					autofocus={props.autofocus}
					onEnter={props.onEnter}
					readOnly={readOnly}
					multiline={props.multiline || meta.multiline}
					notResizable={props.notResizable}
					rows={props.rows}
					autocomplete={props.autocomplete}
					testId={props.testId}
					placeholder={props.placeholder}
					width={props.width}
				/>
				{!l.isNil(props.expected) &&
					!isExactlyNaN(props.expected) &&
					!readOnly && (
						<div className={classes.expectedSubText}>
							{`${tString('expected', 'common')}: ${props.expected}`}
						</div>
					)}
			</FormStackedItem>
		)
	},
)
