import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { tIfNotTranslated, tString } from 'ui/components/i18n/i18n'
import { l } from 'ui/lib/lodashImports'
import { ISelectOptions } from './ISelectOptions'
import classes from './SelectNative.module.scss'

export const SelectNative: FC = (props: {
	name?: string
	tPrefix?: string
	value: string
	options: string[] | ISelectOptions[]
	testId?: string
	onChange: (newValue) => void
}) => {
	const onChange = (ev: React.ChangeEvent<HTMLSelectElement>): void => {
		props.onChange(ev.target.value)
	}

	const t = null

	return (
		<div className={classes.select}>
			<select
				onChange={onChange}
				value={props.value || ''}
				data-testid={props.testId}
			>
				<option key='-' value=''>
					(Select one)
				</option>
				{l.map(props.options, (c) => {
					if (typeof c === 'string') {
						return (
							<option key={c} value={c}>
								{c}
							</option>
						)
					} else {
						const x: ISelectOptions = c as ISelectOptions // Required for Typescript
						return (
							<option key={x.value} value={x.value}>
								{x.label ||
									tString(props.tPrefix + (x.t || l.camelCase(x.value)))}
							</option>
						)
					}
				})}
			</select>
			{tIfNotTranslated(t)}
		</div>
	)
}
