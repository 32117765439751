import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { Input } from 'ui/components/common/input'
import { StackedItem } from 'ui/components/form'
import { t } from 'ui/components/i18n/i18n'
import { StopType } from 'ui/pages/new-quote/state/sosNewQuote'
import classes from './ShipmentStopCard.module.scss'

export const SpecialInstructions: FC = (props: {
	stopId: string
	stopType: StopType
	value: string
	onChange: (instructions: string, stopId?: string) => void
}) => {
	const tPrefix = 'page.newQuote.' + props.stopType
	return (
		<StackedItem label={t('specialInstructions', tPrefix)}>
			<Input
				className={classes.specialInstructions}
				multiline={true}
				rows={3}
				value={props.value}
				onChange={(change: string) => props.onChange(change, props.stopId)}
			/>
		</StackedItem>
	)
}
