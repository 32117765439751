import React from 'react'
import { FormReadOnly } from './FormReadOnly'
import { FormStackedItem } from './FormStackedItem'
import { IFormData } from './IFormData'

export function FormStackedReadOnly<T>(props: {
	field: keyof T
	formData: IFormData<T>
	showWarning?: boolean
}): React.ReactElement {
	const { field, formData, showWarning } = props

	return (
		<FormStackedItem
			field={field}
			formData={formData}
			hideOptional={true}
			showWarning={showWarning}
		>
			<FormReadOnly form={formData.form} field={field} />
		</FormStackedItem>
	)
}
