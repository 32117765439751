import { FC } from 'app/FunctionalComponent'
import numeral from 'numeral'
import React, { useState } from 'react'
import { Button } from 'ui/components/common/button'
import { Popup } from 'ui/components/common/popup'
import { SmallButton } from 'ui/components/common/small-button'
import { AmPmType } from 'ui/components/common/TimePicker'
import classes from 'ui/components/common/TimePicker.module.scss'
import { tString } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight'
import {
	createDefaultTime,
	ITimeStore,
} from 'ui/components/shared/ShipmentStopCard/DateTimeDesired'
import { l } from 'ui/lib/lodashImports'

export const TimePickerSimple: FC = (props: {
	state?: ITimeStore
	updateState?: (dateTimeChanges: Partial<ITimeStore>) => void
	testId?: string
}) => {
	const [demoState, demoUpdateState] = useState(createDefaultTime())
	const state = props.state || (demoState as ITimeStore)
	const updateState = props.updateState
		? props.updateState
		: (changes) => demoUpdateState(l.assign({}, state, changes))

	return (
		<div data-testid={props.testId}>
			<Popup
				isOpen={state.isOpen}
				content={
					<div>
						<TimeSelectorSimple
							hour={state.hour}
							minute={state.minute}
							amPm={state.amPm}
							onChange={(hour, minute, amPm) => {
								updateState({ hour, minute, amPm, isOpen: false })
							}}
						/>
						<AlignRight>
							<Button
								color='blue'
								onClick={() => {
									updateState({ isOpen: false })
								}}
							>
								Ok
							</Button>
						</AlignRight>
					</div>
				}
				onClickOutside={() => {
					updateState({ isOpen: false })
				}}
			>
				<div className={classes.timePicker}>
					<SmallButton
						minWidth='5em'
						color='gray'
						onClick={() => {
							updateState({ isOpen: true })
						}}
						testId={'time-picker-open-button'}
					>
						{state.hour < 0 && tString('page.newQuote.anytime')}
						{state.hour >= 0 && (
							<>
								{state.hour}:{numeral(state.minute).format('00')}
								{state.amPm || 'am'}
							</>
						)}
					</SmallButton>
				</div>
			</Popup>
		</div>
	)
}

const TimeSelectorSimple: FC = (props: {
	hour: number
	minute: number
	amPm: AmPmType
	onChange: (hour: number, minute: number, amPm: AmPmType) => void
}) => {
	const anytime = {
		h: -1,
		m: -1,
		amPm: 'am' as AmPmType,
		label: tString('page.newQuote.anytime'),
	}
	const times30min: {
		h: number
		m: number
		amPm: AmPmType
		label: string
	}[] = [anytime]
	for (let i = 0; i < 60 * 24; i += 30) {
		const amPm: AmPmType = i >= 12 * 60 ? 'pm' : 'am'
		let h = Math.floor(i / 60)
		if (h === 0) {
			h = 12
		}
		if (h > 12) {
			h -= 12
		}
		const m = i % 60
		times30min.push({
			h,
			m,
			amPm,
			label: `${h}:${numeral(m).format('00')}${amPm}`,
		})
	}
	return (
		<div className={classes.timeSelector} data-testid='time-selector'>
			<div className={classes.timeSelectorSimpleContainer}>
				{l.map(times30min, (c, idx) => (
					<div className={classes.timeSelectorSimpleItem} key={idx}>
						<SmallButton
							minWidth='5em'
							color={
								c.h === props.hour &&
								c.m === props.minute &&
								c.amPm === props.amPm
									? 'blue'
									: 'gray'
							}
							onClick={() => {
								props.onChange(c.h, c.m, c.amPm)
							}}
						>
							{c.label}
						</SmallButton>
					</div>
				))}
			</div>
		</div>
	)
}
