import { apiProductCatalog } from 'ui/api/'
import { UpdateMode } from 'ui/api/apiCommon'
import { createDefault, IRequestState } from 'ui/api/requestState'
import { dataUtils } from 'ui/components/table/'
import {
	createDefaultDataTableState,
	IDataTableState,
} from 'ui/components/table/IDataTableState'
import { formUtils } from 'ui/forms/'
import {
	IProductCatalogEntryForm,
	productCatalogEntryFormMetadata,
} from 'ui/forms/formProductCatalogEntry'
import { IProductCatalog } from 'ui/lib/books/IProductCatalog'
import { IProductCatalogEntry } from 'ui/lib/books/IProductCatalogEntry'
import { l } from 'ui/lib/lodashImports'
import { createSos2 } from 'ui/lib/state/sos2/sos2'
import { router } from './router'

export interface IStateProductCatalog {
	bookId: string
	dataTableStateProductCatalog: IDataTableState<IProductCatalogEntry>
	dataProductCatalog: IProductCatalogEntry[]
	productCatalogEntryForm: IProductCatalogEntryForm
	requestProductCatalog: IRequestState<IProductCatalog>
	requestProductCatalogEntries: IRequestState<IProductCatalogEntry[]>
	requestUpdateProductPage: IRequestState<IProductCatalogEntry>
}

export const sos = createSos2<IStateProductCatalog>('product-catalog', 2, {
	bookId: { default: '' },
	dataTableStateProductCatalog: {
		default: createDefaultDataTableState({
			sortOn: 'label',
			hiddenHeaders: ['id', 'pageType'],
		}),
	},
	dataProductCatalog: { default: [] },
	productCatalogEntryForm: {
		default: formUtils.createDefaultFormStringData(
			productCatalogEntryFormMetadata,
		),
	},
	requestProductCatalog: { default: createDefault() },
	requestProductCatalogEntries: { default: createDefault() },
	requestUpdateProductPage: { default: createDefault() },
})

export async function fetchProductPages(): Promise<void> {
	const state = sos.getState()
	await apiProductCatalog.fetchProductCatalogEntries(state.bookId, (rs) => {
		sos.change((ds) => {
			ds.requestProductCatalogEntries = rs
			ds.dataProductCatalog = rs.data
		})
	})
}

export async function fetchBook(): Promise<void> {
	const state = sos.getState()
	await apiProductCatalog.fetchProductCatalog(state.bookId, (rs) => {
		sos.change((ds) => {
			ds.requestProductCatalog = rs
		})
	})
}

export function productsTableSetUpdateMode(
	mode: UpdateMode,
	row: IProductCatalogEntry = null,
): void {
	sos.change((ds) => {
		dataUtils.toggleUpdateMode(ds.dataTableStateProductCatalog, mode, row)
		if (ds.dataTableStateProductCatalog.isAdding) {
			ds.productCatalogEntryForm = formUtils.createDefaultFormStringData(
				productCatalogEntryFormMetadata,
			)
		} else if (ds.dataTableStateProductCatalog.editRow) {
			ds.productCatalogEntryForm = formUtils.apiDataToFormStringData(
				productCatalogEntryFormMetadata,
				row,
			)
		}
	})
}

export function updateProductPageForm(
	key: keyof IProductCatalogEntryForm,
	newVal: string,
): void {
	sos.change((ds) => {
		ds.productCatalogEntryForm[key] = newVal
	})
}

export async function updateProductPage(mode: UpdateMode): Promise<void> {
	const state = sos.getState()

	const apiData = formUtils.formStringDataToApiData(
		productCatalogEntryFormMetadata,
		state.dataTableStateProductCatalog.editRow,
		state.productCatalogEntryForm,
	)

	if (!apiData.hazmat) {
		apiData.hazmatClass = null
		apiData.hazmatPackingGroup = null
		apiData.hazmatUnCode = null
	}

	const result = await apiProductCatalog.updateProductCatalogEntry(
		mode,
		state.bookId,
		apiData,
		(rs) => {
			sos.change((ds) => {
				ds.requestUpdateProductPage = rs
			})
		},
	)

	if (result.data) {
		sos.change((ds) => {
			dataUtils.updateItem(
				ds.dataTableStateProductCatalog,
				ds.dataProductCatalog,
				mode,
				result.data,
				ds.productCatalogEntryForm.id,
			)
		})
		productsTableSetUpdateMode('none')
	}
	if (result.error) {
		sos.change((ds) => {
			dataUtils.setError(ds.dataTableStateProductCatalog, result.error)
		})
	}
}

export function sort(sortOn: string): void {
	sos.change((ds) => {
		dataUtils.sort(
			ds.dataTableStateProductCatalog,
			ds.dataProductCatalog,
			sortOn,
		)
	})
}

export function toggleHeader(field: string): void {
	sos.change((ds) => {
		dataUtils.toggleHeader(ds.dataTableStateProductCatalog, field)
	})
}

export function navigateToProductCatalog(params: { bookId?: string }): void {
	sos.change((ds) => {
		l.defaults(params, {
			bookId: ds.bookId,
		})
		ds.bookId = params.bookId
		dataUtils.toggleUpdateMode(ds.dataTableStateProductCatalog, 'none', null)
	})
	router.navTo(`/product-catalog/${params.bookId}`)
}

export function updateState(changes: Partial<IStateProductCatalog>): void {
	sos.change((ds) => {
		l.assign(ds, changes)
	})
}
