import { FC } from 'app/FunctionalComponent'
import { DateTime } from 'luxon'
import React from 'react'
import { Button } from 'ui/components/common/button'
import { Input } from 'ui/components/common/input'
import { Popup, PopupBoxPosition } from 'ui/components/common/popup'
import { Calendar } from 'ui/components/date-time'
import { t } from 'ui/components/i18n/i18n'
import { FlexItem, FlexRow } from 'ui/components/layout/flexRow'
import * as classes from './ShipmentStopCard.module.scss'

export const CalendarInput: FC = (props: {
	dateString: string
	isOpen: boolean
	onChangeText: (newVal: string) => void
	onChangeOpen: (isOpen: boolean) => void
	popupPosition?: keyof PopupBoxPosition
	readOnly?: boolean
	hasToday?: boolean
	className?: string
}) => {
	return (
		<FlexRow verticalAlign={'center'}>
			{props.hasToday && (
				<FlexItem>
					<Button
						onClick={() => {
							props.onChangeText(DateTime.local().toISODate())
						}}
						testId='selectToday'
						color={'white'}
						borderColor={'#797979'}
						className={classes.calendarTodayButton}
					>
						{t('shared.calendarInput.today')}
					</Button>
				</FlexItem>
			)}
			<FlexItem>
				<Popup
					content={
						<div>
							<Calendar
								inset={true}
								dateString={props.dateString}
								onSelectDate={(dateString: string) => {
									props.onChangeOpen(false)
									props.onChangeText(dateString)
								}}
								readOnly={props.readOnly}
							/>
						</div>
					}
					onClickOutside={() => {
						props.onChangeOpen(false)
					}}
					isOpen={props.isOpen}
					position={props.popupPosition}
				>
					<Input
						value={props.dateString || ''}
						width='80px'
						onChange={(newVal) => {
							props.onChangeText(newVal)
						}}
						onFocus={() => {
							props.onChangeOpen(true)
						}}
						readOnly={props.readOnly}
						className={props.className}
					/>
				</Popup>
			</FlexItem>
		</FlexRow>
	)
}
