import React from 'react'
import { StringCheckbox } from './StringCheckbox'

export function FormCheckbox<T>(props: {
	form: T
	field: keyof T
	onUpdateForm: (field: keyof T, newVal: boolean) => void
	name?: string
	testId?: string
	children?: React.ReactNode
	readOnly?: boolean
}): React.ReactElement {
	const onChange = (newVal: string): void => {
		props.onUpdateForm(props.field, newVal === 'true')
	}
	const val = '' + props.form[props.field]

	return (
		<StringCheckbox
			value={val}
			onChange={onChange}
			testId={props.testId || 'form-' + props.field}
			readOnly={props.readOnly}
		>
			{props.children}
		</StringCheckbox>
	)
}
