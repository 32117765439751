import { apiTypes } from 'ui/api'
import { IFormMetadataCollection } from 'ui/components/form'
import { DateTime, Interval } from 'luxon'
import { _idx } from 'ui/lib'
import { l } from 'ui/lib/lodashImports'
import { IStateDockSchedulerAppointmentDetails } from '../state/sosDockSchedulerAppointmentDetails'
import { sosDockScheduler } from '../state'
import {
	tHandlingUnitType,
	tMode,
	tStopType,
	tEquipmentType,
} from 'ui/components/i18n/commonTranslations'
import { tString } from 'ui/components/i18n/i18n'
import { zipToTimezone } from 'ui/lib/addresses'

export interface IAppointmentDetailsGeneralInformation {
	slid: string
	dateTime: string
	dock: string
	carrier: string
	mode: string
	stopType: string
	equipmentType: string
	proNumber: string
	bolNumbers: string[]
	poNumbers: string[]
	soNumbers: string[]
	handlingUnits: number
	handlingUnitTypes: string[]
	expectedWeight: number
}

export const generalInformationMetaData: IFormMetadataCollection<IAppointmentDetailsGeneralInformation> = {
	slid: { readOnly: true },
	dateTime: { readOnly: true },
	dock: { readOnly: true },
	carrier: { readOnly: true },
	mode: { readOnly: true },
	stopType: { readOnly: true },
	equipmentType: { readOnly: true },
	proNumber: { readOnly: true },
	bolNumbers: { readOnly: true },
	poNumbers: { readOnly: true },
	soNumbers: { readOnly: true },
	handlingUnits: { readOnly: true },
	handlingUnitTypes: { readOnly: true },
	expectedWeight: { readOnly: true },
}

export interface IAppointmentDetailsCheckInForm {
	dock: string
	tractorNumber: string
	trailerNumber: string
	weight: number
	driverName: string
	checkInComments: string
	checkedLicense: boolean
	checkInDateTime: string
	checkedInBy: string
}

export const checkInMetaData: IFormMetadataCollection<IAppointmentDetailsCheckInForm> = {
	dock: {
		required: true,
	},
	tractorNumber: { required: false },
	trailerNumber: { required: false },
	weight: {
		required: false,
		mustBeANumber: true,
	},
	driverName: { required: false },
	checkInComments: {},
	checkedLicense: { required: false },
	checkInDateTime: { readOnly: false },
	checkedInBy: { readOnly: false },
}

export interface IAppointmentDetailsLoadStartForm {
	loadedBy: string
	loadStartComments: string
	loadStartDateTime: string
	loadStartRecordedBy: string
}

export const loadStartMetaData: IFormMetadataCollection<IAppointmentDetailsLoadStartForm> = {
	loadedBy: { required: true },
	loadStartComments: {},
	loadStartDateTime: { readOnly: true },
	loadStartRecordedBy: { readOnly: true },
}

export interface IAppointmentDetailsLoadEndForm {
	handlingUnitsLoaded: number
	cargoWeight: number
	sealNumber: string
	loadEndComments: string
	loadEndDateTime: string
	loadEndRecordedBy: string
}

export const loadEndMetaData: IFormMetadataCollection<IAppointmentDetailsLoadEndForm> = {
	handlingUnitsLoaded: {
		mustBeAnInteger: true,
	},
	cargoWeight: {
		mustBeANumber: true,
	},
	sealNumber: {},
	loadEndComments: {},
	loadEndDateTime: { readOnly: true },
	loadEndRecordedBy: { readOnly: true },
}

export interface IAppointmentDetailsCheckOutForm {
	tractorNumber: string
	trailerNumber: string
	weight: number
	driverName: string
	checkOutComments: string
	checkedLicense: boolean
	checkOutDateTime: string
	duration: string // XhXXm (i.e. 1h 52m)
	checkedOutBy: string
}

export const checkOutMetaData: IFormMetadataCollection<IAppointmentDetailsCheckOutForm> = {
	tractorNumber: { required: false },
	trailerNumber: { required: false },
	weight: {
		required: false,
		mustBeANumber: true,
	},
	driverName: { required: false },
	checkOutComments: {},
	checkedLicense: { required: false },
	checkOutDateTime: { readOnly: false },
	duration: { readOnly: false },
	checkedOutBy: { readOnly: false },
}

export function createGeneralInfoForm(
	state: IStateDockSchedulerAppointmentDetails,
): IAppointmentDetailsGeneralInformation {
	const { shipment, appointment } = state
	let bols: string[] = [],
		poNumbers: string[] = [],
		soNumbers: string[] = [],
		handlingUnits = 0,
		handlingUnitTypes: string[] = [],
		weight = 0
	if (shipment) {
		const payloads = shipment.payloads.filter(
			(payload) =>
				payload.originStop.metaData.locationId === appointment.locationId ||
				payload.destinationStop.metaData.locationId === appointment.locationId,
		)

		payloads.forEach((payload) => {
			bols.push(payload.bolIdentifier)
			poNumbers = poNumbers.concat(payload.purchaseOrders)
			soNumbers = soNumbers.concat(payload.salesOrders)
			handlingUnits += l.sumBy(
				payload.containers,
				(container) => container.count,
			)
			handlingUnitTypes = handlingUnitTypes.concat(
				payload.containers.map((container) =>
					tHandlingUnitType(container.type),
				),
			)
			weight += payload.totalWeight
		})
		bols = l.uniq(bols)
		poNumbers = l.uniq(poNumbers)
		soNumbers = l.uniq(soNumbers)
		handlingUnitTypes = l.uniq(l.compact(handlingUnitTypes))
	} else {
		bols = appointment.shipmentInfo.bolIdentifiers
		poNumbers = appointment.shipmentInfo.purchaseOrders
		soNumbers = appointment.shipmentInfo.salesOrders
		handlingUnits = appointment.shipmentInfo.handlingUnits
		handlingUnitTypes = appointment.shipmentInfo.handlingUnitsTypes
		weight = appointment.shipmentInfo.expectedWeight
	}

	const dateTimeForAppointment = DateTime.fromISO(
		appointment.startTime,
	).setZone(
		zipToTimezone(
			sosDockScheduler.getSos().getState().currentLocation.defaults
				.defaultDeliveryAddress.address.zip,
		),
	)
	const endDateTime = dateTimeForAppointment.plus({
		minutes: appointment.scheduledDuration,
	})
	const dateTime = `${dateTimeForAppointment.toFormat(
		'D',
	)} ${dateTimeForAppointment.toFormat('t')} ${tString(
		'to',
		'common',
	)} ${endDateTime.toFormat('t')}`

	return {
		slid: shipment ? shipment.identifier : null,
		dateTime,
		dock: _idx(
			() =>
				l.find(
					sosDockScheduler.getSos().getState().docks,
					(dock) => dock.id === appointment.dockId,
				).nickName,
		),
		carrier: appointment.trailerInfo?.carrier,
		mode: tMode(shipment ? shipment.mode : appointment.shipmentInfo.mode),
		stopType: tStopType(appointment.stopType),
		equipmentType: tEquipmentType(
			shipment
				? shipment.equipmentType
				: appointment.shipmentInfo.equipmentType,
		),
		proNumber: shipment
			? shipment.proNumber
			: appointment.shipmentInfo.proNumber,
		bolNumbers: bols,
		poNumbers,
		soNumbers,
		handlingUnits,
		handlingUnitTypes,
		expectedWeight: weight,
	}
}

export function createCheckInForm(
	state: IStateDockSchedulerAppointmentDetails,
): IAppointmentDetailsCheckInForm {
	const { appointment } = state
	const checkInLog = getLastAppointmentAuditLogsForStatusTransition(
		appointment,
		['not-arrived'],
		['arrived'],
	)
	return {
		dock:
			appointment.status === 'not-arrived' || state.editingStage === 'checkIn'
				? appointment.dockId
				: _idx(
						() =>
							l.find(
								sosDockScheduler.getSos().getState().docks,
								(dock) => dock.id === appointment.dockId,
							).nickName,
				  ),
		tractorNumber: appointment.trailerInfo?.tractorNumber,
		trailerNumber: appointment.trailerInfo?.trailerNumber,
		weight: appointment.arrivalWeight,
		driverName: appointment.trailerInfo?.driverName,
		checkInComments: _idx(() => checkInLog.comment),
		checkedLicense: appointment.trailerInfo?.driversLicenseCheck,
		checkInDateTime: getFormattedDateTimeStringFromLog(checkInLog),
		checkedInBy: _idx(() => checkInLog.username),
	}
}

export function createLoadStartForm(
	state: IStateDockSchedulerAppointmentDetails,
): IAppointmentDetailsLoadStartForm {
	const { appointment } = state
	const loadStartLog = getLastAppointmentAuditLogsForStatusTransition(
		appointment,
		['arrived'],
		['loading', 'unloading'],
	)
	return {
		loadedBy:
			appointment.stopType === 'pickup'
				? appointment.loadedBy
				: appointment.unloadedBy,
		loadStartComments: _idx(() => loadStartLog.comment),
		loadStartDateTime: getFormattedDateTimeStringFromLog(loadStartLog),
		loadStartRecordedBy: _idx(() => loadStartLog.username),
	}
}

export function createLoadEndForm(
	state: IStateDockSchedulerAppointmentDetails,
): IAppointmentDetailsLoadEndForm {
	const { appointment } = state
	const loadEndLog = getLastAppointmentAuditLogsForStatusTransition(
		appointment,
		['loading', 'unloading'],
		['loaded', 'unloaded'],
	)
	return {
		handlingUnitsLoaded: _idx(() => appointment.shipmentInfo.handlingUnits),
		cargoWeight: _idx(() => appointment.shipmentInfo.expectedWeight),
		sealNumber: appointment.trailerInfo?.sealNumber,
		loadEndComments: _idx(() => loadEndLog.comment),
		loadEndDateTime: getFormattedDateTimeStringFromLog(loadEndLog),
		loadEndRecordedBy: _idx(() => loadEndLog.username),
	}
}

export function createCheckOutForm(
	state: IStateDockSchedulerAppointmentDetails,
): IAppointmentDetailsCheckOutForm {
	const { appointment } = state
	const isCheckingOut =
		appointment.status === 'unloaded' || appointment.status === 'loaded'
	const departedLog = getLastAppointmentAuditLogsForStatusTransition(
		appointment,
		['loaded', 'unloaded'],
		['departed'],
	)
	return {
		tractorNumber: isCheckingOut ? '' : appointment.trailerInfo?.tractorNumber,
		trailerNumber: isCheckingOut ? '' : appointment.trailerInfo?.trailerNumber,
		weight: appointment.departureWeight,
		driverName: isCheckingOut ? '' : appointment.trailerInfo?.driverName,
		checkOutComments: _idx(() => departedLog.comment),
		checkedLicense: isCheckingOut
			? false
			: appointment.trailerInfo?.driversLicenseCheck,
		checkOutDateTime: getFormattedDateTimeStringFromLog(departedLog),
		duration: departedLog
			? Interval.fromDateTimes(
					DateTime.fromISO(
						getLastAppointmentAuditLogsForStatusTransition(
							appointment,
							['not-arrived'],
							['arrived'],
						).timestamp,
					),
					DateTime.fromISO(departedLog.timestamp),
			  )
					.toDuration('hour')
					.toFormat("h'h 'mm'm'")
			: null,
		checkedOutBy: _idx(() => departedLog.username),
	}
}

function getFormattedDateTimeStringFromLog(
	log: apiTypes.AppointmentEventResponse,
): string {
	if (!log) {
		return ''
	} else {
		const dateTime = DateTime.fromISO(log.timestamp)
		return `${dateTime.toFormat('D')} ${dateTime.toFormat('t')}`
	}
}

export function getLastAppointmentAuditLogsForStatusTransition(
	appointment: apiTypes.AppointmentResponse,
	previousStatuses: apiTypes.AppointmentResponse['status'][],
	currentStatuses: apiTypes.AppointmentResponse['status'][],
): apiTypes.AppointmentEventResponse {
	const matchingAuditLogs = l.filter(
		appointment.auditTrail,
		(event) =>
			previousStatuses.indexOf(event.previousStatus) > -1 &&
			currentStatuses.indexOf(event.currentStatus) > -1,
	)
	return matchingAuditLogs[matchingAuditLogs.length - 1]
}
