import React, { useState } from 'react'
import { Table } from 'react-bootstrap'
import { Modal } from 'ui/components/common/modal/Modal'
import { apiProviderInvoice, apiProviders, apiTypes } from 'ui/api'
import { sosToast } from 'ui/common/components/toast'
import { IconButton, solidIcons } from 'ui/components/common/icon'
import { Input } from 'ui/components/common/input'
import { OkCancelButtons } from 'ui/components/common/okCancelButtons'
import { t, tString } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight'
import { CalendarInput } from 'ui/components/shared/ShipmentStopCard'
import { FC } from 'ui/FunctionalComponent'
import * as classes from './ProviderInvoiceTitle.module.scss'
import { AsyncTypeahead, TypeaheadOption } from 'ui/common/components/typeahead'
import { processSearchString } from 'ui/lib/elasticSearch'
import { IRequestState } from 'ui/api/requestState'
import { tProviderName } from 'ui/components/i18n/commonTranslations'
import { UIProviderInvoice } from '../../CustomerInvoicePage'

export const ProviderInvoiceTitle: FC = (props: {
	brokerShipment: apiTypes.BrokerShipmentResponse
	brokerInvoice: apiTypes.BrokerInvoiceResponse
	providerInvoice: UIProviderInvoice
	onUpdate: (
		updateMode: 'upsert' | 'delete',
		data?: apiTypes.ProviderInvoiceResponse,
	) => void
	tPrefix?: string
}) => {
	const { brokerInvoice, providerInvoice, onUpdate, tPrefix } = props

	const [invoiceNumber, setInvoiceNumber] = useState<string>(
		providerInvoice.invoiceNumber,
	)
	const [invoiceDate, setInvoiceDate] = useState<string>(
		providerInvoice.invoiceDate,
	)

	const [editInvoice, setEditInvoice] = useState<boolean>(false)

	const [showModal, setShowModal] = useState<boolean>(false)

	const [isLoading, setIsLoading] = useState<boolean>(false)

	const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false)

	const formatDate = (date: string): string => {
		const dateArray = date.split('-')

		return dateArray[1] + '-' + dateArray[2] + '-' + dateArray[0]
	}

	const updateProviderInvoice = async (): Promise<void> => {
		setIsLoading(true)

		const updatedProviderInvoice = {
			...providerInvoice,
			invoiceNumber: invoiceNumber,
			invoiceDate: invoiceDate,
			providerId: providerInvoice?.providerId,
		}

		const response = await apiProviderInvoice.updateProviderInvoice(
			providerInvoice.id,
			updatedProviderInvoice,
		)

		if (response.error) {
			sosToast.sendApiErrorResponseToast(response)
		} else {
			onUpdate('upsert', response.data)
		}

		setIsLoading(false)
	}

	const deleteProviderInvoice = async (): Promise<void> => {
		setIsLoading(true)

		const response = await apiProviderInvoice.deleteProviderInvoice(() => {},
		providerInvoice.id)

		if (response.error) {
			sosToast.sendApiErrorResponseToast(response)
		} else {
			onUpdate('delete')
		}

		setIsLoading(false)
	}

	return (
		<div className={classes.providerInvoiceTitle}>
			<div className={classes.header}>
				<AsyncTypeahead
					testId={'provider-invoice-providerName-input'}
					size={'small'}
					options={[]}
					onSearch={async (searchTerm: string) => {
						const processedSearchTerm = processSearchString(searchTerm)
						const response: IRequestState<apiTypes.ProviderListResponse> = await apiProviders.getProviders(
							25,
							0,
							`name:${processedSearchTerm}`,
						)

						if (response.error) {
							sosToast.sendApiErrorResponseToast(
								response,
								tString('providerFetchError', 'common'),
							)

							return []
						}

						let responseOptions: TypeaheadOption[] = []
						if (response.data) {
							responseOptions = response.data.entities.map((c) => ({
								value: c.id,
								label: c.name || tProviderName(c.providerName) || c.id,
							}))
						}

						return responseOptions
					}}
					onChange={async (selected: TypeaheadOption) => {
						providerInvoice.providerId = selected?.value
						providerInvoice.providerName = selected?.label
					}}
					isClearable={true}
					useCache={true}
					disabled={!editInvoice}
					value={providerInvoice?.providerName}
					className={classes.providerName}
				/>

				{brokerInvoice?.invoiceStatus === 'review' && (
					<div className={classes.iconButtons}>
						<IconButton
							icon={editInvoice ? solidIcons.faCheck : solidIcons.faPencilAlt}
							buttonClassName={editInvoice ? classes.save : classes.edit}
							color={editInvoice ? 'green' : 'black'}
							onClick={async () => {
								if (editInvoice) {
									await updateProviderInvoice()
									setEditInvoice(false)
								} else {
									setEditInvoice(true)
								}
							}}
							spin={isLoading}
							disabled={showModal}
							testId={'provider-invoice-header-edit-save'}
						></IconButton>
						<IconButton
							icon={solidIcons.faTimes}
							buttonClassName={classes.cancel}
							color={'red'}
							onClick={async () => {
								if (editInvoice) {
									setEditInvoice(false)
								} else {
									setShowModal(true)
								}
							}}
							testId={'provider-invoice-header-cancel'}
						></IconButton>
					</div>
				)}
			</div>
			<div className={classes.subheader}>
				<Table>
					<tbody>
						<tr>
							<td>
								<Input
									value={`${tString('invoiceNumber', tPrefix)}:`}
									readOnly={true}
									className={classes.disabledInput}
								/>
							</td>
							<td>
								<Input
									value={invoiceNumber}
									onChange={(newVal: string) => setInvoiceNumber(newVal)}
									autofocus={editInvoice}
									readOnly={!editInvoice}
									className={classes.invoiceNumber}
								/>
							</td>
							<td>
								<Input
									value={`${tString('dueDate', tPrefix)}:`}
									readOnly={true}
									className={classes.disabledInput}
								/>
							</td>
							<td>
								<CalendarInput
									dateString={invoiceDate}
									isOpen={isCalendarOpen}
									onChangeText={(newVal: string) => {
										setInvoiceDate(formatDate(newVal))
										setIsCalendarOpen(false)
									}}
									onChangeOpen={(isOpen: boolean) => {
										setIsCalendarOpen(!isCalendarOpen)
									}}
									readOnly={!editInvoice}
									hasToday={false}
								/>
							</td>
						</tr>
					</tbody>
				</Table>
			</div>
			<Modal
				content={() => (
					<div data-testid={'provider-invoice-delete-modal'}>
						<p>{t('deleteThisInvoice?', tPrefix)}</p>
						<AlignRight>
							<OkCancelButtons
								isValid={true}
								isSpinning={isLoading}
								okColor={'green'}
								ok={t('ok', tPrefix)}
								onOk={async () => {
									await deleteProviderInvoice()
									setShowModal(false)
								}}
								okTestId={'provider-invoice-delete-modal-ok'}
								cancel={t('cancel', tPrefix)}
								onCancel={() => {
									setShowModal(false)
								}}
								cancelTestId={'provider-invoice-delete-modal-cancel'}
							></OkCancelButtons>
						</AlignRight>
					</div>
				)}
				isOpen={showModal}
				onModalClose={() => {}}
				title={t('confirmDelete', tPrefix)}
			/>
		</div>
	)
}
