import { LatLngTuple, DivIcon } from 'leaflet'
import { decodeValhallaRoute } from './decodeValhallaRoute'
import { leafletMapIcons } from 'ui/components/shared/leaflet-map/utils'
import { l } from 'ui/lib/lodashImports'
import { LeafletMapStop } from 'ui/components/shared/leaflet-map/utils/leafletMapStop'
import { Colors } from 'ui/theme/theme'

export interface RouteMarkerMetadata {
	position: {
		lat: number
		lng: number
	}
	icon: DivIcon
}

export interface RoutingElements {
	polylinePositions: LatLngTuple[]
	routeMarkersMetadata: RouteMarkerMetadata[]
	truckMarkerPosition?: {
		lat: number
		lng: number
	}
}

const getMapStopMarker = (color?: Colors): DivIcon => {
	switch (color) {
		case 'green':
			return leafletMapIcons.getStartMarkerIcon()
		case 'red':
			return leafletMapIcons.getEndMarkerIcon()
		default:
			return leafletMapIcons.getDefaultMarkerIcon()
	}
}

export const extractRoutePolylineAndMarkers = (
	stopsInOrder: LeafletMapStop[],
	shape?: string,
): RoutingElements => {
	let positions: LatLngTuple[], markerPosition: { lat: number; lng: number }
	if (shape) {
		positions = decodeValhallaRoute(shape)
		const midpoint = positions[Math.floor(positions.length / 2)]
		markerPosition = { lat: midpoint[0], lng: midpoint[1] }
	} else {
		positions = l.map(stopsInOrder, (stop) => [stop.lat, stop.lng])
	}

	const routeMarkersMetadata: RouteMarkerMetadata[] = l.map(
		stopsInOrder,
		(stop, stopIdx) => ({
			position: { lat: stop.lat, lng: stop.lng },
			icon: getMapStopMarker(stop.markerColor),
		}),
	)

	return {
		polylinePositions: positions,
		routeMarkersMetadata,
		truckMarkerPosition: markerPosition,
	}
}
