import { IFormMetadataCollection } from 'ui/components/form/IFormMetadata'
import { IBookForm } from 'ui/forms/formBook'

export interface IProductCatalogForm extends IBookForm {}

export const productCatalogFormMetadata: IFormMetadataCollection<IProductCatalogForm> = {
	id: {
		readOnly: true,
	},
	name: {
		required: true,
	},
}
