import { IFormMetadataCollection } from 'ui/components/form/IFormMetadata'

export interface ILightningIntegrationSetPasswordForm {
	password?: string
	confirmPassword?: string
}

export const lightningIntegrationSetPasswordFormMetadata: IFormMetadataCollection<ILightningIntegrationSetPasswordForm> = {
	password: {
		label: 'password',
		required: true,
	},
	confirmPassword: {
		label: 'confirmPassword',
		required: true,
	},
}
