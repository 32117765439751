import { FC } from 'app/FunctionalComponent'
import React, { useState } from 'react'
import { apiShipments, apiTypes } from 'ui/api'
import { Button } from 'ui/components/common/button/Button'
import { DocumentViewer } from 'ui/components/common/modal'
import { tDocumentType } from 'ui/components/i18n/commonTranslations'
import { t, tString } from 'ui/components/i18n/i18n'
import * as tableClasses from 'ui/components/rates-table/RatesTable.module.scss'
import { l } from 'ui/lib/lodashImports'
import { voidTrackingNumber } from '../../../shipper/functions'
import { sosToast } from 'common/components/toast'
import { log } from 'ui/lib/log'

const tPrefixTable = 'page.shipmentProfile.labels'

interface UIContainers extends apiTypes.ContainerResponse {
	shippingDocuments?: apiTypes.ShippingDocumentResponse[]
}

export const ShipperShipmentProfileLabels: FC = (props: {
	shipment: apiTypes.ShipmentResponse
}) => {
	const { shipment } = props
	const [documentUrl, setDocumentUrl] = useState<string>(null)
	const [isGettingLabelImage, setIsGettingLabelImage] = useState<boolean>(false)
	const [trackingNumbersBeingVoided, setTrackingNumbersBeingVoided] = useState<
		string[]
	>([])

	return (
		<React.Fragment>
			{/* Not Included in ticket */}
			{/*<PrintStationDropdown />   */}
			<table
				className={tableClasses.tableRates}
				cellPadding={0}
				cellSpacing={0}
				data-testid='rates-table'
			>
				<thead>
					<tr>
						<th>{t('weight', tPrefixTable)}</th>
						<th>{t('dimensions', tPrefixTable)}</th>
						<th>{t('tracking', tPrefixTable)}</th>
						<th>{t('labelsAndDocuments', tPrefixTable)}</th>
						<th>{t('packingSlip', tPrefixTable)}</th>
						<th>{t('void', tPrefixTable)}</th>
					</tr>
				</thead>
				<tbody>
					{l.map(shipment.payloads, (payload) =>
						payload.containers?.map((container) =>
							container.containers?.map((parcel: UIContainers, parcelIdx) => {
								return (
									<tr key={parcelIdx}>
										<td>{parcel.packedWeight}</td>
										<td>{`${parcel.length}" x ${parcel.width}" x ${parcel.height}"`}</td>
										<td>{parcel.trackingNumber}</td>
										<td>
											{l.map(
												l.sortBy(parcel.shippingDocuments, (doc) => doc.type),
												(shippingDocument, shippingDocIdx) => (
													<div key={shippingDocument.id || shippingDocIdx}>
														<Button
															bootstrapStyles={true}
															isSmall={true}
															color='green'
															isOutline={shippingDocument.type !== 'Label'}
															isSpinning={isGettingLabelImage}
															className={tableClasses.buttonSmall}
															onClick={async () => {
																setIsGettingLabelImage(true)
																if (
																	shippingDocument.images &&
																	shippingDocument.images.length
																) {
																	setDocumentUrl(
																		shippingDocument.images[0].image,
																	)
																} else {
																	const result = await apiShipments.printDocument(
																		shippingDocument.id,
																	)
																	if (result.error) {
																		sosToast.sendToast({
																			type: 'danger',
																			header: tString(
																				'errorPrintingDocument',
																				tPrefixTable,
																			),
																		})
																		log('error printing document', result.error)
																	} else {
																		sosToast.sendToast({
																			type: 'info',
																			header: tString(
																				'printingDocument',
																				tPrefixTable,
																			),
																		})
																	}
																}
																setIsGettingLabelImage(false)
															}}
														>{`${tString(
															'print',
															tPrefixTable,
														)} ${tDocumentType(
															shippingDocument.type,
														)} ${(shippingDocument.identifiers &&
															shippingDocument.identifiers[0].identifier) ||
															''}`}</Button>
													</div>
												),
											)}
										</td>
										<td />
										<td>
											<Button
												bootstrapStyles={true}
												isSmall={true}
												color='red'
												isSpinning={l.includes(
													trackingNumbersBeingVoided,
													parcel.trackingNumber,
												)}
												isDisabled={!parcel.trackingNumber}
												onClick={async () => {
													setTrackingNumbersBeingVoided(
														trackingNumbersBeingVoided.concat(
															parcel.trackingNumber,
														),
													)
													await voidTrackingNumber(
														shipment.id,
														parcel.trackingNumber,
													)
													setTrackingNumbersBeingVoided(
														l.pull(
															l.cloneDeep(trackingNumbersBeingVoided),
															parcel.trackingNumber,
														),
													)
												}}
											>
												{t('void', tPrefixTable)}
											</Button>
										</td>
									</tr>
								)
							}),
						),
					)}
				</tbody>
			</table>
			{documentUrl && (
				<DocumentViewer
					isOpen={true}
					documentUrl={documentUrl}
					onModalClose={() => setDocumentUrl(null)}
					base64Specification='application/pdf'
				/>
			)}
		</React.Fragment>
	)
}
