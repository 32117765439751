import { FC } from 'app/FunctionalComponent'
import React, { useState } from 'react'
import { Modal } from 'ui/components/common/modal/Modal'
import { OkCancelButtons } from 'ui/components/common/okCancelButtons/OkCancelButtons'
import { FormStackedTextInput } from 'ui/components/form'
import { IFormData } from 'ui/components/form/IFormData'
import { t } from 'ui/components/i18n/i18n'
import { sosDockConfigPage } from 'ui/pages/dock-scheduler/state'
import { tPrefixDockConfig } from './DockConfigurationPage'

export const NewDockModal: FC = (props: {
	isOpen: boolean
	newDock: { name: string }
}) => {
	const formData: IFormData<{ name: string }> = {
		form: { name: props.newDock.name },
		metadata: { name: { required: true } },
		tPrefix: tPrefixDockConfig,
		onUpdateForm: (_field, newVal) => {
			sosDockConfigPage.updateNewDockName(newVal)
		},
	}

	const isValid = formData.form.name !== ''
	const [isAddingDock, setIsAddingDock] = useState(false)

	return (
		<Modal
			content={() => (
				<div data-testid='addDockModalContent'>
					<FormStackedTextInput field='name' formData={formData} />
					<OkCancelButtons
						ok={t('addNewDock', tPrefixDockConfig)}
						isValid={isValid}
						okColor='green'
						onOk={async () => {
							setIsAddingDock(true)
							await sosDockConfigPage.addDock()
							setIsAddingDock(false)
						}}
						okTestId={'addNewDock'}
						isSpinning={isAddingDock}
					/>
				</div>
			)}
			isOpen={props.isOpen}
			onModalClose={() => sosDockConfigPage.updateShowNewDockModal(false)}
			title={t('newDock', tPrefixDockConfig)}
		/>
	)
}
