import { l } from 'ui/lib/lodashImports'
import { IDataTableHeader } from 'ui/components/table'

export function elasticSearchSortBuilder<T>(
	sortOn: string,
	sortReversed: boolean,
	defaultSorts: string,
	availableHeaders: IDataTableHeader<T>[],
): string {
	const esSorts: string[] = l.split(defaultSorts, ',')
	if (sortOn) {
		const header = l.find(availableHeaders, (c) => c.field === sortOn)
		if (!header || !header.elasticSearchKey) {
			throw new Error(`Misconfiguration: Unknown table header ${sortOn}.`)
		}
		let sortKey = header.elasticSearchKey
		if (header.useKeyword) {
			sortKey += '.keyword'
		}
		// ensure sorted field isn't already a default -- if it is, remove the default
		l.remove(esSorts, (c) => l.includes(c, sortKey))
		esSorts.unshift(`${sortKey}:${sortReversed ? 'desc' : 'asc'}`)
	}
	return l.join(esSorts, ',')
}
