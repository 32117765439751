import React, { useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { apiBrokerConfig, apiTmsServiceConfig, apiTypes } from 'ui/api'
import { sosToast } from 'ui/common/components/toast'
import { Button } from 'ui/components/common/button'
import { Checkbox } from 'ui/components/common/checkbox'
import { Input } from 'ui/components/common/input'
import { FormStackedTextInput, IFormData } from 'ui/components/form'
import { t } from 'ui/components/i18n/i18n'
import { Spacer } from 'ui/components/layout/spacer'
import { FC } from 'ui/FunctionalComponent'
import { l } from 'ui/lib/lodashImports'
import * as classes from './GenerateCodeCard.module.scss'

export const GenerateCodeCard: FC = (props: {
	codeType: 'provider' | 'customer'
	code: apiTypes.UniqueCodeConfigResponse
	setCode: React.Dispatch<
		React.SetStateAction<apiTypes.UniqueCodeConfigResponse>
	>
	tPrefix?: string
}) => {
	const { codeType, code, setCode, tPrefix } = props

	const [isLoading, setIsLoading] = useState<boolean>(false)

	const [autoGenerateCode, setAutoGenerateCode] = useState<boolean>(
		code?.autoGenerate,
	)

	const [nextNumber, setNextNumber] = useState<number>(code?.currentValue + 1)

	const chargeCodeForm: IFormData<apiTypes.UniqueCodeConfigResponse> = {
		form: code,
		metadata: {
			autoGenerate: {},
			prefix: {},
			currentValue: {},
			suffix: {},
			numberOfDigits: {},
		},
		onUpdateForm: (field: string, value: any) => {
			const updatedFormData = l.cloneDeep(code)
			updatedFormData[field] = value
			setCode(updatedFormData)
		},
		tPrefix,
	}

	const updateGeneratedCode = async (): Promise<void> => {
		setIsLoading(true)

		if (codeType === 'provider') {
			const updateRequest: apiTypes.TmsServiceConfigRequest = {
				providerCodeConfig: {
					autoGenerate: autoGenerateCode,
					prefix: chargeCodeForm.form.prefix,
					currentValue: nextNumber - 1,
					suffix: chargeCodeForm.form.suffix,
					numberOfDigits: chargeCodeForm.form.numberOfDigits,
				},
			}

			const response = await apiTmsServiceConfig.updateTmsServiceConfig(
				updateRequest,
			)

			if (response.error) {
				sosToast.sendApiErrorResponseToast(response)
			}
		} else if (codeType === 'customer') {
			const updateRequest: apiBrokerConfig.BrokerConfigRequest = {
				clientConfigCode: {
					autoGenerate: autoGenerateCode,
					prefix: chargeCodeForm.form.prefix,
					currentValue: nextNumber - 1,
					suffix: chargeCodeForm.form.suffix,
					numberOfDigits: chargeCodeForm.form.numberOfDigits,
				},
			}

			const response = await apiBrokerConfig.updateBrokerConfig(updateRequest)

			if (response.error) {
				sosToast.sendApiErrorResponseToast(response)
			}
		}

		setIsLoading(false)
	}

	const pad = chargeCodeForm.form?.numberOfDigits ?? 1
	const number = nextNumber
	const sequence = number?.toString().padStart(pad, '0')

	return (
		<Card className={classes.generateCodeCard}>
			<Card.Header>
				<Row className={classes.generateCodeTitle}>
					<Col>{t(`${codeType}Codes`, tPrefix)}</Col>
				</Row>
			</Card.Header>
			<Card.Body>
				<>
					<Row className={classes.generateCodeCheckbox}>
						<Col xs={12}>
							<Checkbox
								value={autoGenerateCode}
								onChange={async (bool: boolean) => {
									setAutoGenerateCode(bool)
								}}
								readOnly={isLoading}
								testId={`${codeType}-checkbox`}
							>
								{t(`autoGenerate ${codeType}Code`, tPrefix)}
							</Checkbox>
						</Col>
					</Row>
					<Spacer height={'20px'} />
					<FormStackedTextInput
						formData={chargeCodeForm}
						field={'prefix'}
						testId={`${codeType}-prepend`}
					/>
					<label>{t(`nextNumber`, tPrefix)}</label>
					<Input
						value={nextNumber.toString()}
						onChange={(newVal: string) => {
							setNextNumber(parseInt(newVal))
						}}
						testId={`${codeType}-next-number`}
					/>
					<FormStackedTextInput
						formData={chargeCodeForm}
						field={'suffix'}
						testId={`${codeType}-append`}
					/>
					<FormStackedTextInput
						formData={chargeCodeForm}
						field={'numberOfDigits'}
						testId={`${codeType}-numberOfDigits`}
					/>
					<Spacer height={'20px'} />
					<div className={classes.previewContainer}>
						<label className={classes.preview}>
							Preview:{' '}
							<span data-testid={`${codeType}-code-preview`}>
								{`${chargeCodeForm.form?.prefix ||
									''}${sequence}${chargeCodeForm.form?.suffix || ''}`}
							</span>
						</label>
						<Spacer width={'10px'} />
						<Button
							color={'green'}
							onClick={async () => {
								await updateGeneratedCode()
							}}
							isSpinning={isLoading}
							testId={`${codeType}-save-button`}
						>
							{t('save', tPrefix)}
						</Button>
					</div>
				</>
			</Card.Body>
		</Card>
	)
}
