import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { Select } from 'ui/components/common/select'

export const RfpDataTableContractSelector: FC = (props: {
	contractList: string[]
	selectedContract: string
	testId?: string
	onChange: (val: string) => void
}) => {
	const { contractList, selectedContract, testId, onChange } = props
	return (
		<Select
			options={contractList}
			value={selectedContract}
			testId={testId}
			onChange={onChange}
		/>
	)
}
