import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { useOnce } from 'ui/components/hooks'
import { Center } from 'ui/components/layout/center'
import { sosApiDocs } from 'ui/state'
import styles from './ApiExamples.module.scss'
import { Examples } from './Examples'
import { Menu } from './Menu'

export const ApiV6Examples: FC = (props: {}) => {
	const state = sosApiDocs.useSubscribe()

	useOnce(async () => {
		await sosApiDocs.fetchExamples()
	})

	return (
		<div className={styles.container}>
			{/* <link
        href='https://fonts.googleapis.com/css?family=Montserrat:300,400,700|Roboto:300,400,700'
        rel='stylesheet'
      ></link> */}

			<div className={styles.leftArea}>
				<img
					className={styles.logo}
					src='https://s3.amazonaws.com/freightspoke-images/images/logos/swanleap.png'
					alt='SwanLeap Logo'
				/>

				<div>
					<Center>
						<a href='http://tms3.swanleap.com/api/v6/swagger/index.html'>
							Back to API Docs
						</a>
					</Center>
				</div>
				<div>
					<Menu
						data={sosApiDocs.getData()}
						selectedMenu={state.selectedMenu}
						selectedSubMenu={state.selectedSubMenu}
					/>
				</div>
			</div>
			<div className={styles.rightArea}>
				{/* <h1 className={styles.h1}>Examples</h1> */}

				<h1 className={styles.h1}>
					{state.selectedMenu}{' '}
					{state.selectedSubMenu && (
						<span>&ndash; {state.selectedSubMenu}</span>
					)}
				</h1>

				<Examples
					data={sosApiDocs.getData()}
					selectedMenu={state.selectedMenu}
					selectedSubMenu={state.selectedSubMenu}
				/>
			</div>
		</div>
	)
}
