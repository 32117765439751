import { FC } from 'app/FunctionalComponent'
import React, { useState } from 'react'
import { t } from 'ui/components/i18n/i18n'
import { IFormData } from 'ui/components/form/IFormData'
import { apiProviders, apiTypes } from 'ui/api'
import {
	getCountryNameAndCodeList,
	getRegionsForCountry,
} from 'ui/data/countryAndRegionUtils'
import { l } from 'ui/lib/lodashImports'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { Button } from 'ui/components/common/button'
import { FormStackedSelect, FormStackedTextInput } from 'ui/components/form'
import { ISelectOptions } from 'ui/components/common/select'
import * as classes from './AddressCard.module.scss'
import { Spacer } from 'ui/components/layout/spacer'
import { sosToast } from 'ui/common/components/toast'

export const AddressCard: FC = (props: {
	type: 'billingAddress' | 'remitToAddress'
	title: string
	provider: apiTypes.ProviderResponse
	setProvider: React.Dispatch<React.SetStateAction<apiTypes.ProviderResponse>>
	address: apiTypes.Address
	setAddress: React.Dispatch<React.SetStateAction<apiTypes.Address>>
	regions: string[] | ISelectOptions[]
	setRegions: React.Dispatch<React.SetStateAction<string[] | ISelectOptions[]>>
	tPrefix?: string
}) => {
	const {
		type,
		title,
		provider,
		setProvider,
		address,
		setAddress,
		regions,
		setRegions,
		tPrefix,
	} = props

	const [isUpdating, setIsUpdating] = useState(false)

	const formData: IFormData<apiTypes.Address> = {
		form: address,
		metadata: {
			name: {},
			email: {},
			street1: {},
			street2: {},
			city: {},
			state: {},
			zip: {},
			country: {
				options: getCountryNameAndCodeList().map((countryData) => {
					return {
						label: countryData.countryName,
						value: countryData.countryCode,
					}
				}),
			},
			phone: {},
		},
		tPrefix: tPrefix,
		onUpdateForm: (field: string, newVal) => {
			const newAddress = l.cloneDeep(address)
			newAddress[field] = newVal
			setAddress(newAddress)

			if (field === 'country') {
				setRegions(getRegionsForCountry(newVal, true, true))
			}
		},
	}

	return (
		<Container>
			<Card>
				<Card.Header>
					<Row className={classes.cardTitle}>
						<Col>{t(title, tPrefix)}</Col>
						<Col className={classes.flexEnd}>
							<Button
								bootstrapStyles={true}
								color='blue'
								onClick={async () => {
									setIsUpdating(true)
									const updatedProvider = l.cloneDeep(provider)

									if (type === 'billingAddress') {
										updatedProvider.billingAddress = address
									} else if (type === 'remitToAddress') {
										updatedProvider.remitToAddress = address
									}

									const response = await apiProviders.updateProvider(
										() => {},
										provider.id,
										updatedProvider,
									)

									if (response.error) {
										sosToast.sendApiErrorResponseToast(response)
									} else {
										setProvider(response.data)
									}

									setIsUpdating(false)
								}}
							>
								{t('save', tPrefix)}
							</Button>
						</Col>
					</Row>
				</Card.Header>
				<Card.Body>
					<Row>
						<Col xs={12}>
							<FormStackedTextInput
								formData={formData}
								field={'name'}
								testId={`${type}-name`}
								hideOptional={true}
								readOnly={isUpdating}
							/>
							<Spacer />
							<FormStackedTextInput
								formData={formData}
								field={'email'}
								testId={`${type}-email`}
								hideOptional={true}
								readOnly={isUpdating}
							/>
							<Spacer />
							<FormStackedTextInput
								formData={formData}
								field={'street1'}
								testId={`${type}-street1`}
								hideOptional={true}
								readOnly={isUpdating}
							/>
							<Spacer />
							<FormStackedTextInput
								formData={formData}
								field={'street2'}
								testId={`${type}-street2`}
								hideOptional={true}
								readOnly={isUpdating}
							/>
							<Spacer />
						</Col>
					</Row>
					<Row>
						<Col xs={6}>
							<FormStackedTextInput
								formData={formData}
								field={'city'}
								testId={`${type}-city`}
								hideOptional={true}
								readOnly={isUpdating}
							/>
							<Spacer />
							<FormStackedTextInput
								formData={formData}
								field={'zip'}
								testId={`${type}-zip`}
								hideOptional={true}
								readOnly={isUpdating}
							/>
							<Spacer />
						</Col>
						<Col xs={6}>
							<FormStackedSelect
								formData={formData}
								field={'state'}
								options={regions}
								testId={`${type}-state`}
								numListOptionsBeforeScroll={5}
								hideOptional={true}
								width='100%'
								readOnly={isUpdating}
							/>
							<Spacer />
							<FormStackedSelect
								formData={formData}
								field={'country'}
								testId={`${type}-country`}
								numListOptionsBeforeScroll={5}
								hideOptional={true}
								width='100%'
								readOnly={isUpdating}
							/>
							<Spacer />
						</Col>
					</Row>
					<Row>
						<Col xs={12}>
							<FormStackedTextInput
								formData={formData}
								field={'phone'}
								testId={`${type}-phone`}
								hideOptional={true}
								readOnly={isUpdating}
							/>
						</Col>
					</Row>
				</Card.Body>
			</Card>
		</Container>
	)
}
