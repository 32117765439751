import { apiShipments, apiTypes } from 'ui/api'
import { getWebsocketURL } from 'ui/pages/shipment-profile/shared/functions/getWebsocketUrl'
import { sosShipmentProfileShipper } from '..'
import { getShipperShipmentProfileUrlState } from '../sosShipmentProfileShipper'

export async function trickleRateShipperShipment(
	websocketUrl: string,
	onComplete: () => void,
): Promise<void> {
	const onProgressRate = (websocketData: apiTypes.RateResponse): void => {
		sosShipmentProfileShipper.setRate(websocketData)
	}
	const onProgressRatingError = (websocketData: apiTypes.RatingError): void => {
		sosShipmentProfileShipper.setRatingError(websocketData)
	}

	const { shipmentId } = getShipperShipmentProfileUrlState()

	if (!websocketUrl) {
		websocketUrl = await getWebsocketURL()
		sosShipmentProfileShipper.setWebsocketUrl(websocketUrl)
	}

	const onMessage = (websocketData: string): void => {
		if (websocketData === 'complete') {
			onComplete()
		}
	}

	await apiShipments.trickleRateShipment(
		onProgressRate,
		onProgressRatingError,
		onMessage,
		sosShipmentProfileShipper.restRateShipment,
		shipmentId,
		websocketUrl,
	)
}
