import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { ndash } from 'ui/components/common'
import { CarrierImage } from 'ui/components/shared/CarrierImage'
import { l } from 'ui/lib/lodashImports'
import styles from './RatesErrors.module.scss'

const removeQuotes = (str): string => {
	str = '' + str
	// Remove surrounding quotes
	if (str.indexOf('"') === 0 && str.lastIndexOf('"') === str.length - 1) {
		str = str.substring(1, str.length - 1)
	}
	return str
}

const RatesErrors: FC = (props: {
	ratesErrors: any
	isOpen: boolean
	onToggle: () => void
}) => {
	const { ratesErrors } = props

	if (!ratesErrors || ratesErrors.length === 0) {
		return null
	}

	return (
		<div>
			<div className={styles.header} onClick={props.onToggle}>
				<span className={styles.isOpen}>{props.isOpen ? ndash : '+'}</span>
				No rate quotes received from {ratesErrors.length} providers
			</div>
			{props.isOpen && (
				<table className={styles.table}>
					<thead>
						<tr>
							<th className={styles.carrierColumn}>Carrier</th>
							<th>Response</th>
						</tr>
					</thead>
					<tbody>
						{l.map(ratesErrors, (c, cIdx) => (
							<tr key={cIdx}>
								<td>
									<CarrierImage carrier={c.carrier} />
								</td>
								<td data-testid={`rating-error-${cIdx}`}>
									{removeQuotes(c.message)}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			)}
		</div>
	)
}

export { RatesErrors }
