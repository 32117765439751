import { apiAddressBook } from 'ui/api/'
import {
	createDefaultDataTableState,
	IDataTableState,
} from 'ui/components/table/IDataTableState'
import { IAddressBook } from 'ui/lib/books/IAddressBook'
import { IAddressBookEntry } from 'ui/lib/books/IAddressBookEntry'
import { r } from 'ui/lib/ramdaImports'
import { delayedTask } from 'ui/pages/shipments/state'
import {
	copyStatePagerFromElasticsearch,
	createPagerSosMeta,
	getTakeAndSkip,
	IStatePager,
} from '../state/paging'
import { sosUser } from 'ui/state'
import { createSos2 } from 'ui/lib/state/sos2/sos2'
import { createDelayedTask } from 'ui/pages/shipments/state/delayedTask'
import { elasticSearchBuilder, processSearchString } from 'ui/lib/elasticSearch'
import { l } from 'ui/lib/lodashImports'

export const addressBookPageSize = 25

export interface IStateAddressBookModal {
	isModalOpen: boolean
	modalEntries: IAddressBookEntry[]
	modalPager: IStatePager
	updateAddressId?: string
	searchFor?: string
	dataTable: IDataTableState<IAddressBook>
}

export const sos = createSos2<IStateAddressBookModal>('address-book-modal', 2, {
	isModalOpen: { default: false, localStorage: true },
	modalEntries: { default: [], localStorage: true },
	dataTable: {
		default: createDefaultDataTableState({
			sortOn: 'name',
			hiddenHeaders: ['id', 'pageType'],
		}),
		localStorage: true,
	},
	modalPager: createPagerSosMeta(undefined, true),
	searchFor: { default: '' },
})

export function showModal(show = true, id?: string): void {
	sos.change((ds) => {
		ds.isModalOpen = show
		ds.updateAddressId = id
	})
	if (show) {
		updateSearchFor('', true)
	}
}

const searchFor = async (): Promise<void> => {
	const state = sos.getState()

	const { take, skip } = getTakeAndSkip(state.modalPager)

	const qb = elasticSearchBuilder()

	let addressBookId: string

	if (sosUser.isUserBroker()) {
		const selectedClientConfig = sosUser.getSos().getState()
			.selectedClientConfig
		if (selectedClientConfig) {
			addressBookId = selectedClientConfig.addressBookId
			qb.and('bookId', addressBookId)
		}
	}

	const queries: string[] = [qb.toQuery(), processSearchString(state.searchFor)]
	const query = l.join(l.compact(queries), ' AND ')
	const result = await apiAddressBook.searchAddressBook((rs) => {}, {
		take,
		skip,
		query,
	})

	const addressBookEntries: IAddressBookEntry[] = r.map(
		(c) => c.address,
		result.data.entities,
	)

	sos.change((ds) => {
		ds.modalEntries = addressBookEntries
		copyStatePagerFromElasticsearch(ds.modalPager, result.data)
	})
}

export const delayedSearchFor = createDelayedTask(
	'sosAddressBook:delayedSearchFor',
	async () => {
		await searchFor()
		return true
	},
	{},
)

export const setCurrentPage = (newPage: number): void => {
	sos.change((ds) => {
		ds.modalPager.fetchingPageNumber = newPage
	})
	delayedTask.callNow(delayedSearchFor)
}

export const updateSearchFor = (newVal: string, callNow?: boolean): void => {
	sos.change((ds) => {
		ds.searchFor = newVal
		ds.modalPager.fetchingPageNumber = 0
	})
	if (callNow) {
		delayedTask.callNow(delayedSearchFor)
	} else {
		delayedTask.callLater(delayedSearchFor)
	}
}
