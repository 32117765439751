import { IFormMetadataCollection } from 'ui/components/form/IFormMetadata'
import { IBookForm } from 'ui/forms/formBook'

export type IAddressBookForm = IBookForm

export const addressBookFormMetadata: IFormMetadataCollection<IAddressBookForm> = {
	id: {
		readOnly: true,
	},
	name: {
		required: true,
	},
}
