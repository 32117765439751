import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { FilterItem } from 'ui/components/common/filters/FilterItem'
import { Filters } from 'ui/components/common/filters/Filters'
import { solidIcons } from 'ui/components/common/icon'
import { Icon } from 'ui/components/common/icon/Icon'
import { t } from 'ui/components/i18n/i18n'
import { l } from 'ui/lib/lodashImports'
import { sosComponentExamples } from 'ui/state/'
import { IStateComponentExamples } from 'ui/state/sosComponentExamples'
import { Section } from './Section'

const filters = [
	{
		name: 'filter-1',
	},
	{
		name: 'filter-2',
	},
	{
		name: 'filter-3',
	},
	{
		name: 'filter-4',
	},
]

export const SectionFilters: FC = (props: {
	stateComponentExamples: IStateComponentExamples
}) => {
	return (
		<Section
			title={t('page.componentExamples.filters')}
			stateComponentExamples={props.stateComponentExamples}
			name='filters'
		>
			<Filters>
				{l.map(filters, (c) => (
					<FilterItem
						key={c.name}
						isSelected={props.stateComponentExamples.selectedFilter === c.name}
						onClick={() => {
							sosComponentExamples.selectFilter(c.name)
						}}
					>
						{t('page.componentExamples.tabs.' + l.camelCase(c.name))}
						<Icon icon={solidIcons.faCoffee} />
					</FilterItem>
				))}
			</Filters>
		</Section>
	)
}
