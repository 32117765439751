import { sosToast } from 'common/components/toast'
import { apiShipments, apiTypes } from 'ui/api'
import { IRequestState } from 'ui/api/requestState'
import { sosRouter2 } from 'ui/components/common/router'
import { l } from 'ui/lib/lodashImports'
import { createLazySos2 } from 'ui/lib/state/sos2/sos2'

export type ShipperApiCalls =
	| 'awardingShipment'
	| 'resendingAward'
	| 'retractingAward'
	| 'booking'
	| 'resendingBook'
	| 'unbooking'

export interface IStateShipmentProfileShipper {
	shipment: apiTypes.ShipmentResponse
	runningApiCalls: ShipperApiCalls[]
	websocketUrl: string
}

export const getSos = createLazySos2<IStateShipmentProfileShipper>(
	'sosShipmentProfileShipper',
	1,
	() => ({
		shipment: { default: null },
		runningApiCalls: { default: [], localStorage: false },
		websocketUrl: { default: null, localStorage: true },
	}),
)

export const pageInfo = {
	url: '/shipments-v3/shipment-profile/:shipmentId',
	params: {
		shipmentId: {
			name: 'shipmentId',
		},
	},
	queries: {},
}

export const getShipperShipmentProfileUrlState = (): { shipmentId: string } => {
	const shipmentId = sosRouter2.getUrlParam(
		pageInfo,
		pageInfo.params.shipmentId,
	)
	return {
		shipmentId,
	}
}

export const fetchShipment = async (
	id: string,
): Promise<IRequestState<apiTypes.ShipmentResponse>> => {
	if (id !== getSos().getState().shipment?.id) {
		getSos().change((ds) => {
			ds.shipment = null
		})
	}
	const fetchResult = await apiShipments.fetchShipment(() => {}, id, true, true)
	if (fetchResult.data) {
		getSos().change((ds) => {
			ds.shipment = fetchResult.data
		})
	} else if (fetchResult.error) {
		getSos().change((ds) => {
			ds.shipment = null
		})
		sosToast.sendApiErrorResponseToast(fetchResult)
	}
	return fetchResult
}

export const bookShipment = async (
	shipmentId: string,
	rateId: string,
	method: 'api' | 'manual',
): Promise<IRequestState<apiTypes.ShipmentResponse>> => {
	getSos().change((ds) => {
		ds.runningApiCalls.push('booking')
	})
	const bookResult = await apiShipments.bookRate(
		() => {},
		shipmentId,
		rateId,
		method,
	)
	if (bookResult.data) {
		getSos().change((ds) => {
			ds.shipment = bookResult.data
		})
	} else if (bookResult.error) {
		sosToast.sendApiErrorResponseToast(bookResult)
	}
	getSos().change((ds) => {
		l.pull(ds.runningApiCalls, 'booking')
	})
	return bookResult
}

export const setShipmentReference = (
	shipment: apiTypes.ShipmentResponse,
): void => {
	getSos().change((ds) => {
		ds.shipment = shipment
	})
}

export const restRateShipment = async (): Promise<
	apiTypes.ShipmentResponse
> => {
	const { shipmentId } = getShipperShipmentProfileUrlState()

	const result: apiTypes.ShipmentResponse = (
		await apiShipments.rateShipment(() => {}, shipmentId)
	).data

	getSos().change((ds) => {
		ds.shipment = result
	})
	return result
}

export const setRate = (rate: apiTypes.RateResponse): void => {
	getSos().change((ds) => {
		if (ds.shipment?.rates) {
			ds.shipment.rates.push(rate)
		} else {
			ds.shipment.rates = [rate]
		}
	})
}

export const setRatingError = (ratingError: apiTypes.RatingError): void => {
	getSos().change((ds) => {
		if (ds.shipment?.rates) {
			ds.shipment.ratingErrors.push(ratingError)
		} else {
			ds.shipment.ratingErrors = [ratingError]
		}
	})
}

export const setWebsocketUrl = (webSocketUrl: string): void => {
	getSos().change((ds) => {
		ds.websocketUrl = webSocketUrl
	})
}
