import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { FlexItem, FlexRow } from 'ui/components/layout/flexRow'
import { InvoiceDetails } from 'ui/pages/provider-portal/invoice-profile/invoice-details'
import { PaymentDetails } from 'ui/pages/provider-portal/invoice-profile/payment-details'

export const InvoicePaid: FC = (props: {}) => {
	// const state = sosProviderPortal.useSubscribe()  <-- do not delete

	return (
		<React.Fragment>
			<FlexRow>
				<FlexItem>
					<InvoiceDetails />
				</FlexItem>
				<FlexItem>
					<PaymentDetails />
				</FlexItem>
			</FlexRow>
		</React.Fragment>
	)
}
