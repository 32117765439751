import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { log } from 'ui/lib/log'
import { r } from 'ui/lib/ramdaImports'
import { queryStringToObject } from 'ui/lib/state/queryParams'

export type LoginModeType = 'unknown' | 'anonymous' | 'loggedIn'

interface IParsedHref {
	origin: string
	pathname: string
	query: string
	basePath: string
}

interface IRouteInfo {
	route?: IRoute
	isAMatch?: boolean
	params: any
	url: string
}

export interface IRoute {
	key?: string
	urls: string[]
	if?: () => boolean
	renderer: (
		currentLocation: string,
		params: any,
		loginMode: LoginModeType,
	) => React.ReactNode
	onPageLoaded?: (params: any) => void
	isDefault?: boolean
	anonymous?: boolean
}

export const parseHref = (href: string): IParsedHref => {
	let origin = ''
	let query = ''
	let pathname = ''
	let basePath = '/'

	if (href.endsWith('/')) {
		href = href.substring(0, href.length - 1)
	}

	const idxHash = href.indexOf('#')
	if (idxHash !== -1) {
		origin = href.substring(0, idxHash)
		pathname = href.substring(idxHash + 1)
	} else {
		if (href.toLowerCase().startsWith('http')) {
			origin = href
			pathname = ''
		} else {
			origin = ''
			pathname = href
		}
	}

	// Split out basepath
	let protocolEndIndex = origin.indexOf('://')
	if (protocolEndIndex !== -1) {
		protocolEndIndex += 3
		const endingSlash = origin.indexOf('/', protocolEndIndex + 1)
		if (endingSlash !== -1) {
			basePath = origin.substring(endingSlash)
			origin = origin.substring(0, endingSlash)
		}
	}

	const idxQuery = pathname.indexOf('?')
	if (idxQuery !== -1) {
		query = pathname.substring(idxQuery + 1)
		pathname = pathname.substring(0, idxQuery)
	}
	if (pathname.endsWith('/')) {
		pathname = pathname.substring(0, pathname.length - 1)
	}

	return {
		origin,
		pathname,
		query,
		basePath,
	}
}

// split a url string into an array of url segments
export const splitPathnameIntoSegments = (pathname: string): string[] => {
	if (pathname.startsWith('/')) {
		pathname = pathname.substring(1)
	}
	if (pathname.endsWith('/')) {
		pathname = pathname.substring(0, pathname.length - 1)
	}
	const hrefParts = r.split('/', pathname)
	return hrefParts
}

export const isPathnameAMatch = (
	segments: string[],
	pathname: string,
): { match: boolean; params?: any } => {
	const parts = splitPathnameIntoSegments(pathname)
	const params: any = {}

	if (parts.length === 0 && segments.length === 0) {
		// Index match
		return {
			match: true,
			params,
		}
	} else {
		if (segments.length < parts.length) {
			return { match: false }
		}
		for (let idxPart = 0; idxPart < parts.length; idxPart++) {
			if (parts[idxPart].startsWith(':')) {
				const paramName = parts[idxPart].substring(1)
				params[paramName] = segments[idxPart]
			} else if (parts[idxPart] !== segments[idxPart]) {
				return { match: false }
			}
		}
	}
	return {
		match: true,
		params,
	}
}

const _isRouteAMatch = (
	segments: string[],
	route: IRoute,
): { isAMatch: boolean; params: any; url: string } => {
	let matchParams: any = {}
	let matchUrl = ''

	if (route.if) {
		if (r.not(route.if())) {
			return {
				isAMatch: false,
				params: matchParams,
				url: matchUrl,
			}
		}
	}

	const isAMatch = r.any((url) => {
		const m = isPathnameAMatch(segments, url)
		if (m.match) {
			matchUrl = url
			matchParams = m.params
			return true
		}
	}, route.urls)

	return {
		isAMatch,
		params: matchParams,
		url: matchUrl,
	}
}

export const isRouteAMatch = (href: string, route: IRoute): IRouteInfo => {
	const parts = parseHref(href)
	const segments = splitPathnameIntoSegments(parts.pathname)
	return _isRouteAMatch(segments, route)
}

export const getBestRoute = (routes: IRoute[], href: string): IRouteInfo => {
	const parts = parseHref(href)
	const segments = splitPathnameIntoSegments(parts.pathname)

	let route: IRoute = null
	let params: any = {}
	let url = ''

	routes = r.sort(
		(a: IRoute, b: IRoute) => (a.urls[0].length > b.urls[0].length ? -1 : 1),
		routes,
	)

	r.find((c) => {
		const match = _isRouteAMatch(segments, c)
		if (match.isAMatch) {
			route = c
			params = match.params
			url = match.url
		}
		return match.isAMatch
	}, routes)

	if (!route) {
		route = r.find((c) => c.isDefault, routes)
	}
	return { route, params, url }
}

export const SimpleRouter: FC = (props: {
	routes: IRoute[]
	currentLocation: string
	loginMode: LoginModeType
}) => {
	const { route, params, url } = getBestRoute(
		props.routes,
		props.currentLocation,
	)
	if (!route) {
		log('simple-router', 'no route found', props.currentLocation)
		return <div>[No Route Found]</div>
	}
	if (route.isDefault) {
		log('simple-router', 'found default route', url)
	} else {
		log('simple-router', 'found route', url)
	}

	// Check for anonymous access

	if (route.onPageLoaded) {
		const parts = parseHref(props.currentLocation)
		const queryParams = queryStringToObject(parts.query)
		const loadedParams = r.mergeDeepRight(queryParams, params)
		route.onPageLoaded(loadedParams)
	}

	return (
		<div>{route.renderer(props.currentLocation, params, props.loginMode)}</div>
	)
}
