import { l } from 'ui/lib/lodashImports'
import { apiCommon, apiTypes } from '.'
import { IRequestState } from './requestState'

export const fetchDock = async (
	onProgress: (rs: IRequestState<apiTypes.DockResponse>) => void,
	id: string,
): Promise<IRequestState<apiTypes.DockResponse>> => {
	return await apiCommon.apiFetch(onProgress, { method: 'GET' }, `docks/${id}`)
}

export async function fetchDocks(
	onProgress: (rs: IRequestState<apiTypes.DocksListResponse>) => void,
	params?: {
		take?: number
		skip?: number
		query?: string
		include?: string
		locationId?: string
	},
): Promise<IRequestState<apiTypes.DocksListResponse>> {
	l.defaults(params, {})
	const result = await apiCommon.apiFetch(
		onProgress,
		{ entireResponse: true },
		'docks',
		params,
	)

	return result
}

export async function createDock(
	onProgress: (rs: IRequestState<apiTypes.DockResponse>) => void,
	dockRequest: apiTypes.DockRequest,
): Promise<IRequestState<apiTypes.DockResponse>> {
	return await apiCommon.apiFetch(
		onProgress,
		{ method: 'POST', data: dockRequest },
		'docks',
	)
}

export async function deleteDock(
	onProgress: (rs: IRequestState<{}>) => void,
	dockId: string,
): Promise<IRequestState<{}>> {
	return await apiCommon.apiFetch(
		onProgress,
		{ method: 'DELETE' },
		'docks/' + dockId,
	)
}

export async function updateDock(
	onProgress: (rs: IRequestState<apiTypes.DockResponse>) => void,
	dockId: string,
	dockRequest: apiTypes.DockRequest,
): Promise<IRequestState<apiTypes.DockResponse>> {
	const result = await apiCommon.apiFetch(
		onProgress,
		{ method: 'PUT', data: dockRequest },
		'docks/' + dockId,
	)
	return result
}

export async function fetchAppointments(
	onProgress: (rs: IRequestState<apiTypes.AppointmentListResponse>) => void,
	params?: {
		take?: number
		skip?: number
		query?: string
		include?: string
		fields?: string
	},
): Promise<IRequestState<apiTypes.AppointmentListResponse>> {
	const result = await apiCommon.apiFetch(
		onProgress,
		{ entireResponse: true },
		'docks/appointments',
		params,
	)

	return result
}

export async function fetchSchedule(
	onProgress: (rs: IRequestState<apiTypes.ScheduleResponse>) => void,
	scheduleId: string,
): Promise<IRequestState<apiTypes.ScheduleResponse>> {
	const result = await apiCommon.apiFetch(
		onProgress,
		{},
		'schedules/' + scheduleId,
	)

	return result
}

export async function createSchedule(
	onProgress: (rs: IRequestState<apiTypes.ScheduleResponse>) => void,
	scheduleRequest: apiTypes.ScheduleRequest,
): Promise<IRequestState<apiTypes.ScheduleResponse>> {
	const result = await apiCommon.apiFetch(
		onProgress,
		{ method: 'POST', data: scheduleRequest },
		'schedules',
	)

	return result
}

export async function updateSchedule(
	onProgress: (rs: IRequestState<apiTypes.ScheduleResponse>) => void,
	scheduleId: string,
	scheduleRequest: apiTypes.ScheduleRequest,
): Promise<IRequestState<apiTypes.ScheduleResponse>> {
	const result = await apiCommon.apiFetch(
		onProgress,
		{ method: 'PUT', data: scheduleRequest },
		'schedules/' + scheduleId,
	)

	return result
}

export async function createAppointment(
	onProgress: (rs: IRequestState<apiTypes.AppointmentResponse>) => void,
	body: apiTypes.AppointmentRequest,
	forceSchedule: boolean,
	shipmentId?: string,
): Promise<IRequestState<apiTypes.AppointmentResponse>> {
	const params: { forceSchedule: boolean; shipmentId?: string } = {
		forceSchedule,
	}
	if (shipmentId) {
		params.shipmentId = shipmentId
	}
	return await apiCommon.apiFetch(
		onProgress,
		{ entireResponse: true, method: 'POST', data: body },
		'docks/appointments',
		params,
	)
}

export const fetchAppointment = async (
	onProgress: (rs: IRequestState<apiTypes.AppointmentResponse>) => void,
	id: string,
): Promise<IRequestState<apiTypes.AppointmentResponse>> => {
	return await apiCommon.apiFetch(
		onProgress,
		{ method: 'GET' },
		`docks/appointments/${id}`,
	)
}

export const updateAppointment = async (
	onProgress: (rs: IRequestState<apiTypes.AppointmentResponse>) => void,
	id: string,
	appointment: apiTypes.AppointmentRequest,
): Promise<IRequestState<apiTypes.AppointmentResponse>> => {
	return await apiCommon.apiFetch(
		onProgress,
		{ method: 'PUT', data: appointment },
		`docks/appointments/${id}`,
	)
}

export const updateAppointmentEvent = async (
	onProgress: (rs: IRequestState<apiTypes.AppointmentEventResponse>) => void,
	appointmentId: string,
	eventId: string,
	event: apiTypes.AppointmentEventRequest,
): Promise<IRequestState<apiTypes.AppointmentEventResponse>> => {
	return await apiCommon.apiFetch(
		onProgress,
		{ method: 'PUT', data: event },
		`docks/appointments/${appointmentId}/appointmentEvent/${eventId}`,
	)
}

export const deleteAppointment = async (
	onProgress: (rs: IRequestState<apiTypes.EmptyBody>) => void,
	appointmentId: string,
): Promise<IRequestState<apiTypes.EmptyBody>> => {
	return await apiCommon.apiFetch(
		onProgress,
		{ method: 'DELETE' },
		`docks/appointments/${appointmentId}`,
	)
}
