import React from 'react'
import { ISelectOptions } from 'ui/components/common/select/ISelectOptions'
import { FormSelect } from './FormSelect'
import { FormStackedItem } from './FormStackedItem'
import { IFormData } from './IFormData'

export function FormStackedSelect<T>(props: {
	field: keyof T
	formData: IFormData<T>
	options?: string[] | ISelectOptions[]
	readOnly?: boolean
	autocomplete?: boolean
	tPrefix?: string
	testId?: string
	width?: string
	label?: React.ReactNode
	hideLabel?: boolean
	hideOptional?: boolean
	numListOptionsBeforeScroll?: number
	className?: string
}): React.ReactElement {
	const { field, formData, options } = props
	const fieldData = props.formData.metadata[props.field]
	const readOnly = props.readOnly || fieldData.readOnly
	return (
		<FormStackedItem
			field={field}
			formData={formData}
			label={props.label}
			hideLabel={props.hideLabel}
			hideOptional={props.hideOptional}
			className={props.className}
		>
			<FormSelect
				name={'' + field}
				form={formData.form}
				field={field}
				onUpdateForm={formData.onUpdateForm}
				options={options || fieldData.options}
				readOnly={readOnly}
				autocomplete={props.autocomplete}
				isClearable={fieldData.clearable}
				tPrefix={props.tPrefix}
				testId={props.testId}
				width={props.width}
				numListOptionsBeforeScroll={props.numListOptionsBeforeScroll}
			/>
		</FormStackedItem>
	)
}
