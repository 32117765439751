import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import React, { ReactElement, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import { apiTypes } from 'ui/api'
import { Checkbox } from 'ui/components/common/checkbox'
import { Icon, solidIcons } from 'ui/components/common/icon'
import { t } from 'ui/components/i18n/i18n'
import { Spacer } from 'ui/components/layout/spacer'
import { l } from 'ui/lib/lodashImports'
import * as sosCustomerManagement from 'ui/pages/customer-management/sosCustomerManagement'
import * as sosUser from 'ui/state/sosUser'
import * as classes from './ClientManagement.module.scss'

const tPrefix = 'page.customerManagement.companyInformation'

export const CompanyInformation = (props: {
	companyAddress: apiTypes.Address
	brokerNumber: string
	useCustomerInfoForBOLHeader: boolean
}): ReactElement => {
	const { companyAddress, brokerNumber, useCustomerInfoForBOLHeader } = props
	const supportEditingCompanyInfo = false // we do not support editing until api does.
	const [editingBrokerNumber, setEditBrokerNumberMode] = useState(false)

	const _updateApi = l.debounce(async (): Promise<void> => {
		const result = await sosUser.updateClientConfig(
			sosCustomerManagement.sos.getState(),
		)
		if (result?.error) {
			sosCustomerManagement.sendErrorToast(result.error)
		}
	}, 500)
	const onChange = async (value): Promise<void> => {
		sosCustomerManagement.setBrokerNumber(value)
		await _updateApi()
	}

	return (
		<div className='bootstrap-wrapper'>
			<Card data-testid={'company-information-card'}>
				<Card.Header>
					<Row>
						<Col className={classes.verticalAlignCenter}>
							{t('companyInformation', tPrefix)}
							{supportEditingCompanyInfo && (
								<div className='float-right'>
									<Button variant='link' size='sm' onClick={(): void => {}}>
										{t('edit', tPrefix)}
									</Button>
								</div>
							)}
						</Col>
						<Col>
							<div className={classes.alignOnEnd}>
								{editingBrokerNumber ? (
									<Form>
										<Col className={classes.verticalAlignCenter}>
											<Form.Group
												controlId='percentSelector'
												className={classes.noMargin}
											>
												<Form.Control
													type='text'
													onChange={async (e): Promise<void> =>
														onChange(e.target.value)
													}
													value={brokerNumber || ''}
													data-testid={`form-broker-number`}
												/>
											</Form.Group>
											<EditBrokerNumberButton
												editingBrokerNumber={editingBrokerNumber}
												setEditBrokerNumberMode={setEditBrokerNumberMode}
												brokerNumber={brokerNumber}
											/>
										</Col>
									</Form>
								) : (
									<div className={classes.verticalAlignCenter}>
										{brokerNumber || t('noBrokerNumber', tPrefix)}
										<EditBrokerNumberButton
											editingBrokerNumber={editingBrokerNumber}
											setEditBrokerNumberMode={setEditBrokerNumberMode}
											brokerNumber={brokerNumber}
										/>
									</div>
								)}
							</div>
						</Col>
					</Row>
				</Card.Header>
				<Card.Body
					data-testid='company-information-card-body'
					className={classes.checkboxLabel}
				>
					<Checkbox
						value={useCustomerInfoForBOLHeader}
						onChange={async (newValue: boolean) => {
							sosCustomerManagement.updateUseClientInfoForBolHeader(newValue)
							await _updateApi()
						}}
					>
						{t('useCustomerInfoForBOL', tPrefix)}
					</Checkbox>
					<Spacer height={'10px'} />
					{companyAddress.company && (
						<Card.Title>{companyAddress.company}</Card.Title>
					)}
					<Row>
						<Col style={{ borderRight: 'solid 1px lightGrey' }}>
							{companyAddress.street1 && (
								<Card.Text>{companyAddress.street1}</Card.Text>
							)}
							{companyAddress.street2 && (
								<Card.Text>{companyAddress.street2}</Card.Text>
							)}
							{companyAddress.street3 && (
								<Card.Text>{companyAddress.street3}</Card.Text>
							)}
							<Card.Text>
								{companyAddress.city && <>{`${companyAddress.city}, `}</>}
								{companyAddress.state && <>{`${companyAddress.state} `}</>}
								{companyAddress.zip && <>{`${companyAddress.zip}`}</>}
							</Card.Text>
							{companyAddress.country && (
								<Card.Text>{companyAddress.country}</Card.Text>
							)}
						</Col>
						<Col>
							{companyAddress.name && (
								<Card.Text>{companyAddress.name}</Card.Text>
							)}
							{companyAddress.phone && (
								<Card.Text>{companyAddress.phone}</Card.Text>
							)}
							{companyAddress.email && (
								<Card.Text>{companyAddress.email}</Card.Text>
							)}
						</Col>
					</Row>
				</Card.Body>
			</Card>
		</div>
	)
}

export function determineBrokerNumberIcon(
	editingBrokerNumber: boolean,
	brokerNumber: string,
): IconDefinition {
	if (editingBrokerNumber) {
		return solidIcons.faCheck
	} else {
		return brokerNumber ? solidIcons.faPencilAlt : solidIcons.faPlusCircle
	}
}

const EditBrokerNumberButton = (props: {
	editingBrokerNumber: boolean
	setEditBrokerNumberMode: (on: boolean) => void
	brokerNumber: string
}): ReactElement => {
	const { editingBrokerNumber, setEditBrokerNumberMode, brokerNumber } = props
	return (
		<Button
			variant='link'
			size='sm'
			onClick={(): void => setEditBrokerNumberMode(!editingBrokerNumber)}
			data-testid={'edit-broker-number'}
		>
			<Icon
				icon={determineBrokerNumberIcon(editingBrokerNumber, brokerNumber)}
			/>
		</Button>
	)
}
