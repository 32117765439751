import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { Button } from 'ui/components/common/button/Button'
import { Modal } from 'ui/components/common/modal'
import { FormVertical } from 'ui/components/form/FormVertical'
import { FormVerticalReadOnly } from 'ui/components/form/FormVerticalReadOnly'
import { FormVerticalTextInput } from 'ui/components/form/FormVerticalTextInput'
import { IFormData } from 'ui/components/form/IFormData'
import { sosI18n } from 'ui/components/i18n/state'
import { AlignRight } from 'ui/components/layout/alignRight/AlignRight'
import * as formTranslate from 'ui/forms/formTranslate'
import { sos2 } from 'ui/lib/state/sos2'
import { sosTranslate } from 'ui/state/'

const TranslatorForm: FC = (props: {
	stateTranslate: sosTranslate.IStateTranslate
	onSubmit: () => void
	onSubmitAndNext: () => void
	onNext: () => void
}) => {
	const formData: IFormData<formTranslate.IFormTranslate> = {
		tPrefix: 'dev.translate',
		form: props.stateTranslate.translateForm,
		metadata: formTranslate.translateFormMetadata,
		onUpdateForm: sosTranslate.updateTranslateForm,
	}

	const onEnter = (): void => {
		if (props.stateTranslate.untranslatedStrings.length > 1) {
			props.onSubmitAndNext()
		} else {
			props.onSubmit()
		}
	}

	return (
		<div>
			<FormVertical>
				<FormVerticalReadOnly formData={formData} field='i18nKey' />
				<FormVerticalTextInput
					formData={formData}
					field='en'
					autofocus={true}
					onEnter={onEnter}
					multiline={true}
				/>
				{/* <FormVerticalReadOnly formData={formData} field='es' />
        <FormVerticalReadOnly formData={formData} field='es-MX' />
        <FormVerticalTextInput formData={formData} field='pirate' /> */}

				<AlignRight>
					{props.stateTranslate.untranslatedStrings.length > 1 && (
						<Button color='green' onClick={props.onSubmitAndNext}>
							Ok and Next ({props.stateTranslate.untranslatedStrings.length})
						</Button>
					)}
					<Button color='gray' onClick={props.onSubmit}>
						Ok
					</Button>
					{props.stateTranslate.untranslatedStrings.length > 1 && (
						<Button color='gray' onClick={props.onNext}>
							Next
						</Button>
					)}
				</AlignRight>
			</FormVertical>
		</div>
	)
}

export const Translator: FC = (props: {}) => {
	const stateTranslate = sos2.useSubscription(sosTranslate.getSos())

	// sosI18n.getTranslationForKey(stateTranslate.translateForm.i18nKey)

	return (
		<div>
			<Modal
				closeModalX={true}
				isOpen={stateTranslate.isTranslateModalOpen}
				onModalClose={() => {
					sosTranslate.closeTranslateModal()
				}}
				title='Translate'
				content={() => (
					<div>
						{stateTranslate.requestSaving.error && (
							<div>
								{"Error: Couldn't save translation. Probably couldn't find\n" +
									'translation file with a valid prefix.'}
								<pre>{'' + stateTranslate.requestSaving.error}</pre>
							</div>
						)}

						<TranslatorForm
							stateTranslate={stateTranslate}
							onSubmit={async () => {
								if (await sosTranslate.saveTranslation()) {
									await sosI18n.redownloadEnglish()
									sosTranslate.moveToNextTranslation(false)
								}
							}}
							onSubmitAndNext={async () => {
								if (await sosTranslate.saveTranslation()) {
									await sosI18n.redownloadEnglish()
									sosTranslate.moveToNextTranslation(true)
								}
							}}
							onNext={async () => {
								sosTranslate.moveToNextTranslation(true)
							}}
						/>
					</div>
				)}
			/>
		</div>
	)
}
