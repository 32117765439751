import React from 'react'
import styles from './FormStackedLayout.module.scss'
import { IFormData } from './IFormData'

export function FormStackedLayout<T extends IFormData<T>>(props: {
	children: React.ReactNode
}): React.ReactElement {
	return (
		<div className={styles.table}>
			<div className={styles.tbody}>{props.children}</div>
		</div>
	)
}
