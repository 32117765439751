import { FC } from 'app/FunctionalComponent'
import _ from 'lodash'
import React from 'react'
import { FilterItem, Filters } from 'ui/components/common/filters'
import { t } from 'ui/components/i18n/i18n'
import { l } from 'ui/lib/lodashImports'

export const ControlledFilters: FC = (props: {
	tPrefix: string
	filterKeys: string[]
	selectedKey: string
	onSelect: (key: string) => void
}) => {
	const { filterKeys } = props

	return (
		<Filters>
			{_.map(filterKeys, (c) => (
				<FilterItem
					key={c}
					isSelected={props.selectedKey === c}
					onClick={() => {
						props.onSelect(c)
					}}
					testId={'filter-item-' + l.camelCase(c)}
				>
					{t(_.camelCase(c), props.tPrefix)}
				</FilterItem>
			))}
		</Filters>
	)
}
