import { FC } from 'app/FunctionalComponent'
import React from 'react'
import {
	Breadcrumb,
	IBreadcrumbEntry,
} from 'ui/components/common/breadcrumb/Breadcrumb'
import { Debug } from 'ui/components/dev/Debug'
import { useOnce } from 'ui/components/hooks/useOnce'
import { Spacer } from 'ui/components/layout/spacer/Spacer'
import { sos2 } from 'ui/lib/state/sos2'
import { Layout } from 'ui/pages/layout/Layout'
import { sosAddressBook } from 'ui/state'
import { AddressBookDataTable } from './AddressBookDataTable'

export const ManageAddressBooksPage: FC = (props: {}) => {
	const state = sos2.useSubscription(sosAddressBook.sos)

	useOnce(async () => {
		await sosAddressBook.getBooks()
	})

	const bread: IBreadcrumbEntry[] = [
		{
			t: 'page.manageAddressBooks.manageAddressBooks',
		},
	]

	return (
		<Layout>
			<Breadcrumb entries={bread} />
			<Debug
				hide={true}
				label='book get all request'
				data={state.requestGetAddressBooks}
			/>
			<AddressBookDataTable
				requestGetAddressBooks={state.requestGetAddressBooks}
				requestAddEditAddressBook={state.requestAddEditAddressBook}
				dataTableState={state.dataTableAddressBooks}
				data={state.dataAddressBooks}
				form={state.formAddressBook}
			/>
			<Spacer />
			<Debug
				hide={true}
				label='book create request'
				data={state.requestAddEditAddressBook}
			/>
		</Layout>
	)
}
