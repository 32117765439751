import { FC } from 'app/FunctionalComponent'
import React, { useState } from 'react'
import { Input } from 'ui/components/common/input/Input'
import { Modal } from 'ui/components/common/modal/Modal'
import { OkCancelButtons } from 'ui/components/common/okCancelButtons/OkCancelButtons'
import formClasses from 'ui/components/form/FormStacked.module.scss'
import { t } from 'ui/components/i18n/i18n'
import { sosRfp } from '.'
import { tPrefixRfp } from './RfpLayout'

export const NewRfpProjectModal: FC = (props: { isOpen: boolean }) => {
	const [newRfpProjectName, setNewRfpProjectName] = useState('')

	const isValid = newRfpProjectName !== ''

	const addRfpProject = async (): Promise<void> => {
		await sosRfp.addRfpProject(newRfpProjectName)
		setNewRfpProjectName('')
	}

	return (
		<Modal
			content={() => (
				<div data-testid='addRfpProjectModalContent'>
					<div>
						<label className={formClasses.label}>
							{t('projectName', tPrefixRfp)}
						</label>
					</div>
					<Input
						value={newRfpProjectName}
						onChange={setNewRfpProjectName}
						testId='newRfpProjectNameInput'
						onEnter={addRfpProject}
					/>
					<OkCancelButtons
						ok={t('addNewRfpProject', tPrefixRfp)}
						isValid={isValid}
						okColor='green'
						onOk={addRfpProject}
						okTestId='addNewRfpProject'
					/>
				</div>
			)}
			isOpen={props.isOpen}
			onModalClose={() => sosRfp.updateShowNewRfpProjectModal(false)}
			title={t('newRfpProject', tPrefixRfp)}
		/>
	)
}
