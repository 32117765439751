import React from 'react'
import { divIcon, DivIcon } from 'leaflet'
import { renderToStaticMarkup } from 'react-dom/server'
import { solidIcons } from 'ui/components/common/icon/solidIcons'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { theme } from 'ui/theme'
import { apiTypes } from 'ui/api'
import * as classes from './MapIcons.module.scss'
import { Icon } from 'ui/components/common/icon/Icon'
import { Colors } from 'ui/theme/theme'

export type MarkerStatus = 'normal' | 'success' | 'late'

export type MarkerDirection = 'normal' | 'reversed'

const iconColorMap: { [k in MarkerStatus]: Colors } = {
	normal: 'blue',
	success: 'green',
	late: 'red',
}

const iconMap: {
	[k in apiTypes.ShipmentResponse['shippingMode']]: IconDefinition
} = {
	ltl: solidIcons.faTruck,
	truckload: solidIcons.faTruckMoving,
	parcel: solidIcons.faTruck,
	freight: solidIcons.faTruck,
	simplifiedFreight: solidIcons.faTruck,
	ftl: solidIcons.faTruck,
	fr: solidIcons.faTruck,
	modeless: solidIcons.faTruck,
	lcl: solidIcons.faTruck,
	bb: solidIcons.faTruck,
	international: solidIcons.faTruck,
}

const markerCache: { [k: string]: DivIcon } = {}

export const markerIconFactory = (
	markerIcon: apiTypes.ShipmentResponse['mode'] = 'ltl',
	markerStatus: MarkerStatus = 'normal',
	markerDirection: MarkerDirection = 'normal',
): DivIcon => {
	const key = `${markerIcon}-${markerStatus}-${markerDirection}`
	if (markerCache[key]) {
		return markerCache[key]
	} else {
		let className = classes.mapIcon
		className = theme.addClassIf(
			markerDirection === 'reversed',
			classes.reversedMapIcon,
			className,
		)

		const innerIcon = divIcon({
			html: renderToStaticMarkup(
				<div className={className} data-testid='map-marker'>
					<Icon icon={iconMap[markerIcon]} color={iconColorMap[markerStatus]} />
				</div>,
			),
		})

		markerCache[key] = innerIcon

		return innerIcon
	}
}

export const getStartMarkerIcon = (): DivIcon =>
	divIcon({
		html: renderToStaticMarkup(
			<div className={classes.routeMarker}>
				<Icon
					className={classes.markerDropShadow}
					icon={solidIcons.faMapMarker}
					color={'green'}
				/>
			</div>,
		),
	})

export const getEndMarkerIcon = (): DivIcon =>
	divIcon({
		html: renderToStaticMarkup(
			<div className={classes.routeMarker}>
				<Icon
					className={classes.markerDropShadow}
					icon={solidIcons.faMapMarker}
					color={'red'}
				/>
			</div>,
		),
	})
