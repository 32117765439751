import * as apiTypes from 'ui/api/apiTypes'

export const hazmatPackingGroupOptions: {
	label: string
	value: apiTypes.Good['hazmatPackingGroup']
}[] = [
	{ label: 'PGI', value: 'PGI' },
	{ label: 'PGII', value: 'PGII' },
	{ label: 'PGIII', value: 'PGIII' },
]
