import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { sosPageConfig } from 'ui/state'
import { IPageElementConfig } from './pageConfig'
import classes from './PageConfigizer.module.scss'

export const PageConfigizer: FC = (props: {
	children: React.ReactNode
	config: IPageElementConfig
	advanced: boolean
}) => {
	if (props.config.hidden) {
		return null
	}
	if (props.config.advanced && !props.advanced) {
		return null
	}

	return (
		<div>
			{"I'm a wrapper"}
			<div>{props.children}</div>
			Done wrapping
		</div>
	)
}

export const PageConfigizer2: FC = (props: {
	children: React.ReactNode
	configKey: string
	prefix: string
}) => {
	// if (props.config.hidden) {
	// 	return null
	// }
	// if (props.config.advanced && !props.advanced) {
	// 	return null
	// }

	const fullKey = props.prefix + '.' + props.configKey

	const isPageConfigMode = sosPageConfig.isPageConfigMode()

	if (isPageConfigMode) {
		return (
			<div
				className={classes.configurable}
				onClick={() => {
					sosPageConfig.openPageConfigModal('testKey')
				}}
			>
				<div className={classes.configurableTag}>{fullKey}</div>

				<div>{props.children}</div>
			</div>
		)
	}

	return (
		<div>
			<div>{props.children}</div>
		</div>
	)
}
