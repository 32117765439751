import { apiTypes } from 'ui/api'

export interface ValhallaRoutingStop {
	lat: number
	lon: number
	type: 'break'
}

export const getValhallaStopSequence = (
	shipment: apiTypes.ShipmentResponse,
): ValhallaRoutingStop[] => {
	const stops: ValhallaRoutingStop[] = []
	for (const addressHash of shipment.stopSequence) {
		for (const payload of shipment.payloads) {
			if (payload.originStop?.metaData?.addressHash === addressHash) {
				stops.push({
					lat: Number(payload.originStop.metaData.coordinates?.latitude),
					lon: Number(payload.originStop.metaData.coordinates?.longitude),
					type: 'break',
				})
			} else if (
				payload.destinationStop?.metaData?.addressHash === addressHash
			) {
				stops.push({
					lat: Number(payload.destinationStop.metaData.coordinates?.latitude),
					lon: Number(payload.destinationStop.metaData.coordinates?.longitude),
					type: 'break',
				})
			}
		}
	}

	return stops
}
