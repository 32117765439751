import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { t } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight'
import { IStatePager } from 'ui/state/paging'
import { Icon, IconButton, solidIcons } from '../icon'
import classes from './Pager.module.scss'

export const ElasticSearchPager: FC = (props: {
	pager: IStatePager
	onClickPage?: (pageNum: number) => void
}) => {
	const { pager } = props
	return (
		<Pager
			pageSize={pager.pageSize}
			currentPage={pager.pageNumber}
			fetchingPage={pager.fetchingPageNumber}
			numPages={pager.pageCount}
			count={pager.total}
			onClickPage={props.onClickPage}
		/>
	)
}

// NOTE: All pages are 0-indexed
export const Pager: FC = (props: {
	numPages: number
	currentPage: number
	fetchingPage?: number
	hasMorePages?: boolean
	onClickPage?: (pageNum: number) => void
	onClickLoadMore?: () => void
	pageSize?: number
	count?: number
}) => {
	// Don't display only one page
	if (props.numPages === 1 && !props.hasMorePages) {
		return null
	}
	const lastPage: number = props.numPages - 1 //props.numPages takes us one page too far
	const navButtonClass: string = 'fa-lg ' + classes.navigationMargin

	return (
		<AlignRight>
			<div className={classes.pagerContainer}>
				{props.hasMorePages && (
					<div className={classes.loadMore} onClick={props.onClickLoadMore}>
						{t('component.pager.loadMore')}{' '}
						<span className={classes.nextIcon}>
							<Icon icon={solidIcons.faChevronRight} />
						</span>
					</div>
				)}
				<PageCount
					count={props.count}
					pageSize={props.pageSize}
					currentPage={props.currentPage}
				/>
				<IconButton
					icon={solidIcons.faAngleDoubleLeft}
					iconClassName={navButtonClass}
					onClick={() => {
						props.onClickPage(0)
					}}
				/>
				<IconButton
					icon={solidIcons.faAngleLeft}
					iconClassName={navButtonClass}
					onClick={() => {
						if (props.currentPage === 0) {
							props.onClickPage(0)
						} else {
							props.onClickPage(props.currentPage - 1)
						}
					}}
				/>
				<IconButton
					icon={solidIcons.faAngleRight}
					iconClassName={navButtonClass}
					onClick={() => {
						if (props.currentPage === lastPage) {
							props.onClickPage(lastPage)
						} else {
							props.onClickPage(props.currentPage + 1)
						}
					}}
				/>
				<IconButton
					icon={solidIcons.faAngleDoubleRight}
					iconClassName={navButtonClass}
					onClick={() => {
						props.onClickPage(lastPage)
					}}
				/>
			</div>
		</AlignRight>
	)
}

export const PageCount: FC = (props: { count; pageSize; currentPage }) => {
	let currentPageTo = 0

	if (!props.count) {
		return null
	}

	currentPageTo = (props.currentPage + 1) * props.pageSize
	if (currentPageTo > props.count) {
		currentPageTo = props.count
	}

	return (
		<div className={classes.count}>
			{props.pageSize ? (
				<span>
					{`${props.currentPage * props.pageSize + 1}-${currentPageTo} of ${
						props.count
					}`}
				</span>
			) : (
				<span>{props.count}</span>
			)}
		</div>
	)
}
