import axios, { AxiosResponse } from 'axios'
import { sosUser } from 'ui/state'
import { getApiHost } from 'ui/api/apiCommon'

export interface RequestOptions {
	method?: 'GET' | 'POST' | 'PUT' | 'DELETE'
	data?: any
	headers?: { [header: string]: string }
}

export async function callApi(
	options: RequestOptions,
	path: string,
	params?: { [key: string]: string | number },
): Promise<AxiosResponse<any>> {
	const auth = sosUser.getAuthToken()

	options = {
		method: options.method || 'GET',
		data: options.data || null,
		headers: Object.assign(options.headers || {}, {
			Authorization: `Bearer ${auth}`,
		}),
	}

	const host = getApiHost()
	let url = `${host}/${path}`

	if (params) {
		const query = Object.keys(params)
			.map(
				(key) =>
					`${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`,
			)
			.join('&')
		url += `?${query}`
	}

	return await axios.request({
		url,
		method: options.method,
		data: options.data,
		headers: options.headers,
	})
}
