import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { ISelectOptions } from 'ui/components/common/select'
import { Select } from 'ui/components/common/select/Select'
import { sosI18n } from 'ui/components/i18n/state'
import { sos2 } from 'ui/lib/state/sos2'

export const LanguagePicker: FC = (props: {}) => {
	const stateI18n = sos2.useSubscription(sosI18n.getSos())

	const options: ISelectOptions[] = [
		{
			value: 'en',
			label: 'English',
		},
		{
			value: 'check',
			label: 'i18n Check',
		},
		{
			value: 'keys',
			label: 'i18n Keys',
		},
		{
			value: 'test',
			label: 'i18n Test',
		},
		{
			value: 'test-ids',
			label: 'i18n Test Ids',
		},
		// {
		//   value: 'en-GB',
		//   display: 'English (GB)',
		// },
		{
			value: 'es',
			label: 'Spanish',
		},
		// {
		//   value: 'es-MX',
		//   display: 'Spanish (Mexican)',
		// },
		// {
		//   value: 'fr',
		//   display: 'French',
		// },
		// {
		//   value: 'pig',
		//   display: 'Pig Latin',
		// },
		// {
		//   value: 'pirate',
		//   display: 'Pirate',
		// },
		{
			value: 'palindrome',
			label: 'Palindrome',
		},
		{
			value: 'rtl',
			label: 'Right-to-Left',
		},
	]

	return (
		<div>
			Language
			<Select
				options={options}
				value={stateI18n.locale}
				onChange={(newVal) => {
					sosI18n.setLanguageCode(('' + newVal) as sosI18n.TLanguageCode)
				}}
			/>
		</div>
	)
}
