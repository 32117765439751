import { apiTypes } from 'ui/api'
import { RateRow } from 'ui/components/rates-table/RatesTable'
import { filterRatesForRatesTable } from 'ui/pages/shipment-profile/shared/functions/filterRatesForRatesTable'
import { sortRateRows } from 'ui/pages/shipment-profile/shared/functions/sortRateRows'
import { convertShipperRateResponseToRateRow } from 'ui/pages/shipment-profile/shipper/functions/convertShipperRateResponseToRateRow'

export const processShipperRateRows = (
	shipment: apiTypes.ShipmentResponse,
): RateRow[] => {
	let rateRows: RateRow[]

	const filteredRates = filterRatesForRatesTable(shipment.rates)

	rateRows = filteredRates.map(
		(rate): RateRow => {
			return convertShipperRateResponseToRateRow(rate)
		},
	)
	rateRows = sortRateRows(rateRows)
	return rateRows
}
