import { FC } from 'app/FunctionalComponent'
import React, { useEffect, useState } from 'react'
import { apiTypes } from 'ui/api'
import { ndash } from 'ui/components/common'
import { Card } from 'ui/components/common/card'
import { t } from 'ui/components/i18n/i18n'
import { l } from 'ui/lib/lodashImports'
import {
	constructCommoditiesForm,
	constructCommoditiesFormFromParcels,
} from 'ui/lib/shipments'
import { INewQuoteCommoditiesForm } from 'ui/pages/new-quote/INewQuoteCommoditiesForm'
import * as classes from './Commodities.module.scss'

const valueToWords = (key: string): string =>
	l.startCase(key).replace(/_/g, ' ')
const valueOrDefault = (value: any, defaultValue?: any): any => {
	if (value) {
		return value
	}
	if (!defaultValue) {
		defaultValue = ndash
	}
	return defaultValue
}

export const CommoditiesReadOnly: FC = (props: {
	fullWidth?: boolean
	shipment?: apiTypes.ShipmentResponse
}) => {
	const { fullWidth, shipment } = props
	const [commodities, setCommodities] = useState<INewQuoteCommoditiesForm[]>([])

	useEffect(() => {
		if (shipment) {
			setCommodities(shipment.expectedMode === 'parcel'
				? constructCommoditiesFormFromParcels(shipment)
				: constructCommoditiesForm(shipment))
		}
	}, [shipment])
	const showStopNumbers =
		commodities.filter(
			(commodity) => commodity.pickupStopNumber || commodity.deliveryStopNumber,
		).length > 0

	if (shipment?.expectedMode !== 'parcel') {
		return (
			<Card
				title={t('page.newQuote.commodities')}
				color='darkBlue'
				noPadding={true}
				fullWidth={fullWidth}
			>
				<table
					className={classes.commoditiesTable}
					data-testid={'commodities-table'}
				>
					<thead>
						<tr>
							<th>{t('page.newQuote.quantity')}</th>
							<th>{t('page.newQuote.type')}</th>
							<th>{t('page.newQuote.unitType.pieces')}</th>
							<th>{t('page.newQuote.unitType.pieces')} Type</th>
							<th style={{ textAlign: 'right' }}>
								{t('page.newQuote.weight')}
							</th>
							<th className={classes.normalCaps}>
								{t('page.newQuote.unitDimensions')} (L x W x H)
							</th>
							<th className={classes.normalCaps}>
								{t('page.newQuote.packageDimensions')} (L x W x H)
							</th>
							<th>{t('page.newQuote.description')}</th>
							<th>{t('page.newQuote.po')}</th>
							<th>{t('page.newQuote.so')}</th>
							<th style={{ textAlign: 'right' }}>{t('page.newQuote.class')}</th>
							<th>{t('page.newQuote.nmfc')}</th>
							{showStopNumbers && (
								<>
									<th>{t('page.newQuote.pickupStopNumber')}</th>
									<th>{t('page.newQuote.deliveryStopNumber')}</th>
								</>
							)}
						</tr>
					</thead>
					<tbody>
						{l.map(commodities, (c, cIdx) => (
							<tr key={cIdx}>
								<td>{c.count}</td>
								<td>{valueToWords(c.type)}</td>
								<td>{valueOrDefault(c.piecesCount)}</td>
								<td>{valueOrDefault(valueToWords(c.piecesType))}</td>
								<td style={{ textAlign: 'right' }}>
									{c.packedWeight || ndash}
								</td>
								<td>{`${c.length}" x ${c.width}" x ${c.height}"`}</td>
								<td>
									{c.piecesLength || c.piecesWidth || c.piecesHeight ? (
										<>
											{`${c.piecesLength}" x ${c.piecesWidth}" x ${c.piecesHeight}"`}
										</>
									) : (
										ndash
									)}
								</td>
								<td>{c.description}</td>
								<td>{valueOrDefault(c.po)}</td>
								<td>{valueOrDefault(c.so)}</td>
								<td style={{ textAlign: 'right' }}>
									{valueOrDefault(c.class)}
								</td>
								<td>{valueOrDefault(c.nmfc)}</td>
								{showStopNumbers && (
									<>
										<td>{c.pickupStopNumber}</td>
										<td>{c.deliveryStopNumber}</td>
									</>
								)}
							</tr>
						))}
					</tbody>
				</table>
			</Card>
		)
	} else {
		return (
			<Card
				title={t('page.newQuote.commodities')}
				color='darkBlue'
				noPadding={true}
				fullWidth={fullWidth}
			>
				<table
					className={classes.commoditiesTable}
					data-testid={'commodities-table'}
				>
					<thead>
						<tr>
							<th style={{ textAlign: 'left' }}>
								{t('page.newQuote.quantity')}
							</th>
							<th style={{ textAlign: 'left' }}>{t('page.newQuote.weight')}</th>
							<th className={classes.normalCaps} style={{ textAlign: 'left' }}>
								{t('page.newQuote.packageDimensions')} (L x W x H)
							</th>
							<th style={{ textAlign: 'left' }}>
								{t('page.newQuote.declaredValue')}
							</th>
							<th style={{ textAlign: 'left' }}>
								{t('page.newQuote.reference1')}
							</th>
							<th style={{ textAlign: 'left' }}>
								{t('page.newQuote.reference2')}
							</th>
						</tr>
					</thead>
					<tbody>
						{l.map(commodities, (c, cIdx) => (
							<tr key={cIdx}>
								<td style={{ textAlign: 'left' }}>{c.count}</td>
								<td style={{ textAlign: 'left' }}>{c.packedWeight || ndash}</td>
								<td style={{ textAlign: 'left' }}>
									{c.length || c.width || c.height ? (
										<>
											{`${c.length}" x ${c.width}" x ${c.height}"`}
										</>
									) : (
										ndash
									)}
								</td>
								<td style={{ textAlign: 'left' }}>{c.declaredValue}</td>
								<td style={{ textAlign: 'left' }}>{c.reference1}</td>
								<td style={{ textAlign: 'left' }}>{c.reference2}</td>
							</tr>
						))}
					</tbody>
				</table>
			</Card>
		)
	}
}
