import React from 'react'
import { t, testId } from 'ui/components/i18n/i18n'
import { formValidator, validation } from 'ui/lib/validation/'
import * as commonStyles from 'ui/theme/common.module.scss'
import { Colors } from 'ui/theme/theme'
import { Optional } from '../common/badge'
import { Recommended } from '../common/badge/Recommended'
import { HelpHover } from '../common/icon-hover/IconHover'
import styles from './FormVertical.module.scss'
import { IFormData } from './IFormData'

function FormVerticalItemLabel<T>(props: {
	field: keyof T
	formData: IFormData<T>
	labelSide: 'left' | 'right'
	color?: Colors
}): React.ReactElement {
	const { field, formData, labelSide } = props
	const meta = formData.metadata[props.field]

	return (
		<div
			className={
				styles.td + ' ' + labelSide === 'left'
					? styles.labelLeft
					: styles.labelRight
			}
		>
			<label htmlFor={'' + field}>
				{t(formData.tPrefix + '.' + (meta.label || props.field))}
			</label>
		</div>
	)
}

export function FormVerticalItem<T>(props: {
	field: keyof T
	formData: IFormData<T>
	children: React.ReactNode
	helpText?: React.ReactNode
	labelSide?: 'left' | 'right'
	color?: Colors
	hideLabel?: boolean
}): React.ReactElement {
	const { field, formData, labelSide } = props

	const meta = props.formData.metadata[props.field]
	const val: any = formData.form[field]
	const isAValue = validation.isAValue(val)
	const showOptional = !meta.required && !props.hideLabel
	const showRecommended = meta.recommended && !isAValue

	let error = null
	if (isAValue) {
		error = formValidator.validateValue(meta, val, false, formData.form)
	}

	return (
		<React.Fragment>
			<div className={styles.formRow}>
				{(!labelSide || labelSide === 'left') && !props.hideLabel && (
					<FormVerticalItemLabel
						field={field}
						formData={formData}
						labelSide='left'
						color={props.color}
					/>
				)}
				<div className={styles.td}>{props.children}</div>
				<div className={styles.td}>
					{props.helpText && (
						<div className={commonStyles.fontSmall}>
							<HelpHover>{props.helpText}</HelpHover>
						</div>
					)}
				</div>
				{labelSide === 'right' && !props.hideLabel && (
					<FormVerticalItemLabel
						field={field}
						formData={formData}
						labelSide='right'
						color={props.color}
					/>
				)}

				<div className={styles.td + ' ' + styles.required}>
					{showOptional && <Optional />}
					{showRecommended && <Recommended />}
					{testId('form-' + field)}
				</div>
			</div>
			{meta.description && (
				<div className={styles.descriptionRow}>
					<div className={styles.td + ' ' + styles.labelLeft}>&nbsp;</div>
					<div>
						<div className={styles.description}>
							{t(meta.description, formData.tPrefix)}
						</div>
					</div>
				</div>
			)}
			{error && (
				<div className={styles.errorRow}>
					<div className={styles.td + ' ' + styles.labelLeft}>&nbsp;</div>
					<div className={styles.td}>
						<div className={styles.error}>{t(error)}</div>
					</div>
				</div>
			)}
		</React.Fragment>
	)
}
