import { tString } from 'ui/components/i18n/i18n'
import { l } from 'ui/lib/lodashImports'
import { ISelectOptions } from '../select/ISelectOptions'

export interface IAutocompleteOptions {
	quality: number
	option: ISelectOptions
}

export const getSelectedOptionFromVal = (
	val: string,
	options: ISelectOptions[],
	allowEmptyValue?: boolean,
): ISelectOptions => {
	const blankOpt = { label: '', value: '' }
	if ((!allowEmptyValue && val === '') || options.length === 0) {
		return blankOpt
	}
	return (
		l.filter(options, (c: ISelectOptions) => c.value === val)[0] || blankOpt
	)
}

export const defaultGetQualityForSuggestion = (
	label: string,
	val: string,
	searchTerm: string,
): number => {
	val = l.toLower(val)
	searchTerm = l.toLower(searchTerm)
	label = l.toLower(label)
	if (val === searchTerm || label === searchTerm) {
		return 1
	}
	if (label.indexOf(searchTerm) === 0) {
		return 0.6
	}
	return 0.3
}

export const allFilter = (
	options: ISelectOptions[],
): IAutocompleteOptions[] => {
	return l.map(options, (c) => ({
		quality: 1,
		option: c,
	}))
}

export const defaultFilter = (
	options: ISelectOptions[],
	val: string,
): IAutocompleteOptions[] => {
	val = l.toLower(val)
	const suggestions = l.filter(
		options,
		(c) =>
			l.toLower(c.value).indexOf(val) !== -1 ||
			l.toLower(c.label).indexOf(val) !== -1,
	)

	return l.map(suggestions, (c) => ({
		quality: defaultGetQualityForSuggestion(c.label, c.value, val),
		option: c,
	}))
}

export const processOptions = (
	options: string[] | ISelectOptions[],
	tPrefix?: string,
): ISelectOptions[] => {
	return l.map(options as any, (c: string | ISelectOptions) => {
		let label = ''
		let value = ''
		if (l.isString(c)) {
			label = c
			if (tPrefix) {
				label = tString(c, tPrefix)
			}
			value = c
		} else {
			label = c.label || c.value
			if (tPrefix || c.t) {
				label = tString(c.t || l.camelCase(label), tPrefix)
			}
			value = c.value
		}
		return {
			value: value,
			label: label,
		}
	})
}
