import { apiTypes } from 'ui/api'
import { RateRow } from 'ui/components/rates-table/RatesTable'

export const convertShipperRateResponseToRateRow = (
	rateResponse: apiTypes.RateResponse,
): RateRow => {
	if (!rateResponse) {
		return null
	}

	const rateRow: RateRow = {
		providerImage: rateResponse.providerName,
		providerName: rateResponse.carrier || rateResponse.providerName,
		serviceLevel: rateResponse.serviceLevel,
		method: rateResponse.method,
		quoteType: rateResponse.quoteType,
		transit: rateResponse.transit,
		delivery: rateResponse.delivery,
		guaranteed: rateResponse.guaranteed,
		rateId: rateResponse.id,
		offerId: rateResponse.offerId,
		offerStatus: rateResponse.offerStatus || 'offerMade',
		message: rateResponse.providerNote,
		buyPrice: rateResponse.costTotal,
		costBreakdown: rateResponse.costBreakdown,
		rateBookingOptions: rateResponse.rateBookingOptions,
	}
	return rateRow
}
