import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { addClassIf } from 'ui/theme/theme'
import classes from './FlexRow.module.scss'

type FlexRowModes =
	| 'default'
	| 'space-around'
	| 'space-between'
	| 'center'
	| 'right'
type FlexRowVerticalAlign = 'top' | 'center' | 'bottom'

const FlexRow: FC = (props: {
	children: React.ReactNode
	fullWidth?: boolean
	mode?: FlexRowModes
	verticalAlign?: FlexRowVerticalAlign
	wrap?: boolean
	noChildMargins?: boolean
}) => {
	let className = classes.flexRow
	className = addClassIf(!props.noChildMargins, classes.childMargins, className)

	className = addClassIf(props.fullWidth, classes.fullWidth, className)
	className = addClassIf(props.wrap === false, classes.noWrap, className)
	className = addClassIf(
		props.mode === 'space-around',
		classes.spaceAround,
		className,
	)
	className = addClassIf(
		props.mode === 'space-between',
		classes.spaceBetween,
		className,
	)
	className = addClassIf(props.mode === 'center', classes.center, className)
	className = addClassIf(
		props.verticalAlign === 'center',
		classes.verticalAlignCenter,
		className,
	)
	className = addClassIf(
		props.verticalAlign === 'bottom',
		classes.verticalAlignBottom,
		className,
	)

	return <div className={className}>{props.children}</div>
}

export { FlexRow }
