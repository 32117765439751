import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { Checkbox } from 'ui/components/common/checkbox/Checkbox'
import { sosUser } from 'ui/state/'
import { IStateUser } from 'ui/state/sosUser'

export const LoggingPicker: FC = (props: { stateUser: IStateUser }) => {
	const { stateUser } = props

	return (
		<div>
			Show debug tags
			<Checkbox
				value={stateUser.devShowDebugTags}
				onChange={(newVal) => {
					sosUser.update({ devShowDebugTags: newVal })
				}}
			>
				Show debug tags
			</Checkbox>
			TODO: Logging volume TODO: Logging namespaces
		</div>
	)
}
