import { IDataTableHeader } from 'ui/components/table'
import { l } from 'ui/lib/lodashImports'

/*
	used to make sure there are no misconfigurations when setting up the table headers for an dataTable with ES
 */
export function checkEsTableHeaders(headers: IDataTableHeader<any>[]): void {
	l.forEach(headers, (c) => {
		if (!c.elasticSearchKey && (c.sort || c.defaultSortDirection)) {
			throw new Error(
				`Misconfiguration: for field ${String(
					c.field,
				)}, You can not sort on a column that does not have an elasticSearchKey`,
			)
		}
		if (
			l.split(c.elasticSearchKey, ',').length > 1 &&
			(c.sort || c.defaultSortDirection)
		) {
			throw new Error(
				`Misconfiguration: for field ${String(
					c.field,
				)}, You can not sort on multi-key columns.`,
			)
		}
		if (
			l.split(c.elasticSearchKey, ',').length > 1 &&
			c.filterSelector === 'date'
		) {
			throw new Error(
				`Misconfiguration: for field ${String(
					c.field,
				)}, Multiple field searching on date fields is currently unsupported.`,
			)
		}
	})
}
