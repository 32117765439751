import { IFormattedRule } from './sosLoadOptimizer'
import { l } from 'ui/lib/lodashImports'

const capitalizeFirstLetter = (s: string): string => {
	return l.upperCase(s[0]) + s.slice(1)
}

const functionToFormattedRule: { [functionName: string]: IFormattedRule } = {
	mustHaveSamePickupFullAddress: {
		ruleType: 'address',
		stopType: 'pickup',
		addressMatchStrength: 'fullAddress',
	},
	mustHaveSamePickupCity: {
		ruleType: 'address',
		stopType: 'pickup',
		addressMatchStrength: 'city',
	},
	mustHaveSamePickupState: {
		ruleType: 'address',
		stopType: 'pickup',
		addressMatchStrength: 'state',
	},
	mustHaveSamePickupThreeDigitZip: {
		ruleType: 'address',
		stopType: 'pickup',
		addressMatchStrength: 'threeDigitPostal',
	},
	mustHaveSamePickupFiveDigitZip: {
		ruleType: 'address',
		stopType: 'pickup',
		addressMatchStrength: 'fiveDigitPostal',
	},
	mustHaveSamePickupCountry: {
		ruleType: 'address',
		stopType: 'pickup',
		addressMatchStrength: 'country',
	},
	mustHaveSamePickupLocationId: {
		ruleType: 'address',
		stopType: 'pickup',
		addressMatchStrength: 'locationId',
	},
	mustHaveSameDeliveryFullAddress: {
		ruleType: 'address',
		stopType: 'delivery',
		addressMatchStrength: 'fullAddress',
	},
	mustHaveSameDeliveryCity: {
		ruleType: 'address',
		stopType: 'delivery',
		addressMatchStrength: 'city',
	},
	mustHaveSameDeliveryState: {
		ruleType: 'address',
		stopType: 'delivery',
		addressMatchStrength: 'state',
	},
	mustHaveSameDeliveryThreeDigitZip: {
		ruleType: 'address',
		stopType: 'delivery',
		addressMatchStrength: 'threeDigitPostal',
	},
	mustHaveSameDeliveryFiveDigitZip: {
		ruleType: 'address',
		stopType: 'delivery',
		addressMatchStrength: 'fiveDigitPostal',
	},
	mustHaveSameDeliveryCountry: {
		ruleType: 'address',
		stopType: 'delivery',
		addressMatchStrength: 'country',
	},
	mustHaveSameDeliveryLocationId: {
		ruleType: 'address',
		stopType: 'delivery',
		addressMatchStrength: 'locationId',
	},
	mustHaveTotalWeightLessThan: {
		ruleType: 'size',
		sizeType: 'totalWeight',
		sizeOperator: 'lessThan',
	},
	mustHaveTotalWeightLessThanOrEqualTo: {
		ruleType: 'size',
		sizeType: 'totalWeight',
		sizeOperator: 'lessThanOrEqualTo',
	},
	mustHaveTotalWeightGreaterThan: {
		ruleType: 'size',
		sizeType: 'totalWeight',
		sizeOperator: 'greaterThan',
	},
	mustHaveTotalWeightGreaterThanOrEqualTo: {
		ruleType: 'size',
		sizeType: 'totalWeight',
		sizeOperator: 'greaterThanOrEqualTo',
	},
	mustHaveHandlingUnitCountLessThan: {
		ruleType: 'size',
		sizeType: 'handlingUnitCount',
		sizeOperator: 'lessThan',
	},
	mustHaveHandlingUnitCountLessThanOrEqualTo: {
		ruleType: 'size',
		sizeType: 'handlingUnitCount',
		sizeOperator: 'lessThanOrEqualTo',
	},
	mustHaveHandlingUnitCountGreaterThan: {
		ruleType: 'size',
		sizeType: 'handlingUnitCount',
		sizeOperator: 'greaterThan',
	},
	mustHaveHandlingUnitCountGreaterThanOrEqualTo: {
		ruleType: 'size',
		sizeType: 'handlingUnitCount',
		sizeOperator: 'greaterThanOrEqualTo',
	},
	mustHavePalletSpotCountLessThan: {
		ruleType: 'size',
		sizeType: 'palletSpotCount',
		sizeOperator: 'lessThan',
	},
	mustHavePalletSpotCountLessThanOrEqualTo: {
		ruleType: 'size',
		sizeType: 'palletSpotCount',
		sizeOperator: 'lessThanOrEqualTo',
	},
	mustHavePalletSpotCountGreaterThan: {
		ruleType: 'size',
		sizeType: 'palletSpotCount',
		sizeOperator: 'greaterThan',
	},
	mustHavePalletSpotCountGreaterThanOrEqualTo: {
		ruleType: 'size',
		sizeType: 'palletSpotCount',
		sizeOperator: 'greaterThanOrEqualTo',
	},
	mustHaveSameEquipmentType: { ruleType: 'equipmentType' },
}

export const getRuleStringFromObject = (ruleObject: IFormattedRule): string => {
	let rule: string
	if (ruleObject.ruleType === 'address') {
		rule =
			'mustHaveSame' +
			capitalizeFirstLetter(ruleObject.stopType) +
			capitalizeFirstLetter(ruleObject.addressMatchStrength) +
			'(shipment1, shipment2)'
	} else if (ruleObject.ruleType === 'size') {
		rule =
			'mustHave' +
			capitalizeFirstLetter(ruleObject.sizeType) +
			capitalizeFirstLetter(ruleObject.sizeOperator) +
			'(shipment1, shipment2, ' +
			ruleObject.sizeThreshold +
			')'
	} else if (ruleObject.ruleType === 'equipmentType') {
		rule = 'mustHaveSameEquipmentType(shipment1, shipment2)'
	}
	return rule
}

export const getRuleObjectFromString = (rule: string): IFormattedRule => {
	const ruleObject: IFormattedRule = l.cloneDeep(
		functionToFormattedRule[rule.split('(')[0]],
	)
	if (ruleObject) {
		const thresholdMatch = rule.match(/shipment2, (.*)\)/)
		ruleObject.sizeThreshold = thresholdMatch && thresholdMatch[1]
		return ruleObject
	}
}
