import React, { useEffect, useState } from 'react'
import { FC } from 'ui/FunctionalComponent'
import { Card } from 'ui/components/common/card'
import { t } from 'ui/components/i18n/i18n'
import { IconButton, solidIcons } from 'ui/components/common/icon'
import { apiBrokerInvoice, apiTypes } from 'ui/api'
import { Input } from 'ui/components/common/input/Input'
import { sosToast } from 'ui/common/components/toast'
import * as classes from './CustomerInvoiceNotes.module.scss'
import { Debug } from 'ui/components/dev'

const tPrefix = 'page.customerInvoice.customerInvoiceNotes'

export const CustomerInvoiceNotes: FC = (props: {
	brokerInvoice: apiTypes.BrokerInvoiceResponse
	onUpdate: (notes: string) => void
}) => {
	const { brokerInvoice, onUpdate } = props

	const [customerInvoiceNotes, setCustomerInvoiceNotes] = useState<string>(
		brokerInvoice?.notes,
	)
	const [editCustomerInvoiceNotes, setEditCustomerInvoiceNotes] = useState<
		boolean
	>(false)
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const updateCustomerInvoiceNotes = async (): Promise<void> => {
		setIsLoading(true)

		const response = await apiBrokerInvoice.updateBrokerInvoice(
			() => {},
			brokerInvoice.id,
			{ ...brokerInvoice, notes: customerInvoiceNotes },
		)

		if (response.error) {
			if (brokerInvoice.invoiceDate === 'Invalid Date') {
				// custom toast
			} else {
				sosToast.sendApiErrorResponseToast(response)
			}
		} else {
			onUpdate(customerInvoiceNotes)
		}
		setIsLoading(false)
	}

	useEffect(() => {
		if (brokerInvoice !== null) setCustomerInvoiceNotes(brokerInvoice?.notes)
	}, [brokerInvoice])

	return (
		<Card
			title={
				<div className={classes.invoiceNotesHeader}>
					<div>{t('customerInvoiceNotes', tPrefix)}</div>
					<div>
						{brokerInvoice?.invoiceStatus === 'review' ||
						brokerInvoice?.invoiceStatus === 'approved' ? (
							<IconButton
								icon={
									!editCustomerInvoiceNotes
										? solidIcons.faPencilAlt
										: solidIcons.faCheck
								}
								color={editCustomerInvoiceNotes ? 'green' : 'black'}
								spin={isLoading}
								iconClassName={classes.editSaveIcon}
								onClick={async () => {
									if (editCustomerInvoiceNotes) {
										await updateCustomerInvoiceNotes()
									}
									setEditCustomerInvoiceNotes(!editCustomerInvoiceNotes)
								}}
								testId={'customer-invoice-edit-save'}
							></IconButton>
						) : null}
					</div>
				</div>
			}
			color={'darkBlue'}
			fillHeight={true}
			testId={'customer-invoice-parent-card'}
		>
			<div>
				<Input
					value={customerInvoiceNotes}
					onChange={(newVal: string) => setCustomerInvoiceNotes(newVal)}
					autofocus={editCustomerInvoiceNotes}
					readOnly={!editCustomerInvoiceNotes}
					multiline={true}
					width={'100%'}
				></Input>
			</div>
			<Debug data={brokerInvoice} label='brokerInvoice' />
		</Card>
	)
}
