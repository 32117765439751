import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { apiTypes } from 'ui/api'
import { AngryRedBox } from 'ui/components/common/angry-red-box'
import { Loader } from 'ui/components/common/loader'
import { Modal } from 'ui/components/common/modal'
import { OkCancelButtons } from 'ui/components/common/okCancelButtons'
import { t } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight'
import { FlexItem, FlexRow } from 'ui/components/layout/flexRow'
import { Spacer } from 'ui/components/layout/spacer'
import { CalendarInput } from 'ui/components/shared/ShipmentStopCard'
import { l } from 'ui/lib/lodashImports'
import {
	DockSchedulerTimeSlots,
	IScheduleTimeSlot,
} from '../components/DockSchedulerTimeSlots'
import * as classes from '../DockScheduler.module.scss'

const tPrefix = 'page.dockScheduler.appointmentScheduler'

export const TimeslotSelectorModal: FC = (props: {
	isOpen: boolean
	onModalClose: () => void
	docks: apiTypes.DockResponse[]
	appointments: apiTypes.AppointmentResponse[]
	fetchingAppointments: boolean
	date: string // ISO date
	onUpdateDate?: (date: string) => void
	equipmentType: apiTypes.ShipmentResponse['equipmentType']
	flow: apiTypes.AppointmentResponse['stopType']
	mode: apiTypes.ShipmentResponse['mode']
	zipcode: string
	selectedTimeSlot?: IScheduleTimeSlot
	selectedTimeSlotWarning?: string
	onSelectTimeSlot: (slot: IScheduleTimeSlot) => void
	onSubmit?: () => void
	submitting: boolean
	title?: React.ReactNode
	children?: React.ReactNode
}) => {
	const [calendarIsOpen, setCalendarIsOpen] = React.useState(false)
	return (
		<Modal
			isOpen={props.isOpen}
			onModalClose={props.onModalClose}
			title={props.title || t('title', tPrefix)}
			closeModalX={true}
			content={() => {
				return (
					<div className={classes.timeSlotModal}>
						{props.onUpdateDate && (
							<>
								<CalendarInput
									isOpen={calendarIsOpen}
									onChangeOpen={setCalendarIsOpen}
									dateString={props.date}
									onChangeText={props.onUpdateDate}
								/>
								<Spacer />
							</>
						)}
						{props.children}
						{props.fetchingAppointments && (
							<FlexRow>
								<FlexItem>
									<Loader isLoading={true} />
								</FlexItem>
								<FlexItem>{t('fetchingAppointments', tPrefix)}</FlexItem>
							</FlexRow>
						)}
						{!props.fetchingAppointments && props.isOpen && (
							<DockSchedulerTimeSlots
								docks={props.docks}
								appointments={props.appointments}
								date={props.date}
								equipmentType={props.equipmentType}
								flow={props.flow}
								mode={props.mode}
								zipcode={props.zipcode}
								selectedTimeSlot={props.selectedTimeSlot}
								onSelectTimeSlot={props.onSelectTimeSlot}
							/>
						)}
						<Spacer />
						<FlexRow fullWidth={true}>
							{props.selectedTimeSlotWarning && (
								<FlexItem>
									<AngryRedBox>{props.selectedTimeSlotWarning}</AngryRedBox>
								</FlexItem>
							)}
							{props.onSubmit && (
								<FlexItem>
									<AlignRight>
										<OkCancelButtons
											okColor='blue'
											onOk={props.onSubmit}
											isSpinning={props.submitting}
											onCancel={props.onModalClose}
											ok={t('submit', tPrefix)}
											isValid={!l.isNil(props.selectedTimeSlot)}
										/>
									</AlignRight>
								</FlexItem>
							)}
						</FlexRow>
					</div>
				)
			}}
		/>
	)
}
