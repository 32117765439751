export const classDensityBreaks = [
	['30', '60'],
	['22.5', '65'],
	['15', '70'],
	['12', '85'],
	['10', '92.5'],
	['8', '100'],
	['6', '125'],
	['4', '175'],
	['2', '250'],
	['1', '300'],
	['0', '400'],
]
