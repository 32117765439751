import { FC } from 'app/FunctionalComponent'
import React, { useState } from 'react'
import { apiTmsServiceConfig, apiTypes } from 'ui/api'
import { ndash } from 'ui/components/common'
import { IconHover } from 'ui/components/common/icon-hover/IconHover'
import { solidIcons } from 'ui/components/common/icon/solidIcons'
import { Loader } from 'ui/components/common/loader/Loader'
import { t, tDate, tString } from 'ui/components/i18n/i18n'
import { RateRow } from 'ui/components/rates-table/RatesTable'
import * as tableClasses from 'ui/components/rates-table/RatesTable.module.scss'
import { CarrierImage } from 'ui/components/shared/CarrierImage'
import { l } from 'ui/lib/lodashImports'
import { CostContainer } from 'ui/pages/shipment-profile/shared/components/CostContainer'
import { RateBookingDetails } from 'ui/pages/shipment-profile/shared/functions/rateBookingDetailsTypes'
import { ShipperShipmentProfileRateInteraction } from 'ui/pages/shipment-profile/shipper/components/rates/ShipperShipmentProfileRateInteraction'
import { useOnce } from 'ui/components/hooks'
import { fireAndForget } from 'ui/lib/async'

export const ShipperShipmentProfileRatesTable: FC = (props: {
	shipment: apiTypes.ShipmentResponse
	tPrefix: string
	rateTableRows: RateRow[]
	isRating: boolean
	isBooking: boolean
	setIsBookCarrierModalOpen: React.Dispatch<React.SetStateAction<boolean>>
	setRateBookingDetails: React.Dispatch<
		React.SetStateAction<RateBookingDetails>
	>
}) => {
	const {
		shipment,
		tPrefix,
		rateTableRows,
		isRating,
		isBooking,
		setIsBookCarrierModalOpen,
		setRateBookingDetails,
	} = props

	const [returnToParcel, setReturnToParcel] = useState<boolean>(false)

	useOnce(() => {
		fireAndForget(async () => {
			const result = await apiTmsServiceConfig.getTmsServiceConfig()
			if (result.data) {
				setReturnToParcel(
					shipment.expectedMode === 'parcel' &&
						result.data.parcel.automaticNewQuoteRerouteAfterBooking,
				)
			}
		}, '')
	})

	if (!shipment) {
		return null
	}

	const tPrefixTable = tPrefix + '.table'

	return (
		<div>
			{(isRating || isBooking) && (
				<div className='d-flex flex-row'>
					<div className='p-2'>
						<Loader isLoading={true} testId='is-rating-spinner' />
					</div>
					<div className='p-2'>
						{isRating ? t('loadingRates', tPrefix) : t('bookingRate', tPrefix)}
					</div>
				</div>
			)}

			<table
				className={tableClasses.tableRates}
				cellPadding={0}
				cellSpacing={0}
				data-testid='rates-table'
			>
				<thead>
					<tr>
						<th></th>
						<th>{t('providerName', tPrefixTable)}</th>
						<th>{t('serviceLevel', tPrefixTable)}</th>
						<th className={tableClasses.center}>{t('method', tPrefixTable)}</th>
						<th className={tableClasses.center}>
							{t('quoteType', tPrefixTable)}
						</th>
						<th className={tableClasses.center}>{t('cost', tPrefixTable)}</th>
						<th className={tableClasses.center}>
							{t('transit', tPrefixTable)}
						</th>
						<th className={tableClasses.left}>{t('delivery', tPrefixTable)}</th>
						<th className={tableClasses.left}>
							{t('guaranteed', tPrefixTable)}
						</th>
						<th></th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{l.map(rateTableRows, (rate, rateIdx) => {
						const cost = rate.buyPrice

						return (
							<tr key={rateIdx} data-testid={`rates-table-${rateIdx}`}>
								<td>
									<CarrierImage
										carrier={rate.providerName}
										style={{ maxHeight: '25px', margin: '5px' }}
									/>
								</td>
								<td>
									<div>
										{rate.providerName
											? rate.providerName.replace(/_/g, ' ')
											: ' '}
									</div>
								</td>
								<td>
									{rate.serviceLevel || ndash}{' '}
									{rate.costBreakdown?.accessorials > 0 && '(Saturday)'}
								</td>
								<td className={tableClasses.center}>
									{rate.method
										? t(rate.method, 'page.makeOffer.shipmentIs')
										: ndash}
								</td>
								<td className={tableClasses.center}>
									{rate.quoteType
										? t(rate.quoteType, 'page.shipmentProfile.rates.quoteTypes')
										: ndash}
								</td>
								<td className={tableClasses.center} data-testid={'cost'}>
									<CostContainer cents={cost} />
								</td>
								<td className={tableClasses.center}>
									<div>
										{rate.transit
											? `${rate.transit} ${tString('common.days')}`
											: ndash}
									</div>
								</td>
								<td className={tableClasses.left}>
									{tDate(rate.delivery) || ndash}
								</td>
								<td className={tableClasses.left}>
									{tDate(rate.guaranteed) || ndash}
								</td>
								<td>
									<div style={{ paddingLeft: '20px' }}>
										<ShipperShipmentProfileRateInteraction
											rateRow={rate}
											tPrefix={tPrefixTable}
											shipment={shipment}
											shipmentIsRating={isRating}
											shipmentIsBooking={isBooking}
											setIsBookCarrierModalOpen={setIsBookCarrierModalOpen}
											setRateBookingDetails={setRateBookingDetails}
											returnToParcel={returnToParcel}
										/>
									</div>
								</td>
								<td>
									{rate.message ? (
										<IconHover
											color='red'
											popupPosition='topLeft'
											testId='message-flag'
											icon={solidIcons.faFlag}
										>
											{rate.message}
										</IconHover>
									) : null}
								</td>
							</tr>
						)
					})}
				</tbody>
			</table>
		</div>
	)
}
