import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import { LaneAnalysis } from './LaneAnalysis'
import { Providers } from './Providers'

export const Marketplace: FC = () => {
	return (
		<div className='bootstrap-wrapper'>
			<div className='container'>
				<Router>
					<Switch>
						<Route path='/marketplace/providers'>
							<Providers />
						</Route>
						<Route path='/marketplace/lane-analysis'>
							<LaneAnalysis />
						</Route>
					</Switch>
				</Router>
			</div>
		</div>
	)
}
