import { ProviderName, ServiceLevel } from 'ui/api/apiTypes'

const parcelCarrier: ProviderName[] = ['Fedex', 'UPS']

export const mappedParcelCarrier = parcelCarrier.map((value) => ({ value }))

const fedexParcelServiceLevels: ServiceLevel[] = [
	'Ground',
	'FedEx Ground Home Delivery',
	'FedEx First Overnight',
	'FedEx Priority Overnight',
	'FedEx 2 Day AM',
	'FedEx Express Saver',
	'FedEx Europe First International Priority',
	'International Priority DirectDistribution',
	'FedEx International Economy',
	'FedEx International First',
	'FedEx International Priority',
	'FedEx Smart Post',
]

export const mappedFedexParcelServiceLevels = fedexParcelServiceLevels.map(
	(value) => ({ value }),
)

const upsParcelServiceLevels: ServiceLevel[] = [
	'Overnight',
	'2nd Day',
	'Ground',
	'UPS Next Day Air Early',
	'UPS Next Day Air Saver',
	'UPS 2nd Day Air A.M.',
	'UPS 3 Day Select',
	'UPS Standard',
	'UPS Worldwide Express',
	'UPS Worldwide Express Plus',
	'UPS Worldwide Expedited',
	'UPS Worldwide Saver',
	'UPS Today Standard',
	'UPS Today Dedicated Courier',
	'UPS Today Intercity',
	'UPS Today Express',
	'UPS Today Express Saver',
	'UPS Access Point Economy',
	'UPS SurePost (USPS) < 1lb',
	'UPS SurePost (USPS) > 1lb',
	'UPS SurePost (USPS) BPM',
	'UPS SurePost (USPS) Media',
]

export const mappedUpsParcelServiceLevels = upsParcelServiceLevels.map(
	(value) => ({ value }),
)
