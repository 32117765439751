import { apiCommon, apiTypes } from 'ui/api'
import { IRequestState } from 'ui/api/requestState'

export async function createPaymentAllocation(
	paymentId: string,
	data: apiTypes.PaymentAllocationCreateRequest,
): Promise<IRequestState<apiTypes.PaymentAllocationResponse>> {
	const url = `invoicing/payments/${paymentId}/allocations`
	return await apiCommon.apiFetch(
		() => {},
		{
			method: 'POST',
			data,
		},
		url,
	)
}

export async function getPaymentAllocation(
	paymentId: string,
	paymentAllocationId: string,
): Promise<IRequestState<apiTypes.PaymentAllocationResponse>> {
	const url = `invoicing/payments/${paymentId}/allocations/${paymentAllocationId}`
	return await apiCommon.apiFetch(
		() => {},
		{
			method: 'GET',
		},
		url,
	)
}

export async function updatePaymentAllocation(
	paymentId: string,
	paymentAllocationId: string,
	data: apiTypes.PaymentAllocationUpdateRequest,
): Promise<IRequestState<apiTypes.PaymentAllocationResponse>> {
	const url = `invoicing/payments/${paymentId}/allocations/${paymentAllocationId}`
	return await apiCommon.apiFetch(
		() => {},
		{
			method: 'PUT',
			data,
		},
		url,
	)
}

export async function deletePaymentAllocation(
	paymentId: string,
	paymentAllocationId: string,
): Promise<IRequestState<apiTypes.PaymentAllocationResponse>> {
	const url = `invoicing/payments/${paymentId}/allocations/${paymentAllocationId}`
	return await apiCommon.apiFetch(
		() => {},
		{
			method: 'DELETE',
		},
		url,
	)
}
