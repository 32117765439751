export interface IPageElementConfig {
	required?: boolean
	hidden?: boolean
	advanced?: boolean
}

export interface IPageElementMode<T> {
	mode: T
}

export interface IPageElementConfigWithDefault<T> extends IPageElementConfig {
	default: T
}

export function noop(): void {}
export const test = '12'
