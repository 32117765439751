import React, { useEffect, useState } from 'react'
import { FC } from 'ui/FunctionalComponent'
import { Modal } from 'ui/components/common/modal'
import { t } from 'ui/components/i18n/i18n'
import { sosShipmentProfileBroker } from 'ui/pages/shipment-profile/broker'
import { IBookingWarningModalState } from 'shipmentProfileBroker/sosShipmentProfileBroker'
import { Spacer } from 'ui/components/layout/spacer'
import { AlignRight } from 'ui/components/layout/alignRight'
import { OkCancelButtons } from 'ui/components/common/okCancelButtons'
import { apiBroker } from 'ui/api'
import { sosToast } from 'common/components/toast'

const tPrefix = 'page.shipmentProfile'
const tPrefixModal = tPrefix + '.modalBookWarning'

interface UIState {
	message: string
	isLoading: boolean
}

export const BookingWarningModal: FC = (props: IBookingWarningModalState) => {
	const {
		method,
		isOpen,
		warnings,
		rateBookingDetails,
		headerWarnings,
		setIsNotifyProviderModalOpen,
	} = props
	const [state, setState] = useState<UIState>({
		message: '',
		isLoading: false,
	})
	useEffect(() => {
		if (warnings) {
			setState((prevState) => ({
				...prevState,
				message: warnings[0]?.message || '',
			}))
		}
	}, [warnings])

	const style = {
		maxWidth: 550,
		'text-align': 'center',
	}

	const setLoading = (isLoading: boolean): void => {
		setState((previousState) => ({
			...previousState,
			isLoading,
		}))
	}

	return (
		<Modal
			content={() => (
				<div
					// className={classes.bookCarrierModal}
					data-testid={'booking-warning-modal'}
				>
					<Spacer height={15} />
					<div style={style}>{state.message}</div>
					<Spacer height={15} />
					<AlignRight>
						<OkCancelButtons
							ok={t('book', tPrefixModal)}
							onOk={async () => {
								setLoading(true)
								const bookResult = await apiBroker.brokerBook(
									() => {},
									rateBookingDetails.shipmentId,
									rateBookingDetails.buyRateId,
									method,
									headerWarnings,
								)
								setLoading(false)

								if (bookResult.data) {
									sosShipmentProfileBroker.setShipmentReference(bookResult.data)
									if (method === 'manual') {
										setIsNotifyProviderModalOpen(true)
									}
									sosShipmentProfileBroker.toggleBookingWarningModal(false)
								} else if (bookResult.error) {
									sosToast.sendApiErrorResponseToast(bookResult)
								}
							}}
							okColor='green'
							cancel={t('cancel', tPrefixModal)}
							onCancel={() =>
								sosShipmentProfileBroker.toggleBookingWarningModal(false)}
							onclose={() => {
								sosShipmentProfileBroker.toggleBookingWarningModal(false)
							}}
							isSpinning={state.isLoading}
							isValid={true}
							okTestId={'booking-warning-book-button'}
							cancelTestId={'booking-warning-cancel-button'}
						/>
					</AlignRight>
				</div>
			)}
			isOpen={isOpen}
			onModalClose={() => {
				sosShipmentProfileBroker.toggleBookingWarningModal(false, [])
			}}
			closeModalX={true}
			title={t('confirmBooking', tPrefixModal)}
		/>
	)
}
