import { IProviderSignupForm } from 'ui/pages/provider-portal/sign-up'
import { callTms2Global } from './IframeRpc'
import { IAddCustomerForm } from 'ui/forms/formProviderPortalCustomersAdd'

export interface IShipmentListRequestQuery {
	shipment_status: string
	top_level_organization_id?: string
	pro_number?: string
	$or?: any
}

interface IQueryOptions {
	skip?: number
	limit?: number
	fields?: { [key: string]: boolean }
}

const tms2_providerPortal = {
	getShipments: async (args: {
		query: IShipmentListRequestQuery
		options: IQueryOptions
		exportMode?: boolean
	}) => {
		return await callTms2Global<any>('providerPortal_getShipments', args)
	},
	getShipmentDocuments: async (args: {
		query
		options: IQueryOptions
		exportMode?: boolean
	}) => {
		return await callTms2Global<any>(
			'providerPortal_getShipmentDocuments',
			args,
		)
	},
	getInvoicesReceivable: async (args: {
		query
		options: IQueryOptions
		exportMode?: boolean
	}) => {
		return await callTms2Global<any>(
			'providerPortal_getInvoicesReceivable',
			args,
		)
	},
	getInvoicesPayable: async (args: {
		query
		options: IQueryOptions
		exportMode?: boolean
	}) => {
		return await callTms2Global<any>('providerPortal_getInvoicesPayable', args)
	},
	getDisputes: async (args: {
		query
		options: IQueryOptions
		exportMode?: boolean
	}) => {
		return await callTms2Global<any>('providerPortal_getDisputes', args)
	},
	userSignup: async (args: { signupForm: Partial<IProviderSignupForm> }) => {
		return await callTms2Global<any>('providerPortal_userSignup', args)
	},
	getCustomers: async () => {
		return await callTms2Global<any>('providerPortal_getCustomers', null)
	},
	addCustomer: async (args: { addForm: Partial<IAddCustomerForm> }) => {
		return await callTms2Global<any>('providerPortal_addCustomer', args)
	},
}
export { tms2_providerPortal }
