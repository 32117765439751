import { FC } from 'app/FunctionalComponent'
import React, { useState } from 'react'
import { Card } from 'ui/components/common/card'
import { Checkbox } from 'ui/components/common/checkbox'
import { t } from 'ui/components/i18n/i18n'
import { FlexItem, FlexRow } from 'ui/components/layout/flexRow'
import { Accessorials, IAccessorial } from 'ui/components/shared/accessorials'
import { l } from 'ui/lib/lodashImports'
import { sosComponentExamples } from 'ui/state'
import { Section } from './Section'

const accessorialFields: IAccessorial[] = [
	{ accessorial: { accessorialKey: 'appointment' }, checked: true },
	{ accessorial: { accessorialKey: 'tarp' } },
	{ accessorial: { accessorialKey: 'other' }, checked: false },
]

export const SectionCheckboxes: FC = (props: {
	stateComponentExamples: sosComponentExamples.IStateComponentExamples
}) => {
	const [options, setOptions] = useState([
		{ label: 'cats', checked: true },
		{ label: 'dogs', checked: true },
		{ label: 'mice', checked: false },
		{ label: 'birds', checked: false },
	])

	return (
		<Section
			title={t('page.componentExamples.checkboxes')}
			stateComponentExamples={props.stateComponentExamples}
			name='checkboxes'
		>
			<FlexRow>
				<FlexItem>
					<Card title='Default Style' color='blue'>
						{options.map((c, cIdx) => (
							<div key={cIdx}>
								<Checkbox
									value={c.checked}
									onChange={(val) => {
										const newOptions = l.cloneDeep(options)
										newOptions[cIdx].checked = val
										setOptions(newOptions)
									}}
								>
									{c.label}
								</Checkbox>
							</div>
						))}
					</Card>
				</FlexItem>
				<FlexItem>
					<Card title='Checkbox States' color='blue'>
						<table>
							<thead>
								<tr>
									<th>&nbsp;</th>
									<th>Default</th>
									<th>Pretty</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Checked</td>
									<td>
										<Checkbox value={true} onChange={() => {}}>
											Checked
										</Checkbox>
									</td>
								</tr>
								<tr>
									<td>Indeterminate</td>
									<td>
										<Checkbox
											value={false}
											indeterminate={true}
											onChange={() => {}}
										>
											Indeterminate
										</Checkbox>
									</td>
								</tr>
								<tr>
									<td>Unchecked</td>
									<td>
										<Checkbox value={false} onChange={() => {}}>
											Unchecked
										</Checkbox>
									</td>
								</tr>
							</tbody>
						</table>
					</Card>
				</FlexItem>
			</FlexRow>
			<FlexRow>
				<div>Accessorials</div>
				<div>
					<Accessorials
						metadata={[
							{
								accessorialKey: 'tarp',
								doNotTranslate: true,
							},
						]}
						accessorialObject={accessorialFields}
						updateState={() => {
							alert('hi')
						}}
					/>
				</div>
			</FlexRow>
		</Section>
	)
}
