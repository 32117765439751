import { sosRouter } from 'ui/components/common/router'
import { l } from 'ui/lib/lodashImports'
import { r } from 'ui/lib/ramdaImports'
import { IDatePickerState } from 'ui/pages/shipments'
import {
	getWindow,
	windowExists,
	getLocationHref,
} from 'ui/components/common/router/windowUtils'

export interface IQueryParamMeta {
	type: 'strings' | 'string' | 'elasticsearch-page' | 'date-range'
	values?: string[]
	default?: string | number
	lens?: string
}
export type IQueryParamsMeta = { [key: string]: IQueryParamMeta }

export const removePrefix = (prefix: string, s: string): string =>
	r.when<string, string>(r.startsWith(prefix), r.drop(prefix.length))(s)

export const queryStringToObject = (
	queryString: string,
): {
	[index: string]: string
} => {
	const qs = removePrefix('?', queryString)
	const pairs = r.map(r.split('='), r.split('&', qs)) as [string, string][]
	r.forEach((pair) => {
		if (pair[1]) {
			pair[1] = decodeURIComponent(pair[1])
		}
	}, pairs)
	return r.fromPairs(pairs)
}

export const queryStringToQueryParams = (
	meta: IQueryParamsMeta,
	queryString: string,
): any => {
	const rawParams = queryStringToObject(queryString)
	return r.reduce(
		(obj, cIdx) => {
			let ret: any = null
			const m = meta[cIdx]
			ret = r.defaultTo(m.default, rawParams[cIdx])
			if (m.type === 'elasticsearch-page') {
				ret = l.parseInt('' + ret, 10)
			}
			return r.assoc('' + cIdx, ret, obj)
		},
		{} as any,
		r.keys(meta),
	)
}

const queryDate = (date: string): string => {
	return date.replace(new RegExp('/', 'g'), '-')
}

export const stringifyParams = (
	meta: IQueryParamsMeta,
	params: any,
): string => {
	const obj: any = {}

	let qs = ''

	r.forEach((cIdx) => {
		const m = meta[cIdx]

		if (r.isNil(params[cIdx]) || params[cIdx] === m.default) {
			// Don't write this
		} else {
			const val = r.defaultTo(m.default, params[cIdx])
			if (m.type === 'date-range') {
				const dps = val as IDatePickerState
				obj[cIdx] = dps.mode
				if (dps.mode === 'in-the-last') {
					obj[cIdx + '-days'] = dps.inLastX
				}
				if (dps.mode === 'on') {
					obj[cIdx + '-on'] = queryDate(dps.date1String)
				}
				if (dps.mode === 'between') {
					obj[cIdx + '-from'] = queryDate(dps.date1String)

					obj[cIdx + '-to'] = queryDate(dps.date2String)
				}
			} else {
				obj[cIdx] = val
				if (qs) {
					qs += '&'
				}
				qs += `${cIdx}=${encodeURIComponent(val)}`
			}
		}
	}, r.keys(meta))

	return qs
}

export function getParams(meta: IQueryParamsMeta): any {
	if (!windowExists) {
		return {}
	}
	const qs = l.split(getWindow().location.hash, '?')[1] || ''
	const params = queryStringToQueryParams(meta, qs)
	return params
}

export function syncParams(paramsMeta: IQueryParamsMeta, existing: any): any {
	if (!windowExists) {
		return {}
	}
	const params = queryStringToQueryParams(
		paramsMeta,
		getWindow().location.search,
	)
	return r.when(r.equals(existing), existing)(params)
}

export const fIndexOf = (toFind: string) => (str: string): number => {
	return ('' + str).indexOf(toFind)
}

export function getQueryString(href: string): string {
	const idx = fIndexOf('?')(href)
	if (idx === -1) {
		return ''
	}
	return r.drop(idx, href)
}

export const genericSyncQueryParams = (
	fromQuery: (queryString) => void,
	toQuery: () => any,
): void => {
	if (false === sosRouter.isQueryStringConsumed()) {
		sosRouter.consumeQueryString()
		const queryString = getQueryString(getLocationHref())
		fromQuery(queryString)
	} else {
		const qs = toQuery()
		sosRouter.setQueryString('?' + qs)
	}
}
