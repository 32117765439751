import { l } from '../lodashImports'

export function prettyLocation(city, state?, country?): string {
	let prettiedLocation = ''

	if (city) {
		const cityParts = l.split(city.trim(), ' ')
		prettiedLocation = l.join(
			l.map(cityParts, (c) => l.capitalize(c)),
			' ',
		)

		const prettyingCityParts = l.split(prettiedLocation, '-')
		prettiedLocation = l.join(
			l.map(
				prettyingCityParts,
				(c) => c[0].toUpperCase() + c.substr(1, c.length - 1), // keep all cases not at index 0 the same
			),
			'-',
		)
	}

	if (state) {
		if (prettiedLocation) {
			prettiedLocation += ', '
		}
		prettiedLocation += l.upperCase(state)
	}
	const upperCaseCountry = l.upperCase(country)

	if (country && upperCaseCountry !== 'US' && upperCaseCountry !== 'USA') {
		if (prettiedLocation) {
			prettiedLocation += ', '
		}
		prettiedLocation += upperCaseCountry
	}
	return prettiedLocation
}
