import { FC } from 'app/FunctionalComponent'
import React from 'react'
import ReactSelect from 'react-select'
import { tString } from 'ui/components/i18n/i18n'
import { l } from 'ui/lib/lodashImports'
import { ISelectOptions } from './'

const _defaultProps = { isClearable: true }
export const SelectThirdParty: FC = (props: {
	name?: string
	tPrefix?: string
	value: string
	options: string[] | ISelectOptions[]
	testId?: string
	width?: string
	onChange?: (newValue) => void
	isClearable?: boolean
}) => {
	const tPrefix = props.tPrefix || ''

	const processedOptions = l.map(props.options, (c) => {
		if (typeof c === 'string') {
			let label = ''
			if (tPrefix) {
				label = tString(tPrefix + c)
			} else {
				label = c
			}
			return {
				value: c,
				label: label,
			}
		} else {
			const opt: ISelectOptions = c as ISelectOptions // Required for Typescript
			let label = ''
			if (tPrefix || opt.t) {
				label = tString(tPrefix + (opt.t || l.camelCase(opt.value)))
			} else {
				label = opt.label || opt.value
			}
			return {
				value: opt.value,
				label: label,
			}
		}
	})

	const onChange = (value, { action, removedValue }): void => {
		props.onChange(value ? value.value : null)
	}

	const height = '21px'
	const heightM2 = '19px'
	const fontFamily = 'Arial'
	const fontSize = '13.3333px'
	const borderColor = 'rgb(170, 170, 170)'
	return (
		<div>
			<ReactSelect
				options={processedOptions}
				isSearchable={true}
				isClearable={props.isClearable}
				value={l.find(processedOptions, (c) => c.value === props.value) || ''}
				onChange={onChange}
				styles={{
					clearIndicator: (styles) => ({
						...styles,
						...{ padding: '0px' },
					}),
					indicatorsContainer: (styles) => ({
						...styles,
						...{
							padding: '0px',
							minHeight: heightM2,
							maxHeight: heightM2,
						},
					}),
					indicatorSeparator: (styles) => ({
						...styles,
						...{
							padding: '0px',
							marginBottom: '2px',
							marginTop: '1px',
							height: '16px',
						},
					}),

					dropdownIndicator: (styles) => ({
						...styles,
						...{ padding: '0px' },
					}),

					placeholder: (styles) => ({
						...styles,
						...{ top: '8px', fontFamily, fontSize },
					}),
					singleValue: (styles) => ({
						...styles,
						...{ top: '8px', fontFamily, fontSize, marginLeft: '1px' },
					}),
					valueContainer: (styles) => ({
						...styles,
						...{ padding: '0px', margin: '0px', marginLeft: '1px' },
					}),
					container: (styles) => ({
						...styles,
						...{
							width: props.width,
							minHeight: height,
							maxHeight: height,
						},
					}),
					control: (styles) => ({
						...styles,
						...{
							minHeight: height,
							maxHeight: height,
							borderRadius: '3px',
							padding: '0px',
							borderColor,
						},
					}),
					input: (styles) => ({
						...styles,
						...{
							minWidth: '100px',
							minHeight: heightM2,
							maxHeight: heightM2,
							padding: '0px',
							margin: '0px',
							marginLeft: '1px',
							fontFamily,
							fontSize,
							color: 'black',
						},
					}),
				}}
			/>
		</div>
	)
}

SelectThirdParty.defaultProps = _defaultProps
