import { FC } from 'app/FunctionalComponent'
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { apiShipments, apiTypes } from 'ui/api'
import { IRequestState } from 'ui/api/requestState'
import { Input } from 'ui/components/common/input'
import { t, tString } from 'ui/components/i18n/i18n'
import { Spacer } from 'ui/components/layout/spacer'
import classes from './LoadOrder.module.scss'
import { Button } from 'ui/components/common/button'
import { sosRouter2 } from 'ui/components/common/router'
import { useOnce } from 'ui/components/hooks'
import { fireAndForget } from 'ui/lib/async'

const tPrefix = 'page.newQuote.parcel.loadOrder'

type ExpectedMode = 'parcel' | 'freight'

export const LoadOrder: FC = (props: {
	mode: ExpectedMode
	inputRef: any
	onLoad: (shipment: apiTypes.OrderResponse) => void
	isLoadingOrder: boolean
	setIsLoadingOrder: React.Dispatch<React.SetStateAction<boolean>>
	setErrorMessage: (
		orderResponse: IRequestState<apiTypes.OrderResponse>,
	) => void
	isFetchingPrintNodeScales: boolean
	setModalTitle: string
	orderNumber: string
	onChange: (orderNumber: string) => void
}) => {
	const { onLoad, setIsLoadingOrder, setErrorMessage, inputRef } = props

	const [loadOrderNumber, setLoadOrderNumber] = useState<string>('')

	const loadOrder = async (value?: string): Promise<void> => {
		setIsLoadingOrder(true)

		const orderResponse = await apiShipments.getOrder(() => {},
		value || loadOrderNumber)

		if (orderResponse.data) {
			const autoRateServiceList = ['Freight Standard']
			const expectedMode = orderResponse.data.shipment.expectedMode

			if (
				(props.mode === 'parcel' && expectedMode !== 'parcel') ||
				(props.mode === 'parcel' &&
					autoRateServiceList.includes(
						orderResponse.data.shipment.autoRateService,
					))
			) {
				navigateToNewQuote('freight', value || loadOrderNumber)
			} else if (
				props.mode === 'freight' &&
				expectedMode === 'parcel' &&
				!autoRateServiceList.includes(
					orderResponse.data.shipment.autoRateService,
				)
			) {
				navigateToNewQuote('parcel', value || loadOrderNumber)
			} else {
				onLoad(orderResponse.data)
			}
		} else if (orderResponse.error) {
			setErrorMessage(orderResponse)
		}

		setIsLoadingOrder(false)
	}

	const navigateToNewQuote = (
		mode: ExpectedMode,
		orderNumber: string,
	): void => {
		sosRouter2.navigateTo(
			{
				url: `/shipments-v3/new-quote/${mode}?load=${orderNumber}`,
				params: {},
				queries: {},
			},
			{},
		)
	}

	const clearInput = (): void => {
		props.onChange(null)
		setLoadOrderNumber('')
	}

	useEffect(() => {
		setLoadOrderNumber(props.orderNumber)
	}, [props.orderNumber])

	useOnce(() => {
		const order = sosRouter2.getQueryParam(
			{
				url: `/shipments-v3/new-quote/${props.mode}`,
				params: {},
				queries: {
					load: {
						name: 'load',
					},
				},
			},
			{
				name: 'load',
			},
		)

		if (order) {
			fireAndForget(async () => {
				await loadOrder(order)
			}, '')

			setLoadOrderNumber(order)
		}
	})

	return (
		<div className={classes.loadOrder + ' bootstrap-wrapper'}>
			<Spacer />
			<Row>
				<Col>{t('loadAnOrder', tPrefix)}</Col>
			</Row>
			<Row className={classes.loadOrderInputAndButton}>
				<Col
					className={
						classes.loadOrderColNoPaddingRight + ' d-flex justify-content-start'
					}
				>
					<Input
						ref={inputRef}
						value={loadOrderNumber}
						onChange={props.onChange}
						testId='new-quote-parcel-load-order-id-input'
						width={'189px'}
						onEnter={(value: string) => loadOrder(value.toString())}
						autofocus
						disabled={props.isFetchingPrintNodeScales || props.isLoadingOrder}
					/>
					<Button
						color={'green'}
						className={classes.loadOrderButtons}
						isSmall={true}
						title={tString('loadOrder', tPrefix)}
						isSpinning={props.isLoadingOrder}
						onClick={() => loadOrder()}
					/>
					<Button
						color={'green'}
						className={classes.loadOrderButtons}
						isSmall={true}
						title={tString('clear', tPrefix)}
						id={'clear'}
						isSpinning={props.isLoadingOrder}
						onClick={clearInput}
					/>
				</Col>
			</Row>
		</div>
	)
}
