import { FC } from 'app/FunctionalComponent'
import React, { useState } from 'react'
import { InputGroup, DropdownButton, Dropdown } from 'react-bootstrap'
import { ClientSelector } from 'ui/components/broker'
import { IconButton, solidIcons } from 'ui/components/common/icon'
import { FilterSeparator } from 'ui/components/common/filters'
import { SearchInput } from 'ui/components/common/search'
import { StackedItem } from 'ui/components/form/StackedItem'
import { t, tArgz } from 'ui/components/i18n/i18n'
import { FlexItem, FlexRow } from 'ui/components/layout/flexRow'
import { LocationSelector } from 'ui/components/shared/location-selector'
import { IStateShipmentsList } from 'ui/pages/shipments/state/sosShipmentsList'
import { sosUser } from 'ui/state'
import { IStateUser } from 'ui/state/sosUser'
import classes from './ShipmentListOptions.module.scss'
import { ShipmentsListDatePicker } from './ShipmentsListDatePicker'
import { sosShipmentsList } from './state'
import { SelectCustomersModal } from './select-customers-modal'
import { MultiSelectFilterModal } from './multi-select-filter-modal'
import { TypeaheadOption } from 'ui/common/components/typeahead'
import { useOnce } from 'ui/components/hooks'
import { getRegionsForCountry } from 'ui/data/countryAndRegionUtils'
import { Button } from 'ui/components/common/button'
import { fireAndForget } from 'ui/lib/async'
import { clientConfigSearch } from 'ui/components/broker'

export type FilterCustomerMode = 'Single' | 'Multiple' | 'All'

export const ShipmentsListOptions: FC = (props: {
	state: IStateShipmentsList
	userState: IStateUser
	tPrefix?: string
}) => {
	const { state, tPrefix } = props

	const [isSelectCustomersModalOpen, setIsSelectCustomersModalOpen] = useState<
		boolean
	>(false)
	const [selectedCustomersFilter, setSelectedCustomersFilter] = useState<
		string[]
	>(['All'])
	const [filterCustomerMode, setFilterCustomerMode] = useState<
		FilterCustomerMode
	>('All')
	const [customers, setCustomers] = useState<TypeaheadOption[]>([])

	const [
		isSelectPickupStateModalOpen,
		setIsSelectPickupStateModalOpen,
	] = useState<boolean>(false)
	const [selectedPickupStatesFilter, setSelectedPickupStatesFilter] = useState<
		string[]
	>([])
	const [regions, setRegions] = useState<TypeaheadOption[]>([])

	const tPrefixShipmentsListOptions = tPrefix + '.shipmentsListOptions'

	useOnce(() => {
		setRegions(getRegionsForCountry('US', true, true) as TypeaheadOption[])
	})

	const openSelectCustomerModal = (): void => {
		setIsSelectCustomersModalOpen(true)
	}

	return (
		<FlexItem align={'left'}>
			<FlexRow wrap={false} verticalAlign={'bottom'}>
				<StackedItem
					label={t('page.shipmentsList.searchFor')}
					showRequired={true}
				>
					<SearchInput
						testId='shipmentsList.searchFor'
						value={state.searchFor}
						onChange={sosShipmentsList.updateSearchFor}
						width='360px'
					/>
				</StackedItem>
				<FilterSeparator />

				{sosUser.isUserBroker() ? (
					<StackedItem
						label={t('clientFilter', 'page.shipmentsList')}
						showRequired={true}
					>
						<div
							className={`bootstrap-wrapper ${classes.filterCustomerWrapper}`}
						>
							<InputGroup className={classes.filterCustomerInputGroup}>
								<div className={classes.clientSelector}>
									<ClientSelector
										isClearable={false}
										currentlySelectedClient={state.filterLocation}
										showAll={false}
										onChange={async (newVal) => {
											sosShipmentsList.setLocationIds([])
											await sosShipmentsList.updateCurrentPage(0)
											setSelectedCustomersFilter([newVal])
										}}
										onFocus={() => {
											setFilterCustomerMode('Single')
										}}
									/>
								</div>

								<DropdownButton
									as={InputGroup.Append}
									variant='outline-secondary'
									title={filterCustomerMode}
									id='input-group-dropdown-2'
									data-testid='customer-filter-dropdown'
								>
									<Dropdown.Item
										onClick={() => {
											setFilterCustomerMode('Single')
											setSelectedCustomersFilter([])
										}}
										data-testid='customer-filter-dropdown-single'
									>
										{t('single', tPrefixShipmentsListOptions)}
									</Dropdown.Item>
									<Dropdown.Item
										onClick={openSelectCustomerModal}
										data-testid='customer-filter-dropdown-multiple'
									>
										{t('multiple', tPrefixShipmentsListOptions)}
									</Dropdown.Item>
									<Dropdown.Item
										onClick={async () => {
											if (filterCustomerMode !== 'All') {
												sosUser.setSelectedClientConfig(null)
												sosShipmentsList.setLocationIds([])
												await sosShipmentsList.updateCurrentPage(0)
											}

											setFilterCustomerMode('All')
											setSelectedCustomersFilter(['All'])
										}}
										data-testid='customer-filter-dropdown-all'
									>
										{t('all', tPrefixShipmentsListOptions)}
									</Dropdown.Item>
								</DropdownButton>
							</InputGroup>

							<div>
								<IconButton
									icon={solidIcons.faPencilAlt}
									buttonClassName={classes.filterCustomerPencilIcon}
									onClick={openSelectCustomerModal}
									testId={'customer-filter-pencil-multiple'}
									color={'black'}
								/>
							</div>

							<SelectCustomersModal
								options={customers}
								isModalOpen={isSelectCustomersModalOpen}
								onModalClose={() => setIsSelectCustomersModalOpen(false)}
								tPrefix={tPrefix}
								selectedCustomers={selectedCustomersFilter}
								onFilterApplied={async (options: TypeaheadOption[]) => {
									const filters = options.map((option) => option.value)
									setSelectedCustomersFilter(filters)

									sosUser.setSelectedClientConfig(null)

									if (filters.length > 0) {
										if (filters.length === customers.length) {
											sosShipmentsList.setLocationIds([])
											setFilterCustomerMode('All')
										} else if (options.length > 1) {
											sosShipmentsList.setLocationIds(filters)
											setFilterCustomerMode('Multiple')
										} else if (options.length === 1) {
											sosShipmentsList.setLocationIds(filters)
											sosShipmentsList.setSelectedLocation(options[0])
											setFilterCustomerMode('Single')
										}
									}

									await sosShipmentsList.updateCurrentPage(0)
								}}
							/>
						</div>
					</StackedItem>
				) : (
					<StackedItem
						label={t('page.shipmentsList.locationFilter')}
						showRequired={true}
					>
						<LocationSelector
							currentlySelectedLocation={state.filterLocation}
							onChange={sosShipmentsList.selectFilterLocation}
							includeAll={true}
							onlyShowLeafLocations={true}
						/>
					</StackedItem>
				)}

				<FilterSeparator />

				<StackedItem
					label={t('page.shipmentsList.pickupDate')}
					showRequired={true}
				>
					<ShipmentsListDatePicker
						datePickerState={state.datePickerState}
						onChangeDatePickerState={sosShipmentsList.updateDatePickerState}
					/>
				</StackedItem>

				<Button
					color={'blue'}
					onClick={() => setIsSelectPickupStateModalOpen(true)}
					testId={'multi-select-pickup-state-filter-button'}
					isDisabled={false}
				>
					{selectedPickupStatesFilter.length < 1
						? t('pickupState', tPrefixShipmentsListOptions)
						: tArgz(
								'pickupStateCount',
								{ pickupStateCount: selectedPickupStatesFilter.length },
								tPrefixShipmentsListOptions,
						  )}
				</Button>

				<MultiSelectFilterModal
					options={regions}
					isModalOpen={isSelectPickupStateModalOpen}
					onModalClose={() => setIsSelectPickupStateModalOpen(false)}
					tPrefix={tPrefixShipmentsListOptions}
					selectedFilters={selectedPickupStatesFilter}
					onFilterApplied={(filters: string[]) => {
						setSelectedPickupStatesFilter(filters)

						if (filters.length > 0) {
							if (filters.length === regions.length) {
								sosShipmentsList.setPickupStates([])

								const allOptions = regions.map((region) => region.value)
								setSelectedPickupStatesFilter(allOptions)
							} else {
								sosShipmentsList.setPickupStates(filters)
							}
						}
					}}
					title={'selectStates'}
					cardTitle={'states'}
				/>
			</FlexRow>
		</FlexItem>
	)
}
