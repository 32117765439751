import { IProductCatalogEntry } from 'ui/lib/books/'
import { IProductCatalog } from 'ui/lib/books/IProductCatalog'
import { apiCommon, apiTypes } from '.'
import { UpdateMode } from './apiCommon'
import { IRequestState } from './requestState'

export async function fetchProductCatalogs(
	onProgress: (rs: IRequestState<IProductCatalog[]>) => void,
): Promise<IRequestState<IProductCatalog[]>> {
	return await apiCommon.apiFetch(onProgress, {}, 'books/product')
}

export async function fetchProductCatalog(
	bookId: string,
	onProgress: (rs: IRequestState<IProductCatalog>) => void,
): Promise<IRequestState<IProductCatalog>> {
	const result = await apiCommon.apiFetch(
		onProgress,
		{},
		`books/product/${bookId}`,
	)
	return result
}

export async function updateProductCatalog(
	mode: UpdateMode,
	data: Partial<IProductCatalog>,
	onProgress: (rs: IRequestState<IProductCatalog>) => void,
): Promise<IRequestState<IProductCatalog>> {
	let url = 'books/product'
	if (data.id) {
		url += `/${data.id}`
	}
	const result = await apiCommon.apiFetch(
		onProgress,
		{
			method: apiCommon.modeToMethod(mode, data),
			data,
		},
		url,
	)
	return result
}

export async function fetchProductCatalogEntries(
	bookId: string,
	onProgress: (rs: IRequestState<IProductCatalogEntry[]>) => void,
): Promise<IRequestState<IProductCatalogEntry[]>> {
	const result = await apiCommon.apiFetch(
		onProgress,
		{},
		`books/product/${bookId}/pages`,
	)
	return result
}

export async function updateProductCatalogEntry(
	mode: UpdateMode,
	bookId: string,
	data: Partial<IProductCatalogEntry>,
	onProgress: (rs: IRequestState<IProductCatalogEntry>) => void,
): Promise<IRequestState<IProductCatalogEntry>> {
	let url = `books/product/${bookId}/pages`
	if (data.id) {
		url += `/${data.id}`
	}
	const result = await apiCommon.apiFetch(
		onProgress,
		{
			method: apiCommon.modeToMethod(mode, data),
			data,
		},
		url,
	)
	return result
}

export async function searchProductCatalog(
	onProgress: (rs: IRequestState<apiTypes.ProductPageListResponse>) => void,
	params?: {
		take?: number
		skip?: number
		query?
	},
	alternateApiKey?: string,
): Promise<IRequestState<apiTypes.ProductPageListResponse>> {
	const result = await apiCommon.apiFetch(
		onProgress,
		{ entireResponse: true, method: 'POST', data: params, alternateApiKey },
		'books/product/pages/search',
		params,
	)

	return result
}
