import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { OkCancelButtons } from 'ui/components/common/okCancelButtons'
import { PaperCard } from 'ui/components/common/paperCard/PaperCard.tsx'
import { Radio, Radios } from 'ui/components/common/radioButton'
import {
	FormStacked,
	FormStackedItem,
	FormStackedTextInput,
	IFormData,
} from 'ui/components/form'
import { t } from 'ui/components/i18n/i18n'
import { Center } from 'ui/components/layout/center/Center.tsx'
import {
	addCustomerFormMetadata,
	IAddCustomerForm,
} from 'ui/forms/formProviderPortalCustomersAdd'
import { l } from 'ui/lib/lodashImports'
import classes from 'ui/pages/provider-portal/customers/Customers.module.scss'
import { sosProviderPortalCustomers } from 'ui/state'
import { paymentNumberTypeOptions } from '../PaymentNumberTypeOptions'

export const CustomersTableAdd: FC = (props: {}) => {
	const state = sosProviderPortalCustomers.useSubscribe()
	const addCustomerFormData: IFormData<IAddCustomerForm> = {
		form: state.addFormData,
		metadata: addCustomerFormMetadata,
		onUpdateForm: sosProviderPortalCustomers.updateAddCustomerForm,
		tPrefix: 'page.providerPortal.customers.addCustomer',
	}
	return (
		<>
			{state.addFormShowing && (
				<div className={classes.customerAddFormContainer}>
					<PaperCard shadow={false}>
						<Center>
							<h2>{t('page.providerPortal.customers.addCustomer.header')}</h2>
						</Center>
						<Center>
							<FormStacked>
								<FormStackedTextInput
									formData={addCustomerFormData}
									field={'proNumber'}
									keepSpaceForRequired={true}
								/>
								<FormStackedItem
									formData={addCustomerFormData}
									field={'paymentNumberType'}
								>
									<Radios orientation='vertical'>
										{l.map(paymentNumberTypeOptions, (c) => (
											<Radio
												key={c}
												value={addCustomerFormData.form.paymentNumberType === c}
												onSelected={() =>
													sosProviderPortalCustomers.updateAddCustomerForm(
														'paymentNumberType',
														c,
													)
												}
											>
												{t(
													c,
													'page.providerPortal.customers.addCustomer.paymentNumberType',
												)}
											</Radio>
										))}
									</Radios>
								</FormStackedItem>
								<div className={classes.paymentNumberForm}>
									<FormStackedTextInput
										formData={addCustomerFormData}
										field={'paymentNumber'}
										keepSpaceForRequired={true}
									/>
								</div>
							</FormStacked>
						</Center>

						<div>
							<OkCancelButtons
								cancel={t('page.providerPortal.customers.addCustomer.cancel')}
								ok={t('page.providerPortal.customers.addCustomer.submit')}
								onOk={sosProviderPortalCustomers.submitAddCustomer}
								onCancel={sosProviderPortalCustomers.toggleShowAddCustomer}
								errors={state.addErrorMessage}
								isValid={true}
							/>
						</div>
					</PaperCard>
				</div>
			)}
		</>
	)
}
