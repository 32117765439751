import { extractFileFormat } from 'ui/lib/files/exatractFileFormat'

export function isSupported(
	fileName: string,
	supportedTypes?: string[],
): boolean {
	if (!fileName) {
		return false
	}
	if (!supportedTypes) {
		return true
	}
	return supportedTypes.includes(extractFileFormat(fileName))
}
