import React, { ReactElement, useState } from 'react'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import { apiTypes } from 'ui/api'
import { t } from 'ui/components/i18n/i18n'
import { Spacer } from 'ui/components/layout/spacer/Spacer'
import { toDollars } from 'ui/lib/numbers/toNumber'
import { sosCustomerManagement } from '../..'

const tPrefix = 'page.customerManagement.defaultMarkup'

export const BrokerDefaultMarkup = (props: {
	brokerDefaultMarkupForm: apiTypes.MarkupLogic
}): ReactElement => {
	const [markupLogic, setMarkupLogic] = useState<apiTypes.MarkupLogic>({
		markupType: props.brokerDefaultMarkupForm.markupType,
		markupFlat: toDollars(props.brokerDefaultMarkupForm.markupFlat),
		minMarkupFlat: toDollars(props.brokerDefaultMarkupForm.minMarkupFlat),
		maxMarkupFlat: toDollars(props.brokerDefaultMarkupForm.maxMarkupFlat),
		markupPercent: props.brokerDefaultMarkupForm.markupPercent,
		minMarkupPercent: props.brokerDefaultMarkupForm.minMarkupPercent,
		maxMarkupPercent: props.brokerDefaultMarkupForm.maxMarkupPercent,
	})

	const onChange = async (field, value): Promise<void> => {
		sosCustomerManagement.markupFormOnFormUpdate(field, value)

		setMarkupLogic({
			...markupLogic,
			[field]: value,
		})
	}

	return (
		<div
			className='bootstrap-wrapper'
			data-testid='accounting-broker-default-markup'
		>
			<Row data-testid='broker-default-markup'>
				<Col
					className={'align-items-stretch'}
					data-testid='broker-default-markup-percent'
				>
					<Form>
						<Form.Group>
							<Form.Check
								type='radio'
								label={t('percentMarkup', tPrefix)}
								onChange={(): Promise<void> =>
									onChange('markupType', 'percent')
								}
								checked={markupLogic.markupType === 'percent'}
								data-testid='form-percent'
							/>
						</Form.Group>
						<Form.Group>
							{['markupPercent', 'minMarkupFlat'].map((c) => (
								<div key={c}>
									<Form.Label>{t(c, tPrefix)}</Form.Label>
									<Form.Control
										type='text'
										onChange={(e): Promise<void> => onChange(c, e.target.value)}
										id={c}
										value={markupLogic[c] || ''}
										disabled={markupLogic.markupType !== 'percent'}
										data-testid={`form-${c}`}
									/>
									<Spacer height='5px' />
								</div>
							))}
						</Form.Group>
					</Form>
				</Col>

				<Col data-testid='broker-default-markup-flat'>
					<Form>
						<Form.Group controlId='flatSelector'>
							<Form.Check
								type='radio'
								label={t('flatMarkup', tPrefix)}
								onChange={(): Promise<void> => onChange('markupType', 'flat')}
								checked={markupLogic.markupType === 'flat'}
								data-testid='form-flat'
							/>
						</Form.Group>
						<Form.Group>
							{['markupFlat', 'minMarkupPercent', 'maxMarkupPercent'].map(
								(c) => (
									<div key={c}>
										<Form.Label>{t(c, tPrefix)}</Form.Label>
										<Form.Control
											type='text'
											onChange={(e): Promise<void> =>
												onChange(c, e.target.value)
											}
											id={c}
											value={markupLogic[c] || ''}
											disabled={markupLogic.markupType !== 'flat'}
											data-testid={`form-${c}`}
										/>
										<Spacer height='5px' />
									</div>
								),
							)}
						</Form.Group>
					</Form>
				</Col>
			</Row>
		</div>
	)
}
