import React from 'react'
import classes from './FormStacked.module.scss'
import { IFormData } from './IFormData'

export function FormStacked<T extends IFormData<T>>(props: {
	children: React.ReactNode
	fullWidth?: boolean
}): React.ReactElement {
	return (
		<div className={classes.formStackedContainer}>
			<form
				onSubmit={(ev) => {
					ev.preventDefault()
					return false
				}}
				className={props.fullWidth ? classes.fullWidth : null}
			>
				{props.children}
			</form>
		</div>
	)
}
