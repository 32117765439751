import React from 'react'
import { apiTypes } from 'ui/api'
import { Button } from 'ui/components/common/button'
import {
	FormStacked,
	FormStackedReadOnly,
	FormStackedTextInput,
	IFormData,
} from 'ui/components/form'
import { validateFormData } from 'ui/components/form/validateFormData'
import { t } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight'
import { fireAndForget } from 'ui/lib/async'
import { l } from 'ui/lib/lodashImports'
import { sosDockSchedulerAppointmentDetails } from '../state'
import { IStateDockSchedulerAppointmentDetails } from '../state/sosDockSchedulerAppointmentDetails'
import { DockSchedulerAppointmentDetailsCard } from './DockSchedulerAppointmentDetailsCard'
import {
	IAppointmentDetailsLoadStartForm,
	loadStartMetaData,
} from './DockSchedulerAppointmentDetailsForms'
import { FC } from 'app/FunctionalComponent'

const tPrefixLoading = 'page.dockScheduler.appointmentDetails.loadStart'
const tPrefixUnloading = 'page.dockScheduler.appointmentDetails.unloadStart'

export const DockSchedulerAppointmentDetailsLoadStart: FC = (props: {
	state: IStateDockSchedulerAppointmentDetails
	isActive: boolean
	stopType: apiTypes.AppointmentResponse['stopType']
}) => {
	const { state, isActive, stopType } = props

	const tPrefix = stopType === 'delivery' ? tPrefixUnloading : tPrefixLoading

	const loadStartFormData: IFormData<IAppointmentDetailsLoadStartForm> = {
		form: state.loadStartForm,
		metadata: l.cloneDeep(loadStartMetaData),
		tPrefix,
		onUpdateForm: (field, newVal) => {
			const changes = {}
			changes[field] = newVal
			sosDockSchedulerAppointmentDetails.updateLoadStart(changes)
		},
	}
	return (
		<DockSchedulerAppointmentDetailsCard
			cardType={'loadStart'}
			isActiveCard={isActive}
			delivery={stopType === 'delivery'}
		>
			<FormStacked>
				{state.appointment.status !== 'arrived' && (
					<div>
						<FormStackedReadOnly
							formData={loadStartFormData}
							field={'loadStartDateTime'}
						/>
						<FormStackedReadOnly
							formData={loadStartFormData}
							field={'loadStartRecordedBy'}
						/>
					</div>
				)}
				<FormStackedTextInput
					formData={loadStartFormData}
					field={'loadedBy'}
					readOnly={!isActive}
				/>
				<FormStackedTextInput
					formData={loadStartFormData}
					field={'loadStartComments'}
					multiline={true}
					notResizable={true}
					readOnly={!isActive}
				/>
				{isActive && (
					<AlignRight>
						<Button
							color={'blue'}
							isDisabled={!validateFormData(loadStartFormData)}
							isSpinning={state.submitting}
							onClick={() =>
								fireAndForget(
									sosDockSchedulerAppointmentDetails.submitLoadStart,
									'submitting dock scheduler load start from button click',
								)
							}
						>
							{t(
								state.appointment.status === 'arrived' ? 'submit' : 'save',
								tPrefix,
							)}
						</Button>
					</AlignRight>
				)}
			</FormStacked>
		</DockSchedulerAppointmentDetailsCard>
	)
}
