import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { Checkbox } from 'ui/components/common/checkbox'
import { l } from 'ui/lib/lodashImports'
import { sos2 } from 'ui/lib/state/sos2'
import { sosFeatureToggles } from 'ui/state'

export const DevFeatureToggles: FC = (props: {}) => {
	const state = sos2.useSubscription(sosFeatureToggles.sos)

	return (
		<div>
			Feature Toggles:
			{l.map(state.featureToggles, (c, cKey) => (
				<Checkbox
					key={cKey}
					value={c.enabled}
					onChange={(newVal: boolean) =>
						sosFeatureToggles.setDevFeatureToggleOverride(cKey, newVal)
					}
				>
					{cKey}
				</Checkbox>
			))}
		</div>
	)
}
