import { FC } from 'app/FunctionalComponent'
import React, { useEffect } from 'react'
import {
	Breadcrumb,
	IBreadcrumbEntry,
} from 'ui/components/common/breadcrumb/Breadcrumb'
import { Debug } from 'ui/components/dev/Debug'
import { fireAndForget } from 'ui/lib/async'
import { Layout } from 'ui/pages/layout/Layout'
import { sosPackageCatalogEntry } from 'ui/state'
import { PackageCatalogEntryDataTable } from './PackageCatalogEntryDataTable'

export const PackageCatalogPage: FC = (props: { bookId: string }) => {
	const state = sosPackageCatalogEntry.useSubscribe()

	useEffect(() => {
		sosPackageCatalogEntry.navigateToPackageCatalog({
			bookId: props.bookId,
		})
	}, [props.bookId])

	useEffect(() => {
		fireAndForget(async () => {
			await sosPackageCatalogEntry.fetchPackageCatalogEntries()
			await sosPackageCatalogEntry.fetchBook()
		}, 'fetching packageCodeBookCatalogs and entries')
	}, [state.bookId])

	const bread: IBreadcrumbEntry[] = [
		{
			t: 'shared.packageCatalog.managePackageCatalogs',
			url: '/manage-package-catalogs',
		},
		{
			label: state.requestPackageCatalog.data
				? state.requestPackageCatalog.data.name
				: '',
			url: '',
		},
	]

	return (
		<Layout>
			<Breadcrumb entries={bread} />
			<PackageCatalogEntryDataTable
				fetchPackageCatalogEntries={state.requestPackageCatalogEntries}
				requestUpdatePackageCatalogEntry={
					state.requestUpdatePackageCatalogEntry
				}
				data={state.dataPackageCatalogEntries}
				dataTableState={state.dataTablePackageCatalogEntries}
				form={state.packageCatalogPageForm}
			/>
			<Debug
				hide={true}
				label='All pages from this book'
				data={state.requestPackageCatalogEntries}
			/>
		</Layout>
	)
}
