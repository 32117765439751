import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FC } from 'app/FunctionalComponent'
import React, { useState } from 'react'
import { theme } from 'ui/theme'
import { Colors } from 'ui/theme/theme'
import { Icon } from '../icon'
import { solidIcons } from '../icon/solidIcons'
import { Popup, PopupBoxPosition } from '../popup'

export const HelpHover: FC = (props: { children: React.ReactNode }) => {
	return (
		<IconHover
			icon={solidIcons.faQuestionCircle}
			color='blue'
			testId='help-hover'
			popupPosition='topRight'
		>
			{props.children}
		</IconHover>
	)
}

export const IconHover: FC = (props: {
	icon: IconDefinition
	color?: Colors
	testId?: string
	popupPosition?: keyof PopupBoxPosition
	children: React.ReactNode
}) => {
	const [isOpen, setIsOpen] = useState(false)

	const color = props.color || 'blue'
	const popupPosition = props.popupPosition || 'topRight'

	return (
		<Popup isOpen={isOpen} content={props.children} position={popupPosition}>
			<div
				onClick={() => setIsOpen(!isOpen)}
				onMouseEnter={() => setIsOpen(true)}
				onMouseLeave={() => setIsOpen(false)}
				data-testid={props.testId}
			>
				<div className={theme.getTextColor(color)}>
					<Icon icon={props.icon} />
				</div>
			</div>
		</Popup>
	)
}
