import { DateTime } from 'luxon'
import { elasticSearchBuilder } from 'ui/lib/elasticSearch'
import { l } from 'ui/lib/lodashImports'

export type Flows = 'all' | 'inbound' | 'outbound'
export type Modes = 'all' | 'ltl' | 'truckload' | 'parcel'

export const buildQuery = (
	startDate: DateTime,
	endDate: DateTime,
	locationIds?: string[],
	providerId?: string,
	mode?: Modes,
	flow?: Flows,
): { query: string; nestedFieldQuery: string } => {
	const qb = elasticSearchBuilder()
	const payloadQb = elasticSearchBuilder()

	qb.and('shipmentStatus', 'booked')

	const compactLocationIds = l.compact(locationIds)
	if (
		!l.isEmpty(compactLocationIds) &&
		!l.includes(compactLocationIds, 'all')
	) {
		const orClauses = []
		compactLocationIds.forEach((id) =>
			orClauses.push(
				['payloads.originStop.metaData.locationId', id],
				['payloads.destinationStop.metaData.locationId', id],
			),
		)
		payloadQb.andOr(orClauses)
	}

	if (providerId) {
		qb.and('bookedRate.providerId', providerId)
	}

	if (flow && flow !== 'all') {
		if (flow === 'inbound') {
			payloadQb.and('_exists_', 'payloads.destinationStop.metaData.locationId')
			payloadQb.andOr([
				[
					'payloads.destinationStop.metaData.desiredDateTimeInfo.initialDate',
					`[${startDate.toISODate()} TO ${endDate.toISODate()}]`,
				],
				[
					'payloads.destinationStop.metaData.actualDateTimeInfo.initialDate',
					`[${startDate.toISODate()} TO ${endDate.toISODate()}]`,
				],
			])
		} else if (flow === 'outbound') {
			payloadQb.and('_exists_', 'payloads.originStop.metaData.locationId')
			payloadQb.andOr([
				[
					'payloads.originStop.metaData.desiredDateTimeInfo.initialDate',
					`[${startDate.toISODate()} TO ${endDate.toISODate()}]`,
				],
				[
					'payloads.originStop.metaData.actualDateTimeInfo.initialDate',
					`[${startDate.toISODate()} TO ${endDate.toISODate()}]`,
				],
			])
		}
	} else {
		const destinationClause =
			'(_exists_:payloads.destinationStop.metaData.locationId AND ' +
			`(payloads.destinationStop.metaData.desiredDateTimeInfo.initialDate:[${startDate.toISODate()} TO ${endDate.toISODate()}] OR ` +
			`payloads.destinationStop.metaData.actualDateTimeInfo.initialDate:[${startDate.toISODate()} TO ${endDate.toISODate()}]))`
		const originClause =
			'(_exists_:payloads.originStop.metaData.locationId AND ' +
			`(payloads.originStop.metaData.desiredDateTimeInfo.initialDate:[${startDate.toISODate()} TO ${endDate.toISODate()}] OR ` +
			`payloads.originStop.metaData.actualDateTimeInfo.initialDate:[${startDate.toISODate()} TO ${endDate.toISODate()}]))`
		payloadQb.andRaw(`${originClause} OR ${destinationClause}`)
	}

	if (mode && mode !== 'all') {
		qb.and('mode', mode)
	}
	return { query: qb.toQuery(), nestedFieldQuery: payloadQb.toQuery() }
}
