import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { l } from 'ui/lib/lodashImports'
import { theme } from 'ui/theme'
import * as commonClasses from 'ui/theme/common.module.scss'
import * as classes from './PaperCard.module.scss'

export const PaperCard: FC = (props: {
	children: React.ReactNode
	inset?: boolean
	onClick?: () => void
	selected?: boolean
	shadow?: boolean
	className?: string
	testId?: string
}) => {
	let className = classes.paperCard
	className = theme.addClassIf(props.inset, classes.inset, className)
	className = theme.addClassIf(
		props.onClick != null,
		commonClasses.clickable,
		className,
	)
	className = theme.addClassIf(props.shadow, classes.displayShadow, className)
	className = theme.addClassIf(
		!l.isNil(props.className),
		props.className,
		className,
	)
	className = theme.addClassIf(
		props.onClick && !props.selected,
		classes.highlightOnHover,
		className,
	)
	className = theme.addClassIf(
		props.selected,
		classes.paperCardSelected,
		className,
	)

	return (
		<div
			data-testid={props.testId}
			className={className}
			onClick={props.onClick}
		>
			{props.children}
		</div>
	)
}
