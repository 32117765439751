import { FC } from 'app/FunctionalComponent'
import React, { useState } from 'react'
import { Button } from 'ui/components/common/button'
import { InfiniteLoader } from 'ui/components/common/loader'
import { ElasticSearchPager } from 'ui/components/common/pager/Pager'
import { Debug } from 'ui/components/dev/Debug'
import { useOnce } from 'ui/components/hooks'
import { t } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight'
import { DataTable } from 'ui/components/table/DataTable'
import { fireAndForget } from 'ui/lib/async'
import { sos2 } from 'ui/lib/state/sos2'
import { Layout } from 'ui/pages/layout/Layout'
import { sosProviderManagement } from 'ui/pages/provider-management'
import { AddProviderModal } from '../AddProviderModal/AddProviderModal'
import { providerListHeaders } from './ProviderListHeaders'
import * as classes from './ProviderListPage.module.scss'

export const tPrefixProvidersList = 'page.providers.providersList'

export const ProviderListPage: FC = (props: {}) => {
	const state = sos2.useSubscription(sosProviderManagement.getSos())
	const { providers, pager, filterChips } = state
	const [addProviderModalOpen, setAddProviderModalOpen] = useState<boolean>(
		false,
	)

	useOnce(() => {
		fireAndForget(
			() => sosProviderManagement.fetchProviders(),
			'fetch providers for provider management',
		)
	})

	return (
		<Layout>
			<div className='bootstrap-wrapper'>
				<h4>{t('providers', tPrefixProvidersList)}</h4>
				<AlignRight>
					<Button
						bootstrapStyles={true}
						color='green'
						onClick={() => setAddProviderModalOpen(true)}
						testId={'add-provider-button'}
					>
						{t('addProvider', tPrefixProvidersList)}
					</Button>
				</AlignRight>
				<InfiniteLoader
					isLoading={state.isFetchingProviders}
					testId={'provider-table-infinite-loader'}
				/>
				<DataTable
					data={providers}
					headers={providerListHeaders}
					state={{ filterChips }}
					tPrefix={tPrefixProvidersList}
					onChangeFilter={sosProviderManagement.setColumnFilterValue}
					onClearFilter={sosProviderManagement.clearColumnFilter}
					onToggleHeaderActionBox={() => {}}
					rowClassName={classes.providerRow}
					testId={'provider-table'}
				/>
				<ElasticSearchPager
					pager={pager}
					onClickPage={async (pageNum) => {
						await sosProviderManagement.updateCurrentPage(pageNum)
					}}
				/>
				<AddProviderModal
					isOpen={addProviderModalOpen}
					onClose={() => setAddProviderModalOpen(false)}
				/>
				<Debug label='Providers' data={providers} />
			</div>
		</Layout>
	)
}
