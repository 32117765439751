import { FC } from 'app/FunctionalComponent'
import React from 'react'
import styles from './ThreeColumn.module.scss'

const ThreeColumn: FC = (props: { children: React.ReactNode }) => {
	return <div className={styles.threeColumn}>{props.children}</div>
}

const ThreeColumnItem: FC = (props: { children: React.ReactNode }) => {
	return <div className={styles.threeColumnItem}>{props.children}</div>
}

export { ThreeColumn, ThreeColumnItem }
