import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { IRequestState } from 'ui/api/requestState'
import { t } from 'ui/components/i18n/i18n'
import { DataTable } from 'ui/components/table/DataTable'
import { DataTableUpdate } from 'ui/components/table/DataTableUpdate'
import { IDataTableHeader } from 'ui/components/table/IDataTableHeader'
import { IDataTableState } from 'ui/components/table/IDataTableState'
import { IAccountingCodeCatalogEntryForm } from 'ui/forms/formAccountingCodeCatalogEntry'
import { IAccountingCodeCatalogEntry } from 'ui/lib/books/IAccountingCodeCatalogEntry'
import { sosAccountingCodeCatalogEntry } from 'ui/state'
import { UpdateAccountingCodeCatalogEntryForm } from '.'

export const AccountingCodeCatalogEntryDataTable: FC = (props: {
	fetchAccountingCodeCatalogEntries: IRequestState<
		IAccountingCodeCatalogEntry[]
	>
	requestUpdateAccountingCodeCatalogEntry: IRequestState<
		IAccountingCodeCatalogEntry
	>
	dataTableState: IDataTableState<IAccountingCodeCatalogEntry>
	data: IAccountingCodeCatalogEntry[]
	form: IAccountingCodeCatalogEntryForm
}) => {
	const {
		fetchAccountingCodeCatalogEntries,
		requestUpdateAccountingCodeCatalogEntry,
	} = props
	const { dataTableState } = props
	const headers: IDataTableHeader<IAccountingCodeCatalogEntry>[] = [
		{
			field: 'accountingCode',
		},
		{
			field: 'accountingCodeDescription',
		},
	]
	return (
		<div>
			<DataTable
				testId='data-table-accounting-code-catalog'
				headers={headers}
				tPrefix={'shared.accountingCodeCatalog'}
				isLoading={
					fetchAccountingCodeCatalogEntries.isFetching ||
					requestUpdateAccountingCodeCatalogEntry.isFetching
				}
				data={props.data}
				state={dataTableState}
				onAddRow={() => {
					sosAccountingCodeCatalogEntry.accountingCodeCatalogTableSetUpdateMode(
						'add',
					)
				}}
				addLabel={t(
					'shared.accountingCodeCatalog.addAccountingCodeCatalogPage',
				)}
				onEditRow={(row) => {
					sosAccountingCodeCatalogEntry.accountingCodeCatalogTableSetUpdateMode(
						'edit',
						row,
					)
				}}
				onToggleHeader={(c) => {
					sosAccountingCodeCatalogEntry.toggleHeader(c.field)
				}}
				onClickHeader={(header) => {
					sosAccountingCodeCatalogEntry.sort(header.field)
				}}
				onRefresh={async () => {
					await sosAccountingCodeCatalogEntry.fetchAccountingCodeCatalogEntries()
				}}
				rendererAdd={() => (
					<DataTableUpdate error={dataTableState.error}>
						<UpdateAccountingCodeCatalogEntryForm
							mode='add'
							form={props.form}
						/>
					</DataTableUpdate>
				)}
				rendererEdit={(row) => (
					<DataTableUpdate
						error={dataTableState.error}
						onDeleteRow={() =>
							sosAccountingCodeCatalogEntry.updateAccountingCodeCatalogEntry(
								'delete',
							)
						}
					>
						<UpdateAccountingCodeCatalogEntryForm
							mode='edit'
							form={props.form}
						/>
					</DataTableUpdate>
				)}
			/>
		</div>
	)
}
