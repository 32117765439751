import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { regularIcons, solidIcons } from 'ui/components/common/icon'
import { Icon } from 'ui/components/common/icon/Icon'
import { t } from 'ui/components/i18n/i18n'
import { l } from 'ui/lib/lodashImports'
import { IStateComponentExamples } from 'ui/state/sosComponentExamples'
import { Section } from './Section'

export const SectionIcons: FC = (props: {
	stateComponentExamples: IStateComponentExamples
}) => {
	return (
		<Section
			title={t('page.componentExamples.icons')}
			name='icons'
			stateComponentExamples={props.stateComponentExamples}
		>
			<div>
				<Icon icon={solidIcons.faCoffee} /> Icons go here <Icon />
			</div>
			<div>
				<table>
					<thead>
						<tr>
							<th>Name</th>
							<th>Type</th>
							<th>Icon</th>
						</tr>
					</thead>
					<tbody>
						{l.map(solidIcons, (c, cIdx) => (
							<tr key={'solid' + cIdx}>
								<td>{cIdx}</td>
								<td>Solid</td>
								<td>
									<Icon icon={c} />
								</td>
							</tr>
						))}
						{l.map(regularIcons, (c, cIdx) => (
							<tr key={'reg' + cIdx}>
								<td>{cIdx}</td>
								<td>Regular</td>
								<td>
									<Icon icon={c} />
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</Section>
	)
}
