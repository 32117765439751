import React, { useState } from 'react'
import { FC } from 'app/FunctionalComponent'
import { sosToast } from 'common/components/toast'
import { DateTime } from 'luxon'
import { Card, Col, Row } from 'react-bootstrap'
import { apiBroker, apiTypes } from 'ui/api'
import { IRequestState } from 'ui/api/requestState'
import { ndash } from 'ui/components/common'
import { Button } from 'ui/components/common/button'
import { solidIcons } from 'ui/components/common/icon'
import { IconHover } from 'ui/components/common/icon-hover'
import { LinkButton } from 'ui/components/common/link/LinkButton'
import { ColOffset } from 'ui/components/common/separator/ColOffset'
import { tClientStatusOnBrokerOffer } from 'ui/components/i18n/commonTranslations'
import { t, tCurrencyFromCents, tString } from 'ui/components/i18n/i18n'
import { addBusinessDays } from 'ui/lib/dates/businessDays'
import { theme } from 'ui/theme'
import { addClass } from 'ui/theme/theme'
import { sosShipmentProfileBroker } from 'ui/pages/shipment-profile/broker'
import { BrokerApiCalls } from 'ui/pages/shipment-profile/broker/sosShipmentProfileBroker'
import { BrokerOfferModal } from 'ui/pages/shipment-profile/broker/components/offer-modal'
import * as classes from './BrokerSellSection.module.scss'

const tPrefix = 'page.shipmentProfile.brokerSell'

const customerHasTMSConnection = (
	clientConfig: apiTypes.ClientConfigResponse,
): boolean => {
	return clientConfig?.tmsService === 'SwanLeap' && clientConfig.tmsCredentials
		? true
		: false
}

export const BrokerSellSection: FC = (props: {
	shipment: apiTypes.BrokerShipmentResponse
	clientConfig: apiTypes.ClientConfigResponse
	runningApiCalls: BrokerApiCalls[]
	shipmentIsRating: boolean
}) => {
	const { shipment, clientConfig, runningApiCalls, shipmentIsRating } = props
	const [offerModalShowing, updateOfferModalShowing] = useState(false)
	const offer = shipment.offers[0]
	const sellPrice = offer?.flatOffer?.grossRate
	let showCreateNewOffer = false,
		showEditOffer = false,
		showEditMarkup = false,
		showSendOffer = false,
		showRetractOffer = false,
		showAcceptOfferForCustomer = false,
		showClearOffer = false

	if (shipment.brokerShipmentStatus !== 'canceled') {
		if (!offer?.clientStatus || offer.clientStatus === 'not-sent') {
			if (sellPrice) {
				if (customerHasTMSConnection(clientConfig)) {
					showSendOffer = true
				}
				showAcceptOfferForCustomer = true
				showClearOffer = true
				if (offer.brokerRateId) {
					showEditMarkup = true
				} else {
					showEditOffer = true
				}
			} else {
				showCreateNewOffer = true
			}
		} else if (offer.clientStatus === 'retracted') {
			showClearOffer = true
		} else if (offer.clientStatus === 'sent') {
			showAcceptOfferForCustomer = true
			showEditOffer = true
			showRetractOffer = true
		} else if (
			offer.clientStatus === 'booked' &&
			shipment.brokerShipmentStatus !== 'booked'
		) {
			showRetractOffer = true
		}
	}

	const makeBrokerApiCall = async <T extends any>(
		apiCallName: BrokerApiCalls,
		apiCall: Promise<IRequestState<T>>,
	): Promise<void> => {
		sosShipmentProfileBroker.setRunningApiCall(apiCallName)
		const result = await apiCall
		if (result.error) {
			sosToast.sendApiErrorResponseToast(
				result,
				tString(`${apiCallName}Error`, tPrefix),
			)
		}
		await sosShipmentProfileBroker.fetchShipment(shipment.id)
		sosShipmentProfileBroker.setRunningApiCall(apiCallName, false)
	}

	return (
		<>
			<BrokerOfferModal
				shipment={shipment}
				defaultMarkupLogic={clientConfig.defaultMarkupLogic}
				isOpen={offerModalShowing}
				onClose={() => updateOfferModalShowing(false)}
			/>
			<Card
				className={`${theme.getBorderColor('lightGray')} ${
					classes.brokerSellSectionCard
				}`}
				data-testid='sell-price-block'
			>
				<Row>
					<Col md={3}>
						<div className={classes.label}>{t('sellPrice', tPrefix)}</div>
						<div>{sellPrice ? tCurrencyFromCents(sellPrice) : ndash}</div>
					</Col>
					{offer?.message && (
						<>
							<ColOffset offset={8} size='md' />
							<Col md={1}>
								<IconHover icon={solidIcons.faFlag}>{offer.message}</IconHover>
							</Col>
						</>
					)}
				</Row>
				<Row>
					<Col md={12}>
						<Card
							className={`${theme.getBorderColor(
								'lightGray',
							)} ${theme.getBackgroundWithTextColor('lightGray')} ${
								classes.innerCardDetails
							}`}
						>
							<Row>
								<Col md={3}>
									<div className={classes.label}>{t('status', tPrefix)}</div>
									<div>
										{tClientStatusOnBrokerOffer(
											offer?.clientStatus || 'not-sent',
										)}
									</div>
								</Col>
								<Col md={3}>
									<div className={classes.label}>
										{t('quoteNumber', tPrefix)}
									</div>
									<div data-testid='quote-number-block'>
										{offer?.quoteNumber || ndash}
									</div>
								</Col>
								<div className={addClass('col-3', classes.noPadding)}>
									<div className={classes.label}>
										{t('estimatedTransit', tPrefix)}
									</div>
									<div data-testid='estimated-transit-block'>
										{offer?.transit
											? `${offer.transit} ${tString('days', 'common')}`
											: ndash}
									</div>
								</div>
								<Col md={3}>
									<div className={classes.label}>{t('eta', tPrefix)}</div>
									<div>
										{offer?.transit
											? addBusinessDays(
													shipment.pickupDate || DateTime.local().toISO(),
													offer.transit - 1, //minus 1 to match that the delivery date calculated on the api counts today as day 1
											  )
											: ndash}
									</div>
								</Col>
							</Row>
						</Card>
					</Col>
				</Row>
				<Row>
					<Col md={12}>
						<div
							className={addClass(
								'd-flex justify-content-end',
								classes.brokerSellSectionInteractionButtons,
							)}
							data-testid='sell-section-buttons'
						>
							{/* POSITION ONE (LEFTMOST) */}
							{showClearOffer && (
								<LinkButton
									className={classes.linkButtonPadding}
									onClick={async () =>
										await makeBrokerApiCall(
											'clearingBrokerOffer',
											apiBroker.deleteOffer(
												() => {},
												shipment.id,
												shipment.offers[0].id,
											),
										)
									}
									isDisabled={runningApiCalls.includes('sendingBrokerOffer')}
									isSpinning={runningApiCalls.includes('clearingBrokerOffer')}
									testId='clear-offer-button'
								>
									{t('clearOffer', tPrefix)}
								</LinkButton>
							)}
							{showRetractOffer && (
								<LinkButton
									className={classes.linkButtonPadding}
									onClick={async () =>
										await makeBrokerApiCall(
											'retractingBrokerOffer',
											apiBroker.retractBrokerOffer(
												() => {},
												shipment.id,
												shipment.offers[0].id,
											),
										)
									}
									isDisabled={runningApiCalls.includes('sendingBrokerOffer')}
									isSpinning={runningApiCalls.includes('retractingBrokerOffer')}
									testId='retract-offer-button'
								>
									{t('retractOffer', tPrefix)}
								</LinkButton>
							)}
							{showCreateNewOffer && (
								<Button
									color='green'
									onClick={() => updateOfferModalShowing(true)}
									testId='create-new-offer-button'
									isDisabled={shipmentIsRating}
								>
									{t('createNewOffer', tPrefix)}
								</Button>
							)}

							{/* POSITION TWO */}
							{showEditOffer && (
								<LinkButton
									className={classes.linkButtonPadding}
									onClick={() => updateOfferModalShowing(true)}
									testId='edit-offer-button'
									isDisabled={runningApiCalls.includes('sendingBrokerOffer')}
								>
									{t('editOffer', tPrefix)}
								</LinkButton>
							)}

							{/* POSITION THREE */}
							{showAcceptOfferForCustomer && (
								<Button
									color='blue'
									onClick={async () =>
										await makeBrokerApiCall(
											'acceptingBrokerOfferForCustomer',
											apiBroker.acceptBrokerOfferForCustomer(
												() => {},
												shipment.id,
												shipment.offers[0].id,
											),
										)
									}
									isDisabled={runningApiCalls.includes('sendingBrokerOffer')}
									isSpinning={runningApiCalls.includes(
										'acceptingBrokerOfferForCustomer',
									)}
									testId='accept-for-customer-button'
								>
									{t('acceptForCustomer', tPrefix)}
								</Button>
							)}

							{/* POSITION FOUR */}
							{showSendOffer && (
								<Button
									color='green'
									onClick={async () =>
										await makeBrokerApiCall(
											'sendingBrokerOffer',
											apiBroker.sendBrokerOfferToClient(
												() => {},
												shipment.id,
												shipment.offers[0].id,
											),
										)
									}
									isDisabled={runningApiCalls.includes('updatingBrokerOffer')}
									isSpinning={runningApiCalls.includes('sendingBrokerOffer')}
									testId='send-offer-to-client-button'
								>
									{t('sendOffer', tPrefix)}
								</Button>
							)}
							{showEditMarkup && (
								<Button
									color='gray'
									onClick={() => updateOfferModalShowing(true)}
									testId='edit-markup-button'
									isDisabled={runningApiCalls.includes('sendingBrokerOffer')}
								>
									{t('editMarkup', tPrefix)}
								</Button>
							)}
						</div>
					</Col>
				</Row>
			</Card>
		</>
	)
}
