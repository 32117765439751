import * as loadJs from 'load-js'
import { l } from 'ui/lib/lodashImports'
import { logError, logSuccess } from 'ui/lib/log/log'
import { createSos } from './secretaryOfState'

export interface IScriptLoaderInfo {
	href: string
	state: 'loading' | 'loaded' | 'error'
}

export interface IStateScriptLoader {
	scripts: IScriptLoaderInfo[]
}

const initialState: IStateScriptLoader = {
	scripts: [],
}

const { stateManager, useSubscribe } = createSos(
	'script-loader',
	'1.0.0',
	initialState,
	{
		useLocalStorage: false,
	},
)
export { useSubscribe }

export function findScript(
	scripts: IScriptLoaderInfo[],
	href,
): IScriptLoaderInfo {
	return l.find(scripts, (c) => c.href === href)
}

export async function loadScript(href: string): Promise<any> {
	const state = stateManager.getState()
	if (findScript(state.scripts, href)) {
		return null // Already loading or loaded
	}

	stateManager.produce((ds) => {
		ds.scripts.push({
			href,
			state: 'loading',
		})
	})

	return new Promise((resolve, reject) => {
		loadJs([href])
			.then(() => {
				stateManager.produce((ds) => {
					findScript(ds.scripts, href).state = 'loaded'
				})
				logSuccess('sosScriptLoader', 'Loaded', href)
				resolve()
			})
			.catch((err) => {
				stateManager.produce((ds) => {
					findScript(ds.scripts, href).state = 'error'
				})
				logError('sosScriptLoader', 'couldnt load', href)
				reject(err)
			})
	})
}
