import { apiTypes } from 'ui/api'
import { toInteger } from 'ui/lib/numbers/toNumber'

export const applyDefaultMarkupLogic = (
	buyPrice: number,
	markupLogic: apiTypes.MarkupLogic,
): number => {
	if (!markupLogic) {
		return buyPrice
	}
	let markup: number
	if (markupLogic.markupType === 'flat') {
		markup = Number(markupLogic.markupFlat)
		if (markupLogic.maxMarkupPercent) {
			const maxMarkup = (markupLogic.maxMarkupPercent * buyPrice) / 100
			if (markup > maxMarkup) {
				markup = Number(maxMarkup)
			}
		}
		if (markupLogic.minMarkupPercent) {
			const minMarkup = (markupLogic.minMarkupPercent * buyPrice) / 100
			if (markup < minMarkup) {
				markup = Number(minMarkup)
			}
		}
	} else if (markupLogic.markupType === 'percent') {
		markup = (markupLogic.markupPercent * buyPrice) / 100
		if (markupLogic.maxMarkupFlat) {
			const maxMarkup = markupLogic.maxMarkupFlat
			if (markup > maxMarkup) {
				markup = maxMarkup
			}
		}
		if (markupLogic.minMarkupFlat) {
			const minMarkup = markupLogic.minMarkupFlat
			if (markup < minMarkup) {
				markup = minMarkup
			}
		}
	}

	return toInteger(buyPrice + markup)
}
