import { FC } from 'app/FunctionalComponent'
import React, { useEffect } from 'react'
import {
	Breadcrumb,
	IBreadcrumbEntry,
} from 'ui/components/common/breadcrumb/Breadcrumb'
import { fireAndForget } from 'ui/lib/async'
import { Layout } from 'ui/pages/layout/Layout'
import { sosAddressBookEntry } from 'ui/state'
import { AddressBookEntryDataTable } from './AddressBookEntryDataTable'

export const AddressBookPage: FC = (props: { bookId: string }) => {
	const state = sosAddressBookEntry.useSubscribe()

	useEffect(() => {
		sosAddressBookEntry.navigateToAddressBook({
			bookId: props.bookId,
		})
	}, [props.bookId])

	useEffect(() => {
		fireAndForget(async () => {
			await sosAddressBookEntry.fetchAddressBookEntries()
			await sosAddressBookEntry.fetchBook()
		}, 'fetching addressBookCatalogs and entries')
	}, [state.bookId])

	const bread: IBreadcrumbEntry[] = [
		{
			t: 'shared.addressBook.manageAddressBooks',
			url: '/manage-address-books',
		},
		{
			label: state.requestAddressBook.data
				? state.requestAddressBook.data.name
				: '',
			url: '',
		},
	]

	return (
		<Layout>
			<Breadcrumb entries={bread} />
			<AddressBookEntryDataTable
				fetchAddressBookEntries={state.requestAddressBookEntries}
				requestUpdateAddressBookEntry={state.requestUpdateAddressBookPage}
				data={state.dataAddressBookPages}
				dataTableState={state.dataTableAddressBookPages}
				form={state.addressBookPageForm}
			/>
			{/* <Debug
				hide={true}
				label='All pages from the address book'
				data={state.requestAddressBookEntries}
			/> */}
		</Layout>
	)
}
