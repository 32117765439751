import { dataUtils } from 'ui/components/table/'
import {
	createDefaultDataTableState,
	IDataTableState,
} from 'ui/components/table/IDataTableState'
import { formUtils } from 'ui/forms'
import {
	accountingCodeCatalogFormMetadata,
	IAccountingCodeCatalogForm,
} from 'ui/forms/formAccountingCodeCatalog'

import { apiAccountingCodeCatalog } from 'ui/api'
import { UpdateMode } from 'ui/api/apiCommon'
import { IRequestState } from 'ui/api/requestState'
import {
	IAccountingCodeCatalog,
	IAccountingCodeCatalogEntry,
} from 'ui/lib/books'
import { createSos } from './secretaryOfState'

export interface IStateAccountingCodeCatalog {
	dataTableAccountingCodeCatalogs: IDataTableState<IAccountingCodeCatalog>
	dataAccountingCodeCatalogs: IAccountingCodeCatalog[]
	formAccountingCodeCatalog: IAccountingCodeCatalogForm
	requestGetAccountingCodeCatalogs: IRequestState<IAccountingCodeCatalog[]>
	requestGetAccountingCodeCatalogEntries: IRequestState<
		IAccountingCodeCatalogEntry[]
	>
	requestAddEditAccountingCodeCatalog: IRequestState<IAccountingCodeCatalog>
}

const initialState: IStateAccountingCodeCatalog = {
	dataTableAccountingCodeCatalogs: createDefaultDataTableState({
		sortOn: 'name',
		hiddenHeaders: ['id', 'pageType'],
	}),
	dataAccountingCodeCatalogs: [],
	formAccountingCodeCatalog: formUtils.createDefaultFormStringData(
		accountingCodeCatalogFormMetadata,
	),
	requestGetAccountingCodeCatalogs: {},
	requestGetAccountingCodeCatalogEntries: {},
	requestAddEditAccountingCodeCatalog: {},
}
const { stateManager, useSubscribe } = createSos(
	'accounting-code-catalogs',
	'1.0.3',
	initialState,
)
export { useSubscribe }

export async function getCatalogs(): Promise<void> {
	stateManager.produce(() => {})
	await apiAccountingCodeCatalog.getAccountingCodeCatalogs((rs) => {
		stateManager.produce((ds) => {
			ds.requestGetAccountingCodeCatalogs = rs
			if (rs.data) {
				ds.dataAccountingCodeCatalogs = rs.data
				dataUtils.initialLoad(
					ds.dataTableAccountingCodeCatalogs,
					ds.dataAccountingCodeCatalogs,
				)
			}
		})
	})
}

export function catalogsTableSort(sortOn: string): void {
	stateManager.produce((ds) => {
		dataUtils.sort(
			ds.dataTableAccountingCodeCatalogs,
			ds.dataAccountingCodeCatalogs,
			sortOn,
		)
	})
}
export function catalogsTableToggleHeader(field: string): void {
	stateManager.produce((ds) => {
		dataUtils.toggleHeader(ds.dataTableAccountingCodeCatalogs, field)
	})
}
export function catalogsTableSetUpdateMode(
	mode: UpdateMode,
	row: IAccountingCodeCatalog = null,
): void {
	stateManager.produce((ds) => {
		dataUtils.toggleUpdateMode(ds.dataTableAccountingCodeCatalogs, mode, row)
		if (ds.dataTableAccountingCodeCatalogs.isAdding) {
			ds.formAccountingCodeCatalog = formUtils.createDefaultFormStringData(
				accountingCodeCatalogFormMetadata,
			)
		} else if (ds.dataTableAccountingCodeCatalogs.editRow) {
			ds.formAccountingCodeCatalog = formUtils.apiDataToFormStringData(
				accountingCodeCatalogFormMetadata,
				row,
			)
		}
	})
}

export function updateCatalogForm(
	key: keyof IAccountingCodeCatalogForm,
	newVal: string,
): void {
	stateManager.produce((ds) => {
		ds.formAccountingCodeCatalog[key] = newVal
	})
}

export async function updateCatalog(mode: UpdateMode): Promise<void> {
	const state = stateManager.getState()
	const apiData = formUtils.formStringDataToApiData(
		accountingCodeCatalogFormMetadata,
		state.dataTableAccountingCodeCatalogs.editRow,
		state.formAccountingCodeCatalog,
	)
	const result = await apiAccountingCodeCatalog.updateAccountingCodeCatalog(
		mode,
		apiData,
		(rs) => {
			stateManager.produce((ds) => {
				ds.requestAddEditAccountingCodeCatalog = rs
			})
		},
	)

	if (result.data) {
		stateManager.produce((ds) => {
			dataUtils.updateItem(
				ds.dataTableAccountingCodeCatalogs,
				ds.dataAccountingCodeCatalogs,
				mode,
				result.data,
				ds.formAccountingCodeCatalog.id,
			)
		})
		catalogsTableSetUpdateMode('none')
	}
	if (result.error) {
		stateManager.produce((ds) => {
			dataUtils.setError(ds.dataTableAccountingCodeCatalogs, result.error)
		})
	}
}
