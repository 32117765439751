import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { testId } from 'ui/components/i18n/i18n'
import { theme } from 'ui/theme'
import commonStyles from 'ui/theme/common.module.scss'
import { Colors } from 'ui/theme/theme'
import { regularIcons, solidIcons } from '../icon'
import classes from './Radio.module.scss'

export const Radio: FC = (props: {
	value: boolean
	onSelected: () => void
	testId?: string
	children?: React.ReactNode
	color?: Colors
	readOnly?: boolean
}) => {
	const [hovered, setHovered] = React.useState(false)

	const className = `${classes.radio} ${commonStyles.noTextSelect}`
	let iconClassName = classes.icon
	iconClassName = theme.addClassIf(
		props.value === true,
		classes.selected,
		iconClassName,
	)
	iconClassName = theme.addClassIf(hovered, classes.hovered, iconClassName)

	const onChange = (ev: React.ChangeEvent<HTMLInputElement>): void => {
		if (ev.target.checked) {
			props.onSelected()
		}
	}

	return (
		<div className={className}>
			<label
				className={props.readOnly ? '' : commonStyles.clickable}
				onMouseEnter={
					props.readOnly
						? null
						: () => {
								setHovered(true)
						  }
				}
				onMouseLeave={
					props.readOnly
						? null
						: () => {
								setHovered(false)
						  }
				}
			>
				<span className={iconClassName}>
					{props.value && <FontAwesomeIcon icon={solidIcons.faCircle} />}
					{!props.value && !hovered && (
						<FontAwesomeIcon icon={regularIcons.faCircle} color={props.color} />
					)}
					{!props.value && hovered && (
						<FontAwesomeIcon icon={regularIcons.faDotCircle} />
					)}
				</span>
				<input
					type='radio'
					checked={props.value || false}
					onChange={onChange}
					disabled={props.readOnly}
					data-testid={props.testId}
				/>
				{props.children}
				{testId(props.testId)}
			</label>
		</div>
	)
}
