/**
 * @param array
 * @param iteratee Return false to 'break' from the forEach
 */
export async function asyncForEachSerial<T>(
	array: T[],
	iteratee: (value: T, index: number) => Promise<boolean | void>,
): Promise<void> {
	for (let index = 0; index < array.length; index++) {
		const value = array[index]
		const result = await iteratee(value, index)
		if (result === false) {
			return // Early exit
		}
	}
}
