import { FC } from 'app/FunctionalComponent'
import { DateTime } from 'luxon'
import React from 'react'
import { apiTypes } from 'ui/api'
import { ndash } from 'ui/components/common'
import { tStopType } from 'ui/components/i18n/commonTranslations'
import { t, tString } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight/AlignRight'
import { FlexRow } from 'ui/components/layout/flexRow/FlexRow'
import { FlexVerticalSeparator } from 'ui/components/layout/flexRow/FlexVerticalSeparator'
import { _idx } from 'ui/lib'
import * as dockSchedulerClasses from 'ui/pages/dock-scheduler/DockScheduler.module.scss'
import * as commonClasses from 'ui/theme/common.module.scss'
import {
	addClass,
	addClassIf,
	getBorderColor,
	getColor,
	getTextColor,
} from 'ui/theme/theme'
import { sosDockScheduler } from '../state'
import { AppointmentStatusBadge } from './AppointmentStatusBadge'
import { tPrefixStatusBoard } from './DockSchedulerStatusBoard'
import * as classes from './DockSchedulerStatusBoard.module.scss'
import { getHandlingUnitDescription } from './getHandlingUnitDescription'

const appointmentStatusItemClass = addClass(
	getBorderColor('gray'),
	classes.appointmentStatusItem,
)

export const EmptyDock: FC = (props: { dockName: string }) => {
	let emptyAppointmentClassName = addClass(
		getColor('lightGray'),
		appointmentStatusItemClass,
	)
	emptyAppointmentClassName = addClass(
		dockSchedulerClasses.verticalCenter,
		emptyAppointmentClassName,
	)

	const { dockName } = props

	return (
		<div className={emptyAppointmentClassName}>
			<FlexRow verticalAlign='center'>
				<div data-testid='dock-name' className={classes.dockName}>
					{dockName}
				</div>
				<FlexVerticalSeparator />
				<div
					className={addClass(
						classes.appointmentDetailsBox,
						dockSchedulerClasses.verticalCenter,
					)}
					data-testid='dockVacant'
				>
					{t('dockVacant', tPrefixStatusBoard)}
				</div>
			</FlexRow>
		</div>
	)
}

export const AppointmentStatusItem: FC = (props: {
	appointment: apiTypes.AppointmentResponse
	dock: apiTypes.DockResponse
	currentTime: string
}) => {
	const { appointment, dock } = props

	const dockName: string =
		_idx(() => dock.nickName) || tString('Dock', tPrefixStatusBoard)

	if (
		!appointment ||
		appointment.status === 'not-arrived' ||
		appointment.status === 'departed'
	) {
		return <EmptyDock dockName={dockName} />
	}

	// TODO react doesn't know to rerender the time because it sucks at state management
	const currentTime = DateTime.fromISO(props.currentTime)
	const timeFormat = 'h:mma'

	let timeStatus: 'onTime' | 'overTime' = 'onTime'
	let detention = false

	const appointmentCheckinTime = DateTime.fromISO(appointment.arrivalTime)
	const appointmentStartTime = DateTime.fromISO(appointment.startTime)
	const appointmentEndTime = DateTime.fromISO(appointment.startTime).plus({
		minutes: appointment.scheduledDuration,
	})

	const arrivedLate: boolean =
		appointmentStartTime.diff(appointmentCheckinTime, 'minutes').minutes < 0
	let appointmentDetentionTime: DateTime
	let timeLeft: number
	if (!arrivedLate) {
		if (_idx(() => appointment.shipmentInfo.detentionDuration)) {
			appointmentDetentionTime = appointmentStartTime.plus({
				minutes: appointment.shipmentInfo.detentionDuration,
			})
		} else {
			appointmentDetentionTime = appointmentEndTime
		}
		timeLeft = Math.ceil(
			appointmentDetentionTime.diff(currentTime, 'minutes').minutes,
		) // this uses detention time
	} else {
		timeLeft = Math.ceil(
			appointmentEndTime.diff(currentTime, 'minutes').minutes,
		) // this uses scheduled end time
	}

	if (timeLeft < 0) {
		timeStatus = 'overTime'
		timeLeft = -timeLeft
		if (!arrivedLate) {
			detention = true
		}
	}

	const appointmentClassName = addClass(
		commonClasses.clickable,
		classes.appointmentDetailsBox,
	)
	let checkInTimeClass = classes.subItem
	checkInTimeClass = addClassIf(
		arrivedLate,
		getTextColor('lightRed'),
		checkInTimeClass,
	)

	const handlingUnitDescription = getHandlingUnitDescription(appointment)

	return (
		<div className={appointmentStatusItemClass}>
			<FlexRow verticalAlign='center'>
				<div data-testid='dock-name' className={classes.dockName}>
					{dockName}
				</div>
				<FlexVerticalSeparator />
				<div
					className={appointmentClassName}
					onClick={() =>
						sosDockScheduler.updateSelectedTab(
							'appointment-details',
							appointment.id,
						)
					}
				>
					<FlexRow mode='space-between'>
						<div>
							<div className={classes.mainItem}>
								{t(timeStatus, tPrefixStatusBoard + '.timeStatuses')}
							</div>
							<div className={classes.subItem}>
								{timeLeft}
								{t('m', tPrefixStatusBoard + '.units')}{' '}
								{timeStatus === 'onTime'
									? t('left', tPrefixStatusBoard)
									: t('over', tPrefixStatusBoard)}
							</div>

							<div className={classes.mainItem}>
								{t('checkedIn', tPrefixStatusBoard)}
							</div>
							<div className={checkInTimeClass} data-testid='checkInTime'>
								{appointmentCheckinTime.toFormat(timeFormat)}
							</div>

							<div className={classes.mainItem}>
								{t('detentionStart', tPrefixStatusBoard)}
							</div>
							<div className={classes.subItem} data-testid='detentionStartTime'>
								{appointmentDetentionTime
									? appointmentDetentionTime.toFormat(timeFormat)
									: ndash}
							</div>
						</div>
						<div>
							<div className={classes.mainItem}>
								{t('appointment', tPrefixStatusBoard)}
							</div>
							<div className={classes.subItem} data-testid='appointmentTime'>
								{appointmentStartTime.toFormat(timeFormat)} -{' '}
								{appointmentEndTime.toFormat(timeFormat)}
								{/* (when the appointment goes into detention) */}
							</div>
							<div className={classes.mainItem}>
								{appointment.stopType && tStopType(appointment.stopType)}
							</div>
							<div className={classes.subItem}>{handlingUnitDescription}</div>
						</div>
						<div className={classes.badgesAndProvider}>
							<AlignRight>
								{appointment.status && (
									<AppointmentStatusBadge
										status={appointment.status}
										detention={detention}
									/>
								)}
								<div
									className={addClass(
										classes.alignBottom,
										classes.providerName,
									)}
								>
									{appointment.trailerInfo?.carrier}
								</div>
							</AlignRight>
						</div>
					</FlexRow>
				</div>
			</FlexRow>
		</div>
	)
}
