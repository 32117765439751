import { log, logError } from 'ui/lib/log/log'
import { inJestUnitTest } from 'ui/lib/testing/inJestUnitTest'

const _fireAndForget = async <T>(
	asyncFunc: () => Promise<T>,
	message?: string,
): Promise<void> => {
	try {
		if (inJestUnitTest()) {
			await asyncFunc()
		} else {
			log('fireAndForget', message, 'starting')
			await asyncFunc()
			log('fireAndForget', message, 'finished')
		}
	} catch (ex) {
		logError('fireAndForget', message, 'failed', ex)
	}
}

// Call an async function without caring about its result
export function fireAndForget<T>(
	asyncFunc: () => Promise<T>,
	message: string,
): void {
	_fireAndForget(asyncFunc, message) // eslint-disable-line @typescript-eslint/no-floating-promises
}
