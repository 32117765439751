// This file is shared with Api v6 and needs to manually be kept in sync
import { l } from 'ui/lib/lodashImports'

function hasExactPermission(
	userPermissions: string[],
	permission: string,
): boolean {
	const item = l.find(userPermissions, (c) => c === permission)
	return item ? true : false
}
function hasPermission(permissionTree: any, permission: string): boolean {
	if (!permissionTree) {
		return false
	}

	const parts = permission.split(':')
	if (parts.length !== 3) {
		throw Error(`Permission ${permission} is not in the correct format`)
	}
	const domainPart = parts[0].toLowerCase()
	const entityPart = parts[1].toLowerCase()
	const actionPart = parts[2].toLowerCase()

	const domain = permissionTree[domainPart]
	if (domain == null) {
		return false
	}

	if (domain['*'] != null) {
		return true // Wildcard hit
	}

	const entity = domain[entityPart]
	if (entity == null) {
		return false
	}
	if (entity['*'] != null) {
		return true // Wildcard hit
	}

	const action = entity[actionPart]
	if (action == null) {
		return false
	}

	return true
}

function buildPermissionsTree(userPermissions: string[]): any {
	const tree: any = {}

	l.forEach(userPermissions, (c) => {
		const parts = c.split(':')

		let domainPart = parts[0]
		let entityPart = parts[1] || null
		let actionPart = parts[2] || null

		domainPart = domainPart.toLowerCase()
		if (!tree[domainPart]) {
			tree[domainPart] = {}
		}
		const domain = tree[domainPart]

		if (entityPart) {
			entityPart = entityPart.toLowerCase()
			if (!domain[entityPart]) {
				domain[entityPart] = {}
			}
			const entity = domain[entityPart]

			if (actionPart) {
				actionPart = actionPart.toLowerCase()
				if (!entity[actionPart]) {
					entity[actionPart] = true
				}
				// let action = entity[actionPart]
			}
		}
	})

	return tree
}

const permissions = {
	hasPermission,
	hasExactPermission,
	buildPermissionsTree,
}
export { permissions }
