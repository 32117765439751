import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { Icon, solidIcons } from 'ui/components/common/icon'
import { IAddress, SimpleAddress } from 'ui/components/shared/address'
import { Colors } from 'ui/theme/theme'
import * as classes from './AddressDetails.module.scss'
import * as mapIconClasses from './MapIcons.module.scss'

export const AddressDetails: FC = (props: {
	address: IAddress
	markerColor: Colors
}) => {
	return (
		<div className={classes.addressContainer}>
			<div className={classes.addressMarkerIcon}>
				<Icon
					className={mapIconClasses.markerDropShadow}
					icon={solidIcons.faMapMarker}
					color={props.markerColor}
				/>
			</div>
			<div className={classes.address}>
				<SimpleAddress address={props.address} />
			</div>
		</div>
	)
}
