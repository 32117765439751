import { apiRfp, apiTypes } from 'ui/api'
import { IRequestState } from 'ui/api/requestState'
import { sosRouter2 } from 'ui/components/common/router'
import { dataUtils } from 'ui/components/table/'
import {
	createDefaultDataTableState,
	IDataTableState,
} from 'ui/components/table/IDataTableState'
import { fireAndForget } from 'ui/lib/async/fireAndForget'
import { IRfp } from 'ui/lib/books/IRfp'
import { ICarrier } from 'ui/lib/contracts/ICarrier'
import { IContract } from 'ui/lib/contracts/IContract'
import { l } from 'ui/lib/lodashImports'
import { createLazySos2 } from 'ui/lib/state/sos2/sos2'
import { formatContractName } from 'ui/pages/rfp/functions/formatContractName'
import { parseRfps } from 'ui/pages/rfp/functions/parseRfps'
import { getRfpUrlState, rfpPageInfo } from 'ui/pages/rfp/sosRfp'
import { exportRfpShipments } from 'ui/pages/rfp/sosRfpAnalysisPage_exportRfpShipments'
import {
	copyStatePagerFromElasticsearch,
	createDefaultStatePager,
	getTakeAndSkip,
	IStatePager,
} from './paging'

export interface IStateRfp {
	dataTableStateRfps: IDataTableState<IRfp>
	dataRfps: IRfp[]
	shipmentResponses: apiTypes.RfpShipmentResponse[]
	requestGetRfp: IRequestState<apiTypes.RfpShipmentListResponse>
	carrierList: ICarrier[]
	compareContractA: string
	compareContractB: string
	pager: IStatePager
	modalOpen: boolean
	bookedRateIds: string[]
	exportTotalSteps: number
	exportCurrentStep: number
	errors: string[]
}

const pageSize = 25

export const getSos = createLazySos2<IStateRfp>(
	'sosRfpAnalysisPage',
	2,
	() => ({
		dataTableStateRfps: {
			default: createDefaultDataTableState({
				sortOn: 'id',
				hiddenHeaders: [],
			}),
			localStorage: true,
		},
		dataRfps: { default: [], localStorage: false },
		shipmentResponses: { default: [], localStorage: false },
		requestGetRfp: { default: {}, localStorage: true },
		compareContractA: { default: '', localStorage: true },
		compareContractB: { default: '', localStorage: true },
		carrierList: { default: [], localStorage: false },
		pager: { default: createDefaultStatePager(pageSize), localStorage: true },
		modalOpen: { default: false, localStorage: false },
		bookedRateIds: { default: [], localStorage: true },
		exportTotalSteps: { default: 1, localStorage: false },
		exportCurrentStep: { default: 0, localStorage: false },
		errors: {
			default: [],
			localStorage: false,
		},
	}),
)

const _parseRfps = (): void => {
	const { shipmentResponses } = getSos().getState()
	getSos().change((ds) => {
		ds.bookedRateIds = l.compact(shipmentResponses).map((shipmentResponse) => {
			return l.find(
				shipmentResponse.rates,
				(rate) => rate.offerStatus === 'booked',
			)?.id
		})
	})

	const state = getSos().getState()

	fireAndForget(async () => {
		const { compareContractA, compareContractB, pager, carrierList } = state
		const rfpRows = await parseRfps(
			shipmentResponses,
			compareContractA,
			compareContractB,
			pager.pageNumber || 0,
			carrierList,
			pageSize,
		)
		getSos().change((ds) => {
			ds.dataRfps = rfpRows
			dataUtils.initialLoad(ds.dataTableStateRfps, ds.dataRfps)
			if (!ds.compareContractA) {
				ds.compareContractA = carrierList[0]?.name || ''
			}
			if (!ds.compareContractB) {
				ds.compareContractB = carrierList[1]?.name || ds.compareContractA
			}
		})
	}, 'parsing rfp data into table data')
}

export const getCarriersAndContracts = (
	shipments: apiTypes.RfpShipmentResponse[],
	bookedRateIds?: string[],
): void => {
	if (!bookedRateIds) {
		bookedRateIds = getSos().getState().bookedRateIds
	}
	l.forEach(shipments, (shipment: apiTypes.RfpShipmentResponse) => {
		l.forEach(shipment.rates, (rate: apiTypes.RateResponse) => {
			if (!bookedRateIds.includes(rate.id)) {
				getSos().change((ds) => {
					let rfpCarrier: ICarrier = l.find(
						ds.carrierList,
						(carrier) => carrier.id === rate.providerId,
					)
					if (!rfpCarrier) {
						rfpCarrier = {
							name: rate.carrier || rate.providerName,
							id: rate.providerId,
							includedInAnalysis: true,
							contracts: [],
						}
						ds.carrierList.push(rfpCarrier)
					}
					let rfpContract = l.find(rfpCarrier.contracts, (contract) =>
						rate.contractId
							? contract.id === rate.contractId
							: contract.name === formatContractName(rate),
					)
					if (!rfpContract) {
						rfpContract = {
							name: formatContractName(rate),
							id: rate.contractId,
							includedInAnalysis: true,
						}
						rfpCarrier.contracts.push(rfpContract)
					}
				})
			}
		})
	})
}

export const getRfps = async (
	refetch = false,
): Promise<apiTypes.RfpShipmentResponse[]> => {
	let state = getSos().getState()
	let shipmentResponses = l.cloneDeep(state.shipmentResponses)
	const { rfpId } = getRfpUrlState()
	let pageNumber = state.pager.fetchingPageNumber
	if (l.isNil(pageNumber)) {
		pageNumber = state.pager.pageNumber ? state.pager.pageNumber : 0
	}
	let rawDataSectionStart = pageNumber * state.pager.pageSize
	const pagerTakeAndSkip = getTakeAndSkip(state.pager)
	let skip = pagerTakeAndSkip.skip
	const take = pagerTakeAndSkip.take
	if (!l.isEmpty(shipmentResponses[rawDataSectionStart]) && !refetch) {
		getSos().change((ds) => {
			copyStatePagerFromElasticsearch(ds.pager, {
				pageNumber: skip / take,
				pageCount: Math.ceil(shipmentResponses.length / take),
				pageSize: take,
				total: shipmentResponses.length,
				skip,
				take,
			})
		})
		_parseRfps()
		return shipmentResponses
	} else if (refetch) {
		getSos().change((ds) => {
			ds.pager = createDefaultStatePager(pageSize)
			ds.shipmentResponses = []
		})

		state = getSos().getState()
		skip = getTakeAndSkip(state.pager).skip
		shipmentResponses = l.cloneDeep(state.shipmentResponses)
		rawDataSectionStart = state.pager.fetchingPageNumber * state.pager.pageSize
	}

	const query = {
		take: take,
		skip: skip,
		query: `rfpId:${rfpId}`,
	}
	const entireResponse = true
	const response = await apiRfp.getRfpShipments(
		(rs: IRequestState<apiTypes.RfpShipmentListResponse>) => {},
		query,
		entireResponse,
	)
	if (response.data) {
		const bookedRateIds = response.data.entities?.map((shipment) => {
			const bookedRate =
				shipment.bookedRate ||
				l.find(shipment.rates, (rate) => rate.offerStatus === 'booked')
			return bookedRate?.id
		})

		getCarriersAndContracts(response.data.entities, bookedRateIds)
		getSos().change((ds) => {
			copyStatePagerFromElasticsearch(ds.pager, response.data)
		})
	}
	if (shipmentResponses.length !== state.pager.total) {
		// populate placeholders for RFP shipments
		for (let i = 0; i < state.pager.total; i++) {
			shipmentResponses[i] = null
		}
	}
	for (let i = 0; i < response.data.entities.length; i++) {
		shipmentResponses[rawDataSectionStart + i] = response.data.entities[i]
	}

	if (response.data) {
		getSos().change((ds) => {
			ds.shipmentResponses = shipmentResponses
		})
	}
	_parseRfps()
	return shipmentResponses
}

export const setCurrentPage = async (i): Promise<void> => {
	getSos().change((ds) => {
		ds.pager.fetchingPageNumber = i
	})
	await getRfps(false)
}

export const setModalOpen = (open: boolean): void => {
	getSos().change((ds) => {
		ds.modalOpen = open
	})
}

export const clearErrors = (): void => {
	getSos().change((ds) => {
		ds.errors = []
	})
}

export async function exportData(): Promise<void> {
	setModalOpen(true)
	const { rfpId } = getRfpUrlState()
	clearErrors()
	const state = getSos().getState()

	const carrierCount = state.carrierList.length === 0 ? 1 : state.carrierList.length

	const csvContent = await exportRfpShipments(rfpId, carrierCount)
	const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
	const url = URL.createObjectURL(blob)
	const link = document.createElement('a')
	link.setAttribute('href', url)
	link.setAttribute('download', 'rfp.csv')
	document.body.appendChild(link)
	link.click()

	setModalOpen(false)
}

export const navigateToRfpAnalysis = (params: {
	shipmentIds: string[]
	bookedRateIds: string[]
	rfpId: string
	rfpProjectId?: string
}): void => {
	getSos().change((ds) => {
		ds.bookedRateIds = params.bookedRateIds
		ds.pager.fetchingPageNumber = 0
	})
	sosRouter2.navigateTo(rfpPageInfo, {
		selectedPage: 'analysis',
		rfpId: params.rfpId,
		rfpProjectId: params.rfpProjectId,
		edit: 'false',
	})
	fireAndForget(async () => getRfps(false), 'getting RFP shipments')
}

export const atRfpUpload = (): boolean => {
	return getRfpUrlState().selectedPage === 'upload'
}

export const debouncedParseRfps = l.debounce(_parseRfps, 1000)

export function rfpTableSort(sortOn: string): void {
	getSos().change((ds) => {
		dataUtils.sort(ds.dataTableStateRfps, ds.dataRfps, sortOn)
	})
}

export function rfpTableToggleHeader(field: string): void {
	getSos().change((ds) => {
		dataUtils.toggleHeader(ds.dataTableStateRfps, field)
	})
}

export function toggleCarrier(carrier: ICarrier, checked: boolean): void {
	getSos().change((ds) => {
		const affectedCarrier: ICarrier = l.find(
			ds.carrierList,
			(c) => c.id === carrier.id,
		)
		affectedCarrier.includedInAnalysis = checked
	})

	debouncedParseRfps()
}

export function toggleAllCarriers(checked: boolean): void {
	getSos().change((ds) => {
		l.forEach(ds.carrierList, (c) => {
			c.includedInAnalysis = checked
			l.forEach(c.contracts, (d) => {
				d.includedInAnalysis = checked
			})
		})
	})

	debouncedParseRfps()
}

export function toggleContract(
	carrier: ICarrier,
	contract: IContract,
	checked: boolean,
): void {
	getSos().change((ds) => {
		const affectedCarrier: ICarrier = l.find(
			ds.carrierList,
			(c) => c.id === carrier.id,
		)
		const affectedContract: IContract = l.find(
			affectedCarrier.contracts,
			(c) => c.id === contract.id,
		)
		if (checked) {
			affectedCarrier.includedInAnalysis = true
		}
		affectedContract.includedInAnalysis = checked
	})

	debouncedParseRfps()
}

export function rfpChangeCompareContractA(contract: string): void {
	getSos().change((ds) => {
		ds.compareContractA = contract
	})
	debouncedParseRfps()
}

export function rfpChangeCompareContractB(contract: string): void {
	getSos().change((ds) => {
		ds.compareContractB = contract
	})
	debouncedParseRfps()
}

export const setExportTotalSteps = (totalSteps: number): void => {
	getSos().change((ds) => {
		ds.exportTotalSteps = totalSteps
	})
}

export const increaseExportCurrentStep = (): void => {
	getSos().change((ds) => {
		ds.exportCurrentStep++
	})
}

export const resetExportCurrentStep = (): void => {
	getSos().change((ds) => {
		ds.exportCurrentStep = 0
	})
}

export const addToErrors = (errorMessage: string): void => {
	getSos().change((ds) => {
		ds.errors.push(errorMessage)
	})
}
