import React from 'react'
import {
	getLocalStorage,
	getLocationHref,
	localStorageExists,
} from 'ui/components/common/router/windowUtils'
import { l } from '../../lodashImports'
import { logInfo, logWarn } from '../../log'
import { r, ra } from '../../ramdaImports'
import { deepFreeze } from '../deepFreeze'
import { inJestUnitTest } from '../../testing'

const namespace = 'sos2'

// TODO:
// Http requests
// Big data
export interface IStateMetaItem<T> {
	default: T
	localStorage?: boolean

	/**
	 * @deprecated
	 */
	queryStringParam?: string
	/**
	 * @deprecated
	 */
	queryStringEncode?: (c: T) => string
	/**
	 * @deprecated
	 */
	queryStringDecode?: (c: T, qs: string) => T
	/**
	 * @deprecated
	 */
	urlParam?: boolean
}

// type NotNull<A> = A extends null ? never : A

export type IStateMeta<T> = {
	[key in keyof T]: IStateMetaItem<T[key]>
}

export type StateCallbackType<T> = (state: T) => void

let _onNavigate: (url: string, queryString: string) => void
export const setOnNavigate = (
	action: (url: string, queryString: string) => void,
): void => {
	_onNavigate = action
}

export interface ISos2<T> {
	getSosKey: () => string
	subscribe: (callback: StateCallbackType<T>) => StateCallbackType<T>
	unsubscribe: (callback: StateCallbackType<T>) => void
	getState: () => T
	change: (producer: (state: T) => void) => void
	replace: (state: T) => void
	patchShallow: (partialState: Partial<T>) => void
	patchDeep: (partialState: Partial<T>) => void
	getStats: () => ISosStats
	syncToLocation: () => void
	syncFromLocation: (params: any) => void
}
export interface ISosStats {
	sosKey: string
	numUpdates: number
	numSubscriptionUpdates: number
	numSubscriptions?: number
	totalUpdateMs: number
	minUpdateMs: number
	maxUpdateMs: number
	averageUpdateMs: number
}

const registeredSos2s: ISos2<any>[] = []
// Export to console
const g = global as any

const ensureSosKeyIsNotAlreadyRegistered = (sosKey): void => {
	if (r.find((c) => c.getSosKey() === sosKey, registeredSos2s)) {
		throw new Error(
			`This sos key is already registered! Please use a unique one for your sos. ${sosKey}`,
		)
	}
}

// Load sos on-demand, should speed up performance and help reduce circular dependencies
export type LazySos2Type<T> = () => ISos2<T>
export const createLazySos2 = <T>(
	sosKey: string,
	version: number,
	metaMaker: () => IStateMeta<T>,
): LazySos2Type<T> => {
	ensureSosKeyIsNotAlreadyRegistered(sosKey)

	let _sos: ISos2<T> = null
	return () => {
		if (!_sos) {
			const meta = metaMaker()
			_sos = createSos2(sosKey, version, meta)
		}
		return _sos
	}
}

export const createSos2 = <T>(
	sosKey: string,
	version: number,
	meta: IStateMeta<T>,
	baseUrl?: string,
	options?: { immediateUpdate: boolean },
): ISos2<T> => {
	ensureSosKeyIsNotAlreadyRegistered(sosKey)

	const getSosKey = (): string => sosKey

	const metaArray = r.map((c) => {
		const m: IStateMetaItem<T> = meta[c]
		return { key: c, meta: m }
	}, Object.keys(meta))
	const urlMetaArray = r.filter(
		(c) => ra.isTruthy(c.meta.queryStringParam || c.meta.urlParam),
		metaArray,
	)

	if (urlMetaArray.length > 0) {
		if (r.not(inJestUnitTest())) {
			logWarn(
				namespace,
				sosKey,
				'queryStringParam and urlParam support is deprecated. Please use sosRouter2 -- see sosShipmentProfile and sosShipmentsList for examples',
			)
		}
	}

	const stats: ISosStats = {
		sosKey,
		maxUpdateMs: 0,
		minUpdateMs: 0,
		totalUpdateMs: 0,
		averageUpdateMs: 0,
		numUpdates: 0,
		numSubscriptionUpdates: 0,
		numSubscriptions: 0,
	}

	const initialState: any = r.map(r.prop('default'), meta as any)

	// Load from local storage
	if (localStorageExists) {
		try {
			const stored = getLocalStorage().getItem('sos2:' + sosKey)
			if (stored) {
				const parsed = JSON.parse(stored)
				if (parsed && parsed.version === version) {
					const data = parsed.state
					const keys = Object.keys(meta)
					r.forEach((k) => {
						const v = meta[k]
						if (v && v.localStorage && r.not(r.isNil(data[k]))) {
							initialState[k] = data[k]
						}
					}, keys)
				}
			}
		} catch (err) {
			logWarn(sosKey, `sos ${sosKey} local storage corrupted`)
		}
	}

	let _state: T
	const _setState = (newState): void => {
		stats.numUpdates++
		_state = deepFreeze(newState)
	}
	_setState(initialState as T)

	const saveStateToLocalStorage = l.debounce(() => {
		if (localStorageExists) {
			const state = getState()
			const toSave: any = {}
			const keys = r.keys(meta)
			r.forEach((k) => {
				const v = meta[k]
				if (v.localStorage) {
					toSave[k] = state[k]
				}
			}, keys)

			getLocalStorage().setItem(
				'sos2:' + sosKey,
				JSON.stringify({ version, state: toSave }, null, 2),
			)
		}
	}, 500)

	let subscriptions: Array<(state: T) => void> = []
	const subscribe = (callback: (state: T) => void): ((state: T) => void) => {
		subscriptions.push(callback)
		if (subscriptions.length > 10) {
			logWarn(
				sosKey,
				`sos ${sosKey} has ${subscriptions.length} subscriptions, possible leak`,
			)
		}
		return callback
	}
	const unsubscribe = (callback: (state: T) => void): void => {
		subscriptions = r.reject(r.equals(callback), subscriptions)
	}
	const callSubscriptions = (): void => {
		stats.numSubscriptionUpdates++
		const start = Date.now()
		r.forEach((c) => c(_state), subscriptions)
		const elapsedMs = Date.now() - start
		stats.totalUpdateMs += elapsedMs
		if (elapsedMs > stats.maxUpdateMs) {
			stats.maxUpdateMs = elapsedMs
		}
		if (elapsedMs < stats.minUpdateMs) {
			stats.minUpdateMs = elapsedMs
		}
		if (elapsedMs > 10) {
			logWarn(
				sosKey,
				`sos ${sosKey} took ${elapsedMs} ms to update ${subscriptions.length} subscriptions`,
			)
		}
		saveStateToLocalStorage()
	}
	const getState = (): T => _state
	let timerId: any = 0
	const replace = (state: T): void => {
		// Make sure there's a change
		const isSame = r.equals(_state, state)
		if (r.not(isSame)) {
			const updateUrl = r.any(
				(c) => r.not(r.equals(state[c.key], _state[c.key])),
				urlMetaArray,
			)

			_setState(state)

			if (updateUrl) {
				syncToLocation()
			}

			if (options && options.immediateUpdate) {
				callSubscriptions()
			} else {
				if (timerId) {
					clearTimeout(timerId)
				}
				timerId = setTimeout(() => {
					callSubscriptions()
				}, 1000 / 120)
			}
		}
	}
	const change = (producer: (state: T) => void): void => {
		const draftState = r.clone(_state)
		producer(draftState)
		replace(draftState)
	}
	const patchShallow = (partialState: Partial<T>): void => {
		const newState = r.mergeLeft(partialState, _state as any) as T
		replace(newState)
	}
	const patchDeep = (partialState: Partial<T>): void => {
		const newState = r.mergeDeepLeft(partialState, _state as any) as T
		replace(newState)
	}

	// Urls
	const syncToLocation = (): void => {
		// Sync to url
		if (baseUrl) {
			const state = getState()
			const queryParams: string[] = []
			let url = baseUrl
			//url += '?'
			r.forEach((c) => {
				if (c.meta.urlParam) {
					url = url.replace(':' + c.key, state[c.key])
				}
				if (
					c.meta.queryStringParam &&
					r.not(r.equals(c.meta.default, state[c.key]))
				) {
					let encoded = ''
					if (c.meta.queryStringEncode) {
						encoded = encodeURIComponent(c.meta.queryStringEncode(state[c.key]))
					} else {
						encoded = encodeURIComponent('' + state[c.key])
					}

					queryParams.push(`${c.meta.queryStringParam}=${encoded}`)
				}
			}, urlMetaArray)
			const qs = '?' + r.join('&', queryParams)

			if (_onNavigate) {
				_onNavigate(url, qs)
			}
		}
	}
	const syncFromLocation = (params: any): void => {
		change((ds) => {
			r.forEach((c) => {
				if (c.meta.urlParam) {
					ds[c.key] = params[c.key] || ds[c.key] || c.meta.default
				}
				if (c.meta.queryStringParam) {
					let queryParam = params[c.meta.queryStringParam]
					if (c.meta.queryStringDecode) {
						queryParam = c.meta.queryStringDecode(ds[c.key], queryParam)
					}

					ds[c.key] = queryParam || ds[c.key] || c.meta.default
				}
			}, urlMetaArray)
		})
		// Force navigate to page if state is the same, just url changed
		if (getLocationHref().indexOf(baseUrl) === -1) {
			syncToLocation()
		}
	}

	const getStats = (): {
		numSubscriptions: number
		averageUpdateMs: number
		sosKey: string
		numUpdates: number
		numSubscriptionUpdates: number
		totalUpdateMs: number
		minUpdateMs: number
		maxUpdateMs: number
	} => {
		return r.mergeLeft(
			{
				numSubscriptions: subscriptions.length,
				averageUpdateMs: stats.numSubscriptionUpdates
					? stats.totalUpdateMs / stats.numSubscriptionUpdates
					: 0,
			},
			stats,
		)
	}

	const sos = {
		getSosKey,
		getState,
		change,
		replace,
		patchShallow,
		patchDeep,
		subscribe,
		unsubscribe,
		getStats,
		syncToLocation,
		syncFromLocation,
	}
	registeredSos2s.push(sos)
	// Export to console
	if (!g[sosKey]) {
		g[sosKey] = sos
	}
	return sos
}

// Debugging
export const reportStats = (): void => {
	logInfo(namespace, 'sos stats')
	logInfo(namespace, 'registered ' + registeredSos2s.length)
	r.forEach((c) => {
		const info = c.getStats()
		logInfo(namespace, 'stats', info)
	}, registeredSos2s)
}

// React hook
export function useSubscription<T>(sos2: ISos2<T>): T {
	const [state, setState] = React.useState(sos2.getState)
	const handleStateChange = (newState: T): void => {
		const start = Date.now()
		setState(newState)
		const elapsedMs = Date.now() - start
		if (elapsedMs > 1000) {
			logWarn('sos', `sos took ${elapsedMs} ms to update a single subscription`)
		}
	}
	React.useEffect(() => {
		sos2.subscribe(handleStateChange)
		return () => {
			sos2.unsubscribe(handleStateChange)
		}
	}, [sos2])

	return state
}
