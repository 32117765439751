// barrel-rider:ignore
import 'core-js' // Polyfills for ie11, do not remove this
import { createBrowserHistory, createHashHistory } from 'history'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './AppIndex.scss'
import { sosRouter } from 'ui/components/common/router'
import { documentExists, getDocument } from 'ui/components/common/router/windowUtils'

declare let module: any

const useHashRouting = true

const customHistory = useHashRouting
  ? createHashHistory({ hashType: 'slash' })
  : createBrowserHistory()
sosRouter.setHistory(customHistory)

const render = (Component): void => {
  if (documentExists) {
    return ReactDOM.render(<Component />, getDocument().getElementById('root'))
  }
}

render(App)
// console.log('initial render complete')
if (module.hot) {
  // console.log('hot reloading active')
  module.hot.accept('./App', () => {
    // console.log('accepting next app')
    const NextApp = require('./App').default
    render(NextApp)
  })
}
