import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { ISelectOptions } from '../common/select/ISelectOptions'
import { ListSelect } from '../common/select/ListSelect'
import { t } from '../i18n/i18n'

export const FormStackedListSelect: FC = (props: {
	options: ISelectOptions[]
	value: any
	onChange: (values: any) => void
	label?: React.ReactElement | string
	tPrefix?: string
	size?: number
	testId?: string
	readOnly?: boolean
}) => {
	return (
		<div>
			{props.label && (
				<div>
					{props.tPrefix && typeof props.label === 'string' ? (
						t(props.label, props.tPrefix)
					) : (
						<div>{props.label}</div>
					)}
				</div>
			)}
			<ListSelect
				options={props.options}
				value={props.value}
				size={props.size || props.options.length}
				onChange={(values) => {
					props.onChange(values)
				}}
				testId={props.testId}
				tPrefix={props.tPrefix}
				readOnly={props.readOnly}
			/>
		</div>
	)
}
