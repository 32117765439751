import _ from 'lodash'
import { IAddress } from 'ui/components/shared/address/IAddress'
import { _idx } from '../_idx'

// TODO: move to common code repo
export function extractOriginStopsFromShipment(shipment: any): IAddress[] {
	const originStop = []
	if (!shipment) {
		return originStop
	}
	_.forEach(shipment.payloads, (payload) => {
		originStop.push(payload.originStop.address)
	})
	return originStop
}

export function getInitialOrigin(shipment: any): IAddress {
	if (!shipment) {
		return null
	}

	return shipment.origin || _idx(() => shipment.payloads[0].originStop.address)
}
