import React, { useState } from 'react'
import { FC } from 'ui/FunctionalComponent'
import { Card } from 'ui/components/common/card'
import { t, tString } from 'ui/components/i18n/i18n'
import { Button } from 'ui/components/common/button'
import * as classes from './ProviderInvoice.module.scss'
import { ProviderCharges } from './provider-charges'
import { ProviderInvoiceTitle } from './provider-invoice-title/ProviderInvoiceTitle'
import { ProviderInvoiceNotes } from './provider-invoice-notes'
import { apiProviderInvoice, apiProviders, apiTypes } from 'ui/api'
import { l } from 'ui/lib/lodashImports'
import { sosToast } from 'ui/common/components/toast'
import { Spacer } from 'ui/components/layout/spacer'
import { Center } from 'ui/components/layout/center'
import { Loader } from 'ui/components/common/loader'
import { UIProviderInvoice } from '../CustomerInvoicePage'

const tPrefix = 'page.customerInvoice.providerInvoice'

export const ProviderInvoice: FC = (props: {
	shipmentId: string
	brokerShipment: apiTypes.BrokerShipmentResponse
	isFetchingInvoices: boolean
	brokerInvoice: apiTypes.BrokerInvoiceResponse
	providerInvoices: UIProviderInvoice[]
	setProviderInvoices: React.Dispatch<React.SetStateAction<UIProviderInvoice[]>>
	providerChargeCodes: apiTypes.ChargeCodePageResponse[]
}) => {
	const {
		shipmentId,
		brokerShipment,
		isFetchingInvoices,
		brokerInvoice,
		providerInvoices,
		setProviderInvoices,
		providerChargeCodes,
	} = props

	const [isLoading, setIsLoading] = useState<boolean>(false)

	const addProviderInvoice = async (): Promise<void> => {
		setIsLoading(true)

		const response = await apiProviders.getProviderByName(() => {},
		brokerShipment?.bookedRate?.providerName)

		if (response.error) {
			sosToast.sendApiErrorResponseToast(
				response,
				tString('providerFetchError', 'common'),
			)
		} else {
			const providerInvoiceRequest: apiTypes.ProviderInvoiceCreateRequest = {
				shipmentId: shipmentId,
				clientConfigId: brokerShipment?.contractId,
				brokerInvoiceId: brokerInvoice?.id,
				invoiceNumber: '-',
				invoiceStatus: 'review',
				invoiceDate: '-',
				paymentTerms: 0,
				currency: 'USD',
				notes: '',
				creationSource: 'Customer Invoicing',
				charges: [],
				providerId: response.data.id,
			}

			const providerInvoiceResponse = await apiProviderInvoice.createProviderInvoice(
				() => {},
				providerInvoiceRequest,
			)

			if (providerInvoiceResponse.error) {
				sosToast.sendApiErrorResponseToast(providerInvoiceResponse)
			} else {
				const data = providerInvoiceResponse.data as UIProviderInvoice

				data.providerName = await getProviderById(
					providerInvoiceResponse.data.providerId,
				)

				if (providerInvoices) {
					providerInvoices.push(data)
					setProviderInvoices(providerInvoices)
				} else {
					setProviderInvoices([data] as UIProviderInvoice[])
				}
			}
		}

		setIsLoading(false)
	}

	const getProviderById = async (id?: string): Promise<string> => {
		const providerId = id ? id : brokerShipment?.bookedRate.providerId

		const response = await apiProviders.getProvider(() => {}, providerId)

		if (response.error) {
			sosToast.sendApiErrorResponseToast(
				response,
				tString('providerFetchError', 'common'),
			)
		} else {
			return response.data.name
		}
	}

	return (
		<Card
			title={
				<div className={classes.titleContainer}>
					{t('providerInvoice', tPrefix)}
					{brokerInvoice?.invoiceStatus === 'review' && (
						<Button
							color={'green'}
							onClick={addProviderInvoice}
							isSpinning={isLoading}
						>
							{t('addProviderInvoice', tPrefix)}
						</Button>
					)}
				</div>
			}
			color={'darkBlue'}
			testId={'customer-invoice-provider-invoice'}
		>
			{!providerInvoices?.length && !isFetchingInvoices ? (
				<div className={classes.noDataWarning}>
					<Center>{t('noLinkedProviderInvoices', tPrefix)}</Center>
				</div>
			) : isFetchingInvoices ? (
				<Center>
					<Loader isLoading={isFetchingInvoices} />
				</Center>
			) : (
				l.map(providerInvoices, (providerInvoice, idx) => {
					return (
						<div key={`${providerInvoice} ${idx}`}>
							<Spacer />
							<ProviderInvoiceTitle
								shipmentId={shipmentId}
								brokerInvoice={brokerInvoice}
								providerInvoice={providerInvoice}
								onUpdate={async (
									updateMode: 'upsert' | 'delete',
									data?: apiTypes.ProviderInvoiceResponse,
								) => {
									const updatedProviderInvoices = [...providerInvoices]

									const index = updatedProviderInvoices.findIndex(
										(invoice) => invoice.id === providerInvoice.id,
									)

									if (updateMode === 'upsert') {
										updatedProviderInvoices[index].invoiceNumber =
											data.invoiceNumber
										updatedProviderInvoices[index].invoiceDate =
											data.invoiceDate
										updatedProviderInvoices[index].providerId = data.providerId
									} else {
										updatedProviderInvoices.splice(index, 1)
									}
									setProviderInvoices(updatedProviderInvoices)
								}}
								tPrefix={tPrefix}
							/>
							<Spacer height={'20px'} />
							<ProviderCharges
								shipmentId={shipmentId}
								brokerInvoice={brokerInvoice}
								providerInvoice={providerInvoice}
								providerChargeCodes={providerChargeCodes}
								onUpdate={(
									updateMode: 'add' | 'upsert' | 'delete',
									charges: any,
								) => {
									const updatedProviderInvoices = [...providerInvoices]

									const index = updatedProviderInvoices.findIndex(
										(invoice) => invoice.id === providerInvoice.id,
									)

									if (updateMode === 'add') {
										if (providerInvoice?.charges.length) {
											updatedProviderInvoices[index].charges.push(charges)
										} else {
											updatedProviderInvoices[index].charges = [charges]
										}
									} else {
										updatedProviderInvoices[index].charges = charges
									}

									setProviderInvoices(updatedProviderInvoices)
								}}
								tPrefix={tPrefix}
							/>
							<Spacer height={'20px'} />
							<ProviderInvoiceNotes
								brokerInvoice={brokerInvoice}
								providerInvoice={providerInvoice}
								onUpdate={(notes?: string) => {
									const updatedProviderInvoices = [...providerInvoices]

									const index = updatedProviderInvoices.findIndex(
										(invoice) => invoice.id === providerInvoice.id,
									)

									updatedProviderInvoices[index].notes = notes

									setProviderInvoices(updatedProviderInvoices)
								}}
								tPrefix={tPrefix}
							/>
							<Spacer height={'20px'} />
						</div>
					)
				})
			)}
		</Card>
	)
}
