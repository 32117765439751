import React, { useState } from 'react'
import { FC } from 'app/FunctionalComponent'
import { sosToast } from 'common/components/toast'
import { Col, Container, Row } from 'react-bootstrap'
import { apiBroker, apiTypes } from 'ui/api'
import { IRequestState } from 'ui/api/requestState'
import { AngryRedBox } from 'ui/components/common/angry-red-box'
import { Input } from 'ui/components/common/input'
import { Modal } from 'ui/components/common/modal'
import { OkCancelButtons } from 'ui/components/common/okCancelButtons'
import {
	FormStackedTextInput,
	IFormData,
	IFormMetadataCollection,
	StackedItem,
} from 'ui/components/form'
import { useOnce } from 'ui/components/hooks'
import { t, tString } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight'
import { fireAndForget } from 'ui/lib/async'
import { l } from 'ui/lib/lodashImports'
import { toCents, toDollars, toDollarsFormatted } from 'ui/lib/numbers/toNumber'
import { validateForm } from 'ui/lib/validation/formValidator'
import {
	applyDefaultMarkupLogic,
	calculateBrokerPrices,
	IBrokerPrices,
} from 'ui/pages/shipment-profile/broker/functions'
import { addClass } from 'ui/theme/theme'
import { sosShipmentProfileBroker } from 'ui/pages/shipment-profile/broker'
import * as classes from './BrokerOfferModal.module.scss'

interface IBrokerOfferForm {
	sellPrice: number
	markupPercent?: number
	profit?: number
	transit: number
	quoteNumber: string
	message?: string
	upchargeType?: apiTypes.BrokerOfferRequest['markupLogic']['markupType']
}

const formMetadata: IFormMetadataCollection<IBrokerOfferForm> = {
	sellPrice: {
		required: true,
	},
	markupPercent: {
		required: false,
	},
	profit: {
		required: false,
	},
	transit: {
		required: true,
	},
	quoteNumber: {
		required: false,
	},
	message: {
		multiline: true,
		required: false,
	},
}

const tPrefix = 'page.shipmentProfile.brokerOfferModal'

export const BrokerOfferModal: FC = (props: {
	isOpen: boolean
	shipment: apiTypes.BrokerShipmentResponse
	defaultMarkupLogic: apiTypes.MarkupLogic
	onClose: () => void
}) => {
	const { shipment } = props
	const offer = shipment.offers[0]
	const buyRate = l.find(
		shipment.rates,
		(rate) => rate.id === offer?.brokerRateId,
	)
	const [brokerOfferForm, updateBrokerOfferForm] = useState<IBrokerOfferForm>({
		sellPrice: null,
		markupPercent: null,
		profit: null,
		transit: null,
		quoteNumber: null,
		message: null,
		upchargeType: null,
	})
	const [savingOffer, updateSavingOffer] = useState(false)
	const [showFillAllFieldsMessage, updateShowFillAllFieldsMessage] = useState(
		false,
	)
	useOnce(() => {
		const sellPrice = toDollars(offer?.flatOffer?.grossRate)
		const profit = toDollars(offer?.markupLogic?.markupFlat)
		updateBrokerOfferForm({
			sellPrice,
			markupPercent: Math.round((profit / sellPrice) * 100) || null,
			profit,
			transit: offer?.transit,
			quoteNumber: offer?.quoteNumber,
			message: offer?.message,
		})
	})
	let markedUpMarketRate: number
	const marketRate: apiTypes.RateResponse = l.find(
		shipment.rates,
		(rate) =>
			rate.providerName ===
			('truckstop.com' as apiTypes.RateResponse['providerName']),
	)
	if (marketRate) {
		markedUpMarketRate = applyDefaultMarkupLogic(
			marketRate.costTotal,
			props.defaultMarkupLogic,
		)
	}

	const formData: IFormData<IBrokerOfferForm> = {
		form: brokerOfferForm,
		metadata: formMetadata,
		onUpdateForm: (field: keyof IBrokerOfferForm, newVal) => {
			updateShowFillAllFieldsMessage(false)
			const changes: Partial<IBrokerOfferForm> = { [field]: newVal }
			if (buyRate && !l.isNil(newVal)) {
				const buyPrice = toDollars(buyRate.costTotal)
				let brokerPrices: IBrokerPrices

				if (field === 'markupPercent') {
					changes['upchargeType'] = 'percent'
					brokerPrices = calculateBrokerPrices({
						buyPrice,
						markupPercent: newVal,
					})
					changes.sellPrice = Number(brokerPrices.sellPrice.toFixed(2))
					changes.profit = Number(brokerPrices.profit.toFixed(2))
				} else if (field === 'profit') {
					changes['upchargeType'] = 'flat'
					brokerPrices = calculateBrokerPrices({
						buyPrice,
						profit: toDollars(newVal, 'dollars'),
					})
					changes.sellPrice = Number(brokerPrices.sellPrice.toFixed(2))
					changes.markupPercent = Number(brokerPrices.markupPercent.toFixed(2))
				} else if (field === 'sellPrice') {
					changes.upchargeType = 'flat'
					brokerPrices = calculateBrokerPrices({
						buyPrice,
						sellPrice: toDollars(newVal, 'dollars'),
					})
					changes.profit = Number(brokerPrices.profit.toFixed(2))
					changes.markupPercent = Number(brokerPrices.markupPercent.toFixed(2))
				}
			}
			updateBrokerOfferForm(l.assign(l.cloneDeep(brokerOfferForm), changes))
		},
		tPrefix,
	}

	const formIsValid = validateForm(brokerOfferForm, formMetadata).isValid
	return (
		<Modal
			closeModalX={true}
			title={t('editOffer', tPrefix)}
			isOpen={props.isOpen}
			onModalClose={props.onClose}
			content={() => (
				<div className={addClass('bootstrap-wrapper', classes.modalWrapper)}>
					<Container>
						<Row>
							<Col md={6} className={classes.modalCol}>
								{buyRate && (
									<StackedItem
										label={t('buyCost', tPrefix)}
										hideOptional={true}
									>
										<Input
											value={toDollarsFormatted(buyRate.costTotal)}
											readOnly={true}
											testId={'shipment-buy-cost'}
										/>
									</StackedItem>
								)}

								<FormStackedTextInput
									formData={formData}
									field={'transit'}
									inputType={'text'}
								/>
								<FormStackedTextInput
									formData={formData}
									field={'quoteNumber'}
									inputType={'text'}
								/>
								<FormStackedTextInput
									formData={formData}
									field={'message'}
									inputType={'text'}
								/>
							</Col>
							<Col md={6} className={classes.modalCol}>
								{markedUpMarketRate && (
									<StackedItem
										label={t('marketSellPrice', tPrefix)}
										hideOptional={true}
										testId={'market-sell-price'}
									>
										<Input
											value={toDollarsFormatted(markedUpMarketRate)}
											readOnly={true}
										/>
									</StackedItem>
								)}

								<FormStackedTextInput
									formData={formData}
									field={'sellPrice'}
									inputType={'text'}
								/>
								{buyRate && (
									<FormStackedTextInput
										formData={formData}
										field={'markupPercent'}
										inputType={'text'}
									/>
								)}
								{buyRate && (
									<FormStackedTextInput
										formData={formData}
										field={'profit'}
										inputType={'text'}
									/>
								)}
							</Col>
						</Row>

						<AlignRight>
							<OkCancelButtons
								ok={t('createOffer', tPrefix)}
								onOk={async () => {
									if (formIsValid) {
										updateSavingOffer(true)
										const brokerOfferRequest: apiTypes.BrokerOfferRequest = {
											offerType: buyRate ? 'markedUpRate' : 'flatOffer',
											flatOffer: {
												grossRate: toCents(brokerOfferForm.sellPrice),
											},
											markupLogic:
												buyRate && brokerOfferForm.profit
													? {
															markupType:
																brokerOfferForm.upchargeType || 'flat',
															markupFlat: toCents(brokerOfferForm.profit),
															markupPercent: Number(
																brokerOfferForm.markupPercent,
															),
													  }
													: props.defaultMarkupLogic,
											transit: Number(brokerOfferForm.transit),
											quoteNumber: brokerOfferForm.quoteNumber,
											brokerRateId: buyRate?.id,
											clientStatus: offer?.clientStatus || 'not-sent',
											message: brokerOfferForm.message,
										}
										let offerResponse: IRequestState<apiTypes.BrokerOfferResponse>
										if (offer) {
											offerResponse = await apiBroker.updateBrokerOffer(
												() => {},
												shipment.id,
												offer.id,
												brokerOfferRequest,
											)
										} else {
											offerResponse = await apiBroker.createBrokerOffer(
												() => {},
												shipment.id,
												brokerOfferRequest,
											)
										}
										if (offerResponse.error) {
											sosToast.sendApiErrorResponseToast(
												offerResponse,
												tString('updatingOfferError', tPrefix),
											)
										}
										updateSavingOffer(false)
										fireAndForget(
											async () =>
												await sosShipmentProfileBroker.fetchShipment(
													shipment.id,
												),
											'refetching shipment after updating broker offer',
										)
										props.onClose()
									} else {
										updateShowFillAllFieldsMessage(true)
									}
								}}
								okColor={formIsValid ? 'green' : 'gray'}
								cancel={t('cancel', tPrefix)}
								onCancel={props.onClose}
								isSpinning={savingOffer}
								isValid={true}
								okTestId='create-offer'
								cancelTestId='cancel-create-offer'
							/>
						</AlignRight>
						{offer?.clientStatus === 'sent' && savingOffer && (
							<div data-testid={'resending-offer-message'}>
								{t('resendingOfferToClient', tPrefix)}
							</div>
						)}
						{showFillAllFieldsMessage && (
							<AngryRedBox dataTestId={'fill-all-fields-warning'}>
								{t('fillAllRequiredFields', tPrefix)}
							</AngryRedBox>
						)}
					</Container>
				</div>
			)}
		/>
	)
}
