import { IFormMetadataCollection } from 'ui/components/form/IFormMetadata'

export interface ILightningIntegrationRegistrationForm {
	company: string
	email: string
}

export const lightningIntegrationRegistrationFormMetadata: IFormMetadataCollection<ILightningIntegrationRegistrationForm> = {
	company: {
		label: 'company',
		required: true,
	},
	email: {
		label: 'email',
		required: true,
	},
}
