import React, { useState } from 'react'
import { FC } from 'ui/FunctionalComponent'
import { Col } from 'react-bootstrap'
import * as classes from './InvoiceStatusButton.module.scss'
import { apiPayment, apiPaymentAllocation, apiTypes } from 'ui/api'
import { Button } from 'ui/components/common/button'
import { t } from 'ui/components/i18n/i18n'
import { l } from 'ui/lib/lodashImports'
import { sosToast } from 'ui/common/components/toast'
import { Modal } from 'ui/components/common/modal'
import { Center } from 'ui/components/layout/center'
import { AlignRight } from 'ui/components/layout/alignRight'
import { OkCancelButtons } from 'ui/components/common/okCancelButtons'
import { Spacer } from 'ui/components/layout/spacer'
import { Icon, solidIcons } from 'ui/components/common/icon'
import { theme } from 'ui/theme'

const tPrefixInvoiceModal = 'page.customerInvoice.invoiceCustomerConfirmModal'

export const InvoiceStatusButton: FC = (props: {
	shipmentId: string
	brokerInvoice: apiTypes.BrokerInvoiceResponse
	isUpdatingInvoiceStatus: boolean
	updateBrokerInvoiceStatus: (
		invoiceStatus: apiTypes.InvoiceStatus,
	) => Promise<void>
	approveInvoice: () => void
	downloadPDF: () => Promise<void>
	isDownloading: boolean
	paymentAllocations: apiTypes.PaymentAllocationResponse[]
	setPaymentAllocations: React.Dispatch<
		React.SetStateAction<apiTypes.PaymentAllocationResponse[]>
	>
	tPrefix: string
}) => {
	const {
		shipmentId,
		brokerInvoice,
		isUpdatingInvoiceStatus,
		updateBrokerInvoiceStatus,
		approveInvoice,
		downloadPDF,
		isDownloading,
		paymentAllocations,
		setPaymentAllocations,
		tPrefix,
	} = props

	const [isLoading, setIsLoading] = useState<boolean>(false)

	const [voidInvoice, setVoidInvoiceModal] = useState<boolean>(false)
	const [invoiceModal, setInvoiceModal] = useState<boolean>(false)

	const createPayment = async (): Promise<void> => {
		setIsLoading(true)

		const paymentRequest: apiTypes.PaymentRequest = {
			paidAmount: l.sum(
				brokerInvoice?.charges.map((charge) => charge.totalPrice * 100),
			),
			checkNumber: '',
			source: 'credit',
			currency: 'USD',
		}

		const paymentResponse = await apiPayment.createPayment(paymentRequest)

		if (paymentResponse.error) {
			sosToast.sendApiErrorResponseToast(paymentResponse)
		} else {
			brokerInvoice?.charges.map(
				async (charge) =>
					await createPaymentAllocation(
						paymentResponse.data.id,
						charge.id,
						charge.totalPrice * 100,
					),
			)
		}
	}

	const createPaymentAllocation = async (
		paymentId: string,
		chargeId: string,
		totalPrice: number,
	): Promise<void> => {
		const paymentAllocationRequest: apiTypes.PaymentAllocationCreateRequest = {
			chargeId: chargeId,
			amount: totalPrice,
			currency: 'USD',
			shipmentId: shipmentId,
			brokerInvoiceId: brokerInvoice?.id,
		}

		const paymentAllocationResponse = await apiPaymentAllocation.createPaymentAllocation(
			paymentId,
			paymentAllocationRequest,
		)

		if (paymentAllocationResponse.error) {
			sosToast.sendApiErrorResponseToast(paymentAllocationResponse)
		} else {
			if (paymentAllocations) {
				paymentAllocations.unshift(paymentAllocationResponse.data)
				setPaymentAllocations(paymentAllocations)
			} else {
				setPaymentAllocations([paymentAllocationResponse.data])
			}

			await updateBrokerInvoiceStatus('paid_in_full')
		}

		setIsLoading(false)
	}

	const deletePayment = async (paymentId: string): Promise<void> => {
		const paymentResponse = await apiPayment.deletePayment(paymentId)

		if (paymentResponse.error) {
			sosToast.sendApiErrorResponseToast(paymentResponse)
		} else {
			setPaymentAllocations(null)

			await updateBrokerInvoiceStatus('invoiced')
		}

		setIsLoading(false)
	}

	const deletePaymentAllocations = async (
		paymentId: string,
		paymentAllocationId: string,
	): Promise<void> => {
		setIsLoading(true)

		const paymentAllocationResponse = await apiPaymentAllocation.deletePaymentAllocation(
			paymentId,
			paymentAllocationId,
		)

		if (paymentAllocationResponse.error) {
			sosToast.sendApiErrorResponseToast(paymentAllocationResponse)
		} else {
			await deletePayment(paymentId)
		}

		setIsLoading(false)
	}

	return (
		<>
			<Col xs={4} className={classes.invoiceStatusButton}>
				{brokerInvoice?.invoiceStatus === 'review' && (
					<React.Fragment>
						<Button
							color={'red'}
							onClick={async () => {
								setVoidInvoiceModal(true)
							}}
							isDisabled={isUpdatingInvoiceStatus}
						>
							{t('voidCustomerInvoice', tPrefix)}
						</Button>
						<Button
							color={'green'}
							onClick={approveInvoice}
							isDisabled={isUpdatingInvoiceStatus}
						>
							{t('approveInvoice', tPrefix)}
						</Button>
					</React.Fragment>
				)}
				{brokerInvoice?.invoiceStatus === 'approved' && (
					<React.Fragment>
						<div className={classes.brokerInvoiceButtons}>
							<Button
								color={'green'}
								onClick={async () => {
									await updateBrokerInvoiceStatus('review')
								}}
								isDisabled={isUpdatingInvoiceStatus}
								isSpinning={isUpdatingInvoiceStatus}
							>
								{t('reviewInvoice', tPrefix)}
							</Button>
						</div>
						<div>
							<Button
								color={'red'}
								onClick={async () => {
									setVoidInvoiceModal(true)
								}}
								isDisabled={isUpdatingInvoiceStatus}
							>
								{t('voidCustomerInvoice', tPrefix)}
							</Button>
						</div>
						<Button
							color={'green'}
							onClick={() => {
								setInvoiceModal(true)
							}}
							isDisabled={isUpdatingInvoiceStatus}
						>
							{t('invoiceCustomer', tPrefix)}
						</Button>
					</React.Fragment>
				)}

				{['invoiced', 'partially_paid'].includes(
					brokerInvoice?.invoiceStatus,
				) && (
					<React.Fragment>
						<Button
							color={'blue'}
							onClick={downloadPDF}
							isDisabled={isLoading}
							isSpinning={isDownloading}
						>
							{t('downloadPDF', tPrefix)}
						</Button>
						<Button
							color={'green'}
							onClick={async () => {
								await createPayment()
							}}
							isSpinning={isLoading}
						>
							{t('applyPayment', tPrefix)}
						</Button>
					</React.Fragment>
				)}
				{brokerInvoice?.invoiceStatus === 'paid_in_full' && (
					<React.Fragment>
						<div className={classes.brokerInvoiceButtons}>
							<Button
								color={'green'}
								onClick={async () => {
									if (!l.isNil(paymentAllocations)) {
										paymentAllocations.map(
											async (paymentAllocation) =>
												await deletePaymentAllocations(
													paymentAllocation?.paymentId,
													paymentAllocation?.id,
												),
										)
									}
								}}
								isSpinning={isLoading}
							>
								{t('adjustPayment', tPrefix)}
							</Button>
						</div>
						<Button
							color={'blue'}
							onClick={downloadPDF}
							isDisabled={isLoading}
							isSpinning={isDownloading}
						>
							{t('downloadPDF', tPrefix)}
						</Button>
					</React.Fragment>
				)}
			</Col>
			{/* Invoice Modal */}
			<Modal
				content={() => (
					<>
						<div className={classes.invoiceCustomerConfirmModal}>
							<div className={classes.warningIcon}>
								<Icon
									icon={solidIcons.faExclamationTriangle}
									className={classes.icon}
									color='yellow'
								/>
								<div className={theme.getTextColor('yellow')}>
									{t('warning', tPrefixInvoiceModal)}
								</div>
							</div>

							<div className={classes.warningMessage}>
								{t('warningMessage', tPrefixInvoiceModal)}
							</div>
						</div>
						<div>
							<Spacer height={'10px'} />
							<AlignRight>
								<OkCancelButtons
									ok={t('confirm', tPrefixInvoiceModal)}
									onOk={async () => {
										await updateBrokerInvoiceStatus('invoiced')

										setInvoiceModal(false)

										await downloadPDF()
									}}
									okColor='green'
									cancel={t('cancel', tPrefixInvoiceModal)}
									onCancel={() => setInvoiceModal(false)}
									isSpinning={isLoading}
									isValid={true}
									okTestId={'invoice-customer-confirm-button'}
									cancelTestId={'invoice-customer-cancel-button'}
								/>
							</AlignRight>
						</div>
					</>
				)}
				isOpen={invoiceModal}
				title={t('invoiceCustomerConfirmModal', tPrefixInvoiceModal)}
			/>
			{/* Void Invoice Modal */}
			<Modal
				content={() => (
					<div data-testid={'void-customer-invoice-modal'}>
						<Center>
							<p>{t('voidCustomerInvoice?', tPrefix)}</p>
						</Center>

						<AlignRight>
							<OkCancelButtons
								isValid={true}
								ok={t('ok', tPrefix)}
								okColor={'green'}
								okTestId={'void-customer-invoice-modal-ok'}
								isSpinning={isLoading}
								onOk={async () => {
									await updateBrokerInvoiceStatus('void')
									setVoidInvoiceModal(false)
								}}
								cancel={t('cancel', tPrefix)}
								onCancel={() => setVoidInvoiceModal(false)}
								cancelTestId={'void-customer-invoice-modal-cancel'}
							/>
						</AlignRight>
					</div>
				)}
				isOpen={voidInvoice}
				title={t('confirmVoidStatus', tPrefix)}
			/>
		</>
	)
}
