import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { Card } from 'ui/components/common/card'
import {
	FormStackedLayout,
	FormStackedSelect,
	FormStackedTextInput,
	FormVerticalCheckbox,
	IFormData,
} from 'ui/components/form'
import { tSignatureType } from 'ui/components/i18n/commonTranslations'
import {
	getCountryNameAndCodeList,
	getRegionsForCountry,
} from 'ui/data/countryAndRegionUtils'
import { l } from 'ui/lib/lodashImports'
import { NewQuoteAccessorialsForm } from '../newQuoteForms'
import * as classes from './NewQuoteAccessorial.module.scss'

const tPrefix = 'page.newQuote.parcel.newQuoteAccessorial'

export const NewQuoteAccessorial: FC = (props: {
	accesorialForm: NewQuoteAccessorialsForm
	setAccessorialsForm: React.Dispatch<
		React.SetStateAction<NewQuoteAccessorialsForm>
	>
	isReadOnly?: boolean
}) => {
	const { accesorialForm, setAccessorialsForm, isReadOnly } = props

	const formData: IFormData<NewQuoteAccessorialsForm> = {
		form: accesorialForm,
		metadata: {
			cod: { readOnly: isReadOnly },
			codAmount: { readOnly: isReadOnly },
			moneyOrderOnly: { readOnly: isReadOnly },
			fedexSmartPost: { readOnly: isReadOnly },
			smartPostHub: { readOnly: isReadOnly },
			holdAtCarrier: { readOnly: isReadOnly },
			holdAtCarrierPhone: { readOnly: isReadOnly },
			holdAtCarrierStreet: { readOnly: isReadOnly },
			holdAtCarrierCity: { readOnly: isReadOnly },
			holdAtCarrierState: {
				options: getRegionsForCountry(
					accesorialForm.holdAtCarrierCountry,
					true,
					true,
				),
				readOnly: isReadOnly,
			},
			holdAtCarrierZip: { readOnly: isReadOnly },
			holdAtCarrierCountry: {
				options: getCountryNameAndCodeList().map((countryData) => {
					return {
						label: countryData.countryName,
						value: countryData.countryCode,
					}
				}),
				readOnly: isReadOnly,
			},
			protectFromFreeze: { readOnly: isReadOnly },
			saturdayDelivery: { readOnly: isReadOnly },
			signatureRequired: { readOnly: isReadOnly },
			signatureType: {
				options: [
					{ label: tSignatureType('direct'), value: 'direct' },
					{ label: tSignatureType('indirect'), value: 'indirect' },
					{ label: tSignatureType('adult'), value: 'adult' },
				],
				readOnly: isReadOnly,
			},
		},

		onUpdateForm: (field: string, value: any) => {
			const updatedFormData = l.cloneDeep(accesorialForm)
			updatedFormData[field] = value
			setAccessorialsForm(updatedFormData)
		},
		tPrefix,
	}

	return (
		<Card
			title={'Accessorials'}
			color={'darkBlue'}
			fillHeight={true}
			testId={'new-quote-parcel-accessorial-card'}
		>
			<div className={classes.newQuoteAccessorial}>
				<FormStackedLayout>
					<FormVerticalCheckbox formData={formData} field={'cod'} />
					{props.accesorialForm.cod && (
						<div className={classes.indentContainer}>
							<FormStackedTextInput
								formData={formData}
								field={'codAmount'}
								hideOptional={true}
							/>
							<FormVerticalCheckbox
								formData={formData}
								field={'moneyOrderOnly'}
							/>
						</div>
					)}
					<FormVerticalCheckbox formData={formData} field={'fedexSmartPost'} />
					{props.accesorialForm.fedexSmartPost && (
						<div className={classes.indentContainer}>
							<FormStackedTextInput
								formData={formData}
								field={'smartPostHub'}
								hideOptional={true}
							/>
						</div>
					)}
					<FormVerticalCheckbox formData={formData} field={'holdAtCarrier'} />
					{props.accesorialForm.holdAtCarrier && (
						<div className={classes.indentContainer}>
							<FormStackedTextInput
								formData={formData}
								field={'holdAtCarrierPhone'}
								hideOptional={true}
							/>
							<FormStackedTextInput
								formData={formData}
								field={'holdAtCarrierStreet'}
								hideOptional={true}
							/>
							<FormStackedTextInput
								formData={formData}
								field={'holdAtCarrierCity'}
								hideOptional={true}
							/>
							<div className={classes.flexContainer}>
								<FormStackedSelect
									formData={formData}
									field={'holdAtCarrierState'}
									numListOptionsBeforeScroll={3}
									hideOptional={true}
								/>
								<FormStackedTextInput
									formData={formData}
									field={'holdAtCarrierZip'}
									hideOptional={true}
								/>
							</div>
							<FormStackedSelect
								formData={formData}
								field={'holdAtCarrierCountry'}
								numListOptionsBeforeScroll={3}
								hideOptional={true}
							/>
						</div>
					)}

					<FormVerticalCheckbox
						formData={formData}
						field={'protectFromFreeze'}
					/>

					<FormVerticalCheckbox
						formData={formData}
						field={'saturdayDelivery'}
					/>

					<FormVerticalCheckbox
						formData={formData}
						field={'signatureRequired'}
					/>
					{props.accesorialForm.signatureRequired && (
						<div className={classes.indentContainer}>
							<FormStackedSelect
								formData={formData}
								field='signatureType'
								hideOptional={true}
							/>
						</div>
					)}
				</FormStackedLayout>
			</div>
		</Card>
	)
}
