import { createLazySos2 } from 'ui/lib/state/sos2/sos2'
import {
	AppointmentFlowFilter,
	AppointmentModeFilter,
} from './sosDockScheduler'
import { DateTime } from 'luxon'
import { apiTypes, apiDockScheduler } from 'ui/api'
import { elasticSearchBuilder } from 'ui/lib/elasticSearch'
import { sosDockScheduler } from '.'
import { fireAndForget } from 'ui/lib/async'
import { l } from 'ui/lib/lodashImports'

const fetchDebounceTime = 1000

const numTypeaheadOptions = 10

const CARD_SEARCH_FIELDS =
	'shipmentInfo.proNumber,stopType,status,trailerInfo.carrier,shipmentInfo.mode,shipmentInfo.equipmentType,shipmentInfo.otherCompanyNamesOnShipment'

const TYPEAHEAD_SEARCH_FIELDS =
	'shipmentInfo.proNumber,trailerInfo.carrier,shipmentInfo.otherCompanyNamesOnShipment,startTime'

const filterToStopTypeMap: {
	[key: string]: apiTypes.AppointmentResponse['stopType']
} = {
	inbound: 'delivery',
	outbound: 'pickup',
}

export interface IStateDockSchedulerCalendarTab {
	appointments: apiTypes.AppointmentResponse[]
	fetchingAppointments: boolean
	dayScrollerStartDate: string // ISODate String
	selectedDate: string // ISODate String
	calendarOpen: boolean
	flowFilter: AppointmentFlowFilter
	modeFilter: AppointmentModeFilter
	typeaheadOptions: apiTypes.AppointmentResponse[]
	highlightedAppointmentId: string
}

export const getSos = createLazySos2<IStateDockSchedulerCalendarTab>(
	'sosDockSchedulerCalendarTab',
	1,
	() => ({
		appointments: {
			default: [],
			localStorage: true,
		},
		fetchingAppointments: {
			default: false,
			localStorage: false,
		},
		dayScrollerStartDate: {
			default: DateTime.local().minus({ days: 3 }).toISODate(),
			localStorage: false,
		},
		selectedDate: {
			default: DateTime.local().toISODate(),
			localStorage: false,
		},
		calendarOpen: { default: false, localStorage: false },
		flowFilter: { default: 'all', localStorage: true },
		modeFilter: { default: 'all', localStorage: true },
		typeaheadOptions: { default: [], localStorage: false },
		highlightedAppointmentId: { default: null, localStorage: false },
	}),
)

export function toggleCalendarShowing(showing: boolean): void {
	getSos().change((ds) => {
		ds.calendarOpen = showing
	})
}

export function updateDayScrollerStartDate(startDate: string): void {
	getSos().change((ds) => {
		ds.dayScrollerStartDate = startDate
	})
}

export function setCalendarDate(
	date: string,
	updateDateScroller: boolean,
): void {
	if (!getSos().getState().fetchingAppointments) {
		getSos().change((ds) => {
			ds.selectedDate = date
			if (updateDateScroller) {
				const threeDaysEarlier = DateTime.fromISO(date).minus({ day: 3 })
				ds.dayScrollerStartDate = threeDaysEarlier.toISODate()
			}
		})
		fireAndForget(
			_fetchCalendarAppointments,
			'fetching appointments for calendar tab after updating calendar date',
		)
	}
}

export function updateCalendarTabFlowFilter(
	filter: AppointmentFlowFilter,
): void {
	if (!getSos().getState().fetchingAppointments) {
		getSos().change((ds) => {
			ds.flowFilter = filter
		})
		fireAndForget(
			_fetchCalendarAppointments,
			'fetching appointments for calendar tab after updating calendar flow',
		)
	}
}

export function updateCalendarTabModeFilter(
	filter: AppointmentModeFilter,
): void {
	if (!getSos().getState().fetchingAppointments) {
		getSos().change((ds) => {
			ds.modeFilter = filter
		})
		fireAndForget(
			_fetchCalendarAppointments,
			'fetching appointments for calendar tab after updating calendar mode',
		)
	}
}

export async function getAppointmentsFromTypeaheadSearchTerm(
	val: string,
): Promise<apiTypes.AppointmentResponse[]> {
	const typeaheadSearchResult = await apiDockScheduler.fetchAppointments(
		() => {},
		{
			take: numTypeaheadOptions,
			skip: 0,
			fields: TYPEAHEAD_SEARCH_FIELDS,
			query: elasticSearchBuilder()
				.and(
					'locationId',
					sosDockScheduler.getSos().getState().currentLocation.id,
				)
				.andRaw(`*${val}*`)
				.toQuery(),
		},
	)
	let typeaheadOptions: apiTypes.AppointmentResponse[]
	if (typeaheadSearchResult.data) {
		typeaheadOptions = typeaheadSearchResult.data.entities
	} else if (typeaheadSearchResult.error) {
		typeaheadOptions = []
	}
	getSos().change((ds) => {
		ds.typeaheadOptions = typeaheadOptions
	})
	return typeaheadOptions
}

export function selectTypeaheadOption(appointmentId: string): void {
	const state = getSos().getState()
	const matchingAppointment: apiTypes.AppointmentResponse = l.find(
		state.typeaheadOptions,
		(appointment) => appointment.id === appointmentId,
	)
	if (matchingAppointment) {
		const appointmentDate = matchingAppointment.startTime.split('T')[0]
		if (appointmentDate !== state.selectedDate) {
			setCalendarDate(appointmentDate, true)
		}
		getSos().change((ds) => {
			ds.highlightedAppointmentId = matchingAppointment.id
		})
	} else {
		getSos().change((ds) => {
			ds.highlightedAppointmentId = null
		})
	}
}

export const _fetchCalendarAppointments = l.debounce(
	fetchCalendarAppointments,
	fetchDebounceTime,
)

async function fetchCalendarAppointments(): Promise<void> {
	const state = getSos().getState()
	const qb = elasticSearchBuilder()

	qb.and('locationId', sosDockScheduler.getSos().getState().currentLocation.id)
	qb.and('startTime', getSos().getState().selectedDate)

	if (state.flowFilter !== 'all') {
		qb.and('stopType', filterToStopTypeMap[state.flowFilter])
	}
	if (state.modeFilter !== 'all') {
		qb.and('shipmentInfo.mode', state.modeFilter)
	}

	const query = qb.toString()

	getSos().change((ds) => {
		ds.fetchingAppointments = true
	})
	let appointments: apiTypes.AppointmentResponse[] = []
	const take = 100
	let appointmentListResponse = await apiDockScheduler.fetchAppointments(
		() => {},
		{
			take,
			skip: 0,
			query,
			fields: CARD_SEARCH_FIELDS,
		},
	)
	if (appointmentListResponse.data) {
		appointments = appointments.concat(appointmentListResponse.data.entities)
		let pageNumber = 1
		while (appointmentListResponse.data.pageCount > pageNumber) {
			appointmentListResponse = await apiDockScheduler.fetchAppointments(
				() => {},
				{
					take,
					skip: pageNumber * take,
					query,
					fields: CARD_SEARCH_FIELDS,
				},
			)
			appointments = appointments.concat(appointmentListResponse.data.entities)
			pageNumber++
		}
	}
	getSos().change((ds) => {
		ds.appointments = appointments
		ds.fetchingAppointments = false
	})
}
