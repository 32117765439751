import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { Card } from 'ui/components/common/card/Card'
import { InfiniteLoader } from 'ui/components/common/loader/InfiniteLoader'
import { Modal, ModalTitle } from 'ui/components/common/modal'
import { SmallButton } from 'ui/components/common/small-button'
import { ValidatedSubmitButton } from 'ui/components/common/validated-submit-button/ValidatedSubmitButton'
import { t, tString } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight'
import { Spacer } from 'ui/components/layout/spacer/Spacer'
import { FileDropZone } from 'ui/components/shared/csvValidator/file-drop-zone/FileDropZone'
import { MessagesList } from 'ui/components/shared/csvValidator/messages-list'
import { RawDataTable } from 'ui/components/table/raw-data-table/RawDataTable'
import { l } from 'ui/lib/lodashImports'
import { sos2 } from 'ui/lib/state/sos2'
import { sosRfpDataUpload } from 'ui/state'
import {
	optionalColumnsRfp,
	requiredColumnsRfp,
} from 'ui/state/sosRfpDataUpload'
import * as classes from './RfpDataUpload.module.scss'
import { RfpLayout } from './RfpLayout'
import { RfpLoadingBar } from './RfpLoadingBar'

const maxRows = 10

const RfpDataUpload: FC = (props: {}) => {
	const stateRfp = sos2.useSubscription(sosRfpDataUpload.getSos())
	const { previewTableState } = stateRfp

	const uploadShipments = async (): Promise<{
		ids: string[]
		errors: string[]
	}> => {
		return sosRfpDataUpload.manageUpload(stateRfp.previewTableState.rawRecords)
	}

	const message =
		(stateRfp.isUploadingShipments &&
			t('page.compareRfpContracts.uploadingMessage')) ||
		t('page.compareRfpContracts.ratingMessage')

	return (
		<RfpLayout>
			<SmallButton
				onClick={sosRfpDataUpload.downloadTemplateUpload}
				color='blue'
				className={classes.noLeftMargin}
			>
				{t('page.compareRfpContracts.downloadTemplate')}
			</SmallButton>
			<InfiniteLoader isLoading={previewTableState.isLoading} />
			<div className={classes.fileUploadContainer}>
				<div
					className={
						l.isEmpty(previewTableState.records)
							? classes.fileUploadExpanded
							: classes.fileUploadContracted
					}
				>
					<FileDropZone
						onLoaded={async (fileContent) => {
							await sosRfpDataUpload.processCsv(
								fileContent,
								requiredColumnsRfp,
								optionalColumnsRfp,
							)
						}}
						preDropText={t('page.csvValidator.preDropFile')}
						droppingText={t('page.csvValidator.droppingFile')}
						testId='rfp-file-drop'
						fileEncoding='text'
					/>
				</div>
			</div>
			{!l.isEmpty(previewTableState.records) && (
				<div>
					<Card
						title={
							tString('page.compareRfpContracts.previewOfFirstTenLines') +
							` (${stateRfp.previewTableState.rawRecords.length} ${tString(
								'page.compareRfpContracts.total',
							)})`
						}
						color='gray'
						noPadding={true}
					>
						<RawDataTable
							state={previewTableState}
							inset={true}
							maxRows={maxRows}
						/>
					</Card>
					{!l.isEmpty(previewTableState.errors) && (
						<div>
							<Spacer />
							<Card
								title={t('page.compareRfpContracts.errors')}
								color='red'
								noPadding={true}
								fullWidth={false}
								testId='rfpUploadErrors'
							>
								<MessagesList
									messages={previewTableState.errors}
									includeTitle={false}
								/>
							</Card>
						</div>
					)}
					{!l.isEmpty(previewTableState.warnings) && (
						<div>
							<Spacer />
							<Card
								title={t('page.compareRfpContracts.warnings')}
								color='orange'
								noPadding={true}
								fullWidth={false}
							>
								<MessagesList
									messages={previewTableState.warnings}
									includeTitle={false}
								/>
							</Card>
						</div>
					)}

					<Modal
						title='RFP Data Upload'
						content={() => (
							<div>
								<ModalTitle message={message} />
								<RfpLoadingBar />
								<Spacer />
								<SmallButton
									onClick={sosRfpDataUpload.cancelProcess}
									color='blue'
								>
									{t('page.compareRfpContracts.cancel')}
								</SmallButton>
							</div>
						)}
						isOpen={stateRfp.isRatingShipments || stateRfp.isUploadingShipments}
						onModalClose={() => {}}
					/>

					<AlignRight>
						<ValidatedSubmitButton
							onClick={async () => {
								await uploadShipments()
							}}
							isValid={l.isEmpty(previewTableState.errors)}
							testId='upload-shipments'
							className={classes.uploadShipmentsButton}
							validColor='green'
						>
							{t('page.compareRfpContracts.uploadShipments')}
						</ValidatedSubmitButton>
					</AlignRight>
				</div>
			)}
		</RfpLayout>
	)
}

export { RfpDataUpload }
