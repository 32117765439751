import { apiCommon } from 'ui/api'
import { UpdateMode } from 'ui/api/apiCommon'
import { IRequestState } from 'ui/api/requestState'
import { IPackageCatalogEntry } from 'ui/lib/books'
import { IPackageCatalog } from '../lib/books/IPackageCatalog'

export async function getPackageCatalogs(
	onProgress: (rs: IRequestState<IPackageCatalog[]>) => void,
): Promise<IRequestState<IPackageCatalog[]>> {
	return await apiCommon.apiFetch(onProgress, {}, 'books/package')
}

export async function getPackageCatalog(
	bookId: string,
	onProgress: (rs: IRequestState<IPackageCatalog>) => void,
): Promise<IRequestState<IPackageCatalog>> {
	const result = await apiCommon.apiFetch(
		onProgress,
		{},
		`books/package/${bookId}`,
	)
	return result
}

export async function updatePackageCatalog(
	mode: UpdateMode,
	data: Partial<IPackageCatalog>,
	onProgress: (rs: IRequestState<IPackageCatalog>) => void,
): Promise<IRequestState<IPackageCatalog>> {
	let url = 'books/package'
	if (data.id) {
		url += `/${data.id}`
	}
	const result = await apiCommon.apiFetch(
		onProgress,
		{
			method: apiCommon.modeToMethod(mode, data),
			data,
		},
		url,
	)
	return result
}

export async function getPackageCatalogEntries(
	bookId: string,
	onProgress: (rs: IRequestState<IPackageCatalogEntry[]>) => void,
): Promise<IRequestState<IPackageCatalogEntry[]>> {
	const result = await apiCommon.apiFetch(
		onProgress,
		{},
		`books/package/${bookId}/pages`,
	)
	return result
}

export async function updatePackageCatalogEntry(
	mode: UpdateMode,
	bookId: string,
	data: Partial<IPackageCatalogEntry>,
	onProgress: (rs: IRequestState<IPackageCatalogEntry>) => void,
): Promise<IRequestState<IPackageCatalogEntry>> {
	let url = `books/package/${bookId}/pages`
	if (data.id) {
		url += `/${data.id}`
	}
	const result = await apiCommon.apiFetch(
		onProgress,
		{
			method: apiCommon.modeToMethod(mode, data),
			data,
		},
		url,
	)
	return result
}
