import { apiPackageCatalog } from 'ui/api'
import { UpdateMode } from 'ui/api/apiCommon'
import { IRequestState } from 'ui/api/requestState'
import { dataUtils } from 'ui/components/table/'
import {
	createDefaultDataTableState,
	IDataTableState,
} from 'ui/components/table/IDataTableState'
import { formUtils } from 'ui/forms'
import {
	IPackageCatalogForm,
	packageCatalogFormMetadata,
} from 'ui/forms/formPackageCatalog'
import { IPackageCatalog } from 'ui/lib/books'
import { IPackageCatalogEntry } from 'ui/lib/books/IPackageCatalogEntry'
import { log } from 'ui/lib/log'
import { createSos } from './secretaryOfState'

export interface IStatePackageCatalog {
	dataTablePackageCatalogs: IDataTableState<IPackageCatalog>
	dataPackageCatalogs: IPackageCatalog[]
	formPackageCatalog: IPackageCatalogForm
	requestGetPackageCatalogs: IRequestState<IPackageCatalog[]>
	requestGetPackageCatalogEntries: IRequestState<IPackageCatalogEntry[]>
	requestAddEditPackageCatalog: IRequestState<IPackageCatalog>
}

const initialState: IStatePackageCatalog = {
	dataTablePackageCatalogs: createDefaultDataTableState({
		sortOn: 'name',
		hiddenHeaders: ['id', 'pageType'],
	}),
	dataPackageCatalogs: [],
	formPackageCatalog: formUtils.createDefaultFormStringData(
		packageCatalogFormMetadata,
	),
	requestGetPackageCatalogs: {},
	requestGetPackageCatalogEntries: {},
	requestAddEditPackageCatalog: {},
}

const { stateManager, useSubscribe } = createSos(
	'package-catalogs',
	'1.0.1',
	initialState,
	{
		useLocalStorage: true,
	},
)
export { useSubscribe }

export async function getBooks(): Promise<void> {
	await apiPackageCatalog.getPackageCatalogs((rs) => {
		stateManager.produce((ds) => {
			ds.requestGetPackageCatalogs = rs
			if (rs.data) {
				ds.dataPackageCatalogs = rs.data
				dataUtils.initialLoad(
					ds.dataTablePackageCatalogs,
					ds.dataPackageCatalogs,
				)
			}
		})
	})
}

export function booksTableSort(sortOn: string): void {
	stateManager.produce((ds) => {
		dataUtils.sort(ds.dataTablePackageCatalogs, ds.dataPackageCatalogs, sortOn)
	})
}
export function booksTableToggleHeader(field: string): void {
	stateManager.produce((ds) => {
		dataUtils.toggleHeader(ds.dataTablePackageCatalogs, field)
	})
}
export function booksTableSetUpdateMode(
	mode: UpdateMode,
	row: IPackageCatalog = null,
): void {
	stateManager.produce((ds) => {
		dataUtils.toggleUpdateMode(ds.dataTablePackageCatalogs, mode, row)
		if (ds.dataTablePackageCatalogs.isAdding) {
			ds.formPackageCatalog = formUtils.createDefaultFormStringData(
				packageCatalogFormMetadata,
			)
		} else if (ds.dataTablePackageCatalogs.editRow) {
			ds.formPackageCatalog = formUtils.apiDataToFormStringData(
				packageCatalogFormMetadata,
				row,
			)
		}
	})
}

export function updateBookForm(
	key: keyof IPackageCatalogForm,
	newVal: string,
): void {
	stateManager.produce((ds) => {
		ds.formPackageCatalog[key] = newVal
	})
}

export async function updateBook(mode: UpdateMode): Promise<void> {
	log('package-catalog', 'status', 'updating')
	const state = stateManager.getState()
	const apiData = formUtils.formStringDataToApiData(
		packageCatalogFormMetadata,
		state.dataTablePackageCatalogs.editRow,
		state.formPackageCatalog,
	)
	const result = await apiPackageCatalog.updatePackageCatalog(
		mode,
		apiData,
		(rs) => {
			stateManager.produce((ds) => {
				ds.requestAddEditPackageCatalog = rs
			})
		},
	)

	if (result.data) {
		stateManager.produce((ds) => {
			dataUtils.updateItem(
				ds.dataTablePackageCatalogs,
				ds.dataPackageCatalogs,
				mode,
				result.data,
				ds.formPackageCatalog.id,
			)
		})
		booksTableSetUpdateMode('none')
	}
	if (result.error) {
		stateManager.produce((ds) => {
			dataUtils.setError(ds.dataTablePackageCatalogs, result.error)
		})
	}
}
