import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { Button } from 'ui/components/common/button/Button'
import {
	ConfirmButton,
	ConfirmDelete,
} from 'ui/components/common/confirm-button'
import { Icon, regularIcons } from 'ui/components/common/icon'
import { OkCancelButtons } from 'ui/components/common/okCancelButtons'
import { Radio, Radios } from 'ui/components/common/radioButton'
import { Separator } from 'ui/components/common/separator'
import { SmallButton } from 'ui/components/common/small-button'
import { ValidatedSubmitButton } from 'ui/components/common/validated-submit-button/ValidatedSubmitButton'
import { StackedItem } from 'ui/components/form/StackedItem'
import { t } from 'ui/components/i18n/i18n'
import { FlexItem, FlexRow } from 'ui/components/layout/flexRow'
import { Spacer } from 'ui/components/layout/spacer'
import { l } from 'ui/lib/lodashImports'
import { sosComponentExamples } from 'ui/state/'
import { Colors } from 'ui/theme/theme'
import { Section } from './Section'

const colorOptions: Colors[] = [
	'blue',
	'gray',
	'green',
	'red',
	'orange',
	'teal',
	'lightGray',
]

export const SectionButtons: FC = (props: {
	stateComponentExamples: sosComponentExamples.IStateComponentExamples
}) => {
	const state = props.stateComponentExamples

	const [radio1, setRadio1] = React.useState('1')
	const [radio2, setRadio2] = React.useState('A')
	const [button1Errors, setButton1Errors] = React.useState(null)
	const [button2Errors, setButton2Errors] = React.useState([])

	return (
		<Section
			title={t('page.componentExamples.buttons')}
			stateComponentExamples={props.stateComponentExamples}
			name='buttons'
		>
			<FlexRow>
				{l.map(colorOptions, (c, cIdx) => (
					<FlexItem key={cIdx}>
						<Button color={c}>color: {c}</Button>
						<pre>{`<Button color='${c}'>color: ${c}</Button>`}</pre>
						<Spacer />
						<SmallButton color={c}>color: {c}</SmallButton>
						<pre>{`<SmallButton color='${c}'>color: ${c}</SmallButton>`}</pre>
						<div className='bootstrap-wrapper'>
							<Button color={c} bootstrapStyles={true}>
								{c} bootstrap button
							</Button>
						</div>
						<pre>{`<Button color='${c}' bootstrapStyles={true}>\n\t${c} bootstrap button\n</Button>`}</pre>
						<Spacer />
						<div className='bootstrap-wrapper'>
							<SmallButton color={c} bootstrapStyles={true}>
								{c} bootstrap button
							</SmallButton>
						</div>
						<pre>{`<SmallButton color='${c}' bootstrapStyles={true}>\n\t${c} bootstrap button\n</SmallButton>`}</pre>
					</FlexItem>
				))}
			</FlexRow>

			<Separator />

			<FlexRow>
				<StackedItem label='Confirm Button' hideOptional={true}>
					<ConfirmButton color='gray' onConfirm={() => {}}>
						Confirmable <Icon icon={regularIcons.faCalendarAlt} />
					</ConfirmButton>
					<pre>{`<ConfirmButton color='gray' onConfirm={() => {}}>\n\tConfirmable <Icon icon={regularIcons.faCalendarAlt} />\n</ConfirmButton>`}</pre>
				</StackedItem>
				<StackedItem label='Confirm Delete Button' hideOptional={true}>
					<ConfirmDelete onConfirm={() => {}} />
					<pre>{`<ConfirmDelete onConfirm={() => {}} />`}</pre>
				</StackedItem>
			</FlexRow>

			<Separator />

			<FlexRow>
				<StackedItem label='Spinny Button' hideOptional={true}>
					<Button isSpinning={true}>Spinny button</Button>
					<pre>{`<Button isSpinning={true}>Spinny button</Button>`}</pre>
				</StackedItem>
				<Spacer />
				<StackedItem label='Small Spinny Button' hideOptional={true}>
					<SmallButton isSpinning={true}>Small spinny button</SmallButton>
					<pre>{`<SmallButton isSpinning={true}>Small spinny button</SmallButton>`}</pre>
				</StackedItem>
			</FlexRow>

			<Separator />

			<div>
				<StackedItem label='Spinning OkCancelButtons' hideOptional={true}>
					<OkCancelButtons
						isSpinning={true}
						onOk={() => {}}
						onCancel={() => {}}
						isValid={true}
					/>
					<pre>
						{
							'<OkCancelButtons\n\tisSpinning={true}\n\tonOk={() => {}}\n\tonCancel={() => {}}\n\tisValid={true}\n/>'
						}
					</pre>
				</StackedItem>
				<StackedItem label='OkCancelButtons with onOk' hideOptional={true}>
					<OkCancelButtons onOk={() => {}} isValid={true} />
					<pre>{'<OkCancelButtons onOk={() => {}} isValid={true} />'}</pre>
				</StackedItem>
				<StackedItem label='OkCancelButtons with onCancel' hideOptional={true}>
					<OkCancelButtons onCancel={() => {}} isValid={true} />
					<pre>{'<OkCancelButtons onCancel={() => {}} isValid={true} />'}</pre>
				</StackedItem>
				<StackedItem
					label='OkCancelButtons with onOk and onCancel'
					hideOptional={true}
				>
					<OkCancelButtons onOk={() => {}} onCancel={() => {}} isValid={true} />
					<pre>
						{
							'<OkCancelButtons onOk={() => {}} onCancel={() => {}} isValid={true} />'
						}
					</pre>
				</StackedItem>
				<StackedItem label='Click to set single error' hideOptional={true}>
					<OkCancelButtons
						onOk={() => setButton1Errors('a single error')}
						onCancel={() => {}}
						errors={button1Errors}
						isValid={true}
					/>
					<pre>
						{
							"<OkCancelButtons\n\tonOk={() => setButton1Errors(['a single error'])}\n\tonCancel={() => {}}\n\terrors={button1Errors}\n\tisValid={true}\n/>"
						}
					</pre>
				</StackedItem>
				<StackedItem
					label='Click to set multiple errors with inline errors'
					hideOptional={true}
				>
					<OkCancelButtons
						onOk={() => setButton2Errors(['multiple', 'errors', 'supported'])}
						onCancel={() => {}}
						errors={button2Errors}
						isValid={true}
						inlineErrors={true}
					/>
					<pre>
						{
							"<OkCancelButtons\n\tonOk={() => setButton2Errors(['multiple', 'errors', 'supported'])}\n\tonCancel={() => {}}\n\terrors={button2Errors}\n\tisValid={true}\n/>"
						}
					</pre>
				</StackedItem>
				<Separator />

				<StackedItem label='Horizontal Radio Buttons' hideOptional={true}>
					<Radios orientation='horizontal'>
						<Radio
							onSelected={() => {
								setRadio1('1')
							}}
							value={radio1 === '1'}
						>
							Radio 1
						</Radio>
						<Radio
							onSelected={() => {
								setRadio1('2')
							}}
							value={radio1 === '2'}
						>
							Radio 2
						</Radio>
						<Radio
							onSelected={() => {
								setRadio1('3')
							}}
							value={radio1 === '3'}
						>
							Radio 3
						</Radio>
					</Radios>
				</StackedItem>
				<StackedItem label='Vertical Radio Buttons' hideOptional={true}>
					<Radios orientation='vertical'>
						<Radio
							onSelected={() => {
								setRadio2('A')
							}}
							value={radio2 === 'A'}
						>
							Radio A
						</Radio>
						<Radio
							onSelected={() => {
								setRadio2('B')
							}}
							value={radio2 === 'B'}
						>
							Radio B
						</Radio>
						<Radio
							onSelected={() => {
								setRadio2('C')
							}}
							value={radio2 === 'C'}
						>
							Radio C
						</Radio>
					</Radios>
				</StackedItem>

				<Separator />

				<div>
					validationButtonIsValid:{' '}
					{state.validationButtonIsValid ? 'true' : 'false'}
				</div>
				<Spacer />
				<FlexRow>
					<ValidatedSubmitButton
						validColor='green'
						isValid={state.validationButtonIsValid}
						onClick={() =>
							sosComponentExamples.setState({
								validationButtonIsValid: !state.validationButtonIsValid,
							})
						}
					>
						Validated Submit Green
					</ValidatedSubmitButton>
					<ValidatedSubmitButton
						validColor='blue'
						isValid={state.validationButtonIsValid}
						smallButton={true}
						onClick={() =>
							sosComponentExamples.setState({
								validationButtonIsValid: !state.validationButtonIsValid,
							})
						}
					>
						Validated Submit Blue SmallButton
					</ValidatedSubmitButton>
				</FlexRow>
			</div>
		</Section>
	)
}
