import { apiTypes } from 'ui/api'
import * as classes from '../DockScheduler.module.scss'

export function appointmentStatusToColor(
	status: apiTypes.AppointmentResponse['status'],
): string {
	switch (status) {
		case 'not-arrived':
			return classes.notArrivedAppointment
		case 'arrived':
			return classes.arrivedAppointment
		case 'loading':
			return classes.loadingAppointment
		case 'unloading':
			return classes.unloadingAppointment
		case 'loaded':
			return classes.loadedAppointment
		case 'unloaded':
			return classes.unloadedAppointment
		case 'departed':
			return classes.departedAppointment
	}
}
