import { FC } from 'app/FunctionalComponent'
import React, { ReactNode } from 'react'
import { Colors } from 'ui/theme/theme'
import { ValidatedSubmitButton } from '../common/validated-submit-button'
import * as formVerticalClasses from './FormVertical.module.scss'
import * as formVerticalButtonClasses from './FormVerticalButton.module.scss'

export const FormVerticalButton: FC = (props: {
	onClick: () => void
	children: ReactNode
	color?: Colors
	isValid?: boolean
}) => {
	return (
		<div className={formVerticalClasses.formRow}>
			<div className={formVerticalClasses.td}>{''}</div>
			<div
				className={`${formVerticalClasses.td} ${formVerticalButtonClasses.right}`}
			>
				<ValidatedSubmitButton
					onClick={props.onClick}
					validColor={props.color}
					isValid={props.isValid}
					smallButton={true}
				>
					{props.children}
				</ValidatedSubmitButton>
			</div>
		</div>
	)
}
