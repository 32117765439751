import { IRfp } from 'ui/lib/books/IRfp'
import { ICarrier } from 'ui/lib/contracts/ICarrier'
import { _idx } from 'ui/lib/_idx'
import { formatContractName } from './formatContractName'
import { getLowestRate } from './getLowestRate'

export async function parseRfps(
	rawData,
	compareContractA: string,
	compareContractB: string,
	currentPage: number,
	carriers: ICarrier[],
	pageSize: number,
): Promise<IRfp[]> {
	const carrierA =
		compareContractA === ''
			? carriers[0]
			: carriers.find((carrier) =>
					carrier.contracts.find(
						(contract) => contract.name === compareContractA,
					),
			  )
	const carrierB =
		compareContractB === ''
			? carriers[1]
			: carriers.find((carrier) =>
					carrier.contracts.find(
						(contract) => contract.name === compareContractB,
					),
			  )

	const contractA =
		carrierA?.contracts.find(
			(contract) => contract.name === compareContractA,
		) || carrierA?.contracts[0]
	const contractB =
		carrierB?.contracts.find(
			(contract) => contract.name === compareContractB,
		) || carrierB?.contracts[0]
	const rawDataPage = rawData.slice(
		pageSize * currentPage,
		pageSize * (currentPage + 1),
	)
	const rfpRows = rawDataPage.map((shipment) => {
		const bestRate = getLowestRate(shipment, carriers)
		const rateA = shipment.rates.find(
			(rate) => formatContractName(rate) === contractA?.name,
		)
		const rateB = shipment.rates.find(
			(rate) => formatContractName(rate) === contractB?.name,
		)
		return {
			// idx used for cyclomatic complexity reasons
			shipmentNumber: _idx(() => shipment.identifier),
			pickupDate: shipment.pickupDate,
			carrier: _idx(() => shipment.bookedRate.providerName),
			weight: shipment.totalWeight?.toString(),
			class: _idx(() => shipment.payloads[0].containers[0].class),
			originZip: shipment.origin.zip,
			destinationZip: shipment.destination.zip,
			oldCharge: _idx(() => shipment.bookedRate.costTotal.toString()),

			carrierBest: _idx(() => bestRate.carrier || bestRate.providerName),
			carrierBaseChargeBest: _idx(() => bestRate.costBreakdown.grossRate),
			discountBest: _idx(() => bestRate.costBreakdown.discount),
			fuelBest: _idx(() => bestRate.costBreakdown.fuel),
			accessorialCostBest: _idx(() => bestRate.accessorialCostTotal),
			netChargeBest: _idx(() => bestRate.costTotal),

			carrierBaseChargeA: _idx(() => rateA.costBreakdown.grossRate),
			discountA: _idx(() => rateA.costBreakdown.discount),
			fuelA: _idx(() => rateA.costBreakdown.fuel),
			accessorialCostA: _idx(() => rateA.accessorialCostTotal),
			netChargeA: _idx(() => rateA.costTotal),

			carrierBaseChargeB: _idx(() => rateB.costBreakdown.grossRate),
			discountB: _idx(() => rateB.costBreakdown.discount),
			fuelB: _idx(() => rateB.costBreakdown.fuel),
			accessorialCostB: _idx(() => rateB.accessorialCostTotal),
			netChargeB: _idx(() => rateB.costTotal),
		}
	})
	return rfpRows
}
