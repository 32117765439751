import { FC } from 'app/FunctionalComponent'
import { AsyncTypeahead, TypeaheadOption } from 'common/components/typeahead'
import React, { useState } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { apiRfp, apiTypes } from 'ui/api'
import { IRequestState } from 'ui/api/requestState'
import { Button } from 'ui/components/common/button'
import { Card } from 'ui/components/common/card/Card'
import { Loader } from 'ui/components/common/loader'
import { t, tString } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight'
import { RequiresFeatureToggle } from 'ui/components/permissions'
import { fireAndForget } from 'ui/lib/async'
import { JsonBlob } from 'ui/pages/api-docs'
import { tPrefixRfpAnalysis } from 'ui/pages/rfp/RfpAnalysisPage'
import classes from 'ui/pages/rfp/rfpDevTools/RfpDevTools.module.scss'
import { sosUser } from 'ui/state'
import { sosRfp } from '..'

export const RfpDevTools: FC = (props: {}) => {
	const { rfpId, rfpProjectId } = sosRfp.getRfpUrlState()

	const [rfpShipmentSelected, setRfpShipmentSelected] = useState<
		TypeaheadOption
	>(null)
	const [rfpShipmentResponse, setRfpShipmentResponse] = useState<
		apiTypes.RfpShipmentResponse
	>(null)

	const [isReratingShipment, setIsReratingShipment] = useState<boolean>(false)
	const [isFetchingShipment, setIsFetchingShipment] = useState<boolean>(false)

	const fetchAndDisplayRfpShipment = (selected: TypeaheadOption): void => {
		setIsFetchingShipment(true)
		setRfpShipmentSelected(selected)
		fireAndForget(async () => {
			const query = `id:${selected.value}`
			const response: IRequestState<apiTypes.RfpShipmentListResponse> = await apiRfp.getRfpShipments(
				() => {},
				{
					take: 1,
					skip: 0,
					query,
				},
				true,
			)

			if (response.data?.entities[0]) {
				setRfpShipmentResponse(response.data?.entities[0])
			}
			setIsFetchingShipment(false)
		}, 'Fetch and display RFP shipment')
	}

	const reRateShipment = (shipmentId: string): void => {
		setIsReratingShipment(true)
		fireAndForget(async () => {
			const ratingResponse = await apiRfp.rateRfpShipments(
				[shipmentId],
				() => {},
				true,
			)

			if (ratingResponse.data?.entities[0]) {
				setRfpShipmentResponse(ratingResponse.data?.entities[0])
			}
			setIsReratingShipment(false)
		}, 'Rerate RFP shipment')
	}

	return (
		<RequiresFeatureToggle featureToggle='rfp-dev-tools'>
			<Card
				title={t('rfpDevTools', tPrefixRfpAnalysis)}
				color='gray'
				isOpen={true}
				onToggle={() => {
					sosUser.toggleShowDevTools()
				}}
			>
				<div className={classes.devToolsContents}>
					<Row>
						<Col>
							<AsyncTypeahead
								testId='rfpShipmentSearch'
								size='small'
								width='300px'
								options={[]}
								onChange={(selected: TypeaheadOption) => {
									fetchAndDisplayRfpShipment(selected)
								}}
								onSearch={async (shipmentNumber: string) => {
									const query = `rfpId:${rfpId} AND rfpProjectId:${rfpProjectId} AND identifier:*${shipmentNumber}*`
									const response: IRequestState<apiTypes.RfpShipmentListResponse> = await apiRfp.getRfpShipments(
										() => {},
										{
											take: 10,
											skip: 0,
											query,
										},
										true,
									)

									let responseOptions: TypeaheadOption[] = []
									if (response.data) {
										responseOptions = response.data.entities.map(
											(rfpShipment) => ({
												value: rfpShipment.id,
												label: rfpShipment.identifier || rfpShipment.id,
											}),
										)
									}

									return responseOptions
								}}
								placeholder={tString('shipmentNumber', tPrefixRfpAnalysis)}
								useCache={false}
								value={rfpShipmentSelected}
								disabled={isReratingShipment || isFetchingShipment}
							/>
						</Col>
						<Col>
							<AlignRight>
								<Button
									onClick={() => reRateShipment(rfpShipmentSelected.value)}
									isDisabled={!rfpShipmentSelected || isFetchingShipment}
									color='blue'
									isSpinning={isReratingShipment}
								>
									{t('reRateShipment', tPrefixRfpAnalysis)}
								</Button>
							</AlignRight>
						</Col>
					</Row>
					<Loader isLoading={isFetchingShipment} />
					{rfpShipmentResponse && <JsonBlob json={rfpShipmentResponse} />}
				</div>
			</Card>
		</RequiresFeatureToggle>
	)
}
