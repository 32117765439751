import React from 'react'
import { t } from 'ui/components/i18n/i18n'
import { FC } from 'ui/FunctionalComponent'
import { sosCustomerManagement } from '../..'
import { Form } from 'react-bootstrap'
import { CustomerDetailsPictureUpload } from '../customer-details-picture-upload/CustomerDetailsPictureUpload'
import { FileDropZoneData } from 'ui/components/shared/csvValidator/file-drop-zone'

export const CustomerDetailsBrandingLogo: FC = (props: {
	useCustomerInfoForBOL: boolean
	brandingImageUrl: string
	brandingPictureData: FileDropZoneData
	tPrefix: string
}) => {
	const tPrefixCustomerDetailsBrandingLogo =
		props.tPrefix + '.customerDetailsBrandingLogo'

	const handleChange = async (
		e: React.FormEvent<HTMLInputElement>,
	): Promise<void> => {
		const value = e.currentTarget.checked

		sosCustomerManagement.updateUseClientInfoForBolHeader(value)
	}

	return (
		<div data-testid='customer-details-branding-logo'>
			<Form.Group controlId='useCustomerInfoForBOLHeader'>
				<Form.Check
					name='useCustomerInfoForBOLHeader'
					label={t(
						'useCustomerInfoForBOLHeader',
						tPrefixCustomerDetailsBrandingLogo,
					)}
					type='checkbox'
					id='checkbox1'
					checked={props.useCustomerInfoForBOL}
					onChange={handleChange}
				/>
			</Form.Group>

			<CustomerDetailsPictureUpload
				existingImageUrl={props.brandingImageUrl}
				headerText={t('clientLogo', tPrefixCustomerDetailsBrandingLogo)}
				brandingPictureData={props.brandingPictureData}
				tPrefix={tPrefixCustomerDetailsBrandingLogo}
			/>
		</div>
	)
}
