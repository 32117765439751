import React, { useState } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { TitledBorderBox } from 'ui/components/common/titled-border-box'
import { t, tString } from 'ui/components/i18n/i18n'
import { Spacer } from 'ui/components/layout/spacer'
import { FC } from 'ui/FunctionalComponent'
import { l } from 'ui/lib/lodashImports'
import { toDollars } from 'ui/lib/numbers/toNumber'
import * as classes from './CreditLimit.module.scss'
import { sosCustomerManagement } from 'ui/pages/customer-management'

const tPrefix = 'page.customerManagement.creditLimit'

const currencies = ['USD']
export interface ICreditLimitForm {
	creditLimit: number
	creditLimitCurrency: string
	totalBalance: number
	invoicedBalance: number
	inTransitBalance: number
	creditUtilization: number
	canExceedCreditLimit: boolean
	emailRecipients: string
	emailWhenBeyondWarningLimit: boolean
	warningThreshold: number
	creditLimitEnabled: boolean
}

export const CreditLimit: FC = (props: {
	creditLimitForm: ICreditLimitForm
}) => {
	const [creditLimitForm, setCreditLimitForm] = useState<ICreditLimitForm>(
		props.creditLimitForm,
	)

	const handleChange = (e: React.FormEvent<HTMLInputElement>): void => {
		const name = e.currentTarget.name
		const value =
			e.currentTarget.type === 'checkbox'
				? e.currentTarget.checked
				: e.currentTarget.value

		sosCustomerManagement.updateCreditLimitForm(
			name as keyof ICreditLimitForm,
			value,
		)

		if (name === 'canExceedCreditLimit') {
			const newValue = value === '0' ? false : true

			setCreditLimitForm({
				...creditLimitForm,
				canExceedCreditLimit: newValue,
			})
		} else if (name === 'creditLimit') {
			const creditLimitReal = parseFloat(value as string)
			const creditUtilizationReal = props.creditLimitForm.creditUtilization
			setCreditLimitForm({
				...creditLimitForm,
				creditLimit: creditLimitReal,
				creditUtilization: creditUtilizationReal,
			})
		} else {
			setCreditLimitForm({
				...creditLimitForm,
				[name]: value,
			})
		}
	}

	return (
		<div className='bootstrap-wrapper' data-testid='accounting-credit-limit'>
			<Row>
				<Col xs={12} md={6}>
					<Row>
						<Col xs={7}>
							<Form.Group controlId='creditLimit'>
								<Form.Label>{t('creditLimit', tPrefix)}</Form.Label>
								<Form.Control
									name='creditLimit'
									type='number'
									onChange={handleChange}
									value={creditLimitForm.creditLimit}
									data-testid='creditLimitForm-creditLimit'
								/>
							</Form.Group>
						</Col>
						<Col xs={5}>
							<Spacer height='13px' />
							<Form.Control
								name='creditLimitCurrency'
								as='select'
								custom={true}
								onChange={handleChange}
								value={`$ ${creditLimitForm.creditLimitCurrency}`}
								data-testid='creditLimitForm-creditLimitCurrency'
							>
								{l.map(currencies, (currency, currencyIdx) => (
									<option key={currencyIdx}>{currency}</option>
								))}
							</Form.Control>
						</Col>
					</Row>
					<Form.Group controlId='totalBalance'>
						<Form.Label>{t('totalBalance', tPrefix)}</Form.Label>
						<InputGroup>
							<InputGroup.Prepend>
								<InputGroup.Text>$</InputGroup.Text>
							</InputGroup.Prepend>
							<Form.Control
								name='totalBalance'
								type='text'
								onChange={handleChange}
								value={toDollars(creditLimitForm.totalBalance).toFixed(2)}
								data-testid='creditLimitForm-totalBalance'
								disabled={true}
							/>
						</InputGroup>
					</Form.Group>
					<Form.Group controlId='invoicedBalance'>
						<Form.Label>{t('invoicedBalance', tPrefix)}</Form.Label>
						<InputGroup>
							<InputGroup.Prepend>
								<InputGroup.Text>$</InputGroup.Text>
							</InputGroup.Prepend>
							<Form.Control
								name='invoicedBalance'
								type='text'
								onChange={handleChange}
								value={toDollars(creditLimitForm.invoicedBalance).toFixed(2)}
								data-testid='creditLimitForm-invoicedBalance'
								disabled={true}
							/>
						</InputGroup>
					</Form.Group>
					<Form.Group controlId='inTransitBalance'>
						<Form.Label>{t('inTransitBalance', tPrefix)}</Form.Label>
						<InputGroup>
							<InputGroup.Prepend>
								<InputGroup.Text>$</InputGroup.Text>
							</InputGroup.Prepend>
							<Form.Control
								name='inTransitBalance'
								type='text'
								onChange={handleChange}
								value={toDollars(creditLimitForm.inTransitBalance).toFixed(2)}
								data-testid='creditLimitForm-inTransitBalance'
								disabled={true}
							/>
						</InputGroup>
					</Form.Group>
					<Form.Group controlId='creditUtilization'>
						<Form.Label>{t('creditUtilization', tPrefix)}</Form.Label>
						<InputGroup>
							<Form.Control
								name='creditUtilization'
								type='text'
								onChange={handleChange}
								value={props.creditLimitForm.creditUtilization}
								data-testid='creditLimitForm-creditUtilization'
								disabled={true}
							/>
							<InputGroup.Append>
								<InputGroup.Text>%</InputGroup.Text>
								
							</InputGroup.Append>
						</InputGroup>
					</Form.Group>
				</Col>
				<Col xs={12} md={6}>
					<div className={classes.cardRadioBox}>
						<Form.Group controlId='canExceedCreditLimit'>
							<Form.Label>{t('canExceedCreditLimit', tPrefix)}</Form.Label>
							<div>
								<Form.Check
									name='canExceedCreditLimit'
									inline={true}
									label={t('allow', tPrefix)}
									type='radio'
									value='1'
									checked={creditLimitForm.canExceedCreditLimit === true}
									onChange={handleChange}
								/>
								<Form.Check
									name='canExceedCreditLimit'
									inline={true}
									label={t('doNotAllow', tPrefix)}
									type='radio'
									value='0'
									checked={creditLimitForm.canExceedCreditLimit === false}
									onChange={handleChange}
								/>
							</div>
						</Form.Group>
					</div>

					<TitledBorderBox title={t('highBalanceAlerts', tPrefix)}>
						<div className={classes.cardCheckBox}>
							<Form.Group controlId='emailWhenBeyondWarningLimit'>
								<Form.Check
									name='emailWhenBeyondWarningLimit'
									label={t('emailWhenBeyondWarningLimit', tPrefix)}
									type='checkbox'
									id='checkbox1'
									checked={creditLimitForm.emailWhenBeyondWarningLimit}
									onChange={handleChange}
								/>
							</Form.Group>
						</div>

						<Form.Group controlId='emails'>
							<Form.Label>{t('emailRecipients', tPrefix)}</Form.Label>
							<Form.Control
								name='emailRecipients'
								type='text'
								onChange={handleChange}
								value={creditLimitForm.emailRecipients}
								placeholder='Email, comma separated'
								data-testid='creditLimitForm-emails'
								disabled={!creditLimitForm.emailWhenBeyondWarningLimit}
							/>
						</Form.Group>

						<Form.Group controlId='warningThreshold'>
							<Form.Label>{t('warningThreshold', tPrefix)}</Form.Label>
							<Row className={classes.warningTreshhold}>
								<Col>
									<InputGroup>
										<Form.Control
											name='warningThreshold'
											type='number'
											onChange={handleChange}
											value={creditLimitForm.warningThreshold}
											placeholder={tString('percent', tPrefix)}
											data-testid='creditLimitForm-warningThreshold'
										/>
										<InputGroup.Append>
											<InputGroup.Text>%</InputGroup.Text>
										</InputGroup.Append>
									</InputGroup>
								</Col>
								<Col>
									<Form.Label>{t('ofCreditLimit', tPrefix)}</Form.Label>
								</Col>
							</Row>
						</Form.Group>
					</TitledBorderBox>

					<div className={classes.flexEnd}>
						{/* <Button
							bootstrapStyles={true}
							color='green'
							className={classes.saveButton}
							onClick={() => {
								handleSubmit()
								// validateInput()
							}}
						>
							{t('save', tPrefix)}
						</Button> */}
					</div>
				</Col>
			</Row>
		</div>
	)
}
