import { exampleDataLoader } from 'ui/pages/api-docs/'

import { createSos } from './secretaryOfState'

export interface IStateApiDocs {
	selectedMenu: string
	selectedSubMenu: string
	// data: any
	dataCount: number
}

const initialState: IStateApiDocs = {
	selectedMenu: 'Fedex Examples',
	selectedSubMenu: 'Accessorials',
	// data: null,
	dataCount: 0,
}

let _data: {
	examples: {
		name: string
		examples: any[]
	}[]
} = null

const { stateManager, useSubscribe } = createSos(
	'api-docs',
	'1.0.4',
	initialState,
	{
		useLocalStorage: true,
		localStorageFields: ['selectedMenu', 'selectedSubMenu'],
	},
)
export { useSubscribe }

export async function setMenuSelection(
	menu: string,
	subMenu = '',
): Promise<void> {
	stateManager.produce((ds) => {
		ds.selectedMenu = menu
		ds.selectedSubMenu = subMenu
	})
}

export async function fetchExamples(): Promise<void> {
	const data = await exampleDataLoader.getData()
	stateManager.produce((ds) => {
		_data = data
		ds.dataCount++
	})
}

export function getData(): {
	examples: {
		name: string
		examples: any[]
	}[]
} {
	return _data
}
