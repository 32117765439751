import nanoid from 'nanoid'
import { Address } from 'ui/api/apiTypes'
import { IMetaData } from 'ui/lib/shipments/IMetadata'
import { AddressEditModeType, AddressSelectorType, IAddress } from '.'
import { ValidationCarrierType } from './validateAddressUtils'
import { CombinedAccessorialsType } from 'ui/pages/new-quote/accessorialsTypes'
import { apiTypes } from 'ui/api'

export type StopType = 'delivery' | 'pickup'

export type LocationDropdownSourceType =
	| 'companyLocations'
	| 'organizationRelationshipShipFromLocations'
	| 'organizationRelationshipShipToLocations'

export interface IAddressState {
	id?: string
	address: IAddress
	metaData?: IMetaData // for addresses returned from the API
	locationId?: string
	isLocationLoading?: boolean
	locationRequestToken?: string
	isEditing?: boolean
	validation?: IAddressValidationState
	accessorials?: CombinedAccessorialsType
	selectedAddressFilter?: AddressSelectorType
	addressEditMode?: AddressEditModeType
}

export interface IAddressValidationState {
	showValidatedAddresses?: boolean
	providersLoaded?: boolean
	preferredCarrier?: ValidationCarrierType
	validationIsLoading?: boolean
	upsAddress?: apiTypes.AddressValidationResponse
	fedexAddress?: apiTypes.AddressValidationResponse
}

export function createDefaultAddress(address?: Address): Address {
	if (!address) {
		address = {
			street1: '',
			city: '',
			state: '',
			zip: '',
		}
	}
	if (!address.country) {
		address.country = 'US'
	}
	return address
}

export function createDefaultAddressState(
	address?: Address,
	addressEditMode?: AddressEditModeType,
): IAddressState {
	return {
		id: nanoid(),
		address: createDefaultAddress(address),
		accessorials: {},
		validation: {},
		selectedAddressFilter: 'companyLocation',
		addressEditMode: addressEditMode || 'readOnly',
		isEditing: false,
	}
}
