import { FC } from 'app/FunctionalComponent'
import React, { useEffect, useState, useRef } from 'react'
import { DateTime } from 'luxon'
import { apiLocation, apiTypes } from 'ui/api'
import { IRequestState } from 'ui/api/requestState'
import { sosToast } from 'ui/common/components/toast'
import { ClientSelector } from 'ui/components/broker'
import { Card } from 'ui/components/common/card'
import { Icon, solidIcons } from 'ui/components/common/icon'
import { Modal } from 'ui/components/common/modal'
import { OkCancelButtons } from 'ui/components/common/okCancelButtons'
import { selected } from 'ui/components/common/radioButton/Radio.module.scss'
import { Debug } from 'ui/components/dev'
import { StackedItem } from 'ui/components/form'
import { useOnce, watch } from 'ui/components/hooks'
import { t, tArgz, tString } from 'ui/components/i18n/i18n'
import { FlexRow } from 'ui/components/layout/flexRow'
import { Spacer } from 'ui/components/layout/spacer'
import { Accessorials } from 'ui/components/shared/accessorials'
import { ShipmentStopCard } from 'ui/components/shared/ShipmentStopCard'
import {
	getDefaultTimesForLocationAndDate,
	IDateTimeDesiredState,
} from 'ui/components/shared/ShipmentStopCard/DateTimeDesired'
import { fireAndForget } from 'ui/lib/async'
import { l } from 'ui/lib/lodashImports'
import { sos2 } from 'ui/lib/state/sos2'
import {
	deliveryAccessorials,
	pickupAccessorials,
} from 'ui/pages/new-quote/accessorials'
import classes from 'ui/pages/new-quote/newQuotePage.module.scss'
import { IStateNewQuote } from 'ui/pages/new-quote/state/sosNewQuote'
import { sosUser } from 'ui/state'
import { BillTo, BookWithPreferredRoutingButton, FormDetails } from '.'
import { CommoditiesForm } from './CommoditiesForm'
import { LoadOrder } from './parcel/components/load-order'
import { emptyAddressForm } from './parcel/components/newQuoteForms'
import { commodities, sosNewQuote } from './state'
import { orderToLoad } from './state/newQuoteUtils/shipmentMapping'
import {
	apiShipments,
	apiTmsServiceConfig,
} from 'ui/api'
import { Col, Row } from 'react-bootstrap'
import { getLocation } from './state/newQuoteUtils/locationDropdownUtils'
import { OriginMetaData, DestinationMetaData } from 'ui/api/apiTypes'

const tPrefix = 'page.newQuote'

export const NewQuoteFreight: FC = (props: {
	state: IStateNewQuote
	isEditing?: boolean
}) => {
	const { state } = props

	const userState = sos2.useSubscription(sosUser.getSos())
	const [validationErrors, setValidationErrors] = useState<string[]>([])
	const [activeValidation, setActiveValidation] = useState(false)
	const sendShippingNotification =
		userState.selectedClientConfig?.customerShipNotification
			?.sendShippingNotification
	const shippingNotificationEmails =
		userState.selectedClientConfig?.customerShipNotification
			?.shippingNotificationEmails
	const [isFetchingPrintNodeScales, setIsFetchingPrintNodeScales] = useState<
		boolean
	>(true)
	const [modalTitle, setModalTitle] = useState<string>('')
	const [errorMessage, setErrorMessage] = useState<IRequestState<any>>(null)

	const [successMessage, setSuccessMessage] = useState<IRequestState<any>>(null)

	const [orderNumber, setOrderNumber] = useState<string>('')
	const [isLoadingOrder, setIsLoadingOrder] = useState<boolean>(false)

	const [billToAdress, setBillToAddress] = useState(() =>
		l.cloneDeep(emptyAddressForm),
	)

	const loadOrderInput = useRef(null)
	const bookWithButton = useRef<HTMLButtonElement>(null)
	const getRatesButton = useRef<HTMLButtonElement>(null)


	const [autoRateCarrier, setAutoRateCarrier] = useState<string>('')
	const [autoRateService, setAutoRateService] = useState<string>('')
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [shipperFreightConfig, setShipperFreightConfig] = useState<
		apiTypes.ShipperFreightConfigRequest
		>({
		allowRateShopping: false,
		automaticFreightBooking: false,
		automaticPrintDocuments: false
	})

	useOnce(() => {
		sosNewQuote.resetState()
		sosNewQuote.setCompanyDefaults()
		fireAndForget(async () => {
			const result = await apiTmsServiceConfig.getTmsServiceConfig()
			if (result.data) {
				setShipperFreightConfig(
					Object.assign({}, shipperFreightConfig, result.data.freight),
				)
			}
		}, 'fetching shipper config')
	})

	useEffect(() => {
		if (userState.selectedClientConfig && !state.shipmentId) {
			let emails: string

			if (sendShippingNotification) {
				emails = shippingNotificationEmails.join(', ')
			} else {
				emails = ''
			}

			sosNewQuote.updateCustomerShipNotification(emails)
		}
	}, [userState.selectedClientConfig]) // eslint-disable-line react-hooks/exhaustive-deps

	watch(() => {
		if (activeValidation) {
			setValidationErrors(sosNewQuote.validateShipment())
		}
	}, [state])

	const showNewQuote =
		!sosUser.isUserBroker() ||
		(userState.selectedClientConfig && userState.selectedClientConfig.id)

	const clearForm = (): void => {
		sosNewQuote.resetState()
		sosNewQuote.setCompanyDefaults()
		setOrderNumber('')
	}

	const mapLocationDockHoursToDesiredDateTimeInflo = async (
		metaData: OriginMetaData | DestinationMetaData,
	): Promise<apiTypes.DateTimeInfo> => {
		if (metaData) {
			if (metaData.locationId && !metaData.datetimeDesired) {
				const location = await getLocation(metaData.locationId)
				return mapDockHoursToDateTimeDesired(location.dockHours)
			}
		} else return null
	}

	const mapDockHoursToDateTimeDesired = (
		dockHours: apiTypes.DockWeekDoc,
	): apiTypes.DateTimeInfo => {
		const dateToday = DateTime.local()
		const dayTodayString = dateToday.toFormat('EEEE') // Monday, Tuesday, etc.
		const dockHoursToday = dockHours[dayTodayString.toLocaleLowerCase()]
		return {
			timeType: 'between',
			initialDate: dateToday.toFormat('yyyy-MM-dd'),
			initialTime: dockHoursToday.openingTime,
			latestDate: dateToday.toFormat('yyyy-MM-dd'),
			latestTime: dockHoursToday.closingTime,
			timezone: '',
		}
	}

	return (
		<div>
			{sosUser.isUserBroker() && (
				<div className={classes.clientSelector}>
					<StackedItem
						label={t('selectClientContract', tPrefix)}
						showRequired={true}
					>
						<ClientSelector isClearable={false} readOnly={props.isEditing} />
					</StackedItem>
				</div>
			)}
			{showNewQuote && (
				<div>
					<LoadOrder
						mode='freight'
						inputRef={loadOrderInput}
						onLoad={async (orderResponse: apiTypes.OrderResponse) => {
							if (orderResponse === null) {
								clearForm()
								return
							}

							setAutoRateCarrier(orderResponse.shipment.autoRateCarrier)
							setAutoRateService(orderResponse.shipment.autoRateService)

							if (orderResponse.shipment.payloads.length > 1) {
								sosToast.sendToast({
									type: 'danger',
									header: tString('payload_overload', tPrefix),
								})
								return
							}

							if (!orderResponse.shipment.equipmentType) {
								orderResponse.shipment.equipmentType = 'V'
							}

							if (orderResponse.shipment.payloads[0].originStop.metaData) {
								const metaData =
									orderResponse.shipment.payloads[0].originStop.metaData
								if (metaData.locationId && !metaData.datetimeDesired) {
									orderResponse.shipment.payloads[0].originStop.metaData.desiredDateTimeInfo = await mapLocationDockHoursToDesiredDateTimeInflo(
										metaData,
									)
								}
							}

							if (orderResponse.shipment.payloads[0].destinationStop.metaData) {
								const metaData =
									orderResponse.shipment.payloads[0].destinationStop.metaData
								if (metaData.locationId && !metaData.datetimeDesired) {
									orderResponse.shipment.payloads[0].destinationStop.metaData.desiredDateTimeInfo = await mapLocationDockHoursToDesiredDateTimeInflo(
										metaData,
									)
								}
							}

							await sosNewQuote.setStateFromShipmentRequest(
								orderResponse.shipment,
							)
						}}
						isLoadingOrder={isLoadingOrder}
						setIsLoadingOrder={setIsLoadingOrder}
						setErrorMessage={(
							orderResponse: IRequestState<apiTypes.OrderResponse>,
						) => {
							setModalTitle('errorLoadingOrder')
							setErrorMessage(orderResponse)
						}}
						isFetchingPrintNodeScales={isFetchingPrintNodeScales}
						orderNumber={orderNumber}
						onChange={(loadedOrderNumber: string) => {
							if (loadedOrderNumber) {
								setOrderNumber(loadedOrderNumber)
							} else if (loadedOrderNumber === null) {
								setOrderNumber('')
								clearForm()
							}
						}}
					/>
					<Spacer />
					<div className={classes.shipmentCardNewQuote}>
						<ShipmentStopCard
							mode='pickup'
							stopId={state.stops[0].stopId}
							stopCardState={{
								filters: ['companyLocation', 'customAddress', 'addressBook'],
								updateAddressFilter: sosNewQuote.selectAddressFilter,
								addressState: state.stops[0].addressState,
								updateAddress: sosNewQuote.updateStopByAddressId,
								addressSaving: state.isSubmitting,
								saveAddress: sosNewQuote.saveAddress, //TODO: Change this when TMS address book saving is functional
								showSaveAddress: true,
								onSelectLocation: sosNewQuote.onSelectLocation,
								specialInstructions: state.stops[0].specialInstructions,
								updateSpecialInstructions: sosNewQuote.updateStopInstructions,
								dateTimeDesiredState: state.stops[0].dateTimeDesiredState,
								updateDateTimeDesired: sosNewQuote.updateDateTimeDesired,
							}}
							accessorials={
								<div className={classes.shipmentStopCardAccessorialsNewQuote}>
									<Accessorials
										metadata={pickupAccessorials}
										accessorialObject={state.stops[0].addressState.accessorials}
										updateState={sosNewQuote.updateStopAccessorials}
										tPrefix='page.newQuote.pickupAccessorials'
										stopId={state.stops[0].stopId}
										mode='pickup'
									/>
								</div>
							}
							locationsSource='companyLocations'
						/>
					</div>
					<Spacer />
					<div>
						<div className={classes.shipmentCardNewQuote}>
							<ShipmentStopCard
								mode='delivery'
								stopId={state.stops[1].stopId}
								stopCardState={{
									filters: ['companyLocation', 'customAddress', 'addressBook'],
									updateAddressFilter: sosNewQuote.selectAddressFilter,
									addressState: state.stops[1].addressState,
									updateAddress: sosNewQuote.updateAddress,
									addressSaving: state.isSubmitting,
									saveAddress: sosNewQuote.saveAddress,
									showSaveAddress: true,
									onSelectLocation: sosNewQuote.onSelectLocation,
									specialInstructions: state.stops[1].specialInstructions,
									updateSpecialInstructions: sosNewQuote.updateStopInstructions,
									dateTimeDesiredState: state.stops[1].dateTimeDesiredState,
									updateDateTimeDesired: sosNewQuote.updateDateTimeDesired,
								}}
								accessorials={
									<div className={classes.shipmentStopCardAccessorialsNewQuote}>
										<Accessorials
											metadata={deliveryAccessorials}
											accessorialObject={
												state.stops[1].addressState.accessorials
											}
											updateState={sosNewQuote.updateStopAccessorials}
											tPrefix='page.newQuote.deliveryAccessorials'
											stopId={state.stops[1].stopId}
											mode='delivery'
										/>
									</div>
								}
								locationsSource='companyLocations'
							/>
						</div>
					</div>
					<Spacer />
					<div className={classes.shipmentCardNewQuote}>
						{/* <CommoditiesAdvanced /> */}
						<CommoditiesForm
							commodities={state.commodities}
							linearFeet={state.linearFeet}
						/>
					</div>
					<Spacer />
					<div className={classes.flexWrapNewQuote + ' ' + classes.shipmentCardNewQuote}>
						<div className={classes.billToNewQuoteModule}>
							<Card
								title={t('billTo', tPrefix)}
								color='darkBlue'
								className={classes.billToNewQuoteModuleCard}
								fillHeight={true}
							>
								<BillTo
									state={state}
									billToLocations={sosNewQuote.getLocationsBySource(
										'companyLocations',
									)}
									onSelectLocation={sosNewQuote.onSelectLocation}
									updateAddressState={sosNewQuote.updateStopByAddressId}
								/>
							</Card>
						</div>
						<Spacer />
						<div className={classes.detailsNewQuoteModule}>
							<Card
								title={t('page.newQuote.details')}
								color='darkBlue'
								fillHeight={true}
							>
								<FormDetails
									state={state}
									sendShippingNotification={sendShippingNotification}
								/>
							</Card>
						</div>
					</div>
					<div className={classes.shipmentCardNewQuote + ' bootstrap-wrapper'}>
							<div className='d-flex flex-row-reverse bd-highlight mt-2' style={{'marginRight': '-5px'}}>
								{shipperFreightConfig.allowRateShopping && (
									<OkCancelButtons
										ok={
											sosNewQuote.isInEditMode()
												? t('page.newQuote.edit.submit')
												: t('page.newQuote.getRates.submit')
										}
										onOk={async () => {
											const errors = sosNewQuote.validateShipment()
											setValidationErrors(errors)
											if (!errors.length) {
												await sosNewQuote.createShipmentWorkflow(activeValidation)
											} else {
												setActiveValidation(true)
											}
										}}
										okColor={'green'}
										isValid={validationErrors.length === 0}
										errors={[...validationErrors, ...state.createShipmentErrors]}
										isSpinning={state.loadingQuotes || isLoadingOrder}
										okTestId='get-quotes-ok'
									/>
								)}
								{shipperFreightConfig.automaticFreightBooking && (
									<BookWithPreferredRoutingButton
										refProp={bookWithButton}
										autoRateCarrier={autoRateCarrier}
										autoRateService={autoRateService}
										// onOk={getQuotesWithPreferredProviderService}
										isValid={validationErrors.length === 0}
										onOk={async () => {
											
											const errors = sosNewQuote.validateShipment()
											setValidationErrors(errors)
											if (!errors.length) {
												const { data , error } = await sosNewQuote.createShipmentWorkflow(activeValidation, true)
												if (error) {
													setErrorMessage({ error })
													setModalTitle('errorBookingShipment')
												} else {
													if(data.bookedRate?.rateBookingOptions?.api === false) {
														setModalTitle('notifyProvider')
														const warnignMsg: any = `Notify ${data.bookedRate?.carrier ?? data.bookedRate?.providerName} about this shipment.`
														setErrorMessage({error: warnignMsg})
													}

													sosToast.sendToast({
														type: 'success',
														header: tArgz('successfullyBooked', { orderNumber }, tPrefix),
													})
										
													setOrderNumber('')
													clearForm()
												}
											} else {
												setActiveValidation(true)
											}
										}}
										isLoadingOrder={isLoadingOrder}
										isLoading={state.loadingQuotes}
										tPrefix={tPrefix}
									/>
								)}
							</div>
					</div>
					<Modal
						title={tString(modalTitle, tPrefix)}
						content={() => (
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
									width: 500,
								}}
							>
								<Icon
									icon={solidIcons.faExclamationTriangle}
									className={classes.icon}
									color='yellow'
								/>
								<div style={{ padding: '20px 10px 30px' }}>
									{errorMessage?.error}
								</div>

								<div
									style={{
										display: 'flex',
										justifyContent: 'flex-end',
										alignItems: 'flex-end',
										width: '100%',
									}}
								>
									<OkCancelButtons
										isValid={true}
										onOk={() => {
											setErrorMessage(null)
											setModalTitle('Error Loading Order')
										}}
										okColor={'blue'}
										okTestId={'new-quote-freight-error-modal-ok'}
									/>
								</div>
							</div>
						)}
						isOpen={errorMessage !== null}
					/>
				</div>
			)}
		</div>
	)
}
