import { apiServiceDiscovery } from 'ui/api'
import { IRequestState } from 'ui/api/requestState'
import { logError } from 'ui/lib/log/log'

export const getWebsocketURL = async (retries = 0): Promise<string> => {
	if (retries > 2) {
		logError('*', 'UNABLE TO GET A VALID WEBSOCKET URL')
		return null
	}
	const websocketUrlReqeust: IRequestState<{
		value: any
	}> = await apiServiceDiscovery.getWebsocketUrl(() => {}, 'getRates')
	if (websocketUrlReqeust.error) {
		return await getWebsocketURL(retries + 1)
	} else {
		return websocketUrlReqeust.data.value
	}
}
