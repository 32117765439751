import { FC } from 'app/FunctionalComponent'
import _ from 'lodash'
import React from 'react'
import { Input } from 'ui/components/common/input/Input'
import { StackedItem } from 'ui/components/form'
import { t } from 'ui/components/i18n/i18n'
import { getAccessorialKeyPath } from 'ui/pages/new-quote/accessorials'
import { IAccessorialProps } from '.'

export const AccessorialInput: FC = (props: IAccessorialProps) => {
	const {
		accessorialMetadata,
		mode,
		accessorialObject,
		updateState,
		stopId,
		tPrefix,
	} = props

	const accessorialKey = getAccessorialKeyPath(accessorialMetadata, mode)
	return (
		<StackedItem label={t(accessorialKey, tPrefix)} showRequired={true}>
			<Input
				value={_.get(accessorialObject, accessorialKey) || ''}
				onChange={(newVal: string) => {
					const changes: any = {}
					_.set(changes, accessorialKey, newVal)
					updateState(changes, stopId)
				}}
			/>
		</StackedItem>
	)
}
