import { FC } from 'app/FunctionalComponent'
import React from 'react'
// import styles from './Preloader.module.scss'

export const Preloader: FC = (props: {}) => {
	return (
		// <img
		//   className={styles.preloader}
		//   alt='Loading'
		//   title='Loading'
		//   src='http://localhost:3000/images/preloader.gif'
		// />
		<div>Preloader is obsolete. Consider the InfiniteLoader instead</div>
	)
}
