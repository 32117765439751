import { ndash } from 'ui/components/common'

export type TRenderInvoiceStatusKey =
	| 'waitingOnInvoice'
	| 'undergoingAudit'
	| 'disputed'
	| 'paid'
	| 'clearedToPay'
	| 'fundingRequested'
	| 'fundingReceived'

export type TPaymentStatusFilter = TRenderInvoiceStatusKey | 'all'

export type ProviderPortalPage =
	| 'welcome'
	| 'invoices'
	// | 'upload-invoice'
	| 'customers'
	| 'invoice-profile'

export const defaultProviderPortalCellValue = ndash
