import { sosToast } from 'common/components/toast'
import { apiRfp, apiTypes } from 'ui/api'
import { IRequestState } from 'ui/api/requestState'
import { sosRouter2 } from 'ui/components/common/router'
import { tString } from 'ui/components/i18n/i18n'
import { fireAndForget } from 'ui/lib/async'
import { l } from 'ui/lib/lodashImports'
import { createLazySos2 } from 'ui/lib/state/sos2/sos2'
import { sosRfpAnalysisPage } from 'ui/state'
import { tPrefixRfp } from './RfpLayout'

export interface IStateRfp {
	rfpProjects: apiTypes.RfpProjectResponse[]
	selectedRfpProject: apiTypes.RfpProjectResponse
	projectsBeingEdited: string[]
	projectsBeingUpdated: string[]
	showNewRfpProjectModal: boolean
	showRfpProjectOptions: boolean
	deletingRfpProjects: string[]
}

export const getSos = createLazySos2<IStateRfp>('sosRfp', 1, () => ({
	rfpProjects: { default: [], localStorage: true },
	selectedRfpProject: {
		default: {
			id: '',
			name: '',
			rfpIds: [],
			currentRfpId: '',
			status: 'active',
		},
		localStorage: false,
	},
	projectsBeingEdited: { default: [], localStorage: false },
	projectsBeingUpdated: { default: [], localStorage: false },
	showNewRfpProjectModal: { default: false, localStorage: false },
	showRfpProjectOptions: { default: false, localStorage: false },
	deletingRfpProjects: { default: [], localStorage: false },
}))

export const rfpPageInfo = {
	url: '/rfp/:selectedPage',
	params: {
		selectedPage: {
			name: 'selectedPage',
		},
	},
	queries: {
		rfpId: {
			name: 'rfpId',
			default: undefined,
		},
		rfpProjectId: {
			name: 'rfpProjectId',
			default: undefined,
		},
		edit: {
			name: 'edit',
			default: undefined,
		},
	},
}

export const getRfpUrlState = (): {
	rfpId: string
	rfpProjectId: string
	edit: string
	selectedPage: string
} => {
	const rfpId = sosRouter2.getQueryParam(rfpPageInfo, rfpPageInfo.queries.rfpId)
	const rfpProjectId = sosRouter2.getQueryParam(
		rfpPageInfo,
		rfpPageInfo.queries.rfpProjectId,
	)
	const edit = sosRouter2.getQueryParam(rfpPageInfo, rfpPageInfo.queries.edit)
	const selectedPage = sosRouter2.getUrlParam(
		rfpPageInfo,
		rfpPageInfo.params.selectedPage,
	)

	return {
		rfpId,
		rfpProjectId,
		edit,
		selectedPage,
	}
}

export const sortRfpProjectsByCurrentRfpId = (
	rfpProjects: apiTypes.RfpProjectResponse[],
): apiTypes.RfpProjectResponse[] => {
	const collator = new Intl.Collator(undefined, {
		numeric: true,
		sensitivity: 'base',
	})

	return rfpProjects?.sort((a, b) =>
		collator.compare(b.currentRfpId, a.currentRfpId),
	)
}

export const fetchRfpProjects = async (): Promise<void> => {
	const response = await apiRfp.getRfpProjects(
		(rs: IRequestState<apiTypes.RfpProjectsListResponse>) => {},
		{},
		true,
	)

	if (response.data) {
		getSos().change((ds) => {
			ds.rfpProjects = sortRfpProjectsByCurrentRfpId(response.data.entities)
		})
	}
}

export const selectRfpProject = (
	rfpProjectId: string,
	edit?: boolean,
): void => {
	const { rfpProjects } = getSos().getState()
	const selectedRfpProject = l.find(
		rfpProjects,
		(rfpProject) => rfpProject.id === rfpProjectId,
	)

	if (selectedRfpProject) {
		getSos().change((ds) => {
			ds.selectedRfpProject = selectedRfpProject
		})

		if (selectedRfpProject.currentRfpId && !edit) {
			sosRouter2.navigateTo(rfpPageInfo, {
				selectedPage: 'analysis',
				rfpId: selectedRfpProject.currentRfpId,
				rfpProjectId,
			})

			fireAndForget(
				() => sosRfpAnalysisPage.getRfps(true),
				'fetching RFP shipments',
			)
		} else {
			sosRouter2.navigateTo(rfpPageInfo, {
				selectedPage: 'upload',
				rfpProjectId,
			})
		}
	}
}

export const addRfpProject = async (
	newRfpProjectName: string,
): Promise<void> => {
	const rfpProjectRequest: apiTypes.RfpProjectRequest = {
		name: newRfpProjectName,
		rfpIds: [],
		status: 'active',
	}
	const response = await apiRfp.createRfpProject(
		(rs: IRequestState<apiTypes.RfpProjectResponse>) => {},
		rfpProjectRequest,
	)

	if (response.data) {
		getSos().change((ds) => {
			ds.rfpProjects.push(response.data)
		})
	}

	getSos().change((ds) => {
		ds.showNewRfpProjectModal = false
	})

	fireAndForget(fetchRfpProjects, 'refetching RFP Projects')
}

export const deleteRfpProject = async (rfpProjectId: string): Promise<void> => {
	getSos().change((ds) => {
		ds.deletingRfpProjects.push(rfpProjectId)
	})

	const { rfpProjects } = getSos().getState()
	const deletingRfpProject = l.find(
		rfpProjects,
		(rfpProject) => rfpProject.id === rfpProjectId,
	)

	if (deletingRfpProject) {
		const updatedRfpProject: apiTypes.RfpProjectRequest = {
			rfpIds: deletingRfpProject.rfpIds,
			name: deletingRfpProject.name,
			status: 'void',
		}

		const response = await apiRfp.updateRfpProject(
			(rs: IRequestState<apiTypes.RfpProjectResponse>) => {},
			rfpProjectId,
			updatedRfpProject,
		)

		if (response.data) {
			getSos().change((ds) => {
				if (ds.selectedRfpProject.id === rfpProjectId) {
					ds.selectedRfpProject = { rfpIds: [], id: null, status: null }
				}
			})
		} else if (response.error) {
			sosToast.sendApiErrorResponseToast(
				response,
				tString('failedToDeleteRfpProject', tPrefixRfp),
			)
		}

		await fetchRfpProjects()
	}

	getSos().change((ds) => {
		l.pull(ds.deletingRfpProjects, rfpProjectId)
	})
}

export const rerunRfpProject = (): void => {
	sosRouter2.navigateTo(rfpPageInfo, {
		selectedPage: 'upload',
		rfpProjectId: getRfpUrlState().rfpProjectId,
		edit: 'true',
	})

	getSos().change((ds) => {
		ds.showRfpProjectOptions = false
	})
}

export const editRfpProjectName = (rfpProjectId: string): void => {
	getSos().change((ds) => {
		ds.projectsBeingEdited.push(rfpProjectId)
	})
}

export const updateRfpProjectName = async (
	rfpProjectId: string,
	rfpProjectIndex: number,
	newVal: string,
): Promise<void> => {
	getSos().change((ds) => {
		ds.projectsBeingUpdated.push(rfpProjectId)
	})

	const rfpProject = getSos().getState().rfpProjects[rfpProjectIndex]

	if (rfpProject) {
		const rfpProjectRequest: apiTypes.RfpProjectRequest = {
			rfpIds: rfpProject.rfpIds,
			name: newVal,
			status: rfpProject.status,
		}

		const response = await apiRfp.updateRfpProject(
			(rs: IRequestState<apiTypes.RfpProjectResponse>) => {},
			rfpProjectId,
			rfpProjectRequest,
		)

		if (response.data) {
			getSos().change((ds) => {
				ds.rfpProjects[rfpProjectIndex] = response.data
			})
		} else if (response.error) {
			sosToast.sendApiErrorResponseToast(
				response,
				tString('failedToUpdateRfpProjectName', tPrefixRfp),
			)
		}

		await fetchRfpProjects()

		getSos().change((ds) => {
			l.pull(ds.projectsBeingEdited, rfpProjectId)
		})
	}

	getSos().change((ds) => {
		l.pull(ds.projectsBeingUpdated, rfpProjectId)
	})
}

export const updateShowNewRfpProjectModal = (show: boolean): void => {
	getSos().change((ds) => {
		ds.showNewRfpProjectModal = show
	})
}

export const updateShowDeleteOptions = (show: boolean): void => {
	getSos().change((ds) => {
		ds.showRfpProjectOptions = show
	})
}
