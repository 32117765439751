import React from 'react'
import { ISelectOptions } from 'ui/components/common/select/ISelectOptions'
import { FormSelect } from './FormSelect'
import { FormVerticalItem } from './FormVerticalItem'
import { IFormData } from './IFormData'

export function FormVerticalSelect<T>(props: {
	field: keyof T
	formData: IFormData<T>
	options?: string[] | ISelectOptions[]
	readOnly?: boolean
	autocomplete?: boolean
	tPrefix?: string
	testId?: string
}): React.ReactElement {
	const { field, formData, options } = props
	const fieldData = props.formData.metadata[props.field]
	return (
		<FormVerticalItem field={field} formData={formData}>
			<FormSelect
				name={'' + field}
				form={formData.form}
				field={field}
				onUpdateForm={formData.onUpdateForm}
				options={options || fieldData.options}
				readOnly={props.readOnly}
				autocomplete={props.autocomplete}
				isClearable={fieldData.clearable}
				tPrefix={props.tPrefix}
				testId={props.testId}
			/>
		</FormVerticalItem>
	)
}
