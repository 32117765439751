import { l } from '../lodashImports'

export const uniqueTruthyMap = <T, K>(array: T[], func: (c: T) => K): K[] => {
	return l.compact(l.uniq(l.map(array, func)))
}

export const uniqueTruthyFlatMap = <T, K>(
	array: T[],
	func: (c: T) => K[],
): K[] => {
	return l.compact(l.uniq(l.flatten(l.map(array, func))))
}
