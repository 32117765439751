import { IFormMetadataCollection } from 'ui/components/form/IFormMetadata'
import { classCodes } from 'ui/data/classCodes'
import { IProductCatalogEntry } from 'ui/lib/books/IProductCatalogEntry'

import { FormStringData } from './formUtils'
import { hazmatPackingGroupOptions } from 'ui/data/hazmatPackingGroupOptions'

export interface IProductCatalogEntryForm
	extends FormStringData<IProductCatalogEntry> {}

export const productCatalogEntryFormMetadata: IFormMetadataCollection<IProductCatalogEntryForm> = {
	id: {
		readOnly: true,
	},
	bookId: {
		readOnly: true,
	},
	label: {
		required: true,
	},
	description: {
		required: true,
	},
	itemNumber: {},
	freightClass: {
		options: classCodes,
	},
	nmfc: {},
	nmfcSub: {},
	weightEach: {
		mustBeANumber: true,
	},
	hazmat: {
		mustBeABoolean: true,
	},
	hazmatClass: {
		recommended: true,
	},
	hazmatUnCode: {
		recommended: true,
	},
	hazmatPackingGroup: {
		recommended: true,
		options: hazmatPackingGroupOptions,
	},
}
