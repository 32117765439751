import { FC } from 'app/FunctionalComponent'
import React from 'react'
import Col from 'react-bootstrap/Col'

export type ColSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

export const ColOffset: FC = (props: { offset: number; size?: ColSize }) => {
	const { offset, size } = props

	if (size === 'xs') {
		return <Col xs={offset} />
	} else if (size === 'sm') {
		return <Col sm={offset} />
	} else if (size === 'md' || !size) {
		return <Col md={offset} />
	} else if (size === 'lg') {
		return <Col lg={offset} />
	} else if (size === 'xl') {
		return <Col xl={offset} />
	}
}
