import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { ShipmentResponse } from 'ui/api/apiTypes'
import { ndash } from 'ui/components/common'
import { CarrierImage } from 'ui/components/shared'
import { DataTable, IDataTableHeader } from 'ui/components/table'
import { l } from 'ui/lib/lodashImports'

export interface RatingErrorRow {
	providerImage: any // TODO how to type this?
	providerName: string
	error: string
}
export const ShipmentProfileRatingErrorsTable: FC = (props: {
	shipment: ShipmentResponse
	tPrefix: string
}) => {
	const { shipment, tPrefix } = props
	const headers: IDataTableHeader<RatingErrorRow>[] = [
		{
			field: 'providerImage',
			hideLabel: true,
			renderer: (providerImage: string, row: RatingErrorRow) => {
				if (!providerImage) {
					return ndash
				}

				// TODO: use common renderer
				return (
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<CarrierImage
							carrier={providerImage}
							style={{ maxHeight: '25px' }}
						/>
					</div>
				)
			},
		},
		{
			field: 'providerName',
		},
		{
			field: 'error',
		},
	]

	const ratingErrorRows: RatingErrorRow[] = l.map(
		shipment.ratingErrors,
		(ratingError): RatingErrorRow => {
			return {
				providerImage: ratingError.provider,
				providerName: ratingError.provider,
				error: ratingError.error,
			}
		},
	)
	return (
		<DataTable
			headers={headers}
			data={ratingErrorRows}
			state={{}}
			tPrefix={tPrefix + '.errorTable'}
			spacerCell={false}
		/>
	)
}
