import { apiTypes } from 'ui/api'

export const documentTypes: apiTypes.ShippingDocumentTypes[] = [
	'Bill of Lading',
	'Packing Slip',
	'Label',
	'Return Label',
	'Receipt',
	'Proof Of Delivery',
	'Invoice',
	'Corrected Invoice',
	'Hazmat Documentation',
	'Commercial Invoice',
	'Reclass Docs',
	'Reweigh Docs',
	'Relength Docs',
	'Lumper',
	'Reconsignment',
	'Letter of Authority',
	'Customs Documents',
	'Inspection Report',
]
