export const noop = (): void => {}
// import minimongo from 'minimongo'
// import PubSub from 'pubsub-js'
// import { l } from 'ui/lib/lodashImports'

// let db: IMinimongo = new minimongo.MemoryDb()

// export type Keys =
// 	| 'Shipments'
// 	| 'Payloads'
// 	| 'Packages'
// 	| 'Rates'
// 	| 'Rates_Errors'
// 	| 'Rates_Pending'

// export interface IMinimongo {
// 	addCollection: (name: Keys) => void
// 	Rates: IMinimongoCollection
// 	Rates_Pending: IMinimongoCollection
// 	Rates_Errors: IMinimongoCollection
// 	Shipments: IMinimongoCollection
// 	Payloads: IMinimongoCollection
// 	Packages: IMinimongoCollection
// }

// export interface IMinimongoCollection {
// 	upsert: (doc) => void
// 	remove: (doc) => void
// 	find: (selector?, options?) => IMinimongoCursor
// 	findOne: (selector, options, callback: (res) => void) => void
// }

// export interface IMinimongoCursor {
// 	fetch: (cb: (playload: any[]) => void) => void
// }

// PubSub.subscribe(
// 	'update-subscription',
// 	(
// 		msg,
// 		data: {
// 			key: Keys
// 			changeType: 'added' | 'changed' | 'removed'
// 			oldDoc: any
// 			newDoc: any
// 		},
// 	) => {
// 		let { key, changeType, newDoc, oldDoc } = data
// 		if (!db[key]) {
// 			db.addCollection(key)
// 		}

// 		if (changeType === 'added') {
// 			db[key].upsert(newDoc)
// 		}
// 		if (changeType === 'changed') {
// 			db[key].upsert(newDoc)
// 		}
// 		if (changeType === 'removed') {
// 			db[key].remove(oldDoc._id)
// 		}

// 		PubSub.publish('update-subscription:' + key, {})
// 	},
// )

// export function getMinimongo() {
// 	return db
// }

// export function subscribeTo(
// 	keys: Keys[] | Keys,
// 	callback: (data: { db: IMinimongo }) => void,
// ) {
// 	if (!_.isArray(keys)) {
// 		keys = [keys]
// 	}

// 	const debounceCallback = _.throttle(
// 		() => {
// 			callback({ db })
// 		},
// 		200,
// 		{ leading: true, trailing: true },
// 	)

// 	_.forEach(keys, (key) => {
// 		PubSub.subscribe('update-subscription:' + key, (msg, data) => {
// 			// callback({ db })
// 			debounceCallback()
// 		})
// 	})
// }
