import { FC } from 'app/FunctionalComponent'
import React from 'react'
import {
	IRoute,
	LoginModeType,
	SimpleRouter,
} from 'ui/components/common/router'
import { DockScheduler } from './DockScheduler'

export const dockSchedulerRoutes: IRoute[] = [
	{
		urls: ['/dock-scheduler'],
		renderer: (currentLocation, params) => <DockScheduler />,
	},
]

export const RouterDockScheduler: FC = (props: {
	currentLocation: string
	loginMode: LoginModeType
}) => {
	return (
		<SimpleRouter
			routes={dockSchedulerRoutes}
			currentLocation={props.currentLocation}
			loginMode={props.loginMode}
		/>
	)
}
