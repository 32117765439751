import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { IRequestState } from 'ui/api/requestState'
import { t } from 'ui/components/i18n/i18n'
import { DataTable } from 'ui/components/table/DataTable'
import { DataTableUpdate } from 'ui/components/table/DataTableUpdate'
import { IDataTableHeader } from 'ui/components/table/IDataTableHeader'
import { IDataTableState } from 'ui/components/table/IDataTableState'
import { IAddressBookEntryForm } from 'ui/forms/formAddressBookEntry'
import { IAddressBookEntry } from 'ui/lib/books/IAddressBookEntry'
import { l } from 'ui/lib/lodashImports'
import { sosAddressBookEntry } from 'ui/state'
import { UpdateAddressBookEntryForm } from './UpdateAddressBookEntryForm'

export const addressBookEntryHeaders: IDataTableHeader<IAddressBookEntry>[] = [
	{
		field: 'addressType',
		renderer: (addressType: string) => l.upperFirst(addressType),
	},
	{
		field: 'name',
	},
	{
		field: 'phone',
		monospace: true,
	},
	{
		field: 'email',
	},
	{
		field: 'street1',
	},
	{
		field: 'street2',
	},
	{
		field: 'city',
	},
	{
		field: 'state',
		monospace: true,
	},
	{
		field: 'zip',
		monospace: true,
	},
	{
		field: 'country',
	},
]

export const AddressBookEntryDataTable: FC = (props: {
	fetchAddressBookEntries: IRequestState<IAddressBookEntry[]>
	requestUpdateAddressBookEntry: IRequestState<IAddressBookEntry>
	dataTableState: IDataTableState<IAddressBookEntry>
	data: IAddressBookEntry[]
	form: IAddressBookEntryForm
}) => {
	const { fetchAddressBookEntries, requestUpdateAddressBookEntry } = props
	const { dataTableState } = props

	return (
		<div>
			<DataTable
				testId='data-table-address-book'
				headers={addressBookEntryHeaders}
				tPrefix={'shared.addressBook'}
				isLoading={
					fetchAddressBookEntries.isFetching ||
					requestUpdateAddressBookEntry.isFetching
				}
				data={props.data}
				state={dataTableState}
				onAddRow={() => {
					sosAddressBookEntry.addressBookTableSetUpdateMode('add')
				}}
				addLabel={t('shared.addressBook.addAddressBookPage')}
				onEditRow={(row) => {
					sosAddressBookEntry.addressBookTableSetUpdateMode('edit', row)
				}}
				onToggleHeader={(c) => {
					sosAddressBookEntry.toggleHeader(c.field)
				}}
				onClickHeader={(header) => {
					sosAddressBookEntry.sort(header.field)
				}}
				onRefresh={async () => {
					await sosAddressBookEntry.fetchAddressBookEntries()
				}}
				rendererAdd={() => (
					<DataTableUpdate error={dataTableState.error}>
						<UpdateAddressBookEntryForm mode='add' form={props.form} />
					</DataTableUpdate>
				)}
				rendererEdit={(row) => (
					<DataTableUpdate
						error={dataTableState.error}
						onDeleteRow={() =>
							sosAddressBookEntry.updateAddressBookEntry('delete')
						}
					>
						<UpdateAddressBookEntryForm mode='edit' form={props.form} />
					</DataTableUpdate>
				)}
			/>
		</div>
	)
}
