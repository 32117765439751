import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { InfiniteLoader } from 'ui/components/common/loader/InfiniteLoader'
import { Debug } from 'ui/components/dev/Debug'
import { tDate, tNumber } from 'ui/components/i18n/i18n'
import { CarrierImage } from 'ui/components/shared/CarrierImage'
import { l } from 'ui/lib/lodashImports'
import styles from './Rates.module.scss'
import stylesRatesErrors from './RatesErrors.module.scss'

const CentsDisplay: FC = (props: { cents: number }) => {
	const centsPart = (props.cents % 100) / 100
	const dollarsPart = (props.cents - centsPart) / 100

	return (
		<div className={styles.costContainer}>
			$<span className={styles.dollar}>{tNumber(dollarsPart, '0')}</span>
			{tNumber(centsPart, '.00')}
		</div>
	)
}

const RatesTms3: FC = (props: {
	isLoading: boolean
	rates: any
	ratesPending: any
	markup: number
	markupPercent: number
}) => {
	const { rates, ratesPending } = props

	if (!props.isLoading) {
		if (!rates || rates.length === 0) {
			if (!ratesPending || ratesPending.length === 0) {
				return null
			}
		}
	}

	let data = l.cloneDeep(rates)
	data = l.sortBy(data, (c) => c.costTotal)

	// let ratesPendingData = _.cloneDeep(ratesPending)
	// ratesPendingData = _.uniqBy(
	//   _.sortBy(ratesPendingData, (c) => c.carrier),
	//   (c) => c.carrier,
	// )

	return (
		<div>
			<InfiniteLoader isLoading={props.isLoading} />

			<table className={stylesRatesErrors.table + ' ' + styles.tableRates}>
				<thead>
					<tr>
						<th>Provider</th>
						<th style={{ width: '200px' }}>Cost</th>
						<th style={{ width: '70px' }}>Mode</th>
						<th style={{ width: '90px' }}>Quote Type</th>
						<th style={{ width: '150px' }}>Estimated Transit</th>
						<th style={{ width: '150px' }}>ETA Date</th>
						<th style={{ width: '150px' }}>Guarantee Date</th>
					</tr>
				</thead>
				<tbody>
					{l.map(data, (c, cIdx) => {
						let method = c.method
						if (method === 'ltl') {
							method = 'LTL'
						}
						if (method === 'parcel') {
							method = 'Parcel'
						}

						return (
							<tr key={cIdx}>
								<td>
									<div className={styles.carrierContainer}>
										<div>
											<CarrierImage carrier={c.providerName} />
										</div>
										<div>
											{c.providerName} &ndash; {c.serviceLevel}
										</div>
									</div>
								</td>
								<td className={styles.cost}>
									<CentsDisplay
										cents={
											c.costTotal * (1 + props.markupPercent) +
											props.markup * 100
										}
									/>
								</td>
								<td>{method}</td>
								<td>
									{true /*everything is webrate right now*/ && (
										<span>Web Rate</span>
									)}
								</td>
								<td>{c.transit ? <span>{c.transit} day</span> : 'N/A'}</td>
								<td>
									{c.delivery ? (
										// <span>{moment(c.delivery).format('YYYY-MM-DD')}</span>
										<span>{tDate(c.delivery)}</span>
									) : (
										'N/A'
									)}
								</td>
								<td>
									{c.guaranteed ? (
										// <span>
										//   {c.guaranteed_human_readable
										//     ? c.guaranteed_human_readable
										//     : moment(c.guaranteed).format('YYYY-MM-DD')}
										// </span>
										<span>
											{c.guaranteed_human_readable
												? c.guaranteed_human_readable
												: tDate(c.guaranteed)}
										</span>
									) : (
										'N/A'
									)}
								</td>
							</tr>
						)
					})}
				</tbody>
			</table>
			<Debug data={rates} label='rates' maxItems={3} />
		</div>
	)
}

export { RatesTms3 }

// {_.map(ratesPendingData, (c, cIdx) => {
//   return (
//     <tr key={'rate-pending-' + cIdx}>
//       <td>
//         <CarrierImage carrier={c.carrier} />
//       </td>
//       <td colSpan={7}>
//         <Preloader />
//         {/* Pending... */}
//       </td>
//     </tr>
//   )
// })}
