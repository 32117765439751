import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { TwoColumn, TwoColumnItem } from 'ui/components/layout/column/TwoColumn'
import { sos2 } from 'ui/lib/state/sos2'
import { sosQuickRateTms3, sosUser } from 'ui/state/'
import { Layout } from '../layout/Layout'
import { QuickRateForm } from './QuickRateForm'
import { QuickRateProductCostForm } from './QuickRateProductCostForm'
import { RatesErrors } from './RatesErrors'
import { RatesTms3 } from './RatesTms3'

export const QuickRatePageTms3: FC = (props: {}) => {
	const stateUser = sos2.useSubscription(sosUser.getSos())
	const stateQuickRate = sosQuickRateTms3.useSubscribe()

	const markup = parseFloat(stateQuickRate.productCostForm.productCost) || 0
	let markupPercent =
		parseFloat(stateQuickRate.productCostForm.freightMarkupPercent) || 0
	if (markupPercent >= 1) {
		markupPercent = markupPercent / 100
	}

	return (
		<Layout>
			<TwoColumn>
				<TwoColumnItem minWidth='200px' maxWidth='500px'>
					<QuickRateForm
						locations={stateUser.requestLocationsInfo.data}
						form={stateQuickRate.quickRateForm}
					/>
				</TwoColumnItem>
				<TwoColumnItem minWidth='220px' maxWidth='500px'>
					<QuickRateProductCostForm form={stateQuickRate.productCostForm} />
				</TwoColumnItem>
			</TwoColumn>

			<RatesTms3
				isLoading={stateQuickRate.isLoading}
				rates={stateQuickRate.rates}
				ratesPending={[]}
				markup={markup}
				markupPercent={markupPercent}
			/>
			<RatesErrors
				onToggle={sosQuickRateTms3.toggleIsRatesErrorsOpen}
				ratesErrors={stateQuickRate.ratesErrors}
				isOpen={stateQuickRate.isRatesErrorsOpen}
			/>
		</Layout>
	)
}
