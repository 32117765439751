import { DateTime } from 'luxon'
import { l } from 'ui/lib/lodashImports'
import { tCurrency, tDate, tCurrencyFromCents } from '../i18n/i18n'
import { DataTableHeaderFormat, IDataTableHeader } from './IDataTableHeader'
import { IDataTableHeaderProcessed } from './IDataTableHeaderProcessed'
import { IFilterChip } from './IDataTableState'

const getFormat = (
	type: DataTableHeaderFormat,
):
	| ((
			amount: number,
			format?: string,
			minDecimals?: number,
			maxDecimals?: number,
	  ) => string)
	| ((data: string) => string) => {
	switch (type) {
		case 'currency': {
			return tCurrency
		}
		case 'currencyFromCents': {
			return tCurrencyFromCents
		}
		case 'shortDate': {
			return (data: string) => tDate(data, DateTime.DATE_SHORT)
		}
		case 'date': {
			return (data: string) => tDate(data, DateTime.DATE_FULL)
		}
		case 'time': {
			return (data: string) => tDate(data, DateTime.TIME_WITH_SHORT_OFFSET)
		}
		case 'dateTime': {
			return (data: string) => tDate(data, DateTime.DATETIME_FULL)
		}
		default: {
			return () => 'bad-date-format'
		}
	}
}

const applyFormatFunctions = (
	headers: IDataTableHeader<any>[],
	filterChips?: IFilterChip<any>[],
): IDataTableHeaderProcessed<any>[] => {
	const processedHeaders: IDataTableHeaderProcessed<any>[] = []
	l.forEach(headers, (header) => {
		const processedHeader: IDataTableHeaderProcessed<any> = l.cloneDeep(header)
		if (header.formatType) {
			processedHeader.format = getFormat(header.formatType)
		}
		if (header.headerGroup) {
			processedHeader.headerGroup = header.headerGroup
		}

		const filter = l.find(
			filterChips,
			(filter) => filter.columnKey === header.field,
		)
		if (filter) {
			processedHeader.filterValue = filter.filterValue
		}

		processedHeaders.push(processedHeader)
	})
	return processedHeaders
}

export const formatUtils = {
	applyFormatFunctions,
}
