import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { Button } from 'ui/components/common/button'
import { Icon, solidIcons } from 'ui/components/common/icon'
import { DocumentViewer } from 'ui/components/common/modal'
import { PaperCard, PaperCardTitle } from 'ui/components/common/paperCard'
import { t } from 'ui/components/i18n/i18n'
import { Spacer } from 'ui/components/layout/spacer'
import { IDataTableHeader, KeyValueTable } from 'ui/components/table'
import * as renderers from 'ui/components/table/commonDataCellRenderers'
import { _idx } from 'ui/lib'
import { l } from 'ui/lib/lodashImports'
import { IProviderPortalInvoiceProfile } from 'ui/pages/provider-portal/IProviderPortalInvoiceProfile'
import { sosProviderPortalInvoiceProfile } from 'ui/state'
import { renderCityState, renderDates } from '../../invoices'

const tPrefix = 'page.providerPortal.invoiceDetails'

interface IInvoiceDetails {
	invoiceNumber: string
	status: string
	pro_number: string
	providerName: string
	scac: string
	invoiceAmount: number
	invoiceDate: string
	dueDate: string
	initialOrigin: string
	finalDestination: string
	currencyCode: string
}

const table1Headers: IDataTableHeader<IInvoiceDetails>[] = [
	{
		field: 'invoiceNumber',
	},
	{
		field: 'status',
		renderer: (val: string) => l.startCase(val),
	},
	{
		field: 'pro_number',
	},
	{
		field: 'scac',
	},
]

const table2Headers: IDataTableHeader<IInvoiceDetails>[] = [
	{
		field: 'invoiceAmount',
		renderer: renderers.currency,
	},
	{
		field: 'invoiceDate',
		renderer: renderDates,
	},
	{
		field: 'dueDate',
		renderer: renderDates,
	},
]

const table3Headers: IDataTableHeader<IInvoiceDetails>[] = [
	{
		field: 'initialOrigin',
		renderer: renderCityState,
	},
	{
		field: 'finalDestination',
		renderer: renderCityState,
	},
]

const labelMinWidth = '120px'

export const InvoiceDetailsBasicTemplate: FC = (props: {
	invoiceData: IProviderPortalInvoiceProfile
}) => {
	const invoiceDetailsState = sosProviderPortalInvoiceProfile.useSubscribe()
	const { showInvoiceDocumentViewer } = invoiceDetailsState
	const { invoiceData } = props

	return (
		<PaperCard>
			<PaperCardTitle border={true}>{t('title', tPrefix)}</PaperCardTitle>
			<KeyValueTable
				tPrefix={tPrefix}
				headers={table1Headers}
				rawData={invoiceData}
				borderless={true}
				labelMinWidth={labelMinWidth}
			/>
			<Spacer />
			{invoiceData.status !== 'waitingOnInvoice' && (
				<React.Fragment>
					<KeyValueTable
						tPrefix={tPrefix}
						headers={table2Headers}
						rawData={invoiceData}
						borderless={true}
						labelMinWidth={labelMinWidth}
					/>
					<Spacer />
				</React.Fragment>
			)}
			<KeyValueTable
				tPrefix={tPrefix}
				headers={table3Headers}
				rawData={invoiceData}
				borderless={true}
				labelMinWidth={labelMinWidth}
			/>
			<Spacer />
			{invoiceData.status !== 'waitingOnInvoice' &&
				_idx(() => invoiceData.invoice.url) && (
					<React.Fragment>
						<DocumentViewer
							documentUrl={invoiceData.invoice.url}
							onModalClose={() =>
								sosProviderPortalInvoiceProfile.setShowInvoiceDocumentViewer(
									false,
								)
							}
							isOpen={showInvoiceDocumentViewer}
						/>
						<Button
							color={'blue'}
							onClick={() =>
								sosProviderPortalInvoiceProfile.setShowInvoiceDocumentViewer(
									true,
								)
							}
						>
							<Icon icon={solidIcons.faSearch} /> {t('viewInvoice', tPrefix)}
						</Button>
					</React.Fragment>
				)}
		</PaperCard>
	)
}

export const InvoiceDetails: FC = (props: {}) => {
	const state = sosProviderPortalInvoiceProfile.useSubscribe()
	const { invoiceData } = state

	return (
		<div>
			<InvoiceDetailsBasicTemplate invoiceData={invoiceData} />
		</div>
	)
}
