export const isOnLastPage = (
	page: number,
	totalSize: number,
	pageSize: number,
): boolean => {
	return page >= totalSize / pageSize
}

export const getNextPage = (
	page: number,
	totalSize: number,
	pageSize: number,
): number => {
	return isOnLastPage(page, totalSize, pageSize) ? page : page + 1
}

export const getPreviousPage = (page: number): number => {
	return page <= 1 ? 1 : page - 1
}

export const getLastPage = (totalSize: number, pageSize: number): number => {
	return Math.ceil(totalSize / pageSize)
}
