import countryData, { Country } from 'country-region-data'
import { ISelectOptions } from 'ui/components/common/select'
import { l } from 'ui/lib/lodashImports'

export interface ICountryNameAndCodeData {
	countryName: string
	countryCode: string
}

export function getFullCountryData(): Country[] {
	return countryData
}

export function getCountryNameList(): string[] {
	return l.map(countryData, (c) => c.countryName)
}

export function getCountryNameAndCodeList(): ICountryNameAndCodeData[] {
	return l.map(countryData, (c) => ({
		countryName: c.countryName,
		countryCode: c.countryShortCode,
	}))
}

export function getRegionsForCountry(
	country: string,
	countryCode = false,
	asSelectOptions = false,
): string[] | ISelectOptions[] {
	const _countryData = getFullCountryData()
	const selectedCountryData = l.filter(_countryData, (c) =>
		countryCode
			? c.countryShortCode.toLowerCase() === country?.toLowerCase()
			: c.countryName.toLowerCase() === country?.toLowerCase(),
	)
	const regions = l.map(selectedCountryData, (d) => d.regions)
	if (asSelectOptions) {
		return l.map(regions[0], (r) => ({ label: r.name, value: r.shortCode }))
	} else {
		return l.map(regions[0], (r) => r.name)
	}
}

export const toCountryCode = (country: string): string => {
	if (country.length === 2) {
		return country.toUpperCase()
	}
	const singleCountryData = l.find(
		countryData,
		(c) => c.countryName === country,
	)
	return singleCountryData?.countryShortCode
}

export const toCountryName = (country: string): string => {
	if (country.length === 2) {
		const singleCountryData = l.find(
			countryData,
			(c) => c.countryShortCode === country,
		)
		return singleCountryData?.countryName
	}
	return country
}
