import { FC } from 'app/FunctionalComponent'
import React, { useState } from 'react'
import { l } from 'ui/lib/lodashImports'
import { theme } from 'ui/theme'
import { Icon, solidIcons } from '../icon'
import { Input } from '../input'
import classes from './SearchInput.module.scss'

export const SearchInput: FC = (props: {
	testId?: string
	value: string
	onChange?: (newVal: string) => void
	onChangeImmediate?: (newVal: string) => void
	width?: string
	placeholder?: string
	readOnly?: boolean
	className?: string
}) => {
	const [isFocused, setIsFocused] = useState(false)
	const { testId, onChangeImmediate } = props

	const refInput = React.createRef<any>()

	let className = classes.container
	className = theme.addClassIf(
		!l.isNil(props.className),
		props.className,
		className,
	)
	let classNameIcon = classes.icon
	if (isFocused) {
		className += ' ' + classes.focused
		classNameIcon += ' ' + classes.focused
	}

	let style: React.CSSProperties = null
	if (props.width) {
		style = {
			width: props.width,
			minWidth: props.width,
			maxWidth: props.width,
		}
	}

	return (
		<div
			className={className}
			onClick={() => {
				refInput.current.focus()
			}}
			style={style}
		>
			<div className={classNameIcon}>
				<Icon icon={solidIcons.faSearch} />
			</div>
			<div className={classes.inputArea}>
				<Input
					width='100%'
					ref={refInput}
					borderless={true}
					autocomplete='off'
					testId={testId}
					value={props.value}
					onFocus={() => {
						setIsFocused(true)
					}}
					onBlur={() => {
						setIsFocused(false)
					}}
					onChange={(newVal) => {
						props.onChange(newVal)
						if (onChangeImmediate) {
							onChangeImmediate(newVal)
						}
					}}
					onEnter={(newVal) => {
						props.onChange(newVal)
					}}
					placeholder={props.placeholder}
					readOnly={props.readOnly}
				/>
			</div>
		</div>
	)
}
