import { FC } from 'app/FunctionalComponent'
import { ToastContext } from 'common/components/toast/Toast'
import React, { useContext, useState } from 'react'
import { Button } from 'ui/components/common/button/Button'
import { t } from 'ui/components/i18n/i18n'
import { l } from 'ui/lib/lodashImports'
import { sos2 } from 'ui/lib/state/sos2'
import { Layout } from 'ui/pages/layout/Layout'
import * as classes from 'ui/pages/load-optimizer/LoadOptimizer.module.scss'
import { tPrefixLoadOptimizer } from 'ui/pages/load-optimizer/payloads-table/LoadOptimizerPayloadsTable'
import * as sosLoadOptimizer from 'ui/pages/load-optimizer/state/sosLoadOptimizer'
import { ConsolidatedShipmentCard } from './ConsolidatedShipmentCard'

const LoadOptimizerLoadConsolidationPage: FC = (props: {}) => {
	const dispatch = useContext(ToastContext)
	const state = sos2.useSubscription(sosLoadOptimizer.getSos())
	const { consolidationShipments } = state

	const shipmentsSaved = state.consolidationShipments.some(
		(shipment) => shipment.saved,
	)

	const [isSavingShipments, setIsSavingShipments] = useState(false)

	return (
		<Layout>
			<h4>{t('loadConsolidation', tPrefixLoadOptimizer)}</h4>
			<div className='bootstrap-wrapper'>
				<div className='container'>
					<div className='d-flex flex-row-reverse'>
						<div>
							<Button
								color='blue'
								isSmall={true}
								bootstrapStyles={true}
								onClick={async () => {
									setIsSavingShipments(true)
									await sosLoadOptimizer.confirmLoadConsolidation(dispatch)
									setIsSavingShipments(false)
								}}
								isDisabled={shipmentsSaved}
								isSpinning={isSavingShipments}
								testId='confirmLoadConsolidation'
							>
								{t('confirmLoadConsolidation', tPrefixLoadOptimizer)}
							</Button>
						</div>
						<div className={classes.sideMargin}>
							<Button
								color='blue'
								isSmall={true}
								bootstrapStyles={true}
								onClick={sosLoadOptimizer.addConsolidationShipment}
								isDisabled={shipmentsSaved || isSavingShipments}
								testId='addShipment'
							>
								{t('addShipment', tPrefixLoadOptimizer)}
							</Button>
						</div>
					</div>
					<div className='row'>
						{l.map(
							consolidationShipments,
							(consolidationShipment, consolidationShipmentIdx) => {
								return (
									<ConsolidatedShipmentCard
										shipment={consolidationShipment}
										key={consolidationShipment.id}
										testId={
											'consolidatedShipmentCard-' + consolidationShipmentIdx
										}
									/>
								)
							},
						)}
					</div>
				</div>
			</div>
		</Layout>
	)
}

export { LoadOptimizerLoadConsolidationPage }
