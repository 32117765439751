import { FC } from 'app/FunctionalComponent'
import React from 'react'
import styles from './Placeholder.module.scss'

export const Placeholder: FC = (props: {
	children?: React.ReactNode
	width?: string
	height?: string
}) => {
	return (
		<div
			style={{ width: props.width, height: props.height }}
			className={styles.placeholder}
		>
			<div>{props.children || 'Placeholder'}</div>
		</div>
	)
}
