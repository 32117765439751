import React from 'react'
import { l } from 'ui/lib/lodashImports'
import { ndash } from '../common'
import { t, tDate, tNumber } from '../i18n/i18n'
import { CarrierImage } from '../shared'
import classes from './RatesTable.module.scss'

export const rendererCarrierName = (carrier: string): React.ReactElement => {
	if (!carrier) {
		return <div>{'–'}</div>
	}
	carrier = carrier.replace(/_/g, ' ')
	return <div>{carrier}</div>
}
export const rendererT = (
	cellData: any,
	tPrefix: string,
): React.ReactElement => {
	if (!cellData) {
		return <div>{ndash}</div>
	}
	return t('' + cellData, tPrefix)
}
export const rendererRateCents = (cents: number): React.ReactElement => {
	if (l.isNil(cents) || cents === 0) {
		return <div className={classes.costContainer}>{ndash}</div>
	}

	const centsPart = Math.round(cents % 100)
	const dollarsPart = Math.floor((cents - centsPart) / 100)

	return (
		<div className={classes.costContainer}>
			$
			<span className={classes.dollars} data-testid='dollarsPart'>
				{tNumber(dollarsPart, '0,0')}
			</span>
			.{tNumber(centsPart, '00')}
		</div>
	)
}
export const rendererDays = (days: number): React.ReactElement => {
	return (
		<div>
			{days} {t('common.days')}
		</div>
	)
}
export const rendererCarrierImage = (
	providerName: string,
): React.ReactElement => {
	return (
		<CarrierImage
			carrier={providerName}
			style={{ maxHeight: '25px', margin: '5px' }}
		/>
	)
}
export const rendererDate = (date: string): string => {
	return tDate(date)
}
