import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC } from 'app/FunctionalComponent'
import React, { RefObject } from 'react'
import { Button } from 'react-bootstrap'
import { testId } from 'ui/components/i18n/i18n'
import { l } from 'ui/lib/lodashImports'
import { theme } from 'ui/theme'
import { addClassIf, Colors } from 'ui/theme/theme'
import * as classes from './Icon.module.scss'
import { solidIcons } from './solidIcons'

export const Icon = React.memo(
	(props: {
		icon?: IconDefinition
		className?: string
		spin?: boolean
		pulse?: boolean
		color?: Colors
		large?: boolean
	}) => {
		let classname = props.className || ''

		if (props.color) {
			classname += ` ${theme.getTextColor(props.color)}`
		}
		if (props.large) {
			classname += ` ${classes.large}`
		}

		return React.useMemo(
			() => (
				<FontAwesomeIcon
					className={classname}
					icon={
						props.spin
							? solidIcons.faSpinner
							: props.icon || solidIcons.faCoffee
					}
					spin={props.spin}
					pulse={props.pulse}
				/>
			),
			[props.icon, classname, props.spin, props.pulse],
		)
	},
)

export const IconButton: FC = (props: {
	buttonRef?: RefObject<Button<'button'>> & RefObject<HTMLButtonElement>
	icon?: IconDefinition
	iconClassName?: string
	buttonClassName?: string
	onClick?: (ev: React.MouseEvent<HTMLElement>) => void
	children?: React.ReactNode
	testId?: string
	inline?: boolean
	spin?: boolean
	pulse?: boolean
	color?: Colors
	large?: boolean
	disabled?: boolean
}) => {
	let classname = classes.iconButton
	classname = addClassIf(props.inline, classes.inlineIcon, classname)
	classname = addClassIf(props.disabled, classes.disabled, classname)
	classname = addClassIf(
		!l.isNil(props.buttonClassName),
		props.buttonClassName,
		classname,
	)

	return (
		<Button
			ref={props.buttonRef}
			data-testid={props.testId}
			className={classname}
			onClick={props.disabled ? null : props.onClick}
		>
			<Icon
				icon={props.icon}
				className={props.iconClassName}
				spin={props.spin}
				pulse={props.pulse}
				color={props.color}
				large={props.large}
			/>{' '}
			{props.children}
			{testId(props.testId)}
		</Button>
	)
}
