import axios, { ResponseType } from 'axios'
import {
	ITimeStore,
	unsetTime,
} from 'ui/components/shared/ShipmentStopCard/DateTimeDesired'
import { l } from 'ui/lib/lodashImports'
import { logError } from 'ui/lib/log/log'
import { inJestUnitTest } from 'ui/lib/testing/inJestUnitTest'
import { sosUser } from 'ui/state'
import { isIE11CompatibilityTestingMode, isInDev } from 'ui/theme/theme'
import { requestState } from '.'
import { IRequestState } from './requestState'
import { getCookieByKey } from 'ui/lib/cookieHelper'

let requestNumber = 100
const ie11CompatibilityTestingHost = 'http://192.168.1.134:4250/api/v6' // You'll need to use your local ip address here
export interface IRequestOptions {
	method?: 'GET' | 'POST' | 'PUT' | 'DELETE'
	data?: any
	headers?: { [header: string]: string }
	entireResponse?: boolean
	alternateApiKey?: string
	responseType?: ResponseType
	transform?: boolean
}

interface IId {
	id?: string
}

export function getApiHost(): string {
	let host = '/api/v6'
	if (isInDev()) {
		host = 'https://devapp.swanleap.com/api/v6'
		if (isIE11CompatibilityTestingMode() && ie11CompatibilityTestingHost) {
			host = ie11CompatibilityTestingHost
		}
	}
	return host
}

export type UpdateMode = 'add' | 'edit' | 'upsert' | 'delete' | 'none'
export function modeToMethod(
	mode: UpdateMode,
	data?: IId,
): 'PUT' | 'POST' | 'DELETE' {
	if (mode === 'upsert') {
		if (data && data.id) {
			return 'PUT'
		}
		return 'POST'
	}
	switch (mode) {
		case 'add':
			return 'POST'
		case 'edit':
			return 'PUT'
		case 'delete':
			return 'DELETE'
		default:
			throw new Error('should never execute')
	}
}

export async function externalFetch<T>(
	onProgress: (rs: IRequestState<T>) => void,
	url: string,
	options: IRequestOptions,
	params?: any,
): Promise<IRequestState<T>> {
	if (inJestUnitTest()) {
		onProgress(requestState.createTestComplete())
		return requestState.createTestComplete()
	}

	l.defaults(options, { method: 'GET', data: null })

	requestNumber++
	const requestKey = `[${requestNumber}]`

	// log('api-common', requestKey, url, options.method, 'requesting')

	if (onProgress) {
		onProgress(requestState.createRequesting())
	}

	const processedData = l.cloneDeep(options.data || {})

	// Don't send some null data
	if (processedData != null && !processedData.id) {
		delete processedData.id
	}

	let rs: IRequestState<T> = {}

	if (params) {
		// Convert data to query params
		url += '?'
		const keys = Object.keys(params)
		l.forEach(keys, (key) => {
			if (!l.isNil(params[key])) {
				url +=
					encodeURIComponent(key) + '=' + encodeURIComponent(params[key]) + '&'
			}
		})
	}

	try {
		axios.interceptors.response.use(
			(response) => response,
			(error) => error.response,
		)
		const response = await axios({
			url,
			method: options.method,
			data: processedData,
			headers: options.headers,
			responseType: options.responseType,
			transformResponse: (response) => {
				try {
					return JSON.parse(response)
				} catch (ex) {
					return response
				}
			},
		})

		if (response.status === 200 || response.headers['x-response-warnings']) {
			let data = response.data
			if (data.entities && !options.entireResponse) {
				data = data.entities
			}

			rs = response.headers['x-response-warnings']
				? requestState.createWarning(
						response.headers['x-response-warnings'],
						data,
				  )
				: requestState.createSuccess(data)
		} else {
			rs = requestState.createError(response.data)
		}
	} catch (err) {
		if (err.response) {
			// The request was made and the server responded with a status code
			// that falls out of the range of 2xx
			rs = requestState.createError(
				'' + err.response.status + ':' + err.response.data,
			)
			logError(
				requestKey,
				url,
				options.method,
				'error',
				requestNumber,
				err.response.data,
			)
			logError(
				requestKey,
				url,
				options.method,
				'error',
				requestNumber,
				err.response.status,
			)
		} else {
			logError(
				requestKey,
				url,
				options.method,
				'error',
				requestNumber,
				url,
				err,
			)
			rs = requestState.createError('' + err)
		}
	}

	if (onProgress) {
		onProgress(rs)
	}
	return rs
}

export async function apiFetch<T>(
	onProgress: (_fetch: IRequestState<T>) => void,
	options: IRequestOptions,
	path: string,
	params?: any,
): Promise<IRequestState<T>> {
	if (inJestUnitTest()) {
		onProgress(requestState.createTestComplete())
		return requestState.createTestComplete()
	}
	const host = getApiHost()
	let authKey = sosUser.getAuthToken()
	if (options.alternateApiKey) {
		authKey = options.alternateApiKey
	}
	const url = host + '/' + path

	const selectedPrintStationCookie = getCookieByKey('x-printer-group-id')

	options.headers = l.assign(options.headers || {}, {
		Authorization: `Bearer ${authKey}`,
	})

	if (selectedPrintStationCookie && selectedPrintStationCookie !== 'null') {
		options.headers['x-printer-group-id'] = selectedPrintStationCookie
	}

	return await externalFetch(onProgress, url, options, params)
}

export async function apiFetchUntilNoMorePagedData<T, P>(
	onProgress: (_fetch: IRequestState<T[]>) => void,
	actionFetch: (
		onProgress: (_fetch: IRequestState<T[]>) => void,
		params: P,
	) => Promise<IRequestState<T[]>>,
	params: P,
	maxRecords?: number,
	take?: number,
): Promise<IRequestState<T[]>> {
	if (inJestUnitTest()) {
		return null
	}

	take = l.defaultTo(take, 100)
	maxRecords = l.defaultTo(maxRecords, take * 500)
	if (take === 0) {
		throw new Error('take cannot be 0')
	}

	onProgress({
		isFetching: true,
	})

	let data: T[] = []
	// This data is paged by the api so we need to keep getting data until we run out of data
	try {
		for (let skip = 0; ; skip += take) {
			if (skip > maxRecords) {
				throw new Error('possible infinite loop detected')
			}
			const actionResponse = await actionFetch(() => {},
			Object.assign({}, params, { take, skip }))

			if (actionResponse.error) {
				throw actionResponse.error
			}
			if (actionResponse.data && actionResponse.data.length > 0) {
				data = l.concat(data, actionResponse.data)
				continue // still need to see if we have more data
			}
			break // no more data
		}
		const result = {
			data: data,
			isFetching: false,
		}
		onProgress(result)
		return result
	} catch (err) {
		const errorResult = {
			isFetching: false,
			error: err,
		}
		onProgress(errorResult)
		return errorResult
	}
}

export const formatTimeForApi = (timeStore: ITimeStore): string => {
	if (timeStore.hour === unsetTime) {
		// Indicates 'anytime'
		return undefined
	}
	function padToTwoChars(num: number): string {
		if (l.isNumber(num)) {
			return num.toString().padStart(2, '0')
		} else {
			return num
		}
	}
	let hour = timeStore.hour
	if (hour === 12) {
		hour -= 12
	}
	if (timeStore.amPm === 'pm') {
		hour += 12
	}
	return `${padToTwoChars(hour)}:${padToTwoChars(timeStore.minute)}:00`
}
