import React, { ReactElement, useEffect, useState } from 'react'
import { Layout } from 'ui/pages/layout'
import { Button, Card, Col, Row } from 'react-bootstrap'
import { useOnce } from 'ui/components/hooks'
import { fetchTopLevelLocation, saveLocationAddress } from './state-management'
import { sosCompanyManagement } from './state-management'
import { TBrokerCompanyManagementTabs } from './state-management/sosCompanyManagement'
import { EditableAddressCard } from './address-card'
import { sosToast, Toast, ToastState } from 'common/components/toast'
import { t, tString } from 'ui/components/i18n/i18n'
import { sos2 } from 'ui/lib/state/sos2'
import { FileDropZoneData } from 'ui/components/shared/csvValidator/file-drop-zone'
import { saveLocationLogo } from './state-management/locationLogo'
import { PictureUpload } from 'ui/components/common/images/PictureUpload'
import { ChargeCodeCatalog } from './charge-code-catalog'
import {
	apiBrokerConfig,
	apiCharges,
	apiTmsServiceConfig,
	apiTypes,
} from 'ui/api'
import { fireAndForget } from 'ui/lib/async'
import { Center } from 'ui/components/layout/center'
import { Loader } from 'ui/components/common/loader'
import { Spacer } from 'ui/components/layout/spacer'
import { GenerateCodeCard } from './generate-code-card'
import { TabContent, TabItem2, Tabs2 } from 'ui/components/common/tabs'
import { l } from 'ui/lib/lodashImports'
import * as classes from './CompanyManagementTabs.module.scss'
import { BrokerConfigResponse } from 'ui/api/apiBrokerConfig'
import { Input } from 'ui/components/common/input'
import { AlignRight } from 'ui/components/layout/alignRight'

export const tPrefixCompanyManagement = 'page.companyManagement'

export const tPrefix = 'page.companyManagement.tabs'

const tabs: Array<{ name: TBrokerCompanyManagementTabs }> = [
	{
		name: 'company',
	},
	{
		name: 'branding',
	},
	{
		name: 'catalogs',
	},
	{
		name: 'codeGeneration',
	},
	{
		name: 'shipNotifications',
	},
]
export interface StateCompanyManagement {
	companyShipNotificationMessage: string
}

type TCompanyMangementTabs =
	| 'company'
	| 'branding'
	| 'catalogs'
	| 'codeGeneration'
	| 'shipNotifications'

export const emptyCodeRequest: apiTypes.UniqueCodeConfigResponse = {
	autoGenerate: false,
	prefix: '',
	currentValue: 0,
	suffix: '',
	numberOfDigits: 0,
}

export const BrokerCompanyManagementTabs = (): ReactElement => {
	const state = sos2.useSubscription(sosCompanyManagement.getSos())
	const [chargeCodeBookId, setChargeCodeBookId] = useState<string>(null)
	const [isFetchingChardCodeBookId, setIsFetchingChardCodeBookId] = useState<
		boolean
	>(false)
	const [brokerConfig, setBrokerConfig] = useState<BrokerConfigResponse>()
	const [providerCode, setProviderCode] = useState<
		apiTypes.UniqueCodeConfigResponse
	>({
		autoGenerate: false,
		currentValue: 0,
	})
	const [isFetchingProviderCode, setIsFetchingProviderCode] = useState<boolean>(
		false,
	)
	const [customerCode, setCustomerCode] = useState<
		apiTypes.UniqueCodeConfigResponse
	>({
		autoGenerate: false,
		currentValue: 0,
	})
	const [isFetchingCustomerCode, setIsFetchingCustomerCode] = useState<boolean>(
		false,
	)

	const [selectedTab, setSelectedTab] = useState<TBrokerCompanyManagementTabs>(
		'company',
	)

	useOnce(async () => {
		await fetchTopLevelLocation()
	})

	const successToast: ToastState = {
		type: 'success',
		body: tString('locationSuccess', tPrefixCompanyManagement),
		dataTestId: 'location-Success',
	}

	const errorToast: ToastState = {
		type: 'danger',
		header: tString('locationError', tPrefixCompanyManagement),
		body: state.apiError,
	}

	// const [key, setKey] = useState<TCompanyMangementTabs>('company')

	const getChargeBookList = async (): Promise<void> => {
		setIsFetchingChardCodeBookId(true)
		const bookResponse = await apiCharges.getBookList()

		if (bookResponse.error) {
			sosToast.sendApiErrorResponseToast(bookResponse)
		} else {
			if (bookResponse.data[0] === undefined || bookResponse.data[0] === null) {
				const createResponse = await apiCharges.createChargesBook(
					'Charge Codes Book',
				)
				setChargeCodeBookId(createResponse.data.id)
			} else {
				setChargeCodeBookId(bookResponse.data[0].id)
			}
		}
		setIsFetchingChardCodeBookId(false)
	}

	const getTmsServiceConfig = async (): Promise<void> => {
		setIsFetchingProviderCode(true)
		const response = await apiTmsServiceConfig.getTmsServiceConfig()

		let data = null
		if (response.error) {
			if (response.error.toString().match('400')) {
				const createResponse = await apiTmsServiceConfig.createTmsServiceConfig()
				if (createResponse.data) {
					data = createResponse.data
				} else {
					sosToast.sendApiErrorResponseToast(createResponse)
				}
			} else {
				sosToast.sendApiErrorResponseToast(response)
			}
		} else if (response.data) {
			data = response.data
		}
		if (data) {
			if (l.isNil(data.providerCodeConfig)) {
				setProviderCode(emptyCodeRequest)
			} else {
				setProviderCode(data.providerCodeConfig)
			}
		}
		setIsFetchingProviderCode(false)
	}

	const getBrokerConfig = async (): Promise<void> => {
		setIsFetchingCustomerCode(true)

		const response = await apiBrokerConfig.getBrokerConfig()

		if (response.error) {
			if (response.error?.toString().match('400')) {
				const creationResponse = await apiBrokerConfig.createBrokerConfig()
				if (creationResponse.data) {
					setCustomerCode(creationResponse.data.clientConfigCode)
					setBrokerConfig(creationResponse.data)
				} else {
					sosToast.sendApiErrorResponseToast(creationResponse)
				}
			} else {
				sosToast.sendApiErrorResponseToast(response)
			}
		} else {
			if (response.data) {
				if (l.isNil(response.data.clientConfigCode)) {
					setCustomerCode(emptyCodeRequest)
				} else {
					setCustomerCode(response.data.clientConfigCode)
				}

				setBrokerConfig(response.data)
			}
		}
		setIsFetchingCustomerCode(false)
	}

	useEffect(() => {
		fireAndForget(async () => {
			await getTmsServiceConfig()
		}, `Fetching Provider Code`)
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		fireAndForget(async () => {
			await getBrokerConfig()
		}, `Fetching Customer Code`)
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		sosCompanyManagement.getSos().change((ds) => {
			ds.apiSuccess = false
		})
	}, [state.apiSuccess])

	useEffect(() => {
		sosCompanyManagement.getSos().change((ds) => {
			ds.apiError = null
		})
	}, [state.apiError])

	useOnce(async () => await getChargeBookList())

	const switchTab = (tabSelected: TCompanyMangementTabs): void => {
		setSelectedTab(tabSelected)
	}

	return (
		<Layout>
			<div className='bootstrap-wrapper' style={{ width: '900px' }}>
				<Tabs2>
					{l.map(tabs, (c) => (
						<TabItem2
							isSelected={selectedTab === c.name}
							key={c.name}
							onClick={() => {
								if (selectedTab !== c.name) {
									switchTab(c.name)
								}
							}}
							dataTestId={`${c.name}-test-id`}
						>
							{t(l.camelCase(c.name), tPrefixCompanyManagement)}
						</TabItem2>
					))}
				</Tabs2>
				<div className={classes.companyManagementTabsContainer}>
					<TabContent
						isVisible={selectedTab === 'company'}
						renderer={() => (
							<EditableAddressCard
								tPrefix={tPrefixCompanyManagement}
								header='billTo'
								addressData={
									state.location?.defaults?.defaultBillingAddress?.address
								}
								fieldToUpdate='defaults.defaultBillingAddress.address'
								onSave={saveLocationAddress}
							/>
						)}
					/>
					<TabContent
						isVisible={selectedTab === 'branding'}
						renderer={() => (
							<PictureUpload
								onPictureUpload={async (fileData: FileDropZoneData) => {
									await saveLocationLogo(fileData)
								}}
								existingImageUrl={state.location.brandingImageUrl}
								headerText={t('companyLogo', tPrefixCompanyManagement)}
							/>
						)}
					/>
					<TabContent
						isVisible={selectedTab === 'catalogs'}
						renderer={() => (
							<>
								{chargeCodeBookId === null && !isFetchingChardCodeBookId ? (
									<Col xs={12}>
										<Spacer height={'10px'} />
										<Center>{t('noLinkedChargeCodes', tPrefix)}</Center>
										<Spacer height={'10px'} />
									</Col>
								) : isFetchingChardCodeBookId ? (
									<Col xs={12}>
										<Spacer height={'10px'} />
										<Center>
											<Loader isLoading={isFetchingChardCodeBookId} />
										</Center>
										<Spacer height={'10px'} />
									</Col>
								) : (
									<Col xs={12}>
										<ChargeCodeCatalog chargeCodeBookId={chargeCodeBookId} />
									</Col>
								)}
								<Spacer height={'20px'} />
							</>
						)}
					/>
					<TabContent
						isVisible={selectedTab === 'codeGeneration'}
						renderer={() => (
							<Row>
								<Col xs={6}>
									{!isFetchingProviderCode && (
										<GenerateCodeCard
											codeType={'provider'}
											code={providerCode}
											setCode={setProviderCode}
											tPrefix={'page.companyManagement.tabs.chargeCodeCatalog'}
										/>
									)}
								</Col>
								<Col xs={6}>
									{!isFetchingCustomerCode && (
										<GenerateCodeCard
											codeType={'customer'}
											code={customerCode}
											setCode={setCustomerCode}
											tPrefix={'page.companyManagement.tabs.chargeCodeCatalog'}
										/>
									)}
								</Col>
							</Row>
						)}
					/>
					<TabContent
						isVisible={selectedTab === 'shipNotifications'}
						renderer={() => (
							<Card>
								<Card.Header>
									{t('shipNotificationMessage', tPrefix)}
								</Card.Header>
								<Card.Body>
									<Row>
										<Col xs={6}>
											<Input
												value={
													brokerConfig?.shippingTrackingNotificationMessage
												}
												multiline={true}
												notResizable={true}
												onChange={(newVal: string) => {
													setBrokerConfig({
														...brokerConfig,
														shippingTrackingNotificationMessage: newVal,
													})
												}}
												testId={'broker-ship-notification-message-input'}
											/>
										</Col>
									</Row>
									<Row>
										<Col xs={6}>
											<AlignRight>
												<Button
													color={'green'}
													onClick={async () => {
														const result = await apiBrokerConfig.updateBrokerConfig(
															brokerConfig,
														)

														if (result.error) {
															sosToast.sendApiErrorResponseToast(result)
														} else {
															setBrokerConfig(result.data)
														}
													}}
													data-testId='ship-notifications-save-button'
												>
													{t('save', tPrefix)}
												</Button>
											</AlignRight>
										</Col>
									</Row>
								</Card.Body>
							</Card>
						)}
					/>
				</div>
				{/* <Tabs
					id='company-management-tab'
					activeKey={key}
					onSelect={(k: any) => setKey(k)}
					defaultActiveKey='company'
					variant='pills'
				>
					<Tab eventKey='company' title={t('company', tPrefix)}>
						<EditableAddressCard
							tPrefix={tPrefixCompanyManagement}
							header='billTo'
							addressData={
								state.location?.defaults?.defaultBillingAddress?.address
							}
							fieldToUpdate='defaults.defaultBillingAddress.address'
							onSave={saveLocationAddress}
						/>
					</Tab>
					<Tab eventKey='branding' title={t('branding', tPrefix)}>
						<PictureUpload
							onPictureUpload={async (fileData: FileDropZoneData) => {
								await saveLocationLogo(fileData)
							}}
							existingImageUrl={state.location.brandingImageUrl}
							headerText={t('companyLogo', tPrefixCompanyManagement)}
						/>
					</Tab>
					<Tab eventKey='catalogs' title={t('catalogs', tPrefix)}>
						<Row>
							{chargeCodeBookId === null && !isFetchingChardCodeBookId ? (
								<Col xs={12}>
									<Spacer height={'10px'} />
									<Center>{t('noLinkedChargeCodes', tPrefix)}</Center>
									<Spacer height={'10px'} />
								</Col>
							) : isFetchingChardCodeBookId ? (
								<Col xs={12}>
									<Spacer height={'10px'} />
									<Center>
										<Loader isLoading={isFetchingChardCodeBookId} />
									</Center>
									<Spacer height={'10px'} />
								</Col>
							) : (
								<Col xs={12}>
									<ChargeCodeCatalog chargeCodeBookId={chargeCodeBookId} />
								</Col>
							)}
							<Spacer height={'20px'} />
						</Row>
					</Tab>
					<Tab eventKey='codeGeneration' title={t('codeGeneration', tPrefix)}>
						<Row>
							<Col xs={6}>
								{!isFetchingProviderCode && (
									<GenerateCodeCard
										codeType={'provider'}
										code={providerCode}
										setCode={setProviderCode}
										tPrefix={'page.companyManagement.tabs.chargeCodeCatalog'}
									/>
								)}
							</Col>
							<Col xs={6}>
								{!isFetchingCustomerCode && (
									<GenerateCodeCard
										codeType={'customer'}
										code={customerCode}
										setCode={setCustomerCode}
										tPrefix={'page.companyManagement.tabs.chargeCodeCatalog'}
									/>
								)}
							</Col>
						</Row>
					</Tab>
				</Tabs> */}

				{/* Add these next two cards in once the data is available from the API */}
				{/* <EditableAddressCard
					tPrefix={tPrefix}
					header='remitTo'
					addressData={state.remitTo}
					onSave={() => {
						console.log('potato')
					}}
				/> */}
				{/* <EditableAddressCard
					tPrefix={tPrefix}
					header='companyHQ'
					addressData={state.companyHQ}
					onSave={() => {
						console.log('potato')
					}}
				/> */}
				{state.apiSuccess && <Toast toast={successToast} />}
				{state.apiError && <Toast toast={errorToast} />}
			</div>
		</Layout>
	)
}
