import React from 'react'
import { FC } from 'app/FunctionalComponent'
import { apiTypes } from 'ui/api'
import { ndash } from 'ui/components/common'
import { AccountingText } from 'ui/components/common/accounting-text/AccountingText'
import { IconHover } from 'ui/components/common/icon-hover/IconHover'
import { solidIcons } from 'ui/components/common/icon/solidIcons'
import { Loader } from 'ui/components/common/loader/Loader'
import { t, tDate, tString } from 'ui/components/i18n/i18n'
import { RateRow } from 'ui/components/rates-table/RatesTable'
import * as tableClasses from 'ui/components/rates-table/RatesTable.module.scss'
import { CarrierImage } from 'ui/components/shared/CarrierImage'
import { l } from 'ui/lib/lodashImports'
import { theme } from 'ui/theme'
import { CostContainer } from 'ui/pages/shipment-profile/shared/components/CostContainer'
import { BrokerShipmentProfileRateInteraction } from 'ui/pages/shipment-profile/broker/components/rate-interaction'
import * as classes from './BrokerShipmentProfileRatesTable.module.scss'
import { RateBookingDetails } from 'ui/pages/shipment-profile/shared/functions/rateBookingDetailsTypes'

export const BrokerShipmentProfileRatesTable: FC = (props: {
	shipment: apiTypes.BrokerShipmentResponse
	markupLogic: apiTypes.MarkupLogic
	tPrefix: string
	rateTableRows: RateRow[]
	isRating: boolean
	setRateBookingDetails: React.Dispatch<
		React.SetStateAction<RateBookingDetails>
	>
	rateBookingDetails: RateBookingDetails
}) => {
	const {
		shipment,
		markupLogic,
		tPrefix,
		rateTableRows,
		isRating,
		setRateBookingDetails,
		rateBookingDetails,
	} = props

	if (!shipment) {
		return null
	}

	const tPrefixTable = tPrefix + '.table'

	return (
		<div>
			{isRating && (
				<div className='d-flex flex-row'>
					<div className='p-2'>
						<Loader isLoading={true} testId='is-rating-spinner' />
					</div>
					<div className='p-2'>{t('loadingRates', tPrefix)}</div>
				</div>
			)}

			<table
				className={tableClasses.tableRates}
				cellPadding={0}
				cellSpacing={0}
				data-testid='rates-table'
			>
				<thead>
					<tr>
						<th></th>
						<th>{t('providerName', tPrefixTable)}</th>
						<th>{t('serviceLevel', tPrefixTable)}</th>
						<th
							className={theme.addClass(
								tableClasses.center,
								classes.methodHeader,
							)}
						>
							{t('method', tPrefixTable)}
						</th>
						<th className={tableClasses.center}>
							{t('quoteType', tPrefixTable)}
						</th>
						<th className={tableClasses.center}>
							{t('buyPrice', tPrefixTable)}
						</th>
						<th className={tableClasses.center}>
							{t('sellPrice', tPrefixTable)}
						</th>
						<th className={tableClasses.center}>{t('markup', tPrefixTable)}</th>
						<th
							className={theme.addClass(
								tableClasses.center,
								classes.transitHeader,
							)}
						>
							{t('transit', tPrefixTable)}
						</th>
						<th className={tableClasses.left}>{t('delivery', tPrefixTable)}</th>
						<th className={tableClasses.left}>
							{t('guaranteed', tPrefixTable)}
						</th>
						<th></th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{l.map(rateTableRows, (rate, rateIdx) => {
						const buy = rate.buyPrice
						const sell = rate.sellPrice
						let markup = 0
						if (buy !== 0) {
							markup = ((sell - buy) / buy) * 100
						}
						const isRed = markup < 0

						return (
							<tr key={rateIdx} data-testid={`rates-table-${rateIdx}`}>
								<td>
									<CarrierImage
										carrier={rate.providerName}
										style={{ maxHeight: '25px', margin: '5px' }}
									/>
								</td>
								<td data-testid={'broker-carrier'}>
									<div>
										{rate.providerName
											? rate.providerName.replace(/_/g, ' ')
											: ' '}
									</div>
								</td>
								<td>{rate.serviceLevel || ndash}</td>
								<td className={tableClasses.center}>
									{rate.method
										? t(rate.method, 'page.makeOffer.shipmentIs')
										: ndash}
								</td>
								<td className={tableClasses.center}>
									{rate.quoteType
										? t(rate.quoteType, 'page.shipmentProfile.rates.quoteTypes')
										: ndash}
								</td>
								<td
									className={tableClasses.center}
									data-testid={'broker-buy-price'}
								>
									<CostContainer cents={buy} />
								</td>
								<td
									className={tableClasses.center}
									data-testid={'broker-sell-price'}
								>
									<CostContainer cents={sell} />
								</td>
								<td
									className={tableClasses.center}
									data-testid={'broker-markup'}
								>
									<AccountingText isRed={isRed}>
										{Math.round(markup) + '%'}
									</AccountingText>
								</td>
								<td
									className={tableClasses.center}
									data-testid={'broker-transit-days'}
								>
									<div>
										{rate.transit
											? `${rate.transit} ${tString('common.days')}`
											: ndash}
									</div>
								</td>
								<td className={tableClasses.left}>
									{tDate(rate.delivery) || ndash}
								</td>
								<td className={tableClasses.left}>
									{tDate(rate.guaranteed) || ndash}
								</td>
								<td>
									<div style={{ paddingLeft: '20px' }}>
										<BrokerShipmentProfileRateInteraction
											rateRow={rate}
											tPrefix={tPrefixTable}
											shipment={shipment}
											markupLogic={markupLogic}
											shipmentIsRating={isRating}
											setRateBookingDetails={setRateBookingDetails}
											rateBookingDetails={rateBookingDetails}
										/>
									</div>
								</td>
								<td>
									{rate.message ? (
										<IconHover
											color='red'
											popupPosition='topLeft'
											testId='message-flag'
											icon={solidIcons.faFlag}
										>
											{rate.message}
										</IconHover>
									) : null}
								</td>
							</tr>
						)
					})}
				</tbody>
			</table>
		</div>
	)
}
