import React, { useState } from 'react'
import { Icon } from 'ui/components/common/icon/Icon'
import { t } from 'ui/components/i18n/i18n'
import { l } from 'ui/lib/lodashImports'
import * as commonClasses from 'ui/theme/common.module.scss'
import { Checkbox } from '../common/checkbox/Checkbox'
import { IDataTableHeader } from './IDataTableHeader'
import { IDataTableHeaderProcessed } from './IDataTableHeaderProcessed'
import { Button } from 'ui/components/common/button'
import { SmallButton } from 'ui/components/common/small-button'
import { solidIcons } from 'ui/components/common/icon'
import { Popup } from '../common/popup'
import * as classes from './TableStyles.module.scss'

export const FilterHeaders = <T extends any>(props: {
	processedHeaders: IDataTableHeaderProcessed<T>[]
	onReset?: () => void
	onToggleHeader?: (header: IDataTableHeader<T>) => void
	tPrefix?: string
}): React.ReactElement => {
	const { tPrefix, processedHeaders, onReset, onToggleHeader } = props
	const [filterColumnsOpen, setFilterColumnsOpen] = useState(false)

	const groupedProcessedHeaders = l.groupBy(
		l.filter(processedHeaders, (c) => !c.untoggleable),
		(header) => header.headerGroup,
	)
	const headerGroups = Object.keys(groupedProcessedHeaders)

	return (
		<div
			onClick={() => {
				setFilterColumnsOpen(!filterColumnsOpen)
			}}
		>
			<Popup
				position='bottomLeft'
				breakerClassName={classes.filterPopupBreaker}
				isOpen={filterColumnsOpen}
				content={
					<div className={classes.filterPopup}>
						<Button onClick={onReset} color={'blue'} minWidth={'100%'}>
							{t('component.table.resetTableHeaders')}
						</Button>
						{l.map(headerGroups, (c, cIdx) => (
							<div key={cIdx}>
								{cIdx > 0 ? <hr /> : null}
								<div>{c !== 'undefined' ? t(c) : ''}</div>
								{l.map(groupedProcessedHeaders[c], (d, dIdx) => (
									<div key={dIdx}>
										<Checkbox
											value={!d.hide}
											onChange={() => {
												onToggleHeader(d)
											}}
										>
											<label>
												{t(d.t || '' + d.field, tPrefix)}
												{d.label}
											</label>
										</Checkbox>
									</div>
								))}
							</div>
						))}
					</div>
				}
			>
				<SmallButton minWidth={'0px'} color={'lightGray'}>
					<Icon
						className={commonClasses.clickable}
						icon={solidIcons.faFilter}
					/>
				</SmallButton>
			</Popup>
		</div>
	)
}
