import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { tString } from 'ui/components/i18n/i18n'
import { twentyFourHourToTwelveHourTime } from 'ui/lib/time/time'
import { addClass, getBorderColor, getTextColor } from 'ui/theme/theme'
import * as classes from '../../dock-scheduler/DockScheduler.module.scss'
import { tPrefixDockConfig } from './DockConfigurationPage'

export const TimeBox: FC = (props: { hour: number }) => {
	let className = addClass(classes.box, getBorderColor('gray'))
	className = addClass(getTextColor('gray'), className)
	className = addClass(classes.closedBox, className)
	className = addClass(classes.rowWithRoundedTopCorners, className)
	className = addClass(classes.rowWithRoundedBottomCorners, className)

	const { amPm, hour } = twentyFourHourToTwelveHourTime(props.hour)

	return (
		<div className={className}>
			{'' + hour + tString(amPm, tPrefixDockConfig)}
		</div>
	)
}
