import { FC } from 'app/FunctionalComponent'
import GoogleMapReact from 'google-map-react'
import React from 'react'
import { t } from 'ui/components/i18n/i18n'
import { StaticMap } from '../../spot-quote/map'
import { googleMapApiKey } from 'ui/secrets/secrets'
import { sosComponentExamples } from 'ui/state/'
import { Section } from './Section'

const AnyReactComponent: FC = (props: {
	text: string
	lat: number
	lng: number
}) => <div>{props.text}</div>

const SimpleMapFunc: FC = (props: {}) => {
	return (
		// Important! Always set the container height explicitly
		<div style={{ height: '100vh', width: '100%' }}>
			<GoogleMapReact
				bootstrapURLKeys={{ key: googleMapApiKey }}
				defaultCenter={{
					lat: 59.95,
					lng: 30.33,
				}}
				defaultZoom={15}
			>
				<AnyReactComponent lat={59.955413} lng={30.337844} text='My Marker' />
			</GoogleMapReact>
		</div>
	)
}

const DynoMap: FC = (props: {}) => {
	return (
		<div>
			<SimpleMapFunc />
		</div>
	)
}

export const SectionMaps: FC = (props: {
	stateComponentExamples: sosComponentExamples.IStateComponentExamples
}) => {
	return (
		<Section
			title={t('page.componentExamples.maps')}
			stateComponentExamples={props.stateComponentExamples}
			name='maps'
		>
			MAAAAAPS
			<StaticMap addresses={[]} width={100} height={100} />
			<DynoMap />
		</Section>
	)
}
