import React from 'react'
import { t, testId } from 'ui/components/i18n/i18n'
import { l } from 'ui/lib/lodashImports'
import { formValidator, validation } from 'ui/lib/validation/'
import { theme } from 'ui/theme'
import * as commonStyles from 'ui/theme/common.module.scss'
import { Optional } from '../common/badge/Optional'
import { Recommended } from '../common/badge/Recommended'
import { Icon, solidIcons } from '../common/icon'
import { HelpHover } from '../common/icon-hover/IconHover'
import classes from './FormStacked.module.scss'
import { IFormData } from './IFormData'

export function FormStackedItem<T>(props: {
	field: keyof T
	formData: IFormData<T>
	children: React.ReactNode
	helpText?: React.ReactNode
	keepSpaceForRequired?: boolean
	label?: React.ReactNode
	hideLabel?: boolean
	hideOptional?: boolean
	showWarning?: boolean
	className?: string
}): React.ReactElement {
	const { field, hideLabel, formData } = props

	const meta = props.formData.metadata[props.field]
	const val: any = formData.form[field]
	const isAValue = validation.isAValue(val)
	const showOptional = !meta.required && !props.hideOptional
	const showRecommended = meta.recommended && !isAValue

	let error = null
	if (isAValue) {
		error = formValidator.validateValue(meta, val, false, formData.form)
	}

	let formStackedItemClassName = classes.item

	formStackedItemClassName = theme.addClassIf(
		!l.isNil(props.className),
		props.className,
		classes.item,
	)

	return (
		<div className={formStackedItemClassName}>
			<div>
				{!hideLabel && (
					<label htmlFor={'' + field} className={classes.label}>
						{props.label && props.label}
						{!props.label &&
							t(formData.tPrefix + '.' + (meta.label || props.field))}
						{props.helpText && (
							<div className={commonStyles.fontSmall}>
								<HelpHover>{props.helpText}</HelpHover>
							</div>
						)}{' '}
						{showOptional && <Optional />}
						{showRecommended && <Recommended />}
						{props.showWarning && (
							<Icon
								icon={solidIcons.faExclamationTriangle}
								className={theme.getTextColor('orange')}
							/>
						)}
					</label>
				)}
			</div>
			<div>
				<div>{props.children}</div>
				<div
					className={
						classes.formRow +
						' ' +
						classes.required +
						(props.keepSpaceForRequired
							? ' ' + classes.keepSpaceForRequired
							: '')
					}
				>
					{/* {showOptional && <Optional />} */}
					{/* {showRecommended && <Recommended />} */}
					{testId('form-' + field)}
				</div>
			</div>
			{meta.description && (
				<div className={classes.descriptionRow}>
					<div>
						<div className={classes.description}>
							{t(formData.tPrefix + '.' + meta.description)}
						</div>
					</div>
				</div>
			)}
			{error && !showOptional && (
				<div className={classes.errorRow}>
					<div className={classes.td}>
						<div className={classes.error}>{t(error)}</div>
					</div>
				</div>
			)}
		</div>
	)
}
