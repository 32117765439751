import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { t } from 'ui/components/i18n/i18n'
import { l } from 'ui/lib/lodashImports'
import { SimpleLink } from '../router'
import classes from './Breadcrumb.module.scss'

export interface IBreadcrumbEntry {
	t?: string
	label?: string
	url?: string
}

export const Breadcrumb: FC = (props: {
	entries: IBreadcrumbEntry[]
	testId?: string
}) => {
	return (
		<div className={classes.breadcrumb}>
			{l.map(props.entries, (c, cIdx) => (
				<React.Fragment key={cIdx}>
					{cIdx > 0 && <div className={classes.breadcrumbSeparator}>{'>'}</div>}
					<div>
						{c.url ? (
							<SimpleLink href={c.url}>
								{c.t && t(c.t)}
								{c.label}
							</SimpleLink>
						) : (
							<div>
								{c.t && t(c.t)}
								{c.label}
							</div>
						)}
					</div>
				</React.Fragment>
			))}
		</div>
	)
}
