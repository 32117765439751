import React from 'react'
import { t } from 'ui/components/i18n/i18n'
import { Colors } from 'ui/theme/theme'
import { theme } from 'ui/theme'

import classes from './Badge.module.scss'
import { l } from 'ui/lib/lodashImports'
import { FC } from 'app/FunctionalComponent'

export const Badge: FC = (props: {
	color?: Colors
	testId?: string
	children: React.ReactNode
}) => {
	const color = props.color || 'gray'
	return (
		<div
			className={`${classes.badge} ${theme.getColor(color)}`}
			data-testid={props.testId}
		>
			{props.children}
		</div>
	)
}
export const BigBadge: FC = (props: {
	color?: Colors
	className?: string
	children: React.ReactNode
	testId?: string
}) => {
	let className = classes.bigBadge
	className = theme.addClassIf(
		!l.isNil(props.color),
		theme.getBackgroundWithTextColor(props.color),
		className,
	)

	className = theme.addClass(props.className, className)

	return (
		<div className={className} data-testid={props.testId}>
			{props.children}
		</div>
	)
}

export const BadgeInvalid: FC = (props: {
	children: React.ReactNode
	className?: string
}) => {
	let className = classes.bigBadge
	className = theme.addClass(props.className, className)
	className = theme.addClass(theme.getDisabledColors(), className)

	return <div className={className}>{props.children}</div>
}

export const BadgeError: FC = (props: { children?: React.ReactNode }) => {
	return (
		<Badge color='red'>{props.children || t('component.badge.error')}</Badge>
	)
}
export const BadgeSuccess: FC = () => {
	return <Badge color='green'>{t('component.badge.ok')}</Badge>
}
export const BadgeWarning: FC = () => {
	return <Badge color='orange'>{t('component.badge.warning')}</Badge>
}
