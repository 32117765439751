import { parse } from 'json2csv'
import { apiBroker, apiShipments, apiTypes } from 'ui/api'
import { getDocument } from 'ui/components/common/router/windowUtils'
import { tString } from 'ui/components/i18n/i18n'
import { DataCellExportFormatter, IDataTableHeader } from 'ui/components/table'
import { elasticSearchSortBuilder } from 'ui/lib/elasticSearch'
import { l } from 'ui/lib/lodashImports'
import { IShipmentListMappedData, sosUser } from 'ui/state'
import {
	brokerHeaders,
	defaultBrokerShipmentListSorts,
	defaultTmsShipmentListSorts,
	tmsHeaders,
} from './ShipmentsListHeaders'
import { sosShipmentsList } from './state'
import { getSos, getUrlState } from './state/sosShipmentsList'
import {
	createBrokerShipmentQuery,
	createShipmentQuery,
} from './state/sosShipmentsList_createShipmentQuery'
import { processShipments } from './state/sosShipmentsList_processShipments'

export async function exportShipmentsList(): Promise<void> {
	sosShipmentsList.toggleModal()
	const shipments = await fetchExportShipments()
	const headers = getHeaders()
	const fields = buildExportFields(headers)
	const opts = { fields }
	const csv = parse(shipments, opts)
	downloadExport(csv)
	sosShipmentsList.toggleModal()
	resetExportState()
}

async function fetchExportShipments(): Promise<IShipmentListMappedData[]> {
	const state = getSos().getState()

	const filterLocationIds = await sosUser.getLocationFilterForUser(
		state.filterLocation.value,
	)
	const { shipmentStatusFilter } = getUrlState()

	let query: string
	let nestedFieldQuery: string
	let wildcards: string[]
	let sorts: string
	if (sosUser.isUserBroker()) {
		const queryObj = createBrokerShipmentQuery(state, shipmentStatusFilter)
		query = queryObj.query
		nestedFieldQuery = queryObj.nestedQuery
		wildcards = queryObj.wildcards
		sorts = elasticSearchSortBuilder(
			state.shipmentListDataTableState.sortOn,
			state.shipmentListDataTableState.sortReversed,
			defaultBrokerShipmentListSorts,
			brokerHeaders,
		)
	} else {
		const queryObj = createShipmentQuery(
			state,
			shipmentStatusFilter,
			filterLocationIds,
		)
		query = queryObj.query
		nestedFieldQuery = queryObj.nestedQuery
		wildcards = queryObj.wildcards
		sorts = elasticSearchSortBuilder(
			state.shipmentListDataTableState.sortOn,
			state.shipmentListDataTableState.sortReversed,
			defaultTmsShipmentListSorts,
			tmsHeaders,
		)
	}

	const onProgress = (rs): void => {
		getSos().change((ds) => {
			ds.exportTotalSteps = ds.pager.total
			if (rs.isFetching) {
				ds.exportIsRunning = rs.isFetching
			}
		})
	}

	const shipmentsToExport: IShipmentListMappedData[] = []
	const take = 100
	let skip = 0

	while (shipmentsToExport.length < state.pager.total) {
		const requestBody: apiTypes.ApiListRequest = {
			query,
			nestedFieldQuery,
			wildcards,
			take: take,
			skip: skip * take,
			sort: sorts,
		}
		let result
		let clientConfigs: apiTypes.ClientConfigResponse[]
		if (sosUser.isUserBroker()) {
			result = await apiBroker.fetchBrokerShipments(onProgress, requestBody)
			clientConfigs = await sosUser.getClientConfigs(
				l.uniq(
					result.data.entities.map(
						(c: apiTypes.BrokerShipmentResponse) => c.contractId,
					),
				),
			)
		} else {
			result = await apiShipments.fetchShipments(onProgress, requestBody)
		}
		const processedShipments = processShipments(result.data, clientConfigs)
		getSos().change((ds) => {
			ds.exportCompletedSteps = processedShipments.length
		})
		l.forEach(processedShipments, (shipment) => {
			shipmentsToExport.push(shipment)
		})
		skip++
	}
	return shipmentsToExport
}

function getHeaders(): string[] {
	const state = getSos().getState()
	let headers: string[]

	if (sosUser.isUserBroker()) {
		headers = l.difference(
			l.map(brokerHeaders, (c) => c.field),
			state.brokerShipmentListDataTableState.hiddenHeaders,
		)
	} else {
		headers = l.difference(
			l.map(tmsHeaders, (c) => c.field),
			state.shipmentListDataTableState.hiddenHeaders,
		)
	}
	return headers
}

function buildExportFields(headers: string[]): any[] {
	const exportFields: any[] = []
	l.forEach(headers, (header) => {
		let shipmentValue: IDataTableHeader<IShipmentListMappedData>
		if (sosUser.isUserBroker()) {
			shipmentValue = l.find(brokerHeaders, ['field', header])
		} else {
			shipmentValue = l.find(tmsHeaders, ['field', header])
		}
		let csvStyleFormatter: DataCellExportFormatter<IShipmentListMappedData>
		if (shipmentValue.exportFormatter) {
			csvStyleFormatter = (row) => {
				return shipmentValue.exportFormatter(row[header], row)
			}
		}
		exportFields.push({
			label: tString(header, 'page.shipmentsList.table'),
			value: csvStyleFormatter || shipmentValue.field,
		})
	})
	return exportFields
}

function downloadExport(csv): void {
	const hiddenElement = getDocument().createElement('a')
	hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
	hiddenElement.target = '_blank'
	hiddenElement.download = 'shipmentsList.csv'
	hiddenElement.click()
}

function resetExportState(): void {
	getSos().change((ds) => {
		ds.exportTotalSteps = 1
		ds.exportCompletedSteps = 0
		ds.exportIsRunning = false
	})
}
