import { apiCommon, apiTypes } from '.'
import { IRequestState } from './requestState'

export async function getWebsocketUrl(
	onProgress: (rs: IRequestState<apiTypes.KeyValuePairResponse>) => void,
	action: 'getRates' | 'generateManifest',
): Promise<IRequestState<apiTypes.KeyValuePairResponse>> {
	const url = 'websocket-url/' + action
	const result = await apiCommon.apiFetch(onProgress, { method: 'GET' }, url)
	return result
}
