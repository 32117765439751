import { LatLngTuple } from 'leaflet'

/*
	I grabbed this from Valhalla's examples https://github.com/valhalla/demos
	It's an algorithm for decoding a list of coordinates that were encoded using
	Google's polyline encoding algorithm: https://developers.google.com/maps/documentation/utilities/polylinealgorithm
*/
export const decodeValhallaRoute = (
	encodedCoordinates: string,
): LatLngTuple[] => {
	let precision = 6
	const len = encodedCoordinates.length,
		array = []
	let index = 0,
		lat = 0,
		lng = 0

	precision = Math.pow(10, -precision)

	while (index < len) {
		let b: number,
			shift = 0,
			result = 0
		do {
			b = encodedCoordinates.charCodeAt(index++) - 63
			result |= (b & 0x1f) << shift
			shift += 5
		} while (b >= 0x20)
		const dlat = result & 1 ? ~(result >> 1) : result >> 1
		lat += dlat
		shift = 0
		result = 0
		do {
			b = encodedCoordinates.charCodeAt(index++) - 63
			result |= (b & 0x1f) << shift
			shift += 5
		} while (b >= 0x20)
		const dlng = result & 1 ? ~(result >> 1) : result >> 1
		lng += dlng
		//array.push( {lat: lat * precision, lng: lng * precision} );
		array.push([lat * precision, lng * precision])
	}
	return array
}
