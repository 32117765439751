import React, { useState } from 'react'
import { Button } from 'ui/components/common/button'
import { Modal } from 'ui/components/common/modal'
import { t } from 'ui/components/i18n/i18n'
import { Center } from 'ui/components/layout/center'
import { Icon, solidIcons } from 'ui/components/common/icon'
import { FC } from 'ui/FunctionalComponent'
import * as classes from './NotifyCustomerModal.module.scss'
import { sosCustomerManagement } from '../..'

const tPrefixModal = 'page.customerManagement.modalNotifyCustomer'

export const NotifyCustomerModal: FC = (props: {
	isModalOpen: boolean
	onModalClose: () => void
	onSave: () => void
	onDontSave: () => void
}) => {
	const [isSavingCustomerDetails, setIsSavingCustomerDetails] = useState<
		boolean
	>(false)

	const onSave = async (): Promise<void> => {
		setIsSavingCustomerDetails(true)
		await sosCustomerManagement.saveCustomerDetails()
		setIsSavingCustomerDetails(false)

		props.onModalClose()

		if (props.onSave) {
			props.onSave()
		}
	}

	const onDontSave = (): void => {
		sosCustomerManagement.discardChanges()
		props.onModalClose()
		props.onDontSave()
	}

	const onClose = (): void => {
		props.onModalClose()
	}

	return (
		<Modal
			content={() => (
				<div data-testid={'notify-customer-modal'}>
					<div className={classes.notifyCustomerModal}>
						<div className={classes.warningIcon}>
							<Icon
								icon={solidIcons.faExclamationTriangle}
								className={classes.icon}
								color='yellow'
							/>
						</div>

						<div className={classes.notifyMessage}>
							{t('notifyCustomerModalMessage', tPrefixModal)}
						</div>
					</div>
					<div>
						<Center>
							<Button
								color={'green'}
								onClick={onSave}
								testId={'notify-customer-modal-save-button'}
								isSpinning={isSavingCustomerDetails}
							>
								{t('save', tPrefixModal)}
							</Button>

							<Button
								color={'red'}
								onClick={onDontSave}
								testId={'notify-customer-modal-dont-save-button'}
							>
								{t('dontSave', tPrefixModal)}
							</Button>

							<Button
								color={'blue'}
								onClick={onClose}
								testId={'notify-customer-modal-stay-on-page-button'}
							>
								{t('stayOnPage', tPrefixModal)}
							</Button>
						</Center>
					</div>
				</div>
			)}
			isOpen={props.isModalOpen}
			onModalClose={onClose}
			closeModalX={true}
			title={t('notifyCustomerModalTitle', tPrefixModal)}
		/>
	)
}
