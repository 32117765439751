import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { ISelectOptions } from 'ui/components/common/select/ISelectOptions'
import { Select } from 'ui/components/common/select/Select'
import { sosTheme } from 'ui/state/'
import { theme } from 'ui/theme'
import { Themes } from 'ui/theme/theme'

export const ThemePicker: FC = (props: {}) => {
	const stateTheme = sosTheme.useSubscribe()
	theme.updateTheme(stateTheme.theme)

	const options: ISelectOptions[] = [
		{
			value: 'base',
			label: 'Base',
		},
		{
			value: 'gray',
			label: 'Gray',
		},
		{
			value: 'green',
			label: 'Green',
		},
		{
			value: 'tms2',
			label: 'TMS2',
		},
		{
			value: 'tms3',
			label: 'TMS3',
		},
		{
			value: 'tmsDark',
			label: 'Dark',
		},
	]

	return (
		<div>
			Theme
			<Select
				options={options}
				value={stateTheme.theme}
				onChange={async (newVal) => {
					await sosTheme.setTheme(newVal as Themes)
				}}
			/>
		</div>
	)
}
