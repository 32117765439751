import parse from 'csv-parse'
import { ITransformationInformation } from 'ui/data/lightningIntegration/lightning_inbound'
import { l } from 'ui/lib/lodashImports'
import { log } from 'ui/lib/log'
import { validation } from 'ui/lib/validation'
import { createSos } from './secretaryOfState'

export interface IStateCsvValidator {
	columns: string[]
	rawRecords: any
	records: ICsvRecord[]
	itemLines: any[]
	errors: string[]
	warnings: string[]
	isLoading: boolean
}

const initialState: IStateCsvValidator = {
	columns: [],
	rawRecords: null,
	records: null,
	itemLines: null,
	errors: null,
	warnings: null,
	isLoading: false,
}

export interface ICsvRecord {
	result: 'error' | 'warning' | 'success'
	errors: string[]
	warnings: string[]
	rawRecord: any
}

const { stateManager, useSubscribe } = createSos(
	'csv-validator',
	'1.0.0',
	initialState,
	{
		useLocalStorage: false,
		// localStorageFields: ['columns', 'records'],
	},
)
export { useSubscribe }

export function parseCsv(
	fileContent: string,
	validColumnText: string,
	transformationInformation: ITransformationInformation[],
): void {
	let columns: string[] = []
	const rawRecords = []
	const records: ICsvRecord[] = []
	const errors: string[] = []
	const itemLines = []

	stateManager.produce((ds) => {
		ds.isLoading = true
		ds.rawRecords = rawRecords
		ds.records = records
		ds.columns = columns
		ds.errors = null
		ds.itemLines = itemLines
	})

	// See: https://csv.js.org/parse/options/columns/
	const parser = parse(fileContent, {
		columns: (header) => {
			columns = header
			return columns
		},
		relax_column_count: true,
		skip_lines_with_error: true,
	})

	parser.on('readable', () => {
		let rawRecord = null
		while ((rawRecord = parser.read())) {
			rawRecords.push(rawRecord)
			const record: ICsvRecord = {
				rawRecord: rawRecord,
				result: 'success',
				warnings: [],
				errors: [],
			}
			validateRecord(record, transformationInformation)
			records.push(record)
		}
	})

	parser.on('skip', (err) => {
		log('csv-validator', 'line error', err)
		records.push({
			rawRecord: {},
			result: 'warning',
			warnings: ['' + err],
			errors: [],
		})
	})
	parser.on('error', (err) => {
		log('csv-validator', 'parse error', err)
		records.push({
			rawRecord: {},
			result: 'error',
			warnings: [],
			errors: ['' + err],
		})
	})

	parser.on('end', () => {
		// Validate columns
		const validColumns = validColumnText.split(', ')
		columns = columns.filter((col) => col !== '')
		const extraColumns = l.difference(columns, validColumns)
		if (extraColumns.length !== 0) {
			errors.push('Extra columns: ' + l.join(extraColumns, ', '))
		}
		const missingColumns = l.difference(validColumns, columns)
		if (missingColumns.length !== 0) {
			errors.push('Missing columns: ' + l.join(missingColumns, ', '))
		}

		stateManager.produce((ds) => {
			ds.rawRecords = rawRecords
			ds.records = records
			ds.columns = columns
			ds.errors = errors
			ds.itemLines = itemLines
			ds.isLoading = false
		})
	})
	parser.end()
}

export function validateRecord(
	record: ICsvRecord,
	transformationInformation: ITransformationInformation[],
): void {
	if (l.isEmpty(record.rawRecord) || l.every(record.rawRecord, (c) => !c)) {
		record.warnings.push('Empty line')
	} else {
		_validateRecord(record, transformationInformation)
	}
	if (record.warnings.length > 0) {
		record.result = 'warning'
	}
	if (record.errors.length > 0) {
		record.result = 'error'
	}
}

function _validateRecord(
	record: ICsvRecord,
	transformationInformations: ITransformationInformation[],
): void {
	l.forEach(transformationInformations, (c: ITransformationInformation) => {
		if (c.value_from_field) {
			const val = record.rawRecord[c.value_from_field]

			if (c.required) {
				if (null == val || val === '') {
					record.errors.push(`Missing required field ${c.value_from_field}`)
				}
			}
			if (c.data_type && val != null && val !== '') {
				if (c.data_type === 'string') {
					// No validation required, everything is a string
				}
				if (c.data_type === 'number') {
					// The doc says number but means integer
					const error = validation.mustBeAnInteger(
						val,
						`${c.value_from_field} must be an integer`,
					)
					if (error) {
						record.errors.push(error)
					}
				}
				if (c.data_type === 'lower_case_string') {
					const error = validation.mustBeLowercase(
						val,
						`${c.value_from_field} must be lowercase`,
					)
					if (error) {
						record.errors.push(error)
					}
				}
				if (c.data_type === 'boolean') {
					const error = validation.mustBeBoolean(
						val,
						`${c.value_from_field} must be true or false`,
					)
					if (error) {
						record.errors.push(error)
					}
				}
			}
		}

		if (c.save_as_object_in_array) {
			_validateRecord(record, c.override_fields)
		}
	})
}

export function transformToItem(
	record: ICsvRecord,
	transformed: any,
	transformationInformation: ITransformationInformation[],
): any {
	if (!transformed) {
		transformed = {}
	}
	_transformToItem(record, transformed, transformationInformation)
	return transformed
}

function _transformToItem(
	record: ICsvRecord,
	transformed: any,
	transformationInformation: ITransformationInformation[],
): any {
	l.forEach(transformationInformation, (c: ITransformationInformation) => {
		if (c.value_from_field) {
			const inputValue = record.rawRecord[c.value_from_field]
			if (c.data_cleanup_method) {
				// Do something // Load data cleanup method
			}
			transformed[c.field] = inputValue
		}
		if (c.value) {
			transformed[c.field] = c.value
		}
		if (c.save_as_object_in_array) {
			// Recurse to build array
			const array = transformed[c.array_field_name_to_save_as] || []
			array.push(_transformToItem(record, {}, c.override_fields))
			transformed[c.array_field_name_to_save_as] = array
		}
	})
	return transformed
}
