import { IAddress } from 'ui/components/shared/address'
import { r } from 'ui/lib/ramdaImports'
import { apiCommon, apiTypes } from './'
import { IRequestState } from './requestState'

export type AddressValidationProvider = 'Fedex' | 'UPS'

export const fetchValidatedAddress = async (
	onProgress: (rs: IRequestState<apiTypes.AddressValidationResponse>) => void,
	data: IAddress,
	provider: AddressValidationProvider,
): Promise<IRequestState<apiTypes.AddressValidationResponse>> => {
	const data2: IAddress = r.mergeRight(
		{
			street1: '',
			street2: '',
			street3: '',
			city: '',
			state: '',
			zip: '',
			country: '',
		},
		data,
	)
	const result = await apiCommon.apiFetch(
		onProgress,
		{ method: 'POST', data: data2 },
		'providers/addresses/validate',
		{ provider },
	)
	return result
}
