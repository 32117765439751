import { FC } from 'app/FunctionalComponent'
import React from 'react'
import {
	FormStacked,
	FormStackedLayout,
	FormStackedSelect,
	FormStackedTextInput,
	IFormData,
} from 'ui/components/form'
import { FlexRow } from 'ui/components/layout/flexRow'
import {
	freightNewQuoteDetailsFormMetadata,
	INewQuoteDetailsForm,
	vendorNewQuoteDetailsFormMetadata,
} from 'ui/forms/formNewQuoteDetails'
import classes from 'ui/pages/new-quote/newQuotePage.module.scss'
import { FlatbedAccessorials, RefrigeratedAccessorials } from '.'
import { newQuoteEquipmentTypesMetadata } from './EquipmentTypes'
import { sosNewQuote } from './state'
import { IStateNewQuote } from './state/sosNewQuote'

export const FormDetails: FC = (props: {
	state: IStateNewQuote
	sendShippingNotification?: boolean
}) => {
	const { state } = props
	const formData: IFormData<INewQuoteDetailsForm> = {
		form: state.formNewQuoteDetails,
		metadata: freightNewQuoteDetailsFormMetadata,
		onUpdateForm: sosNewQuote.updateNewQuoteDetailsForm,
		tPrefix: 'page.newQuote.newQuoteDetailsForm',
	}

	return (
		<div>
			<FormStacked>
				<FlexRow>
					<div>
						<FormStackedTextInput
							formData={formData}
							field='brokerReferenceNumber'
						/>
						<FormStackedTextInput formData={formData} field='bidDuration' />
						<FormStackedTextInput formData={formData} field='sealNumber' />
						<FormStackedTextInput formData={formData} field='trailerLength' />
						<div style={{ width: '100%' }}>
							<FormStackedSelect formData={formData} field='equipmentTypes' />
						</div>
						{newQuoteEquipmentTypesMetadata[formData.form.equipmentTypes] &&
							newQuoteEquipmentTypesMetadata[formData.form.equipmentTypes]
								.hasTarpAccessorial && (
								<div>
									<FlatbedAccessorials
										accessorials={state.equipmentTypeAccessorials}
										updateState={sosNewQuote.updateEquipmentAccessorials}
									/>
								</div>
							)}
						{newQuoteEquipmentTypesMetadata[formData.form.equipmentTypes] &&
							newQuoteEquipmentTypesMetadata[formData.form.equipmentTypes]
								.hasRefridgeratedAccessorial && (
								<div>
									<RefrigeratedAccessorials
										accessorials={state.equipmentTypeAccessorials}
										updateState={sosNewQuote.updateEquipmentAccessorials}
									/>
								</div>
							)}
					</div>
					<div className={classes.specialInstructionsSection}>
						<FormStackedTextInput
							formData={formData}
							field='specialInstructions'
						/>
						{props.sendShippingNotification && (
							<FormStackedTextInput
								formData={formData}
								field='shippingNotificationEmails'
								placeholder='Email, comma separated'
							/>
						)}
					</div>
				</FlexRow>
			</FormStacked>
		</div>
	)
}

export const VendorFormDetails: FC = (props: { state: IStateNewQuote }) => {
	const { state } = props
	const formData: IFormData<INewQuoteDetailsForm> = {
		form: state.formNewQuoteDetails,
		metadata: vendorNewQuoteDetailsFormMetadata,
		onUpdateForm: sosNewQuote.updateNewQuoteDetailsForm,
		tPrefix: 'page.newQuote.newQuoteDetailsForm',
	}

	return (
		<div>
			<FormStacked>
				<FlexRow>
					<FormStackedLayout>
						<FormStackedTextInput formData={formData} field='shipmentId' />
						<FormStackedTextInput
							formData={formData}
							field='pickTicketNumber'
						/>
					</FormStackedLayout>

					<FormStackedLayout>
						<FormStackedTextInput
							formData={formData}
							field='specialInstructions'
						/>
					</FormStackedLayout>
				</FlexRow>
			</FormStacked>
		</div>
	)
}
