import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { apiTypes } from 'ui/api'
import { Spacer } from 'ui/components/layout/spacer'
import { l } from 'ui/lib/lodashImports'
import { CommoditiesReadOnly } from 'ui/pages/new-quote'
import { BrokerShipmentDetailsCard } from 'ui/pages/shipment-profile/broker/components/shipment-cards'
import { StopCard } from 'ui/pages/shipment-profile/shared/components/shipmentCards'
import { theme } from 'ui/theme'
import { combineStopData } from 'ui/lib/shipments/combineStopData'
import * as classes from './BrokerShipmentDetailsSection.module.scss'

interface StopInfo {
	stop: apiTypes.OriginStopResponse | apiTypes.DestinationStopResponse
	stopType: 'pickup' | 'delivery' | 'deliveryAndPickup'
}

const getStopInfoFromShipment = (
	shipment: apiTypes.BrokerShipmentResponse,
): StopInfo[] => {
	return shipment.stopSequence.map((stopHash) => {
		const matchingOriginStops: apiTypes.OriginStopResponse[] = []
		const matchingDestinationStops: apiTypes.DestinationStopResponse[] = []
		shipment.payloads.forEach((payload) => {
			if (payload.originStop.metaData.addressHash === stopHash) {
				matchingOriginStops.push(payload.originStop)
			} else if (payload.destinationStop.metaData.addressHash === stopHash) {
				matchingDestinationStops.push(payload.destinationStop)
			}
		})
		if (matchingOriginStops.length > 0 && matchingDestinationStops.length > 0) {
			return {
				stop: combineStopData([
					...matchingDestinationStops,
					...matchingOriginStops,
				]),
				stopType: 'deliveryAndPickup',
			}
		} else if (matchingOriginStops.length > 0) {
			return { stop: combineStopData(matchingOriginStops), stopType: 'pickup' }
		} else if (matchingDestinationStops.length > 0) {
			return {
				stop: combineStopData(matchingDestinationStops),
				stopType: 'delivery',
			}
		} else {
			return null
		}
	})
}

export const BrokerShipmentDetailsSection = (props: {
	shipment: apiTypes.BrokerShipmentResponse
}): React.ReactElement => {
	const { shipment } = props
	const stopsInOrder: StopInfo[] = l.compact(getStopInfoFromShipment(shipment))

	return (
		<div>
			<Card className={classes.cardBody}>
				<Row>
					<Col md={4}>
						<BrokerShipmentDetailsCard shipment={shipment} />
					</Col>
					{stopsInOrder.map((stopInfo, idx) => (
						<Col
							md={4}
							key={stopInfo.stop.metaData.addressHash}
							className={theme.addClassIf(idx > 1, classes.nonFirstRowCard, '')}
						>
							<StopCard
								stopNumber={idx + 1}
								stopType={stopInfo.stopType}
								stop={stopInfo.stop}
								brokerBooked={shipment.bookedRate ? true : false}
								shipment={shipment}
							/>
						</Col>
					))}
				</Row>
				<Spacer />
				<CommoditiesReadOnly fullWidth={true} shipment={shipment} />
			</Card>
		</div>
	)
}
