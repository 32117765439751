import {FC} from 'app/FunctionalComponent'
import {sosToast} from 'common/components/toast'
import React, {useEffect, useState} from 'react'
import {Card, Col, Row} from 'react-bootstrap'
import {apiBroker, apiShipments, apiTypes} from 'ui/api'
import {Button} from 'ui/components/common/button'
import {Icon, solidIcons} from 'ui/components/common/icon'
import {windowExists, windowOpen} from 'ui/components/common/router/windowUtils'
import {FormStackedTextInput, IFormData} from 'ui/components/form'
import {tMode} from 'ui/components/i18n/commonTranslations'
import {t, tString} from 'ui/components/i18n/i18n'
import {goToTms2BoL} from 'ui/lib/IframeRpc'
import {l} from 'ui/lib/lodashImports'
import {isInTMS2} from 'ui/theme/theme'
import {sosShipmentProfileBroker} from 'ui/pages/shipment-profile/broker'
import classes from './ShipmentProfileBOL.module.scss'
import {sosShipmentProfileShipper} from 'ui/pages/shipment-profile/shipper'

const tPrefix = 'page.shipmentProfile.bol'
const translate = (key: string, stringOnly = false): any =>
	stringOnly ? tString(key, tPrefix) : t(key, tPrefix)

interface BillOfLadingForm {
	proNumber?: string
	pickupNumber?: string
	brokerReferenceNumber?: string
	trailerNumber?: string
	containerNumber?: string
	sealNumber?: string
	providerName?: string
	mode?: string
}

export const ShipmentProfileBOL: FC = (props: {
	shipment: apiTypes.BrokerShipmentResponse | apiTypes.ShipmentResponse
	isBroker: boolean
}) => {
	const { shipment, isBroker } = props
	const [bolForm, updateBOLForm] = useState<BillOfLadingForm>(null)
	const [isLoading, setIsLoading] = useState(false)

	useEffect(() => {
		if (shipment.shipmentStatus === 'booked') {
			updateBOLForm({
				proNumber: shipment.proNumber,
				pickupNumber: shipment.pickupNumber,
				brokerReferenceNumber: shipment.brokerReferenceNumber,
				trailerNumber: shipment.trailerNumber,
				sealNumber: shipment.sealNumber,
				mode: tMode(shipment.bookedRate?.method),
				providerName: shipment.bookedRate?.providerName,
				containerNumber: shipment.trailerNumber,
			})
		}
	}, [shipment])

	const formData: IFormData<BillOfLadingForm> = {
		form: bolForm,
		metadata: {
			providerName: { readOnly: true },
			mode: { readOnly: true },
			proNumber: {},
			pickupNumber: {},
			brokerReferenceNumber: {},
			trailerNumber: {},
			containerNumber: {},
			sealNumber: {},
		},
		onUpdateForm: (key: string, val: string) => {
			updateBOLForm(Object.assign({}, bolForm, { [key]: val }))
		},
		tPrefix,
	}

	const onUpdate = async (): Promise<void> => {
		setIsLoading(true)

		const updatedShipment = l.cloneDeep(shipment)

		updatedShipment.proNumber = bolForm.proNumber
		updatedShipment.pickupNumber = bolForm.pickupNumber
		updatedShipment.brokerReferenceNumber = bolForm.brokerReferenceNumber
		updatedShipment.sealNumber = bolForm.sealNumber
		updatedShipment.trailerNumber = bolForm.trailerNumber

		if (isBroker) {
			const brokerShipment = shipment as apiTypes.BrokerShipmentResponse

			const updateResult = await apiBroker.updateBrokerShipment(
				() => {},
				brokerShipment.id,
				updatedShipment,
				brokerShipment.contractId,
			)

			if (updateResult.data) {
				sosShipmentProfileBroker.setShipmentReference(updateResult.data)
			} else {
				sosToast.sendApiErrorResponseToast(
					updateResult,
					translate('shipmentUpdateError', true),
				)
			}
		} else {
			const shipperShipment = shipment as apiTypes.BrokerShipmentResponse

			const updateResult = await apiShipments.updateShipment(
				shipperShipment.id,
				updatedShipment,
				() => {},
			)

			if (updateResult.data) {
				sosShipmentProfileShipper.setShipmentReference(updateResult.data)
			} else {
				sosToast.sendApiErrorResponseToast(
					updateResult,
					translate('shipmentUpdateError', true),
				)
			}
		}

		setIsLoading(false)
	}

	const showBOL = async (documentId: string): Promise<void> => {
		if (isInTMS2()) {
			goToTms2BoL(shipment, shipment.payloads[0])
		} else {
			const bolDocument = shipment.shippingDocuments.find(_doc => _doc.type === 'Bill of Lading');
			if (bolDocument) {
				const response = await apiShipments.getShippingDocument(null, bolDocument.id)
				const bol = response.data[0];
				const link = `data:application/${bol.headers['Content-Type']};base64,${bol.body}`
				const downloadLink = document.createElement('a', {})
				downloadLink.setAttribute('href', link)
				downloadLink.setAttribute('download', bolDocument.filename)
				document.body.appendChild(downloadLink)
				downloadLink.click()
				downloadLink.remove()
			}
		}
	}

	const showPalletSlips = async (): Promise<void> => {
		const palletPdf = shipment?.shippingDocuments?.find(
			(document) => document.type === 'Pallet Slip',
		)

		// const palletPdf =
		const base64str = palletPdf.images[0]?.image

		// console.log(base64str)
		// decode base64 string, remove space for IE compatibility
		const binary = atob(base64str)
		const len = binary.length
		const buffer = new ArrayBuffer(len)
		const view = new Uint8Array(buffer)

		for (let i = 0; i < len; i++) {
			view[i] = binary.charCodeAt(i)
		}
		const blob = new Blob([view], {
			type: 'application/pdf',
		})

		const url = URL.createObjectURL(blob)

		if (windowExists) {
			windowOpen(url)
		}
	}

	return (
		<div className='bootstrap-wrapper'>
			{bolForm && (
				<Card className='p-3'>
					<Row>
						<Col>
							<Row>
								<Col>
									<div
										className={classes.downloadBox}
										onClick={async () => {
											await showBOL('123')
										}}
									>
										<div className={classes.downloadBoxContent}>
											<span className='d-block'>
												{translate('billOfLading')}
											</span>
											{/* <span className='d-block'>{translate('_')}</span> */}
											<Icon icon={solidIcons.faDownload} />
										</div>
										<div className={classes.downloadBoxSuccess}>
											<span className='mr-1'>
												{translate('printBillOfLading')}
											</span>
											<Icon icon={solidIcons.faCheckCircle} />
										</div>
									</div>
									<div
										className={classes.downloadBox}
										onClick={async () => {
											await showPalletSlips()
										}}
									>
										<div className={classes.downloadBoxContent}>
											<span className='d-block'>
												{translate('palletSlips')}
											</span>
											{/* <span className='d-block'>{translate('_')}</span> */}
											<Icon icon={solidIcons.faDownload} />
										</div>
										<div className={classes.downloadBoxSuccess}>
											<span className='mr-1'>
												{translate('printPalletSlips')}
											</span>
											<Icon icon={solidIcons.faCheckCircle} />
										</div>
									</div>
								</Col>
								<Col>
									<FormStackedTextInput
										formData={formData}
										field='providerName'
									/>
									<FormStackedTextInput formData={formData} field='mode' />
								</Col>
							</Row>
						</Col>
						<Col>
							<FormStackedTextInput
								formData={formData}
								field='proNumber'
								readOnly={isLoading}
							/>
							<FormStackedTextInput
								formData={formData}
								field='pickupNumber'
								readOnly={isLoading}
							/>
							<FormStackedTextInput
								formData={formData}
								field='brokerReferenceNumber'
								readOnly={isLoading}
							/>
							<FormStackedTextInput
								formData={formData}
								field='trailerNumber'
								readOnly={isLoading}
							/>
							<FormStackedTextInput
								formData={formData}
								field='containerNumber'
								readOnly={isLoading}
							/>
							<FormStackedTextInput
								formData={formData}
								field='sealNumber'
								readOnly={isLoading}
							/>
							<div className='d-flex justify-content-end'>
								<Button color='blue' isSpinning={isLoading} onClick={onUpdate}>
									{translate('update')}
								</Button>
							</div>
						</Col>
					</Row>
				</Card>
			)}
		</div>
	)
}
