import { FC } from 'app/FunctionalComponent'
import { DateTime } from 'luxon'
import React from 'react'
import { apiTypes } from 'ui/api'
import { useOnce } from 'ui/components/hooks'
import { FlexRow } from 'ui/components/layout/flexRow/FlexRow'
import { Spacer } from 'ui/components/layout/spacer'
import { fireAndForget } from 'ui/lib/async'
import { l } from 'ui/lib/lodashImports'
import { sos2 } from 'ui/lib/state/sos2'
import { sosDockSchedulerStatusBoard } from '.'
import { DockSchedulerLocationSelector } from '../components/DockSchedulerLocationSelector'
import { sosDockScheduler } from '../state'
import { AppointmentStatusItem } from './AppointmentStatusItem'
import * as classes from './DockSchedulerStatusBoard.module.scss'

export const tPrefixStatusBoard = 'page.dockScheduler.statusBoard'

export const DockSchedulerStatusBoard: FC = (props: {}) => {
	const timeFormat = 'h:mm:ss'
	const dateTimeFormat = 'MM/dd/y  ' + timeFormat
	const amPmFormat = 'a'
	const { appointments, currentTime } = sos2.useSubscription(
		sosDockSchedulerStatusBoard.getSos(),
	)
	const { docks } = sos2.useSubscription(sosDockScheduler.getSos())

	useOnce(() => {
		setInterval(() => {
			const currentTime = DateTime.local()
			sosDockSchedulerStatusBoard.updateCurrentTime(currentTime.toISO())
		}, 1000)

		fireAndForget(
			() => sosDockSchedulerStatusBoard.fetchAppointments(),
			'fetching appointments',
		)
		fireAndForget(() => sosDockScheduler.fetchDocks(), 'fetching docks')

		setInterval(() => {
			fireAndForget(
				() => sosDockSchedulerStatusBoard.fetchAppointments(),
				'fetching appointments',
			)
			fireAndForget(() => sosDockScheduler.fetchDocks(), 'fetching docks')
		}, 30 * 1000)
	})

	return (
		<>
			{currentTime && (
				<div data-testid='dockSchedulerStatusBoard'>
					<FlexRow>
						<DockSchedulerLocationSelector />
						<div className={classes.time} data-testid='currentTime'>
							<FlexRow>
								<span className={classes.timePiece}>
									{currentTime &&
										DateTime.fromISO(currentTime).toFormat(dateTimeFormat)}
								</span>
								<span className={classes.timePiece}>
									{currentTime &&
										DateTime.fromISO(currentTime).toFormat(amPmFormat)}
								</span>
							</FlexRow>
						</div>
					</FlexRow>
					<Spacer />

					<div className={classes.flexContainer}>
						{docks.map((dock: apiTypes.DockResponse, appointmentIdx) => {
							const appointment = l.find(
								appointments,
								(a) => a.dockId === dock.id,
							)

							return (
								<div
									key={appointmentIdx}
									className={classes.flexItem}
									data-testclass='dockSchedulerAppointmentStatusItem'
								>
									<AppointmentStatusItem
										appointment={appointment}
										dock={dock}
										currentTime={currentTime}
									/>
								</div>
							)
						})}
					</div>
				</div>
			)}
		</>
	)
}
