import { l } from 'ui/lib/lodashImports'
import { IDatePickerState } from 'ui/pages/shipments'

export interface IFilterChip<T> {
	columnKey: keyof T
	isOpen: boolean
	filterValue?: string
	filterDatePickerState?: IDatePickerState
}

export interface IDataTableState<T> {
	hiddenHeaders?: string[]
	filterChips?: IFilterChip<T>[]
	sortOn?: keyof T
	sortReversed?: boolean
	isAdding?: boolean
	editRow?: T
	error?: any
}

export function createDefaultDataTableState<T>(
	initialState: Partial<T>,
): Partial<T> {
	return l.assign({}, initialState)
}
