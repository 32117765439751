import { FC } from 'app/FunctionalComponent'
import React from 'react'
import image from './loader-21px.gif'
import classes from './Loader.module.scss'

export const Loader: FC = (props: {
	isLoading: boolean
	testId?: string
	size?: string
}) => {
	if (!props.isLoading) {
		return null
	}

	return (
		<img
			data-testid={props.testId}
			className={classes.loader}
			alt='Loading'
			title='Loading'
			src={image}
			height={props.size}
			width={props.size}
		/>
	)
}
