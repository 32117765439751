import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { l } from 'ui/lib/lodashImports'
import { HeaderText } from 'ui/pages/spot-quote/MakeOfferPage'
import { FontSizes } from 'ui/theme/theme'
import { Checkbox } from '../common/checkbox/Checkbox'
import { ISelectOptions } from '../common/select/ISelectOptions'
import { t } from '../i18n/i18n'

export const CheckboxList: FC = (props: {
	options: ISelectOptions[]
	checkedValues: string[]
	label: string
	tPrefix: string
	onChange: (optionValue, checked: boolean) => void
	headerFontSize?: FontSizes
	checkboxLabelFontSize?: FontSizes
}) => {
	const { options, checkedValues, label, tPrefix } = props
	return (
		<div>
			<HeaderText fontSize={props.headerFontSize}>
				{t(label, tPrefix)}
			</HeaderText>
			{l.map(options, (option, optionIdx) => {
				const onChange = (checked: boolean): void => {
					props.onChange(option.value, checked)
				}

				return (
					<Checkbox
						value={l.includes(checkedValues, option.value)}
						onChange={onChange}
						key={optionIdx}
						testId={option.label || option.value}
						fontSize={props.checkboxLabelFontSize}
					>
						{t(option.label || option.value, tPrefix)}
					</Checkbox>
				)
			})}
		</div>
	)
}
