import { esQueryStringFormatterGenerator } from 'ui/pages/shipments/state/lib/esQueryStringFormatterGenerator'

/*
	Searches through a queryString for any AND operators and formats to the correct syntax for ElasticSearch
 */
export function queryStringAndFormatter(qs: string): string {
	const andSplitters = [' AND ', ' & ', ' and ', ' + ', ' && ']
	const esAndSyntax = ' AND '
	return esQueryStringFormatterGenerator(andSplitters, esAndSyntax)(qs)
}
