import { UpdateMode } from 'ui/api/apiCommon'
import { l } from 'ui/lib/lodashImports'

import { IDataTableState } from './IDataTableState'
import { r } from 'ui/lib/ramdaImports'
import { DateTime } from 'luxon'
import { getDefaultDateState, IDatePickerState } from 'ui/pages/shipments'

//Can we use this for sorting?
// let collator = new Intl.Collator(undefined, {
//   numeric: true,
//   sensitivity: 'base',
// })
// let keys = Object.keys(formData)
//   .filter((v, k) => {
//     return v.match(/Pkg_Type_\d+/)
//   })
//   .sort(collator.compare)

const _doSort = <T extends any>(state: IDataTableState<T>, data: T[]): T[] => {
	// TODO: server sort
	const { sortOn, sortReversed } = state
	if (state.sortOn && data && data.length > 0) {
		// Get our first data for this field
		let firstData = null
		let sortType = 'default'
		for (let i = 0; i < data.length; i++) {
			firstData = data[i][sortOn]
			if (firstData != null) {
				if (typeof firstData === 'string') {
					sortType = 'string'
				}
				break
			}
		}
		if (sortType === 'string') {
			data = l.orderBy(
				data,
				(c) => l.toLower(c[sortOn]),
				sortReversed ? 'desc' : 'asc',
			)
		} else {
			data = l.orderBy(data, sortOn, sortReversed ? 'desc' : 'asc')
		}
	}
	return data
}

export const initialLoad = <T extends any>(
	state: IDataTableState<T>,
	data: T[],
): T[] => {
	// TODO: server sort
	data = _doSort(state, data)
	return data
}

// const removeById = <T extends any>(
//   state: IDataTableState<T>,
//   item: { id?: string },
// ) => {
//   state.data = _.filter(state.data, (c) => c.id !== item.id)
// }

export const upsert = <T>(arr: T[], key: (t: T) => boolean, newVal: T): T[] => {
	const match = l.find(arr, key)
	if (match) {
		const index = l.indexOf(arr, l.find(arr, key))
		arr.splice(index, 1, newVal)
	} else {
		arr.push(newVal)
	}
	return arr
}

export const updateItem = <T extends any>(
	state: IDataTableState<T>,
	data: T[],
	mode: UpdateMode,
	item: T,
	id?: string,
): T[] => {
	if (mode === 'delete') {
		data = l.filter(data, (c) => c.id !== (item.id || id))
	} else {
		if (!data) {
			data = []
		}

		data = upsert(data, (c) => c.id === item.id, item)
	}
	return data
}

const _sort = <T extends any>(
	state: IDataTableState<T>,
	data: T[],
	sortOn: string,
): void => {
	const fieldIsAlreadySorted = state.sortOn === sortOn
	const { sortReversed } = state

	if (fieldIsAlreadySorted && sortReversed) {
		state.sortOn = null
		state.sortReversed = false
	} else if (fieldIsAlreadySorted && r.not(sortReversed)) {
		state.sortReversed = true
	} else if (r.not(fieldIsAlreadySorted)) {
		state.sortOn = sortOn
		state.sortReversed = false
	}
}

export const sortOnServer = _sort

export const sort = <T extends any>(
	state: IDataTableState<T>,
	data: T[],
	sortOn: string,
): T[] => {
	_sort(state, data, sortOn)
	return _doSort(state, data)
}

export const toggleHeader = <T extends any>(
	state: IDataTableState<T>,
	field: string,
): void => {
	if (!state.hiddenHeaders) {
		state.hiddenHeaders = []
	}
	const removed = l.remove(state.hiddenHeaders, (c) => c === field)
	if (removed.length === 0) {
		state.hiddenHeaders.push(field)
	}
}

export const setFilterDateValue = <T extends any>(
	state: IDataTableState<T>,
	field: string,
	changes: Partial<IDatePickerState>,
): void => {
	if (changes.date1String === 't') {
		changes.date1String = DateTime.local().toLocaleString(DateTime.DATE_SHORT)
	}
	const existingChip = l.find(state.filterChips, (c) => c.columnKey === field)
	if (existingChip) {
		existingChip.filterDatePickerState = l.assign(
			existingChip.filterDatePickerState,
			changes,
		)
	} else {
		if (!state.filterChips) {
			state.filterChips = []
		}
		state.filterChips.push({
			columnKey: field,
			filterDatePickerState: l.assign(getDefaultDateState('on'), changes),
			isOpen: true,
		})
	}
}

export const toggleActionsModal = <T extends any>(
	ds: IDataTableState<T>,
	field: string,
): void => {
	const existingChip = l.find(ds.filterChips, (c) => c.columnKey === field)
	if (existingChip) {
		if (!existingChip.isOpen) {
			setFilterDateValue(ds, field, {
				showDate1Calendar: true,
				showDate2Calendar: false,
			})
		}
		existingChip.isOpen = !existingChip.isOpen
	} else {
		if (!ds.filterChips) {
			ds.filterChips = []
		}
		ds.filterChips.push({
			columnKey: field,
			isOpen: true,
		})
		setFilterDateValue(ds, field, {
			showDate1Calendar: true,
			showDate2Calendar: false,
		})
	}
}

export const clearFilter = <T extends any>(
	state: IDataTableState<T>,
	field: string,
): void => {
	state.filterChips = l.remove(state.filterChips, (c) => c.columnKey !== field)
}

export const setFilterValue = <T extends any>(
	ds: IDataTableState<T>,
	field: string,
	filterValue: string,
): void => {
	const existingChip = l.find(ds.filterChips, (c) => c.columnKey === field)
	if (existingChip) {
		existingChip.filterValue = filterValue
	} else {
		if (!ds.filterChips) {
			ds.filterChips = []
		}
		ds.filterChips.push({
			columnKey: field,
			filterValue: filterValue,
			isOpen: true,
		})
	}
}

export const setHiddenHeaders = <T extends any>(
	state: IDataTableState<T>,
	hiddenHeaders: string[],
): void => {
	state.hiddenHeaders = hiddenHeaders
}

export const toggleUpdateMode = <T extends any>(
	state: IDataTableState<T>,
	mode: UpdateMode,
	row: T = null,
): void => {
	// Clear any error
	state.error = null
	if (mode === 'add' || (mode === 'upsert' && !row)) {
		state.editRow = null
		state.isAdding = !state.isAdding
	} else if (mode === 'edit' || (mode === 'upsert' && row)) {
		if (!row) {
			throw new Error('edit mode must have a row to edit')
		}
		state.isAdding = false
		if (row && state.editRow && row.id === state.editRow.id) {
			// Same edit row, toggle off
			state.editRow = null
		} else {
			state.editRow = row
		}
	} else if (mode === 'none') {
		state.editRow = null
		state.isAdding = false
	} else if (mode === 'delete') {
		throw new Error('dont use delete mode')
	} else {
		throw new Error('shouldnt execute')
	}
}

export const setError = <T extends any>(
	state: IDataTableState<T>,
	error: any,
): void => {
	state.error = error
}
