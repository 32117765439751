import { apiCommon, apiTypes } from '.'
import { IRequestState } from './requestState'

export async function getProvider(
	onProgress: (rs: IRequestState<apiTypes.ProviderResponse>) => void,
	providerId: string,
): Promise<IRequestState<apiTypes.ProviderResponse>> {
	const url = 'providers/' + providerId
	const response = await apiCommon.apiFetch(
		onProgress,
		{
			method: 'GET',
		},
		url,
	)
	return response
}

export async function getProviders(
	take = 25,
	skip = 0,
	query?: string,
	fields?: string[],
): Promise<IRequestState<apiTypes.ProviderListResponse>> {
	const url = 'providers'
	const response = await apiCommon.apiFetch<apiTypes.ProviderListResponse>(
		null,
		{
			method: 'GET',
			entireResponse: true,
		},
		url,
		{ take, skip, query, fields },
	)
	return response
}

export async function getProviderByName(
	onProgress: (rs: IRequestState<apiTypes.ProviderResponse>) => void,
	providerName: string,
): Promise<IRequestState<apiTypes.ProviderResponse>> {
	const url = 'providers/name/' + providerName
	const response = await apiCommon.apiFetch(
		onProgress,
		{
			method: 'GET',
		},
		url,
	)
	return response
}

export async function updateProvider(
	onProgress: (rs: IRequestState<apiTypes.ProviderResponse>) => void,
	providerId: string,
	body: apiTypes.ProviderRequest,
): Promise<IRequestState<apiTypes.ProviderResponse>> {
	const url = `providers/${providerId}`
	const response = await apiCommon.apiFetch(
		onProgress,
		{
			method: 'PUT',
			entireResponse: true,
			data: body,
		},
		url,
	)
	return response
}

export async function createNewProvider(
	onProgress: (rs: IRequestState<apiTypes.ProviderResponse>) => void,
	data: apiTypes.ProviderRequest,
): Promise<IRequestState<apiTypes.ProviderResponse>> {
	const url = 'providers'
	const result = await apiCommon.apiFetch(
		onProgress,
		{
			method: 'POST',
			data,
		},
		url,
	)
	return result
}

export async function checkExistingContract(
	onProgress: (rs: IRequestState<apiTypes.ContractListResponse>) => void,
	providerId: string,
): Promise<IRequestState<apiTypes.ContractListResponse>> {
	const url = `providers/contracts/provider/${providerId}`
	const result = await apiCommon.apiFetch(
		onProgress,
		{
			method: 'GET',
		},
		url,
	)
	return result
}
