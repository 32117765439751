import { DateTime } from 'luxon'
import { apiTypes } from 'ui/api'

const shipmentQueryByProvider: Partial<
	{
		[k in apiTypes.ApiProviderName]: (options: {
			providerId: string
			date?: string
			locationId?: string
		}) => string
	}
> = {
	// TO-DO: NEED TO MODIFY THIS
	Fedex: (options: {
		providerId: string
		date?: string
		locationId?: string
	}) => {
		return (
			'NOT flags:RFP AND NOT _exists_:rfpId ' +
			`AND bookedRate.providerId:${options.providerId} ` +
			'AND shipmentStatus:booked ' +
			`AND locationId:${options.locationId}`
		)
	},
	Speedee: (options: { providerId: string; locationId?: string }) => {
		return (
			'NOT flags:RFP AND NOT _exists_:rfpId ' +
			`AND bookedRate.providerId:${options.providerId} ` +
			'AND manifestStatus:pending ' +
			'AND shipmentStatus:booked ' +
			`AND locationId:${options.locationId}`
		)
	},
	Purolator: (options: { providerId: string; locationId?: string }) => {
		return (
			'NOT flags:RFP AND NOT _exists_:rfpId ' +
			`AND bookedRate.providerId:${options.providerId} ` +
			'AND manifestStatus:pending ' +
			'AND shipmentStatus:booked ' +
			`AND locationId:${options.locationId}`
		)
	},
	Canada_Post: (options: { providerId: string; locationId?: string }) => {
		return (
			'NOT flags:RFP AND NOT _exists_:rfpId ' +
			`AND bookedRate.providerId:${options.providerId} ` +
			'AND manifestStatus:pending ' +
			'AND shipmentStatus:booked ' +
			`AND locationId:${options.locationId}`
		)
	},
	UPS: (options: { providerId: string; date?: string }) => {
		const dateRangeEnd = DateTime.fromISO(options.date)
			.set({ hour: 23, minute: 0, second: 0, millisecond: 0 })
			.toISO()

		const dateRangeStart = DateTime.fromISO(dateRangeEnd)
			.minus({ day: 1 })
			.toISO()

		return (
			'NOT flags:RFP AND NOT _exists_:rfpId ' +
			`AND bookedRate.providerId:${options.providerId} ` +
			'AND manifestStatus:pending ' +
			'AND (' +
			'shipmentStatus:booked ' +
			`AND bookedDate:{${dateRangeStart} TO ${dateRangeEnd}}` +
			') OR (' +
			'shipmentStatus:void ' +
			`AND voidDate:{${dateRangeStart} TO ${dateRangeEnd}} ` +
			`AND NOT bookedDate:{${dateRangeStart} TO ${dateRangeEnd}}` +
			')'
		)
	},
}

export const getShipmentQuery = (
	provider: apiTypes.ApiProviderName,
	providerId: string,
	date?: string,
	locationId?: string,
): string => {
	return shipmentQueryByProvider[provider]({ providerId, date, locationId })
}
