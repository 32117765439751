import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { sosRouter } from 'ui/components/common/router'
import styles from './Sidebar.module.scss'

export const SidebarSubItem: FC = (
	props: {
		pathname: string
		isSelected: boolean
		children: React.ReactNode
	},
	testId?: string,
) => {
	let className = styles.sidebarSubItem
	if (props.isSelected) {
		className += ' ' + styles.selectedSubItem
	}

	return (
		<div
			className={className}
			onClick={(ev) => {
				sosRouter.navigate(props.pathname)
				ev.preventDefault()
				ev.stopPropagation()
			}}
		>
			{props.children}
		</div>
	)
}
