/**
 * Note: No early exit is possible, return false does nothing
 * @param array
 * @param iteratee
 */
export async function asyncForEachParallel<T>(
	array: T[],
	iteratee: (value: T, index: number) => Promise<void>,
): Promise<void> {
	const promises: Promise<any>[] = []
	for (let index = 0; index < array.length; index++) {
		const value = array[index]
		promises.push(iteratee(value, index))
	}
	await Promise.all(promises)
}
