import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { apiTypes } from 'ui/api'
import { Button } from 'ui/components/common/button/Button'
import { t, tString } from 'ui/components/i18n/i18n'
import { RateRow } from 'ui/components/rates-table'
import classes from 'ui/pages/shipment-profile/shared/components/rates/ShipmentProfileRateInteraction.module.scss'
import { RateBookingDetails } from 'ui/pages/shipment-profile/shared/functions/rateBookingDetailsTypes'
import { sosShipmentProfileShipper } from 'ui/pages/shipment-profile/shipper'
import { sosRouter } from 'ui/components/common/router'
import { sosToast } from 'common/components/toast'

export const ShipperShipmentProfileRateInteraction: FC = (props: {
	rateRow: RateRow
	tPrefix: string
	shipment: apiTypes.ShipmentResponse
	shipmentIsRating: boolean
	shipmentIsBooking: boolean
	setIsBookCarrierModalOpen: React.Dispatch<React.SetStateAction<boolean>>
	setRateBookingDetails: React.Dispatch<
		React.SetStateAction<RateBookingDetails>
	>
	returnToParcel: boolean
}) => {
	const {
		rateRow,
		tPrefix,
		shipment,
		shipmentIsRating,
		shipmentIsBooking,
		setIsBookCarrierModalOpen,
		setRateBookingDetails,
		returnToParcel,
	} = props

	if (!shipment || !rateRow) {
		return null
	}

	return (
		<div className={classes.interactionOptions}>
			<ShipperRateInteractionButton
				rateRow={rateRow}
				shipment={shipment}
				tPrefix={tPrefix}
				shipmentStatus={shipment.shipmentStatus}
				shipmentIsRating={shipmentIsRating}
				shipmentIsBooking={shipmentIsBooking}
				setIsBookCarrierModalOpen={setIsBookCarrierModalOpen}
				setRateBookingDetails={setRateBookingDetails}
				returnToParcel={returnToParcel}
			/>
		</div>
	)
}

export const ShipperRateInteractionButton: FC = (props: {
	rateRow: RateRow
	shipment: apiTypes.ShipmentResponse
	tPrefix: string
	shipmentStatus: apiTypes.ShipmentResponse['shipmentStatus']
	shipmentIsRating: boolean
	shipmentIsBooking: boolean
	setIsBookCarrierModalOpen: React.Dispatch<React.SetStateAction<boolean>>
	setRateBookingDetails: React.Dispatch<
		React.SetStateAction<RateBookingDetails>
	>
	returnToParcel: boolean
	serviceConfig?: apiTypes.TmsServiceConfigResponse
}) => {
	const {
		rateRow,
		shipment,
		tPrefix,
		shipmentStatus,
		shipmentIsRating,
		shipmentIsBooking,
		setIsBookCarrierModalOpen,
		setRateBookingDetails,
		returnToParcel,
	} = props

	switch (rateRow.offerStatus) {
		case 'booked': {
			return (
				<Button
					color='green'
					isSmall={true}
					bootstrapStyles={true}
					isOutline={true}
					isDisabled={true}
					className={classes.ratesTableBadge}
				>
					<div className={classes.buttonText} data-testid='booked-badge'>
						{t('booked', tPrefix)}
					</div>
				</Button>
			)
		}

		default: {
			return (
				<Button
					color='green'
					isSmall={true}
					onClick={async () => {
						if (
							Object.keys(rateRow.rateBookingOptions).length === 1 &&
							rateRow.rateBookingOptions.api
						) {
							const bookingResponse = await sosShipmentProfileShipper.bookShipment(
								shipment.id,
								rateRow.rateId,
								'api',
							)

							if (bookingResponse.error) {
								sosToast.sendApiErrorResponseToast(bookingResponse)
							} else if (bookingResponse.data) {
								sosToast.sendToast({
									type: 'success',
									header: tString('successBook', tPrefix),
								})
								if (returnToParcel) {
									setTimeout(() => {
										const newQuoteParcelLink = '/shipments-v3/new-quote/parcel/'
										sosRouter.navigate(newQuoteParcelLink)
									}, 1500)
								}
							}
						} else {
							setRateBookingDetails({
								shipmentId: shipment.id,
								rateId: rateRow.rateId,
								providerName: rateRow.providerName,
								api: rateRow.rateBookingOptions?.api,
								email: rateRow.rateBookingOptions?.email,
								phone: rateRow.rateBookingOptions?.phone,
								isBroker: false,
							})

							setIsBookCarrierModalOpen(true)
						}
					}}
					bootstrapStyles={true}
					isDisabled={
						shipmentIsRating || shipmentIsBooking || shipmentStatus === 'booked'
					}
					testId='book-rate-button'
					className={classes.ratesTableBadge}
				>
					<div className={classes.buttonText}>{t('book', tPrefix)}</div>
				</Button>
			)
		}
	}
}
