import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { Card } from 'ui/components/common/card/Card'
import { LoadingBar } from 'ui/components/common/loader/LoadingBar'
import { Modal } from 'ui/components/common/modal/Modal'
import { useOnce } from 'ui/components/hooks'
import { t } from 'ui/components/i18n/i18n'
import { Spacer } from 'ui/components/layout/spacer/Spacer'
import { MessagesList } from 'ui/components/shared/csvValidator/messages-list/MessagesList'
import { fireAndForget } from 'ui/lib/async'
import { l } from 'ui/lib/lodashImports'
import { sos2 } from 'ui/lib/state/sos2'
import { sosRfpAnalysisPage } from 'ui/state'
import { RfpAnalysisDataTable } from './RfpAnalysisDataTable'
import { RfpLayout } from './RfpLayout'
import { RfpDevTools } from './rfpDevTools/RfpDevTools'

export const tPrefixRfpAnalysis = 'page.rfpAnalysis'

export const RfpAnalysisPage: FC = (props: {}) => {
	const state = sos2.useSubscription(sosRfpAnalysisPage.getSos())

	useOnce(() => {
		fireAndForget(
			() => sosRfpAnalysisPage.getRfps(false),
			'getting RFP result data',
		)
	})

	return (
		<RfpLayout>
			<RfpAnalysisDataTable
				data={state.dataRfps}
				dataTableState={state.dataTableStateRfps}
				requestGetRfps={state.requestGetRfp}
				compareContractA={state.compareContractA}
				compareContractB={state.compareContractB}
				carrierList={state.carrierList}
				onExport={sosRfpAnalysisPage.exportData}
			/>
			{!l.isEmpty(state.errors) && (
				<div>
					<Spacer />
					<Card
						title={t('page.compareRfpContracts.errors')}
						color='red'
						noPadding={true}
						fullWidth={false}
						testId='rfpUploadErrors'
					>
						<MessagesList messages={state.errors} includeTitle={false} />
					</Card>
				</div>
			)}
			<Spacer />
			<RfpDevTools />
			<Modal
				title={t('csvExport', tPrefixRfpAnalysis)}
				onModalClose={() => sosRfpAnalysisPage.setModalOpen(false)}
				content={() => (
					<div>
						{t('exporting', tPrefixRfpAnalysis)}
						<LoadingBar
							totalSteps={state.exportTotalSteps}
							completedSteps={state.exportCurrentStep}
							isLoading={state.modalOpen}
						/>
					</div>
				)}
				isOpen={state.modalOpen}
			/>
		</RfpLayout>
	)
}
