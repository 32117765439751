import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { Card } from 'ui/components/common/card/Card'
import { Checkbox } from 'ui/components/common/checkbox'
import { Group } from 'ui/components/common/group/Group'
import {
	getLocalStorage,
	localStorageExists,
	reload,
} from 'ui/components/common/router/windowUtils'
import { SmallButton } from 'ui/components/common/small-button'
import { sos2 } from 'ui/lib/state/sos2'
import { sosUser } from 'ui/state'
import { DevFeatureToggles } from '.'
import styles from './DevTools.module.scss'
import { LanguagePicker } from './LanguagePicker'
import { LoggingPicker } from './LoggingPicker'
import { PageConfigModePicker } from './PageConfigModePicker'
import { PageConfigorizer } from './PageConfigorizer'
import { ThemePicker } from './ThemePicker'
import { TranslateModePicker } from './TranslateModePicker'
import { Translator } from './Translator'

export const DevTools: FC = (props: {}) => {
	const stateUser = sos2.useSubscription(sosUser.getSos())

	return (
		<div className={styles.devTools}>
			<Card
				title='Dev'
				color='gray'
				isOpen={stateUser.showDevTools}
				onToggle={() => {
					sosUser.toggleShowDevTools()
				}}
			>
				<SmallButton
					color='blue'
					onClick={() => {
						if (localStorageExists) {
							getLocalStorage().clear()
						}
						reload()
					}}
				>
					Clear Local Storage
				</SmallButton>
				<div>
					<Checkbox
						value={stateUser.pollOnLivePages}
						onChange={() => {
							sosUser.update({
								pollOnLivePages: !stateUser.pollOnLivePages,
							})
						}}
					>
						Poll on Live Pages
					</Checkbox>
				</div>
				<Group>
					<LoggingPicker stateUser={stateUser} />
				</Group>
				<Group>
					<ThemePicker />
				</Group>
				<Group>
					<LanguagePicker />
					<TranslateModePicker />
					<PageConfigModePicker />
				</Group>
				{/* <Group>
					<DevPermissions stateUser={stateUser} />
				</Group> */}
				<Group>
					<DevFeatureToggles />
				</Group>
			</Card>
			<Translator />
			<PageConfigorizer />
		</div>
	)
}
