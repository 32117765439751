import { FC } from 'app/FunctionalComponent'
import { Table } from 'common/components/table/Table'
import React, { useState } from 'react'
import { ColumnDescription } from 'react-bootstrap-table-next'
import { Button } from 'ui/components/common/button/Button'
import { Icon } from 'ui/components/common/icon/Icon'
import { solidIcons } from 'ui/components/common/icon/solidIcons'
import { ISelectOptions, Select } from 'ui/components/common/select'
import { useOnce } from 'ui/components/hooks'
import { t, tString } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight/AlignRight'
import { Spacer } from 'ui/components/layout/spacer'
import { fireAndForget } from 'ui/lib/async/fireAndForget'
import { l } from 'ui/lib/lodashImports'
import { sos2 } from 'ui/lib/state/sos2'
import { Layout } from 'ui/pages/layout/Layout'
import * as classes from 'ui/pages/load-optimizer/LoadOptimizer.module.scss'
import { tPrefixLoadOptimizer } from 'ui/pages/load-optimizer/payloads-table/LoadOptimizerPayloadsTable'
import * as sosLoadOptimizer from 'ui/pages/load-optimizer/state/sosLoadOptimizer'
import * as commonClasses from 'ui/theme/common.module.scss'
import { addClass } from 'ui/theme/theme'
import { IFormattedRule } from '../state/sosLoadOptimizer'
import { RuleSpecifications } from './RuleSpecifications'

const LoadOptimizerRulesTable: FC = (props: {}) => {
	useOnce(() => {
		fireAndForget(sosLoadOptimizer.fetchRules, 'fetch rules')
	})

	const ruleTypeOptions: ISelectOptions[] = [
		{ value: 'address', label: 'matchOrderAddresses' },
		{ value: 'size', label: 'combinedWeightAndSize' },
		{ value: 'equipmentType', label: 'equipmentType' },
	]

	const [deletingRuleIds, setDeletingRuleIds] = useState([])

	const headers: ColumnDescription[] = [
		{
			dataField: 'ruleSelection',
			text: tString('ruleSelection', tPrefixLoadOptimizer),
			//@ts-ignore
			attrs: () => ({
				'data-testid': 'ruleSelection',
			}),
			formatter: (_cell, row: IFormattedRule) => {
				return (
					<Select
						value={row.ruleType}
						options={ruleTypeOptions}
						onChange={(newVal: IFormattedRule['ruleType']) => {
							const updatedRule = l.cloneDeep(row)
							updatedRule.ruleType = newVal
							fireAndForget(
								async () => sosLoadOptimizer.updateRule(updatedRule),
								'update rule',
							)
						}}
						tPrefix={tPrefixLoadOptimizer}
					/>
				)
			},
		},
		{
			dataField: 'ruleSpecifications',
			text: tString('ruleSpecifications', tPrefixLoadOptimizer),
			//@ts-ignore
			attrs: () => ({
				'data-testid': 'ruleSpecifications',
			}),
			formatter: (cell, row) => <RuleSpecifications cell={cell} row={row} />,
		},
		{
			dataField: 'delete',
			text: '',
			//@ts-ignore
			attrs: () => ({
				'data-testid': 'delete',
			}),
			formatter: (_cell, row: IFormattedRule) => {
				return (
					<div
						onClick={async () => {
							let newDeletingRuleIds = l.cloneDeep(deletingRuleIds)
							newDeletingRuleIds.push(row.id)
							setDeletingRuleIds(newDeletingRuleIds)
							await sosLoadOptimizer.deleteRule(row.id)
							newDeletingRuleIds = l.without(newDeletingRuleIds, row.id)
							setDeletingRuleIds(newDeletingRuleIds)
						}}
						className={addClass(
							deletingRuleIds.indexOf(row.id) === -1
								? classes.deleteRuleIcon
								: classes.deleteRuleSpinner,
							commonClasses.clickable,
						)}
					>
						{deletingRuleIds.indexOf(row.id) === -1 && <span>&times;</span>}
						{deletingRuleIds.indexOf(row.id) > -1 && (
							<Icon icon={solidIcons.faSpinner} spin={true} />
						)}
					</div>
				)
			},
		},
	]

	const state = sos2.useSubscription(sosLoadOptimizer.getSos())

	return (
		<Layout>
			<div className='bootstrap-wrapper'>
				<div className='container'>
					<h4>{t('consolidationRulesEngine', tPrefixLoadOptimizer)}</h4>
					<Spacer />
					<div className={classes.mediumText}>
						{t('rulesEngineHelpText', tPrefixLoadOptimizer)}
					</div>
					<AlignRight>
						<Button
							onClick={sosLoadOptimizer.addRule}
							color='blue'
							isSpinning={state.isAddingRule}
							bootstrapStyles={true}
						>
							{t('addRule', tPrefixLoadOptimizer)}
						</Button>
					</AlignRight>
					<Spacer />
					<Table
						columns={headers}
						data={state.rules}
						isLoading={state.isFetchingRules}
						totalSize={state.totalRuleCount}
						onTableChange={sosLoadOptimizer.fetchRules}
						keyField='id'
					/>
				</div>
			</div>
		</Layout>
	)
}

export { LoadOptimizerRulesTable }
