import React, { ReactElement, useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import { apiShipments } from 'ui/api'
import { ConnectionRequest, ConnectionResponse } from 'ui/api/apiTypes'
import { IRequestState } from 'ui/api/requestState'
import { Modal } from 'ui/components/common/modal'
import { OkCancelButtons } from 'ui/components/common/okCancelButtons'
import {
	FormStackedLayout,
	FormStackedTextInput,
	FormVerticalCheckbox,
	FormVerticalRadio,
	IFormData,
} from 'ui/components/form'
import { t, tString } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight'
import { l } from 'ui/lib/lodashImports'
import { validateForm } from 'ui/lib/validation/formValidator'
import { sosUser } from 'ui/state'
import * as classes from './ConnectionModal.module.scss'

const tPrefix = 'page.shipmentProfile.invites'
const translate = (key: string): React.ReactElement => t(key, tPrefix)

export interface ConnectionForm {
	companyName: string
	phone: string
	email: string
	contactName: string
	mc: string
	usdot: string
	scac: string
	isCarrier: boolean
	isFreightForwarder: boolean
	isBroker: boolean
	truckload: boolean
	volume: boolean
	active?: boolean
	id?: string
	locationId: string
}

export const defaultConnectionForm: ConnectionForm = {
	companyName: '',
	phone: '',
	email: '',
	contactName: '',
	mc: '',
	usdot: '',
	scac: '',
	isCarrier: true,
	isFreightForwarder: false,
	isBroker: false,
	truckload: true,
	volume: false,
	active: true,
	id: null,
	locationId: null,
}

const mapFormData = (connectionForm: ConnectionForm): ConnectionResponse => {
	return {
		companyName: connectionForm.companyName,
		phone: connectionForm.phone,
		email: connectionForm.email,
		contactName: connectionForm.contactName,
		mc: connectionForm.mc,
		usdot: connectionForm.usdot,
		scac: connectionForm.scac,
		connectionType: {
			isCarrier: connectionForm.isCarrier,
			isFreightForwarder: connectionForm.isFreightForwarder,
			isBroker: connectionForm.isBroker,
		},
		freightSize: {
			truckload: connectionForm.truckload,
			volume: connectionForm.volume,
		},
		active: true,
		locationId:
			connectionForm.locationId || sosUser.getSos().getState().locationId,
		id: connectionForm.id,
	} as ConnectionResponse
}

function upsertConnection(
	connection: ConnectionRequest,
	connectionId?: string,
): Promise<IRequestState<ConnectionRequest>> {
	if (connectionId) {
		return apiShipments.updateConnection(null, connectionId, connection)
	}

	return apiShipments.createConnection(() => {}, connection)
}

export const ConnectionModal = (props: {
	onHide: () => void
	connection?: ConnectionForm
	onSave: () => void
}): ReactElement => {
	const { onHide, connection, onSave } = props
	const [connectionForm, updateConnectionForm] = React.useState<ConnectionForm>(
		defaultConnectionForm,
	)
	const [isLoading, setIsLoading] = useState(false)

	useEffect(() => {
		updateConnectionForm(connection || defaultConnectionForm)
	}, [connection])

	const formData: IFormData<ConnectionForm> = {
		form: connectionForm,
		metadata: {
			companyName: { required: true },
			phone: {},
			email: { required: true },
			contactName: {},
			mc: { required: true },
			usdot: {},
			scac: {},
			isCarrier: { required: false, label: 'carrier' },
			isFreightForwarder: { required: false, label: 'freightForwarder' },
			isBroker: { required: false, label: 'broker' },
			truckload: { required: false },
			volume: { required: false },
			id: {},
			active: {},
			locationId: {},
		},
		onUpdateForm: (key: string, val: any) => {
			let value = val
			const toggles = ['isCarrier', 'isFreightForwarder', 'isBroker']
			if (toggles.includes(key)) {
				value = !connectionForm[key]
			}
			updateConnectionForm(
				Object.assign({}, connectionForm, {
					[key]: value,
				}),
			)
		},
		tPrefix,
	}

	const validationResult = validateForm(formData.form, formData.metadata)

	return (
		<Modal
			title={translate('connectionModal.title')}
			isOpen={!l.isNil(connection)}
			closeModalX={true}
			onModalClose={onHide}
			content={() => (
				<React.Fragment>
					<div
						className='bootstrap-wrapper'
						data-testid={'add-connection-modal'}
					>
						<Container>
							<Row>
								<Col className={classes.modalInputText}>
									<FormStackedLayout>
										<FormStackedTextInput
											formData={formData}
											field='companyName'
											testId={'modal-companyName'}
										/>
										<FormStackedTextInput
											formData={formData}
											field='contactName'
											testId={'modal-contactName'}
										/>
										<FormStackedTextInput
											formData={formData}
											field='phone'
											testId={'modal-phone'}
										/>
										<FormStackedTextInput
											formData={formData}
											field='email'
											testId={'modal-email'}
										/>
										<FormStackedTextInput
											formData={formData}
											field='mc'
											testId={'modal-mc'}
										/>
										<FormStackedTextInput
											formData={formData}
											field='usdot'
											testId={'modal-usdot'}
										/>
										<FormStackedTextInput
											formData={formData}
											field='scac'
											testId={'modal-scac'}
										/>
									</FormStackedLayout>
								</Col>

								<Col className={`pt-3 ${classes.modalFormCheckbox}`}>
									<FormStackedLayout>
										<FormVerticalCheckbox
											field='isCarrier'
											formData={formData}
											labelSide={'right'}
										/>
										<FormVerticalCheckbox
											field='isFreightForwarder'
											formData={formData}
											labelSide={'right'}
										/>
										<FormVerticalCheckbox
											field='isBroker'
											formData={formData}
											labelSide={'right'}
										/>
										<FormVerticalRadio
											labelSide='right'
											options={['volume', 'truckload']}
											selected={
												connectionForm.truckload ? 'truckload' : 'volume'
											}
											formData={formData}
											onChange={(selectedOption: any) => {
												const form = Object.assign({}, connectionForm)
												form.volume = selectedOption === 'volume'
												form.truckload = !form.volume
												updateConnectionForm(form)
											}}
										/>
									</FormStackedLayout>
								</Col>
							</Row>
						</Container>
					</div>
					<AlignRight>
						<OkCancelButtons
							okColor='blue'
							onOk={async () => {
								setIsLoading(true)

								await upsertConnection(
									mapFormData(connectionForm),
									connectionForm.id,
								)

								onSave()
								setIsLoading(false)
							}}
							isSpinning={isLoading}
							onCancel={onHide}
							ok={tString('connectionModal.save', tPrefix)}
							isValid={
								validationResult.isValid &&
								(connectionForm.isCarrier ||
									connectionForm.isBroker ||
									connectionForm.isFreightForwarder)
							}
							cancelTestId={'cancel-add-connection-button'}
							okTestId={'submit-add-connection-button'}
						/>
					</AlignRight>
				</React.Fragment>
			)}
		/>
	)
}
