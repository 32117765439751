import { FC } from 'app/FunctionalComponent'
import React from 'react'
import classes from './Tabs2.module.scss'

export const Tabs2: FC = (props: { children?: React.ReactNode }) => {
	return (
		<div className={classes.tabs}>
			<div className={classes.tabsItems}>
				{props.children}
				<div className={classes.tabsSpacer} />
			</div>
		</div>
	)
}
