import { IOfferInformation } from './sosMakeOfferPage'
import { l } from 'ui/lib/lodashImports'
import { toCents, toFloat } from 'ui/lib/numbers/toNumber'

export const getAccessorialsTotal = (
	offerInformation: IOfferInformation,
): number => {
	const accessorialCharges = offerInformation.accessorialCharges
	return accessorialCharges
		? l.sum(
				l.map(accessorialCharges, (accessorialCharge) =>
					toCents(accessorialCharge),
				),
		  )
		: 0
}

export const getFuelTotal = (offerInformation: IOfferInformation): number => {
	const { fuelCostType, fuelCost, estimatedMilesDistance } = offerInformation
	if (fuelCostType === 'included') {
		return 0
	} else if (fuelCostType === 'perMile') {
		return (toCents(fuelCost) || 0) * toFloat(estimatedMilesDistance) || 0
	} else if (fuelCostType === 'percent') {
		const { otherCharge } = offerInformation

		const lineHaulCostTotal = getLinehaulCostTotal(offerInformation)
		const accessorialsTotal = getAccessorialsTotal(offerInformation)
		const otherChargeTotal = toCents(otherCharge)

		const quoteTotalWithoutFuel =
			lineHaulCostTotal + accessorialsTotal + otherChargeTotal
		return toFloat((quoteTotalWithoutFuel * toFloat(fuelCost)) / 100)
	} else {
		return 0
	}
}

export const getLinehaulCostTotal = (
	offerInformation: IOfferInformation,
): number => {
	const {
		linehaulCost,
		linehaulCostType,
		estimatedMilesDistance,
	} = offerInformation
	return (
		(toCents(linehaulCost) || 0) *
		(linehaulCostType === 'perMile' ? toFloat(estimatedMilesDistance) || 0 : 1)
	)
}

export const getTotal = (offerInformation: IOfferInformation): number => {
	const { otherCharge } = offerInformation

	const lineHaulCostTotal = getLinehaulCostTotal(offerInformation)
	const fuelCostTotal = getFuelTotal(offerInformation)
	const accessorialsTotal = getAccessorialsTotal(offerInformation)
	const otherChargeTotal = toCents(otherCharge)

	const quoteTotal =
		lineHaulCostTotal + fuelCostTotal + accessorialsTotal + otherChargeTotal
	return quoteTotal
}
