import { FC } from 'app/FunctionalComponent'
import React from 'react'

export const ExternalLink: FC = (props: {
	href: string
	children?: React.ReactNode
}) => {
	return (
		<div>
			<a href={props.href} target='_blank' rel='noreferrer noopener'>
				{!props.children && props.href}
				{props.children}
			</a>
		</div>
	)
}
