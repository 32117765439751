import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { apiTypes } from 'ui/api'
import { t } from 'ui/components/i18n/i18n'
import { theme } from 'ui/theme'
import { addClass, getBackgroundWithTextColor } from 'ui/theme/theme'
import { appointmentStatusToColor } from '../lib/appointmentStatusToColor'
import { tPrefixStatusBoard } from './DockSchedulerStatusBoard'
import * as classes from './DockSchedulerStatusBoard.module.scss'

export const AppointmentStatusBadge: FC = (props: {
	status: apiTypes.AppointmentResponse['status']
	detention?: boolean
}) => {
	const detentionClassName = addClass(
		getBackgroundWithTextColor('lightRed'),
		classes.appointmentStatusBadge,
	)

	return (
		<div>
			<div
				className={theme.addClass(
					classes.appointmentStatusBadge,
					appointmentStatusToColor(props.status),
				)}
				data-testid='statusBadge'
				data-status={props.status}
			>
				{t(props.status, tPrefixStatusBoard + '.statuses')}
			</div>
			{props.detention && (
				<div className={detentionClassName} data-testid='detentionBadge'>
					{t('detention', tPrefixStatusBoard + '.statuses')}
				</div>
			)}
		</div>
	)
}
