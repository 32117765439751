import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { t } from 'ui/components/i18n/i18n'
import { RequiresFeatureToggle } from 'ui/components/permissions'
import { RequiresPermission } from 'ui/components/permissions/RequiresPermission'
import { l } from 'ui/lib/lodashImports'
import { sos2 } from 'ui/lib/state/sos2'
import { sosUser } from 'ui/state/'
import { IStateComponentExamples } from 'ui/state/sosComponentExamples'
import { Section } from './Section'

export const SectionPermissions: FC = (props: {
	stateComponentExamples: IStateComponentExamples
}) => {
	const stateUser = sos2.useSubscription(sosUser.getSos())

	return (
		<Section
			title={t('page.componentExamples.permissions')}
			stateComponentExamples={props.stateComponentExamples}
			name='permissions'
		>
			<div>
				Current permissions:
				<pre>{l.map(stateUser.permissions, (c) => `${c}\n`)}</pre>
			</div>
			<div>
				Permission tree
				<pre>{JSON.stringify(stateUser.permissionsTree, null, 2)}</pre>
			</div>

			<div>
				Permissions checks
				<RequiresPermission permission='Test:ComponentExamples:Show'>
					Test:ComponentExamples Test
				</RequiresPermission>
				<RequiresPermission permission='Test:ComponentExamples:Example1'>
					Test:ComponentExamples:Example1 Test
				</RequiresPermission>
				<RequiresPermission permission='Test:ComponentExamples:Example2'>
					Test:ComponentExamples:Example2 Test
				</RequiresPermission>
				<RequiresPermission permission='Test:InvalidPermission:1234'>
					Invalid permission
				</RequiresPermission>
			</div>
			<div>
				<RequiresFeatureToggle featureToggle='component-test-feature-toggle'>
					<div>This is inside a feature toggle</div>
				</RequiresFeatureToggle>
			</div>
		</Section>
	)
}
