import { FC } from 'app/FunctionalComponent'
import React, { useState } from 'react'
import { watch } from 'ui/components/hooks'
import { t } from 'ui/components/i18n/i18n'
import { l } from 'ui/lib/lodashImports'
import * as commonClasses from 'ui/theme/common.module.scss'
import { addClassIf, getBackgroundWithTextColor } from 'ui/theme/theme'
import { ISelectOptions } from '../select'
import * as classes from './Pill.module.scss'

export const PillSwitch: FC = (props: {
	value?: string
	options: ISelectOptions[]
	onChange: (selectedOption: string) => void
	disabled?: boolean
	className?: string
	tPrefix?: string
	testId?: string
}) => {
	const [selectedOption, setSelectedOption] = useState<ISelectOptions>(
		props.options[0],
	)

	watch(() => {
		if (props.value) {
			const initialValue = l.find(
				props.options,
				(option) => option.value === props.value,
			)

			if (initialValue) {
				setSelectedOption(initialValue)
			}
		}
	}, [props.value])

	return (
		<div className={classes.pillSwitch}>
			{l.map(props.options, (option) => {
				let className = classes.pill

				className = addClassIf(
					selectedOption.value === option.value,
					getBackgroundWithTextColor(props.disabled ? 'gray' : 'green'),
					className,
				)

				className = addClassIf(
					selectedOption.value !== option.value && !props.disabled,
					commonClasses.clickable,
					className,
				)

				const testId = (props.testId || 'pill') + '-' + option.value

				return (
					<div
						key={option.value}
						onClick={() => {
							if (option.value !== selectedOption.value && !props.disabled) {
								props.onChange(option.value)
								setSelectedOption(option)
							}
						}}
						data-selected={option.value === selectedOption.value}
						data-testid={testId}
						className={className}
					>
						{option.label || t(option.value, props.tPrefix)}
					</div>
				)
			})}
		</div>
	)
}
