import React from 'react'
import { l } from 'ui/lib/lodashImports'
import { validation } from 'ui/lib/validation'
import { formValidator } from 'ui/lib/validation/'
import { t } from '../i18n/i18n'
import classes from './FormStacked.module.scss'
import { IFormData } from './IFormData'

// import { validation } from 'ui/lib/validation/validation'

export function nodeOrTranslated(node: React.ReactNode, tPrefix?: string): any {
	if (l.isString(node)) {
		if (tPrefix) {
			return t(tPrefix + '.' + node)
		}
		return t(node)
	}
	return node
}

export function FormStackedCustom<T>(props: {
	label?: React.ReactNode
	description?: string
	formData: IFormData<T>
	children: React.ReactNode
	fields?: Array<keyof T>
	// required?: boolean
}): React.ReactElement {
	const { fields, formData } = props

	let showRequired = false
	let error = null
	l.forEach(fields, (c) => {
		const meta = props.formData.metadata[c]
		const val: any = formData.form[c]
		const isAValue = validation.isAValue(val)
		if (meta.required && !isAValue) {
			showRequired = true
		}
		if (!showRequired && !error) {
			error = formValidator.validateValue(meta, val)
		}
	})

	return (
		<React.Fragment>
			<div className={classes.item}>
				<div>
					<label className={classes.label}>
						{nodeOrTranslated(props.label, formData.tPrefix)}
					</label>
				</div>
				<div>
					<div className={classes.td}>{props.children}</div>
				</div>
				{props.description && (
					<div className={classes.descriptionRow}>
						<div>
							<div className={classes.description}>
								{nodeOrTranslated(props.description, formData.tPrefix)}
							</div>
						</div>
					</div>
				)}
				{error && !showRequired && (
					<div className={classes.errorRow}>
						<div className={classes.td}>
							<div className={classes.error}>{t(error)}</div>
						</div>
					</div>
				)}
			</div>
		</React.Fragment>
	)
}
