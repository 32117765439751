import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { apiCommon } from 'ui/api'
import { Button } from 'ui/components/common/button'
import { SimpleLink } from 'ui/components/common/router'
import { StackedItem } from 'ui/components/form'
import { t } from 'ui/components/i18n/i18n'
import { FlexRow } from 'ui/components/layout/flexRow'
import { tSidebarPrefix } from 'ui/components/layout/sidebar/Sidebar'
import { Spacer } from 'ui/components/layout/spacer'
import { fireAndForget } from 'ui/lib/async'
import { l } from 'ui/lib/lodashImports'
import { sosUser } from 'ui/state'
import { theme } from 'ui/theme'
import { Layout } from '../layout'
import { links } from '../layout/links'
import { DevCompany } from './DevCompany'

export const DevPage: FC = () => {
	if (theme.isInTMS2()) {
		return null // Display nothing in TMS2
	}

	return (
		<Layout>
			<FlexRow>
				<div>
					{l.map(links, (c) => (
						<div key={c.pathname}>
							<SimpleLink href={`${c.pathname}`} data-testid={c.pathname}>
								{t(c.name, tSidebarPrefix)}
							</SimpleLink>
						</div>
					))}
				</div>
			</FlexRow>
			<Spacer />
			<div>
				<Button
					onClick={() => {
						fireAndForget(async () => {
							await sosUser.fetchUser()
						}, 'fetchUser')
					}}
				>
					Fetch user
				</Button>

				<StackedItem label='API Host' hideOptional={true}>
					{apiCommon.getApiHost()}
				</StackedItem>

				<DevCompany />
			</div>
		</Layout>
	)
}
