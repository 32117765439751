import { sosToast } from 'common/components/toast'
import { apiShipments } from 'ui/api'
import { sosShipmentProfileShipper } from '..'

export const voidTrackingNumber = async (
	shipmentId: string,
	trackingNumber: string,
): Promise<void> => {
	const response = await apiShipments.voidTrackingNumbers(
		() => {},
		shipmentId,
		[trackingNumber],
	)
	if (response.data) {
		sosShipmentProfileShipper.setShipmentReference(response.data)
	} else if (response.error) {
		sosToast.sendApiErrorResponseToast(response)
	}
}
