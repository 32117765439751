import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { Modal } from 'ui/components/common/modal'
import { OkCancelButtons } from 'ui/components/common/okCancelButtons'
import { PaperCard } from 'ui/components/common/paperCard'
import { Radio, Radios } from 'ui/components/common/radioButton'
import {
	FormStacked,
	FormStackedItem,
	FormStackedLayout,
	FormStackedTextInput,
	IFormData,
	IFormMetadataCollection,
} from 'ui/components/form'
import { t } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight'
import { Center } from 'ui/components/layout/center'
import { FlexRow } from 'ui/components/layout/flexRow'
import { Spacer } from 'ui/components/layout/spacer'
import { l } from 'ui/lib/lodashImports'
import { validateForm } from 'ui/lib/validation/formValidator'
import { sosProviderPortal } from 'ui/state'
import { theme } from 'ui/theme'
import { paymentNumberTypeOptions } from '../PaymentNumberTypeOptions'
import { IProviderSignupForm } from './IProviderSignupForm'
import styles, * as classes from './ProviderPortalSignup.module.scss'

const tPrefix = 'page.providerPortal.signup'

const formMetadata: IFormMetadataCollection<IProviderSignupForm> = {
	name: {
		required: true,
	},
	email: {
		required: true,
	},
	password: {
		required: true,
	},
	confirmPassword: {
		required: true,
		customValidator: (val, formData) => {
			if (val === formData.password) {
				return null
			}
			return tPrefix + '.passwordError'
		},
	},
	paymentNumberType: {
		options: paymentNumberTypeOptions,
		defaultValue: 'check',
		clearable: false,
	},
	paymentNumber: {
		required: true,
	},
	proNumber: {
		required: true,
	},
}

const changePaymentNumberLabel = (newLabel: string): void => {
	formMetadata.paymentNumber.label = newLabel
}

export const ProviderPortalSignup: FC = (props: {}) => {
	const state = sosProviderPortal.useSubscribe()

	changePaymentNumberLabel(state.signupForm.paymentNumberType)

	const formData: IFormData<IProviderSignupForm> = {
		form: state.signupForm,
		metadata: formMetadata,
		onUpdateForm: (field, newVal) => {
			const changes = {}
			changes[field] = newVal
			sosProviderPortal.updateSignupForm(changes)
		},
		tPrefix: tPrefix,
	}

	const formValidation = validateForm(state.signupForm, formMetadata)
	return (
		<>
			<Center>
				<div className={styles.providerPortalContainer}>
					<PaperCard>
						<div
							className={classes.signupArea}
							data-testid='provider-portal-signup-area'
						>
							<div style={{ textAlign: 'center' }}>
								<h1>{t('title', tPrefix)}</h1>
							</div>
							<Center>
								<FormStacked>
									<FormStackedTextInput
										formData={formData}
										field='name'
										autocomplete='name'
										keepSpaceForRequired={true}
									/>
									<FormStackedTextInput
										formData={formData}
										field='email'
										inputType='email'
										autocomplete='email'
										keepSpaceForRequired={true}
									/>
									<FormStackedLayout>
										<FlexRow>
											<FormStackedTextInput
												formData={formData}
												field='password'
												inputType='password'
												autocomplete='new-password'
												keepSpaceForRequired={true}
											/>
											<FormStackedTextInput
												formData={formData}
												field='confirmPassword'
												inputType='password'
												autocomplete='new-password'
												keepSpaceForRequired={true}
											/>
										</FlexRow>
									</FormStackedLayout>
									<div style={{ paddingTop: '5px', marginBottom: '5px' }}>
										<FormStackedItem
											formData={formData}
											field={'paymentNumberType'}
											helpText={
												<div className={classes.helpText}>
													{t(
														formData.form.paymentNumberType,
														tPrefix + '.paymentNumberHelp',
													)}
												</div>
											}
										>
											<Radios orientation='vertical'>
												{l.map(paymentNumberTypeOptions, (c) => (
													<Radio
														key={c}
														value={formData.form.paymentNumberType === c}
														onSelected={() =>
															sosProviderPortal.updateSignupForm({
																paymentNumberType: c,
															})
														}
													>
														{t(c, tPrefix + '.paymentNumberType')}
													</Radio>
												))}
											</Radios>
											<FormStackedTextInput
												formData={formData}
												field='paymentNumber'
												autocomplete='off'
												keepSpaceForRequired={true}
											/>
										</FormStackedItem>
									</div>
									<FormStackedTextInput
										formData={formData}
										field='proNumber'
										autocomplete='off'
										helpText={
											<div className={classes.helpText}>
												{t('proNumberHelp', tPrefix)}
											</div>
										}
										keepSpaceForRequired={true}
									/>
								</FormStacked>
							</Center>
							<AlignRight>
								<OkCancelButtons
									ok={t('signUpButton', tPrefix)}
									onOk={sosProviderPortal.userSignup}
									okColor={formValidation.isValid ? 'green' : 'gray'}
									errors={state.errorMessage}
									isValid={true}
								/>
							</AlignRight>
						</div>
					</PaperCard>
				</div>
			</Center>
			<Modal
				title='Status'
				isOpen={!l.isNil(state.successMessage)}
				onModalClose={() => {}}
				content={() => (
					<div className={classes.redirect}>
						<div className={theme.getTextColor('green')}>
							{state.successMessage}
						</div>
						<Spacer />
						<div>{t('signingIn', tPrefix)}</div>
					</div>
				)}
			/>
		</>
	)
}
