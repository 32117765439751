import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { ErrorBox } from 'ui/components/common/angry-red-box'
import { Loader } from 'ui/components/common/loader'
import { StackedItem } from 'ui/components/form'
import { l } from 'ui/lib/lodashImports'
import { sos2 } from 'ui/lib/state/sos2'
import { sosUser } from 'ui/state/'

export const DevCompany: FC = (props: {}) => {
	const stateUser = sos2.useSubscription(sosUser.getSos())

	let username = 'Unknown'
	const isLoadingUser = stateUser.requestUserInfo.isFetching
	if (stateUser.requestUserInfo) {
		username = stateUser.username
	}

	return (
		<div>
			<div>
				{l.map(stateUser.requestLocationsInfo.data, (c, cIdx) => {
					return (
						<div key={cIdx}>
							{c.id} {c.name}
						</div>
					)
				})}
			</div>

			<div>
				<StackedItem label='User' hideOptional={true}>
					<Loader isLoading={isLoadingUser} />
					{username}
					<ErrorBox error={stateUser.requestUserInfo.error} />
				</StackedItem>
			</div>
		</div>
	)
}
