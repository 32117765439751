import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { t } from 'ui/components/i18n/i18n'
import classes from './Badge.module.scss'

export const Optional: FC = (props: {}) => {
	return (
		<span className={classes.optional}>
			&nbsp;&ndash;&nbsp;{t('component.badge.optional')}
		</span>
	)
}

// export const Optional = (props: {}) => {
// 	return <Badge>{t('component.badge.optional')}</Badge>
// }
