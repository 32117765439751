import { createLazySos2 } from 'ui/lib/state/sos2/sos2'
import { LocationResponse } from 'ui/api/apiTypes'
import { getEmptyAddress } from 'ui/components/shared/address'

export type TBrokerCompanyManagementTabs =
	| 'company'
	| 'branding'
	| 'catalogs'
	| 'codeGeneration'
	| 'shipNotifications'

export type TShipperCompanyManagementTabs =
	| 'shippingWorkflows'
	| 'dockConfiguration'
	| 'printersAndScales'

export interface UICompanyManagement {
	location: LocationResponse
	apiError: string
	apiSuccess: boolean
}

export const getSos = createLazySos2<UICompanyManagement>(
	'companyManagement',
	1,
	() => ({
		location: {
			default: {
				name: null,
				isOrganizationalUnit: null,
				defaults: { defaultBillingAddress: { address: getEmptyAddress() } },
				allDescendantOrganizationsIds: [],
			},
		},
		apiError: { default: null },
		apiSuccess: { default: false },
	}),
)
