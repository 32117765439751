import { FC } from 'app/FunctionalComponent'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { apiTypes } from 'ui/api'
import { BigBadge } from 'ui/components/common/badge'
import {
	tMode,
	tProviderName,
	tServiceLevel,
	tTrackingStatus,
} from 'ui/components/i18n/commonTranslations'
import { t, tCurrencyFromCents, tString } from 'ui/components/i18n/i18n'
import { CarrierImage } from 'ui/components/shared/CarrierImage'
import { l } from 'ui/lib/lodashImports'
import { theme } from 'ui/theme'
import * as classes from './ShipperShipmentSummary.module.scss'

export const ShipperShipmentSummary: FC = (props: {
	shipment: apiTypes.ShipmentResponse
}) => {
	const { shipment } = props
	const tPrefix = 'page.shipmentProfile.shipper.shipmentSummary'

	let latestTrackingStatus = null
	let latestTrackingTime = null

	shipment.payloads.forEach((payload) => {
		payload.trackingEvents?.forEach((trackingEvent) => {
			if (!latestTrackingTime || trackingEvent.timestamp > latestTrackingTime) {
				latestTrackingStatus = trackingEvent.status
				latestTrackingTime = trackingEvent.timestamp
			}
		})
	})

	let shipmentStatusClassName = classes.preparingBgColor
	switch (shipment.shipmentStatus) {
		case 'preparing':
			shipmentStatusClassName = classes.preparingBgColor
			break

		case 'quoted':
			shipmentStatusClassName = classes.quotedBgColor
			break

		case 'active':
			shipmentStatusClassName = classes.activeBgColor
			break

		case 'active_invites_not_sent':
			shipmentStatusClassName = classes.activeInvitesNotSentBgColor
			break

		case 'active_invites_sent':
			shipmentStatusClassName = classes.activeInvitesSentBgColor
			break

		case 'active_with_offers':
			shipmentStatusClassName = classes.activeWithOffersBgColor
			break

		case 'awarded':
			shipmentStatusClassName = classes.awardedBgColor
			break

		case 'booked':
			shipmentStatusClassName = classes.bookedBgColor
			break

		case 'void':
			shipmentStatusClassName = classes.voidBgColor
			break

		case 'no_longer_in_erp':
			shipmentStatusClassName = classes.noLongerInErpBgColor
			break
	}

	return (
		<div
			className={`${classes.shipmentSummaryCard} ${theme.getBorderColor(
				'lightGray',
			)} d-inline-block`}
		>
			<Row>
				<Col>
					<span
						className={classes.shipmentIdMargin}
						data-testid={'shipment-identifier'}
					>
						{tString('shipmentId', tPrefix)} # {shipment.identifier}
					</span>

					<BigBadge
						className={theme.addClass(
							classes.shipmentBigBadge,
							shipmentStatusClassName,
						)}
						testId={'summary-shipmentStatus'}
					>
						{l.startCase(shipment.shipmentStatus)}
					</BigBadge>

					<BigBadge
						className={classes.shipmentBigBadge}
						color={'lightBlue'}
						testId={'summary-trackingStatus'}
					>
						{latestTrackingStatus
							? tTrackingStatus(latestTrackingStatus)
							: t('noTracking', tPrefix)}
					</BigBadge>
				</Col>
			</Row>

			{shipment.bookedRate && (
				<Row>
					<Col className='d-flex justify-content-between'>
						<CarrierImage
							carrier={shipment.bookedRate.providerName}
							style={{ borderRadius: '5px', maxHeight: '28px', margin: '5px' }}
						/>
						<BigBadge
							className={classes.shipmentBigBadge}
							color={'purple'}
							testId={'summary-providerName'}
						>
							{tProviderName(shipment.bookedRate.providerName)}
						</BigBadge>
						<BigBadge
							className={classes.shipmentBigBadge}
							color={'darkPurple'}
							testId={'summary-method'}
						>
							{tMode(shipment.bookedRate.method)}
						</BigBadge>
						{shipment.bookedRate.serviceLevel && (
							<BigBadge
								className={classes.shipmentBigBadge}
								color={'lightBlue'}
								testId={'summary-serviceLevel'}
							>
								{tServiceLevel(shipment.bookedRate.serviceLevel)}
							</BigBadge>
						)}
						<BigBadge
							className={classes.shipmentBigBadge}
							color={'blue'}
							testId={'summary-totalCost'}
						>
							{tString('bookedRateTotalCost', tPrefix) +
								': ' +
								tCurrencyFromCents(shipment.bookedRate.costTotal)}
						</BigBadge>
					</Col>
				</Row>
			)}
		</div>
	)
}
