import { FC } from 'app/FunctionalComponent'
import React, { useEffect, useState } from 'react'
import { sosToast } from 'common/components/toast'
import { apiTypes, apiPrintNode } from 'ui/api'
import { Button } from 'ui/components/common/button'
import { Icon, solidIcons } from 'ui/components/common/icon'
import { t } from 'ui/components/i18n/i18n'
import { Center } from 'ui/components/layout/center'
import { l } from 'ui/lib/lodashImports'
import { AddPrinterModal } from '../add-printer-modal'
import { PrinterConfigModal } from '../printer-config-modal'
import * as classes from '../PrinterScalesListTable.module.scss'
import { Spacer } from 'ui/components/layout/spacer'
import { fireAndForget } from 'ui/lib/async'
import { IStatePager } from 'ui/state/paging'
import { ElasticSearchPager } from 'ui/components/common/pager'

export enum StatusEnum {
	online = 'green',
	offline = 'gray',
	error = 'red',
	out_of_paper = 'orange',
	idle = 'yellow',
	undefined = 'gray',
}

export const tPrefix = 'page.companyManagement.printersAndScales.tabs.printer'

export const PrinterListTable: FC = (props: {
	// printerList: apiPrintNode.UIPrinterResponse[]
	// setPrinterList: React.Dispatch<
	// 	React.SetStateAction<apiPrintNode.UIPrinterResponse[]>
	// >

	printerList: apiPrintNode.UIPrinterResponse[]
	setPrinterList: React.Dispatch<
		React.SetStateAction<apiPrintNode.UIPrinterResponse[]>
	>

	locationId: string
	userCredentials: apiTypes.PrintNodeCredentialsResponse
}) => {
	const [showOfflinePrinters, setShowOfflinePrinters] = useState<boolean>(true)
	const [isScanningPrintNode, setIsScanningPrintNode] = useState<boolean>(false)
	const [idOfPrinterToTest, setIdOfPrinterToTest] = useState<string>(null)
	const [isPrinterConfigModalOpen, setIsPrinterConfigModalOpen] = useState<
		boolean
	>(false)
	const [selectedPrinterConfig, setSelectedPrinterConfig] = useState<
		apiTypes.PrinterResponse
	>(null)
	const [isAddPrinterModalOpen, setIsAddPrinterModalOpen] = useState<boolean>(
		false,
	)

	const [pager, setPager] = useState<IStatePager>({
		fetchingPageNumber: 0,
		pageNumber: 0,
		pageCount: 0,
		pageSize: 25,
		total: 0,
		hasMore: true,
	})

	const [page, setPage] = useState<number>(0)

	useEffect(() => {
		fireAndForget(async () => {
			await scanPrintNode()
			await fetchPrintersList(0)
		}, `Fetching Printer List`)
	}, [props.userCredentials]) // eslint-disable-line react-hooks/exhaustive-deps

	const fetchPrintersList = async (page?: number): Promise<void> => {
		setIsScanningPrintNode(true)

		const printerListResponse = await apiPrintNode.getPrinterList(
			props.userCredentials.id,
			{
				take: 25,
				skip: page * 25,
			},
			true,
		)

		if (printerListResponse.error) {
			sosToast.sendApiErrorResponseToast(printerListResponse)
		} else {
			setPager({
				...pager,
				fetchingPageNumber: page,
				pageCount: printerListResponse.data.pageCount,
				pageNumber: page,
				total: printerListResponse.data.total,
			})

			props.setPrinterList(printerListResponse.data.entities)
		}

		setIsScanningPrintNode(false)
	}

	const scanPrintNode = async (): Promise<void> => {
		setIsScanningPrintNode(true)

		await apiPrintNode.createPrinter(() => {}, props.userCredentials.id)

		const printerListResponse = await apiPrintNode.getPrinterList(
			props.userCredentials.id,
		)

		if (printerListResponse.data) {
			props.setPrinterList(printerListResponse.data.entities)
		} else if (printerListResponse.error) {
			sosToast.sendApiErrorResponseToast(printerListResponse)
		}

		setIsScanningPrintNode(false)
	}

	const testPrinter = (id: string): void => {
		setIdOfPrinterToTest(id)

		setTimeout(() => {
			setIdOfPrinterToTest(null)
		}, 2000)
	}

	const sortedPrinterList = l.sortBy(
		l.sortBy(props.printerList, (printer) => printer.nickname),
		(printer) => printer.computerName,
	)

	const filteredPrinters = sortedPrinterList.filter(
		(printer) => printer.state === 'online' || showOfflinePrinters,
	)

	return (
		<div
			className={classes.printersListScalesTable}
			data-testid={'printer-list-container'}
		>
			<div className={classes.printerScaleListButtonContainer}>
				{/* <Button
					color={'blue'}
					isSpinning={false}
					testId={'printer-list-add-printer'}
					onClick={() => {
						setIsAddPrinterModalOpen(true)
					}}
				>
					{t('addPrinter', tPrefix)}
				</Button> */}
				<Button
					color={'blue'}
					isDisabled={isScanningPrintNode}
					isSpinning={false}
					testId={'printer-list-toggle-offline-printers'}
					onClick={() => {
						setShowOfflinePrinters(!showOfflinePrinters)
					}}
				>
					{showOfflinePrinters
						? t('hideOfflinePrinters', tPrefix)
						: t('showOfflinePrinters', tPrefix)}
				</Button>
				<Button
					color={'green'}
					isSpinning={isScanningPrintNode}
					testId={'printer-list-scan-for-printer-changes'}
					// onClick={scanPrintNode()}
				>
					{t('scanPrinterChanges', tPrefix)}
				</Button>
			</div>
			<table
				className={classes.printerScalesTable}
				cellPadding={0}
				cellSpacing={0}
			>
				<thead>
					<tr>
						<th>{t('online', tPrefix)}</th>
						<th>{t('computerName', tPrefix)}</th>
						<th>{t('deviceName', tPrefix)}</th>
						<th>{t('printNodeId', tPrefix)}</th>
						<th>{t('printerType', tPrefix)}</th>
						<th>{t('configuration', tPrefix)}</th>
					</tr>
				</thead>
				<tbody>
					{isScanningPrintNode ? (
						<tr>
							<td colSpan={2}>{t('loadingPrinterList', tPrefix)}</td>
						</tr>
					) : (
						<>
							{l.map(filteredPrinters, (printer, idx) => {
								const color = StatusEnum[printer.state]

								return (
									<tr
										key={printer.printNodeId + idx}
										data-testid={`printerListDataTable-${idx}`}
									>
										<td>
											<Center>
												<Icon
													color={color}
													icon={solidIcons.faCircle}
													className={classes.onlineIndicator}
												/>
											</Center>
										</td>
										<td>{printer.computerName}</td>
										<td>{printer.nickname}</td>
										<td>{printer.printNodeId}</td>
										<td>{t(printer.printerType.toString(), tPrefix)}</td>
										<td>
											<div>
												<Button
													color={'blue'}
													isSpinning={printer.printNodeId === idOfPrinterToTest}
													testId={'printer-list-test'}
													onClick={() => {
														testPrinter(printer.printNodeId)
													}}
												>
													{t('test', tPrefix)}
												</Button>

												<Button
													color={'blue'}
													isSpinning={false}
													testId={'printer-list-printer-config'}
													onClick={() => {
														setSelectedPrinterConfig(printer)
														setIsPrinterConfigModalOpen(true)
													}}
												>
													{t('printerConfig', tPrefix)}
												</Button>
											</div>
										</td>
									</tr>
								)
							})}
						</>
					)}
				</tbody>
			</table>
			<ElasticSearchPager
				pager={pager}
				onClickPage={async (pageNum: number) => {
					setPage(pageNum)
					await fetchPrintersList(pageNum)
				}}
			/>

			<Spacer height={'30px'} />

			<PrinterConfigModal
				isModalOpen={isPrinterConfigModalOpen}
				onModalClose={(printer: apiTypes.PrinterResponse) => {
					if (printer) {
						const index = props.printerList.findIndex(
							(item) => item.id === printer.id,
						)
						const printers = [...props.printerList]
						const isOnline = printers[index].state
						printers[index] = Object.assign({}, printer, { state: isOnline })
						props.setPrinterList(printers)
					}
					setIsPrinterConfigModalOpen(false)
				}}
				selectedPrinterConfig={selectedPrinterConfig}
				locationId={props.locationId}
				userCredentials={props.userCredentials}
			/>

			<AddPrinterModal
				isModalOpen={isAddPrinterModalOpen}
				onModalClose={() => {
					setIsAddPrinterModalOpen(false)
				}}
				printerList={props.printerList}
				setPrinterList={props.setPrinterList}
				locationId={props.locationId}
				credentialsId={props.userCredentials.id}
			/>
		</div>
	)
}
