import React from 'react'
import { FC } from 'app/FunctionalComponent'
import { apiTypes } from 'ui/api'
import { RateRow } from 'ui/components/rates-table'
import { compareBrokerShipmentStatus } from 'ui/pages/shipment-profile/shared/functions/shipmentStatusRanks'
import { BrokerRateInteractionButton } from 'ui/pages/shipment-profile/broker/components/rate-interaction-button'
import classes from 'ui/pages/shipment-profile/shared/components/rates/ShipmentProfileRateInteraction.module.scss'
import { RateBookingDetails } from 'ui/pages/shipment-profile/shared/functions/rateBookingDetailsTypes'

export const BrokerShipmentProfileRateInteraction: FC = (props: {
	rateRow: RateRow
	tPrefix: string
	shipment: apiTypes.BrokerShipmentResponse
	markupLogic: apiTypes.MarkupLogic
	shipmentIsRating: boolean
	setRateBookingDetails: React.Dispatch<
		React.SetStateAction<RateBookingDetails>
	>
	rateBookingDetails: RateBookingDetails
}) => {
	const {
		rateRow,
		tPrefix,
		shipment,
		markupLogic,
		shipmentIsRating,
		setRateBookingDetails,
		rateBookingDetails,
	} = props

	if (
		!shipment ||
		!rateRow ||
		compareBrokerShipmentStatus(shipment.brokerShipmentStatus, 'canceled') <= 0
	) {
		return null
	}

	return (
		<div className={classes.interactionOptions}>
			<BrokerRateInteractionButton
				rateRow={rateRow}
				shipment={shipment}
				markupLogic={markupLogic}
				tPrefix={tPrefix}
				shipmentIsRating={shipmentIsRating}
				setRateBookingDetails={setRateBookingDetails}
				rateBookingDetails={rateBookingDetails}
			/>
		</div>
	)
}
