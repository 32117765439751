import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { LinkButton } from 'ui/components/common/link'
import { t } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight'
import { FlexItem, FlexRow } from 'ui/components/layout/flexRow'
import { Spacer } from 'ui/components/layout/spacer/Spacer'
import { HeaderText } from 'ui/pages/spot-quote'
import { theme } from 'ui/theme'
import { addClass } from 'ui/theme/theme'
import { sosDockSchedulerAppointmentDetails } from '../state'
import { AppointmentDetailCard } from '../state/sosDockSchedulerAppointmentDetails'
import * as classes from './DockSchedulerAppointmentDetails.module.scss'

const tPrefix = 'page.dockScheduler.appointmentDetails.card'

export const DockSchedulerAppointmentDetailsCard: FC = (props: {
	cardType: AppointmentDetailCard
	children: React.ReactNode
	isActiveCard: boolean
	delivery?: boolean
}) => {
	const { cardType, isActiveCard } = props
	let cardClass = classes.detailsCard
	cardClass = addClass(theme.getBorderColor('gray'), cardClass)
	const showEditButton = !isActiveCard && cardType !== 'generalInformation'
	let headerTranslate = cardType
	if (props.delivery) {
		headerTranslate += '.delivery'
	}
	return (
		<FlexItem maxWidth={'19.5%'}>
			<div className={cardClass} data-testid={`${tPrefix}.${cardType}`}>
				<FlexRow fullWidth={true} mode='space-between'>
					<FlexItem>
						<HeaderText large={true} bold={true}>
							{t(headerTranslate, tPrefix)}
						</HeaderText>
					</FlexItem>
					{showEditButton && (
						<FlexItem align='right'>
							<AlignRight>
								<LinkButton
									onClick={() =>
										sosDockSchedulerAppointmentDetails.setStageEdit(cardType)
									}
								>
									{t('edit', tPrefix)}
								</LinkButton>
							</AlignRight>
						</FlexItem>
					)}
				</FlexRow>
				<Spacer />
				{props.children}
			</div>
		</FlexItem>
	)
}
