import React, { useContext } from 'react'
import { Comment } from 'ui/components/common/comment/'
import { theme } from 'ui/theme'
import { IRoute, SimpleRouter, sosRouter } from 'ui/components/common/router'
import { tms2 } from 'ui/lib'
import { fireAndForget } from 'ui/lib/async'
import { sos2 } from 'ui/lib/state/sos2'
import { ApiV6Examples } from 'ui/pages/api-docs/ApiExamples'
import { RouterBooks } from 'ui/pages/books'
import { RouterProviderPortal } from 'ui/pages/provider-portal/RouterProviderPortal'
import { RouterRfp } from 'ui/pages/rfp'
import { RouterShipments } from 'ui/pages/shipments/RouterShipments'
import { RouterTests } from 'ui/pages/tests'
import { WelcomePage } from 'ui/pages/welcome/WelcomePage'
import { log } from 'ui/lib/log'
import { RouterCompanyManagement } from 'ui/pages/company-management'
import { RouterCustomerManagement } from 'ui/pages/customer-management'
import { DevLoginPage } from 'ui/pages/login'
import { DevPage, DevPageConfig } from 'ui/pages/dev'
import { RouterDockScheduler } from 'ui/pages/dock-scheduler'
import { Marketplace } from 'marketplace/pages'
import { RouterControlTower } from 'ui/pages/control-tower/RouterControlTower'
import { RouterLoadOptimizer } from 'ui/pages/load-optimizer/RouterLoadOptimizer'
import { RouterManifests } from 'ui/pages/manifests/RouterManifests'
import { AnalyticsPage } from 'ui/pages/analytics/AnalyticsPage'
import { ManageFeatureToggle } from 'ui/pages/manage-feature-toggle/ManageFeatureToggle'
import { RouterProviders } from 'ui/pages/provider-management/RouterProviders'
import { sosToast, ToastContext, ToastDispatch } from 'common/components/toast'
import { useOnce } from 'ui/components/hooks'
import { FC } from './FunctionalComponent'

// const CsvValidatorPage = React.lazy(() => import('./pages/csv-validator/CsvValidatorPage'))
export const NotFound = (): React.ReactElement => {
	if (theme.isInTMS2()) {
		return null // Display nothing in TMS2
	}
	return <h2>404 Page not found</h2>
}

const TMS2 = (): React.ReactElement => {
	return (
		<div>
			<Comment>Waiting for TMS2 to Sync Page Location</Comment>
		</div>
	)
}

// const WaitingComponent = (Component) => {
//   return (props) => (
//     <Suspense fallback={<Preloader />}>
//       <Component {...props} />
//     </Suspense>
//   )
// }

export const routes: IRoute[] = [
	{
		isDefault: true,
		urls: ['/404'],
		renderer: () => <NotFound />,
		anonymous: true,
	},
	{
		urls: ['/welcome-v3', '/index.html', '/'],
		renderer: () => <WelcomePage />,
	},
	{
		urls: ['/dev-login'],
		renderer: () => <DevLoginPage />,
		anonymous: true,
	},
	{
		urls: ['/analytics'],
		renderer: () => <AnalyticsPage />,
	},
	{
		urls: ['/dev-page-config'],
		renderer: () => <DevPageConfig />,
		anonymous: theme.isInDev(),
	},
	{
		urls: ['/dev'],
		renderer: () => <DevPage />,
		anonymous: theme.isInDev(),
	},
	{
		urls: ['/tms2'],
		renderer: () => <TMS2 />,
	},
	{
		urls: ['/tests-v3'],
		renderer: (currentLocation, params, loginMode) => (
			<RouterTests currentLocation={currentLocation} loginMode={loginMode} />
		),
	},
	{
		urls: ['/books-v3'],
		renderer: (currentLocation, params, loginMode) => (
			<RouterBooks currentLocation={currentLocation} loginMode={loginMode} />
		),
	},
	{
		urls: ['/shipments-v3'],
		renderer: (currentLocation, params, loginMode) => (
			<RouterShipments
				currentLocation={currentLocation}
				loginMode={loginMode}
			/>
		),
		anonymous: true,
	},
	{
		urls: ['/api-v6-examples'],
		renderer: () => <ApiV6Examples />,
	},
	{
		urls: ['/api/v6/swagger/examples.html'],
		renderer: () => <ApiV6Examples />,
	},
	{
		urls: ['/provider-portal'],
		renderer: (currentLocation, params, loginMode) => (
			<RouterProviderPortal
				currentLocation={currentLocation}
				anonymous={false}
				loginMode={loginMode}
			/>
		),
		anonymous: true,
	},
	{
		urls: ['/rfp'],
		// if: () => {
		// 	return sosUser.checkFeatureToggle('rfp')
		// },
		renderer: (currentLocation, params, loginMode) => (
			<RouterRfp currentLocation={currentLocation} loginMode={loginMode} />
		),
	},
	{
		urls: ['/company-management-v3'],
		renderer: (currentLocation, params, loginMode) => (
			<RouterCompanyManagement
				currentLocation={currentLocation}
				loginMode={loginMode}
			/>
		),
	},
	{
		urls: ['/customer-management-v3'],
		renderer: (currentLocation, params, loginMode) => (
			<RouterCustomerManagement
				currentLocation={currentLocation}
				loginMode={loginMode}
			/>
		),
	},
	{
		urls: ['/providers'],
		renderer: (currentLocation, params, loginMode) => (
			<RouterProviders
				currentLocation={currentLocation}
				loginMode={loginMode}
			/>
		),
	},
	{
		urls: ['/dock-scheduler'],
		renderer: (currentLocation, params, loginMode) => (
			<RouterDockScheduler
				currentLocation={currentLocation}
				loginMode={loginMode}
			/>
		),
	},
	{
		urls: ['/control-tower'],
		renderer: (currentLocation, params, loginMode) => (
			<RouterControlTower
				currentLocation={currentLocation}
				loginMode={loginMode}
			/>
		),
	},
	{
		urls: ['/load-optimizer'],
		renderer: (currentLocation, params, loginMode) => (
			<RouterLoadOptimizer
				currentLocation={currentLocation}
				loginMode={loginMode}
			/>
		),
	},
	{
		urls: ['/manifests'],
		renderer: (currentLocation, params, loginMode) => (
			<RouterManifests
				currentLocation={currentLocation}
				loginMode={loginMode}
			/>
		),
	},
	{
		urls: ['/marketplace'],
		renderer: () => <Marketplace />,
	},
	{
		urls: ['/manage-feature-toggles'],
		renderer: () => <ManageFeatureToggle />,
	},
]

export const AppRouterTms2Redirect = (): void => {
	if (theme.isInTMS2()) {
		const goToHref = async (): Promise<void> => {
			const pathname = await tms2.getPathname()
			log('app-router', 'redirect to', pathname)
			sosRouter.navigate(pathname)
		}
		fireAndForget(goToHref, 'goToHref')
	}
}

export const AppRouter: FC = (props: {
	loginMode: 'unknown' | 'anonymous' | 'loggedIn'
}) => {
	const stateRouter = sos2.useSubscription(sosRouter.getSos())
	const toastDispatch: ToastDispatch = useContext(ToastContext)

	useOnce(() => {
		sosToast.setToastDispatch(toastDispatch)
	})

	React.useEffect(() => {
		AppRouterTms2Redirect()
	}, [stateRouter])

	return (
		<SimpleRouter
			routes={routes}
			currentLocation={stateRouter.currentLocation}
			loginMode={props.loginMode}
		/>
	)
}
