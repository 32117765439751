import { IFormMetadataCollection } from 'ui/components/form/IFormMetadata'
import { addressTypes } from 'ui/data/addressTypes'
import { countries } from 'ui/data/countries'
import { IAddressBookEntry } from 'ui/lib/books/IAddressBookEntry'
import { FormStringData } from './formUtils'

export type IAddressBookEntryForm = FormStringData<IAddressBookEntry>

const addressBookEntryFormMetadata: IFormMetadataCollection<IAddressBookEntryForm> = {
	id: {
		readOnly: true,
	},
	name: {
		required: true,
	},
	city: {},
	phone: {},
	email: {},
	street1: {},
	street2: {},
	zip: {},
	state: {},
	country: {
		options: countries,
	},
	addressType: {
		options: addressTypes,
	},
}

export { addressBookEntryFormMetadata }
