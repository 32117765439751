import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { theme } from 'ui/theme'
import { Colors } from 'ui/theme/theme'
import classes from './CardTitle.module.scss'

export const CardTitle: FC = (props: {
	color: Colors
	children?: React.ReactNode
	onToggle?: () => void
}) => {
	let className = `${classes.cardTitle} ${theme.getColor(
		props.color,
	)} ${theme.getBorderBottomColor(props.color)}`

	if (props.onToggle) {
		className += ' ' + classes.toggleable
	}

	return (
		<div onClick={props.onToggle} className={className}>
			{props.children}
		</div>
	)
}
