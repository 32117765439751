import { DateTime } from 'luxon'
import React from 'react'
import { apiTypes } from 'ui/api'
import { t } from 'ui/components/i18n/i18n'
import { Spacer } from 'ui/components/layout/spacer'
import { l } from 'ui/lib/lodashImports'
import { getTimeSlotsByDate, ITimeSlot } from 'ui/lib/time/timeSlots'
import { addClass, getBorderColor, getTextColor } from 'ui/theme/theme'
import * as classes from '../../dock-scheduler/DockScheduler.module.scss'
import { tPrefixDockConfig } from './DockConfigurationPage'
import { FifteenMinuteSlotBoxRow } from './FifteenMinuteSlotBoxRow'
import { TimeBox } from './TimeBox'
import { RowPositionType } from './FifteenMinuteSlotBox'
import { FC } from 'app/FunctionalComponent'

const allTimes = (): React.ReactElement[] => {
	const times: React.ReactElement[] = []
	for (let i = 0; i < 24; i++) {
		times.push(<TimeBox hour={i} key={i} />)
	}
	return times
}

export const AllDockDays: FC = (props: {
	rules: apiTypes.ScheduleRuleRequest[]
}) => {
	let className = addClass(classes.box, getBorderColor('gray'))
	className = addClass(getTextColor('gray'), className)

	let dayClassName = addClass(classes.closedBox, className)
	dayClassName = addClass(classes.dayBox, dayClassName)

	const tPrefixDockConfigDays = tPrefixDockConfig + '.day'

	const days: string[] = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']
	const weekOfTimeSlots: ITimeSlot[][] = [[], [], [], [], [], [], []]

	if (props.rules) {
		l.forEach(props.rules, (rule) => {
			l.forEach(weekOfTimeSlots, (timeSlots, dayIdx) => {
				weekOfTimeSlots[dayIdx] = timeSlots.concat(
					getTimeSlotsByDate(
						rule,
						DateTime.local().set({ weekday: dayIdx }).toISO(),
					),
				)
			})
		})
	}

	return (
		<div>
			<Spacer />
			<div className={classes.row}>
				<div>
					<div className={addClass(classes.box, classes.emptyBox)} />
					<div>
						{l.map(days, (day, dayIdx) => {
							return (
								<div className={dayClassName} key={dayIdx}>
									{t(day, tPrefixDockConfigDays)}
								</div>
							)
						})}
					</div>
				</div>
				<div>
					<div className={classes.row}>{allTimes()}</div>
					{l.map(weekOfTimeSlots, (timeSlots, dayIdx) => {
						let position: RowPositionType
						if (dayIdx === 0) {
							position = 'top'
						} else if (dayIdx === 6) {
							position = 'bottom'
						} else {
							position = 'middle'
						}

						return (
							<FifteenMinuteSlotBoxRow
								key={dayIdx}
								timeSlots={timeSlots}
								rowPosition={position}
							/>
						)
					})}
				</div>
			</div>
		</div>
	)
}
