import { apiCommon, apiTypes } from 'ui/api'
import { UpdateMode } from 'ui/api/apiCommon'
import { IRequestState } from 'ui/api/requestState'
import { IAddressBook } from 'ui/lib/books/IAddressBook'
import { IAddressBookEntry } from 'ui/lib/books/IAddressBookEntry'

export async function getAddressBooks(
	onProgress: (rs: IRequestState<IAddressBook[]>) => void,
): Promise<IRequestState<IAddressBook[]>> {
	return await apiCommon.apiFetch(onProgress, {}, 'books/address')
}

export async function getAddressBook(
	bookId: string,
	onProgress: (rs: IRequestState<IAddressBook>) => void,
): Promise<IRequestState<IAddressBook>> {
	return await apiCommon.apiFetch(onProgress, {}, `books/address/${bookId}`)
}

export async function updateAddressBook(
	mode: UpdateMode,
	data: Partial<IAddressBook>,
	onProgress: (rs: IRequestState<IAddressBook>) => void,
): Promise<IRequestState<IAddressBook>> {
	let url = 'books/address'
	if (data.id) {
		url += `/${data.id}`
	}
	return await apiCommon.apiFetch(
		onProgress,
		{
			method: apiCommon.modeToMethod(mode, data),
			data,
		},
		url,
	)
}

export async function getAddressBookEntries(
	bookId: string,
	query: { take: number; skip: number },
	onProgress: (rs: IRequestState<apiTypes.AddressPageListResponse>) => void,
): Promise<IRequestState<apiTypes.AddressPageListResponse>> {
	return await apiCommon.apiFetch(
		onProgress,
		{ entireResponse: true },
		`books/address/${bookId}/pages`,
		query,
	)
}

export async function updateAddressBookEntry(
	mode: UpdateMode,
	bookId: string,
	data, //TODO: Put the correct type on this (same as the old type, but in an object with a key of 'address')
	onProgress: (rs: IRequestState<IAddressBookEntry>) => void,
): Promise<IRequestState<IAddressBookEntry>> {
	let url = `books/address/${bookId}/pages`
	if (data.id) {
		url += `/${data.id}`
	}
	return await apiCommon.apiFetch(
		onProgress,
		{
			method: apiCommon.modeToMethod(mode, data),
			data,
		},
		url,
	)
}

export const searchAddressBook = async (
	onProgress: (rs: IRequestState<apiTypes.AddressPageListResponse>) => void,
	body: apiTypes.ApiListRequest,
): Promise<IRequestState<apiTypes.AddressPageListResponse>> => {
	const url = 'books/address/pages/search'
	return await apiCommon.apiFetch(
		onProgress,
		{
			entireResponse: true,
			method: 'POST',
			data: body,
		},
		url,
	)
}
