import { FC } from 'app/FunctionalComponent'
import React, { CSSProperties } from 'react'
import { theme } from 'ui/theme'
import classes from './Filters.module.scss'

export const Filters: FC = (props: {
	children?: React.ReactNode
	style?: CSSProperties
	className?: string
}) => {
	let className = classes.filters
	className = theme.addClass(props.className, className)
	return (
		<div className={className} style={props.style}>
			{props.children}{' '}
		</div>
	)
}
