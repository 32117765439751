import { FC } from 'app/FunctionalComponent'
import React from 'react'
import classes from './Subway.module.scss'

export const SubwayStation: FC = (props: {
	top: React.ReactNode
	bottom: React.ReactNode
	children: React.ReactNode
	dataTestId?: string
}) => {
	return (
		<div data-testid={props.dataTestId}>
			{props.top != null && <div className={classes.topLabel}>{props.top}</div>}
			<div className={classes.station}>{props.children}</div>
			{props.bottom != null && (
				<div className={classes.bottomLabel}>{props.bottom}</div>
			)}
		</div>
	)
}
