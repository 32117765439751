import React from 'react'
import { solidIcons } from 'ui/components/common/icon'
import { IconButton } from 'ui/components/common/icon/Icon'
import { t } from 'ui/components/i18n/i18n'
import { l } from 'ui/lib/lodashImports'
import commonStyles from 'ui/theme/common.module.scss'
import { addClassIf } from 'ui/theme/theme'
import { DataCell } from './DataCell'
import { IDataTableHeaderProcessed } from './IDataTableHeaderProcessed'
import { IDataTableState } from './IDataTableState'
import { Cell, Row } from './Table'
import classes from './TableStyles.module.scss'

export const DataTableBody = <T extends any>(props: {
	data: T[]
	state: IDataTableState<T>
	onClickRowZoom?: (row: T) => void
	onClickRow?: (row: T) => void
	onEditRow?: (row: T) => void
	rendererEdit?: (row: T) => React.ReactNode
	testId?: string
	filteredHeaders: IDataTableHeaderProcessed<T>[]
	columnCount: number
	spacerCell: boolean
	highlightedRows?: string[]
	fadedRowIdxs?: number[]
	rowClassName?: string
}): React.ReactElement => {
	const { filteredHeaders, columnCount, data } = props
	let { fadedRowIdxs } = props
	fadedRowIdxs = fadedRowIdxs || []

	let rowClassName = props.rowClassName
	rowClassName = addClassIf(
		!l.isNil(props.onClickRow),
		commonStyles.clickable,
		rowClassName,
	)

	const onEditRow = (ev: React.MouseEvent<HTMLElement>, row): void => {
		if (props.onEditRow) {
			ev.preventDefault()
			ev.stopPropagation()
			props.onEditRow(row)
		}
	}

	const onClickRow = (ev: React.MouseEvent<HTMLElement>, row: T): void => {
		if (props.onClickRow) {
			ev.preventDefault()
			ev.stopPropagation()
			props.onClickRow(row)
		}
	}
	const onClickRowZoom = (ev: React.MouseEvent<HTMLElement>, row: T): void => {
		if (props.onClickRowZoom) {
			ev.preventDefault()
			ev.stopPropagation()
			props.onClickRowZoom(row)
		}
	}

	return (
		<>
			{l.map(data, (row, rowIdx) => (
				<React.Fragment key={rowIdx}>
					<Row
						onClick={(ev) => {
							onClickRow(ev, row)
						}}
						className={rowClassName}
						highlighted={l.includes(props.highlightedRows, row.id)}
						faded={fadedRowIdxs.indexOf(rowIdx) > -1}
						testId={props.testId + '-' + rowIdx}
					>
						{props.onEditRow && row && (
							<Cell
								key='edit'
								onClick={(ev) => {
									onEditRow(ev, row)
								}}
							>
								<IconButton
									onClick={(ev) => {
										onEditRow(ev, row)
									}}
									iconClassName={classes.editIcon}
									icon={solidIcons.faPencilAlt}
									testId={props.testId + '-edit'}
								/>
							</Cell>
						)}

						{props.onClickRowZoom && row && (
							<Cell
								key='zoom'
								onClick={(ev) => {
									onClickRowZoom(ev, row)
								}}
							>
								<IconButton
									onClick={(ev) => {
										onClickRowZoom(ev, row)
									}}
									iconClassName={classes.searchIcon}
									icon={solidIcons.faSearchPlus}
									testId={props.testId + '-zoom'}
								/>
							</Cell>
						)}

						{row ? (
							<>
								{l.map(filteredHeaders, (header, cellIdx) => (
									<DataCell
										className={header.className}
										key={cellIdx}
										row={row}
										header={header}
										testId={props.testId + '-' + header.field}
									/>
								))}
								{props.spacerCell && <DataCell key='end' row={row} />}
							</>
						) : (
							<td colSpan={columnCount}>{t('component.table.noData')}</td>
						)}
					</Row>

					{row === props.state.editRow && props.rendererEdit && (
						<Row className={classes.edit}>
							<td colSpan={columnCount}>
								<div>{props.rendererEdit(row)}</div>
							</td>
						</Row>
					)}
				</React.Fragment>
			))}
		</>
	)
}
