import { apiTypes, apiDockScheduler } from 'ui/api'
import { createLazySos2 } from 'ui/lib/state/sos2/sos2'
import { elasticSearchBuilder } from 'ui/lib/elasticSearch'
import { sosDockScheduler } from '../state'

export interface IStateDockSchedulerStatusBoard {
	appointments: apiTypes.AppointmentResponse[]
	currentTime: string
}

export const getSos = createLazySos2<IStateDockSchedulerStatusBoard>(
	'sosDockSchedulerStatusBoard',
	1,
	() => ({
		appointments: { default: [], localStorage: true },
		currentTime: { default: null },
	}),
)

export const fetchAppointments = async (): Promise<void> => {
	const qb = elasticSearchBuilder()
	qb.and('locationId', sosDockScheduler.getSos().getState().currentLocation?.id)
	qb.andIn('status', ['arrived', 'loading', 'unloading', 'loaded', 'unloaded'])
	const take = 100
	const query = qb.toQuery()
	let appointments: apiTypes.AppointmentResponse[] = []
	let result = await apiDockScheduler.fetchAppointments(() => {}, {
		query,
		take,
	})
	if (result.data) {
		appointments = appointments.concat(result.data.entities)
		if (result.data.pageCount > 1) {
			let page = 1
			while (result.data.pageCount > page) {
				result = await apiDockScheduler.fetchAppointments(() => {}, {
					take,
					skip: page * take,
					query,
				})
				appointments = appointments.concat(result.data.entities)
				page++
			}
		}
		getSos().change((ds) => {
			ds.appointments = appointments
		})
	}
}

export const updateCurrentTime = (currentTime: string): void => {
	getSos().change((ds) => {
		ds.currentTime = currentTime
	})
}
