import { FC } from 'app/FunctionalComponent'
import React from 'react'
import classes from './Group.module.scss'

export const Group: FC = (props: {
	children?: React.ReactNode
	onClick?: () => void
}) => {
	return (
		<div
			className={`${classes.group}`}
			onClick={props.onClick}
			style={{ cursor: props.onClick && 'pointer' }}
		>
			{props.children}
		</div>
	)
}
