import { FC } from 'app/FunctionalComponent'
import React from 'react'
import classes from './LoadingBar.module.scss'

export const LoadingBar: FC = (props: {
	totalSteps: number
	completedSteps: number
	isLoading: boolean
	message?: React.ReactNode
}) => {
	const totalSteps = Math.max(props.totalSteps || 1, 1)
	let percentage = (props.completedSteps / totalSteps) * 100
	if (percentage < 0) {
		percentage = 0
	}
	if (percentage > 100) {
		percentage = 100
	}
	return (
		<div>
			{props.isLoading && (
				<div>
					<div className={classes.loadingBar}>
						<div
							data-testid='loaded-bar'
							style={{
								width: percentage + '%',
							}}
							className={classes.loadedBar}
						/>
					</div>
					<div>{props.message}</div>
				</div>
			)}
		</div>
	)
}
