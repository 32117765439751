import { FC } from 'app/FunctionalComponent'
import _ from 'lodash'
import React from 'react'
import { Checkbox } from 'ui/components/common/checkbox'
import { t } from 'ui/components/i18n/i18n'
import { getAccessorialKeyPath } from 'ui/pages/new-quote/accessorials'
import { IAccessorialProps } from './Accessorials'

//if props.renderer is not set, set it to checkbox
export const AccessorialCheckbox: FC = (props: IAccessorialProps) => {
	const {
		accessorialMetadata,
		mode,
		accessorialObject,
		updateState,
		stopId,
		tPrefix,
	} = props

	const accessorialKey = getAccessorialKeyPath(accessorialMetadata, mode)

	return (
		<Checkbox
			value={_.get(accessorialObject, accessorialKey) || false}
			onChange={(newVal: boolean) => {
				const changes: any = {}
				_.set(changes, accessorialKey, newVal)
				updateState(changes, stopId)
			}}
		>
			{tPrefix
				? t(accessorialMetadata.accessorialKey, tPrefix)
				: accessorialMetadata.accessorialKey}
		</Checkbox>
	)
}
