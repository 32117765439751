import { apiTypes } from 'ui/api'
import { l } from 'ui/lib/lodashImports'

export const validateShipmentData = (
	shipmentData: apiTypes.ShipmentRequest,
	validations: ((shipment: apiTypes.ShipmentRequest) => string[])[] = [],
): string[] => {
	const errorMessages: string[] = []

	l.forEach(validations, (validation) => {
		const validationFailures = validation(shipmentData)
		if (validationFailures.length) {
			errorMessages.push(...validationFailures)
		}
	})

	return errorMessages
}
