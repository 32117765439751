import { FC } from 'app/FunctionalComponent'
import { DateTime } from 'luxon'
import React from 'react'
import { IconButton } from 'ui/components/common/icon/Icon'
import { solidIcons } from 'ui/components/common/icon/solidIcons'
import { DataTable, IDataTableHeader } from 'ui/components/table'
import { ITimeSlot } from 'ui/lib/time/timeSlots'
import * as classes from 'ui/pages/dock-scheduler/DockScheduler.module.scss'
import * as sosDockConfigPage from 'ui/pages/dock-scheduler/state/sosDockConfigPage'
import { getTextColor } from 'ui/theme/theme'
import { tPrefixDockConfig } from './DockConfigurationPage'

interface ITimeSlotHeaders {
	appointmentStart: string
	appointmentEnd: string
	delete: React.ReactElement
}

export const DockConfigurationDataTable: FC = (props: {
	timeSlots: ITimeSlot[]
	scheduleId: string
	isLoading: boolean
}) => {
	const headers: IDataTableHeader<ITimeSlotHeaders>[] = [
		{ field: 'appointmentStart', columnAlign: 'center' },
		{ field: 'appointmentEnd', columnAlign: 'center' },
		{ field: 'delete', columnAlign: 'center' },
	]

	const timeSlots = props.timeSlots.map((timeSlot) => {
		return {
			appointmentStart: DateTime.fromISO(timeSlot.startTime, { setZone: true })
				.toFormat('h:mma')
				.toLowerCase(),
			appointmentEnd: DateTime.fromISO(timeSlot.endTime, { setZone: true })
				.toFormat('h:mma')
				.toLowerCase(),
			delete: (
				<IconButton
					icon={solidIcons.faTrashAlt}
					iconClassName={getTextColor('red')}
					onClick={() => {
						sosDockConfigPage.removeTimeSlot(timeSlot)
					}}
				/>
			),
		}
	})

	return (
		<DataTable
			headers={headers}
			data={timeSlots}
			state={{}}
			tPrefix={tPrefixDockConfig}
			isLoading={props.isLoading}
			testId='dockTimeSlots'
			fontSize='fontMedium'
			spacerCell={false}
			rowClassName={classes.rowPadding}
		/>
	)
}
