import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { t } from 'ui/components/i18n/i18n'
import * as tableClasses from 'ui/components/table/TableStyles.module.scss'
import { l } from 'ui/lib/lodashImports'
import * as stateCsvValidator from 'ui/state/sosCsvValidator'
import * as rawDataTableClasses from './RawDataTable.module.scss'

const RawDataTable: FC = (props: {
	state: stateCsvValidator.IStateCsvValidator
	inset?: boolean
	maxRows?: number
	borderless?: boolean
}) => {
	let rawRecords
	if (props.maxRows) {
		rawRecords =
			props.state.rawRecords && props.state.rawRecords.slice(0, props.maxRows)
	} else {
		rawRecords = props.state.rawRecords
	}

	const border = props.borderless ? 'none' : null

	return (
		<div
			className={`${tableClasses.dataTable} ${
				props.inset && tableClasses.inset
			}`}
		>
			<table>
				<thead>
					<tr>
						<th>{t('common.lineNumber')}</th>
						{l.map(props.state.columns, (c, cIdx) => (
							<th key={cIdx}>{c}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{l.map(rawRecords, (r, rIdx) => (
						<tr key={rIdx}>
							<td
								style={{
									borderLeft: border,
									borderTop: border,
									borderRight: border,
									borderBottom: border,
								}}
							>
								{rIdx + 1}
							</td>
							{l.map(props.state.columns, (c, cIdx) => (
								<td
									key={cIdx}
									style={{
										borderLeft: border,
										borderTop: border,
										borderRight: border,
										borderBottom: border,
									}}
								>
									<div className={rawDataTableClasses.cell}>{r[c]}</div>
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	)
}
export { RawDataTable }
