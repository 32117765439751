import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { Debug } from 'ui/components/dev/Debug'
import { FormVertical } from 'ui/components/form/FormVertical'
import { FormVerticalLayout } from 'ui/components/form/FormVerticalLayout'
import { FormVerticalSelect } from 'ui/components/form/FormVerticalSelect'
import { FormVerticalTextInput } from 'ui/components/form/FormVerticalTextInput'
import { FormUpdate, IFormData } from 'ui/components/form/IFormData'
import { IAddress } from 'ui/components/shared/address/IAddress'
import { addressFormMetadata, IAddressForm } from 'ui/forms/formAddress'
import { l } from 'ui/lib/lodashImports'

const AddressFormFields: FC = (props: {
	formData: IFormData<IAddressForm>
	exclude: (keyof IAddress)[]
	readOnly?: boolean
}) => {
	return (
		<FormVerticalLayout>
			{!l.includes(props.exclude, 'addressType') && (
				<FormVerticalSelect
					formData={props.formData}
					field='addressType'
					readOnly={props.readOnly}
				/>
			)}
			{!l.includes(props.exclude, 'contactName') && (
				<FormVerticalTextInput
					formData={props.formData}
					field='contactName'
					readOnly={props.readOnly}
				/>
			)}
			<FormVerticalTextInput
				formData={props.formData}
				field='street1'
				readOnly={props.readOnly}
			/>
			{!l.includes(props.exclude, 'street2') && (
				<FormVerticalTextInput
					formData={props.formData}
					field='street2'
					readOnly={props.readOnly}
				/>
			)}
			<FormVerticalTextInput
				formData={props.formData}
				field='city'
				readOnly={props.readOnly}
			/>
			<FormVerticalTextInput
				formData={props.formData}
				field='state'
				readOnly={props.readOnly}
			/>
			<FormVerticalTextInput
				formData={props.formData}
				field='zip'
				readOnly={props.readOnly}
			/>
			{!l.includes(props.exclude, 'country') && (
				<FormVerticalTextInput
					formData={props.formData}
					field='country'
					readOnly={props.readOnly}
				/>
			)}
			{!l.includes(props.exclude, 'phone') && (
				<FormVerticalTextInput
					formData={props.formData}
					field='phone'
					readOnly={props.readOnly}
				/>
			)}
			{!l.includes(props.exclude, 'email') && (
				<FormVerticalTextInput
					formData={props.formData}
					field='email'
					readOnly={props.readOnly}
				/>
			)}
		</FormVerticalLayout>
	)
}

export const AddressForm: FC = (props: {
	mode: 'add' | 'edit' | 'readonly'
	form: IAddressForm
	onUpdateForm: FormUpdate<IAddressForm>
	embedded?: boolean
	exclude?: (keyof IAddress)[]
}) => {
	const formData: IFormData<IAddressForm> = {
		form: props.form,
		metadata: addressFormMetadata,
		onUpdateForm: props.onUpdateForm,
		tPrefix: 'shared.addressForm',
	}

	return (
		<div>
			<Debug hide={true} label='form data' data={props.form} />
			{!props.embedded && (
				<FormVertical>
					<AddressFormFields
						formData={formData}
						exclude={props.exclude}
						readOnly={props.mode === 'readonly'}
					/>
				</FormVertical>
			)}
			{props.embedded && (
				<AddressFormFields
					formData={formData}
					exclude={props.exclude || []}
					readOnly={props.mode === 'readonly'}
				/>
			)}
		</div>
	)
}
