import { FC } from 'app/FunctionalComponent'
import React from 'react'
import styles from './Tabs.module.scss'

export const TabContent: FC = (props: {
	isVisible: boolean
	renderer: () => React.ReactElement
}) => {
	if (!props.isVisible) {
		return null
	}

	return <div className={styles.tabContent}>{props.renderer()}</div>
}
