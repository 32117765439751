import { parseDateFromUSLocale } from 'ui/pages/shipments'
import { escapeField } from 'ui/lib/elasticSearch/elasticSearchBuilder'

const elasticSearchDateFormat = 'yyyy-LL-dd'

export function formatElasticSearchDateSearh(date: string): string {
	return parseDateFromUSLocale(date).toFormat(elasticSearchDateFormat)
}

export function formatElasticSearchDateRangeSearch(
	key: string,
	date1: string,
	date2: string,
): string {
	return `(${escapeField(key)}:[${formatElasticSearchDateSearh(
		date1,
	)} TO ${formatElasticSearchDateSearh(date2)}])`
}

export function formatElasticSearchSingleDaySearch(
	key: string,
	date: string,
): string {
	return `(${escapeField(key)}:${formatElasticSearchDateSearh(date)})`
}
