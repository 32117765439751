import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { Checkbox } from '../common/checkbox/Checkbox'

export const StringCheckbox: FC = (props: {
	value: string
	onChange: (newVal: string) => void
	testId?: string
	children: React.ReactNode
	readOnly?: boolean
}) => {
	const onChange = (newVal: boolean): void => {
		props.onChange(newVal ? 'true' : '')
	}
	return (
		<Checkbox
			value={props.value === 'true'}
			onChange={onChange}
			testId={props.testId}
			readOnly={props.readOnly}
		>
			{props.children}
		</Checkbox>
	)
}
