import { FC } from 'app/FunctionalComponent'
import * as Diff from 'diff'
import React from 'react'
import classes from './DiffedText.module.scss'

interface IPart {
	value: string
	added?: boolean
	removed?: boolean
}

const DiffedText: FC = (props: { str1: string; str2: string }) => {
	const els: React.ReactNode[] = []
	if (!props.str1) {
		els.push(
			<ins key='added' className={classes.newText}>
				{props.str2}
			</ins>,
		)
	} else if (!props.str2) {
		els.push(
			<del key='removed' className={classes.replaced}>
				{props.str1}
			</del>,
		)
	} else {
		const parts: Array<IPart> = Diff.diffChars(props.str1, props.str2, {
			ignoreCase: true,
		})
		parts.forEach((val, index) => {
			els.push(
				val.added ? (
					<ins key={index} className={classes.newText}>
						{val.value}
					</ins>
				) : val.removed ? (
					<del key={index} className={classes.replaced}>
						{val.value}
					</del>
				) : (
					<span key={index}>{val.value}</span>
				),
			)
		})
	}
	return <span>{els}</span>
}

export { DiffedText }
