import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { ValidatedSubmitButton } from 'ui/components/common/validated-submit-button'
import { FormVertical } from 'ui/components/form/FormVertical'
import { FormVerticalLayout } from 'ui/components/form/FormVerticalLayout'
import { FormVerticalReadOnly } from 'ui/components/form/FormVerticalReadOnly'
import { FormVerticalTextInput } from 'ui/components/form/FormVerticalTextInput'
import { IFormData } from 'ui/components/form/IFormData'
import { t } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight/AlignRight'
import {
	addressBookFormMetadata,
	IAddressBookForm,
} from 'ui/forms/formAddressBook'
import { formValidator } from 'ui/lib/validation/'
import { sosAddressBook } from 'ui/state/'

export const UpdateAddressBookForm: FC = (props: {
	mode: 'add' | 'edit'
	form: IAddressBookForm
}) => {
	const formData: IFormData<IAddressBookForm> = {
		form: props.form,
		metadata: addressBookFormMetadata,
		onUpdateForm: sosAddressBook.updateBookForm,
		tPrefix: 'page.manageAddressBooks.bookForm',
	}

	const validationResult = formValidator.validateForm(
		formData.form,
		formData.metadata,
	)

	return (
		<div>
			<FormVertical>
				<FormVerticalLayout>
					{props.mode === 'edit' && (
						<FormVerticalReadOnly formData={formData} field='id' />
					)}

					<FormVerticalTextInput formData={formData} field='name' />
				</FormVerticalLayout>
				<AlignRight>
					<ValidatedSubmitButton
						testId='submit-upsert-book'
						onClick={async () => {
							await sosAddressBook.updateBook('upsert')
						}}
						isValid={validationResult.isValid}
					>
						{t(`page.manageAddressBooks.${props.mode}Book`)}
					</ValidatedSubmitButton>
				</AlignRight>
			</FormVertical>
		</div>
	)
}
