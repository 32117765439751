import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { useDropzone } from 'react-dropzone'
import { isSupported } from 'ui/lib/files/isSupported'
import { l } from 'ui/lib/lodashImports'
import { log } from 'ui/lib/log/log'
import { r } from 'ui/lib/ramdaImports'
import styles from './FileDropZone.module.scss'

export interface FileDropZoneData {
	fileContent: string
	fileName: string
}

export const FileDropZone: FC = (props: {
	onLoaded: (fileContent: string, filename?: string) => void
	preDropText: string | React.ReactNode
	droppingText: string | React.ReactNode
	testId: string
	onError?: (errorMessage: string, filename?: string) => void
	onDrop?: () => void
	supportedFileTypes?: string[]
	fileEncoding: 'text' | 'dataUrl'
}) => {
	const onDrop = React.useCallback(
		(acceptedFiles) => {
			log('file-drop', 'accepted files', acceptedFiles)

			if (props.onDrop) {
				props.onDrop()
			}

			l.forEach(acceptedFiles, (file) => {
				if (r.not(isSupported(file.name, props.supportedFileTypes))) {
					if (props.onError) {
						props.onError(
							`The file type for ${file.name}'s is not supported.` +
								` Only ${props.supportedFileTypes.join(
									', ',
								)} files are supported.`,
						)
					}
					return true // continue forEach
				}

				const reader = new FileReader()
				const filename = file.name
				reader.onabort = () => {
					const message = 'file reading was aborted'
					log('file-drop', message)
					if (props.onError) {
						props.onError(message)
					}
				}
				reader.onerror = () => {
					const message = 'file reading has failed'
					log('file-drop', message)
					if (props.onError) {
						props.onError(message, filename)
					}
				}
				reader.onload = () => {
					const fileContent: string = reader.result as string
					if (props.onLoaded) {
						props.onLoaded(fileContent, filename)
					}
				}

				if (props.fileEncoding === 'dataUrl') {
					reader.readAsDataURL(file)
				}
				if (props.fileEncoding === 'text') {
					reader.readAsText(file)
				}
			})
		},
		[props],
	)
	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

	return (
		<div className={styles.fileDropZoneContainer}>
			<div {...getRootProps({ className: styles.fileDropZone })}>
				<input
					{...getInputProps({ className: styles.fileDropZoneInput })}
					data-testid={props.testId}
				/>
				{isDragActive ? (
					<p>{props.droppingText}</p>
				) : (
					<p> {props.preDropText}</p>
				)}
			</div>
		</div>
	)
}
