// import * as i18nData from 'ui/data/i18n/i18n.json'
import axios from 'axios'
import { Settings } from 'luxon'
import * as numeral from 'numeral'
import {
	getLocationHref,
	getWindow,
} from 'ui/components/common/router/windowUtils'
import { fireAndForget } from 'ui/lib/async/fireAndForget'
import { l } from 'ui/lib/lodashImports'
import { log } from 'ui/lib/log'
import { sos2 } from 'ui/lib/state/sos2'
import { inJestUnitTest } from 'ui/lib/testing/inJestUnitTest'
import { sosTranslate } from 'ui/state'

export type TLanguageCode =
	| 'en'
	// | 'en-GB'
	// | 'es'
	// | 'es-MX'
	// | 'fr'
	| 'palindrome'
	| 'rtl'
	| 'check'
	| 'keys'
	| 'test'
	| 'test-ids'
const translatableLanguages: TLanguageCode[] = [
	'en',
	// 'es',
	// 'es-MX',
	// 'fr',
	//'pirate',
]
export const testingLanguageCodes: TLanguageCode[] = [
	'palindrome',
	'rtl',
	'check',
	'keys',
	'test',
	'test-ids',
]
export { translatableLanguages }

export type TInternationalText = { [key in TLanguageCode]?: string }

export interface IStateI18n {
	locale: TLanguageCode
	isTranslateMode: boolean
	i18nData: any
}

export const getSos = sos2.createLazySos2<IStateI18n>('sosI18n', 3, () => ({
	locale: { default: 'en', localStorage: true },
	isTranslateMode: { default: false, localStorage: true },
	i18nData: { default: null, localStorage: true },
}))

const downloadTranslation = async (locale: string): Promise<void> => {
	// Post form to translate
	if (!inJestUnitTest()) {
		log('i18n', 'status', 'downloading translation')
		if (getLocationHref().indexOf('http://localhost') !== -1) {
			const host = 'http://localhost:4218'
			const r = await axios({
				url: `${host}/json?locale=${locale}`,
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
			})

			getSos().change((ds) => {
				ds.i18nData = r.data
			})
		} else {
			// Download our English translation file always, for now

			const path = getWindow().location.pathname.replace('index.html', '')
			const i18nUrl = `${path}i18n/en.json`
			const r = await axios({
				url: i18nUrl,
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
			})
			getSos().change((ds) => {
				ds.i18nData = r.data
			})
		}
	}
}

fireAndForget(async () => {
	if (!inJestUnitTest()) {
		await downloadTranslation('en')
	}
}, 'downloading en translations')

export async function redownloadEnglish(): Promise<void> {
	await downloadTranslation('en')
}

function initDependentLibraries(): void {
	// TODO: add additional localization
	numeral.register('locale', 'fr', {
		delimiters: {
			thousands: ' ',
			decimal: ',',
		},
		abbreviations: {
			thousand: 'k',
			million: 'm',
			billion: 'b',
			trillion: 't',
		},
		ordinal: function (number) {
			return number === 1 ? 'er' : 'ème'
		},
		currency: {
			symbol: '$',
		},
	})
}
initDependentLibraries()

function updateDependentLibraries(locale: string): void {
	const lowercaseLocale = locale.toLowerCase()
	if ((numeral as any).locales[lowercaseLocale]) {
		numeral.locale(lowercaseLocale)
	} else {
		numeral.locale('en')
	}

	if (l.some(testingLanguageCodes, (c) => c === locale)) {
		Settings.defaultLocale = 'en'
	} else {
		Settings.defaultLocale = locale
	}
}
updateDependentLibraries(getSos().getState().locale)

export function getLocale(): TLanguageCode {
	return getSos().getState().locale
}

export function setLanguageCode(locale: TLanguageCode): void {
	getSos().change((ds) => {
		ds.locale = locale
	})
	updateDependentLibraries(locale)
}

export function isRightToLeft(): boolean {
	const locale = getLocale() // TODO: make this a faster call?
	if (locale === 'rtl') {
		return true
	}
	return false
}

export function isTranslateMode(): boolean {
	return getSos().getState().isTranslateMode
}
export function setIsTranslateMode(newVal: boolean): void {
	getSos().change((ds) => {
		ds.isTranslateMode = newVal
	})
}

export function getTranslationForKey(
	i18nKey: string,
	addUntranslated = true,
): TInternationalText {
	const state = getSos().getState()

	if (!i18nKey) {
		throw new Error('invalid i18n key ' + i18nKey)
	}

	// TODO: download other language files
	let translation: any = l.get(state.i18nData, i18nKey)

	if (translation && !l.isString(translation)) {
		translation = translation['_']
	}

	if (!translation) {
		if (addUntranslated) {
			// Not translated, track this for developers
			sosTranslate.addUntranslated(i18nKey)
		}
		return {}
	} else {
		return { en: translation }
	}
}

function _translate(i18nKey: string, translation: TInternationalText): string {
	const locale = getLocale() // TODO: make this a faster call?
	const en = translation['en']
	let text = translation[locale]

	if (!text) {
		// TODO: Check for base language translations
		// if (locale === 'es-MX') {
		//   text = translation['es']
		// }
	}

	if (!text) {
		text = en // Default to English translation
		if (text) {
			// Special translations
			if (locale === 'palindrome') {
				text = text + text.split('').reverse().join('')
			} else if (locale === 'rtl') {
				text = text.split('').reverse().join('')
			} else if (locale === 'check') {
				text = `{${text}}`
			} else if (locale === 'keys') {
				text = ` {$ {i18nKey}}`
			} else if (locale === 'test') {
				text = `t - $ {i18nKey}`
			} else if (locale === 'test-ids') {
				// Normal flow
			}
		}
	}
	return text
}

export function translate(i18nKey: string): string {
	const translation = getTranslationForKey(i18nKey)
	return _translate(i18nKey, translation)
}
