import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { theme } from 'ui/theme'
import classes from './TwoColumnLeftRight.module.scss'

export const TwoColumnLeftRight: FC = (props: {
	children: React.ReactNode
	verticalAlign?: 'middle'
}) => {
	let className = classes.twoColumnLeftRight
	className = theme.addClassIf(
		props.verticalAlign === 'middle',
		classes.verticalAlignMiddle,
		className,
	)
	return <div className={className}>{props.children}</div>
}

export const Left: FC = (props: { children: React.ReactNode }) => {
	return <div className={classes.left}>{props.children}</div>
}

export const Right: FC = (props: { children: React.ReactNode }) => {
	return <div className={classes.right}>{props.children}</div>
}
