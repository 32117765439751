import { FC } from 'app/FunctionalComponent'
import { barkdown } from 'barkdown'
import 'node_modules/barkdown/Barkdown.scss'
import React from 'react'
import { t } from 'ui/components/i18n/i18n'
import { IStateComponentExamples } from 'ui/state/sosComponentExamples'
import { Section } from './Section'

export const SectionBarkdown: FC = (props: {
	stateComponentExamples: IStateComponentExamples
}) => {
	const text = `
=== barkdown test!

some
* very
important news!
  `

	return (
		<Section
			title={t('page.componentExamples.barkdown')}
			stateComponentExamples={props.stateComponentExamples}
			name='barkdown'
		>
			barkdown
			<pre>{text}</pre>
			<div>{barkdown.reactize(barkdown.tokenize(text))}</div>
			{t('page.componentExamples.barkdown.tBarkdown')}
		</Section>
	)
}
