import React from 'react'
import { t } from '../i18n/i18n'
import { FormCheckbox } from './FormCheckbox'
import { FormVerticalItem } from './FormVerticalItem'
import { IFormData } from './IFormData'

export function FormVerticalCheckbox<T>(props: {
	field: keyof T
	formData: IFormData<T>
	labelSide?: 'left' | 'right'
}): React.ReactElement {
	const { field, formData, labelSide } = props
	const meta = props.formData.metadata[props.field]
	return (
		<FormVerticalItem
			field={field}
			formData={formData}
			labelSide={labelSide}
			color={'black'}
			hideLabel={true}
		>
			<FormCheckbox
				field={field}
				form={formData.form}
				onUpdateForm={formData.onUpdateForm}
				readOnly={meta.readOnly}
			>
				{t(formData.tPrefix + '.' + (meta.label || props.field))}
			</FormCheckbox>
		</FormVerticalItem>
	)
}
