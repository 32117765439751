import { IFormMetadataCollection } from 'ui/components/form/IFormMetadata'
import { IAddress } from 'ui/components/shared/address/IAddress'
import { FormStringData } from './formUtils'

export type IAddressForm = FormStringData<IAddress>

const addressFormAddressType = [
	'commercial',
	'residential',
	'school',
	'prison',
	'military',
	'construction',
	'tradeshow',
	'government',
	'mine',
	'storage',
	'other',
	'church',
	'mixed',
	'university',
	'camp',
	'country_club',
	'farm',
]

// readOnly: true
// required: true
// options: classCodes (probably an array or dictionary)
// mustBeANumber: true
// mustBeABoolean: true
// recommended: true
const addressFormMetadata: IFormMetadataCollection<IAddressForm> = {
	company: { required: false },
	addressName: {},
	contactName: {},
	name: {},
	street1: {
		required: true,
	},
	street2: {},
	street3: {},
	city: {
		required: true,
	},
	state: {
		required: true,
	},
	zip: {
		required: true,
	},
	country: {},
	addressType: {
		options: addressFormAddressType,
	},
	phone: {},
	email: {},
}

export { addressFormMetadata }
