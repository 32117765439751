import { FC } from 'app/FunctionalComponent'
import React from 'react'
import classes from './AngryRedBox.module.scss'

export const AngryRedBox: FC = (props: {
	children: React.ReactNode
	label?: React.ReactNode
	dataTestId?: string
}) => {
	const { children, label } = props

	return (
		<div className='bootstrap-wrapper'>
			<div
				className={'alert alert-danger'}
				data-testid={props.dataTestId}
				style={{ marginBottom: '0px', padding: '0.35rem' }}
			>
				{label && <div className={classes.label}>{label}</div>}
				<div className={classes.inner}>{children}</div>
			</div>
		</div>
	)
}
