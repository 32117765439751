import { FC } from 'app/FunctionalComponent'
import React from 'react'
import {
	IRoute,
	LoginModeType,
	SimpleRouter,
} from 'ui/components/common/router'
import { TestAddress } from '.'
import { Layout } from '../layout'
import { ComponentExamples } from './ComponentExamples'
import { TestPerformance } from './TestPerformance'
import { TestTms2Integration } from './TestTms2Integration'

export const testsRoutes: IRoute[] = [
	{
		isDefault: true,
		urls: ['/tests-v3/404'],
		renderer: () => <div>This route not found</div>,
	},
	{
		urls: ['/tests-v3/test-tms2-integration'],
		renderer: () => <TestTms2Integration />,
	},
	{
		urls: ['/tests-v3/test-performance'],
		renderer: () => <TestPerformance />,
	},
	{
		urls: ['/tests-v3/test-address'],
		renderer: () => <TestAddress />,
	},
	{
		urls: ['/tests-v3/component-examples'],
		renderer: () => <ComponentExamples />,
	},
]

export const RouterTests: FC = (props: {
	currentLocation: string
	loginMode: LoginModeType
}) => {
	return (
		<Layout>
			<SimpleRouter
				routes={testsRoutes}
				currentLocation={props.currentLocation}
				loginMode={props.loginMode}
			/>
		</Layout>
	)
}
