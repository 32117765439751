import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { t } from '../i18n/i18n'
import { Spacer } from '../layout/spacer'
import classes from './SimpleKeyValueTable.module.scss'
import { Cell, HeaderCell } from './Table'

export const KeyValueSpacer: FC = () => {
	return (
		<tr>
			<td colSpan={2}>&nbsp;</td>
		</tr>
	)
}

export const KeyValueSeparator: FC = () => {
	return (
		<tr>
			<td colSpan={2}>
				<hr className={classes.skvSeparator} />
			</td>
		</tr>
	)
}

export const KeyValueHeader: FC = (props: { children: React.ReactNode }) => {
	return (
		<tr>
			<td colSpan={2}>
				<div className={classes.skvHeader}>{props.children}</div>
			</td>
		</tr>
	)
}

export interface IKeyValueOptions {
	labelMinWidth?: string
	tPrefix: string
}

export const KeyValue = <T extends object>(props: {
	options: IKeyValueOptions
	field: keyof T
	data: T
	renderer?: (dataItem: any, data: T) => React.ReactNode
	label?: React.ReactNode
	testId?: string
}): React.ReactElement => {
	const { options, field, data, renderer, label } = props
	let val: any = null
	if (data) {
		val = data[field]
	}
	return (
		<tr>
			<HeaderCell style={{ minWidth: options.labelMinWidth }}>
				{label || t('' + field, options.tPrefix)}
			</HeaderCell>
			<Cell testId={props.testId}>{renderer ? renderer(val, data) : val}</Cell>
		</tr>
	)
}

export const ManualKeyValue: FC = (props: {
	options: IKeyValueOptions
	label: React.ReactNode
	val: any
	renderer?: (val: any) => React.ReactNode
}) => {
	const { options, val, label, renderer } = props

	return (
		<tr>
			<HeaderCell
				style={{ minWidth: options ? options.labelMinWidth : undefined }}
			>
				{label}
			</HeaderCell>
			<Cell>{renderer ? renderer(val) : val}</Cell>
		</tr>
	)
}

export const SimpleKeyValueTable: FC = (props: {
	borderless?: boolean
	children: React.ReactNode
	testId?: string
}) => {
	let className = classes.keyValueTable
	if (props.borderless) {
		className = classes.keyValueTableBorderless
	}

	className += ' ' + classes.tableHighlight

	return (
		<div className={className} data-testid={props.testId}>
			<table>
				<tbody>{props.children}</tbody>
			</table>
		</div>
	)
}

export const divForEveryLineRenderer = (val: string[]): React.ReactElement => {
	return (
		<div>
			{val.map((line, lineIdx) => (
				<div key={lineIdx}>{line || <Spacer />}</div>
			))}
		</div>
	)
}
