import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { t } from 'ui/components/i18n/i18n'
import { ProviderPortalLayout } from '.'

export const ProviderPortalSplashPage: FC = (props: {}) => {
	return (
		<ProviderPortalLayout>
			{t('welcome', 'page.providerPortal.splash')}
		</ProviderPortalLayout>
	)
}
