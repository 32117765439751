import { IFormMetadataCollection } from 'ui/components/form/IFormMetadata'
import { IAccountingCodeCatalogEntry } from 'ui/lib/books/IAccountingCodeCatalogEntry'
import { FormStringData } from './formUtils'

export type IAccountingCodeCatalogEntryForm = FormStringData<
	IAccountingCodeCatalogEntry
>

const accountingCodeCatalogEntryFormMetadata: IFormMetadataCollection<IAccountingCodeCatalogEntryForm> = {
	id: {
		readOnly: true,
	},
	accountingCode: {
		required: true,
	},
	accountingCodeDescription: {},
}

export { accountingCodeCatalogEntryFormMetadata }
