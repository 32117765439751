import { FC } from 'app/FunctionalComponent'
import React, { useEffect } from 'react'
import { Checkbox } from 'ui/components/common/checkbox/Checkbox'
import {
	documentExists,
	getDocument,
} from 'ui/components/common/router/windowUtils'
import { t } from 'ui/components/i18n/i18n'
import { sosI18n } from 'ui/components/i18n/state'
import { l } from 'ui/lib/lodashImports'
import { sos2 } from 'ui/lib/state/sos2'
import { sosTranslate } from 'ui/state/'
import { theme } from 'ui/theme'

export const TranslateModePicker: FC = (props: {}) => {
	const stateI18n = sos2.useSubscription(sosI18n.getSos())
	const stateTranslate = sos2.useSubscription(sosTranslate.getSos())

	useEffect(() => {
		const listener = (ev): void => {
			if (ev.code === 'F9') {
				if (theme.isInDev()) {
					sosI18n.setIsTranslateMode(!stateI18n.isTranslateMode)
				}
			}
		}
		if (documentExists) {
			getDocument().addEventListener('keyup', listener)
		}

		return () => {
			if (documentExists) {
				getDocument().removeEventListener('keyup', listener)
			}
		}
	})

	return (
		<div>
			<Checkbox
				value={stateI18n.isTranslateMode}
				onChange={(newVal) => {
					sosI18n.setIsTranslateMode(newVal)
				}}
			>
				Translate Mode
			</Checkbox>
			{stateTranslate.untranslatedStrings && (
				<div>
					<div>Untranslated strings:</div>
					{l.map(stateTranslate.untranslatedStrings, (c) => (
						<div key={c}>{t(c)}</div>
					))}
				</div>
			)}
		</div>
	)
}
