import { FC } from 'app/FunctionalComponent'
import { DateTime } from 'luxon'
import React from 'react'
import { IconButton, solidIcons } from 'ui/components/common/icon'
import { t } from 'ui/components/i18n/i18n'
import { FlexItem, FlexRow } from 'ui/components/layout/flexRow'
import { l } from 'ui/lib/lodashImports'
import { theme } from 'ui/theme'
import { addClass } from 'ui/theme/theme'
import * as classes from '../DockScheduler.module.scss'

const tPrefix = 'page.dockScheduler.dayScroller'

interface IDayScrollerDay {
	selected: boolean
	today: boolean
	dateTime: DateTime
}

export const DockSchedulerCalendarDayScroller: FC = (props: {
	startDate: string // ISODate String
	onUpdateStartDate: (val: string) => void
	selectedDate: string // ISODate String
	onUpdateSelectedDate: (val: string) => void
	readOnly?: boolean
}) => {
	const {
		startDate,
		selectedDate,
		onUpdateSelectedDate,
		onUpdateStartDate,
	} = props
	const days: IDayScrollerDay[] = []
	const todayFormatted = DateTime.local().toISODate()
	for (let i = 0; i < 7; i++) {
		const dateTime: DateTime = DateTime.fromISO(startDate).plus({
			days: i,
		})
		days.push({
			selected: dateTime.toISODate() === selectedDate,
			today: dateTime.toISODate() === todayFormatted,
			dateTime,
		})
	}

	const arrowClass = addClass(classes.dayScrollerItem, classes.verticalCenter)

	return (
		<FlexRow>
			<FlexItem>
				<div className={arrowClass}>
					<IconButton
						testId={'day-scroller-arrow-left'}
						icon={solidIcons.faArrowLeft}
						large={true}
						onClick={() =>
							onUpdateStartDate(
								DateTime.fromISO(startDate).minus({ day: 1 }).toISODate(),
							)
						}
					/>
				</div>
			</FlexItem>
			{l.map(days, (day: IDayScrollerDay, dayIdx) => {
				let className = addClass(
					classes.dayScrollerItem,
					classes.dayScrollerDay,
				)
				className = theme.addClassIf(
					day.selected,
					classes.dayScrollerItemSelected,
					className,
				)
				className = theme.addClassIf(
					props.readOnly,
					classes.disabled,
					className,
				)
				return (
					<FlexItem key={day.dateTime.toFormat('dd')}>
						<div
							onClick={() => {
								if (!props.readOnly) {
									onUpdateSelectedDate(day.dateTime.toISODate())
								}
							}}
							className={className}
							data-testid={`day-scroller-day-${dayIdx}`}
						>
							<div
								className={classes.dayScrollerTodaySection}
								data-testid={'day-scroller-today'}
							>
								{day.today && t('today', tPrefix)}
							</div>
							<div data-testid={'day-scroller-date'}>
								{day.dateTime.toFormat('M/d')}
							</div>
							<div data-testid={'day-scroller-weekday'}>
								{day.dateTime.toFormat('EEEE')}
							</div>
						</div>
					</FlexItem>
				)
			})}

			<FlexItem>
				<div className={arrowClass}>
					<IconButton
						testId={'day-scroller-arrow-right'}
						icon={solidIcons.faArrowRight}
						large={true}
						onClick={() =>
							onUpdateStartDate(
								DateTime.fromISO(startDate).plus({ day: 1 }).toISODate(),
							)
						}
					/>
				</div>
			</FlexItem>
		</FlexRow>
	)
}
