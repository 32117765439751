import * as apiTypes from 'ui/api/apiTypes'
import { ICustomerDetailsAddressForm } from 'ui/pages/customer-management/broker/customer-details-address'
import { ICreditLimitForm } from 'ui/pages/customer-management/broker/default-markup/credit-limit'

export interface INotificationsForm {
	sendShippingNotification: boolean
	shippingNotificationEmails: string
}

export const createDefaultPaymentTerms = (): apiTypes.PaymentTerms => {
	return {
		net: 0,
	}
}

export const createDefaultMarkup = (): apiTypes.MarkupLogic => {
	return {
		markupType: 'flat',
		markupFlat: 1000,
		markupPercent: 10,
		maxMarkupFlat: 0,
		minMarkupFlat: 0,
		maxMarkupPercent: 0,
		minMarkupPercent: 0,
	}
}

export const createDefaultCreditLimit = (): ICreditLimitForm => {
	return {
		creditLimit: 0,
		creditLimitCurrency: 'USD',
		totalBalance: 0,
		invoicedBalance: 0,
		inTransitBalance: 0,
		creditUtilization: 0,
		canExceedCreditLimit: false,
		emailWhenBeyondWarningLimit: false,
		emailRecipients: '',
		warningThreshold: 0,
		creditLimitEnabled: true,
	}
}

export const createDefaultAddress = (): ICustomerDetailsAddressForm => {
	return {
		customerName: '',
		customerNumber: '',
		street1: '',
		street2: '',
		city: '',
		state: '',
		zipCode: '',
		country: '',
		contactName: '',
		phone: '',
		email: '',
		fax: '',
	}
}

export const createDefaultNotification = (): INotificationsForm => {
	return {
		sendShippingNotification: false,
		shippingNotificationEmails: '',
	}
}
