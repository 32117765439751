import React from 'react'
import { testId } from 'ui/components/i18n/i18n'
import { theme } from 'ui/theme'
import { Colors } from 'ui/theme/theme'
import { IconButton, solidIcons } from '../icon'
import classes from './Chip.module.scss'
import { FlexRow } from 'ui/components/layout/flexRow'
import { FC } from 'app/FunctionalComponent'

const defaultProps = {
	color: 'gray',
}
export const Chip: FC = (props: {
	color: Colors
	onClick?: () => void
	onClear?: () => void
	children?: React.ReactNode
	testId?: string
	clearable?: boolean
}) => {
	const className = `${classes.chip} ${theme.getColorButton(
		props.color,
	)} ${theme.getTextColor(props.color)}`

	return (
		<FlexRow verticalAlign={'center'}>
			{props.clearable && (
				<IconButton
					onClick={props.onClear}
					icon={solidIcons.faTimes}
					color={'lightGray'}
					testId={props.testId}
				/>
			)}
			<button
				type={'button'}
				className={className}
				onClick={props.onClick}
				data-testid={props.testId}
				data-color={props.color}
			>
				<div>{props.children}</div>

				{props.testId && <div>{testId(props.testId)}</div>}
			</button>
		</FlexRow>
	)
}
Chip.defaultProps = defaultProps
