import { l } from 'ui/lib/lodashImports'
import { r } from 'ui/lib/ramdaImports'
import { IStateMetaItem } from 'ui/lib/state/sos2/sos2'

// NOTE: All pages are 0-indexed
export interface IElasticSearchPaging {
	pageNumber?: number
	pageCount?: number
	pageSize?: number
	total: number
	take?: number
	skip?: number
}
// NOTE: All pages are 0-indexed
export interface IStatePager {
	fetchingPageNumber?: number
	pageNumber?: number
	pageCount?: number
	pageSize?: number
	total?: number
	hasMore?: boolean
}

export const createPagerSosMeta = (
	queryStringParam = 'page',
	localStorage?: boolean,
	pageSize?: number,
): IStateMetaItem<IStatePager> => {
	return {
		default: createDefaultStatePager(pageSize),
		queryStringParam,
		queryStringEncode: (c) => {
			return '' + (c.pageNumber + 1)
		},
		queryStringDecode: (c, qs) => {
			const pager = c
			let pageNumber = parseInt(qs, 10)
			if (l.isNaN(pageNumber)) {
				pageNumber = 1
			}
			pager.pageNumber = pageNumber - 1
			return pager
		},
		localStorage,
	}
}

export function createDefaultStatePager(pageSize = 25): IStatePager {
	return {
		pageNumber: 1,
		pageSize,
		fetchingPageNumber: 0,
	}
}
export function copyStatePagerFromElasticsearch(
	pager: IStatePager,
	es: IElasticSearchPaging,
): void {
	// TODO: this api will change soon
	pager.pageNumber = Math.floor(es.skip / es.take)
	pager.pageSize = es.take
	pager.total = es.total
	pager.pageCount = Math.ceil(es.total / es.take)
	pager.fetchingPageNumber = undefined
	pager.hasMore = false
}

export function getTakeAndSkip(
	statePager: IStatePager,
	pageSize = 25,
): {
	skip: number
	take: number
} {
	let skipPages = statePager.fetchingPageNumber
	if (r.isNil(skipPages)) {
		skipPages = statePager.pageNumber
	}
	const skip = skipPages * pageSize
	return {
		skip,
		take: pageSize,
	}
}
