import { IAccessorialMetadata } from 'ui/pages/new-quote/accessorialsTypes'

export interface IAccessorial {
	accessorial: IAccessorialMetadata
	checked?: boolean
}

export interface IAccessorialState {
	fields: IAccessorial[]
}

export const createDefaultAccessorialState = (
	fields: IAccessorial[],
): IAccessorialState => {
	return { fields }
}
