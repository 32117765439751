import React, { useState } from 'react'
import { apiTypes } from 'ui/api'
import { FC } from 'ui/FunctionalComponent'
import { BrokerDefaultMarkup, PaymentTerms } from '../default-markup'
import { CreditLimit, ICreditLimitForm } from '../default-markup/credit-limit'
import { Tab, Nav } from 'react-bootstrap'
import { t } from 'ui/components/i18n/i18n'
import { l } from 'ui/lib/lodashImports'
import * as classes from '../shared/BSTab.module.scss'

export const Accounting: FC = (props: {
	paymentTermsForm: apiTypes.PaymentTerms
	creditLimitForm: ICreditLimitForm
	brokerDefaultMarkupForm: apiTypes.MarkupLogic
	tPrefix: string
}) => {
	const tPrefixAccounting = props.tPrefix + '.accounting'

	const [selectedTab, setSelectedTab] = useState<string>('paymentTerms')

	const tabs: Array<{ name: string; component: React.ReactElement }> = [
		{
			name: 'paymentTerms',
			component: <PaymentTerms paymentTermsForm={props.paymentTermsForm} />,
		},
		{
			name: 'creditLimit',
			component: <CreditLimit creditLimitForm={props.creditLimitForm} />,
		},
		{
			name: 'markupRules',
			component: (
				<BrokerDefaultMarkup
					brokerDefaultMarkupForm={props.brokerDefaultMarkupForm}
				/>
			),
		},
	]

	return (
		<div>
			<Tab.Container
				id='accounting-tab-container'
				onSelect={(selected) => {
					setSelectedTab(selected)
				}}
			>
				<Nav variant='pills' className={classes.bsTabPills}>
					{l.map(tabs, (c, idx) => (
						<Nav.Item key={idx} className={classes.bsTabPillsNavItem}>
							<Nav.Link
								className={
									c.name === selectedTab ? classes.bsTabPillActive : null
								}
								onClick={() => {
									setSelectedTab(c.name)
								}}
							>
								{t(c.name, tPrefixAccounting)}
							</Nav.Link>
						</Nav.Item>
					))}
				</Nav>

				<Tab.Content className={classes.bsTabContent}>
					{l.map(tabs, (c, idx) => (
						<Tab.Pane key={idx} active={selectedTab === c.name}>
							{c.component}
						</Tab.Pane>
					))}
				</Tab.Content>
			</Tab.Container>
		</div>
	)
}
