import React, { useState } from 'react'
import { FC } from 'ui/FunctionalComponent'
import { t } from 'ui/components/i18n/i18n'
import { Form } from 'react-bootstrap'
import { sosCustomerManagement } from '../..'
import * as classes from './Notifications.module.scss'
import { INotificationsForm } from 'ui/lib/clientConfigs'

export const Notifications: FC = (props: {
	notificationsForm: INotificationsForm
	tPrefix: string
}) => {
	const [notificationsForm, setNotificationsForm] = useState<
		INotificationsForm
	>(props.notificationsForm)

	const tPrefixNotifications = props.tPrefix + '.notifications'

	const handleChange = (e: React.FormEvent<HTMLInputElement>): void => {
		const name = e.currentTarget.name
		const value =
			e.currentTarget.type === 'checkbox'
				? e.currentTarget.checked
				: e.currentTarget.value

		sosCustomerManagement.updateNoticationsForm(
			name as keyof INotificationsForm,
			value,
		)

		setNotificationsForm({
			...notificationsForm,
			[name]: value,
		})
	}

	return (
		<div className='bootstrap-wrapper' data-testid='notifications-tab'>
			<div className={classes.notificationsWrapper}>
				<Form>
					<Form.Group controlId='sendShippingNotification'>
						<Form.Check
							label={t('sendShippingNotification', tPrefixNotifications)}
							id='checkbox1'
							name='sendShippingNotification'
							type='checkbox'
							onChange={handleChange}
							checked={notificationsForm.sendShippingNotification}
						/>
					</Form.Group>

					<div className={classes.notificationEmailsWrapper}>
						<Form.Group controlId='shippingNotificationEmails'>
							<Form.Label>
								{t('shippingNotificationEmails', tPrefixNotifications)}
							</Form.Label>
							<Form.Control
								name='shippingNotificationEmails'
								type='email'
								onChange={handleChange}
								value={notificationsForm.shippingNotificationEmails}
								data-testid='notificationsForm-shippingNotificationEmails'
								placeholder='Email, comma separated'
								disabled={!notificationsForm.sendShippingNotification}
							></Form.Control>
						</Form.Group>
					</div>
				</Form>
			</div>
		</div>
	)
}
