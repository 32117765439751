import { LatLngTuple } from 'leaflet'
import React from 'react'
import { Marker } from 'react-leaflet'
import { l } from 'ui/lib/lodashImports'
import { extractRoutePolylineAndMarkers } from 'ui/lib/valhalla-routing'
import { controlTowerMapIcons } from '../components'
import { MapMarker } from './controlTowerMapUtils'

export interface RouteElements {
	polylinePositions: LatLngTuple[]
	markers: React.ReactElement<Marker>[]
	routeInfo?: Partial<MapMarker>
	popupSide?: 'left' | 'right'
}

const middleOfMap = -94.8

export const getRouteElements = (
	marker: MapMarker,
	shape?: string,
): RouteElements => {
	const routePolylineAndMarkers = extractRoutePolylineAndMarkers(
		marker.stopsInOrder,
		shape,
	)
	const { routeMarkersMetadata, polylinePositions } = routePolylineAndMarkers
	const truckMarkerPosition = routePolylineAndMarkers.truckMarkerPosition ?? {
		lat: marker.lat,
		lng: marker.lng,
	}

	const truckMarker = (
		<Marker
			key='truck-marker'
			position={truckMarkerPosition}
			icon={controlTowerMapIcons.markerIconFactory(
				marker.markerType,
				marker.markerStatus,
				marker.markerDirection,
			)}
		/>
	)

	const markers = l.map(routeMarkersMetadata, (marker, idx) => (
		<Marker key={idx} icon={marker.icon} position={marker.position} />
	))

	return {
		markers: [truckMarker, ...markers],
		polylinePositions,
		popupSide: truckMarkerPosition.lng < middleOfMap ? 'right' : 'left',
		routeInfo: marker,
	}
}
