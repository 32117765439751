import { FC } from 'app/FunctionalComponent'
import React from 'react'

// import { Button } from 'ui/components/common/button'

// import { IShipmentResponse } from 'tms3-common/dist/models/shipment'
// import { ICostBreakdown } from 'tms3-common/dist/models/shipment'

// import * as c2 from 'tms3-common'
// import * as cc from 'tms3-common/dist/models/shipment'
// cc.shipmentUtils.getFinalDestination()

// let d: IAddress

// import { Debug } from 'ui/components/dev'

//import { ICostBreakdown, IShipmentResponse } from 'ui/lib/commonImports'

// let c: IShipmentResponse = {
//   id: '1234',
//   billTo: null,
//   payloads: [],
//   shipmentStatus: 'asdf',
// }

// let d: ICostBreakdown = {
//   discount: 123,
// }
// let c = 1,
//   d = 1

export const ConnectToCommonCode: FC = (props: {}) => {
	// const onClick = () => {}

	return <div>TODO: connect to common code</div>
}
