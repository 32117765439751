import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { BadgeError } from 'ui/components/common/badge/Badge'
import { sos2 } from 'ui/lib/state/sos2'
import { sosUser } from 'ui/state/'
import styles from './Permissions.module.scss'

// Ye only allowed React class
// see: https://reactjs.org/docs/hooks-faq.html#do-hooks-cover-all-use-cases-for-classes
class ErrorBoundary extends React.Component<{}> {
	state = {
		hasError: false,
	}

	static getDerivedStateFromError(
		error,
	): {
		hasError: boolean
	} {
		// Update state so the next render will show the fallback UI.
		return { hasError: true }
	}

	componentDidCatch(error, info): void {
		// You can also log the error to an error reporting service
		// logErrorToMyServ/ice(error, info);
	}

	render(): React.ReactNode {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return <h1>This component encountered an error.</h1>
		}

		return this.props.children
	}
}

const DevPermissionBorder: FC = (props: {
	permission: string
	hasPermission: boolean
	children: React.ReactNode
}) => {
	const { permission, hasPermission } = props
	return (
		<div
			className={
				styles.requiresPermission +
				' ' +
				(hasPermission ? styles.green : styles.red)
			}
		>
			<div>
				<div className={styles.permissionTag}>{permission}</div>
			</div>

			{props.children}
		</div>
	)
}

const RequiresPermission: FC = (props: {
	permission: string
	children: React.ReactNode
}) => {
	const state = sos2.useSubscription(sosUser.getSos())

	const hasPermission = sosUser.hasPermission(props.permission)

	if (state.devCheckPermissions) {
		if (sosUser.permissionsList.indexOf(props.permission) === -1) {
			return (
				<ErrorBoundary>
					<DevPermissionBorder
						permission={props.permission}
						hasPermission={hasPermission}
					>
						<BadgeError>
							{"Error: Permission isn't in permission list"}
						</BadgeError>
						<div>{hasPermission && props.children}</div>
					</DevPermissionBorder>
				</ErrorBoundary>
			)
		}
	}

	if (state.showPermissions) {
		return (
			<ErrorBoundary>
				<DevPermissionBorder
					permission={props.permission}
					hasPermission={hasPermission}
				>
					<div>{hasPermission && props.children}</div>
				</DevPermissionBorder>
			</ErrorBoundary>
		)
	}

	return (
		<React.Fragment>
			{hasPermission && <ErrorBoundary>{props.children}</ErrorBoundary>}
		</React.Fragment>
	)
}

export { RequiresPermission }
