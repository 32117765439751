import { apiProductCatalog, apiTypes } from 'ui/api/'
import {
	createDefaultDataTableState,
	IDataTableState,
} from 'ui/components/table/IDataTableState'
import { r } from 'ui/lib/ramdaImports'
import { delayedTask } from 'ui/pages/shipments/state'
import {
	copyStatePagerFromElasticsearch,
	createPagerSosMeta,
	getTakeAndSkip,
	IStatePager,
} from '../state/paging'
import { _idx } from 'ui/lib'
import { sosUser } from 'ui/state'
import { createSos2 } from 'ui/lib/state/sos2/sos2'
import { createDelayedTask } from 'ui/pages/shipments/state/delayedTask'

export const addressBookPageSize = 25

export interface IStateProductCatalogModal {
	isModalOpen: boolean
	isModalLoading: boolean
	modalEntries: apiTypes.ProductPageResponse[]
	modalPager: IStatePager
	searchFor: string
	updateCommodityId: string
	dataTable: IDataTableState<apiTypes.ProductPageResponse>
}

export const sos = createSos2<IStateProductCatalogModal>(
	'product-catalog-modal',
	2,
	{
		isModalOpen: { default: false, localStorage: true },
		isModalLoading: { default: false },
		modalEntries: { default: [] },
		searchFor: { default: '' },
		modalPager: createPagerSosMeta(),
		updateCommodityId: { default: '' },
		dataTable: {
			default: createDefaultDataTableState({
				sortOn: 'id',
				hiddenHeaders: ['id'],
			}),
		},
	},
)

export function showModal(show = true, id?: string): void {
	sos.change((ds) => {
		ds.isModalOpen = show
		ds.updateCommodityId = id
	})
	if (show) {
		updateSearchFor('', true)
	}
}

const searchFor = async (): Promise<void> => {
	const state = sos.getState()

	const { take, skip } = getTakeAndSkip(state.modalPager)

	let alternateApiKey: string
	if (sosUser.isUserBroker()) {
		const selectedClientConfig = sosUser.getSos().getState()
			.selectedClientConfig
		if (selectedClientConfig) {
			alternateApiKey = _idx(() => selectedClientConfig.tmsCredentials.apiKey)
		}
	}

	const result = await apiProductCatalog.searchProductCatalog(
		(rs) => {},
		{
			query: state.searchFor,
			take,
			skip: skip,
		},
		alternateApiKey,
	)

	const entries: apiTypes.ProductPageResponse[] = r.map(
		(c) => c,
		result.data.entities,
	)

	sos.change((ds) => {
		ds.modalEntries = entries
		copyStatePagerFromElasticsearch(ds.modalPager, result.data)
	})
}

export const delayedSearchFor = createDelayedTask(
	'sosProductBook:delayedSearchFor',
	async () => {
		await searchFor()
		return true
	},
	{},
)

export const setCurrentPage = (newPage: number): void => {
	sos.change((ds) => {
		ds.modalPager.fetchingPageNumber = newPage
	})
	delayedTask.callNow(delayedSearchFor)
}

export const updateSearchFor = (newVal: string, callNow?: boolean): void => {
	sos.change((ds) => {
		ds.searchFor = newVal
		ds.modalPager.fetchingPageNumber = 0
	})
	if (callNow) {
		delayedTask.callNow(delayedSearchFor)
	} else {
		delayedTask.callLater(delayedSearchFor)
	}
}
