import React, { useState, ReactElement, useEffect } from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import { t } from 'ui/components/i18n/i18n'
import { Address } from 'ui/api/apiTypes'
import { Button } from 'ui/components/common/button'
import { FlexRow } from 'ui/components/layout/flexRow'
import { IFormData } from 'ui/components/form/IFormData'
import {
	FormStacked,
	FormStackedTextInput,
	FormStackedSelect,
} from 'ui/components/form'
import {
	getRegionsForCountry,
	getCountryNameList,
} from 'ui/data/countryAndRegionUtils'

export const EditableAddressCard = (props: {
	tPrefix: string
	header: string
	addressData: Address
	fieldToUpdate: string
	onSave?: (fieldToUpdate, newVal) => void //You can save data any way you'd like, but make sure you expect this component to know where and what you're updating
}): ReactElement => {
	const [isEditing, setIsEditing] = useState(false)
	const [unsavedAddress, setUnsavedAddress] = useState(props.addressData)
	const [regions, setRegions] = useState(
		getRegionsForCountry(props.addressData.country, true, true),
	)

	const formData: IFormData<Address> = {
		form: unsavedAddress,
		metadata: {
			company: { required: false, readOnly: !isEditing },
			street1: { required: false, readOnly: !isEditing },
			street2: { required: false, readOnly: !isEditing },
			city: { required: false, readOnly: !isEditing },
			state: { required: true, readOnly: !isEditing },
			zip: { required: false, readOnly: !isEditing },
			country: { required: false, readOnly: !isEditing },
			name: { required: false, readOnly: !isEditing },
			phone: { required: false, readOnly: !isEditing },
			email: { required: false, readOnly: !isEditing },
		},
		onUpdateForm: (field, newVal) => {
			const updateAddress = { ...unsavedAddress, [field]: newVal }
			setUnsavedAddress(updateAddress)
		},
		tPrefix: props.tPrefix,
	}

	function onClickSave(): void {
		props.onSave(props.fieldToUpdate, unsavedAddress)
		setIsEditing(!isEditing)
	}

	function onClickCancel(): void {
		setUnsavedAddress(props.addressData)
		setIsEditing(!isEditing)
	}

	useEffect(() => {
		setUnsavedAddress(props.addressData)
	}, [props.addressData])

	useEffect(() => {
		const newRegions = getRegionsForCountry(unsavedAddress.country, false, true)
		setRegions(newRegions)
	}, [unsavedAddress.country])

	return (
		<Card>
			<Card.Header>{t(props.header, props.tPrefix)}</Card.Header>
			<Card.Body>
				<Row>
					<Col>
						<FormStacked>
							<FormStackedTextInput field='company' formData={formData} />
							<FormStackedTextInput field='street1' formData={formData} />
							<FormStackedTextInput field='street2' formData={formData} />
							<FormStackedTextInput field='city' formData={formData} />
							<FormStackedSelect
								formData={formData}
								field='state'
								options={regions}
								readOnly={!isEditing}
								hideOptional={true}
							/>
							<FormStackedTextInput field='zip' formData={formData} />
							<FormStackedSelect
								formData={formData}
								field='country'
								autocomplete={true}
								readOnly={!isEditing}
								options={getCountryNameList()}
								hideOptional={true}
							/>
						</FormStacked>
					</Col>
					<Col>
						<FormStacked>
							<FormStackedTextInput field='name' formData={formData} />
							<FormStackedTextInput field='phone' formData={formData} />
							<FormStackedTextInput field='email' formData={formData} />
						</FormStacked>
					</Col>
				</Row>
			</Card.Body>
			<Card.Footer>
				{isEditing ? (
					<div>
						<FlexRow>
							<Button
								color='green'
								onClick={onClickSave}
								testId={'save-companyDetails'}
							>
								{t('save', props.tPrefix)}
							</Button>
							<Button color='red' onClick={onClickCancel}>
								{t('cancel', props.tPrefix)}
							</Button>
						</FlexRow>
					</div>
				) : (
					<div>
						<Button
							color='blue'
							onClick={(): void => {
								setIsEditing(!isEditing)
							}}
							testId={'edit-companyDetails'}
						>
							{t('edit', props.tPrefix)}
						</Button>
					</div>
				)}
			</Card.Footer>
		</Card>
	)
}
