import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { DiffedText } from 'ui/components/common/diffed-text/DiffedText'
import { t } from 'ui/components/i18n/i18n'
import { IStateComponentExamples } from 'ui/state/sosComponentExamples'
import { Section } from './Section'

export const SectionDiffedText: FC = (props: {
	stateComponentExamples: IStateComponentExamples
}) => {
	return (
		<Section
			title={t('page.componentExamples.diffed-text')}
			stateComponentExamples={props.stateComponentExamples}
			name='diffed-text'
		>
			<div>
				Added text:
				<DiffedText
					str1='The quick brown fox'
					str2='The quick brown fox jumped over the lazy dog'
				/>
			</div>
			<div>
				Removed text:
				<DiffedText
					str1='She sells seashells down by the seashore'
					str2='She sells seashells'
				/>
			</div>
			<div>
				Both:
				<DiffedText
					str1='The quick brown fox jumped over the lazy seashore'
					str2='The quick brown seashell jumped over the lazy dog'
				/>
			</div>
		</Section>
	)
}
