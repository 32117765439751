import React from 'react'
import { apiTypes } from 'ui/api'
import { IconButton, solidIcons } from 'ui/components/common/icon'
import { sosRouter } from 'ui/components/common/router'
import { tProviderName } from 'ui/components/i18n/commonTranslations'
import { IDataTableHeader } from 'ui/components/table/IDataTableHeader'
import tableClasses from 'ui/components/table/TableStyles.module.scss'

export const providerListHeaders: IDataTableHeader<
	apiTypes.ProviderResponse
>[] = [
	{
		field: 'id',
		hideLabel: true,
		renderer: (cell: string, row: apiTypes.ProviderResponse) => {
			return (
				<IconButton
					onClick={() => {
						sosRouter.navigate('/providers/' + row.id)
					}}
					iconClassName={tableClasses.searchIcon}
					icon={solidIcons.faSearchPlus}
				/>
			)
		},
	},
	{
		field: 'providerName',
		headerAlign: 'left',
		elasticSearchKey: 'providerName',
		renderer: (cell: apiTypes.ProviderName) => tProviderName(cell),
	},
	{
		field: 'doingBusinessAs',
		label: 'Doing Business As',
		headerAlign: 'left',
		elasticSearchKey: 'doingBusinessAs',
	},
	{
		field: 'usdotNumber',
		columnAlign: 'right',
		headerAlign: 'right',
		elasticSearchKey: 'usdotNumber',
	},
	{
		field: 'mcNumber',
		columnAlign: 'right',
		headerAlign: 'right',
		elasticSearchKey: 'mcNumber',
	},
	{
		field: 'scac',
		columnAlign: 'center',
		headerAlign: 'center',
		elasticSearchKey: 'scac',
		renderer: (cell: string) => cell?.toUpperCase(),
	},
]
