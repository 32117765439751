import { FC } from 'app/FunctionalComponent'
import { TypeaheadOption } from 'common/components/typeahead'
import { DateTime } from 'luxon'
import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import { Marker, Polyline } from 'react-leaflet'
import { apiRouting, apiTypes } from 'ui/api'
import { RoutingResponse } from 'ui/api/apiRouting'
import { IRequestState } from 'ui/api/requestState'
import { IconButton, solidIcons } from 'ui/components/common/icon'
import { Loader } from 'ui/components/common/loader'
import { watch } from 'ui/components/hooks'
import { t, tString } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight'
import { Spacer } from 'ui/components/layout/spacer'
import { RequiresFeatureToggle } from 'ui/components/permissions/RequiresFeatureToggle'
import { LeafletMap } from 'ui/components/shared/leaflet-map/LeafletMap'
import { LocationSelector } from 'ui/components/shared/location-selector'
import { ProviderSelector } from 'ui/components/shared/provider-selector'
import { fireAndForget } from 'ui/lib/async'
import { l } from 'ui/lib/lodashImports'
import { sos2 } from 'ui/lib/state/sos2'
import { Layout } from 'ui/pages/layout'
import { sosUser } from 'ui/state'
import { theme } from 'ui/theme'
import { isInTMS2 } from 'ui/theme/theme'
import { Flows, Modes } from '../common/controlTowerUtils'
import { markerIconFactory } from './components/controlTowerMapIcons'
import { RouteStopDetails } from './components/RouteStopDetails'
import * as classes from './ControlTowerMap.module.scss'
import './ReactDatePicker.scss'
import { controlTowerMapUtils, sosControlTowerMap } from './state'
import { getRouteElements, RouteElements } from './state/getRouteElements'

export const controlTowerMapTPrefix = 'page.controlTowerMap'

export const ControlTowerMap: FC = (props: {}) => {
	const state = sos2.useSubscription(sosControlTowerMap.getSos())
	const userState = sos2.useSubscription(sosUser.getSos())
	const [providerFilter, setProviderFilter] = useState<string>(null)
	const [locationFilter, setLocationFilter] = useState({} as TypeaheadOption)
	const [modeFilter, setModeFilter] = useState('' as Modes)
	const [flowFilter, setFlowFilter] = useState('' as Flows)
	const [startDate, setStartDate] = useState(
		DateTime.local().startOf('day').toJSDate(),
	)
	const [endDate, setEndDate] = useState(
		DateTime.local().endOf('day').toJSDate(),
	)
	const [isLoading, setIsLoading] = useState(false)

	const [isPopoverVisible, setIsPopoverVisible] = useState(false)
	const [routingElements, setRoutingElements] = useState({} as RouteElements)

	watch(() => {
		fireAndForget(async () => {
			setIsLoading(true)
			await controlTowerMapUtils.fetchShipments(
				startDate,
				endDate,
				locationFilter?.value,
				providerFilter,
				modeFilter,
				flowFilter,
			)
			setIsLoading(false)
		}, 'fetching shipments for control tower map')
	}, [
		providerFilter,
		locationFilter,
		startDate,
		endDate,
		flowFilter,
		modeFilter,
		userState.requestUserInfo?.data?.profile?.settings
			?.canViewDataForTheseLocations,
	])

	return (
		<Layout>
			<RequiresFeatureToggle featureToggle='control-tower'>
				<Spacer />
				<div className={classes.filterContainer}>
					{!isInTMS2() && (
						<div>
							<LocationSelector
								onChange={(location) => setLocationFilter(location)}
								includeAll={true}
								onlyShowLeafLocations={true}
							/>
						</div>
					)}
					<div>
						<ProviderSelector
							onChange={(provider: apiTypes.ProviderResponse) =>
								setProviderFilter(provider.id)
							}
							tPrefix={controlTowerMapTPrefix}
						/>
					</div>
					<div
						className={theme.addClass(
							classes.datePicker,
							'date-picker-wrapper bootstrap-wrapper',
						)}
					>
						<DatePicker
							maxDate={endDate}
							selected={startDate}
							onChange={(date) => {
								setStartDate(date)
							}}
							className='form-control form-control-sm'
							placeholderText={tString('startDate', controlTowerMapTPrefix)}
						/>
					</div>
					<div className={classes.dateRangeSeparator}>{t('to', 'common')}</div>
					<div
						className={theme.addClass(
							classes.datePicker,
							'date-picker-wrapper bootstrap-wrapper',
						)}
					>
						<DatePicker
							minDate={startDate}
							selected={endDate}
							onChange={(date) => {
								setEndDate(new Date(date.setHours(23, 59, 59)))
							}}
							className='form-control form-control-sm'
							placeholderText={tString('endDate', controlTowerMapTPrefix)}
						/>
					</div>
					<div className='bootstrap-wrapper'>
						<select
							data-testid='flow-select'
							value={flowFilter}
							className='form-control form-control-sm'
							onChange={(event) => setFlowFilter(event.target.value as Flows)}
						>
							<option disabled value=''>
								{tString('filterByFlow', controlTowerMapTPrefix)}
							</option>
							<option value='all'>
								{tString('all', controlTowerMapTPrefix + '.flows')}
							</option>
							<option value='inbound'>
								{tString('inbound', controlTowerMapTPrefix + '.flows')}
							</option>
							<option value='outbound'>
								{tString('outbound', controlTowerMapTPrefix + '.flows')}
							</option>
						</select>
					</div>
					<div className='bootstrap-wrapper'>
						<select
							data-testid='mode-select'
							value={modeFilter}
							className='form-control form-control-sm'
							onChange={(event) => setModeFilter(event.target.value as Modes)}
						>
							<option disabled value=''>
								{tString('filterByMode', controlTowerMapTPrefix)}
							</option>
							<option value='all'>
								{tString('all', controlTowerMapTPrefix + '.modes')}
							</option>
							<option value='ltl'>
								{tString('ltl', controlTowerMapTPrefix + '.modes')}
							</option>
							<option value='truckload'>
								{tString('truckload', controlTowerMapTPrefix + '.modes')}
							</option>
							<option value='parcel'>
								{tString('parcel', controlTowerMapTPrefix + '.modes')}
							</option>
						</select>
					</div>
					<div>
						<Loader isLoading={isLoading} />
					</div>
				</div>
				<LeafletMap height='700px'>
					{l.map(state.mapMarkers, (c, cIdx) => (
						<Marker
							key={cIdx}
							position={{ lat: c.lat, lng: c.lng }}
							icon={markerIconFactory(
								c.markerType,
								c.markerStatus,
								c.markerDirection,
							)}
							onClick={async () => {
								let route: IRequestState<RoutingResponse>
								if (c.markerType !== 'ltl') {
									route = await apiRouting.fetchRoutingInfo((rs) => {}, {
										locations: l.map(c.stopsInOrder, (stop) => ({
											lat: stop.lat,
											lon: stop.lng,
											type: 'break',
										})),
										costing: 'truck',
									})
								}

								setRoutingElements(
									l.assign(
										getRouteElements(c, route?.data?.trip?.legs[0]?.shape),
									),
								)
								setIsPopoverVisible(true)
							}}
						/>
					))}
					{isPopoverVisible && !l.isNil(routingElements.polylinePositions) && (
						<>
							<Polyline positions={routingElements.polylinePositions} />
							{routingElements.markers}
							<div
								className={theme.addClass(
									routingElements.popupSide === 'left'
										? classes.left
										: classes.right,
									classes.popOver,
								)}
								data-testid='route-popover'
							>
								<div className={classes.shipmentDetailsContainer}>
									<div className={theme.getTextColor('lightGray')}>
										<AlignRight>
											<IconButton
												icon={solidIcons.faTimes}
												onClick={() => setIsPopoverVisible(false)}
											/>
										</AlignRight>
									</div>
									<RouteStopDetails
										routeInfo={{
											stops: routingElements.routeInfo.stopsInOrder,
											shipmentId: routingElements.routeInfo.shipmentId,
											shipmentNumber: routingElements.routeInfo.shipmentNumber,
											carrier: routingElements.routeInfo.carrier,
											proNumber: routingElements.routeInfo.proNumber,
											mode: routingElements.routeInfo.markerType,
											salesOrders: l.flatMap(
												routingElements.routeInfo.payloads,
												(payload) => payload.salesOrders,
											),
											purchaseOrders: l.flatMap(
												routingElements.routeInfo.payloads,
												(payload) => payload.purchaseOrders,
											),
										}}
									/>
								</div>
							</div>
						</>
					)}
				</LeafletMap>
			</RequiresFeatureToggle>
		</Layout>
	)
}
