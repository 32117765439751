import { apiBroker, apiTypes } from 'ui/api'
import { sosShipmentProfileBroker } from 'ui/pages/shipment-profile/broker'
import { getWebsocketURL } from 'ui/pages/shipment-profile/shared/functions/getWebsocketUrl'
import { getBrokerShipmentProfileUrlState } from 'ui/pages/shipment-profile/broker/sosShipmentProfileBroker'

export async function trickleRateBrokerShipment(
	websocketUrl: string,
	onComplete: () => void,
): Promise<void> {
	const onProgressRate = (websocketData: apiTypes.RateResponse): void => {
		sosShipmentProfileBroker.setRate(websocketData)
	}
	const onProgressRatingError = (websocketData: apiTypes.RatingError): void => {
		sosShipmentProfileBroker.setRatingError(websocketData)
	}

	const { shipmentId } = getBrokerShipmentProfileUrlState()

	if (!websocketUrl) {
		websocketUrl = await getWebsocketURL()
		sosShipmentProfileBroker.setWebsocketUrl(websocketUrl)
	}

	await apiBroker.trickleRateShipment(
		onProgressRate,
		onProgressRatingError,
		onComplete,
		sosShipmentProfileBroker.restRateShipment,
		shipmentId,
		websocketUrl,
	)
}
