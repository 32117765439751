import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { Breadcrumb } from 'ui/components/common/breadcrumb'
import { Button } from 'ui/components/common/button'
import { HoverHighlight } from 'ui/components/common/hover-highlight/HoverHighlight'
import { Icon, solidIcons } from 'ui/components/common/icon'
import { HelpHover } from 'ui/components/common/icon-hover/IconHover'
import { LinkButton } from 'ui/components/common/link/LinkButton'
import { LoadingBar } from 'ui/components/common/loader'
import { InfiniteLoader } from 'ui/components/common/loader/InfiniteLoader'
import { DocumentViewer, Modal } from 'ui/components/common/modal'
import { Popup } from 'ui/components/common/popup/Popup'
import { RelativeExample } from 'ui/components/common/popup/RelativeExample'
import { ScriptLoader } from 'ui/components/common/script-loader/ScriptLoader'
import { Separator } from 'ui/components/common/separator/Separator'
import { Debug } from 'ui/components/dev/Debug'
import { Placeholder } from 'ui/components/dev/placeholder/Placeholder'
import { t } from 'ui/components/i18n/i18n'
import { Spacer } from 'ui/components/layout/spacer'
import * as secrets from 'ui/secrets/secrets'
import { sosComponentExamples } from 'ui/state/'
import { IStateComponentExamples } from 'ui/state/sosComponentExamples'
import { ConnectToCommonCode } from './ConnectToCommonCode'
import { Section } from './Section'

export const SectionMisc: FC = (props: {
	stateComponentExamples: IStateComponentExamples
}) => {
	return (
		<Section
			title={t('page.componentExamples.misc')}
			stateComponentExamples={props.stateComponentExamples}
			name='misc'
		>
			<ConnectToCommonCode />
			<Breadcrumb
				entries={[
					{ label: "let's" },
					{ label: 'yeet' },
					{ label: 'this' },
					{ label: 'wheat', url: '/' },
				]}
			/>
			<ScriptLoader
				href={`https://maps.googleapis.com/maps/api/js?key=${secrets.googleMapApiKey}&libraries=geometry`}
				render={() => {
					return <div> Google maps is loaded!</div>
				}}
			/>
			<Placeholder />
			<Placeholder>
				A placeholder <br /> is here
			</Placeholder>
			<Separator />
			middle
			<Separator inset={true} />
			<Placeholder width='120px' height='220px'>
				A sized placeholder <br /> 120x220
			</Placeholder>
			<Separator />
			<RelativeExample />
			Separators
			<Separator color='blue' />
			<Separator color='gray' />
			<Separator color='green' />
			<Separator color='red' />
			<Separator color='orange' />
			<Separator />A popup example
			<Spacer />
			<div>
				<Popup
					isOpen={props.stateComponentExamples.showPopup}
					onClickOutside={() => {
						sosComponentExamples.closePopup()
					}}
					content={<div>some stuff goes here</div>}
				>
					<LinkButton
						onClick={() => {
							sosComponentExamples.openPopup()
						}}
					>
						Open popup
					</LinkButton>
				</Popup>
			</div>
			<Debug label='test debug data' data={{ test: 123, hello: 'world' }} />
			<div>
				Infinite loader
				<InfiniteLoader isLoading={true} />
			</div>
			<div>
				Infinite preloader
				<InfiniteLoader isPreLoading={true} />
			</div>
			<div>
				Infinite loader hidden
				<InfiniteLoader />
			</div>
			<Spacer />
			<div>
				Loading Bar
				<LoadingBar isLoading={true} totalSteps={100} completedSteps={50} />
			</div>
			<Spacer />
			<div>
				Hover Highlight
				<HoverHighlight
					tooltip={t('page.componentExamples.hoverHighlight.tooltip')}
					color={'blue'}
				>
					<Debug
						label='some test data'
						data={{
							text: 'Look at me. HOVER OVER ME!',
							color: 'blue',
							tooltipPosition: 'topLeft(default)',
						}}
					/>
				</HoverHighlight>
				<HoverHighlight
					color={'gray'}
					onClick={() => {
						alert('You clicked me')
					}}
					tooltip={<Icon icon={solidIcons.faCoffee} />}
					tooltipPosition='topRight'
				>
					<Debug
						label='some test data'
						data={{
							text: 'Look at me. HOVER OVER ME!',
							color: 'gray',
							clickMe: 'Click me!',
							tooltipPosition: 'topRight',
						}}
					/>
				</HoverHighlight>
				<HoverHighlight
					color={'green'}
					tooltip={t('page.componentExamples.hoverHighlight.tooltip')}
					tooltipPosition='bottomLeft'
				>
					<Debug
						label='some test data'
						data={{
							text: 'Look at me. HOVER OVER ME!',
							color: 'green',
							tooltipPosition: 'bottomLeft',
						}}
					/>
				</HoverHighlight>
				<HoverHighlight
					color={'red'}
					tooltip={t('page.componentExamples.hoverHighlight.tooltip')}
					tooltipPosition='bottomRight'
				>
					<Debug
						label='some test data'
						data={{
							text: 'Look at me. HOVER OVER ME!',
							color: 'red',
							tooltipPosition: 'bottomRight',
						}}
					/>
				</HoverHighlight>
				<HoverHighlight color={'orange'}>
					<Debug
						label='some test data'
						data={{ text: 'Look at me. HOVER OVER ME!', color: 'orange' }}
					/>
				</HoverHighlight>
			</div>
			<Spacer />
			<div>
				Modal example:
				<Modal
					title='Modalrific'
					content={() => (
						<div>
							<h1>So modal. Very wow.</h1>
						</div>
					)}
					onModalClose={sosComponentExamples.closeModal}
					isOpen={props.stateComponentExamples.showModal}
				/>
				<Button color='blue' onClick={sosComponentExamples.openModal}>
					Open Modal
				</Button>
			</div>
			<Spacer />
			<div>
				PNG Document Viewer Example:
				<DocumentViewer
					documentUrl={
						'https://media1.giphy.com/media/tXTqLBYNf0N7W/giphy-downsized.gif?cid=6104955ef657b5b2d2e57abe4627823038466ac4d1493acc&rid=giphy-downsized.gif'
					}
					onModalClose={() =>
						sosComponentExamples.setShowPngDocumentViewer(false)
					}
					isOpen={props.stateComponentExamples.showPngDocumentViewer}
				/>
				<Button
					color='blue'
					onClick={() => sosComponentExamples.setShowPngDocumentViewer(true)}
				>
					Open Document Viewer
				</Button>
			</div>
			<Spacer />
			<div>
				PDF Document Viewer Example:
				<DocumentViewer
					documentUrl={
						'https://freightspoke-cfs.s3.amazonaws.com/payables_invoice_4160926095701-02_ABF_for_LC%20Industries_generated_at_2016-10-19%2815%3A11%3A01%29.pdf'
					}
					onModalClose={() =>
						sosComponentExamples.setShowPdfDocumentViewer(false)
					}
					isOpen={props.stateComponentExamples.showPdfDocumentViewer}
				/>
				<Button
					color='blue'
					onClick={() => sosComponentExamples.setShowPdfDocumentViewer(true)}
				>
					Open Document Viewer
				</Button>
			</div>
			<Spacer />
			<div>
				HelpHover tooltip:
				<HelpHover>Help me Obi-Wan Kenobi</HelpHover>
			</div>
		</Section>
	)
}
