import { l } from 'ui/lib/lodashImports'
import { log } from 'ui/lib/log'
import { IProviderPortalInvoiceProfile } from 'ui/pages/provider-portal/IProviderPortalInvoiceProfile'
import { createSos } from './secretaryOfState'

type TSubwayStates = 'success' | 'waiting' | 'failed'

interface ISubwayStatus {
	invoiceReceived: TSubwayStates
	clearedToPay: TSubwayStates
	fundingRequested: TSubwayStates
	customerFunded: TSubwayStates
	invoicePaid: TSubwayStates
}

interface IInvoiceProfileState {
	invoiceData: IProviderPortalInvoiceProfile
	subwayState: ISubwayStatus
	showInvoiceDocumentViewer: boolean
	showDisputeDocumentViewer: boolean
}

const initialState: IInvoiceProfileState = {
	invoiceData: {
		isBroker: false,
		status: null,
		dueDate: null,
		currencyCode: 'USD',
		dateFundsReceived: null,
		dateFundsRequested: null,
		datePaidToProvider: null,
		invoiceDate: null,
		invoiceNumber: null,
		invoiceAmount: null,
		paymentMethod: null,
		paymentNumber: null,
		disputeNumber: null,
		pro_number: null,
		providerName: null,
		remittanceNumberBase: null,
		remittanceNumberSuffix: null,
		scac: null,
		initialOrigin: {
			city: null,
			state: null,
		},
		finalDestination: {
			city: null,
			state: null,
		},
	},
	subwayState: {
		invoiceReceived: null,
		clearedToPay: null,
		fundingRequested: null,
		customerFunded: null,
		invoicePaid: null,
	},
	showInvoiceDocumentViewer: false,
	showDisputeDocumentViewer: false,
}

const { stateManager, useSubscribe } = createSos(
	'sosProviderPortalInvoiceProfile',
	'1.0.0',
	initialState,
)
export { useSubscribe }

export const setSubwayState = (stop: string, value: TSubwayStates): void => {
	stateManager.produce((ds) => {
		ds.subwayState[stop] = value
	})
}

export const setInvoiceData = (invoiceData: any): void => {
	log('provider-portal', 'invoice data', invoiceData)
	stateManager.produce((ds) => {
		l.assign(ds.invoiceData, invoiceData)
	})
}

export function setShowInvoiceDocumentViewer(isShown: boolean): void {
	stateManager.produce((ds) => {
		ds.showInvoiceDocumentViewer = isShown
	})
}

export function setShowDisputeDocumentViewer(isShown: boolean): void {
	stateManager.produce((ds) => {
		ds.showDisputeDocumentViewer = isShown
	})
}
