import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { IRequestState } from 'ui/api/requestState'
import { t } from 'ui/components/i18n/i18n'
import { DataTable } from 'ui/components/table/DataTable'
import { DataTableUpdate } from 'ui/components/table/DataTableUpdate'
import { IDataTableHeader } from 'ui/components/table/IDataTableHeader'
import { IDataTableState } from 'ui/components/table/IDataTableState'
import { IPackageCatalogForm } from 'ui/forms/formPackageCatalog'
import { IPackageCatalog } from 'ui/lib/books'
import { sosPackageCatalog, sosPackageCatalogEntry } from 'ui/state'
import { UpdatePackageCatalogForm } from './UpdatePackageCatalogForm'

export const PackageCatalogDataTable: FC = (props: {
	requestAddEditPackageCatalog: IRequestState<IPackageCatalog>
	requestGetPackageCatalogs: IRequestState<IPackageCatalog[]>
	dataTableState: IDataTableState<IPackageCatalog>
	data: IPackageCatalog[]
	form: IPackageCatalogForm
}) => {
	const { requestGetPackageCatalogs, requestAddEditPackageCatalog } = props
	const { dataTableState } = props
	const headers: IDataTableHeader<IPackageCatalog>[] = [
		{
			field: 'id',
		},
		{
			field: 'name',
		},
		{
			field: 'pageType',
		},
	]
	return (
		<div>
			<DataTable
				testId='data-table-books'
				headers={headers}
				tPrefix='page.managePackageCatalogs.bookTable'
				isLoading={
					requestGetPackageCatalogs.isFetching ||
					requestAddEditPackageCatalog.isFetching
				}
				data={props.data}
				state={dataTableState}
				onEditRow={(row) => {
					sosPackageCatalog.booksTableSetUpdateMode('edit', row)
				}}
				onToggleHeader={(c) => {
					sosPackageCatalog.booksTableToggleHeader(c.field)
				}}
				onClickHeader={(header) => {
					sosPackageCatalog.booksTableSort(header.field)
				}}
				onRefresh={async () => {
					await sosPackageCatalog.getBooks()
				}}
				onClickRow={(row) => {
					sosPackageCatalogEntry.navigateToPackageCatalog({
						bookId: row.id,
					})
				}}
				onAddRow={() => {
					sosPackageCatalog.booksTableSetUpdateMode('add')
				}}
				addLabel={t('page.managePackageCatalogs.bookTable.add')}
				rendererAdd={() => (
					<DataTableUpdate error={props.dataTableState.error}>
						<UpdatePackageCatalogForm mode='add' form={props.form} />
					</DataTableUpdate>
				)}
				rendererEdit={(row) => (
					<DataTableUpdate
						error={props.dataTableState.error}
						onDeleteRow={async () => {
							await sosPackageCatalog.updateBook('delete')
						}}
					>
						<UpdatePackageCatalogForm mode='edit' form={props.form} />
					</DataTableUpdate>
				)}
			/>
		</div>
	)
}
