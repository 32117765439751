import React, { useEffect, useState } from 'react'
import { apiTypes } from 'ui/api'
import { FC } from 'ui/FunctionalComponent'
import { Card } from 'ui/components/common/card'
import { t, tCurrency, tCurrencyFromCents } from 'ui/components/i18n/i18n'
import classes from './ReferenceInformation.module.scss'
import { Center } from 'ui/components/layout/center'
import { Loader } from 'ui/components/common/loader'
import { l } from 'ui/lib/lodashImports'
interface ReferenceInformation {
	brokerOffer: string
	providerQuote: string
	providerInvoice: string
	shipmentId: string
}

const defaultReferenceInformationData: ReferenceInformation = {
	brokerOffer: '0',
	providerQuote: '0',
	providerInvoice: '0',
	shipmentId: '-',
}

const tPrefix = 'page.customerInvoice.referenceInformation'
const currencyInitial = ' USD'

export const ReferenceInformation: FC = (props: {
	isFetchingInvoices: boolean
	brokerShipment: apiTypes.BrokerShipmentResponse
	providerInvoices: apiTypes.ProviderInvoiceResponse[]
}) => {
	const { isFetchingInvoices, brokerShipment, providerInvoices } = props
	const [referenceInformationData, setReferenceInformationData] = useState<
		ReferenceInformation
	>(defaultReferenceInformationData)

	const computeReferenceInfomation = (): void => {
		const providerInvoiceCharges = l.flatMap(
			providerInvoices,
			(providerInvoice) => providerInvoice.charges,
		)

		const totalCost = l.sumBy(
			providerInvoiceCharges,
			(charge) => charge.quantity * charge.unitPrice,
		)

		setReferenceInformationData({
			brokerOffer:
				tCurrencyFromCents(
					brokerShipment?.offers[0]?.flatOffer?.grossRate || 0,
				) + currencyInitial,
			providerQuote:
				tCurrencyFromCents(brokerShipment?.bookedRate?.costTotal) +
				currencyInitial,
			providerInvoice:
				tCurrency(
					isNaN(totalCost) || totalCost < 0 || totalCost === Infinity
						? 0
						: totalCost,
				) + currencyInitial,
			shipmentId: brokerShipment?.identifier,
		})
	}

	useEffect(() => {
		if (brokerShipment) {
			computeReferenceInfomation()
		}
	}, [brokerShipment, providerInvoices]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Card
			title={t('referenceInformation', tPrefix)}
			color={'darkBlue'}
			testId={'customer-invoice-reference-information'}
		>
			{isFetchingInvoices ? (
				<Center>
					<Loader isLoading={isFetchingInvoices} />
				</Center>
			) : (
				<div className={classes.referenceInformationTable}>
					<table>
						<tbody>
							<tr>
								<th>{t('brokerOffer', tPrefix)}:</th>
								<td>{referenceInformationData.brokerOffer}</td>
								<th>{t('shipmentId', tPrefix)}:</th>
								<td>{referenceInformationData.shipmentId}</td>
							</tr>
							<tr>
								<th>{t('providerQuote', tPrefix)}:</th>
								<td>{referenceInformationData.providerQuote}</td>
							</tr>
							{/* TO BE ADDED */}
							{/* <tr>
								<th>{t('providerInvoice', tPrefix)}:</th>
								<td>{referenceInformationData.providerInvoice}</td>
							</tr> */}
						</tbody>
					</table>
				</div>
			)}
		</Card>
	)
}
