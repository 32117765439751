import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { Breadcrumb, IBreadcrumbEntry } from 'ui/components/common/breadcrumb'
import { t } from 'ui/components/i18n/i18n'
import { FlexItem, FlexRow } from 'ui/components/layout/flexRow'
import { Spacer } from 'ui/components/layout/spacer'
import {
	StationFail,
	StationSuccess,
	StationWaiting,
	Subway,
	SubwayArrow,
	SubwayStation,
} from 'ui/components/shared/subway'
import { l } from 'ui/lib/lodashImports'
import { BrokerView } from 'ui/pages/provider-portal/invoice-profile/broker-view'
import { ClearedToPay } from 'ui/pages/provider-portal/invoice-profile/invoice-display-configs/cleared-to-pay'
import { FundingReceived } from 'ui/pages/provider-portal/invoice-profile/invoice-display-configs/funding-received'
import { FundingRequested } from 'ui/pages/provider-portal/invoice-profile/invoice-display-configs/funding-requested'
import { InvoiceDisputed } from 'ui/pages/provider-portal/invoice-profile/invoice-display-configs/invoice-disputed'
import { InvoicePaid } from 'ui/pages/provider-portal/invoice-profile/invoice-display-configs/invoice-paid'
import { UndergoingAudit } from 'ui/pages/provider-portal/invoice-profile/invoice-display-configs/undergoing-audit/UndergoingAudit'
import { WaitingOnInvoice } from 'ui/pages/provider-portal/invoice-profile/invoice-display-configs/waiting-on-invoice'
import {
	sosProviderPortalInvoiceProfile,
	sosProviderPortalInvoices,
} from 'ui/state'
import * as commonClasses from 'ui/theme/common.module.scss'
import { ProviderPortalLayout } from '../ProviderPortalLayout'
import { defaultProviderPortalCellValue } from '../providerPortalTypes'

const tPrefix = 'page.providerPortal.invoiceProfile'

const findInvoiceDisplayConfig = (state): React.ReactElement => {
	switch (state.status) {
		case 'clearedToPay':
			return <ClearedToPay />
		case 'disputed':
			return <InvoiceDisputed />
		case 'fundingReceived':
			return <FundingReceived />
		case 'fundingRequested':
			return <FundingRequested />
		case 'paid':
			return <InvoicePaid />
		case 'waitingOnInvoice':
			return <WaitingOnInvoice />
		case 'undergoingAudit':
			return <UndergoingAudit />
	}
}

export const displayStation = (stationStatus): React.ReactElement => {
	if (stationStatus === 'waiting') {
		return <StationWaiting />
	}
	if (stationStatus === 'success') {
		return <StationSuccess />
	}
	if (stationStatus === 'fail') {
		return <StationFail />
	}
	return <div>Invalid station state {stationStatus}</div>
}

export const InvoiceProfile: FC = (props: {}) => {
	const state = sosProviderPortalInvoiceProfile.useSubscribe()
	const invoiceState = sosProviderPortalInvoices.useSubscribe()

	const { invoiceData } = state
	const { subwayState } = state

	const bread: IBreadcrumbEntry[] = [
		{ t: 'page.providerPortal.invoices', url: '/provider-portal/invoices' },
		{ t: 'page.providerPortal.invoiceProfile' },
	]

	if (invoiceData.invoiceNumber !== defaultProviderPortalCellValue) {
		bread.push({ label: invoiceData.invoiceNumber })
	}

	const customerName = l.find(
		invoiceState.customers,
		(c) => c.value === invoiceState.currentCustomer,
	)

	return (
		<ProviderPortalLayout>
			<FlexRow fullWidth={true}>
				<FlexItem>
					<Breadcrumb entries={bread} />
					<Spacer />
					{customerName && (
						<div>
							{t('customer', tPrefix)}
							{': ' + customerName.label}
						</div>
					)}
				</FlexItem>
				<FlexItem>
					<Subway>
						<SubwayStation
							top={
								<div className={commonClasses.boldText}>
									{t('invoiceReceived', tPrefix)}
								</div>
							}
							bottom={''}
						>
							{displayStation(subwayState.invoiceReceived)}
						</SubwayStation>
						<SubwayArrow />

						<SubwayStation
							top={
								<div className={commonClasses.boldText}>
									{t('clearedToPay', tPrefix)}
								</div>
							}
							bottom={
								invoiceData.status === 'disputed'
									? t('stageDisputed', tPrefix)
									: ''
							}
						>
							{displayStation(subwayState.clearedToPay)}
						</SubwayStation>

						<SubwayArrow />

						<SubwayStation
							top={
								<div className={commonClasses.boldText}>
									{t('fundingRequested', tPrefix)}
								</div>
							}
							bottom={''}
						>
							{displayStation(subwayState.fundingRequested)}
						</SubwayStation>
						<SubwayArrow />

						<SubwayStation
							top={
								<div className={commonClasses.boldText}>
									{t('customerFunded', tPrefix)}
								</div>
							}
							bottom={''}
						>
							{displayStation(subwayState.customerFunded)}
						</SubwayStation>
						<SubwayArrow />

						{invoiceData.isBroker && <BrokerView />}
						<SubwayStation
							top={
								<div className={commonClasses.boldText}>
									{t('invoicePaid', tPrefix)}
								</div>
							}
							bottom={''}
						>
							{displayStation(subwayState.invoicePaid)}
						</SubwayStation>
					</Subway>
				</FlexItem>
			</FlexRow>
			{findInvoiceDisplayConfig(invoiceData)}
		</ProviderPortalLayout>
	)
}
