import React, { useState, useEffect } from 'react'
import { useDataApi, UseDataApiParams } from 'marketplace/services/useDataApi'
import { Table as ProviderTable } from './table/Table'
import { Toast, ToastState } from 'common/components/toast'
import { FC } from 'app/FunctionalComponent'

export const ProviderList: FC = () => {
	const [initLoading, setInitLoading] = useState(true)

	const dataConfig: UseDataApiParams = {
		path: `providers`,
		options: { method: 'GET' },
		params: { take: 10, query: '' },
	}

	const [{ data, isLoading, isError }, doFetch] = useDataApi(dataConfig, {
		entities: [],
	})

	useEffect(() => {
		setInitLoading(false)
	}, [data.entities])

	const ErrorToast: ToastState = {
		type: 'danger',
		body: 'error loading providers',
	}

	function onHandleChange(
		type,
		{ page, sizePerPage, filters, sortField, sortOrder },
	): void {
		// don't care about type!
		if (!initLoading) {
			// setting the page params
			const currentIndex = (page - 1) * sizePerPage
			dataConfig.params['take'] = sizePerPage
			dataConfig.params['skip'] = currentIndex

			// setting filters
			dataConfig.params.query = ''
			const filterKeys = Object.keys(filters)
			const query = []

			if (filterKeys.length > 0) {
				filterKeys.forEach((f) => {
					if (f === 'active') {
						query.push(`(${f}:${filters[f].filterVal})`)
					} else {
						query.push(`(${f}.keyword:*${filters[f].filterVal}*)`)
					}
				})
				dataConfig.params.query = query.join(' AND ')
			} else {
				delete dataConfig.params.query
			}

			// setting sort
			if (sortField) {
				dataConfig.params.sort = `${sortField}.keyword:${sortOrder}`
			} else {
				delete dataConfig.params.sort
			}

			doFetch(dataConfig)
		}
	}

	return (
		<div className='providers list'>
			{data.entities && (
				<ProviderTable
					data={data.entities}
					totalSize={data.total}
					isLoading={isLoading}
					onHandleChange={onHandleChange}
				/>
			)}
			{isError && <Toast toast={ErrorToast} />}
		</div>
	)
}
