import { FC } from 'app/FunctionalComponent'
import React from 'react'
import styles from './Spacer.module.scss'

const Spacer: FC = (props: { width?: string; height?: string }) => {
	return (
		<div
			className={styles.spacer}
			style={{ width: props.width, height: props.height }}
		/>
	)
}

export { Spacer }
