import React from 'react'
import { Center } from 'ui/components/layout/center'
import { IShipmentListMappedData } from 'ui/state'
import { CarrierImage } from 'ui/components/shared'
import { l } from 'ui/lib/lodashImports'
import { t, tString, tCurrency } from 'ui/components/i18n/i18n'
import { DateTime } from 'luxon'

export const EmptyRenderer = (): React.ReactElement => <Center>&ndash;</Center>

export const dateRenderer = (c): React.ReactElement => {
	if (!c) {
		return <EmptyRenderer />
	}
	return (
		<div title={DateTime.fromISO(c).toLocaleString(DateTime.DATETIME_SHORT)}>
			{DateTime.fromISO(c).toLocaleString(DateTime.DATE_SHORT)}
		</div>
	)
}

export const providerRendererImage = (
	c: string,
	row: IShipmentListMappedData,
): React.ReactElement => {
	if (!c) {
		return <EmptyRenderer />
	}

	return (
		<div style={{ display: 'flex', justifyContent: 'center' }}>
			<CarrierImage carrier={c} style={{ maxHeight: '25px' }} showName={true} />
		</div>
	)
}

export const stringArrayRenderer = (c: string[]): React.ReactElement => {
	if (!c) {
		return <EmptyRenderer />
	}
	return (
		<>
			{l.map(c, (d, dIdx) => (
				<div key={dIdx}>{d}</div>
			))}
		</>
	)
}

export const shippingModeRenderer = (c: string): React.ReactElement => {
	if (!c) {
		return <EmptyRenderer />
	}

	return t(c, 'page.shipmentsList')
}

export const shipmentStatusFormatter = (c): string => {
	if (c) {
		return tString(c, 'page.shipmentsList')
	}
}

export const dateExportFormatter = (c): string => {
	if (c) {
		return DateTime.fromISO(c).toLocaleString(DateTime.DATE_SHORT)
	}
}

export const idArrayExportFormatter = (c: string[]): string => {
	if (c) {
		return l.map(c, (d) => d).join(', ')
	}
}

export const shippingModeExportFormatter = (c: string): string => {
	if (c) {
		return tString(c, 'page.shipmentsList')
	}
}

export const providerExportFormatter = (c: string): string => {
	if (c) {
		return l.startCase(c)
	}
}

export const currencyExportFormatter = (c: number): string => {
	if (c) {
		return tCurrency(c / 100, 'USD', 2)
	}
}

export const percentExportFormatter = (c: number): string => {
	if (c) {
		return c + '%'
	}
}
