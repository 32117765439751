import { useEffect } from 'react'

// Only do this on the first render
// Warning: Action can't have any dependencies or there will be bugs
/* eslint-disable react-hooks/exhaustive-deps */
export function useOnce<T>(action: () => any): void {
	useEffect(() => {
		action()
	}, [])
}
