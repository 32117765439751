import { apiCommon } from '.'
import { IRequestState } from './requestState'

export const createRegistration = async (
	onProgress: (fetch: IRequestState<{}>) => void,
	email: string,
	company: string,
): Promise<IRequestState<{}>> => {
	const result = await apiCommon.apiFetch(
		onProgress,
		{},
		'lightningIntegration',
		{ email, company },
	)
	return result
}
export const createSetPassword = async (
	onProgress: (fetch: IRequestState<{}>) => void,
	password: string,
	confirmPassword: string,
): Promise<IRequestState<{}>> => {
	const result = await apiCommon.apiFetch(
		onProgress,
		{},
		'lightningIntegration',
		{ password, confirmPassword },
	)
	return result
}
export const createConfiguration = async (
	onProgress: (fetch: IRequestState<{}>) => void,
	upsId: string,
	upsKey: string,
	// fedexId: string,
	// fedexKey: string,
	// uspsId: string,
	// uspsKey: string,
	// saiaId: string,
	// saiaKey: string,
	// truckload: string,
): Promise<IRequestState<{}>> => {
	const result = await apiCommon.apiFetch(
		onProgress,
		{},
		'lightningIntegration',
		{
			upsId,
			upsKey,
			// fedexId,
			// fedexKey,
			// uspsId,
			// uspsKey,
			// saiaId,
			// saiaKey,
			// truckload,
		},
	)
	return result
}
