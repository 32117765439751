import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { FilterItem } from 'ui/components/common/filters/FilterItem'
import { Filters } from 'ui/components/common/filters/Filters'
import { FilterSeparator } from 'ui/components/common/filters/FilterSeparator'
import { t } from 'ui/components/i18n/i18n'
import { FlexRow } from 'ui/components/layout/flexRow'
import { l } from 'ui/lib/lodashImports'
import { sosUser } from 'ui/state'

const allFilter = [{ name: 'all' }]

const nonBrokerFilters = [
	{ name: 'quoted' },
	{ name: 'awarded' },
	{ name: 'booked' },
	{ name: 'in-transit' },
	{ name: 'delivered' },
]

const abnormalWorkflowFilters = [
	{ name: 'undeliverable' },
	{ name: 'void' },
	{ name: 'no-rates' },
]

const brokerFilters = [
	{ name: 'needs-quote' },
	{ name: 'quoted' },
	{ name: 'needs-transport' },
	{ name: 'awarded' },
	{ name: 'booked' },
	{ name: 'in-transit' },
	{ name: 'delivered' },
]

const abnormalBrokerWorkflowFilters = [
	// { name: 'closed' },
	{ name: 'canceled' },
]

function insertFilterItem(
	filters,
	selectedFilter,
	props,
): React.ReactElement[] {
	return l.map(filters, (c) => (
		<FilterItem
			key={c.name}
			isSelected={selectedFilter === c.name}
			onClick={() => {
				props.onChangeFilter(c.name)
			}}
		>
			{t(`page.shipmentsList.filter.${l.camelCase(c.name)}`)}
		</FilterItem>
	))
}

export const ShipmentsListFilter: FC = (props: {
	selectedFilter: string
	onChangeFilter: (newVal: string) => void
}) => {
	const selectedFilter = props.selectedFilter || 'all'
	const isUserBroker = sosUser.isUserBroker()
	const filters = isUserBroker ? brokerFilters : nonBrokerFilters
	const abnormalFilters = isUserBroker
		? abnormalBrokerWorkflowFilters
		: abnormalWorkflowFilters

	return (
		<FlexRow mode={'center'} fullWidth={true}>
			<Filters>
				{insertFilterItem(allFilter, selectedFilter, props)}
				<FilterSeparator />
				{insertFilterItem(filters, selectedFilter, props)}

				<FilterSeparator />
				{insertFilterItem(abnormalFilters, selectedFilter, props)}
			</Filters>
		</FlexRow>
	)
}
