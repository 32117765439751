import { Colors } from 'ui/theme/theme'
import { StopType } from 'ui/pages/new-quote/state/sosNewQuote'

export type CardEditMode = 'full' | 'dropdown' | 'none'

interface IShipmentStopCardConfig {
	tTitle: string
	headerColor: Colors
}

export const cardConfigs: { [k in StopType]: IShipmentStopCardConfig } = {
	pickup: {
		tTitle: 'shared.shipmentStopCard.pickup',
		headerColor: 'darkBlue',
	},
	delivery: {
		tTitle: 'shared.shipmentStopCard.delivery',
		headerColor: 'darkBlue',
	},
}
