import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { Modal } from 'ui/components/common/modal'
import styles from './DocumentViewer.module.scss'

export const DocumentViewer: FC = (props: {
	documentUrl: string
	isOpen: boolean
	onModalClose: () => void
	alt?: React.ReactNode
	title?: React.ReactNode
	base64Specification?: string
}) => {
	const imageFormats = ['png', 'jpg', 'jpeg', 'gif']
	const imageUrlPieces = props.documentUrl.split('.')
	const documentFormat = imageUrlPieces[imageUrlPieces.length - 1]

	const base64URL = `data:${props.base64Specification};base64,${props.documentUrl}`

	return (
		<div>
			<Modal
				title='Document Viewer'
				isOpen={props.isOpen}
				onModalClose={props.onModalClose}
				content={() => (
					<div>
						{imageFormats.includes(documentFormat) && (
							<img
								src={props.documentUrl}
								alt={'' + (props.alt || 'Document')}
								title={'' + props.title}
								className={styles.image}
							/>
						)}
						{(documentFormat === 'pdf' || props.base64Specification) && (
							<iframe
								title='pdf'
								className={styles.pdf}
								src={
									props.base64Specification
										? base64URL
										: `https://docs.google.com/viewer?url=${props.documentUrl}&embedded=true`
								}
							/>
						)}
					</div>
				)}
				closeModalX={true}
			/>
		</div>
	)
}
