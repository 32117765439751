import React from 'react'
import { FormCheckbox } from './FormCheckbox'
import { FormStackedItem } from './FormStackedItem'
import { IFormData } from './IFormData'

export function FormStackedCheckbox<T>(props: {
	field: keyof T
	formData: IFormData<T>
	hideOptional?: boolean
}): React.ReactElement {
	const { field, formData, hideOptional } = props

	return (
		<FormStackedItem
			field={field}
			formData={formData}
			hideOptional={hideOptional}
		>
			<FormCheckbox
				field={field}
				form={formData.form}
				onUpdateForm={formData.onUpdateForm}
			/>
		</FormStackedItem>
	)
}
