import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { Icon, solidIcons } from 'ui/components/common/icon'
import { theme } from 'ui/theme'

export const StationSuccess: FC = (props: {}) => {
	return (
		<div
			data-testid='subway-station-success'
			className={`${theme.getTextColor('green')}`}
		>
			<Icon icon={solidIcons.faCheckCircle} />
		</div>
	)
}
