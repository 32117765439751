import { FC } from 'app/FunctionalComponent'
import { TypeaheadOption } from 'common/components/typeahead/TypeaheadOption'
import React, { useEffect, useState } from 'react'
import { apiLocation, apiTypes } from 'ui/api'
import { IRequestState } from 'ui/api/requestState'
import { sosToast } from 'ui/common/components/toast'
import { FilterItem, Filters } from 'ui/components/common/filters'
import { Loader } from 'ui/components/common/loader'
import { ISelectOptions } from 'ui/components/common/select'
import { StackedItem } from 'ui/components/form'
import { t } from 'ui/components/i18n/i18n'
import { Spacer } from 'ui/components/layout/spacer'
import { EditAddressFormStacked, IAddress } from 'ui/components/shared/address'
import { UpdateAddressStateType } from 'ui/components/shared/address/UpdateAddressStateType'
import { IOnSelectLocation } from 'ui/components/shared/address/IOnSelectLocation'
import { SimpleAddress } from 'ui/components/shared/address/SimpleAddress'
import { LocationSelector } from 'ui/components/shared/location-selector/LocationSelector'
import { _idx } from 'ui/lib'
import { fireAndForget } from 'ui/lib/async'
import { l } from 'ui/lib/lodashImports'
import { r } from 'ui/lib/ramdaImports'
import { sos2 } from 'ui/lib/state/sos2'
import { sosUser } from 'ui/state'
import { IStateUser } from 'ui/state/sosUser'
import { BillToThirdParty } from '.'
import * as classes from './BillTo.module.scss'
import { sosNewQuote } from './state'
import { IStateNewQuote } from './state/sosNewQuote'

export type BillToSelectorType =
	| 'companyLocation'
	| 'pickup'
	| 'delivery'
	| 'customAddress'
	| 'addressBook'

const allFilters: BillToSelectorType[] = [
	'pickup',
	'delivery',
	'companyLocation',
	'customAddress',
	'addressBook',
]
const tPrefix = 'page.newQuote.billTo'

export const AddressWrapper: FC = (props: {
	children: React.ReactNode
	label: React.ReactNode
}) => {
	return (
		<div>
			<div className={classes.addressLabel}>{props.label}</div>
			<div className={classes.address}>{props.children}</div>
		</div>
	)
}

function getBillingAddress(
	state: IStateNewQuote,
	stateUser: IStateUser,
): { billToAddress: IAddress; billToShipToAddress: IAddress } {
	const { selectedBillToFilter } = state

	if (
		selectedBillToFilter === 'customAddress' ||
		selectedBillToFilter === 'addressBook'
	) {
		return {
			billToAddress: _idx(() => state.billTo.address),
			billToShipToAddress: null,
		}
	}

	if (
		selectedBillToFilter === 'pickup' ||
		selectedBillToFilter === 'delivery'
	) {
		let billToLocationId = ''
		let shipTo: IAddress = null
		if (state.selectedBillToFilter === 'pickup') {
			billToLocationId = state.stops[0].addressState.locationId
			shipTo = state.stops[0].addressState.address
		} else if (state.selectedBillToFilter === 'delivery') {
			billToLocationId = state.stops[1].addressState.locationId
			shipTo = state.stops[1].addressState.address
		}

		const billToLocation: apiTypes.LocationResponse = l.find(
			stateUser.requestLocationsInfo.data,
			(location) => billToLocationId === location.id,
		)
		const billingAddress: IAddress = _idx(
			() => billToLocation.defaults.defaultBillingAddress.address,
		)

		if (billingAddress) {
			return { billToAddress: billingAddress, billToShipToAddress: shipTo }
		} else {
			return { billToAddress: shipTo, billToShipToAddress: null }
		}
	}

	return { billToAddress: null, billToShipToAddress: null }
}

export const BillTo: FC = (props: {
	state: IStateNewQuote
	filters?: BillToSelectorType[]
	billToLocations?: IRequestState<ISelectOptions[]>
	onSelectLocation?: IOnSelectLocation
	organizationRelationshipId?: string
	updateAddressState: UpdateAddressStateType
}) => {
	const {
		state,
		// billToLocations,
		// onSelectLocation,
		// organizationRelationshipId,
	} = props
	let filters = props.filters
	if (!filters) {
		filters = allFilters
	}
	const stateUser = sos2.useSubscription(sosUser.getSos())
	const [currentlySelectedLocation, setCurrentlySelectedLocation] = useState(
		null,
	)

	const { billToAddress } = getBillingAddress(state, stateUser)

	useEffect(() => {
		if (
			state.billToThisParty === 'thirdParty' &&
			state.billTo.locationId &&
			state.selectedBillToFilter === 'companyLocation' &&
			state.useCompanyLocationAddress
		) {
			fireAndForget(async () => {
				const locationResponse = await apiLocation.fetchLocation(
					null,
					state.billTo.locationId,
				)
				if (locationResponse.data) {
					setCurrentlySelectedLocation({
						label: locationResponse.data.name,
						value: locationResponse.data.id,
					})
					sosNewQuote.updateBillToAddress(
						locationResponse.data.defaults.defaultBillingAddress.address,
					)
				} else if (locationResponse.error) {
					sosToast.sendApiErrorResponseToast(locationResponse)
				}
			}, 'Fetching Location')
		}
	}, [state.billTo.locationId, state.selectedBillToFilter]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className={classes.container} data-testid='bill-to'>
			<Filters>
				{l.map(filters, (c) => (
					<FilterItem
						key={c}
						isSelected={state.selectedBillToFilter === c}
						onClick={() => {
							// TODO: clear out address?
							sosNewQuote.selectBillToFilter(c)
						}}
					>
						{t(
							sosUser.isUserBroker() && c === 'companyLocation'
								? 'customerLocation'
								: c,
							'page.newQuote.billTo',
						)}
					</FilterItem>
				))}
			</Filters>
			{state.selectedBillToFilter === 'companyLocation' && (
				<div>
					<StackedItem
						label={t('companyLocationShort', 'shared.address')}
						showRequired={true}
					>
						<LocationSelector
							onChange={(selected: TypeaheadOption) => {
								props.onSelectLocation(
									selected.value,
									(changes) =>
										sosNewQuote.updateBillTo({
											billTo: changes,
											billToStopId: '',
										}),
									props.organizationRelationshipId,
									state.billTo.id,
								)
							}}
							includeAll={false}
							onlyShowLeafLocations={true}
							currentlySelectedLocation={currentlySelectedLocation}
						/>
					</StackedItem>
					<Loader isLoading={state.billTo.isLocationLoading} />
					<AddressWrapper label={t('billingAddress', tPrefix)}>
						<SimpleAddress
							address={state.billTo.address}
							addressState={state.billTo}
							onChangeAddressAddressState={(changes) => {
								changes.locationId = ''
								sosNewQuote.updateBillTo({
									billTo: changes,
									billToStopId: '',
								})
							}}
						/>
					</AddressWrapper>

					{/* {r.not(state.billTo.isLocationLoading) && state.billTo.locationId && (
						<AddressWrapper label={t('billingAddress', tPrefix)}>
							<SimpleAddress address={state.billTo.address} /> 
						</AddressWrapper>
					)} */}
				</div>
			)}

			{/* {state.selectedBillToFilter === 'pickup' && (
				<div>
					{billToAddress && (
						<AddressWrapper label={t('billingAddress', tPrefix)}>
							<SimpleAddress
								address={state.billTo.address}
								// addressState={state.stops[0].addressState}
								// onChangeAddressAddressState={props.updateAddressState}
								addressState={state.billTo}
								onChangeAddressAddressState={(changes) => {
									changes.locationId = ''
									sosNewQuote.updateBillTo({
										billTo: changes,
										billToStopId: '',
									})
								}}
							/>
						</AddressWrapper>
					)}
					<Spacer />
				</div>
			)} */}

			{/* {state.selectedBillToFilter === 'delivery' && (
				<div>
					{billToAddress && (
						<AddressWrapper label={t('billingAddress', tPrefix)}>
							<SimpleAddress
								address={state.billTo}
								// addressState={state.stops[1].addressState}
								// onChangeAddressAddressState={props.updateAddressState}
								addressState={state.billTo}
								onChangeAddressAddressState={(changes) => {
									changes.locationId = ''
									sosNewQuote.updateBillTo({
										billTo: changes,
										billToStopId: '',
									})
								}}
							/>
						</AddressWrapper>
					)}
					<Spacer />
				</div>
			)} */}

			{(state.selectedBillToFilter === 'pickup' ||
				state.selectedBillToFilter === 'delivery') && (
				<div>
					{state.billTo.address && (
						<AddressWrapper label={t('billingAddress', tPrefix)}>
							<SimpleAddress address={state.billTo.address} />
						</AddressWrapper>
					)}
					<Spacer />
				</div>
			)}
			{r.includes(state.selectedBillToFilter, [
				'customAddress',
				'addressBook',
			]) && (
				<div>
					<EditAddressFormStacked
						addressState={state.billTo}
						showSave={true}
						updateAddressState={(changes) => {
							changes.locationId = ''
							sosNewQuote.updateBillTo({
								billTo: changes,
								billToStopId: '',
							})
						}}
					/>
				</div>
			)}
			{paidByThirdParty(state) && (
				<div>
					<BillToThirdParty state={state} />
				</div>
			)}
		</div>
	)
}

function paidByThirdParty(state: IStateNewQuote): boolean {
	//TODO: if selectedBillToFilter is addressBook and the address selected is a client location, then return false
	return !(
		state.selectedBillToFilter === 'companyLocation' ||
		(state.selectedBillToFilter === 'pickup' &&
			state.stops[0].addressState.locationId) ||
		(state.selectedBillToFilter === 'delivery' &&
			state.stops[1].addressState.locationId)
	)
}
