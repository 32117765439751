import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { sosComponentExamples } from 'ui/state/'
import { SectionRoutes, SectionTime } from '.'
import { SectionAccountingText } from './SectionAccountingText'
import { SectionBadges } from './SectionBadges'
import { SectionBarkdown } from './SectionBarkdown'
import { SectionButtons } from './SectionButtons'
import { SectionCheckboxes } from './SectionCheckboxes'
import { SectionDates } from './SectionDates'
import { SectionDiffedText } from './SectionDiffedText'
import { SectionErrors } from './SectionErrors'
import { SectionFilters } from './SectionFilters'
import { SectionForms } from './SectionForms'
import { SectionIcons } from './SectionIcons'
import { SectionLayouts } from './SectionLayouts'
import { SectionLocalization } from './SectionLocalization'
import { SectionMaps } from './SectionMaps'
import { SectionMisc } from './SectionMisc'
import { SectionPaperCard } from './SectionPaperCard'
import { SectionPermissions } from './SectionPermissions'
import { SectionShipmentStopCard } from './SectionShipmentStopCard'
import { SectionSubway } from './SectionSubway'
import { SectionTables } from './SectionTables'
import { SectionTabs } from './SectionTabs'

const ComponentExamples: FC = (props: {}) => {
	const stateComponentExamples = sosComponentExamples.useSubscribe()

	return (
		<>
			<SectionAccountingText stateComponentExamples={stateComponentExamples} />
			<SectionBadges stateComponentExamples={stateComponentExamples} />
			<SectionButtons stateComponentExamples={stateComponentExamples} />
			<SectionCheckboxes stateComponentExamples={stateComponentExamples} />
			<SectionDates stateComponentExamples={stateComponentExamples} />
			<SectionTime stateComponentExamples={stateComponentExamples} />
			<SectionDiffedText stateComponentExamples={stateComponentExamples} />
			<SectionErrors stateComponentExamples={stateComponentExamples} />
			<SectionFilters stateComponentExamples={stateComponentExamples} />
			<SectionForms stateComponentExamples={stateComponentExamples} />
			<SectionIcons stateComponentExamples={stateComponentExamples} />
			<SectionLayouts stateComponentExamples={stateComponentExamples} />
			<SectionLocalization stateComponentExamples={stateComponentExamples} />
			<SectionMaps stateComponentExamples={stateComponentExamples} />
			<SectionMisc stateComponentExamples={stateComponentExamples} />
			<SectionPaperCard stateComponentExamples={stateComponentExamples} />
			<SectionPermissions stateComponentExamples={stateComponentExamples} />
			<SectionShipmentStopCard
				stateComponentExamples={stateComponentExamples}
			/>
			<SectionTabs stateComponentExamples={stateComponentExamples} />
			<SectionTables stateComponentExamples={stateComponentExamples} />
			<SectionBarkdown stateComponentExamples={stateComponentExamples} />
			<SectionSubway stateComponentExamples={stateComponentExamples} />
			<SectionRoutes stateComponentExamples={stateComponentExamples} />
		</>
	)
}

export { ComponentExamples }
