import { apiAccountingCodeCatalog } from 'ui/api'
import { UpdateMode } from 'ui/api/apiCommon'
import { IRequestState } from 'ui/api/requestState'
import { dataUtils } from 'ui/components/table/'
import { IDataTableState } from 'ui/components/table/IDataTableState'
import { formUtils } from 'ui/forms'
import { accountingCodeCatalogEntryFormMetadata } from 'ui/forms/formAccountingCodeCatalogEntry'
import { IAccountingCodeCatalog } from 'ui/lib/books'
import { l } from 'ui/lib/lodashImports'

import { IAccountingCodeCatalogEntryForm } from '../forms/formAccountingCodeCatalogEntry'
import { IAccountingCodeCatalogEntry } from '../lib/books/IAccountingCodeCatalogEntry'
import { router } from './router'
import { createSos } from './secretaryOfState'

export interface IStateAccountingCodeCatalog {
	bookId: string
	requestAccountingCodeCatalog: IRequestState<IAccountingCodeCatalog>
	requestAccountingCodeCatalogEntries: IRequestState<
		IAccountingCodeCatalogEntry[]
	>
	requestUpdateAccountingCodeCatalogEntry: IRequestState<
		IAccountingCodeCatalogEntry
	>
	dataTableAccountingCodeCatalogEntries: IDataTableState<
		IAccountingCodeCatalogEntry
	>
	dataAccountingCodeCatalogEntries: IAccountingCodeCatalogEntry[]
	accountingCodeCatalogEntryForm: IAccountingCodeCatalogEntryForm
}

const initialState: IStateAccountingCodeCatalog = {
	bookId: '',
	requestAccountingCodeCatalog: {},
	requestAccountingCodeCatalogEntries: {},
	requestUpdateAccountingCodeCatalogEntry: {},
	dataTableAccountingCodeCatalogEntries: {},
	dataAccountingCodeCatalogEntries: [],
	accountingCodeCatalogEntryForm: formUtils.createDefaultFormStringData(
		accountingCodeCatalogEntryFormMetadata,
	),
}

const { stateManager, useSubscribe } = createSos(
	'accounting-code-catalog',
	'1.0.3',
	initialState,
	{
		useLocalStorage: true,
		localStorageFields: [
			'requestAccountingCodeCatalogEntries',
			'dataTableAccountingCodeCatalogEntries',
		],
	},
)
export { useSubscribe }

export async function fetchAccountingCodeCatalogEntries(): Promise<void> {
	const state = stateManager.getState()
	await apiAccountingCodeCatalog.getAccountingCodeCatalogEntries(
		state.bookId,
		(rs) => {
			stateManager.produce((ds) => {
				ds.requestAccountingCodeCatalogEntries = rs
				ds.dataAccountingCodeCatalogEntries = rs.data
			})
		},
	)
}

export async function fetchCatalog(): Promise<void> {
	const state = stateManager.getState()
	await apiAccountingCodeCatalog.getAccountingCodeCatalog(
		state.bookId,
		(rs) => {
			stateManager.produce((ds) => {
				ds.requestAccountingCodeCatalog = rs
			})
		},
	)
}

export function accountingCodeCatalogTableSetUpdateMode(
	mode: UpdateMode,
	row: IAccountingCodeCatalogEntry = null,
): void {
	stateManager.produce((ds: IStateAccountingCodeCatalog) => {
		dataUtils.toggleUpdateMode(
			ds.dataTableAccountingCodeCatalogEntries,
			mode,
			row,
		)
		if (ds.dataTableAccountingCodeCatalogEntries.isAdding) {
			ds.accountingCodeCatalogEntryForm = formUtils.createDefaultFormStringData(
				accountingCodeCatalogEntryFormMetadata,
			)
		} else if (ds.dataTableAccountingCodeCatalogEntries.editRow) {
			ds.accountingCodeCatalogEntryForm = formUtils.apiDataToFormStringData(
				accountingCodeCatalogEntryFormMetadata,
				row,
			)
		}
	})
}

export function updateAccountingCodeCatalogEntryForm(
	key: keyof IAccountingCodeCatalogEntryForm,
	newVal: string,
): void {
	stateManager.produce((ds: IStateAccountingCodeCatalog) => {
		ds.accountingCodeCatalogEntryForm[key] = newVal
	})
}

export async function updateAccountingCodeCatalogEntry(
	mode: UpdateMode,
): Promise<void> {
	const state = stateManager.getState()

	const apiData = formUtils.formStringDataToApiData(
		accountingCodeCatalogEntryFormMetadata,
		state.dataTableAccountingCodeCatalogEntries.editRow,
		state.accountingCodeCatalogEntryForm,
	)

	const result = await apiAccountingCodeCatalog.updateAccountingCodeCatalogEntry(
		mode,
		state.bookId,
		apiData,
		(rs) => {
			stateManager.produce((ds: IStateAccountingCodeCatalog) => {
				ds.requestUpdateAccountingCodeCatalogEntry = rs
			})
		},
	)

	if (result.data) {
		stateManager.produce((ds: IStateAccountingCodeCatalog) => {
			dataUtils.updateItem(
				ds.dataTableAccountingCodeCatalogEntries,
				ds.dataAccountingCodeCatalogEntries,
				mode,
				result.data,
				ds.accountingCodeCatalogEntryForm.id,
			)
		})
		accountingCodeCatalogTableSetUpdateMode('none')
	}
	if (result.error) {
		stateManager.produce((ds: IStateAccountingCodeCatalog) => {
			dataUtils.setError(ds.dataTableAccountingCodeCatalogEntries, result.error)
		})
	}
}

export function sort(sortOn: string): void {
	stateManager.produce((ds) => {
		dataUtils.sort(
			ds.dataTableAccountingCodeCatalogEntries,
			ds.dataAccountingCodeCatalogEntries,
			sortOn,
		)
	})
}
export function toggleHeader(field: string): void {
	stateManager.produce((ds) => {
		dataUtils.toggleHeader(ds.dataTableAccountingCodeCatalogEntries, field)
	})
}

export function navigateToAccountingCodeCatalog(params: {
	bookId?: string
}): void {
	stateManager.produce((ds) => {
		l.defaults(params, {
			bookId: ds.bookId,
		})
		ds.bookId = params.bookId
		dataUtils.toggleUpdateMode(
			ds.dataTableAccountingCodeCatalogEntries,
			'none',
			null,
		)
	})
	router.navTo(`/accounting-code-catalog/${params.bookId}`)
}
