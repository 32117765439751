import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { sos2 } from 'ui/lib/state/sos2'
import { sosUser } from 'ui/state/'
import { Layout } from '../layout'
import { PageConfigizer2 } from '../new-quote/config'

const kConfig = 'devPageConfig'

export const DevPageConfig: FC = (props: {}) => {
	const stateUser = sos2.useSubscription(sosUser.getSos())
	return (
		<Layout>
			<div>
				This is the page config test
				{stateUser.username}
				<PageConfigizer2 prefix={kConfig} configKey='first'>
					This is the first thing to configize
				</PageConfigizer2>
				<PageConfigizer2 prefix={kConfig} configKey='second'>
					This is the second thing to configize
				</PageConfigizer2>
				<PageConfigizer2 prefix={kConfig} configKey='third'>
					Another!
				</PageConfigizer2>
			</div>
		</Layout>
	)
}
