import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { SimpleLink } from 'ui/components/common/router'
import { t } from 'ui/components/i18n/i18n'
import { IStateComponentExamples } from 'ui/state/sosComponentExamples'
import { Section } from '.'

export const SectionRoutes: FC = (props: {
	stateComponentExamples: IStateComponentExamples
}) => {
	return (
		<Section
			title={t('page.componentExamples.routes')}
			stateComponentExamples={props.stateComponentExamples}
			name='routes'
		>
			<SimpleLink href='/welcome-v3'>Welcome</SimpleLink>

			<SimpleLink href='/welcome-v3dne'>Bad link</SimpleLink>
		</Section>
	)
}
