import { FC } from 'app/FunctionalComponent'
import React, { useState } from 'react'
import { Modal } from 'ui/components/common/modal'
import { t } from 'ui/components/i18n/i18n'
import { addClass } from 'ui/theme/theme'
import * as classes from './AddProviderModal.module.scss'
import { AddProviderModalConfirm } from './AddProviderModalConfirm'
import { AddProviderModalForm } from './AddProviderModalForm'
import { AddProviderModalSearch } from './AddProviderModalSearch'
import { AddProviderModalWarning } from './AddProviderModalWarning'

export const tPrefixProvidersAdd = 'page.providers.addProviderModal'
export type AddProviderModalMode = 'search' | 'warning' | 'form' | 'confirm'

export interface ProviderModalForm {
	providerName: string
	doingBusinessAs?: string
	street1: string
	street2?: string
	city: string
	state: string
	zipCode: string
	country: string
}

const defaultProviderModalForm: ProviderModalForm = {
	providerName: null,
	doingBusinessAs: null,
	street1: null,
	street2: null,
	city: null,
	state: null,
	zipCode: null,
	country: 'US',
}

export const AddProviderModal: FC = (props: {
	isOpen: boolean
	onClose: () => void
}) => {
	const [addProviderMode, setAddProviderMode] = useState<AddProviderModalMode>(
		'search',
	)

	const [providerCreateForm, setProviderCreateForm] = useState<
		ProviderModalForm
	>(defaultProviderModalForm)

	const onClose = (): void => {
		setAddProviderMode('search')
		props.onClose()
	}

	return (
		<Modal
			content={() => (
				<div
					className={addClass('bootstrap-wrapper', classes.addProviderModal)}
				>
					{addProviderMode === 'search' && (
						<AddProviderModalSearch
							onChangeMode={setAddProviderMode}
							onClose={onClose}
							tPrefix={tPrefixProvidersAdd}
						/>
					)}
					{addProviderMode === 'warning' && (
						<AddProviderModalWarning
							onChangeMode={setAddProviderMode}
							onClose={onClose}
							tPrefix={tPrefixProvidersAdd}
						/>
					)}
					{addProviderMode === 'form' && (
						<AddProviderModalForm
							onChangeMode={setAddProviderMode}
							onClose={onClose}
							providerCreateForm={providerCreateForm}
							setProviderCreateForm={setProviderCreateForm}
						/>
					)}
					{addProviderMode === 'confirm' && (
						<AddProviderModalConfirm
							onClose={onClose}
							providerCreateForm={providerCreateForm}
						/>
					)}
				</div>
			)}
			isOpen={props.isOpen}
			onModalClose={onClose}
			title={t('addNewProvider', tPrefixProvidersAdd)}
			closeModalX={true}
		/>
	)
}
