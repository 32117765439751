import { elasticSearchBuilder } from 'ui/lib/elasticSearch'
import { apiTypes, apiDockScheduler } from 'ui/api'

export const fetchAppointmentsForLocationOnDay = async (
	locationId: string,
	date: string, // ISO DateString
): Promise<apiTypes.AppointmentResponse[]> => {
	const qb = elasticSearchBuilder()
	qb.and('locationId', locationId)
	qb.and('startTime', date)
	const query = qb.toString()
	let appointments: apiTypes.AppointmentResponse[] = []
	const take = 100
	let appointmentListResponse = await apiDockScheduler.fetchAppointments(
		() => {},
		{
			take,
			skip: 0,
			query,
		},
	)
	if (appointmentListResponse.data) {
		appointments = appointmentListResponse.data.entities
		let pageNumber = 1
		while (appointmentListResponse.data.pageCount > pageNumber) {
			appointmentListResponse = await apiDockScheduler.fetchAppointments(
				() => {},
				{
					take,
					skip: pageNumber * take,
					query,
				},
			)
			appointments = appointments.concat(appointmentListResponse.data.entities)
			pageNumber++
		}
	}
	return appointments
}
