import { FC } from 'app/FunctionalComponent'
import React from 'react'
import styles from './Popup.module.scss'

export const RelativeExample: FC = (props: {}) => {
	// const el = useRef<HTMLDivElement>(null)

	return (
		<div className={styles.relativeExample}>
			<div className={styles.example + ' ' + styles.topLeft}>top left</div>
			<div className={styles.example + ' ' + styles.topRight}>top right</div>
			<div className={styles.example + ' ' + styles.bottomLeft}>
				bottom left
			</div>
			<div className={styles.example + ' ' + styles.bottomRight}>
				bottom right
			</div>
		</div>
	)
}
