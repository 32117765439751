import { FC } from 'app/FunctionalComponent'
import { AsyncTypeahead } from 'common/components/typeahead/AsyncTypeahead'
import { TypeaheadOption } from 'common/components/typeahead/TypeaheadOption'
import React, { useEffect, useState } from 'react'
import { apiLocation } from 'ui/api'
import { Loader } from 'ui/components/common/loader'
import { tString } from 'ui/components/i18n/i18n'
import { elasticSearchBuilder } from 'ui/lib/elasticSearch'
import { sos2 } from 'ui/lib/state/sos2'
import { DefaultAddressType } from 'ui/pages/new-quote/state/newQuoteUtils/locationDropdownUtils'
import { sosUser } from 'ui/state'
import { isInTMS2 } from 'ui/theme/theme'
import { LocationDropdownSourceType } from '../address/IAddressState'
import { getLocations } from './locationSelectorUtils'
import { l } from 'ui/lib/lodashImports'

const tPrefix = 'shared.locationSelector'
const testId = 'location-selector'

export const LocationSelector: FC = (props: {
	onChange?: (selected: TypeaheadOption) => void
	preSearch?: () => void
	postSearch?: () => void
	currentlySelectedLocation?: TypeaheadOption
	includeAll?: boolean
	locationsSource?: LocationDropdownSourceType
	neededDefaultAddressType?: DefaultAddressType
	onlyShowLeafLocations: boolean
	getBrokerLocations?: boolean
	isReadOnly?: boolean
}) => {
	const {
		currentlySelectedLocation,
		locationsSource,
		neededDefaultAddressType,
		onlyShowLeafLocations,
		isReadOnly,
	} = props

	const userState = sos2.useSubscription(sosUser.getSos())

	const [location, setLocation] = useState<TypeaheadOption>()

	useEffect(() => {
		if (currentlySelectedLocation) {
			const option = {
				label:
					currentlySelectedLocation.label || currentlySelectedLocation.value,
				value: currentlySelectedLocation.value,
			}
			setLocation(option)
		}
	}, [currentlySelectedLocation])

	const allLocationTranslationKey = isInTMS2()
		? 'iframeAllManagedLocations'
		: 'allLocations'

	const locations = getLocations(
		userState,
		locationsSource,
		neededDefaultAddressType,
	)

	if (locations?.isFetching) {
		return <Loader isLoading={true} />
	}

	const locationOptions: TypeaheadOption[] = (
		locations.data?.splice(0, 10) || []
	).map((c) => ({
		value: c.value,
		label: c.label || c.value,
	}))

	if (props.includeAll) {
		locationOptions.unshift({
			value: '',
			label: tString(allLocationTranslationKey, tPrefix),
		})
	}

	const clientConfigId =
		// sosUser.isUserBroker() &&
		// !getBrokerLocations &&
		sosUser.getCurrentClientConfig()?.id

	return (
		<AsyncTypeahead
			testId={testId}
			size='small'
			width='300px'
			options={locationOptions}
			onSearch={async (searchTerm: string): Promise<TypeaheadOption[]> => {
				if (props.preSearch) {
					props.preSearch()
				}
				const qb = elasticSearchBuilder()
				qb.andOr([
					['name', '*' + searchTerm + '*'],
					['organizationCode', '*' + searchTerm + '*'],
				])
				if (onlyShowLeafLocations) {
					qb.andNot('_exists_', 'allDescendantOrganizationsIds')
				}
				const response = await apiLocation.fetchLocations((rs) => {}, {
					take: 10,
					query: qb.toString(),
					clientConfigId: clientConfigId || '',
				})

				let responseOptions: TypeaheadOption[] = []
				if (response.data) {
					sosUser.addLocationsToMemory(response.data, !l.isNil(clientConfigId))
					responseOptions = response.data.map((c) => ({
						value: c.id,
						label: c.name || c.id,
					}))
				}

				if (props.includeAll) {
					responseOptions.unshift({
						value: '',
						label: tString(allLocationTranslationKey, tPrefix),
					})
				}

				if (props.postSearch) {
					props.postSearch()
				}

				return responseOptions
			}}
			onChange={(value: TypeaheadOption) => {
				setLocation(value)
				props.onChange(value)
			}}
			value={location}
			defaultSelected={currentlySelectedLocation?.label}
			placeholder={tString('selectALocation', tPrefix)}
			makeInitialFetch={true}
			disabled={isReadOnly}
		/>
	)
}
