import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { DevTools } from 'ui/components/dev/dev-tools/DevTools'
import { FlexItem, FlexRow } from 'ui/components/layout/flexRow'
import { Sidebar } from 'ui/components/layout/sidebar'
import { links, linksBroker } from 'ui/pages/layout/links'
import { sosUser } from 'ui/state'
import { theme } from 'ui/theme'
import { Header } from './Header'
import classes from './Layout.module.scss'

export const Layout: FC = (props: {
	children: React.ReactNode
	hideDefaultSidebar?: boolean
	fullWidth?: boolean
	bootstrapMargins?: boolean
}) => {
	let className = classes.layoutTms3
	if (theme.isInTMS2()) {
		className = classes.layoutTms2
	}

	const loginMode = sosUser.getLoginMode()
	const isUserBroker = sosUser.isUserBroker()

	const hasSidebar = !theme.isInTMS2() && !props.hideDefaultSidebar
	return (
		<div>
			{!theme.isInTMS2() && <Header />}
			<FlexRow fullWidth={true} wrap={false}>
				{!props.bootstrapMargins && hasSidebar && (
					<FlexItem fitContent={true}>
						<Sidebar
							links={isUserBroker ? linksBroker : links}
							loginMode={loginMode}
						/>
					</FlexItem>
				)}
				<div className={classes.mainPanel}>
					{props.bootstrapMargins && hasSidebar && (
						<FlexItem fitContent={true}>
							<Sidebar
								links={isUserBroker ? linksBroker : links}
								loginMode={loginMode}
							/>
						</FlexItem>
					)}
					<FlexItem>
						<div className={className}>
							{props.children}
							{theme.isInDev() && <DevTools />}
						</div>
					</FlexItem>
				</div>
			</FlexRow>
		</div>
	)
}
