import { apiCommon, apiTypes } from 'ui/api'
import { IRequestState } from 'ui/api/requestState'

export const getRfpShipments = async (
	onProgress: (rs: IRequestState<apiTypes.RfpShipmentListResponse>) => void,
	query: { take: number; skip: number; query: string },
	entireResponse = false,
): Promise<IRequestState<apiTypes.RfpShipmentListResponse>> => {
	const response = await apiCommon.apiFetch(
		onProgress,
		{
			method: 'GET',
			entireResponse,
		},
		'rfp/shipments',
		query,
	)
	return response
}

export async function rateRfpShipments(
	shipmentIDs: string[],
	onProgress: (rs: IRequestState<apiTypes.RfpShipmentListResponse>) => void,
	entireResponse = true,
): Promise<IRequestState<apiTypes.RfpShipmentListResponse>> {
	const response = await apiCommon.apiFetch(
		onProgress,
		{
			method: 'POST',
			data: { ids: shipmentIDs },
			entireResponse,
		},
		'rfp/shipments/quote',
	)
	return response
}

export const createRfpShipments = async (
	fileData: apiTypes.RfpShipmentListRequest,
	onProgress: (
		rs: IRequestState<apiTypes.RfpShipmentBulkCreateResponse>,
	) => void,
): Promise<IRequestState<apiTypes.RfpShipmentBulkCreateResponse>> => {
	const response = await apiCommon.apiFetch(
		onProgress,
		{ method: 'POST', data: fileData },
		'rfp/shipments/bulk',
	)
	return response
}

export const exportData = async (
	email: string,
	rfpId: string,
	onProgress: (rs: IRequestState<void>) => void,
): Promise<IRequestState<void>> => {
	const response = await apiCommon.apiFetch(
		onProgress,
		{ method: 'POST', data: { email } },
		'rfp/shipments/export',
		{ rfpId },
	)
	return response
}

export const getRfpProjects = async (
	onProgress: (rs: IRequestState<apiTypes.RfpProjectsListResponse>) => void,
	query?: { take?: number },
	entireResponse = false,
): Promise<IRequestState<apiTypes.RfpProjectsListResponse>> => {
	const response = await apiCommon.apiFetch(
		onProgress,
		{
			method: 'GET',
			entireResponse,
		},
		'rfp/projects',
		query,
	)
	return response
}

export const createRfpProject = async (
	onProgress: (rs: IRequestState<apiTypes.RfpProjectResponse>) => void,
	rfpProjectRequest: apiTypes.RfpProjectRequest,
): Promise<IRequestState<apiTypes.RfpProjectResponse>> => {
	const response = await apiCommon.apiFetch(
		onProgress,
		{
			method: 'POST',
			data: rfpProjectRequest,
		},
		'rfp/projects',
	)
	return response
}

export const updateRfpProject = async (
	onProgress: (rs: IRequestState<apiTypes.RfpProjectResponse>) => void,
	rfpProjectId: string,
	rfpProjectRequest: apiTypes.RfpProjectRequest,
): Promise<IRequestState<apiTypes.RfpProjectResponse>> => {
	const response = await apiCommon.apiFetch(
		onProgress,
		{
			method: 'PUT',
			data: rfpProjectRequest,
		},
		'rfp/projects/' + rfpProjectId,
	)
	return response
}
