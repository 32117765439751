import { apiTypes, apiShipments } from 'ui/api'
import {
	MarkerStatus,
	MarkerDirection,
} from '../components/controlTowerMapIcons'
import { l } from 'ui/lib/lodashImports'
import { setMapMarkers } from './sosControlTowerMap'
import { DateTime } from 'luxon'
import { buildQuery, Modes, Flows } from '../../common/controlTowerUtils'
import { isInTMS2 } from 'ui/theme/theme'
import { tms2_common } from 'ui/lib'
import { LeafletMapStop } from 'ui/components/shared/leaflet-map/utils/leafletMapStop'

export interface MapMarker {
	lat: number
	lng: number
	markerType?: apiTypes.ShipmentResponse['mode']
	markerStatus?: MarkerStatus
	markerDirection?: MarkerDirection
	shipmentId?: string
	shipmentNumber?: string
	proNumber?: string
	carrier?: string
	payloads?: apiTypes.PayloadResponse[]
	stopsInOrder?: LeafletMapStop[]
}

export interface Coordinates {
	latitude: string
	longitude: string
}

export const calculateLatLngMidpoint = (
	firstPoint: Coordinates,
	secondPoint: Coordinates,
): { latitude: number; longitude: number } => {
	if (!firstPoint || !secondPoint) {
		return undefined
	}

	return {
		latitude: (Number(firstPoint.latitude) + Number(secondPoint.latitude)) / 2,
		longitude:
			(Number(firstPoint.longitude) + Number(secondPoint.longitude)) / 2,
	}
}

export const _findCurrentPayloadForMultiStop = (
	payloads: apiTypes.PayloadResponse[],
	stopSequence: string[],
): apiTypes.PayloadResponse => {
	let currentPayload: apiTypes.PayloadResponse
	for (const addressHash of stopSequence) {
		const payload = l.find(
			payloads,
			(p) => p.destinationStop.metaData?.addressHash === addressHash,
		)
		if (
			payload &&
			l.isNil(payload.destinationStop.metaData?.actualDateTimeInfo?.initialDate)
		) {
			currentPayload = payload
			break
		}
	}

	return currentPayload
}

export const _getMarkerPositionAndStatus = (
	payloads: apiTypes.PayloadResponse[],
	stopSequence: string[],
): {
	lat: number
	lng: number
	status: MarkerStatus
	direction: MarkerDirection
} => {
	const payload =
		payloads?.length > 1
			? _findCurrentPayloadForMultiStop(payloads, stopSequence)
			: payloads[0]

	if (!payload) {
		return { lat: undefined, lng: undefined, status: null, direction: null }
	}

	const today = DateTime.local().endOf('day')
	let lat: number, lng: number, status: MarkerStatus
	if (
		payload.originStop.metaData?.desiredDateTimeInfo?.initialDate &&
		l.isNil(payload.originStop.metaData?.actualDateTimeInfo?.initialDate)
	) {
		//not picked up yet
		const desiredPickupTime = DateTime.fromISO(
			payload.originStop.metaData.desiredDateTimeInfo.initialDate,
		)
		if (today > desiredPickupTime) {
			//late for pickup
			status = 'late'
		} else {
			status = 'normal'
		}

		lat = Number(payload.originStop.metaData?.coordinates?.latitude)
		lng = Number(payload.originStop.metaData?.coordinates?.longitude)
	} else if (
		payload.originStop.metaData?.actualDateTimeInfo?.initialDate &&
		l.isNil(payload.destinationStop.metaData?.actualDateTimeInfo?.initialDate)
	) {
		//in transit
		const desiredDeliveryTime = DateTime.fromISO(
			payload.destinationStop.metaData?.desiredDateTimeInfo?.initialDate,
		)
		const desiredPickupTime = DateTime.fromISO(
			payload.originStop.metaData?.desiredDateTimeInfo?.initialDate,
		)
		const actualPickupTime = DateTime.fromISO(
			payload.originStop.metaData?.actualDateTimeInfo?.initialDate,
		)

		if (today > desiredDeliveryTime || actualPickupTime > desiredPickupTime) {
			//late
			status = 'late'
		} else {
			status = 'normal'
		}

		const midpoint = calculateLatLngMidpoint(
			payload.originStop.metaData?.coordinates,
			payload.destinationStop.metaData?.coordinates,
		)
		lat = midpoint?.latitude
		lng = midpoint?.longitude
	} else {
		//delivered
		const desiredDeliveryTime = DateTime.fromISO(
			payload.destinationStop.metaData?.desiredDateTimeInfo?.initialDate,
		)
		const actualDeliveryTime = DateTime.fromISO(
			payload.destinationStop.metaData?.actualDateTimeInfo?.initialDate,
		)

		if (actualDeliveryTime > desiredDeliveryTime) {
			status = 'late'
		} else {
			status = 'success'
		}

		lat = Number(payload.destinationStop.metaData?.coordinates?.latitude)
		lng = Number(payload.destinationStop.metaData?.coordinates?.longitude)
	}

	const direction =
		payload.destinationStop.metaData?.coordinates?.longitude <
		payload.originStop.metaData?.coordinates?.longitude
			? 'reversed'
			: 'normal'

	return { lat, lng, status, direction }
}

export const _sortAddressesByStopSequence = (
	payloads: apiTypes.PayloadResponse[],
	stopSequence: string[],
): LeafletMapStop[] => {
	const sortedStops: LeafletMapStop[] = []
	for (const addressHash of stopSequence) {
		for (const payload of payloads) {
			if (payload.originStop?.metaData?.addressHash === addressHash) {
				sortedStops.push({
					...payload.originStop.address,
					markerColor: 'green',
					lat: Number(payload.originStop.metaData.coordinates?.latitude),
					lng: Number(payload.originStop.metaData.coordinates?.longitude),
				})
				break
			}

			if (payload.destinationStop?.metaData?.addressHash === addressHash) {
				sortedStops.push({
					...payload.destinationStop.address,
					markerColor: 'red',
					lat: Number(payload.destinationStop.metaData.coordinates?.latitude),
					lng: Number(payload.destinationStop.metaData.coordinates?.longitude),
				})
				break
			}
		}
	}

	return sortedStops
}

export const parseShipmentsToMapMarkers = (
	shipments: apiTypes.ShipmentResponse[],
): MapMarker[] => {
	const mapMarkers: MapMarker[] = []

	l.forEach(shipments, (shipment) => {
		const { lat, lng, status, direction } = _getMarkerPositionAndStatus(
			shipment.payloads,
			shipment.stopSequence,
		)

		if (!l.isNil(lat) && !l.isNil(lng) && !isNaN(lat) && !isNaN(lng)) {
			const marker: MapMarker = {
				lat,
				lng,
				markerType: shipment.mode,
				markerStatus: status,
				markerDirection: direction,
				carrier: shipment.bookedRate?.carrier,
				shipmentId: shipment.id,
				shipmentNumber: shipment.identifier,
				proNumber: shipment.proNumber,
				payloads: l.cloneDeep(shipment.payloads),
				stopsInOrder: _sortAddressesByStopSequence(
					shipment.payloads,
					shipment.stopSequence,
				),
			}

			mapMarkers.push(marker)
		}
	})

	return mapMarkers
}

export const fetchShipments = async (
	startDate: Date,
	endDate: Date,
	locationId: string,
	providerId: string,
	modeFilter: Modes,
	flowFilter: Flows,
): Promise<void> => {
	const queryObj = buildQuery(
		DateTime.fromJSDate(startDate),
		DateTime.fromJSDate(endDate),
		isInTMS2()
			? await tms2_common.currently_managed_organization_ids()
			: [locationId],
		providerId,
		modeFilter,
		flowFilter,
	)
	const query = queryObj.query
	const nestedFieldQuery = queryObj.nestedFieldQuery
	let response = await apiShipments.fetchShipments(() => {}, {
		take: 100,
		sort: 'createdDate:asc',
		query,
		nestedFieldQuery,
	})
	if (response.data) {
		let markers: MapMarker[] = parseShipmentsToMapMarkers(
			response.data.entities,
		)
		setMapMarkers(markers)

		const numberOfNextRequests = Math.floor(response.data.total / 100)

		for (let i = 1; i <= numberOfNextRequests; i++) {
			response = await apiShipments.fetchShipments(() => {}, {
				take: 100,
				skip: i * 100,
				sort: 'createdDate:asc',
				query,
				nestedFieldQuery,
			})

			if (response.data) {
				const newMarkers = parseShipmentsToMapMarkers(response.data.entities)
				markers = markers.concat(newMarkers)
				setMapMarkers(markers)
			}
		}
	}
}
