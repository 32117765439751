import { IAddress } from 'ui/components/shared/address/IAddress'
import { apiTypes } from 'ui/api'

export interface IRate {
	shipmentI?: string
	hashKey?: string
	rangeKey?: string
	id?: string
	locationId?: string
	userId?: string
	createdDate?: string
	companyId?: string
	providerApiCredentialsId?: string
	shipmentDocumentId?: string
	currencyCode?: string
	rateGeneratedDate?: Date
	isCalculatedRate?: boolean
	isIncumbentRate?: boolean
	isMostRelevant?: boolean
	method?: string
	shippingMode?: {
		humanReadable?: string
		truckload?: boolean
		parcel?: boolean
		ltl?: boolean
	}
	rateOwner?: string
	carrier?: string
	carrierHuman?: string
	carrierId?: string
	customerCarrierNumber?: string
	brokerCarrier?: string
	serviceLevel?: string
	serviceHuman?: string
	accountNumber?: string
	invoiceNumber?: string
	scac?: string
	delivery?: string
	transit?: number
	estimatedCalenderDaysTransit?: number
	providerId?: string
	providerName?: string //this is ProviderName in API
	sort?: number
	rateCheck?: string
	priority?: number
	zone?: string
	excludeFromSavings?: boolean
	cost?: {
		base?: string
		markup?: string
		total?: string
	}
	costCents?: {
		base?: number
		markup?: number
		total?: number
	}
	pickupDate?: string
	nickname?: string
	billTo?: IAddress
	brokerApi?: string
	brokerName?: string
	brokerNameHuman?: string
	quoteId?: string
	quoteNumber?: string
	carrierPhone?: string
	retailRate?: string
	lowestRate?: boolean
	guaranteed?: string
	guaranteedTime?: boolean
	fuelCalculationBreakdown?: {
		fuelCostType?: string
		fuelCostRate?: number
		fuelCostMultiplier?: number
	}
	costBreakdown?: any
	readonly costTotal?: number
	readonly accessorialCostTotal?: number
	customerFees?: {
		auditingFee?: number
		technologyFee?: number
		consultingFee?: number
	}
	clearviewFees?: {
		freight?: {
			auditingFeeEdiFlat?: number
			auditingFeePaperFlat?: number
			technologyFeeFlat?: number
			consultingFeePercent?: number
			consultingFeeCalculated?: number
			documentFee?: number
		}
		billableDocumentTypes?: {
			new?: boolean
			billOfLading?: boolean
			invoice?: boolean
			correctedInvoice?: boolean
			invoiceSummary?: boolean
			proofOfDelivery?: boolean
			claims?: boolean
			disputeForm?: boolean
			other?: boolean
		}
		parcel?: any
		auditingFee?: number
		technologyFee?: number
		consultingFee?: number
		documentFee?: number
		inhibitRecalculation?: boolean
		clientMarkup?: {
			baseProductCost?: number
			freightMarkupCost?: number
		}
		totalWithFees?: number
		transactionalCosts?: {
			agentName?: string
			auditingFee?: number
			technologyFee?: number
			consultingFee?: number
			processingCharge?: number
		}
		fullValueCoverage?: number
	}
	parcelInvoice?: {
		total?: number
		totalBeforeGsr?: number
		grossRate?: number
		fuel?: number
		other?: number
		discount?: number
		guaranteedServiceRefund?: number
	}
	mcNumber?: string
	stopOffCharge?: number
	stopOffChargeType?: string
	invoiceAuditNotes?: string
	matchingLanes?: any
	connectionId?: string
	companyName?: string
	contactName?: string
	email?: string
	phone?: string
	clientId?: string
	offerAmount?: string
	sortableOfferAmount?: number
	sortableTransitDays?: number
	offerStatus?: string
	connectionIsActive?: boolean
	connectionType?: {
		carrier?: boolean
		freightForwarder?: boolean
		broker?: boolean
	}
	allowedEquipmentType?: {
		value?: boolean
	}
	invited?: boolean
	source?: string
	offerCreatedTimestamp?: Date
	offerAwardedTimestamp?: Date
	offerFinalizedTimestamp?: Date
	estimatedDistance?: number
	ratedWeight?: number
	minimumCharge?: number
	linehaulCostType?: string
	linehaulCost?: number
	fuelCostType?: string
	fuelCost?: number
	transitDays?: number
	notes?: string
	contractId?: string
	quoteType?: {
		humanReadable?: string
		spotQuote?: boolean
		contract?: boolean
		loggedOffer?: boolean
		webRate?: boolean
		vendorQuote?: boolean
	}
	expirationDate?: Date
	carrierSortFacility?: string
	carrierRoute?: string
	configId?: string
	apiConfirmationInformation?: {
		carrierConfirmationNumber?: string
		serviceGroupId?: string
		carrierId?: string
		upgradeHash?: string
	}
	customFields?: any

	//setIds(shipmentId?: string, companyId?: string, locationId?: string): void
}

export const createDefaultRate = (): apiTypes.RateResponse => {
	return {
		id: '',
		contractId: '',
		providerId: '',
		offerId: '',
		updateUrl: '',
		providerName: null,
		costBreakdown: {},
	}
}
