import { sosUser } from 'ui/state'
import { apiBroker, apiShipments, apiTypes } from '.'
import { BridgeShipmentResponseType } from 'ui/api/apiBrokerAndNonBrokerBridgeTypes'

export const upsertShipment = async (
	id: string,
	shipmentRequest: apiTypes.ShipmentRequest | apiTypes.BrokerShipmentRequest,
	getRates: boolean,
	clientConfigId: string,
	book?: boolean
): Promise<BridgeShipmentResponseType> => {
	let shipmentResponse: BridgeShipmentResponseType
	if (sosUser.isUserBroker()) {
		if (id) {
			shipmentResponse = await apiBroker.updateBrokerShipment(
				() => {},
				id,
				shipmentRequest,
				clientConfigId,
				false,
			)
		} else {
			shipmentResponse = await apiBroker.createBrokerShipment(
				() => {},
				shipmentRequest,
				clientConfigId,
				getRates,
			)
		}
	} else {
		const params = {
			book,
		}
		if (book) {
			if (shipmentRequest.autoRateCarrier) {
				params['providerName'] = shipmentRequest.autoRateCarrier
			}

			if (shipmentRequest.autoRateCarrier) {
				params['serviceLevel'] = shipmentRequest.autoRateService
			}
		}

		// console.log(params, shipmentRequest)
		// return
		shipmentResponse = await apiShipments.createShipment(
			shipmentRequest,
			!book,
			() => {},
			params
		)

		// if (id) {
		// 	shipmentResponse = await apiShipments.updateShipment(
		// 		id,
		// 		shipmentRequest,
		// 		() => {},
		// 	)
		// } else {
		// 	shipmentResponse = await apiShipments.createShipment(
		// 		shipmentRequest,
		// 		!book,
		// 		() => {}
		// 	)
		// }
	}

	return shipmentResponse
}
