import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { Card } from 'ui/components/common/card/Card'
import { Spacer } from 'ui/components/layout/spacer/Spacer'
import { sosComponentExamples } from 'ui/state/'
import { IStateComponentExamples } from 'ui/state/sosComponentExamples'

export const Section: FC = (props: {
	title: string | React.ReactNode
	children: React.ReactNode
	stateComponentExamples: IStateComponentExamples
	name: string
}) => {
	return (
		<div>
			<Card
				color='blue'
				title={props.title}
				isOpen={props.stateComponentExamples.openSection === props.name}
				onToggle={() => {
					sosComponentExamples.toggleSection(props.name)
				}}
			>
				{props.children}
			</Card>
			<Spacer />
		</div>
	)
}
