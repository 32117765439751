import { apiTypes } from 'ui/api'
import { l } from 'ui/lib/lodashImports'

export const getMarketRateFromRateRows = (
	shipment: apiTypes.BrokerShipmentResponse,
): apiTypes.RateResponse => {
	return l.find(
		shipment.rates,
		(rate) =>
			rate.providerName ===
			('truckstop.com' as apiTypes.RateResponse['providerName']),
	)
}
