import { FC } from 'app/FunctionalComponent'
import React, { createContext, useContext, useEffect } from 'react'
import { Toast as Toasted, Alert as Alerted } from 'react-bootstrap'

import './Toast.scss'

// --------------- Create Toast Context
export const ToastContext = createContext(null)

export type Variant =
	| 'primary'
	| 'secondary'
	| 'success'
	| 'danger'
	| 'warning'
	| 'info'
	| 'dark'
	| 'light'

export type ToastState = {
	key?: number
	type?: Variant
	header?: string
	body?: React.ReactNode
	autoClose?: boolean
	dataTestId?: string
}

interface DispatchArgs {
	type: string
	payload: ToastState | { toast: ToastState }
}

export type ToastDispatch = (dispatchAction: DispatchArgs) => void

const ADD = 'ADD'
const REMOVE = 'REMOVE'

export function ToastReducer(state: ToastState[], action): ToastState[] {
	const key = Date.now()
	switch (action.type) {
		case ADD:
			action.payload.toast.key = key
			return [...state, action.payload.toast]
		case REMOVE:
			return state.filter((t: ToastState) => action.payload.key !== t.key)
		default:
			return state
	}
}

function addToast(toast: ToastState): DispatchArgs {
	return {
		type: ADD,
		payload: {
			toast: toast,
		},
	}
}

function removeToast(key: number): DispatchArgs {
	return {
		type: REMOVE,
		payload: {
			key: key,
		},
	}
}

// --------------- Toast Components
// <ToastWrapper /> used once at top-level
// Use <Toast toast={toastBody} /> to create toasts
// Use toast(useContext(ToastContext, toastBody)) to create tests

export const ToastWrapper: FC = (props: { toasts: ToastState[] }) => {
	return (
		<div
			className='toast-wrapper bootstrap-wrapper'
			id='toast-wrapper'
			data-testid='toast-wrapper'
			aria-live='polite'
			aria-atomic='true'
		>
			{props.toasts &&
				props.toasts.map((t: ToastState) => <SlToast key={t.key} toast={t} />)}
		</div>
	)
}

export function toast(dispatch: ToastDispatch, toast: ToastState): void {
	dispatch(addToast(toast))

	setTimeout(
		() => {
			dispatch(removeToast(toast.key))
		},
		toast.type === 'success' || toast.autoClose ? 5000 : 30000,
	)
}

export const Toast: FC = (props: { toast: ToastState }) => {
	const dispatch = useContext(ToastContext)
	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => {
		const toastBody = Object.assign({}, props.toast)
		toast(dispatch, toastBody)
	}, [])

	return null
}

export function deleteToast(dispatch, key: number): void {
	dispatch(removeToast(key))
}

// --------------- Private Toast types
const SlToast: FC = (props: { toast: ToastState }) => {
	return (
		<div data-test-id={props.toast.dataTestId}>
			{!props.toast.type ? (
				<Toaster toast={props.toast} />
			) : (
				<Alerter toast={props.toast} />
			)}
		</div>
	)
}

const Toaster: FC = (props: { toast: ToastState }) => {
	const dispatch = useContext(ToastContext)
	const { key, header, body } = props.toast
	const toast = header ? (
		<Toasted
			show={true}
			onClose={() => dispatch(removeToast(key))}
			className='shadow-sm'
		>
			<Toasted.Header>
				<strong className='mr-auto'>{header}</strong>
			</Toasted.Header>
			<Toasted.Body>{body}</Toasted.Body>
		</Toasted>
	) : (
		<Toasted
			show={true}
			onClose={() => dispatch(removeToast(key))}
			delay={1000}
			className='shadow-sm'
			autohide
		>
			<Toasted.Body>{body}</Toasted.Body>
		</Toasted>
	)
	return toast
}

const Alerter: FC = (props: { toast: ToastState }) => {
	const dispatch = useContext(ToastContext)
	const { key, type, header, body } = props.toast
	return (
		<>
			<Alerted
				key={key}
				variant={type}
				className='shadow-sm'
				show={true}
				onClose={() => dispatch(removeToast(key))}
				dismissible
			>
				{header && <Alerted.Heading>{header}</Alerted.Heading>}
				{header && body && <hr />}
				{body && <>{body}</>}
			</Alerted>
		</>
	)
}
