import { callTms2Global } from './IframeRpc'

const tms2_quickRate = {
	quickRate_createQuickRateShipment: async () => {
		return await callTms2Global<{
			parent_organization_id: string
			shipment_id: string
			list_of_locations: any[]
		}>('quickRate_createQuickRateShipment')
	},
	quickRate_deleteQuickRateShipment: async (shipmentId) => {
		return await callTms2Global<void>('quickRate_deleteQuickRateShipment')
	},
	quickRate_updateLocationParentOrganizationId: async (
		shipmentId,
		locationParentOrganizationId,
	) => {
		return await callTms2Global<void>(
			'quickRate_updateLocationParentOrganizationId',
			shipmentId,
			locationParentOrganizationId,
		)
	},
	quickRate_updateQuickRateShipmentData: async (shipmentId, data) => {
		return await callTms2Global<any>(
			'quickRate_updateQuickRateShipmentData',
			shipmentId,
			data,
		)
	},
}
export { tms2_quickRate }
