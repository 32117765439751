import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { FilterItem, Filters } from 'ui/components/common/filters'
import { t } from 'ui/components/i18n/i18n'
import { l } from 'ui/lib/lodashImports'
import { TPaymentStatusFilter } from 'ui/pages/provider-portal/providerPortalTypes'

const paymentStatusFilters: TPaymentStatusFilter[] = [
	'all',
	'waitingOnInvoice',
	'undergoingAudit',
	'clearedToPay',
	'disputed',
	'fundingRequested',
	'fundingReceived',
	'paid',
]

const tPrefix = `page.providerPortal.paymentStatusFilters`

export const PaymentStatusFilter: FC = (props: {
	selected: string
	onChange: (newVal: TPaymentStatusFilter) => void
}) => {
	return (
		<Filters>
			{l.map(paymentStatusFilters, (c) => (
				<FilterItem
					key={c}
					onClick={() => props.onChange(c)}
					isSelected={props.selected === c}
				>
					{t(c, tPrefix)}
				</FilterItem>
			))}
		</Filters>
	)
}
