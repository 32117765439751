import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { ValidatedSubmitButton } from 'ui/components/common/validated-submit-button'
import { FormVertical } from 'ui/components/form/FormVertical'
import { FormVerticalLayout } from 'ui/components/form/FormVerticalLayout'
import { FormVerticalReadOnly } from 'ui/components/form/FormVerticalReadOnly'
import { FormVerticalTextInput } from 'ui/components/form/FormVerticalTextInput'
import { IFormData } from 'ui/components/form/IFormData'
import { t } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight/AlignRight'
import {
	IPackageCatalogForm,
	packageCatalogFormMetadata,
} from 'ui/forms/formPackageCatalog'
import { validateForm } from 'ui/lib/validation/formValidator'
import { sosPackageCatalog } from 'ui/state'

export const UpdatePackageCatalogForm: FC = (props: {
	mode: 'add' | 'edit'
	form: IPackageCatalogForm
}) => {
	const formData: IFormData<IPackageCatalogForm> = {
		form: props.form,
		metadata: packageCatalogFormMetadata,
		onUpdateForm: sosPackageCatalog.updateBookForm,
		tPrefix: 'page.managePackageCatalogs.bookForm',
	}

	const validationResult = validateForm(formData.form, formData.metadata)

	return (
		<div>
			<FormVertical>
				<FormVerticalLayout>
					{props.mode === 'edit' && (
						<FormVerticalReadOnly formData={formData} field='id' />
					)}

					<FormVerticalTextInput formData={formData} field='name' />
				</FormVerticalLayout>
				<AlignRight>
					<ValidatedSubmitButton
						testId='submit-upsert-book'
						onClick={async () => {
							await sosPackageCatalog.updateBook('upsert')
						}}
						isValid={validationResult.isValid}
					>
						{t(`page.managePackageCatalogs.${props.mode}Book`)}
					</ValidatedSubmitButton>
				</AlignRight>
			</FormVertical>
		</div>
	)
}
