import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { apiTypes } from 'ui/api'
import { AngryRedBox } from 'ui/components/common/angry-red-box'
import { Modal } from 'ui/components/common/modal'
import { OkCancelButtons } from 'ui/components/common/okCancelButtons'
import { ISelectOptions } from 'ui/components/common/select'
import {
	FormStackedSelect,
	FormStackedTextInput,
	IFormData,
} from 'ui/components/form'
import {
	tEquipmentType,
	tHandlingUnitType,
	tMode,
	tStopType,
} from 'ui/components/i18n/commonTranslations'
import { t } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight'
import { FlexItem, FlexRow } from 'ui/components/layout/flexRow'
import { validateForm } from 'ui/lib/validation/formValidator'
import { containerTypes } from '../../new-quote'
import * as classes from '../DockScheduler.module.scss'

export interface IFormCreateAppointmentWithoutShipment {
	carrier: string
	otherCompanyNames: string
	proNumber: string
	poNumbers: string
	soNumbers: string
	mode: apiTypes.ShipmentResponse['mode']
	flow: apiTypes.AppointmentResponse['stopType']
	equipmentType: apiTypes.ShipmentResponse['equipmentType']
	handlingUnits: number
	handlingUnitType: apiTypes.Container['type']
	weight: number
}

const tPrefix = 'page.dockScheduler.appointmentWithoutShipmentCreate'

export const AppointmentWithoutShipmentCreateModal: FC = (props: {
	isOpen: boolean
	appointmentCreateForm: IFormCreateAppointmentWithoutShipment
	onSubmit: () => void
	onModalClose: () => void
	onUpdateForm: (
		changes: Partial<IFormCreateAppointmentWithoutShipment>,
	) => void
	errorText: string
	submitting: boolean
}) => {
	const formData: IFormData<IFormCreateAppointmentWithoutShipment> = {
		form: props.appointmentCreateForm,
		onUpdateForm: (
			field: keyof IFormCreateAppointmentWithoutShipment,
			newVal: any,
		) => {
			const changes = {}
			changes[field] = newVal
			props.onUpdateForm(changes)
		},
		tPrefix,
		metadata: {
			carrier: { required: false },
			otherCompanyNames: { required: false },
			proNumber: { required: false },
			poNumbers: { required: false },
			soNumbers: { required: false },
			mode: {
				required: true,
				options: [
					{ label: tMode('ltl'), value: 'ltl' },
					{ label: tMode('volume'), value: 'volume' },
					{ label: tMode('truckload'), value: 'truckload' },
					{ label: tMode('ocean'), value: 'ocean' },
					{ label: tMode('air'), value: 'air' },
				],
			},
			flow: {
				required: true,
				options: [
					{ label: tStopType('delivery'), value: 'delivery' },
					{ label: tStopType('pickup'), value: 'pickup' },
				],
			},
			equipmentType: {
				required: true,
				options: [
					{ label: tEquipmentType('V'), value: 'V' },
					{ label: tEquipmentType('F'), value: 'F' },
					{ label: tEquipmentType('R'), value: 'R' },
				],
			},
			handlingUnits: { required: false, mustBeAnInteger: true },
			handlingUnitType: {
				required: false,
				options: containerTypes.map(
					(containerType): ISelectOptions => {
						return {
							label: tHandlingUnitType(containerType),
							value: containerType,
						}
					},
				),
			},
			weight: { required: false, mustBeANumber: true },
		},
	}
	return (
		<Modal
			isOpen={props.isOpen}
			onModalClose={props.onModalClose}
			title={t('addAppointment', tPrefix)}
			content={() => (
				<div
					data-testid='apppintment-create-modal'
					className={classes.appointmentWithoutShipmentModal}
				>
					<FlexRow fullWidth={true} wrap={false}>
						<FlexItem minWidth='50% - 20px' maxWidth='50% - 20px'>
							<FormStackedTextInput
								formData={formData}
								field='carrier'
								hideOptional={true}
								width='100%'
							/>
							<FormStackedTextInput
								formData={formData}
								field='otherCompanyNames'
								hideOptional={true}
								width='100%'
							/>
							<FormStackedTextInput
								formData={formData}
								field='proNumber'
								hideOptional={true}
								width='100%'
							/>
							<FormStackedTextInput
								formData={formData}
								field='poNumbers'
								hideOptional={true}
								width='100%'
							/>
							<FormStackedTextInput
								formData={formData}
								field='soNumbers'
								hideOptional={true}
								width='100%'
							/>
						</FlexItem>
						<FlexItem minWidth='50% - 20px' maxWidth='50% - 20px'>
							<FormStackedSelect
								formData={formData}
								field='mode'
								width='100%'
							/>
							<FormStackedSelect
								formData={formData}
								field='flow'
								width='100%'
							/>
							<FormStackedSelect
								formData={formData}
								field='equipmentType'
								width='100%'
							/>
							<FlexRow fullWidth={true} wrap={false}>
								<FlexItem minWidth='50% - 10px' maxWidth='50% - 10px'>
									<FormStackedTextInput
										formData={formData}
										field='handlingUnits'
										hideOptional={true}
										width='100%'
									/>
								</FlexItem>
								<FlexItem minWidth='50% - 10px' maxWidth='50% - 10px'>
									<FormStackedSelect
										formData={formData}
										field='handlingUnitType'
										hideOptional={true}
										width='100%'
										numListOptionsBeforeScroll={5}
									/>
								</FlexItem>
							</FlexRow>
							<FormStackedTextInput
								formData={formData}
								field='weight'
								hideOptional={true}
								width='100%'
							/>
						</FlexItem>
					</FlexRow>
					<FlexRow fullWidth={true}>
						{props.errorText && (
							<FlexItem>
								<AngryRedBox>{props.errorText}</AngryRedBox>
							</FlexItem>
						)}
						<FlexItem>
							<AlignRight>
								<OkCancelButtons
									ok={t('save', tPrefix)}
									cancel={t('cancel', tPrefix)}
									onOk={props.onSubmit}
									onCancel={props.onModalClose}
									isValid={
										validateForm(formData.form, formData.metadata).isValid
									}
									isSpinning={props.submitting}
									okColor='darkBlue'
									okTestId='submit-appointment-create'
									okClass={classes.appointmentWithoutShipmentModalSave}
								/>
							</AlignRight>
						</FlexItem>
					</FlexRow>
				</div>
			)}
		/>
	)
}
