import { Address } from 'ui/api/apiTypes'

export interface IAddress {
	isOrigin?: boolean
	isDestination?: boolean
	addressName?: string
	contactName?: string
	attentionTo?: string
	name?: string
	street1?: string
	street2?: string
	street3?: string
	city?: string
	state?: string
	zip?: string
	country?: string
	addressType?: Address['addressType']
	phone?: string
	email?: string
	company?: string
}

export const getEmptyAddress = (): IAddress => {
	return {
		street1: '',
		street2: '',
		street3: '',
		contactName: '',
		addressName: '',
		name: '',
		phone: '',
		email: '',
		city: '',
		state: '',
		zip: '',
		country: '',
	}
}
