import { DateTime } from 'luxon'
import { l } from 'ui/lib/lodashImports'
import { log } from 'ui/lib/log/log'

const staleSeconds = 15
export interface IRequestState<T> {
	isFetching?: boolean
	data?: T
	warningsHeader?: string
	status?: number
	error?: any
	time?: string
}

// Very complicated function for elite programmers
export const createDefault = <T extends any>(): IRequestState<T> => {
	return {}
}
export const createTestComplete = <T extends any>(): IRequestState<T> => {
	return {
		data: ({ fetchCalled: true } as unknown) as T,
	}
}
export const createRequesting = <T extends any>(): IRequestState<T> => {
	return {
		isFetching: true,
		time: DateTime.local().toISO(),
	}
}

export const createWarning = <T extends any>(
	warnings: string,
	data: T,
): IRequestState<T> => {
	return {
		warningsHeader: warnings,
		data,
		time: DateTime.local().toISO(),
	}
}
export const createSuccess = <T extends any>(data: T): IRequestState<T> => {
	return {
		data,
		time: DateTime.local().toISO(),
	}
}
export const createError = <T extends any>(error: any): IRequestState<T> => {
	return {
		error,
		time: DateTime.local().toISO(),
	}
}
export const isStale = <T extends any>(rs: IRequestState<T>): boolean => {
	if (rs.isFetching) {
		return false
	}
	if (!rs.data) {
		return true
	}
	if (!rs.time) {
		return true
	}

	const diff = DateTime.local().diff(DateTime.fromISO(rs.time))
	const diffSeconds = diff.as('seconds')
	log('request-state', 'data is', '' + diffSeconds, 'seconds old')
	if (l.isNaN(diffSeconds)) {
		return true
	}
	return diffSeconds > staleSeconds
}
