import { callTms2Global } from 'ui/lib/IframeRpc'

const tms2 = {
	getMeteorUser: async () => {
		return await callTms2Global('getMeteorUser')
	},

	getPathname: async () => {
		return await callTms2Global<string>('getPathname')
	},

	currently_managed_top_level_organization_id: async () => {
		return await callTms2Global<string>(
			'currently_managed_top_level_organization_id',
		)
	},
	swal: async (options: {
		title: string
		text: string
		type?: 'info' | 'error' | 'warning'
		showCancelButton?: boolean
	}) => {
		return await callTms2Global('swal', options)
	},
}
export { tms2 }
