import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { apiTypes } from 'ui/api'
import { t } from 'ui/components/i18n/i18n'
import {
	StationSuccess,
	StationWaiting,
	Subway,
	SubwayArrow,
	SubwayStation,
} from 'ui/components/shared/subway'
import { compareBrokerShipmentStatus } from 'ui/pages/shipment-profile/shared/functions/shipmentStatusRanks'

const getStation = (
	currentStatus: apiTypes.BrokerShipmentResponse['brokerShipmentStatus'],
	stationStatus: apiTypes.BrokerShipmentResponse['brokerShipmentStatus'],
): React.ReactNode => {
	return compareBrokerShipmentStatus(currentStatus, stationStatus) >= 0 ? (
		<StationSuccess />
	) : (
		<StationWaiting />
	)
}

export const BrokerShipmentProfileSubway: FC = (props: {
	brokerShipmentStatus: apiTypes.BrokerShipmentResponse['brokerShipmentStatus']
	tPrefix: string
}) => {
	const { brokerShipmentStatus, tPrefix } = props

	if (!brokerShipmentStatus) {
		return null
	}

	return (
		<Subway testId='broker-shipment-profile-subway'>
			<SubwayStation
				top={t('subwayQuoted', tPrefix)}
				bottom=''
				dataTestId='shipment-profile-broker-subway-quoted'
			>
				{getStation(brokerShipmentStatus, 'quoted')}
			</SubwayStation>
			<SubwayArrow />
			<SubwayStation
				top={t('subwayAccepted', tPrefix)}
				bottom=''
				dataTestId='shipment-profile-broker-subway-accepted'
			>
				{getStation(brokerShipmentStatus, 'needs_transport')}
			</SubwayStation>
			<SubwayArrow />
			<SubwayStation
				top={t('subwayAwarded', tPrefix)}
				bottom=''
				dataTestId='shipment-profile-broker-subway-awarded'
			>
				{getStation(brokerShipmentStatus, 'awarded')}
			</SubwayStation>
			<SubwayArrow />
			<SubwayStation
				top={t('subwayBooked', tPrefix)}
				bottom=''
				dataTestId='shipment-profile-broker-subway-booked'
			>
				{getStation(brokerShipmentStatus, 'booked')}
			</SubwayStation>
		</Subway>
	)
}
