import { NotFound } from 'app/AppRouter'
import { FC } from 'app/FunctionalComponent'
import React from 'react'
import {
	IRoute,
	LoginModeType,
	SimpleRouter,
} from 'ui/components/common/router'
import { Layout } from '../layout'
import { ControlTowerCalendarPage } from './calendar-view'
import { ControlTowerMap } from './map-view'

export const controlTowerRoutes: IRoute[] = [
	{
		isDefault: true,
		urls: ['/control-tower/404'],
		renderer: () => (
			<Layout>
				<NotFound />
			</Layout>
		),
		anonymous: true,
	},
	{
		urls: ['/control-tower/calendar'],
		renderer: () => <ControlTowerCalendarPage />,
	},
	{
		urls: ['/control-tower/map'],
		renderer: () => <ControlTowerMap />,
	},
]

export const RouterControlTower: FC = (props: {
	currentLocation: string
	loginMode: LoginModeType
}) => {
	return (
		<SimpleRouter
			routes={controlTowerRoutes}
			currentLocation={props.currentLocation}
			loginMode={props.loginMode}
		/>
	)
}
