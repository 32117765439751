import React, { useState, ReactElement } from 'react'
import { apiBrokerConfig, apiTypes } from 'ui/api'
import Button from 'react-bootstrap/Button'
import { t, tString } from 'ui/components/i18n/i18n'
import Form from 'react-bootstrap/Form'
import {
	getCountryNameAndCodeList,
	getRegionsForCountry,
} from 'ui/data/countryAndRegionUtils'
import { ISelectOptions } from 'ui/components/common/select'
import { l } from 'ui/lib/lodashImports'
import * as apiClientConfig from 'ui/api/broker/apiClientConfig'
import { Modal } from 'ui/components/common/modal'
import { useOnce } from 'ui/components/hooks'
import { AlignRight } from 'ui/components/layout/alignRight'
import { FlexRow } from 'ui/components/layout/flexRow'
import { Spacer } from 'ui/components/layout/spacer'
import * as classes from './AddCustomerModal.module.scss'
import { createDefaultMarkup } from 'ui/lib/clientConfigs'
import { sosUser } from 'ui/state'
import { sosToast } from 'common/components/toast'
import { Loader } from 'ui/components/common/loader'

const tPrefix = 'page.customerManagement.defaultMarkup'

export type UpsertCompanyForm = {
	companyName: string
	brokerNumber: string
	street1: string
	street2: string
	city: string
	state: string
	zip: string
	country: string
	name: string
	phone: string
	fax: string
	email: string
}

const formDefault: UpsertCompanyForm = {
	companyName: '',
	brokerNumber: '',
	street1: '',
	street2: '',
	city: '',
	state: '',
	zip: '',
	country: 'US',
	name: '',
	phone: '',
	fax: '',
	email: '',
}

const countryCodes = getCountryNameAndCodeList().map((c) => ({
	label: c.countryName,
	value: c.countryCode,
}))
const defaultRegions: ISelectOptions[] = getRegionsForCountry(
	formDefault.country,
	true,
	true,
) as ISelectOptions[]

export const mapFormToAddClientRequest = (
	form: UpsertCompanyForm,
): apiTypes.AddClientRequest => {
	const address: apiTypes.Address = {
		street1: form.street1,
		street2: form.street2,
		city: form.city,
		state: form.state,
		zip: form.zip,
		country: form.country,
		name: form.name,
		phone: form.phone,
		fax: form.fax,
		email: form.email,
		company: form.companyName,
	}
	return {
		company: {
			companyName: form.companyName,
		},
		location: {
			name: form.companyName,
			isOrganizationalUnit: true,
			defaults: {
				defaultPickupAddress: {
					address,
				},
				defaultDeliveryAddress: {
					address,
				},
				defaultBillingAddress: {
					address,
				},
			},
		},
		clientConfig: {
			tmsService: 'SwanLeap',
			brokerNumber: form.brokerNumber,
			defaultMarkupLogic: createDefaultMarkup(),
			customerShipNotification: {
				sendShippingNotification: false,
				shippingNotificationEmails: [],
			},
		},
	}
}

export const UpsertCompanyModal = (props: {
	show: boolean
	onHide: () => void
}): ReactElement => {
	const { show, onHide } = props
	const [form, updateForm] = useState(l.cloneDeep(formDefault))
	const [regions, setRegions] = useState(defaultRegions)
	const [submitDisabled, setSubmitDisabled] = useState(false)
	const [validated, setValidated] = useState(false)

	const [allBrokerNumbers, setAllBrokerNumbers] = useState<string[]>([])
	const [autoGeneratedCustomerCode, setAutoGeneratedCustomerCode] = useState<
		string
	>(null)
	const [isFetchingCustomerCode, setIsFetchingCustomerCode] = useState<boolean>(
		false,
	)

	const getBrokerConfig = async (): Promise<void> => {
		setIsFetchingCustomerCode(true)
		const response = await apiBrokerConfig.getBrokerConfig()

		if (response.data) {
			await getAutoGeneratedCode(response.data.clientConfigCode?.autoGenerate)
		} else if (response.error) {
			sosToast.sendApiErrorResponseToast(response)
		}

		setIsFetchingCustomerCode(false)
	}

	const getAutoGeneratedCode = async (bool: boolean): Promise<void> => {
		if (bool === true) {
			const response = await apiBrokerConfig.getAutoGeneratedCustomerCode()

			if (response.error) {
				sosToast.sendApiErrorResponseToast(response)
			} else {
				setAutoGeneratedCustomerCode(response.data)
			}
		}
	}

	const clientConfigSearch = async (): Promise<void> => {
		const clientConfigsResponse = await apiClientConfig.getClientConfigList(
			() => {},
			'',
			100,
			0,
		)

		if (clientConfigsResponse.data) {
			const clientConfigBrokerNumber: string[] = clientConfigsResponse.data.entities?.map(
				(entity) => entity.brokerNumber,
			)

			setAllBrokerNumbers(clientConfigBrokerNumber)
		} else {
			sosToast.sendApiErrorResponseToast(
				clientConfigsResponse,
				tString('clientFetchError', 'common'),
			)
		}
	}

	useOnce(async () => {
		await clientConfigSearch()
	})

	useOnce(async () => {
		await getBrokerConfig()
	})

	useOnce(() => {
		updateForm(l.cloneDeep(formDefault))
	})

	const handleSubmit = async (
		event: any,
		brokerNumber: string = null,
	): Promise<void> => {
		// TODO: support location information editing
		event.preventDefault()

		if (event.currentTarget.checkValidity() === false) {
			event.stopPropagation()
			return
		}

		if (brokerNumber !== null) {
			form.brokerNumber = brokerNumber
		}

		const isMatchingBrokerNumber = allBrokerNumbers.includes(
			brokerNumber || form.brokerNumber,
		)

		if (isMatchingBrokerNumber === true) {
			sosToast.sendToast({
				header: tString('matchingBrokerNumberError', tPrefix),
				type: 'danger',
			})
		} else {
			setValidated(true)
			setSubmitDisabled(true)
			const result = await apiClientConfig.addClient(
				mapFormToAddClientRequest(form),
				l.noop,
			)
			if (result.error) {
				sosToast.sendApiErrorResponseToast(
					result,
					tString('addCompanyError', tPrefix),
				)
				event.preventDefault()
				event.stopPropagation()
			} else {
				sosUser.addClientConfigsToMemory([result.data.clientConfig])
				props.onHide()
				sosToast.sendToast({
					header: tString('companyCreated', tPrefix),
					type: 'success',
					dataTestId: 'company-created',
				})
			}
		}

		setSubmitDisabled(false)
	}

	const onChange = (e, field): void => {
		const val = e.target.value
		// Reset the state/region if the country changed
		if (field === 'country' && form.country !== val) {
			console.log(form.country)
			const newStateOptions: ISelectOptions[] = getRegionsForCountry(
				val,
				true,
				true,
			) as ISelectOptions[]
			setRegions(newStateOptions)
			if (!l.find(newStateOptions, (c) => c.value === form.state)) {
				form.state = ''
			}
		}
		if (field === 'brokerNumber') {
			setAutoGeneratedCustomerCode(null)
		}
		form[field] = val
		updateForm((prev) => ({ ...prev, ...form }))
	}

	return (
		<Modal
			isOpen={show}
			onModalClose={onHide}
			closeModalX={true}
			title={t('companyInformation', tPrefix)}
			content={() => (
				<div
					className={`bootstrap-wrapper ${classes.addCustomerModal}`}
					data-testid='add-customer-modal'
				>
					<Form
						validated={validated}
						onSubmit={async (e: any) => {
							await handleSubmit(e, autoGeneratedCustomerCode)
						}}
					>
						<FlexRow>
							<div>
								<Form.Group controlId='companyAddress'>
									<Form.Label>{t('companyName', tPrefix)}</Form.Label>
									<Form.Control
										required
										type='text'
										onChange={(e): void => onChange(e, 'companyName')}
										value={form.companyName}
										data-testid={'form-companyName'}
									/>
								</Form.Group>

								{isFetchingCustomerCode ? (
									<Loader
										isLoading={isFetchingCustomerCode}
										testid='customer-code-loader'
									/>
								) : (
									<Form.Group controlId='brokerNumber'>
										<Form.Label>{t('brokerNumber', tPrefix)}</Form.Label>
										<Form.Control
											required
											type='text'
											onChange={(e): void => onChange(e, 'brokerNumber')}
											value={autoGeneratedCustomerCode || form.brokerNumber}
											data-testid={'form-brokerNumber'}
										/>
									</Form.Group>
								)}
								{['street1', 'street2', 'city'].map((c) => (
									<Form.Group controlId={c} key={c}>
										<Form.Label>{t(c, tPrefix)}</Form.Label>
										<Form.Control
											type='text'
											onChange={(e): void => onChange(e, c)}
											value={form[c]}
											data-testid={`form-${c}`}
										/>
									</Form.Group>
								))}

								<Form.Group controlId={'state'}>
									<Form.Label>
										{form.country !== 'US'
											? t('province', tPrefix)
											: t('state', tPrefix)}
									</Form.Label>
									<Form.Control
										as='select'
										onChange={(e): void => onChange(e, 'state')}
										value={form.state}
										data-testid={'form-state'}
									>
										{regions.map((c, idx) => (
											<option
												key={idx}
												value={c.value}
												data-testid={`form-state-option-${c.value}`}
											>
												{c.label}
											</option>
										))}
									</Form.Control>
								</Form.Group>

								<Form.Group controlId={'zip'}>
									<Form.Label>
										{form.country !== 'US'
											? t('postalCode', tPrefix)
											: t('zip', tPrefix)}
									</Form.Label>
									<Form.Control
										type='text'
										onChange={(e): void => onChange(e, 'zip')}
										value={form.zip}
										data-testid={'form-zip'}
									/>
								</Form.Group>

								<Form.Group controlId={'country'}>
									<Form.Label>{t('country', tPrefix)}</Form.Label>
									<Form.Control
										as='select'
										onChange={(e): void => onChange(e, 'country')}
										value={form.country}
										data-testid={'form-country'}
									>
										{countryCodes.map((c, idx) => (
											<option
												key={idx}
												value={c.value}
												data-testid={`form-country-option-${c.value}`}
											>
												{c.label}
											</option>
										))}
									</Form.Control>
								</Form.Group>
							</div>

							<Spacer width={'5px'} />

							<div>
								<Form.Group controlId='contactInfo'>
									{['name', 'phone', 'fax'].map((c) => (
										<div key={c}>
											<Form.Label>{t(c, tPrefix)}</Form.Label>
											<Form.Control
												type='text'
												onChange={(e): void => onChange(e, c)}
												value={form[c]}
												data-testid={`form-${c}`}
											/>
										</div>
									))}
									<Form.Label>{t('email', tPrefix)}</Form.Label>
									<Form.Control
										type='email'
										onChange={(e): void => onChange(e, 'email')}
										value={form['email']}
										data-testid={'form-email'}
									/>
								</Form.Group>
							</div>
						</FlexRow>

						<Spacer height={'10px'} />

						<AlignRight>
							<Button variant='link' onClick={props.onHide}>
								{t('cancel', tPrefix)}
							</Button>
							<Button
								variant='primary'
								type='submit'
								data-testid='submit'
								disabled={submitDisabled}
							>
								{submitDisabled && (
									<>
										<span
											className='spinner-border spinner-border-sm'
											role='status'
											aria-hidden='true'
										></span>{' '}
									</>
								)}
								{t('confirm', tPrefix)}
							</Button>
						</AlignRight>
					</Form>
				</div>
			)}
		></Modal>
	)
}
