import React, { ReactElement } from 'react'
import { SimpleRouter, LoginModeType } from 'ui/components/common/router'
import { AppCompanyManagement } from '.'

export const RouterCompanyManagement = (props: {
	currentLocation: string
	loginMode: LoginModeType
}): ReactElement => {
	return (
		<SimpleRouter
			routes={[
				{
					urls: ['/company-management-v3'],
					renderer: () => <AppCompanyManagement />,
				},
			]}
			currentLocation={props.currentLocation}
			loginMode={props.loginMode}
		/>
	)
}
