import React, { ReactElement, useState } from 'react'
import { Layout } from 'ui/pages/layout'
import { useOnce } from 'ui/components/hooks'
import { fetchTopLevelLocation } from './state-management'
import { TShipperCompanyManagementTabs } from './state-management/sosCompanyManagement'
import { t } from 'ui/components/i18n/i18n'
import { TabContent, TabItem2, Tabs2 } from 'ui/components/common/tabs'
import { l } from 'ui/lib/lodashImports'
import * as classes from './CompanyManagementTabs.module.scss'
import { Card } from 'react-bootstrap'
import { DockConfigurationPage } from './dock-configuration'
import { PrintersAndScalesPage } from './printers-and-scales'
import { ShippingWorkflows } from 'ui/pages/company-management/shipping-workflows/ShippingWorfkflows'

const tPrefixCompanyManagement = 'page.companyManagement'

const tabs: Array<{ name: TShipperCompanyManagementTabs }> = [
	{
		name: 'shippingWorkflows',
	},
	{
		name: 'dockConfiguration',
	},
	{
		name: 'printersAndScales',
	},
]

export const ShipperCompanyManagementTabs = (): ReactElement => {
	const [selectedTab, setSelectedTab] = useState<TShipperCompanyManagementTabs>(
		'shippingWorkflows',
	)

	useOnce(async () => {
		await fetchTopLevelLocation()
	})

	const switchTab = (tabSelected: TShipperCompanyManagementTabs): void => {
		setSelectedTab(tabSelected)
	}

	return (
		<Layout>
			<div
				className='bootstrap-wrapper'
				style={{ minWidth: '1000px' }}
				data-testid={'shipper-company-management-tabs'}
			>
				<Tabs2>
					{l.map(tabs, (c) => (
						<div data-testid={c.name + '-tab'} key={c.name}>
							<TabItem2
								isSelected={selectedTab === c.name}
								key={c.name}
								onClick={() => {
									if (selectedTab !== c.name) {
										switchTab(c.name)
									}
								}}
							>
								{t(l.camelCase(c.name), tPrefixCompanyManagement)}
							</TabItem2>
						</div>
					))}
				</Tabs2>
				<div className={classes.companyManagementTabsContainer}>
					<TabContent
						isVisible={selectedTab === 'shippingWorkflows'}
						renderer={() => <ShippingWorkflows />}
					/>
					<TabContent
						isVisible={selectedTab === 'dockConfiguration'}
						renderer={() => (
							<Card>
								<Card.Header>
									{t('dockConfiguration', tPrefixCompanyManagement)}
								</Card.Header>
								<Card.Body>
									<DockConfigurationPage />
								</Card.Body>
							</Card>
						)}
					/>
					<TabContent
						isVisible={selectedTab === 'printersAndScales'}
						renderer={() => (
							<Card>
								<Card.Header>
									{t('printersAndScales', tPrefixCompanyManagement)}
								</Card.Header>
								<Card.Body>
									<PrintersAndScalesPage />
								</Card.Body>
							</Card>
						)}
					/>
				</div>
			</div>
		</Layout>
	)
}
