import { FC } from 'app/FunctionalComponent'
import React from 'react'
import {
	IRoute,
	LoginModeType,
	SimpleRouter,
} from 'ui/components/common/router'
import { ProviderDetailsPage } from './ProviderDetailsPage/ProviderDetailsPage'
import { ProviderListPage } from './ProviderListPage/ProviderListPage'

export const providerRoutes: IRoute[] = [
	{
		isDefault: true,
		urls: ['/providers'],
		renderer: (currentLocation, params) => <ProviderListPage />,
	},
	{
		urls: ['/providers/:providerId'],
		renderer: (currentLocation, params) => <ProviderDetailsPage />,
	},
]

export const RouterProviders: FC = (props: {
	currentLocation: string
	loginMode: LoginModeType
}) => {
	return (
		<SimpleRouter
			routes={providerRoutes}
			currentLocation={props.currentLocation}
			loginMode={props.loginMode}
		/>
	)
}
