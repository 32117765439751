import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { apiTypes } from 'ui/api'
import { t, tDate, tTime } from 'ui/components/i18n/i18n'

export const SimpleDateTime: FC = (props: {
	mode: 'pickup' | 'delivery'
	type: apiTypes.DateTimeInfo['timeType']
	beginDate?: string
	endDate?: string
	beginTime?: string
	endTime?: string
}) => {
	const { beginDate, endDate, beginTime, endTime } = props
	const showDateRange = beginDate && endDate
	const showTimeRange = beginTime && endTime
	const noData = !beginDate && !endDate && !beginTime && !endTime
	return (
		<div>
			{showDateRange && (
				<div>
					{/* {t('shared.simpleDateTime.between')} */}
					{` ${tDate(beginDate)} `}
					&ndash;
					{/* {t('shared.simpleDateTime.and')} */}
					{` ${tDate(endDate)}`}
				</div>
			)}
			{!showDateRange && beginDate && <div>{` ${tDate(beginDate)}`}</div>}
			{!showDateRange && endDate && (
				<div>
					{/* {t('shared.simpleDateTime.before')} */}
					{` ${tDate(endDate)}`}
				</div>
			)}

			{showTimeRange && (
				<div>
					{/* {t('shared.simpleDateTime.between')} */}
					{` ${tTime(beginTime)} `}
					&ndash;
					{/* {t('shared.simpleDateTime.and')} */}
					{` ${tTime(endTime)}`}
				</div>
			)}
			{!showTimeRange && beginTime && (
				<div>
					{/* {t('shared.simpleDateTime.at')} */}
					{props.type === 'at' ? (
						<>{t('shared.simpleDateTime.at')}</>
					) : (
						<>{t('shared.simpleDateTime.after')}</>
					)}
					{` ${tTime(beginTime)}`}
				</div>
			)}
			{!showTimeRange && endTime && (
				<div>
					{t('shared.simpleDateTime.before')}
					{` ${tTime(endTime)}`}
				</div>
			)}

			{noData && t(props.mode, 'shared.simpleDateTime.standard')}
		</div>
	)
}
