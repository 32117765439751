import { DateTime } from 'luxon'
import { elasticSearchBuilder, processSearchString } from 'ui/lib/elasticSearch'
import { buildQueriesFromFilters } from 'ui/lib/elasticSearch/buildQueriesFromFilters'
import { l } from 'ui/lib/lodashImports'
import { log } from 'ui/lib/log'
import { IDatePickerState, parseDateFromUSLocale } from 'ui/pages/shipments'
import {
	brokerHeaders,
	tmsHeaders,
} from 'ui/pages/shipments/ShipmentsListHeaders'
import { sosUser } from 'ui/state'
import { IStateShipmentsList } from './sosShipmentsList'

export function createShipmentQuery(
	state: IStateShipmentsList,
	shipmentStatusFilter: string,
	filterLocationIds: string[],
	externalQuery?: string,
	externalNestedQuery?: string,
): { query: string; nestedQuery: string; wildcards: string[] } {
	let qb = elasticSearchBuilder()
	let payloadQb = elasticSearchBuilder()
	if (filterLocationIds) {
		qb.andIn('locationId', filterLocationIds)
	}
	const statusFilterUpdate = setShipmentStatusFilter(
		qb,
		payloadQb,
		shipmentStatusFilter,
	)
	qb = statusFilterUpdate.qb
	qb = setDateFilter(qb, state.datePickerState)
	payloadQb = statusFilterUpdate.payloadQb

	const filterQueries = buildQueriesFromFilters(
		state.shipmentListDataTableState.filterChips,
		tmsHeaders,
	)
	const queries: string[] = [
		qb.toQuery(),
		// processSearchString(state.searchFor),
		...filterQueries.shipmentClauses,
	]
	if (externalQuery) {
		queries.push(`(${externalQuery})`)
	}

	const payloadQueries: string[] = [
		payloadQb.toQuery(),
		...filterQueries.payloadClauses,
	]
	if (externalNestedQuery) {
		payloadQueries.push(`(${externalNestedQuery})`)
	}

	const query = l.join(l.compact(queries), ' AND ')
	const nestedQuery = l.join(l.compact(payloadQueries), ' AND ')
	const wildcards = processSearchString(state.searchFor, true).split(' ')
	log(
		'sosShipmentsList',
		'query',
		query,
		'nestedQuery',
		nestedQuery,
		'wildcard',
		wildcards,
	)
	return { query, nestedQuery, wildcards }
}

export function createBrokerShipmentQuery(
	state: IStateShipmentsList,
	shipmentStatusFilter: string,
	externalQuery?: string,
	externalNestedQuery?: string,
): { query: string; nestedQuery: string; wildcards: string[] } {
	let qb = elasticSearchBuilder()
	let payloadQb = elasticSearchBuilder()

	qb = setBrokerConfigFilter(qb)
	const statusFilters = setBrokerShipmentStatusFilter(
		qb,
		payloadQb,
		shipmentStatusFilter,
	)
	qb = statusFilters.qb
	payloadQb = statusFilters.payloadQb
	payloadQb = setDateFilter(payloadQb, state.datePickerState)

	const filterQueries = buildQueriesFromFilters(
		state.brokerShipmentListDataTableState.filterChips,
		brokerHeaders,
	)

	if (state.clients?.length) {
		qb.andIn('contractId', state.clients)
	}

	if (state.originStates?.length) {
		qb.andIn('origin.state', state.originStates)
	}

	const queries: string[] = [qb.toQuery(), ...filterQueries.shipmentClauses]
	if (externalQuery) {
		queries.push(`(${externalQuery})`)
	}

	const payloadQueries: string[] = [
		payloadQb.toQuery(),
		...filterQueries.payloadClauses,
	]
	if (externalNestedQuery) {
		payloadQueries.push(`(${externalNestedQuery})`)
	}

	const query = l.join(l.compact(queries), ' AND ')
	const nestedQuery = l.join(l.compact(payloadQueries), ' AND ')
	const wildcards = processSearchString(state.searchFor, true).split(' ')
	log(
		'sosShipmentsList',
		'query',
		query,
		'nestedQuery',
		nestedQuery,
		'wildcard',
		wildcards,
	)
	return { query, nestedQuery, wildcards }
}

function setShipmentStatusFilter(
	qb,
	payloadQb,
	shipmentStatusFilter: string,
): {
	qb: any
	payloadQb: any
} {
	qb.andNot('shipmentStatus', 'preparing')
	qb.andNot('shipmentStatus', 'no_longer_in_erp')
	if (
		l.find(['booked', 'awarded', 'void'], (c) => c === shipmentStatusFilter)
	) {
		qb.and('shipmentStatus', shipmentStatusFilter)
	}

	if (l.find(['quoted'], (c) => c === shipmentStatusFilter)) {
		qb.andOr([
			['shipmentStatus', 'quoted'],
			['shipmentStatus', 'active'],
			['shipmentStatus', 'active_invites_sent'],
			['shipmentStatus', 'active_with_offers'],
			['shipmentStatus', 'active_invites_not_sent'],
		])
		qb.andOr([
			['rates.quoteType', 'spotQuote'],
			['rates.quoteType', 'contract'],
			['rates.quoteType', 'loggedOffer'],
			['rates.quoteType', 'webRate'],
			['rates.quoteType', 'vendorQuote'],
			['rates.quoteType', 'brokerOffer'],
		])
	}

	if (l.find(['in-transit'], (c) => c === shipmentStatusFilter)) {
		qb.and('shipmentStatus', 'booked')
		payloadQb.and('payloads.trackingStatus', 'pickup') // 'in_transit')
	}
	if (l.find(['delivered'], (c) => c === shipmentStatusFilter)) {
		qb.and('shipmentStatus', 'booked')
		payloadQb.and('payloads.trackingStatus', 'delivered')
	}
	if (l.find(['undeliverable'], (c) => c === shipmentStatusFilter)) {
		qb.and('shipmentStatus', 'booked')
		qb.and('payloads.trackingStatus', 'undeliverable')
	}

	if (l.find(['no-rates'], (c) => c === shipmentStatusFilter)) {
		qb.andOr([
			['shipmentStatus', 'quoted'],
			['shipmentStatus', 'active'],
			['shipmentStatus', 'active_invites_sent'],
			['shipmentStatus', 'active_with_offers'],
			['shipmentStatus', 'active_invites_not_sent'],
		])
		qb.andNot('rates.quoteType', 'spotQuote')
		qb.andNot('rates.quoteType', 'contract')
		qb.andNot('rates.quoteType', 'loggedOffer')
		qb.andNot('rates.quoteType', 'webRate')
		qb.andNot('rates.quoteType', 'vendorQuote')
		qb.andNot('rates.quoteType', 'brokerOffer')
	}

	log('sosShipmentsList', 'fetch', shipmentStatusFilter)

	return { qb, payloadQb }
}

function setBrokerShipmentStatusFilter(
	qb,
	payloadQb,
	shipmentStatusFilter: string,
): { qb; payloadQb } {
	if (shipmentStatusFilter !== 'all') {
		let filterTerm = shipmentStatusFilter
		if (shipmentStatusFilter === 'needs-quote') {
			filterTerm = 'needs_quote'
		} else if (shipmentStatusFilter === 'in-transit') {
			filterTerm = 'booked'
			payloadQb.and('payloads.trackingStatus', 'pickup')
		} else if (shipmentStatusFilter === 'delivered') {
			filterTerm = 'booked'
			payloadQb.and('payloads.trackingStatus', 'delivered')
		} else if (shipmentStatusFilter === 'closed') {
			filterTerm = 'lostBid'
		} else if (shipmentStatusFilter === 'needs-transport') {
			filterTerm = 'needs_transport'
		}

		qb.and('brokerShipmentStatus', filterTerm)
	}

	log('sosShipmentsList', 'fetch', shipmentStatusFilter)
	qb.log('sosShipmentsList')
	payloadQb.log('sosShipmentsList')

	return { qb, payloadQb }
}

function setBrokerConfigFilter(qb): any {
	const userState = sosUser.getSos().getState()
	if (
		userState.selectedClientConfig &&
		userState.selectedClientConfig.id !== ''
	) {
		qb.and('contractId', userState.selectedClientConfig.id)
	}
	return qb
}

function setDateFilter(qb, datePickerState: IDatePickerState): any {
	// Date filters
	if (datePickerState.mode === 'on') {
		qb.and(
			'pickupTimeRange.initialDate',
			parseDateFromUSLocale(datePickerState.date1String).toISODate(),
		)
	}
	if (datePickerState.mode === 'between') {
		qb.andRange(
			'pickupTimeRange.initialDate',
			parseDateFromUSLocale(datePickerState.date1String).toISODate(),
			parseDateFromUSLocale(datePickerState.date2String).toISODate(),
		)
	}
	if (datePickerState.mode === 'in-the-last') {
		qb.andRange(
			'pickupTimeRange.initialDate',
			DateTime.local()
				.minus({ days: parseInt(datePickerState.inLastX, 10) })
				.toISODate(),
			DateTime.local().toISODate(),
		)
	}
	qb.log('sosShipmentsList')

	log(
		'sosShipmentsList',
		'date',
		datePickerState,
		DateTime.fromISO(datePickerState.date1String).toISODate(),
	)

	return qb
}
