import { apiDockScheduler, apiTypes } from 'ui/api'
import { sosRouter2 } from 'ui/components/common/router'
import { l } from 'ui/lib/lodashImports'
import { createLazySos2 } from 'ui/lib/state/sos2/sos2'
import { sosUser } from 'ui/state'
import {
	sosDockConfigPage,
	sosDockSchedulerCalendarTab,
	sosDockSchedulerScheduleTab,
} from '.'

export type DockSchedulerPages =
	| 'schedule'
	| 'calendar'
	| 'dock-status-board'
	| 'appointment-details'

export type AppointmentFlowFilter = 'inbound' | 'outbound' | 'all'

export type AppointmentModeFilter =
	| 'ltl'
	| 'volume'
	| 'truckload'
	| 'ocean'
	| 'air'
	| 'all'

const pageInfo = {
	url: '/dock-scheduler/:selectedPage',
	params: {
		selectedPage: {
			name: 'selectedPage',
		},
	},
	queries: {
		shipmentId: {
			//description: ''
			name: 'shipmentId',
			default: undefined,
		},
		appointmentId: {
			//description: ''
			name: 'appointmentId',
			default: undefined,
		},
	},
}

export const getUrlState = (): {
	selectedPage: string
	appointmentId: string
	shipmentId: string
} => {
	const selectedPage = sosRouter2.getUrlParam(
		pageInfo,
		pageInfo.params.selectedPage,
	)
	const appointmentId = sosRouter2.getQueryParam(
		pageInfo,
		pageInfo.queries.appointmentId,
	)
	const shipmentId = sosRouter2.getQueryParam(
		pageInfo,
		pageInfo.queries.shipmentId,
	)
	return {
		selectedPage,
		appointmentId,
		shipmentId,
	}
}

export interface IStateDockScheduler {
	currentLocation: apiTypes.LocationResponse
	docks: apiTypes.DockResponse[]
	isLoadingLocations: boolean
}

export const getSos = createLazySos2<IStateDockScheduler>(
	'sosDockScheduler',
	1,
	() => ({
		currentLocation: { default: null, localStorage: true },
		docks: {
			default: [],
			localStorage: true,
		},
		isLoadingLocations: { default: false, localStorage: false },
	}),
)

export function updateSelectedTab(
	tab: DockSchedulerPages,
	appointmentId?: string,
	shipmentId?: string,
): void {
	if (
		[
			'schedule',
			'calendar',
			'dock-status-board',
			'appointment-details',
		].indexOf(tab) > -1
	) {
		if (tab !== 'appointment-details') {
			appointmentId = undefined
			shipmentId = undefined
		}
		sosRouter2.navigateTo(pageInfo, {
			selectedPage: tab,
			shipmentId,
			appointmentId,
		})
	}
}

export async function updateLocation(id: string): Promise<void> {
	if (!id) {
		return
	}

	const location: apiTypes.LocationResponse = await sosUser.getLocation(id)
	if (location) {
		getSos().change((ds) => {
			ds.currentLocation = location
		})
		const fetches: Promise<any>[] = []
		sosDockSchedulerScheduleTab.setSelectedShipmentWithoutAppointment(null)
		fetches.push(fetchDocks())
		const urlState = getUrlState()
		if (urlState.selectedPage === 'schedule') {
			fetches.push(
				sosDockSchedulerScheduleTab._fetchAppointmentsForLocationOnSelectedDay(),
			)
			fetches.push(
				sosDockSchedulerScheduleTab._fetchShipmentsWithoutAppointments(),
			)
		} else if (urlState.selectedPage === 'calendar') {
			fetches.push(sosDockSchedulerCalendarTab._fetchCalendarAppointments())
		} else if (!urlState.selectedPage) {
			fetches.push(sosDockConfigPage.fetchDocks())
		}
		await Promise.all(fetches)
	}
}

export const fetchDocks = async (): Promise<void> => {
	let docks: apiTypes.DockResponse[] = []
	let pageNumber = 1
	const take = 100
	let result = await apiDockScheduler.fetchDocks(() => {}, {
		locationId: getSos().getState().currentLocation.id,
		take,
		include: 'schedule',
	})
	if (result.data) {
		docks = result.data.entities
		while (result.data.pageCount > pageNumber) {
			result = await apiDockScheduler.fetchDocks(() => {}, {
				take,
				skip: pageNumber * take,
				include: 'schedule',
			})
			docks = docks.concat(result.data.entities)
			pageNumber++
		}
		const collator = new Intl.Collator(undefined, {
			numeric: true,
			sensitivity: 'base',
		})

		getSos().change((ds) => {
			ds.docks = docks.sort((a, b) => collator.compare(a.nickName, b.nickName))
		})
	}
}

export const setIsLoadingLocations = (value: boolean): void => {
	getSos().change((ds) => {
		ds.isLoadingLocations = value
	})
}

// in a perfect world this is not necessary as refetching docks should handle this
// however, elasticsearch is eventually consistent so the API will take a bit to update the dock in elasticesearch, so refetching docks doesn't always show changes
// this function lets the UI have the changes when the API doesn't have them updatedyet
export const updateDockList = (
	dock: apiTypes.DockResponse,
	deleteDock = false,
): void => {
	const matchingDock = l.find(
		getSos().getState().docks,
		(dockInList) => dockInList.id === dock.id,
	)
	if (matchingDock) {
		if (deleteDock) {
			getSos().change((ds) => {
				ds.docks = l.filter((dockInList) => dockInList.id !== dock.id)
			})
		} else {
			getSos().change((ds) => {
				ds.docks = ds.docks.map((dockInList) =>
					dockInList.id === dock.id ? dock : dockInList,
				)
			})
		}
	} else if (!deleteDock) {
		getSos().change((ds) => {
			ds.docks.push(dock)
		})
	}
}
