import * as holidays from '@date/holidays-us'
import { toInteger } from '../numbers/toNumber'
import { DateTime } from 'luxon'

interface BankHolidays {
	isHoliday: (date: Date) => boolean
	getHoliday: (
		date: Date,
	) => {
		name: string
	}
}
const bankHolidays = holidays.bank() as BankHolidays
const format = 'yyyy-LL-dd'

// Add business days, skipping weekends and holidays
export function addBusinessDays(
	date: string,
	daysToAdd: number,
	includeSaturdayAsBusiness = false,
): string {
	if (!daysToAdd) {
		return date
	}
	if (!date) {
		return ''
	}
	let dateIterator = DateTime.fromISO(date)
	let iterateBy = 1
	// Allow backwards support (i.e. 5 business days before this day)
	daysToAdd = toInteger(daysToAdd)
	if (daysToAdd < 0) {
		iterateBy = -1
	}

	while (daysToAdd !== 0) {
		dateIterator = dateIterator.plus({ days: iterateBy })

		// Don't count weekends
		const dayOfWeek = dateIterator.weekday
		const SUNDAY = 7
		const SATURDAY = 6
		if (
			dayOfWeek === SUNDAY ||
			(!includeSaturdayAsBusiness && dayOfWeek === SATURDAY)
		) {
			continue
		}
		// Don't count bank holidays
		if (bankHolidays.isHoliday(dateIterator.toJSDate())) {
			continue
		}

		daysToAdd -= iterateBy
	}

	return dateIterator.toFormat(format)
}

export function getAllBankHolidaysInYear(
	year: number,
): { date: DateTime; holidayName: string }[] {
	const holidays = []
	const numDaysInYear = DateTime.fromFormat(`${year}-01-01`, format).daysInYear
	for (let i = 1; i <= numDaysInYear; i++) {
		const day = DateTime.fromFormat(`${year}-01-01`, format).set({ ordinal: i })
		if (bankHolidays.isHoliday(day.toJSDate())) {
			holidays.push({
				date: day,
				holidayName: bankHolidays.getHoliday(day.toJSDate()).name,
			})
		}
	}
	return holidays
}

export function findBusinessDaysBetween(
	isoString1: string,
	isoString2: string,
): number {
	let datetime1 = DateTime.fromISO(isoString1).set({
		hour: 0,
		minute: 0,
		millisecond: 0,
	})
	let datetime2 = DateTime.fromISO(isoString2).set({
		hour: 0,
		minute: 0,
		millisecond: 0,
	})
	let datetime1Sonner = true
	if (datetime1.diff(datetime2, 'days').days > 0) {
		datetime1Sonner = false
	}
	let businessDays = 0
	while (datetime1.diff(datetime2, 'days').days !== 0) {
		if (datetime1Sonner) {
			datetime1 = datetime1.plus({ days: 1 })
			if (
				datetime1.weekday !== 6 &&
				datetime1.weekday !== 7 &&
				!bankHolidays.isHoliday(datetime1.toJSDate())
			) {
				businessDays++
			}
		} else {
			datetime2 = datetime2.plus({ days: 1 })
			if (
				datetime2.weekday !== 6 &&
				datetime2.weekday !== 7 &&
				!bankHolidays.isHoliday(datetime2.toJSDate())
			) {
				businessDays++
			}
		}
	}
	return businessDays
}
