import { l } from 'ui/lib/lodashImports'
import { apiCommon, apiTypes } from './'
import { IRequestState } from './requestState'

export async function fetchLocation(
	onProgress: (rs: IRequestState<apiTypes.LocationResponse>) => void,
	locationId: string,
	alternateApiKey?: string,
): Promise<IRequestState<apiTypes.LocationResponse>> {
	return await apiCommon.apiFetch(
		onProgress,
		{ alternateApiKey },
		`locations/${locationId}`,
	)
}

export async function fetchLocations(
	onProgress: (rs: IRequestState<apiTypes.LocationResponse[]>) => void,
	params?: {
		take?: number
		skip?: number
		organizationRelationshipId?: string
		clientConfigId?: string
		query?: string
	},
	alternateApiKey?: string,
): Promise<IRequestState<apiTypes.LocationResponse[]>> {
	params = l.defaults(params || {}, { take: 100 }) // 100 is the max right now
	return await apiCommon.apiFetch(
		onProgress,
		{ alternateApiKey },
		'locations',
		params,
	)
}

export async function fetchOrganizationRelationship(
	companyRelationshipId: string,
	onProgress: (
		rs: IRequestState<apiTypes.OrganizationRelationshipResponse>,
	) => void,
): Promise<IRequestState<apiTypes.OrganizationRelationshipResponse>> {
	return await apiCommon.apiFetch(
		onProgress,
		{},
		`companies/relationships/${companyRelationshipId}`,
	)
}

export async function fetchLinkedLocation(
	onProgress: (rs: IRequestState<apiTypes.LocationResponse>) => void,
	relationshipId: string,
	locationId: string,
	alternateApiKey: string,
): Promise<IRequestState<apiTypes.LocationResponse>> {
	return await apiCommon.apiFetch(
		onProgress,
		{ alternateApiKey },
		`companies/relationships/${relationshipId}/locations/${locationId}`,
	)
}

export async function putLocation(
	onProgress: (rs: IRequestState<apiTypes.LocationResponse>) => void,
	locationID: string,
	locationRequest: apiTypes.LocationResponse,
): Promise<IRequestState<apiTypes.LocationResponse>> {
	return await apiCommon.apiFetch(
		onProgress,
		{
			method: 'PUT',
			data: locationRequest,
		},
		`locations/${locationID}`,
	)
}

export async function uploadLocationLogo(
	onProgress: (rs: IRequestState<apiTypes.LocationResponse>) => void,
	locationID: string,
	logoRequest: apiTypes.LogoRequest,
	clientConfigId?: string,
): Promise<IRequestState<apiTypes.LocationResponse>> {
	return await apiCommon.apiFetch(
		onProgress,
		{
			method: 'POST',
			data: logoRequest,
		},
		`locations/${locationID}/brandingUrl`,
		{ clientConfigId },
	)
}
