import { FC } from 'app/FunctionalComponent'
import { Table } from 'common/components/table/Table'
import React, { useState } from 'react'
import { ColumnDescription } from 'react-bootstrap-table-next'
import { Button } from 'ui/components/common/button/Button'
import { Checkbox } from 'ui/components/common/checkbox'
import { SearchInput } from 'ui/components/common/search/SearchInput'
import { useOnce } from 'ui/components/hooks'
import { t, tString } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight/AlignRight'
import { Spacer } from 'ui/components/layout/spacer'
import { fireAndForget } from 'ui/lib/async/fireAndForget'
import { sos2 } from 'ui/lib/state/sos2'
import { DockSchedulerLocationSelector } from 'ui/pages/dock-scheduler/components/DockSchedulerLocationSelector'
import { Layout } from 'ui/pages/layout/Layout'
import * as classes from 'ui/pages/load-optimizer/LoadOptimizer.module.scss'
import * as sosLoadOptimizer from 'ui/pages/load-optimizer/state/sosLoadOptimizer'
import { PayloadListMappedData } from '../state/sosLoadOptimizer'

export const tPrefixLoadOptimizer = 'page.loadOptimizer'

const LoadOptimizerPayloadsTable: FC = (props: {}) => {
	useOnce(() => {
		fireAndForget(sosLoadOptimizer.fetchPayloads, 'fetch payloads')
	})

	const headers: ColumnDescription[] = [
		{
			dataField: 'included',
			text: tString('included', tPrefixLoadOptimizer),
			//@ts-ignore
			attrs: () => ({
				'data-testid': 'included',
			}),
			formatter: (cell, row) => {
				return (
					<Checkbox
						value={cell}
						onChange={(newVal: boolean) => {
							sosLoadOptimizer.updateSelectedPayloads(row.id, newVal)
						}}
						testId='includeCheckbox'
					>
						{null}
					</Checkbox>
				)
			},
			align: 'center',
		},
		{
			dataField: 'pickupDate',
			text: tString('pickupDate', tPrefixLoadOptimizer),
			//@ts-ignore
			attrs: () => ({
				'data-testid': 'pickupDate',
			}),
		},
		{
			dataField: 'salesOrder',
			text: tString('salesOrder', tPrefixLoadOptimizer),
			//@ts-ignore
			attrs: () => ({
				'data-testid': 'salesOrder',
			}),
		},
		{
			dataField: 'purchaseOrder',
			text: tString('purchaseOrder', tPrefixLoadOptimizer),
			//@ts-ignore
			attrs: () => ({
				'data-testid': 'purchaseOrder',
			}),
		},
		{
			dataField: 'originCompany',
			text: tString('originCompany', tPrefixLoadOptimizer),
			//@ts-ignore
			attrs: () => ({
				'data-testid': 'originCompany',
			}),
		},
		{
			dataField: 'originStreet1',
			text: tString('originStreet1', tPrefixLoadOptimizer),
			//@ts-ignore
			attrs: () => ({
				'data-testid': 'originStreet1',
			}),
		},
		{
			dataField: 'originCity',
			text: tString('originCity', tPrefixLoadOptimizer),
			//@ts-ignore
			attrs: () => ({
				'data-testid': 'originCity',
			}),
		},
		{
			dataField: 'originState',
			text: tString('originState', tPrefixLoadOptimizer),
			//@ts-ignore
			attrs: () => ({
				'data-testid': 'originState',
			}),
		},
		{
			dataField: 'originZip',
			text: tString('originZip', tPrefixLoadOptimizer),
			//@ts-ignore
			attrs: () => ({
				'data-testid': 'originZip',
			}),
		},
		{
			dataField: 'destinationCompany',
			text: tString('destinationCompany', tPrefixLoadOptimizer),
			//@ts-ignore
			attrs: () => ({
				'data-testid': 'destinationCompany',
			}),
		},
		{
			dataField: 'destinationStreet1',
			text: tString('destinationStreet1', tPrefixLoadOptimizer),
			//@ts-ignore
			attrs: () => ({
				'data-testid': 'destinationStreet1',
			}),
		},
		{
			dataField: 'destinationCity',
			text: tString('destinationCity', tPrefixLoadOptimizer),
			//@ts-ignore
			attrs: () => ({
				'data-testid': 'destinationCity',
			}),
		},
		{
			dataField: 'destinationState',
			text: tString('destinationState', tPrefixLoadOptimizer),
			//@ts-ignore
			attrs: () => ({
				'data-testid': 'destinationState',
			}),
		},
		{
			dataField: 'destinationZip',
			text: tString('destinationZip', tPrefixLoadOptimizer),
			//@ts-ignore
			attrs: () => ({
				'data-testid': 'destinationZip',
			}),
		},
		{
			dataField: 'pallets',
			text: tString('pallets', tPrefixLoadOptimizer),
			//@ts-ignore
			attrs: () => ({
				'data-testid': 'pallets',
			}),
		},
		{
			dataField: 'weight',
			text: tString('weight', tPrefixLoadOptimizer),
			//@ts-ignore
			attrs: () => ({
				'data-testid': 'weight',
			}),
		},
	]

	const [isOptimizingPayloads, setIsOptimizingPayloads] = useState(false)
	const state = sos2.useSubscription(sosLoadOptimizer.getSos())
	const { payloads } = state
	const processedPayloads: PayloadListMappedData[] = sosLoadOptimizer.processPayloads(
		payloads,
	)

	return (
		<Layout>
			<div className='bootstrap-wrapper'>
				<div className={classes.fullScreen}>
					<h4>{t('orderManagement', tPrefixLoadOptimizer)}</h4>
					<Spacer />
					<div className='row'>
						<div className='col-md-2'>
							<SearchInput
								testId={'payloadSearchInput'}
								value={state.payloadWildcardQuery}
								onChange={sosLoadOptimizer.updatePayloadWildcardQuery}
								readOnly={state.isFetchingPayloads}
								className={classes.searchInput}
							/>
						</div>
						<div className='col-md-2'>
							<div className={classes.sideMargin}>
								<DockSchedulerLocationSelector />
							</div>
						</div>
						<div className='col-md-2 offset-md-6'>
							<AlignRight>
								<Button
									onClick={async () => {
										setIsOptimizingPayloads(true)
										await sosLoadOptimizer.optimizePayloads()
										setIsOptimizingPayloads(false)
									}}
									color='blue'
									isSmall={true}
									bootstrapStyles={true}
									testId='optimizePayloads'
									isSpinning={isOptimizingPayloads}
								>
									{t('optimizeOrders', tPrefixLoadOptimizer)}
								</Button>
							</AlignRight>
						</div>
					</div>
					<div className={classes.mediumFont}>
						<Table
							columns={headers}
							data={processedPayloads}
							isLoading={state.isFetchingPayloads}
							totalSize={state.totalPayloadCount}
							onTableChange={(_type, newState) =>
								sosLoadOptimizer.fetchPayloads(
									newState.sizePerPage,
									newState.sizePerPage * (newState.page - 1),
								)
							}
							keyField='id'
							verticalCenterCells={true}
							testId='loadOptimizerPayloadsTable'
						/>
					</div>
				</div>
			</div>
		</Layout>
	)
}

export { LoadOptimizerPayloadsTable }
