import { apiTypes } from 'ui/api'
import { RateRow } from 'ui/components/rates-table/RatesTable'
import { calculateBrokerPrices } from 'ui/pages/shipment-profile/broker/functions/calculateBrokerPrices'

export const convertBrokerRateResponseToRateRow = (
	rateResponse: apiTypes.RateResponse,
	shipment?: apiTypes.BrokerShipmentResponse | apiTypes.ShipmentResponse,
	defaultMarkupLogic?: apiTypes.MarkupLogic,
): RateRow => {
	if (!rateResponse) {
		return null
	}

	const rateRow: RateRow = {
		providerImage: rateResponse.providerName,
		providerName: rateResponse.carrier || rateResponse.providerName,
		serviceLevel: rateResponse.serviceLevel,
		method: rateResponse.method,
		quoteType: rateResponse.quoteType,
		transit: rateResponse.transit,
		delivery: rateResponse.delivery,
		guaranteed: rateResponse.guaranteed,
		rateId: rateResponse.id,
		offerId: rateResponse.offerId,
		offerStatus: rateResponse.offerStatus || 'offerMade',
		message: rateResponse.providerNote,
	}

	const brokerPrices = calculateBrokerPrices(
		{
			buyPrice: rateResponse.costTotal,
			sellPrice:
				shipment &&
				'offers' in shipment &&
				shipment.offers[0]?.flatOffer?.grossRate,
		},
		defaultMarkupLogic,
	)
	rateRow.buyPrice = brokerPrices.buyPrice
	rateRow.sellPrice = brokerPrices.sellPrice
	rateRow.markup = brokerPrices.markupPercent
	return rateRow
}
