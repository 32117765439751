// TODO: google analytics
//import ReactGA from 'react-ga'
// if (typeof window !== 'undefined') {
//   window.onpopstate = function(event) {
//     alert(
//       'location: ' +
//         document.location +
//         ', state: ' +
//         JSON.stringify(event.state),
//     )
//     log('ga', 'pageview', window.location.pathname + window.location.search)
//     ReactGA.pageview(window.location.pathname + window.location.search)
//   }
// }

export const noop = (): void => {}
