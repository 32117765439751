import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { l } from 'ui/lib/lodashImports'
import styles from './ApiExamples.module.scss'
import { JsonBlob } from './JsonBlob'

export const ExampleData: FC = (props: { name: string; json: any }) => {
	if (!props.json || l.isEmpty(props.json)) {
		return null
	}
	return (
		<div>
			<h4 className={styles.h4}>{props.name}</h4>
			<JsonBlob json={props.json} />
		</div>
	)
}
