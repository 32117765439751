import React from 'react'
import {
	AppointmentFlowFilter,
	AppointmentModeFilter,
} from '../state/sosDockScheduler'
import { FlexRow } from 'ui/components/layout/flexRow'
import { Spacer } from 'ui/components/layout/spacer'
import { l } from 'ui/lib/lodashImports'
import { tString } from 'ui/components/i18n/i18n'
import { FC } from 'app/FunctionalComponent'

export const DockSchedulerAppointmentFilters: FC = (props: {
	selectedFlow: AppointmentFlowFilter
	selectedMode: AppointmentModeFilter
	onUpdateFlow: (flow: AppointmentFlowFilter) => void
	onUpdateMode: (mode: AppointmentModeFilter) => void
	readOnly?: boolean
}) => {
	const { selectedFlow, selectedMode, onUpdateFlow, onUpdateMode } = props
	const flowOptions: AppointmentFlowFilter[] = ['all', 'inbound', 'outbound']
	const modeOptions: AppointmentModeFilter[] = [
		'all',
		'ltl',
		'volume',
		'truckload',
		'ocean',
		'air',
	]
	return (
		<FlexRow wrap={false} mode={'center'} noChildMargins={true}>
			<div className='bootstrap-wrapper'>
				<select
					className='form-control form-control-sm'
					value={selectedFlow}
					disabled={props.readOnly}
					onChange={(event) =>
						onUpdateFlow(event.target.value as AppointmentFlowFilter)
					}
					data-testid='dock-scheduler-flow-filter'
				>
					{l.map(flowOptions, (option, optionIdx) => {
						return (
							<option value={option} key={optionIdx}>
								{tString(option, 'page.dockScheduler.flowFilter')}
							</option>
						)
					})}
				</select>
			</div>
			<Spacer />
			<div className='bootstrap-wrapper'>
				<select
					className='form-control form-control-sm'
					value={selectedMode}
					disabled={props.readOnly}
					onChange={(event) =>
						onUpdateMode(event.target.value as AppointmentModeFilter)
					}
					data-testid='dock-scheduler-mode-filter'
				>
					{l.map(modeOptions, (option, optionIdx) => {
						return (
							<option value={option} key={optionIdx}>
								{tString(option, 'page.dockScheduler.modeFilter')}
							</option>
						)
					})}
				</select>
			</div>
		</FlexRow>
	)
}
