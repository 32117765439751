import { tString } from 'ui/components/i18n/i18n'
import { IDataTableState } from 'ui/components/table'
import { dataUtils } from 'ui/components/table/'
import { formUtils } from 'ui/forms/'
import {
	addCustomerFormMetadata,
	IAddCustomerForm,
} from 'ui/forms/formProviderPortalCustomersAdd'
import { tms2_providerPortal } from 'ui/lib'
import { l } from 'ui/lib/lodashImports'
import { log } from 'ui/lib/log'
import { createSos } from './secretaryOfState'

export interface ICustomerListRow {
	name: string
}

export interface ICustomersState {
	dataTableStateCustomers: IDataTableState<ICustomerListRow>
	dataCustomers: ICustomerListRow[]
	searchValue: string
	addFormShowing: boolean
	addFormData: IAddCustomerForm
	addErrorMessage: string
}

const initialState: ICustomersState = {
	dataTableStateCustomers: {
		hiddenHeaders: [],
	},
	dataCustomers: [],
	searchValue: '',
	addFormShowing: false,
	addFormData: formUtils.createDefaultFormStringData(addCustomerFormMetadata),
	addErrorMessage: '',
}

const { stateManager, useSubscribe } = createSos(
	'sosCustomersPage',
	'1.0.0',
	initialState,
)
export { useSubscribe }

export const updateSearch = (searchVal: string): void => {
	log('provider-portal', 'my customers search', searchVal)
	stateManager.produce((ds) => {
		const filteredData = l.filter(ds.dataCustomers, (org) => {
			return org.name.toLowerCase().indexOf(searchVal.toLowerCase()) > -1
		})
		ds.dataCustomers = filteredData
		ds.searchValue = searchVal
	})
}

export const addToCustomerTable = (): void => {
	stateManager.produce((ds) => {
		dataUtils.toggleUpdateMode(ds.dataTableStateCustomers, 'add')
		if (ds.dataTableStateCustomers.isAdding) {
			ds.addFormData = formUtils.createDefaultFormStringData(
				addCustomerFormMetadata,
			)
		}
	})
}

export function toggleShowAddCustomer(): void {
	stateManager.produce((ds) => {
		ds.addErrorMessage = ''
		ds.addFormShowing = !ds.addFormShowing
	})
}

export function updateAddCustomerForm(
	key: keyof IAddCustomerForm,
	newVal: string,
): void {
	stateManager.produce((ds) => {
		ds.addFormData[key] = newVal
	})
}

export async function getCustomers(): Promise<void> {
	const data = await tms2_providerPortal.getCustomers()
	if (data) {
		stateManager.produce((ds) => {
			ds.dataCustomers = data
		})
	}
}

export async function submitAddCustomer(): Promise<void> {
	stateManager.produce((ds) => {
		ds.addErrorMessage = ''
	})
	const formData = stateManager.getState().addFormData
	if (
		!formData.paymentNumberType ||
		!formData.paymentNumber ||
		!formData.proNumber
	) {
		stateManager.produce((ds) => {
			ds.addErrorMessage = tString(
				'page.providerPortal.customers.addCustomer.fillAllfields',
			)
		})
	} else {
		const data = await tms2_providerPortal.addCustomer({
			addForm: stateManager.getState().addFormData,
		})
		if (data.success) {
			stateManager.produce((ds) => {
				ds.addFormData = formUtils.createDefaultFormStringData(
					addCustomerFormMetadata,
				)
				ds.addFormShowing = false
			})
			await getCustomers()
		} else {
			stateManager.produce((ds) => {
				ds.addErrorMessage = data.message
			})
		}
	}
}
