/**
 * Shamelessly stolen from
 * https://github.com/the-road-to-learn-react/react-hooks-introduction
 */
import { useState, useEffect, useReducer } from 'react'
import { callApi, RequestOptions } from './api'
import { logSuccess, logError } from 'ui/lib/log'
import { fireAndForget } from 'ui/lib/async'

const LOGNAMESPACE = 'useDataApi'

const dataFetchReducer = (state, action): any => {
	switch (action.type) {
		case 'FETCH_INIT':
			return { ...state, isLoading: true, isError: false }
		case 'FETCH_SUCCESS':
			return {
				...state,
				isLoading: false,
				isError: false,
				data: action.payload,
			}
		case 'FETCH_FAILURE':
			return {
				...state,
				isLoading: false,
				isError: true,
				data: { error: action.error },
			}
		default:
			throw new Error()
	}
}

export type UseDataApiParams = {
	path: string
	options: RequestOptions
	params?: {
		[key: string]: any
	}
}

export function useDataApi<T>(
	initOpts?: UseDataApiParams,
	initData?,
): [
	{ data; isLoading: boolean; isError: boolean },
	React.Dispatch<React.SetStateAction<UseDataApiParams>>,
] {
	initOpts = {
		path: initOpts.path || '',
		options: initOpts.options || { method: 'GET' },
		params: initOpts.params || null,
	}
	const [options, setOptions] = useState(initOpts)

	const [state, dispatch] = useReducer(dataFetchReducer, {
		isLoading: false,
		isError: false,
		data: initData,
	})

	useEffect(() => {
		let didCancel = false

		const fetchData = async (): Promise<void> => {
			dispatch({ type: 'FETCH_INIT' })

			try {
				const result = await callApi(
					options.options,
					options.path,
					options.params,
				)

				if (!didCancel) {
					logSuccess(LOGNAMESPACE, 'Success in useDataApi', result)

					dispatch({ type: 'FETCH_SUCCESS', payload: result.data })
				}
			} catch (error) {
				logError(LOGNAMESPACE, 'Error in useDataApi ', error)

				if (!didCancel) {
					dispatch({ type: 'FETCH_FAILURE', error: error })
				}
			}
		}

		fireAndForget(fetchData, 'useDataApiUseEffect')

		return () => {
			didCancel = true
		}
	}, [options])

	return [state, setOptions]
}
