import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { SearchInput } from 'ui/components/common/search'
import { SmallButton } from 'ui/components/common/small-button'
import { useOnce } from 'ui/components/hooks'
import { t, tString } from 'ui/components/i18n/i18n'
import { DataTable, IDataTableHeader } from 'ui/components/table'
import { fireAndForget } from 'ui/lib/async'
import classes from 'ui/pages/provider-portal/customers/Customers.module.scss'
import { sosProviderPortalCustomers } from 'ui/state'
import { ICustomerListRow } from 'ui/state/sosProviderPortalCustomers'
import { ProviderPortalLayout } from '../ProviderPortalLayout'
import { CustomersTableAdd } from './CustomersTableAdd'

const headers: IDataTableHeader<ICustomerListRow>[] = [{ field: 'name' }]

export const Customers: FC = (props: {}) => {
	const state = sosProviderPortalCustomers.useSubscribe()
	useOnce(() => {
		fireAndForget(
			sosProviderPortalCustomers.getCustomers,
			'getting provider portal customers',
		)
	})
	return (
		<ProviderPortalLayout selectedPage='customers'>
			<div className={classes.searchAndAddButtonContainer}>
				<div className={classes.customerSearch}>
					<SearchInput
						value={state.searchValue}
						onChange={sosProviderPortalCustomers.updateSearch}
						placeholder={tString('search', 'page.providerPortal.customers')}
					/>
				</div>
				{!state.addFormShowing && (
					<div className={classes.addButton}>
						<SmallButton
							color='blue'
							onClick={sosProviderPortalCustomers.toggleShowAddCustomer}
						>
							{t('page.providerPortal.customers.addCustomer.button')}
						</SmallButton>
					</div>
				)}
			</div>
			<div>
				<CustomersTableAdd />
			</div>
			<div className={classes.customersDataTable}>
				<DataTable
					testId={'page.providerPortal.customers.dataTable'}
					tPrefix={'page.providerPortal.customers.dataTable'}
					data={state.dataCustomers}
					state={state.dataTableStateCustomers}
					headers={headers}
					spacerCell={false}
				/>
			</div>
		</ProviderPortalLayout>
	)
}
