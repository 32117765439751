import { IFormMetadataCollection } from 'ui/components/form/IFormMetadata'
import { apiTypes } from 'ui/api'

export type carrierType = 'truckload' | 'ltl'
export const carrierTypes: carrierType[] = ['truckload', 'ltl']

export interface IBillToThirdPartyForm {
	carrierType: apiTypes.BillTo['carrierType']
	carrierName: string
	proNumber: string
	usingBroker: string
	accountNumber: string
}

export const billToThirdPartyFormMetadata: IFormMetadataCollection<IBillToThirdPartyForm> = {
	carrierType: {
		required: true,
		options: carrierTypes,
	},
	carrierName: {
		required: true,
	},
	proNumber: {},
	accountNumber: {},
	usingBroker: {},
}
