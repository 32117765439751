import React from 'react'
import { FC } from 'app/FunctionalComponent'
import { apiTypes } from 'ui/api'
import { Button } from 'ui/components/common/button/Button'
import { t } from 'ui/components/i18n/i18n'
import { RateRow } from 'ui/components/rates-table'
import { SelectBrokerRateButton } from 'ui/pages/shipment-profile/broker/components/select-broker-rate-button'
import { SpotQuoteButtons } from 'ui/pages/shipment-profile/broker/components/spot-quote-button'
import classes from 'ui/pages/shipment-profile/shared/components/rates/ShipmentProfileRateInteraction.module.scss'
import { RateBookingDetails } from 'ui/pages/shipment-profile/shared/functions/rateBookingDetailsTypes'

export const BrokerRateInteractionButton: FC = (props: {
	rateRow: RateRow
	shipment: apiTypes.BrokerShipmentResponse
	tPrefix: string
	shipmentIsRating: boolean
	markupLogic: apiTypes.MarkupLogic
	setRateBookingDetails: React.Dispatch<
		React.SetStateAction<RateBookingDetails>
	>
	rateBookingDetails: RateBookingDetails
}) => {
	const {
		rateRow,
		shipment,
		tPrefix,
		shipmentIsRating,
		markupLogic,
		setRateBookingDetails,
		rateBookingDetails,
	} = props

	const brokerOffer = shipment.offers[0]

	switch (rateRow.offerStatus) {
		case 'active': {
			if (rateRow.quoteType === 'contract') {
				return (
					<SelectBrokerRateButton
						rateRow={rateRow}
						shipment={shipment}
						tPrefix={tPrefix}
						shipmentIsRating={shipmentIsRating}
						brokerOffer={brokerOffer}
						markupLogic={markupLogic}
						setRateBookingDetails={setRateBookingDetails}
						rateBookingDetails={rateBookingDetails}
					/>
				)
			} else {
				return (
					<>
						<Button
							color='red'
							isSmall={true}
							bootstrapStyles={true}
							isOutline={true}
							isDisabled={true}
							className={classes.ratesTableBadge}
						>
							<div
								className={classes.buttonText}
								data-testid={'awaiting-badge'}
							>
								{t('awaitingOffer', tPrefix)}
							</div>
						</Button>
						<SpotQuoteButtons
							shipment={shipment}
							rateRow={rateRow}
							tPrefix={tPrefix}
						/>
					</>
				)
			}
		}

		case 'retracted': {
			return (
				<Button
					color='gray'
					isSmall={true}
					bootstrapStyles={true}
					isOutline={true}
					isDisabled={true}
					className={classes.ratesTableBadge}
				>
					<div className={classes.buttonText} data-testid={'retracted-badge'}>
						{t('inviteRetracted', tPrefix)}
					</div>
				</Button>
			)
		}

		case 'booked': {
			return (
				<>
					<Button
						color='green'
						isSmall={true}
						bootstrapStyles={true}
						isOutline={true}
						isDisabled={true}
						className={classes.ratesTableBadge}
					>
						<div className={classes.buttonText} data-testid='booked-badge'>
							{t('booked', tPrefix)}
						</div>
					</Button>
					{rateRow.quoteType === 'spotQuote' && (
						<SpotQuoteButtons
							shipment={shipment}
							rateRow={rateRow}
							tPrefix={tPrefix}
						/>
					)}
				</>
			)
		}

		default: {
			return (
				<>
					<SelectBrokerRateButton
						rateRow={rateRow}
						shipment={shipment}
						tPrefix={tPrefix}
						shipmentIsRating={shipmentIsRating}
						brokerOffer={brokerOffer}
						markupLogic={markupLogic}
						setRateBookingDetails={setRateBookingDetails}
						rateBookingDetails={rateBookingDetails}
					/>
					{rateRow.quoteType === 'spotQuote' && (
						<SpotQuoteButtons
							shipment={shipment}
							rateRow={rateRow}
							tPrefix={tPrefix}
						/>
					)}
				</>
			)
		}
	}
}
