import { tArgz } from 'ui/components/i18n/i18n'
import { sosToast } from 'ui/common/components/toast'

export const validateEmail = (
	formEmails: string,
	tPrefix?: string,
): string[] => {
	if (formEmails) {
		const regex = new RegExp(
			/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
		)
		const emails = splitEmail(formEmails)
		const validEmails: string[] = []
		const invalidEmails: string[] = []

		emails.forEach((email) => {
			const valid = regex.test(email)

			if (valid) {
				validEmails.push(email)
			} else {
				invalidEmails.push(email)
			}
		})

		if (invalidEmails.length) {
			sosToast.sendToast({
				header: tArgz(
					'invalidEmailWarning',
					{ invalidEmail: invalidEmails[0] },
					tPrefix,
				),
				type: 'danger',
				dataTestId: 'warning-invalid-email-toast',
			})
		} else {
			return validEmails
		}
	}
}

export const splitEmail = (emails: string): string[] => {
	return emails.split(',').map((item) => item.trim())
}
