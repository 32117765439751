import React, { useState } from 'react'
import { FC } from 'app/FunctionalComponent'
import { sosToast } from 'common/components/toast'
import { apiBroker, apiTypes } from 'ui/api'
import { LinkButton } from 'ui/components/common/link'
import {
	getWindow,
	windowExists,
	windowOpen,
} from 'ui/components/common/router/windowUtils'
import { t, tString } from 'ui/components/i18n/i18n'
import { RateRow } from 'ui/components/rates-table'
import { l } from 'ui/lib/lodashImports'
import { sosShipmentProfileBroker } from 'ui/pages/shipment-profile/broker'
import * as classes from './SpotQuoteButton.module.scss'

export const SpotQuoteButtons: FC = (props: {
	shipment: apiTypes.BrokerShipmentResponse
	rateRow: RateRow
	tPrefix: string
}) => {
	const { shipment, rateRow, tPrefix } = props
	const [resendingInvite, updateResendingInvite] = useState(false)
	const [retractingInvte, updateRetractingInvite] = useState(false)
	if (
		shipment.brokerShipmentStatus === 'booked' &&
		rateRow.offerStatus !== 'booked'
	) {
		return null
	}
	return (
		<span data-testid={'spot-quote-buttons'}>
			{shipment.brokerShipmentStatus !== 'booked' &&
				rateRow.offerStatus === 'active' && (
					<>
						<LinkButton
							onClick={async () => {
								updateResendingInvite(true)
								const result = await apiBroker.inviteConnections(
									() => {},
									shipment.id,
									shipment,
									[rateRow.rateId],
									true,
								)
								if (result.error) {
									sosToast.sendApiErrorResponseToast(
										result,
										tString('resendInviteError', tPrefix),
									)
								}
								updateResendingInvite(false)
							}}
							testId={'broker-resend-connection-invite'}
							isSpinning={resendingInvite}
							isDisabled={retractingInvte}
							className={classes.spotQuoteButton}
						>
							{t('resendInvite', tPrefix)}
						</LinkButton>
						<LinkButton
							onClick={async () => {
								updateRetractingInvite(true)
								const rate = shipment.rates.find(
									(rate) => rate.id === rateRow.rateId,
								)
								if (rate) {
									const rateRequest: apiTypes.RateRequest = l.cloneDeep(rate)
									rateRequest.offerStatus = 'retracted'
									const result = await apiBroker.updateConnectionOffer(
										() => {},
										shipment.id,
										rateRequest,
									)
									if (result.error) {
										sosToast.sendApiErrorResponseToast(
											result,
											tString('retractInviteError', tPrefix),
										)
									}
								}
								await sosShipmentProfileBroker.fetchShipment(shipment.id)
								updateRetractingInvite(false)
							}}
							isSpinning={retractingInvte}
							isDisabled={resendingInvite}
							testId={'broker-retract-connection-invite'}
							className={classes.spotQuoteButton}
						>
							{t('retractInvite', tPrefix)}
						</LinkButton>
					</>
				)}
			<LinkButton
				onClick={() => {
					if (windowExists) {
						windowOpen(
							getWindow().location.origin +
								getWindow().location.pathname +
								`#/shipments-v3/brokers/make-offer/${shipment.id}/${rateRow.offerId}`,
						)
					}
				}}
				testId={'broker-override-connection-offer'}
				className={classes.spotQuoteButton}
			>
				{t('overrideOffer', tPrefix)}
			</LinkButton>
		</span>
	)
}
