import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { mapHelpers } from './MapHelpers'
import { l } from 'ui/lib/lodashImports'
import { googleMapApiKey } from 'ui/secrets/secrets'
import { IAddress } from 'ui/components/shared/address'

const StaticMap: FC = (props: {
	addresses: IAddress[]
	width: number
	height: number
}) => {
	const { addresses, width, height } = props
	const markers = []
	l.forEach(addresses, (address) => {
		const color = mapHelpers.identifyMarkerColor(address)
		markers.push(mapHelpers.formatAddressForMap(address, color))
	})
	const apiUrl =
		'http://maps.googleapis.com/maps/api/staticmap?size=' +
		width +
		'x' +
		height +
		'&maptype=roadmap' +
		'&markers=' +
		markers.join('&markers=') +
		'&key=' +
		googleMapApiKey
	return (
		<div>
			<img src={apiUrl} alt='Map' />
		</div>
	)
}
export { StaticMap }
