import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { Separator } from 'ui/components/common/separator'
import {
	FormRow,
	FormStacked,
	FormStackedSelect,
	FormStackedTextInput,
	FormVerticalTextInput,
} from 'ui/components/form'
import { IFormData } from 'ui/components/form/IFormData'
import { t } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight/AlignRight'
import { Spacer } from 'ui/components/layout/spacer/Spacer'
import { rendererRateCents } from 'ui/components/rates-table/RatesTableRenderers'
import { l } from 'ui/lib/lodashImports'
import { sos2 } from 'ui/lib/state/sos2'
import { HeaderText } from 'ui/pages/spot-quote/MakeOfferPage.tsx'
import { sosMakeOfferPage } from '.'
import { LabelText } from '../new-quote'
import * as classes from './MakeOfferPage.module.scss'
import { tPrefixMakeOffer } from './OfferDetailsCard'
import { IOfferInformation, IProviderInformation } from './sosMakeOfferPage'

export const OfferInformationForm: FC = (props: {
	readOnly: boolean
	formData: IFormData<IOfferInformation & IProviderInformation>
}) => {
	const state = sos2.useSubscription(sosMakeOfferPage.sos)
	const { offerInformation, shipment } = state
	const { readOnly } = props

	const formData = l.cloneDeep(props.formData)
	formData.onUpdateForm = sosMakeOfferPage.updateOfferInformationForm

	const accessorialFormData = sosMakeOfferPage.getAccessorialFormData(shipment)

	const formStackTextInputWidth = '235px'

	return (
		<div>
			<div className={classes.offerDetailsItem}>
				<HeaderText large={true}>
					{t('offerInformation', tPrefixMakeOffer)}
				</HeaderText>
				<Spacer />
				<FormStacked>
					<FormStackedTextInput field='transitDays' formData={formData} />
					<FormRow>
						<FormStackedTextInput field='linehaulCost' formData={formData} />
						<Spacer />
						<FormStackedSelect
							field={'linehaulCostType'}
							formData={formData}
							options={['flat', 'perMile']}
							tPrefix={tPrefixMakeOffer}
							readOnly={readOnly}
						/>
					</FormRow>

					<FormRow>
						<FormStackedSelect
							field={'fuelCostType'}
							formData={formData}
							options={['included', 'perMile', 'percent']}
							tPrefix={tPrefixMakeOffer}
							readOnly={readOnly}
							hideOptional={true}
						/>
						{offerInformation.fuelCostType !== 'included' && (
							<FormStackedTextInput field='fuelCost' formData={formData} />
						)}
					</FormRow>

					<FormRow>
						<FormStackedSelect
							field='currencyCode'
							formData={formData}
							options={['USD']}
							readOnly={readOnly}
							hideOptional={true}
						/>
					</FormRow>
					{Object.keys(accessorialFormData.form).length > 0 && (
						<>
							<Separator color='gray' />
							<div className={classes.indent}>
								{l.map(
									accessorialFormData.form,
									(_accessorialValue, accessorial) => {
										return (
											<div key={accessorial}>
												<FormVerticalTextInput
													field={accessorial}
													formData={accessorialFormData}
												/>
											</div>
										)
									},
								)}
							</div>
							<Separator color='gray' />
						</>
					)}

					<FormRow>
						<FormStackedTextInput field='otherCharge' formData={formData} />
					</FormRow>
					<FormRow>
						<FormStackedTextInput
							field='estimatedMilesDistance'
							formData={formData}
						/>
					</FormRow>
				</FormStacked>
				<AlignRight vertical={true}>
					<table className={classes.totalTable}>
						<tbody>
							<tr>
								<td>
									<LabelText>
										{t(
											offerInformation.fuelCostType !== 'included'
												? 'linehaulTotal'
												: 'linehaulTotalWithFuel',
											tPrefixMakeOffer,
										)}
									</LabelText>
								</td>
								<td data-testid='linehaulCost'>
									{rendererRateCents(
										sosMakeOfferPage.getLinehaulTotalCents(
											state.offerInformation,
										),
									)}
								</td>
							</tr>
							{offerInformation.fuelCostType !== 'included' && (
								<tr>
									<td>
										<LabelText>{t('fuelTotal', tPrefixMakeOffer)}</LabelText>
									</td>
									<td>
										{rendererRateCents(
											sosMakeOfferPage.getFuelTotalCents(
												state.offerInformation,
											),
										)}
									</td>
								</tr>
							)}
							<tr>
								<td>
									<LabelText>
										{t('accessorialsTotal', tPrefixMakeOffer)}
									</LabelText>
								</td>
								<td>
									{rendererRateCents(
										sosMakeOfferPage.getAccessorialsTotalWithOtherCents(
											state.offerInformation,
										),
									)}
								</td>
							</tr>

							<tr>
								<td style={{ fontWeight: 'bold' }}>
									<LabelText>{t('quoteTotal', tPrefixMakeOffer)}</LabelText>
								</td>
								<td data-testid={'make-offer-quote-total'}>
									{rendererRateCents(
										sosMakeOfferPage.getQuoteTotalCents(state.offerInformation),
									)}
								</td>
							</tr>
						</tbody>
					</table>
				</AlignRight>
				<Separator color='gray' />
				<Spacer />
				<FormStacked>
					<FormStackedTextInput
						field='message'
						formData={formData}
						multiline={true}
						notResizable={true}
						rows={3}
						width={formStackTextInputWidth}
					/>
					<FormStackedTextInput
						field='quoteNumber'
						formData={formData}
						width={formStackTextInputWidth}
					/>
					<FormStackedTextInput
						field='priceExpires'
						formData={formData}
						width={formStackTextInputWidth}
					/>
				</FormStacked>
			</div>
		</div>
	)
}
