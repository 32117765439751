import { apiTypes } from 'ui/api'
import { l } from 'ui/lib/lodashImports'

export const filterRatesForRatesTable = (
	rates: apiTypes.RateResponse[],
): apiTypes.RateResponse[] => {
	return l.filter(
		rates,
		(rate) =>
			rate.offerStatus !== 'canceled' &&
			rate.offerStatus !== 'void' &&
			rate.providerName !==
				('truckstop.com' as apiTypes.RateResponse['providerName']) &&
			rate.quoteType !== 'brokerOffer',
	)
}
