import { FC } from 'app/FunctionalComponent'
import { sosToast } from 'common/components/toast'
import { DateTime } from 'luxon'
import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { apiBroker, apiTypes } from 'ui/api'
import { Button } from 'ui/components/common/button/Button'
import { Modal } from 'ui/components/common/modal'
import { ISelectOptions } from 'ui/components/common/select'
import {
	FormStacked,
	FormStackedItem,
	FormStackedSelect,
	FormStackedTextInput,
	FormVerticalCheckbox,
	IFormData,
} from 'ui/components/form'
import { tTrackingStatus } from 'ui/components/i18n/commonTranslations'
import { t, tString } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight'
import {
	getCountryNameAndCodeList,
	getRegionsForCountry,
} from 'ui/data/countryAndRegionUtils'
import { l } from 'ui/lib/lodashImports'
import { validateForm } from 'ui/lib/validation/formValidator'
import { sosShipmentProfileBroker } from 'ui/pages/shipment-profile/broker'
import * as classes from './AddTrackingEventModal.module.scss'

const tPrefix = 'page.shipmentProfile.broker.tracking'

interface AddEventForm {
	status: apiTypes.TrackingStatus
	eventDescription: string
	city: string
	state: string
	country: string
	date: string // ISO datestring
	internal: boolean
	payloadId?: string
}

const trackingEventStatuses: apiTypes.TrackingStatus[] = [
	'dispatched',
	'arrangePickup',
	'inTransit',
	'delayed',
	'delivered',
	'returned',
	'canceled',
]

export const AddTrackingEventModal: FC = (props: {
	isOpen: boolean
	onClose: () => void
	shipment: apiTypes.BrokerShipmentResponse
}) => {
	const { shipment, isOpen, onClose } = props
	const [creatingEventOnAPI, setCreatingEventOnAPI] = useState(false)
	const [addEventForm, setAddEventForm] = useState<AddEventForm>({
		status: null,
		eventDescription: null,
		city: null,
		state: null,
		country: 'US',
		date: DateTime.local().toUTC().toISO(),
		internal: true,
		payloadId: null,
	})
	const isParcelShipment = shipment.bookedRate?.method === 'parcel'
	const formData: IFormData<AddEventForm> = {
		form: addEventForm,
		metadata: {
			status: {
				required: true,
				options: trackingEventStatuses.map(
					(status: apiTypes.TrackingStatus): ISelectOptions => {
						return {
							label: tTrackingStatus(status),
							value: status,
						}
					},
				),
			},
			eventDescription: { multiline: true },
			city: {},
			state: {
				options: getRegionsForCountry(addEventForm.country, true, true),
			},
			country: {
				options: getCountryNameAndCodeList().map((countryData) => {
					return {
						label: countryData.countryName,
						value: countryData.countryCode,
					}
				}),
			},
			date: { required: true },
			internal: {},
			payloadId: {
				required: isParcelShipment,
				options: l.compact(
					shipment.payloads.map(
						(payload): ISelectOptions => {
							if (payload.trackingNumber) {
								return {
									label: payload.trackingNumber,
									value: payload.id,
								}
							} else {
								return null
							}
						},
					),
				),
			},
		},
		tPrefix,
		onUpdateForm: (field: string, value: any) => {
			const updatedFormData = l.cloneDeep(addEventForm)
			updatedFormData[field] = value
			setAddEventForm(updatedFormData)
		},
	}
	const formIsValid = validateForm(formData.form, formData.metadata).isValid
	return (
		<Modal
			isOpen={isOpen}
			title={t('addEventTitle', tPrefix)}
			onModalClose={onClose}
			closeModalX={true}
			content={() => (
				<div
					className={'bootstrap-wrapper'}
					data-testid={'add-tracking-event-modal'}
				>
					<Container>
						<Row>
							<Col xs={6} className={classes.addEventModalHalf}>
								<FormStacked fullWidth={true}>
									<FormStackedSelect
										formData={formData}
										field={'status'}
										width={'100%'}
									/>
									<FormStackedTextInput
										formData={formData}
										field='eventDescription'
									/>
									<FormStackedTextInput formData={formData} field={'city'} />
									<FormStackedSelect
										formData={formData}
										field={'state'}
										numListOptionsBeforeScroll={4}
										width={'100%'}
									/>
									<FormStackedSelect
										formData={formData}
										field={'country'}
										numListOptionsBeforeScroll={4}
										width={'100%'}
									/>
								</FormStacked>
							</Col>
							<Col xs={6} className={classes.addEventModalHalf}>
								<FormStacked fullWidth={true}>
									<FormStackedItem formData={formData} field={'date'}>
										<div
											className={`date-picker-wrapper ${classes.datePickerWrapper}`}
										>
											<DatePicker
												showTimeInput={true}
												selected={new Date(formData.form.date)}
												onChange={(date: Date) =>
													formData.onUpdateForm('date', date.toISOString())
												}
												dateFormat={'MM/dd/yyyy h:mm aa'}
												className={`form-control form-control-sm ${classes.dateTimePickerInput}`}
												wrapperClassName={classes.componentWrapper}
											/>
										</div>
									</FormStackedItem>
									<div className={classes.internalCheckboxWrapper}>
										<span className={classes.internalCheckboxWrapper2}>
											<FormVerticalCheckbox
												formData={formData}
												field={'internal'}
											/>
										</span>
									</div>
									{isParcelShipment && (
										<FormStackedSelect
											formData={formData}
											field={'payloadId'}
											width={'100%'}
										/>
									)}
								</FormStacked>
							</Col>
						</Row>
						<AlignRight>
							<Button
								color='green'
								onClick={async () => {
									setCreatingEventOnAPI(true)
									let error
									if (isParcelShipment) {
										const payload = l.find(
											shipment.payloads,
											(payload) => payload.id === addEventForm.payloadId,
										)
										const response = await apiBroker.createTrackingEvent(
											() => {},
											shipment.id,
											addEventForm.payloadId,
											{
												address: {
													city: addEventForm.city,
													state: addEventForm.state,
													country: addEventForm.country,
												},
												timestamp: addEventForm.date,
												event: addEventForm.eventDescription,
												status: addEventForm.status,
												internalOnly: addEventForm.internal,
												eventSource: 'userInput',
												trackingNumber: payload.trackingNumber,
											},
										)
										error = response.error
									} else {
										const addEventRequest: apiTypes.BrokerTrackingEventRequest = {
											address: {
												city: addEventForm.city,
												state: addEventForm.state,
											},
											timestamp: addEventForm.date,
											event: addEventForm.eventDescription,
											status: addEventForm.status,
											internalOnly: addEventForm.internal,
											eventSource: 'userInput',
										}
										const responses = await Promise.all(
											shipment.payloads.map(
												async (payload) =>
													await apiBroker.createTrackingEvent(
														() => {},
														shipment.id,
														payload.id,
														addEventRequest,
													),
											),
										)
										error = l.compact(
											responses.map((response) =>
												response.error ? response : null,
											),
										)[0]
									}
									if (error) {
										sosToast.sendApiErrorResponseToast(
											error,
											tString('createEventError', tPrefix),
										)
									}
									await sosShipmentProfileBroker.fetchShipment(shipment.id)
									setCreatingEventOnAPI(false)
									if (!error) {
										setAddEventForm({
											status: null,
											eventDescription: null,
											city: null,
											state: null,
											country: 'US',
											date: DateTime.local().toUTC().toISO(),
											internal: true,
											payloadId: null,
										})
										onClose()
									}
								}}
								isSpinning={creatingEventOnAPI}
								isDisabled={!formIsValid}
								testId={'submit-create-event'}
							>
								{t('add', tPrefix)}
							</Button>
						</AlignRight>
					</Container>
				</div>
			)}
		/>
	)
}
