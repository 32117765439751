import { sosRouter } from 'ui/components/common/router'
import { inJestUnitTest } from 'ui/lib/testing/inJestUnitTest'
import { log } from 'ui/lib/log/log'

export function navTo(href: string, replace = false): boolean {
	if (inJestUnitTest()) {
		return false
	}
	log('router', 'deprecated navTo found')
	log('router', 'navigating to', href)
	sosRouter.navigate(href, replace)
}
