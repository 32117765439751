import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { FilterItem } from 'ui/components/common/filters/FilterItem'
import { Filters } from 'ui/components/common/filters/Filters'
import { FilterSeparator } from 'ui/components/common/filters/FilterSeparator'
import { t } from 'ui/components/i18n/i18n'
import { FlexRow } from 'ui/components/layout/flexRow'
import { l } from 'ui/lib/lodashImports'

const allFilter = [{ name: 'all' }]

const invoiceFilters = [
	{ name: 'review' },
	{ name: 'approved' },
	{ name: 'invoiced' },
	{ name: 'paid_in_full' },
]

function insertFilterItem(
	filters,
	selectedFilter,
	props,
	tPrefix,
): React.ReactElement[] {
	return l.map(filters, (c) => (
		<FilterItem
			key={c.name}
			isSelected={selectedFilter === c.name}
			onClick={() => {
				props.onChangeFilter(c.name)
			}}
		>
			{t(`${tPrefix}.${l.camelCase(c.name)}`)}
		</FilterItem>
	))
}

export const InvoicesFilter: FC = (props: {
	selectedFilter: string
	onChangeFilter: (newVal: string) => void
	tPrefix: string
}) => {
	const { tPrefix } = props
	const selectedFilter = props.selectedFilter || 'all'

	return (
		<FlexRow mode={'center'} fullWidth={true}>
			<Filters>
				{insertFilterItem(allFilter, selectedFilter, props, tPrefix)}
				<FilterSeparator />
				{insertFilterItem(invoiceFilters, selectedFilter, props, tPrefix)}
			</Filters>
		</FlexRow>
	)
}
