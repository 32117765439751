import React from 'react'
import { ISelectOptions } from 'ui/components/common/select/ISelectOptions'
import { Select } from 'ui/components/common/select/Select'
import { Autocomplete } from '../common/autocomplete'
import { FormReadOnly } from './FormReadOnly'

export function FormSelect<T>(props: {
	form: T
	field: keyof T
	onUpdateForm: (field: keyof T, newVal: string) => void
	options: string[] | ISelectOptions[]
	id?: string
	name?: string
	testId?: string
	readOnly?: boolean
	autocomplete?: boolean
	isClearable?: boolean
	tPrefix?: string
	width?: string
	numListOptionsBeforeScroll?: number
}): React.ReactElement {
	const { form, options, field, tPrefix } = props
	let testId = props.testId

	const onChange = (newVal: string): void => {
		props.onUpdateForm(field, newVal)
	}
	const val = form[field] ? '' + form[field] : 'unknown'

	testId = 'form-' + (testId || field)

	if (props.readOnly) {
		// if (props.options.length > 0 && typeof props.options !== 'string') {
		// 	props.options.forEach((option) => {
		// 		const x: ISelectOptions = option as ISelectOptions
		// 		if (x.value === val) {
		// 			val = x.label || x.value
		// 		}
		// 	})
		// }

		return <FormReadOnly form={form} field={field} tPrefix={tPrefix} />
	} else if (props.autocomplete) {
		return (
			<Autocomplete
				type='autocomplete'
				onChange={onChange}
				value={val}
				name={props.name}
				options={options}
				testId={testId}
				tPrefix={props.tPrefix}
				width={props.width}
				numListOptionsBeforeScroll={props.numListOptionsBeforeScroll}
			/>
		)
	} else {
		return (
			<Select
				width={props.width}
				onChange={onChange}
				value={val}
				name={props.name}
				options={options}
				testId={testId}
				isClearable={props.isClearable}
				tPrefix={props.tPrefix}
				numListOptionsBeforeScroll={props.numListOptionsBeforeScroll}
			/>
		)
	}
}
