import { FC } from 'app/FunctionalComponent'
import { DateTime } from 'luxon'
import React from 'react'
import { Button } from 'ui/components/common/button'
import {
	FormStacked,
	FormStackedReadOnly,
	FormStackedSelect,
	FormStackedTextInput,
	FormVerticalCheckbox,
	IFormData,
} from 'ui/components/form'
import { validateFormData } from 'ui/components/form/validateFormData'
import { t } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight'
import { fireAndForget } from 'ui/lib/async'
import { l } from 'ui/lib/lodashImports'
import { sosDockScheduler, sosDockSchedulerAppointmentDetails } from '../state'
import { IStateDockSchedulerAppointmentDetails } from '../state/sosDockSchedulerAppointmentDetails'
import { DockSchedulerAppointmentDetailsCard } from './DockSchedulerAppointmentDetailsCard'
import {
	checkInMetaData,
	IAppointmentDetailsCheckInForm,
} from './DockSchedulerAppointmentDetailsForms'

const tPrefix = 'page.dockScheduler.appointmentDetails.checkIn'

export const DockSchedulerAppointmentDetailsCheckIn: FC = (props: {
	state: IStateDockSchedulerAppointmentDetails
	isActive: boolean
}) => {
	const { state, isActive } = props

	const checkInFormData: IFormData<IAppointmentDetailsCheckInForm> = {
		form: state.checkInForm,
		metadata: l.cloneDeep(checkInMetaData),
		tPrefix,
		onUpdateForm: (field, newVal) => {
			const changes = {}
			changes[field] = newVal
			sosDockSchedulerAppointmentDetails.updateCheckIn(changes)
		},
	}

	let checkInTimeShowWarning = false
	if (!isActive && state.checkInForm.checkInDateTime) {
		const reconstructedCheckInDateTime = DateTime.fromFormat(
			state.checkInForm.checkInDateTime,
			'D t',
		)
		checkInTimeShowWarning =
			reconstructedCheckInDateTime >
			DateTime.fromISO(state.appointment.arrivalTime)
	}

	const collator = new Intl.Collator(undefined, {
		numeric: true,
		sensitivity: 'base',
	})
	const dockOptions = sosDockScheduler
		.getSos()
		.getState()
		.docks.map((dock) => {
			return { label: dock.nickName, value: dock.id }
		})
		.sort((a, b) => collator.compare(a.label, b.label))

	if (!isActive) {
		checkInFormData.metadata.checkedLicense.readOnly = true
	}
	return (
		<DockSchedulerAppointmentDetailsCard
			cardType={'checkIn'}
			isActiveCard={isActive}
		>
			<FormStacked>
				{state.appointment.status !== 'not-arrived' && (
					<div>
						<FormStackedReadOnly
							formData={checkInFormData}
							field={'checkInDateTime'}
							showWarning={checkInTimeShowWarning}
						/>
						<FormStackedReadOnly
							formData={checkInFormData}
							field={'checkedInBy'}
						/>
					</div>
				)}
				<FormStackedSelect
					formData={checkInFormData}
					field={'dock'}
					options={dockOptions}
					readOnly={!isActive}
					width='100%'
				/>
				<FormStackedTextInput
					formData={checkInFormData}
					field={'tractorNumber'}
					readOnly={!isActive}
					hideOptional={true}
				/>
				<FormStackedTextInput
					formData={checkInFormData}
					field={'trailerNumber'}
					readOnly={!isActive}
					hideOptional={true}
				/>
				<FormStackedTextInput
					formData={checkInFormData}
					field={'weight'}
					readOnly={!isActive}
					hideOptional={true}
					testId={'arrivalWeight'}
				/>
				<FormStackedTextInput
					formData={checkInFormData}
					field={'driverName'}
					readOnly={!isActive}
					hideOptional={true}
				/>
				<FormStackedTextInput
					formData={checkInFormData}
					field={'checkInComments'}
					multiline={true}
					notResizable={true}
					readOnly={!isActive}
					hideOptional={true}
				/>
				<FormVerticalCheckbox
					formData={checkInFormData}
					field={'checkedLicense'}
				/>
				{isActive && (
					<AlignRight>
						<Button
							color='blue'
							isDisabled={!validateFormData(checkInFormData)}
							isSpinning={state.submitting}
							onClick={() =>
								fireAndForget(
									sosDockSchedulerAppointmentDetails.submitCheckIn,
									'submitting dock scheduler checkin from button click',
								)
							}
						>
							{t(
								state.appointment.status === 'not-arrived' ? 'submit' : 'save',
								tPrefix,
							)}
						</Button>
					</AlignRight>
				)}
			</FormStacked>
		</DockSchedulerAppointmentDetailsCard>
	)
}
