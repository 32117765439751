import { FC } from 'app/FunctionalComponent'
import * as midboss from 'midboss'
import React from 'react'
import { t } from 'ui/components/i18n/i18n'
import { Spacer } from 'ui/components/layout/spacer'
import {
	createDefaultAccessorialState,
	IAccessorialState,
} from 'ui/components/shared/accessorials'
import { Address } from 'ui/components/shared/address/Address'
import {
	createDefaultAddressState,
	IAddressState,
} from 'ui/components/shared/address/IAddressState'
import { ShipmentStopCard } from 'ui/components/shared/ShipmentStopCard'
import { l } from 'ui/lib/lodashImports'
import { r } from 'ui/lib/ramdaImports'
import { IStateComponentExamples } from 'ui/state/sosComponentExamples'
import { Section } from './Section'

interface ISectionShipmentStopCard {
	addressState: IAddressState
	accessorialState: IAccessorialState
}

const baseState: ISectionShipmentStopCard = {
	addressState: createDefaultAddressState({
		name: '',
		street1: '',
		city: '',
		state: '',
		zip: '',
		country: '',
	}),
	accessorialState: { fields: [] },
}

function updateNoneAddress(addressChanges: Partial<IAddressState>): void {
	stateManager.produce((ds) => {
		l.assign(ds.noneState.addressState, addressChanges)
	})
}
function updateDropdownAddress(addressChanges: Partial<IAddressState>): void {
	stateManager.produce((ds) => {
		l.assign(ds.dropdownState.addressState, addressChanges)
	})
}

function updateFullAddress(addressChanges: Partial<IAddressState>): void {
	stateManager.produce((ds) => {
		l.assign(ds.fullState.addressState, addressChanges)
	})
}

function updateBothAddress(addressChanges: Partial<IAddressState>): void {
	stateManager.produce((ds) => {
		l.assign(ds.bothState.addressState, addressChanges)
	})
}

const updateBothAccessorials = (
	accessorialChanges: Partial<IAccessorialState>,
): void => {
	stateManager.produce((ds) => {
		l.assign(ds.bothState.accessorialState, accessorialChanges)
	})
}

const noneExample = r.mergeDeepRight(l.cloneDeep(baseState), {
	addressState: { adressEditMode: 'readOnly' },
})

const dropdownExample = r.mergeDeepRight(l.cloneDeep(baseState), {
	addressState: { adressEditMode: 'readOnly' },
})

const fullExample = r.mergeDeepRight(l.cloneDeep(baseState), {
	addressState: { adressEditMode: 'customAddress' },
})

const bothExample = r.mergeDeepRight(l.cloneDeep(baseState), {
	addressState: { adressEditMode: 'customAddress' },
})
bothExample.accessorialState = createDefaultAccessorialState([])

const initialState: { [state: string]: ISectionShipmentStopCard } = {
	noneState: noneExample,
	dropdownState: dropdownExample,
	fullState: fullExample,
	bothState: bothExample,
}

// This is bad and needs to be replaced
const stateManager = midboss.createMidboss(
	'ship-orders-tab',
	'1.0.2',
	initialState,
	{
		useLocalStorage: true,
	},
)
function useSubscribe(): {
	[state: string]: ISectionShipmentStopCard
} {
	return midboss.useSubscription(stateManager)
}

const AddressGroup: FC = (props: { children: React.ReactNode }) => {
	return <fieldset>{props.children}</fieldset>
}

const SectionShipmentStopCard: FC = (props: {
	stateComponentExamples: IStateComponentExamples
}) => {
	const state = useSubscribe()

	const defaultAddress = {
		name: '',
		street1: '',
		city: '',
		state: '',
		zip: '',
		country: '',
	}
	const defaultAddressState1 = createDefaultAddressState(defaultAddress)
	defaultAddressState1.addressEditMode = 'readOnly'

	const defaultAddressState2 = createDefaultAddressState(defaultAddress)
	defaultAddressState1.addressEditMode = 'readOnly'

	const defaultAddressState3 = createDefaultAddressState(defaultAddress)
	defaultAddressState1.addressEditMode = 'readOnly'

	const defaultAddressState4 = createDefaultAddressState(defaultAddress)
	defaultAddressState1.addressEditMode = 'readOnly'

	return (
		<Section
			title={t('page.componentExamples.shipmentStopCard')}
			stateComponentExamples={props.stateComponentExamples}
			name='shipment-stop-card'
		>
			<div>
				<h2>Addresses</h2>
				<AddressGroup>
					<Address
						addressState={defaultAddressState1}
						updateAddressState={() => {}}
						filters={['companyLocation']}
					/>
				</AddressGroup>

				<AddressGroup>
					<Address
						addressState={defaultAddressState2}
						updateAddressState={() => {}}
						filters={['companyLocation', 'addressBook']}
					/>
				</AddressGroup>

				<AddressGroup>
					<Address
						addressState={defaultAddressState3}
						updateAddressState={() => {}}
						filters={['companyLocation']}
						showMap={true}
					/>
				</AddressGroup>

				<AddressGroup>
					<Address
						addressState={defaultAddressState4}
						updateAddressState={() => {}}
						filters={['companyLocation']}
						showAddressValidation={true}
					/>
				</AddressGroup>
			</div>

			<div>
				<pre>{`<ShipmentStopCard state={noneState} mode='pickup' addressEditMode={addressEditMode} />`}</pre>
				<Spacer />
				<ShipmentStopCard
					stopCardState={{
						addressState: state.noneState.addressState,
						updateAddress: updateNoneAddress,
						filters: ['companyLocation'],
					}}
					mode='pickup'
				/>
			</div>
			<div>
				<pre>{`<ShipmentStopCard state={dropdownExample} mode='pickup' addressEditMode={addressEditMode} />`}</pre>
				<pre>addressEditMode={'{ dropdown: true }'}</pre>
				<Spacer />
				<ShipmentStopCard
					stopCardState={{
						addressState: state.dropdownState.addressState,
						updateAddress: updateDropdownAddress,
						filters: ['companyLocation'],
					}}
					mode='pickup'
				/>
			</div>
			<div>
				<pre>{`<ShipmentStopCard state={fullExample} mode='delivery' addressEditMode={addressEditMode} />`}</pre>
				<pre>addressEditMode={"{ editForm: 'customAddress' }"}</pre>
				<Spacer />
				<ShipmentStopCard
					stopCardState={{
						addressState: state.fullState.addressState,
						updateAddress: updateFullAddress,
						filters: ['companyLocation', 'addressBook'],
					}}
					mode='delivery'
				/>
			</div>
			<div>
				<Spacer />
				<pre>{`<ShipmentStopCard state={bothExample} mode='delivery' addressEditMode={addressEditMode} />`}</pre>
				<pre>
					addressEditMode=
					{"{ dropdown: true, editForm: 'customAddress', addressBook: true }"}
				</pre>
				<pre>With accessorials</pre>
				<ShipmentStopCard
					stopCardState={{
						addressState: state.bothState.addressState,
						updateAddress: updateBothAddress,
						accessorialState: state.bothState.accessorialState,
						updateAccessorials: updateBothAccessorials,
						filters: ['companyLocation', 'addressBook'],
					}}
					mode='delivery'
				/>
			</div>
		</Section>
	)
}

export { SectionShipmentStopCard }
