import _ from 'lodash'
import { IAddress } from 'ui/components/shared/address/IAddress'

// TODO: move to common code repo
export function extractDestinationStopsFromShipment(shipment: any): IAddress[] {
	const destinationStop = []
	if (!shipment) {
		return destinationStop
	}
	_.forEach(shipment.payloads, (payload) => {
		destinationStop.push(payload.destinationStop.address)
	})
	return destinationStop
}

export function getFinalDestination(shipment: any): IAddress {
	if (!shipment) {
		return null
	}

	return (
		shipment.destination ||
		shipment.payloads[shipment.payloads.length - 1].destinationStop.address
	)
}
