import { apiCommon } from 'ui/api'

export const googleMapApiKey = 'AIzaSyCCCMBVfXYLSaYBf9l3lIaAeiikAhpgjR4'

export interface IBearerToken {
	bearerToken: string
	name: string
}
let savedBearerTokens: IBearerToken[] = []
export function getSavedBearerTokens(): IBearerToken[] {
	return savedBearerTokens
}

export async function fetchDevSavedBearerTokens(): Promise<void> {
	const result = await apiCommon.externalFetch<{
		savedBearerTokens: IBearerToken[]
	}>(() => {}, 'http://localhost:4600/get-secrets', {
		method: 'POST',
	})

	if (result.data) {
		savedBearerTokens = result.data.savedBearerTokens
	}
}
