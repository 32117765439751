import React, { useEffect, useState } from 'react'
import { FC } from 'ui/FunctionalComponent'
import { apiTypes } from 'ui/api'
import { TitledBorderBox } from 'ui/components/common/titled-border-box'
import { t, tCurrency } from 'ui/components/i18n/i18n'
import { tInvoiceStatus } from 'ui/components/i18n/commonTranslations'
import { Spacer } from 'ui/components/layout/spacer'
import { l } from 'ui/lib/lodashImports'
import * as classes from './CustomerInvoiceDetails.module.scss'
import { Loader } from 'ui/components/common/loader'
import { Row, Col } from 'react-bootstrap'
import { Center } from 'ui/components/layout/center'

interface CustomerInvoiceDetails {
	invoiceStatus: apiTypes.InvoiceStatus
	invoiceDate?: string
	invoiceDue?: string
	totalCharges: string
	totalPaid: string
	totalDue: string
	totalCost: string
	totalProfit: string
	markup: string
}

const defaultCustomerInvoiceDetails: CustomerInvoiceDetails = {
	invoiceStatus: 'review',
	invoiceDate: '',
	invoiceDue: '',
	totalCharges: '$0.00 USD',
	totalPaid: '$0.00 USD',
	totalDue: '$0.00 USD',
	totalCost: '$0.00 USD',
	totalProfit: '$0.00 USD',
	markup: '0',
}

const tPrefix = 'page.customerInvoice.customerInvoiceDetails'
const currencyInitial = ' USD'

export const CustomerInvoiceDetails: FC = (props: {
	isFetchingInvoices: boolean
	brokerInvoice: apiTypes.BrokerInvoiceResponse
	brokerInvoiceCharges: apiTypes.BrokerInvoiceChargeResponse[]
}) => {
	const { isFetchingInvoices, brokerInvoice, brokerInvoiceCharges } = props

	const [invoiceDetails, setInvoiceDetails] = useState<CustomerInvoiceDetails>(
		defaultCustomerInvoiceDetails,
	)

	useEffect(() => {
		if (brokerInvoice) {
			computeCustomerCharges()
		}
	}, [brokerInvoice, brokerInvoiceCharges]) // eslint-disable-line react-hooks/exhaustive-deps

	const formatDate = (date: string): string => {
		const dateArray = date.split('-')

		return dateArray[1] + '/' + dateArray[2] + '/' + dateArray[0]
	}

	const getMarkup = (totalCharges: number, totalCost: number): number => {
		const markup = ((totalCharges - totalCost) / totalCost) * 100

		return isNaN(markup) || markup < 0 || markup === Infinity ? 0 : markup
	}

	const computeCustomerCharges = (): void => {
		const brokerChargesTotal = brokerInvoiceCharges?.map(
			(charge) => charge.unitPrice * charge.qty,
		)
		const totalCharges = l.sum(brokerChargesTotal)
		const totalPaid =
			brokerInvoice.invoiceStatus === 'paid_in_full'
				? l.sum(
						brokerInvoice.charges.map(
							(charge) => charge.unitPrice * charge.qty,
						),
				  )
				: 0
		const totalDue = totalCharges - totalPaid
		const providerCostTotal = brokerInvoiceCharges?.map(
			(charge) => +charge.providerCost,
		) || [0]
		const totalCost = l.sum(providerCostTotal)
		const totalProfit = +totalCharges - +totalCost

		setInvoiceDetails({
			invoiceStatus: brokerInvoice.invoiceStatus,
			invoiceDate: brokerInvoice.invoiceDate,
			invoiceDue: brokerInvoice.invoiceDue,
			totalCharges: tCurrency(totalCharges) + currencyInitial,
			totalPaid: tCurrency(totalPaid) + currencyInitial,
			totalDue: tCurrency(totalDue) + currencyInitial,
			totalCost: tCurrency(totalCost) + currencyInitial,
			totalProfit: tCurrency(totalProfit) + currencyInitial,
			markup: `${getMarkup(totalCharges, totalCost).toFixed()}%`,
		})
	}

	return (
		<div>
			<Spacer height={'5px'} />
			<TitledBorderBox
				title={t('customerInvoiceDetailsTitle', tPrefix)}
				testId={'customer-invoice-details'}
				className={classes.customerInvoiceDetails}
			>
				{isFetchingInvoices ? (
					<Center>
						<Loader isLoading={isFetchingInvoices} />
					</Center>
				) : (
					<div className={classes.tableWrapper}>
						<Row>
							<Col xs={3}>
								<table>
									<tbody>
										<tr>
											<th>{t('invoiceStatus', tPrefix)}:</th>
											<td>{tInvoiceStatus(invoiceDetails.invoiceStatus)}</td>
										</tr>
										<tr>
											<th>{t('invoiceDate', tPrefix)}:</th>
											<td>
												{l.isNil(invoiceDetails?.invoiceDate)
													? '-'
													: formatDate(invoiceDetails?.invoiceDate)}
											</td>
										</tr>
										<tr>
											<th>{t('invoiceDue', tPrefix)}:</th>
											<td>
												{l.isNil(invoiceDetails?.invoiceDue)
													? '-'
													: formatDate(invoiceDetails?.invoiceDue)}
											</td>
										</tr>
									</tbody>
								</table>
							</Col>
							<Col xs={3}>
								<table>
									<tbody>
										<tr>
											<th>{t('totalCharges', tPrefix)}:</th>
											<td>{invoiceDetails.totalCharges}</td>
										</tr>
										<tr>
											<th>{t('totalPaid', tPrefix)}:</th>
											<td>{invoiceDetails.totalPaid}</td>
										</tr>
										<tr>
											<th>{t('totalDue', tPrefix)}:</th>
											<td>{invoiceDetails.totalDue}</td>
										</tr>
									</tbody>
								</table>
							</Col>
							<Col xs={3}>
								<table>
									<tbody>
										<tr>
											<th>{t('totalCost', tPrefix)}:</th>
											<td>{invoiceDetails.totalCost}</td>
										</tr>
										<tr>
											<th>{t('totalProfit', tPrefix)}:</th>
											<td>{invoiceDetails.totalProfit}</td>
										</tr>
										<tr>
											<th>{t('markup', tPrefix)}:</th>
											<td>{invoiceDetails.markup}</td>
										</tr>
									</tbody>
								</table>
							</Col>
							<Col xs={3} />
						</Row>
					</div>
				)}
			</TitledBorderBox>
		</div>
	)
}
