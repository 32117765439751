import React, { ReactElement, useState } from 'react'
import { Card } from 'react-bootstrap'
import { t } from 'ui/components/i18n/i18n'
import {
	FileDropZone,
	FileDropZoneData,
} from 'ui/components/shared/csvValidator/file-drop-zone'
import { l } from 'ui/lib/lodashImports'
import { Button } from '../button'
import { OkCancelButtons } from '../okCancelButtons'
import * as classes from './PictureUpload.module.scss'
import { Loader } from 'ui/components/common/loader'

const tPrefix = 'common.pictureUpload'

export const PictureUpload = (props: {
	onPictureUpload: (fileData: FileDropZoneData) => Promise<void>
	existingImageUrl?: string
	headerText?: ReactElement | string
}): ReactElement => {
	const [isEditingPicture, setIsEditingPicture] = useState(false)
	const [pictureData, setPictureData] = useState<FileDropZoneData>(null)
	const [isUploadingPicture, setIsUploadingPicture] = useState(false)

	const [isLoading, setIsLoading] = useState(true)
	const [showImage, setShowImage] = useState(true)

	return (
		<Card className={classes.pictureUploadCard}>
			<Card.Header>{props.headerText || t('picture', tPrefix)}</Card.Header>
			<Card.Body className={classes.pictureCenter}>
				{(isEditingPicture && !pictureData?.fileContent) ||
				!props.existingImageUrl ? (
					<FileDropZone
						onLoaded={(fileContent: string, filename: string) => {
							setPictureData({ fileName: filename, fileContent })
							setShowImage(true)
						}}
						preDropText={t('uploadImageHere', tPrefix)}
						droppingText={t('uploading', tPrefix)}
						testId={'picture-upload'}
						fileEncoding='dataUrl'
					/>
				) : null}

				<Loader isLoading={isLoading && !l.isNil(props.existingImageUrl)} />

				<img
					style={{
						display: showImage && !isLoading ? 'block' : 'none',
					}}
					src={
						isEditingPicture ? pictureData?.fileContent : props.existingImageUrl
					}
					className={classes.pictureImage}
					onLoad={() => {
						setIsLoading(false)
					}}
					alt={''}
				/>
			</Card.Body>
			<Card.Footer>
				{!isEditingPicture && (
					<Button
						onClick={() => {
							setIsEditingPicture(true)
							setShowImage(false)
						}}
						testId={'edit-picture'}
					>
						{t('edit', tPrefix)}
					</Button>
				)}
				{isEditingPicture && (
					<OkCancelButtons
						isValid={!l.isNil(pictureData?.fileContent)}
						okColor={'blue'}
						ok={t('uploadPicture', tPrefix)}
						cancel={t('cancelUpload', tPrefix)}
						onOk={async () => {
							setIsUploadingPicture(true)
							await props.onPictureUpload(pictureData)
							setIsUploadingPicture(false)
							setPictureData(null)
							setIsEditingPicture(false)
						}}
						onCancel={() => {
							setPictureData(null)
							setIsEditingPicture(false)
							setShowImage(true)
						}}
						isSpinning={isUploadingPicture}
						okTestId={'picture-upload-confirm'}
						cancelTestId={'picture-upload-cancel'}
					/>
				)}
			</Card.Footer>
		</Card>
	)
}
