import { FC } from 'app/FunctionalComponent'
import React, { useState } from 'react'
import { l } from 'ui/lib/lodashImports'
import styles from './CarrierImage.module.scss'

const errorList = []

export const CarrierImage: FC = (props: {
	carrier: string
	style?: React.CSSProperties
	showNameOnError?: boolean
	showName?: boolean
}) => {
	const [error, setError] = useState(false)

	let carrier = props.carrier

	if (!carrier) {
		return null
	}

	carrier = carrier.toLowerCase()
	carrier = carrier.replace(/\s/g, '_')

	if (error || l.some(errorList, (c) => c === carrier)) {
		if (props.showNameOnError || props.showName) {
			return <div>{props.carrier}</div>
		}
		return null
	}

	return (
		<div>
			<img
				className={styles.image}
				style={props.style}
				alt={`Provider logo for ${props.carrier}`}
				title={props.carrier}
				src={`https://s3.amazonaws.com/freightspoke-images/images/carriers/${carrier}.png`}
				onError={() => {
					setError(true)
					if (!l.some(errorList, (c) => c === carrier)) {
						errorList.push(carrier)
					}
				}}
			/>
			{props.showName && <div>{props.carrier}</div>}
		</div>
	)
}
