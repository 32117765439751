import { sosToast } from 'common/components/toast'
import { apiLocation, apiTypes } from 'ui/api'
import { tString } from 'ui/components/i18n/i18n'
import { FileDropZoneData } from 'ui/components/shared/csvValidator/file-drop-zone'
import {
	getApiImageTypeFromImageString,
	getBase64StringFromImageString,
} from 'ui/lib/images/imageUtils'
import { sosCompanyManagement } from '.'
import { tPrefixCompanyManagement } from '../BrokerCompanyManagementTabs'

export async function saveLocationLogo(
	logoData: FileDropZoneData,
): Promise<void> {
	const state = sosCompanyManagement.getSos().getState()
	if (logoData.fileContent.indexOf('data:image/') === -1) {
		sosToast.sendToast({
			type: 'danger',
			body: tString('pleaseUploadAnImage', tPrefixCompanyManagement),
		})
		return
	}
	const imageFormat: apiTypes.DocumentImageFormat = getApiImageTypeFromImageString(
		logoData.fileContent,
	)
	if (!imageFormat) {
		sosToast.sendToast({
			type: 'danger',
			body: tString('pleaseUploadAValidImageType', tPrefixCompanyManagement),
		})
		return
	}
	const result = await apiLocation.uploadLocationLogo(
		() => {},
		state.location.id,
		{
			filename: logoData.fileName,
			image: getBase64StringFromImageString(logoData.fileContent),
			format: imageFormat,
		},
	)
	if (result.error) {
		sosToast.sendApiErrorResponseToast(
			result,
			tString('errorUploading', tPrefixCompanyManagement),
		)
	} else {
		sosCompanyManagement.getSos().change((ds) => {
			ds.location = result.data
		})
	}
}
