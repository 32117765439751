import { FC } from 'app/FunctionalComponent'
import cookie from 'cookie'
import React, { useState } from 'react'
import { apiFetch } from 'ui/api/apiCommon'
import { CognitoTokensResponse } from 'ui/api/apiTypes'
import { IRequestState } from 'ui/api/requestState'
import { Button } from 'ui/components/common/button'
import { useOnce } from 'ui/components/hooks'
import { t } from 'ui/components/i18n/i18n'
import { isInDev } from 'ui/theme/theme'
import { Layout } from '../layout'

const IN_LOCALHOST = isInDev()
const SSO_ENDPOINT = '/sso'
const ANALYTICS_URL = IN_LOCALHOST
	? `https://localhost`
	: `https://secure-kibana-1270343488.us-east-1.elb.amazonaws.com` // TODO: Use ServiceDiscovery for development and production

// Use this method to set the Oauth tokens as query params. The SSO_ENDPOINT then converts the params them to cookies
export const getTokenParams = (data: any): string => {
	const { idToken, refreshToken, accessToken } = data || {}
	return `?access=${accessToken}&id=${idToken}&refresh=${refreshToken}`
}

// Use this method if you would like to set access token cookies manually instead of using the SSO_ENDPOINT
// const setTokenCookies = (data: any) => {
// 	document.cookie = `ID-TOKEN=${data.idToken}; expires=Session; path=/`
// 	document.cookie = `ACCESS-TOKEN=${data.accessToken}; expires=Session; path=/`
// 	document.cookie = `REFRESH-TOKEN=${data.refreshToken}; expires=Session; path=/`
// }

const attemptLogin = async (
	setUrl: (url: string) => void,
	setSsoState: (state: IRequestState<CognitoTokensResponse>) => void,
	forceRefresh = false,
): Promise<void> => {
	const activeAccessToken = cookie.parse(document.cookie)['ACCESS-TOKEN']
	if (activeAccessToken && !forceRefresh) {
		setUrl(ANALYTICS_URL)
	} else {
		const tokenResponse = await apiFetch<CognitoTokensResponse>(
			(_fetch) => setSsoState(_fetch),
			{ method: 'GET' },
			'cognito/login',
		)
		if (tokenResponse.data) {
			setUrl(ANALYTICS_URL + SSO_ENDPOINT + getTokenParams(tokenResponse.data))
		}
	}
}

export const AnalyticsPage: FC = () => {
	const [url, setUrl] = useState(null)
	const [ssoState, setSsoState] = useState<
		IRequestState<CognitoTokensResponse>
	>({})

	// Attempt automatic login
	useOnce(() => attemptLogin(setUrl, setSsoState))

	return (
		<Layout>
			<div>{t('page.analytics.title')}</div>

			{ssoState.isFetching ? (
				<div>{t('page.analytics.ssoState.fetching')}</div>
			) : (
				<Button onClick={() => attemptLogin(setUrl, setSsoState, true)}>
					Login
				</Button>
			)}

			<div>{ssoState.error}</div>

			{url && (
				<div style={{ height: '800px' }}>
					<iframe
						title='analytics'
						width={'100%'}
						height={'100%'}
						scrolling={'no'}
						style={{ marginTop: '-110px', border: 'none' }}
						src={url}
					/>
				</div>
			)}
		</Layout>
	)
}
