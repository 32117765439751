import { ITimeSlot } from 'ui/lib/time/timeSlots'
import { IFormattedTimeSlot } from './NewTimeSlotModal'
import { DateTime } from 'luxon'
import { l } from 'ui/lib/lodashImports'

export const validateNewTimeSlot = (
	newTimeSlot: IFormattedTimeSlot,
	existingTimeSlots: ITimeSlot[],
): 'startIsNotBeforeEnd' | 'newTimeSlotConflictsWithExistingTimeSlot' => {
	const comparableFormat = 'HHmmssu'
	const { startTime, endTime } = newTimeSlot

	const startDateTime = DateTime.local().set({
		hour: startTime.hour,
		minute: startTime.minute,
		second: 0,
		millisecond: 0,
	})

	const endDateTime = DateTime.local().set({
		hour: endTime.hour,
		minute: endTime.minute,
		second: 0,
		millisecond: 0,
	})

	if (startDateTime.toMillis() >= endDateTime.toMillis()) {
		return 'startIsNotBeforeEnd'
	}

	const timeSlot: ITimeSlot = {
		startTime: startDateTime.toFormat(comparableFormat),
		endTime: endDateTime.toFormat(comparableFormat),
	}

	return l.every(existingTimeSlots, (existingTimeSlot) => {
		const existingStart: string = DateTime.fromISO(
			existingTimeSlot.startTime,
		).toFormat(comparableFormat)
		const existingEnd: string = DateTime.fromISO(
			existingTimeSlot.endTime,
		).toFormat(comparableFormat)

		if (
			existingStart <= timeSlot.startTime &&
			existingEnd > timeSlot.startTime
		) {
			// check if beginning of new time slot overlaps
			return false
		}

		if (existingStart < timeSlot.endTime && existingEnd >= timeSlot.endTime) {
			// check if end of new time slot overlaps
			return false
		}

		if (existingStart > timeSlot.startTime && existingEnd < timeSlot.endTime) {
			// check new time slot contains existing time slot
			return false
		}
		return true
	})
		? null
		: 'newTimeSlotConflictsWithExistingTimeSlot'
}
