import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { theme } from 'ui/theme'
import { Colors } from 'ui/theme/theme'
import classes from './FlexRow.module.scss'

export const FlexHorizontalSeparator: FC = (props: {
	color?: Colors
	className?: string
}) => {
	let className =
		classes.horizontalSeparator +
		' ' +
		theme.getBorderColor(props.color || 'gray')
	className = theme.addClass(props.className, className)
	return <div className={className} />
}
