import { apiCommon, apiTypes } from 'ui/api'
import { IRequestState } from 'ui/api/requestState'

export async function createPayment(
	data: apiTypes.PaymentRequest,
): Promise<IRequestState<apiTypes.PaymentResponse>> {
	const url = `invoicing/payments`
	return await apiCommon.apiFetch(
		() => {},
		{
			method: 'POST',
			data,
		},
		url,
	)
}

export async function getPayment(
	paymentId: string,
): Promise<IRequestState<apiTypes.PaymentAllocationResponse>> {
	const url = `invoicing/payments/${paymentId}`
	return await apiCommon.apiFetch(
		() => {},
		{
			method: 'GET',
		},
		url,
	)
}

export async function updatePayment(
	paymentId: string,
	data: apiTypes.PaymentAllocationCreateRequest,
): Promise<IRequestState<apiTypes.PaymentAllocationResponse>> {
	const url = `invoicing/payments/${paymentId}`
	return await apiCommon.apiFetch(
		() => {},
		{
			method: 'PUT',
			data,
		},
		url,
	)
}

export async function deletePayment(
	paymentId: string,
): Promise<IRequestState<apiTypes.PaymentAllocationResponse>> {
	const url = `invoicing/payments/${paymentId}`
	return await apiCommon.apiFetch(
		() => {},
		{
			method: 'DELETE',
		},
		url,
	)
}
