import { r } from 'ui/lib/ramdaImports'

export const queryStringToObject = (
	queryString: string,
): {
	[index: string]: string
} => {
	const qs = removePrefix('?', queryString)
	const pairs = r.map(r.split('='), r.split('&', qs)) as [string, string][]
	let parentPairs: [string, string][]
	r.forEach((pair) => {
		if (pair[1]) {
			pair[1] = decodeURIComponent(pair[1])
		}
		if (pair.length > 2) {
			const parentQueryString = pair.slice(1, pair.length).join('=')
			const parentQs = parentQueryString.split('?')[1]
			parentPairs = r.map(r.split('='), r.split('&', parentQs)) as [
				string,
				string,
			][]
			r.forEach((pair) => {
				if (pair[1]) {
					pair[1] = decodeURIComponent(pair[1])
				}
			}, parentPairs)
		}
	}, pairs)
	if (parentPairs) {
		pairs.push(...parentPairs)
	}
	return r.fromPairs(pairs)
}

export const objectToQueryString = (params: { [k: string]: any }): string => {
	let queryString = ''
	r.forEach((cIdx) => {
		if (r.isNil(params[cIdx])) {
			// Don't write this
		} else {
			const val = params[cIdx]
			if (queryString) {
				queryString += '&'
			}
			queryString += `${'' + cIdx}=${encodeURIComponent(val)}`
		}
	}, r.keys(params))

	return queryString
}

export const removePrefix = (prefix: string, s: string): string =>
	r.when<string, string>(r.startsWith(prefix), r.drop(prefix.length))(s)

const fIndexOf = (toFind: string) => (str: string): number => {
	return ('' + str).indexOf(toFind)
}

export function getQueryString(href: string): string {
	const idx = fIndexOf('?')(href)
	if (idx === -1) {
		return ''
	}
	return r.drop(idx, href)
}
