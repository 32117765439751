import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { LoadingBar } from 'ui/components/common/loader'
import { Modal, ModalTitle } from 'ui/components/common/modal'
import { reload } from 'ui/components/common/router/windowUtils'
import { SmallButton } from 'ui/components/common/small-button'
import { t } from 'ui/components/i18n/i18n'
import { Spacer } from 'ui/components/layout/spacer'
import { sosShipmentsList } from './state'

export const ExportToCsvModal: FC = (props: {
	state: sosShipmentsList.IStateShipmentsList
}) => {
	const { state } = props
	const cancelExport = (): void => {
		reload()
	}
	const message = t('page.shipmentsList.table.exportingMessage')
	return (
		<Modal
			title='CSV Export'
			onModalClose={() => sosShipmentsList.toggleModal()}
			content={() => (
				<div>
					<ModalTitle message={message} />
					<LoadingBar
						totalSteps={state.exportTotalSteps}
						completedSteps={state.exportCompletedSteps}
						isLoading={state.exportIsRunning}
					/>
					<Spacer />
					<SmallButton onClick={cancelExport} color='blue'>
						{t('page.shipmentsList.table.cancel')}
					</SmallButton>
				</div>
			)}
			isOpen={state.isModalOpen}
		/>
	)
}
