export function batch<T>(
	entities: any[],
	batchSize: number,
	batchedBatchSize: number = null,
): any[][] {
	const batches: any[][] = []
	for (let i = 0; i < entities.length; i += batchSize) {
		batches.push(entities.slice(i, i + batchSize))
	}
	if (batchedBatchSize) {
		const batchedBatches: any[][][] = []
		for (let i = 0; i < batches.length; i += batchedBatchSize) {
			batchedBatches.push(batches.slice(i, i + batchedBatchSize))
		}
		return batchedBatches
	}
	return batches
}
