import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { Modal } from 'ui/components/common/modal'
import { ElasticSearchPager } from 'ui/components/common/pager'
import { SearchInput } from 'ui/components/common/search'
import { StackedItem } from 'ui/components/form'
import { t } from 'ui/components/i18n/i18n'
import { IAddressState } from 'ui/components/shared/address'
import { DataTable, IDataTableHeader } from 'ui/components/table'
import { IAddressBookEntry } from 'ui/lib/books'
import { l } from 'ui/lib/lodashImports'
import { sos2 } from 'ui/lib/state/sos2'
import { delayedTask } from 'ui/pages/shipments/state'
import { sosAddressBookModal } from '.'
import classes from './modals.module.scss'

export const headers: IDataTableHeader<IAddressBookEntry>[] = [
	{
		field: 'company',
	},
	{
		field: 'street1',
	},
	{
		field: 'street2',
	},
	{
		field: 'city',
	},
	{
		field: 'state',
		monospace: true,
	},
	{
		field: 'zip',
		monospace: true,
	},
	{
		field: 'country',
	},
	{
		field: 'addressType',
		renderer: (addressType: string) => l.upperFirst(addressType),
	},
	{
		field: 'name',
	},
	{
		field: 'phone',
		monospace: true,
	},
	{
		field: 'email',
	},
]
export const ModalAddressBook: FC = (props: {
	onClickRow?: (addressChanges: Partial<IAddressState>, id?: string) => void
	onClose?: () => void
}) => {
	const state = sos2.useSubscription(sosAddressBookModal.sos)

	const delayed = delayedTask.useSubscription(
		sosAddressBookModal.delayedSearchFor,
	)

	return (
		<Modal
			closeModalX={true}
			isOpen={state.isModalOpen}
			onModalClose={() => {
				sosAddressBookModal.showModal(false)
				if (props.onClose) {
					props.onClose()
				}
			}}
			title={t('shared.address.modalTitle')}
			fullScreen={true}
			content={() => (
				<div className={classes.fullModal}>
					{/* {t('shared.address.pickAnAddress')} */}
					<StackedItem label={t('shared.address.search')} showRequired={true}>
						<SearchInput
							testId='shipmentsList.searchFor'
							value={state.searchFor}
							onChange={sosAddressBookModal.updateSearchFor}
							width='360px'
						/>
					</StackedItem>
					<DataTable
						testId='address-book-modal-data-table'
						headers={headers}
						data={state.modalEntries}
						state={{}}
						loadingStatus={delayed.status}
						tPrefix='shared.address.addressBook'
						onClickRow={(row) => {
							if (props.onClickRow) {
								props.onClickRow({ address: row }, state.updateAddressId)
								sosAddressBookModal.showModal(false)
							}
						}}
					/>
					<ElasticSearchPager
						pager={state.modalPager}
						onClickPage={sosAddressBookModal.setCurrentPage}
					/>
				</div>
			)}
		/>
	)
}
