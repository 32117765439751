import React from 'react'
import { solidIcons } from 'ui/components/common/icon'
import { Icon } from 'ui/components/common/icon/Icon'
import { SmallButton } from 'ui/components/common/small-button'
import { t } from 'ui/components/i18n/i18n'
import { FilterHeaders } from 'ui/components/table/DataTableInteractionOptionsFilterHeaders'
import { LoadingStatusType } from 'ui/pages/shipments/state/delayedTask'
import * as commonClasses from 'ui/theme/common.module.scss'
import { FlexRow } from '../layout/flexRow/FlexRow'
import { IDataTableHeader } from './IDataTableHeader'
import { IDataTableHeaderProcessed } from './IDataTableHeaderProcessed'
import * as classes from './TableStyles.module.scss'

export const DataTableInteractionOptions = <T extends any>(props: {
	processedHeaders: IDataTableHeaderProcessed<T>[]
	onToggleHeader?: (header: IDataTableHeader<T>) => void
	onToggleHeaderActionBox?: (headerKey: keyof T) => void
	onReset?: () => void
	onRefresh?: () => void
	onAddRow?: () => void
	onExport?: () => void
	addLabel?: React.ReactNode
	testId?: string
	tPrefix?: string
	exportTestId: string
	customToolbar?: React.ReactNode
	loadingStatus?: LoadingStatusType
	onClearFilter?: (headerKey: keyof T) => void
	customSearchTemplate?: React.ReactNode
}): React.ReactElement => {
	const showTableOptions =
		props.onToggleHeader ||
		props.onRefresh ||
		props.onAddRow ||
		props.onExport ||
		props.customToolbar ||
		props.loadingStatus ||
		props.customSearchTemplate

	const spacerForCustomToolbar =
		props.customToolbar &&
		(props.onToggleHeader ||
			props.onRefresh ||
			props.onAddRow ||
			props.onExport)

	const tableIsLoading =
		props.loadingStatus === 'preparing' || props.loadingStatus === 'in-progress'

	if (showTableOptions) {
		return (
			<div>
				<FlexRow
					fullWidth={true}
					mode={'space-between'}
					verticalAlign={'bottom'}
				>
					{props.customSearchTemplate ? (
						props.customSearchTemplate
					) : (
						<div>{/*empty  element for spacing*/}</div>
					)}
					<Toolbar>
						{props.onExport && (
							<ToolbarElement>
								<SmallButton
									onClick={props.onExport}
									color={'blue'}
									testId={props.exportTestId}
								>
									{t('component.table.exportToCsv')}
								</SmallButton>
							</ToolbarElement>
						)}
						{props.onToggleHeader && (
							<ToolbarElement>
								<FilterHeaders
									processedHeaders={props.processedHeaders}
									onReset={props.onReset}
									onToggleHeader={props.onToggleHeader}
									tPrefix={props.tPrefix}
								/>
							</ToolbarElement>
						)}
						{props.onAddRow && (
							<ToolbarElement>
								<SmallButton
									minWidth={'0px'}
									color={'lightGray'}
									testId={props.testId + '-add'}
									onClick={props.onAddRow}
								>
									<Icon
										className={commonClasses.clickable}
										icon={solidIcons.faPlus}
									/>
									{props.addLabel}
								</SmallButton>
							</ToolbarElement>
						)}
						{props.customToolbar && (
							<ToolbarElement>
								<div
									className={
										spacerForCustomToolbar ? classes.customToolbarContainer : ''
									}
								>
									{props.customToolbar}
								</div>
							</ToolbarElement>
						)}
						{props.onRefresh && (
							<ToolbarElement>
								<SmallButton
									minWidth={'0px'}
									color={'lightGray'}
									isSpinning={tableIsLoading}
									onClick={props.onRefresh}
								>
									{tableIsLoading ? (
										<div>{/*empty  element for spacing*/}</div>
									) : (
										<Icon
											className={commonClasses.clickable}
											icon={solidIcons.faSyncAlt}
										/>
									)}
								</SmallButton>
							</ToolbarElement>
						)}
					</Toolbar>
				</FlexRow>
			</div>
		)
	} else {
		return null
	}
}

const Toolbar = (props: { children: any }): React.ReactElement => {
	return (
		<div className={classes.toolbar} style={{ marginRight: '0px' }}>
			{props.children}
		</div>
	)
}

const ToolbarElement = (props: { children: any }): React.ReactElement => {
	return <div className={classes.toolbarElement}>{props.children}</div>
}
