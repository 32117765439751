import { createLazySos2 } from 'ui/lib/state/sos2/sos2'
import { apiTypes, apiLoadOptimizer, apiShipments } from 'ui/api'
import { l } from 'ui/lib/lodashImports'
import { fireAndForget } from 'ui/lib/async/fireAndForget'
import {
	getRuleStringFromObject,
	getRuleObjectFromString,
} from './sosLoadOptimizer_ruleUtils'
import { sosRouter2 } from 'ui/components/common/router'
import { DateTime } from 'luxon'
import { batch } from 'ui/lib/batch'
import { asyncForEachSerial, asyncForEachParallel } from 'ui/lib/async'
import { tString } from 'ui/components/i18n/i18n'
import { tPrefixLoadOptimizer } from '../payloads-table/LoadOptimizerPayloadsTable'
import { sosToast } from 'common/components/toast'

export interface ConsolidatedShipment {
	id: string
	payloads: apiTypes.PayloadResponse[]
	saved: boolean
	error?: string
}

export interface IStateLoadOptimizer {
	rules: IFormattedRule[]
	totalRuleCount: number
	payloads: apiTypes.PayloadResponse[]
	movingPayload: apiTypes.PayloadResponse
	totalPayloadCount: number
	isFetchingPayloads: boolean
	isFetchingRules: boolean
	isAddingRule: boolean
	payloadWildcardQuery: string
	selectedPayloads: string[]
	consolidationShipments: ConsolidatedShipment[]
	payloadWithPopupShowing: string
}

export interface IFormattedRule {
	id?: string
	ruleType: 'address' | 'size' | 'equipmentType'
	stopType?: 'pickup' | 'delivery'
	addressMatchStrength?:
		| 'fullAddress'
		| 'city'
		| 'state'
		| 'fiveDigitPostal'
		| 'threeDigitPostal'
		| 'country'
		| 'locationId'
	sizeType?: 'totalWeight' | 'handlingUnitCount' | 'palletSpotCount'
	sizeOperator?:
		| 'lessThan'
		| 'lessThanOrEqualTo'
		| 'greaterThan'
		| 'greaterThanOrEqualTo'
	sizeThreshold?: string
	createdDate?: string
}

export const getSos = createLazySos2<IStateLoadOptimizer>(
	'sosLoadOptimizer',
	1,
	() => ({
		rules: { default: [], localStorage: false },
		totalRuleCount: { default: 0, localStorage: false },
		payloads: { default: [], localStorage: false },
		movingPayload: { default: null, localStorage: false },
		totalPayloadCount: { default: 0, localStorage: false },
		isFetchingPayloads: { default: false, localStorage: false },
		isFetchingRules: { default: false, localStorage: false },
		isAddingRule: { default: false, localStorage: false },
		payloadWildcardQuery: { default: '', localStorage: false },
		selectedPayloads: { default: [], localStorage: false },
		consolidationShipments: { default: [], localStorage: true },
		payloadWithPopupShowing: { default: null, localStorage: false },
	}),
)

export interface PayloadListMappedData {
	id: string
	included: boolean
	pickupDate: string
	salesOrder: string
	purchaseOrder: string
	originCompany: string
	originStreet1: string
	originCity: string
	originState: string
	originZip: string
	destinationCompany: string
	destinationStreet1: string
	destinationCity: string
	destinationState: string
	destinationZip: string
	pallets: number
	weight: number
}

interface DragEvent {
	to: React.ReactElement // target list
	from: React.ReactElement // previous list
	oldIndex: number // element's old index within old parent
	newIndex: number // element's new index within new parent
	oldDraggableIndex: number // element's old index within old parent, only counting draggable elements
	newDraggableIndex: number // element's new index within new parent, only counting draggable elements
	clone: React.ReactElement // the clone element
	item: React.ReactElement
	pullMode: 'clone' | 'true' // when item is in another sortable: `"clone"` if cloning, `true` if moving
}

const pageInfo = {
	url: '/load-optimizer/:selectedPage',
	params: {
		selectedPage: {
			name: 'selectedPage',
		},
	},
	queries: {},
}

export interface RuleListMappedData {
	rule: string
	locationIds: string[]
	id: string
}

const fetchDebounceTime = 1000

export function updatePayloadWildcardQuery(searchTerm: string): void {
	getSos().change((ds) => {
		ds.payloadWildcardQuery = searchTerm
	})
	fireAndForget(
		_fetchPayloads,
		'fetching shipments without appointments after updating search term',
	)
}

export const fetchPayloads = async (take = 25, skip = 0): Promise<void> => {
	getSos().change((ds) => {
		ds.isFetchingPayloads = true
	})

	const wildcards = getSos().getState().payloadWildcardQuery?.split(' ')
	const fields = [
		'originStop.metaData.datetimeDesired',
		'purchaseOrders',
		'salesOrders',
		'originStop.address.company',
		'originStop.address.street1',
		'originStop.address.city',
		'originStop.address.state',
		'originStop.address.zip',
		'destinationStop.address.company',
		'destinationStop.address.street1',
		'destinationStop.address.city',
		'destinationStop.address.state',
		'destinationStop.address.zip',
	]

	const result = await apiLoadOptimizer.fetchPayloads(
		() => {},
		take,
		skip,
		true,
		null,
		fields,
		wildcards,
	)

	if (result.data) {
		getSos().change((ds) => {
			ds.payloads = result.data.entities
			ds.totalPayloadCount = result.data.total
		})
	}

	getSos().change((ds) => {
		ds.isFetchingPayloads = false
	})
}

export const _fetchPayloads = l.debounce(fetchPayloads, fetchDebounceTime)

export const processPayloads = (
	payloads: apiTypes.PayloadResponse[],
): PayloadListMappedData[] => {
	const processedPayloads: PayloadListMappedData[] = []
	const { selectedPayloads } = getSos().getState()
	l.forEach(payloads, (payload) => {
		const processedPayload: PayloadListMappedData = {
			id: payload.id,
			included: selectedPayloads.indexOf(payload.id) > -1,
			pickupDate: payload.originStop.metaData.datetimeDesired,
			salesOrder: payload.salesOrder,
			purchaseOrder: payload.purchaseOrder,
			originCompany: payload.originStop.address.company,
			originStreet1: payload.originStop.address.street1,
			originCity: payload.originStop.address.city,
			originState: payload.originStop.address.state,
			originZip: payload.originStop.address.zip,
			destinationCompany: payload.destinationStop.address.company,
			destinationStreet1: payload.destinationStop.address.street1,
			destinationCity: payload.destinationStop.address.city,
			destinationState: payload.destinationStop.address.state,
			destinationZip: payload.destinationStop.address.zip,
			pallets: payload.containers.length,
			weight: l.sum(
				l.map(
					payload.containers,
					(c) =>
						c.containerWeightEach ||
						c.goods.reduce(
							(totalWeight, { weightEach }) => totalWeight + weightEach || 0,
							0,
						),
				),
			),
		}

		processedPayloads.push(processedPayload)
	})

	return processedPayloads
}

export const updateSelectedPayloads = (
	payloadId: string,
	add: boolean,
): void => {
	getSos().change((ds) => {
		if (add) {
			ds.selectedPayloads.push(payloadId)
		} else {
			l.pull(ds.selectedPayloads, payloadId)
		}
	})
}

export const optimizePayloads = async (): Promise<void> => {
	const data: apiTypes.ConsolidatePayloadsRequest = {
		payloadIds: getSos().getState().selectedPayloads,
	}
	const result = await apiLoadOptimizer.consolidatePayloads(() => {}, data)

	if (result.data) {
		const consolidatedShipments = l.map(
			result.data.shipments,
			(shipment, shipmentIdx) => {
				return {
					id:
						shipment.id ||
						DateTime.local().toFormat('yMMddHHmmssSSS') + shipmentIdx,
					payloads: shipment.payloads,
					saved: false,
				}
			},
		)

		getSos().change((ds) => {
			ds.consolidationShipments = consolidatedShipments
		})
	}

	sosRouter2.navigateTo(pageInfo, { selectedPage: 'consolidate' })
	return
}

export const addConsolidationShipment = (): void => {
	getSos().change((ds) => {
		ds.consolidationShipments.push({
			payloads: [],
			id: DateTime.local().toFormat('yMMddHHmmssSSS'),
			saved: false,
		})
	})
}

const getShipmentIdFromDragEvent = (
	event: DragEvent,
	toFrom: 'to' | 'from',
): string => {
	return l
		.find(
			event[toFrom]['attributes'],
			(attribute) => attribute.localName === 'id',
		)
		?.nodeValue?.split('-')[1]
}

export const getPayloadIdFromDragEvent = (event: DragEvent): string => {
	return l.find(
		event.item['attributes'],
		(attribute) => attribute.localName === 'data-payloadid',
	)?.nodeValue
}

export const movePayloadFromShipment = (event: DragEvent): void => {
	const fromShipmentId = getShipmentIdFromDragEvent(event, 'from')
	const payloadId = getPayloadIdFromDragEvent(event)

	getSos().change((ds) => {
		const fromShipment = l.find(
			ds.consolidationShipments,
			(consolidationShipment) => consolidationShipment.id === fromShipmentId,
		)

		const payload = l.find(
			fromShipment.payloads,
			(payload) => payload.id === payloadId,
		)

		ds.movingPayload = l.cloneDeep(payload)

		fromShipment.payloads = l.filter(
			fromShipment.payloads,
			(payload) => payload.id !== payloadId,
		)
	})
}

export const movePayloadToShipment = (event: DragEvent): void => {
	const toShipmentId = getShipmentIdFromDragEvent(event, 'to')
	const payloadId = getPayloadIdFromDragEvent(event)

	getSos().change((ds) => {
		const toShipment = l.find(
			ds.consolidationShipments,
			(consolidationShipment) => consolidationShipment.id === toShipmentId,
		)

		toShipment.payloads.push(l.cloneDeep(ds.movingPayload))
		ds.movingPayload = null
	})

	event.to['removeChild'](
		l.find(
			event.to['children'],
			(payload) =>
				payload.attributes['data-payloadid']?.nodeValue === payloadId,
		) || null,
	)
}

export const removePayloadFromShipment = (
	payloadId: string,
	shipmentId: string,
): void => {
	getSos().change((ds) => {
		const shipment = l.find(
			ds.consolidationShipments,
			(consolidationShipment) => consolidationShipment.id === shipmentId,
		)
		const payload = l.find(
			shipment.payloads,
			(payload) => payload.id === payloadId,
		)

		if (payload) {
			l.pull(shipment.payloads, payload)
		}
	})
}

export const updatePayloadWithPopupShowing = (payloadId: string): void => {
	getSos().change((ds) => {
		ds.payloadWithPopupShowing = payloadId
	})
}

export const confirmLoadConsolidation = async (
	dispatch: any,
): Promise<void> => {
	getSos().change((ds) => {
		ds.consolidationShipments = l.filter(
			ds.consolidationShipments,
			(shipment) => shipment.payloads.length > 0,
		)
	})
	const { consolidationShipments } = getSos().getState()
	const batchedRequests: ConsolidatedShipment[][] = batch<ConsolidatedShipment>(
		consolidationShipments,
		7,
	)

	await asyncForEachSerial(
		batchedRequests,
		async (batchedRequest: ConsolidatedShipment[]) => {
			await asyncForEachParallel(
				batchedRequest,
				async (consolidatedShipment: ConsolidatedShipment) => {
					const payloadIds = consolidatedShipment.payloads.map(
						(payload) => payload.id,
					)
					const result = await apiShipments.createShipmentFromPayloads(() => {},
					payloadIds)
					if (result.data) {
						getSos().change((ds) => {
							const shipment: ConsolidatedShipment = l.find(
								ds.consolidationShipments,
								(consolidationShipment) => {
									return !l.isNil(
										l.find(
											consolidationShipment.payloads,
											(payload) => payload.id === result.data.payloads[0].id,
										),
									)
								},
							)
							shipment.saved = true
							shipment.id = result.data.id
						})
					} else if (result.error) {
						getSos().change((ds) => {
							const shipment: ConsolidatedShipment = l.find(
								ds.consolidationShipments,
								(consolidationShipment) => {
									return !l.isNil(
										l.find(
											consolidationShipment.payloads,
											(payload) => payload.id === payloadIds[0],
										),
									)
								},
							)
							shipment.error = result.error
						})
					}
				},
			)
		},
	)
	sosToast.sendToast({
		header: tString('shipmentsSaved', tPrefixLoadOptimizer),
		type: 'success',
	})
}

export const fetchRules = async (): Promise<void> => {
	getSos().change((ds) => {
		ds.isFetchingRules = true
	})

	const result = await apiLoadOptimizer.fetchRules(() => {})
	if (result.data) {
		getSos().change((ds) => {
			ds.totalRuleCount = result.data.total
			ds.rules = l.sortBy(
				result.data.entities.map((rule) => {
					return l.assign(
						{ id: rule.id, createdDate: rule.createdDate },
						getRuleObjectFromString(rule.rule),
					)
				}),
				(rule) => rule.createdDate,
			)
		})
	}

	getSos().change((ds) => {
		ds.isFetchingRules = false
	})
}

export const addRule = async (): Promise<void> => {
	getSos().change((ds) => {
		ds.isAddingRule = true
	})

	const result = await apiLoadOptimizer.createRule(() => {}, {
		rule: getRuleStringFromObject({ ruleType: 'equipmentType' }),
		locationIds: ['all'],
	})
	if (result.data) {
		getSos().change((ds) => {
			ds.rules.push(
				l.assign(
					{
						id: result.data.id,
					},
					getRuleObjectFromString(result.data.rule),
				),
			)
		})
	}
	getSos().change((ds) => {
		ds.isAddingRule = false
	})
}

export const deleteRule = async (ruleId: string): Promise<void> => {
	const result = await apiLoadOptimizer.deleteRule(() => {}, ruleId)
	if (!result.error) {
		getSos().change((ds) => {
			l.pull(
				ds.rules,
				l.find(ds.rules, (rule) => rule.id === ruleId),
			)
		})
	}
}

const fillInDefaults = (rule: IFormattedRule): IFormattedRule => {
	const ruleObject = l.cloneDeep(rule)

	if (ruleObject.ruleType === 'address') {
		ruleObject.stopType = ruleObject.stopType || 'pickup'
		ruleObject.addressMatchStrength =
			ruleObject.addressMatchStrength || 'fullAddress'
	} else if (ruleObject.ruleType === 'size') {
		ruleObject.sizeType = ruleObject.sizeType || 'totalWeight'
		ruleObject.sizeOperator = ruleObject.sizeOperator || 'lessThanOrEqualTo'
		ruleObject.sizeThreshold = ruleObject.sizeThreshold || '0'
	}
	return ruleObject
}

export const updateRule = async (rule: IFormattedRule): Promise<void> => {
	const updatedRule = fillInDefaults(rule)
	const response = await apiLoadOptimizer.updateRule(() => {}, updatedRule.id, {
		rule: getRuleStringFromObject(updatedRule),
		locationIds: ['all'],
	})

	if (response.data) {
		getSos().change((ds) => {
			const rule = l.find(ds.rules, (rule) => rule.id === updatedRule.id)
			const indexOfRule = l.indexOf(ds.rules, rule)
			ds.rules[indexOfRule] = l.assign(
				{ id: response.data.id, createdDate: response.data.createdDate },
				getRuleObjectFromString(response.data.rule),
			)
		})
	}

	await fetchRules()
}
