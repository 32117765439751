import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { Icon, solidIcons } from 'ui/components/common/icon'
import { StackedItem } from 'ui/components/form'
import { t } from 'ui/components/i18n/i18n'
import { FlexItem, FlexRow } from 'ui/components/layout/flexRow'
import {
	createDefaultDesiredState,
	IDateTimeDesiredState,
	TimeType,
} from 'ui/components/shared/ShipmentStopCard/DateTimeDesired'
import { r } from 'ui/lib/ramdaImports'
import { TimePickerSimple } from 'ui/pages/new-quote'
import { StopType } from 'ui/pages/new-quote/state/sosNewQuote'
import { CalendarInput, ControlledFilters } from '.'
import classes from './ShipmentStopCard.module.scss'

export const PickupAndDeliveryTime: FC = (props: {
	stopId: string
	mode: StopType
	state: IDateTimeDesiredState
	updateState: (
		dateTimeChanges: Partial<IDateTimeDesiredState>,
		stopId?: string,
	) => void
	stopNumber?: number
}) => {
	const { updateState, state } = props
	const isPickup = props.mode === 'pickup'


	const Date1 = (
		<CalendarInput
			dateString={state.date1}
			isOpen={state.isOpenDate1}
			onChangeOpen={(isOpen) => {
				updateState({ isOpenDate1: isOpen }, props.stopId)
			}}
			onChangeText={(newVal) => {
				updateState({ date1: newVal }, props.stopId)
			}}
			hasToday={false}
		/>
	)

	const Time1 = (
		<FlexItem>
			<TimePickerSimple
				state={state.time1}
				updateState={(changes) => updateState({ time1: changes }, props.stopId)}
				testId='time-picker-1'
			/>
		</FlexItem>
	)

	const Date2 = (
		<CalendarInput
			dateString={state.date2}
			isOpen={state.isOpenDate2}
			onChangeOpen={(isOpen) => {
				updateState({ isOpenDate2: isOpen }, props.stopId)
			}}
			onChangeText={(newVal) => {
				updateState({ date2: newVal }, props.stopId)
			}}
			hasToday={false}
		/>
	)

	const Time2 = (
		<FlexItem>
			<TimePickerSimple
				state={state.time2}
				updateState={(changes) => updateState({ time2: changes }, props.stopId)}
				testId='time-picker-2'
			/>
		</FlexItem>
	)

	const tPrefix = isPickup
		? 'shared.shipmentStopCard.pickupTime'
		: 'shared.shipmentStopCard.deliveryTime'

	return (
		<div
			className={classes.pickupAndDelivery}
			data-testid='pickup-and-delivery-time'
		>
			<StackedItem label={t('title', tPrefix)} showRequired={true}>
				<div className={classes.pickupAndDeliveryPadding}>
					<ControlledFilters
						tPrefix={tPrefix}
						filterKeys={['standard', 'before', 'at', 'after', 'between']}
						selectedKey={state.timeType || 'standard'}
						onSelect={(selectedKey) => {
							updateState(
								r.mergeDeepRight(createDefaultDesiredState(), {
									timeType: selectedKey as TimeType,
								}),
								props.stopId,
							)
						}}
					/>
					{['after', 'at', 'before', 'between'].includes(state.timeType) && (
						<div className={classes.pickupAndDeliveryTop}>
							<FlexRow verticalAlign='center'>
								<FlexItem>{Time1}</FlexItem>
								<FlexItem>on</FlexItem>
								<FlexItem>{Date1}</FlexItem>
							</FlexRow>
						</div>
					)}
					{state.timeType === 'between' && (
						<div className={classes.pickupAndDeliveryTop}>
							<FlexRow verticalAlign='center'>
								<FlexItem>{Time2}</FlexItem>
								<FlexItem>on</FlexItem>
								<FlexItem>{Date2}</FlexItem>
							</FlexRow>
						</div>
					)}
					{state.timeType === 'standard' && (
						<div className={classes.pickupAndDeliveryTop}>
							{t('localTimeNote', tPrefix)}
						</div>
					)}
				</div>
			</StackedItem>

			{/* <TimePicker /> */}
			{isPickup && !['between'].includes(state.timeType) && (
				<div className={classes.pickupWarning}>
					<div className={classes.pickupWarningIcon}>
						<Icon icon={solidIcons.faExclamationTriangle} />
					</div>
					<div>{t('ltlPickupWarning', tPrefix)}</div>
				</div>
			)}
		</div>
	)
}
