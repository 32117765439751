import React, { useEffect, useState } from 'react'
import { TypeaheadOption } from './TypeaheadOption'
import { AsyncTypeahead as BTAsyncTypeahead } from 'react-bootstrap-typeahead'
import { useOnce } from 'ui/components/hooks/useOnce'
import { l } from 'ui/lib/lodashImports'
import { FC } from 'app/FunctionalComponent'
import * as classes from './AsyncTypehead.module.scss'
import { addClassIf } from 'ui/theme/theme'
import { theme } from 'ui/theme'

export const AsyncTypeahead: FC = (props: {
	testId: string
	options: TypeaheadOption[]
	value?: TypeaheadOption
	isClearable?: boolean
	size?: 'small' | 'large'
	onSearch: (searchTerm: string) => Promise<TypeaheadOption[]>
	onChange: (selected: TypeaheadOption) => void
	onBlur?: () => {}
	onFocus?: () => {}
	width?: string
	defaultSelected?: TypeaheadOption
	defaultInputValue?: string
	placeholder?: string
	useCache?: boolean
	makeInitialFetch?: boolean
	disabled?: boolean
	className?: string
}) => {
	const {
		testId,
		size,
		onSearch,
		onChange,
		width,
		makeInitialFetch,
		isClearable,
	} = props

	let className = classes.large

	className = theme.addClass(props.className, className)
	className = addClassIf(props.disabled, classes.disabled, className)

	// async typeahead crashes the entire app if it detects an option without a label, so this prevents that
	const fixedOptions = l.compact(
		l.cloneDeep(props.options).map((option) => {
			if (!option.label) {
				if (option.value) {
					option.label = option.value
				} else {
					return null
				}
			}
			return option
		}),
	)

	const [isLoading, setIsLoading] = useState(false)
	const [options, setOptions] = useState(() => fixedOptions)
	const [value, setValue] = useState(() => props.value)

	const searchFunction = async (searchTerm: string): Promise<void> => {
		setValue(null)
		setIsLoading(true)
		const response = await onSearch(searchTerm)
		setOptions(response)
		setIsLoading(false)
	}

	useOnce(async () => {
		if (makeInitialFetch) {
			setIsLoading(true)
			const response = await onSearch('')
			setOptions(response)
			setIsLoading(false)
		}
	})

	let defaultSelected = []

	if (props.defaultSelected) {
		defaultSelected = [props.defaultSelected]
	}

	useEffect(() => {
		setValue(props.value)
	}, [props.value])

	return (
		<div className={`bootstrap-wrapper ${className}`} style={{ width }}>
			<BTAsyncTypeahead
				id={testId}
				size={size}
				isLoading={isLoading}
				options={options}
				selected={value ? [value] : []}
				onInputChange={async (val: string) => {
					// must do this because component will not trigger onSearch function for empty string
					if (val === '' && !isClearable) {
						await searchFunction('')
					}
				}}
				onSearch={searchFunction}
				onChange={(selected: TypeaheadOption[]) => {
					if (selected[0] || isClearable) {
						if (selected[0] && value?.value !== selected[0]?.value) {
							setValue(selected[0])
							onChange(selected[0])
						}
					}
				}}
				onBlur={props.onBlur}
				onFocus={props.onFocus}
				defaultSelected={defaultSelected}
				defaultInputValue={props.defaultInputValue}
				minLength={0}
				placeholder={props.placeholder}
				useCache={props.useCache}
				disabled={props.disabled}
				inputProps={{ 'data-testid': testId }}
				renderMenuItemChildren={(option: TypeaheadOption) => (
					<span data-testid={`suggestions-list-${l.kebabCase(option.label)}`}>
						{option.label}
					</span>
				)}
			/>
		</div>
	)
}
