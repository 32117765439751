import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { TabContent, TabItem2, Tabs2 } from 'ui/components/common/tabs'
import { useOnce } from 'ui/components/hooks'
import { t } from 'ui/components/i18n/i18n'
import { fireAndForget } from 'ui/lib/async'
import { getUserManagedLocationId } from 'ui/lib/locations'
import { l } from 'ui/lib/lodashImports'
import { sos2 } from 'ui/lib/state/sos2'
import { DockSchedulerAppointmentDetails } from 'ui/pages/dock-scheduler/appointment-details'
import { DockSchedulerStatusBoard } from 'ui/pages/dock-scheduler/status-board'
import { sosUser } from 'ui/state'
import { Layout } from '../layout'
import { DockSchedulerCalendar } from './calendar-tab/DockSchedulerCalendar'
import * as classes from './DockScheduler.module.scss'
import { DockSchedulerSchedule } from './schedule-tab/DockSchedulerSchedule'
import { sosDockScheduler } from './state'

export const DockScheduler: FC = (props: {}) => {
	const tabs: Array<{
		name: sosDockScheduler.DockSchedulerPages
	}> = [
		{
			name: 'schedule',
		},
		{
			name: 'calendar',
		},
		{
			name: 'dock-status-board',
		},
	]

	const state = sos2.useSubscription(sosDockScheduler.getSos())
	const stateUser = sos2.useSubscription(sosUser.getSos())

	useOnce(() => {
		if (!state.currentLocation) {
			fireAndForget(
				() =>
					sosDockScheduler.updateLocation(
						getUserManagedLocationId(stateUser.requestUserInfo.data),
					),
				'no dock scheduler location, setting it from the location on stateUser',
			)
		} else if (!state.docks.length) {
			fireAndForget(
				sosDockScheduler.fetchDocks,
				'initial dock fetch for dock scheduler',
			)
		}
	})
	const currentPage = sosDockScheduler.getUrlState().selectedPage
	if (!currentPage) {
		sosDockScheduler.updateSelectedTab('schedule')
	}

	return (
		<Layout fullWidth={true}>
			{state.currentLocation && (
				<>
					<Tabs2>
						{l.map(tabs, (c) => (
							<TabItem2
								key={c.name}
								isSelected={currentPage === c.name}
								onClick={() => {
									sosDockScheduler.updateSelectedTab(c.name)
								}}
							>
								{t('page.dockScheduler.tabs.' + l.camelCase(c.name))}
							</TabItem2>
						))}
					</Tabs2>
					<div className={classes.container}>
						<TabContent
							isVisible={currentPage === 'schedule'}
							renderer={() => <DockSchedulerSchedule />}
						/>
						<TabContent
							isVisible={currentPage === 'calendar'}
							renderer={() => <DockSchedulerCalendar docks={state.docks} />}
						/>
						<TabContent
							isVisible={currentPage === 'dock-status-board'}
							renderer={() => <DockSchedulerStatusBoard />}
						/>
						<TabContent
							isVisible={currentPage === 'appointment-details'}
							renderer={() => <DockSchedulerAppointmentDetails />}
						/>
					</div>
				</>
			)}
		</Layout>
	)
}
