import { l } from 'ui/lib/lodashImports'

export const surroundWithParens = (string: string): string =>
	string ? `(${string})` : string

export const containsParens = (string: string): boolean =>
	l.includes(string, '(') || l.includes(string, ')')

export const addParensIfNotThereAlready = (string: string): string => {
	if (containsParens(string)) {
		return string
	}
	return surroundWithParens(string)
}
