import { FC } from 'app/FunctionalComponent'
import { TypeaheadOption } from 'common/components/typeahead'
import React, { useState } from 'react'
import { sosToast } from 'common/components/toast'
import { apiTypes, apiPrintNode } from 'ui/api'
import { Loader } from 'ui/components/common/loader'
import { t, tString } from 'ui/components/i18n/i18n'
import { Spacer } from 'ui/components/layout/spacer'
import { LocationSelector } from 'ui/components/shared/location-selector'
import { PrintersAndScalesInstructions } from './printers-and-scales-instructions/PrintersAndScalesInstructions'
import { PrintStationList } from './printers-and-scales-print-station/PrintStationList'
import { PrintersAndScalesTabs } from './printers-and-scales-tabs/PrintersAndScalesTabs'

export interface IUserCredentials {
	id: string
	username: string
	password?: string
	apiKey: string
	accountId: string
}

export const tPrefixPrintersAndScales =
	'page.companyManagement.printersAndScales'

const spacerHeight = '25px'

export const PrintersAndScalesPage: FC = (props: {}) => {
	const [
		isFetchingPrintNodeCredentials,
		setIsFetchingPrintNodeCredentials,
	] = useState<boolean>(false)
	const [location, setLocation] = useState<TypeaheadOption>(null)
	const [userCredentials, setUserCredentials] = useState<
		apiTypes.PrintNodeCredentialsResponse
	>(null)
	const [printerList, setPrinterList] = useState<
		apiPrintNode.UIPrinterResponse[]
	>([])
	const [showInstructions, setShowInstructions] = useState<boolean>(false)

	return (
		<div className='bootstrap-wrapper'>
			<Spacer height={spacerHeight} />

			<h6>{t('locationToManage', tPrefixPrintersAndScales)}</h6>
			<LocationSelector
				onChange={async (selected: TypeaheadOption) => {
					if (selected) {
						setIsFetchingPrintNodeCredentials(true)
						setLocation(selected)

						const userCredentialsResponse = await apiPrintNode.getCredentialsList(
							() => {},
							selected.value,
						)

						if (userCredentialsResponse.data) {
							if (userCredentialsResponse.data[0]) {
								const credential = userCredentialsResponse.data[0]
								setUserCredentials({
									accountId: credential.accountId,
									apiKey: credential.apiKey,
									username: credential.username,
									password: credential.password,
									locationId: credential.locationId,
									id: credential.id,
								})

								setShowInstructions(true)
							} else {
								sosToast.sendToast({
									header: tString(
										'noCredentialsFoundForSelectedLocation',
										tPrefixPrintersAndScales,
									),
									type: 'warning',
								})
								setUserCredentials(null)
								setShowInstructions(true)
							}
						} else if (userCredentialsResponse.error) {
							sosToast.sendApiErrorResponseToast(userCredentialsResponse)
						} else {
							sosToast.sendToast({
								header: tString(
									'noCredentialsFoundForSelectedLocation',
									tPrefixPrintersAndScales,
								),
								type: 'warning',
							})
							setUserCredentials(null)
							setShowInstructions(true)
						}

						setIsFetchingPrintNodeCredentials(false)
					}
				}}
				onlyShowLeafLocations={false}
			/>

			<Spacer height={spacerHeight} />

			<Loader isLoading={isFetchingPrintNodeCredentials} />

			{showInstructions && (
				<PrintersAndScalesInstructions
					userCredentials={userCredentials}
					setUserCredentials={setUserCredentials}
					location={location}
				/>
			)}

			{userCredentials && (
				<>
					<Spacer height={spacerHeight} />
					<PrintersAndScalesTabs
						printerList={printerList}
						setPrinterList={setPrinterList}
						locationId={location.value}
						userCredentials={userCredentials}
					/>
					<Spacer height={spacerHeight} />
					<PrintStationList
						printerList={printerList}
						credentials={userCredentials}
						locationId={location.value}
					/>
				</>
			)}
		</div>
	)
}
