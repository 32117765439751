import { apiTypes } from 'ui/api'
import { ICarrier } from 'ui/lib/contracts/ICarrier'
import { l } from 'ui/lib/lodashImports'

export const getLowestRate = (
	shipment: apiTypes.ShipmentResponse,
	carriers: ICarrier[],
): apiTypes.RateResponse => {
	let lowestRate
	let lowestRateCost
	shipment.rates.forEach((rate) => {
		const carrier = l.find(carriers, (c) => c.id === rate.providerId)
		const contract =
			carrier && l.find(carrier.contracts, (c) => c.id === rate.contractId)
		if (
			carrier &&
			carrier.includedInAnalysis &&
			contract &&
			contract.includedInAnalysis
		) {
			if (!lowestRateCost || rate.costTotal <= lowestRateCost) {
				lowestRate = rate
				lowestRateCost = rate.costTotal
			}
		}
	})
	return lowestRate
}
