import { FC } from 'app/FunctionalComponent'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import { OkCancelButtons } from 'ui/components/common/okCancelButtons'
import {
	FormStackedLayout,
	FormStackedSelect,
	FormStackedTextInput,
	IFormData,
} from 'ui/components/form'
import { t } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight'
import {
	getCountryNameAndCodeList,
	getRegionsForCountry,
} from 'ui/data/countryAndRegionUtils'
import { l } from 'ui/lib/lodashImports'
import { validateForm } from 'ui/lib/validation/formValidator'
import { AddProviderModalMode, ProviderModalForm } from './AddProviderModal'
import { AddProviderModalContent } from './AddProviderModalContent'

const tPrefix = 'page.providers.addProviderModal.newProviderForm'

export const AddProviderModalForm: FC = (props: {
	onChangeMode: (newMode: AddProviderModalMode) => void
	onClose: () => void
	providerCreateForm: ProviderModalForm
	setProviderCreateForm: React.Dispatch<React.SetStateAction<ProviderModalForm>>
}) => {
	const {
		onChangeMode,
		onClose,
		providerCreateForm,
		setProviderCreateForm,
	} = props

	const formData: IFormData<ProviderModalForm> = {
		form: providerCreateForm,
		metadata: {
			providerName: { required: true },
			doingBusinessAs: {},
			street1: { required: true },
			street2: {},
			city: { required: true },
			state: {
				options: getRegionsForCountry(providerCreateForm.country, true, true),
				required: true,
			},
			zipCode: { required: true },
			country: {
				options: getCountryNameAndCodeList().map((countryData) => {
					return {
						label: countryData.countryName,
						value: countryData.countryCode,
					}
				}),
				required: true,
			},
		},
		onUpdateForm: (field: string, value: any) => {
			const updatedFormData = l.cloneDeep(providerCreateForm)

			if (field === 'country' && value !== providerCreateForm.country) {
				updatedFormData.state = null
			}

			updatedFormData[field] = value
			setProviderCreateForm(updatedFormData)
		},
		tPrefix,
	}

	const formIsValid = validateForm(formData.form, formData.metadata).isValid

	const body = (
		<div data-testid={'add-provider-modal-form'}>
			<Container>
				<div>
					<FormStackedLayout>
						<Row>
							<Col xs={12}>
								<FormStackedTextInput
									formData={formData}
									field={'providerName'}
									testId={'modal-providerName'}
								/>
								<FormStackedTextInput
									formData={formData}
									field={'doingBusinessAs'}
									testId={'modal-doingBusinessAs'}
								/>
								<FormStackedTextInput
									formData={formData}
									field={'street1'}
									testId={'modal-street1'}
								/>
								<FormStackedTextInput
									formData={formData}
									field={'street2'}
									testId={'modal-street2'}
								/>
							</Col>
						</Row>

						<Row>
							<Col xs={6}>
								<FormStackedTextInput
									formData={formData}
									field={'city'}
									testId={'modal-city'}
								/>
								<FormStackedTextInput
									formData={formData}
									field={'zipCode'}
									testId={'modal-zipCode'}
								/>
							</Col>

							<Col xs={6}>
								<FormStackedSelect
									formData={formData}
									field={'state'}
									testId={'modal-state'}
									numListOptionsBeforeScroll={4}
								/>
								<FormStackedSelect
									formData={formData}
									field={'country'}
									testId={'modal-country'}
									numListOptionsBeforeScroll={4}
								/>
							</Col>
						</Row>
					</FormStackedLayout>
				</div>
			</Container>
		</div>
	)

	const footer = (
		<div>
			<Container>
				<AlignRight>
					<OkCancelButtons
						ok={t('addNewProvider', tPrefix)}
						onOk={() => {
							onChangeMode('confirm')
						}}
						okColor='blue'
						onCancel={onClose}
						isValid={formIsValid}
						okTestId={'submit-add-provider-button'}
					/>
				</AlignRight>
			</Container>
		</div>
	)

	return <AddProviderModalContent body={body} footer={footer} />
}
