import { apiTypes } from 'ui/api'

const brokerShipmentInvoiceStatusRanks: {
	[key in apiTypes.BrokerInvoiceResponse['invoiceStatus']]: number
} = {
	void: -1,
	review: 0,
	approved: 1,
	invoiced: 2,
	paid_in_full: 4,
	partially_paid: 2,
	no_invoices: 0,
}

export const compareBrokerShipmentInvoiceStatus = (
	status: apiTypes.BrokerInvoiceResponse['invoiceStatus'],
	statusToCompareAgainst: apiTypes.BrokerInvoiceResponse['invoiceStatus'],
): number => {
	const statusRank = brokerShipmentInvoiceStatusRanks[status]
	const compareRank = brokerShipmentInvoiceStatusRanks[statusToCompareAgainst]
	if (!status) return -1
	if (statusRank === compareRank) {
		return 0
	} else if (statusRank < compareRank) {
		return -1
	} else {
		return 1
	}
}
