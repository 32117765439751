import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { Button } from 'ui/components/common/button'
import { Modal } from 'ui/components/common/modal'
import { t, tString } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight'
import { fireAndForget } from 'ui/lib/async'
import { l } from 'ui/lib/lodashImports'
import { IStateDockSchedulerAppointmentDetails } from 'ui/pages/dock-scheduler/state/sosDockSchedulerAppointmentDetails'
import { sosDockSchedulerAppointmentDetails } from '../state'
import { tPrefixDockSchedulerAppointmentDetails } from './DockSchedulerAppointmentDetails'
import * as classes from './DockSchedulerAppointmentDetails.module.scss'

export const CheckinErrorModal: FC = (props: {
	state: IStateDockSchedulerAppointmentDetails
}) => {
	return (
		<Modal
			title={tString('checkInErrors', tPrefixDockSchedulerAppointmentDetails)}
			isOpen={!l.isNil(props.state.checkInError)}
			closeModalX={true}
			onModalClose={() =>
				sosDockSchedulerAppointmentDetails.setCheckInError(null)
			}
			content={() => (
				<div>
					<div className={classes.errorModalPrompt}>
						{props.state.checkInError}
					</div>
					<AlignRight>
						<Button
							onClick={() =>
								fireAndForget(
									() => sosDockSchedulerAppointmentDetails.sendCheckInToApi(),
									'Sending checkin to API with invalid dock',
								)
							}
							isSpinning={props.state.submitting}
							testId={'submitCheckInWithInvalidDock'}
						>
							{t('assignAnyways', tPrefixDockSchedulerAppointmentDetails)}
						</Button>
					</AlignRight>
				</div>
			)}
		/>
	)
}
