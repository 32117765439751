import { FC } from 'app/FunctionalComponent'
import React from 'react'
import classes from './Subway.module.scss'

export const Subway: FC = (props: {
	testId?: string
	children: React.ReactNode
}) => {
	return (
		<div data-testid={props.testId} className={classes.subwayContainer}>
			{props.children}
		</div>
	)
}
