import React from 'react'
import { FC } from 'app/FunctionalComponent'
import { Card, Col, Row } from 'react-bootstrap'
import { apiTypes } from 'ui/api'
import { ndash } from 'ui/components/common'
import { Separator } from 'ui/components/common/separator'
import { t, tString } from 'ui/components/i18n/i18n'
import { Spacer } from 'ui/components/layout/spacer'
import { toDollarsFormatted } from 'ui/lib/numbers/toNumber'
import { applyDefaultMarkupLogic } from 'ui/pages/shipment-profile/broker/functions'
import { theme } from 'ui/theme'
import { addClass } from 'ui/theme/theme'
import * as classes from './MarketRate.module.scss'

const tPrefix = 'page.shipmentProfile.rates'

export const MarketRate: FC = (props: {
	markupLogic?: apiTypes.MarkupLogic
	marketRate: apiTypes.RateResponse
}) => {
	const { marketRate, markupLogic } = props

	return (
		<Card
			className={`${theme.getBorderColor('lightGray')} ${
				classes.marketRateCard
			}`}
		>
			<div className={classes.marketRate}>
				<div className={addClass(classes.bold, 'd-flex flex-wrap')}>
					<div>{t('truckload', tPrefix)}</div>
					<div className={addClass(classes.titlePart, 'flex-nowrap')}>
						{t('marketRate', tPrefix)}
					</div>
				</div>
				<Separator />
				<Row>
					<Col md={5} className={classes.marketRateNoPaddingRight}>
						{markupLogic && marketRate && (
							<div className={classes.marketRateMedium}>
								<div className={classes.bold}>
									{t('sellPrice', tPrefix)}
									<div>
										{toDollarsFormatted(
											applyDefaultMarkupLogic(
												marketRate.costTotal,
												markupLogic,
											),
										)}
									</div>
									<Spacer />
								</div>
							</div>
						)}
					</Col>
					<Col md={5} className={classes.marketRateNoPaddingRight}>
						<div className={classes.marketRateMedium}>
							{markupLogic && tString('buyPrice', tPrefix)}
							{marketRate ? (
								<div>{toDollarsFormatted(marketRate.costTotal)}</div>
							) : (
								<div>{ndash}</div>
							)}
						</div>
					</Col>
				</Row>
			</div>
		</Card>
	)
}
