import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { theme } from 'ui/theme'

export const AccountingText: FC = (props: {
	children: React.ReactNode
	isRed: boolean
}) => {
	const className = props.isRed ? theme.getTextColor('red') : null
	return <div className={className}>{props.children}</div>
}
