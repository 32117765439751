import { delay } from './delay'

export async function retry<T>(
	func: () => Promise<T>,
	timeoutMs = 15 * 60 * 1000,
	waitBetweenMs = 500,
): Promise<any> {
	const start = Date.now()
	let error
	let val
	/* eslint-disable no-constant-condition */
	while (true) {
		try {
			val = await func()
		} catch (e) {
			error = e
		}
		if (val != null && val !== false) {
			return val
		} else {
			if (Date.now() - start >= timeoutMs) {
				throw new Error(error ? error.toString() : 'timeout retrying')
			}
			await delay(waitBetweenMs)
		}
	}
}
