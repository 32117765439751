import { apiCommon } from 'ui/api'
import { IRequestState } from 'ui/api/requestState'
import { UniqueCodeConfigRequest, UniqueCodeConfigResponse } from './apiTypes'

export interface BrokerConfigRequest {
	clientConfigCode?: UniqueCodeConfigRequest
	shippingTrackingNotificationMessage?: string
}

export interface BrokerConfigResponse extends BrokerConfigRequest {
	clientConfigCode?: UniqueCodeConfigResponse
}

export const createBrokerConfig = async (): Promise<
	IRequestState<BrokerConfigResponse>
> => {
	const url = `brokers/broker-config`
	return apiCommon.apiFetch<BrokerConfigResponse>(
		() => {},
		{
			method: 'POST',
		},
		url,
	)
}

export const getBrokerConfig = async (): Promise<
	IRequestState<BrokerConfigResponse>
> => {
	const url = `brokers/broker-config`
	return apiCommon.apiFetch<BrokerConfigResponse>(
		() => {},
		{
			method: 'GET',
		},
		url,
	)
}

export const updateBrokerConfig = async (
	data: BrokerConfigRequest,
): Promise<IRequestState<BrokerConfigResponse>> => {
	const url = `brokers/broker-config`
	return apiCommon.apiFetch<BrokerConfigResponse>(
		() => {},
		{
			method: 'PUT',
			data,
		},
		url,
	)
}

export const deleteBrokerConfig = async (): Promise<
	IRequestState<BrokerConfigRequest>
> => {
	const url = `brokers/broker-config`
	return apiCommon.apiFetch<BrokerConfigResponse>(
		() => {},
		{
			method: 'DELETE',
		},
		url,
	)
}

export const getAutoGeneratedCustomerCode = async (): Promise<
	IRequestState<string>
> => {
	const url = `brokers/broker-configs/autoGenerateClientCode`
	return apiCommon.apiFetch<string>(
		() => {},
		{
			method: 'GET',
		},
		url,
	)
}
