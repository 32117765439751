import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { Icon, solidIcons } from 'ui/components/common/icon'
import classes from './Subway.module.scss'

export const SubwayArrow: FC = (props: {}) => {
	return (
		<div>
			<div className={classes.arrow}>
				<Icon icon={solidIcons.faLongArrowAltRight} />
			</div>
		</div>
	)
}
