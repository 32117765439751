import { NotFound } from 'app/AppRouter'
import { FC } from 'app/FunctionalComponent'
import React from 'react'
import {
	IRoute,
	LoginModeType,
	SimpleRouter,
} from 'ui/components/common/router'
import { MakeOfferPage } from 'ui/pages/spot-quote'
import { sosUser } from 'ui/state'
import { NewQuotePage } from 'ui/pages/new-quote'
import { QuickRatePageTms3 } from 'ui/pages/quick-rate'
import { ShipmentProfileBroker } from 'ui/pages/shipment-profile/broker/ShipmentProfileBroker'
import { ShipmentProfileShipper } from 'ui/pages/shipment-profile/shipper/ShipmentProfileShipper'
import { ShipmentsListPage } from './ShipmentsListPage'
import { CustomerInvoicePage } from 'ui/pages/customer-invoice'

export const shipmentRoutes: IRoute[] = [
	{
		isDefault: true,
		urls: ['/404'],
		renderer: () => <NotFound />,
		anonymous: true,
	},
	{
		urls: ['/shipments-v3'],
		renderer: () => <ShipmentsListPage />,
	},

	// {
	// 	urls: ['/shipments-v3/ship-orders'],
	// 	renderer: (currentLocation, params) => (
	// 		<ShipOrdersPage selectedTab={params.selectedTab} />
	// 	),
	// },
	{
		urls: ['/shipments-v3/shipment-profile/:shipmentId'],
		renderer: (currentLocation, params) => {
			if (sosUser.isUserBroker()) {
				return <ShipmentProfileBroker />
			} else {
				return <ShipmentProfileShipper />
			}
		},
	},
	{
		urls: ['/shipments-v3/quick-rate'],
		renderer: () => <QuickRatePageTms3 />,
	},
	{
		urls: ['/shipments-v3/new-quote/freight'],
		renderer: () => <NewQuotePage type={'freight'} />,
	},
	{
		urls: ['/shipments-v3/new-quote/parcel'],
		renderer: () => <NewQuotePage type={'parcel'} />,
	},
	{
		urls: ['/shipments-v3/vendor-new-quote'],
		renderer: () => {
			return <NewQuotePage type={'vendor'} />
		},
	},
	{
		urls: ['/shipments-v3/new-quote/freight/:shipmentId'],
		renderer: (currentLocation, params) => (
			<NewQuotePage shipmentId={params.shipmentId} type={'freight'} />
		),
	},
	{
		urls: ['/shipments-v3/new-quote/parcel/:shipmentId'],
		renderer: (currentLocation, params) => (
			<NewQuotePage shipmentId={params.shipmentId} type={'parcel'} />
		),
	},
	{
		urls: ['/shipments-v3/make-offer/:shipmentId/:offerId'],
		// makeOffer
		renderer: (currentLocation, params) => (
			<MakeOfferPage shipmentId={params.shipmentId} offerId={params.offerId} />
		),
		anonymous: true,
	},
	{
		urls: ['/shipments-v3/log-offer/:shipmentId'],
		// logOffer
		renderer: (currentLocation, params) => (
			<MakeOfferPage shipmentId={params.shipmentId} />
		),
	},
	{
		urls: ['/shipments-v3/brokers/make-offer/:shipmentId/:offerId'],
		// brokers makeOffer
		renderer: (currentLocation, params) => (
			<MakeOfferPage
				shipmentId={params.shipmentId}
				offerId={params.offerId}
				isBroker={true}
			/>
		),
		anonymous: true,
	},
	{
		urls: ['/shipments-v3/brokers/log-offer/:shipmentId'],
		// brokers logOffer
		renderer: (currentLocation, params) => (
			<MakeOfferPage shipmentId={params.shipmentId} isBroker={true} />
		),
	},
	{
		urls: [
			'/shipments-v3/shipment-profile/:shipmentId/customer-invoice/:brokerInvoiceId',
		],
		// brokers customer invoice
		renderer: (currentLocation, params) => (
			<CustomerInvoicePage
				shipmentId={params.shipmentId}
				brokerInvoiceId={params.brokerInvoiceId}
			/>
		),
	},
]

export const RouterShipments: FC = (props: {
	currentLocation: string
	loginMode: LoginModeType
}) => {
	return (
		<SimpleRouter
			routes={shipmentRoutes}
			currentLocation={props.currentLocation}
			loginMode={props.loginMode}
		/>
	)
}
