import { sosToast, ToastState } from 'common/components/toast'
import { DateTime } from 'luxon'
import { apiDockScheduler, apiTypes } from 'ui/api'
import { IRequestState } from 'ui/api/requestState'
import { IPageUrlMeta } from 'ui/components/common/router/sosRouter2'
import { ISelectOptions } from 'ui/components/common/select/ISelectOptions'
import { ITimePickerState } from 'ui/components/common/TimePicker'
import { tString } from 'ui/components/i18n/i18n'
import { fireAndForget } from 'ui/lib/async'
import { _idx } from 'ui/lib/_idx'
import { l } from 'ui/lib/lodashImports'
import { createLazySos2 } from 'ui/lib/state/sos2/sos2'
import {
	DayType,
	getDaysForRRule,
	getHoursForRRule,
	getMinutesForRRule,
	getRuleForDifferentDay,
	getRulesWithoutDay,
	getRulesWithoutTimeSlotsForSpecificDay,
	getScheduleRules,
	ITimeSlot,
} from 'ui/lib/time/timeSlots'
import { tPrefixDockConfig } from 'ui/pages/company-management/dock-configuration'
import { sosDockScheduler } from '.'

export type FlowType = 'inbound' | 'outbound'
export type DockModeType = 'ltl' | 'volume' | 'truckload' | 'ocean' | 'air'

export interface IDockInfo {
	id: string
	locationId: string
	name: string
	allowedFlows: string[]
	allowedModes: apiTypes.RateResponse['method'][]
	equipmentTypes: apiTypes.DockResponse['equipmentTypes']
	type: apiTypes.DockResponse['type']
	scheduleId: string
	schedule: apiTypes.ScheduleResponse
}

export interface IStateDockConfig {
	selectedDock: IDockInfo
	selectedDockHasUnsavedChanges: boolean
	selectedDay: DayType
	isFetchingSchedule: boolean
	showDeleteOptions: boolean
	showNewDockModal: boolean
	newDock: { name: string }
	newTimeSlot: {
		startTime: ITimePickerState
		endTime: ITimePickerState
	}
	errors: string[]
	locationOptions: ISelectOptions[]
	unsavedChangesModalShowing: boolean
	navTo?: { pageInfo: IPageUrlMeta; params: any }
}

const createDefaultSelectedDock = (): IDockInfo => {
	return {
		id: null,
		locationId: null,
		name: '',
		allowedFlows: [],
		allowedModes: [],
		equipmentTypes: [],
		type: null,
		scheduleId: null,
		schedule: { id: null, rules: [] },
	}
}

export const getSos = createLazySos2<IStateDockConfig>(
	'sosDockConfig',
	1,
	() => ({
		selectedDock: {
			default: createDefaultSelectedDock(),
		},
		selectedDockHasUnsavedChanges: { default: false, localStorage: false },
		selectedDay: { default: 'monday' },
		isFetchingSchedule: { default: false, localStorage: false },
		showDeleteOptions: { default: false, localStorage: false },
		showNewDockModal: { default: false, localStorage: false },
		newDock: {
			default: {
				name: '',
			},
		},
		newTimeSlot: {
			default: {
				startTime: { hour: 12, minute: 0 },
				endTime: { hour: 12, minute: 0 },
			},
		},
		errors: { default: [] },
		locationOptions: { default: [] },
		unsavedChangesModalShowing: { default: false, localStorage: false },
		navTo: { default: null, localStorage: false },
	}),
)

export const luxonDays = {
	sunday: 0,
	monday: 1,
	tuesday: 2,
	wednesday: 3,
	thursday: 4,
	friday: 5,
	saturday: 6,
}

export async function fetchDocks(selectedDockId?: string): Promise<void> {
	if (sosDockScheduler.getSos().getState().currentLocation) {
		await sosDockScheduler.fetchDocks()
		const docks = sosDockScheduler.getSos().getState().docks

		const selectedDock = getSos().getState().selectedDock
		if (l.find(docks, (dock) => dock.id === selectedDock.id)) {
			selectedDockId = selectedDockId || selectedDock.id
		}

		if (docks.length > 0) {
			selectDock(selectedDockId || docks[0].id)
		} else {
			getSos().change((ds) => {
				ds.selectedDock = createDefaultSelectedDock()
			})
		}
	}
}

export function selectDock(dockId: string): void {
	const { docks } = sosDockScheduler.getSos().getState()
	const dock = l.find(docks, (d) => d.id === dockId)
	if (dock) {
		if (dock.id !== getSos().getState().selectedDock.id) {
			updateHasUnsavedChanges(false)
		}

		getSos().change((ds) => {
			ds.selectedDock = {
				name: dock.nickName,
				id: dock.id,
				locationId: dock.locationId,
				allowedFlows: [],
				allowedModes: dock.modes || [],
				equipmentTypes: _idx(() => dock.equipmentTypes) || [],
				type: dock.type,
				scheduleId: dock.scheduleId,
				schedule: dock.schedule || { id: null, rules: [] },
			}

			if (dock.stopTypes && dock.stopTypes.indexOf('pickup') >= 0) {
				ds.selectedDock.allowedFlows.push('outbound')
			}

			if (dock.stopTypes && dock.stopTypes.indexOf('delivery') >= 0) {
				ds.selectedDock.allowedFlows.push('inbound')
			}
		})

		if (dock.scheduleId && !dock.schedule) {
			fireAndForget(async () => {
				await fetchSchedule(dock.scheduleId)
			}, 'fetching schedule for dock')
		}
	}
}

export async function updateSelectedDock(): Promise<void> {
	let { selectedDock } = getSos().getState()
	if (selectedDock) {
		let scheduleResponse: IRequestState<apiTypes.ScheduleResponse>
		if (selectedDock.scheduleId) {
			scheduleResponse = await apiDockScheduler.updateSchedule(
				(rs: IRequestState<apiTypes.ScheduleResponse>) => {},
				selectedDock.scheduleId,
				selectedDock.schedule,
			)
		} else if (_idx(() => selectedDock.schedule.rules.length)) {
			scheduleResponse = await apiDockScheduler.createSchedule(
				(rs: IRequestState<apiTypes.ScheduleResponse>) => {},
				selectedDock.schedule,
			)
		}
		if (scheduleResponse?.data) {
			getSos().change((ds) => {
				ds.selectedDock.scheduleId = scheduleResponse.data.id
				ds.selectedDock.schedule = scheduleResponse.data
			})
			selectedDock = getSos().getState().selectedDock
		} else if (scheduleResponse?.error) {
			getSos().change((ds) => {
				ds.errors.push(scheduleResponse.error)
				ds.unsavedChangesModalShowing = false
			})
		}
		const dockRequest = createDockRequest(selectedDock)
		const dockUpdateResult = await apiDockScheduler.updateDock(
			(rs: IRequestState<apiTypes.DockResponse>) => {},
			selectedDock.id,
			dockRequest,
		)

		if (dockUpdateResult.data) {
			updateHasUnsavedChanges(false)
			await fetchDocks(selectedDock.id)
			sosDockScheduler.updateDockList(dockUpdateResult.data)
			let toastBody: ToastState
			if (
				dockUpdateResult.data.stopTypes.length === 0 ||
				dockUpdateResult.data.modes.length === 0 ||
				dockUpdateResult.data.equipmentTypes.length === 0 ||
				!dockUpdateResult.data.scheduleId
			) {
				toastBody = {
					header: tString(
						'dockUpdatedButMissingConfiguration',
						tPrefixDockConfig,
					),
					type: 'warning',
				}
			} else {
				toastBody = {
					header: tString('dockUpdatedSuccessfully', tPrefixDockConfig),
					type: 'success',
				}
			}
			sosToast.sendToast(toastBody)
		} else if (dockUpdateResult.error) {
			getSos().change((ds) => {
				ds.errors.push(dockUpdateResult.error)
			})
		}
	}
}

function createDockRequest(dockInfo: IDockInfo): apiTypes.DockRequest {
	return {
		locationId: dockInfo.locationId,
		modes: dockInfo.allowedModes,
		equipmentTypes: dockInfo.equipmentTypes,
		stopTypes: l.map(dockInfo.allowedFlows, (flow) =>
			flow === 'inbound' ? 'delivery' : 'pickup',
		),
		type: dockInfo.type,
		scheduleId: dockInfo.scheduleId,
		nickName: dockInfo.name,
	}
}

export async function fetchSchedule(scheduleId: string): Promise<void> {
	getSos().change((ds) => {
		ds.isFetchingSchedule = true
	})

	const response = await apiDockScheduler.fetchSchedule(
		(rs: IRequestState<apiTypes.ScheduleResponse>) => {},
		scheduleId,
	)

	getSos().change((ds) => {
		if (response.data) {
			ds.selectedDock.schedule = response.data
		}

		ds.isFetchingSchedule = false
	})
}

function updateHasUnsavedChanges(hasUnsavedChanges: boolean): void {
	getSos().change((ds) => {
		ds.selectedDockHasUnsavedChanges = hasUnsavedChanges
	})
}

export function addTimeSlot(): void {
	updateHasUnsavedChanges(true)
	const { selectedDay, newTimeSlot } = getSos().getState()
	const { startTime, endTime } = l.cloneDeep(newTimeSlot)

	const timeSlot: ITimeSlot = {
		startTime: DateTime.local()
			.set({
				hour: startTime.hour,
				minute: startTime.minute,
				weekday: luxonDays[selectedDay],
			})
			.toISO(),
		endTime: DateTime.local()
			.set({
				hour: endTime.hour,
				minute: endTime.minute,
				weekday: luxonDays[selectedDay],
			})
			.toISO(),
	}

	getSos().change((ds) => {
		const newRules = getScheduleRules([timeSlot])
		ds.selectedDock.schedule.rules = ds.selectedDock.schedule.rules.concat(
			newRules,
		)
		ds.newTimeSlot = {
			startTime: { hour: 12, minute: 0 },
			endTime: { hour: 12, minute: 0 },
		}
	})
}

export function removeTimeSlot(timeSlot: ITimeSlot): void {
	updateHasUnsavedChanges(true)
	const startDateTime = DateTime.fromISO(timeSlot.startTime, {
		setZone: true,
	})
	const startTime = { hour: startDateTime.hour, minute: startDateTime.minute }

	const day = startDateTime.weekday
	const rruleDayAbbreviations = {
		1: 'MO',
		2: 'TU',
		3: 'WE',
		4: 'TH',
		5: 'FR',
		6: 'SA',
		7: 'SU', // wtf luxon
	}

	const { selectedDock: dockSelected } = getSos().getState()
	const rule: apiTypes.ScheduleRuleRequest = l.find(
		dockSelected.schedule.rules,
		(r) => {
			const days = getDaysForRRule(r.rrule)
			const hours = getHoursForRRule(r.rrule)
			const minutes = getMinutesForRRule(r.rrule)

			return (
				r.durationMinutes === timeSlot.durationMinutes &&
				hours.indexOf(startTime.hour) >= 0 &&
				minutes.indexOf(startTime.minute) >= 0 &&
				days.indexOf(rruleDayAbbreviations[day]) >= 0
			)
		},
	)
	const ruleIdx = l.indexOf(dockSelected.schedule.rules, rule)

	getSos().change((ds) => {
		const newRules = getRulesWithoutTimeSlotsForSpecificDay(
			[rule],
			timeSlot,
			ds.selectedDay,
		)
		console.log(newRules)
		ds.selectedDock.schedule.rules.splice(ruleIdx, 1, ...newRules)
	})
}

export function copyScheduleTo(timeSlots: ITimeSlot[], days: DayType[]): void {
	updateHasUnsavedChanges(true)
	const rules = getScheduleRules(timeSlots)
	l.forEach(days, (day) => {
		const rulesForDay = l.map(rules, (rule) =>
			getRuleForDifferentDay(rule, day),
		)
		getSos().change((ds) => {
			const oldRulesWithoutDay = getRulesWithoutDay(
				ds.selectedDock.schedule.rules,
				day,
			)

			ds.selectedDock.schedule.rules = oldRulesWithoutDay.concat(rulesForDay)
		})
	})

	sosToast.sendToast({
		header: tString('scheduleCopiedSuccessfully', tPrefixDockConfig),
		type: 'success',
	})
}

export function updateDockName(name: string): void {
	updateHasUnsavedChanges(true)
	getSos().change((ds) => {
		ds.selectedDock.name = name
	})
}

export function updateDockEquipmentTypes(
	val: apiTypes.ShipmentResponse['equipmentType'],
	add: boolean,
): void {
	updateHasUnsavedChanges(true)
	getSos().change((ds) => {
		if (add) {
			ds.selectedDock.equipmentTypes.push(val)
		} else {
			l.pull(ds.selectedDock.equipmentTypes, val)
		}
	})
}

export function updateAllowedFlow(flow: FlowType, include: boolean): void {
	updateHasUnsavedChanges(true)
	getSos().change((ds) => {
		if (include) {
			ds.selectedDock.allowedFlows.push(flow)
		} else {
			l.pull(ds.selectedDock.allowedFlows, flow)
		}
	})
}

export function updateAllowedModes(mode: DockModeType, include: boolean): void {
	updateHasUnsavedChanges(true)
	getSos().change((ds) => {
		if (include) {
			ds.selectedDock.allowedModes.push(mode)
		} else {
			l.pull(ds.selectedDock.allowedModes, mode)
		}
	})
}

export function updateDockDay(day: DayType): void {
	getSos().change((ds) => {
		ds.selectedDay = day
	})
}

export function updateNewTimeSlotTime(
	startEnd: 'start' | 'end',
	field: any,
	newVal: any,
): void {
	getSos().change((ds) => {
		if (startEnd === 'start') {
			ds.newTimeSlot.startTime[field] = newVal
		} else if (startEnd === 'end') {
			ds.newTimeSlot.endTime[field] = newVal
		}
	})
}

export function updateShowNewDockModal(show: boolean): void {
	getSos().change((ds) => {
		ds.showNewDockModal = show
	})
}

export function updateNewDockName(newVal): void {
	getSos().change((ds) => {
		ds.newDock.name = newVal
	})
}

export async function addDock(): Promise<void> {
	const { name } = getSos().getState().newDock
	const response = await apiDockScheduler.createDock(
		(rs: IRequestState<apiTypes.DockResponse>) => {},
		{
			nickName: name,
			locationId: sosDockScheduler.getSos().getState().currentLocation.id,
			type: 'pool',
			stopTypes: ['pickup', 'delivery'],
			equipmentTypes: ['V', 'F', 'R'],
			modes: ['ltl', 'truckload'],
		},
	)

	if (response.data) {
		await sosDockScheduler.fetchDocks()
		sosDockScheduler.updateDockList(response.data)
		const flows = []
		if (response.data.stopTypes?.includes('pickup')) {
			flows.push('outbound')
		}
		if (response.data.stopTypes?.includes('delivery')) {
			flows.push('inbound')
		}

		getSos().change((ds) => {
			ds.selectedDock = {
				id: response.data.id,
				locationId: response.data.locationId,
				name: response.data.nickName,
				allowedFlows: flows,
				allowedModes: response.data.modes || [],
				equipmentTypes: response.data.equipmentTypes || [],
				type: response.data.type,
				scheduleId: response.data.scheduleId || response.data.schedule?.id,
				schedule: response.data.schedule || { id: null, rules: [] },
			}
			ds.newDock.name = ''
		})
		fireAndForget(
			sosDockScheduler.fetchDocks,
			'refetching docks for dockScheduler since we created a dock in the config',
		)
	} else if (response.error) {
		getSos().change((ds) => {
			ds.errors.push(response.error)
		})
	}

	getSos().change((ds) => {
		ds.showNewDockModal = false
	})
}

export function updateShowDeleteOptions(show: boolean): void {
	getSos().change((ds) => {
		ds.showDeleteOptions = show
	})
}

export async function deleteDock(): Promise<void> {
	const { selectedDock: dockSelected } = getSos().getState()

	await apiDockScheduler.deleteDock(() => {}, dockSelected.id)

	await fetchDocks()

	sosDockScheduler.updateDockList(
		{
			id: dockSelected.id,
			locationId: null,
			type: null,
		},
		true,
	)

	getSos().change((ds) => {
		ds.showDeleteOptions = false
	})
}

export function updateUnsavedChangesModalShowing(show: boolean): void {
	getSos().change((ds) => {
		ds.unsavedChangesModalShowing = show
	})
}

export function updateNavTo(navTo: {
	pageInfo: IPageUrlMeta
	params: any
}): void {
	getSos().change((ds) => {
		ds.navTo = navTo
	})
}
