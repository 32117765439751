import { FC } from 'app/FunctionalComponent'
import React, { useState } from 'react'
import { Autocomplete } from 'ui/components/common/autocomplete'
import { Fieldset } from 'ui/components/common/fieldset/Fieldset'
import { FieldsetLegend } from 'ui/components/common/fieldset/FieldsetLegend'
import { Input } from 'ui/components/common/input'
import { SearchInput } from 'ui/components/common/search'
import {
	Select,
	SelectNative,
	SelectThirdParty,
} from 'ui/components/common/select'
import { Debug } from 'ui/components/dev'
import {
	FormVerticalTextInput,
	IFormData,
	IFormMetadataCollection,
} from 'ui/components/form'
import { t } from 'ui/components/i18n/i18n'
import { FlexRow } from 'ui/components/layout/flexRow'
import { classCodes } from 'ui/data'
import { getCountryNameAndCodeList } from 'ui/data/countryAndRegionUtils'
import { l } from 'ui/lib/lodashImports'
import { sosComponentExamples } from 'ui/state'
import {
	IExampleForm,
	IStateComponentExamples,
} from 'ui/state/sosComponentExamples'
import { Example } from '.'
import { Section } from './Section'

const exampleOptions = l.map(getCountryNameAndCodeList(), (c) => ({
	label: c.countryName,
	value: c.countryCode,
}))

const exampleOptionsShort = l.take(exampleOptions, 5)
const exampleOptionsMed = l.take(exampleOptions, 50)
const exampleOptionsLong = l.take(exampleOptions, 150)

const formMetadata: IFormMetadataCollection<IExampleForm> = {
	name: {
		required: true,
	},
	dogsName: {
		required: false,
	},
	catsName: {
		required: false,
	},
}

export const SectionForms: FC = (props: {
	stateComponentExamples: IStateComponentExamples
}) => {
	const [state, setState] = useState('')

	const [state1, setState1] = useState('1')

	const onChangeState = (newVal): void => {
		setState(newVal)
	}

	const formData: IFormData<IExampleForm> = {
		form: props.stateComponentExamples.exampleForm,
		metadata: formMetadata,
		onUpdateForm: (field, newVal) => {
			const changes = {}
			changes[field] = newVal
			sosComponentExamples.updateForm(changes)
		},
		tPrefix: 'page.componentExamples.forms',
	}
	return (
		<Section
			title={t('page.componentExamples.forms')}
			stateComponentExamples={props.stateComponentExamples}
			name='forms'
		>
			<div>This is a test</div>

			<Example title='Input w set state'>
				<Input value={state1} onChange={setState1} /> {state1}
				<Input value={state1} onChange={setState1} /> {state1}
				<Input value={state1} onChange={setState1} /> {state1}
			</Example>

			<Example title='Input'>
				<Input value='1234' />
			</Example>
			<Example title='Search Input'>
				<SearchInput value='search for this' />
			</Example>

			<Example title='Select'>
				<Select onChange={() => {}} options={['a', 'b', 'c']} value='c' />
			</Example>
			<Example title='SelectNative'>
				<SelectNative onChange={() => {}} options={['a', 'b', 'c']} value='c' />
			</Example>
			<Example title='SelectThirdParty'>
				<SelectThirdParty
					onChange={() => {}}
					options={['a', 'b', 'c']}
					value='c'
				/>
			</Example>

			<Example title='Select Short'>
				<FlexRow>
					<Select
						onChange={onChangeState}
						options={exampleOptionsShort}
						value={state}
					/>
					<Input value='123' />
				</FlexRow>
				<SelectThirdParty
					onChange={onChangeState}
					options={exampleOptionsShort}
					value={state}
				/>
				<SelectNative
					onChange={onChangeState}
					options={exampleOptionsShort}
					value={state}
				/>
			</Example>
			<Example title='Select Med'>
				<Select
					onChange={onChangeState}
					options={exampleOptionsMed}
					value={state}
				/>
				<SelectThirdParty
					onChange={onChangeState}
					options={exampleOptionsMed}
					value={state}
				/>
			</Example>
			<Example title='Select Long'>
				<Select
					onChange={onChangeState}
					options={exampleOptionsLong}
					value={state}
				/>
				<SelectThirdParty
					onChange={onChangeState}
					options={exampleOptionsLong}
					value={state}
				/>
			</Example>
			<Example title='Select Extra Long'>
				<Select
					onChange={onChangeState}
					options={exampleOptions}
					value={state}
				/>
				<SelectThirdParty
					onChange={onChangeState}
					options={exampleOptions}
					value={state}
				/>
				<SelectNative
					onChange={onChangeState}
					options={exampleOptions}
					value={state}
				/>
			</Example>

			<Example title='Select-style Autocomplete (classes)'>
				<Autocomplete
					type='select'
					value={props.stateComponentExamples.autocompleteValue}
					options={classCodes}
					onChange={(newVal) =>
						sosComponentExamples.setAutocompleteValue(newVal)
					}
					name={'autocompleteClass'}
				/>
			</Example>
			<Example title='Select-style Autocomplete (classes) + clearable'>
				<Autocomplete
					type='select'
					value={props.stateComponentExamples.autocompleteValue}
					options={classCodes}
					isClearable={true}
					onChange={(newVal) =>
						sosComponentExamples.setAutocompleteValue(newVal)
					}
					name={'autocompleteClass'}
				/>
			</Example>

			<Example title='Autocomplete'>
				<Autocomplete
					type='autocomplete'
					value={props.stateComponentExamples.autocompleteValue}
					options={exampleOptions}
					onChange={(newVal) =>
						sosComponentExamples.setAutocompleteValue(newVal)
					}
					name={'Autocomplete'}
				/>
				<Debug
					data={{
						autocompleteValue: props.stateComponentExamples.autocompleteValue,
					}}
					label={'Autocomplete'}
				/>
			</Example>
			<Example title='Fieldset'>
				<div style={{ width: '300px' }}>
					<Fieldset>
						<FieldsetLegend>I am fieldset</FieldsetLegend>
						<FormVerticalTextInput field={'name'} formData={formData} />
						<FormVerticalTextInput field={'dogsName'} formData={formData} />
						<FormVerticalTextInput field={'catsName'} formData={formData} />
					</Fieldset>
				</div>
				<div>
					<FlexRow>
						<Fieldset>
							<FieldsetLegend>I am fieldset</FieldsetLegend>
							<FormVerticalTextInput field={'name'} formData={formData} />
							<FormVerticalTextInput field={'dogsName'} formData={formData} />
							<FormVerticalTextInput field={'catsName'} formData={formData} />
						</Fieldset>
						<Fieldset>
							<FieldsetLegend>I am fieldset</FieldsetLegend>
							<FormVerticalTextInput field={'name'} formData={formData} />
							<FormVerticalTextInput field={'dogsName'} formData={formData} />
							<FormVerticalTextInput field={'catsName'} formData={formData} />
						</Fieldset>
					</FlexRow>
				</div>
			</Example>
		</Section>
	)
}
