import { esQueryStringFormatterGenerator } from 'ui/pages/shipments/state/lib/esQueryStringFormatterGenerator'

/*
	Searches through a queryString for any OR operators and formats to the correct syntax for ElasticSearch
 */
export function queryStringOrFormatter(qs: string): string {
	const orSplitters = [' OR ', ' | ', ' or ', ' || ']
	const esOrSyntax = ' OR '
	return esQueryStringFormatterGenerator(orSplitters, esOrSyntax)(qs)
}
