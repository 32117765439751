import { FC } from 'app/FunctionalComponent'
import React from 'react'
import {
	tEquipmentType,
	tMode,
	tStopType,
} from 'ui/components/i18n/commonTranslations'
import { t } from 'ui/components/i18n/i18n'
import { theme } from 'ui/theme'
import * as commonClasses from 'ui/theme/common.module.scss'
import { minutesPerColumn } from '../components/DockSchedulerTimeSlots'
import * as classes from '../DockScheduler.module.scss'
import { appointmentStatusToColor } from '../lib/appointmentStatusToColor'
import { sosDockScheduler } from '../state'
import { ICalendarCard } from './DockSchedulerCalendar'

const tPrefix = 'page.dockScheduler.calendar.card.appointmentStatus'

export const DockSchedulerCalendarCard: FC = (props: {
	cardData: ICalendarCard
	highlighted: boolean
}) => {
	const { cardData } = props
	const width = cardData.durationMinutes / minutesPerColumn

	return (
		<div
			className={classes.slotWrapper}
			style={{
				flexBasis: width,
				flexGrow: width,
				flexShrink: width,
			}}
		>
			<div
				className={theme.addClass(
					classes.calendarCard,
					commonClasses.clickable,
				)}
				data-testid='dock-scheduler-calendar-card'
				onClick={() =>
					sosDockScheduler.updateSelectedTab(
						'appointment-details',
						cardData.appointmentId,
					)
				}
			>
				<div
					className={theme.addClass(
						classes.calendarCardHeader,
						appointmentStatusToColor(cardData.appointmentStatus),
					)}
				>
					<div className={classes.calendarCardIdentifier}>
						{cardData.identifier}
					</div>
					<div data-testid={tPrefix + '.' + cardData.appointmentStatus}>
						{cardData.appointmentStatus &&
							t(cardData.appointmentStatus, tPrefix)}
					</div>
				</div>
				<div
					className={theme.addClassIf(
						props.highlighted,
						classes.highlightedCard,
						classes.calendarCardBody,
					)}
				>
					<div>{cardData.otherCompaniesOnAppointment.join(', ')}</div>
					<div>
						{cardData.carrier}{' '}
						{cardData.mode && (
							<span
								data-testid={`dock-scheduler-calendar-card-mode-${cardData.mode}`}
							>
								{`(${tMode(cardData.mode)})`}
							</span>
						)}
					</div>
					<div
						data-testid={`dock-scheduler-calendar-card-flow-${cardData.flow}`}
					>
						{cardData.flow && tStopType(cardData.flow)}
					</div>
					<div className={classes.calendarCardEquipmentType}>
						{tEquipmentType(cardData.equipmentType)}
					</div>
					{cardData.dockName && (
						<div className={classes.calendarCardDockName}>
							<span>{cardData.dockName}</span>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}
