import { sosToast } from 'common/components/toast'
import { apiProviders, apiTypes } from 'ui/api'
import { IRequestState } from 'ui/api/requestState'
import { IFilterChip } from 'ui/components/table/IDataTableState'
import { l } from 'ui/lib/lodashImports'
import { createLazySos2 } from 'ui/lib/state/sos2/sos2'
import {
	createPagerSosMeta,
	getTakeAndSkip,
	IStatePager,
} from 'ui/state/paging'
import { delayedTask } from '../shipments/state'
import { createDelayedTask } from '../shipments/state/delayedTask'
import { searchProviders } from './functions/searchProviders'

export interface IStateProviderManagement {
	providers: apiTypes.ProviderResponse[]
	isFetchingProviders: boolean
	pager: IStatePager
	filterChips?: IFilterChip<apiTypes.ProviderResponse>[]
}

export const getSos = createLazySos2<IStateProviderManagement>(
	'sosProviderManagement',
	1,
	() => ({
		providers: { default: [] },
		isFetchingProviders: { default: false },
		pager: createPagerSosMeta(),
		filterChips: { default: [] },
	}),
)

export const fetchProviders = async (): Promise<void> => {
	const state = getSos().getState()
	const { pager, filterChips } = state
	const { take, skip } = getTakeAndSkip(pager, 25)

	const queries: string[] = []
	l.forEach(filterChips, (filterChip) => {
		// If only providerName or only doingBusinessAs is filtered, query both fields for that filter
		if (
			(filterChip.columnKey === 'providerName' &&
				!l.find(filterChips, (chip) => chip.columnKey === 'doingBusinessAs')) ||
			(filterChip.columnKey === 'doingBusinessAs' &&
				!l.find(filterChips, (chip) => chip.columnKey === 'providerName'))
		) {
			queries.push(
				`(providerName:*${filterChip.filterValue}* OR doingBusinessAs:*${filterChip.filterValue}*)`,
			)
		} else {
			queries.push(`${filterChip.columnKey}:*${filterChip.filterValue}*`)
		}
	})

	getSos().change((ds) => {
		ds.isFetchingProviders = true
	})

	const response = await searchProviders(take, skip, queries.join(' AND '))

	if (response.data) {
		getSos().change((ds) => {
			ds.providers = response.data.entities
			ds.isFetchingProviders = false
		})
	} else if (response.error) {
		sosToast.sendApiErrorResponseToast(response)
		getSos().change((ds) => {
			ds.isFetchingProviders = false
		})
	}
}

export const activateProvider = async (
	provider: apiTypes.ProviderResponse,
): Promise<apiTypes.ProviderResponse> => {
	const providerRequest: apiTypes.ProviderRequest = l.cloneDeep(provider)
	providerRequest.isActive = true

	const response: IRequestState<apiTypes.ProviderResponse> = await apiProviders.updateProvider(
		(rs: IRequestState<apiTypes.ProviderResponse>) => {},
		provider.id,
		providerRequest,
	)
	if (response.data) {
		getSos().change((ds) => {
			ds.providers.push(response.data)
		})
	} else if (response.error) {
		sosToast.sendApiErrorResponseToast(response)
	}

	return response.data
}

export const updateCurrentPage = async (newPage: number): Promise<void> => {
	getSos().change((ds) => {
		ds.pager.fetchingPageNumber = newPage
	})
	delayedTask.callNow(delayedFetchProviders)
}

export const delayedFetchProviders = createDelayedTask(
	'sosProviderManagement:fetchProviders',
	async () => {
		await fetchProviders()
		return true
	},
	{},
)

export const setColumnFilterValue = (
	field: keyof apiTypes.ProviderResponse,
	filterValue: string,
): void => {
	getSos().change((ds) => {
		const existingChip = l.find(
			ds.filterChips,
			(filter) => filter.columnKey === field,
		)
		if (existingChip) {
			if (filterValue === '') {
				ds.filterChips = l.filter(
					ds.filterChips,
					(filter) => filter.columnKey !== field,
				)
			} else {
				existingChip.filterValue = filterValue
			}
		} else {
			if (filterValue !== '') {
				ds.filterChips.push({
					columnKey: field,
					filterValue: filterValue,
					isOpen: true,
				})
			}
		}
	})

	delayedTask.callNow(delayedFetchProviders)
}

export const clearColumnFilter = (field: string): void => {
	getSos().change((ds) => {
		ds.filterChips = l.filter(ds.filterChips, (c) => c.columnKey !== field)
	})
}
