import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { t } from '../i18n/i18n'
import { FlexRow } from '../layout/flexRow/FlexRow'
import styles from './FormVertical.module.scss'
import { IFormData } from './IFormData'

export const FormVerticalDoubleInput: FC = (props: {
	mainField: string
	formData: IFormData<any>
	leftInput: React.ReactNode
	rightInput: React.ReactNode
}) => {
	return (
		<div className={styles.formRow}>
			<div className={styles.td + ' ' + styles.labelLeft}>
				<label htmlFor={'' + props.mainField}>
					{t(props.mainField, props.formData.tPrefix)}
				</label>
			</div>
			<div className={styles.td}>
				<FlexRow>
					{props.leftInput}
					{props.rightInput}
				</FlexRow>
			</div>
		</div>
	)
}
