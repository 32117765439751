import { theme } from 'ui/theme'
import { Themes } from 'ui/theme/theme'
import { createSos } from './secretaryOfState'

export interface IStateTheme {
	theme: Themes
}

const initialState: IStateTheme = {
	theme: 'tmsDark',
}

const { stateManager, useSubscribe } = createSos('theme', '5', initialState, {
	useLocalStorage: true,
	localStorageFields: ['theme'],
})
export { useSubscribe }

export async function setTheme(newTheme: Themes): Promise<void> {
	theme.updateTheme(newTheme)
	stateManager.produce((ds) => {
		ds.theme = newTheme
	})
}
