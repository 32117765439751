import {
	IFormMetadata,
	IFormMetadataCollection,
} from 'ui/components/form/IFormMetadata'
import { l } from 'ui/lib/lodashImports'
import { validation } from '.'

export function validateValue<T>(
	meta: IFormMetadata<T>,
	val: any,
	includeLabel = true,
	form?: T,
): string {
	let errorLabel = null

	let error: string = null
	errorLabel = meta.label

	if (meta.required && validation.isNotAValue(val)) {
		error = `${meta.label} is required`
		errorLabel = null
	}

	if (val != null && val !== '') {
		if (!error && meta.customValidator) {
			error = meta.customValidator(val, form)
		}
		if (!error && meta.mustBeANumber) {
			error = validation.mustBeANumber(val)
		}
		if (!error && meta.mustBeAnInteger) {
			error = validation.mustBeAnInteger(val)
		}
		if (!error && meta.mustBeGreaterThan) {
			error = validation.mustBeGreaterThan(val, meta.mustBeGreaterThan)
		}
	}
	if (error) {
		if (includeLabel && errorLabel) {
			error = `${errorLabel}: ${error}`
		}
	}
	return error
}

export function validateFormItem<T>(
	form: T,
	formMetadata: IFormMetadataCollection<T>,
	field: keyof T,
): string {
	const meta = formMetadata[field]
	const val = form[field]
	return validateValue(meta, val)
}

export function validateForm<T>(
	form: T,
	formMetadata: IFormMetadataCollection<T>,
): {
	isValid: boolean
	error: any
	errors: any[]
} {
	const errors = []

	l.forIn(formMetadata, (meta, field) => {
		const val = form[field]
		const error = validateValue(meta, val, false, form)
		if (error) {
			errors.push(error)
		}
	})

	return {
		isValid: errors.length === 0,
		error: errors.length > 0 ? errors[0] : null,
		errors,
	}
}
