import {apiTypes} from 'ui/api'
import {l} from 'ui/lib/lodashImports'
import {
	NewQuoteAccessorialsForm,
	NewQuoteAddressForm,
	NewQuoteBillToForm,
	NewQuotePackageForm,
} from 'ui/pages/new-quote/parcel/components/newQuoteForms'
import {ContainerResponse, GoodResponse, OrderResponse, ProviderName, ServiceLevel} from 'ui/api/apiTypes'
import {UIGoodsResponse} from "ui/pages/new-quote/parcel/components";

export const updateContainer = (
	container: apiTypes.ContainerResponse,
	packages: NewQuotePackageForm[],
): void => {
	if (container.freightLevel === 'parcel') {
		const form = packages.find((parcel) => parcel.id === container.id)
		if (form) {
			container.length = form.length
			container.width = form.width
			container.height = form.height
			container.packedWeight = form.weight
			container.declaredValue = form.declaredValue
			container.count = form.quantity
			container.labelReferenceValue1 = form.reference1
			container.labelReferenceValue2 = form.reference2
			container.type = form.type
			container.packagePageId = form.packagePageId
		}
	} else if (container.containers?.length) {
		container.containers.forEach((_container) => {
			updateContainer(_container, packages)
		})
	}
}

export const updatePackagesFromOrder = (
	packages: NewQuotePackageForm[],
	pickupAddressForm: NewQuoteAddressForm,
	deliveryAddressForm: NewQuoteAddressForm,
	billToForm: NewQuoteBillToForm,
	order: OrderResponse,
	goods: UIGoodsResponse[],
	isIPD: boolean,
): apiTypes.ShipmentResponse => {
	// TODO update for when we're going to support payloads
	const payload = l.cloneDeep(order.shipment.payloads[0])

	payload.containers.forEach((container) =>
		updateContainer(container, packages),
	)

	const freightIndex = payload.containers.findIndex(
		(container) => container.containers && container.freightLevel === 'freight',
	)

	let freightContainer: apiTypes.ContainerResponse = {
		id: undefined,
		count: 1,
		length: 48,
		width: 40,
		height: 40,
		containerWeightEach: 40,
		packedWeight: 40,
		freightLevel: 'freight',
		containers: [],
	}

	if (freightIndex > -1) {
		freightContainer = payload.containers[freightIndex]
		payload.containers.splice(freightIndex, 1)
	}

	const hasParcel = payload.containers.some(
		(_container) => _container.freightLevel === 'parcel',
	)

	if (hasParcel) {
		const nonParcelContainers: apiTypes.ContainerResponse[] = []

		payload.containers.forEach((container) => {
			if (container.freightLevel === 'parcel') {
				freightContainer.containers.push(container)
			} else {
				nonParcelContainers.push(container)
			}
		})

		payload.containers = nonParcelContainers
	}

	packages
		.filter((_package) => !_package.id)
		.forEach((_package) =>
			freightContainer.containers.push({
				id: undefined,
				freightLevel: 'parcel',
				length: _package.length,
				width: _package.width,
				height: _package.height,
				packedWeight: _package.weight,
				declaredValue: _package.declaredValue,
				count: _package.quantity,
				labelReferenceValue1: _package.reference1,
				labelReferenceValue2: _package.reference2,
				type: _package.type,
				packagePageId: _package.packagePageId,
			})
		)

	if (freightContainer.containers.length) {
		if (isIPD) {
			freightContainer.containers.forEach((_container, index) => {
				_container.goods = goods.filter(_good => _good.packageId === index.toString())
			})
		}
		payload.containers.unshift(freightContainer)
	}


	// Save location id and delete from object
	const deliveryLocationId = deliveryAddressForm.locationId
	delete deliveryAddressForm.locationId
	const pickupLocationId = pickupAddressForm.locationId
	delete pickupAddressForm.locationId

	let billTo = order.shipment.billTo
	if (billTo.billToThisParty === 'shipper') {
		billTo = Object.assign({}, billTo, {
			address: pickupAddressForm,
			locationId: pickupLocationId,
		})
	} else if (billTo.billToThisParty === 'consignee') {
		billTo = Object.assign({}, billTo, {
			address: deliveryAddressForm,
			locationId: deliveryLocationId,
		})
	} else {
		billTo = Object.assign({}, billTo, billToForm)
	}

	payload.destinationStop = Object.assign(
		{},
		{
			address: deliveryAddressForm,
			metaData: {
				...payload.destinationStop.metaData,
				locationId: deliveryLocationId || undefined,
			},
		},
	)

	payload.originStop = Object.assign(
		{},
		{
			address: pickupAddressForm,
			metaData: {
				...payload.originStop.metaData,
				locationId: pickupLocationId || undefined,
			},
		},
	)

	return Object.assign({}, order.shipment, {
		billTo: billTo,
		payloads: [payload],
	})
}

export const createParcelShipmentRequest = (shipment: {
	pickupAddressForm: NewQuoteAddressForm
	deliveryAddressForm: NewQuoteAddressForm
	accessorialsForm: NewQuoteAccessorialsForm
	packagesForm: NewQuotePackageForm[]
	billToForm: NewQuoteBillToForm
	orderPickTicketNumber: string
	goods: UIGoodsResponse[]
}): apiTypes.ShipmentRequest => {
	const { accessorialsForm, billToForm, orderPickTicketNumber } = shipment

	const billTo = {
		// address: billToForm.address,
		address: null,
		carrierAccountNumber: billToForm.carrierAccountNumber,
		carrierName: billToForm.carrier,
		billToThisParty: billToForm.billToParty,
		carrierService: billToForm.serviceLevel,
		locationId: billToForm.locationId,
	}

	switch (billToForm.billToParty) {
		case 'consignee':
			billTo.address = shipment.deliveryAddressForm
			break
		case 'shipper':
			billTo.address = shipment.pickupAddressForm
			break
		default:
			billTo.address = billToForm.address
			break
	}
	const shipmentData: apiTypes.ShipmentRequest = {
		flags: null,
		expectedMode: 'parcel',
		billTo: billTo,
		payloads: [
			{
				originStop: {
					address: shipment.pickupAddressForm,
					metaData: {
						locationId: shipment.pickupAddressForm.locationId,
						accessorialsInfo: undefined,
					},
				},
				destinationStop: {
					address: shipment.deliveryAddressForm,
					metaData: {
						locationId: shipment.deliveryAddressForm.locationId,
					},
				},
				containers: [
					{
						count: 1,
						length: 48,
						width: 40,
						height: 40,
						containerWeightEach: 40,
						packedWeight: 40,
						freightLevel: 'freight',
						containers: shipment.packagesForm.map((packageForm) => ({
							count: packageForm.quantity,
							labelReferenceValue1: packageForm.reference1,
							labelReferenceValue2: packageForm.reference2,
							declaredValue: packageForm.declaredValue,
							type: packageForm.type,
							packagePageId: packageForm.packagePageId,
							length: packageForm.length,
							width: packageForm.width,
							height: packageForm.height,
							packedWeight: packageForm.weight,
							freightLevel: 'parcel',
							accessorials: {
								cod: accessorialsForm.cod,
							},
							accessorialsInfo: {
								codCollectionAmount: accessorialsForm.codAmount,
								codMoneyOrderOnly: accessorialsForm.moneyOrderOnly,
							},
						})),
					},
				],
				accessorials: {
					signature: accessorialsForm.signatureRequired,
					holdAtLocation: accessorialsForm.holdAtCarrier,
					saturdayDelivery: accessorialsForm.saturdayDelivery,
					fedexSmartPost: accessorialsForm.fedexSmartPost,
				},
				accessorialsInfo: {
					smartPostHub: accessorialsForm.smartPostHub,
					signatureType: accessorialsForm.signatureType,
					holdPhone: accessorialsForm.holdAtCarrierPhone,
					holdStreet: accessorialsForm.holdAtCarrierStreet,
					holdCity: accessorialsForm.holdAtCarrierCity,
					holdCode: accessorialsForm.holdAtCarrierZip,
					holdState: accessorialsForm.holdAtCarrierState,
					holdCountry: accessorialsForm.holdAtCarrierCountry,
					codCollectionAmount: accessorialsForm.codAmount,
				},
				pickTicketNumber: orderPickTicketNumber,
			},
		],
		accessorials: {
			protectFromFreeze: accessorialsForm.protectFromFreeze,
		},
	}
	return shipmentData
}

export const getContainer = (
	container: apiTypes.ContainerResponse,
	parcels: apiTypes.ContainerResponse[],
): void => {
	if (container.freightLevel === 'parcel') {
		parcels.push(container)
	} else if (container.containers?.length) {
		container.containers.forEach((_container) => {
			getContainer(_container, parcels)
		})
	}
}

function getAllGoods(containers: ContainerResponse[]): GoodResponse[] {
	let goods: GoodResponse[] = []
	containers.forEach((container) => {
		if (container.goods) {
			goods = goods.concat(container.goods)
		}
		if (container.containers) {
			goods = goods.concat(getAllGoods(container.containers))
		}
	})
	return goods
}

export const orderToLoad = (
	order: apiTypes.OrderResponse,
): {
	pickUpAddress: NewQuoteAddressForm
	deliveryAddress: NewQuoteAddressForm
	accessorials: NewQuoteAccessorialsForm
	packages: NewQuotePackageForm[]
	billTo: NewQuoteBillToForm
	pickTicketNumber: string
	autoRateCarrier: string
	autoRateService: string
	goods: GoodResponse[]
} => {
	let pickUpAddress: apiTypes.Address
	let deliveryAddress: apiTypes.Address
	let accessorialsRequired: apiTypes.AccessorialsRequired
	let accessorialsInfoContainer: apiTypes.ContainerAccessorialsInfo = {}
	let accessorialsPayload: apiTypes.PayloadAccessorials
	let accessorialsInfoPayload: apiTypes.PayloadAccessorialsInfo
	let containers: apiTypes.ContainerResponse[]
	let billTo: apiTypes.BillTo
	let pickTicketNumber: string
	let autoRateCarrier: ProviderName | string
	let autoRateService: ServiceLevel | string
	let pickupAddressLocationId = undefined
	let deliveryAddressLocationId = undefined
	let goods: GoodResponse[] = []

	const parcels: apiTypes.ContainerResponse[] = []

	let signatureRequired = false
	if (order?.shipment) {
		const payload = l.cloneDeep(order.shipment.payloads[0])

		pickUpAddress = payload.originStop.address
		pickupAddressLocationId = payload.originStop.metaData?.locationId
		deliveryAddress = payload.destinationStop.address
		deliveryAddressLocationId = payload.destinationStop.metaData?.locationId
		accessorialsRequired = order.shipment.accessorials
		payload.containers.forEach((container) => {
			accessorialsInfoContainer = l.assign(
				accessorialsInfoContainer,
				container.accessorialsInfo,
			)
		})
		accessorialsPayload = payload.accessorials
		accessorialsInfoPayload = payload.accessorialsInfo
		payload.containers.forEach((container) => getContainer(container, parcels))
		pickTicketNumber = payload.pickTicketNumber
		autoRateCarrier = order.shipment.autoRateCarrier
		autoRateService = order.shipment.autoRateService
		billTo = order.shipment.billTo
		billTo.carrierName = billTo.carrierName || autoRateCarrier
		billTo.carrierService =
			billTo.carrierService || (autoRateService as ServiceLevel)
		signatureRequired = payload.destinationStop.metaData.accessorials.signature

		goods = getAllGoods(order.shipment.payloads[0].containers).map((_good, index) => ({..._good, id: (Math.random() + index).toString(), packageId: '0'}))
		goods = goods.sort((a, b) => a.description.localeCompare(b.description))

	} else if (order?.payload) {
		pickUpAddress = order.payload.originStop.address
		pickupAddressLocationId = order.payload.originStop.metaData?.locationId
		deliveryAddress = order.payload.destinationStop.address
		deliveryAddressLocationId =
			order.payload.destinationStop.metaData?.locationId

		accessorialsRequired = {}
		order.payload.containers.forEach((container) => {
			accessorialsInfoContainer = l.assign(
				accessorialsInfoContainer,
				container.accessorialsInfo,
			)
		})
		accessorialsPayload = order.payload.accessorials
		accessorialsInfoPayload = order.payload.accessorialsInfo
		billTo = {}
		containers = order.payload.containers
		pickTicketNumber = order.payload.pickTicketNumber
		autoRateCarrier =
			order.payload.erpRequestedShipmentSettings?.autoRateCarrier
		autoRateService =
			order.payload.erpRequestedShipmentSettings?.autoRateService
	} else {
		return
	}

	return {
		goods,
		pickUpAddress: {
			name: pickUpAddress.name || '',
			street1: pickUpAddress.street1 || '',
			street2: pickUpAddress.street2 || '',
			city: pickUpAddress.city || '',
			state: pickUpAddress.state || '',
			zip: pickUpAddress.zip || '',
			country: pickUpAddress.country || '',
			addressType: pickUpAddress.addressType || null,
			contactName: pickUpAddress.name || '',
			phone: pickUpAddress.phone || '',
			email: pickUpAddress.email || '',
			locationId: pickupAddressLocationId,
			company: pickUpAddress.company || '',
		},

		deliveryAddress: {
			name: deliveryAddress.name || '',
			street1: deliveryAddress.street1 || '',
			street2: deliveryAddress.street2 || '',
			city: deliveryAddress.city || '',
			state: deliveryAddress.state || '',
			zip: deliveryAddress.zip || '',
			country: deliveryAddress.country || '',
			addressType: deliveryAddress.addressType || null,
			contactName: deliveryAddress.name || '',
			phone: deliveryAddress.phone || '',
			email: deliveryAddress.email || '',
			locationId: deliveryAddressLocationId,
			company: deliveryAddress.company || '',
		},

		accessorials: {
			cod: accessorialsRequired?.cod || null,
			codAmount: accessorialsInfoPayload?.codCollectionAmount || null,
			moneyOrderOnly: accessorialsInfoContainer?.codMoneyOrderOnly || null,

			fedexSmartPost: accessorialsPayload?.fedexSmartPost || null,
			smartPostHub: accessorialsInfoPayload?.smartPostHub || '',

			holdAtCarrier: accessorialsPayload?.holdAtLocation || null,
			holdAtCarrierPhone: accessorialsInfoPayload?.holdPhone || '',
			holdAtCarrierStreet: accessorialsInfoPayload?.holdStreet || '',
			holdAtCarrierCity: accessorialsInfoPayload?.holdCity || '',
			holdAtCarrierState: accessorialsInfoPayload?.holdState || '',
			holdAtCarrierZip: accessorialsInfoPayload?.holdCode || '',
			holdAtCarrierCountry: accessorialsInfoPayload?.holdCountry || '',

			protectFromFreeze: accessorialsRequired?.protectFromFreeze || null,

			saturdayDelivery: accessorialsPayload?.saturdayDelivery || null,

			signatureRequired: signatureRequired || accessorialsRequired?.signature,
			signatureType: accessorialsInfoPayload?.signatureType || null,
		},

		packages: parcels.length
			? parcels.map((container) => {
					return {
						quantity: container.count || 1,
						freightLevel: container.freightLevel || 'parcel',
						reference1: container.labelReferenceValue1 || '',
						reference2: container.labelReferenceValue2 || '',
						declaredValue: container.declaredValue || null,
						type: container.type || 'boxes',
						packagePageId: container.id || '',
						length: container.length || null,
						width: container.width || null,
						height: container.height || null,
						weight: container.packedWeight || null,
						id: container.id,
					}
			  })
			: [
					{
						quantity: 1,
						reference1: '',
						reference2: '',
						declaredValue: null,
						packagePageId: '',
						length: null,
						width: null,
						height: null,
						weight: null,
					},
			  ],

		billTo: {
			billToParty: billTo?.billToThisParty || null,
			carrier: billTo?.carrierName || null,
			serviceLevel: billTo?.carrierService || null,
			carrierAccountNumber: billTo?.carrierAccountNumber || '',
			address: {
				name: billTo?.address?.name || '',
				street1: billTo?.address?.street1 || '',
				street2: billTo?.address?.street2 || '',
				city: billTo?.address?.city || '',
				state: billTo?.address?.state || '',
				zip: billTo?.address?.zip || '',
				country: billTo?.address?.country || '',
				addressType: billTo?.address?.addressType || null,
				phone: billTo?.address?.phone || '',
				email: billTo?.address?.email || '',
				company: billTo?.address?.company || '',
			},
		},

		pickTicketNumber: pickTicketNumber,

		autoRateCarrier: autoRateCarrier,
		autoRateService: autoRateService,
	}
}

export const shipmentToParcelForm = (
	shipment: apiTypes.ShipmentResponse,
): {
	pickupAddressForm: NewQuoteAddressForm
	deliveryAddressForm: NewQuoteAddressForm
	accessorialsForm: NewQuoteAccessorialsForm
	packagesForm: NewQuotePackageForm[]
	billToForm: NewQuoteBillToForm
} => {
	const pickupAddressForm: NewQuoteAddressForm = {
		...shipment.payloads[0].originStop.address,
		locationId: shipment.payloads[0].originStop.metaData?.locationId,
	}
	const deliveryAddressForm: NewQuoteAddressForm = {
		...shipment.payloads[0].destinationStop.address,
		locationId: shipment.payloads[0].destinationStop.metaData?.locationId,
	}
	const accessorialsForm: NewQuoteAccessorialsForm = {
		cod: shipment.payloads[0].containers[0]?.containers[0]?.accessorials?.cod,
		codAmount:
			shipment.payloads[0].containers[0]?.containers[0]?.accessorialsInfo
				?.codCollectionAmount,
		moneyOrderOnly:
			shipment.payloads[0].containers[0]?.containers[0]?.accessorialsInfo
				?.codMoneyOrderOnly,
		fedexSmartPost: shipment.payloads[0].accessorials?.fedexSmartPost,
		smartPostHub: shipment.payloads[0].accessorialsInfo?.smartPostHub,
		holdAtCarrier: shipment.payloads[0].accessorials?.holdAtLocation,
		holdAtCarrierPhone: shipment.payloads[0].accessorialsInfo?.holdPhone,
		holdAtCarrierStreet: shipment.payloads[0].accessorialsInfo?.holdStreet,
		holdAtCarrierCity: shipment.payloads[0].accessorialsInfo?.holdCity,
		holdAtCarrierState: shipment.payloads[0].accessorialsInfo?.holdState,
		holdAtCarrierZip: shipment.payloads[0].accessorialsInfo?.holdCode,
		holdAtCarrierCountry: shipment.payloads[0].accessorialsInfo?.holdCountry,
		protectFromFreeze: shipment.accessorials?.protectFromFreeze,
		saturdayDelivery: shipment.payloads[0].accessorials?.saturdayDelivery,
		signatureRequired: shipment.payloads[0].destinationStop?.metaData?.accessorials?.signature,
		signatureType: shipment.payloads[0].accessorialsInfo?.signatureType,
	}

	const packagesForm: NewQuotePackageForm[] = shipment.payloads[0].containers[0].containers.map(
		(container) => ({
			quantity: container.count,
			reference1: container.labelReferenceValue1,
			reference2: container.labelReferenceValue2,
			declaredValue: container.declaredValue,
			type: container.type,
			packagePageId: container.id,
			length: container.length,
			width: container.width,
			height: container.height,
			weight: container.packedWeight,
		}),
	)

	const billToForm: NewQuoteBillToForm = {
		address: shipment.billTo.address,
		carrierAccountNumber: shipment.billTo.carrierAccountNumber,
		carrier: shipment.billTo.carrierName,
		billToParty: shipment.billTo.billToThisParty,
		serviceLevel: shipment.billTo.carrierService,
	}

	return {
		pickupAddressForm,
		billToForm,
		deliveryAddressForm,
		accessorialsForm,
		packagesForm,
	}
}
