import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { l } from 'ui/lib/lodashImports'
import { addClass, addClassIf, getTextColor } from 'ui/theme/theme'
import { Icon, solidIcons } from '../icon'
import classes from './Link.module.scss'

// see: https://github.com/evcohen/eslint-plugin-jsx-a11y/blob/master/docs/rules/anchor-is-valid.md
export const LinkButton: FC = (props: {
	children: React.ReactNode
	onClick: () => void
	testId?: string
	isSpinning?: boolean
	isDisabled?: boolean
	className?: string
}) => {
	let className = addClass(getTextColor('linkBlue'), classes.linkButton)
	className = addClassIf(!l.isNil(props.className), props.className, className)

	return (
		<div className={classes.linkButtonContainer}>
			<button
				type='button'
				className={className}
				onClick={props.onClick}
				data-testid={props.testId}
				disabled={props.isSpinning || props.isDisabled}
			>
				{props.children}
				{props.isSpinning && (
					<span className={classes.linkSpinner} data-testid='button-spinner'>
						<Icon icon={solidIcons.faSpinner} spin={true} />
					</span>
				)}
			</button>
		</div>
	)
}
