import { IDataTableHeader, IFilterChip } from 'ui/components/table'
import { l } from 'ui/lib/lodashImports'
import {
	buildQueryStringQuery,
	processSearchString,
} from 'ui/lib/elasticSearch/elasticSearchBuilder'
import { stringExists } from 'ui/lib/misc/stringExists'
import {
	formatElasticSearchDateRangeSearch,
	formatElasticSearchSingleDaySearch,
} from 'ui/lib/elasticSearch/formatElasticSearchDateQueries'
import { formatValueToCurrency } from 'ui/lib/elasticSearch/formatElasticSearchCurrencyQuery'

export function buildQueriesFromFilters<T extends any>(
	filterChips: IFilterChip<T>[],
	availableHeaders: IDataTableHeader<T>[],
): { shipmentClauses: string[]; payloadClauses: string[] } {
	const filterClauses = l.compact(
		l.map(filterChips, (c) => {
			const header = l.find(availableHeaders, (d) => d.field === c.columnKey)

			if (!header || !header.elasticSearchKey) {
				throw new Error(
					`Misconfiguration: Unknown table header ${c.columnKey}.`,
				)
			}

			if (stringExists(c.filterValue)) {
				let query = c.filterValue
				if (header.filterSelector === 'currency') {
					query = formatValueToCurrency(c.filterValue)
				}

				if (!header.absoluteSearch) {
					query = processSearchString(query)
				}

				return buildQueryStringQuery(query, header.elasticSearchKey.split(','))
			}

			if (c.filterDatePickerState) {
				if (
					c.filterDatePickerState.date1String &&
					c.filterDatePickerState.date2String
				) {
					return formatElasticSearchDateRangeSearch(
						header.elasticSearchKey,
						c.filterDatePickerState.date1String,
						c.filterDatePickerState.date2String,
					)
				} else if (c.filterDatePickerState.date1String) {
					return formatElasticSearchSingleDaySearch(
						header.elasticSearchKey,
						c.filterDatePickerState.date1String,
					)
				}
			}
		}),
	)
	const [payloadClauses, shipmentClauses] = l.partition(
		filterClauses,
		(clause) => clause.indexOf('payloads') > -1,
	)
	return { shipmentClauses, payloadClauses }
}
