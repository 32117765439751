import React from 'react'
import { logWarn } from 'ui/lib/log'
import { r } from 'ui/lib/ramdaImports'

export type IDelayedTaskAction<T> = (arg: T) => Promise<boolean>
export type LoadingStatusType =
	| 'stopped'
	| 'preparing'
	| 'in-progress'
	| 'error'
export interface IDelayedTask<T> {
	subscriptions: IDelayedTaskUpdate<T>[]
	state: IDelayedTaskState<T>
}

export interface IDelayedTaskState<T> {
	key: string
	status: LoadingStatusType
	action: IDelayedTaskAction<T>
	arg: T
	delay: number
	timerId: number
}

type IDelayedTaskUpdate<T> = (taskState: IDelayedTaskState<T>) => void
const delayedTasks: IDelayedTask<any>[] = []

// TODO: what if we prepare while we are already running our action?

export const createDelayedTask = <T>(
	key: string,
	action: IDelayedTaskAction<T>,
	arg: T,
): IDelayedTask<T> => {
	const _st = {
		key,
		status: 'stopped',
		action,
		arg: arg || null,
		delay: 1000,
		timerId: 0,
	} as IDelayedTaskState<T>
	const delayedTask: IDelayedTask<T> = {
		subscriptions: [],
		state: _st,
	}
	delayedTasks.push(delayedTask)
	return delayedTask
}

export const callLater = <T>(task: IDelayedTask<T>): void => {
	clearTaskTimeout(task)
	const timerId = (setTimeout(() => {
		callNow(task)
	}, task.state.delay) as unknown) as number
	patchState(task, {
		status: 'preparing',
		timerId,
	})
}

export const callNow = <T>(task: IDelayedTask<T>): void => {
	clearTaskTimeout(task)

	task.state
		.action(task.state.arg)
		.then(() => {
			// complete action
			patchState(task, {
				status: 'stopped',
			})
		})
		.catch(() => {
			patchState(task, {
				status: 'error',
			})
		})

	patchState(task, {
		status: 'in-progress',
		timerId: 0,
	})
}

export const cancel = <T>(task: IDelayedTask<T>): void => {
	clearTaskTimeout(task)
	patchState(task, {
		status: 'stopped',
		timerId: 0,
	})
}

const patchState = <T>(
	task: IDelayedTask<T>,
	changes: Partial<IDelayedTaskState<T>>,
): void => {
	task.state = r.mergeRight(task.state, changes) as IDelayedTaskState<T>
	r.forEach((c) => {
		c(task.state)
	}, task.subscriptions)
}

const clearTaskTimeout = <T>(task: IDelayedTask<T>): void => {
	if (task.state.timerId) {
		clearTimeout(task.state.timerId)
	}
}

export const subscribe = <T>(
	onUpdate: IDelayedTaskUpdate<T>,
	task: IDelayedTask<T>,
): IDelayedTaskUpdate<T> => {
	task.subscriptions.push(onUpdate)
	if (task.subscriptions.length > 10) {
		logWarn(
			'delayed-tasks',
			task.state.key,
			`has ${task.subscriptions.length} subscriptions, possible leak`,
		)
	}
	return onUpdate
}

export const unsubscribe = <T>(
	onUpdate: IDelayedTaskUpdate<T>,
	task: IDelayedTask<T>,
): void => {
	task.subscriptions = r.reject(r.equals(onUpdate), task.subscriptions)
}

export const useSubscription = <T>(
	task: IDelayedTask<T>,
): IDelayedTaskState<T> => {
	const [state, setState] = React.useState(task.state)
	const handleStateChange = (newTask: IDelayedTaskState<T>): void => {
		setState(newTask)
	}
	React.useEffect(() => {
		subscribe(handleStateChange, task)
		return () => {
			unsubscribe(handleStateChange, task)
		}
	}, [task])

	return r.clone(state)
}
