import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { Modal } from 'ui/components/common/modal'
import { t, tArgz } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight'
import { Spacer } from 'ui/components/layout/spacer'
import { Icon, solidIcons } from 'ui/components/common/icon'
import * as classes from './NotifyProviderModal.module.scss'
import { Button } from 'ui/components/common/button'
import { RateBookingDetails } from 'ui/pages/shipment-profile/shared/functions/rateBookingDetailsTypes'
import { sosRouter } from 'ui/components/common/router'

const spacerHeight = '10px'

export const NotifyProviderModal: FC = (props: {
	isModalOpen: boolean
	onModalClose: () => void
	tPrefix: string
	rateBookingDetails: RateBookingDetails
	isBooking: boolean
}) => {
	const {
		isModalOpen,
		onModalClose,
		tPrefix,
		rateBookingDetails,
		isBooking,
	} = props

	const tPrefixModal = tPrefix + '.modalNotifyProvider'

	const newQuoteParcelLink = '/shipments-v3/new-quote/parcel/'

	const onClose = (): void => {
		onModalClose()
	}

	const onOk = (): void => {
		onModalClose()

		if (!rateBookingDetails.isBroker && isBooking) {
			if (rateBookingDetails.providerName) {
				sosRouter.navigate(newQuoteParcelLink)
			}
		}
	}

	const phone = rateBookingDetails?.phone
	const email = rateBookingDetails?.email

	return (
		<Modal
			content={() => (
				<div data-testid={'notify-provider-modal'}>
					<div className={classes.notifyProviderModal}>
						<div className={classes.warningIcon}>
							<Icon
								icon={solidIcons.faExclamationTriangle}
								className={classes.icon}
								color='yellow'
							/>
						</div>

						<div className={classes.notifyMessage}>
							{tArgz(
								'notifyMessage',
								{ providerName: rateBookingDetails.providerName },
								tPrefixModal,
							)}
							<br />
							{phone}
							<br />
							{email}
						</div>
					</div>
					<div>
						<Spacer height={spacerHeight} />
						<AlignRight>
							<Button
								color={'green'}
								onClick={onOk}
								testId={'notify-provider-modal-ok-button'}
							>
								{t('ok', tPrefixModal)}
							</Button>
						</AlignRight>
					</div>
				</div>
			)}
			isOpen={isModalOpen}
			onModalClose={onClose}
			closeModalX={true}
			title={t('notifyProviderModal', tPrefixModal)}
		/>
	)
}
