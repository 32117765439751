import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { Button } from 'ui/components/common/button'
import { Modal } from 'ui/components/common/modal'
import { t, tString } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight'
import { fireAndForget } from 'ui/lib/async'
import { IStateDockSchedulerAppointmentDetails } from 'ui/pages/dock-scheduler/state/sosDockSchedulerAppointmentDetails'
import { sosDockSchedulerAppointmentDetails } from '../state'
import { tPrefixDockSchedulerAppointmentDetails } from './DockSchedulerAppointmentDetails'
import * as classes from './DockSchedulerAppointmentDetails.module.scss'

export const CheckoutErrorModal: FC = (props: {
	state: IStateDockSchedulerAppointmentDetails
}) => {
	return (
		<Modal
			title={tString('checkOutErrors', tPrefixDockSchedulerAppointmentDetails)}
			isOpen={props.state.checkOutErrors.length > 0}
			closeModalX={true}
			onModalClose={() =>
				sosDockSchedulerAppointmentDetails.setCheckOutErrors([])
			}
			content={() => (
				<div>
					{/*{props.state.checkOutErrors.map(makeErrorMessage)}*/}
					<div className={classes.errorModalPrompt}>
						{t(
							'overwriteCheckInWithCheckOutQuestion',
							tPrefixDockSchedulerAppointmentDetails,
						)}
					</div>
					<AlignRight>
						<Button
							onClick={() =>
								fireAndForget(
									() => sosDockSchedulerAppointmentDetails.sendCheckOutToApi(),
									'Sending checkout to API with inconsistencies',
								)
							}
							isSpinning={props.state.submitting}
							testId={'overrideCheckInWithCheckout'}
						>
							{t(
								'overwriteWithCheckout',
								tPrefixDockSchedulerAppointmentDetails,
							)}
						</Button>
					</AlignRight>
				</div>
			)}
		/>
	)
}
