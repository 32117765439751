import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { Card } from 'react-bootstrap'
import { apiTypes } from 'ui/api'
import {
	tAccessorial,
	tBillToParty,
	tCustomerInvoicingStatus,
	tEquipmentType,
	tPaymentType,
	tShipmentFlow,
} from 'ui/components/i18n/commonTranslations'
import { t } from 'ui/components/i18n/i18n'
import {
	IKeyValueOptions,
	KeyValue,
	KeyValueSpacer,
	SimpleKeyValueTable,
} from 'ui/components/table'
import { theme } from 'ui/theme'
import * as classes from './BrokerShipmentCards.module.scss'

const tPrefix = 'page.shipmentProfile.broker.shipmentDetails'

interface ShipmentDetailsData {
	identifier: string
	brokerReferenceNumber: string
	customerInvoicingStatus: string
	pickupDate: string
	bol: string
	equipmentType: string
	accessorials: string
	bidDuration: number
	sealNumber: string
	trailerLength: number
	billToParty: string
	paymentType: string
	shipmentFlow: string
	totalWeight: number
	totalMiles: number
	specialInstructions: string
}

export const BrokerShipmentDetailsCard: FC = (props: {
	shipment: apiTypes.BrokerShipmentResponse
}) => {
	const { shipment } = props
	const keyValueTableOptions: IKeyValueOptions = { tPrefix }
	const shipmentDetailsData: ShipmentDetailsData = {
		identifier: shipment.identifier,
		brokerReferenceNumber: shipment.brokerReferenceNumber,
		customerInvoicingStatus: tCustomerInvoicingStatus(
			shipment.customerInvoicingStatus,
		),
		pickupDate: shipment.pickupDate,
		bol: shipment.payloads.map((payload) => payload.bolIdentifier).join(', '),
		equipmentType: tEquipmentType(shipment.equipmentType),
		accessorials: (Object.keys(
			shipment.accessorials,
		) as (keyof apiTypes.AccessorialsRequired)[])
			.filter(
				(accessorialKey: keyof apiTypes.AccessorialsRequired) =>
					shipment.accessorials[accessorialKey] === true,
			)
			.map((accessorialKey: keyof apiTypes.AccessorialsRequired) =>
				tAccessorial(accessorialKey),
			)
			.join(', '),
		bidDuration: shipment.bidDuration,
		sealNumber: shipment.sealNumber,
		trailerLength: shipment.trailerLength,
		billToParty: tBillToParty(shipment.billTo?.billToThisParty),
		paymentType: tPaymentType(shipment.bookedRate?.billTo?.paymentType),
		shipmentFlow: tShipmentFlow(shipment.shipmentFlow),
		totalWeight: shipment.totalWeight,
		totalMiles: shipment.estimatedDistance,
		specialInstructions: shipment.specialInstructions,
	}

	return (
		<Card className={classes.card} data-testid='shipment-details-card'>
			<div
				className={`${theme.getBorderBottomColor('lightGray')} ${
					classes.cardTitle
				}`}
			>
				{t('detailsHeader', tPrefix)}
			</div>
			<div className={classes.cardBody}>
				<SimpleKeyValueTable borderless={true}>
					<KeyValue
						options={keyValueTableOptions}
						data={shipmentDetailsData}
						field='identifier'
					/>
					<KeyValue
						options={keyValueTableOptions}
						data={shipmentDetailsData}
						field='brokerReferenceNumber'
					/>
					<KeyValue
						options={keyValueTableOptions}
						data={shipmentDetailsData}
						field='customerInvoicingStatus'
					/>
					<KeyValueSpacer />
					<KeyValue
						options={keyValueTableOptions}
						data={shipmentDetailsData}
						field='pickupDate'
					/>
					<KeyValue
						options={keyValueTableOptions}
						data={shipmentDetailsData}
						field='bol'
					/>
					<KeyValue
						options={keyValueTableOptions}
						data={shipmentDetailsData}
						field='equipmentType'
					/>
					<KeyValue
						options={keyValueTableOptions}
						data={shipmentDetailsData}
						field='accessorials'
					/>
					<KeyValue
						options={keyValueTableOptions}
						data={shipmentDetailsData}
						field='bidDuration'
					/>
					<KeyValue
						options={keyValueTableOptions}
						data={shipmentDetailsData}
						field='sealNumber'
					/>
					<KeyValue
						options={keyValueTableOptions}
						data={shipmentDetailsData}
						field='trailerLength'
					/>
					<KeyValueSpacer />
					<KeyValue
						options={keyValueTableOptions}
						data={shipmentDetailsData}
						field='billToParty'
					/>
					<KeyValue
						options={keyValueTableOptions}
						data={shipmentDetailsData}
						field='paymentType'
					/>
					<KeyValue
						options={keyValueTableOptions}
						data={shipmentDetailsData}
						field='shipmentFlow'
					/>
					<KeyValue
						options={keyValueTableOptions}
						data={shipmentDetailsData}
						field='totalWeight'
					/>
					<KeyValue
						options={keyValueTableOptions}
						data={shipmentDetailsData}
						field='totalMiles'
					/>
					<KeyValue
						options={keyValueTableOptions}
						data={shipmentDetailsData}
						field='specialInstructions'
					/>
				</SimpleKeyValueTable>
			</div>
		</Card>
	)
}
