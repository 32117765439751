import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { apiTypes } from 'ui/api'
import { Card } from 'ui/components/common/card'
import { t } from 'ui/components/i18n/i18n'
import { _idx } from 'ui/lib/_idx'
import { tPrefixMakeOffer } from './OfferDetailsCard'

export const ShipperCard: FC = (props: {
	shipment: apiTypes.ShipmentResponse
}) => {
	return (
		<Card title={t('shipper', tPrefixMakeOffer)} color='darkBlue'>
			{_idx(() => props.shipment.shippedBy)}
		</Card>
	)
}
