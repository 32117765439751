import { useEffect, DependencyList } from 'react'

// Watch for a change without watching all dependencies
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
export function watch<T>(action: () => T, dependencies?: DependencyList): void {
	useEffect(() => {
		action()
	}, dependencies)
}
