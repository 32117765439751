import { FC } from 'app/FunctionalComponent'
import React from 'react'
import styles from './TwoColumn.module.scss'

const TwoColumn: FC = (props: { children: React.ReactNode }) => {
	return <div className={styles.twoColumn}>{props.children}</div>
}

const TwoColumnItem: FC = (props: {
	children: React.ReactNode
	minWidth?: string
	maxWidth?: string
}) => {
	const { minWidth, maxWidth } = props

	if ((!minWidth && maxWidth) || (minWidth && !maxWidth)) {
		throw new Error(
			'hey you need to set both min and max widths or it will not work!',
		)
	}

	return (
		<div className={styles.twoColumnItem} style={{ minWidth, maxWidth }}>
			{props.children}
		</div>
	)
}

export { TwoColumn, TwoColumnItem }
