import { IRequestState } from 'ui/api/requestState'
import {
	createDefaultAccessorialState,
	IAccessorialState,
} from 'ui/components/shared/accessorials/IAccessorialState'
import {
	createDefaultAddressState,
	IAddressState,
} from 'ui/components/shared/address/IAddressState'
import { IDataTableState } from 'ui/components/table'
import { l } from 'ui/lib/lodashImports'
import { IGood } from 'ui/lib/shipments/IGood'
import { IRate } from 'ui/lib/shipments/IRate'
import { createSos } from './secretaryOfState'

export interface IStateShipOrdersTab {
	selectedOrderNumber: string
	requestGetOrders: IRequestState<any>
	dataTableGoods: IDataTableState<IGood>
	dataTableContainers: IDataTableState<any>

	deliveryAddress: IAddressState
	deliveryAccessorials: IAccessorialState
	pickupAddress: IAddressState
	pickupAccessorials: IAccessorialState

	bestShipRate: IRate
	shipRates: IDataTableState<IRate>
}

const initialState: IStateShipOrdersTab = {
	selectedOrderNumber: '',
	requestGetOrders: {},
	dataTableGoods: {},
	dataTableContainers: {},
	deliveryAddress: createDefaultAddressState(null, 'readOnly'),
	deliveryAccessorials: createDefaultAccessorialState([]),
	pickupAddress: createDefaultAddressState(null, 'companyLocation'),
	pickupAccessorials: createDefaultAccessorialState([]),
	bestShipRate: null,
	shipRates: {},
}

const { stateManager, useSubscribe } = createSos(
	'ship-orders-tab',
	'1.0.2',
	initialState,
	{
		useLocalStorage: true,
	},
)
export { useSubscribe }

export function updateSelectedOrderNumber(newVal): void {
	stateManager.produce((ds) => {
		ds.selectedOrderNumber = newVal
	})
}

export function updatePickupAddress(
	addressChanges: Partial<IAddressState>,
): void {
	stateManager.produce((ds) => {
		l.assign(ds.pickupAddress, addressChanges)
	})
}
export function updateDeliveryAddress(
	addressChanges: Partial<IAddressState>,
): void {
	stateManager.produce((ds) => {
		if (addressChanges.address) {
			// TODO: send new address to API
		}
		l.assign(ds.deliveryAddress, addressChanges)
	})
}

export const updateDeliveryAccessorials = (
	accessorialChanges: Partial<IAccessorialState>,
): void => {
	stateManager.produce((ds) => {
		l.assign(ds.deliveryAccessorials, accessorialChanges)
	})
}

export const updatePickupAccessorials = (
	accessorialChanges: Partial<IAccessorialState>,
): void => {
	stateManager.produce((ds) => {
		l.assign(ds.pickupAccessorials, accessorialChanges)
	})
}

// export async function fetchShipment(shipmentId: string) {
// stateManager.produce((ds) => {
// 	ds.selectedOrderNumber = shipmentId
// })
// let result = await apiShipments.fetchShipment((rs) => {
// 	stateManager.produce((ds) => {
// 		ds.requestGetOrders = rs
// 	})
// }, shipmentId)
// if (result.data) {
// 	log('ship-orders-tab', '', result.data)
// 	stateManager.produce((ds) => {
// 		ds.dataTableContainers.data = extractContainersFromShipment(
// 			result.data as any,
// 		)
// 		ds.dataTableGoods.data = extractGoodsFromShipment(result.data)
// 		ds.deliveryAddress = createDefaultAddressState(
// 			getFinalDestination(result.data),
// 		)
// 		ds.pickupAddress = createDefaultAddressState(
// 			getInitialOrigin(result.data),
// 		)
// 		// ds.pickupAccessorials = createDefaultAccessorialState([
// 		//   { accessorialKey: 'cats', checked: true },
// 		//   { accessorialKey: 'dogs', checked: false },
// 		// ])
// 		ds.pickupAccessorials = { fields: [] }
// 	})
// }
// }
