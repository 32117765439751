import React, { useState } from 'react'
import { Spinner, InputGroup } from 'react-bootstrap'
import { useParams, Redirect } from 'react-router'
import {
	Field,
	Form,
	Formik,
	FieldArray,
	ErrorMessage,
	FormikErrors,
} from 'formik'
import { Toast, ToastState } from 'common/components/toast/Toast'
import { t, tString } from 'ui/components/i18n/i18n'
import { UseDataApiParams, useDataApi } from 'app/marketplace/services'
import { callApi } from 'app/marketplace/services/api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { log } from 'ui/lib/log'
import { FC } from 'app/FunctionalComponent'

const RepeatedMcNumberField: FC = (props: {
	mcNumbers: string[]
	isSaving: boolean
	isLoading: boolean
	errors: string | string[] | FormikErrors<any> | FormikErrors<any>[]
}) => {
	const { isSaving, isLoading, mcNumbers, errors } = props

	return (
		<>
			<div className='row'>
				<label className='form-label text-muted' htmlFor='mcNumber'>
					{t('page.marketplace.providers.properties.mcnumber')}
				</label>
			</div>
			<FieldArray name='mcNumber'>
				{(arrayHelpers) => (
					<>
						{mcNumbers.map((mcNumber, index) => {
							return index === 0 ? (
								<div className='form-group row' key={index}>
									<InputGroup>
										<Field
											className={
												'form-control' +
												(errors && errors[0] ? ' is-invalid' : '')
											}
											type='text'
											id='mcNumber.0'
											name='mcNumber.0'
											data-testid='mcNumber.0'
											disabled={isSaving || isLoading}
										/>
										<button
											className='btn btn-outline-primary ml-1 border'
											type='button'
											id='add-mc-number'
											name='add-mc-number'
											disabled={isSaving || isLoading}
											data-testid='add-mc-number'
											onClick={() => {
												arrayHelpers.push('')
											}}
										>
											<FontAwesomeIcon icon={faPlus} />
										</button>
									</InputGroup>
									<ErrorMessage name='mcNumber[0]'>
										{(msg) => (
											<div className='d-block invalid-feedback'>{t(msg)}</div>
										)}
									</ErrorMessage>
								</div>
							) : (
								<div className='form-group row' key={index}>
									<InputGroup>
										<Field
											className={
												'form-control' +
												(errors && errors[index] ? ' is-invalid' : '')
											}
											type='text'
											id={`mcNumber.${index}`}
											name={`mcNumber.${index}`}
											data-testid={`mcNumber.${index}`}
											disabled={isSaving || isLoading}
										/>
										<button
											className='btn btn-outline-primary ml-1 border'
											type='button'
											id={`remove-mc-number.${index}`}
											name={`remove-mc-number.${index}`}
											disabled={isSaving || isLoading}
											data-testid={`remove-mc-number.${index}`}
											onClick={() => arrayHelpers.remove(index)}
										>
											<FontAwesomeIcon icon={faMinus} />
										</button>
									</InputGroup>
									<ErrorMessage name={`mcNumber[${index}]`}>
										{(msg) => (
											<div className='d-block invalid-feedback'>{t(msg)}</div>
										)}
									</ErrorMessage>
								</div>
							)
						})}
					</>
				)}
			</FieldArray>
		</>
	)
}

export const ProviderDetails: FC = () => {
	const { id } = useParams<{ id: string }>()

	let dataConfig: UseDataApiParams = {
		path: 'providers/' + id,
		options: { method: 'GET' },
	}

	const [{ data, isLoading, isError }, doFetch] = useDataApi(dataConfig, {})
	const [isSaving, setIsSaving] = useState(false)
	const [isValidating, setIsValidating] = useState(false)
	const [isCanceling, setIsCanceling] = useState(false)

	const errorToast: ToastState = {
		type: 'danger',
		body: tString('page.marketplace.providers.details.toastHeader.error'),
	}

	const successToast: ToastState = {
		type: 'success',
		body: tString('page.marketplace.providers.details.toastHeader.saved'),
	}

	const initialValues = {
		name: data.name || '',
		isActive: data.isActive || false,
		mcNumber:
			data.mcNumber && Array.isArray(data.mcNumber)
				? data.mcNumber
				: [data.mcNumber || ''],
		usdotNumber: data.usdotNumber || '',
	}

	function cancel(): void {
		setIsCanceling(true)
	}

	async function validate(
		values,
	): Promise<{
		usdotNumber?: string
		mcNumber?: string[]
	}> {
		setIsValidating(true)
		const errors: { usdotNumber?: string; mcNumber?: string[] } = {}

		if (values.usdotNumber.length || values.mcNumber[0].length) {
			const query = [`(usdotNumber.keyword:${values.usdotNumber})`]
			values.mcNumber.forEach((mc) => {
				if (mc.length) query.push(`(mcNumber.keyword:${mc})`)
			})

			try {
				setIsSaving(true)

				const axios = await callApi({}, 'providers', {
					take: 10,
					query: query.join(' OR '),
				})
				const results = axios.data.entities
				if (results.length) {
					const usdotNumbers = results.map((r) => {
						if (r.id !== data.id) {
							return r.usdotNumber
						}
						return undefined
					})

					if (usdotNumbers.includes(values.usdotNumber)) {
						errors.usdotNumber =
							'page.marketplace.providers.details.errors.usdotNumber'
					}
					let mcNumbers = []
					results.forEach((r) => {
						if (r.id !== data.id) {
							mcNumbers = mcNumbers.concat(
								Array.isArray(r.mcNumber) ? r.mcNumber : [r.mcNumber],
							)
						}
					})
					errors.mcNumber = []
					values.mcNumber.forEach((mc, index) => {
						if (mcNumbers.includes(mc)) {
							errors.mcNumber[index] =
								'page.marketplace.providers.details.errors.mcNumber'
						}
					})
					if (!errors.mcNumber.length) {
						delete errors.mcNumber
					}
				}
			} catch (e) {
				log('provider details', 'error during validation', e)
			} finally {
				setIsSaving(false)
				setIsValidating(false)
			}
		}
		return errors
	}

	function updateProvider(values): void {
		setIsSaving(false)

		// remove empty mc Numbers
		values.mcNumber = values.mcNumber.filter((mc) => mc.length)

		dataConfig = {
			path: 'providers/' + id,
			options: { method: 'PUT', data: Object.assign({}, data, values) },
		}

		doFetch(dataConfig)
		setIsSaving(true)
	}

	return (
		<>
			{isSaving && !isValidating && !isError && !isLoading && (
				<>
					<Toast toast={successToast} />
					<Redirect to='/marketplace/providers/' />
				</>
			)}

			{isError && (
				<>
					<Toast toast={errorToast} />
					{data.error.response.status === 404 && (
						<Redirect to='/marketplace/providers/' />
					)}
				</>
			)}

			{isCanceling && <Redirect to='/marketplace/providers/' />}

			<div className='d-flex justify-content-center'>
				<div className='col-lg-6'>
					<Formik
						enableReinitialize={true}
						initialValues={{
							name: initialValues.name,
							isActive: initialValues.isActive,
							mcNumber: initialValues.mcNumber,
							usdotNumber: initialValues.usdotNumber,
						}}
						validate={validate}
						validateOnBlur={false}
						validateOnChange={false}
						onSubmit={updateProvider}
					>
						{({ errors, values }) => (
							<Form noValidate>
								<div className='form-group row'>
									<label className='form-label text-muted' htmlFor='name'>
										{t('page.marketplace.providers.properties.name')}
									</label>
									<Field
										className='form-control'
										type='text'
										id='name'
										name='name'
										data-testid='name'
										disabled={true}
									/>
								</div>
								<RepeatedMcNumberField
									mcNumbers={values.mcNumber}
									isSaving={isSaving}
									isLoading={isLoading}
									errors={errors.mcNumber}
								/>
								<div className='form-group row'>
									<label
										className='form-label text-muted'
										htmlFor='usdotNumber'
									>
										{tString(
											'page.marketplace.providers.properties.usdotnumber',
										)}
									</label>
									<Field
										className={
											'form-control' +
											(errors && errors.usdotNumber ? ' is-invalid' : '')
										}
										type='text'
										id='usdotNumber'
										name='usdotNumber'
										data-testid='usdotNumber'
										disabled={isSaving || isLoading}
									/>
									<ErrorMessage name='usdotNumber'>
										{(msg) => (
											<div className='d-block invalid-feedback'>{t(msg)}</div>
										)}
									</ErrorMessage>
								</div>
								<div className='row checkbox d-none'>
									<div className='custom-control custom-switch'>
										<Field
											type='checkbox'
											className='custom-control-input'
											name='isActive'
											id='isActive'
											data-testid='isActive'
											disabled={isSaving || isLoading}
										/>
										<label className='custom-control-label' htmlFor='isActive'>
											{tString('page.marketplace.providers.properties.active')}
										</label>
									</div>
								</div>
								<div className='row flex-row-reverse'>
									<button
										className='btn btn-primary'
										type='submit'
										id='saveButton'
										name='saveButton'
										disabled={isSaving || isLoading}
										data-testid='submit'
									>
										{isSaving && (
											<Spinner
												as='span'
												size='sm'
												className='position-absolute mt-1'
												animation='border'
												role='status'
											>
												<span className='sr-only'>Saving...</span>
											</Spinner>
										)}
										<span className='px-4'>
											{tString(
												'page.marketplace.providers.details.buttonText.save',
											)}
										</span>
									</button>
									<button
										className='btn btn-link'
										type='button'
										id='cancelButton'
										name='cancelButton'
										disabled={isSaving || isLoading}
										data-testid='cancel'
										onClick={cancel}
									>
										{tString('common.cancel')}
									</button>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		</>
	)
}
