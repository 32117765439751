import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { Card, ListGroup } from 'react-bootstrap'
import {
	BrokerShippingDocumentResponse,
	ShippingDocumentResponse,
} from 'ui/api/apiTypes'
import { IconButton, solidIcons } from 'ui/components/common/icon'

export const DocumentList: FC = (props: {
	documents: Array<ShippingDocumentResponse | BrokerShippingDocumentResponse>
	onRemove?: (ShippingDocumentResponse) => void
	onOpen?: (FileUpload) => void
	canDelete?: boolean
}) => {
	const { canDelete, onRemove, onOpen } = props

	return (
		props.documents.length > 0 && (
			<Card className='p-2'>
				<ListGroup horizontal>
					{props.documents.map((file, key) => (
						<ListGroup.Item key={key}>
							<span>
								{file.filename}
								{canDelete && (
									<IconButton
										onClick={onRemove}
										icon={solidIcons.faTrash}
										buttonClassName='float-right ml-2'
									/>
								)}
								{onOpen && (
									<IconButton
										onClick={() => onOpen(file)}
										icon={solidIcons.faEye}
										buttonClassName='float-right ml-2'
									/>
								)}
							</span>
						</ListGroup.Item>
					))}
				</ListGroup>
			</Card>
		)
	)
}
