import { FC } from 'app/FunctionalComponent'
import React, { useState } from 'react'
import { apiTypes } from 'ui/api'
import { IRequestState } from 'ui/api/requestState'
import { Input } from 'ui/components/common/input/Input'
import { LinkButton } from 'ui/components/common/link'
import { OkCancelButtons } from 'ui/components/common/okCancelButtons'
import { sosRouter } from 'ui/components/common/router'
import { StackedItem } from 'ui/components/form/StackedItem'
import { t } from 'ui/components/i18n/i18n'
import { Spacer } from 'ui/components/layout/spacer/Spacer'
import { SimpleAddress } from 'ui/components/shared/address/SimpleAddress'
import {
	IKeyValueOptions,
	KeyValue,
	KeyValueSeparator,
	SimpleKeyValueTable,
} from 'ui/components/table'
import { l } from 'ui/lib/lodashImports'
import { stringArrayRenderer } from 'ui/pages/shipments/ShipmentsListHeaderRenderers'
import { addClass, getTextColor } from 'ui/theme/theme'
import { sosProviderManagement } from '..'
import { searchProviders } from '../functions/searchProviders'
import { AddProviderModalMode, tPrefixProvidersAdd } from './AddProviderModal'
import * as classes from './AddProviderModal.module.scss'
import { AddProviderModalContent } from './AddProviderModalContent'

export const AddProviderModalSearch: FC = (props: {
	onChangeMode: (newMode: AddProviderModalMode) => void
	onClose: () => void
	tPrefix: string
}) => {
	const [dotOrMcNumber, setDotOrMcNumber] = useState<string>()
	const [isSearchingForProvider, setIsSearchingForProvider] = useState<boolean>(
		false,
	)
	const [noProviderFound, setNoProviderFound] = useState<boolean>(false)
	const [provider, setProvider] = useState<apiTypes.ProviderResponse>()
	const [isActivatingProvider, setIsActivatingProvider] = useState<boolean>(
		false,
	)

	const close = (): void => {
		setDotOrMcNumber(null)
		setNoProviderFound(false)
		setProvider(null)
		setIsSearchingForProvider(false)
		setIsActivatingProvider(false)
		props.onClose()
	}

	const searchForProvider = async (): Promise<void> => {
		setNoProviderFound(false)
		const query = `usdotNumber:${dotOrMcNumber} OR mcNumber:${dotOrMcNumber}`

		setIsSearchingForProvider(true)
		const providerResponse: IRequestState<apiTypes.ProviderListResponse> = await searchProviders(
			1,
			0,
			query,
		)

		if (providerResponse.data?.entities[0]) {
			setProvider(providerResponse.data?.entities[0])
		} else {
			setNoProviderFound(true)
		}
		setIsSearchingForProvider(false)
	}

	const addProvider = async (): Promise<void> => {
		setIsActivatingProvider(true)
		const activatedprovider = await sosProviderManagement.activateProvider(
			provider,
		)
		setIsActivatingProvider(false)
		if (activatedprovider) {
			close()
		}
		sosRouter.navigate('/providers/' + activatedprovider?.id)
	}

	const providerInformation = provider && {
		name: provider.providerName,
		address: {
			street1: provider.billingAddress?.street1,
			street2: provider.billingAddress?.street2,
			city: provider.billingAddress?.city,
			state: provider.billingAddress?.state,
			zip: provider.billingAddress?.zip,
			country: provider.billingAddress?.country,
		},
		usDot: provider.usdotNumber,
		mcNumber: provider.mcNumber,
		scac: provider.scac,
	}

	const providerKeyValueOptions: IKeyValueOptions = {
		tPrefix: tPrefixProvidersAdd,
	}

	const body = (
		<div data-testid={'add-provider-search'}>
			<StackedItem
				label={t('usdotOrMcNumber', props.tPrefix)}
				hideOptional={true}
			>
				<Input
					value={dotOrMcNumber}
					onChange={setDotOrMcNumber}
					readOnly={!l.isNil(provider)}
					testId={'add-provider-modal-search'}
				/>
			</StackedItem>
			{l.isNil(provider) && (
				<LinkButton
					onClick={() => props.onChangeMode('warning')}
					className={getTextColor('linkBlue')}
					testId={'create-provider-link'}
				>
					<div className={classes.smallFont}>
						{t('noMcOrDot', props.tPrefix)}
					</div>
				</LinkButton>
			)}
			<Spacer />
			<div className={classes.modalSearchResults}>
				{provider && (
					<div className='d-flex justify-content-center'>
						<SimpleKeyValueTable borderless={true}>
							<KeyValue
								options={providerKeyValueOptions}
								data={providerInformation}
								field='name'
								testId={'provider-name'}
							/>
							<KeyValueSeparator />
							<KeyValue
								options={providerKeyValueOptions}
								data={providerInformation}
								field='address'
								renderer={(data) => (
									<div style={{ minHeight: '140px' }}>
										<SimpleAddress address={data} />
									</div>
								)}
								testId={'provider-address'}
							/>
							<KeyValueSeparator />
							<KeyValue
								options={providerKeyValueOptions}
								data={providerInformation}
								field='usDot'
								testId={'provider-usDot'}
							/>
							<KeyValue
								options={providerKeyValueOptions}
								data={providerInformation}
								field='mcNumber'
								renderer={stringArrayRenderer}
								testId={'provider-mcNumber'}
							/>
							<KeyValue
								options={providerKeyValueOptions}
								data={providerInformation}
								field='scac'
								testId={'provider-scac'}
							/>
						</SimpleKeyValueTable>
					</div>
				)}
			</div>
		</div>
	)

	const footer = (
		<div>
			<div className='d-flex flex-row justify-content-between'>
				<div className={addClass(getTextColor('red'), classes.warningText)}>
					{noProviderFound && (
						<div data-testid={'no-provider-found'}>
							{t('noProviderFound', tPrefixProvidersAdd)}
						</div>
					)}
					{provider?.isActive && (
						<div data-testid={'provider-already-active'}>
							{t('providerAlreadyActive', tPrefixProvidersAdd)}
						</div>
					)}
				</div>
				{provider?.isActive && (
					<OkCancelButtons
						ok={t('ok', tPrefixProvidersAdd)}
						okColor='blue'
						onOk={close}
						isValid={true}
						okTestId={'provider-active-ok'}
					/>
				)}
				{provider && !provider.isActive && (
					<OkCancelButtons
						ok={t('addProvider', props.tPrefix)}
						okColor='blue'
						cancel={t('cancel', props.tPrefix)}
						onOk={addProvider}
						onCancel={close}
						isValid={true}
						okTestId={'activate-provider-button'}
						cancelTestId={'cancel-activate-provider-button'}
						isSpinning={isActivatingProvider}
					/>
				)}
				{l.isNil(provider) && (
					<OkCancelButtons
						ok={t('search', props.tPrefix)}
						okColor='blue'
						cancel={t('cancel', props.tPrefix)}
						onOk={searchForProvider}
						onCancel={close}
						isValid={!l.isNil(dotOrMcNumber) && dotOrMcNumber !== ''}
						okTestId={'search-for-provider'}
						isSpinning={isSearchingForProvider}
					/>
				)}
			</div>
		</div>
	)

	return <AddProviderModalContent body={body} footer={footer} />
}
