import { NotFound } from 'app/AppRouter'
import React, { ReactElement, useState } from 'react'
import { Button } from 'ui/components/common/button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { ClientSelector } from 'ui/components/broker'
import { Loader } from 'ui/components/common/loader'
import { Debug } from 'ui/components/dev'
import { StackedItem } from 'ui/components/form'
import { watch } from 'ui/components/hooks'
import { t } from 'ui/components/i18n/i18n'
import { fireAndForget } from 'ui/lib/async'
import { l } from 'ui/lib/lodashImports'
import { sos2 } from 'ui/lib/state/sos2'
import { UpsertCompanyModal } from 'ui/pages/customer-management/broker/default-markup/UpsertCompanyModal'
import { Layout } from 'ui/pages/layout'
import { sosUser } from 'ui/state'
import { sosCustomerManagement } from '../..'
import { CustomerManagementTabs } from '../customer-management-tabs/CustomerManagementTabs'
import classes from './ClientManagement.module.scss'
import { AlignRight } from 'ui/components/layout/alignRight'

export const tPrefixClientManagement = 'page.customerManagement'

export const ClientManagementPage = (): ReactElement => {
	const stateUser = sos2.useSubscription(sosUser.getSos())
	const state = sos2.useSubscription(sosCustomerManagement.sos)

	const [isUpsertCompanyModalOpen, setIsUpsertCompanyModalOpen] = useState<
		boolean
	>(false)
	const [isSavingCustomerDetails, setIsSavingCustomerDetails] = useState<
		boolean
	>(false)

	watch(() => {
		fireAndForget(async () => {
			await sosCustomerManagement.updateWithSelectedConfig(
				stateUser.selectedClientConfig,
			)
		}, 'Updating Customer management data when client config changes')
	}, [stateUser.selectedClientConfig?.id])

	if (!sosUser.isUserBroker()) {
		return (
			<Layout>
				<NotFound />
			</Layout>
		)
	}
	return (
		<Layout>
			<div className='bootstrap-wrapper'>
				<div className={classes.container}>
					<Row className={'align-items-center'}>
						<Col className={'mb-3'} xs={3}>
							<StackedItem
								label={t('clientSelector', tPrefixClientManagement)}
								hideOptional={true}
							>
								<ClientSelector isClearable={false} />
							</StackedItem>
						</Col>
						<Col>
							<div>
								<Button
									color={'green'}
									onClick={() => {
										setIsUpsertCompanyModalOpen(true)
									}}
									testId={'add-company'}
								>
									{t('addCompany', tPrefixClientManagement)}
								</Button>
							</div>
						</Col>

						{state.brokerLocationId &&
							state.clientAddress &&
							state.paymentTermsForm &&
							state.brokerDefaultMarkupForm &&
							state.addressForm && (
								<Col>
									<AlignRight>
										<Button
											color={'blue'}
											onClick={async () => {
												setIsSavingCustomerDetails(true)
												await sosCustomerManagement.saveCustomerDetails()
												setIsSavingCustomerDetails(false)
											}}
											testId={'save'}
											isSpinning={isSavingCustomerDetails}
										>
											{t('save', tPrefixClientManagement)}
										</Button>
									</AlignRight>
								</Col>
							)}
					</Row>
					<Loader
						isLoading={
							!state.clientAddress && !l.isNil(stateUser.selectedClientConfig)
						}
					/>

					{state.brokerLocationId &&
						state.clientAddress &&
						state.paymentTermsForm &&
						state.brokerDefaultMarkupForm &&
						state.addressForm && (
							<CustomerManagementTabs
								state={state}
								tPrefix={tPrefixClientManagement}
							/>
						)}

					{isUpsertCompanyModalOpen && (
						<UpsertCompanyModal
							show={isUpsertCompanyModalOpen}
							onHide={(): void => {
								setIsUpsertCompanyModalOpen(false)
							}}
						/>
					)}

					<Debug
						data={state.brokerDefaultMarkupForm}
						label='Broker Default Markup'
					/>
				</div>
			</div>
			<Debug label={'stateUser'} data={stateUser} />
			<Debug label={'state'} data={state} />
		</Layout>
	)
}
