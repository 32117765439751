import { sosCompanyManagement } from '.'
import { apiLocation } from 'ui/api'
import { sosUser } from 'ui/state'
import { LocationResponse } from 'ui/api/apiTypes'
import l from 'lodash'
import { Address } from 'ui/api/apiTypes'

export async function saveLocationAddress(
	fieldToUpdate: keyof LocationResponse,
	newVal: Address,
): Promise<void> {
	const locationRequest: LocationResponse = l.cloneDeep(
		sosCompanyManagement.getSos().getState().location,
	)
	const locationID: string = sosUser.getSos().getState().requestUserInfo.data
		.locationId
	l.set(locationRequest, fieldToUpdate, newVal)
	const result = await apiLocation.putLocation(
		() => {},
		locationID,
		locationRequest,
	)
	if (result.data) {
		sosCompanyManagement.getSos().change((ds) => {
			ds.location = locationRequest
			ds.apiSuccess = true
		})
	} else {
		sosCompanyManagement.getSos().change((ds) => {
			ds.apiError =
				'Unable to save address. Please review entries and try again.'
		})
	}
}
