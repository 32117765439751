import { apiTypes } from 'ui/api'
import { l } from 'ui/lib/lodashImports'

export interface IFormattedManifest {
	id: string
	createdDate: string
	uploadStatus: 'success' | 'nonFatalError' | 'fatalError'
	packageCount: number
	providerErrors: string[]
	tmsErrors: string[]
	voidDate: string
	providerFile: string
	image: string
	shipmentIds: string[]
	providerName: apiTypes.ApiProviderName
	status: apiTypes.ManifestStatus
	errors?: string[]
	clearanceFacilityLocationId?: string
}

export const processManifests = (
	manifests: apiTypes.ManifestResponse[],
): IFormattedManifest[] => {
	return l.map(manifests, (manifest) => {
		return {
			id: manifest.id,
			createdDate: manifest.createdDate,
			uploadStatus: manifest.tmsErrors?.length > 0 ? 'fatalError' : 'success',
			packageCount: manifest.parcelCount,
			providerErrors: [],
			tmsErrors: manifest?.tmsErrors || [],
			voidDate: manifest.voidedDate,
			providerFile: manifest.providerFile,
			image: manifest.image,
			shipmentIds: manifest.shipmentIds,
			providerName: manifest.providerName,
			status: manifest.status,
			errors: manifest.tmsErrors.concat(manifest.providerErrors),
			clearanceFacilityLocationId: manifest.clearanceFacilityLocationId,
		}
	})
}
