import { FC } from 'app/FunctionalComponent'
import React from 'react'
import classes from './Tabs.module.scss'

export const TabItem: FC = (props: {
	isSelected?: boolean
	children: React.ReactNode
	onClick?: () => void
}) => {
	let className = classes.tabItem
	if (props.isSelected) {
		className += ' ' + classes.selected
	}

	return (
		<div className={className} onClick={props.onClick}>
			{props.children}
		</div>
	)
}
