import { l } from 'ui/lib/lodashImports'
import { r } from 'ui/lib/ramdaImports'
import { AccessorialMapType, IAccessorialMetadata } from './accessorialsTypes'
import { deepFreeze } from 'ui/lib/state'
import { mapToArray } from 'ui/lib/array/mapToArray'

const _allAccessorials: AccessorialMapType = {
	appointment: {
		isDelivery: true,
		isParcel: true,
		childrenAccessorials: {
			appointmentContactName: {
				type: 'text',
			},
			appointmentContactPhone: {
				type: 'text',
			},
		},
	},
	pickupAtStorage: {
		isPickup: true,
		pickupKey: 'pickupAtStorage',
		isInvoice: true,
	},

	deliveryAtStorage: {
		isDelivery: true,
		deliveryKey: 'deliveryAtStorage',
		isInvoice: true,
	},

	californiaComplianceCharge: {
		isInvoice: true,
	},
	collectOnDelivery: {
		isDelivery: true,
		deliveryKey: 'collectOnDelivery',
	},
	continuousRefrigerationMode: {
		isShipmentWide: true,
	},
	customsDeliveryFee: {
		isInvoice: true,
		isDelivery: true,
	},
	detentionWithPower: {
		isInvoice: true,
		isPickup: true,
		pickupKey: 'pickupDetentionWithPower',
		isDelivery: true,
		deliveryKey: 'deliveryDetentionWithPower',
	},
	detentionWithoutPower: {
		isInvoice: true,
		isPickup: true,
		pickupKey: 'pickupDetentionWithoutPower',
		isDelivery: true,
		deliveryKey: 'deliveryDetentionWithoutPower',
	},
	diversion: {
		isInvoice: true,
		isShipmentWide: true,
	},
	duringNonBusinessHours: {
		isPickup: true,
		pickupKey: 'pickupDuringNonBusinessHours',
		isDelivery: true,
		deliveryKey: 'deliveryDuringNonBusinessHours',
	},
	extraLaborOrLumper: {
		isPickup: true,
		pickupKey: 'extraLaborOrLumperPickup',
		isDelivery: true,
		deliveryKey: 'extraLaborOrLumperDelivery',
	},

	guaranteeCharge: {
		isInvoice: true,
		isDelivery: true,
	},
	hazardousMaterial: {
		isShipmentWide: true,
	},
	inside: {
		isPickup: true,
		pickupKey: 'insidePickup',
		isDelivery: true,
		deliveryKey: 'insideDelivery',
	},
	liftgate: {
		isPickup: true,
		pickupKey: 'liftgateAtPickup',
		isDelivery: true,
		deliveryKey: 'liftgateAtDelivery',
	},
	notifyConsignee: {
		isDelivery: true,
		childrenAccessorials: {
			notifyConsigneeName: {
				type: 'text',
			},
			notifyConsigneePhone: {
				type: 'text',
			},
		},
	},
	overDimension: {
		isShipmentWide: true,
	},
	redelivery: {
		isInvoice: true,
		isPickup: true,
		isDelivery: true,
	},
	reweighAndInspection: {
		isInvoice: true,
	},
	sortAndSegregate: {
		isPickup: true,
		pickupKey: 'sortAndSegregateAtPickup',
		isDelivery: true,
		deliveryKey: 'sortAndSegregateAtDelivery',
	},
	stackable: {
		isPackage: true,
	},
	singleShipment: {},
	tarp: {
		isShipmentWide: true,
	},

	temperature: {
		childrenAccessorials: {
			temperatureMinimum: {
				nestedPath: 'temperatureInfo',
				type: 'text',
			},
			temperatureMaximum: {
				nestedPath: 'temperatureInfo',
				type: 'text',
			},
			temperatureRange: {
				nestedPath: 'temperatureInfo',
			},
		},
	},

	saturday: {
		isPickup: true,
		pickupKey: 'saturdayPickup',
		isDelivery: true,
		deliveryKey: 'saturdayDelivery',
	},

	// 	//Parcel Only
	cod: {
		isParcel: true,
		childrenAccessorials: {
			codCollectionAmount: {
				type: 'text',
			},
			codMoneyOrderOnly: {
				type: 'text',
			},
		},
	},
	holdAtLocation: {
		isParcel: true,
		childrenAccessorials: {
			holdPhone: {
				type: 'text',
			},
			holdStreet: {
				type: 'text',
			},
			holdCity: {
				type: 'text',
			},
			holdCode: {
				type: 'text',
			},
			holdState: {
				type: 'text',
			},
			holdCountry: {
				type: 'text',
			},
		},
	},
	// parcelAppointment: {
	//
	// 	childrenAccessorials: {
	// 		scheduleAppointmentContactName: {
	//
	// 			renderer: AccessorialInput,
	// 		},
	// 		scheduleAppointmemntContactPhone: {
	//
	// 			renderer: AccessorialInput,
	// 		},
	// 	},
	// },
	signature: {
		isParcel: true,
		isPayload: true,
		childrenAccessorials: {
			signatureType: {
				type: 'select',
				selectOptions: ['Indirect', 'Direct', 'Adult'],
			},
		},
	},
	// 	fedexSmartPost: {
	//
	// 		// isParcel: true,
	// 		isDelivery: true,
	// 		isPayload: true,
	// 		childrenAccessorials: {
	// 			smartPostHub: {
	//
	// 				renderer: AccessorialSelect,
	// 				translateOptions: { doNotTranslate: true },
	// 				rendererOptions: {
	// 					selectOptions: [
	// 						'5185 Allentown, PA',
	// 						'5303 Atlanta, GA',
	// 						'5281 Charlotte, NC',
	// 						'5929 Chino, CA',
	// 						'5751 Dallas, TX',
	// 						'5802 Denver, CO',
	// 						'5481 Detroidt, MI',
	// 						'5087 Edison, NJ',
	// 						'5431 Grove City, OH',
	// 						'5771 Houston, TX',
	// 						'5436 Graveport, OH',
	// 						'5902 Indianapolis, IN',
	// 						'5648 Kansas City, KS',
	// 						'5254 Martinsburg WV',
	// 						'5379 Memphis, TN',
	// 						'5552 Minneapolis, MN',
	// 						'5531 New Berlin, WI',
	// 						'5110 Newburgh, NY',
	// 						'5015 Northborough, MA',
	// 						'5327 Orlando, FL',
	// 						'5194 Philadelphia, PA',
	// 						'5854 Pheonix, AZ',
	// 						'5150 Pittseburgh, PA',
	// 						'5958 Sacramento, CA',
	// 						'5983 Seattle, WA',
	// 						'5631 St. Louis, MO',
	// 						'5893 Reno, NV',
	// 					],
	// 				},
	// 			},
	// 		},
	// 	},
	noSignature: {
		isParcel: true,
		isPayload: true,
	},
	addressCorrection: {
		isParcel: true,
		isInvoice: true,
		isPayload: true,
	},
	billingAdjustmentForWeekend: {
		isParcel: true,
		isInvoice: true,
		isPayload: true,
	},
	zoneAdjustment: {
		isParcel: true,
		isInvoice: true,
		isPayload: true,
	},
	returns: {
		isParcel: true,
		isInvoice: true,
		isPayload: true,
	},
	addressSurcharge: {
		isParcel: true,
		isInvoice: true,
		isPayload: true,
	},
	packageSurcharge: {
		isParcel: true,
		isInvoice: true,
		isPayload: true,
	},
	timeSurcharge: {
		isParcel: true,
		isInvoice: true,
		isPayload: true,
	},
	rerouteSurcharge: {
		isParcel: true,
		isInvoice: true,
		isPayload: true,
	},
	//this is set based on a limitedAccessAccessorial being selected
	limitedAccessPickup: {
		isLimitedAccess: true,
		// This is a meta key that gets added if a isLimitedAccess location is selected
		// isPickup: true, // Don't want this to be selectable, this is automatically added
	},
	limitedAccessDelivery: {
		isLimitedAccess: true,
		// This is a meta key that gets added if a isLimitedAccess location is selected
		// isDelivery: true, // Don't want this to be selectable, this is automatically added
	},
	//these accessorials will turn on based on location select in address
	school: {
		isLimitedAccess: true,
		pickupKey: 'pickupAtSchool',
		deliveryKey: 'deliveryAtSchool',
	},
	tradeshow: {
		isLimitedAccess: true,
		pickupKey: 'pickupAtExhibitionOrTradeShow',
		deliveryKey: 'deliveryAtExhibitionOrTradeShow',
	},
	prison: {
		isLimitedAccess: true,
		pickupKey: 'pickupAtPrison',
		deliveryKey: 'deliveryAtPrison',
	},
	residential: {
		isLimitedAccess: true,
		pickupKey: 'pickupAtPrivateResidence',
		deliveryKey: 'deliveryAtPrivateResidence',
	},
	military: {
		isLimitedAccess: true,
		pickupKey: 'pickupAtMilitaryLocation',
		deliveryKey: 'deliveryAtMilitaryLocation',
	},
	mine: {
		isLimitedAccess: true,
		pickupKey: 'pickupAtMine',
		deliveryKey: 'deliveryAtMine',
	},
	construction: {
		isLimitedAccess: true,
		pickupKey: 'pickupAtConstructionSite',
		deliveryKey: 'deliveryAtConstructionSite',
	},
	church: {
		isLimitedAccess: true,
		pickupKey: 'pickupAtChurch',
		deliveryKey: 'deliveryAtChurch',
	},
}

function processMetadata(accessorials: AccessorialMapType): AccessorialMapType {
	const _accessorialsProcessed = r.clone(accessorials)

	l.forEach(Object.keys(_accessorialsProcessed), (cKey) => {
		const c = _accessorialsProcessed[cKey]
		c.accessorialKey = cKey
		c.isChild = false
		if (c.childrenAccessorials) {
			l.forEach(Object.keys(c.childrenAccessorials), (dKey) => {
				const d = c.childrenAccessorials[dKey]
				d.accessorialKey = dKey
				d.isChild = true
			})
		}
	})

	return deepFreeze(_accessorialsProcessed)
}

export const allAccessorialsMap: AccessorialMapType = processMetadata(
	_allAccessorials,
)
export const allAccessorialsArray: IAccessorialMetadata[] = deepFreeze(
	mapToArray(allAccessorialsMap),
)
