import { sosToast } from 'common/components/toast'
import { TypeaheadOption } from 'common/components/typeahead'
import { DateTime } from 'luxon'
import { apiBroker, apiLocation, apiShipments } from 'ui/api'
import * as apiTypes from 'ui/api/apiTypes'
import { ShipmentListResponse } from 'ui/api/apiTypes'
import { createDefault, IRequestState } from 'ui/api/requestState'
import { sosRouter2 } from 'ui/components/common/router'
import {
	getLocalStorage,
	getWindow,
	localStorageExists,
	windowExists,
	windowOpen,
} from 'ui/components/common/router/windowUtils'
import { tEquipmentType } from 'ui/components/i18n/commonTranslations'
import { tString } from 'ui/components/i18n/i18n'
import { IDataTableHeader, IDataTableState } from 'ui/components/table'
import {
	clearFilter,
	setFilterDateValue,
	setFilterValue,
	setHiddenHeaders,
	sortOnServer,
	toggleActionsModal,
	toggleHeader,
} from 'ui/components/table/dataUtils'
import { elasticSearchSortBuilder } from 'ui/lib/elasticSearch'
import { getActiveElasticsearchFields } from 'ui/lib/elasticSearch/getActiveElasticsearchFields'
import { l } from 'ui/lib/lodashImports'
import { log } from 'ui/lib/log'
import { createLazySos2 } from 'ui/lib/state/sos2/sos2'
import { IDatePickerState } from 'ui/pages/shipments'
import {
	brokerHeaders,
	defaultBrokerHiddenHeaders,
	defaultBrokerShipmentListSorts,
	defaultTmsHiddenHeaders,
	defaultTmsShipmentListSorts,
	tmsHeaders,
} from 'ui/pages/shipments/ShipmentsListHeaders'
import { IShipmentListMappedData, sosUser } from 'ui/state'
import {
	copyStatePagerFromElasticsearch,
	createPagerSosMeta,
	getTakeAndSkip,
	IStatePager,
} from 'ui/state/paging'
import { delayedTask } from '.'
import { createDelayedTask } from './delayedTask'
import {
	createBrokerShipmentQuery,
	createShipmentQuery,
} from './sosShipmentsList_createShipmentQuery'
import { processShipments } from './sosShipmentsList_processShipments'

interface InvoiceFormattedShipment
	extends Omit<IShipmentListMappedData, 'equipmentType'> {
	equipmentType?: string
}

interface GenerateInvoiceData {
	selectedShipments: InvoiceFormattedShipment[]
	clientConfig: apiTypes.ClientConfigResponse
	customerTopLevelLocation: apiTypes.LocationResponse
	brokerUserLocation: apiTypes.LocationResponse
	invoiceNumber: string
	invoiceDate: string
}

export interface IStateShipmentsList {
	searchFor: string
	pager: IStatePager
	datePickerState: IDatePickerState
	requestShipments: IRequestState<ShipmentListResponse>
	shipmentListDataTableState: IDataTableState<IShipmentListMappedData>
	fetchShipmentsError: object | null
	brokerShipmentListDataTableState: IDataTableState<IShipmentListMappedData>
	processedShipments: IShipmentListMappedData[]
	clientConfigsForBrokerShipments: apiTypes.ClientConfigResponse[]
	selectedShipments: IShipmentListMappedData[]
	accountingMode: boolean
	filterLocation: TypeaheadOption
	isModalOpen: boolean
	exportEmail: string
	exportCompletedSteps: number
	exportTotalSteps: number
	exportIsRunning: boolean
	invoiceIsGenerating: boolean
	clients?: string[]
	originStates?: string[]
}

export const shipmentsListPageInfo = {
	url: '/shipments-v3/shipments-list',
	params: {},
	queries: {
		filter: {
			name: 'filter',
			default: 'all',
		},
		externalQuery: {
			name: 'externalQuery',
			default: '',
		},
		externalNestedQuery: {
			name: 'externalNestedQuery',
			default: '',
		},
	},
}

export const getUrlState = (): {
	shipmentStatusFilter: any
	externalQuery: any
	externalNestedQuery: any
} => {
	const shipmentStatusFilter = sosRouter2.getQueryParam(
		shipmentsListPageInfo,
		shipmentsListPageInfo.queries.filter,
	)
	const externalQuery = sosRouter2.getQueryParam(
		shipmentsListPageInfo,
		shipmentsListPageInfo.queries.externalQuery,
	)
	const externalNestedQuery = sosRouter2.getQueryParam(
		shipmentsListPageInfo,
		shipmentsListPageInfo.queries.externalNestedQuery,
	)
	return {
		shipmentStatusFilter,
		externalQuery,
		externalNestedQuery,
	}
}

export const getSos = createLazySos2<IStateShipmentsList>(
	'sosShipmentsList',
	1,
	() => ({
		// shipmentStatusFilter: { default: 'all', queryStringParam: 'filter' },
		searchFor: { default: '', queryStringParam: 'search' },
		pager: createPagerSosMeta(),
		requestShipments: { default: createDefault() },
		processedShipments: { default: [] },
		clientConfigsForBrokerShipments: { default: [] },
		fetchShipmentsError: { default: null },
		shipmentListDataTableState: {
			default: {
				sortOn: null,
				sortReversed: false,
				hiddenHeaders: defaultTmsHiddenHeaders,
			},
		},
		brokerShipmentListDataTableState: {
			default: {
				sortOn: null,
				sortReversed: false,
				hiddenHeaders: defaultBrokerHiddenHeaders,
			},
		},
		selectedShipments: { default: [] },
		accountingMode: { default: false },
		filterLocation: { default: { value: '', label: '' } },
		datePickerState: {
			default: {
				mode: 'all',
				date1String: '',
				date2String: '',
				showDate1Calendar: false,
				showDate2Calendar: false,
				inLastX: '',
			},
		},
		isModalOpen: { default: false },
		exportEmail: { default: '' },
		exportTotalSteps: { default: 1 },
		exportCompletedSteps: { default: 0 },
		exportIsRunning: { default: false },
		invoiceIsGenerating: { default: false },
	}),
)

export const delayedFetchShipments = createDelayedTask(
	'sosShipmentsList:fetchShipments',
	async () => {
		await fetchShipments()
		return true
	},
	{},
)

export function updateShipmentStatusFilter(filter: string): void {
	sosRouter2.setQueryParam(
		shipmentsListPageInfo,
		shipmentsListPageInfo.queries.filter,
		filter,
	)

	getSos().change((ds) => {
		// ds.shipmentStatusFilter = filter
		ds.selectedShipments = []
		ds.pager.fetchingPageNumber = 0
	})
	delayedTask.callNow(delayedFetchShipments)
}

export async function updateSearchFor(newVal: string): Promise<void> {
	getSos().change((ds) => {
		ds.searchFor = newVal
		ds.pager.fetchingPageNumber = 0
	})
	delayedTask.callLater(delayedFetchShipments)
}

export function refresh(): void {
	delayedTask.callLater(delayedFetchShipments)
}

export async function updateCurrentPage(newPage: number): Promise<void> {
	getSos().change((ds) => {
		ds.pager.fetchingPageNumber = newPage
	})
	delayedTask.callNow(delayedFetchShipments)
}

async function fetchShipments(): Promise<void> {
	const state = getSos().getState()

	const {
		shipmentStatusFilter,
		externalQuery,
		externalNestedQuery,
	} = getUrlState()

	const filterLocationIds = await sosUser.getLocationFilterForUser(
		state.filterLocation.value,
	)

	log('sosShipmentsList', 'filterLocationIds', filterLocationIds)

	let query: string
	let nestedFieldQuery: string
	let wildcards: string[]
	let sorts: string
	let searchFields: string[]
	if (sosUser.isUserBroker()) {
		const queryObj = createBrokerShipmentQuery(
			state,
			shipmentStatusFilter,
			externalQuery,
			externalNestedQuery,
		)
		query = queryObj.query
		nestedFieldQuery = queryObj.nestedQuery
		wildcards = queryObj.wildcards
		sorts = elasticSearchSortBuilder(
			state.brokerShipmentListDataTableState.sortOn,
			state.brokerShipmentListDataTableState.sortReversed,
			defaultBrokerShipmentListSorts,
			brokerHeaders,
		)
		searchFields = getActiveElasticsearchFields(
			state.brokerShipmentListDataTableState.hiddenHeaders,
			brokerHeaders,
		)
	} else {
		const queryObj = createShipmentQuery(
			state,
			shipmentStatusFilter,
			filterLocationIds,
			externalQuery,
			externalNestedQuery,
		)
		query = queryObj.query
		nestedFieldQuery = queryObj.nestedQuery
		wildcards = queryObj.wildcards
		sorts = elasticSearchSortBuilder(
			state.shipmentListDataTableState.sortOn,
			state.shipmentListDataTableState.sortReversed,
			defaultTmsShipmentListSorts,
			tmsHeaders,
		)
		searchFields = getActiveElasticsearchFields(
			state.shipmentListDataTableState.hiddenHeaders,
			tmsHeaders,
		)
	}

	const { take, skip } = getTakeAndSkip(state.pager, 20)

	const onProgress = (rs): void => {
		getSos().change((ds) => {
			ds.requestShipments = rs
			ds.fetchShipmentsError = null

			if (rs.error) {
				ds.fetchShipmentsError = rs.error
				ds.processedShipments = []
			}
		})
	}
	const requestBody: apiTypes.ApiListRequest = {
		query,
		nestedFieldQuery,
		wildcards,
		take: take,
		skip: skip,
		sort: sorts,
		fields: searchFields,
	}

	log('sosShipmentsList', 'fetchRequestBody', requestBody)

	if (sosUser.isUserBroker()) {
		const brokerFetchResult = await apiBroker.fetchBrokerShipments(
			onProgress,
			requestBody,
		)
		if (brokerFetchResult.data) {
			const clientConfigs = await sosUser.getClientConfigs(
				l.uniq(brokerFetchResult.data.entities.map((c) => c.contractId)),
			)
			getSos().change((ds) => {
				ds.processedShipments = processShipments(
					brokerFetchResult.data,
					clientConfigs,
				)
				copyStatePagerFromElasticsearch(ds.pager, brokerFetchResult.data)
			})
		} else {
			sosToast.sendApiErrorResponseToast(
				brokerFetchResult,
				tString('errorLoadingShipments', 'page.shipmentsList'),
			)
		}
	} else {
		const shipperFetchResult = await apiShipments.fetchShipments(
			onProgress,
			requestBody,
		)
		if (shipperFetchResult.data) {
			getSos().change((ds) => {
				ds.processedShipments = processShipments(shipperFetchResult.data, [])
				copyStatePagerFromElasticsearch(ds.pager, shipperFetchResult.data)
			})
		} else {
			sosToast.sendApiErrorResponseToast(
				shipperFetchResult,
				tString('errorLoadingShipments', 'page.shipmentsList'),
			)
		}
	}
}

export function updateDatePickerState(
	changes: Partial<IDatePickerState>,
): void {
	getSos().change((ds) => {
		// Yo likes that entering t is a shortcut to entering today's date
		if (changes.date1String === 't') {
			changes.date1String = DateTime.local().toLocaleString(DateTime.DATE_SHORT)
		}

		ds.datePickerState = l.assign(ds.datePickerState, changes)
	})
	delayedTask.callLater(delayedFetchShipments)
}

export const toggleModal = (): void => {
	getSos().change((ds) => {
		ds.isModalOpen = !ds.isModalOpen
	})
}

export function selectFilterLocation(selectedLocation: TypeaheadOption): void {
	getSos().change((ds) => {
		ds.filterLocation = selectedLocation
	})
	delayedTask.callNow(delayedFetchShipments)
}

export const toggleSort = (
	header: IDataTableHeader<IShipmentListMappedData>,
): void => {
	if (!header.sort) {
		return
	}

	getSos().change((ds) => {
		const tableState = sosUser.isUserBroker()
			? ds.brokerShipmentListDataTableState
			: ds.shipmentListDataTableState
		sortOnServer(tableState, [], header.field)
	})
	delayedTask.callLater(delayedFetchShipments)
}

export const setEmail = (newVal: string): void => {
	getSos().change((ds) => {
		ds.exportEmail = newVal
	})
}

export function shipmentTableToggleHeader(field: string): void {
	getSos().change((ds) => {
		const tableState = sosUser.isUserBroker()
			? ds.brokerShipmentListDataTableState
			: ds.shipmentListDataTableState
		toggleHeader(tableState, field)
	})
}

export function resetTableHeaders(): void {
	getSos().change((ds) => {
		const tableState = sosUser.isUserBroker()
			? ds.brokerShipmentListDataTableState
			: ds.shipmentListDataTableState
		setHiddenHeaders(
			tableState,
			sosUser.isUserBroker()
				? defaultBrokerHiddenHeaders
				: defaultTmsHiddenHeaders,
		)
	})
}

export function toggleActionBox(field: string): void {
	getSos().change((ds) => {
		const tableState = sosUser.isUserBroker()
			? ds.brokerShipmentListDataTableState
			: ds.shipmentListDataTableState
		toggleActionsModal(tableState, field)
	})
}

export function clearColumnFilter(field: string): void {
	getSos().change((ds) => {
		const tableState = sosUser.isUserBroker()
			? ds.brokerShipmentListDataTableState
			: ds.shipmentListDataTableState
		clearFilter(tableState, field)
	})
	delayedTask.callNow(delayedFetchShipments)
}

export function setColumnFilterValue(field: string, value: string): void {
	getSos().change((ds) => {
		const tableState = sosUser.isUserBroker()
			? ds.brokerShipmentListDataTableState
			: ds.shipmentListDataTableState
		setFilterValue(tableState, field, value)
	})
	delayedTask.callNow(delayedFetchShipments)
}

export function updateFilterDatePickerState(
	field: string,
	changes: Partial<IDatePickerState>,
): void {
	getSos().change((ds) => {
		const tableState = sosUser.isUserBroker()
			? ds.brokerShipmentListDataTableState
			: ds.shipmentListDataTableState
		setFilterDateValue(tableState, field, changes)
	})
	delayedTask.callLater(delayedFetchShipments)
}

export function selectShipment(shipmentRow: IShipmentListMappedData): void {
	getSos().change((ds) => {
		if (
			l.includes(
				l.map(ds.selectedShipments, (c) => c.id),
				shipmentRow.id,
			)
		) {
			l.remove(ds.selectedShipments, (c) => c.id === shipmentRow.id)
		} else {
			ds.selectedShipments.push(shipmentRow)
		}
	})
}

export function resetAccountingShipments(): void {
	getSos().change((ds) => {
		ds.selectedShipments = []
	})
}

export function toggleAccountingMode(): void {
	getSos().change((ds) => {
		if (ds.accountingMode) {
			ds.selectedShipments = []
		}
		ds.accountingMode = !ds.accountingMode
		const tableState = sosUser.isUserBroker()
			? ds.brokerShipmentListDataTableState
			: ds.shipmentListDataTableState
		toggleHeader(tableState, 'id')
	})
}

export async function generateBrokerInvoice(
	invoiceDate: string = DateTime.local().toFormat('yyyy-LL-dd'),
): Promise<void> {
	const { selectedShipments } = getSos().getState()
	const clientConfig = sosUser.getCurrentClientConfig()
	const onProgress = (rs): void => {
		getSos().change((ds) => {
			if (rs.isFetching) {
				ds.invoiceIsGenerating = rs.isFetching
			}
		})
	}
	const clientConfigLocationResponse: IRequestState<
		apiTypes.LocationResponse[]
	> = await apiLocation.fetchLocations(onProgress, {
		clientConfigId: clientConfig.id,
		query: 'NOT _exists_:parentId',
	})

	if (clientConfigLocationResponse.error) {
		sosToast.sendApiErrorResponseToast(
			clientConfigLocationResponse,
			tString('clientConfigLocationResponseError', 'page.shipmentsList'),
		)
		return
	}

	const userLocationResponse: IRequestState<apiTypes.LocationResponse> = await apiLocation.fetchLocation(
		onProgress,
		sosUser.getSos().getState().locationId,
	)

	if (userLocationResponse.error) {
		sosToast.sendApiErrorResponseToast(
			userLocationResponse,
			tString('userLocationResponseError', 'page.shipmentsList'),
		)
		return
	}

	const formattedSelectedShipments: InvoiceFormattedShipment[] = selectedShipments.map(
		(selectedShipment) => {
			return {
				...selectedShipment,
				equipmentType: tEquipmentType(selectedShipment.equipmentType),
			}
		},
	)

	const invoiceData: GenerateInvoiceData = {
		selectedShipments: formattedSelectedShipments,
		clientConfig,
		customerTopLevelLocation: clientConfigLocationResponse.data?.[0],
		brokerUserLocation: userLocationResponse.data,
		invoiceNumber: null,
		invoiceDate,
	}
	if (alertMissingData(invoiceData) === true) {
		getSos().change((ds) => {
			ds.invoiceIsGenerating = false
		})
		return
	}
	const markAsInvoicedResult = await apiBroker.markAsInvoiced(
		l.noop,
		selectedShipments.map((c) => c.id),
		invoiceDate,
	)
	if (markAsInvoicedResult.error) {
		sosToast.sendApiErrorResponseToast(
			markAsInvoicedResult,
			tString('markAsInvoicedError', 'page.shipmentsList'),
		)
		getSos().change((ds) => {
			ds.invoiceIsGenerating = false
		})
		return
	} else {
		invoiceData.invoiceNumber = markAsInvoicedResult.data.invoiceNumber
		sosToast.sendToast({
			header: tString('successfully generated invoice', 'page.shipmentsList'),
			type: 'success',
		})
	}
	if (localStorageExists) {
		getLocalStorage().setItem('brokerInvoiceData', JSON.stringify(invoiceData))
	}
	if (windowExists) {
		// If isDev, use localhost:4250 instead
		windowOpen(
			getWindow().location.origin + '/pdfs/index.html?customer-invoice',
		)
	}

	resetAccountingShipments()

	getSos().change((ds) => {
		ds.invoiceIsGenerating = false
	})
}

function alertMissingData(invoiceData: GenerateInvoiceData): boolean {
	const requiredBrokerData = {
		name: invoiceData.brokerUserLocation?.name,
		street1:
			invoiceData?.brokerUserLocation?.defaults?.defaultBillingAddress?.address
				?.street1,
		city:
			invoiceData?.brokerUserLocation?.defaults?.defaultBillingAddress?.address
				?.city,
		state:
			invoiceData?.brokerUserLocation?.defaults?.defaultBillingAddress?.address
				?.state,
		zip:
			invoiceData?.brokerUserLocation?.defaults?.defaultBillingAddress?.address
				?.zip,
	}
	Object.entries(requiredBrokerData).forEach(([key, value]) => {
		if (!value) {
			sosToast.sendToast({
				header: tString('brokerDataError', 'page.shipmentsList'),
				body: tString('brokerDataErrorBody' + key, 'page.shipmentsList'),
				type: 'danger',
			})
			return true
		}
	})

	const requiredCustomerData = {
		name: invoiceData.clientConfig.tmsCompanyName,
		street1:
			invoiceData?.customerTopLevelLocation?.defaults?.defaultBillingAddress
				?.address?.street1,
		city:
			invoiceData?.customerTopLevelLocation?.defaults?.defaultBillingAddress
				?.address?.city,
		state:
			invoiceData?.customerTopLevelLocation?.defaults?.defaultBillingAddress
				?.address?.state,
		zip:
			invoiceData?.customerTopLevelLocation?.defaults?.defaultBillingAddress
				?.address?.zip,
		netPaymentDays: invoiceData?.clientConfig?.paymentTerms?.net,
	}
	Object.entries(requiredCustomerData).forEach(([key, value]) => {
		if (!value) {
			sosToast.sendToast({
				header: tString('customerDataError', 'page.shipmentsList'),
				body: tString('customerDataErrorBody' + key, 'page.shipmentsList'),
				type: 'danger',
			})
			return true
		}
	})

	if (!invoiceData?.invoiceDate) {
		sosToast.sendToast({
			header: tString('invoiceDateError', 'page.shipmentsList'),
			body: tString('tryAgain', 'page.shipmentsList'),
			type: 'danger',
		})
		return true
	}
	return false
}

export function setLocationIds(filters: string[]): void {
	getSos().change((ds) => {
		ds.clients = filters
	})
}
export function setSelectedLocation(option: TypeaheadOption): void {
	getSos().change((ds) => {
		ds.filterLocation = option
	})
}

export function setPickupStates(filters: string[]): void {
	getSos().change((ds) => {
		ds.originStates = filters
	})

	refresh()
}
