import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { Layout } from 'ui/pages/layout/Layout'

export const BooksPage: FC = () => {
	return (
		<Layout>
			<div>Manage Books</div>
		</Layout>
	)
}
