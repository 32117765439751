import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { t } from 'ui/components/i18n/i18n'
import { Subway } from 'ui/components/shared/subway'
import { StationFail } from 'ui/components/shared/subway/StationFail'
import { StationSuccess } from 'ui/components/shared/subway/StationSuccess'
import { StationWaiting } from 'ui/components/shared/subway/StationWaiting'
import { SubwayArrow } from 'ui/components/shared/subway/SubwayArrow'
import { SubwayStation } from 'ui/components/shared/subway/SubwayStation'
import { IStateComponentExamples } from 'ui/state/sosComponentExamples'
import { Section } from '.'

export const SubwayExample: FC = (props: {
	label: React.ReactNode
	children: React.ReactNode
}) => {
	return (
		<fieldset style={{ textAlign: 'center' }}>
			<legend style={{ fontSize: '16px' }}>{props.label}</legend>
			{props.children}
		</fieldset>
	)
}

export const SectionSubway: FC = (props: {
	stateComponentExamples: IStateComponentExamples
}) => {
	return (
		<Section
			title={t('page.componentExamples.subway')}
			stateComponentExamples={props.stateComponentExamples}
			name='subway'
		>
			<SubwayExample label='Example'>
				<Subway>
					<SubwayStation top='hello' bottom='world'>
						<StationSuccess />
					</SubwayStation>
					<SubwayArrow />
					<SubwayStation top='top only' bottom=''>
						<StationWaiting />
					</SubwayStation>
					<SubwayArrow />
					<SubwayStation bottom='bottom only' top=''>
						<StationFail />
					</SubwayStation>
				</Subway>
			</SubwayExample>

			<SubwayExample label='Translated'>
				<Subway>
					<SubwayStation top='hello' bottom='world'>
						<StationSuccess />
					</SubwayStation>
					<SubwayArrow />
					<SubwayStation
						top={t('page.componentExamples.subwayStation')}
						bottom=''
					>
						<StationWaiting />
					</SubwayStation>
					<SubwayArrow />
					<SubwayStation bottom='bottom only' top=''>
						<StationFail />
					</SubwayStation>
				</Subway>
			</SubwayExample>
		</Section>
	)
}
