import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { Spacer } from 'ui/components/layout/spacer/Spacer'
import * as classes from './AddProviderModal.module.scss'

export const AddProviderModalContent: FC = (props: {
	body: React.ReactNode
	footer: React.ReactNode
}) => {
	return (
		<div className={classes.addProviderModal}>
			<div className={classes.addProviderModalBody}>{props.body}</div>
			<Spacer />
			<div className={classes.addProviderModalFooter}>{props.footer}</div>
		</div>
	)
}
