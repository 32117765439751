import { IFormMetadataCollection } from 'ui/components/form/IFormMetadata'
import { IBookForm } from 'ui/forms/formBook'

export type IPackageCatalogForm = IBookForm

export const packageCatalogFormMetadata: IFormMetadataCollection<IPackageCatalogForm> = {
	id: {
		readOnly: true,
	},
	name: {
		required: true,
	},
}
