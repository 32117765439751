import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { apiTypes } from 'ui/api'
import { Spacer } from 'ui/components/layout/spacer'
import { l } from 'ui/lib/lodashImports'
import { CommoditiesReadOnly } from 'ui/pages/new-quote'
import { ShipperShipmentDetailsCard } from '../shipper-shipment-cards/ShipperShipmentDetailsCard'
import { StopCard } from 'ui/pages/shipment-profile/shared/components/shipmentCards'
import * as classes from './ShipperShipmentDetailsSection.module.scss'

interface StopInfo {
	stop: apiTypes.OriginStopResponse | apiTypes.DestinationStopResponse
	stopType: 'pickup' | 'delivery'
}

const getStopInfoFromShipment = (
	shipment: apiTypes.ShipmentResponse,
): StopInfo[] => {
	return shipment.stopSequence.map((stopHash) => {
		const matchingOriginStops: apiTypes.OriginStopResponse[] = []
		const matchingDestinationStops: apiTypes.DestinationStopResponse[] = []

		shipment.payloads.forEach((payload) => {
			if (payload.originStop.metaData.addressHash === stopHash) {
				matchingOriginStops.push(payload.originStop)
			} else if (payload.destinationStop.metaData.addressHash === stopHash) {
				matchingDestinationStops.push(payload.destinationStop)
			}
		})
		if (matchingOriginStops.length > 0 && matchingDestinationStops.length > 0) {
			// TODO handle case where we drop off and pick up at same stop
			return { stop: matchingOriginStops[0], stopType: 'pickup' }
		} else if (matchingOriginStops.length > 0) {
			// TODO handle case where multiple payloads are picked up at same place
			return { stop: matchingOriginStops[0], stopType: 'pickup' }
		} else if (matchingDestinationStops.length > 0) {
			// TODO handle case where multiple payloads are dropped off at same place
			return { stop: matchingDestinationStops[0], stopType: 'delivery' }
		} else {
			return null
		}
	})
}

/*
	Commented out as reference incase needed.
*/

// const getCommoditiesFromShipment = (
// 	shipment: apiTypes.ShipmentResponse,
// ): INewQuoteCommoditiesForm[] => {
// 	const commodities: INewQuoteCommoditiesForm[] = []
// 	shipment.payloads.forEach((payload) => {
// 		payload.containers.forEach((container) => {
// 			const inner: apiTypes.ContainerResponse = container.containers
// 				? container.containers[0]
// 				: null
// 			const good: apiTypes.GoodResponse = inner?.goods ? inner.goods[0] : null
// 			const commodity: INewQuoteCommoditiesForm = {
// 				id: null,
// 				count: String(container.count),
// 				class: container.class,
// 				length: String(container.length),
// 				width: String(container.width),
// 				height: String(container.height),
// 				packedWeight: String(container.packedWeight),
// 				description: container.description,
// 				type: container.type,
// 				piecesCount: String(inner?.count || '1'),
// 				piecesType: inner?.type,
// 				piecesLength: String(inner?.length || ''),
// 				piecesWidth: String(inner?.width || ''),
// 				piecesHeight: String(inner?.height || ''),
// 				hazmat: null,
// 				nmfc: good?.nmfcCode,
// 				nmfcSub: null,
// 				hazmatClass: null,
// 				unNumber: null,
// 				packagingGroup: null,
// 				bol: null,
// 				so: container.salesOrders?.join(', '),
// 				po: container.purchaseOrders?.join(', '),
// 				notes: null,
// 			}
// 			if (shipment.stopSequence.length > 2) {
// 				commodity.pickupStopNumber =
// 					l.findIndex(
// 						shipment.stopSequence,
// 						(stopHash) => stopHash === payload.originStop.metaData.addressHash,
// 					) + 1
// 				commodity.deliveryStopNumber =
// 					l.findIndex(
// 						shipment.stopSequence,
// 						(stopHash) =>
// 							stopHash === payload.destinationStop.metaData.addressHash,
// 					) + 1
// 			}
// 			commodities.push(commodity)
// 		})
// 	})
// 	return commodities
// }

export const ShipperShipmentDetailsSection = (props: {
	shipment: apiTypes.ShipmentResponse
}): React.ReactElement => {
	const { shipment } = props
	const stopsInOrder: StopInfo[] = l.compact(getStopInfoFromShipment(shipment))

	return (
		<div>
			<Card className={classes.cardBody}>
				<Row>
					<Col md={4}>
						<ShipperShipmentDetailsCard shipment={shipment} />
					</Col>
					{stopsInOrder.map((stopInfo, idx) => (
						<Col md={4} key={stopInfo.stop.metaData.addressHash}>
							<StopCard
								stopNumber={idx + 1}
								stopType={stopInfo.stopType}
								stop={stopInfo.stop}
								shipment={shipment}
							/>
						</Col>
					))}
				</Row>
				<Spacer />
				<CommoditiesReadOnly fullWidth={true} shipment={shipment} />
			</Card>
		</div>
	)
}
