import React, { forwardRef, ReactElement } from 'react'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Dropdown from 'react-bootstrap/Dropdown'
import { FC } from 'app/FunctionalComponent'

export const ActionDropdown: FC = (props: { children?: ReactElement }) => {
	//@ts-ignore
	const CustomDropdownToggle = forwardRef(({ children, onClick }, ref) => (
		<a
			href='/'
			//@ts-ignore
			ref={ref}
			onClick={(e) => {
				e.preventDefault()
				onClick(e)
			}}
		>
			<FontAwesomeIcon icon={faEllipsisV} />
		</a>
	))
	return (
		<Dropdown>
			<Dropdown.Toggle as={CustomDropdownToggle} id='ellipsis-toggle' />
			<Dropdown.Menu>{props.children}</Dropdown.Menu>
		</Dropdown>
	)
}
