import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { FormStacked, FormStackedReadOnly, IFormData } from 'ui/components/form'
import { IStateDockSchedulerAppointmentDetails } from '../state/sosDockSchedulerAppointmentDetails'
import { DockSchedulerAppointmentDetailsCard } from './DockSchedulerAppointmentDetailsCard'
import {
	generalInformationMetaData,
	IAppointmentDetailsGeneralInformation,
} from './DockSchedulerAppointmentDetailsForms'

const tPrefix = 'page.dockScheduler.appointmentDetails.generalInformation'

export const DockSchedulerAppointmentDetailsGeneralInformation: FC = (props: {
	state: IStateDockSchedulerAppointmentDetails
}) => {
	const { state } = props

	const detailsFormData: IFormData<IAppointmentDetailsGeneralInformation> = {
		form: state.generalInfoForm,
		metadata: generalInformationMetaData,
		tPrefix,
		onUpdateForm: () => {},
	}

	return (
		<DockSchedulerAppointmentDetailsCard
			cardType={'generalInformation'}
			isActiveCard={false}
		>
			<FormStacked>
				<FormStackedReadOnly formData={detailsFormData} field='slid' />
				<FormStackedReadOnly formData={detailsFormData} field='dateTime' />
				<FormStackedReadOnly formData={detailsFormData} field='dock' />
				<FormStackedReadOnly formData={detailsFormData} field='carrier' />
				<FormStackedReadOnly formData={detailsFormData} field='mode' />
				<FormStackedReadOnly formData={detailsFormData} field='stopType' />
				<FormStackedReadOnly formData={detailsFormData} field='equipmentType' />
				<FormStackedReadOnly formData={detailsFormData} field='proNumber' />
				<FormStackedReadOnly formData={detailsFormData} field='bolNumbers' />
				<FormStackedReadOnly formData={detailsFormData} field='poNumbers' />
				<FormStackedReadOnly formData={detailsFormData} field='soNumbers' />
				<FormStackedReadOnly formData={detailsFormData} field='handlingUnits' />
				<FormStackedReadOnly
					formData={detailsFormData}
					field='handlingUnitTypes'
				/>
				<FormStackedReadOnly
					formData={detailsFormData}
					field='expectedWeight'
				/>
			</FormStacked>
		</DockSchedulerAppointmentDetailsCard>
	)
}
