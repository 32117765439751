import React, { ReactElement } from 'react'
import {
	IRoute,
	SimpleRouter,
	LoginModeType,
} from 'ui/components/common/router'
import { ClientManagementPage } from 'ui/pages/customer-management/broker/default-markup'
export const customerManagementRoutes: IRoute[] = [
	{
		urls: ['/customer-management-v3/default-markup'],
		renderer: (): ReactElement => <ClientManagementPage />,
	},
]

export const RouterCustomerManagement = (props: {
	currentLocation: string
	loginMode: LoginModeType
}): ReactElement => {
	return (
		<SimpleRouter
			routes={customerManagementRoutes}
			currentLocation={props.currentLocation}
			loginMode={props.loginMode}
		/>
	)
}
