import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { Card } from 'ui/components/common/card/Card'
import { Separator } from 'ui/components/common/separator/Separator'
import { t } from 'ui/components/i18n/i18n'
import { CustomColumn } from 'ui/components/layout/column/CustomColumn'
import {
	ThreeColumn,
	ThreeColumnItem,
} from 'ui/components/layout/column/ThreeColumn'
import { TwoColumn, TwoColumnItem } from 'ui/components/layout/column/TwoColumn'
import { Sidebar } from 'ui/components/layout/sidebar'
import { Spacer } from 'ui/components/layout/spacer'
import {
	Left,
	Right,
	TwoColumnLeftRight,
} from 'ui/components/layout/twoColumnLeftRight/TwoColumnLeftRight'
import { IStateComponentExamples } from 'ui/state/sosComponentExamples'
import { Section } from './Section'

export const SectionLayouts: FC = (props: {
	stateComponentExamples: IStateComponentExamples
}) => {
	return (
		<Section
			title={t('page.componentExamples.layout')}
			stateComponentExamples={props.stateComponentExamples}
			name='layout'
		>
			Sidebar
			<Sidebar links={[]} loginMode={'loggedIn'} />
			<Spacer />
			Two Column Left/Right
			<TwoColumnLeftRight>
				<Left>left side</Left>
				<Right>right side</Right>
			</TwoColumnLeftRight>
			Custom column no min/max
			<CustomColumn
				debug={true}
				entries={[
					{
						targetScale: 0.5,
						renderer: () => <div>50%</div>,
					},
					{
						targetScale: 0.5,
						renderer: () => <div>50%</div>,
					},
					{
						targetScale: 0.5,
						renderer: () => <div>50%</div>,
					},
					{
						targetScale: 0.75,
						renderer: () => <div>75%</div>,
					},
					{
						targetScale: 0.25,
						renderer: () => <div>25%</div>,
					},
					{
						targetScale: 1 / 3,
						renderer: () => <div>33.33%</div>,
					},
					{
						targetScale: 1 / 3,
						renderer: () => <div>33.33%</div>,
					},
					{
						targetScale: 1 / 3,
						renderer: () => (
							<div>
								33.33% <br /> and <br /> more <br /> height
							</div>
						),
					},
				]}
			/>
			<Separator />
			with min widths
			<CustomColumn
				debug={true}
				entries={[
					{
						targetScale: 1 / 3,
						minWidth: 300,
						renderer: () => <div>33.33%</div>,
					},
					{
						targetScale: 1 / 3,
						minWidth: 450,
						renderer: () => <div>33.33%</div>,
					},
					{
						targetScale: 1 / 3,
						minWidth: 300,
						renderer: () => (
							<div>
								33.33% <br /> and <br /> more <br /> height
							</div>
						),
					},
				]}
			/>
			<Separator />
			with max widths
			<CustomColumn
				debug={true}
				entries={[
					{
						targetScale: 1 / 3,
						maxWidth: 200,
						renderer: () => <div>33.33%</div>,
					},
					{
						targetScale: 1 / 3,
						maxWidth: 300,
						renderer: () => <div>33.33%</div>,
					},
					{
						targetScale: 1 / 3,
						maxWidth: 200,
						renderer: () => (
							<div>
								33.33% <br /> and <br /> more <br /> height
							</div>
						),
					},
				]}
			/>
			<Separator />
			three column
			<ThreeColumn>
				<ThreeColumnItem>
					<Card color='blue' title='a'>
						a
					</Card>
				</ThreeColumnItem>
				<ThreeColumnItem>
					<Card color='red' title='a'>
						b
					</Card>
				</ThreeColumnItem>
				<ThreeColumnItem>
					<Card color='green' title='a'>
						c
					</Card>
				</ThreeColumnItem>
			</ThreeColumn>
			<Separator />
			three column x2
			<ThreeColumn>
				<ThreeColumnItem>
					<Card color='blue' title='a'>
						a
					</Card>
				</ThreeColumnItem>
				<ThreeColumnItem>
					<Card color='red' title='a'>
						b
					</Card>
				</ThreeColumnItem>
				<ThreeColumnItem>
					<Card color='green' title='a'>
						c
					</Card>
				</ThreeColumnItem>
				<ThreeColumnItem>
					<Card color='blue' title='a'>
						a
					</Card>
				</ThreeColumnItem>
				<ThreeColumnItem>
					<Card color='red' title='a'>
						b
					</Card>
				</ThreeColumnItem>
				{/* <ThreeColumnItem>
          <Card color='green' title='a'>
            c
          </Card>
        </ThreeColumnItem> */}
			</ThreeColumn>
			<Separator />
			two column
			<TwoColumn>
				<TwoColumnItem>
					<Card color='blue' title='a'>
						a
					</Card>
				</TwoColumnItem>
				<TwoColumnItem>
					<Card color='red' title='a'>
						b
					</Card>
				</TwoColumnItem>
			</TwoColumn>
			<Separator />
			two column x2
			<TwoColumn>
				<TwoColumnItem>
					<Card color='blue' title='a'>
						a
					</Card>
				</TwoColumnItem>
				<TwoColumnItem>
					<Card color='red' title='a'>
						b
					</Card>
				</TwoColumnItem>
				<TwoColumnItem>
					<Card color='green' title='a'>
						c
					</Card>
				</TwoColumnItem>
				<TwoColumnItem>
					<Card color='gray' title='a'>
						d
					</Card>
				</TwoColumnItem>
			</TwoColumn>
		</Section>
	)
}
