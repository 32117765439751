import { FC } from 'app/FunctionalComponent'
import React, { useState } from 'react'
import { apiTypes } from 'ui/api'
import { AngryRedBox } from 'ui/components/common/angry-red-box'
import { Button } from 'ui/components/common/button'
import { Debug } from 'ui/components/dev'
import { useOnce } from 'ui/components/hooks'
import { t } from 'ui/components/i18n/i18n'
import { FlexRow } from 'ui/components/layout/flexRow'
import { Spacer } from 'ui/components/layout/spacer'
import { RequiresFeatureToggle } from 'ui/components/permissions/RequiresFeatureToggle'
import { fireAndForget } from 'ui/lib/async'
import { l } from 'ui/lib/lodashImports'
import { sos2 } from 'ui/lib/state/sos2'
import { ModalAddressBook, ModalProductCatalog } from 'ui/modals'
import { Layout } from 'ui/pages/layout/Layout'
import { NewQuoteModeType } from 'ui/pages/new-quote/state/sosNewQuote'
import { isInDev } from 'ui/theme/theme'
import { NewQuoteFreight, NewQuoteVendor } from '.'
import { NewQuoteParcel } from './parcel/components/NewQuoteParcel'
import { sosNewQuote } from './state'

const tPrefix = 'page.newQuote'

export const NewQuotePage: FC = (props: {
	shipmentId?: string
	type?: NewQuoteModeType
}) => {
	const state = sos2.useSubscription(sosNewQuote.sos)
	const [parcelShipment, setParcelShipment] = useState<
		apiTypes.ShipmentResponse
	>(null)
	let filters: NewQuoteModeType[]
	if (props.type) {
		filters = [props.type]
		sosNewQuote.selectNewQuoteMode(props.type)
	} else {
		filters = ['freight', 'freight-light', 'parcel']
		if (isInDev()) {
			filters.push('vendor')
		}
	}

	useOnce(() => {
		if (props.shipmentId) {
			fireAndForget(async () => {
				const response = await sosNewQuote.fetchShipment(props.shipmentId)
				if (response) {
					setParcelShipment(response)
				}
			}, `fetch new quote shipment ${props.shipmentId}`)
		}
	})

	return (
		<Layout>
			{/* Turning off filters until we have more new shipment pages built */}
			{/* <Filters>
				{l.map(filters, (c) => (
					<FilterItem
						key={c}
						isSelected={state.newQuoteMode === c}
						onClick={() => {
							// TODO: clear out address?
							sosNewQuote.selectNewQuoteMode(c)
						}}
					>
						{t(c, tPrefix)}
					</FilterItem>
				))}
			</Filters> */}
			{state.newQuoteMode !== 'parcel' && (
				<FlexRow>
					<Button
						color={'gray'}
						onClick={sosNewQuote.setCompanyDefaults}
						inline={true}
					>
						{t('getQuotes.setDefaults', tPrefix)}
					</Button>
					<RequiresFeatureToggle featureToggle='demo-shipment-button'>
						<Button
							color={'gray'}
							onClick={sosNewQuote.setFreightDemoShipment}
							inline={true}
						>
							{t('getQuotes.demoShipment', tPrefix)}
						</Button>
					</RequiresFeatureToggle>
				</FlexRow>
			)}
			<Spacer />
			{state.dataFetchErrors.length > 0 && (
				<AngryRedBox>
					{l.map(state.dataFetchErrors, (c, cIdx) => (
						<div key={cIdx}>{c}</div>
					))}
				</AngryRedBox>
			)}
			{state.newQuoteMode === 'freight' && (
				<NewQuoteFreight state={state} isEditing={!l.isNil(props.shipmentId)} />
			)}
			{state.newQuoteMode === 'freight-light' && <>{`Not Implemented`}</>}
			{state.newQuoteMode === 'vendor' && <NewQuoteVendor state={state} />}
			{props.type === 'parcel' && <NewQuoteParcel state={parcelShipment} />}
			<Debug label='sos' data={state} />
			<div>
				<ModalAddressBook onClickRow={sosNewQuote.updateAddress} />
				<ModalProductCatalog onClickRow={sosNewQuote.updateCommodity} />
			</div>
		</Layout>
	)
}
