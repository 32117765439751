import { FC } from 'app/FunctionalComponent'
import { sosToast } from 'common/components/toast'
import React, { useState } from 'react'
import Card from 'react-bootstrap/Card'
import { apiProviders, apiTypes } from 'ui/api'
import { Button } from 'ui/components/common/button/Button'
import { t, tString } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight'
import { SimpleAddress } from 'ui/components/shared/address'
import {
	IKeyValueOptions,
	KeyValue,
	KeyValueSpacer,
	SimpleKeyValueTable,
} from 'ui/components/table'
import { ProviderModalForm } from './AddProviderModal'
import * as classes from './AddProviderModal.module.scss'
import { AddProviderModalContent } from './AddProviderModalContent'

const tPrefix = 'page.providers.addProviderModal.confirm'

interface ProviderModalFormReadOnly {
	providerName: string
	doingBusinessAs?: string
	address: apiTypes.Address
}

export const AddProviderModalConfirm: FC = (props: {
	onClose: () => void
	providerCreateForm: ProviderModalForm
}) => {
	const { onClose, providerCreateForm } = props
	const [addingNewProviderOnAPI, setAddingNewProviderOnAPI] = useState(false)
	const keyValueTableOptions: IKeyValueOptions = { tPrefix }

	const providerModalFormReadOnly: ProviderModalFormReadOnly = {
		providerName: providerCreateForm.providerName,
		doingBusinessAs: providerCreateForm.doingBusinessAs,
		address: {
			street1: providerCreateForm.street1,
			street2: providerCreateForm.street2,
			city: providerCreateForm.city,
			state: providerCreateForm.state,
			zip: providerCreateForm.zipCode,
			country: providerCreateForm.country,
		},
	}

	const body = (
		<div data-testid={'add-provider-modal-confirm'}>
			<div className={classes.cardTitle}>{t('createProvider', tPrefix)}</div>
			<Card className={'align-items-center'}>
				<div className={classes.cardBody}>
					<SimpleKeyValueTable borderless={true}>
						<KeyValue
							options={keyValueTableOptions}
							data={providerModalFormReadOnly}
							field='providerName'
							testId={'provider-create-providerName'}
						/>

						{providerModalFormReadOnly.doingBusinessAs && (
							<KeyValue
								options={keyValueTableOptions}
								data={providerModalFormReadOnly}
								field='doingBusinessAs'
								testId={'provider-create-dba'}
							/>
						)}

						<KeyValueSpacer />
						<KeyValue
							options={keyValueTableOptions}
							data={providerModalFormReadOnly}
							field='address'
							renderer={(address: apiTypes.Address) => (
								<SimpleAddress address={address} />
							)}
							testId={'provider-create-address'}
						/>
					</SimpleKeyValueTable>
				</div>
			</Card>
		</div>
	)

	const footer = (
		<div>
			<AlignRight>
				<Button
					color='blue'
					onClick={async () => {
						setAddingNewProviderOnAPI(true)

						const addNewProviderRequest: apiTypes.ProviderRequest = {
							providerName: providerCreateForm.providerName as apiTypes.ProviderRequest['providerName'],
							doingBusinessAs: providerCreateForm.doingBusinessAs
								?.split(',')
								.map((dba) => dba.trim()),
							mainAddress: {
								street1: providerCreateForm.street1,
								street2: providerCreateForm.street2,
								city: providerCreateForm.city,
								state: providerCreateForm.state,
								zip: providerCreateForm.zipCode,
								country: providerCreateForm.country,
							},
						}

						const result = await apiProviders.createNewProvider(() => {},
						addNewProviderRequest)

						if (result.data) {
							onClose()
						} else if (result.error) {
							sosToast.sendApiErrorResponseToast(
								result,
								tString('errorAddingProvider', tPrefix),
							)
						}

						setAddingNewProviderOnAPI(false)
					}}
					isSpinning={addingNewProviderOnAPI}
					testId={'confirm-create-provider'}
				>
					{t('confirm', tPrefix)}
				</Button>
			</AlignRight>
		</div>
	)

	return <AddProviderModalContent body={body} footer={footer} />
}
