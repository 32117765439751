import { IFormMetadataCollection } from 'ui/components/form/IFormMetadata'
import { IPackageCatalogEntry } from 'ui/lib/books'
import { FormStringData } from './formUtils'

export interface IPackageCatalogEntryForm
	extends FormStringData<IPackageCatalogEntry> {}

export const packageCatalogEntryFormMetadata: IFormMetadataCollection<IPackageCatalogEntryForm> = {
	id: {
		readOnly: true,
	},
	name: {
		required: true,
	},
	length: {
		mustBeANumber: true,
	},
	width: {
		mustBeANumber: true,
	},
	height: {
		mustBeANumber: true,
	},
	barCode: {},
}
