import { apiCommon, apiTypes } from 'ui/api'
import { l } from 'ui/lib/lodashImports'
import { IRequestState } from 'ui/api/requestState'

export const fetchFeatureToggles = async (
	featureToggles: string | string[],
): Promise<IRequestState<apiTypes.FeatureToggleLookupResponse>> => {
	if (l.isArray(featureToggles)) {
		featureToggles = featureToggles.join(',')
	}

	return apiCommon.apiFetch<apiTypes.FeatureToggleLookupResponse>(
		() => {},
		{ method: 'GET' },
		'feature-toggles/' + featureToggles,
	)
}

export const searchFeatureToggles = (
	onProgress: (rs: IRequestState<apiTypes.FeatureToggleResponse[]>) => void,
	queryParams?: string,
): Promise<IRequestState<apiTypes.FeatureToggleResponse[]>> => {
	let url = `feature-toggles/manage-feature-toggles`

	if (queryParams !== null)
		url += `?query=*${queryParams}*&fields=feature,sourceName,description,sourceId`

	return apiCommon.apiFetch(onProgress, {}, url)
}

export async function createFeatureToggle(
	onProgress: (rs: IRequestState<apiTypes.CreateFeatureToggleRequest>) => void,
	featureToggle: apiTypes.UpdateFeatureToggleRequest,
): Promise<IRequestState<apiTypes.FeatureToggleResponse>> {
	const url = 'feature-toggles/manage-feature-toggles'

	const result = await apiCommon.apiFetch<apiTypes.FeatureToggleResponse>(
		onProgress,
		{
			method: 'POST',
			data: featureToggle,
		},
		url,
	)

	return result
}

export async function updateFeatureToggle(
	onProgress: (rs: IRequestState<apiTypes.FeatureToggleResponse>) => void,
	featureToggle: apiTypes.UpdateFeatureToggleRequest,
	featureName: string,
	sourceId: string,
): Promise<IRequestState<apiTypes.FeatureToggleResponse>> {
	const url = `feature-toggles/manage-feature-toggles/${featureName}/${sourceId}`

	const result = await apiCommon.apiFetch<apiTypes.FeatureToggleResponse>(
		onProgress,
		{
			method: 'PUT',
			data: featureToggle,
		},
		url,
	)

	return result
}
