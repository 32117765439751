import { FC } from 'app/FunctionalComponent'
import { DateTime } from 'luxon'
import React from 'react'
import { solidIcons } from 'ui/components/common/icon'
import { IconButton } from 'ui/components/common/icon/Icon'
import { Input } from 'ui/components/common/input/Input'
import { SmallButton } from 'ui/components/common/small-button'
import { useOnce } from 'ui/components/hooks'
import { t } from 'ui/components/i18n/i18n'
import { FlexRow, FlexVerticalSeparator } from 'ui/components/layout/flexRow'
import { fireAndForget } from 'ui/lib/async'
import { l } from 'ui/lib/lodashImports'
import { sos2 } from 'ui/lib/state/sos2'
import * as classes from 'ui/pages/rfp/RfpLayout.module.scss'
import { getRfpUrlState } from 'ui/pages/rfp/sosRfp'
import * as commonClasses from 'ui/theme/common.module.scss'
import {
	addClass,
	addClassIf,
	getBackgroundWithTextColor,
	getTextColor,
} from 'ui/theme/theme'
import { sosRfp } from '.'
import { Layout } from '../layout'
import { NewRfpProjectModal } from './NewRfpProjectModal'

export const tPrefixRfp = 'page.rfp'

export const RfpLayout: FC = (props: { children: React.ReactNode }) => {
	const state = sos2.useSubscription(sosRfp.getSos())
	useOnce(() => {
		fireAndForget(async () => {
			await sosRfp.fetchRfpProjects()
			const rfpProjectId = getRfpUrlState().rfpProjectId
			if (rfpProjectId) {
				sosRfp.selectRfpProject(rfpProjectId, getRfpUrlState().edit === 'true')
			}
		}, 'fetching RFP projects')
	})

	const rfpProjectOptions = state.rfpProjects.map((rfpProject) => {
		return { value: rfpProject.id, label: rfpProject.name }
	})

	return (
		<div>
			<Layout fullWidth={true}>
				<FlexRow wrap={false}>
					<div>
						<FlexRow mode='space-between' verticalAlign='center'>
							<div className={classes.label}>
								{t('rfpProjects', tPrefixRfp)}
							</div>
							<FlexRow>
								<SmallButton
									minWidth='fit-content'
									onClick={sosRfp.rerunRfpProject}
									testId='rerunRfpProject'
									color='blue'
									isDisabled={!state.selectedRfpProject?.id}
								>
									{t('rerun', tPrefixRfp)}
								</SmallButton>
								<SmallButton
									minWidth='fit-content'
									onClick={() => sosRfp.updateShowNewRfpProjectModal(true)}
									testId='addRfpProject'
									color='blue'
								>
									{t('add', tPrefixRfp)}
								</SmallButton>
							</FlexRow>
						</FlexRow>
						<div className={classes.projectContainer}>
							{l.map(rfpProjectOptions, (rfpProject, rfpProjectIdx) => {
								const className = addClassIf(
									state.selectedRfpProject?.id === rfpProject.value,
									getBackgroundWithTextColor('blue'),
									classes.rfpProjectCard,
								)

								let subItemClassName = addClass(
									classes.subItem,
									classes.rfpProjectLabel,
								)
								subItemClassName = addClass(
									getTextColor('gray'),
									subItemClassName,
								)

								const rfpProjectObject = l.find(
									state.rfpProjects,
									(project) => project.id === rfpProject.value,
								)
								return (
									<div
										className={className}
										onClick={() => {
											sosRfp.selectRfpProject(rfpProject.value)
										}}
										key={rfpProjectIdx}
										data-testid={'rfpProject-' + rfpProjectIdx}
									>
										<FlexRow
											mode='space-between'
											verticalAlign='top'
											wrap={false}
										>
											<div className={classes.rfpProjectLabel}>
												<div className={classes.topAlign}>
													{state.projectsBeingEdited.indexOf(rfpProject.value) >
													-1 ? (
														<Input
															value={rfpProject.label}
															onEnter={(newVal: string) =>
																sosRfp.updateRfpProjectName(
																	rfpProject.value,
																	rfpProjectIdx,
																	newVal,
																)
															}
															readOnly={
																state.projectsBeingUpdated.indexOf(
																	rfpProject.value,
																) > -1
															}
														/>
													) : (
														rfpProject.label
													)}
												</div>
											</div>
											<div className={classes.rfpProjectLabel}>
												<FlexRow
													mode='space-around'
													wrap={false}
													noChildMargins={true}
												>
													<div className={classes.icon}>
														{state.projectsBeingEdited.indexOf(
															rfpProject.value,
														) > -1 ? (
															state.projectsBeingUpdated.indexOf(
																rfpProject.value,
															) > -1 && (
																<IconButton
																	icon={solidIcons.faSpinner}
																	spin={true}
																/>
															)
														) : (
															<IconButton
																onClick={() =>
																	sosRfp.editRfpProjectName(rfpProject.value)
																}
																icon={solidIcons.faPencilAlt}
																testId='editRfpProject'
															/>
														)}
													</div>
													<div
														className={commonClasses.clickable}
														onClick={() =>
															fireAndForget(
																async () =>
																	sosRfp.deleteRfpProject(rfpProject.value),
																`deleting RFP Project`,
															)
														}
														data-testid='deleteRfpProject'
													>
														<div
															className={addClass(
																classes.removeIcon,
																classes.topAlign,
															)}
														>
															&times;
														</div>
													</div>
												</FlexRow>
											</div>
										</FlexRow>
										<div className={subItemClassName}>
											{rfpProjectObject?.currentRfpId &&
												DateTime.fromFormat(
													rfpProjectObject?.currentRfpId?.slice(0, 17),
													'yMMddHHmmssSSS',
												).toFormat('MM/dd/y hh:mm a')}
											{!rfpProjectObject?.currentRfpId &&
												t('notYetRun', tPrefixRfp)}
										</div>
									</div>
								)
							})}
						</div>
					</div>
					<FlexVerticalSeparator />
					<div className={classes.container}>{props.children}</div>
				</FlexRow>
				<NewRfpProjectModal isOpen={state.showNewRfpProjectModal} />
			</Layout>
		</div>
	)
}
