import { apiPackageCatalog } from 'ui/api'
import { UpdateMode } from 'ui/api/apiCommon'
import { IRequestState } from 'ui/api/requestState'
import { dataUtils } from 'ui/components/table/'
import { IDataTableState } from 'ui/components/table/IDataTableState'
import { formUtils } from 'ui/forms'
import {
	IPackageCatalogEntryForm,
	packageCatalogEntryFormMetadata,
} from 'ui/forms/formPackageCatalogEntry'
import { IPackageCatalogEntry } from 'ui/lib/books'
import { IAddressBook as IPackageCatalog } from 'ui/lib/books/IAddressBook'
import { l } from 'ui/lib/lodashImports'
import { router } from './router'
import { createSos } from './secretaryOfState'

export interface IStatePackageCatalog {
	bookId: string
	requestPackageCatalog: IRequestState<IPackageCatalog>
	requestPackageCatalogEntries: IRequestState<IPackageCatalogEntry[]>
	requestUpdatePackageCatalogEntry: IRequestState<IPackageCatalogEntry>
	dataTablePackageCatalogEntries: IDataTableState<IPackageCatalogEntry>
	dataPackageCatalogEntries: IPackageCatalogEntry[]
	packageCatalogPageForm: IPackageCatalogEntryForm
}

const initialState: IStatePackageCatalog = {
	bookId: '',
	requestPackageCatalog: {},
	requestPackageCatalogEntries: {},
	requestUpdatePackageCatalogEntry: {},
	dataTablePackageCatalogEntries: {},
	dataPackageCatalogEntries: [],
	packageCatalogPageForm: formUtils.createDefaultFormStringData(
		packageCatalogEntryFormMetadata,
	),
}

const { stateManager, useSubscribe } = createSos(
	'package-catalog-entry',
	'1.0.1',
	initialState,
	{
		useLocalStorage: true,
		localStorageFields: [
			'requestPackageCatalogEntries',
			'dataTablePackageCatalogEntries',
		],
	},
)
export { useSubscribe }

export async function fetchPackageCatalogEntries(): Promise<void> {
	const state = stateManager.getState()
	await apiPackageCatalog.getPackageCatalogEntries(state.bookId, (rs) => {
		stateManager.produce((ds) => {
			ds.requestPackageCatalogEntries = rs
			ds.dataPackageCatalogEntries = rs.data
		})
	})
}

export async function fetchBook(): Promise<void> {
	const state = stateManager.getState()
	await apiPackageCatalog.getPackageCatalog(state.bookId, (rs) => {
		stateManager.produce((ds) => {
			ds.requestPackageCatalog = rs
		})
	})
}

export function packageCatalogTableSetUpdateMode(
	mode: UpdateMode,
	row: IPackageCatalogEntry = null,
): void {
	stateManager.produce((ds: IStatePackageCatalog) => {
		dataUtils.toggleUpdateMode(ds.dataTablePackageCatalogEntries, mode, row)
		if (ds.dataTablePackageCatalogEntries.isAdding) {
			ds.packageCatalogPageForm = formUtils.createDefaultFormStringData(
				packageCatalogEntryFormMetadata,
			)
		} else if (ds.dataTablePackageCatalogEntries.editRow) {
			ds.packageCatalogPageForm = formUtils.apiDataToFormStringData(
				packageCatalogEntryFormMetadata,
				row,
			)
		}
	})
}

export function updatePackageCatalogEntryForm(
	key: keyof IPackageCatalogEntryForm,
	newVal: string,
): void {
	stateManager.produce((ds: IStatePackageCatalog) => {
		ds.packageCatalogPageForm[key] = newVal
	})
}

export async function updatePackageCatalogEntry(
	mode: UpdateMode,
): Promise<void> {
	const state = stateManager.getState()

	const apiData = formUtils.formStringDataToApiData(
		packageCatalogEntryFormMetadata,
		state.dataTablePackageCatalogEntries.editRow,
		state.packageCatalogPageForm,
	)

	const result = await apiPackageCatalog.updatePackageCatalogEntry(
		mode,
		state.bookId,
		apiData,
		(rs) => {
			stateManager.produce((ds: IStatePackageCatalog) => {
				ds.requestUpdatePackageCatalogEntry = rs
			})
		},
	)

	if (result.data) {
		stateManager.produce((ds: IStatePackageCatalog) => {
			dataUtils.updateItem(
				ds.dataTablePackageCatalogEntries,
				ds.dataPackageCatalogEntries,
				mode,
				result.data,
				ds.packageCatalogPageForm.id,
			)
		})
		packageCatalogTableSetUpdateMode('none')
	}
	if (result.error) {
		stateManager.produce((ds: IStatePackageCatalog) => {
			dataUtils.setError(ds.dataTablePackageCatalogEntries, result.error)
		})
	}
}

export function sort(sortOn: string): void {
	stateManager.produce((ds) => {
		dataUtils.sort(
			ds.dataTablePackageCatalogEntries,
			ds.dataPackageCatalogEntries,
			sortOn,
		)
	})
}

export function toggleHeader(field: string): void {
	stateManager.produce((ds) => {
		dataUtils.toggleHeader(ds.dataTablePackageCatalogEntries, field)
	})
}

export function navigateToPackageCatalog(params: { bookId?: string }): void {
	stateManager.produce((ds) => {
		l.defaults(params, {
			bookId: ds.bookId,
		})
		ds.bookId = params.bookId
		dataUtils.toggleUpdateMode(ds.dataTablePackageCatalogEntries, 'none', null)
	})
	router.navTo(`/package-catalog/${params.bookId}`)
}
