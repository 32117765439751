import React, { useState } from 'react'
import { Table } from 'react-bootstrap'
import { apiProviderInvoice, apiTypes } from 'ui/api'
import { sosToast } from 'ui/common/components/toast'
import { AsyncTypeahead, TypeaheadOption } from 'ui/common/components/typeahead'
import { Button } from 'ui/components/common/button'
import { IconButton, solidIcons } from 'ui/components/common/icon'
import { Input } from 'ui/components/common/input'
import { Modal } from 'ui/components/common/modal'
import { OkCancelButtons } from 'ui/components/common/okCancelButtons'
import { FormTextInput, IFormData } from 'ui/components/form'
import { t, tCurrency } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight'
import { FC } from 'ui/FunctionalComponent'
import { l } from 'ui/lib/lodashImports'
import * as classes from './ProviderCharges.module.scss'

export const ProviderCharges: FC = (props: {
	shipmentId: string
	brokerInvoice: apiTypes.BrokerInvoiceResponse
	providerInvoice: apiTypes.ProviderInvoiceResponse
	providerChargeCodes: apiTypes.ChargeCodePageResponse[]
	onUpdate: (updateMode: 'add' | 'upsert' | 'delete', charges: any) => void
	tPrefix?: string
}) => {
	const {
		shipmentId,
		brokerInvoice,
		providerInvoice,
		providerChargeCodes,
		onUpdate,
		tPrefix,
	} = props

	const [chargeId, setChargeId] = useState<string>(null)
	const [chargeRequest, setChargeRequest] = useState<
		apiTypes.ProviderInvoiceChargeRequest
	>(null)

	const [selectedChargeCode, setSelectedChargeCode] = useState<TypeaheadOption>(
		null,
	)
	const [selectedChargeDescription, setSelectedChargeDescription] = useState<
		TypeaheadOption
	>(null)

	const [isAddingCharge, setIsAddingCharge] = useState<boolean>(false)
	const [isUpdatingCharge, setIsUpdatingCharge] = useState<boolean>(false)
	const [isDeletingCharge, setIsDeletingCharge] = useState<boolean>(false)

	const [chargeToEdit, setChargeToEdit] = useState<number>(null)
	const [chargeToDelete, setChargeToDelete] = useState<number>(null)

	const [showModal, setShowModal] = useState<boolean>(false)

	const updateForm = (
		data: apiTypes.ProviderInvoiceChargeResponse,
		updateMode: 'upsert' | 'delete',
	): void => {
		const charges = l.cloneDeep(providerInvoice.charges)

		charges.splice(
			updateMode === 'upsert' ? chargeToEdit : chargeToDelete,
			1,
			data,
		)

		onUpdate('upsert', charges)
	}

	const createProviderInvoiceCharge = async (): Promise<void> => {
		setIsAddingCharge(true)

		const charge: apiTypes.ProviderInvoiceChargeRequest = {
			shipmentId: shipmentId,
			chargeCode: '',
			chargeDescription: '',
			quantity: 1,
			unitPrice: 0,
		}

		const response = await apiProviderInvoice.createProviderInvoiceCharge(
			() => {},
			providerInvoice.id,
			charge,
		)

		if (response.error) {
			sosToast.sendApiErrorResponseToast(response)
		} else {
			onUpdate('add', response.data)
			setChargeId(response.data.id)
			setChargeRequest(charge)

			setChargeToEdit(
				providerInvoice?.charges?.length === 0
					? 0
					: providerInvoice?.charges?.length - 1,
			)
		}

		setIsAddingCharge(false)
	}

	const updateProviderInvoiceCharge = async (): Promise<void> => {
		setIsUpdatingCharge(true)

		const updatedChargeRequest = {
			...chargeRequest,
			chargeCode: selectedChargeCode?.value || chargeRequest.chargeCode,
			chargeDescription:
				selectedChargeDescription?.value || chargeRequest.chargeDescription,
			unitPrice: chargeRequest.unitPrice * 100,
		}

		const response = await apiProviderInvoice.updateProviderInvoiceCharge(
			providerInvoice.id,
			chargeId,
			updatedChargeRequest,
		)

		if (response.error) {
			sosToast.sendApiErrorResponseToast(response)
		} else {
			setSelectedChargeCode(null)
			setSelectedChargeDescription(null)

			const data = response.data
			data.unitPrice = data.unitPrice / 100

			updateForm(data, 'upsert')
		}

		setIsUpdatingCharge(false)
	}

	const deleteProviderInvoiceCharge = async (): Promise<void> => {
		setIsDeletingCharge(true)

		const response = await apiProviderInvoice.deleteProviderInvoiceCharge(
			providerInvoice.id,
			chargeId,
		)

		if (response.error) {
			sosToast.sendApiErrorResponseToast(response)
		} else {
			updateForm(response.data, 'delete')
		}

		setIsDeletingCharge(false)
	}

	const updateCharges = (
		target: 'chargecode' | 'chargedescription',
		str: TypeaheadOption,
		idx: number,
	): void => {
		if (target === 'chargecode') {
			if (idx === chargeToEdit) {
				const filteredCharges = l.find(
					providerChargeCodes,
					(c) => c.chargeCode === str.label,
				)

				setSelectedChargeCode({
					value: filteredCharges?.chargeCode,
					label: filteredCharges?.chargeCode,
				})

				setSelectedChargeDescription({
					value: filteredCharges?.chargeCodeDescription,
					label: filteredCharges?.chargeCodeDescription,
				})
			}
		} else if (target === 'chargedescription') {
			if (idx === chargeToEdit) {
				const filteredCharges = l.find(
					providerChargeCodes,
					(c) => c.chargeCodeDescription === str.label,
				)

				setSelectedChargeCode({
					value: filteredCharges?.chargeCode,
					label: filteredCharges?.chargeCode,
				})

				setSelectedChargeDescription({
					value: filteredCharges?.chargeCodeDescription,
					label: filteredCharges?.chargeCodeDescription,
				})
			}
		}
	}

	return (
		<div className={classes.providerCharges}>
			<div className={classes.titleContainer}>
				<h4>{t('providerCharges', tPrefix)}</h4>
				{brokerInvoice?.invoiceStatus === 'review' && (
					<Button
						color={'green'}
						onClick={async () => {
							await createProviderInvoiceCharge()
						}}
						isSpinning={isAddingCharge}
					>
						{t('addCharge', tPrefix)}
					</Button>
				)}
			</div>

			<Table striped size={'xs'}>
				<thead>
					<tr>
						<th>{t('chargeCode', tPrefix)}</th>
						<th>{t('chargeDescription', tPrefix)}</th>
						<th>{t('quantity', tPrefix)}</th>
						<th>{t('unitPrice', tPrefix)}</th>
						<th>{t('totalCost', tPrefix)}</th>
						<th>&nbsp;</th>
					</tr>
				</thead>
				<tbody>
					{l.map(providerInvoice?.charges || [], (charge, idx) => {
						if (charge.providerInvoiceId === providerInvoice.id) {
							const formData: IFormData<apiTypes.ProviderInvoiceChargeResponse> = {
								form: charge,

								metadata: {
									shipmentId: {},
									chargeCode: {},
									chargeDescription: {},
									quantity: {},
									unitPrice: {},
									id: {},
									providerInvoiceId: {},
									totalPrice: {},
								},

								onUpdateForm: (field: string, value: any) => {
									const updateInvoiceChargesRequest = l.cloneDeep(
										providerInvoice.charges,
									)

									const updateInvoiceRequest = l.cloneDeep(charge)

									updateInvoiceRequest[field] = value

									updateInvoiceChargesRequest.splice(
										idx,
										1,
										updateInvoiceRequest,
									)

									setChargeRequest(updateInvoiceRequest)

									onUpdate('upsert', updateInvoiceChargesRequest)
								},

								tPrefix,
							}

							return (
								<tr key={`${charge.chargeCode} ${idx}`}>
									<td>
										<AsyncTypeahead
											testId={'cutomer-invoice-chargeCode-input'}
											size={'small'}
											options={[]}
											onSearch={async () => {
												let responseOptions: TypeaheadOption[] = []

												if (providerChargeCodes) {
													responseOptions = providerChargeCodes.map((c) => ({
														value: c.chargeCode,
														label: c.chargeCode,
													}))
												}

												return responseOptions
											}}
											onChange={(selected: TypeaheadOption) => {
												updateCharges('chargecode', selected, idx)
											}}
											isClearable={true}
											useCache={true}
											className={`${classes.asyncTypehead} ${classes.asyncTypeheadChargeCode}`}
											disabled={idx !== chargeToEdit}
											value={
												(idx === chargeToEdit && selectedChargeCode) ||
												charge.chargeCode
											}
										/>
									</td>
									<td>
										<AsyncTypeahead
											testId={'provider-invoice-chargeDescription-input'}
											size={'small'}
											options={[]}
											onSearch={async () => {
												let responseOptions: TypeaheadOption[] = []

												if (providerChargeCodes) {
													responseOptions = providerChargeCodes.map((c) => ({
														value: c.chargeCodeDescription,
														label: c.chargeCodeDescription,
													}))
												}

												return responseOptions
											}}
											onChange={(selected: TypeaheadOption) => {
												updateCharges('chargedescription', selected, idx)
											}}
											isClearable={true}
											useCache={true}
											className={classes.asyncTypehead}
											disabled={idx !== chargeToEdit}
											value={
												(idx === chargeToEdit && selectedChargeDescription) ||
												charge.chargeDescription
											}
										/>
									</td>
									<td>
										<FormTextInput
											form={formData.form}
											field={'quantity'}
											onUpdateForm={formData.onUpdateForm}
											className={classes.alignRight}
											readOnly={idx !== chargeToEdit}
											testId={'provider-invoice-charge-quantity-input'}
										/>
									</td>
									<td>
										{idx !== chargeToEdit ? (
											<Input
												value={tCurrency(
													charge.unitPrice,
													providerInvoice.currency,
												)}
												readOnly={true}
												className={classes.alignRight}
											/>
										) : (
											<FormTextInput
												form={formData.form}
												field={'unitPrice'}
												onUpdateForm={formData.onUpdateForm}
												className={classes.alignRight}
												testId={'provider-invoice-charge-unitPrice-input'}
											/>
										)}
									</td>
									<td>
										<Input
											value={tCurrency(
												charge.unitPrice * charge.quantity,
												providerInvoice.currency,
											)}
											readOnly={true}
											className={classes.alignRight}
											testId={'provider-invoice-charge-totalPrice-input'}
										/>
									</td>

									{brokerInvoice?.invoiceStatus === 'review' && (
										<td>
											<div className={classes.iconButtons}>
												<IconButton
													icon={
														idx === chargeToEdit
															? solidIcons.faCheck
															: solidIcons.faPencilAlt
													}
													buttonClassName={
														idx === chargeToEdit ? classes.save : classes.edit
													}
													color={idx === chargeToEdit ? 'green' : 'black'}
													onClick={async () => {
														if (idx === chargeToEdit) {
															await updateProviderInvoiceCharge()
															setChargeToEdit(null)
														} else {
															setChargeId(charge.id)
															setChargeRequest(charge)
															setChargeToEdit(idx)
														}
													}}
													spin={idx === chargeToEdit && isUpdatingCharge}
													testId={'provider-invoice-charge-editSave'}
												></IconButton>
												<IconButton
													icon={solidIcons.faTimes}
													buttonClassName={classes.cancel}
													color={'red'}
													onClick={async () => {
														if (chargeToEdit !== null) {
															setChargeToEdit(null)
														} else {
															setChargeId(charge.id)
															setChargeToDelete(idx)
															setShowModal(true)
														}
													}}
													testId={'provider-invoice-charge-cancel'}
												></IconButton>
											</div>
										</td>
									)}
								</tr>
							)
						}
					})}
				</tbody>
			</Table>
			<Modal
				content={() => (
					<div data-testid={'provider-invoice-charge-delete-modal'}>
						<p>{t('deleteThisInvoiceCharge?', tPrefix)}</p>
						<AlignRight>
							<OkCancelButtons
								isValid={true}
								ok={t('ok', tPrefix)}
								okColor={'green'}
								okTestId={'provider-invoice-charge-delete-modal-ok'}
								isSpinning={isDeletingCharge}
								onOk={async () => {
									await deleteProviderInvoiceCharge()
									setChargeToDelete(null)
									setShowModal(false)
								}}
								cancel={t('cancel', tPrefix)}
								onCancel={() => {
									setChargeToDelete(null)
									setShowModal(false)
								}}
								cancelTestId={'provider-invoice-charge-delete-modal-cancel'}
							></OkCancelButtons>
						</AlignRight>
					</div>
				)}
				isOpen={showModal}
				onModalClose={() => {}}
				title={t('confirmDelete', tPrefix)}
			/>
		</div>
	)
}
