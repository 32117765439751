import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { IconButton, solidIcons } from 'ui/components/common/icon'
import { FlexItem } from 'ui/components/layout/flexRow/FlexItem'
import { FlexRow } from 'ui/components/layout/flexRow/FlexRow'
import {
	CalendarInput,
	DatePickerMode,
	IDatePickerState,
} from 'ui/pages/shipments/ShipmentsListDatePicker'
import classes from './ShipmentsListDatePicker.module.scss'

export function getDefaultDateState(mode: DatePickerMode): IDatePickerState {
	return {
		mode: mode,
		date1String: '',
		date2String: '',
		showDate1Calendar: false,
		showDate2Calendar: false,
		inLastX: '',
	}
}

export const ListFilterDatePicker: FC = (props: {
	datePickerState: IDatePickerState
	onChangeDatePickerState: (changes: Partial<IDatePickerState>) => void
}) => {
	const state = props.datePickerState

	const showSecondDate = state.mode === 'between'

	return (
		<div className={classes.datePicker}>
			<FlexRow>
				<FlexItem>
					<CalendarInput
						dateString={state.date1String}
						isOpen={state.showDate1Calendar}
						index={showSecondDate ? 1 : null}
						onChangeOpen={(isOpen) => {
							props.onChangeDatePickerState({
								showDate1Calendar: isOpen,
							})
						}}
						onChangeText={(newVal) => {
							props.onChangeDatePickerState({
								date1String: newVal,
							})
						}}
					/>
				</FlexItem>
				{showSecondDate ? (
					<>
						<FlexItem>
							<CalendarInput
								dateString={state.date2String}
								isOpen={state.showDate2Calendar}
								index={2}
								onChangeOpen={(isOpen) => {
									props.onChangeDatePickerState({
										showDate2Calendar: isOpen,
									})
								}}
								onChangeText={(newVal) => {
									props.onChangeDatePickerState({
										date2String: newVal,
									})
								}}
							/>
						</FlexItem>
						<IconButton
							icon={solidIcons.faMinus}
							color={'gray'}
							onClick={() =>
								props.onChangeDatePickerState({
									date2String: '',
									mode: 'on',
								})
							}
						/>
					</>
				) : (
					<IconButton
						icon={solidIcons.faPlus}
						color={'gray'}
						onClick={() =>
							props.onChangeDatePickerState({
								showDate2Calendar: true,
								mode: 'between',
							})
						}
					/>
				)}
			</FlexRow>
		</div>
	)
}
