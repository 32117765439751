import { FC } from 'app/FunctionalComponent'
import { DateTime } from 'luxon'
import React from 'react'
import { Icon, solidIcons } from 'ui/components/common/icon'
import { Pager } from 'ui/components/common/pager/Pager'
import { SmallButton } from 'ui/components/common/small-button'
import { t } from 'ui/components/i18n/i18n'
import { DataTable } from 'ui/components/table/DataTable'
import { IDataTableHeader } from 'ui/components/table/IDataTableHeader'
import { IDataTableHeaderGroup } from 'ui/components/table/IDataTableHeaderGroup'
import { KeyValueTable } from 'ui/components/table/KeyValueTable'
import { log } from 'ui/lib/log/log'
import { IStateComponentExamples } from 'ui/state/sosComponentExamples'
import { Section } from './Section'

interface IExampleData {
	time: string
	status: string
	activity: string
	address: string
	lat: number
	long: number
	color?: object
	cost: number
}
const exampleData: IExampleData[] = [
	{
		time: DateTime.local().toString(),
		status: 'delivered	',
		activity: 'Delivered',
		address: '    Harahan,LA  	',
		lat: 47.1474656,
		long: -114.81,
		color: {
			status: 4,
		},
		cost: 80000,
	},
	{
		time: DateTime.local().toString(),
		status: 'in transit',
		activity: '	Dispatched for Delivery	',
		address: '  Harahan,LA  	  ',
		lat: 47.1474656,
		long: -114.81,
		cost: 70000,
	},
	{
		time: DateTime.local().toString(),
		status: 'in transit',
		activity: '	Load Trailer:292311',
		address: '  Harahan,LA	    ',
		lat: 47.1474656,
		long: -114.81,
		cost: 60000,
	},
	{
		time: DateTime.local().toString(),
		status: 'in transit',
		activity: '	Unload Trailer: 288603 Assigned',
		address: '    Harahan,LA    ',
		lat: 47.1474656,
		long: -114.81,
		cost: 50000,
	},
	{
		time: DateTime.local().toString(),
		status: 'in transit',
		activity: '	Arrived at Destination Terminal	',
		address: '  Harahan,LA	    ',
		lat: 47.1474656,
		long: -114.81,
		cost: 40000,
	},
	{
		time: DateTime.local().toString(),
		status: 'exception	',
		activity: 'ADVERSE WEATHER CONDITIONS',
		address: '  Columbus,  OH	  ',
		lat: 39.98,
		long: -83.13,
		cost: 30000,
	},
	{
		time: DateTime.local().toString(),
		status: 'in transit',
		activity: '	Departed from Terminal',
		address: '  Columbus, OH	  ',
		lat: 39.98,
		long: -83.13,
		cost: 30000,
	},
	{
		time: DateTime.local().toString(),
		status: 'in transit',
		activity: '	Load Trailer: 288603	',
		address: '   Columbus, OH	  ',
		lat: 39.98,
		long: -83.13,
		cost: 20000,
	},
	{
		time: DateTime.local().toString(),
		status: 'in transit',
		activity: '	Unload Trailer: 290911 Assigned	',
		address: '      Columbus, OH',
		lat: 39.98,
		long: -83.13,
		cost: 10000,
	},
	{
		time: DateTime.local().toString(),
		status: 'in transit',
		activity: '	Arrived at Breakbulk Terminal	  ',
		address: '      Columbus, OH',
		lat: 39.98,
		long: -83.13,
		cost: 90000,
	},
	{
		time: DateTime.local().toString(),
		status: 'in transit',
		activity: '	Departed from Terminal	        ',
		address: '        Madison,WI',
		lat: 39.87,
		long: 67.55,
		cost: 8000,
	},
	{
		time: DateTime.local().toString(),
		status: 'in transit',
		activity: '	Load Trailer: 290911	          ',
		address: '        Madison,WI',
		lat: 39.87,
		long: 67.55,
		cost: 9000,
	},
	{
		time: DateTime.local().toString(),
		status: 'in transit',
		activity: '	Unload Trailer: 533041 Assigned	',
		address: '      Madison,WI	',
		lat: 39.87,
		long: 67.55,
		cost: 7000,
	},
	{
		time: DateTime.local().toString(),
		status: 'in transit',
		activity: '	Arrived at Origin Terminal	    ',
		address: '    Madison,WI	  ',
		lat: 39.87,
		long: 67.55,
		cost: 6000,
	},
	{
		time: DateTime.local().toString(),
		status: 'picked up	',
		activity: 'Pickup from Customer	            ',
		address: '  Madison,WI	    ',
		lat: 40.87,
		long: -76.72,
		cost: 5000,
	},
]

const exampleData2 = {
	shipmentId: '719041015280001',
	po: `478289292
478289294
478289296 `,
	pickupDate: ' 2019-03-10',
	equipmentTypes: '',
	temperature: ' -20°F',
	totalMiles: '1267',
	totalWeight: '48587',
	accessorials: 'Stackable',
}
type temp = typeof exampleData2
type ITest = temp

const ExampleDataTable: FC = (props: {}) => {
	const headers: IDataTableHeader<IExampleData>[] = [
		{
			field: 'time',
			formatType: 'dateTime',
		},
		{
			field: 'status',
		},
		{
			field: 'activity',
			renderer: (value: string) => (
				<React.Fragment>
					<Icon icon={solidIcons.faCoffee} />
					{value} {'(custom renderer)'}
				</React.Fragment>
			),
		},
		{
			field: 'address',
		},
		{
			field: 'lat',
			colorIndex: 4,
		},
		{
			field: 'long',
		},
		{
			field: 'cost',
			formatType: 'currency',
		},
	]

	const headerGroups: IDataTableHeaderGroup<IExampleData>[] = [
		{
			t: 'timeline',
			colSpan: 1,
			colorIndex: 1,
		},
		{
			t: 'action',
			colSpan: 2,
			colorIndex: 2,
			renderer: () => {
				return (
					<div style={{ color: 'red' }}>
						I was passed in via <u>renderer</u>
					</div>
				)
			},
		},
		{
			t: 'location',
			colSpan: 3,
			colorIndex: 3,
		},
		{
			t: 'val',
			colSpan: 1,
			colorIndex: 2,
		},
	]
	return (
		<DataTable
			onExport={() =>
				log('example-components', 'this button can execute a csv export')
			}
			data={exampleData}
			state={{}}
			tPrefix='page.componentExamples'
			headers={headers}
			headerGroups={headerGroups}
			customToolbar={
				<div>
					This is a custom toolbar. It has buttons.
					<SmallButton>A button</SmallButton>
					<SmallButton>Another button</SmallButton>
				</div>
			}
		/>
	)
}

const ExampleKeyValueTable: FC = (props: { borderless?: boolean }) => {
	const headers: IDataTableHeader<ITest>[] = [
		{
			field: 'shipmentId',
		},
		{
			field: 'po',
		},
		{
			field: 'pickupDate',
		},
		{
			field: 'equipmentTypes',
		},
		{
			field: 'temperature',
		},
		{
			field: 'totalMiles',
		},
		{
			field: 'totalWeight',
		},
		{
			field: 'accessorials',
		},
	]
	return (
		<KeyValueTable
			tPrefix='page.componentExamples.exampleKeyValueTable'
			headers={headers}
			rawData={exampleData2}
			borderless={props.borderless}
		/>
	)
}

export const SectionTables: FC = (props: {
	stateComponentExamples: IStateComponentExamples
}) => {
	return (
		<Section
			title={t('page.componentExamples.tables')}
			stateComponentExamples={props.stateComponentExamples}
			name='tables'
		>
			<Pager numPages={8} currentPage={4} />
			<Pager numPages={1} currentPage={0} hasMorePages={true} />
			<Pager
				numPages={4}
				currentPage={2}
				hasMorePages={true}
				count={100}
				pageSize={10}
			/>
			<ExampleKeyValueTable borderless={true} />
			<ExampleKeyValueTable />
			<ExampleDataTable />
		</Section>
	)
}
