import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { Card } from 'ui/components/common/card/Card'
import { FormStacked } from 'ui/components/form/FormStacked'
import { FormStackedLayout } from 'ui/components/form/FormStackedLayout'
import { FormStackedTextInput } from 'ui/components/form/FormStackedTextInput'
import { IFormData } from 'ui/components/form/IFormData'
import { t } from 'ui/components/i18n/i18n'
import { IQuickRateProductCostForm } from 'ui/forms/formQuickRateProductCost'
import { sosQuickRateTms3 } from 'ui/state/'

const QuickRateProductCostForm: FC = (props: {
	form: IQuickRateProductCostForm
}) => {
	const { form } = props

	const formData: IFormData<IQuickRateProductCostForm> = {
		form,
		metadata:
			sosQuickRateTms3.formQuickRateProductCost
				.quickRateProductCostFormMetadata,
		onUpdateForm: sosQuickRateTms3.updateProductCostForm,
		tPrefix: 'page.quickRate.productCostForm',
	}

	return (
		<Card title={t('page.quickRate.productCostCardTitle')} color='red'>
			<FormStacked>
				<FormStackedLayout>
					<FormStackedTextInput formData={formData} field='productCost' />
					<FormStackedTextInput
						formData={formData}
						field='freightMarkupPercent'
					/>
				</FormStackedLayout>
			</FormStacked>
		</Card>
	)
}

export { QuickRateProductCostForm }
