import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { theme } from 'ui/theme'
import { Colors } from 'ui/theme/theme'
import classes from './Card.module.scss'
import { CardContent } from './CardContent'
import { CardTitle } from './CardTitle'

export const Card: FC = (props: {
	title: string | React.ReactNode
	color: Colors
	children?: React.ReactNode
	noPadding?: boolean
	isOpen?: boolean
	fillHeight?: boolean
	fullWidth?: boolean
	inline?: boolean
	onToggle?: () => void
	testId?: string
	className?: string
}) => {
	let className = `${classes.card} ${theme.getBorderColor(props.color)}`

	className = theme.addClassIf(props.fillHeight, classes.fillHeight, className)
	className = theme.addClassIf(props.fullWidth, classes.fullWidth, className)
	className = theme.addClassIf(props.inline, classes.notFullWidth, className)
	className = theme.addClass(props.className, className)

	return (
		<div className={className} data-testid={props.testId}>
			<CardTitle color={props.color} onToggle={props.onToggle}>
				{props.title}
			</CardTitle>
			{props.isOpen !== false && (
				<CardContent noPadding={props.noPadding}>{props.children}</CardContent>
			)}
		</div>
	)
}
