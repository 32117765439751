import { FC } from 'app/FunctionalComponent'
import React, { useEffect } from 'react'
import { sosRouter2 } from 'ui/components/common/router'
import { Debug } from 'ui/components/dev/Debug'
import { useOnce } from 'ui/components/hooks'
import { sos2 } from 'ui/lib/state/sos2'
import { Layout } from 'ui/pages/layout/Layout'
import { sosUser } from 'ui/state'
import { ShipmentsListDataTable } from '.'
import { delayedTask, sosShipmentsList } from './state'

export const ShipmentsListPage: FC = (props: {}) => {
	return (
		<Layout>
			<ShipmentsList />
		</Layout>
	)
}

export const ShipmentsList: FC = (props: {}) => {
	sosRouter2.useSubscribeToUrlChanges()

	const state = sos2.useSubscription(sosShipmentsList.getSos())
	const userState = sos2.useSubscription(sosUser.getSos())
	const urlState = sosShipmentsList.getUrlState()
	const delayed = delayedTask.useSubscription(
		sosShipmentsList.delayedFetchShipments,
	)
	useOnce(() => {
		delayedTask.callNow(sosShipmentsList.delayedFetchShipments)
	})
	useEffect(() => {
		sosShipmentsList.resetAccountingShipments()
	}, [userState.selectedClientConfig])

	return (
		<>
			<ShipmentsListDataTable
				state={state}
				userState={userState}
				shipmentStatusFilter={urlState.shipmentStatusFilter}
				loadingStatus={delayed.status}
				brokerMode={sosUser.isUserBroker()}
			/>
			<Debug label='Shipments State' data={state} />
		</>
	)
}
