import { FC } from 'app/FunctionalComponent'
import React from 'react'
import classes from './Fieldset.module.scss'

export const Fieldset: FC = (props: {
	children: React.ReactNode
	inset?: boolean
}) => {
	let className = classes.fieldset
	if (props.inset) {
		className += ' ' + classes.inset
	}

	return <fieldset className={className}>{props.children}</fieldset>
}
