import { FC } from 'app/FunctionalComponent'
import { LatLngTuple } from 'leaflet'
import React, { ReactNode } from 'react'
import { Map, TileLayer, ZoomControl } from 'react-leaflet'
import { l } from 'ui/lib/lodashImports'
import { theme } from 'ui/theme'
import './Leaflet.scss'

export interface LeafletMapBounds {
	topLeft: LatLngTuple
	bottomRight: LatLngTuple
}

const defaults = {
	lat: 38.2,
	lng: -94.8,
	zoom: 5,
	scrollable: false,
}

export const LeafletMap: FC = (props: {
	height: string
	center?: { lat: number; lng: number }
	zoom?: number
	scrollable?: boolean
	onClick?: () => void
	children?: ReactNode
	containerClassName?: string
	zoomPosition?: 'bottomright' | 'bottomleft' | 'topright' | 'topleft'
	bounds?: LeafletMapBounds
	testId?: string
}) => {
	const {
		height,
		center,
		zoom,
		onClick,
		scrollable,
		containerClassName,
		zoomPosition,
		bounds,
	} = props

	let className = 'leaflet-wrapper'
	className = theme.addClassIf(
		containerClassName !== '',
		containerClassName,
		className,
	)
	return (
		<div data-testid={props.testId} className={className}>
			<Map
				center={[center?.lat ?? defaults.lat, center?.lng ?? defaults.lng]}
				zoom={zoom ?? defaults.zoom}
				scrollWheelZoom={scrollable ?? defaults.scrollable}
				onclick={() => {
					if (onClick) {
						onClick()
					}
				}}
				style={{ height }}
				zoomControl={l.isNil(zoomPosition)}
				bounds={bounds && [bounds.topLeft, bounds.bottomRight]}
			>
				<TileLayer
					attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
					url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
				/>
				{zoomPosition && <ZoomControl position={zoomPosition} />}
				{props.children}
			</Map>
		</div>
	)
}
