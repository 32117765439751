import numeral from 'numeral'
import React from 'react'
import { Button } from 'ui/components/common/button'
import { Popup } from 'ui/components/common/popup'
import { SmallButton } from 'ui/components/common/small-button'
import { AlignRight } from 'ui/components/layout/alignRight'
import {
	FlexItem,
	FlexRow,
	FlexVerticalSeparator,
} from 'ui/components/layout/flexRow'
import { l } from 'ui/lib/lodashImports'
import classes from './TimePicker.module.scss'
import { IFormData } from 'ui/components/form'
import { t } from 'ui/components/i18n/i18n'
import { twentyFourHourToTwelveHourTime } from 'ui/lib/time/time'
import { FC } from 'app/FunctionalComponent'

export type AmPmType = 'am' | 'pm'
const hours = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
const amPmValues: AmPmType[] = ['am', 'pm']

export interface ITimePickerState {
	hour: number
	minute: number
	isOpen?: boolean
}

export const TimePicker: FC = (props: {
	state: IFormData<ITimePickerState>
	intervalMinutes?: number
	testId?: string
}) => {
	const intervalMinutes = props.intervalMinutes || 5
	const minutes: number[] = []
	for (let i = 0; i * intervalMinutes < 60; i++) {
		minutes.push(intervalMinutes * i)
	}

	const amPm: AmPmType = props.state.form.hour >= 12 ? 'pm' : 'am'
	let displayHour = props.state.form.hour
	if (displayHour >= 13) {
		displayHour -= 12
	}

	return (
		<div>
			<Popup
				isOpen={props.state.form.isOpen}
				content={
					<div>
						<TimeSelector
							hour={props.state.form.hour}
							minute={props.state.form.minute}
							minutes={minutes}
							onChange={props.state.onUpdateForm}
							tPrefix={props.state.tPrefix}
						/>
						<AlignRight>
							<Button
								color='blue'
								onClick={() => {
									props.state.onUpdateForm('isOpen', false)
								}}
								testId='timePickerOk'
							>
								{t('ok', props.state.tPrefix)}
							</Button>
						</AlignRight>
					</div>
				}
				onClickOutside={() => {
					props.state.onUpdateForm('isOpen', false)
				}}
				testId={props.testId + 'PickerPopup'}
			>
				<div className={classes.timePicker}>
					<SmallButton
						color='gray'
						onClick={() => {
							props.state.onUpdateForm('isOpen', true)
						}}
						testId={props.testId}
					>
						{displayHour}:{numeral(props.state.form.minute).format('00')}
						{amPm || 'am'}
					</SmallButton>
				</div>
			</Popup>
		</div>
	)
}

export const TimeSelector: FC = (props: {
	hour: number
	minute: number
	minutes: number[]
	onChange: (field: any, newVal: any) => void
	tPrefix: string
	testId?: string
}) => {
	const { amPm, hour: displayHour } = twentyFourHourToTwelveHourTime(props.hour)

	const onUpdateForm = (field, newVal, hour: number): void => {
		if (field === 'amPm') {
			if (hour < 12 && newVal === 'pm') {
				hour += 12
			} else if (hour >= 12 && newVal === 'am') {
				hour -= 12
			}
			props.onChange('hour', hour)
		} else if (field === 'hour') {
			let newHour = newVal
			if (newHour === 12) {
				newHour = 0
			}
			if (amPm === 'pm') {
				newHour += 12
			}
			props.onChange('hour', newHour)
		} else {
			props.onChange(field, newVal)
		}
	}

	return (
		<div className={classes.timeSelector} data-testid={props.testId}>
			<FlexRow mode='center'>
				<FlexItem>
					<div>{t('hour', props.tPrefix)}</div>
					{l.map(hours, (c) => (
						<div key={c}>
							<SmallButton
								minWidth='3em'
								color={c === displayHour ? 'blue' : 'gray'}
								onClick={() => {
									onUpdateForm('hour', c, props.hour)
								}}
								testId={'hour' + c}
							>
								{c}
							</SmallButton>
						</div>
					))}
				</FlexItem>
				<FlexVerticalSeparator />
				<FlexItem>
					<div>{t('minute', props.tPrefix)}</div>
					{l.map(props.minutes, (c) => (
						<div key={c}>
							<SmallButton
								minWidth='3em'
								color={c === props.minute ? 'blue' : 'gray'}
								onClick={() => {
									onUpdateForm('minute', c, props.hour)
								}}
								testId={'minute' + c}
							>
								{numeral(c).format('00')}
							</SmallButton>
						</div>
					))}
				</FlexItem>
				<FlexVerticalSeparator />
				<FlexItem>
					<div>{t('amPm', props.tPrefix)}</div>
					{l.map(amPmValues, (c) => (
						<div key={c}>
							<SmallButton
								minWidth='3em'
								color={c === amPm ? 'blue' : 'gray'}
								onClick={() => {
									onUpdateForm('amPm', c, props.hour)
								}}
								testId={'amPm-' + c}
							>
								{c}
							</SmallButton>
						</div>
					))}
				</FlexItem>
			</FlexRow>
		</div>
	)
}
