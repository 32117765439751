import { FC } from 'app/FunctionalComponent'
import React, { useState } from 'react'
import { Button } from 'ui/components/common/button'
import { getWindow } from 'ui/components/common/router/windowUtils'
import { tms2_common } from 'ui/lib/tms2_common'
import { Layout } from '../layout'

export const TestTms2Integration: FC = (props: {}) => {
	const [state, setState] = useState({})

	// const testWindowListener = (ev) => {
	// 	if (ev && ev.data && ev.data.type === 'call-global-response') {
	// 		setState(ev.data.result)
	// 	}
	// }
	// useEffect(() => {
	// 	window.addEventListener('message', testWindowListener, false)
	// 	return () => {
	// 		window.removeEventListener('message', testWindowListener)
	// 	}
	// })

	return (
		<Layout>
			Test TMS2 Integration
			<Button
				onClick={async () => {
					const info = await tms2_common.getAuthInfo()
					setState(info)
				}}
			>
				Get User Info
			</Button>
			<Button
				onClick={() => {
					getWindow().parent.postMessage(
						{
							type: 'call-global',
							token: 12345,
							functionName: 'getAuthInfo',
						},
						'*', // TODO: fix target origin
					)
				}}
			>
				Get User Info Raw
			</Button>
			<pre>{JSON.stringify(state, null, 2)}</pre>
		</Layout>
	)
}
