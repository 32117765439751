import React, { useEffect, useState } from 'react'
import { Col, Row, Table } from 'react-bootstrap'
import { apiCharges, apiTypes } from 'ui/api'
import { IRequestState } from 'ui/api/requestState'
import { sosToast } from 'ui/common/components/toast'
import { Button } from 'ui/components/common/button'
import { IconButton, solidIcons } from 'ui/components/common/icon'
import { Loader } from 'ui/components/common/loader'
import { Modal } from 'ui/components/common/modal'
import { OkCancelButtons } from 'ui/components/common/okCancelButtons'
import {
	FormStackedPillSwitch,
	FormStackedSelect,
	FormStackedTextInput,
	FormTextInput,
	IFormData,
} from 'ui/components/form'
import { t, tString } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight'
import { Center } from 'ui/components/layout/center'
import { Spacer } from 'ui/components/layout/spacer'
import { FC } from 'ui/FunctionalComponent'
import { fireAndForget } from 'ui/lib/async'
import { l } from 'ui/lib/lodashImports'
import * as classes from './ChargeCodeCatalog.module.scss'
import { SearchInput } from 'ui/components/common/search'
import { ElasticSearchPager } from 'ui/components/common/pager'
import { IStatePager } from 'ui/state/paging'
import { Autocomplete } from 'ui/components/common/autocomplete'
import { ISelectOptions } from 'ui/components/common/select'

export const emptyChargeRequest: apiTypes.ChargeCodePageRequest = {
	chargeCode: '',
	chargeCodeDescription: '',
	chargeCodeType: 'customer',
}

const chargeCodeTypes = ['customer', 'provider', 'both']

export const ChargeCodeCatalog: FC = (props: { chargeCodeBookId: string }) => {
	const { chargeCodeBookId } = props
	const [isFetchingChargeCodes, setIsFetchingChargeCodes] = useState<boolean>(
		false,
	)
	const tPrefix = 'page.companyManagement.tabs.chargeCodeCatalog'

	const [rowToEdit, setRowToEdit] = useState<number>(null)
	const [rowToDelete, setRowToDelete] = useState<number>(null)
	const [isAddingCharge, setIsAddingCharge] = useState<boolean>(false)
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const [charges, setCharges] = useState<apiTypes.ChargeCodePageResponse[]>(
		() => [],
	)

	const [chargeCodeRequest, setChargeCodeRequest] = useState<
		apiTypes.ChargeCodePageRequest
	>(emptyChargeRequest)

	const [pager, setPager] = useState<IStatePager>({
		fetchingPageNumber: 0,
		pageNumber: 0,
		pageCount: 0,
		pageSize: 10,
		total: 0,
		hasMore: true,
	})

	const [searchTerm, setSearchTerm] = useState<apiTypes.ChargeCodePageRequest>(
		{},
	)

	useEffect(() => {
		if (!isFetchingChargeCodes) {
			onSearchChargeCodes(0)
		}
	}, [searchTerm]) // eslint-disable-line react-hooks/exhaustive-deps

	const addChargeForm: IFormData<apiTypes.ChargeCodePageRequest> = {
		form: chargeCodeRequest,
		metadata: {
			chargeCode: { required: true },
			chargeCodeDescription: { required: true },
			chargeCodeType: {
				options: [
					{ value: 'customer', label: tString('customer', tPrefix) },
					{ value: 'provider', label: tString('provider', tPrefix) },
				],
			},
		},
		onUpdateForm: (field: string, value: any) => {
			const updatedFormData = l.cloneDeep(chargeCodeRequest)
			updatedFormData[field] = value
			setChargeCodeRequest(updatedFormData)
		},
		tPrefix,
	}

	const fetchChargeCodes = async (page?: number): Promise<void> => {
		setIsFetchingChargeCodes(true)

		const result = await apiCharges.fetchAllChargeCodes(
			{
				take: 10,
				skip: page * 10,
			},
			true,
		)

		if (result.error) {
			sosToast.sendApiErrorResponseToast(result)
		} else {
			setPager({
				...pager,
				fetchingPageNumber: page,
				pageCount: result.data.pageCount,
				pageNumber: page,
				total: result.data.total,
			})

			setCharges(result.data.entities)
		}

		setIsFetchingChargeCodes(false)
	}

	const onSearchChargeCodes = (page?: number): void => {
		fireAndForget(async () => {
			setIsFetchingChargeCodes(true)

			const result = await apiCharges.fetchChargeCodes(
				{
					take: 10,
					skip: page * 10,
					sort: 'createdDate:asc',
					chargeCode: searchTerm.chargeCode,
					chargeCodeDescription: searchTerm.chargeCodeDescription,
					chargeCodeType: searchTerm.chargeCodeType,
				},
				true,
			)

			if (result.data) {
				setPager({
					...pager,
					fetchingPageNumber: page,
					pageCount: result.data.pageCount,
					pageNumber: page,
					total: result.data.total,
				})

				setCharges(result.data.entities)
			} else if (result.error) {
				sosToast.sendApiErrorResponseToast(result)
			}

			setIsFetchingChargeCodes(false)
		}, 'Searching Charge Codes')
	}

	const updateChargeCode = async (
		mode: 'add' | 'edit' | 'delete',
		chargeCodeId?: string,
		idx?: number,
	): Promise<void> => {
		setIsLoading(true)

		let chargePageResponse: IRequestState<apiTypes.ChargeCodePageResponse> = null

		if (mode === 'add') {
			chargePageResponse = await apiCharges.createChargeCode(
				chargeCodeBookId,
				chargeCodeRequest,
			)
		} else if (mode === 'edit') {
			chargePageResponse = await apiCharges.updateChargeCode(
				chargeCodeBookId,
				chargeCodeId,
				chargeCodeRequest,
			)
		} else if (mode === 'delete') {
			chargePageResponse = await apiCharges.deleteChargeCode(
				chargeCodeBookId,
				chargeCodeId,
			)
		}

		if (chargePageResponse.error) {
			sosToast.sendApiErrorResponseToast(chargePageResponse)
		} else {
			setIsFetchingChargeCodes(true)
			setTimeout(async () => {
				await fetchChargeCodes(0)
			}, 3000)
		}

		setIsLoading(false)
	}

	useEffect(() => {
		fireAndForget(async () => {
			await fetchChargeCodes(0)
		}, `Get ChargeCodes`)
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			{chargeCodeBookId === null ? (
				<div>{t('noLinkedChargeCodes', tPrefix)}</div>
			) : (
				<>
					<div className={classes.addChargeCodeButton}>
						<Button
							color={'green'}
							onClick={() => {
								setChargeCodeRequest(emptyChargeRequest)
								setIsAddingCharge(true)
								setRowToEdit(null)
							}}
							isDisabled={isAddingCharge}
							testId='add-chargecode-button'
						>
							{t('addChargeCode', tPrefix)}
						</Button>
					</div>
					<Row>
						<Col xs={12}>
							<Table bordered className={classes.chargeCodeCatalogTable}>
								<thead>
									<tr>
										<th>
											<Row>
												<Col xs={12}>{t('chargeCode', tPrefix)}</Col>
											</Row>
											<Spacer height={'4px'} />
											<Row>
												<Col xs={12}>
													<SearchInput
														testId='charge-code-catalog-search-charge-code'
														value={searchTerm.chargeCode || ''}
														placeholder={tString('searchChargeCode', tPrefix)}
														onChange={async (value: string) => {
															setSearchTerm({
																...searchTerm,
																chargeCode: value,
															})
														}}
														width='200px'
														className={classes.searchBox}
														readOnly={isFetchingChargeCodes}
													/>
												</Col>
											</Row>
										</th>
										<th>
											<Row>
												<Col xs={12}>{t('chargeDescription', tPrefix)}</Col>
											</Row>
											<Spacer height={'4px'} />
											<Row>
												<Col xs={12}>
													<SearchInput
														testId='charge-code-catalog-search-charge-description'
														value={searchTerm.chargeCodeDescription || ''}
														placeholder={tString(
															'searchChargeDescription',
															tPrefix,
														)}
														onChange={async (value: string) => {
															setSearchTerm({
																...searchTerm,
																chargeCodeDescription: value,
															})
														}}
														width='240px'
														className={classes.searchBox}
														readOnly={isFetchingChargeCodes}
													/>
												</Col>
											</Row>
										</th>
										<th>
											<Row>
												<Col xs={12}>{t('invoiceType', tPrefix)}</Col>
											</Row>
											<Spacer height={'4px'} />
											<Row>
												<Col xs={12}>
													<Autocomplete
														options={chargeCodeTypes.map(
															(chargeCodeType): ISelectOptions => ({
																value: l.startCase(l.toLower(chargeCodeType)),
																label: l.startCase(l.toLower(chargeCodeType)),
															}),
														)}
														onChange={async (
															value: 'customer' | 'provider' | 'both',
														) => {
															setSearchTerm({
																...searchTerm,
																chargeCodeType: value,
															})
														}}
														value={searchTerm.chargeCodeType}
														type='select'
														isClearable={false}
														allowEmptyValue={false}
														placeholder={tString(
															'selectChargeCodeType',
															tPrefix,
														)}
														width={'240px'}
														className={classes.selectBox}
														testId='charge-code-catalog-search-charge-type'
													/>
												</Col>
											</Row>
										</th>
										<th />
									</tr>
								</thead>
								<tbody>
									{isFetchingChargeCodes ? (
										<tr>
											<td colSpan={4}>
												<Center>
													<Loader isLoading={isFetchingChargeCodes} />
												</Center>
											</td>
										</tr>
									) : (
										l.map(charges, (charge, i) => {
											const chargeCodeData: IFormData<apiTypes.ChargeCodePageRequest> = {
												form: charge,

												metadata: {
													chargeCode: { required: true },
													chargeCodeDescription: { required: true },
													chargeCodeType: {
														options: ['customer', 'provider'],
													},
												},

												onUpdateForm: (field: string, value: any) => {
													const updatedCharges = l.cloneDeep(charges)
													const updatedCharge = l.cloneDeep(charge)
													updatedCharge[field] = value

													updatedCharges.splice(i, 1, updatedCharge)

													setChargeCodeRequest(updatedCharge)
													setCharges(updatedCharges)
												},

												tPrefix,
											}

											return (
												<tr key={i}>
													<td>
														<FormTextInput
															form={chargeCodeData.form}
															field={'chargeCode'}
															onUpdateForm={chargeCodeData.onUpdateForm}
															readOnly={i !== rowToEdit}
															testId={`chargecode-catalog-chargeCode-${i}`}
														/>
													</td>
													<td>
														<FormTextInput
															form={chargeCodeData.form}
															field={'chargeCodeDescription'}
															onUpdateForm={chargeCodeData.onUpdateForm}
															readOnly={i !== rowToEdit}
															testId={`chargecode-catalog-chargeCodeDescription-${i}`}
														/>
													</td>
													<td>
														<FormStackedSelect
															formData={chargeCodeData}
															field={'chargeCodeType'}
															hideOptional={true}
															readOnly={i !== rowToEdit}
															className={classes.noLabel}
															width={'110px'}
															testId={`chargecode-catalog-chargeCodeType-${i}`}
															tPrefix={tPrefix}
														/>
													</td>
													<td style={{ whiteSpace: 'nowrap' }}>
														<IconButton
															icon={
																i === rowToEdit
																	? solidIcons.faSave
																	: solidIcons.faPencilAlt
															}
															color={'black'}
															onClick={async () => {
																if (i === rowToEdit) {
																	await updateChargeCode('edit', charge.id, i)
																	setChargeCodeRequest(emptyChargeRequest)
																	setRowToEdit(null)
																} else {
																	setChargeCodeRequest(charge)
																	setRowToEdit(i)
																}
															}}
															spin={i === rowToEdit && isLoading}
															testId={'chargecode-catalog-edit-save'}
														></IconButton>

														<IconButton
															icon={solidIcons.faTrash}
															color={'black'}
															onClick={async () => {
																if (rowToEdit !== null) {
																	setRowToEdit(null)
																} else {
																	setRowToDelete(i)
																	setRowToEdit(null)
																	await updateChargeCode('delete', charge.id, i)
																}
															}}
															testId={'chargecode-catalog-delete'}
															spin={i === rowToDelete && isLoading}
														></IconButton>
													</td>
												</tr>
											)
										})
									)}
								</tbody>
							</Table>
						</Col>
					</Row>
					<ElasticSearchPager
						pager={pager}
						onClickPage={async (pageNum: number) => {
							await fetchChargeCodes(pageNum)
						}}
					/>
				</>
			)}

			<Modal
				content={() => (
					<div
						className={classes.addChargeCodeCatalogModal}
						data-testid={'chargecode-catalog-add-modal'}
					>
						<Spacer height='10px' />
						<FormStackedTextInput
							formData={addChargeForm}
							field={'chargeCode'}
							testId={'chargecode-catalog-add-modal-chargeCode'}
						/>
						<Spacer height='10px' />
						<FormStackedTextInput
							formData={addChargeForm}
							field={'chargeCodeDescription'}
							testId={'chargecode-catalog-add-modal-chargeCodeDescription'}
						/>
						<Spacer height='10px' />
						<FormStackedPillSwitch
							formData={addChargeForm}
							field={'chargeCodeType'}
							label={t('chargeCodeType', tPrefix)}
							hideOptional={true}
							testId={'chargecode-catalog-add-modal-chargeCodeType'}
						/>

						<Spacer height='30px' />
						<AlignRight>
							<OkCancelButtons
								isValid={true}
								ok={t('ok', tPrefix)}
								okColor={'green'}
								okTestId={'chargecode-catalog-add-modal-ok'}
								isSpinning={isLoading}
								onOk={async () => {
									await updateChargeCode('add')

									setIsAddingCharge(false)
								}}
								cancel={t('cancel', tPrefix)}
								onCancel={() => setIsAddingCharge(false)}
								cancelTestId={'chargecode-catalog-add-modal-cancel'}
							/>
						</AlignRight>
					</div>
				)}
				isOpen={isAddingCharge}
				title={t('addChargeCode', tPrefix)}
				closeModalX={true}
				onModalClose={() => setIsAddingCharge(false)}
			/>
		</>
	)
}
