import { FC } from 'app/FunctionalComponent'
import { Toast, ToastState } from 'common/components/toast'
import React, { RefObject } from 'react'
import { AngryRedBox } from 'ui/components/common/angry-red-box'
import { Button } from 'ui/components/common/button'
import { LinkButton } from 'ui/components/common/link'
import { t, tString } from 'ui/components/i18n/i18n'
import { FlexRow } from 'ui/components/layout/flexRow'
import { l } from 'ui/lib/lodashImports'
import { Colors, getTextColor } from 'ui/theme/theme'
import classes from './OkCancelButtons.module.scss'

export const OkCancelButtons: FC = (props: {
	refProp?: RefObject<HTMLButtonElement>
	ok?: React.ReactNode
	onOk?: () => void
	okColor?: Colors
	cancel?: React.ReactNode
	onCancel?: () => void
	errors?: string | string[]
	isSpinning?: boolean
	isValid?: boolean
	okTestId?: string
	cancelTestId?: string
	okClass?: string
	inlineErrors?: boolean
}) => {
	let errors = []
	if (l.isArray(props.errors)) {
		errors = props.errors
	} else if (props.errors) {
		errors = [props.errors]
	}
	errors = l.compact(errors)
	const ToastBody = ({ errors }): React.ReactElement => (
		<div data-testid='validation-errors'>
			{l.map(errors, (c, cIdx) => (
				<div key={cIdx}>{'- ' + c}</div>
			))}
		</div>
	)

	const errorToast: ToastState = {
		type: 'danger',
		header: tString('validationError', 'validation.error'),
		body: <ToastBody errors={errors} />,
	}

	return (
		<div>
			<div className={classes.container}>
				<FlexRow verticalAlign='center'>
					{(props.onCancel || props.cancel) && (
						<div className={classes.cancel}>
							<LinkButton
								onClick={props.onCancel}
								testId={props.cancelTestId}
								className={getTextColor('linkBlue')}
							>
								{props.cancel || t('cancel', 'common')}
							</LinkButton>
						</div>
					)}
					{(props.onOk || props.ok) && (
						<div className={classes.ok}>
							<Button
								refProp={props.refProp}
								color={props.isValid ? props.okColor : 'gray'}
								onClick={props.isValid ? props.onOk : () => {}}
								isSpinning={props.isSpinning}
								testId={props.okTestId}
								className={props.okClass}
								isDisabled={!props.isValid}
							>
								{props.ok || t('ok', 'common')}
							</Button>
						</div>
					)}
					{errors.length > 0 && (
						<>
							{props.inlineErrors ? (
								<AngryRedBox dataTestId={'ok-cancel-errors'}>
									<ToastBody errors={errors} />
								</AngryRedBox>
							) : (
								<Toast toast={errorToast} />
							)}
						</>
					)}
				</FlexRow>
			</div>
		</div>
	)
}
