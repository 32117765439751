import { apiCommon } from 'ui/api'
import { IRequestState } from 'ui/api/requestState'
import { sosI18n } from 'ui/components/i18n/state'
import { TLanguageCode } from 'ui/components/i18n/state/sosI18n'
import * as formTranslate from 'ui/forms/formTranslate'
import { l } from 'ui/lib/lodashImports'
import { sos2 } from 'ui/lib/state/sos2'

const translatableLanguages: Array<TLanguageCode> = [
	'en',
	// 'en-GB',
	// 'es',
	// 'es-MX',
	// 'pirate',
]
export { translatableLanguages }

export type TInternationalText = { [key in TLanguageCode]?: string }

export interface IStateTranslate {
	isTranslateModalOpen: boolean
	translateForm: formTranslate.IFormTranslate
	requestSaving: IRequestState<any>
	untranslatedStrings: string[]
	untranslatedStringsIndex: 0
}

export const getSos = sos2.createLazySos2<IStateTranslate>(
	'sosTranslate',
	3,
	() => ({
		isTranslateModalOpen: { default: false },
		translateForm: {
			default: {
				i18nKey: '',
				en: '',
				// es: '',
				// 'es-MX': '',
				// pirate: '',
			},
		},
		requestSaving: { default: {} },
		untranslatedStrings: { default: [] },
		untranslatedStringsIndex: { default: 0 },
	}),
)

export function openTranslateModal(i18nKey: string): void {
	const translation = sosI18n.getTranslationForKey(i18nKey)

	getSos().change((ds) => {
		ds.requestSaving = {}
		ds.isTranslateModalOpen = true
		// Set form info from data
		ds.translateForm.i18nKey = i18nKey
		ds.translateForm.en = translation.en || ''

		if (!ds.translateForm.en) {
			// Get a good basic value
			const lastDot = i18nKey.lastIndexOf('.')
			if (lastDot > -1) {
				const lastKey = i18nKey.substring(lastDot, i18nKey.length)
				ds.translateForm.en = l.startCase(lastKey)
			}
		}

		// ds.translateForm.es = translation.es || ''
		// ds.translateForm['es-MX'] = translation['es-MX'] || ''
		// ds.translateForm.pirate = translation.pirate || ''
	})
}

export async function saveTranslation(): Promise<boolean> {
	// Post form to translate
	const state = getSos().getState()
	const result = await apiCommon.externalFetch(
		(rs) => {
			getSos().change((ds) => {
				ds.requestSaving = rs
			})
		},
		'http://localhost:4218/key',
		{
			method: 'POST',
			data: state.translateForm,
		},
	)

	return result.error == null
}

export function closeTranslateModal(): void {
	getSos().change((ds) => {
		ds.isTranslateModalOpen = false
	})
}

export function moveToNextTranslation(reOpen: boolean): void {
	// Recheck translations
	getSos().change((ds) => {
		ds.untranslatedStringsIndex++

		const newUntranslatedStrings = []
		l.forEach(ds.untranslatedStrings, (c) => {
			if (!sosI18n.getTranslationForKey(c, false).en) {
				newUntranslatedStrings.push(c)
			}
		})
		ds.untranslatedStringsIndex -=
			ds.untranslatedStrings.length - newUntranslatedStrings.length
		ds.untranslatedStrings = newUntranslatedStrings

		if (ds.untranslatedStringsIndex >= ds.untranslatedStrings.length) {
			ds.untranslatedStringsIndex = 0
		}
	})

	const state = getSos().getState()
	if (state.untranslatedStrings && reOpen) {
		openTranslateModal(
			state.untranslatedStrings[state.untranslatedStringsIndex],
		)
	} else {
		closeTranslateModal()
	}
}

export function updateTranslateForm(
	key: keyof formTranslate.IFormTranslate,
	newVal: string,
): void {
	getSos().change((ds) => {
		ds.translateForm[key] = newVal
	})
}

export function addUntranslated(i18nKey: string): void {
	// Only add this if we don't know about it yet
	const state = getSos().getState()
	if (state.untranslatedStrings.indexOf(i18nKey) === -1) {
		getSos().change((ds) => {
			ds.untranslatedStrings.push(i18nKey)
			ds.untranslatedStrings = l.sortBy(
				l.uniq(ds.untranslatedStrings),
				(c) => c,
			)
		})
	}
}
