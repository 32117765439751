import * as apiTypes from 'ui/api/apiTypes'
import {
	getBottomLevelContainers,
	getGoodsFromContainer,
	pullFromGoods,
} from 'ui/lib/shipments/shipmentUtils'
import { l } from 'ui/lib/lodashImports'
import { _idx } from 'ui/lib'
import { INewQuoteCommoditiesForm } from 'ui/pages/new-quote/INewQuoteCommoditiesForm'

export const constructCommoditiesForm = (
	shipment: apiTypes.ShipmentResponse,
): INewQuoteCommoditiesForm[] => {
	const containers = getBottomLevelContainers(shipment)
	return l.map(containers, (c) => {
		const goods = getGoodsFromContainer(c)
		const form: INewQuoteCommoditiesForm = {
			id: c.id,
			count: '' + c.count,
			class: c.class,
			length: '' + c.length,
			width: '' + c.width,
			height: '' + c.height,
			packedWeight: '' + (c.packedWeight || 0),
			description: c.description,
			type: c.type,
			piecesCount: '',
			piecesType: null,
			piecesLength: '',
			piecesWidth: '',
			piecesHeight: '',
			hazmat: '' + l.some(goods, (g) => g.hazmat),
			// stackable: c.stackable, // TODO: Not implemented in API
			nmfc: pullFromGoods(goods, 'nmfcCode'),
			nmfcSub: pullFromGoods(goods, 'nmfcSubCode'),
			hazmatClass: pullFromGoods(goods, 'hazmatClass'),
			unNumber: pullFromGoods(goods, 'hazmatUnCode'),
			packagingGroup: pullFromGoods(
				goods,
				'hazmatPackingGroup',
			) as apiTypes.Good['hazmatPackingGroup'],
			// bol: c.bol, // TODO: pull from Payload
			so: pullFromGoods(goods, 'salesOrder'),
			po: pullFromGoods(goods, 'purchaseOrder'),
			notes: pullFromGoods(goods, 'description'),
		}
		if (_idx(() => c.containers[0])) {
			form.piecesCount += c.containers[0].count
			form.piecesType = c.containers[0].type
			form.piecesLength += c.containers[0].length
			form.piecesWidth += c.containers[0].width
			form.piecesHeight += c.containers[0].height
		}
		return form
	})
}

export const constructCommoditiesFormFromParcels = (
	shipment: apiTypes.ShipmentResponse,
): INewQuoteCommoditiesForm[] => {
	const parcelContainers = l.flatten(
		l.map(shipment.payloads, (load) =>
			load.containers.map((inner) =>
				inner.containers.filter(
					(container) => container.freightLevel === 'parcel',
				),
			),
		),
	)
	return l.flatten(parcelContainers).map(
		(parcel) =>
			({
				reference1: parcel.labelReferenceValue1,
				reference2: parcel.labelReferenceValue2,
				declaredValue: parcel.declaredValue?.toString(),
				length: parcel.length?.toString(),
				width: parcel.width?.toString(),
				height: parcel.height?.toString(),
				count: parcel.count?.toString(),
				packedWeight: parcel.packedWeight?.toString(),
			} as INewQuoteCommoditiesForm),
	)
}

// const getCommoditiesFromShipment = (
// 	shipment: apiTypes.ShipmentResponse,
// ): INewQuoteCommoditiesForm[] => {
// 	const isParcel = (shipment.shippingMode || shipment.mode) === 'parcel'
// 	const parcels = constructCommoditiesFormFromParcels(shipment)
// 	const commodities = shipment.payloads.map((payload) =>
// 		payload.containers?.map((outer) =>
// 			outer.containers?.map((inner) => {
// 				return {
// 					id: null,
// 					count: isParcel ? String(inner.count) : String(outer.count),
// 					class: outer.class,
// 					length: String(outer.length),
// 					width: String(outer.width),
// 					height: String(outer.height),
// 					packedWeight: isParcel
// 						? String(inner.packedWeight)
// 						: String(outer.packedWeight),
// 					description: outer.description,
// 					type: outer.type,
// 					piecesCount: String(inner?.count || '1'),
// 					piecesType: inner?.type,
// 					piecesLength: String(inner?.length || ''),
// 					piecesWidth: String(inner?.width || ''),
// 					piecesHeight: String(inner?.height || ''),
// 					hazmat: null,
// 					nmfcSub: null,
// 					hazmatClass: null,
// 					unNumber: null,
// 					packagingGroup: null,
// 					bol: null,
// 					so: outer.salesOrders?.join(', '),
// 					po: outer.purchaseOrders?.join(', '),
// 					notes: null,
// 				}
// 			}),
// 		),
// 	)

// 	return (l.flatMap(commodities[0]) as INewQuoteCommoditiesForm[]) || []
// }
