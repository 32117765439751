import { FC } from 'app/FunctionalComponent'
import React, { useEffect, useState } from 'react'
import { apiAddressBook } from 'ui/api'
import { sosToast, ToastState } from 'ui/common/components/toast'
import { ISelectOptions } from 'ui/components/common/select'
import { SmallButton } from 'ui/components/common/small-button'
import {
	FormStackedLayout,
	FormStackedSelect,
	FormStackedTextInput,
	IFormData,
	IFormMetadataCollection,
} from 'ui/components/form'
import { useOnce } from 'ui/components/hooks'
import { tAddressType } from 'ui/components/i18n/commonTranslations'
import { t, tString } from 'ui/components/i18n/i18n'
import { FlexRow } from 'ui/components/layout/flexRow'
import { validateAddress } from 'ui/pages/new-quote/state/newQuoteUtils/validateShipment/shipmentValidations'
import { addressTypes } from 'ui/data'
import {
	getCountryNameAndCodeList,
	getRegionsForCountry,
} from 'ui/data/countryAndRegionUtils'
import { l } from 'ui/lib/lodashImports'
import { UINewQuoteAddressForm } from '../../NewQuoteParcelAddress'

const tPrefix = 'page.newQuote.parcel.address'

export const CustomAddressForm: FC = (props: {
	addressForm: UINewQuoteAddressForm
	testIdPrefix: string
	onUpdate: (form: any) => void
	mode: 'pickupDelivery' | 'billTo'
	isReadOnly?: boolean
}) => {
	const { testIdPrefix, addressForm, onUpdate, isReadOnly } = props

	const [regions, setRegions] = useState([])
	const [isSubmitting, setIsSubmitting] = useState(false)

	const addressFormMetaData: IFormMetadataCollection<UINewQuoteAddressForm> = {
		id: {},
		name: { label: 'contactName' },
		street1: {},
		street2: {},
		city: {},
		state: {},
		zip: { required: true },
		country: {
			options: getCountryNameAndCodeList().map((countryData) => {
				return {
					label: countryData.countryName,
					value: countryData.countryCode,
				}
			}),
		},
		addressType: {
			options: addressTypes.map((addressType) => {
				return { label: tAddressType(addressType), value: addressType }
			}),
		},
		company: { label: 'locationName' },
		phone: {},
		email: {},
		locationId: {},
	}

	if (props.mode === 'billTo') {
		addressFormMetaData.street1.required = true
		addressFormMetaData.city.required = true
		addressFormMetaData.state.required = true
		addressFormMetaData.country.required = true
		addressFormMetaData.addressType.required = true
	}

	useEffect(() => {
		if (addressForm.country) {
			setRegions(getRegionsForCountry(addressForm.country, true, true))
		}
	}, [addressForm.country])

	const formData: IFormData<UINewQuoteAddressForm> = {
		form: addressForm,
		metadata: addressFormMetaData,
		onUpdateForm: (field: string, value: any) => {
			const updatedFormData = l.cloneDeep(addressForm)
			updatedFormData[field] = value

			// Reset the state / region if the country has changed
			if (field === 'country' && addressForm.country !== value) {
				const newStateOptions: ISelectOptions[] = getRegionsForCountry(
					updatedFormData.country,
					true,
					true,
				) as ISelectOptions[]

				setRegions(newStateOptions)

				if (
					!l.find(newStateOptions, (stateOption) => {
						return stateOption.value === addressForm.state
					})
				) {
					updatedFormData.state = ''
				}
			}
			onUpdate(updatedFormData)
		},
		tPrefix,
	}

	const isValid = validateAddress(addressForm).length === 0

	return (
		<FormStackedLayout>
			<FlexRow>
				<div>
					<FormStackedTextInput
						formData={formData}
						field={'company'}
						testId={`new-quote-parcel-${testIdPrefix}-address-name`}
						readOnly={isReadOnly}
					/>
					<FormStackedTextInput
						formData={formData}
						field={'street1'}
						testId={`new-quote-parcel-${testIdPrefix}-address-street1`}
						readOnly={isReadOnly}
					/>
					<FormStackedTextInput
						formData={formData}
						field={'street2'}
						testId={`new-quote-parcel-${testIdPrefix}-address-street2`}
						readOnly={isReadOnly}
					/>
					<FormStackedTextInput
						formData={formData}
						field={'city'}
						testId={`new-quote-parcel-${testIdPrefix}-address-city`}
						readOnly={isReadOnly}
					/>
					<FormStackedSelect
						formData={formData}
						field={'country'}
						testId={`new-quote-parcel-${testIdPrefix}-address-country`}
						numListOptionsBeforeScroll={5}
						readOnly={isReadOnly}
					/>
					<FormStackedSelect
						formData={formData}
						field={'state'}
						options={regions}
						testId={`new-quote-parcel-${testIdPrefix}-address-state`}
						numListOptionsBeforeScroll={5}
						readOnly={isReadOnly}
					/>
					<FormStackedTextInput
						formData={formData}
						field={'zip'}
						testId={`new-quote-parcel-${testIdPrefix}-address-zip`}
						readOnly={isReadOnly}
					/>
				</div>
				<div>
					<FormStackedSelect
						formData={formData}
						field={'addressType'}
						testId={`new-quote-parcel-${testIdPrefix}-address-addressType`}
						numListOptionsBeforeScroll={5}
						readOnly={isReadOnly}
					/>
					<FormStackedTextInput
						formData={formData}
						field={'name'}
						testId={`new-quote-parcel-${testIdPrefix}-address-contactName`}
						readOnly={isReadOnly}
					/>
					<FormStackedTextInput
						formData={formData}
						field={'phone'}
						testId={`new-quote-parcel-${testIdPrefix}-address-phone`}
						readOnly={isReadOnly}
					/>
					<FormStackedTextInput
						formData={formData}
						field={'email'}
						testId={`new-quote-parcel-${testIdPrefix}-address-email`}
						readOnly={isReadOnly}
					/>
				</div>
			</FlexRow>
			<div>
				<SmallButton
					color={isValid ? 'blue' : 'gray'}
					isSpinning={isSubmitting}
					isDisabled={isReadOnly}
					onClick={async () => {
						setIsSubmitting(true)

						const toastOptions: ToastState = {
							header: tString('shared.address.toast.invalidAddress'),
							type: 'danger',
						}

						if (isValid) {
							const response = await apiAddressBook.getAddressBooks(() => {})

							if (response.error) {
								sosToast.sendApiErrorResponseToast(response.error)
							} else {
								const success = await apiAddressBook.updateAddressBookEntry(
									'upsert',
									response.data[0]?.id,
									{
										address: addressForm,
										id: addressForm.id || undefined,
									},
									() => {},
								)

								if (success) {
									toastOptions.header = tString(
										'shared.address.toast.addressSaved',
									)
									toastOptions.type = 'success'
								} else {
									toastOptions.header = tString('shared.address.toast.error')
									toastOptions.type = 'danger'
								}
							}
						}

						sosToast.sendToast(toastOptions)

						setIsSubmitting(false)
					}}
				>
					{t('shared.address.saveAddressBook')}
				</SmallButton>
			</div>
		</FormStackedLayout>
	)
}
