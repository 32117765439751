import React, { ReactElement, useState } from 'react'
import { apiTypes } from 'ui/api'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { t } from 'ui/components/i18n/i18n'
import { sosCustomerManagement } from '../..'
import Form from 'react-bootstrap/Form'

const tPrefix = 'page.customerManagement.paymentTerms'

export const PaymentTerms = (props: {
	paymentTermsForm: apiTypes.PaymentTerms
}): ReactElement => {
	const [net, setNet] = useState(props.paymentTermsForm.net)

	const onChange = async (field, value): Promise<void> => {
		sosCustomerManagement.updatePaymentTermsForm(field, value)

		setNet(value)
	}

	return (
		<div className='bootstrap-wrapper' data-testid='accounting-payment-terms'>
			<Row>
				<Col>
					<Form>
						<Form.Group controlId='paymentTermsPercentSelector'>
							<Form.Label>{t('net', tPrefix)}</Form.Label>
							<Form.Control
								type='number'
								onChange={(e): Promise<void> => onChange('net', e.target.value)}
								value={net}
								data-testid={`form-net`}
							/>
						</Form.Group>
					</Form>
				</Col>
			</Row>
		</div>
	)
}
