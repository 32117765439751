// obj is an object whose values are boolean (like shipment.paymentStatus in tms2)
// obj = {
// 	invoiced: false,
// 	paid: false,
// 	disputed: false,
// 	cleared_to_pay: true
// }
// here, the function would return 'cleared_to_pay'
export function booleanObjectFindTrueKey<T>(obj: T): keyof T {
	if (obj) {
		for (const [key, value] of Object.entries(obj)) {
			if (typeof value === 'boolean' && value) {
				return key as keyof T // do I like using "as" here? No. Do I have any other way to make typescript work? Also no.
			}
		}
	}

	return undefined
}
