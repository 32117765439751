import { apiCommon, apiTypes } from 'ui/api'
import { IRequestState } from 'ui/api/requestState'

export interface UIBrokerInvoiceUpdateRequest
	extends apiTypes.BrokerInvoiceUpdateRequest {
	invoiceDue?: string
}

export async function createBrokerInvoice(
	onProgress: (rs: IRequestState<apiTypes.BrokerInvoiceResponse>) => void,
	data: apiTypes.BrokerInvoiceCreateRequest,
): Promise<IRequestState<apiTypes.BrokerInvoiceResponse>> {
	const url = `invoicing/broker`
	const response = await apiCommon.apiFetch(
		onProgress,
		{
			method: 'POST',
			data,
		},
		url,
	)

	return response
}

export async function getBrokerInvoice(
	onProgress: (rs: IRequestState<apiTypes.BrokerInvoiceResponse>) => void,
	brokerInvoiceId: string,
): Promise<IRequestState<apiTypes.BrokerInvoiceResponse>> {
	const url = `invoicing/broker/${brokerInvoiceId}`
	const response = await apiCommon.apiFetch(
		onProgress,
		{
			method: 'GET',
		},
		url,
	)

	return response
}

export async function updateBrokerInvoice(
	onProgress: (rs: IRequestState<apiTypes.BrokerInvoiceResponse>) => void,
	brokerInvoiceId: string,
	data: UIBrokerInvoiceUpdateRequest,
): Promise<IRequestState<apiTypes.BrokerInvoiceResponse>> {
	const url = `invoicing/broker/${brokerInvoiceId}`
	const response = await apiCommon.apiFetch(
		onProgress,
		{
			method: 'PUT',
			data,
		},
		url,
	)

	return response
}

export async function deleteBrokerInvoice(
	onProgress: (rs: IRequestState<apiTypes.BrokerInvoiceResponse>) => void,
	brokerInvoiceId: string,
): Promise<IRequestState<apiTypes.BrokerInvoiceResponse>> {
	const url = `invoicing/broker/${brokerInvoiceId}`
	const response = await apiCommon.apiFetch(
		onProgress,
		{
			method: 'DELETE',
		},
		url,
	)

	return response
}

export async function createBrokerInvoiceCharge(
	brokerInvoiceId: string,
	data: apiTypes.BrokerInvoiceChargeRequest,
): Promise<IRequestState<apiTypes.BrokerInvoiceChargeResponse>> {
	const url = `invoicing/broker/${brokerInvoiceId}/charges`

	return await apiCommon.apiFetch(() => {}, { method: 'POST', data }, url)
}

export async function getBrokerInvoiceCharge(
	brokerInvoiceId: string,
	chargeId: string,
): Promise<IRequestState<apiTypes.BrokerInvoiceChargeResponse>> {
	const url = `invoicing/broker/${brokerInvoiceId}/charges/${chargeId}`

	return await apiCommon.apiFetch(() => {}, { method: 'GET' }, url)
}

export async function updateBrokerInvoiceCharge(
	brokerInvoiceId: string,
	chargeId: string,
	data: apiTypes.BrokerInvoiceChargeRequest,
): Promise<IRequestState<apiTypes.BrokerInvoiceChargeResponse>> {
	const url = `invoicing/broker/${brokerInvoiceId}/charges/${chargeId}`

	return await apiCommon.apiFetch(() => {}, { method: 'PUT', data }, url)
}

export async function deleteBrokerInvoiceCharge(
	brokerInvoiceId: string,
	chargeId: string,
): Promise<IRequestState<apiTypes.BrokerInvoiceChargeResponse>> {
	const url = `invoicing/broker/${brokerInvoiceId}/charges/${chargeId}`

	return await apiCommon.apiFetch(() => {}, { method: 'DELETE' }, url)
}

export async function updateCustomerInvoiceNotes(
	onProgress: (rs: IRequestState<apiTypes.BrokerInvoiceResponse>) => void,
	brokerInvoiceId: string,
	data: apiTypes.BrokerInvoiceUpdateRequest,
): Promise<IRequestState<apiTypes.BrokerInvoiceResponse>> {
	const url = `invoicing/broker/${brokerInvoiceId}`
	const response = await apiCommon.apiFetch(
		onProgress,
		{
			method: 'PUT',
			data,
		},
		url,
	)

	return response
}

export async function fetchBrokerInvoicePDF(
	brokerInvoiceId: string,
): Promise<any> {
	const url = `invoicing/invoice/broker/${brokerInvoiceId}/generateCustomerInvoice`
	return apiCommon.apiFetch(
		null,
		{
			method: 'POST',
			headers: { 'Content-Type': 'application/pdf' },
			responseType: 'blob',
			transform: false,
		},
		url,
	)
}
