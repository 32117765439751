import { l } from 'ui/lib/lodashImports'

import { log } from '../log'
import {
	IElasticSearchSearch,
	IQueryStringQuery,
} from 'ui/lib/elasticSearch/elasticSearchTypes'
import {
	queryStringAndFormatter,
	queryStringOrFormatter,
} from 'ui/pages/shipments/state/lib'
import { parse, stringify } from 'parenthesis'
import { stringExists } from 'ui/lib/misc'
import {
	addParensIfNotThereAlready,
	containsParens,
} from 'ui/lib/strings/parensUtils'
import { queryToWildcardAndSearch } from 'ui/lib/elasticSearch/queryToWildcardAndSearch'
import { ensureSingleSpace } from 'ui/lib/misc/ensureSingleSpace'

// TODO:unit tests

export function elasticSearchBuilder(): any {
	let query = ''

	const addAnd = (): void => {
		if (query) {
			query += ' AND '
		}
	}

	const builder = {
		toQuery: () => query,
		toString: () => query,
		log: (msg) => {
			log('elasticSearchBuilder', msg, query)
			return builder
		},
		and: (field: string, value: string | number) => {
			addAnd()
			query += `(${escapeField(field)}:${escapeValue(value)})`
			return builder
		},
		andNot: (field: string, value: string | number) => {
			addAnd()
			query += `(NOT ${escapeField(field)}:${escapeValue(value)})`
			return builder
		},
		andIn: (field: string, values: string[]) => {
			if (!values || values.length === 0) {
				return builder
			}
			addAnd()
			query += '('
			query += l.join(
				l.map(values, (c) => `${escapeField(field)}:${escapeValue(c)}`),
				' OR ',
			)
			query += ')'
			return builder
		},
		andOrAnd: (values: [string, string | number][][]) => {
			addAnd()
			query += '('
			query += l.join(
				l.map(values, (andOrValue) => {
					let andClause = '('
					andClause += l.join(
						l.map(
							andOrValue,
							(c) => `${escapeField(c[0])}:${escapeValue(c[1])}`,
						),
						' AND ',
					)
					andClause += ')'
					return andClause
				}),
				' OR ',
			)
			query += ')'
		},
		andOr: (values: [string, string | number][]) => {
			addAnd()
			query += '('
			query += l.join(
				l.map(values, (c) => `${escapeField(c[0])}:${escapeValue(c[1])}`),
				' OR ',
			)
			query += ')'
			return builder
		},
		andRaw: (raw: string) => {
			addAnd()
			query += raw
			return builder
		},
		andRange: (
			field: string,
			value1: string | number,
			value2: string | number,
		) => {
			addAnd()
			query += `(${escapeField(field)}:[${escapeValue(value1)} TO ${escapeValue(
				value2,
			)}])`
			return builder
		},
	}
	return builder
}

export function escapeField(field: string): string {
	// TODO: actually escape
	return field
}

export function escapeValue(value: string | number): string {
	// TODO: actually escape
	return '' + value
}

export function and(query: string, field: string, value: string): string {
	return query
}

/*
	Supporting either 'AND' or 'OR', not both. If both are present, the 'AND' will be treated as any other search string.
*/
export const processSearchString = (
	qs: string,
	wildcardSearch = false,
): string => {
	let processedQs = ensureSingleSpace(qs.trim())

	if (wildcardSearch) {
		return processedQs
	} else {
		const _refQs = processedQs
		processedQs = queryStringOrFormatter(processedQs)
		// a check to prevent the 'and' parser if the 'or' parser has already parsed
		if (processedQs === _refQs) {
			processedQs = queryStringAndFormatter(processedQs)
		}

		processedQs = queryToWildcardAndSearch(processedQs, [' AND ', ' OR '])
		return processedQs ? `(${processedQs})` : processedQs
	}
}

export function buildQueryStringQuery(
	qs: string,
	qsSearchFields: string[],
): string {
	const parseArray = (parenArray: Array<[] | string>): any => {
		return l.map(parenArray, (c) => {
			if (l.isArray(c)) {
				return parseArray(c)
			}
			if (!stringExists(c)) {
				return
			}
			if (containsParens(c)) {
				return c
			}
			return l.join(
				l.map(qsSearchFields, (d) => `${d}:${addParensIfNotThereAlready(c)}`),
				' OR ',
			)
		})
	}

	if (stringExists(qs)) {
		return stringify(parseArray(parse(qs))) || qs
	}
}

export function buildElasticSearchSearch(
	queries: IQueryStringQuery[],
	sorts?: string,
): IElasticSearchSearch {
	return {
		sort: sorts,
		query: {
			bool: {
				filter: queries,
			},
		},
	}
}
