import React, { useState } from 'react'
import { apiUser } from 'ui/api'
import { TypeaheadOption } from 'ui/common/components/typeahead'
import { SalesAgentSelector } from 'ui/components/broker/sales-agent-selector'
import { t } from 'ui/components/i18n/i18n'
import { l } from 'ui/lib/lodashImports'
import { Spacer } from 'ui/components/layout/spacer'
import { LocationSelector } from 'ui/components/shared/location-selector'
import { FC } from 'ui/FunctionalComponent'
import { sosUser } from 'ui/state'
import { sos2 } from 'ui/lib/state/sos2'
import { sosCustomerManagement } from '../..'
import { watch } from 'ui/components/hooks'
import { fireAndForget } from 'ui/lib/async'
import * as classes from './BrokerAssignments.module.scss'

export const BrokerAssignments: FC = (props: {
	brokerLocationId: string
	brokerLocationName: string
	tPrefix: string
}) => {
	const stateUser = sos2.useSubscription(sosUser.getSos())

	const tPrefixBrokerAssignments = props.tPrefix + '.brokerAssignments'

	const [defaultUser, setDefaultUser] = useState<TypeaheadOption>(null)
	const [showErrorLabel, setShowErrorLabel] = useState<boolean>(false)

	const getUser = async (): Promise<void> => {
		const usersResponse = await apiUser.getUsersForCompany(
			() => {},
			true,
			100,
			0,
		)

		if (usersResponse.data) {
			if (!l.isNil(stateUser?.selectedClientConfig?.users)) {
				const currentUser = l.find(
					usersResponse.data,
					(user) =>
						user.id === stateUser?.selectedClientConfig?.users[0]?.userId,
				)

				setDefaultUser({
					value: currentUser.id,
					label: currentUser.username || currentUser.id,
				})
			}
			setShowErrorLabel(false)
		} else if (usersResponse.error.match('403')) {
			setShowErrorLabel(true)
		}
	}

	watch(() => {
		fireAndForget(async () => {
			await getUser()
		}, 'Fetch users for company')
	}, [stateUser.selectedClientConfig?.id])

	return (
		<div data-testid='broker-assignments-tab'>
			{t('assignedBrokerLocation', tPrefixBrokerAssignments)}
			<Spacer />
			<LocationSelector
				onChange={async (selected: TypeaheadOption) => {
					if (selected) {
						sosCustomerManagement.setBrokerLocation(selected)
					}
				}}
				currentlySelectedLocation={{
					value: props.brokerLocationId,
					label: props.brokerLocationName,
				}}
				onlyShowLeafLocations={false}
				getBrokerLocations={true}
			/>

			<Spacer height={'15px'} />

			{t('assignedSalesAgent', tPrefixBrokerAssignments)}
			<Spacer />
			<SalesAgentSelector
				onChange={async (selected: TypeaheadOption) => {
					if (selected) {
						sosCustomerManagement.setSalesAgent({
							userId: selected.value,
							userType: 'salesAgent',
						})
					}
				}}
				onError={(usersResponse) => {
					if (usersResponse.error.match('403')) {
						setShowErrorLabel(true)
					}
				}}
				selectedSalesAgent={defaultUser}
				disabled={showErrorLabel}
				tPrefix={tPrefixBrokerAssignments}
			/>
			{showErrorLabel && (
				<div
					className={classes.salesAgentErrorLabel}
					data-testid={'sales-agent-selector-error-label'}
				>
					{t('unauthorizedErrorMessage', tPrefixBrokerAssignments)}
				</div>
			)}
		</div>
	)
}
