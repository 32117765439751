import React, { useState } from 'react'
import { Button } from 'ui/components/common/button/Button'
import { Modal } from 'ui/components/common/modal/Modal'
import { OkCancelButtons } from 'ui/components/common/okCancelButtons/OkCancelButtons'
import { t, tString } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight/AlignRight'

const tPrefix = 'page.shipmentProfile.broker.shipmentInteractionButtons'
interface CancelShipmentButtonProps {
	onCancel: () => void
	isSpinning: boolean
}
export const CancelShipmentButton: React.FC<CancelShipmentButtonProps> = ({
	onCancel,
	isSpinning,
}) => {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
	return (
		<>
			<Button
				testId={'cancel-shipment-button'}
				color='red'
				onClick={() => {
					setIsModalOpen(true)
				}}
				isSpinning={isSpinning}
			>
				{t('cancelShipment', tPrefix)}
			</Button>
			<Modal
				title={tString('notice', tPrefix)}
				content={() => (
					<div data-testid={'cancel-shipment-modal'}>
						<div className='bootstrap-wrapper'>
							<div className='pb-4 pt-4 px-5 font-weight-bold text-center'>
								{tString('askCancelShipment', tPrefix)}
							</div>
						</div>
						<AlignRight>
							<OkCancelButtons
								isValid={true}
								ok={tString('yes', tPrefix)}
								okColor='green'
								onOk={() => {
									onCancel()
									setIsModalOpen(false)
								}}
								cancel={tString('no', tPrefix)}
								onCancel={() => {
									setIsModalOpen(false)
								}}
								okTestId={'cancel-shipment-modal-yes-button'}
								cancelTestId={'cancel-shipment-modal-no-button'}
							></OkCancelButtons>
						</AlignRight>
					</div>
				)}
				isOpen={isModalOpen}
				onModalClose={() => {
					setIsModalOpen(false)
				}}
				closeModalX={true}
			/>
		</>
	)
}
