import { sosToast } from 'common/components/toast'
import { apiProviders, apiTypes } from 'ui/api'
import { IRequestState } from 'ui/api/requestState'
import { sosRouter2 } from 'ui/components/common/router'

export const providerDetailsPageInfo = {
	url: '/providers/:providerId',
	params: {
		providerId: {
			name: 'providerId',
		},
	},
	queries: {},
}

export const getProviderDetailsPageInfo = (): {
	providerId: string
} => {
	const providerId = sosRouter2.getUrlParam(
		providerDetailsPageInfo,
		providerDetailsPageInfo.params.providerId,
	)

	return {
		providerId,
	}
}

export const fetchProvider = async (): Promise<apiTypes.ProviderResponse> => {
	const { providerId } = getProviderDetailsPageInfo()
	const providerResponse: IRequestState<apiTypes.ProviderResponse> = await apiProviders.getProvider(
		() => {},
		providerId,
	)

	if (providerResponse.data) {
		return providerResponse.data
	} else if (providerResponse.error) {
		sosToast.sendApiErrorResponseToast(providerResponse)
	}
}
