import { apiTypes } from 'ui/api'
import { ndash } from 'ui/components/common'
import {
	documentExists,
	getDocument,
} from 'ui/components/common/router/windowUtils'
import { ISelectOptions } from 'ui/components/common/select'
import { tCurrency, tString } from 'ui/components/i18n/i18n'
import { fireAndForget } from 'ui/lib/async'
import { l } from 'ui/lib/lodashImports'
import { logLevel } from 'ui/lib/log/log'
import {
	IShipmentListRequestQuery,
	tms2_providerPortal,
} from 'ui/lib/tms2_providerPortal'
import {
	defaultProviderPortalCellValue,
	TPaymentStatusFilter,
	TRenderInvoiceStatusKey,
} from 'ui/pages/provider-portal/providerPortalTypes'
import { createSos } from './secretaryOfState'
import * as utils from './sosProviderPortalInvoices_utils'

const paymentStatusFilterToTms2paymentStatus: {
	[k in TPaymentStatusFilter]: string[]
} = {
	all: [
		'waiting_on_invoice',
		'cleared_to_pay',
		'invoice_by_clearview_pending',
		'exceptions',
		'disputed',
		'invoiced_by_clearview',
		'paid_to_clearview',
		'paid_to_provider',
	],
	waitingOnInvoice: ['waiting_on_invoice'],
	clearedToPay: ['cleared_to_pay', 'invoice_by_clearview_pending'],
	undergoingAudit: ['exceptions'],
	disputed: ['disputed'],
	fundingRequested: ['invoiced_by_clearview'],
	fundingReceived: ['paid_to_clearview'],
	paid: ['paid_to_provider'], //  'paid_by_third_party', 'paid_prior_to_clearview'],
}

interface IStateProviderPortalMyInvoices {
	searchFor: string
	shipmentDocuments: any
	shipments: any
	invoicesReceivable: any
	invoicesPayable: any
	disputes: any
	isLoading: boolean
	paymentStatusFilter: TPaymentStatusFilter
	pages: number
	currentPage: number
	hasMorePages: boolean
	customers: ISelectOptions[]
	currentCustomer?: string
	shipmentsLastFetched?: number
	isExporting: boolean
}

const initialState: IStateProviderPortalMyInvoices = {
	searchFor: '',
	shipmentDocuments: null,
	shipments: null,
	invoicesReceivable: null,
	invoicesPayable: null,
	disputes: null,
	isLoading: false,
	paymentStatusFilter: 'all',
	pages: 1,
	currentPage: 0,
	hasMorePages: true,
	customers: [
		{
			t: 'page.providerPortal.invoices.noCustomers',
			value: '',
		},
	],
	isExporting: false,
}

export interface IExportInvoiceData {
	'Invoice Number': string
	'PRO Number': string
	Status: string
	'Invoice Date': string
	'Due Date': string
	'Invoice Amount': string
	'Pickup City': string
	'Pickup State': string
	'Delivery City': string
	'Delivery State': string
	'Date Funds Requested': string
	'Date Paid to Provider': string
	'Remittance Number': string
	'Payment Method': string
	'Payment Number': string
	'Dispute Status': string
	'Dispute Number': string
}

interface IMatchedDocumentAndShipmentData {
	_id: string
	invoice: IStateProviderPortalMyInvoices['shipmentDocuments']
	dispute: IStateProviderPortalMyInvoices['shipmentDocuments']
	pro_number: string
	status: string
	initialOrigin: apiTypes.Address
	finalDestination: apiTypes.Address
	providerName: string
	scac: string
	invoiceNumber: string
	invoiceDate: string
	dueDate: string
	invoiceAmount: string
	currencyCode: string
	dateFundsRequested: string
	dateFundsReceived: string
	datePaidToProvider: string
	remittanceNumberBase: string
	remittanceNumberSuffix: string
	paymentMethod: string
	paymentNumber: string
	disputeNumber: string
	correctAmount: string
	overchargeAmount: string
	disputeDate: string
	invoicedCurrencyCode: string
	overchargeCurrencyCode: string
	correctCurrencyCode: string
	remittancePdfUrl: string
	remittanceExcelUrl: string
}

const { stateManager, useSubscribe } = createSos(
	'provider-portal-invoices',
	'1.0.0',
	initialState,
	{
		useLocalStorage: true,
	},
)
stateManager.setState({ isExporting: false }) // Make sure to clear this flag -- fix by not using local storage in sos2
export { useSubscribe }

export function updateSearchFor(newVal): void {
	stateManager.produce((ds) => {
		ds.searchFor = newVal
		ds.currentPage = 0
		ds.pages = 1
		ds.hasMorePages = false
		ds.paymentStatusFilter = 'all'
	})
	fetchThrottled()
}
export function setPaymentStatusFilter(newVal: TPaymentStatusFilter): void {
	stateManager.produce((ds) => {
		ds.paymentStatusFilter = newVal
		ds.currentPage = 0
		ds.pages = 1
		ds.hasMorePages = false
		ds.searchFor = ''
	})
	fetchThrottled()
}

export function addLike(query: any, field: string, like: string): void {
	if (!like) {
		return
	}
	like = like.trim()
	if (!like) {
		return
	}
	query[field] = {
		$regex: new RegExp(l.escapeRegExp(like.trim()), 'i'),
	}
}

export function addOr(query: any, orQueries: any[]): void {
	if (!orQueries || orQueries.length === 0) {
		return
	}
	query.$or = orQueries
}

export async function fetch(): Promise<void> {
	stateManager.produce((ds) => {
		ds.isLoading = true
	})
	const fields = {
		_id: true,
		payment_statuses: true,
		pro_number: true,
		'rate.provider_company_id': true,
		'calculated.searchable.origin_stop': true,
		'calculated.searchable.destination_stop': true,
		'calculated.searchable.rate_info.most_relevant.provider_company_id': true,
		'calculated.searchable.rate_info.most_relevant.carrier_human': true,
		'calculated.searchable.rate_info.most_relevant.scac': true,
	}
	const query: IShipmentListRequestQuery = buildQueryBasedOffState()

	const currentPage = stateManager.getState().currentPage
	const pageSize = 10

	logLevel('payment-portal', 800, 'query', () => JSON.stringify(query, null, 2))
	const shipments = await tms2_providerPortal.getShipments({
		query: query,
		options: {
			skip: currentPage * pageSize,
			limit: pageSize,
			fields: fields,
		},
	})

	stateManager.produce((ds) => {
		ds.shipmentDocuments = null
		ds.shipments = shipments
		ds.isLoading = true
		ds.hasMorePages = shipments.length === pageSize
	})

	const {
		shipmentDocuments,
		invoicesReceivable,
		invoicesPayable,
		disputes,
	} = await utils.getShippingDocuments(shipments, false)

	stateManager.produce((ds) => {
		ds.shipmentDocuments = shipmentDocuments
		ds.shipments = shipments
		ds.invoicesReceivable = invoicesReceivable
		ds.invoicesPayable = invoicesPayable
		ds.disputes = disputes
		ds.isLoading = false
		ds.shipmentsLastFetched = Date.now()
	})
}
export const fetchThrottled = (): void => {
	stateManager.produce((ds) => {
		ds.isLoading = true
	})
	fireAndForget(async () => {
		await _fetchThrottled()
	}, 'fetching provider portal shipments')
}
export const _fetchThrottled = l.debounce(fetch, 1000)

export const fetchPage = (pageNum: number): void => {
	stateManager.produce((ds) => {
		ds.currentPage = pageNum
	})
	fetchThrottled()
}
export const fetchNextPage = (): void => {
	stateManager.produce((ds) => {
		ds.pages++
		ds.currentPage = ds.pages - 1
	})
	fetchThrottled()
}

export const updateState = (
	changes: Partial<IStateProviderPortalMyInvoices>,
): void => {
	stateManager.produce((ds) => {
		l.assign(ds, changes)
	})
}

export const getMatchedDocumentAndShipmentData = (): IMatchedDocumentAndShipmentData[] => {
	const state = stateManager.getState()
	return l.map(state.shipments, (c) => {
		const matchedInvoice = l.find(
			state.shipmentDocuments,
			(d) => d.shipment_id === c._id && d.type === 'invoice',
		)
		const matchedDisputeDocument = l.find(
			state.shipmentDocuments,
			(d) => d.shipment_id === c._id && d.type === 'dispute_form',
		)

		const invoicesReceivable = state.invoicesReceivable
			? state.invoicesReceivable[c._id]
			: {}
		const invoicesPayable = state.invoicesPayable
			? state.invoicesPayable[c._id]
			: {}
		const disputes = state.disputes ? state.disputes[c._id] : {}

		return {
			_id: c._id,
			invoice: matchedInvoice,
			dispute: matchedDisputeDocument,
			pro_number: c.pro_number,
			status: renderStatus(c.payment_statuses),
			initialOrigin: l.get(c, 'calculated.searchable.origin_stop'),
			finalDestination: l.get(c, 'calculated.searchable.destination_stop'),
			providerName: l.get(
				c,
				'calculated.searchable.rate_info.most_relevant.carrier_human',
			),
			scac: l.get(c, 'calculated.searchable.rate_info.most_relevant.scac'),
			invoiceNumber: l.get(
				matchedInvoice,
				'invoice_number',
				defaultProviderPortalCellValue,
			),
			invoiceDate: l.get(
				matchedInvoice,
				'date_sent',
				defaultProviderPortalCellValue,
			),
			dueDate: l.get(
				matchedInvoice,
				'payment_due_date',
				defaultProviderPortalCellValue,
			),
			invoiceAmount:
				'' +
				Number.parseFloat(
					l.get(
						matchedInvoice,
						'calculated.invoice_total',
						defaultProviderPortalCellValue,
					),
				) /
					100,
			currencyCode: l.get(c, 'rate.currency_code', 'USD'),
			dateFundsRequested: l.get(
				invoicesReceivable,
				'invoice_date',
				defaultProviderPortalCellValue,
			),
			dateFundsReceived: l.get(
				invoicesReceivable,
				'invoice_date_paid',
				defaultProviderPortalCellValue,
			),
			datePaidToProvider: l.get(
				invoicesPayable,
				'invoice_date_paid',
				defaultProviderPortalCellValue,
			),
			remittanceNumberBase: invoicesPayable
				? invoicesPayable.invoice_number_base
				: null,
			remittanceNumberSuffix: invoicesPayable
				? invoicesPayable.invoice_number_suffix
				: null,
			paymentMethod: l.get(
				invoicesPayable,
				'payment_method',
				defaultProviderPortalCellValue,
			),
			paymentNumber: l.get(
				invoicesPayable,
				'reference_number',
				defaultProviderPortalCellValue,
			),
			disputeNumber: l.get(
				disputes,
				'dispute_number',
				defaultProviderPortalCellValue,
			),
			correctAmount: l.get(
				disputes,
				'correct_amount',
				defaultProviderPortalCellValue,
			),
			overchargeAmount: l.get(
				disputes,
				'overcharge_amount',
				defaultProviderPortalCellValue,
			),
			disputeDate: l.get(
				disputes,
				'date_of_claim',
				defaultProviderPortalCellValue,
			),
			invoicedCurrencyCode: l.get(disputes, 'invoiced_currency_code'),
			overchargeCurrencyCode: l.get(disputes, 'overcharge_currency_code'),
			correctCurrencyCode: l.get(disputes, 'correct_currency_code'),
			remittancePdfUrl: l.get(invoicesPayable, 'pdf_url'),
			remittanceExcelUrl: l.get(invoicesPayable, 'excel_url'),
		}
	})
}

export const getCustomerList = async (): Promise<void> => {
	const data = await tms2_providerPortal.getCustomers()
	if (data) {
		const customerOptions: ISelectOptions[] = []
		data.forEach((customer) => {
			customerOptions.push({
				value: customer._id,
				label: customer.name,
			})
			stateManager.produce((ds) => {
				ds.customers = customerOptions
				if (!ds.currentCustomer) {
					ds.currentCustomer = customerOptions[0].value
				}
			})
		})
	}
}

export const switchCustomer = (customerId: string): void => {
	stateManager.produce((ds) => {
		ds.currentCustomer = customerId
	})
	fetchThrottled()
}

const renderStatus = (paymentStatuses): string => {
	return l.join(
		l.map(
			l.filter(l.keys(paymentStatuses), (c) => paymentStatuses[c]),
			(d) => mapRenderToDisplay('' + d),
		),
		',',
	)
}

const mapRenderToDisplay = (status: string): TRenderInvoiceStatusKey => {
	//todo refactor this to use paymentStatusFilterToTms2paymentStatus
	switch (l.camelCase(status)) {
		case 'disputed':
			return 'disputed'
		case 'waitingOnInvoice':
			return 'waitingOnInvoice'
		case 'exceptions':
			return 'undergoingAudit'
		case 'clearedToPay':
		case 'invoiceByClearviewPending':
		case 'invoiceBySwanleapPending':
			return 'clearedToPay'
		case 'invoicedByClearview':
		case 'invoicedBySwanLeap':
			return 'fundingRequested'
		case 'paidToClearview':
		case 'paidToSwanLeap':
			return 'fundingReceived'
		case 'paidToProvider':
		case 'notPaidToSwanLeap;PaidToProvider':
		case 'notPaidToClearview;PaidToProvider':
		case 'paidPriorToClearview':
			return 'paid'
	}
}

export async function exportData(): Promise<void> {
	const state = stateManager.getState()
	if (state.isExporting) {
		return
	}
	stateManager.produce((ds) => {
		ds.isExporting = true
	})

	const fields = {
		_id: true,
		payment_statuses: true,
		pro_number: true,
		'rate.provider_company_id': true,
		'calculated.searchable.origin_stop': true,
		'calculated.searchable.destination_stop': true,
		'calculated.searchable.rate_info.most_relevant.provider_company_id': true,
		'calculated.searchable.rate_info.most_relevant.carrier_human': true,
		'calculated.searchable.rate_info.most_relevant.scac': true,
	}

	const query: IShipmentListRequestQuery = buildQueryBasedOffState()

	const shipments = await tms2_providerPortal.getShipments({
		query: query,
		options: {
			skip: 0,
			fields: fields,
		},
		exportMode: true,
	})

	const {
		shipmentDocuments,
		invoicesReceivable,
		invoicesPayable,
		disputes,
	} = await utils.getShippingDocuments(shipments, true)

	const exportableData: IExportInvoiceData[] = []

	l.forEach(shipments, (shipment) => {
		const invoice = l.find(
			shipmentDocuments,
			(d) => d.shipment_id === shipment._id && d.type === 'invoice',
		)
		const invoiceReceivable = invoicesReceivable[shipment._id]
		const invoicePayable = invoicesPayable[shipment._id]
		const dispute = disputes[shipment._id]
		const defaultCellValue = ''

		const exportableShipment = {
			'Invoice Number': l.get(invoice, 'invoice_number', defaultCellValue),
			'PRO Number': shipment.pro_number || '',
			Status: getStatus(shipment.payment_statuses),
			'Invoice Date': l.get(invoice, 'date_sent', defaultCellValue),
			'Due Date': l.get(invoice, 'payment_due_date', defaultCellValue),
			'Invoice Amount': getInvoiceAmount(invoice),
			'Pickup City': getPickupCity(shipment),
			'Pickup State': getPickupState(shipment),
			'Delivery City': getDeliveryCity(shipment),
			'Delivery State': getDeliveryState(shipment),
			'Date Funds Requested': l.get(
				invoiceReceivable,
				'invoice_date',
				defaultCellValue,
			),
			'Date Paid to Provider': l.get(
				invoicePayable,
				'invoice_date_paid',
				defaultCellValue,
			),
			'Remittance Number': getRemittanceNumber(invoicePayable),
			'Payment Method': l.get(
				invoicePayable,
				'payment_method',
				defaultCellValue,
			),
			'Payment Number': l.get(
				invoicePayable,
				'reference_number',
				defaultCellValue,
			),
			'Dispute Status': l.get(dispute, 'status', defaultCellValue),
			'Dispute Number': l.get(dispute, 'dispute_number', defaultCellValue),
		}
		exportableData.push(exportableShipment)
	})

	if (exportableData.length > 0) {
		if (documentExists) {
			let csvContent = 'data:text/csv;charset=utf-8,'
			csvContent += Object.keys(exportableData[0]).join(',') + '\n'
			l.forEach(exportableData, (row) => {
				csvContent += Object.values(row).join(',') + '\n'
			})
			const el = getDocument().createElement('a')
			el.setAttribute('href', csvContent)
			el.setAttribute(
				'download',
				`${state.currentCustomer}_${state.paymentStatusFilter}.csv`,
			)
			el.style.visibility = 'hidden'
			getDocument().body.appendChild(el)
			el.click()
			getDocument().body.removeChild(el)
		}
	}

	stateManager.produce((ds) => {
		ds.isExporting = false
	})
}

export function getStatus(paymentStatuses): string {
	const status = l.join(
		l.filter(l.keys(paymentStatuses), (c) => paymentStatuses[c]),
	)
	const returnStatus =
		status &&
		tString(l.camelCase(status), 'page.providerPortal.paymentStatusFilters')
	return returnStatus
}

function getInvoiceAmount(invoice): string {
	return invoice
		? tCurrency(
				Number.parseFloat(l.get(invoice, 'calculated.invoice_total')) / 100,
		  )
		: ''
}

function getPickupCity(shipment): string {
	const initialOrigin = l.get(shipment, 'calculated.searchable.origin_stop')
	return initialOrigin.city
}

function getPickupState(shipment): string {
	const initialOrigin = l.get(shipment, 'calculated.searchable.origin_stop')
	return initialOrigin.state
}

function getDeliveryCity(shipment): string {
	const initialOrigin = l.get(
		shipment,
		'calculated.searchable.destination_stop',
	)
	return initialOrigin.city
}

function getDeliveryState(shipment): string {
	const initialOrigin = l.get(
		shipment,
		'calculated.searchable.destination_stop',
	)
	return initialOrigin.state
}

function getRemittanceNumber(invoicePayable): string {
	return invoicePayable
		? invoicePayable.invoice_number_base +
				ndash +
				invoicePayable.invoice_number_suffix
		: ''
}

function buildQueryBasedOffState(): IShipmentListRequestQuery {
	const query: IShipmentListRequestQuery = {
		shipment_status: 'booked',
	}
	const state = stateManager.getState()
	// Add filter for customer
	if (state.currentCustomer) {
		query['top_level_organization_id'] = state.currentCustomer
	}
	// Add filter for pro_number
	addLike(query, 'pro_number', state.searchFor)

	// Add invoice status filters to query
	const tms2PaymentStatuses =
		paymentStatusFilterToTms2paymentStatus[state.paymentStatusFilter]
	const paymentStatuses = l.map(tms2PaymentStatuses, (c) => ({
		[`payment_statuses.${c}`]: true,
	}))
	addOr(query, paymentStatuses)
	return query
}
