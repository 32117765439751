import React, { useEffect, useState } from 'react'
import { Table } from 'common/components/table/Table'
import { ColumnDescription } from 'react-bootstrap-table-next'
import { apiShipments } from 'ui/api'
import { IconButton, solidIcons } from 'ui/components/common/icon'
import { tString } from 'ui/components/i18n/i18n'
import { FC } from 'ui/FunctionalComponent'
import { fireAndForget } from 'ui/lib/async'
import { l } from 'ui/lib/lodashImports'
import { stringArrayRenderer } from 'ui/pages/shipments/ShipmentsListHeaderRenderers'
import { elasticSearchBuilder } from 'ui/lib/elasticSearch'
import { apiTypes } from 'ui/api'
import { sosRouter2 } from 'ui/components/common/router'
import * as classes from './FedexIpdManifestShipmentsTableModal.module.scss'

interface IFedexIpdManifestShipment {
	dateGenerated?: string
	identifier: string
	shipmentId: string
	pickup: string
	delivery: string
	orderNumber: string
	poNumbers: string[]
	soNumbers: string[]
	trackingNumbers: string[]
	packageCount: number
}

export const FedexIpdManifestShipmentsTable: FC = (props: {
	shipmentIds: string[]
	tPrefix: string
}) => {
	const { shipmentIds, tPrefix } = props

	const tPrefixFedexIpdManifestShipmentsTable =
		tPrefix + '.fedexIpdManifestShipmentsTable'

	const [fedexIpdManifestShipments, setFedexIpdManifestShipments] = useState<
		IFedexIpdManifestShipment[]
	>([])
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const viewShipment = (shipmentId: string): void => {
		sosRouter2.navigateTo(
			{
				url: '/shipments-v3/shipment-profile/:shipmentId?rerate=1',
				params: {
					shipmentId: {
						name: 'shipmentId',
					},
				},
				queries: {},
			},
			{ shipmentId },
		)
	}

	const fetchShipment = async (): Promise<void> => {
		setIsLoading(true)

		const qb = elasticSearchBuilder()

		qb.andIn('id', shipmentIds)
		// qb.andIn('id', ['prFmCjt11iH4A2atDcGDx4', 'so5iFN7jt8iZPuosNzY9hP'])

		const requestBody: apiTypes.ApiListRequest = {
			query: qb.toQuery(),
		}

		const shipmentResponse = await apiShipments.fetchShipments(() => {},
		requestBody)

		if (shipmentResponse.data) {
			setFedexIpdManifestShipments(
				shipmentResponse.data.entities.map((entity) => ({
					identifier: entity.identifier,
					shipmentId: entity.id,
					pickup: entity.origin.city + ', ' + entity.origin.state,
					delivery: entity.destination.city + ', ' + entity.destination.state,
					orderNumber: entity.payloads[0].pickTicketNumber,
					poNumbers: l.flatMap(
						entity.payloads.map((payload) => payload.purchaseOrders),
					),
					soNumbers: l.flatMap(
						entity.payloads.map((payload) => payload.salesOrders),
					),
					trackingNumbers: entity.payloads.map(
						(payload) => payload.trackingNumber,
					),
					packageCount: entity.parcelCount,
				})),
			)
		}

		setIsLoading(false)
	}

	useEffect(() => {
		fireAndForget(fetchShipment, 'Fetching Printer List')
	}, [shipmentIds]) // eslint-disable-line react-hooks/exhaustive-deps

	const fedexIpdManifestShipmentsTableHeaders: ColumnDescription[] = [
		{
			dataField: 'identifier',
			text: tString('shipmentId', tPrefixFedexIpdManifestShipmentsTable),
			//@ts-ignore
			attrs: () => ({
				'data-testid': 'shipmentId',
			}),
			formatter: (cell, row) => {
				return (
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'left',
						}}
					>
						<div>
							<IconButton
								icon={solidIcons.faSearchPlus}
								buttonClassName={classes.fedexIpdManifestShipmentsViewShipment}
								color={'black'}
								onClick={() => {
									viewShipment(row.shipmentId)
								}}
								testId={
									'fedex-ipd-manifest-shipments-table-view-shipment-button'
								}
							></IconButton>
						</div>
						{cell}
					</div>
				)
			},
		},
		{
			dataField: 'pickup',
			text: tString('pickup', tPrefixFedexIpdManifestShipmentsTable),
			//@ts-ignore
			attrs: () => ({
				'data-testid': 'pickup',
			}),
			formatter: (cell, row) => {
				return <div>{cell}</div>
			},
		},
		{
			dataField: 'delivery',
			text: tString('delivery', tPrefixFedexIpdManifestShipmentsTable),
			//@ts-ignore
			attrs: () => ({
				'data-testid': 'delivery',
			}),
			formatter: (cell, row) => {
				return <div>{cell}</div>
			},
		},
		{
			dataField: 'orderNumber',
			text: tString('orderNumber', tPrefixFedexIpdManifestShipmentsTable),
			//@ts-ignore
			attrs: () => ({
				'data-testid': 'orderNumber',
			}),
			formatter: (cell, row) => {
				return <div>{cell}</div>
			},
		},
		{
			dataField: 'poNumbers',
			text: tString('poNumbers', tPrefixFedexIpdManifestShipmentsTable),
			//@ts-ignore
			attrs: () => ({
				'data-testid': 'poNumbers',
			}),
			formatter: stringArrayRenderer,
		},
		{
			dataField: 'soNumbers',
			text: tString('soNumbers', tPrefixFedexIpdManifestShipmentsTable),
			//@ts-ignore
			attrs: () => ({
				'data-testid': 'soNumbers',
			}),
			formatter: stringArrayRenderer,
		},
		{
			dataField: 'trackingNumbers',
			text: tString('trackingNumbers', tPrefixFedexIpdManifestShipmentsTable),
			//@ts-ignore
			attrs: () => ({
				'data-testid': 'trackingNumbers',
			}),
			formatter: stringArrayRenderer,
		},
		{
			dataField: 'packageCount',
			text: tString('packageCount', tPrefixFedexIpdManifestShipmentsTable),
			//@ts-ignore
			attrs: () => ({
				'data-testid': 'packageCount',
			}),
			formatter: (cell, row) => {
				return <div style={{ textAlign: 'right' }}>{cell}</div>
			},
		},
	]

	return (
		<div
			className='bootstrap-wrapper'
			data-testid='fedex-ipd-manifest-shipments-table-wrapper'
		>
			<Table
				columns={fedexIpdManifestShipmentsTableHeaders}
				data={fedexIpdManifestShipments}
				isLoading={isLoading}
				totalSize={100}
				keyField='shipmentId'
				verticalCenterCells={true}
				testId='fedex-ipd-manifest-shipments-table'
				onTableChange={(_type, newState) => {
					console.log('onTableChange:', _type, newState)
				}}
			/>
		</div>
	)
}
