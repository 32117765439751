// import json from './api-examples.json'
import axios from 'axios'
import { l } from 'ui/lib/lodashImports'

export async function getData(): Promise<{
	examples: {
		name: string
		examples: any[]
	}[]
}> {
	const response = await axios({
		url: 'api-examples.json', // '/data/api-examples.json',
		method: 'GET',
	})

	if (response.status !== 200) {
		// tslint:disable-next-line:no-console
		console.error('Could not get examples')
	}
	const json = response.data

	const FedEx = []
	const UPS = []
	const USPS = []

	l.forEach(Object.keys(json), (c) => {
		// console.log('key', c)

		l.forEach(json[c].examples, (d, dIdx) => {
			// console.log(dIdx)

			let name = dIdx
			let subName = dIdx

			if (name.indexOf('|') !== -1) {
				const ss = name.split('|')
				name = ss[0].trim()
				subName = ss[1].trim()
			} else if (name.indexOf('should') !== -1) {
				const ss = name.split('should')
				name = ss[0].trim()
				subName = ss[1].trim()
			} else if (name.indexOf('Create') !== -1) {
				const ss = name.split('Create')
				name = ss[0].trim()
				subName = 'Create ' + ss[1].trim()
			}

			name = name.replace('fedex', 'FedEx')
			name = name.replace('tests', '')

			d.name = titleCase(name)
			d.subName = titleCase(subName)
		})

		if (c.startsWith('FedEx')) {
			FedEx.push(json[c])
		}
		if (c.startsWith('UPS')) {
			UPS.push(json[c])
		}
		if (c.startsWith('USPS')) {
			USPS.push(json[c])
		}
	})

	l.forEach(FedEx, (c) => {
		c.name = c.filename.replace('FedEx', '').trim()
	})
	l.forEach(UPS, (c) => {
		c.name = c.filename.replace('UPS', '').trim()
	})
	l.forEach(USPS, (c) => {
		c.name = c.filename.replace('USPS', '').trim()
	})

	const data = {
		examples: [
			{
				name: 'FedEx',
				examples: FedEx,
			},
			{
				name: 'UPS',
				examples: UPS,
			},
			{
				name: 'USPS',
				examples: USPS,
			},
		],
	}
	return data
}

function titleCase(s: string): string {
	s = l.startCase(s.trim())
	s = s.replace(/ And /g, ' and ')
	s = s.replace(/ With /g, ' with ')
	s = s.replace(/ A /g, ' a ')
	s = s.replace(/ An /g, ' an ')
	s = s.replace(/ At /g, ' at ')
	s = s.replace(/ Of /g, ' of ')
	return s
}
