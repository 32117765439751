import * as formQuickRate from 'ui/forms/formQuickRate'
import * as formQuickRateProductCost from 'ui/forms/formQuickRateProductCost'
import { formValidator } from 'ui/lib/validation/'
import { sosUser } from 'ui/state/'
import WebSocketAsPromised from 'websocket-as-promised'

import { createSos } from './secretaryOfState'
import { log } from 'ui/lib/log'

export { formQuickRate }
export { formQuickRateProductCost }

let webSocketRequestId = 100

export interface IWebSocketRequestState<T> {
	requestId?: number
	isFetching?: boolean
	data?: T
	error?: any
	time?: string
}

export interface IStateQuickRateTms3 {
	isLoading: boolean

	locations: any

	requestGetRates: IWebSocketRequestState<any>

	rates: any[]
	ratesErrors: any[]

	isRatesErrorsOpen: boolean

	quickRateForm: formQuickRate.IQuickRateForm
	productCostForm: formQuickRateProductCost.IQuickRateProductCostForm
}

const initialState: IStateQuickRateTms3 = {
	isLoading: false,
	locations: null,

	requestGetRates: {},

	rates: [],
	ratesErrors: [],

	isRatesErrorsOpen: false,

	quickRateForm: {
		locationParentOrganizationId: null,
		destinationZip: '',
		weight: '',
		classCode: '',
		handlingUnits: '' + 1,
		length: '' + 48,
		width: '' + 48,
		height: '' + 40,
		pieces: '' + 1,
	},
	productCostForm: {
		productCost: '' + 0,
		freightMarkupPercent: '' + 0,
	},
}

const { stateManager, useSubscribe } = createSos(
	'quick-rate',
	'1.0.1',
	initialState,
	{
		useLocalStorage: true,
		localStorageFields: [
			'quickRateForm',
			'productCostForm',
			'rates',
			'locations',
		],
	},
)
export { useSubscribe }

export function toggleIsRatesErrorsOpen(): void {
	stateManager.produce((ds) => {
		ds.isRatesErrorsOpen = !ds.isRatesErrorsOpen
	})
}

export function updateQuickRateForm(
	key: keyof formQuickRate.IQuickRateForm,
	newVal: string,
): void {
	stateManager.produce((ds) => {
		ds.quickRateForm[key] = newVal
	})
}

export function updateProductCostForm(
	key: keyof formQuickRateProductCost.IQuickRateProductCostForm,
	newVal: string,
): void {
	stateManager.produce((ds) => {
		ds.productCostForm[key] = newVal
	})
}

export async function callWebsocket(): Promise<void> {
	const wsp = new WebSocketAsPromised(
		'wss://7a0i8wk621.execute-api.us-east-1.amazonaws.com/Prod/',
	)

	stateManager.produce((ds) => {
		ds.isLoading = true
		ds.rates = []
		ds.ratesErrors = []
	})
	webSocketRequestId++
	// TODO: Extract this into common library
	wsp.onMessage.addListener((message) => {
		log('quickrate', '', message)
		const m = JSON.parse(message)
		if (m.requestId !== webSocketRequestId) {
			log('quickrate', '', 'rejected stale message')
			return // This is for a different request, reject it
		}

		if (m.type === 'Rate') {
			log('quickrate', '', 'Rate:', JSON.stringify(m, null, 2))

			stateManager.produce((ds) => {
				ds.rates.push(m.data)
			})
		}

		if (m.type === 'message') {
			log('quickrate', '', 'Message:', JSON.stringify(m, null, 2))
			if (m.data === 'complete') {
				stateManager.produce((ds) => {
					ds.isLoading = false
				})
			}
		}
	})

	await wsp.open()

	// TODO: Hook this up
	const body = {
		payloads: [
			{
				destinationStop: {
					address: {
						name: 'ATTN: Jackie Robinson',
						company: 'SP RICHARDS',
						street1: '3673 Corporate Center Dr',
						city: 'Earth City',
						state: 'MO',
						country: 'US',
						phone: '(314) 567-7726',
						email: 'email@email.com',
						zip: '63045',
					},
				},
				originStop: {
					address: {
						country: 'US',
						company: 'Purity Cosmetics',
						addressType: 'commercial',
						street1: '2221 Oakland Road',
						city: 'San Jose',
						zip: '95131',
						state: 'CA',
						phone: '555-555-5555',
					},
				},
				containers: [
					{
						count: 1,
						length: 10,
						width: 10,
						height: 10,
						containerWeightEach: 47,
						description: 'Running Shoes',
						goods: [
							{
								count: 1,
								countryOfManufacture: 'CN',
								customsValue: 100,
								weightEach: 3,
							},
						],
					},
				],
			},
		],
	}

	wsp.send(
		JSON.stringify({
			action: 'getRates',
			data: body,
			requestId: webSocketRequestId,
			authorization: `Bearer ${sosUser.getAuthToken()}`,
		}),
	)
}

export async function submitQuickRateForm(): Promise<void> {
	const state = stateManager.getState()
	const validationResult = formValidator.validateForm(
		state.quickRateForm,
		formQuickRate.quickRateFormMetadata,
	)

	if (!validationResult.isValid) {
		// Show some sort of error
		// tms2.swal({
		//   title: 'Quick Rate Error',
		//   text: validationResult.error,
		//   type: 'error',
		// })
		return
	}

	// TODO LINK THIS UP TO apiShipments.quickRate
	await callWebsocket()
}
