import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { ndash } from 'ui/components/common'
import { tNumber } from 'ui/components/i18n/i18n'
import * as tableClasses from 'ui/components/rates-table/RatesTable.module.scss'
import { l } from 'ui/lib/lodashImports'

export const CostContainer: FC = (props: { cents: number }) => {
	const { cents } = props

	if (l.isNil(cents) || cents === 0) {
		return <div className={tableClasses.costContainer}>{ndash}</div>
	}

	const centsPart = Math.round(cents % 100)
	const dollarsPart = Math.floor((cents - centsPart) / 100)

	return (
		<div className={tableClasses.costContainer}>
			$
			<span className={tableClasses.dollars} data-testid='dollarsPart'>
				{tNumber(dollarsPart, '0,0')}
			</span>
			.{tNumber(centsPart, '00')}
		</div>
	)
}
