import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { LinkButton } from 'ui/components/common/link/'
import { t, testId } from 'ui/components/i18n/i18n'
import { theme } from 'ui/theme'
import { Colors } from 'ui/theme/theme'
import { Popup } from '../popup'
import classes from './ConfirmButton.module.scss'

const ConfirmChoice: FC = (props: {
	onConfirm: () => void
	onCancel: () => void
	tLabel?: string
}) => {
	return (
		<div>
			<LinkButton onClick={props.onConfirm}>
				{t(props.tLabel || 'component.confirmButton.confirm')}
			</LinkButton>

			<LinkButton onClick={props.onCancel}>
				{t('component.confirmButton.cancel')}
			</LinkButton>
		</div>
	)
}

export const ConfirmButton: FC = (props: {
	color?: Colors
	onConfirm: () => void
	children?: React.ReactNode
	testId?: string
	tLabel?: string
}) => {
	const [isOpen, setIsOpen] = React.useState(false)

	const onClick = (): void => {
		setIsOpen(!isOpen)
	}
	const onConfirm = (): void => {
		setIsOpen(false)
		props.onConfirm()
	}
	const onCancel = (): void => {
		setIsOpen(false)
	}

	return (
		<Popup
			onClickOutside={onCancel}
			content={
				<div>
					<ConfirmChoice
						onCancel={onCancel}
						onConfirm={onConfirm}
						tLabel={props.tLabel}
					/>
				</div>
			}
			isOpen={isOpen}
		>
			<button
				type={'button'}
				className={`${classes.confirmButton} ${theme.getColorButton(
					props.color,
				)}`}
				onClick={onClick}
				data-testid={props.testId}
			>
				{props.children}
				{testId(props.testId)}
			</button>
		</Popup>
	)
}
