import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { MagLink } from 'common/components/magLink'
import { ColumnDescription } from 'react-bootstrap-table-next'
import { textFilter, selectFilter } from 'react-bootstrap-table2-filter'

import { Table as SwanleapTable } from 'common/components/table/Table'

import { tString } from 'ui/components/i18n/i18n'
import { Dropdown } from 'react-bootstrap'
import { ActionDropdown } from 'common/components/action-dropdown'
import { FC } from 'app/FunctionalComponent'

export const Table: FC = (props: {
	data
	totalSize: number
	isLoading: boolean
	onHandleChange: (type, newState) => void
}) => {
	const modeOptions = [
		{ value: 'ltl', label: tString('common.mode.ltl') },
		{ value: 'parcel', label: tString('common.mode.parcel') },
		{ value: 'truckload', label: tString('common.mode.truckload') },
	]
	const columns: ColumnDescription[] = [
		{
			dataField: 'id',
			text: '',
			//@ts-ignore - old type doesn't support this yet
			attrs: () => ({
				'data-testid': 'id',
			}),
			formatter: (cell) => {
				return <MagLink url={'edit_provider/'} id={cell} />
			},
			style: { whiteSpace: 'nowrap' },
		},
		{
			dataField: 'name',
			text: tString('page.marketplace.providers.properties.name'),
			sort: true,
			//@ts-ignore - old type doesn't support this yet
			attrs: () => ({
				'data-testid': 'providerName',
			}),
			filter: textFilter({
				className: 'form-control-sm',
				placeholder: tString('page.marketplace.providers.properties.name'),
			}),
			style: { whiteSpace: 'nowrap' },
		},
		{
			dataField: 'mcNumber',
			text: tString('page.marketplace.providers.properties.mcnumber'),
			sort: false,
			//@ts-ignore - old type doesn't support this yet
			attrs: () => ({
				'data-testid': 'mcNumber',
			}),
			filter: textFilter({
				className: 'form-control-sm',
				placeholder: tString('page.marketplace.providers.properties.mcnumber'),
			}),
			formatter: (cell) => {
				if (cell && Array.isArray(cell)) {
					return cell.join(', ')
				}
				return cell
			},
			title: (cell) => {
				if (cell && Array.isArray(cell)) {
					return cell.join(', ')
				}
				return cell
			},
			style: {
				whiteSpace: 'nowrap',
				textOverflow: 'ellipsis',
				overflow: 'hidden',
				maxWidth: '1px',
			},
		},
		{
			dataField: 'usdotNumber',
			text: tString('page.marketplace.providers.properties.usdotnumber'),
			sort: true,
			//@ts-ignore - old type doesn't support this yet
			attrs: () => ({
				'data-testid': 'usdotNumber',
			}),
			filter: textFilter({
				className: 'form-control-sm',
				placeholder: tString(
					'page.marketplace.providers.properties.usdotnumber',
				),
			}),
			style: { whiteSpace: 'nowrap' },
		},
		{
			dataField: 'scac',
			text: tString('page.marketplace.providers.properties.scac'),
			sort: true,
			//@ts-ignore - old type doesn't support this yet
			attrs: () => ({
				'data-testid': 'SCAC',
			}),
			filter: textFilter({
				className: 'form-control-sm',
				placeholder: tString('page.marketplace.providers.properties.scac'),
			}),
			style: { whiteSpace: 'nowrap' },
		},
		{
			dataField: 'service',
			text: tString('page.marketplace.providers.properties.service'),
			sort: true,
			formatter: (cell) => {
				return ['ltl', 'parcel', 'truckload'].includes(cell)
					? tString(`common.mode.${cell}`)
					: ''
			},
			//@ts-ignore - old type doesn't support this yet
			attrs: () => ({
				'data-testid': 'service',
			}),
			filter: selectFilter({
				options: modeOptions,
				className: 'custom-select custom-select-sm',
				placeholder: tString('page.marketplace.providers.properties.service'),
			}),
			style: { whiteSpace: 'nowrap' },
		},
		{
			dataField: 'contracts',
			text: tString('page.marketplace.providers.properties.contracts'),
			//@ts-ignore - old type doesn't support this yet
			attrs: () => ({
				'data-testid': 'contracts',
			}),
			style: { whiteSpace: 'nowrap' },
		},
		{
			dataField: 'active',
			text: tString('page.marketplace.providers.properties.active'),
			formatter: (cell) => {
				if (cell) {
					return <FontAwesomeIcon icon={faCheck} />
				} else {
					return <FontAwesomeIcon icon={faTimesCircle} />
				}
			},
			//@ts-ignore - old type doesn't support this yet
			attrs: () => ({
				'data-testid': 'active',
			}),
			filter: selectFilter({
				options: { 0: tString('common.false'), 1: tString('common.true') },
				className: 'custom-select custom-select-sm',
			}),
			style: { width: '1px', whiteSpace: 'nowrap' },
			classes: 'text-center',
		},
		{
			dataField: 'action',
			text: '',
			//@ts-ignore - old type doesn't support this yet
			attrs: () => ({
				'data-testid': 'ellipsis',
			}),
			formatter: (cell, row) => {
				const hrefEdit = `/#/marketplace/providers/${row.id}`
				return (
					<ActionDropdown>
						<Dropdown.Item href={hrefEdit}>
							{tString('page.marketplace.providers.edit')}
						</Dropdown.Item>
					</ActionDropdown>
				)
			},
			style: { whiteSpace: 'nowrap' },
		},
	]

	return (
		<div className='row justify-content-center'>
			<div className='col'>
				<SwanleapTable
					columns={columns}
					data={props.data}
					keyField='id'
					isLoading={props.isLoading}
					wrapperClasses='table-responsive'
					onTableChange={props.onHandleChange}
					totalSize={props.totalSize}
				/>
			</div>
		</div>
	)
}
