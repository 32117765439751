import React, { useState } from 'react'
import { FC } from 'ui/FunctionalComponent'
import { t, tCurrency, tString } from 'ui/components/i18n/i18n'
import * as classes from './CustomerCharges.module.scss'
import { l } from 'ui/lib/lodashImports'
import { FormTextInput, IFormData } from 'ui/components/form'
import { IconButton, solidIcons } from 'ui/components/common/icon'
import { Button } from 'ui/components/common/button'
import { Input } from 'ui/components/common/input'
import { apiBrokerInvoice, apiProviders, apiTypes } from 'ui/api'
import { sosToast } from 'ui/common/components/toast'
import { AsyncTypeahead, TypeaheadOption } from 'ui/common/components/typeahead'
import { Table } from 'react-bootstrap'
import { IRequestState } from 'ui/api/requestState'
import { Modal } from 'ui/components/common/modal'
import { AlignRight } from 'ui/components/layout/alignRight'
import { OkCancelButtons } from 'ui/components/common/okCancelButtons'
import { Center } from 'ui/components/layout/center'
import { Loader } from 'ui/components/common/loader'
import { tProviderName } from 'ui/components/i18n/commonTranslations'
import { processSearchString } from 'ui/lib/elasticSearch'
import { UIBrokerInvoiceCharge } from '../../CustomerInvoicePage'

const tPrefix = 'page.customerInvoice.customerCharges'

export const CustomerCharges: FC = (props: {
	isFetchingInvoices: boolean
	defaultProvider: apiTypes.ProviderResponse
	brokerInvoice: apiTypes.BrokerInvoiceResponse
	brokerChargeCodes: apiTypes.ChargeCodePageResponse[]
	onUpdate: (updateMode: 'add' | 'upsert' | 'delete', charges: any) => void
}) => {
	const {
		isFetchingInvoices,
		defaultProvider,
		brokerInvoice,
		brokerChargeCodes,
		onUpdate,
	} = props

	const [chargeId, setChargeId] = useState<string>(null)
	const [chargeRequest, setChargeRequest] = useState<UIBrokerInvoiceCharge>(
		null,
	)

	const [selectedChargeCode, setSelectedChargeCode] = useState<TypeaheadOption>(
		null,
	)
	const [selectedChargeDescription, setSelectedChargeDescription] = useState<
		TypeaheadOption
	>(null)

	const [isAddingCharge, setIsAddingCharge] = useState<boolean>(false)
	const [isUpdatingCharge, setIsUpdatingCharge] = useState<boolean>(false)
	const [isDeletingCharge, setIsDeletingCharge] = useState<boolean>(false)

	const [chargeToEdit, setChargeToEdit] = useState<number>(null)
	const [chargeToDelete, setChargeToDelete] = useState<number>(null)

	const [showModal, setShowModal] = useState<boolean>(false)

	const updateForm = (
		data: UIBrokerInvoiceCharge,
		updateMode: 'upsert' | 'delete',
	): void => {
		const charges = l.cloneDeep(brokerInvoice.charges)

		if (updateMode === 'upsert') {
			charges.splice(chargeToEdit, 1, data)
		} else {
			charges.splice(chargeToDelete, 1)
		}

		onUpdate('upsert', charges)
	}

	const createBrokerInvoiceCharge = async (): Promise<void> => {
		setIsAddingCharge(true)

		const charge: apiTypes.BrokerInvoiceChargeRequest = {
			chargeCode: '',
			chargeDescription: '',
			qty: 1,
			quantity: 1,
			unitPrice: 0,
			providerCost: 0,
			providerId: defaultProvider.id,
		}

		const response = await apiBrokerInvoice.createBrokerInvoiceCharge(
			brokerInvoice.id,
			charge,
		)

		if (response.error) {
			sosToast.sendApiErrorResponseToast(response)
		} else {
			onUpdate('add', response.data)
			setChargeId(response.data.id)
			setChargeRequest(response.data as UIBrokerInvoiceCharge)

			setChargeToEdit(
				brokerInvoice?.charges?.length === 0
					? 0
					: brokerInvoice?.charges?.length - 1,
			)
		}

		setIsAddingCharge(false)
	}

	const updateBrokerInvoiceCharge = async (): Promise<void> => {
		setIsUpdatingCharge(true)

		const updatedBrokerInvoiceChargeRequest = {
			...chargeRequest,
			chargeCode: selectedChargeCode?.value || chargeRequest.chargeCode,
			chargeDescription:
				selectedChargeDescription?.value || chargeRequest.chargeDescription,
			unitPrice: chargeRequest.unitPrice * 100,
			providerCost: chargeRequest.providerCost * 100,
		}

		const response = await apiBrokerInvoice.updateBrokerInvoiceCharge(
			brokerInvoice.id,
			chargeId,
			updatedBrokerInvoiceChargeRequest,
		)

		if (response.error) {
			sosToast.sendApiErrorResponseToast(response)
		} else {
			setSelectedChargeCode(null)
			setSelectedChargeDescription(null)

			const data = response.data as UIBrokerInvoiceCharge

			data.selectedProviderName = chargeRequest.selectedProviderName
			data.unitPrice = data.unitPrice / 100
			data.providerCost = data.providerCost / 100

			updateForm(data, 'upsert')
		}

		setIsUpdatingCharge(false)
	}

	const deleteBrokerInvoiceCharge = async (): Promise<void> => {
		setIsDeletingCharge(true)

		const response = await apiBrokerInvoice.deleteBrokerInvoiceCharge(
			brokerInvoice.id,
			chargeId,
		)

		if (response.error) {
			sosToast.sendApiErrorResponseToast(response)
		} else {
			updateForm(response.data as UIBrokerInvoiceCharge, 'delete')
		}

		setIsDeletingCharge(false)
	}

	const updateCharges = (
		target: 'chargecode' | 'chargedescription',
		str: TypeaheadOption,
		idx: number,
	): void => {
		if (target === 'chargecode') {
			if (idx === chargeToEdit) {
				const filteredCharges = l.find(
					brokerChargeCodes,
					(c) => c.chargeCode === str.label,
				)

				setSelectedChargeCode({
					value: filteredCharges?.chargeCode,
					label: filteredCharges?.chargeCode,
				})

				setSelectedChargeDescription({
					value: filteredCharges?.chargeCodeDescription,
					label: filteredCharges?.chargeCodeDescription,
				})
			}
		} else if (target === 'chargedescription') {
			if (idx === chargeToEdit) {
				const filteredCharges = l.find(
					brokerChargeCodes,
					(c) => c.chargeCodeDescription === str.label,
				)

				setSelectedChargeCode({
					value: filteredCharges?.chargeCode,
					label: filteredCharges?.chargeCode,
				})

				setSelectedChargeDescription({
					value: filteredCharges?.chargeCodeDescription,
					label: filteredCharges?.chargeCodeDescription,
				})
			}
		}
	}

	const getMarkup = (totalPrice: number, providerCost: number): number => {
		const markup = ((totalPrice - providerCost) / providerCost) * 100

		return isNaN(markup) || markup < 0 || markup === Infinity ? 0 : markup
	}

	return (
		<div className={classes.customerCharges}>
			<div className={classes.title}>
				<h3>{t('customerCharges', tPrefix)}</h3>

				{brokerInvoice?.invoiceStatus === 'review' && (
					<Button
						color={'green'}
						onClick={async () => {
							await createBrokerInvoiceCharge()
						}}
						isSpinning={isAddingCharge}
					>
						{t('addCharge', tPrefix)}
					</Button>
				)}
			</div>
			<Table bordered size={'xs'}>
				<thead>
					<tr>
						<th>{t('chargeCode', tPrefix)}</th>
						<th>{t('chargeDescription', tPrefix)}</th>
						<th>{t('qty', tPrefix)}</th>
						<th>{t('unitPrice', tPrefix)}</th>
						<th>{t('totalPrice', tPrefix)}</th>
						<th>{t('amountPaid', tPrefix)}</th>
						<th>{t('provider', tPrefix)}</th>
						<th>{t('providerCost', tPrefix)}</th>
						<th> {t('markup', tPrefix)}</th>
						<th>&nbsp;</th>
					</tr>
				</thead>
				<tbody>
					{!brokerInvoice?.charges.length && !isFetchingInvoices ? (
						<tr>
							<td colSpan={10} className={classes.noDataWarning}>
								<Center>{t('noLinkedCustomerCharges', tPrefix)}</Center>
							</td>
						</tr>
					) : isFetchingInvoices ? (
						<tr>
							<td colSpan={10}>
								<Center>
									<Loader isLoading={isFetchingInvoices} />
								</Center>
							</td>
						</tr>
					) : (
						l.map(
							brokerInvoice?.charges || [],
							(charge: UIBrokerInvoiceCharge, idx) => {
								const formData: IFormData<UIBrokerInvoiceCharge> = {
									form: charge,

									metadata: {
										id: {},
										chargeCode: {},
										chargeDescription: {},
										qty: {},
										quantity: {},
										unitPrice: {},
										providerCost: {},
										shipmentId: {},
										totalPrice: {},
										providerId: {},
										providerName: {},
										selectedProviderName: {},
									},

									onUpdateForm: (field: string, value: any) => {
										const updateInvoiceChargesRequest = l.cloneDeep(
											brokerInvoice.charges,
										)

										const updateInvoiceRequest = l.cloneDeep(charge)

										updateInvoiceRequest[field] = value

										updateInvoiceChargesRequest.splice(
											idx,
											1,
											updateInvoiceRequest,
										)

										setChargeRequest(updateInvoiceRequest)

										onUpdate('upsert', updateInvoiceChargesRequest)
									},

									tPrefix,
								}

								return (
									<tr key={`${charge.chargeCode}-${idx}`}>
										<td>
											<AsyncTypeahead
												testId={'cutomer-invoice-chargeCode-input'}
												size={'small'}
												options={[]}
												onSearch={async () => {
													let responseOptions: TypeaheadOption[] = []

													if (brokerChargeCodes) {
														responseOptions = brokerChargeCodes.map((c) => ({
															value: c.chargeCode,
															label: c.chargeCode,
														}))
													}

													return responseOptions
												}}
												onChange={(selected: TypeaheadOption) => {
													updateCharges('chargecode', selected, idx)
												}}
												isClearable={true}
												useCache={true}
												className={`${classes.asyncTypehead} ${classes.asyncTypeheadChargeCode}`}
												disabled={idx !== chargeToEdit}
												value={
													(idx === chargeToEdit && selectedChargeCode) ||
													charge.chargeCode
												}
											/>
										</td>
										<td>
											<AsyncTypeahead
												testId={'customer-invoice-chargeDescription-input'}
												size={'small'}
												options={[]}
												onSearch={async () => {
													let responseOptions: TypeaheadOption[] = []

													if (brokerChargeCodes) {
														responseOptions = brokerChargeCodes.map((c) => ({
															value: c.chargeCodeDescription,
															label: c.chargeCodeDescription,
														}))
													}

													return responseOptions
												}}
												onChange={(selected: TypeaheadOption) => {
													updateCharges('chargedescription', selected, idx)
												}}
												isClearable={true}
												useCache={true}
												className={classes.asyncTypehead}
												disabled={idx !== chargeToEdit}
												value={
													(idx === chargeToEdit && selectedChargeDescription) ||
													charge.chargeDescription
												}
											/>
										</td>
										<td>
											<FormTextInput
												form={formData.form}
												field={'qty'}
												onUpdateForm={formData.onUpdateForm}
												readOnly={idx !== chargeToEdit}
												className={classes.alignRight}
											/>
										</td>
										<td>
											{idx !== chargeToEdit ? (
												<Input
													value={tCurrency(
														charge.unitPrice,
														brokerInvoice.currency,
													)}
													readOnly={true}
													className={classes.alignRight}
												/>
											) : (
												<FormTextInput
													form={formData.form}
													field={'unitPrice'}
													onUpdateForm={formData.onUpdateForm}
													className={classes.alignRight}
												/>
											)}
										</td>
										<td>
											<Input
												value={tCurrency(
													charge.unitPrice * charge.qty,
													brokerInvoice.currency,
												)}
												readOnly={true}
												className={classes.alignRight}
											/>
										</td>
										<td>
											<Input
												value={
													brokerInvoice.invoiceStatus === 'paid_in_full'
														? tCurrency(
																charge.totalPrice,
																brokerInvoice.currency,
														  )
														: tCurrency(0, brokerInvoice.currency)
												}
												readOnly={true}
												className={
													brokerInvoice.invoiceStatus === 'paid_in_full'
														? classes.paidInFull
														: classes.alignRight
												}
											/>
										</td>
										<td>
											<AsyncTypeahead
												testId={'customer-invoice-providerName-input'}
												size={'small'}
												options={[]}
												onSearch={async (searchTerm: string) => {
													const processedSearchTerm = processSearchString(
														searchTerm,
													)
													const response: IRequestState<apiTypes.ProviderListResponse> = await apiProviders.getProviders(
														25,
														0,
														`name:${processedSearchTerm}`,
													)

													if (response.error) {
														sosToast.sendApiErrorResponseToast(
															response,
															tString('providerFetchError', 'common'),
														)

														return []
													}

													let responseOptions: TypeaheadOption[] = []
													if (response.data) {
														responseOptions = response.data.entities.map(
															(c) => ({
																value: c.id,
																label:
																	c.name ||
																	tProviderName(c.providerName) ||
																	c.id,
															}),
														)
													}

													return responseOptions
												}}
												onChange={(selected: TypeaheadOption) => {
													charge.providerId = selected?.value
													charge.selectedProviderName = selected?.label
												}}
												isClearable={true}
												useCache={true}
												value={{
													value: charge.providerId,
													label:
														charge.selectedProviderName || charge.providerName,
												}}
												className={classes.providerSelector}
												disabled={idx !== chargeToEdit}
											/>
										</td>
										<td>
											{idx !== chargeToEdit ? (
												<Input
													value={tCurrency(
														charge.providerCost,
														brokerInvoice.currency,
													)}
													readOnly={true}
													className={classes.alignRight}
												/>
											) : (
												<FormTextInput
													form={formData.form}
													field={'providerCost'}
													onUpdateForm={formData.onUpdateForm}
													readOnly={idx !== chargeToEdit}
													className={classes.alignRight}
												/>
											)}
										</td>
										<td>
											<Input
												value={`${getMarkup(
													charge.qty * charge.unitPrice,
													charge.providerCost,
												).toFixed()}%`}
												readOnly={true}
												className={classes.alignRight}
											/>
										</td>
										{brokerInvoice?.invoiceStatus === 'review' && (
											<td>
												<div className={classes.iconButtons}>
													<IconButton
														icon={
															idx === chargeToEdit
																? solidIcons.faCheck
																: solidIcons.faPencilAlt
														}
														buttonClassName={
															idx === chargeToEdit ? classes.save : classes.edit
														}
														color={idx === chargeToEdit ? 'green' : 'black'}
														onClick={async () => {
															if (idx === chargeToEdit) {
																await updateBrokerInvoiceCharge()
																setChargeToEdit(null)
															} else {
																setChargeId(charge.id)
																setChargeRequest(charge)
																setChargeToEdit(idx)
															}
														}}
														spin={idx === chargeToEdit && isUpdatingCharge}
														testId={'cutomer-invoice-charge-edit-save'}
													></IconButton>
													<IconButton
														icon={solidIcons.faTimes}
														buttonClassName={classes.cancel}
														color={'red'}
														onClick={() => {
															if (chargeToEdit !== null) {
																setChargeToEdit(null)
															} else {
																setChargeId(charge.id)
																setChargeToDelete(idx)
																setShowModal(true)
															}
														}}
														testId={'cutomer-invoice-charge-cancel'}
													></IconButton>
												</div>
											</td>
										)}
									</tr>
								)
							},
						)
					)}
				</tbody>
			</Table>
			<Modal
				content={() => (
					<div data-testid={'customer-invoice-charge-delete-modal'}>
						<p>{t('deleteThisInvoiceCharge?', tPrefix)}</p>
						<AlignRight>
							<OkCancelButtons
								isValid={true}
								ok={t('ok', tPrefix)}
								okColor={'green'}
								okTestId={'customer-invoice-charge-delete-modal-ok'}
								isSpinning={isDeletingCharge}
								onOk={async () => {
									await deleteBrokerInvoiceCharge()
									setChargeToDelete(null)
									setShowModal(false)
								}}
								cancel={t('cancel', tPrefix)}
								onCancel={() => {
									setChargeToDelete(null)
									setShowModal(false)
								}}
								cancelTestId={'customer-invoice-charge-delete-modal-cancel'}
							></OkCancelButtons>
						</AlignRight>
					</div>
				)}
				isOpen={showModal}
				onModalClose={() => {}}
				title={t('confirmDelete', tPrefix)}
			/>
		</div>
	)
}
