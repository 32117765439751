import { l } from 'ui/lib/lodashImports'
import { parse, stringify } from 'parenthesis'
import { addParensIfNotThereAlready } from 'ui/lib/strings/parensUtils'

const splitAndAddParens = (
	string: string,
	splitter: string,
	separator: string,
): string => {
	const splits = l.split(string, splitter)
	if (splits.length > 1) {
		return l.join(l.map(splits, addParensIfNotThereAlready), separator)
	}
	// need to return empty string for the parser to work
	return ''
}

/*
	Creates a parser function to fit the syntax of ElasticSearch. The parser function will split the queryString using
	the splitters and surround their related values with parens. It will also replace those splitters with the esSyntax.
		@param splitters: an array of strings that you would like to split on.  ex: [' & ', ' + ' ]
		@param esSyntax: the syntax the splitters need to be in to work with Elastic Search. ex: ' AND '
 */
export function esQueryStringFormatterGenerator(
	splitters: string[],
	esSyntax: string,
): (qs: string) => string {
	// return a function so we can have multiple different separators depending on what splitters you want
	return (qs: string): string => {
		// https://www.npmjs.com/package/parenthesis
		const parenParsedQueryArray = parse(qs)

		// this fixes a issue with the parser when the first or last elements are parens
		let startParen = ['']
		let endParen = ['']
		if (l.first(parenParsedQueryArray) === '(') {
			startParen = parenParsedQueryArray.shift(parenParsedQueryArray)
		}
		if (l.last(parenParsedQueryArray) === ')') {
			endParen = parenParsedQueryArray.pop(parenParsedQueryArray)
		}

		// loop through each piece of the query string
		const returnArray = l.map(parenParsedQueryArray, (qsPiece) => {
			// loop through each splitter
			return l.map(splitters, (splitter) => {
				// only attempt to parse if the splitter exists in the original query string
				if (l.includes(qs, splitter)) {
					// parser result can either be a array or a string
					if (l.isArray(qsPiece)) {
						return l.map(qsPiece, (c) =>
							splitAndAddParens(c, splitter, esSyntax),
						)
					} else {
						return splitAndAddParens(qsPiece, splitter, esSyntax)
					}
				} else {
					// need to return empty string for the parser to work
					return ''
				}
			})
		})

		// this fixes a issue with the parser when the first or last elements are parens
		returnArray.unshift(startParen)
		returnArray.push(endParen)

		// use stringify function from the parenthesis npm package
		let result = stringify(returnArray)
		// if nothing was parsed, the stringify function returns empty parens, in this case we want to just send back qs
		if (!result || result === '()') {
			result = qs
		}

		return result
	}
}
