import { l } from 'ui/lib/lodashImports'
import { tms2_providerPortal } from 'ui/lib/tms2_providerPortal'

export async function getShippingDocuments(
	shipments,
	exportMode: boolean,
): Promise<{
	shipmentDocuments: any[]
	invoicesReceivable: {}
	invoicesPayable: {}
	disputes: {}
}> {
	const shipmentIds = l.map(shipments, (c) => c._id)
	const promises: Promise<any>[] = []

	let shipmentDocuments = []
	promises.push(
		tms2_providerPortal
			.getShipmentDocuments({
				query: {
					shipment_id: { $in: shipmentIds },
				},
				options: {},
				exportMode,
			})
			.then((data) => {
				l.forEach(data, (c) => {
					if (c.date_sent) {
						c.date_sent = c.date_sent.toISOString()
					}
					if (c.payment_due_date) {
						c.payment_due_date = c.payment_due_date.toISOString()
					}
				})
				shipmentDocuments = data
			}),
	)

	const invoicesReceivable = {}
	const invoicesPayable = {}
	const disputes = {}
	l.forEach(shipmentIds, (c) => {
		promises.push(
			tms2_providerPortal
				.getInvoicesReceivable({
					query: {
						shipment_id_array: c,
						invoice_status: { $ne: 'void' },
					},
					options: {
						fields: {
							invoice_date: true,
							invoice_date_paid: true,
						},
					},
					exportMode,
				})
				.then((data) => (invoicesReceivable[c] = data[0])),
		)

		promises.push(
			tms2_providerPortal
				.getInvoicesPayable({
					query: {
						shipment_id_array: c,
					},
					options: {
						fields: {
							invoice_date_paid: true,
							invoice_number_base: true,
							invoice_number_suffix: true,
							payment_method: true,
							reference_number: true,
							invoice_paid: true,
							pdf_url: true,
							excel_url: true,
						},
					},
					exportMode,
				})
				.then((data) => {
					const paid_invoice = l.find(data, (d) => d.invoice_paid)
					invoicesPayable[c] = paid_invoice || data[0]
				}),
		)

		promises.push(
			tms2_providerPortal
				.getDisputes({
					query: {
						shipment_id: c,
					},
					options: {
						fields: {
							dispute_number: true,
							invoiced_currency_code: true,
							correct_amount: true,
							correct_currency_code: true,
							date_of_claim: true,
							overcharge_amount: true,
							overcharge_currency_code: true,
							status: true,
						},
					},
					exportMode,
				})
				.then((data) => (disputes[c] = data[0])),
		)
	})

	await Promise.all(promises)
	return { shipmentDocuments, invoicesReceivable, invoicesPayable, disputes }
}
