import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { t } from 'ui/components/i18n/i18n'
import { SubwayArrow, SubwayStation } from 'ui/components/shared/subway'
import { sosProviderPortalInvoiceProfile } from 'ui/state'
import { displayStation } from '../'

const tPrefix = 'page.providerPortal.'

export const BrokerView: FC = (props: {}) => {
	const state = sosProviderPortalInvoiceProfile.useSubscribe()
	const { subwayState } = state

	return (
		<React.Fragment>
			<SubwayStation top={t(tPrefix + 'fundingRequested')} bottom={''}>
				{displayStation(subwayState.fundingRequested)}
			</SubwayStation>
			<SubwayArrow />
			<SubwayStation top={t(tPrefix + 'customerFunded')} bottom={''}>
				{displayStation(subwayState.customerFunded)}
			</SubwayStation>
			<SubwayArrow />
		</React.Fragment>
	)
}
