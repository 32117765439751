import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { Icon, regularIcons } from '../icon'
import { ConfirmButton } from './ConfirmButton'

export const ConfirmDelete: FC = (props: {
	onConfirm?: () => void
	testId?: string
}) => {
	return (
		<ConfirmButton
			color='gray'
			onConfirm={props.onConfirm}
			testId={props.testId}
			tLabel={'component.confirmButton.confirmDelete'}
		>
			<Icon icon={regularIcons.faTrashAlt} />
		</ConfirmButton>
	)
}
