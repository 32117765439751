import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { apiTypes } from 'ui/api'
import { Button } from 'ui/components/common/button'
import {
	FormStacked,
	FormStackedReadOnly,
	FormStackedTextInput,
	IFormData,
} from 'ui/components/form'
import { validateFormData } from 'ui/components/form/validateFormData'
import { t } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight'
import { fireAndForget } from 'ui/lib/async'
import { l } from 'ui/lib/lodashImports'
import { sosDockSchedulerAppointmentDetails } from '../state'
import { IStateDockSchedulerAppointmentDetails } from '../state/sosDockSchedulerAppointmentDetails'
import { DockSchedulerAppointmentDetailsCard } from './DockSchedulerAppointmentDetailsCard'
import {
	IAppointmentDetailsLoadEndForm,
	loadEndMetaData,
} from './DockSchedulerAppointmentDetailsForms'

const tPrefixLoading = 'page.dockScheduler.appointmentDetails.loadEnd'
const tPrefixUnloading = 'page.dockScheduler.appointmentDetails.unloadEnd'

export const DockSchedulerAppointmentDetailsLoadEnd: FC = (props: {
	state: IStateDockSchedulerAppointmentDetails
	isActive: boolean
	stopType: apiTypes.AppointmentResponse['stopType']
}) => {
	const { state, isActive, stopType } = props

	const tPrefix = stopType === 'delivery' ? tPrefixUnloading : tPrefixLoading

	const loadEndFormData: IFormData<IAppointmentDetailsLoadEndForm> = {
		form: state.loadEndForm,
		metadata: l.cloneDeep(loadEndMetaData),
		tPrefix,
		onUpdateForm: (field, newVal) => {
			const changes = {}
			changes[field] = newVal
			sosDockSchedulerAppointmentDetails.updateLoadEnd(changes)
		},
	}

	return (
		<DockSchedulerAppointmentDetailsCard
			cardType={'loadEnd'}
			isActiveCard={isActive}
			delivery={stopType === 'delivery'}
		>
			<FormStacked>
				{state.appointment.status !== 'loading' &&
					state.appointment.status !== 'unloading' && (
						<div>
							<FormStackedReadOnly
								formData={loadEndFormData}
								field={'loadEndDateTime'}
							/>
							<FormStackedReadOnly
								formData={loadEndFormData}
								field={'loadEndRecordedBy'}
							/>
						</div>
					)}

				<FormStackedTextInput
					formData={loadEndFormData}
					field={'handlingUnitsLoaded'}
					readOnly={!isActive}
					expected={state.generalInfoForm.handlingUnits}
				/>
				<FormStackedTextInput
					formData={loadEndFormData}
					field={'cargoWeight'}
					readOnly={!isActive}
					expected={state.generalInfoForm.expectedWeight}
				/>
				<FormStackedTextInput
					formData={loadEndFormData}
					field={'sealNumber'}
					readOnly={!isActive}
				/>
				<FormStackedTextInput
					formData={loadEndFormData}
					field={'loadEndComments'}
					multiline={true}
					notResizable={true}
					readOnly={!isActive}
				/>
				{isActive && (
					<AlignRight>
						<Button
							color={'blue'}
							isDisabled={!validateFormData(loadEndFormData)}
							isSpinning={state.submitting}
							onClick={() =>
								fireAndForget(
									sosDockSchedulerAppointmentDetails.submitLoadEnd,
									'submitting dock scheduler load end from button click',
								)
							}
						>
							{t(
								state.appointment.status === 'loading' ||
									state.appointment.status === 'unloading'
									? 'submit'
									: 'save',
								tPrefix,
							)}
						</Button>
					</AlignRight>
				)}
			</FormStacked>
		</DockSchedulerAppointmentDetailsCard>
	)
}
