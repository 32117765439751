import { apiTypes } from 'ui/api'

export type ContainerType = apiTypes.Container['type']
// | 'bags'
// | 'bales'
// | 'barrels'
// | 'baskets'
// | 'box'
// | 'boxes'
// | 'buckets'
// | 'bulkheads'
// | 'bundles'
// | 'carboys'
// | 'cartons'
// | 'cases'
// | 'chests'
// | 'coils'
// | 'crates'
// | 'cylinders'
// | 'drums'
// | 'envelopes'
// | 'firkins'
// | 'gaylord'
// | 'hampers'
// | 'hogsheads'
// | 'kegs'
// | 'packages'
// // | 'pails' // not allowed any more
// | 'palletCstm'
// | 'pallet'
// | 'pieces'
// | 'racks'
// | 'reels'
// | 'rolls'
// | 'skid'
// | 'slip_sheets'
// | 'sows'
// | 'spool'
// | 'super_sack'
// | 'totes'
// | 'trailers'
// | 'truckload'
// | 'trucks'
// | 'tubes'
// | 'units'
// | 'unpackaged'
export const containerTypes: ContainerType[] = [
	'bags',
	'bales',
	'barrels',
	'baskets',
	'box',
	'boxes',
	'buckets',
	'bulkheads',
	'bundles',
	'carboys',
	'cartons',
	'cases',
	'chests',
	'coils',
	'crates',
	'cylinders',
	'drums',
	'envelopes',
	'firkins',
	'gaylord',
	'hampers',
	'hogsheads',
	'kegs',
	'packages',
	'pails',
	'palletCstm',
	'pieces',
	'racks',
	'reels',
	'rolls',
	'skid',
	'slip_sheets',
	'sows',
	'spool',
	'super_sack',
	'totes',
	'trailers',
	'truckload',
	'trucks',
	'tubes',
	'units',
	'unpackaged',
]
