import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { theme } from 'ui/theme'
import * as commonClasses from 'ui/theme/common.module.scss'
import { FontSizes } from 'ui/theme/theme'
import * as tableStyles from './TableStyles.module.scss'

export const Table: FC = (props: {
	inset?: boolean
	grayed?: boolean
	children: React.ReactNode
	testId?: string
	fontSize?: FontSizes
}) => {
	let className = tableStyles.dataTable
	className += ' ' + tableStyles.tableHighlight
	className = theme.addClassIf(props.inset, tableStyles.inset, className)
	className = theme.addClassIf(props.grayed, tableStyles.grayed, className)

	return (
		<div className={className}>
			<table
				className={commonClasses[props.fontSize]}
				data-testid={props.testId}
			>
				{props.children}
			</table>
		</div>
	)
}

export const TableHeader: FC = (props: { children: React.ReactNode }) => {
	return <thead>{props.children}</thead>
}

export const TableBody: FC = (props: {
	children: React.ReactNode
	testId?: string
}) => {
	return <tbody data-testid={`${props.testId}-body`}>{props.children}</tbody>
}

export const Row: FC = (props: {
	children: React.ReactNode
	onClick?: (ev: any) => void
	className?: string
	highlighted?: boolean
	faded?: boolean
	testId?: string
}) => {
	let className = props.className || ''
	className = theme.addClassIf(
		props.highlighted,
		tableStyles.highlightedRow,
		className,
	)
	className = theme.addClassIf(props.highlighted, 'highlighted', className)
	className = theme.addClassIf(props.faded, tableStyles.fadedRow, className)
	return (
		<tr
			className={className}
			onClick={props.onClick}
			data-testid={props.testId}
		>
			{props.children}
		</tr>
	)
}
export const Cell: FC = (props: {
	children?: React.ReactNode
	className?: string
	onClick?: (ev: React.MouseEvent<HTMLElement>) => void
	testId?: string
	title?: string
}) => {
	return (
		<td
			title={props.title}
			className={props.className}
			onClick={props.onClick}
			data-testid={props.testId}
		>
			{props.children}
		</td>
	)
}

export const HeaderCell: FC = (props: {
	style?: React.CSSProperties
	children?: React.ReactNode
	className?: string
	colSpan?: number
	onClick?: (ev: React.MouseEvent<HTMLElement>) => void
}) => {
	return (
		<th
			className={props.className}
			onClick={props.onClick}
			style={props.style}
			colSpan={props.colSpan || 1}
		>
			{props.children}
		</th>
	)
}
