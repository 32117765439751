import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { Icon, solidIcons } from 'ui/components/common/icon'
import { OkCancelButtons } from 'ui/components/common/okCancelButtons'
import { t } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight/AlignRight'
import { theme } from 'ui/theme'
import { AddProviderModalMode } from './AddProviderModal'
import { AddProviderModalContent } from './AddProviderModalContent'
import * as classes from './AddProviderModalWarning.module.scss'

export const AddProviderModalWarning: FC = (props: {
	onChangeMode: (newMode: AddProviderModalMode) => void
	onClose: () => void
	tPrefix: string
}) => {
	const body = (
		<div
			className={classes.addProviderModalWarning}
			data-testid={'add-provider-warning'}
		>
			<div className={classes.warningIcon}>
				<Icon
					icon={solidIcons.faExclamationTriangle}
					className={classes.icon}
					color='yellow'
				/>
				<div className={theme.getTextColor('yellow')}>
					{t('warning', props.tPrefix)}
				</div>
			</div>

			<div className={classes.warningMessage}>
				<div>{t('warningMessage1', props.tPrefix)}</div>
				<div>
					{t('warningMessage2', props.tPrefix)}
					<span className={classes.highlight}>
						{t('warningMessage3', props.tPrefix)}
					</span>
					{t('warningMessage4', props.tPrefix)}
				</div>
			</div>
		</div>
	)

	const footer = (
		<div>
			<AlignRight>
				<OkCancelButtons
					ok={t('confirm', props.tPrefix)}
					okColor='yellow'
					isValid={true}
					cancel={t('cancel', props.tPrefix)}
					onOk={() => {
						props.onChangeMode('form')
					}}
					onCancel={props.onClose}
					okTestId={'add-provider-warning-ok'}
				/>
			</AlignRight>
		</div>
	)

	return <AddProviderModalContent body={body} footer={footer} />
}
