import { apiTypes } from 'ui/api'
import { l } from '../lodashImports'

/**
 * BOTH OF THESE FUNCTIONS ASSUMES THAT YOU HAVE AN IMAGE STRING THAT WOULD WORK RIGHT INSIDE A <img> HTML ELELMENT
 * Example:
 * data:image/${FORMAT};base64,${RANDOM CHARACTER STRING}
 */

const allowedImageTypes: apiTypes.DocumentImageFormat[] = [
	'JPEG',
	'PNG',
	'GIF',
	'TIFF',
]

// Will return the ${FORMAT} piece of the example date string
export const getApiImageTypeFromImageString = (
	image: string,
): apiTypes.DocumentImageFormat => {
	const uploadedLogoType = image
		.split('data:image/')[1]
		.split(';')[0]
		.toUpperCase()
	const matchingImageType: apiTypes.DocumentImageFormat = l.find(
		allowedImageTypes,
		(imageType) => imageType === uploadedLogoType,
	)
	return matchingImageType
}

// Will return the ${RANDOM CHARACTER STRING} piece of the example date string
export const getBase64StringFromImageString = (image: string): string => {
	return image.split('base64,')[1]
}
