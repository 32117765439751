import { FC } from 'app/FunctionalComponent'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { apiTypes } from 'ui/api'
import { Button } from 'ui/components/common/button'
import { Card } from 'ui/components/common/card'
import { DevOnly } from 'ui/components/common/dev-only/DevOnly'
import { t } from 'ui/components/i18n/i18n'
import { fireAndForget } from 'ui/lib/async/fireAndForget'
import { l } from 'ui/lib/lodashImports'
import { sosShipmentProfileShipper } from 'ui/pages/shipment-profile/shipper'
import { ShipperShipmentProfileRatesTable } from 'ui/pages/shipment-profile/shipper/components/rates/ShipperShipmentProfileRatesTable'
import classes from 'ui/pages/shipment-profile/shared/components/rates/ShipmentProfileRatesBrokerActions.module.scss'
import { ShipmentProfileRatingErrorsTable } from 'ui/pages/shipment-profile/shared/components/rates/ShipmentProfileRatingErrorsTable'
import { processShipperRateRows } from '../../functions/processShipperRateRows'
import { trickleRateShipperShipment } from '../../functions/trickleRateShipperShipment'
import { ShipperApiCalls } from '../../sosShipmentProfileShipper'
import { useOnce } from 'ui/components/hooks'
import { windowUtils } from 'ui/components/common/router'
import { log } from 'ui/lib/log'
import { RateBookingDetails } from 'ui/pages/shipment-profile/shared/functions/rateBookingDetailsTypes'

const tPrefix = 'page.shipmentProfile.shipper.rates'

export const ShipperShipmentProfileRates: FC = (props: {
	shipment: apiTypes.ShipmentResponse
	isRating: boolean
	setIsRating: React.Dispatch<React.SetStateAction<boolean>>
	websocketUrl: string
	runningApiCalls: ShipperApiCalls[]
	setIsBookCarrierModalOpen: React.Dispatch<React.SetStateAction<boolean>>
	setRateBookingDetails: React.Dispatch<
		React.SetStateAction<RateBookingDetails>
	>
}) => {
	const {
		shipment,
		isRating,
		setIsRating,
		websocketUrl,
		runningApiCalls,
		setIsBookCarrierModalOpen,
		setRateBookingDetails,
	} = props
	const rateTableRows = processShipperRateRows(shipment)

	useOnce(() => {
		// const location = sosRouter.getSos().getState().currentLocation
		const url = windowUtils.getWindow().location.href.replace('#/', '')
		const validator = new URLSearchParams(new URL(url).search).get('rerate')
		if (validator && Date.now() < +validator) {
			fireAndForget(() => processRates(), 'get new rates for edited shipment')
		} else {
			log('ShipperShipmentProfileRates', 'rerate invalid')
		}
	})

	const processRates = async (): Promise<void> => {
		setIsRating(true)
		await trickleRateShipperShipment(websocketUrl, () => {
			setIsRating(false)
			fireAndForget(
				() => sosShipmentProfileShipper.fetchShipment(shipment.id),
				'Refetching shipment after trickle rating',
			)
		})
	}

	return (
		<div>
			<div>
				<ShipperShipmentProfileRatesTable
					shipment={shipment}
					tPrefix={tPrefix}
					rateTableRows={rateTableRows}
					isRating={isRating}
					isBooking={l.includes(runningApiCalls, 'booking')}
					setIsBookCarrierModalOpen={setIsBookCarrierModalOpen}
					setRateBookingDetails={setRateBookingDetails}
				/>
				<Row>
					<Col>
						<DevOnly>
							<div className={classes.shipmentProfileNewRatesButton}>
								<Button color='orange' onClick={processRates}>
									{t('getNewRates', tPrefix)}
								</Button>
							</div>
						</DevOnly>
					</Col>
					{/* <Col>
						{shipment.shipmentStatus !== 'void' && (
							<AlignRight>
								<div className={classes.shipmentProfileLogOfferButton}>
									<Button
										color='blue'
										bootstrapStyles={true}
										isSmall={true}
										onClick={() => {
											fireAndForget(
												() =>
													sosMakeOfferPage.navigateToMakeOfferPage(
														shipment.id,
														null,
														true,
													),
												`navigating to log offer page ${shipment.id}`,
											)
										}}
										testId='shipment-profile-log-offer'
									>
										{t('logOfferForCarrier', tPrefix)}
									</Button>
								</div>
							</AlignRight>
						)}
					</Col> */}
				</Row>
			</div>
			{shipment.ratingErrors?.length > 0 && (
				<Card title={t('ratingErrors', tPrefix)} color={'gray'}>
					<ShipmentProfileRatingErrorsTable
						shipment={shipment}
						tPrefix={tPrefix}
					/>
				</Card>
			)}
		</div>
	)
}
