import { FC } from 'app/FunctionalComponent'
import React from 'react'
import useDimensions from 'react-use-dimensions'
import { l } from 'ui/lib/lodashImports'
import styles from './CustomColumn.module.scss'

export interface ILayoutEntry {
	minWidth?: number
	maxWidth?: number
	targetScale?: number
	renderer: (width: number) => React.ReactNode
}

interface IProcessedLayoutEntry {
	entry: ILayoutEntry
	spaceBefore: number
	spaceAbove: number
	width: number
	rendered: any
}
interface ILayoutRow {
	isFull: boolean
	items: IProcessedLayoutEntry[]
}

const CustomColumn: FC = (props: {
	entries: ILayoutEntry[]
	debug?: boolean
}) => {
	const [ref, refSize] = useDimensions()

	const margin = 3

	const rows: ILayoutRow[] = []
	let items: IProcessedLayoutEntry[] = []

	if (refSize && refSize.width > 0) {
		let widthLeft = refSize.width

		l.forEach(props.entries, (c) => {
			let target = c.targetScale * refSize.width
			if (c.minWidth && target < c.minWidth) {
				target = c.minWidth
			}
			if (c.maxWidth && target > c.maxWidth) {
				target = c.maxWidth
			}

			if (target > widthLeft) {
				// newline
				rows.push({
					isFull: widthLeft <= 1,
					items,
				})
				items = []

				widthLeft = refSize.width
			}

			widthLeft -= target

			items.push({
				entry: c,
				spaceBefore: 0,
				spaceAbove: rows.length > 0 ? margin : 0,
				width: target,
				rendered: null,
			})
		})
		if (items.length > 0) {
			rows.push({ isFull: widthLeft <= 1, items })
		}

		// Add margins
		l.forEach(rows, (c) => {
			let totalMargins = margin * (c.items.length - 1)
			let marginPerItem = totalMargins / c.items.length
			if (!c.isFull) {
				totalMargins += margin
				marginPerItem = totalMargins / (c.items.length + 1)
			}

			l.forEach(c.items, (d, dIdx) => {
				const target = d.width - marginPerItem

				d.width = target
				d.spaceBefore = dIdx > 0 ? margin : 0
				d.rendered = d.entry.renderer(d.width)
			})
		})
	}

	return (
		<div ref={ref}>
			{l.map(rows, (c, cIdx) => (
				<div
					key={cIdx}
					className={
						props.debug
							? styles.customColumn + ' ' + styles.test
							: styles.customColumn
					}
				>
					{l.map(c.items, (d, dIdx) => (
						<div
							key={dIdx}
							className={
								props.debug
									? styles.customColumnItem + ' ' + styles.test
									: styles.customColumnItem
							}
							style={{
								marginLeft: d.spaceBefore,
								marginTop: d.spaceAbove,
								width: d.width,
								maxWidth: d.width,
								minWidth: d.width,
							}}
						>
							{d.rendered}
						</div>
					))}
				</div>
			))}
		</div>
	)
}

export { CustomColumn }
