import { FC } from 'app/FunctionalComponent'
import { DateTime } from 'luxon'
import React from 'react'
import { AngryRedBox } from 'ui/components/common/angry-red-box'
import { Button } from 'ui/components/common/button'
import { Separator } from 'ui/components/common/separator'
import { useOnce } from 'ui/components/hooks'
import { t, tArgz } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight'
import { FlexItem, FlexRow } from 'ui/components/layout/flexRow'
import { _idx } from 'ui/lib'
import { fireAndForget } from 'ui/lib/async'
import { sos2 } from 'ui/lib/state/sos2'
import { CheckoutErrorModal } from 'ui/pages/dock-scheduler/appointment-details/DockSchedulerAppointmentDetailsCheckOutErrorModal'
import { IScheduleTimeSlot } from '../components/DockSchedulerTimeSlots'
import { sosDockScheduler, sosDockSchedulerAppointmentDetails } from '../state'
import { DockSchedulerAppointmentDetailsCheckIn } from './DockSchedulerAppointmentDetailsCheckIn'
import { CheckinErrorModal } from './DockSchedulerAppointmentDetailsCheckInErrorModal'
import { DockSchedulerAppointmentDetailsCheckOut } from './DockSchedulerAppointmentDetailsCheckOut'
import { DockSchedulerAppointmentDetailsGeneralInformation } from './DockSchedulerAppointmentDetailsGeneralInformation'
import { DockSchedulerAppointmentDetailsLoadEnd } from './DockSchedulerAppointmentDetailsLoadEnd'
import { DockSchedulerAppointmentDetailsLoadStart } from './DockSchedulerAppointmentDetailsLoadStart'
import { DockSchedulerAppointmentDetailsSubway } from './DockSchedulerAppointmentDetailsSubway'
import { TimeslotSelectorModal } from './TimeslotSelectorModal'

export const tPrefixDockSchedulerAppointmentDetails =
	'page.dockScheduler.appointmentDetails'

export const appointmentDetailsStatusToNumberMap = {
	'not-arrived': 0,
	arrived: 1,
	loading: 2,
	unloading: 2,
	loaded: 3,
	unloaded: 3,
	departed: 4,
}

export const DockSchedulerAppointmentDetails: FC = () => {
	const state = sos2.useSubscription(
		sosDockSchedulerAppointmentDetails.getSos(),
	)
	const stateDockScheduler = sos2.useSubscription(sosDockScheduler.getSos())
	useOnce(() => {
		fireAndForget(async () => {
			await sosDockSchedulerAppointmentDetails.fetchAppointmentAndShipment()
		}, 'fetching appointment details')
	})
	const { appointment } = state
	if (!appointment) {
		return (
			<div>
				{state.fetchError && <AngryRedBox>{state.fetchError}</AngryRedBox>}
			</div>
		)
	}
	const appointmentStatus = appointment.status
	const currentStep = appointmentDetailsStatusToNumberMap[appointmentStatus]
	const editStage = state.editingStage
	return (
		<div>
			<AlignRight>
				<DockSchedulerAppointmentDetailsSubway
					appointmentStatus={appointment.status}
					flow={appointment.stopType}
				/>
			</AlignRight>
			<h1>{t('header', tPrefixDockSchedulerAppointmentDetails)}</h1>
			<Separator />
			{state.fetchError && <AngryRedBox>{state.fetchError}</AngryRedBox>}

			{appointment.status === 'not-arrived' && (
				<AlignRight>
					<FlexRow>
						<FlexItem>
							<Button
								color={'gray'}
								onClick={() =>
									fireAndForget(
										() =>
											sosDockSchedulerAppointmentDetails.fetchOtherAppointmentsForCurrentAppointmentReschedule(
												DateTime.fromISO(
													state.appointment.startTime,
												).toISODate(),
											),
										'fetching other appointments to resschedule appointment in appointment details',
									)
								}
							>
								{t(
									'editAppointmentTime',
									tPrefixDockSchedulerAppointmentDetails,
								)}
							</Button>
						</FlexItem>
						<FlexItem>
							<Button
								color={'red'}
								onClick={() =>
									fireAndForget(
										sosDockSchedulerAppointmentDetails.deleteAppointment,
										'deleting appointment from appointment details',
									)
								}
								isSpinning={state.deletingAppointment}
							>
								{t('deleteAppointment', tPrefixDockSchedulerAppointmentDetails)}
							</Button>
						</FlexItem>
					</FlexRow>
				</AlignRight>
			)}
			<FlexRow fullWidth={true} wrap={false}>
				<DockSchedulerAppointmentDetailsGeneralInformation state={state} />
				<DockSchedulerAppointmentDetailsCheckIn
					state={state}
					isActive={
						(!editStage && currentStep === 0) || editStage === 'checkIn'
					}
				/>
				{currentStep >= 1 && (
					<DockSchedulerAppointmentDetailsLoadStart
						state={state}
						isActive={
							(!editStage && currentStep === 1) || editStage === 'loadStart'
						}
						stopType={appointment.stopType}
					/>
				)}
				{currentStep >= 2 && (
					<DockSchedulerAppointmentDetailsLoadEnd
						state={state}
						isActive={
							(!editStage && currentStep === 2) || editStage === 'loadEnd'
						}
						stopType={appointment.stopType}
					/>
				)}
				{currentStep >= 3 && (
					<DockSchedulerAppointmentDetailsCheckOut
						state={state}
						isActive={
							(!editStage && currentStep === 3) || editStage === 'checkOut'
						}
					/>
				)}
			</FlexRow>
			<CheckoutErrorModal state={state} />
			<CheckinErrorModal state={state} />
			<TimeslotSelectorModal
				isOpen={state.editAppointmentModalOpen}
				onModalClose={() =>
					sosDockSchedulerAppointmentDetails.setEditAppointmnetModalOpen(false)
				}
				docks={stateDockScheduler.docks}
				appointments={state.existingOtherAppointmentsForEditing}
				fetchingAppointments={state.fetchingOtherAppointments}
				date={state.dateOnEditAppointmentModal || appointment.startTime}
				onUpdateDate={(date: string) =>
					fireAndForget(
						() =>
							sosDockSchedulerAppointmentDetails.fetchOtherAppointmentsForCurrentAppointmentReschedule(
								date,
							),
						'fetching other appointments to resschedule appointment in appointment details after updating date in modal',
					)
				}
				equipmentType={
					_idx(() => state.shipment.equipmentType) ||
					_idx(() => state.appointment.shipmentInfo.equipmentType)
				}
				flow={state.appointment.stopType}
				mode={
					_idx(() => state.shipment.mode) ||
					_idx(() => state.appointment.shipmentInfo.mode)
				}
				zipcode={
					stateDockScheduler.currentLocation.defaults.defaultDeliveryAddress
						.address.zip
				}
				selectedTimeSlot={state.selectedTimeSlotOnEditAppointmentModal}
				onSelectTimeSlot={(slot: IScheduleTimeSlot) => {
					sosDockSchedulerAppointmentDetails.changeSelectedTimeSlotForAppointmentUpdate(
						slot,
					)
				}}
				selectedTimeSlotWarning={
					state.invalidTimeslotReason
						? tArgz(
								'selectedTimeSlotIsInvalid',
								{ reason: state.invalidTimeslotReason },
								tPrefixDockSchedulerAppointmentDetails,
						  )
						: null
				}
				onSubmit={sosDockSchedulerAppointmentDetails.updateAppointmentTime}
				submitting={state.submitting}
				title={t(
					'rescheduleAppointment',
					tPrefixDockSchedulerAppointmentDetails,
				)}
			/>
		</div>
	)
}
