import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { apiTypes } from 'ui/api'
import { ndash } from 'ui/components/common'
import { t, tString } from 'ui/components/i18n/i18n'
import { getAccessorialsWithInfo } from 'ui/pages/new-quote/accessorials'

interface IAccessorialInfoFormatted {
	info: { [accessorialInfo: string]: string }
}

export interface IFormattedAccessorials {
	[accessorial: string]: IAccessorialInfoFormatted
}

export const FormattedStopAccessorials: FC = (props: {
	stop: apiTypes.OriginStop | apiTypes.DestinationStop
	stopType: 'pickup' | 'delivery'
}) => {
	const formattedAccessorials = getAccessorialsWithInfo(
		props.stop.metaData.accessorials,
		props.stop.metaData.accessorialsInfo,
	)
	const activeAccessorials = Object.keys(formattedAccessorials)

	return (
		<div>
			{activeAccessorials.map((accessorialKey: string) => (
				<div key={accessorialKey}>
					<FormattedAccessorial
						accessorialKey={accessorialKey}
						accessorialInfo={formattedAccessorials[accessorialKey]}
						stopType={props.stopType}
					/>
				</div>
			))}
		</div>
	)
}

export const FormattedAccessorial: FC = (props: {
	accessorialKey: string
	accessorialInfo?: IAccessorialInfoFormatted
	stopType: 'pickup' | 'delivery'
}) => {
	const { accessorialKey, accessorialInfo, stopType } = props

	const tPrefixPickup = 'page.shipmentProfile.cardDetails.pickupAccessorials'
	const tPrefixDelivery =
		'page.shipmentProfile.cardDetails.deliveryAccessorials'
	const tPrefix = stopType === 'delivery' ? tPrefixDelivery : tPrefixPickup

	return (
		<div>
			{t(accessorialKey, tPrefix)}
			{accessorialInfo &&
				Object.keys(accessorialInfo.info).map((key, keyIdx) => (
					<div key={keyIdx}>
						{ndash +
							' ' +
							tString(key, tPrefix) +
							': ' +
							accessorialInfo.info[key]}
					</div>
				))}
		</div>
	)
}
