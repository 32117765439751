import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { l } from 'ui/lib/lodashImports'
import styles from './ApiExamples.module.scss'
import { ExampleData } from './ExampleJsonData'

export const Examples: FC = (props: {
	selectedMenu: string
	selectedSubMenu: string
	data: any
}) => {
	if (!props.data) {
		return <div>&nbsp;</div>
	}

	const a = l.find(props.data.examples, (c) => c.name === props.selectedMenu)
	if (!a) {
		return null
	}
	const b = l.find(a.examples, (c) => c.name === props.selectedSubMenu)
	if (!b) {
		return null
	}

	return (
		<div>
			{l.map(b.examples, (c, cIdx) => (
				<div key={cIdx}>
					<h2 className={styles.h2}>{c.name}</h2>
					<h3 className={styles.h3}>{c.subName}</h3>

					<ExampleData name='Query Params' json={c.queryParams} />
					<ExampleData name='Path Params' json={c.pathParams} />
					<ExampleData name='Request' json={c.request} />
					<ExampleData name='Response' json={c.response} />
				</div>
			))}
		</div>
	)
}
