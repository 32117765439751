import React from 'react'
import { l } from 'ui/lib/lodashImports'
import { theme } from 'ui/theme'
import { IDataTableHeaderProcessed } from './IDataTableHeaderProcessed'
import { Cell } from './Table'
import * as classes from './TableStyles.module.scss'

export const DataCell = <T extends any>(props: {
	header?: IDataTableHeaderProcessed<T>
	row: T
	className?: string
	testId?: string
}): React.ReactElement => {
	const { header, row } = props

	if (!header) {
		return <Cell className={props.className} />
	}

	const { renderer } = header
	let className = props.className ? props.className + ' ' : ''
	className += theme.getColorTable(header.colorIndex)

	if (row.color && row.color[header.field]) {
		className = theme.getColorTable(row.color[header.field])
	}

	if (header.columnAlign && header.columnAlign !== 'left') {
		className = theme.addClass(classes[header.columnAlign], className)
	}
	if (header.monospace) {
		className = theme.addClass(classes.monospace, className)
	}
	if (header.format && header.field && !l.isNil(row[header.field])) {
		const data = header.format(row[header.field])
		return (
			<Cell testId={props.testId} className={className}>
				{renderer ? renderer(data, row) : data}
			</Cell>
		)
	}
	if (header.field && !l.isNil(row[header.field])) {
		const data = row[header.field]
		return (
			<Cell
				testId={props.testId}
				className={className}
				title={
					header.titleRenderer ? header.titleRenderer(data, row) : undefined
				}
			>
				{renderer ? renderer(data, row) : data}
			</Cell>
		)
	}
	// if (header.fieldPath) {
	//   return <Cell>{_.get(row, header.fieldPath)}</Cell>
	// }
	return (
		<Cell className={className} testId={props.testId}>
			&ndash;
		</Cell>
	)
}
