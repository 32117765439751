import { FC } from 'app/FunctionalComponent'
import React from 'react'
import classes from 'ui/components/shared/accessorials/Accessorials.module.scss'
import { l } from 'ui/lib/lodashImports'
import { IAccessorialMetadata } from 'ui/pages/new-quote/accessorialsTypes'
import { StopType } from 'ui/pages/new-quote/state/sosNewQuote'
import { AccessorialCheckbox } from '.'
import { AccessorialInput } from './AccessorialInput'
import { AccessorialSelect } from './AccessorialSelect'

export interface IAccessorialProps {
	accessorialObject: any
	accessorialMetadata: IAccessorialMetadata
	updateState: (changes: any, stopId?: string) => void
	tPrefix?: string
	stopId?: string
	mode?: StopType
}

export const Accessorials: FC = (props: {
	metadata: IAccessorialMetadata[]
	accessorialObject: {}
	updateState: (changes: any, stopId?: string) => void
	tPrefix?: string
	stopId?: string
	mode?: StopType
}) => {
	const { metadata, accessorialObject } = props

	return (
		<div>
			{metadata.map((c, cIdx) => {
				const _props: IAccessorialProps = {
					accessorialObject,
					accessorialMetadata: c,
					updateState: props.updateState,
					tPrefix: props.tPrefix,
					stopId: props.stopId,
					mode: props.mode,
				}

				return (
					<div key={cIdx}>
						{(c.type === 'checkbox' || c.type === undefined) && (
							<AccessorialCheckbox {..._props} />
						)}
						{c.type === 'text' && <AccessorialInput {..._props} />}
						{c.type === 'select' && <AccessorialSelect {..._props} />}
						{c.childrenAccessorials && accessorialObject[c.accessorialKey] && (
							<div className={classes.childAccessorialsIndent}>
								<Accessorials
									metadata={l.flatMap(c.childrenAccessorials)}
									accessorialObject={accessorialObject}
									stopId={props.stopId}
									mode={props.mode}
									updateState={props.updateState}
									tPrefix={props.tPrefix}
								/>
							</div>
						)}
					</div>
				)
			})}
		</div>
	)
}
