import { l } from 'ui/lib/lodashImports'
import { spliceString } from 'ui/lib/misc/spliceString'
import { includesStrings } from 'ui/lib/misc/includesStrings'
import { stringExists } from 'ui/lib/misc'

export function addStars(string: string): string {
	if (string.slice(0, 1) === '(') {
		string = spliceString(string, '*', 1)
	} else {
		string = '*' + string
	}
	if (string.slice(-1) === ')') {
		string = spliceString(string, '*', string.length - 1)
	} else {
		string = string + '*'
	}
	return string
}

export function phraseToWildcardAndSearch(
	phrase: string,
	ignoreWords?: string[],
): string {
	// don't try to handle phrases that already include a star
	if (!l.includes(phrase, '*')) {
		return l.join(
			l.map(l.split(phrase, ' '), (c) => {
				if (!l.includes(ignoreWords, c)) {
					return addStars(c)
				}
				return c
			}),
			' AND ',
		)
	}
	return phrase
}

/*
	Takes a sting that is meant to be an Elasticsearch query and formats search 
	strings to allow for wildcard searches before and after each string.
	It also ignores ignore words which are usually going to be 'AND' and 'OR'
 */
export function queryToWildcardAndSearch(
	query: string,
	ignoreWords?: string[],
): string {
	if (!stringExists(query)) {
		return query
	}
	let processedQuery = query
	if (ignoreWords) {
		l.forEach(ignoreWords, (splitterWord) => {
			processedQuery = l.join(
				l.map(l.split(processedQuery, splitterWord), (stringFragment) => {
					// recursively call queryToWildcardAndSearch again with a fragment that contains another ignore word
					if (includesStrings(stringFragment, ignoreWords)) {
						stringFragment = queryToWildcardAndSearch(
							stringFragment,
							l.filter(ignoreWords, (c) => c !== splitterWord),
						)
					} else {
						stringFragment = phraseToWildcardAndSearch(
							stringFragment,
							ignoreWords,
						)
					}
					return stringFragment
				}),
				splitterWord,
			)
		})
	} else {
		processedQuery = phraseToWildcardAndSearch(processedQuery)
	}

	return processedQuery
}
