import React from 'react'
import { FC } from 'app/FunctionalComponent'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { apiTypes } from 'ui/api'
import { Button } from 'ui/components/common/button'
import { Card } from 'ui/components/common/card'
import { DevOnly } from 'ui/components/common/dev-only/DevOnly'
import { useOnce, watch } from 'ui/components/hooks'
import { t } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight/AlignRight'
import { Spacer } from 'ui/components/layout/spacer'
import { fireAndForget } from 'ui/lib/async/fireAndForget'
import { BrokerBuySection } from 'ui/pages/shipment-profile/broker/components/buy-section'
import { BrokerSellSection } from 'ui/pages/shipment-profile/broker/components/sell-section'
import {
	getMarketRateFromRateRows,
	trickleRateBrokerShipment,
} from 'ui/pages/shipment-profile/broker/functions'
import { findMostRelevantBuyRate } from 'ui/pages/shipment-profile/broker/functions/findMostRelevantBuyRate'
import { processBrokerRateRows } from 'ui/pages/shipment-profile/broker/functions/processBrokerRateRows'
import classes from 'ui/pages/shipment-profile/shared/components/rates/ShipmentProfileRatesBrokerActions.module.scss'
import { sosMakeOfferPage } from 'ui/pages/spot-quote'
import { sosShipmentProfileBroker } from 'ui/pages/shipment-profile/broker'
import { BrokerApiCalls } from 'ui/pages/shipment-profile/broker/sosShipmentProfileBroker'
import { ShipmentProfileRatingErrorsTable } from 'ui/pages/shipment-profile/shared/components/rates/ShipmentProfileRatingErrorsTable'
import { pollInviteConnections } from 'ui/pages/shipment-profile/broker/functions/pollInviteConnections'
import { BrokerShipmentProfileRatesTable } from 'ui/pages/shipment-profile/broker/components/rates-table'
import { MarketRate } from 'ui/pages/shipment-profile/broker/components/marketrate/MarketRate'
import { RateBookingDetails } from 'ui/pages/shipment-profile/shared/functions/rateBookingDetailsTypes'

const tPrefix = 'page.shipmentProfile.broker.rates'

export const BrokerShipmentProfileRates: FC = (props: {
	shipment: apiTypes.BrokerShipmentResponse
	clientConfig: apiTypes.ClientConfigResponse
	runningApiCalls: BrokerApiCalls[]
	isRating: boolean
	setIsRating: React.Dispatch<React.SetStateAction<boolean>>
	websocketUrl: string
	setIsBookCarrierModalOpen: React.Dispatch<React.SetStateAction<boolean>>
	setIsUnbookCarrierModalOpen: React.Dispatch<React.SetStateAction<boolean>>
	rateBookingDetails: RateBookingDetails
	setRateBookingDetails: React.Dispatch<
		React.SetStateAction<RateBookingDetails>
	>
}) => {
	const {
		shipment,
		clientConfig,
		runningApiCalls,
		isRating,
		setIsRating,
		websocketUrl,
		setIsBookCarrierModalOpen,
		setIsUnbookCarrierModalOpen,
		rateBookingDetails,
		setRateBookingDetails,
	} = props
	let rateTableRows = processBrokerRateRows(
		shipment,
		clientConfig.defaultMarkupLogic,
	)
	const marketRate = getMarketRateFromRateRows(shipment)

	const offer = shipment.offers[0]
	const buyRate = findMostRelevantBuyRate(shipment.rates, offer)

	useOnce(() => {
		if (shipment.id) {
			const updatedRateBookingDetails = {
				...rateBookingDetails,
				shipmentId: shipment?.id,
				buyRateId: buyRate?.id,
				providerName: buyRate?.carrier,
				isBroker: true,
			}
			setRateBookingDetails(updatedRateBookingDetails)
		}
	})

	// Poll for invite connections
	watch(() => {
		const pollIntervalMs = 2000
		const intervalId = setInterval(async () => {
			rateTableRows = await pollInviteConnections(
				rateTableRows,
				clientConfig.defaultMarkupLogic,
				shipment.id,
			)
		}, pollIntervalMs)

		return () => {
			clearInterval(intervalId)
		}
	})

	return (
		<div>
			<Row>
				<Col md={5}>
					<BrokerSellSection
						shipment={shipment}
						clientConfig={clientConfig}
						runningApiCalls={runningApiCalls}
						shipmentIsRating={isRating}
					/>
				</Col>
				<Col md={5}>
					<BrokerBuySection
						shipment={shipment}
						offer={offer}
						buyRate={buyRate}
						runningApiCalls={runningApiCalls}
						setIsBookCarrierModalOpen={setIsBookCarrierModalOpen}
						setIsUnbookCarrierModalOpen={setIsUnbookCarrierModalOpen}
						setRateBookingDetails={setRateBookingDetails}
						rateBookingDetails={rateBookingDetails}
					/>
				</Col>
				<Col md={2}>
					<MarketRate
						marketRate={marketRate}
						markupLogic={clientConfig.defaultMarkupLogic}
					/>
				</Col>
			</Row>
			<Spacer />
			<div>
				<BrokerShipmentProfileRatesTable
					shipment={shipment}
					markupLogic={clientConfig.defaultMarkupLogic}
					tPrefix={tPrefix}
					rateTableRows={rateTableRows}
					isRating={isRating}
					setRateBookingDetails={setRateBookingDetails}
					rateBookingDetails={rateBookingDetails}
				/>
				<Row>
					<Col>
						<DevOnly>
							<div className={classes.shipmentProfileNewRatesButton}>
								<Button
									color='orange'
									onClick={async () => {
										setIsRating(true)
										await trickleRateBrokerShipment(websocketUrl, () => {
											setIsRating(false)
											fireAndForget(
												() =>
													sosShipmentProfileBroker.fetchShipment(shipment.id),
												'Refetching shipment after trickle rating',
											)
										})
									}}
								>
									{t('getNewRates', tPrefix)}
								</Button>
							</div>
						</DevOnly>
					</Col>
					<Col>
						{shipment.shipmentStatus !== 'void' && (
							<AlignRight>
								<div className={classes.shipmentProfileLogOfferButton}>
									<Button
										color='blue'
										bootstrapStyles={true}
										isSmall={true}
										onClick={() => {
											fireAndForget(
												() =>
													sosMakeOfferPage.navigateToMakeOfferPage(
														shipment.id,
														null,
														true,
													),
												`navigating to log offer page ${shipment.id}`,
											)
										}}
										testId='shipment-profile-log-offer'
									>
										{t('logOfferForCarrier', tPrefix)}
									</Button>
								</div>
							</AlignRight>
						)}
					</Col>
				</Row>
			</div>
			{shipment.ratingErrors?.length > 0 && (
				<Card title={t('ratingErrors', tPrefix)} color={'gray'}>
					<ShipmentProfileRatingErrorsTable
						shipment={shipment}
						tPrefix={tPrefix}
					/>
				</Card>
			)}
		</div>
	)
}
