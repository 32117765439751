import React from 'react'
import { Button } from 'ui/components/common/button'
import { Modal } from 'ui/components/common/modal'
import { t } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight'
import { Spacer } from 'ui/components/layout/spacer'
import { FC } from 'ui/FunctionalComponent'
import { FedexIpdManifestShipmentsTable } from './FedexIpdManifestShipmentsTable'

import * as classes from './FedexIpdManifestShipmentsTableModal.module.scss'

export const FedexIpdManifestShipmentsTableModal: FC = (props: {
	isModalOpen: boolean
	onModalClose: () => void
	shipmentIds: string[]
	tPrefix: string
}) => {
	const { shipmentIds, tPrefix } = props

	const tPrefixFedexIpdManifestModal =
		tPrefix + '.fedexIpdManifestShipmentsTableModal'

	const onClose = (): void => {
		props.onModalClose()
	}

	return (
		<Modal
			content={() => (
				<div
					className={classes.fedexIpdManifestShipmentsTableModal}
					data-testid={'fedex-ipd-manifest-shipments-table-modal'}
				>
					<Spacer height={'10px'} />

					<FedexIpdManifestShipmentsTable
						shipmentIds={shipmentIds}
						tPrefix={tPrefix}
					/>

					<Spacer height={'10px'} />

					<AlignRight>
						<Button
							color={'green'}
							onClick={onClose}
							testId={'fedex-ipd-manifest-shipments-table-modal-ok-button'}
						>
							{t('ok', tPrefixFedexIpdManifestModal)}
						</Button>
					</AlignRight>
				</div>
			)}
			isOpen={props.isModalOpen}
			onModalClose={onClose}
			closeModalX={true}
			title={t('shipments', tPrefixFedexIpdManifestModal)}
		/>
	)
}
