import { FC } from 'app/FunctionalComponent'
import React from 'react'
import {
	FormStacked,
	FormStackedCheckbox,
	FormStackedSelect,
	FormStackedTextInput,
	IFormData,
} from 'ui/components/form'
import {
	billToThirdPartyFormMetadata,
	IBillToThirdPartyForm,
} from './forms/formBillToThirdParty'
import { sosNewQuote } from './state'
import { IStateNewQuote } from './state/sosNewQuote'

const tPrefix = 'page.newQuote.billToThirdParty'

export const BillToThirdParty: FC = (props: { state: IStateNewQuote }) => {
	const { state } = props

	return (
		<div>
			<BillToThirdPartyForm form={state.billToThirdPartyForm} />
		</div>
	)
}

export const BillToThirdPartyForm: FC = (props: {
	form: IBillToThirdPartyForm
}) => {
	const formData: IFormData<IBillToThirdPartyForm> = {
		form: props.form,
		metadata: billToThirdPartyFormMetadata,
		onUpdateForm: sosNewQuote.updateBillToThirdPartyForm,
		tPrefix,
	}

	return (
		<div>
			<FormStacked>
				<FormStackedTextInput field='carrierName' formData={formData} />
				<FormStackedSelect
					field='carrierType'
					formData={formData}
					tPrefix={tPrefix + '.carrierType'}
				/>
				<FormStackedTextInput field='proNumber' formData={formData} />
				<FormStackedTextInput field='accountNumber' formData={formData} />
				<FormStackedCheckbox field='usingBroker' formData={formData} />
			</FormStacked>
		</div>
	)
}
