import React from 'react'
import { l } from 'ui/lib/lodashImports'
import { Radio } from '../common/radioButton'
import { t } from '../i18n/i18n'
import { FormVerticalItem } from './FormVerticalItem'
import { IFormData } from './IFormData'

export function FormVerticalRadio<T>(props: {
	formData: IFormData<T>
	selected: string
	options: (keyof T)[]
	onChange: (option: keyof T) => void
	labelSide?: 'left' | 'right'
	testId?: string
}): React.ReactElement {
	const { formData, selected, options, labelSide, testId } = props

	return (
		<div>
			{l.map(options, (option, cIdx) => {
				const meta = props.formData.metadata[option]
				return (
					<div key={cIdx}>
						<FormVerticalItem<T>
							field={option}
							formData={formData}
							labelSide={labelSide}
							color={'black'}
							hideLabel={true}
						>
							<Radio
								value={selected === option}
								onSelected={() => props.onChange(option)}
								testId={testId || 'form-' + (meta.label || option)}
								readOnly={meta.readOnly}
							>
								{t(formData.tPrefix + '.' + (meta.label || option))}
							</Radio>
						</FormVerticalItem>
					</div>
				)
			})}
		</div>
	)
}
