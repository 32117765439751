import * as baseTheme from './baseTheme.module.scss'
import * as tms2Theme from './tms2Theme.module.scss'
import * as tms3Theme from './tms3Theme.module.scss'
import * as tmsDarkTheme from './tmsDarkTheme.module.scss'
import * as grayTheme from './grayTheme.module.scss'
import * as greenTheme from './greenTheme.module.scss'

import { l } from 'ui/lib/lodashImports'
import { r } from 'ui/lib/ramdaImports'
import {
	getLocationHref,
	windowExists,
	getWindow,
} from 'ui/components/common/router/windowUtils'

export type Themes = 'base' | 'tms2' | 'tms3' | 'green' | 'gray' | 'tmsDark'
export type Colors =
	| 'gray'
	| 'lightGray'
	| 'blue'
	| 'darkBlue'
	| 'lightBlue'
	| 'linkBlue'
	| 'red'
	| 'green'
	| 'orange'
	| 'purple'
	| 'darkPurple'
	| 'black'
	| 'darkRed'
	| 'lightRed'
	| 'greenBlue'
	| 'persian'
	| 'maroon'
	| 'teal'
	| 'white'
	| 'yellow'

export type FontSizes = 'fontSmall' | 'fontMedium' | 'fontLarge' | 'fontHuge'

const themeMap: { [key in Themes]: any } = {
	base: null,
	tms2: tms2Theme,
	tms3: tms3Theme,
	gray: grayTheme,
	green: greenTheme,
	tmsDark: tmsDarkTheme,
}

export function isIE11CompatibilityTestingMode(): boolean {
	return false // DON'T release with this set to true, for remote testing only
}

// see: https://stackoverflow.com/questions/326069/how-to-identify-if-a-webpage-is-being-loaded-inside-an-iframe-or-directly-into-t
export function isInIframe(): boolean {
	return windowExists && getWindow().self !== getWindow().top
}

export function isInTMS2(): boolean {
	return isInIframe()
}

export function isInDev(): boolean {
	return (
		getLocationHref().startsWith('http://localhost:4200') ||
		isIE11CompatibilityTestingMode()
	)
}

let currentTheme = baseTheme
export function updateTheme(themeName: Themes): void {
	let theme = l.cloneDeep(baseTheme)
	theme = l.assign(theme, themeMap[themeName])
	currentTheme = theme
}
updateTheme('base')

export const isValidClass = (className: string): boolean =>
	r.not(r.or(r.isNil(className), r.isEmpty(className)))

export const addClass = (newClassName: string, className: string): string => {
	if (r.not(isValidClass(newClassName))) {
		return className
	}
	return className ? className + ' ' + newClassName : newClassName
}

export const addClassIf = (
	condition: boolean,
	newClassName: string,
	className: string,
): string => (condition ? addClass(newClassName, className) : className)

export const getTheme = (): typeof baseTheme => currentTheme

export function getColor(color: Colors): string {
	return currentTheme['' + color]
}
export function getBackgroundWithTextColor(color: Colors): string {
	return currentTheme['' + color + 'BackgroundWithTextColor']
}
export function getTextColor(color: Colors): string {
	return currentTheme['' + color + 'Text']
}
export function getBorderColor(color: Colors): string {
	return currentTheme['' + color + 'Border']
}
export function getBorderBottomColor(color: Colors): string {
	return currentTheme['' + color + 'BorderBottom']
}
export function getColorButton(color: Colors): string {
	return currentTheme['' + color + 'Button']
}
export function getColorTable(index: number): string {
	if (!index) {
		return ''
	}
	return currentTheme['seriesColor' + index]
}
export function getHighlightColor(color: Colors): string {
	return currentTheme['' + color + 'Highlight']
}
export function getHighlightTooltip(color: Colors): string {
	return currentTheme['' + color + 'HighlightTooltip']
}
export function getDisabledColors(): string {
	return currentTheme['disabled']
}
export function getHoverBackgroundColors(color: Colors): string {
	return currentTheme['' + color + 'HoverBackground']
}
