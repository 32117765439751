import { FC } from 'app/FunctionalComponent'
import React, { useState } from 'react'
import { apiTypes } from 'ui/api'
import { Modal } from 'ui/components/common/modal'
import { t } from 'ui/components/i18n/i18n'
import { AddPrinterModalForm } from './AddPrinterModalForm'
import { addClass } from 'ui/theme/theme'
import * as classes from './AddPrinterModal.module.scss'

const tPrefix = 'page.companyManagement.printersAndScales.tabs.addPrinterModal'

export interface PrinterCreateForm {
	nickname: string
	printNodeId: string
	paperType: apiTypes.PaperType
	printerType: apiTypes.PrinterType
	locationId: string
	computerName: string
	useDocTab: boolean
}

export const defaultPrinterCreateForm: PrinterCreateForm = {
	nickname: null,
	printNodeId: null,
	paperType: null,
	printerType: null,
	locationId: null,
	computerName: null,
	useDocTab: false,
}

export const AddPrinterModal: FC = (props: {
	isModalOpen: boolean
	onModalClose: () => void
	printerList: apiTypes.PrinterResponse[]
	setPrinterList: React.Dispatch<
		React.SetStateAction<apiTypes.PrinterResponse[]>
	>
	locationId: string
	credentialsId: string
}) => {
	const { printerList, setPrinterList, locationId, credentialsId } = props

	const [printerCreateForm, setPrinterCreateForm] = useState<PrinterCreateForm>(
		defaultPrinterCreateForm,
	)

	const onClose = (): void => {
		setPrinterCreateForm(defaultPrinterCreateForm)
		props.onModalClose()
	}

	return (
		<Modal
			content={() => (
				<div className={addClass('bootstrap-wrapper', classes.addPrinterModal)}>
					<AddPrinterModalForm
						onClose={onClose}
						printerList={printerList}
						setPrinterList={setPrinterList}
						locationId={locationId}
						credentialsId={credentialsId}
						printerCreateForm={printerCreateForm}
						setPrinterCreateForm={setPrinterCreateForm}
					/>
				</div>
			)}
			isOpen={props.isModalOpen}
			onModalClose={onClose}
			title={t('addPrinterModal', tPrefix)}
			closeModalX={true}
		/>
	)
}
