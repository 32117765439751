import { FC } from 'app/FunctionalComponent'
import React, { useState } from 'react'
import { ISelectOptions } from 'ui/components/common/select'
import { SmallButton } from 'ui/components/common/small-button'
import { IFormMetadataCollection } from 'ui/components/form'
import { FormVertical } from 'ui/components/form/FormVertical'
import { FormVerticalLayout } from 'ui/components/form/FormVerticalLayout'
import { FormVerticalSelect } from 'ui/components/form/FormVerticalSelect'
import { FormVerticalTextInput } from 'ui/components/form/FormVerticalTextInput'
import { IFormData } from 'ui/components/form/IFormData'
import { useOnce } from 'ui/components/hooks'
import { addressTypes } from 'ui/data'
import {
	getCountryNameAndCodeList,
	getRegionsForCountry,
} from 'ui/data/countryAndRegionUtils'
import { FormStringData } from 'ui/forms/formUtils'
import { l } from 'ui/lib/lodashImports'
import { r } from 'ui/lib/ramdaImports'
import { StopType } from 'ui/pages/new-quote/state/sosNewQuote'
import { IAddress } from './IAddress'
import { IAddressState } from './IAddressState'

export type AddressEditModeType =
	| 'customAddress'
	| 'companyLocation'
	| 'readOnly'

export type IEditAddressForm = FormStringData<IAddress>

export const editAddressFormMetadata: IFormMetadataCollection<IEditAddressForm> = {
	attentionTo: {
		required: true,
	},
	name: {},
	company: {},
	city: { required: true },
	phone: {},
	email: {},
	street1: { required: true },
	street2: {},
	zip: { required: true },
	state: { required: true },
	country: {
		options: getCountryNameAndCodeList().map((c) => ({
			label: c.countryName,
			value: c.countryCode,
		})),
		required: true,
	},
	addressType: {
		options: addressTypes,
	},
}

const tPrefix = 'shared.address.editAddress'

export const EditAddressForm: FC = (props: {
	addressState: IAddressState
	updateAddressState: (changes: Partial<IAddressState>, id?: string) => void
	nonContactReadOnly?: boolean
	onSave?: () => void
	showSave?: boolean
	stopType?: StopType
}) => {
	const {
		addressState: state,
		updateAddressState: updateState,
		nonContactReadOnly,
	} = props
	const [regions, setRegions] = useState([])
	useOnce(() => {
		if (state.address.country) {
			setRegions(getRegionsForCountry(state.address.country, true, true))
		}
	})

	const formData: IFormData<IAddress> = {
		form: state.address,
		metadata: editAddressFormMetadata,
		onUpdateForm: (key: string, val: string) => {
			const newAddress = r.clone(state.address)
			newAddress[key] = val

			// Reset the state/region if the country changed
			if (key === 'country' && state.address.country !== val) {
				const newStateOptions: ISelectOptions[] = getRegionsForCountry(
					newAddress.country,
					true,
					true,
				) as ISelectOptions[]
				setRegions(newStateOptions)
				if (
					!l.find(newStateOptions, (stateOption) => {
						return stateOption.value === state.address.state
					})
				) {
					newAddress.state = ''
				}
			}

			updateState({ address: newAddress }, props.addressState.id)
		},
		tPrefix,
	}

	return (
		<div>
			<FormVertical>
				<FormVerticalLayout>
					<FormVerticalSelect
						formData={formData}
						field='addressType'
						readOnly={nonContactReadOnly}
					/>
					<FormVerticalTextInput
						formData={formData}
						field='addressName'
						readOnly={nonContactReadOnly}
					/>

					<FormVerticalTextInput
						formData={formData}
						field='attentionTo'
						testId={'name-' + props.stopType}
					/>
					<FormVerticalTextInput
						formData={formData}
						field='phone'
						testId={'phone-' + props.stopType}
					/>
					<FormVerticalTextInput
						formData={formData}
						field='email'
						testId={'email-' + props.stopType}
					/>

					<FormVerticalTextInput
						formData={formData}
						field='street1'
						readOnly={nonContactReadOnly}
						testId={'street1-' + props.stopType}
					/>
					<FormVerticalTextInput
						formData={formData}
						field='street2'
						readOnly={nonContactReadOnly}
						testId={'street2-' + props.stopType}
					/>
					<FormVerticalTextInput
						formData={formData}
						field='city'
						readOnly={nonContactReadOnly}
						testId={'city-' + props.stopType}
					/>
					<FormVerticalSelect
						formData={formData}
						field='state'
						options={regions}
						readOnly={nonContactReadOnly}
						testId={'state-' + props.stopType}
					/>
					<FormVerticalTextInput
						formData={formData}
						field='zip'
						readOnly={nonContactReadOnly}
						testId={'zip-' + props.stopType}
					/>
					<FormVerticalSelect
						formData={formData}
						field='country'
						readOnly={nonContactReadOnly}
						autocomplete={true}
					/>
				</FormVerticalLayout>
			</FormVertical>
			{props.showSave && (
				<SmallButton color='blue' onClick={props.onSave}>
					Save
				</SmallButton>
			)}
		</div>
	)
}
