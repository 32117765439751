import { IFormMetadataCollection } from 'ui/components/form/IFormMetadata'

export interface IQuickRateProductCostForm {
	productCost: string
	freightMarkupPercent: string
}

export const quickRateProductCostFormMetadata: IFormMetadataCollection<IQuickRateProductCostForm> = {
	productCost: {
		label: 'quickRateProductCostForm.cost',
		description: 'quickRateProductCostForm.costDescription', //  This value will be added to each rate cost',
		mustBeANumber: true,
	},
	freightMarkupPercent: {
		label: 'quickRateProductCostForm.freightMarkupPercent',
		description: 'quickRateProductCostForm.freightMarkupPercentDescription', //  Enter as either a percent or a decimal. The rate will be multiplied by this.',
		mustBeANumber: true,
	},
}
