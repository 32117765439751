import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { t } from 'ui/components/i18n/i18n'
import {
	IKeyValueOptions,
	KeyValue,
	SimpleKeyValueTable,
} from 'ui/components/table'

export const ContactInfo: FC = (props: {
	phone?: string
	name?: string
	email?: string
}) => {
	const { phone, name, email } = props
	const tPrefix = 'page.newQuote.contactInfo'
	const options: IKeyValueOptions = {
		labelMinWidth: '120px',
		tPrefix: tPrefix,
	}
	return (
		<>
			<div>
				<strong>{t('contactInfo', tPrefix)}</strong>
			</div>
			<SimpleKeyValueTable borderless={true}>
				<KeyValue options={options} field='phone' data={{ phone }} />
				<KeyValue options={options} field='name' data={{ name }} />
				<KeyValue options={options} field='email' data={{ email }} />
			</SimpleKeyValueTable>
		</>
	)
}
