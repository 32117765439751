import { FC } from 'app/FunctionalComponent'
import React from 'react'
import {
	FormStackedTextInput,
	IFormData,
	IFormMetadataCollection,
} from 'ui/components/form'
import { t, tString } from 'ui/components/i18n/i18n'
import { Spacer } from 'ui/components/layout/spacer'
import { l } from 'ui/lib/lodashImports'
import { r } from 'ui/lib/ramdaImports'
import styles from './Address.module.scss'
import { UINewQuoteAddressForm } from 'ui/pages/new-quote/parcel/components/NewQuoteParcelAddress'
import { IAddress } from './IAddress'
import { IAddressState } from '.'

export const renderCityState = (city?: string, state?: string): string => {
	if (city && state) {
		return `${city}, ${state}`
	}
	if (city) {
		return city
	}
	if (state) {
		return state
	}
	return null
}

export const CityStateZip: FC = (props: {
	address: IAddress
	fontSize?: string
}) => {
	const { address, fontSize } = props
	const { state, zip, city } = address
	return (
		<div style={{ fontSize: fontSize }}>
			{renderCityState(city, state)} {zip}
		</div>
	)
}

export const formatPhoneNumber = (phoneNumber: string): string => {
	// 5093755463
	if (phoneNumber.length === 10) {
		return `(${phoneNumber.substring(0, 3)}) ${phoneNumber.substring(
			3,
			6,
		)}-${phoneNumber.substring(6, 10)}`
	}
	return phoneNumber
}

export const addressHasContactInfo = (address: IAddress): string => {
	return address.addressType || address.phone || address.email
}
export const addressHasNoAddress = (address: IAddress): boolean => {
	return (
		!address.street1 &&
		!address.street2 &&
		!address.street3 &&
		!address.city &&
		!address.zip
	)
}

const tPrefix = 'page.newQuote.parcel.address'

export const SimpleAddress: FC = (props: {
	inset?: boolean
	showAddressType?: boolean
	showNoAddress?: boolean
	showContactInfo?: boolean
	address: IAddress
	onChangeAddress?: React.Dispatch<React.SetStateAction<UINewQuoteAddressForm>>
	fontSize?: string
	testId?: string
	testIdPrefix?: string
	onChangeAddressAddressState?: (
		changes: Partial<IAddressState>,
		id?: string,
	) => void
	addressState?: IAddressState
}) => {
	const { address, fontSize } = props

	const isReadOnly =
		!props.onChangeAddress && !props.onChangeAddressAddressState

	const showNoAddress = l.defaultTo(props.showNoAddress, true)
	const showContactInfo = l.defaultTo(props.showContactInfo, true)

	if (!address && showNoAddress) {
		return <div>{t('noAddress', 'shared.address')}</div>
	}

	// const hasContactInfo = addressHasContactInfo(address)
	const hasNoAddress = addressHasNoAddress(address)

	const addressFormMetaData: IFormMetadataCollection<UINewQuoteAddressForm> = {
		name: {},
		state: {},
		contactName: {},
		phone: {},
		email: {},
		company: { required: true },
	}

	const formData: IFormData<UINewQuoteAddressForm> = {
		form: address,
		metadata: addressFormMetaData,
		onUpdateForm: (field: string, value: any) => {
			const updatedFormData = l.cloneDeep(address)
			updatedFormData[field] = value

			if (l.isNil(props.addressState?.id)) {
				props.onChangeAddress(updatedFormData)
			} else {
				props.onChangeAddressAddressState(
					{ address: updatedFormData },
					props.addressState?.id,
				)
			}
		},
		tPrefix,
	}

	const checkAddress = (address: string, isBold?: boolean): string => {
		return isReadOnly
			? address
				? `${styles.formStackedTextInputReadOnly} ${isBold &&
						styles.formStackedTextInputReadOnlyCompany}`
				: styles.formStackedTextInputDisplayNone
			: null
	}

	return (
		<div data-testid={props.testId}>
			<FormStackedTextInput
				formData={formData}
				field={'company'}
				label={tString('locationName', tPrefix)}
				testId={`new-quote-parcel-${props.testIdPrefix}-address-company`}
				readOnly={isReadOnly}
				hideLabel={isReadOnly}
				className={checkAddress(address.company, true)}
			/>

			{showContactInfo && address.attentionTo && (
				<strong>{address.attentionTo}</strong>
			)}
			<div style={{ fontSize: fontSize }}>{address.street1}</div>
			{address.street2 && (
				<div style={{ fontSize: fontSize }}>{address.street2}</div>
			)}
			{address.street3 && (
				<div style={{ fontSize: fontSize }}>{address.street3}</div>
			)}
			<div>
				<CityStateZip address={address} fontSize={fontSize} />
			</div>
			{hasNoAddress && showNoAddress && (
				<div style={{ fontSize: fontSize }}>
					{t('noAddress', 'shared.address')}
				</div>
			)}
			{r.not(hasNoAddress) && (
				<div style={{ fontSize: fontSize }}>{address.country}</div>
			)}
			{address.addressType && (
				<div>
					<Spacer />
					{t(address.addressType, 'shared.address.addressType')}{' '}
					{t('shared.address.addressType')}
				</div>
			)}

			<div>
				<Spacer />

				<FormStackedTextInput
					formData={formData}
					field={'name'}
					label={tString('contactName', tPrefix)}
					testId={`new-quote-parcel-${props.testIdPrefix}-address-name`}
					readOnly={isReadOnly}
					hideLabel={isReadOnly}
					className={checkAddress(address.name, false)}
				/>

				<FormStackedTextInput
					formData={formData}
					field={'phone'}
					testId={`new-quote-parcel-${props.testIdPrefix}-address-phone`}
					readOnly={isReadOnly}
					hideLabel={isReadOnly}
					className={checkAddress(address.phone, false)}
				/>

				<FormStackedTextInput
					formData={formData}
					field={'email'}
					testId={`new-quote-parcel-${props.testIdPrefix}-address-email`}
					readOnly={isReadOnly}
					hideLabel={isReadOnly}
					className={checkAddress(address.email, false)}
				/>
			</div>
		</div>
	)
}
