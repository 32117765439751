import { FC } from 'ui/FunctionalComponent'
import React, { useEffect, useState } from 'react'
import { apiTypes } from 'ui/api'
import { t } from 'ui/components/i18n/i18n'
import { compareBrokerShipmentInvoiceStatus } from './invoiceStatusRanks'
import {
	StationSuccess,
	StationWaiting,
	Subway,
	SubwayArrow,
	SubwayStation,
} from 'ui/components/shared/subway'

const getInvoiceStation = (
	currentStatus: apiTypes.InvoiceStatus,
	stationStatus: apiTypes.InvoiceStatus,
): React.ReactNode => {
	const statusRank = compareBrokerShipmentInvoiceStatus(
		currentStatus,
		stationStatus,
	)
	return statusRank >= 0 ? <StationSuccess /> : <StationWaiting />
}

const tPrefix = 'page.customerInvoice.customerInvoiceSubway'

export const BrokerShipmentProfileInvoicesSubway: FC = (props: {
	invoice: apiTypes.BrokerInvoiceResponse
}) => {
	const { invoice } = props
	const [status, setStatus] = useState<apiTypes.InvoiceStatus>('review')
	useEffect(() => {
		if (invoice) {
			setStatus(invoice.invoiceStatus)
		}
	}, [invoice])

	return (
		<Subway testId='broker-shipment-profile-invoices-subway'>
			<SubwayStation
				top={t('subwayApproved', tPrefix)}
				bottom=''
				dataTestId='broker-shipment-invoice-subway-approved'
			>
				{getInvoiceStation(status, 'approved')}
			</SubwayStation>
			<SubwayArrow />
			<SubwayStation
				top={t('subwayInvoiced', tPrefix)}
				bottom=''
				dataTestId='broker-shipment-invoice-subway-invoiced'
			>
				{getInvoiceStation(status, 'invoiced')}
			</SubwayStation>
			<SubwayArrow />
			<SubwayStation
				top={t('subwayPaidInFull', tPrefix)}
				bottom=''
				dataTestId='broker-shipment-invoice-subway-paid-in-full'
			>
				{getInvoiceStation(status, 'paid_in_full')}
			</SubwayStation>
		</Subway>
	)
}
