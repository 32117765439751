import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { Autocomplete } from 'ui/components/common/autocomplete'
import { ISelectOptions } from 'ui/components/common/select'
import { Col, Row } from 'react-bootstrap'
import classes from '../bill-to/BillTo.module.scss'
import newQuoteParcelClasses from '../NewQuoteParcel.module.scss'
import { t } from 'ui/components/i18n/i18n'
import { apiTypes } from 'ui/api'
import { sosUser } from 'ui/state'

const tPrefix = 'page.newQuote.parcel.overridePrinterLocation'

export const OverridePrinterLocationDropDown: FC = (props: {
	onUpdate: (view: string) => void
	value: string
	isReadOnly?: boolean
	printers: apiTypes.PrinterResponse[]
}) => {
	const { onUpdate, isReadOnly, printers } = props
	const userSessionPrinterId = sosUser.getSos().getState().sessionPrinterId

	return (
		<Row
			className={`${newQuoteParcelClasses.newQuoteParcelCol} ${newQuoteParcelClasses.locationDropDownNoMargin}`}
		>
			<Col>
				<Row className={classes.billToLabel}>
					{t('overridePrinterLocation', tPrefix)}
				</Row>
				<Row className={classes.billToDropdown}>
					<Autocomplete
						type='select'
						value={userSessionPrinterId}
						options={printers.map(
							(printer: apiTypes.PrinterResponse): ISelectOptions => ({
								value: printer.id,
								label: printer.nickname,
							}),
						)}
						onChange={(view) => onUpdate(view)}
						testId='override-printer-location-selector'
						isClearable={false}
						readOnly={isReadOnly}
						allowEmptyValue={false}
					/>
				</Row>
			</Col>
		</Row>
	)
}
