// Cookie Helper Function

export const getCookieByKey = (cookieKey: string): any => {
	const cookieValue = document.cookie
		.split('; ')
		.map((cookie) => {
			const selectedCoookie = cookie.split('=')

			return { key: selectedCoookie[0], value: selectedCoookie[1] }
		})
		.find((item) => item.key === cookieKey)?.value

	return cookieValue
}

// Example cookie usage:
// import { getCookieByKey } from 'ui/lib/cookieHelper'

// Set Cookie
// document.cookie = `property=${value}; expires=Fri, 31 Dec 2021 12:00:00 UTC; path=/`

// Get Cookie
// const myCookie = getCookieByKey('property')
