import { apiProductCatalog } from 'ui/api'
import { UpdateMode } from 'ui/api/apiCommon'
import { IRequestState } from 'ui/api/requestState'
import { dataUtils } from 'ui/components/table/'
import {
	createDefaultDataTableState,
	IDataTableState,
} from 'ui/components/table/IDataTableState'
import { formUtils } from 'ui/forms/'
import {
	IProductCatalogForm,
	productCatalogFormMetadata,
} from 'ui/forms/formProductCatalog'
import { IProductCatalog } from 'ui/lib/books/IProductCatalog'
import { IProductCatalogEntry } from 'ui/lib/books/IProductCatalogEntry'
import { createSos } from './secretaryOfState'

export interface IStateBook {
	requestGetProductCatalogs: IRequestState<IProductCatalog[]>
	requestGetProductCatalogEntries: IRequestState<IProductCatalogEntry[]>
	requestAddEditProductCatalog: IRequestState<IProductCatalog>
	requestCreateProductCatalogEntry: IRequestState<IProductCatalogEntry>
	dataTableProductCatalog: IDataTableState<IProductCatalog>
	dataProductCatalog: IProductCatalog[]
	formProductCatalog: IProductCatalogForm
}

const initialState: IStateBook = {
	requestGetProductCatalogs: {},
	requestGetProductCatalogEntries: {},
	requestAddEditProductCatalog: {},
	requestCreateProductCatalogEntry: {},
	dataTableProductCatalog: createDefaultDataTableState({
		sortOn: 'name',
		hiddenHeaders: ['id', 'pageType'],
	}),
	dataProductCatalog: [],
	formProductCatalog: formUtils.createDefaultFormStringData(
		productCatalogFormMetadata,
	),
}

const { stateManager, useSubscribe } = createSos(
	'product-catalog-page',
	'1.0.2',
	initialState,
	{
		useLocalStorage: true,
	},
)
export { useSubscribe }

export async function getBooks(): Promise<void> {
	stateManager.produce((ds) => {})
	await apiProductCatalog.fetchProductCatalogs((rs) => {
		stateManager.produce((ds) => {
			ds.requestGetProductCatalogs = rs
			if (rs.data) {
				ds.dataProductCatalog = rs.data
				dataUtils.initialLoad(ds.dataTableProductCatalog, ds.dataProductCatalog)
			}
		})
	})
}

export function booksTableSort(sortOn: string): void {
	stateManager.produce((ds) => {
		dataUtils.sort(ds.dataTableProductCatalog, ds.dataProductCatalog, sortOn)
	})
}

export function booksTableToggleHeader(field: string): void {
	stateManager.produce((ds) => {
		dataUtils.toggleHeader(ds.dataTableProductCatalog, field)
	})
}

export function booksTableSetUpdateMode(
	mode: UpdateMode,
	row: IProductCatalog = null,
): void {
	stateManager.produce((ds) => {
		dataUtils.toggleUpdateMode(ds.dataTableProductCatalog, mode, row)
		if (ds.dataTableProductCatalog.isAdding) {
			ds.formProductCatalog = formUtils.createDefaultFormStringData(
				productCatalogFormMetadata,
			)
		} else if (ds.dataTableProductCatalog.editRow) {
			ds.formProductCatalog = formUtils.apiDataToFormStringData(
				productCatalogFormMetadata,
				row,
			)
		}
	})
}

export function updateBookForm(
	key: keyof IProductCatalogForm,
	newVal: string,
): void {
	stateManager.produce((ds) => {
		ds.formProductCatalog[key] = newVal
	})
}

export async function updateBook(mode: UpdateMode): Promise<void> {
	const state = stateManager.getState()
	const apiData = formUtils.formStringDataToApiData(
		productCatalogFormMetadata,
		state.dataTableProductCatalog.editRow,
		state.formProductCatalog,
	)
	const result = await apiProductCatalog.updateProductCatalog(
		mode,
		apiData,
		(rs) => {
			stateManager.produce((ds) => {
				ds.requestAddEditProductCatalog = rs
			})
		},
	)

	if (result.data) {
		stateManager.produce((ds) => {
			dataUtils.updateItem(
				ds.dataTableProductCatalog,
				ds.dataProductCatalog,
				mode,
				result.data,
				ds.formProductCatalog.id,
			)
		})
		booksTableSetUpdateMode('none')
	}
	if (result.error) {
		stateManager.produce((ds) => {
			dataUtils.setError(ds.dataTableProductCatalog, result.error)
		})
	}
}
