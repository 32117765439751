import { FC } from 'app/FunctionalComponent'
import _ from 'lodash'
import React from 'react'
import { ISelectOptions } from 'ui/components/common/select'
import { Select } from 'ui/components/common/select/Select'
import { _idx } from 'ui/lib'
import { getAccessorialKeyPath } from 'ui/pages/new-quote/accessorials'
import { IAccessorialProps } from '.'

export const AccessorialSelect: FC = (props: IAccessorialProps) => {
	const {
		accessorialMetadata,
		mode,
		accessorialObject,
		updateState,
		stopId,
		tPrefix,
	} = props

	const accessorialKey = getAccessorialKeyPath(accessorialMetadata, mode)
	const options: string[] | ISelectOptions[] =
		_idx(() => accessorialMetadata.selectOptions) || []
	return (
		<Select
			value={_.get(accessorialObject, accessorialKey)}
			options={options}
			onChange={(newVal: string) => {
				const changes: any = {}
				_.set(changes, accessorialKey, newVal)
				updateState(changes, stopId)
			}}
			tPrefix={tPrefix}
		/>
	)
}
