import { IAddress } from 'ui/components/shared/address/IAddress'

export function formatAddressForMap(
	address: IAddress,
	color = 'green',
): string {
	const formatted_address =
		'color:' +
		color +
		'|' +
		address.street1 +
		'+' +
		address.city +
		'+' +
		address.state +
		'+' +
		address.zip
	return formatted_address.replace(/( )/g, '+')
}

export function identifyMarkerColor(
	address: IAddress,
): 'green' | 'red' | 'blue' {
	if (address.isOrigin) {
		return 'green'
	}
	if (address.isDestination) {
		return 'red'
	}
	return 'blue'
}
const mapHelpers = {
	formatAddressForMap,
	identifyMarkerColor,
}
export { mapHelpers }
