import { apiTypes } from 'ui/api'
import { ToggleableShipmentProfilePiece } from '../../ShipmentProfileLayout'

export const getMapHeight = (
	shipment: apiTypes.BrokerShipmentResponse | apiTypes.ShipmentResponse,
	showShipmentDetails: boolean,
	showingShipmentProfileSection: ToggleableShipmentProfilePiece,
	defaultHeight = 900,
): number => {
	let mapHeight = defaultHeight
	const aboveSections = 190
	let necessaryMapHeight = aboveSections

	if (showShipmentDetails) {
		const shipmentDetailsHeader = 50
		const shipmentDetailsHeightPerStopRow = 300
		const shipmentDetailsFooter = 120

		const stopRowCount = Math.ceil((shipment.stopSequence.length + 1) / 4)

		necessaryMapHeight += shipmentDetailsHeader + shipmentDetailsFooter
		necessaryMapHeight += shipmentDetailsHeightPerStopRow * stopRowCount
	}

	if (showingShipmentProfileSection === 'rates') {
		const ratesTableHeader = 260
		const heightPerRate = 47
		const ratesTableFooter = 60

		const rateCount = shipment?.rates?.length || 0

		necessaryMapHeight += ratesTableHeader + ratesTableFooter
		necessaryMapHeight += heightPerRate * rateCount
	}

	if (showingShipmentProfileSection === 'invites') {
		const invitesTableHeader = 100
		const heightPerInvite = 47
		const invitesTableFooter = 5

		const rateCount = 25 // this is Grego's problem now

		necessaryMapHeight += invitesTableHeader + invitesTableFooter
		necessaryMapHeight += heightPerInvite * rateCount
	}

	if (mapHeight < necessaryMapHeight) {
		mapHeight = necessaryMapHeight
	}

	return mapHeight
}
