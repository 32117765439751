import React, { useState } from 'react'
import { FC } from 'ui/FunctionalComponent'
import { TabContent, TabItem2, Tabs2 } from 'ui/components/common/tabs'
import { t } from 'ui/components/i18n/i18n'
import { l } from 'ui/lib/lodashImports'
import * as classes from './CustomerManagementTabs.module.scss'
import { BrokerAssignments } from '../broker-assignments'
import { Accounting } from '../accounting/Accounting'
import { CustomerDetails } from '../customer-details/CustomerDetails'
// import { sos2 } from 'ui/lib/state/sos2'
import { sosCustomerManagement } from '../..'
import { TCustomerManagementTabs } from '../../sosCustomerManagement'
import { NotifyCustomerModal } from '../notify-customer-modal'
import { Notifications } from '../notifications'

export const CustomerManagementTabs: FC = (props: {
	state: sosCustomerManagement.StateCustomerManagement
	tPrefix: string
}) => {
	const { state, tPrefix } = props

	// const state = sos2.useSubscription(sosCustomerManagement.sos)

	const tPrefixCustomerManagementTabs = tPrefix + '.tabs.title'
	const tPrefixCustomerManagementTabContent = tPrefix + '.tabs.content'

	const [selectedTab, setSelectedTab] = useState<TCustomerManagementTabs>(
		'details',
	)
	const [tempSelectedTab, setTempSelectedTab] = useState<
		TCustomerManagementTabs
	>('details')
	const [isNotifyCustomerModalOpen, setIsNotifyCustomerModalOpen] = useState<
		boolean
	>(false)

	const tabs: Array<{ name: TCustomerManagementTabs }> = [
		{
			name: 'details',
		},
		{
			name: 'brokerAssignments',
		},
		{
			name: 'notifications',
		},
		{
			name: 'accounting',
		},
	]

	const switchTab = (tabSelected: TCustomerManagementTabs): void => {
		if (state.isPageModified) {
			setIsNotifyCustomerModalOpen(true)
		} else {
			setSelectedTab(tabSelected)
		}

		setTempSelectedTab(tabSelected)
	}

	return (
		<React.Fragment>
			<div className={classes.customerManagementTabs}>
				<Tabs2>
					{l.map(tabs, (c) => (
						<TabItem2
							isSelected={selectedTab === c.name}
							key={c.name}
							onClick={() => {
								if (selectedTab !== c.name) {
									switchTab(c.name)
								}
							}}
						>
							{t(l.camelCase(c.name), tPrefixCustomerManagementTabs)}
						</TabItem2>
					))}
				</Tabs2>
				<div className={classes.customerManagementTabsContainer}>
					<TabContent
						isVisible={selectedTab === 'details'}
						renderer={() => (
							<CustomerDetails
								customerDetailsAddressForm={state.addressForm}
								useCustomerInfoForBOL={state.useCustomerInfoForBOL}
								brandingImageUrl={state.brandingImageUrl}
								brandingPictureData={state.brandingPictureData}
								tPrefix={tPrefixCustomerManagementTabContent}
							/>
						)}
					/>
					<TabContent
						isVisible={selectedTab === 'brokerAssignments'}
						renderer={() => (
							<BrokerAssignments
								brokerLocationId={state.brokerLocationId}
								brokerLocationName={state.brokerLocationName}
								tPrefix={tPrefixCustomerManagementTabContent}
							/>
						)}
					/>
					<TabContent
						isVisible={selectedTab === 'notifications'}
						data-testid='customer-management-notifications-tab'
						renderer={() => (
							<Notifications
								notificationsForm={state.notificationsForm}
								tPrefix={tPrefixCustomerManagementTabContent}
							/>
						)}
					/>
					<TabContent
						isVisible={selectedTab === 'accounting'}
						renderer={() => (
							<Accounting
								paymentTermsForm={state.paymentTermsForm}
								creditLimitForm={state.creditLimitForm}
								brokerDefaultMarkupForm={state.brokerDefaultMarkupForm}
								tPrefix={tPrefixCustomerManagementTabContent}
							/>
						)}
					/>
				</div>
			</div>

			<NotifyCustomerModal
				isModalOpen={isNotifyCustomerModalOpen}
				onModalClose={() => {
					setIsNotifyCustomerModalOpen(false)
				}}
				onDontSave={() => {
					setSelectedTab(tempSelectedTab)
				}}
			/>
		</React.Fragment>
	)
}
