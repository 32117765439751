import React, { useState } from 'react'

import { FC } from 'ui/FunctionalComponent'
import { Tab, Nav } from 'react-bootstrap'
import { t } from 'ui/components/i18n/i18n'
import { l } from 'ui/lib/lodashImports'
import {
	CustomerDetailsAddress,
	ICustomerDetailsAddressForm,
} from '../customer-details-address'
import { CustomerDetailsBrandingLogo } from '../customer-details-branding-logo/CustomerDetailsBrandingLogo'
import * as classes from '../shared/BSTab.module.scss'
import { FileDropZoneData } from 'ui/components/shared/csvValidator/file-drop-zone'

export const CustomerDetails: FC = (props: {
	customerDetailsAddressForm: ICustomerDetailsAddressForm
	useCustomerInfoForBOL: boolean
	brandingImageUrl: string
	brandingPictureData: FileDropZoneData
	tPrefix: string
}) => {
	const tPrefixCustomerDetails = props.tPrefix + '.customerDetails'

	const [selectedTab, setSelectedTab] = useState<string>('address')

	const tabs: Array<{ name: string; component: React.ReactElement }> = [
		{
			name: 'address',
			component: (
				<CustomerDetailsAddress
					customerDetailsAddressForm={props.customerDetailsAddressForm}
					tPrefix={tPrefixCustomerDetails}
				/>
			),
		},
		{
			name: 'brandingLogo',
			component: (
				<CustomerDetailsBrandingLogo
					useCustomerInfoForBOL={props.useCustomerInfoForBOL}
					brandingImageUrl={props.brandingImageUrl}
					brandingPictureData={props.brandingPictureData}
					tPrefix={tPrefixCustomerDetails}
				/>
			),
		},
	]

	return (
		<div>
			<Tab.Container
				id='customer-details-tab-container'
				onSelect={(selected) => {
					setSelectedTab(selected)
				}}
			>
				<Nav variant='pills' className={classes.bsTabPills}>
					{l.map(tabs, (c, idx) => (
						<Nav.Item key={idx} className={classes.bsTabPillsNavItem}>
							<Nav.Link
								className={
									c.name === selectedTab ? classes.bsTabPillActive : null
								}
								onClick={() => {
									setSelectedTab(c.name)
								}}
							>
								{t(c.name, tPrefixCustomerDetails)}
							</Nav.Link>
						</Nav.Item>
					))}
				</Nav>

				<Tab.Content className={classes.bsTabContent}>
					{l.map(tabs, (c, idx) => (
						<Tab.Pane key={idx} active={selectedTab === c.name}>
							{c.component}
						</Tab.Pane>
					))}
				</Tab.Content>
			</Tab.Container>
		</div>
	)
}
