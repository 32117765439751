import { IRequestState } from 'ui/api/requestState'
import { IShipOrdersBatchStatusForm } from 'ui/forms/formShipOrdersBatchStatus'
import { IShipOrdersBlockedOrdersForm } from 'ui/forms/formShipOrdersBlockedOrders'
import { IShipOrdersFlaggedOrdersForm } from 'ui/forms/formShipOrdersFlaggedOrders'
import { IShipOrdersPendingOrdersForm } from 'ui/forms/formShipOrdersPendingOrders'
import { IShipOrdersShipOrderForm } from 'ui/forms/formShipOrdersShipOrder'
import { IShipOrdersShippedOrdersForm } from 'ui/forms/formShipOrdersShippedOrders'
import { IShipOrdersTestOrdersForm } from 'ui/forms/formShipOrdersTestOrders'
import { l } from 'ui/lib/lodashImports'
import { router } from './router'
import { createSos } from './secretaryOfState'

// import { apiLightningIntegration } from './api/apiLightningIntegration'
// import {
//   ILightningIntegrationSetPasswordForm,
//   lightningIntegrationSetPasswordFormMetadata,
// } from 'ui/forms/formLightningIntegrationSetPassword'
// import {
//   ILightningIntegrationConfigurationForm,
//   lightningIntegrationConfigurationFormMetadata,
// } from 'ui/forms/formLightningIntegrationConfiguration'
export type Tabs =
	| 'ship-order'
	| 'pending-orders'
	| 'batch-status'
	| 'blocked-orders'
	| 'flagged-orders'
	| 'shipped-orders'
	| 'test-orders'

export interface IStateShipOrders {
	//[x: string]: IFetch<any>
	selectedTab: Tabs
	shipOrderForm: IShipOrdersShipOrderForm
	pendingOrdersForm: IShipOrdersPendingOrdersForm
	batchStatusForm: IShipOrdersBatchStatusForm
	blockedOrdersForm: IShipOrdersBlockedOrdersForm
	flaggedOrdersForm: IShipOrdersFlaggedOrdersForm
	shippedOrdersForm: IShipOrdersShippedOrdersForm
	testOrdersForm: IShipOrdersTestOrdersForm
	fetchCreateRegistration: IRequestState<any>
}

const initialState: IStateShipOrders = {
	selectedTab: 'ship-order',
	shipOrderForm: {
		order: '',
		overridePrinterLocation: '',
		overrideScaleLocation: '',
		currentScaleValue: '',
	},
	pendingOrdersForm: {
		pendingOrders: '',
		currentOrdersFilter: '',
		pendingOrdersLabel: '',
		filterByLocation: '',
		filterByRateExisting: '',
		filterByMode: '',
		filterByCarrier: '',
	},
	batchStatusForm: {},
	blockedOrdersForm: {},
	flaggedOrdersForm: {},
	shippedOrdersForm: {
		parcelFulfilledToday: '',
		parcelCarrierBreakdown: '',
		freightFulfilledToday: '',
		freightCarrierBreakdown: '',
	},
	testOrdersForm: {},
	//api call
	fetchCreateRegistration: {},
}

const { stateManager, useSubscribe } = createSos(
	'ship-orders',
	'1.0.1',
	initialState,
	{
		useLocalStorage: true,
	},
)
export { useSubscribe }

export function navigateToShipOrders(params: { selectedTab: Tabs }): void {
	stateManager.produce((ds) => {
		l.defaults(params, {
			selectedTab: ds.selectedTab,
		})
		ds.selectedTab = params.selectedTab
	})
	router.navTo(`/shipments-v3/ship-orders/${params.selectedTab}`)
}
