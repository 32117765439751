import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { ValidatedSubmitButton } from 'ui/components/common/validated-submit-button'
import { Debug } from 'ui/components/dev/Debug'
import { FormVertical } from 'ui/components/form/FormVertical'
import { FormVerticalLayout } from 'ui/components/form/FormVerticalLayout'
import { FormVerticalReadOnly } from 'ui/components/form/FormVerticalReadOnly'
import { FormVerticalTextInput } from 'ui/components/form/FormVerticalTextInput'
import { IFormData } from 'ui/components/form/IFormData'
import { t } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight/AlignRight'
import {
	IPackageCatalogEntryForm,
	packageCatalogEntryFormMetadata,
} from 'ui/forms/formPackageCatalogEntry'
import { formValidator } from 'ui/lib/validation/'
import { sosPackageCatalogEntry } from 'ui/state'

export const UpdatePackageCatalogEntryForm: FC = (props: {
	mode: 'add' | 'edit'
	form: IPackageCatalogEntryForm
}) => {
	const formData: IFormData<IPackageCatalogEntryForm> = {
		form: props.form,
		metadata: packageCatalogEntryFormMetadata,
		onUpdateForm: sosPackageCatalogEntry.updatePackageCatalogEntryForm,
		tPrefix: 'shared.packageCatalog.packagePageForm',
	}

	const validationResult = formValidator.validateForm(
		formData.form,
		formData.metadata,
	)

	return (
		<div>
			<Debug hide={true} label='form data' data={props.form} />
			<FormVertical>
				<FormVerticalLayout>
					{props.mode === 'edit' && (
						<FormVerticalReadOnly formData={formData} field='id' />
					)}
					<FormVerticalTextInput formData={formData} field='name' />
					<FormVerticalTextInput formData={formData} field='length' />
					<FormVerticalTextInput formData={formData} field='width' />
					<FormVerticalTextInput formData={formData} field='height' />
					<FormVerticalTextInput formData={formData} field='barCode' />
				</FormVerticalLayout>
				<AlignRight>
					<ValidatedSubmitButton
						testId='submit-upsert-package-catalog-page'
						onClick={async () => {
							await sosPackageCatalogEntry.updatePackageCatalogEntry('upsert')
						}}
						isValid={validationResult.isValid}
					>
						{t(`shared.packageCatalog.${props.mode}PackageCatalogPage`)}
					</ValidatedSubmitButton>
				</AlignRight>
			</FormVertical>
		</div>
	)
}
