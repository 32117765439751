import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { Button } from 'ui/components/common/button'
import { Card } from 'ui/components/common/card'
import { DocumentViewer } from 'ui/components/common/modal'
import { t } from 'ui/components/i18n/i18n'
import { Spacer } from 'ui/components/layout/spacer'
import { FileDropZone } from 'ui/components/shared/csvValidator/file-drop-zone'
import { MessagesList } from 'ui/components/shared/csvValidator/messages-list'
import { l } from 'ui/lib/lodashImports'
import * as sosNewQuote from 'ui/pages/new-quote/state/sosNewQuote'
import { IStateNewQuote } from 'ui/pages/new-quote/state/sosNewQuote'

export const PackingSlipUpload: FC = (props: { state: IStateNewQuote }) => {
	const { state } = props
	const tPrefix = 'page.newQuote.packingSlipUpload'
	return (
		<Card
			title={t('uploadDocuments', tPrefix)}
			color='orange'
			fillHeight={true}
		>
			<FileDropZone
				onLoaded={sosNewQuote.addNewDocument}
				onError={sosNewQuote.addFileUploadError}
				onDrop={sosNewQuote.wipeFileUploadErrors}
				fileEncoding='dataUrl'
				supportedFileTypes={['PDF']}
				preDropText={t('preDropFile', tPrefix)}
				droppingText={t('droppingFile', tPrefix)}
				testId='vendor-file-drop'
			/>
			{l.map(state.shippingDocuments, (document, idx) => (
				<div key={idx}>
					<DocumentViewer
						documentUrl={'{{{NOT YET IMPLEMENTED}}}'}
						onModalClose={() => {
							sosNewQuote.togglePdfDocumentViewer(idx, false)
						}}
						// isOpen={document.showPdfDocumentViewer} // TODO: Implement PDF viewer
						isOpen={false}
					/>
					<Button
						color={'gray'}
						inline={true}
						onClick={() => sosNewQuote.togglePdfDocumentViewer(idx, true)}
					>
						{document.fileName}
					</Button>
					<Button
						color={'red'}
						inline={true}
						minWidth={'15px'}
						onClick={() => sosNewQuote.removeShippingDocument(document.id)}
					>
						X
					</Button>
				</div>
			))}
			{!l.isEmpty(state.fileUploadErrors) && (
				<div>
					<Card title={t('errors', tPrefix)} color='red'>
						<MessagesList
							messages={state.fileUploadErrors}
							includeTitle={false}
						/>
					</Card>
					<Spacer />
				</div>
			)}
		</Card>
	)
}
