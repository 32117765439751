import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { apiTypes } from 'ui/api'
import { IconButton } from 'ui/components/common/icon/Icon'
import { solidIcons } from 'ui/components/common/icon/solidIcons'
import { sosRouter } from 'ui/components/common/router'
import { t } from 'ui/components/i18n/i18n'
import * as tableClasses from 'ui/components/rates-table/RatesTable.module.scss'
import { prettyLocation } from 'ui/lib/locations/prettyLocations'
import { l } from 'ui/lib/lodashImports'
import { uniqueTruthyMap } from 'ui/lib/misc/uniqueTruthyMap'
import { getShipmentProfileLink } from 'ui/lib/shipments/getShipmentProfileLink'
import { getBolNumbers } from 'ui/lib/shipments/shipmentUtils'
import {
	dateRenderer,
	stringArrayRenderer,
} from '../shipments/ShipmentsListHeaderRenderers'

const tPrefixManifestsShipmentsTable = 'page.manifests.shipmentsTable'

export const ManifestShipmentsTable: FC = (props: {
	shipments: apiTypes.ShipmentResponse[]
}) => {
	const { shipments } = props

	return (
		<div>
			<table
				className={tableClasses.tableRates}
				cellPadding={5}
				cellSpacing={0}
				data-testid='manifestableShipmentsTable'
			>
				<thead>
					<tr>
						<th />
						<th>{t('shipmentId', tPrefixManifestsShipmentsTable)}</th>
						<th>{t('bookedDate', tPrefixManifestsShipmentsTable)}</th>
						<th>{t('pickupDate', tPrefixManifestsShipmentsTable)}</th>
						<th>{t('pickup', tPrefixManifestsShipmentsTable)}</th>
						<th>{t('delivery', tPrefixManifestsShipmentsTable)}</th>
						<th>{t('soNumber', tPrefixManifestsShipmentsTable)}</th>
						<th>{t('poNumber', tPrefixManifestsShipmentsTable)}</th>
						<th>{t('bolNo', tPrefixManifestsShipmentsTable)}</th>
						<th>{t('trackingNumbers', tPrefixManifestsShipmentsTable)}</th>
						<th>{t('handlingUnits', tPrefixManifestsShipmentsTable)}</th>
					</tr>
				</thead>
				<tbody>
					{l.map(shipments, (shipment, shipmentIdx) => {
						const salesOrders = l.flatMap(
							uniqueTruthyMap(
								shipment.payloads,
								(payload) => payload.salesOrders || [],
							),
						)
						const purchaseOrders = l.flatMap(
							uniqueTruthyMap(
								shipment.payloads,
								(payload) => payload.purchaseOrders || [],
							),
						)
						const bolIdentifiers = getBolNumbers(shipment)
						const trackingNumbers = uniqueTruthyMap(
							shipment.payloads,
							(payload) => payload.trackingNumber,
						)
						const handlingUnits = shipment.ltlCount
							? shipment.ltlCount
							: shipment.parcelCount
						const pickup = prettyLocation(
							shipment.origin.city,
							shipment.origin.state,
							shipment.origin.country,
						)
						const delivery = prettyLocation(
							shipment.destination.city,
							shipment.destination.state,
							shipment.destination.country,
						)

						return (
							<tr
								key={shipmentIdx}
								data-testid={'manifestableShipment-' + shipmentIdx}
							>
								<td>
									<IconButton
										icon={solidIcons.faPlusCircle}
										onClick={() => {
											sosRouter.navigate(getShipmentProfileLink(shipment.id))
										}}
									/>
								</td>
								<td>{shipment.identifier}</td>
								<td>{dateRenderer(shipment.bookedDate)}</td>
								<td>{dateRenderer(shipment.pickupDate)}</td>
								<td>{pickup}</td>
								<td>{delivery}</td>
								<td>{stringArrayRenderer(salesOrders)}</td>
								<td>{stringArrayRenderer(purchaseOrders)}</td>
								<td>{stringArrayRenderer(bolIdentifiers)}</td>
								<td>{stringArrayRenderer(trackingNumbers)}</td>
								<td>{handlingUnits}</td>
							</tr>
						)
					})}
				</tbody>
			</table>
		</div>
	)
}
