import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { TabItem } from 'ui/components/common/tabs/TabItem'
import { TabItem2 } from 'ui/components/common/tabs/TabItem2'
import { Tabs } from 'ui/components/common/tabs/Tabs'
import { Tabs2 } from 'ui/components/common/tabs/Tabs2'
import { t } from 'ui/components/i18n/i18n'
import { l } from 'ui/lib/lodashImports'
import { sosComponentExamples } from 'ui/state/'
import { Section } from './Section'

const tabs = [
	{
		name: 'tab-1',
	},
	{
		name: 'tab-2',
	},
	{
		name: 'tab-3',
	},
	{
		name: 'tab-4',
	},
]

export const SectionTabs: FC = (props: {
	stateComponentExamples: sosComponentExamples.IStateComponentExamples
}) => {
	return (
		<Section
			title={t('page.componentExamples.tabs')}
			stateComponentExamples={props.stateComponentExamples}
			name='tabs'
		>
			<Tabs>
				{l.map(tabs, (c) => (
					<TabItem
						key={c.name}
						isSelected={props.stateComponentExamples.selectedTab === c.name}
						onClick={() => {
							sosComponentExamples.selectTab(c.name)
						}}
					>
						{t('page.componentExamples.tabs.' + l.camelCase(c.name))}
					</TabItem>
				))}
			</Tabs>
			{props.stateComponentExamples.selectedTab === 'tab-2' && (
				<div>{"You've selected tab 2!!!"}</div>
			)}
			<Tabs2>
				{l.map(tabs, (c) => (
					<TabItem2
						key={c.name}
						isSelected={props.stateComponentExamples.selectedTab === c.name}
						onClick={() => {
							sosComponentExamples.selectTab(c.name)
						}}
					>
						{t('page.componentExamples.tabs.' + l.camelCase(c.name))}
					</TabItem2>
				))}
			</Tabs2>

			{props.stateComponentExamples.selectedTab === 'tab-2' && (
				<div>{"You've selected tab 2!!!"}</div>
			)}
		</Section>
	)
}
