import { apiTypes, apiCommon } from '.'
import { IRequestState } from './requestState'
import { l } from 'ui/lib/lodashImports'
import WebSocketAsPromised from 'websocket-as-promised'
import { fireAndForget } from 'ui/lib/async/fireAndForget'
import { log } from 'ui/lib/log/log'
import { sosUser } from 'ui/state'
import { DateTime } from 'luxon'
import { IRequestOptions } from 'ui/api/apiCommon'

export async function fetchBrokerShipment(
	onProgress: (rs: IRequestState<apiTypes.BrokerShipmentResponse>) => void,
	id: string,
	includeTracking?: boolean,
	includeRates?: boolean,
): Promise<IRequestState<apiTypes.BrokerShipmentResponse>> {
	let qs = '?include='
	if (includeTracking) {
		qs += 'tracking,'
	}
	if (includeRates) {
		qs += 'rates'
	}
	const result = await apiCommon.apiFetch(
		onProgress,
		{},
		`brokers/shipments/${id}${qs}`,
	)
	return result
}

export async function getSpotQuoteShipment(
	onProgress: (rs: IRequestState<apiTypes.BrokerShipmentResponse>) => void,
	shipmentId: string,
	offerId: string,
): Promise<IRequestState<apiTypes.BrokerShipmentResponse>> {
	const url = `brokers/shipments/${shipmentId}/offer/${offerId}`
	const result = await apiCommon.apiFetch(onProgress, {}, url)
	return result
}

export async function fetchBrokerShipments(
	onProgress: (rs: IRequestState<apiTypes.BrokerShipmentListResponse>) => void,
	body?: apiTypes.ApiListRequest,
): Promise<IRequestState<apiTypes.BrokerShipmentListResponse>> {
	l.defaults(body, {})

	const result = await apiCommon.apiFetch(
		onProgress,
		{ entireResponse: true, method: 'POST', data: body },
		'brokers/shipments/search',
	)

	return result
}

export async function createBrokerShipment(
	onProgress: (rs: IRequestState<apiTypes.BrokerShipmentResponse>) => void,
	data: apiTypes.ShipmentRequest,
	contractId: string,
	rates: boolean,
): Promise<IRequestState<apiTypes.BrokerShipmentResponse>> {
	const result = await apiCommon.apiFetch(
		onProgress,
		{ method: 'POST', data },
		'brokers/shipments',
		{ contractId, rates },
	)
	return result
}

export async function rateShipment(
	onProgress: (rs: IRequestState<apiTypes.BrokerShipmentResponse>) => void,
	shipmentId: string,
): Promise<IRequestState<apiTypes.BrokerShipmentResponse>> {
	return await apiCommon.apiFetch(
		onProgress,
		{ method: 'POST' },
		`brokers/shipments/${shipmentId}/quote`,
	)
}

export async function updateBrokerShipment(
	onProgress: (rs: IRequestState<apiTypes.BrokerShipmentResponse>) => void,
	id: string,
	data: apiTypes.BrokerShipmentRequest | apiTypes.BrokerShipmentResponse,
	contractId: string,
	maintainRates = true,
): Promise<IRequestState<apiTypes.BrokerShipmentResponse>> {
	const result = await apiCommon.apiFetch(
		onProgress,
		{ method: 'PUT', data },
		`brokers/shipments/${id}`,
		{ contractId, maintainRates },
	)

	return result
}

export async function createBrokerOffer(
	onProgress: (rs: IRequestState<apiTypes.BrokerOfferResponse>) => void,
	shipmentId: string,
	body: apiTypes.BrokerOfferRequest,
): Promise<IRequestState<apiTypes.BrokerOfferResponse>> {
	return await apiCommon.apiFetch(
		onProgress,
		{ method: 'POST', entireResponse: true, data: body },
		`brokers/shipments/${shipmentId}/offers`,
	)
}

export async function sendBrokerOfferToClient(
	onProgress: (rs: IRequestState<apiTypes.BrokerOfferResponse>) => void,
	shipmentId: string,
	offerId: string,
): Promise<IRequestState<apiTypes.BrokerOfferResponse>> {
	return apiCommon.apiFetch(
		onProgress,
		{ method: 'POST' },
		`brokers/shipments/${shipmentId}/offers/${offerId}/send-offer`,
	)
}

export async function retractBrokerOffer(
	onProgress: (rs: IRequestState<apiTypes.BrokerOfferResponse>) => void,
	shipmentId: string,
	offerId: string,
): Promise<IRequestState<apiTypes.BrokerOfferResponse>> {
	return apiCommon.apiFetch(
		onProgress,
		{ method: 'POST', entireResponse: true },
		`brokers/shipments/${shipmentId}/offers/${offerId}/retract-offer`,
	)
}

export async function acceptBrokerOfferForCustomer(
	onProgress: (rs: IRequestState<apiTypes.BrokerOfferResponse>) => void,
	shipmentId: string,
	offerId: string,
): Promise<IRequestState<apiTypes.BrokerOfferResponse>> {
	return apiCommon.apiFetch(
		onProgress,
		{ method: 'POST', entireResponse: true },
		`brokers/shipments/${shipmentId}/offers/${offerId}/book-tms-shipment`,
	)
}

export async function updateBrokerOffer(
	onProgress: (rs: IRequestState<apiTypes.BrokerOfferResponse>) => void,
	shipmentId: string,
	offerId: string,
	body: apiTypes.BrokerOfferRequest,
): Promise<IRequestState<apiTypes.BrokerOfferResponse>> {
	return await apiCommon.apiFetch(
		onProgress,
		{ method: 'PUT', entireResponse: true, data: body },
		`brokers/shipments/${shipmentId}/offers/${offerId}`,
	)
}

export async function deleteOffer(
	onProgress: (rs: IRequestState<apiTypes.BrokerOfferResponse>) => void,
	shipmentId: string,
	offerId: string,
): Promise<IRequestState<apiTypes.BrokerOfferResponse>> {
	return await apiCommon.apiFetch(
		onProgress,
		{ method: 'DELETE' },
		`brokers/shipments/${shipmentId}/offers/${offerId}`,
	)
}

export async function updateConnectionOffer(
	onProgress: (rs: IRequestState<void>) => void,
	shipmentId: string,
	rate: apiTypes.RateRequest,
): Promise<IRequestState<void>> {
	// Broker awards shipment to Provider // rate.offerStatus = 'awarded'
	// Broker retracts awarded shipment // rate.offerStatus = 'active'
	// Provider makes an offer on shipment // rate.offerStatus = 'offerMade'
	// Broker retracts an invite // rate.offerStatus = 'retracted'
	return await apiCommon.apiFetch(
		onProgress,
		{ method: 'PUT', data: rate },
		`brokers/shipments/${shipmentId}/offer/${rate.offerId}`,
	)
}

export async function logOfferRate(
	onProgress: (rs: IRequestState<void>) => void,
	shipmentId: string,
	rate: apiTypes.RateRequest,
): Promise<void> {
	const url = `brokers/shipments/${shipmentId}/rates` // TODO add logOffer url
	await apiCommon.apiFetch(
		onProgress,
		{
			method: 'POST',
			data: rate,
		},
		url,
	)
}

export async function brokerBook(
	onProgress: (rs: IRequestState<apiTypes.BrokerShipmentResponse>) => void,
	shipmentId: string,
	rateId: string,
	method: 'api' | 'manual',
	headerWarnings: string = null,
): Promise<IRequestState<apiTypes.BrokerShipmentResponse>> {
	// broker books with provider
	const url = `brokers/shipments/${shipmentId}/book?bookMethod=${method}`

	const options: IRequestOptions = {
		method: 'POST',
		data: { rateId },
		entireResponse: true,
	}
	if (headerWarnings) {
		options['headers'] = {
			'x-request-warnings': headerWarnings,
		}
	}
	return await apiCommon.apiFetch(onProgress, options, url)
}

export async function brokerUnbook(
	onProgress: (rs: IRequestState<apiTypes.BrokerShipmentResponse>) => void,
	shipmentId: string,
): Promise<IRequestState<apiTypes.BrokerShipmentResponse>> {
	const url = `brokers/shipments/${shipmentId}/unbook`
	return await apiCommon.apiFetch(onProgress, { method: 'POST', data: {} }, url)
}

export async function exportShipments(
	onProgress: (rs: IRequestState<void>) => void,
	params?: {
		query?: string
		email?: string
	},
): Promise<IRequestState<void>> {
	const url = 'brokers/shipments/export'
	return await apiCommon.apiFetch(onProgress, { method: 'GET' }, url, params)
}

export async function markAsInvoiced(
	onProgress: (rs: IRequestState<apiTypes.MarkAsInvoicedResponse>) => void,
	shipmentIds: string[],
	invoiceDate: string,
): Promise<IRequestState<apiTypes.MarkAsInvoicedResponse>> {
	const data: apiTypes.MarkAsInvoicedRequest = {
		shipmentIds,
		invoiceDate,
	}
	const url = 'brokers/shipments/markAsInvoiced'
	return await apiCommon.apiFetch(onProgress, { method: 'POST', data }, url)
}

export async function inviteConnections(
	onProgress: (rs: IRequestState<apiTypes.InviteConnectionsResponse>) => void,
	shipmentId: string,
	shipment: apiTypes.ShipmentRequest,
	connectionIds: string[],
	resend?: boolean,
): Promise<IRequestState<apiTypes.InviteConnectionsResponse>> {
	const url = `brokers/shipments/${shipmentId}/invite`
	const params: { resend?: boolean } = {}
	if (resend) {
		params.resend = true
	}
	const result = await apiCommon.apiFetch(
		onProgress,
		{
			method: 'POST',
			data: {
				shipment,
				connectionIds,
			},
		},
		url,
		params,
	)
	return result
}

const internalServerErrorRetryNumber = 2

export async function trickleRateShipment(
	onProgressRate: (websocketData: apiTypes.RateResponse) => void,
	onProgressRatingError: (websocketData: apiTypes.RatingError) => void,
	onComplete: () => void,
	onMajorError: () => void,
	shipmentId: string,
	websocketUrl: string,
	retries = 0,
): Promise<void> {
	const websocket = new WebSocketAsPromised(websocketUrl)

	const requestId = DateTime.local().toISO() // datestring to make sure the requestID is definitely unique
	websocket.onMessage.addListener((message) => {
		const m = JSON.parse(message)
		if (m?.message?.toLowerCase().includes('internal server error')) {
			if (retries < internalServerErrorRetryNumber) {
				retries++
				fireAndForget(
					() =>
						trickleRateShipment(
							onProgressRate,
							onProgressRatingError,
							onComplete,
							onMajorError,
							shipmentId,
							websocketUrl,
							retries,
						),
					`trickle rating retry ${retries}`,
				)
			} else {
				onMajorError()
			}
			return
		}
		if (m.requestId !== requestId) {
			return // This is for a different request, reject it
		}

		if (m.type === 'Rate') {
			onProgressRate(m.data)
		}

		if (m.type === 'Error') {
			// TODO display errors?
			log('broker-shipment-profile', 'new rates', 'Error:', m.error)
		}

		if (m.type === 'RatingError') {
			onProgressRatingError(m.data)
		}

		if (
			m.type === 'Message' &&
			m.data
				.toString()
				.toLocaleLowerCase()
				.includes('complete')
		) {
			onComplete()
			fireAndForget(async () => await websocket.close(), 'closing websocket')
		}
	})

	const requestObject: any = {
		// TODO find way to generate TrickleRequest for swagger
		action: 'getRates',
		requestId: requestId,
		authorization: `Bearer ${sosUser.getAuthToken()}`,
	}
	requestObject.brokerShipmentId = shipmentId
	await websocket.open()
	websocket.send(JSON.stringify(requestObject))
}

export async function createTrackingEvent(
	onProgress: (rs: IRequestState<apiTypes.EmptyBody>) => void,
	shipmentId: string,
	payloadId: string,
	data: apiTypes.BrokerTrackingEventRequest,
): Promise<IRequestState<apiTypes.EmptyBody>> {
	const result = await apiCommon.apiFetch(
		onProgress,
		{
			method: 'POST',
			data,
		},
		`brokers/${shipmentId}/payloads/${payloadId}/tracking-events`,
	)
	return result
}

export async function deleteTrackingEvent(
	onProgress: (rs: IRequestState<any>) => void,
	shipmentId: string,
	payloadId: string,
	trackingEventId: string,
): Promise<IRequestState<any>> {
	const result = await apiCommon.apiFetch(
		onProgress,
		{ method: 'DELETE' },
		`brokers/${shipmentId}/payloads/${payloadId}/tracking-events/${trackingEventId}`,
	)
	return result
}

export async function uploadShippingDocument(
	onProgress: (rs: IRequestState<apiTypes.ShippingDocumentResponse>) => void,
	data: apiTypes.ShippingDocumentRequest,
): Promise<IRequestState<apiTypes.ShippingDocumentResponse>> {
	const result = await apiCommon.apiFetch(
		onProgress,
		{ method: 'POST', data },
		'brokers/shipping-documents',
		{},
	)
	return result
}
