import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { InfiniteLoader } from 'ui/components/common/loader'
import { l } from 'ui/lib/lodashImports'
import { sosApiDocs } from 'ui/state'
// import { data } from './data'
import { MenuItem } from './MenuItem'
import { MenuSubItem } from './MenuSubItem'

export const Menu: FC = (props: {
	data: any
	selectedMenu: string
	selectedSubMenu: string
}) => {
	if (!props.data) {
		return (
			<div>
				<InfiniteLoader isLoading={true} />
			</div>
		)
	}

	return (
		<div>
			{l.map(props.data.examples, (c, cIdx) => (
				<div key={cIdx}>
					<MenuItem
						menu={c.name}
						isSelected={c.name === props.selectedMenu && !props.selectedSubMenu}
						onClick={async () => {
							await sosApiDocs.setMenuSelection(c.name)
						}}
					>
						{c.name}
					</MenuItem>
					{c.name === props.selectedMenu && (
						<div>
							{l.map(c.examples, (d, dIdx) => (
								<MenuSubItem
									menu={c.name}
									subMenu={d.name}
									key={dIdx}
									isSelected={d.name === props.selectedSubMenu}
									onClick={async () => {
										await sosApiDocs.setMenuSelection(c.name, d.name)
									}}
								>
									{d.name}
								</MenuSubItem>
							))}
						</div>
					)}
				</div>
			))}
		</div>
	)
}
