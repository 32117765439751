import { FC } from 'app/FunctionalComponent'
import React from 'react'
import {
	Breadcrumb,
	IBreadcrumbEntry,
} from 'ui/components/common/breadcrumb/Breadcrumb'
import { Debug } from 'ui/components/dev/Debug'
import { useOnce } from 'ui/components/hooks/useOnce'
import { Spacer } from 'ui/components/layout/spacer/Spacer'
import { Layout } from 'ui/pages/layout/Layout'
import { sosProductCatalogEntry } from 'ui/state'

import { ProductCatalogDataTable } from './ProductCatalogDataTable'

export const ManageProductCatalogsPage: FC = (props: {}) => {
	const state = sosProductCatalogEntry.useSubscribe()

	useOnce(async () => {
		await sosProductCatalogEntry.getBooks()
	})

	const bread: IBreadcrumbEntry[] = [
		{
			t: 'page.manageProductCatalogs.manageProductCatalogs',
		},
	]

	return (
		<Layout>
			<Breadcrumb entries={bread} />
			<Debug
				hide={true}
				label='book get all request'
				data={state.requestGetProductCatalogs}
			/>
			<ProductCatalogDataTable
				requestGetBooks={state.requestGetProductCatalogs}
				requestAddEditBook={state.requestAddEditProductCatalog}
				data={state.dataProductCatalog}
				dataTableState={state.dataTableProductCatalog}
				form={state.formProductCatalog}
			/>
			<Spacer />
			<Debug
				hide={true}
				label='book create request'
				data={state.requestAddEditProductCatalog}
			/>
		</Layout>
	)
}
