import { apiTypes, apiShipments } from 'ui/api'
import { l } from 'ui/lib/lodashImports'
import { DateTime } from 'luxon'
import { controlTowerCalendarTPrefix } from '../ControlTowerCalendarPage'
import { tArgz } from 'ui/components/i18n/i18n'
import { setEvents } from './sosControlTowerCalendar'
import { buildQuery, Flows, Modes } from '../../common/controlTowerUtils'
import { tms2_common } from 'ui/lib'
import { isInTMS2 } from 'ui/theme/theme'

export interface DayData {
	pickups?: number
	latePickups?: number
	scheduledPickups?: number
	deliveries?: number
	lateDeliveries?: number
	scheduledDeliveries?: number
}

export interface EventData {
	[k: string]: DayData
}

export interface CalendarEvent {
	title: string
	start: string
	groupId?: number
	backgroundColor?: string
	borderColor?: string
}

const eventColors: { [k: string]: { bg: string; border: string } } = {
	onTime: { bg: '#e6ffec', border: '#9AB3A0' },
	late: { bg: '#ffe6e6', border: '#B39A9A' },
	scheduled: { bg: '#fdffe6', border: '#B1B39A' },
}

export const _createDefaultDayData = (): DayData => {
	return {
		pickups: 0,
		latePickups: 0,
		scheduledPickups: 0,
		deliveries: 0,
		lateDeliveries: 0,
		scheduledDeliveries: 0,
	}
}

export const _compareDates = (
	dtOne: DateTime | string,
	dtTwo: DateTime | string,
): number => {
	if (typeof dtOne === 'string') {
		dtOne = DateTime.fromISO(dtOne)
	}

	if (typeof dtTwo === 'string') {
		dtTwo = DateTime.fromISO(dtTwo)
	}
	return dtOne.diff(dtTwo).valueOf()
}

export const parsePayloadsToEvents = (
	payloads: apiTypes.PayloadResponse[],
	flow?: Flows,
): EventData => {
	const eventData: EventData = {}

	const today = DateTime.local().startOf('day')

	l.forEach(payloads, (c) => {
		const pickupTime =
			c.originStop.metaData?.desiredDateTimeInfo?.latestDate ||
			c.originStop.metaData?.desiredDateTimeInfo?.initialDate

		const deliveryTime =
			c.destinationStop.metaData?.desiredDateTimeInfo?.latestDate ||
			c.destinationStop.metaData?.desiredDateTimeInfo?.initialDate

		//handle pickups
		if (
			pickupTime &&
			flow !== 'inbound' &&
			!l.isNil(c.originStop?.metaData?.locationId)
		) {
			const timeDiff = _compareDates(pickupTime, today)
			if (!eventData[pickupTime]) {
				eventData[pickupTime] = _createDefaultDayData()
			}
			//in the past
			if (timeDiff < 0) {
				if (
					l.isNil(c.originStop.metaData.actualDateTimeInfo?.initialDate) ||
					_compareDates(
						c.originStop.metaData.actualDateTimeInfo?.initialDate,
						pickupTime,
					) > 0
				) {
					eventData[pickupTime].latePickups++
				} else {
					eventData[pickupTime].pickups++
				}
			} else if (timeDiff === 0) {
				if (c.originStop.metaData.actualDateTimeInfo?.initialDate) {
					eventData[pickupTime].pickups++
				} else {
					eventData[pickupTime].scheduledPickups++
				}
			} else {
				eventData[pickupTime].scheduledPickups++
			}
		}

		//handle deliveries
		if (
			deliveryTime &&
			flow !== 'outbound' &&
			!l.isNil(c.destinationStop?.metaData?.locationId)
		) {
			const timeDiff = _compareDates(deliveryTime, today)
			if (!eventData[deliveryTime]) {
				eventData[deliveryTime] = _createDefaultDayData()
			}
			//in the past
			if (timeDiff < 0) {
				if (
					l.isNil(c.destinationStop.metaData.actualDateTimeInfo?.initialDate) ||
					_compareDates(
						c.destinationStop.metaData.actualDateTimeInfo?.initialDate,
						deliveryTime,
					) > 0
				) {
					eventData[deliveryTime].lateDeliveries++
				} else {
					eventData[deliveryTime].deliveries++
				}
			} else if (timeDiff === 0) {
				if (c.destinationStop.metaData.actualDateTimeInfo?.initialDate) {
					eventData[deliveryTime].deliveries++
				} else {
					eventData[deliveryTime].scheduledDeliveries++
				}
			} else {
				eventData[deliveryTime].scheduledDeliveries++
			}
		}
	})

	return eventData
}

const createCalendarEvent = (
	eventType: keyof EventData,
	count: number,
	date: string,
): CalendarEvent => {
	const eventTPrefix = controlTowerCalendarTPrefix + '.event'

	const today = DateTime.local().startOf('day')
	let eventTitle = count > 1 ? eventType + '.plural' : eventType + '.single'

	if (DateTime.fromISO(date) < today) {
		eventTitle += '.past'
	}

	const newEvent: CalendarEvent = {
		title: tArgz(eventTitle, { count }, eventTPrefix),
		start: date,
	}
	switch (eventType) {
		case 'pickups':
			newEvent.groupId = 1
			newEvent.backgroundColor = eventColors.onTime.bg
			newEvent.borderColor = eventColors.onTime.border
			break
		case 'latePickups':
			newEvent.groupId = 2
			newEvent.backgroundColor = eventColors.late.bg
			newEvent.borderColor = eventColors.late.border
			break
		case 'scheduledPickups':
			newEvent.groupId = 3
			newEvent.backgroundColor = eventColors.scheduled.bg
			newEvent.borderColor = eventColors.scheduled.border
			break
		case 'deliveries':
			newEvent.groupId = 4
			newEvent.backgroundColor = eventColors.onTime.bg
			newEvent.borderColor = eventColors.onTime.border
			break
		case 'lateDeliveries':
			newEvent.groupId = 5
			newEvent.backgroundColor = eventColors.late.bg
			newEvent.borderColor = eventColors.late.border
			break
		case 'scheduledDeliveries':
			newEvent.groupId = 6
			newEvent.backgroundColor = eventColors.scheduled.bg
			newEvent.borderColor = eventColors.scheduled.border
			break
	}

	return newEvent
}

export const parseEventDataIntoCalendarEvents = (
	events: EventData,
): CalendarEvent[] => {
	const calendarEvents = []

	l.forEach(events, (c, cIdx) => {
		l.forEach(c, (num: number, eventType: string) => {
			if (num > 0) {
				calendarEvents.push(createCalendarEvent(eventType, num, cIdx))
			}
		})
	})

	return calendarEvents
}

export const _addRawEvents = (
	currentEvents: EventData,
	newEvents: EventData,
): EventData => {
	l.forEach(newEvents, (c, cKey) => {
		if (currentEvents[cKey]) {
			const dayData = currentEvents[cKey]
			dayData.pickups += c.pickups ?? 0
			dayData.latePickups += c.latePickups ?? 0
			dayData.scheduledPickups += c.scheduledPickups ?? 0
			dayData.deliveries += c.deliveries ?? 0
			dayData.lateDeliveries += c.lateDeliveries ?? 0
			dayData.scheduledDeliveries += c.scheduledDeliveries ?? 0
		} else {
			currentEvents[cKey] = c
		}
	})

	return currentEvents
}

export const fetchShipments = async (
	currentDate: DateTime,
	locationId: string,
	flow: Flows,
	providerId: string,
	mode: Modes,
): Promise<void> => {
	const queryObj = buildQuery(
		DateTime.local(currentDate.year, currentDate.month, 1).minus({ days: 6 }),
		DateTime.local(
			currentDate.year,
			currentDate.month,
			currentDate.daysInMonth,
		).plus({ days: 10 }),
		isInTMS2()
			? await tms2_common.currently_managed_organization_ids()
			: [locationId],
		providerId,
		mode,
		flow,
	)
	const query = queryObj.query
	const nestedFieldQuery = queryObj.nestedFieldQuery
	let response = await apiShipments.fetchShipments(() => {}, {
		take: 100,
		query,
		nestedFieldQuery,
		sort: 'createdDate:asc',
	})
	if (response.data) {
		let payloads: apiTypes.PayloadResponse[] = []
		l.forEach(response.data.entities, (c) => {
			payloads = payloads.concat(c.payloads)
		})

		let events = parsePayloadsToEvents(payloads, flow)
		setEvents(parseEventDataIntoCalendarEvents(events))

		const numberOfNextRequests = Math.floor(response.data.total / 100)

		for (let i = 1; i <= numberOfNextRequests; i++) {
			response = await apiShipments.fetchShipments(() => {}, {
				take: 100,
				skip: i * 100,
				sort: 'createdDate:asc',
				query,
				nestedFieldQuery,
			})

			const additionalPayloads = []
			if (response.data) {
				l.forEach(response.data.entities, (shipment) => {
					additionalPayloads.push(...shipment.payloads)
				})
			}

			const newEvents = parsePayloadsToEvents(additionalPayloads, flow)
			events = _addRawEvents(events, newEvents)
			setEvents(parseEventDataIntoCalendarEvents(events))
		}
	}
}
