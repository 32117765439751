import { createLazySos2 } from 'ui/lib/state/sos2/sos2'
import { MapMarker } from './controlTowerMapUtils'

export interface ControlTowerMapState {
	mapMarkers: MapMarker[]
}

export const getSos = createLazySos2<ControlTowerMapState>(
	'sosControlTowerMap',
	1,
	() => ({
		mapMarkers: { default: [], localStorage: false },
	}),
)

export const setMapMarkers = (mapMarkers: MapMarker[]): void => {
	getSos().change((ds) => {
		ds.mapMarkers = mapMarkers
	})
}
