import React, { ReactElement } from 'react'
import { t } from 'ui/components/i18n/i18n'
import {
	FileDropZone,
	FileDropZoneData,
} from 'ui/components/shared/csvValidator/file-drop-zone'
import * as classes from './CustomerDetailsPictureUpload.module.scss'
import { Form } from 'react-bootstrap'
import { sosCustomerManagement } from '../../../customer-management/'

export const CustomerDetailsPictureUpload = (props: {
	existingImageUrl?: string
	headerText?: ReactElement | string
	brandingPictureData: FileDropZoneData
	tPrefix: string
}): ReactElement => {
	const tPrefixCustomerDetailsPictureUpload = props.tPrefix + '.pictureUpload'

	return (
		<div className={classes.pictureUpload}>
			<Form.Label>{props.headerText}</Form.Label>

			<div className={classes.pictureUploadContainer}>
				<FileDropZone
					onLoaded={(fileContent: string, filename: string) => {
						sosCustomerManagement.updateBrandingPictureData({
							fileName: filename,
							fileContent,
						})
					}}
					preDropText={t(
						'uploadImageHere',
						tPrefixCustomerDetailsPictureUpload,
					)}
					droppingText={t('uploading', tPrefixCustomerDetailsPictureUpload)}
					testId={'picture-upload'}
					fileEncoding='dataUrl'
				/>

				<img
					src={
						props.brandingPictureData?.fileContent
							? props.brandingPictureData?.fileContent
							: props.existingImageUrl
					}
					className={classes.pictureImage}
					alt={''}
				/>
			</div>
		</div>
	)
}
