import { callTms2Global } from './IframeRpc'

const tms2_common = {
	getAuthInfo: async () => {
		return await callTms2Global<{
			loginToken: string
			loginTokenExpires: string
			userId: string
		}>('getAuthInfo', {})
	},
	// Get currently selected locations from TMS2
	currently_managed_organization_ids: async () => {
		return await callTms2Global<string[]>('currently_managed_organization_ids')
	},
	currently_used_top_level_organization_id: async () => {
		return await callTms2Global<string>(
			'currently_used_top_level_organization_id',
		)
	},
	loggedInUserIsProvider: async () => {
		return callTms2Global<boolean>('user_loggedInUserIsProvider', null)
	},
}
export { tms2_common }
