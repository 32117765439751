import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { ValidatedSubmitButton } from 'ui/components/common/validated-submit-button'
import { FormVertical } from 'ui/components/form/FormVertical'
import { FormVerticalLayout } from 'ui/components/form/FormVerticalLayout'
import { FormVerticalReadOnly } from 'ui/components/form/FormVerticalReadOnly'
import { FormVerticalTextInput } from 'ui/components/form/FormVerticalTextInput'
import { IFormData } from 'ui/components/form/IFormData'
import { t } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight/AlignRight'
import {
	accountingCodeCatalogFormMetadata,
	IAccountingCodeCatalogForm,
} from 'ui/forms/formAccountingCodeCatalog'
import { formValidator } from 'ui/lib/validation/'
import { sosAccountingCodeCatalog } from 'ui/state'

export const UpdateAccountingCodeCatalogForm: FC = (props: {
	mode: 'add' | 'edit'
	form: IAccountingCodeCatalogForm
}) => {
	const formData: IFormData<IAccountingCodeCatalogForm> = {
		form: props.form,
		metadata: accountingCodeCatalogFormMetadata,
		onUpdateForm: sosAccountingCodeCatalog.updateCatalogForm,
		tPrefix: 'page.manageAccountingCodeCatalogs.bookForm',
	}

	const validationResult = formValidator.validateForm(
		formData.form,
		formData.metadata,
	)

	return (
		<div>
			<FormVertical>
				<FormVerticalLayout>
					{props.mode === 'edit' && (
						<FormVerticalReadOnly formData={formData} field='id' />
					)}

					<FormVerticalTextInput formData={formData} field='name' />
				</FormVerticalLayout>
				<AlignRight>
					<ValidatedSubmitButton
						testId='submit-upsert-acc-code-catalog'
						onClick={async () => {
							await sosAccountingCodeCatalog.updateCatalog('upsert')
						}}
						isValid={validationResult.isValid}
					>
						{t(`page.manageAccountingCodeCatalogs.${props.mode}Book`)}
					</ValidatedSubmitButton>
				</AlignRight>
			</FormVertical>
		</div>
	)
}
