import { FC } from 'app/FunctionalComponent'
import React from 'react'
import {
	Breadcrumb,
	IBreadcrumbEntry,
} from 'ui/components/common/breadcrumb/Breadcrumb'
import { Debug } from 'ui/components/dev/Debug'
import { useOnce } from 'ui/components/hooks/useOnce'
import { Spacer } from 'ui/components/layout/spacer/Spacer'
import { Layout } from 'ui/pages/layout/Layout'
import { sosAccountingCodeCatalog } from 'ui/state'
import { AccountingCodeCatalogDataTable } from './AccountingCodeCatalogDataTable'

export const ManageAccountingCodeCatalogsPage: FC = (props: {}) => {
	const state = sosAccountingCodeCatalog.useSubscribe()

	useOnce(async () => {
		await sosAccountingCodeCatalog.getCatalogs()
	})

	const bread: IBreadcrumbEntry[] = [
		{
			t: 'page.manageAccountingCodeCatalogs.manageAccountingCodeCatalogs',
		},
	]

	return (
		<Layout>
			<Breadcrumb entries={bread} />
			<Debug
				hide={true}
				label='book get all request'
				data={state.requestGetAccountingCodeCatalogs}
			/>
			<AccountingCodeCatalogDataTable
				requestGetAccountingCodeCatalogs={
					state.requestGetAccountingCodeCatalogs
				}
				requestAddEditAccountingCodeCatalog={
					state.requestAddEditAccountingCodeCatalog
				}
				dataTableState={state.dataTableAccountingCodeCatalogs}
				data={state.dataAccountingCodeCatalogs}
				form={state.formAccountingCodeCatalog}
			/>
			<Spacer />
			<Debug
				hide={true}
				label='book create request'
				data={state.requestAddEditAccountingCodeCatalog}
			/>
		</Layout>
	)
}
