import { UpdateMode } from 'ui/api/apiCommon'
import { IRequestState } from 'ui/api/requestState'
import { IAccountingCodeCatalogEntry } from 'ui/lib/books/IAccountingCodeCatalogEntry'
import { IAccountingCodeCatalog } from 'ui/lib/books'
import { apiCommon } from '.'

export async function getAccountingCodeCatalogs(
	onProgress: (rs: IRequestState<IAccountingCodeCatalog[]>) => void,
): Promise<IRequestState<IAccountingCodeCatalog[]>> {
	return await apiCommon.apiFetch(onProgress, {}, 'books/accounting-code')
}

export async function getAccountingCodeCatalog(
	bookId: string,
	onProgress: (rs: IRequestState<IAccountingCodeCatalog>) => void,
): Promise<IRequestState<IAccountingCodeCatalog>> {
	const result = await apiCommon.apiFetch(
		onProgress,
		{},
		`books/accounting-code/${bookId}`,
	)
	return result
}

export async function updateAccountingCodeCatalog(
	mode: UpdateMode,
	data: Partial<IAccountingCodeCatalog>,
	onProgress: (rs: IRequestState<IAccountingCodeCatalog>) => void,
): Promise<IRequestState<IAccountingCodeCatalog>> {
	let url = 'books/accounting-code'
	if (data.id) {
		url += `/${data.id}`
	}
	const result = await apiCommon.apiFetch(
		onProgress,
		{
			method: apiCommon.modeToMethod(mode, data),
			data,
		},
		url,
	)
	return result
}

export async function getAccountingCodeCatalogEntries(
	bookId: string,
	onProgress: (rs: IRequestState<IAccountingCodeCatalogEntry[]>) => void,
): Promise<IRequestState<IAccountingCodeCatalogEntry[]>> {
	const result = await apiCommon.apiFetch(
		onProgress,
		{},
		`books/accounting-code/${bookId}/pages`,
	)
	return result
}

export async function updateAccountingCodeCatalogEntry(
	mode: UpdateMode,
	bookId: string,
	data: Partial<IAccountingCodeCatalogEntry>,
	onProgress: (rs: IRequestState<IAccountingCodeCatalogEntry>) => void,
): Promise<IRequestState<IAccountingCodeCatalogEntry>> {
	let url = `books/accounting-code/${bookId}/pages`
	if (data.id) {
		url += `/${data.id}`
	}
	const result = await apiCommon.apiFetch(
		onProgress,
		{
			method: apiCommon.modeToMethod(mode, data),
			data,
		},
		url,
	)
	return result
}
