import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { SimpleLink } from 'ui/components/common/router'
import { Spacer } from 'ui/components/layout/spacer'
import { LeafletMapStop } from 'ui/components/shared/leaflet-map/utils/leafletMapStop'
import {
	IKeyValueOptions,
	KeyValue,
	KeyValueSpacer,
	SimpleKeyValueTable,
} from 'ui/components/table'
import { l } from 'ui/lib/lodashImports'
import { getShipmentProfileLink } from 'ui/lib/shipments/getShipmentProfileLink'
import { AddressDetails } from '.'
import { controlTowerMapTPrefix } from '..'

interface RouteInfo {
	stops: LeafletMapStop[]
	shipmentNumber: string
	carrier: string
	mode: string
	proNumber: string
	shipmentId: string
	purchaseOrders: string[]
	salesOrders: string[]
}

export const RouteStopDetails: FC = (props: { routeInfo: RouteInfo }) => {
	const { routeInfo } = props
	const routeDetailsKVOptions: IKeyValueOptions = {
		tPrefix: controlTowerMapTPrefix + '.routeDetails',
	}

	return (
		<div>
			{l.map(routeInfo.stops, (stop, stopIdx) => (
				<AddressDetails
					address={stop}
					markerColor={stop.markerColor}
					key={stopIdx}
				/>
			))}
			<Spacer />
			<SimpleKeyValueTable borderless={true}>
				<KeyValue
					options={routeDetailsKVOptions}
					data={props.routeInfo}
					field='shipmentNumber'
					renderer={(shipmentNumber: string, data) => (
						<SimpleLink href={getShipmentProfileLink(data.shipmentId)}>
							{shipmentNumber}
						</SimpleLink>
					)}
				/>
				<KeyValue
					options={routeDetailsKVOptions}
					data={props.routeInfo}
					field='proNumber'
				/>
				<KeyValue
					options={routeDetailsKVOptions}
					data={props.routeInfo}
					field='salesOrders'
					renderer={(dataItem: string[]) => dataItem?.join(', ')}
				/>
				<KeyValue
					options={routeDetailsKVOptions}
					data={props.routeInfo}
					field='purchaseOrders'
					renderer={(dataItem: string[]) => dataItem?.join(', ')}
				/>

				<KeyValueSpacer />
				<KeyValue
					options={routeDetailsKVOptions}
					data={props.routeInfo}
					field='carrier'
				/>
				<KeyValue
					options={routeDetailsKVOptions}
					data={props.routeInfo}
					field='mode'
				/>
			</SimpleKeyValueTable>
		</div>
	)
}
