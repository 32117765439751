import { FC } from 'app/FunctionalComponent'
import { sosToast } from 'common/components/toast'
import React, { useState } from 'react'
import { apiTypes } from 'ui/api'
import {
	searchFeatureToggles,
	updateFeatureToggle,
} from 'ui/api/apiFeatureToggles'
import { Button } from 'ui/components/common/button'
import { Checkbox } from 'ui/components/common/checkbox'
import { InfiniteLoader, Loader } from 'ui/components/common/loader'
import { SearchInput } from 'ui/components/common/search'
import { useOnce } from 'ui/components/hooks'
import { t, tString } from 'ui/components/i18n/i18n'
import { Center } from 'ui/components/layout/center'
import * as tableClasses from 'ui/components/rates-table/RatesTable.module.scss'
import { fireAndForget } from 'ui/lib/async'
import { l } from 'ui/lib/lodashImports'
import { Layout } from 'ui/pages/layout/Layout'
import { addClass } from 'ui/theme/theme'
import { AddFeatureToggleModal } from './AddFeatureToggleModal'
import * as classes from './ManageFeatureToggle.module.scss'

export const tPrefix = 'page.manageFeatureToggle'

export const ManageFeatureToggle: FC = () => {
	const [featureToggleList, setFeatureToggleList] = useState<
		apiTypes.FeatureToggleResponse[]
	>([])
	const [updatingToggleId, setUpdatingToggleId] = useState<string>(null)
	const [fetchingFeatureToggleList, setFetchingFeatureToggleList] = useState<
		boolean
	>(false)
	const [searchTerm, setSearchTerm] = useState<string>()
	const [addFeatureToggleModalOpen, setAddFeatureToggleModalOpen] = useState<
		boolean
	>(false)

	useOnce(() => {
		onSearchFeatures()
	})

	const onSearchFeatures = (query: string = null): void => {
		fireAndForget(async () => {
			setSearchTerm(query)
			setFetchingFeatureToggleList(true)

			const result = await searchFeatureToggles(() => {}, query)

			if (result.data) {
				setFeatureToggleList(result.data)
			} else if (result.error) {
				sosToast.sendApiErrorResponseToast(result)
			}

			setFetchingFeatureToggleList(false)
		}, 'Searching Feature Toggle')
	}

	const toggleFeatureEnabled = async (
		featureToggle: apiTypes.FeatureToggleResponse,
		featureName: string,
		sourceId: string,
	): Promise<void> => {
		if (featureToggle) {
			const newFeatureToggle = Object.assign({}, featureToggle)
			newFeatureToggle.enabled = !featureToggle.enabled

			const result = await updateFeatureToggle(
				() => {},
				newFeatureToggle,
				featureName,
				sourceId,
			)

			if (result.data) {
				const newFeatureToggleList = featureToggleList.map(function (feature) {
					if (feature.id === featureToggle.id) {
						return result.data
					}
					return feature
				})

				setFeatureToggleList(newFeatureToggleList)
			} else if (result.error) {
				sosToast.sendApiErrorResponseToast(result)
			}
		}
	}

	return (
		<Layout>
			<div className={addClass(classes.tableWrapper, 'bootstrap-wrapper')}>
				<h1>{t('Feature Toggles', tPrefix)}</h1>

				<div className='d-flex flex-row justify-content-between'>
					<div className={classes.tableSearch}>
						<SearchInput
							testId='manageFeatureToggle-searchFor'
							value={searchTerm || ''}
							placeholder={tString('Search Features List', tPrefix)}
							onChange={onSearchFeatures}
							width='280px'
							readOnly={!l.isNil(updatingToggleId)}
						/>
					</div>
					<div>
						<Button
							color='green'
							bootstrapStyles={true}
							onClick={() => setAddFeatureToggleModalOpen(true)}
						>
							{t('addFeatureToggle', tPrefix)}
						</Button>
					</div>
				</div>

				<InfiniteLoader isLoading={fetchingFeatureToggleList} />

				<table
					className={tableClasses.tableRates}
					cellPadding={0}
					cellSpacing={0}
				>
					<thead>
						<tr>
							<th>{t('feature', tPrefix)}</th>
							<th>{t('description', tPrefix)}</th>
							<th>{t('sourceName', tPrefix)}</th>
							<th>{t('sourceId', tPrefix)}</th>
							<th>{t('enabled', tPrefix)}</th>
						</tr>
					</thead>
					<tbody>
						{l.map(featureToggleList, (feat, cIdx) => {
							return (
								<tr
									key={cIdx}
									data-testid={`manageFeaturesToggleTable-${cIdx}`}
								>
									<td>{feat.feature}</td>
									<td>{feat.description}</td>
									<td>{feat.sourceName}</td>
									<td>{feat.sourceId}</td>
									<td>
										<Center>
											{updatingToggleId === feat.id ? (
												<Loader
													isLoading={true}
													testId='feature-enabled-button-spinner'
												/>
											) : (
												<Checkbox
													value={feat.enabled}
													readOnly={!l.isNil(updatingToggleId)}
													onChange={async () => {
														setUpdatingToggleId(feat.id)

														await toggleFeatureEnabled(
															feat,
															feat.feature,
															feat.sourceId,
														)
														setUpdatingToggleId(null)
													}}
												>
													{' '}
												</Checkbox>
											)}
										</Center>
									</td>
								</tr>
							)
						})}
					</tbody>
				</table>
			</div>
			<AddFeatureToggleModal
				isOpen={addFeatureToggleModalOpen}
				onClose={() => setAddFeatureToggleModalOpen(false)}
				onCreate={onSearchFeatures}
			/>
		</Layout>
	)
}
