import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { ISelectOptions } from 'ui/components/common/select/ISelectOptions'
import { Select } from 'ui/components/common/select/Select'
import { FormTextInput } from 'ui/components/form/FormTextInput'
import { t } from 'ui/components/i18n/i18n'
import { FlexRow } from 'ui/components/layout/flexRow/FlexRow'
import { fireAndForget } from 'ui/lib/async/fireAndForget'
import { l } from 'ui/lib/lodashImports'
import * as sosLoadOptimizer from 'ui/pages/load-optimizer/state/sosLoadOptimizer'
import { tPrefixLoadOptimizer } from '../payloads-table/LoadOptimizerPayloadsTable'
import { IFormattedRule } from '../state/sosLoadOptimizer'

const stopTypeOptions: ISelectOptions[] = [
	{ value: 'pickup', label: 'pickup' },
	{ value: 'delivery', label: 'delivery' },
]

const addressMatchStrengthOptions: ISelectOptions[] = [
	{ value: 'fullAddress', label: 'fullAddress' },
	{ value: 'city', label: 'city' },
	{ value: 'state', label: 'state' },
	{ value: 'fiveDigitPostal', label: 'fiveDigitPostal' },
	{ value: 'threeDigitPostal', label: 'threeDigitPostal' },
	{ value: 'country', label: 'country' },
	{ value: 'locationId', label: 'locationId' },
]

const sizeTypeOptions: ISelectOptions[] = [
	{ value: 'totalWeight', label: 'totalWeight' },
	{ value: 'handlingUnitCount', label: 'handlingUnitCount' },
	{ value: 'palletSpotCount', label: 'palletSpotCount' },
]

const sizeOperatorOptions: ISelectOptions[] = [
	{ value: 'lessThan', label: 'lessThan' },
	{ value: 'lessThanOrEqualTo', label: 'lessThanOrEqualTo' },
	{ value: 'greaterThan', label: 'greaterThan' },
	{ value: 'greaterThanOrEqualTo', label: 'greaterThanOrEqualTo' },
]

const units = {
	totalWeight: 'lbs',
	handlingUnitCount: 'units',
	palletSpotCount: 'spots',
}

export const RuleSpecifications: FC = (props: {
	cell
	row: IFormattedRule
}) => {
	const { row } = props
	return (
		<div>
			{row.ruleType === 'address' && (
				<FlexRow verticalAlign='center'>
					{t('ordersMustMatchOn', tPrefixLoadOptimizer)}
					<Select
						value={row.stopType}
						options={stopTypeOptions}
						onChange={(newVal: IFormattedRule['stopType']) => {
							const updatedRule = l.cloneDeep(row)
							updatedRule.stopType = newVal
							fireAndForget(
								async () => sosLoadOptimizer.updateRule(updatedRule),
								'update rule',
							)
						}}
						tPrefix={tPrefixLoadOptimizer}
					/>
					<Select
						value={row.addressMatchStrength}
						options={addressMatchStrengthOptions}
						onChange={(newVal: IFormattedRule['addressMatchStrength']) => {
							const updatedRule = l.cloneDeep(row)
							updatedRule.addressMatchStrength = newVal
							fireAndForget(
								async () => sosLoadOptimizer.updateRule(updatedRule),
								'update rule',
							)
						}}
						tPrefix={tPrefixLoadOptimizer}
					/>
				</FlexRow>
			)}
			{row.ruleType === 'size' && (
				<FlexRow verticalAlign='center'>
					{t('combined', tPrefixLoadOptimizer)}
					<Select
						value={row.sizeType}
						options={sizeTypeOptions}
						onChange={(newVal: IFormattedRule['sizeType']) => {
							const updatedRule = l.cloneDeep(row)
							updatedRule.sizeType = newVal
							fireAndForget(
								async () => sosLoadOptimizer.updateRule(updatedRule),
								'update rule',
							)
						}}
						tPrefix={tPrefixLoadOptimizer}
					/>
					{t('is', tPrefixLoadOptimizer)}
					<Select
						value={row.sizeOperator}
						options={sizeOperatorOptions}
						onChange={(newVal: IFormattedRule['sizeOperator']) => {
							const updatedRule = l.cloneDeep(row)
							updatedRule.sizeOperator = newVal
							fireAndForget(
								async () => sosLoadOptimizer.updateRule(updatedRule),
								'update rule',
							)
						}}
						tPrefix={tPrefixLoadOptimizer}
					/>
					<FormTextInput
						form={{
							sizeThreshold: row.sizeThreshold,
						}}
						field='sizeThreshold'
						onUpdateForm={(field, newVal) => {
							const updatedRule = l.cloneDeep(row)
							updatedRule.sizeThreshold = newVal
							fireAndForget(
								async () => sosLoadOptimizer.updateRule(updatedRule),
								'update rule',
							)
						}}
						width='80px'
					/>
					{t(units[row.sizeType], tPrefixLoadOptimizer)}
				</FlexRow>
			)}
			{row.ruleType === 'equipmentType' && (
				<FlexRow verticalAlign='center'>
					{t('ordersMustHaveMatchingEquipmentType', tPrefixLoadOptimizer)}
				</FlexRow>
			)}
		</div>
	)
}
