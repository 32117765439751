import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { Button } from 'ui/components/common/button'
import {
	FormStacked,
	FormStackedReadOnly,
	FormStackedTextInput,
	FormVerticalCheckbox,
	IFormData,
	IFormMetadataCollection,
} from 'ui/components/form'
import { t } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight'
import { fireAndForget } from 'ui/lib/async'
import { l } from 'ui/lib/lodashImports'
import { sosDockSchedulerAppointmentDetails } from '../state'
import { IStateDockSchedulerAppointmentDetails } from '../state/sosDockSchedulerAppointmentDetails'
import { DockSchedulerAppointmentDetailsCard } from './DockSchedulerAppointmentDetailsCard'
import {
	checkOutMetaData,
	IAppointmentDetailsCheckOutForm,
} from './DockSchedulerAppointmentDetailsForms'

const tPrefix = 'page.dockScheduler.appointmentDetails.checkOut'

const findExpectedCheckoutWeight = (
	state: IStateDockSchedulerAppointmentDetails,
): number => {
	const expectedWeight = state.generalInfoForm.expectedWeight | 0
	if (state.appointment.stopType === 'delivery') {
		return state.checkInForm.weight - expectedWeight
	} else {
		return state.checkInForm.weight + expectedWeight
	}
}

const showDurationWarning = (
	state: IStateDockSchedulerAppointmentDetails,
): boolean => {
	if (state.checkOutForm.duration) {
		const durationValues = state.checkOutForm.duration.split('h ') // left side of split is hours, right side is minutes
		const durationMinutes =
			Number(durationValues[0]) * 60 + Number(durationValues[1].substr(0, 2))
		return durationMinutes > state.appointment.scheduledDuration
	} else {
		return false
	}
}

const setCustomValidators = (
	metadata: IFormMetadataCollection<IAppointmentDetailsCheckOutForm>,
	state: IStateDockSchedulerAppointmentDetails,
): IFormMetadataCollection<IAppointmentDetailsCheckOutForm> => {
	metadata.tractorNumber.customValidator = (val) =>
		!state.checkInForm.tractorNumber || val === state.checkInForm.tractorNumber
			? null
			: tPrefix + '.tractorMismatchError'
	metadata.trailerNumber.customValidator = (val) =>
		!state.checkInForm.trailerNumber || val === state.checkInForm.trailerNumber
			? null
			: tPrefix + '.trailerMismatchError'
	metadata.weight.customValidator = (val) =>
		!state.checkInForm.weight ||
		Number(val) === findExpectedCheckoutWeight(state)
			? null
			: tPrefix + '.weightMismatchError'
	metadata.driverName.customValidator = (val) =>
		!state.checkInForm.driverName || val === state.checkInForm.driverName
			? null
			: tPrefix + '.driverMismatchError'
	return metadata
}

export const DockSchedulerAppointmentDetailsCheckOut: FC = (props: {
	state: IStateDockSchedulerAppointmentDetails
	isActive: boolean
}) => {
	const { state, isActive } = props

	let metadata: IFormMetadataCollection<IAppointmentDetailsCheckOutForm> = l.cloneDeep(
		checkOutMetaData,
	)

	if (isActive) {
		metadata = setCustomValidators(metadata, state)
	}
	if (!isActive) {
		metadata.checkedLicense.readOnly = true
	}

	const checkOutFormData: IFormData<IAppointmentDetailsCheckOutForm> = {
		form: state.checkOutForm,
		metadata,
		tPrefix,
		onUpdateForm: (field, newVal) => {
			const changes = {}
			changes[field] = newVal
			sosDockSchedulerAppointmentDetails.updateCheckOut(changes)
		},
	}

	const disableCheckoutButton = false // always allow a user to checkout

	return (
		<DockSchedulerAppointmentDetailsCard
			cardType={'checkOut'}
			isActiveCard={isActive}
		>
			<FormStacked>
				{state.appointment.status !== 'loaded' &&
					state.appointment.status !== 'unloaded' && (
						<div>
							<FormStackedReadOnly
								formData={checkOutFormData}
								field={'checkOutDateTime'}
							/>
							<FormStackedReadOnly
								formData={checkOutFormData}
								field={'duration'}
								showWarning={showDurationWarning(state)}
							/>
						</div>
					)}
				<FormStackedTextInput
					formData={checkOutFormData}
					field={'tractorNumber'}
					readOnly={!isActive}
					hideOptional={true}
					expected={state.checkInForm.tractorNumber}
				/>
				<FormStackedTextInput
					formData={checkOutFormData}
					field={'trailerNumber'}
					readOnly={!isActive}
					hideOptional={true}
					expected={state.checkInForm.trailerNumber}
				/>
				<FormStackedTextInput
					formData={checkOutFormData}
					field={'weight'}
					readOnly={!isActive}
					hideOptional={true}
					expected={findExpectedCheckoutWeight(state)}
					testId={'departureWeight'}
				/>
				<FormStackedTextInput
					formData={checkOutFormData}
					field={'driverName'}
					readOnly={!isActive}
					hideOptional={true}
					expected={state.checkInForm.driverName}
				/>
				<FormStackedTextInput
					formData={checkOutFormData}
					field={'checkOutComments'}
					multiline={true}
					notResizable={true}
					readOnly={!isActive}
					hideOptional={true}
				/>
				<FormVerticalCheckbox
					formData={checkOutFormData}
					field='checkedLicense'
				/>
				{isActive && (
					<AlignRight>
						<Button
							color={'blue'}
							isDisabled={disableCheckoutButton}
							isSpinning={state.submitting}
							testId={'submitCheckout'}
							onClick={() =>
								fireAndForget(
									() =>
										sosDockSchedulerAppointmentDetails.submitCheckOut(
											checkOutFormData,
										),
									'submitting dock scheduler checkout from button click',
								)
							}
						>
							{t(
								state.appointment.status === 'loaded' ||
									state.appointment.status === 'unloaded'
									? 'submit'
									: 'save',
								tPrefix,
							)}
						</Button>
					</AlignRight>
				)}
			</FormStacked>
		</DockSchedulerAppointmentDetailsCard>
	)
}
