import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { apiTypes } from 'ui/api'
import { Card } from 'ui/components/common/card'
import { t } from 'ui/components/i18n/i18n'
import { _idx } from 'ui/lib'
import { tPrefixMakeOffer } from './OfferDetailsCard'

export const SpecialInstructionsCard: FC = (props: {
	shipment: apiTypes.ShipmentResponse
}) => {
	return (
		<Card
			title={t('specialInstructions', tPrefixMakeOffer)}
			color='darkBlue'
			fillHeight={true}
		>
			{_idx(() => props.shipment.specialInstructions) ||
				t('noSpecialInstructions', tPrefixMakeOffer)}
		</Card>
	)
}
