import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { Button } from 'ui/components/common/button/Button'
import { Modal } from 'ui/components/common/modal'
import { FormVertical } from 'ui/components/form/FormVertical'
import { FormVerticalReadOnly } from 'ui/components/form/FormVerticalReadOnly'
import { IFormData } from 'ui/components/form/IFormData'
import { AlignRight } from 'ui/components/layout/alignRight/AlignRight'
import { formPageConfig } from 'ui/forms'
import { sos2 } from 'ui/lib/state/sos2'
import { sosPageConfig } from 'ui/state/'

const PageConfigorizerForm: FC = (props: {
	state: sosPageConfig.IStatePageConfig
	onSubmit: () => void
	// onSubmitAndNext: () => void
	// onNext: () => void
}) => {
	const formData: IFormData<formPageConfig.IFormPageConfig> = {
		tPrefix: 'dev.pageConfig',
		form: props.state.formPageConfig,
		metadata: formPageConfig.pageConfigFormMetadata,
		onUpdateForm: sosPageConfig.updatePageConfigForm,
	}

	// const onEnter = () => {
	// 	props.onSubmit()
	// }

	return (
		<div>
			<FormVertical>
				<FormVerticalReadOnly formData={formData} field='configKey' />
				{/* <FormVerticalTextInput
					formData={formData}
					field='en'
					autofocus={true}
					onEnter={onEnter}
					multiline={true}
				/> */}
				{/* <FormVerticalReadOnly formData={formData} field='es' />
        <FormVerticalReadOnly formData={formData} field='es-MX' />
        <FormVerticalTextInput formData={formData} field='pirate' /> */}

				<AlignRight>
					{/* {props.stateTranslate.untranslatedStrings.length > 1 && (
						<Button color='green' onClick={props.onSubmitAndNext}>
							Ok and Next ({props.stateTranslate.untranslatedStrings.length})
						</Button>
					)} */}
					<Button color='gray' onClick={props.onSubmit}>
						Ok
					</Button>
					{/* {props.stateTranslate.untranslatedStrings.length > 1 && (
						<Button color='gray' onClick={props.onNext}>
							Next
						</Button>
					)} */}
				</AlignRight>
			</FormVertical>
		</div>
	)
}

export const PageConfigorizer: FC = (props: {}) => {
	const state = sos2.useSubscription(sosPageConfig.sos)

	return (
		<div>
			<Modal
				title='Page Config'
				isOpen={state.isConfigModalOpen}
				closeModalX={true}
				onModalClose={() => {
					sosPageConfig.closePageConfigModal()
				}}
				content={() => (
					<div>
						{state.requestSaving.error && (
							<div>
								{"Error: Couldn't save."}
								<pre>{'' + state.requestSaving.error}</pre>
							</div>
						)}
						this is the form
						<PageConfigorizerForm
							state={state}
							onSubmit={async () => {
								// if (await sosTranslate.saveTranslation()) {
								// 	await sosI18n.redownloadEnglish()
								// 	sosTranslate.moveToNextTranslation(false)
								// }
								sosPageConfig.closePageConfigModal()
							}}
							// onSubmitAndNext={async () => {
							// 	if (await sosTranslate.saveTranslation()) {
							// 		await sosI18n.redownloadEnglish()
							// 		sosTranslate.moveToNextTranslation(true)
							// 	}
							// }}
							// onNext={async () => {
							// 	sosTranslate.moveToNextTranslation(true)
							// }}
						/>
					</div>
				)}
			/>
		</div>
	)
}
