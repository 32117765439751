const _global = global as any
export const windowExists = typeof _global.window !== 'undefined'
export const documentExists = typeof _global.document !== 'undefined'
export const localStorageExists =
	windowExists && typeof _global.window.localStorage !== 'undefined'

export const getWindow = (): Window => {
	if (windowExists) {
		return _global.window as Window
	}
	throw new Error(
		'Cannot get window in this context, please check windowExists first',
	)
}

export const getDocument = (): Document => {
	if (documentExists) {
		return _global.document as Document
	}
	throw new Error(
		'Cannot get document in this context, please check documentExists first',
	)
}

export const getLocalStorage = (): Storage => {
	if (localStorageExists) {
		return _global.window.localStorage as Storage
	}
	throw new Error(
		'Cannot get localStorage in this context, please check localStorageExists first',
	)
}

export const getLocationHref = (): string => {
	if (windowExists) {
		return getWindow().location.href
	}
	return ''
}

export const reload = (): void => {
	if (windowExists) {
		getWindow().location.reload()
	}
}

export const windowOpen = (url: string, target?: '_blank'): void => {
	if (windowExists) {
		getWindow().open(url, target)
	}
}
