import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { t } from 'ui/components/i18n/i18n'
import { l } from 'ui/lib/lodashImports'
import * as commonClasses from 'ui/theme/common.module.scss'
import { addClassIf, getBackgroundWithTextColor } from 'ui/theme/theme'
import { ISelectOptions } from './ISelectOptions'
import * as classes from './select.module.scss'

export const ListSelect: FC = (props: {
	options: ISelectOptions[]
	value: any
	size: number
	onChange: (values: any) => void
	testId?: string
	tPrefix?: string
	readOnly?: boolean
}) => {
	let maxHeight
	if (props.size) {
		maxHeight = '' + props.size * 1.5 + 'em'
	}
	const minHeight = '1.5em'

	return (
		<div
			className={classes.select}
			data-testid={props.testId}
			style={{ maxHeight, minHeight }}
		>
			{l.map(props.options, (option, optionIdx) => {
				let className = classes.selectItem
				className = addClassIf(
					props.value === option.value,
					getBackgroundWithTextColor(props.readOnly ? 'gray' : 'blue'),
					className,
				)

				className = addClassIf(
					!props.readOnly,
					commonClasses.clickable,
					className,
				)

				const labelWithoutSpaces = l.camelCase(option.label)
				const testId =
					(props.testId || 'select') +
					'-' +
					(labelWithoutSpaces || option.value)
				return (
					<div
						key={optionIdx}
						onClick={() => !props.readOnly && props.onChange(option.value)}
						className={className}
						data-testid={testId}
						data-selected={props.value === option.value}
					>
						{option.label || t(option.value, props.tPrefix)}
					</div>
				)
			})}
		</div>
	)
}
