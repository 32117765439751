import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { IRequestState } from 'ui/api/requestState'
import { ElasticSearchPager } from 'ui/components/common/pager/Pager'
import { DataTable } from 'ui/components/table/DataTable'
import { IDataTableHeaderGroup } from 'ui/components/table/IDataTableHeaderGroup'
import { IDataTableState } from 'ui/components/table/IDataTableState'
import { IRfp } from 'ui/lib/books/IRfp'
import { ICarrier } from 'ui/lib/contracts/ICarrier'
import { l } from 'ui/lib/lodashImports'
import { sos2 } from 'ui/lib/state/sos2'
import { IRfpDataTableHeader } from 'ui/pages/rfp/IRfpDataTableHeader'
import { sosRfpAnalysisPage } from 'ui/state'
import { RfpContractSelector } from './RfpContractSelector'
import { RfpDataTableContractSelector } from './RfpDataTableContractSelector'

export const RfpAnalysisDataTable: FC = (props: {
	requestGetRfps: IRequestState<any>
	data: IRfp[]
	dataTableState: IDataTableState<IRfp>
	compareContractA: string
	compareContractB: string
	carrierList: ICarrier[]
	onExport?: () => void
}) => {
	const {
		requestGetRfps,
		dataTableState,
		compareContractA,
		compareContractB,
		carrierList,
		onExport,
	} = props

	const state = sos2.useSubscription(sosRfpAnalysisPage.getSos())

	// TODO dynamically populate headers based off response
	const bestHeaderGroup = 'page.rfpAnalysis.rfpTable.bestContract'
	const aHeaderGroup = 'page.rfpAnalysis.rfpTable.compareA'
	const bHeaderGroup = 'page.rfpAnalysis.rfpTable.compareB'

	const headers: IRfpDataTableHeader<IRfp>[] = [
		{ field: 'shipmentNumber', sort: false, headerAlign: 'center' },
		{
			field: 'pickupDate',
			formatType: 'date',
			sort: false,
			headerAlign: 'center',
		},
		{ field: 'carrier', sort: false, headerAlign: 'center' },
		{
			field: 'weight',
			columnAlign: 'right',
			sort: false,
			headerAlign: 'center',
		},
		{ field: 'class', sort: false, headerAlign: 'center' },
		{
			field: 'originZip',
			columnAlign: 'right',
			sort: false,
			headerAlign: 'center',
		},
		{
			field: 'destinationZip',
			columnAlign: 'right',
			sort: false,
			headerAlign: 'center',
		},
		{
			field: 'oldCharge',
			formatType: 'currencyFromCents',
			columnAlign: 'right',
			sort: false,
			headerAlign: 'center',
		},
		{
			field: 'carrierBest',
			headerGroup: bestHeaderGroup,
			sort: false,
			headerAlign: 'center',
		},
		{
			field: 'carrierBaseChargeBest',
			formatType: 'currencyFromCents',
			headerGroup: bestHeaderGroup,
			columnAlign: 'right',
			sort: false,
			headerAlign: 'center',
		},
		{
			field: 'fuelBest',
			formatType: 'currencyFromCents',
			headerGroup: bestHeaderGroup,
			columnAlign: 'right',
			sort: false,
			headerAlign: 'center',
		},
		{
			field: 'accessorialCostBest',
			formatType: 'currencyFromCents',
			headerGroup: bestHeaderGroup,
			columnAlign: 'right',
			sort: false,
			headerAlign: 'center',
		},
		{
			field: 'discountBest',
			formatType: 'currencyFromCents',
			headerGroup: bestHeaderGroup,
			columnAlign: 'right',
			sort: false,
			headerAlign: 'center',
		},
		{
			field: 'netChargeBest',
			formatType: 'currencyFromCents',
			headerGroup: bestHeaderGroup,
			columnAlign: 'right',
			sort: false,
			headerAlign: 'center',
		},
		{
			field: 'carrierBaseChargeA',
			formatType: 'currencyFromCents',
			headerGroup: aHeaderGroup,
			columnAlign: 'right',
			sort: false,
			headerAlign: 'center',
		},
		{
			field: 'fuelA',
			formatType: 'currencyFromCents',
			headerGroup: aHeaderGroup,
			columnAlign: 'right',
			sort: false,
			headerAlign: 'center',
		},
		{
			field: 'accessorialCostA',
			formatType: 'currencyFromCents',
			headerGroup: aHeaderGroup,
			columnAlign: 'right',
			sort: false,
			headerAlign: 'center',
		},
		{
			field: 'discountA',
			formatType: 'currencyFromCents',
			headerGroup: aHeaderGroup,
			columnAlign: 'right',
			sort: false,
			headerAlign: 'center',
		},
		{
			field: 'netChargeA',
			formatType: 'currencyFromCents',
			headerGroup: aHeaderGroup,
			columnAlign: 'right',
			sort: false,
			headerAlign: 'center',
		},
		{
			field: 'carrierBaseChargeB',
			formatType: 'currencyFromCents',
			headerGroup: bHeaderGroup,
			columnAlign: 'right',
			sort: false,
			headerAlign: 'center',
		},
		{
			field: 'fuelB',
			formatType: 'currencyFromCents',
			headerGroup: bHeaderGroup,
			columnAlign: 'right',
			sort: false,
			headerAlign: 'center',
		},
		{
			field: 'accessorialCostB',
			formatType: 'currencyFromCents',
			headerGroup: bHeaderGroup,
			columnAlign: 'right',
			sort: false,
			headerAlign: 'center',
		},
		{
			field: 'discountB',
			formatType: 'currencyFromCents',
			headerGroup: bHeaderGroup,
			columnAlign: 'right',
			sort: false,
			headerAlign: 'center',
		},
		{
			field: 'netChargeB',
			formatType: 'currencyFromCents',
			headerGroup: bHeaderGroup,
			columnAlign: 'right',
			sort: false,
			headerAlign: 'center',
		},
	]

	const contractList: string[] = []
	l.forEach(carrierList, (carrier) => {
		if (carrier.includedInAnalysis) {
			l.forEach(carrier.contracts, (contract) => {
				if (contract.includedInAnalysis) {
					contractList.push(contract.name)
				}
			})
		}
	})

	let shipmentColSpan = 8
	let bestColSpan = 6
	let compareAColSpan = 5
	let compareBColSpan = 5

	const shipmentColCount = 8
	const bestColCount = 6
	const compareACount = 5

	dataTableState.hiddenHeaders.forEach((hiddenHeader) => {
		const i = headers.indexOf(
			l.find(headers, (header) => header.field === hiddenHeader),
		)
		if (i < shipmentColCount) {
			shipmentColSpan--
		} else if (i < shipmentColCount + bestColCount) {
			bestColSpan--
		} else if (i < shipmentColCount + bestColCount + compareACount) {
			compareAColSpan--
		} else {
			compareBColSpan--
		}
	})

	// TODO add in possibility for any number of contract compares, figure out how we will decide that
	// TODO: change colspan when a header is toggled
	const headerGroups: IDataTableHeaderGroup<IRfp>[] = [
		{
			t: 'shipmentData',
			colSpan: shipmentColSpan,
		},
		{
			t: 'bestContract',
			colSpan: bestColSpan,
		},
		{
			t: 'compareA',
			colSpan: compareAColSpan,
			renderer: () => {
				// TODO Hook up options/value/onChange to actual returned data or API call
				return (
					<RfpDataTableContractSelector
						contractList={contractList}
						selectedContract={compareContractA}
						testId='compareASelector'
						onChange={(val: string) => {
							sosRfpAnalysisPage.rfpChangeCompareContractA(val)
						}}
					/>
				)
			},
		},
		{
			t: 'compareB',
			colSpan: compareBColSpan,
			renderer: () => {
				// TODO Hook up options/value/onChange to actual returned data or API call
				return (
					<RfpDataTableContractSelector
						contractList={contractList}
						selectedContract={compareContractB}
						testId='compareBSelector'
						onChange={(val: string) => {
							sosRfpAnalysisPage.rfpChangeCompareContractB(val)
						}}
					/>
				)
			},
		},
	]

	return (
		<div>
			<DataTable
				headers={headers}
				headerGroups={headerGroups}
				tPrefix='page.rfpAnalysis'
				isLoading={requestGetRfps.isFetching}
				data={props.data}
				state={dataTableState}
				onToggleHeader={(c) => {
					sosRfpAnalysisPage.rfpTableToggleHeader(c.field)
				}}
				onExport={onExport}
				exportTestId='export-to-csv'
				customToolbar={<RfpContractSelector carrierList={state.carrierList} />}
				spacerCell={false}
				testId='rfpAnalysis'
				title='manageShipmentData'
			/>
			<ElasticSearchPager
				pager={state.pager}
				onClickPage={sosRfpAnalysisPage.setCurrentPage}
			/>
		</div>
	)
}
