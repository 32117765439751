import {
	createDefaultAddressState,
	IAddressState,
} from 'ui/components/shared/address/IAddressState'
import { l } from 'ui/lib/lodashImports'
import { r } from 'ui/lib/ramdaImports'
import { IStateStop } from 'ui/pages/new-quote/state/sosNewQuote'
import { createSos2 } from 'ui/lib/state/sos2/sos2'

export interface IStateTestAddress {
	selectedTest: string
	addresses: IAddressState[]
	stops: IStateStop[]
}

export const sos = createSos2<IStateTestAddress>('sosTestAddress', 6, {
	selectedTest: {
		default: 'edit-address',
		localStorage: true,
	},
	addresses: {
		default: [createDefaultAddressState(), createDefaultAddressState()],
		localStorage: true,
	},
	stops: {
		default: [],
		localStorage: true,
	},
})

export function updateAddressState(
	changes: Partial<IAddressState>,
	id: string,
): void {
	sos.change((ds) => {
		const address = r.find((c) => c.id === id, ds.addresses)
		if (!address) {
			throw new Error(`invalid address id ${id}`)
		}
		l.assign(address, changes)
	})
}
