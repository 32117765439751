import { FC } from 'app/FunctionalComponent'
import React from 'react'
import styles from './ApiExamples.module.scss'
import { JsonSection } from './JsonSection'

export const JsonBlob: FC = (props: { json: any }) => {
	return (
		<div className={styles.jsonBlob}>
			{'{'}
			<JsonSection json={props.json} indent={1} />
			{'}'}
			{/* <pre>{JSON.stringify(props.json, null, 2)}</pre> */}
		</div>
	)
}
