import { FC } from 'app/FunctionalComponent'
import React from 'react'
import classes from './Tabs2.module.scss'

export const TabItem2: FC = (props: {
	isSelected?: boolean
	children: React.ReactNode
	onClick?: () => void
	dataTestId?: string
}) => {
	let className = classes.tabItem
	if (props.isSelected) {
		className += ' ' + classes.selected
	}

	return (
		<div
			className={className}
			onClick={props.onClick}
			data-testid={props.dataTestId}
		>
			{props.children}
		</div>
	)
}
