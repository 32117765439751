import { IAddress } from 'ui/components/shared/address/IAddress'

import { IPayload } from './IPayload'
import { IRate } from './IRate'
import { IStopDateTimeInformation } from './IStopDateTimeInformation'
import { apiTypes } from 'ui/api'

export interface IShipmentBillTo {
	address: IAddress
	locationId?: string
	billToThisParty?: 'shipper' | 'consignee' | 'third_party'
	clientRole?: 'shipper' | 'consignee' | 'third_party'
}

export interface IShipment {
	identifier?: string
	bookedRateIndex?: number
	flags?: string[]
	totalWeight?: number
	bookedRate?: IRate
	id?: string
	hashKey?: string
	rangeKey?: string
	createdDate?: Date
	userId?: string
	batchFulfillRecordId?: string
	locationId?: string
	companyId?: string
	shipmentStatus?:
		| 'booked'
		| 'void'
		| 'active'
		| 'preparing'
		| 'active_with_offers'
		| 'active_invites_sent'
		| 'active_invites_not_sent'
		| 'quoted'
		| 'no_longer_in_erp'
		| 'awarded'
	parcelDetailStatus?:
		| 'shipped'
		| 'inTransit'
		| 'exceptions'
		| 'disputed'
		| 'notDisputed'
		| 'recovered'
	shipmentSource?:
		| 'docMatching'
		| 'newQuote'
		| 'manualUpload'
		| 'shipmentBuilder'
		| 'upsTimeInTransitServiceApi'
		| 'upsQuantumViewApi'
		| 'fedexInsightSeleniumService'
		| 'fedexTimeInvoiceAuditServiceApi'
		| 'ediProcessing'
		| 'demoData'
		| 'erpSync'
		| 'vendorPortal'
		| 'quickRate'
		| 'eauditInvoiceParse'
		| 'tmsApi'
		| 'simplifiedFreightNewQuote'
	shippingMode?:
		| 'parcel'
		| 'freight'
		| 'modeless'
		| 'truckload'
		| 'ltl'
		| 'lcl'
		| 'ftl'
		| 'fr'
		| 'bb'
		| 'international'
		| 'simplifiedFreight'
	quotedDate?: Date
	bookedDate?: Date
	voidDate?: Date
	trackingDate?: Date
	specialInstructions?: string
	sealNumber?: string
	trailerNumber?: string
	equipmentType?: string
	shipmentIsExpedited?: boolean
	customerPickupRequested?: boolean
	proNumber?: string
	pickupNumber?: string
	brokerOrderIdNumber?: string
	invitesSentTime?: Date
	stopOrderArray?: IAddress[]
	uniqueShipmentNumber?: string
	appointmentId?: string
	useRequiredTrailerSpace?: boolean
	requiredTrailerSpace?: number
	bidDuration?: number
	accessorialsInfo?: any
	accessorials?: any
	reasonOfferAccepted?: string
	billTo?: IShipmentBillTo
	billReceiver?: boolean
	billAccountNumber?: string
	autoRate?: boolean
	autoRateCarrier?: string
	autoRateService?: string
	autoRateAccountNumber?: string
	processing?: boolean
	bookedRateId?: string
	// readonly bookedRate?: Rate
	mostRelevantCalculatedRateId?: string
	mostRelevantIncumbentRateId?: string
	exception?: {
		message?: string
		isDisputedAlready?: boolean
		type?: string
	}
	paymentStatus?: string
	//previousPaymentStatus?: PaymentStatus
	paymentStatusLastChangedTimestamp?: Date
	selected?: boolean
	//flags?: Flags
	invoiceAuditNotes?: string
	auditTrail?: string[]
	clientCustomization?: any
	users?: {
		createdById?: string
		pickedById?: string
		packedById?: string
		shippedById?: string
		bookedById?: string
		vendorUserId?: string
	}
	audit?: {
		guaranteedDeliveryDate?: string
		guaranteedDeliveryTime?: string
		auditStatus?: string
		isDisputable?: boolean
		isLate?: boolean
		disputeReason?: string
		disputeResponseHuman?: string
		disputeResponseCode?: string
		exceptionalEvents?: string[]
		guaranteedServiceRefundAmount?: number
		originalInvoiceAmount?: number
	}
	tracking?: {
		updatedTimestamp?: Date
		apiTimestamp?: Date
		clientCode?: string
		organizationCode?: string
		providerCode?: string
		shippingMode?: string
		shipmentFlow?: string
		trackingMode?: string
		trackingStatus?: string
		trackingEvent?: string
		mostRecentStatus?: string
		mostRecentEvent?: string
		mostRecentDate?: string
		mostRecentTime?: string
		mostRecentTimeZone?: string
		mostRecentCity?: string
		mostRecentState?: string
		mostRecentZip?: string
		mostRecentCountry?: string
		mostRecentLatitude?: string
		mostRecentLongitude?: string
		originCity?: string
		originState?: string
		originZip?: string
		originCountry?: string
		originUtcOffset?: number
		originUseDst?: boolean
		originDate?: string
		originTime?: string
		originTimestamp?: Date
		originDateIsActual?: boolean
		originCommittedDate?: string
		originCommittedTime?: string
		originCommittedTimestamp?: Date
		destinationCity?: string
		destinationState?: string
		destinationZip?: string
		destinationCountry?: string
		destinationUtcOffset?: number
		destinationUseDst?: boolean
		destinationDate?: string
		destinationTime?: string
		destinationTimestamp?: Date
		destinationDateIsActual?: boolean
		destinationCommittedDate?: string
		destinationCommittedTime?: string
		destinationCommittedTimestamp?: Date
	}
	testLabelPrinted?: boolean
	testPackingSlipPrinted?: boolean
	accountingFlowSettings?: {
		freight?: {
			useQuotedRateForAccounting?: boolean
			useCalculatedRateForAccounting?: boolean
		}
	}
	glCode?: string
	manifestId?: string
	reports?: {
		carrierEndOfDay?: {
			processedByCarrier?: boolean
			processedByCarrierTimeStamp?: boolean
			processedByCarrierReportId?: boolean
		}
	}
	palletSlipUrl?: string
	packingSlipUrls?: string[]
	inTransit?: boolean
	delivered?: boolean
	shippingError?: boolean
	shippingErrorType?: string
	shippingErrorOverSpend?: string
	offersReceived?: number
	offersAwaiting?: number
	trackingStatusDemo?: string
	trackingStatusCurrentLocation?: string
	trackingStatusScheduledDelivery?: string
	trackingStatusActualDelivery?: string
	trackingStatusInTransit?: string
	shipmentChoice?: string
	vendorNumber?: string
	vendorPortalShipment?: boolean
	erpShippingNotes?: string
	timestamps?: {
		bookAttempt?: {
			timestamp?: Date
		}
	}
	verificationBooleans?: {
		bookSuccessful?: boolean
		bookStarted?: boolean
		sentToPrintNode?: boolean
		printNodeProcessedSuccessful?: boolean
		erpExportSuccessful?: boolean
	}
	batchFulfill?: {
		batchFulfillRequested?: boolean
		batchFulfillInProcess?: boolean
		batchFulfillSuccess?: boolean
		batchFulfillFailed?: boolean
	}
	scheduleInformation?: {
		pickUp?: {
			time?: string
			date?: string
		}
	}
	requiresReview?: boolean
	notes?: {
		publicNotes?: string
		privateOperationsNotes?: string
	}
	needClientReview?: {
		accounting?: {
			reviewRequested?: boolean
			reviewedByClient?: boolean
			disputeRequested?: boolean
			reviewReason?:
				| 'reweigh'
				| 'reClass'
				| 'addedAccessorialCharge'
				| 'createdByClearviewFromInvoice'
				| 'needsGlCode'
				| 'other'
		}
	}
	dateToClearToPayWhenUsingAging?: Date
	organizationRelationshipId?: string
	organizationIdsWhichCanViewThis?: string[]
	// reviewFlags?: {
	//   overPriceThreshold?: ReviewFlags
	//   chargeTo?: ReviewFlags
	//   glCode?: ReviewFlags
	//   createdFromDocMatching?: ReviewFlags
	//   disputeAutoResolved?: ReviewFlags
	//   manualException?: ReviewFlags
	// }
	automaticallyQuote?: boolean
	isInternational?: boolean
	incoTerm?:
		| 'EXW'
		| 'FCA'
		| 'FAS'
		| 'FOB'
		| 'CFR'
		| 'CIF'
		| 'CPT'
		| 'CIP'
		| 'DAT'
		| 'DAP'
		| 'DDP'
	pickupReferenceNumber?: string
	transShippingPort?: string
	airportCode?: string
	forwarderName?: string
	shiplineName?: string
	importBrokerName?: string
	exportBrokerName?: string
	entryType?: string
	relatedParties?: boolean
	routedExport?: boolean
	insurance?: boolean
	license?: boolean
	coo?: boolean
	fta?: boolean
	materialCerts?: boolean
	inspectionCerts?: boolean
	taxCertificates?: boolean
	letterOfCredit?: boolean
	providerNotifiedAt?: Date
	providerNotificationRequested?: boolean
	dondePodCode?: string
	customFields?: any
	fullValueCoverage?: number
	pickup?: string
	orderNumber?: string
	payloads?: IPayload[]
	rates?: IRate[]
	//ratingErrors?: RatingError[]
	pickupDate?: string
	electronicallyRequestedPickup?: boolean
	origin?: any
	destination?: any
	readonly organizationRelationshipIds?: string[]
	readonly initialOrigin?: IAddress
	readonly initialOriginDateTime?: IStopDateTimeInformation
	readonly finalDestination?: IAddress
	readonly finalDestinationDateTime?: IStopDateTimeInformation
	readonly parcelContainers?: apiTypes.Container[]
	readonly ltlContainers?: apiTypes.Container[]
	readonly containers?: apiTypes.Container[]
	// readonly pickupAccessorials?: AccessorialsRequired
	// readonly deliveryAccessorials?: AccessorialsRequired
	// readonly payloadAccessorials?: AccessorialsRequired
	// readonly containerAccessorials?: AccessorialsRequired
	// readonly allAccessorials?: AccessorialsRequired
	readonly pickupInstructions?: string
	readonly deliveryInstructions?: string

	// toMongo()?: { [p?: string]?: any }

	// getMongoCalculated()?: Partial<MongoCalculated>

	// viewableByLocations()?: Promise<string[]>

	// userIsSecondParty(user?: User)?: Promise<boolean>

	// getTotals()?: Partial<ContainerTotals>

	// save()?: Promise<void>

	// delete()?: Promise<void>

	// getProviderBlacklist()?: Promise<{
	//   providerNames?: ProviderName[]
	//   serviceLevels?: ServiceLevel[]
	// }>

	// /**
	//  * Sets the shipments bookedRateId.
	//  * If a Provider name is passed in without a service it will select the lowest cost from that provider.
	//  * If a service is also passed in it will only select that particular service.
	//  * If neither are passed in the lowest cost rate is selected.
	//  * @param providerName
	//  * @param serviceLevel
	//  */
	// autoSelectBookedRate(
	//   providerName??: ProviderName,
	//   serviceLevel??: ServiceLevel,
	// )?: void

	// propagateIds()?: void

	// getContainers(freightLevel?: 'parcel' | 'freight' | 'intermodal')?: Container[]

	// isHazmat()?: boolean
}

export interface IShipmentResponse {
	entities: IShipment[]
	pageSize: number
	pageNumber: number
	total: number
	pageCount: number
}

export const createDefaultShipment = (): apiTypes.ShipmentResponse => {
	return {
		id: '',
		flags: [],
		payloads: [],
		locationId: '',
		origin: { street1: '', city: '', state: '', zip: '' },
		destination: { street1: '', city: '', state: '', zip: '' },
		shipmentSource: 'newQuote',
		shippedBy: '',
		bookedBy: '',
		updatedDate: null,
		billTo: {},
		mode: null,
		rates: [],
		stopSequence: [],
		trackingStatus: {},
		clientReferenceNumber: '',
		mostAccurateDate: '',
	}
}
