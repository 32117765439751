import { t, tString } from 'ui/components/i18n/i18n'
import * as classes from 'ui/pages/company-management/shipping-workflows/ShippingWorkflows.module.scss'
import { Checkbox } from 'ui/components/common/checkbox'
import React, { useEffect, useState } from 'react'
import { OkCancelButtons } from 'ui/components/common/okCancelButtons'
import { apiTmsServiceConfig, apiTypes } from 'ui/api'
import { fireAndForget } from 'ui/lib/async'
import { Loader } from 'ui/components/common/loader'
import { sosToast } from 'common/components/toast'
import { TitledBorderBox } from 'ui/components/common/titled-border-box'
import { Spacer } from 'ui/components/layout/spacer'

const tPrefix = 'page.companyManagement.shippingWorkflows'

export const ShippingWorkflows = (): JSX.Element => {
	const [config, setConfig] = useState<apiTypes.TmsServiceConfigResponse>({
		parcel: {
			allowRateShopping: false,
			automaticNewQuoteRerouteAfterBooking: false,
			automaticParcelBooking: false,
			printReturnLabelsLast: false,
		},
		freight: {
			allowRateShopping: false,
			automaticFreightBooking: false,
		},
	})
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [isSaving, setIsSaving] = useState<boolean>(false)

	useEffect(() => {
		fireAndForget(async () => {
			setIsLoading(true)

			const configResult = await apiTmsServiceConfig.getTmsServiceConfig()

			setConfig(Object.assign({}, config, configResult.data))

			setIsLoading(false)
		}, 'fetching tms service config')
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	const save = async (): Promise<void> => {
		setIsSaving(true)

		const result = await apiTmsServiceConfig.updateTmsServiceConfig(
			Object.assign({}, config, {
				providerCodeConfig: config.providerCodeConfig || {},
			}),
		)

		if (result.error) {
			sosToast.sendApiErrorResponseToast(result.error)
		}

		setIsSaving(false)
	}

	return (
		<div>
			<Loader isLoading={isLoading} />
			{!isLoading && (
				<React.Fragment>
					<Spacer />

					{/* <FlexRow wrap={false}> */}
					<div className={classes.titledBorderBoxesWrapper}>
						<TitledBorderBox
							title={t('parcel', tPrefix)}
							testId={'shipping-workflows-parcel'}
							className={classes.titledBorderBox}
						>
							<Spacer />
							<div className={classes.checkboxRow}>
								<Checkbox
									value={config?.parcel?.allowRateShopping || false}
									onChange={async (allowRateShopping: boolean) => {
										const configClone = { ...config }
										configClone.parcel.allowRateShopping = allowRateShopping
										setConfig(configClone)
									}}
									testId={'shipping-workflows-parcel-enable-get-rates'}
								>
									{t(`enableGetRates`, tPrefix)}
								</Checkbox>

								<Checkbox
									value={config?.parcel?.automaticParcelBooking || false}
									onChange={async (automaticParcelBooking: boolean) => {
										const configClone = { ...config }
										configClone.parcel.automaticParcelBooking = automaticParcelBooking
										setConfig(configClone)
									}}
									testId={'shipping-workflows-parcel-enable-book-with'}
								>
									{t(`enableBookWith`, tPrefix)}
								</Checkbox>

								<Checkbox
									value={
										config?.parcel?.automaticNewQuoteRerouteAfterBooking ||
										false
									}
									onChange={async (
										automaticNewQuoteRerouteAfterBooking: boolean,
									) => {
										const configClone = { ...config }
										configClone.parcel.automaticNewQuoteRerouteAfterBooking = automaticNewQuoteRerouteAfterBooking
										setConfig(configClone)
									}}
									testId={
										'shipping-workflows-parcel-enable-start-next-shipment'
									}
								>
									{t(`enableStartNextShipment`, tPrefix)}
								</Checkbox>

								<Checkbox
									value={config?.parcel?.printReturnLabelsLast || false}
									onChange={async (printReturnLabelsLast: boolean) => {
										const configClone = { ...config }
										configClone.parcel.printReturnLabelsLast = printReturnLabelsLast
										setConfig(configClone)
									}}
									testId={
										'shipping-workflows-parcel-print-return-label-after-shipping-label'
									}
								>
									{t(`printLabels`, tPrefix)}
								</Checkbox>
							</div>
						</TitledBorderBox>

						<TitledBorderBox
							title={t('freight', tPrefix)}
							testId={'shipping-workflows-freight'}
							className={classes.titledBorderBox}
						>
							<Spacer />
							<div className={classes.checkboxRow}>
								<Checkbox
									value={config?.freight?.allowRateShopping || false}
									onChange={async (allowRateShopping: boolean) => {
										const configClone = { ...config }
										configClone.freight.allowRateShopping = allowRateShopping
										setConfig(configClone)
									}}
									testId={'shipping-workflows-freight-enable-get-rates'}
								>
									{t(`enableGetRatesFreight`, tPrefix)}
								</Checkbox>

								<Checkbox
									value={config?.freight?.automaticFreightBooking || false}
									onChange={async (automaticFreightBooking: boolean) => {
										const configClone = { ...config }
										configClone.freight.automaticFreightBooking = automaticFreightBooking
										setConfig(configClone)
									}}
									testId={'shipping-workflows-freight-enable-book-with'}
								>
									{t(`enableBookWithFreight`, tPrefix)}
								</Checkbox>
							</div>
						</TitledBorderBox>
						{/* </FlexRow> */}
					</div>

					<Spacer />

					<div className={'bootstrap-wrapper'}>
						<div className='d-flex flex-row-reverse bd-highlight'>
							<OkCancelButtons
								ok={tString('save', tPrefix)}
								onOk={save}
								okColor={'green'}
								isValid={true}
								errors={[]}
								isSpinning={isSaving}
								okTestId={'shipping-workflows-save'}
							/>
						</div>
					</div>
				</React.Fragment>
			)}
		</div>
	)
}
