import { apiAddressBook } from 'ui/api/'
import { UpdateMode } from 'ui/api/apiCommon'
import { IRequestState } from 'ui/api/requestState'
import { ISelectOptions } from 'ui/components/common/select'
import { dataUtils } from 'ui/components/table/'
import { IDataTableState } from 'ui/components/table/IDataTableState'
import { getRegionsForCountry } from 'ui/data/countryAndRegionUtils'
import { formUtils } from 'ui/forms'
import {
	addressBookEntryFormMetadata,
	IAddressBookEntryForm,
} from 'ui/forms/formAddressBookEntry'
import { IAddressBook } from 'ui/lib/books/IAddressBook'
import { IAddressBookEntry } from 'ui/lib/books/IAddressBookEntry'
import { l } from 'ui/lib/lodashImports'
import { router } from './router'
import { createSos } from './secretaryOfState'

export interface IStateAddressBook {
	bookId: string
	country: string
	regions: string[] | ISelectOptions[]
	requestAddressBook: IRequestState<IAddressBook>
	requestAddressBookEntries: IRequestState<IAddressBookEntry[]>
	requestUpdateAddressBookPage: IRequestState<IAddressBookEntry>
	dataTableAddressBookPages: IDataTableState<IAddressBookEntry>
	dataAddressBookPages: IAddressBookEntry[]
	addressBookPageForm: IAddressBookEntryForm
}

const initialState: IStateAddressBook = {
	bookId: '',
	country: '',
	regions: [],
	requestAddressBook: {},
	requestAddressBookEntries: {},
	requestUpdateAddressBookPage: {},
	dataTableAddressBookPages: {},
	dataAddressBookPages: [],
	addressBookPageForm: formUtils.createDefaultFormStringData(
		addressBookEntryFormMetadata,
	),
}

const { stateManager, useSubscribe } = createSos(
	'address-book',
	'1.0.1',
	initialState,
	{
		useLocalStorage: true,
		localStorageFields: [
			'requestAddressBookEntries',
			'dataTableAddressBookPages',
		],
	},
)
export { useSubscribe }

export async function fetchAddressBookEntries(): Promise<void> {
	// let state = stateManager.getState()
	// await apiAddressBook.getAddressBookEntries(state.bookId, (rs) => {
	// 	stateManager.produce((ds) => {
	// 		ds.requestAddressBookEntries = rs
	// 		ds.dataTableAddressBookPages.data = rs.data
	// 	})
	// })
}

export async function fetchBook(): Promise<void> {
	const state = stateManager.getState()
	await apiAddressBook.getAddressBook(state.bookId, (rs) => {
		stateManager.produce((ds) => {
			ds.requestAddressBook = rs
		})
	})
}

export function addressBookTableSetUpdateMode(
	mode: UpdateMode,
	row: IAddressBookEntry = null,
): void {
	stateManager.produce((ds: IStateAddressBook) => {
		dataUtils.toggleUpdateMode(ds.dataTableAddressBookPages, mode, row)
		if (ds.dataTableAddressBookPages.isAdding) {
			ds.addressBookPageForm = formUtils.createDefaultFormStringData(
				addressBookEntryFormMetadata,
			)
		} else if (ds.dataTableAddressBookPages.editRow) {
			ds.addressBookPageForm = formUtils.apiDataToFormStringData(
				addressBookEntryFormMetadata,
				row,
			)
		}
	})
}

export function updateAddressBookPageForm(
	key: keyof IAddressBookEntryForm,
	newVal: string,
): void {
	stateManager.produce((ds: IStateAddressBook) => {
		ds.addressBookPageForm[key] = newVal
	})
}

export async function updateAddressBookEntry(mode: UpdateMode): Promise<void> {
	const state = stateManager.getState()

	const apiData = formUtils.formStringDataToApiData(
		addressBookEntryFormMetadata,
		state.dataTableAddressBookPages.editRow,
		state.addressBookPageForm,
	)

	const result = await apiAddressBook.updateAddressBookEntry(
		mode,
		state.bookId,
		apiData,
		(rs) => {
			stateManager.produce((ds: IStateAddressBook) => {
				ds.requestUpdateAddressBookPage = rs
			})
		},
	)

	// if (result.data) {
	// 	stateManager.produce((ds: IStateAddressBook) => {
	// 		dataUtils.updateItem(
	// 			ds.dataTableAddressBookPages,
	// 			mode,
	// 			result.data,
	// 			ds.addressBookPageForm.id,
	// 		)
	// 	})
	// 	addressBookTableSetUpdateMode('none')
	// }
	if (result.error) {
		stateManager.produce((ds: IStateAddressBook) => {
			dataUtils.setError(ds.dataTableAddressBookPages, result.error)
		})
	}
}

export function sort(sortOn: string): void {
	stateManager.produce((ds) => {
		dataUtils.sort(
			ds.dataTableAddressBookPages,
			ds.dataAddressBookPages,
			sortOn,
		)
	})
}
export function toggleHeader(field: string): void {
	stateManager.produce((ds) => {
		dataUtils.toggleHeader(ds.dataTableAddressBookPages, field)
	})
}

export function navigateToAddressBook(params: { bookId?: string }): void {
	stateManager.produce((ds) => {
		l.defaults(params, {
			bookId: ds.bookId,
		})
		ds.bookId = params.bookId
		dataUtils.toggleUpdateMode(ds.dataTableAddressBookPages, 'none', null)
	})
	router.navTo(`/address-book/${params.bookId}`)
}

export function setCountry(countryName: string): void {
	stateManager.produce((ds) => {
		if (countryName) {
			ds.country = countryName
		}
	})
}

export function setRegions(countryName: string): void {
	const regions = getRegionsForCountry(countryName, false, true)
	stateManager.produce((ds) => {
		ds.regions = regions
	})
}
