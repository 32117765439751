import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { AngryRedBox } from 'ui/components/common/angry-red-box/AngryRedBox'
import { Button } from 'ui/components/common/button/Button'
// import Sortable from 'sortablejs'
import { t } from 'ui/components/i18n/i18n'
import { Spacer } from 'ui/components/layout/spacer/Spacer'
import { l } from 'ui/lib/lodashImports'
import { sos2 } from 'ui/lib/state/sos2'
import * as classes from 'ui/pages/load-optimizer/LoadOptimizer.module.scss'
import * as sosLoadOptimizer from 'ui/pages/load-optimizer/state/sosLoadOptimizer'
import { sosShipmentProfileBroker } from 'ui/pages/shipment-profile/broker'
import * as commonClasses from 'ui/theme/common.module.scss'
import { addClass, getBorderColor } from 'ui/theme/theme'
import { tPrefixLoadOptimizer } from '../payloads-table/LoadOptimizerPayloadsTable'
import { ConsolidatedShipment } from '../state/sosLoadOptimizer'
import { ConsolidatedPayloadCard } from './ConsolidatedPayloadCard'

export const ConsolidatedShipmentCard: FC = (props: {
	shipment: ConsolidatedShipment
	testId?: string
}) => {
	const state = sos2.useSubscription(sosLoadOptimizer.getSos())

	// let consolidatedPayloadCardClassName = addClass(
	// 	classes.consolidatedPayloadCard,
	// 	getBackgroundWithTextColor('lightGray'),
	// )
	// consolidatedPayloadCardClassName = addClass(
	// 	getBorderColor('lightGray'),
	// 	consolidatedPayloadCardClassName,
	// )

	let totalWeight = 0
	let totalCount = 0
	l.forEach(props.shipment?.payloads, (payload) => {
		l.forEach(payload.containers, (container) => {
			totalWeight += container.packedWeight || 0
		})
		totalCount += payload.containers?.length || 0
	})

	// let payloadList
	// let el = document.getElementById('payloadsList-' + props.shipment.id)
	// if (el) {
	// 	payloadList = Sortable.create(el, {
	// 		animation: 0,
	// 		sort: false,
	// 		group: 'shipmentPayloadListsGroup',
	// 		draggable: '.list-group-item',
	// 		handle: '.list-group-item',
	// 		filter: '.sortable-disabled',
	// 		chosenClass: 'active',
	// 		removeCloneOnHide: false,
	// 		onStart: (event) => {
	// 			sosLoadOptimizer.movePayloadFromShipment(event)
	// 		},
	// 		onEnd: (event) => {
	// 			sosLoadOptimizer.movePayloadToShipment(event)
	// 		},
	// 	})
	// }

	return (
		<div className='col-md-3' data-testid={props.testId}>
			<div
				className={addClass(
					getBorderColor('lightGray'),
					classes.consolidatedShipmentCard,
				)}
			>
				<div className={commonClasses.boldText}>
					{t('shipmentInformation', tPrefixLoadOptimizer)}:
				</div>
				<Spacer />
				<div>
					{t('totalWeight', tPrefixLoadOptimizer)}: {totalWeight}{' '}
					{t('lbs', tPrefixLoadOptimizer)}
				</div>
				<div>
					{t('totalCount', tPrefixLoadOptimizer)}: {totalCount}
				</div>
				<Spacer />
				{props.shipment.saved && (
					<div>
						<Button
							color='blue'
							bootstrapStyles={true}
							isSmall={true}
							onClick={() =>
								sosShipmentProfileBroker.navigateToShipmentProfile({
									shipmentId: props.shipment.id,
								})
							}
							testId='viewShipmentProfile'
						>
							{t('viewShipmentProfile', tPrefixLoadOptimizer)}
						</Button>
						<Spacer />
					</div>
				)}
				{props.shipment.error && (
					<div>
						<AngryRedBox>{props.shipment.error}</AngryRedBox>
						<Spacer />
					</div>
				)}
				<div
					id={'payloadsList-' + props.shipment.id}
					className='list-group'
					data-testid='payloadsList'
				>
					{l.map(props.shipment?.payloads, (payload, payloadIdx) => {
						return (
							<ConsolidatedPayloadCard
								payload={payload}
								key={payload.id}
								shipmentId={props.shipment?.id}
								popupShowing={state.payloadWithPopupShowing === payload.id}
								testId={'consolidatedPayloadCard-' + payloadIdx}
							/>
						)
					})}
				</div>
			</div>
		</div>
	)
}
