import React, { ReactNode } from 'react'
import { IShipmentListMappedData } from 'ui/state'
import { l } from 'ui/lib/lodashImports'
import { IDataTableHeader } from 'ui/components/table'
import { t, tCurrency, tString } from 'ui/components/i18n/i18n'
import {
	dateRenderer,
	stringArrayRenderer,
	providerRendererImage,
	shippingModeRenderer,
	dateExportFormatter,
	idArrayExportFormatter,
	shippingModeExportFormatter,
	shipmentStatusFormatter,
	providerExportFormatter,
	currencyExportFormatter,
	percentExportFormatter,
} from 'ui/pages/shipments/ShipmentsListHeaderRenderers'
import { checkEsTableHeaders } from 'ui/lib/elasticSearch/checkEsTableHeaders'
import { IconButton, solidIcons } from 'ui/components/common/icon'
import { tTimeRange } from 'ui/components/i18n/commonTranslations'
import { apiTypes } from 'ui/api'

const selectorHeader: IDataTableHeader<IShipmentListMappedData> = {
	field: 'id',
	hideLabel: true,
	width: '2em',
	untoggleable: true,
	renderer: (): ReactNode => (
		<IconButton
			icon={solidIcons.faPlusCircle}
			data-testid={'assign-shipment-button'}
		/>
	),
}

const identifierHeader: IDataTableHeader<IShipmentListMappedData> = {
	field: 'identifier',
	width: '120px',
	monospace: true,
	sort: false,
	elasticSearchKey: 'identifier',
	titleRenderer: (c, row) => '' + row.id,
}

const tmsShipmentStatusHeader: IDataTableHeader<IShipmentListMappedData> = {
	field: 'shipmentStatus',
	width: '60px',
	renderer: (value) => <div key={value}>{t(value, 'page.shipmentsList')}</div>,
	exportFormatter: shipmentStatusFormatter,
	sort: false,
	elasticSearchKey: 'shipmentStatus',
}

const commonHeaders: IDataTableHeader<IShipmentListMappedData>[] = [
	{
		field: 'trackingStatuses',
		width: '80px',
		sort: false,
		elasticSearchKey: 'payloads.trackingStatus',
		useKeyword: true,
		renderer: (c) => (
			<>
				{l.map(c, (d) => (
					<div key={d}>{t(d, 'page.shipmentsList')}</div>
				))}
			</>
		),
		exportFormatter: idArrayExportFormatter,
	},
	{
		field: 'pickTicketNumbers',
		width: '60px',
		sort: true,
		elasticSearchKey: 'payloads.pickTicketNumber',
		useKeyword: true,
		exportFormatter: idArrayExportFormatter,
	},
	{
		field: 'updatedDate',
		width: '100px',
		columnAlign: 'right',
		monospace: true,
		renderer: dateRenderer,
		exportFormatter: dateExportFormatter,
		sort: true,
		filterSelector: 'date',
		elasticSearchKey: 'updatedDate',
		absoluteSearch: true,
	},
	{
		field: 'quotedDate',
		width: '100px',
		columnAlign: 'right',
		monospace: true,
		renderer: dateRenderer,
		exportFormatter: dateExportFormatter,
		sort: true,
		filterSelector: 'date',
		elasticSearchKey: 'quotedDate',
		absoluteSearch: true,
	},
	{
		field: 'bookedDate',
		width: '100px',
		columnAlign: 'right',
		monospace: true,
		renderer: dateRenderer,
		exportFormatter: dateExportFormatter,
		sort: true,
		filterSelector: 'date',
		elasticSearchKey: 'bookedDate',
		absoluteSearch: true,
	},
	{
		field: 'createdDate',
		width: '100px',
		columnAlign: 'right',
		monospace: true,
		renderer: dateRenderer,
		exportFormatter: dateExportFormatter,
		sort: true,
		filterSelector: 'date',
		elasticSearchKey: 'createdDate',
		defaultSortDirection: 'descending',
		absoluteSearch: true,
	},
	{
		field: 'pickupDate',
		width: '100px',
		columnAlign: 'right',
		monospace: true,
		renderer: dateRenderer,
		exportFormatter: dateExportFormatter,
		sort: true,
		filterSelector: 'date',
		elasticSearchKey: 'pickupTimeRange.initialDate',
		absoluteSearch: true,
	},
	{
		field: 'pickupTime',
		width: '100px',
		renderer: (timeRange: apiTypes.DateTimeInfo) =>
			tTimeRange(timeRange, 'pickup'),
		columnAlign: 'left',
		sort: false,
	},
	{
		field: 'deliveryDate',
		width: '100px',
		columnAlign: 'right',
		monospace: true,
		renderer: dateRenderer,
		exportFormatter: dateExportFormatter,
		sort: true,
		filterSelector: 'date',
		elasticSearchKey: 'deliveryTimeRange.initialDate',
		absoluteSearch: true,
	},
	{
		field: 'estimatedDeliveryDate',
		width: '100px',
		columnAlign: 'left',
		monospace: true,
		renderer: dateRenderer,
		exportFormatter: dateExportFormatter,
		useKeyword: true,
		absoluteSearch: true,
	},
	{
		field: 'deliveryTime',
		width: '100px',
		renderer: (timeRange: apiTypes.DateTimeInfo) =>
			tTimeRange(timeRange, 'delivery'),
		columnAlign: 'left',
		sort: false,
	},
	{
		field: 'pickupNumber',
		width: '60px',
		sort: false,
		elasticSearchKey: 'pickupNumber',
	},
	{
		field: 'mode',
		width: '100px',
		renderer: shippingModeRenderer,
		exportFormatter: shippingModeExportFormatter,
		sort: false,
		elasticSearchKey: 'bookedRate.method',
	},
	{
		field: 'originCompany',
		width: '100px',
		sort: true,
		useKeyword: true,
		elasticSearchKey: 'origin.company',
	},
	{
		field: 'originState',
		width: '150px',
		columnAlign: 'right',
		sort: false,
		elasticSearchKey: 'origin.state',
	},
	{
		field: 'originCityState',
		width: '150px',
		columnAlign: 'right',
		sort: false,
		elasticSearchKey: 'origin.city,origin.state',
	},
	{
		field: 'originZip',
		width: '60px',
		columnAlign: 'right',
		sort: false,
		elasticSearchKey: 'origin.zip',
	},
	{
		field: 'originCountryCode',
		width: '60px',
		sort: false,
		elasticSearchKey: 'origin.country',
	},
	{
		field: 'destinationCompany',
		width: '100px',
		sort: true,
		useKeyword: true,
		elasticSearchKey: 'destination.company',
	},
	{
		field: 'destinationCityState',
		width: '150px',
		columnAlign: 'right',
		sort: false, // you can not sort on wildcards
		elasticSearchKey: 'destination.city,destination.state',
	},
	{
		field: 'destinationZip',
		width: '60px',
		columnAlign: 'right',
		sort: false,
		elasticSearchKey: 'destination.zip',
	},
	{
		field: 'destinationCountryCode',
		width: '60px',
		sort: false,
		elasticSearchKey: 'destination.country',
	},
	{
		field: 'flow',
		width: '60px',
		sort: false,
		renderer: (c) => <div>{t(c, 'page.shipmentsList')}</div>,
		exportFormatter: (c) => tString(c, 'page.shipmentsList'),
	},
	{
		field: 'billToRole',
		width: '60px',
		sort: false,
		elasticSearchKey: 'billToRole',
	},
]

const commonHeaders2: IDataTableHeader<IShipmentListMappedData>[] = [
	{
		field: 'salesOrders',
		width: '100px',
		sort: false,
		elasticSearchKey: 'payloads.salesOrders',
		useKeyword: true,
		renderer: stringArrayRenderer,
		exportFormatter: idArrayExportFormatter,
	},
	{
		field: 'purchaseOrders',
		width: '100px',
		sort: false,
		elasticSearchKey: 'payloads.purchaseOrders',
		useKeyword: true,
		renderer: stringArrayRenderer,
		exportFormatter: idArrayExportFormatter,
	},
	{
		field: 'bolIdentifiers',
		width: '100px',
		sort: false,
		elasticSearchKey: 'payloads.bolIdentifier',
		useKeyword: true,
		renderer: stringArrayRenderer,
		exportFormatter: idArrayExportFormatter,
	},
	{
		field: 'proNumber',
		width: '100px',
		sort: false,
		elasticSearchKey: 'proNumber',
	},
	{
		field: 'loadNumbers',
		width: '60px',
		sort: false,
		elasticSearchKey: 'payloads.loadNumber',
		useKeyword: true,
		exportFormatter: idArrayExportFormatter,
	},
	{
		field: 'trackingNumbers',
		width: '60px',
		sort: false,
		elasticSearchKey: 'payloads.trackingNumber', // might also be 'payloads.masterTrackingNumber'
		useKeyword: true,
		renderer: stringArrayRenderer,
		exportFormatter: idArrayExportFormatter,
	},

	// not needed for broker
	{
		field: 'vendorIdentifiers',
		width: '50px',
		sort: true,
		elasticSearchKey: 'payloads.vendorIdentifier',
		useKeyword: true,
		renderer: stringArrayRenderer,
		exportFormatter: idArrayExportFormatter,
	},
	{
		field: 'providerName',
		width: '50px',
		columnAlign: 'center',
		sort: true,
		elasticSearchKey: 'bookedRate.carrier',
		useKeyword: true,
		renderer: providerRendererImage,
		exportFormatter: providerExportFormatter,
	},
	{
		field: 'scac',
		width: '60px',
		sort: true,
		useKeyword: true,
		elasticSearchKey: 'bookedRate.scac',
	},
	{
		field: 'handlingUnits',
		columnAlign: 'right',
		width: '90px',
		sort: false,
		filterSelector: 'number',
		absoluteSearch: true, // non-wildcard search
		elasticSearchKey: 'ltlCount', // can also be parcelCount
	},
	{
		field: 'totalWeight',
		width: '50px',
		columnAlign: 'right',
		sort: true,
		filterSelector: 'number',
		absoluteSearch: true, // non-wildcard search
		elasticSearchKey: 'totalWeight',
	},
	{
		field: 'quotedCost',
		width: '70px',
		columnAlign: 'right',
		sort: true,
		filterSelector: 'currency',
		elasticSearchKey: 'bookedRate.costTotal',
		absoluteSearch: true, // non-wildcard search
		renderer: (c) => tCurrency(c / 100, 'USD', 2),
		exportFormatter: currencyExportFormatter,
		// TODO: cost formatter
	},
	{
		field: 'currency',
		width: '60px',
		sort: false,
		elasticSearchKey: 'bookedRate.currencyCode',
	},
	{
		field: 'shippedBy',
		width: '60px',
		sort: false,
		elasticSearchKey: 'shippedBy',
	},
	{
		field: 'bookedBy',
		width: '60px',
		sort: false,
		elasticSearchKey: 'bookedBy',
	},
	{
		field: 'parcelReferenceOne',
		width: '60px',
		sort: false,
		elasticSearchKey: 'payloads.containers.labelReferenceValue1',
		useKeyword: true,
		renderer: stringArrayRenderer,
		exportFormatter: idArrayExportFormatter,
	},
	{
		field: 'parcelReferenceTwo',
		width: '60px',
		sort: false,
		elasticSearchKey: 'payloads.containers.labelReferenceValue2',
		useKeyword: true,
		renderer: stringArrayRenderer,
		exportFormatter: idArrayExportFormatter,
	},
]

export const tmsHeaders: IDataTableHeader<IShipmentListMappedData>[] = [
	identifierHeader,
	tmsShipmentStatusHeader,
	...commonHeaders,
	...commonHeaders2,
]

checkEsTableHeaders(tmsHeaders)

const brokerReferenceNumberHeader: IDataTableHeader<IShipmentListMappedData> = {
	field: 'brokerReferenceNumber',
	width: '150px',
	sort: false,
	elasticSearchKey: 'brokerReferenceNumber',
}

const endingBrokerHeaders: IDataTableHeader<IShipmentListMappedData>[] = [
	{
		field: 'customerInvoicingStatus',
		width: '60px',
		sort: true,
		renderer: (c) => <div>{t(c, 'page.shipmentsList')}</div>,
		elasticSearchKey: 'customerInvoicingStatus',
	},
	{
		field: 'brokerSellPrice',
		width: '70px',
		columnAlign: 'right',
		sort: false,
		renderer: (c) => tCurrency(c / 100, 'USD', 2),
		exportFormatter: currencyExportFormatter,
	},
	{
		field: 'brokerBuyPrice',
		width: '70px',
		columnAlign: 'right',
		sort: false,
		renderer: (c) => tCurrency(c / 100, 'USD', 2),
		exportFormatter: currencyExportFormatter,
	},
	{
		field: 'brokerMarkupPercent',
		width: '70px',
		columnAlign: 'right',
		sort: false,
		renderer: (c) => c + '%',
		exportFormatter: percentExportFormatter,
	},
	{
		field: 'brokerProfit',
		width: '70px',
		columnAlign: 'right',
		sort: false,
		renderer: (c) => tCurrency(c / 100, 'USD', 2),
		exportFormatter: currencyExportFormatter,
	},
]
const startingBrokerHeaders: IDataTableHeader<IShipmentListMappedData>[] = [
	{
		field: 'clientName',
		width: '120px',
		sort: false,
	},
	{
		field: 'shipmentStatus',
		width: '60px',
		renderer: (value) => (
			<div key={value}>{t(value, 'page.shipmentsList')}</div>
		),
		exportFormatter: shipmentStatusFormatter,
		sort: false,
		elasticSearchKey: 'brokerShipmentStatus',
	},
]

export const brokerHeaders: IDataTableHeader<IShipmentListMappedData>[] = [
	selectorHeader,
	identifierHeader,
	...startingBrokerHeaders,
	...commonHeaders,
	brokerReferenceNumberHeader,
	...commonHeaders2,
	...endingBrokerHeaders,
]
checkEsTableHeaders(brokerHeaders)

export const defaultTmsHeaderFields = [
	'identifier',
	'shipmentStatus',
	'updatedDate',
	'quotedDate',
	'pickupDate',
	'mode',
	'flow',
	'salesOrders',
	'purchaseOrders',
	'bolIdentifiers',
	'proNumber',
	'originCityState',
	'originCountryCode',
	'originZip',
	'destinationCityState',
	'destinationCountryCode',
	'destinationZip',
	'vendorIdentifiers',
	'providerName',
	'handlingUnits',
	'totalWeight',
	'quotedCost',
]

export const defaultBrokerHeaderFields = [
	'trackingStatuses',
	'clientName',
	'identifier',
	'shipmentStatus',
	'updatedDate',
	'quotedDate',
	'pickupDate',
	'mode',
	'flow',
	'salesOrders',
	'purchaseOrders',
	'bolIdentifiers',
	'proNumber',
	'originCityState',
	'originCountryCode',
	'originZip',
	'destinationCityState',
	'destinationCountryCode',
	'destinationZip',
	'vendorIdentifiers',
	'providerName',
	'handlingUnits',
	'totalWeight',
	'brokerSellPrice',
	'brokerBuyPrice',
	'brokerMarkupPercent',
	'brokerProfit',
	'brokerReferenceNumber',
	'pickupTime',
	'deliveryTime',
]

export const defaultTmsHiddenHeaders = l.difference(
	l.map(tmsHeaders, (c) => c.field),
	defaultTmsHeaderFields,
)

export const defaultBrokerHiddenHeaders = l.difference(
	l.map(brokerHeaders, (c) => c.field),
	defaultBrokerHeaderFields,
)

/*
	Searches the shipment list headers for any default sorts and formats the correct sort object for ElasticSearch
 */
export const defaultSortSyntaxer = (
	headers: IDataTableHeader<IShipmentListMappedData>[],
): string => {
	return l.join(
		l.map(
			l.filter(headers, (c) =>
				l.includes(['ascending', 'descending'], c.defaultSortDirection),
			),
			(d) =>
				`${d.elasticSearchKey}${d.useKeyword ? '.keyword' : ''}:${
					d.defaultSortDirection === 'ascending' ? 'asc' : 'desc'
				}`,
		),
		',',
	)
}

export const defaultTmsShipmentListSorts = defaultSortSyntaxer(tmsHeaders)

export const defaultBrokerShipmentListSorts = defaultSortSyntaxer(brokerHeaders)
