import { NotFound } from 'app/AppRouter'
import { FC } from 'app/FunctionalComponent'
import React from 'react'
import {
	IRoute,
	LoginModeType,
	SimpleRouter,
} from 'ui/components/common/router'
import { Customers } from './customers'
import { InvoiceProfile } from './invoice-profile'
import { Invoices } from './invoices'
import { ProviderPortalSplashPage } from './ProviderPortalSplashPage'
import { ProviderPortalSignup } from './sign-up'

export const providerPortalRoutes: IRoute[] = [
	{
		isDefault: true,
		urls: ['/404'],
		renderer: () => <NotFound />,
		anonymous: true,
	},
	{
		urls: ['/provider-portal/signup'],
		renderer: () => <ProviderPortalSignup />,
		anonymous: true,
	},
	{
		urls: ['/provider-portal/invoices'],
		renderer: () => <Invoices />,
	},
	{
		urls: ['/provider-portal/invoice-profile'],
		renderer: () => <InvoiceProfile />,
	},
	{
		urls: ['/provider-portal/customers'],
		renderer: () => <Customers />,
	},
	{
		urls: ['/provider-portal/'],
		renderer: () => <ProviderPortalSplashPage />,
	},
]

export const RouterProviderPortal: FC = (props: {
	currentLocation: string
	anonymous: boolean
	loginMode: LoginModeType
}) => {
	return (
		<SimpleRouter
			routes={providerPortalRoutes}
			currentLocation={props.currentLocation}
			loginMode={props.loginMode}
		/>
	)
}
