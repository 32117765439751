// import { apiCommon, apiTypes } from './'
import { apiCommon } from '.'
import { IRequestState } from './requestState'

export type RelationshipType = 'clientConfig' | 'organizationRelationship'

export interface CreditWarningRequest {
	percentage: number
	enabled: boolean
	email: string[]
}

export interface ClientCreditResponse extends ClientCreditRequest {
	inTransitTotal: number
	unpaidInvoiceTotal: number
}

export interface ClientCreditRequest {
	relationshipId: string
	relationshipType: RelationshipType
	allowToExceedCreditLimit: boolean
	creditWarning: CreditWarningRequest
	creditLimit: number
	creditLimitEnabled?: boolean
}

export async function getClientCredit(
	onProgress: (rs: IRequestState<ClientCreditResponse>) => void,
	relationshipId: string,
): Promise<IRequestState<ClientCreditResponse>> {
	const url = `clientCredit/${relationshipId}`
	const response = await apiCommon.apiFetch(
		onProgress,
		{
			method: 'GET',
		},
		url,
	)

	return response
}

export async function createClientCredit(
	onProgress: (rs: IRequestState<ClientCreditResponse>) => void,
	data: ClientCreditRequest,
): Promise<IRequestState<ClientCreditResponse>> {
	const url = `clientCredit/`
	const response = await apiCommon.apiFetch(
		onProgress,
		{
			method: 'POST',
			data,
		},
		url,
	)

	return response
}

export async function updateClientCredit(
	onProgress: (rs: IRequestState<ClientCreditResponse>) => void,
	relationshipId: string,
	data: ClientCreditRequest,
): Promise<IRequestState<ClientCreditResponse>> {
	const url = `clientCredit/${relationshipId}`
	const response = await apiCommon.apiFetch(
		onProgress,
		{
			method: 'PUT',
			data,
		},
		url,
	)

	return response
}
