import { TypeaheadOption } from 'common/components/typeahead/TypeaheadOption'
import { apiTypes } from 'ui/api'
import { l } from 'ui/lib/lodashImports'

export const filterFormatAndSortConfigsOptions = (
	clientConfigs: apiTypes.ClientConfigResponse[],
): TypeaheadOption[] => {
	return l.sortBy(
		l.map(
			l.filter(clientConfigs, (c) => !l.isNil(c.tmsCompanyName)),
			(c) => ({
				value: c.id,
				label: c.tmsCompanyName,
			}),
		),
		(c) => c.label,
	)
}
