import { FC } from 'app/FunctionalComponent'
import { sosToast, ToastState } from 'common/components/toast'
import { DateTime } from 'luxon'
import nanoid from 'nanoid'
import React, { ReactElement, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { apiBroker, apiTypes } from 'ui/api'
import {
	DocumentImageFormat,
	PayloadResponse,
	ShippingDocumentResponse,
	ShippingDocumentTypes,
} from 'ui/api/apiTypes'
import { IRequestState } from 'ui/api/requestState'
import { Modal } from 'ui/components/common/modal'
import { OkCancelButtons } from 'ui/components/common/okCancelButtons'
import {
	FormStackedLayout,
	FormStackedSelect,
	IFormData,
} from 'ui/components/form'
import { t, tString } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight'
import { FileDropZone } from 'ui/components/shared/csvValidator/file-drop-zone'
import { asyncMapParallel } from 'ui/lib/async/asyncMapParallel'
import { documentTypes } from 'ui/lib/documents/documentTypes'
import { dataUrlToBase64 } from 'ui/lib/files'
import { sosShipmentProfileBroker } from 'ui/pages/shipment-profile/broker'
import { DocumentList } from 'ui/pages/shipment-profile/broker/components/document/ShippingDocumentsList'
import './DocumentUploadModal.scss'

const tPrefix = 'page.shipmentProfile.documentUpload'
const translate = (key: string): ReactElement => t(key, tPrefix)

export interface FileUpload {
	filename: string
	id: string
	dataUrlContent: string
	format: DocumentImageFormat
}

interface UploadForm {
	payloadId: string
	type: ShippingDocumentTypes
}

export const DocumentUploadModal: FC = (props: {
	show: boolean
	shipmentId: string
	onHide: (uploaded: boolean) => void
	payloads: PayloadResponse[]
}): ReactElement => {
	const { onHide, payloads, shipmentId } = props
	const [uploads, updateUploads] = useState<FileUpload[]>([])
	const [isOpen, updateIsOpen] = useState<boolean>(false)
	const [isLoading, updateIsLoading] = useState<boolean>(false)
	const [form, updateForm] = useState<UploadForm>({
		type: 'Bill of Lading',
		payloadId: props.payloads[0].id,
	})

	useEffect(() => {
		updateIsOpen(props.show)
	}, [props.show])

	const formData: IFormData<UploadForm> = {
		form: form,
		metadata: {
			payloadId: { readOnly: props.payloads.length === 1, required: true },
			type: { required: true },
		},
		onUpdateForm: (key: string, value: string) => {
			updateForm(Object.assign({}, form, { [key]: value }))
		},
		tPrefix,
	}

	const filteredPayloads: string[] = payloads
		.filter((payload) => payload.id)
		.map((payload) => payload.id)

	const uploadDocuments = async (
		documents: FileUpload[],
		shipmentId,
		payloadId,
		type,
	): Promise<void> => {
		updateIsLoading(true)
		const fileUploads = await asyncMapParallel(
			documents,
			async (
				file: FileUpload,
			): Promise<IRequestState<ShippingDocumentResponse>> => {
				const shippingDocumentRequest: apiTypes.BrokerShippingDocumentRequest = {
					payloadId,
					shipmentId,
					type,
					image: dataUrlToBase64(file.dataUrlContent),
					format: file.format,
					filename: file.filename,
					uploadDate: DateTime.local().toISO(),
				}
				return await apiBroker.uploadShippingDocument(() => {},
				shippingDocumentRequest)
			},
		)

		let toastState: ToastState = {
			type: 'success',
			header: tString('uploadSuccess', tPrefix),
		}

		if (fileUploads.filter((upload) => upload.error).length) {
			toastState = {
				type: 'danger',
				header: tString('errorUploadingFiles', tPrefix),
			}
		} else {
			await sosShipmentProfileBroker.fetchShipment(shipmentId)
			onHide(true)
		}
		sosToast.sendToast(toastState)
		updateIsLoading(false)
	}

	return (
		<Modal
			title={translate('modalTitle')}
			isOpen={isOpen}
			closeModalX={true}
			onModalClose={() => onHide(false)}
			content={() => (
				<div className='bootstrap-wrapper'>
					<Row>
						<Col>
							<FormStackedLayout>
								<FormStackedSelect
									formData={formData}
									field='type'
									testId={'modal-document-type'}
									options={documentTypes}
								/>
								<FormStackedSelect
									formData={formData}
									field='payloadId'
									testId={'modal-payload-id'}
									options={filteredPayloads}
								/>
							</FormStackedLayout>
						</Col>
						<Col>
							<FileDropZone
								onLoaded={(dataUrlContent, filename): void => {
									updateUploads(
										uploads.concat({
											dataUrlContent,
											filename,
											id: nanoid(),
											format: 'PDF',
										}),
									)
								}}
								onError={(): void => {
									sosToast.sendToast({
										type: 'danger',
										body: translate('invalidFile'),
									})
								}}
								fileEncoding='dataUrl'
								supportedFileTypes={['PDF']}
								preDropText={t('preDropFile', tPrefix)}
								droppingText={t('droppingFile', tPrefix)}
								testId='shipping-document-file-drop'
							/>
						</Col>
					</Row>
					<div className='mt-3 mb-3'>
						<DocumentList
							documents={[]}
							canDelete={true}
							onRemove={(file) => {
								updateUploads(uploads.filter((item) => !item.id.match(file.id)))
							}}
						/>
					</div>
					<AlignRight>
						<OkCancelButtons
							okColor='blue'
							onOk={async () => {
								await uploadDocuments(
									uploads,
									shipmentId,
									form.payloadId,
									form.type,
								)
							}}
							isSpinning={isLoading}
							onCancel={() => onHide(false)}
							ok={tString('upload', tPrefix)}
							isValid={form.payloadId && form.type && uploads.length >= 1}
							cancelTestId={'cancel-add-connection-button'}
							okTestId={'submit-add-connection-button'}
						/>
					</AlignRight>
				</div>
			)}
		/>
	)
}
