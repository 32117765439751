import { apiTypes } from 'ui/api'
import { l } from '../lodashImports'

export function getUserManagedLocationId(user: apiTypes.UserResponse): string {
	if (
		!user.managedCompany ||
		user.managedCompany.companyName === user.company.companyName
	) {
		return user.locationId
	} else {
		return l.last(user.profile.settings.canViewDataForTheseLocations)
	}
}
