import { apiTypes } from 'ui/api'
import { RateRow } from 'ui/components/rates-table/RatesTable'
import { sortRateRows } from 'ui/pages/shipment-profile/shared/functions/sortRateRows'
import { filterRatesForRatesTable } from 'ui/pages/shipment-profile/shared/functions/filterRatesForRatesTable'
import { convertBrokerRateResponseToRateRow } from './convertBrokerRateResponseToRateRow'

export const processBrokerRateRows = (
	shipment: apiTypes.BrokerShipmentResponse,
	defaultMarkupLogic: apiTypes.MarkupLogic,
): RateRow[] => {
	let rateRows: RateRow[]

	const filteredRates = filterRatesForRatesTable(shipment.rates)

	rateRows = filteredRates.map(
		(rate): RateRow => {
			return convertBrokerRateResponseToRateRow(
				rate,
				shipment,
				defaultMarkupLogic,
			)
		},
	)
	rateRows = sortRateRows(rateRows)
	return rateRows
}
