import { FC } from 'app/FunctionalComponent'
import React, { useState } from 'react'
import { LoginModeType, sosRouter2 } from 'ui/components/common/router'
import { t } from 'ui/components/i18n/i18n'
import { l } from 'ui/lib/lodashImports'
import { sos2 } from 'ui/lib/state/sos2'
import { sosCustomerManagement } from 'ui/pages/customer-management'
import { NotifyCustomerModal } from 'ui/pages/customer-management/broker/notify-customer-modal'
import * as commonClasses from 'ui/theme/common.module.scss'
import { addClass } from 'ui/theme/theme'
import { filterLinks, ILink, tSidebarPrefix } from './Sidebar'
import styles from './Sidebar.module.scss'
import { SidebarSubItem } from './SidebarSubItem'

export const SidebarItem: FC = (props: {
	pathname: string
	isSelected: boolean
	selectedSubMenuPathname?: string
	subLinks?: ILink[]
	children: React.ReactNode
	loginMode: LoginModeType
	testId?: string
}) => {
	let className = styles.sidebarItem
	className = addClass(commonClasses.clickable, className)
	if (props.isSelected) {
		className += ' ' + styles.selectedItem
	}

	const filteredLinks = filterLinks(props.loginMode, props.subLinks)

	const state = sos2.useSubscription(sosCustomerManagement.sos)

	const [isNotifyCustomerModalOpen, setIsNotifyCustomerModalOpen] = useState<
		boolean
	>(false)

	return (
		<React.Fragment>
			<div
				data-testid={props.testId}
				className={className}
				onClick={() => {
					if (state.isPageModified) {
						setIsNotifyCustomerModalOpen(true)
					} else {
						sosRouter2.navigateTo(
							{ url: props.pathname, params: {}, queries: {} },
							{},
						)
					}
				}}
			>
				{props.children}

				{props.isSelected && (
					<div>
						{l.map(l.filter(filteredLinks), (d, dIdx) => (
							<React.Fragment key={dIdx}>
								<SidebarSubItem
									pathname={d.pathname}
									isSelected={d.pathname === props.selectedSubMenuPathname}
								>
									{t(d.name, tSidebarPrefix)}
								</SidebarSubItem>
							</React.Fragment>
						))}
					</div>
				)}
			</div>

			<NotifyCustomerModal
				isModalOpen={isNotifyCustomerModalOpen}
				onModalClose={() => {
					setIsNotifyCustomerModalOpen(false)
				}}
				onDontSave={() => {
					sosRouter2.navigateTo(
						{ url: props.pathname, params: {}, queries: {} },
						{},
					)
				}}
			/>
		</React.Fragment>
	)
}
