import { FC } from 'app/FunctionalComponent'
import React, { useEffect, useState } from 'react'
import { useClientRect } from 'ui/components/hooks'
import { t } from 'ui/components/i18n/i18n'
import { fireAndForget } from 'ui/lib/async/fireAndForget'
import { l } from 'ui/lib/lodashImports'
import { theme } from 'ui/theme/'
import commonStyles from 'ui/theme/common.module.scss'
import { addClassIf } from 'ui/theme/theme'
import { Icon, solidIcons } from '../icon'
import { Input } from '../input'
import { Popup } from '../popup'
import { ISelectOptions } from '../select/ISelectOptions'
import classes from './Autocomplete.module.scss'
import {
	allFilter,
	defaultFilter,
	getSelectedOptionFromVal,
	IAutocompleteOptions,
	processOptions,
} from './autocompleteUtils'

const defaultProps = {
	filter: defaultFilter,
	maxOptionsToShow: 8,
	type: 'autocomplete',
}

export const Autocomplete: FC = (props: {
	type?: 'autocomplete' | 'select'
	value: string
	options: string[] | ISelectOptions[]
	onChange: (newValue: string) => void
	placeholder?: string
	name?: string
	tPrefix?: string
	testId?: string
	isClearable?: boolean
	readOnly?: boolean
	width?: string
	filter?: (
		options: ISelectOptions[],
		val: string,
	) => Promise<IAutocompleteOptions[]>
	maxOptionsToShow?: number
	allowEmptyValue?: boolean
	numListOptionsBeforeScroll?: number
	className?: string
}) => {
	const {
		value,
		maxOptionsToShow,
		testId,
		allowEmptyValue,
		numListOptionsBeforeScroll,
	} = props

	const refInput = React.createRef<any>()

	const [processedOptions, setProcessedOptions] = useState(
		processOptions(props.options, props.tPrefix),
	)
	const [internalText, setInternalText] = useState(
		getSelectedOptionFromVal(value, processedOptions, allowEmptyValue).label ||
			'',
	)
	const [showSuggestions, setShowSuggestions] = useState(false)
	const [capturedShowSuggestions, setCapturedShowSuggestions] = useState(false)
	const [suggestions, setSuggestions] = useState([] as IAutocompleteOptions[])
	const [selectedIdx, setSelectedIdx] = useState(0)

	const [rect, ref] = useClientRect()

	const [isFocused, setIsFocused] = useState(false)
	let className = classes.container
	className = addClassIf(isFocused, classes.focused, className)
	className = addClassIf(!l.isNil(props.className), props.className, className)

	useEffect(() => {
		setInternalText(
			getSelectedOptionFromVal(value, processedOptions, allowEmptyValue)
				.label || '',
		)
	}, [value, processedOptions, allowEmptyValue])

	useEffect(() => {
		setProcessedOptions(processOptions(props.options, props.tPrefix))
	}, [props.options, props.tPrefix])

	const filterSetSuggestions = async (
		val: string,
		textChanged = false,
	): Promise<void> => {
		if (props.type === 'autocomplete') {
			if (!val) {
				setSuggestions([])
			} else {
				const rawSuggestions = await props.filter(processedOptions, val)
				const topSuggestions = l.take(
					l.orderBy(
						rawSuggestions,
						['quality', 'option.label'],
						['desc', 'asc'],
					),
					maxOptionsToShow,
				)
				setSuggestions(topSuggestions)
			}
		}
		if (props.type === 'select') {
			if (!textChanged) {
				const rawSuggestions = allFilter(processedOptions)
				setSuggestions(rawSuggestions)
			} else {
				const rawSuggestions = await props.filter(processedOptions, val)
				const topSuggestions = l.take(
					l.orderBy(
						rawSuggestions,
						['quality', 'option.label'],
						['desc', 'asc'],
					),
					maxOptionsToShow,
				)
				setSuggestions(topSuggestions)
			}
		}
	}

	return (
		<Popup
			width={props.width}
			isOpen={showSuggestions}
			content={
				<div
					data-testid='suggestions-list'
					className={classes.suggestionList}
					style={{
						minWidth: rect && rect.width - 13,
						height:
							numListOptionsBeforeScroll &&
							numListOptionsBeforeScroll * 21 + 'px',
					}}
				>
					{suggestions.length > 0 &&
						l.map(suggestions, (c, idx) => {
							const { option } = c

							let suggestionClassName = classes.suggestion
							suggestionClassName += ' ' + commonStyles.clickable
							suggestionClassName += ' ' + theme.getHighlightColor('blue')

							if (idx === selectedIdx) {
								suggestionClassName += ' ' + classes.selectedSuggestion
							}

							return (
								<div
									data-testid={'suggestions-list-' + l.kebabCase(option.label)}
									key={option.value}
									className={suggestionClassName}
									onClick={() => {
										setInternalText(option.label)
										props.onChange(option.value)
										setShowSuggestions(false)
									}}
									onMouseEnter={() => {
										setSelectedIdx(idx)
									}}
								>
									{option.label}
								</div>
							)
						})}
					{suggestions.length === 0 && (
						<div>{t('component.autocomplete.noSuggestions')}</div>
					)}
				</div>
			}
		>
			<div className={className} ref={ref} style={{ width: props.width }}>
				<Input
					readOnly={props.readOnly}
					ref={refInput}
					borderless={true}
					autocomplete='off'
					testId={testId}
					value={internalText}
					placeholder={props.placeholder}
					onFocus={
						props.readOnly
							? null
							: async (onFocusRef) => {
									setIsFocused(true)
									if (onFocusRef) {
										// Select all of the text to replace it
										onFocusRef.setSelectionRange(0, onFocusRef.value.length)
									}
									// refInput.current.setSelectionRange(0, internalText.length)
									if (props.type === 'autocomplete') {
										if (internalText) {
											await filterSetSuggestions(internalText)
											setSelectedIdx(0)
										}
										setShowSuggestions(internalText !== '')
									}
									if (props.type === 'select') {
										await filterSetSuggestions(internalText)
										setSelectedIdx(-1)
										setShowSuggestions(true)
									}
							  }
					}
					onChangeImmediate={(newVal) => {
						setInternalText(newVal)

						fireAndForget(async () => {
							await filterSetSuggestions(newVal, true)
						}, 'filterSetSuggestions')

						setShowSuggestions(true)
						setSelectedIdx(0)
					}}
					onBlur={
						props.readOnly
							? null
							: () => {
									setIsFocused(false)
									if (selectedIdx !== -1) {
										if (internalText && internalText !== value) {
											const suggestion: ISelectOptions = suggestions[
												selectedIdx
											]
												? suggestions[selectedIdx].option
												: { label: '', value: '' }
											setInternalText(suggestion.label)
											props.onChange(suggestion.value)
										} else {
											if (props.type === 'autocomplete') {
												setInternalText('')
												props.onChange('')
											}
										}
									}
									setShowSuggestions(false)
							  }
					}
					onKeyDown={(
						event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
					) => {
						switch (event.keyCode) {
							// Up arrow
							case 38:
								event.preventDefault()
								setSelectedIdx(Math.max(0, selectedIdx - 1))
								break
							// Down arrow
							case 40:
								event.preventDefault()
								setSelectedIdx(
									Math.min(suggestions.length - 1, selectedIdx + 1),
								)
								break
							// Enter
							case 13:
								event.preventDefault()
								if (suggestions.length > 0) {
									setInternalText(suggestions[selectedIdx].option.label)
									props.onChange(suggestions[selectedIdx].option.value)
									setShowSuggestions(false)
									refInput.current.blur()
								}

								break
						}
					}}
				/>
				{props.isClearable && !props.readOnly && (
					<div
						onClick={() => {
							setInternalText('')
							props.onChange('')
						}}
						className={classes.icon}
					>
						<Icon icon={solidIcons.faTimes} />
					</div>
				)}
				{props.type === 'select' && !props.readOnly && (
					<div
						onMouseDown={() => {
							setCapturedShowSuggestions(showSuggestions)
						}}
						onMouseUp={() => {
							if (!capturedShowSuggestions) {
								refInput.current.focus()
							}
						}}
						className={classes.icon}
					>
						<Icon icon={solidIcons.faAngleDown} />
					</div>
				)}
			</div>
		</Popup>
	)
}
Autocomplete.defaultProps = defaultProps
