import { apiCommon, apiTypes } from './'
import { IRequestState } from './requestState'

export async function fetchPayloads(
	onProgress: (rs: IRequestState<apiTypes.PayloadListResponse>) => void,
	take = 25,
	skip = 0,
	excludeAttached = false,
	query?: string,
	fields?: string[],
	wildcards?: string[],
): Promise<IRequestState<apiTypes.PayloadListResponse>> {
	return await apiCommon.apiFetch(
		onProgress,
		{ entireResponse: true },
		'payloads',
		{
			take,
			skip,
			excludeAttached,
			query,
			fields: fields?.join(','),
			wildcards: wildcards?.join(','),
		},
	)
}

export async function consolidatePayloads(
	onProgress: (rs: IRequestState<apiTypes.ConsolidatePayloadsResponse>) => void,
	data: apiTypes.ConsolidatePayloadsRequest,
): Promise<IRequestState<apiTypes.ConsolidatePayloadsResponse>> {
	return await apiCommon.apiFetch(
		onProgress,
		{ method: 'POST', entireResponse: true, data },
		'payloads/consolidate',
	)
}

export async function fetchRules(
	onProgress: (
		rs: IRequestState<apiTypes.ConsolidationRuleListResponse>,
	) => void,
): Promise<IRequestState<apiTypes.ConsolidationRuleListResponse>> {
	return await apiCommon.apiFetch(
		onProgress,
		{ entireResponse: true },
		'payloads/consolidation-rules',
	)
}

export async function createRule(
	onProgress: (rs: IRequestState<apiTypes.ConsolidationRuleResponse>) => void,
	data: apiTypes.ConsolidationRuleRequest,
): Promise<IRequestState<apiTypes.ConsolidationRuleResponse>> {
	return await apiCommon.apiFetch(
		onProgress,
		{ data, method: 'POST' },
		'payloads/consolidation-rules',
	)
}

export async function deleteRule(
	onProgress: (rs: IRequestState<{}>) => void,
	ruleId: string,
): Promise<IRequestState<{}>> {
	return await apiCommon.apiFetch(
		onProgress,
		{ method: 'DELETE' },
		'payloads/consolidation-rules/' + ruleId,
	)
}

export async function updateRule(
	onProgress: (rs: IRequestState<apiTypes.ConsolidationRuleResponse>) => void,
	ruleId: string,
	data: apiTypes.ConsolidationRuleRequest,
): Promise<IRequestState<apiTypes.ConsolidationRuleResponse>> {
	return await apiCommon.apiFetch(
		onProgress,
		{ data, method: 'PUT' },
		`payloads/consolidation-rules/${ruleId}`,
	)
}
