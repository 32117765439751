import { Address } from 'ui/api/apiTypes'
import { StopType } from 'ui/components/shared/address'
import { l } from 'ui/lib/lodashImports'
import { allAccessorialsArray } from '../accessorialsData'
import {
	StopAccessorialsType,
	StopAccessorialsInfoType,
	IAccessorialsAndInfo,
} from '../accessorialsTypes'
import {
	getAccessorialKeyPath,
	getChildrenAccessorials,
	limitedAccessAccessorials,
} from 'ui/pages/new-quote/accessorials'

export function tryToGetLimitedAccessAccessorial(
	addressType: Address['addressType'],
	stopMode: StopType,
): StopAccessorialsType {
	// For backward compatibility we need to add two limited accessorials if the stop is limited access
	const limitedAccessAccessorial = l.find(
		limitedAccessAccessorials,
		(c) => c.accessorialKey === addressType,
	)
	let limitedAccess: StopAccessorialsType = null
	if (limitedAccessAccessorial) {
		if (stopMode === 'pickup') {
			limitedAccess = {
				limitedAccessPickup: true,
				[limitedAccessAccessorial.pickupKey]: true,
			}
		}
		if (stopMode === 'delivery') {
			limitedAccess = {
				limitedAccessDelivery: true,
				[limitedAccessAccessorial.deliveryKey]: true,
			}
		}
	}
	return limitedAccess
}

export function splitAccessorials(
	combinedAccessorials: StopAccessorialsType & StopAccessorialsInfoType,
	stopType: StopType,
): IAccessorialsAndInfo {
	const accessorialsResult: StopAccessorialsType = {}
	const accessorialsInfoResult: StopAccessorialsInfoType = {}

	l.forEach(
		l.map(allAccessorialsArray, (c) => getAccessorialKeyPath(c, stopType)),
		(key) => {
			// Parent accessorials
			const accessorialValue = combinedAccessorials[key]
			if (accessorialValue) {
				if (accessorialsResult) {
					accessorialsResult[key] = accessorialValue
				}
			}
		},
	)

	l.forEach(
		l.map(getChildrenAccessorials(), (c) => c.accessorialKey),
		(key) => {
			// Child accessorials
			const childAccessorial = combinedAccessorials[key]
			if (childAccessorial) {
				accessorialsInfoResult[key] = childAccessorial
			}
		},
	)

	return {
		accessorials: accessorialsResult,
		accessorialsInfo: accessorialsInfoResult,
	}
}
