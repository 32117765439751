import { l } from '../lodashImports'
import { toInteger } from '../numbers/toNumber'

const postalCodeSizeConstraints: {
	[country: string]: { length: number; maximumAllowedLeadingZeros: number }
} = {
	BD: { length: 4, maximumAllowedLeadingZeros: 0 }, // Bangladesh
	CN: { length: 6, maximumAllowedLeadingZeros: 1 }, // China
	DE: { length: 5, maximumAllowedLeadingZeros: 1 }, // Germany
	IN: { length: 6, maximumAllowedLeadingZeros: 0 }, // India
	MX: { length: 5, maximumAllowedLeadingZeros: 1 }, // Mexico
}

const toZipCodeFixedLength = (
	inputCode: string | number,
	length: number,
	maximumAllowedLeadingZeros: number,
): string => {
	const zipCode: any = inputCode ? toInteger(inputCode).toString() : ''
	if (
		zipCode.length < length - maximumAllowedLeadingZeros ||
		zipCode.length > length
	) {
		return null
	} else {
		return l.padStart(zipCode, length, '0')
	}
}

const toZipCodeCa = (inputCode: string | number): string => {
	// Canadian zip code format is 'A1A 1A1'
	const caZipCodeFormat = /[A-Z][0-9][A-Z][0-9][A-Z][0-9]/
	let zipCode: any = inputCode ? inputCode.toString() : ''
	zipCode = zipCode
		.replace(/-/g, '')
		.replace(/\s/g, '')
		.toUpperCase()
	if (zipCode.length !== 6 || !zipCode.match(caZipCodeFormat)) {
		return null
	}
	return zipCode
}

const toZipCodeGl = (inputCode: string | number): string => {
	// all Greenlandic zip codes are four digits and have the format 39xx
	const zipCode: number = toInteger(inputCode)

	if (zipCode < 3900 || zipCode >= 4000) {
		return null
	} else {
		return l.padStart(zipCode.toString(), 4, '0')
	}
}

const toZipCodeRu = (inputCode: string | number): string => {
	const zipCode = toZipCodeFixedLength(inputCode, 6, 0)
	if (!zipCode) {
		return null
	}

	if (
		['1', '2', '3', '4', '6'].includes(zipCode[0]) ||
		zipCode.slice(0, 3) === '555'
	) {
		// Russia has five zip code regions (6 is the fifth) and uses 555xxx for international mail
		return zipCode
	} else {
		return null
	}
}

const toZipCodeUs = (inputCode: string | number): string => {
	let zipCode: string = inputCode ? inputCode.toString() : ''
	zipCode = zipCode.replace(/-/g, '').replace(/\s/g, '')
	if (zipCode.length < 3 || zipCode.length === 6) {
		return null
	} else if (zipCode.length < 6) {
		zipCode = l.padStart(zipCode, 5, '0')
	} else {
		zipCode = l.padStart(zipCode, 9, '0')
		const splitZipCode: string[] = zipCode.split('')
		splitZipCode.splice(5, 0, '-')
		zipCode = splitZipCode.join('')
	}

	return zipCode
}

export const toPostalCode = (
	inputCode: string | number,
	country = 'US',
): string => {
	if (!country) {
		country = 'US'
	}

	const simpleSizeConstraint = postalCodeSizeConstraints[country]

	if (simpleSizeConstraint) {
		// the postal codes for this country of length `simpleSizeConstraint.length`
		// and can have a maximum of `simpleSizeConstraint.maximumAllowedLeadingZeros` leading zeros
		return toZipCodeFixedLength(
			inputCode,
			simpleSizeConstraint.length,
			simpleSizeConstraint.maximumAllowedLeadingZeros,
		)
	} else if (country === 'CA') {
		return toZipCodeCa(inputCode)
	} else if (country === 'GL') {
		return toZipCodeGl(inputCode)
	} else if (country === 'RU') {
		return toZipCodeRu(inputCode)
	} else if (country === 'US') {
		return toZipCodeUs(inputCode)
	} else {
		// TODO validate other zip codes
		return inputCode.toString()
	}
}
