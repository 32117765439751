export type FreightClassType =
	| '50'
	| '55'
	| '60'
	| '65'
	| '70'
	| '77.5'
	| '85'
	| '92.5'
	| '100'
	| '110'
	| '125'
	| '150'
	| '175'
	| '200'
	| '250'
	| '300'
	| '400'
	| '500'
export const freightClasses: FreightClassType[] = [
	'50',
	'55',
	'60',
	'65',
	'70',
	'77.5',
	'85',
	'92.5',
	'100',
	'110',
	'125',
	'150',
	'175',
	'200',
	'250',
	'300',
	'400',
	'500',
]
