import { apiBroker, apiTypes } from 'ui/api'
import { RateRow } from 'ui/components/rates-table/RatesTable'
import { l } from 'ui/lib/lodashImports'
import { sortRateRows } from 'ui/pages/shipment-profile/shared/functions/sortRateRows'
import { sosUser } from 'ui/state'
import { isInDev } from 'ui/theme/theme'
import { sosShipmentProfileBroker } from '..'
import { convertBrokerRateResponseToRateRow } from './convertBrokerRateResponseToRateRow'

const isPollingTurnedOn = (): boolean => {
	const { pollOnLivePages: pollOnShipmentProfile } = sosUser.getSos().getState()
	return isInDev() ? pollOnShipmentProfile : true
}

export const pollInviteConnections = async (
	rateTableRows: RateRow[],
	defaultMarkupLogic: apiTypes.MarkupLogic,
	shipmentId: string,
): Promise<RateRow[]> => {
	const waitingOnProviderAction = l.find(
		rateTableRows,
		(row) =>
			row.quoteType === 'spotQuote' &&
			(row.offerStatus === 'active' || row.offerStatus === 'awarded'),
	)
	if (waitingOnProviderAction && isPollingTurnedOn()) {
		const shipment: apiTypes.BrokerShipmentResponse = (
			await apiBroker.fetchBrokerShipment(() => {}, shipmentId, false, true)
		).data
		if (shipment) {
			const currentConnectionRates = l.filter(
				rateTableRows,
				(rate) => rate.quoteType === 'spotQuote',
			)
			const newConnectionRates: apiTypes.RateResponse[] = l.filter(
				shipment.rates,
				(rate) => rate.quoteType === 'spotQuote',
			)
			let newRateTableRows = l.cloneDeep(rateTableRows)
			newConnectionRates.forEach((newConnectionRate) => {
				const currentConnectionRate = l.find(
					currentConnectionRates,
					(rate) => rate.rateId === newConnectionRate.id,
				)
				if (!currentConnectionRate) {
					// we have a rate that wasn't previously in our rates table, insert it in
					const rateRow: RateRow = convertBrokerRateResponseToRateRow(
						newConnectionRate,
						shipment,
						defaultMarkupLogic,
					)
					newRateTableRows.push(rateRow)
				} else if (
					currentConnectionRate.offerStatus !== newConnectionRate.offerStatus
				) {
					sosShipmentProfileBroker.setShipmentReference(shipment)
					// the connection was updated, change it in the rates table
					const existingRateRowIndex = l.findIndex(
						newRateTableRows,
						(rateRow) => rateRow.rateId === newConnectionRate.id,
					)
					const newRateRow = convertBrokerRateResponseToRateRow(
						newConnectionRate,
						shipment,
						defaultMarkupLogic,
					)
					newRateTableRows.splice(existingRateRowIndex, 1, newRateRow)
				}
			})
			newRateTableRows = sortRateRows(newRateTableRows)
			return newRateTableRows
		}
	}
}
