import React from 'react'
import { l } from 'ui/lib/lodashImports'
import { ndash } from '../common'
import { t } from '../i18n/i18n'
import { IDataTableHeader } from './IDataTableHeader'
import { Cell, HeaderCell } from './Table'
import * as tableStyles from './TableStyles.module.scss'

export const KeyValueTable = <T extends any>(props: {
	tPrefix: string
	headers: IDataTableHeader<T>[]
	rawData: any
	borderless?: boolean
	labelMinWidth?: string
	hideLabelIfNoValue?: boolean
}): React.ReactElement => {
	const { tPrefix, headers, rawData } = props

	let className = tableStyles.keyValueTable
	if (props.borderless) {
		className = tableStyles.keyValueTableBorderless
	}

	className += ' ' + tableStyles.tableHighlight

	return (
		<div className={className}>
			<table>
				<tbody>
					{l.map(headers, (c, cIdx) => {
						const rawCellValue = rawData[c.field]
						let cellValue = rawCellValue
						if (cellValue == null) {
							cellValue = ndash
						}
						if (c.currencyCode) {
							rawData.currencyCode = rawData[c.currencyCode]
						}
						return (
							<tr key={cIdx}>
								<HeaderCell style={{ minWidth: props.labelMinWidth }}>
									{tPrefix &&
										c.field &&
										t(l.camelCase(c.field.toString()), tPrefix)}
									{c.t && t(c.t)}
									{c.label}
								</HeaderCell>
								<Cell>
									{c.renderer ? c.renderer(rawCellValue, rawData) : cellValue}
								</Cell>
							</tr>
						)
					})}
				</tbody>
			</table>
		</div>
	)
}
