import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { l } from 'ui/lib/lodashImports'
import styles from './ApiExamples.module.scss'

export const JsonValue: FC = (props: { value: any; field: string }) => {
	if (
		props.value === true ||
		props.value === false ||
		props.value === 'true' ||
		props.value === 'false'
	) {
		return (
			<span className={styles.jsonValue + ' ' + styles.jsonValueBoolean}>
				{'' + props.value}
			</span>
		)
	}
	if (props.field === 'authorization') {
		return (
			<span className={styles.jsonValue}>
				{'"***** insert your auth token *****"'}
			</span>
		)
	}
	if (l.isString(props.value)) {
		return <span className={styles.jsonValue}>{`"${props.value}"`}</span>
	}
	if (l.isNumber(props.value)) {
		return (
			<span className={styles.jsonValue + ' ' + styles.jsonValueNumber}>
				{props.value}
			</span>
		)
	}
	return <span className={styles.jsonValue}>{props.value}</span>
}
