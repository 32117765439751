import { FC } from 'app/FunctionalComponent'
import React from 'react'
import classes from './Card.module.scss'

export const CardContent: FC = (props: {
	noPadding?: boolean
	children: React.ReactNode
}) => {
	return (
		<div
			className={`${classes.cardContent} ${
				props.noPadding ? null : classes.cardContentPadding
			}`}
		>
			{props.children}
		</div>
	)
}
