import { createLazySos2 } from 'ui/lib/state/sos2/sos2'
import { CalendarEvent } from './controlTowerCalendarUtils'

export type ControlTowerCalendarMode = 'map' | 'calendar'

export interface ControlTowerCalendarState {
	events: CalendarEvent[]
}

export const getSos = createLazySos2<ControlTowerCalendarState>(
	'sosControlTowerCalendar',
	1,
	() => ({
		events: { default: [], localStorage: false },
	}),
)

export const setEvents = (events: CalendarEvent[]): void => {
	getSos().change((ds) => {
		ds.events = events
	})
}
