import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { theme } from 'ui/theme'

const DevOnly: FC = (props: { children: React.ReactNode }) => {
	if (!theme.isInDev()) {
		return null
	} else {
		return <div>{props.children}</div>
	}
}

export { DevOnly }
