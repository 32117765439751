import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { testId } from 'ui/components/i18n/i18n'
import { Colors } from 'ui/theme/theme'
import { Button } from '../button'
import { SmallButton } from '../small-button'

const invalidColor: Colors = 'gray'

export const ValidatedSubmitButton: FC = (props: {
	isValid: boolean
	validColor?: Colors
	smallButton?: boolean
	onClick?: () => void
	children?: React.ReactNode
	testId?: string
	className?: string
}) => {
	const buttonColor = props.isValid ? props.validColor : invalidColor

	return props.smallButton ? (
		<SmallButton
			type='submit'
			color={buttonColor}
			onClick={props.onClick}
			testId={props.testId}
		>
			{props.children}
			{testId(props.testId)}
		</SmallButton>
	) : (
		<Button
			type='submit'
			color={buttonColor}
			onClick={props.onClick}
			testId={props.testId}
			className={props.className}
		>
			{props.children}
			{testId(props.testId)}
		</Button>
	)
}
