import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { AngryRedBox } from 'ui/components/common/angry-red-box'
import { Modal } from 'ui/components/common/modal/Modal'
import { OkCancelButtons } from 'ui/components/common/okCancelButtons'
import { ITimePickerState, TimeSelector } from 'ui/components/common/TimePicker'
import { t } from 'ui/components/i18n/i18n'
import { FlexRow, FlexVerticalSeparator } from 'ui/components/layout/flexRow'
import { Spacer } from 'ui/components/layout/spacer/Spacer'
import { l } from 'ui/lib/lodashImports'
import { ITimeSlot } from 'ui/lib/time/timeSlots'
import { sosDockConfigPage } from 'ui/pages/dock-scheduler/state'
import { HeaderText } from 'ui/pages/spot-quote'
import { tPrefixDockConfig } from './DockConfigurationPage'
import { validateNewTimeSlot } from './validateNewTimeSlot'

export interface IFormattedTimeSlot {
	startTime: ITimePickerState
	endTime: ITimePickerState
}

export const NewTimeSlotModal: FC = (props: {
	isOpen: boolean
	newTimeSlot: IFormattedTimeSlot
	existingTimeSlots: ITimeSlot[]
	close: () => any
}) => {
	const minutes = [0, 15, 30, 45]
	const { startTime, endTime } = props.newTimeSlot

	const onUpdateStart = (field, newVal): void => {
		sosDockConfigPage.updateNewTimeSlotTime('start', field, newVal)
	}

	const onUpdateEnd = (field, newVal): void => {
		sosDockConfigPage.updateNewTimeSlotTime('end', field, newVal)
	}

	const newTimeSlotError = validateNewTimeSlot(
		props.newTimeSlot,
		props.existingTimeSlots,
	)

	return (
		<Modal
			content={() => (
				<div data-testid='addTimeSlotModalContent'>
					<FlexRow>
						<div>
							<HeaderText large={true}>
								{t('startTime', tPrefixDockConfig)}
							</HeaderText>
							<TimeSelector
								hour={startTime.hour}
								minute={startTime.minute}
								minutes={minutes}
								onChange={onUpdateStart}
								tPrefix={tPrefixDockConfig}
								testId='startTimePicker'
							/>
						</div>
						<FlexVerticalSeparator />
						<div>
							<HeaderText>{t('endTime', tPrefixDockConfig)}</HeaderText>
							<TimeSelector
								hour={endTime.hour}
								minute={endTime.minute}
								minutes={minutes}
								onChange={onUpdateEnd}
								tPrefix={tPrefixDockConfig}
								testId='endTimePicker'
							/>
						</div>
					</FlexRow>
					<Spacer />
					{newTimeSlotError && (
						<AngryRedBox>{t(newTimeSlotError, tPrefixDockConfig)}</AngryRedBox>
					)}

					<OkCancelButtons
						ok={t('addTimeSlot', tPrefixDockConfig)}
						onOk={() => {
							sosDockConfigPage.addTimeSlot()
							props.close()
						}}
						okColor='green'
						isValid={l.isNil(newTimeSlotError)}
					/>
				</div>
			)}
			isOpen={props.isOpen}
			onModalClose={props.close}
			title={t('addTimeSlot', tPrefixDockConfig)}
		/>
	)
}
