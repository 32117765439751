import React, {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
} from 'react'
import { _idx } from 'ui/lib'

import classes from './Input.module.scss'
import { theme } from 'ui/theme'

export type InputType = 'text' | 'password' | 'email' | 'search'

export type InputAutocompleteType = 'name' | 'email' | 'new-password' | 'off'

let timeoutId = 0
const clearDebounce = (): void => {
	if (timeoutId) {
		clearTimeout(timeoutId)
	}
}

const setupDebounce = (cb, delay = 1000): void => {
	clearDebounce()
	timeoutId = setTimeout(cb, delay) as any
}

export const Input = forwardRef(
	(
		props: {
			ref?: any
			value: string
			onChange?: (newVal: string) => void
			onChangeImmediate?: (newVal: string) => void
			onFocus?: (ref) => void
			onEnter?: (newVal?: string) => void
			onBlur?: (ref) => void
			onKeyDown?: (
				event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
			) => void
			width?: string
			className?: string
			name?: string
			testId?: string
			placeholder?: string
			maxLength?: number
			autofocus?: boolean
			readOnly?: boolean
			borderless?: boolean
			inputType?: InputType
			autocomplete?: InputAutocompleteType
			multiline?: boolean
			notResizable?: boolean
			rows?: number
			disabled?: boolean
		},
		ref?: any,
	) => {
		const [isFocused, setIsFocused] = React.useState(false)
		const [focusedText, setFocusedText] = React.useState(props.value)

		const refTextArea = React.createRef<HTMLTextAreaElement>()
		const refInput = useRef<HTMLInputElement>(null)

		const onFocus = (): void => {
			setIsFocused(true)
			setFocusedText(props.value)
			if (props.onFocus) {
				props.onFocus(refTextArea.current)
			}
		}
		const onBlur = (): void => {
			setIsFocused(false)
			clearDebounce()
			if (props.onBlur) {
				props.onBlur(refTextArea.current)
			}
			if (props.onChange) {
				if (focusedText !== props.value) {
					props.onChange(focusedText)
				}
			}
		}

		const onChange = (
			event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
		): void => {
			const newVal = event.target.value
			if (props.onChangeImmediate) {
				props.onChangeImmediate(newVal)
			}
			setFocusedText(newVal)
			setupDebounce(() => {
				if (props.onChange && props.value !== newVal) {
					props.onChange(newVal)
				}
			})
		}
		const defaultOnKeyDown = (
			event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
		): void => {
			if (event.keyCode === 13) {
				if (props.onEnter) {
					props.onEnter(focusedText)
				}
			}
		}

		const onKeyDown = props.onKeyDown || defaultOnKeyDown

		useEffect(() => {
			if (props.autofocus) {
				// Timing issue, this needs to be run on the next frame
				setTimeout(() => {
					if (refInput.current) {
						refInput.current.focus()
					}
				}, 1000 / 60) // 1/60th of a second
			}
		}, [props.autofocus, refInput])

		useImperativeHandle(ref, () => ({
			focus: () => {
				_idx(() => refInput.current.focus())
				_idx(() => refTextArea.current.focus())
			},
			blur: () => {
				_idx(() => refInput.current.blur())
				_idx(() => refTextArea.current.blur())
			},
			setSelectionRange: (start: number, end: number) => {
				_idx(() => refInput.current.setSelectionRange(start, end))
				_idx(() => refTextArea.current.setSelectionRange(start, end))
			},
		}))

		let className = classes.inputText
		className = theme.addClassIf(
			!props.multiline,
			classes.singleLine,
			className,
		)
		className = theme.addClassIf(props.readOnly, classes.readOnly, className)
		className = theme.addClassIf(
			props.borderless,
			classes.borderless,
			className,
		)
		className = theme.addClassIf(
			props.notResizable,
			classes.notResizable,
			className,
		)
		className = theme.addClass(props.className, className)

		let { autocomplete } = props
		if (autocomplete === 'off' || !autocomplete) {
			autocomplete = ('' + Math.random()) as any
		}

		const style = {
			width: props.width,
			minWidth: props.width,
			maxWidth: props.width,
		}

		if (props.multiline) {
			return (
				<div>
					<textarea
						ref={refTextArea}
						className={className}
						onChange={onChange}
						value={isFocused ? focusedText : props.value || ''}
						style={style}
						name={props.name}
						data-testid={props.testId}
						placeholder={props.placeholder}
						maxLength={props.maxLength}
						onFocus={onFocus}
						onKeyDown={onKeyDown}
						readOnly={props.readOnly}
						disabled={props.disabled}
						data-readonly={props.readOnly}
						onBlur={onBlur}
						rows={props.rows || 8}
						autoComplete={autocomplete}
					/>
				</div>
			)
		}

		return (
			<input
				ref={refInput}
				className={className}
				type={props.inputType || 'text'}
				onChange={onChange}
				value={isFocused ? focusedText : props.value || ''}
				style={style}
				name={props.name}
				data-testid={props.testId}
				placeholder={props.placeholder}
				maxLength={props.maxLength}
				onFocus={onFocus}
				onKeyDown={onKeyDown}
				readOnly={props.readOnly}
				data-readonly={props.readOnly}
				onBlur={onBlur}
				autoComplete={autocomplete}
			/>
		)
	},
)
