import { AsyncTypeahead, TypeaheadOption } from 'common/components/typeahead'
import React, { useEffect, useState } from 'react'
import { apiUser } from 'ui/api'
import { sosToast } from 'ui/common/components/toast'
import { tString } from 'ui/components/i18n/i18n'
import { FC } from 'ui/FunctionalComponent'

export const SalesAgentSelector: FC = (props: {
	onChange?: (selected: TypeaheadOption) => void
	onError?: (error: any) => void
	selectedSalesAgent?: TypeaheadOption
	disabled?: boolean
	tPrefix?: string
}) => {
	const { selectedSalesAgent, disabled, tPrefix } = props

	const [options, setOptions] = useState<TypeaheadOption[]>([])
	const [salesAgent, setSalesAgent] = useState<TypeaheadOption>()

	useEffect(() => {
		if (selectedSalesAgent) {
			setSalesAgent(selectedSalesAgent)
		}
	}, [selectedSalesAgent])

	return (
		<AsyncTypeahead
			testId='sales-agent-selector'
			size='small'
			width='300px'
			options={options || []}
			onSearch={async (): Promise<TypeaheadOption[]> => {
				const usersResponse = await apiUser.getUsersForCompany(
					() => {},
					true,
					100,
					0,
				)

				if (usersResponse.error) {
					if (props.onError) {
						props.onError(usersResponse)
					} else {
						sosToast.sendApiErrorResponseToast(usersResponse)
					}
				} else {
					setOptions(
						usersResponse.data.map((user) => ({
							value: user.id,
							label: user.username || user.id,
						})),
					)
				}

				return options
			}}
			onChange={(value: TypeaheadOption) => {
				setSalesAgent(value)
				props.onChange(value)
			}}
			value={salesAgent}
			defaultSelected={selectedSalesAgent?.label}
			placeholder={tString('selectASalesAgent', tPrefix)}
			makeInitialFetch={true}
			disabled={disabled}
		/>
	)
}
