import { FC } from 'app/FunctionalComponent'
import React, { CSSProperties } from 'react'
import { l } from 'ui/lib/lodashImports'
import { addClassIf } from 'ui/theme/theme'
import { IconButton, solidIcons } from '../icon'
import classes from './Popup.module.scss'
import { PopupBox, PopupBoxPosition } from './PopupBox'

export const Popup: FC = (props: {
	children?: React.ReactNode
	isOpen: boolean
	onClickOutside?: () => void
	content: React.ReactNode
	inset?: boolean
	position?: keyof PopupBoxPosition
	width?: string
	testId?: string
	breakerClassName?: string
}) => {
	let style: CSSProperties = null
	if (props.width) {
		style = { width: props.width }
	}

	return (
		<div
			className={classes.popupContainer}
			style={style}
			data-testid={props.testId}
		>
			<div
				className={addClassIf(
					!l.isNil(props.breakerClassName),
					props.breakerClassName,
					classes.popupHiddenBreaker,
				)}
			>
				<div className={classes.popupHiddenBreaker2}>
					<PopupBox
						isOpen={props.isOpen}
						onClickOutside={props.onClickOutside}
						inset={props.inset}
						position={props.position}
					>
						{props.content}
					</PopupBox>
				</div>
			</div>
			{props.children}
		</div>
	)
}

export const EllipsisPopup: FC = (props: {
	isOpen: boolean
	onClick?: () => void
	onClickOutside?: () => void
	children: React.ReactNode
	inset?: boolean
	position?: keyof PopupBoxPosition
	width?: string
	testId?: string
}) => {
	return (
		<Popup
			isOpen={props.isOpen}
			onClickOutside={props.onClickOutside}
			position={props.position}
			content={props.children}
			testId={props.testId}
		>
			<IconButton
				onClick={props.onClick}
				icon={solidIcons.faEllipsisV}
				iconClassName={classes.verticalEllipsis}
				testId='ellipsis-popup'
			/>
		</Popup>
	)
}
