import { apiAddressBook } from 'ui/api/'
import { UpdateMode } from 'ui/api/apiCommon'
import { IRequestState } from 'ui/api/requestState'
import { dataUtils } from 'ui/components/table/'
import {
	createDefaultDataTableState,
	IDataTableState,
} from 'ui/components/table/IDataTableState'
import { formUtils } from 'ui/forms'
import {
	addressBookFormMetadata,
	IAddressBookForm,
} from 'ui/forms/formAddressBook'
import { IAddressBook } from 'ui/lib/books/IAddressBook'
import { IAddressBookEntry } from 'ui/lib/books/IAddressBookEntry'
import { createSos2 } from 'ui/lib/state/sos2/sos2'

export const addressBookPageSize = 25

export interface IStateAddressBook {
	dataTableAddressBooks: IDataTableState<IAddressBook>
	dataAddressBooks: IAddressBook[]
	formAddressBook: IAddressBookForm
	requestGetAddressBooks: IRequestState<IAddressBook[]>
	requestGetAddressBookEntries: IRequestState<IAddressBookEntry[]>
	requestAddEditAddressBook: IRequestState<IAddressBook>
}

export const sos = createSos2<IStateAddressBook>('address-books', 2, {
	dataTableAddressBooks: {
		default: createDefaultDataTableState({
			sortOn: 'name',
			hiddenHeaders: ['id', 'pageType'],
		}),
	},
	dataAddressBooks: { default: [] },
	formAddressBook: {
		default: formUtils.createDefaultFormStringData(addressBookFormMetadata),
	},
	requestGetAddressBooks: { default: {} },
	requestGetAddressBookEntries: { default: {} },
	requestAddEditAddressBook: { default: {} },
})

export async function getBooks(): Promise<void> {
	await apiAddressBook.getAddressBooks((rs) => {
		sos.change((ds) => {
			ds.requestGetAddressBooks = rs
			if (rs.data) {
				ds.dataAddressBooks = rs.data
				dataUtils.initialLoad(ds.dataTableAddressBooks, ds.dataAddressBooks)
			}
		})
	})
}

export function booksTableSort(sortOn: string): void {
	sos.change((ds) => {
		dataUtils.sort(ds.dataTableAddressBooks, ds.dataAddressBooks, sortOn)
	})
}

export function booksTableToggleHeader(field: string): void {
	sos.change((ds) => {
		dataUtils.toggleHeader(ds.dataTableAddressBooks, field)
	})
}

export function booksTableSetUpdateMode(
	mode: UpdateMode,
	row: IAddressBook = null,
): void {
	sos.change((ds) => {
		dataUtils.toggleUpdateMode(ds.dataTableAddressBooks, mode, row)
		if (ds.dataTableAddressBooks.isAdding) {
			ds.formAddressBook = formUtils.createDefaultFormStringData(
				addressBookFormMetadata,
			)
		} else if (ds.dataTableAddressBooks.editRow) {
			ds.formAddressBook = formUtils.apiDataToFormStringData(
				addressBookFormMetadata,
				row,
			)
		}
	})
}

export function updateBookForm(
	key: keyof IAddressBookForm,
	newVal: string,
): void {
	sos.change((ds) => {
		ds.formAddressBook[key] = newVal
	})
}

export async function updateBook(mode: UpdateMode): Promise<void> {
	const state = sos.getState()
	const apiData = formUtils.formStringDataToApiData(
		addressBookFormMetadata,
		state.dataTableAddressBooks.editRow,
		state.formAddressBook,
	)
	const result = await apiAddressBook.updateAddressBook(mode, apiData, (rs) => {
		sos.change((ds) => {
			ds.requestAddEditAddressBook = rs
		})
	})

	if (result.data) {
		sos.change((ds) => {
			dataUtils.updateItem(
				ds.dataTableAddressBooks,
				ds.dataAddressBooks,
				mode,
				result.data,
				ds.formAddressBook.id,
			)
		})
		booksTableSetUpdateMode('none')
	}
	if (result.error) {
		sos.change((ds) => {
			dataUtils.setError(ds.dataTableAddressBooks, result.error)
		})
	}
}
