import { FC } from 'app/FunctionalComponent'
import React, { useState } from 'react'
import { apiTypes } from 'ui/api'
import { Modal } from 'ui/components/common/modal'
import { OkCancelButtons } from 'ui/components/common/okCancelButtons'
import {
	FormStackedListSelect,
	FormVerticalRadio,
	IFormData,
} from 'ui/components/form'
import { t } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight'
import { Spacer } from 'ui/components/layout/spacer'
import { l } from 'ui/lib/lodashImports'
import { sosUser } from 'ui/state'
import * as classes from './PrinterGroupModal.module.scss'

interface PrinterGroupModalForm {
	hasPrintStation: boolean
	noPrintStation: boolean
}

const defaultPrinterGroupModalForm: PrinterGroupModalForm = {
	hasPrintStation: true,
	noPrintStation: false,
}

const tPrefix = 'page.companyManagement.printersAndScales.printerGroupModal'

const spacerHeight = '10px'

const noPrintStation = 'N/A'

export const PrinterGroupModal: FC = (props: {
	isModalOpen: boolean
	onModalClose: () => void
	printStationList: apiTypes.PrinterGroupResponse[]
}) => {
	const { printStationList } = props
	const [printerGroupSaveForm, setPrinterGroupSaveForm] = useState<
		PrinterGroupModalForm
	>(defaultPrinterGroupModalForm)
	const [selectedPrintStation, setSelectedPrintStation] = useState<string>(
		noPrintStation,
	)
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const onClose = (): void => {
		props.onModalClose()
	}

	const onSave = async (): Promise<void> => {
		setIsLoading(true)

		document.cookie = `x-printer-group-id=${selectedPrintStation}; expires=Fri, 31 Dec 2050 12:00:00 UTC; path=/`
		await sosUser.fetchPrintStation(selectedPrintStation)

		setIsLoading(false)
		props.onModalClose()
	}

	const printerGroupForm: IFormData<PrinterGroupModalForm> = {
		form: printerGroupSaveForm,
		metadata: {
			hasPrintStation: {},
			noPrintStation: {},
		},
		onUpdateForm: (field: string, value: any) => {
			const updatedFormData = l.cloneDeep(printerGroupSaveForm)

			updatedFormData[field] = value
			setPrinterGroupSaveForm(updatedFormData)
		},
		tPrefix,
	}

	return (
		<Modal
			content={() => (
				<div className={classes.printerGroupModal}>
					<Spacer height={spacerHeight} />
					<FormVerticalRadio
						formData={printerGroupForm}
						selected={
							printerGroupSaveForm.hasPrintStation
								? 'hasPrintStation'
								: 'noPrintStation'
						}
						options={['hasPrintStation', 'noPrintStation']}
						onChange={(selectedOption: any) => {
							const form = Object.assign({}, printerGroupSaveForm)
							form.hasPrintStation = selectedOption === 'hasPrintStation'
							form.noPrintStation = !form.hasPrintStation
							setPrinterGroupSaveForm(form)
							if (form.noPrintStation) {
								setSelectedPrintStation(noPrintStation)
							}
						}}
						labelSide='right'
						testId={'printer-group-modal-radio'}
					/>
					<Spacer height={spacerHeight} />
					<FormStackedListSelect
						options={printStationList.map((printStation) => ({
							label: printStation.nickname,
							value: printStation.id,
						}))}
						value={selectedPrintStation}
						onChange={(selected) => {
							setSelectedPrintStation(selected)
						}}
						label={t('printStation', tPrefix)}
						size={10}
						testId={'printer-group-modal-print-station-select'}
						readOnly={printerGroupSaveForm.noPrintStation}
					/>

					<Spacer height={spacerHeight} />
					<AlignRight>
						<OkCancelButtons
							ok={t('save', tPrefix)}
							onOk={onSave}
							okColor='green'
							cancel={t('cancel', tPrefix)}
							onCancel={onClose}
							isSpinning={isLoading}
							isValid={
								(printerGroupSaveForm.hasPrintStation &&
									selectedPrintStation !== noPrintStation) ||
								printerGroupSaveForm.noPrintStation
							}
							okTestId={'printer-group-modal-save-button'}
							cancelTestId={'printer-group-modal-cancel-button'}
						/>
					</AlignRight>
				</div>
			)}
			isOpen={props.isModalOpen}
			onModalClose={onClose}
			closeModalX={true}
			title={t('printerGroupModal', tPrefix)}
		/>
	)
}
