import { IFormMetadataCollection } from 'ui/components/form/IFormMetadata'
import { l } from 'ui/lib/lodashImports'

export type FormStringData<T> = Extract<
	{ [P in keyof T]: any },
	{ [P in keyof T]: string }
>

export function createDefaultFormStringData<T>(
	metadata: IFormMetadataCollection<T>,
): FormStringData<T> {
	const newObj: FormStringData<T> = {} as any
	l.forIn(metadata, (value, key) => {
		newObj[key] = value.defaultValue || ''
	})
	return newObj
}

export function apiDataToFormStringData<T>(
	metadata: IFormMetadataCollection<T>,
	apiData: T,
): FormStringData<T> {
	const newObj = createDefaultFormStringData(metadata) as FormStringData<T>

	l.forIn(apiData, (value: any, key) => {
		if (l.isArray(value)) {
			return // Don't convert these yet
		}
		// Right now we don't handle sub objects
		if (l.isObject(value)) {
			throw new Error("nested objects aren't supported yet")
		}
		if (value === false) {
			newObj[key] = ''
		} else if (value != null) {
			newObj[key] = '' + value
		} else {
			// Don't override a previous default value
			if (!newObj[key]) {
				newObj[key] = ''
			}
		}
	})
	return newObj
}

export function formStringDataToApiData<T>(
	metadata: IFormMetadataCollection<T>,
	originalApiData: T,
	formData: FormStringData<Partial<T>>,
): T {
	const changedApiData: T = {} as any

	l.forIn(metadata, (metadataEntry, field) => {
		const value = formData[field]

		// Right now we don't handle sub objects
		if (l.isArray(value)) {
			changedApiData[field] = value
		} else if (l.isObject(value)) {
			throw new Error("nested objects aren't supported yet")
		} else if (metadataEntry.mustBeAnInteger) {
			changedApiData[field] = parseInt(value, 10)
		} else if (metadataEntry.mustBeANumber) {
			changedApiData[field] = parseFloat(value)
		} else if (metadataEntry.mustBeABoolean) {
			changedApiData[field] = value === 'true'
		} else if (!value && value !== 0) {
			changedApiData[field] = ''
		} else {
			changedApiData[field] = '' + value
		}
	})

	const newApiData = l.assign({}, originalApiData, changedApiData)

	return newApiData
}
