import React, { ReactElement, useEffect, useState } from 'react'
import { apiTypes } from 'ui/api'
import { Debug } from 'ui/components/dev'
import { t } from 'ui/components/i18n/i18n'
import { Button } from 'ui/components/common/button'
import { DocumentList } from 'ui/pages/shipment-profile/broker/components/document/ShippingDocumentsList'
import { DocumentUploadModal } from 'ui/pages/shipment-profile/broker/components/document/document-upload-modal'
import { l } from 'ui/lib/lodashImports'
import {
	BrokerShippingDocumentResponse,
	ShippingDocumentResponse,
} from 'ui/api/apiTypes'
import { DocumentViewer } from 'ui/components/common/modal'

const translate = (key: string): ReactElement =>
	t(key, 'page.shipmentProfile.documentUpload')

interface DocumentUploadProps {
	shipment: apiTypes.BrokerShipmentResponse | apiTypes.ShipmentResponse
}

export const DocumentSection = (props: DocumentUploadProps): ReactElement => {
	const { shipment } = props
	const [showModal, updateShowModal] = useState<boolean>()
	const [documents, updateDocuments] = useState<
		apiTypes.BrokerShippingDocumentResponse[]
	>([])
	const [documentURL, setDocumentURL] = useState('')
	const [viewerOpen, setViewerOpen] = useState(false)

	useEffect(() => {
		const payloads = shipment.payloads as Array<
			apiTypes.BrokerPayloadResponse | apiTypes.PayloadResponse
		>
		const shippingDocuments = l.flatMap(
			payloads.map((payload) => payload.shippingDocuments),
		)
		updateDocuments(shippingDocuments)
	}, [shipment])

	return (
		<div className='bootstrap-wrapper'>
			<div className='form-group d-flex justify-content-end'>
				<Button
					color='blue'
					className='d-block'
					onClick={(): void => updateShowModal(true)}
				>
					{translate('addDocument')}
				</Button>
			</div>
			<div className='container-fluid'>
				{documents.length === 0 && (
					<div className='text-center alert alert-dark' role='alert'>
						{translate('noDocuments')}
					</div>
				)}
				<DocumentList
					documents={documents}
					onOpen={(
						file: BrokerShippingDocumentResponse | ShippingDocumentResponse,
					) => {
						setDocumentURL(file.images[0].image)
						setViewerOpen(true)
					}}
				/>
			</div>
			<Debug data={shipment} label={'test'} />
			<DocumentUploadModal
				show={showModal}
				shipmentId={shipment.id}
				payloads={shipment.payloads}
				onHide={(): void => {
					updateShowModal(false)
				}}
			/>
			{viewerOpen && (
				<DocumentViewer
					documentUrl={documentURL}
					onModalClose={() => setViewerOpen(false)}
					isOpen={viewerOpen}
					base64Specification='application/pdf'
				/>
			)}
		</div>
	)
}
