import React from 'react'
import { PillSwitch } from '../common/pill-switch'
import { ISelectOptions } from '../common/select'
import { FormStackedItem } from './FormStackedItem'
import { IFormData } from './IFormData'
import { l } from 'ui/lib/lodashImports'

export function FormStackedPillSwitch<T>(props: {
	formData: IFormData<T>
	field: keyof T
	options?: string[] | ISelectOptions[]
	hideOptional?: boolean
	label?: React.ReactElement | string
	className?: string
	tPrefix?: string
	testId?: string
	disabled?: boolean
}): React.ReactElement {
	const {
		formData,
		field,
		hideOptional,
		label,
		disabled,
		className,
		tPrefix,
		testId,
	} = props

	const fieldData = props.formData.metadata[props.field]
	const options = (props.options || fieldData.options) as ISelectOptions[]

	const onChange = (newVal: string): void => {
		formData.onUpdateForm(field, newVal)
	}

	const formValue = formData.form[field]

	const matchingOption = l.find(
		options,
		(option) => option.value === '' + formValue,
	)

	return (
		<FormStackedItem
			field={field}
			formData={formData}
			label={label}
			hideOptional={hideOptional}
			className={className}
		>
			<PillSwitch
				value={matchingOption?.value}
				options={options}
				onChange={onChange}
				disabled={disabled}
				className={className}
				tPrefix={tPrefix}
				testId={testId}
			/>
		</FormStackedItem>
	)
}
