import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { l } from 'ui/lib/lodashImports'
import { Chip } from '../common/chip/Chip'
import { ISelectOptions } from '../common/select/ISelectOptions'
import * as classes from '../common/select/select.module.scss'
import { t } from '../i18n/i18n'
import { FormStackedListSelect } from './FormStackedListSelect'

export const FormStackedListMultiSelect: FC = (props: {
	options: ISelectOptions[]
	selectedValues: any[]
	onChange: (values: any, add: boolean) => void
	label?: React.ReactElement | string
	tPrefix?: string
	size?: number
	testId?: string
}) => {
	const unselectedOptions = l.filter(
		props.options,
		(option) => !l.includes(props.selectedValues, option.value),
	)

	return (
		<div>
			<FormStackedListSelect
				options={unselectedOptions}
				value=''
				onChange={(val) => props.onChange(val, true)}
				label={props.label}
				tPrefix={props.tPrefix}
				size={props.size}
				testId={props.testId}
			/>
			{l.map(props.selectedValues, (value, valueIdx) => {
				const selectedOption = l.find(
					props.options,
					(option) => option.value === value,
				)

				return (
					<div key={valueIdx} className={classes.chip}>
						<Chip clearable={true} onClear={() => props.onChange(value, false)}>
							<div>{selectedOption.label || t(value, props.tPrefix)}</div>
						</Chip>
					</div>
				)
			})}
		</div>
	)
}
