import {
	faAddressBook,
	faCalendarAlt,
	faCircle,
	faEnvelope,
	faEdit,
	faTimesCircle,
	faTrashAlt,
	faUser,
	faDotCircle,
	faSquare,
	faMinusSquare,
	faPlusSquare,
} from '@fortawesome/free-regular-svg-icons'

export const regularIcons = {
	faCalendarAlt,
	faTrashAlt,
	faAddressBook,
	faCircle,
	faEnvelope,
	faEdit,
	faTimesCircle,
	faUser,
	faDotCircle,
	faSquare,
	faMinusSquare,
	faPlusSquare,
}
