import { FC } from 'app/FunctionalComponent'
import React, { useEffect } from 'react'
import { ValidatedSubmitButton } from 'ui/components/common/validated-submit-button'
import { Debug } from 'ui/components/dev/Debug'
import { FormVertical } from 'ui/components/form/FormVertical'
import { FormVerticalLayout } from 'ui/components/form/FormVerticalLayout'
import { FormVerticalSelect } from 'ui/components/form/FormVerticalSelect'
import { FormVerticalTextInput } from 'ui/components/form/FormVerticalTextInput'
import { IFormData } from 'ui/components/form/IFormData'
import { t } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight/AlignRight'
import {
	addressBookEntryFormMetadata,
	IAddressBookEntryForm,
} from 'ui/forms/formAddressBookEntry'
import { validateForm } from 'ui/lib/validation/formValidator'
import { sosAddressBookEntry } from 'ui/state/'

export const UpdateAddressBookEntryForm: FC = (props: {
	mode: 'add' | 'edit'
	form: IAddressBookEntryForm
}) => {
	const state = sosAddressBookEntry.useSubscribe()

	const formData: IFormData<IAddressBookEntryForm> = {
		form: props.form,
		metadata: addressBookEntryFormMetadata,
		onUpdateForm: sosAddressBookEntry.updateAddressBookPageForm,
		tPrefix: 'shared.addressBook.addressBookPageForm',
	}

	const validationResult = validateForm(formData.form, formData.metadata)

	useEffect(() => {
		if (props.form.country) {
			sosAddressBookEntry.setCountry(props.form.country)
			sosAddressBookEntry.setRegions(props.form.country)
		}
	}, [props.form.country])

	// let regionsOptions: ISelectOptions[] = []
	// if (state.regions) {
	//   _.forEach(state.regions, (region) => {
	//     regionsOptions.push({ label: region, value: region })
	//   })
	// }

	return (
		<div>
			<Debug hide={true} label='form data' data={props.form} />
			<FormVertical>
				<FormVerticalLayout>
					{/* {props.mode === 'edit' && (
						<FormVerticalReadOnly formData={formData} field='id' />
					)} */}
					<FormVerticalSelect formData={formData} field='addressType' />

					<FormVerticalTextInput formData={formData} field='name' />
					<FormVerticalTextInput formData={formData} field='phone' />
					<FormVerticalTextInput formData={formData} field='email' />

					<FormVerticalTextInput formData={formData} field='street1' />
					<FormVerticalTextInput formData={formData} field='street2' />
					<FormVerticalTextInput formData={formData} field='city' />
					<FormVerticalSelect
						formData={formData}
						field='state'
						options={state.regions}
					/>
					<FormVerticalTextInput formData={formData} field='zip' />
					<FormVerticalSelect formData={formData} field='country' />
				</FormVerticalLayout>
				<AlignRight>
					<ValidatedSubmitButton
						testId='submit-upsert-address-book-page'
						onClick={async () => {
							await sosAddressBookEntry.updateAddressBookEntry('upsert')
						}}
						isValid={validationResult.isValid}
					>
						{t(`shared.addressBook.${props.mode}AddressBookPage`)}
					</ValidatedSubmitButton>
				</AlignRight>
			</FormVertical>
		</div>
	)
}
