import React from 'react'
import styles from './FormVertical.module.scss'
import { IFormData } from './IFormData'

export function FormVertical<T extends IFormData<T>>(props: {
	children: React.ReactNode
}): React.ReactElement {
	return (
		<div className={styles.formRowContainer}>
			<form
				onSubmit={(ev) => {
					ev.preventDefault()
					return false
				}}
			>
				{props.children}
			</form>
		</div>
	)
}
