import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { IRequestState } from 'ui/api/requestState'
import { t } from 'ui/components/i18n/i18n'
import { DataTable } from 'ui/components/table/DataTable'
import { DataTableUpdate } from 'ui/components/table/DataTableUpdate'
import { IDataTableHeader } from 'ui/components/table/IDataTableHeader'
import { IDataTableState } from 'ui/components/table/IDataTableState'
import { IAddressBook } from 'ui/lib/books/IAddressBook'
import { sosAddressBook } from 'ui/state'
import { navigateToAddressBook } from 'ui/state/sosAddressBookEntry'
import { IAddressBookForm } from '../../forms/formAddressBook'
import { UpdateAddressBookForm } from './UpdateAddressBookForm'

export const AddressBookDataTable: FC = (props: {
	requestAddEditAddressBook: IRequestState<IAddressBook>
	requestGetAddressBooks: IRequestState<IAddressBook[]>
	dataTableState: IDataTableState<IAddressBook>
	data: IAddressBook[]
	form: IAddressBookForm
}) => {
	const {
		requestGetAddressBooks,
		requestAddEditAddressBook,
		dataTableState,
	} = props
	const headers: IDataTableHeader<IAddressBook>[] = [
		{
			field: 'id',
		},
		{
			field: 'name',
		},
		{
			field: 'pageType',
		},
	]

	return (
		<div>
			<DataTable
				testId='data-table-books'
				headers={headers}
				tPrefix='shared.productCatalog.bookTable'
				isLoading={
					requestGetAddressBooks.isFetching ||
					requestAddEditAddressBook.isFetching
				}
				data={props.data}
				state={dataTableState}
				onEditRow={(row) => {
					sosAddressBook.booksTableSetUpdateMode('edit', row)
				}}
				onToggleHeader={(c) => {
					sosAddressBook.booksTableToggleHeader(c.field)
				}}
				onClickHeader={(header) => {
					sosAddressBook.booksTableSort(header.field)
				}}
				onRefresh={async () => {
					await sosAddressBook.getBooks()
				}}
				onClickRow={(row) => {
					navigateToAddressBook({ bookId: row.id })
				}}
				onAddRow={() => {
					sosAddressBook.booksTableSetUpdateMode('add')
				}}
				addLabel={t('page.manageAddressBooks.bookTable.add')}
				rendererAdd={() => (
					<DataTableUpdate error={props.dataTableState.error}>
						<UpdateAddressBookForm mode='add' form={props.form} />
					</DataTableUpdate>
				)}
				rendererEdit={(row) => (
					<DataTableUpdate
						error={props.dataTableState.error}
						onDeleteRow={async () => {
							await sosAddressBook.updateBook('delete')
						}}
					>
						<UpdateAddressBookForm mode='edit' form={props.form} />
					</DataTableUpdate>
				)}
			/>
		</div>
	)
}
