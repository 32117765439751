import React, { useState } from 'react'
import { FC } from 'ui/FunctionalComponent'
import { Row, Col, Form } from 'react-bootstrap'
import { t } from 'ui/components/i18n/i18n'
import { sosCustomerManagement } from '../..'

export interface ICustomerDetailsAddressForm {
	customerName: string
	customerNumber: string
	street1: string
	street2: string
	city: string
	state: string
	zipCode: string
	country: string
	contactName: string
	phone: string
	email: string
	fax: string
}

export const CustomerDetailsAddress: FC = (props: {
	customerDetailsAddressForm: ICustomerDetailsAddressForm
	tPrefix: string
}) => {
	const { tPrefix } = props

	const [customerDetailsAddressForm, setCustomerDetailsAddressForm] = useState<
		ICustomerDetailsAddressForm
	>(props.customerDetailsAddressForm)

	const tPrefixCustomerDetailsAddress = tPrefix + '.customerDetailsAddress'

	// const [readOnlyForm, setReadOnlyForm] = useState<boolean>(true)
	const readOnlyForm = true

	const handleChange = (e: React.FormEvent<HTMLInputElement>): void => {
		const name = e.currentTarget.name
		const value = e.currentTarget.value

		sosCustomerManagement.updateAddressForm(
			name as keyof ICustomerDetailsAddressForm,
			value,
		)

		setCustomerDetailsAddressForm({
			...customerDetailsAddressForm,
			[name]: value,
		})
	}

	return (
		<div className='bootstrap-wrapper' data-testid='customer-details-address'>
			<Row>
				<Col xs={4}>
					<Form.Group controlId='customerName'>
						<Form.Label>
							{t('customerName', tPrefixCustomerDetailsAddress)}
						</Form.Label>
						<Form.Control
							name='customerName'
							type='text'
							onChange={handleChange}
							value={customerDetailsAddressForm.customerName}
							data-testid='customerDetailsAddressForm-customerName'
							disabled={readOnlyForm}
						></Form.Control>
					</Form.Group>

					<Form.Group controlId='customerNumber'>
						<Form.Label>
							{t('customerNumber', tPrefixCustomerDetailsAddress)}
						</Form.Label>
						<Form.Control
							name='customerNumber'
							type='text'
							onChange={handleChange}
							value={customerDetailsAddressForm.customerNumber}
							data-testid='customerDetailsAddressForm-customerNumber'
							disabled={readOnlyForm}
						></Form.Control>
					</Form.Group>
				</Col>

				<Col xs={4}>
					<Form.Group controlId='street1'>
						<Form.Label>
							{t('street1', tPrefixCustomerDetailsAddress)}
						</Form.Label>
						<Form.Control
							name='street1'
							type='text'
							onChange={handleChange}
							value={customerDetailsAddressForm.street1}
							data-testid='customerDetailsAddressForm-street1'
							disabled={readOnlyForm}
						></Form.Control>
					</Form.Group>

					<Form.Group controlId='street2'>
						<Form.Label>
							{t('street2', tPrefixCustomerDetailsAddress)}
						</Form.Label>
						<Form.Control
							name='street2'
							type='text'
							onChange={handleChange}
							value={customerDetailsAddressForm.street2}
							data-testid='customerDetailsAddressForm-street2'
							disabled={readOnlyForm}
						></Form.Control>
					</Form.Group>

					<Row>
						<Col xs={6}>
							<Form.Group controlId='city'>
								<Form.Label>
									{t('city', tPrefixCustomerDetailsAddress)}
								</Form.Label>
								<Form.Control
									name='city'
									type='text'
									onChange={handleChange}
									value={customerDetailsAddressForm.city}
									data-testid='customerDetailsAddressForm-city'
									disabled={readOnlyForm}
								></Form.Control>
							</Form.Group>
						</Col>
						<Col xs={6}>
							<Form.Group controlId='state'>
								<Form.Label>
									{t('state', tPrefixCustomerDetailsAddress)}
								</Form.Label>
								<Form.Control
									name='state'
									type='text'
									onChange={handleChange}
									value={customerDetailsAddressForm.state}
									data-testid='customerDetailsAddressForm-state'
									disabled={readOnlyForm}
								></Form.Control>
							</Form.Group>
						</Col>
					</Row>

					<Form.Group controlId='zipCode'>
						<Form.Label>
							{t('zipCode', tPrefixCustomerDetailsAddress)}
						</Form.Label>
						<Form.Control
							name='zipCode'
							type='text'
							onChange={handleChange}
							value={customerDetailsAddressForm.zipCode}
							data-testid='customerDetailsAddressForm-zipCode'
							disabled={readOnlyForm}
						></Form.Control>
					</Form.Group>

					<Form.Group controlId='country'>
						<Form.Label>
							{t('country', tPrefixCustomerDetailsAddress)}
						</Form.Label>
						<Form.Control
							name='country'
							type='text'
							onChange={handleChange}
							value={customerDetailsAddressForm.country}
							data-testid='customerDetailsAddressForm-country'
							disabled={readOnlyForm}
						></Form.Control>
					</Form.Group>

					{/* <Form.Group controlId='exampleForm.ControlSelect1'>
						<Form.Label>Example select</Form.Label>
						<Form.Control as='select'>
							<option>1</option>
							<option>2</option>
							<option>3</option>
							<option>4</option>
							<option>5</option>
						</Form.Control>
					</Form.Group> */}
				</Col>

				<Col xs={4}>
					<Form.Group controlId='contactName'>
						<Form.Label>
							{t('contactName', tPrefixCustomerDetailsAddress)}
						</Form.Label>
						<Form.Control
							name='contactName'
							type='text'
							onChange={handleChange}
							value={customerDetailsAddressForm.contactName}
							data-testid='customerDetailsAddressForm-contactName'
							disabled={readOnlyForm}
						></Form.Control>
					</Form.Group>

					<Form.Group controlId='phone'>
						<Form.Label>{t('phone', tPrefixCustomerDetailsAddress)}</Form.Label>
						<Form.Control
							name='phone'
							type='text'
							onChange={handleChange}
							value={customerDetailsAddressForm.phone}
							data-testid='customerDetailsAddressForm-phone'
							disabled={readOnlyForm}
						></Form.Control>
					</Form.Group>

					<Form.Group controlId='email'>
						<Form.Label>{t('email', tPrefixCustomerDetailsAddress)}</Form.Label>
						<Form.Control
							name='email'
							type='email'
							onChange={handleChange}
							value={customerDetailsAddressForm.email}
							data-testid='customerDetailsAddressForm-email'
							disabled={readOnlyForm}
						></Form.Control>
					</Form.Group>

					<Form.Group controlId='fax'>
						<Form.Label>{t('fax', tPrefixCustomerDetailsAddress)}</Form.Label>
						<Form.Control
							name='fax'
							type='text'
							onChange={handleChange}
							value={customerDetailsAddressForm.fax}
							data-testid='customerDetailsAddressForm-fax'
							disabled={readOnlyForm}
						></Form.Control>
					</Form.Group>
				</Col>
			</Row>
		</div>
	)
}
