import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { Optional } from 'ui/components/common/badge'
import {
	Badge,
	BadgeError,
	BadgeSuccess,
	BadgeWarning,
} from 'ui/components/common/badge/Badge'
import { Recommended } from 'ui/components/common/badge/Recommended'
import { Required } from 'ui/components/common/badge/Required'
import { StackedItem } from 'ui/components/form/StackedItem'
import { t } from 'ui/components/i18n/i18n'
import { Spacer } from 'ui/components/layout/spacer/Spacer'
import { l } from 'ui/lib/lodashImports'
import { IStateComponentExamples } from 'ui/state/sosComponentExamples'
import { Colors } from 'ui/theme/theme'
import { Section } from './Section'

const colorOptions: Array<Colors> = [
	'lightGray',
	'blue',
	'darkBlue',
	'lightBlue',
	'red',
	'green',
	'orange',
	'purple',
	'darkPurple',
	'darkRed',
	'lightRed',
	'greenBlue',
	'persian',
	'maroon',
	'teal',
	'white',
]

export const SectionBadges: FC = (props: {
	stateComponentExamples: IStateComponentExamples
}) => {
	return (
		<Section
			title={t('page.componentExamples.badges')}
			stateComponentExamples={props.stateComponentExamples}
			name='badges'
		>
			<StackedItem label='Badge Colors' hideOptional={true}>
				{l.map(colorOptions, (c, cIdx) => (
					<React.Fragment key={cIdx}>
						<Badge color={c}>Color: {c}</Badge>
					</React.Fragment>
				))}
			</StackedItem>

			<Spacer />

			<StackedItem label='Common Badges' hideOptional={true}>
				<BadgeError />
				<BadgeSuccess />
				<BadgeWarning />
			</StackedItem>

			<Spacer />

			<StackedItem label='Form Badges' hideOptional={true}>
				<Required />
				<Recommended />
				<Optional />
			</StackedItem>
		</Section>
	)
}
