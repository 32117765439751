import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { useAnimationFrame } from 'ui/components/hooks/useAnimationFrame'
import styles from './InfiniteLoader.module.scss'

export const InfiniteLoader: FC = (props: {
	isLoading?: boolean
	isPreLoading?: boolean
	testId?: string
}) => {
	return (
		<div className={styles.infiniteLoader}>
			{(props.isLoading || props.isPreLoading) && (
				<InfiniteLoaderInner
					isLoading={props.isPreLoading}
					isPreLoading={props.isPreLoading}
					testId={props.testId}
				/>
			)}
		</div>
	)
}

const InfiniteLoaderInner: FC = (props: {
	isLoading?: boolean
	isPreLoading?: boolean
	testId: string
}) => {
	const elapsed = useAnimationFrame()

	let y = 0
	const delay = 250
	if (elapsed > delay) {
		const count = (elapsed - delay) / 120
		y = (1 - 1 / (count / 5 + 1)) * 100
	}

	let opacity = y / 20
	if (opacity > 1) {
		opacity = 1
	}

	if (props.isLoading) {
		if (y < 2.5) {
			y = 2.5
		}
	}
	if (props.isPreLoading) {
		y = y * 0.025
	}

	return (
		<div>
			<div
				className={styles.infiniteLoaderBar}
				style={{ width: y + '%', opacity: opacity }}
				data-testid={props.testId}
			/>
		</div>
	)
}
