import React, { useState } from 'react'
import { sosToast } from 'common/components/toast'
import { apiBroker, apiShipments } from 'ui/api'
import { FC } from 'ui/FunctionalComponent'
import { Modal } from 'ui/components/common/modal'
import { OkCancelButtons } from 'ui/components/common/okCancelButtons'
import { FormStackedPillSwitch, IFormData } from 'ui/components/form'
import { t, tString } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight'
import { Spacer } from 'ui/components/layout/spacer'
import { l } from 'ui/lib/lodashImports'
import * as classes from './UnbookCarrierModal.module.scss'
import { Center } from 'ui/components/layout/center'
import { sosShipmentProfileShipper } from 'ui/pages/shipment-profile/shipper'
import { RateBookingDetails } from 'ui/pages/shipment-profile/shared/functions/rateBookingDetailsTypes'
import { sosShipmentProfileBroker } from 'ui/pages/shipment-profile/broker'

interface UnbookingCarrierForm {
	unbookingOption: 'electronically' | 'manually'
}

const defaultUnbookCarrierForm: UnbookingCarrierForm = {
	unbookingOption: 'electronically',
}

const spacerHeight = '10px'
const apiCallName = 'unbooking'

export const UnbookCarrierModal: FC = (props: {
	isModalOpen: boolean
	onModalClose: () => void
	tPrefix: string
	rateBookingDetails: RateBookingDetails
	setIsNotifyProviderModalOpen: React.Dispatch<React.SetStateAction<boolean>>
	setIsBooking: React.Dispatch<React.SetStateAction<boolean>>
}) => {
	const {
		isModalOpen,
		onModalClose,
		tPrefix,
		rateBookingDetails,
		setIsNotifyProviderModalOpen,
		setIsBooking,
	} = props

	const tPrefixModal = tPrefix + '.modalUnbookCarrier'

	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [unbookingCarrierSaveForm, setUnbookingCarrierSaveForm] = useState<
		UnbookingCarrierForm
	>(defaultUnbookCarrierForm)

	const onUnbook = async (): Promise<void> => {
		setIsLoading(true)
		setIsBooking(false)

		const method =
			unbookingCarrierSaveForm.unbookingOption === 'electronically'
				? 'api'
				: 'manual'

		if (rateBookingDetails.isBroker) {
			sosShipmentProfileBroker.setRunningApiCall(apiCallName)

			const unbookResult = await apiBroker.brokerUnbook(() => {},
			rateBookingDetails.shipmentId)

			if (unbookResult.data) {
				sosShipmentProfileBroker.setShipmentReference(unbookResult.data)

				if (method === 'manual') {
					setIsNotifyProviderModalOpen(true)
				}

				onClose()
			} else if (unbookResult.error) {
				sosToast.sendApiErrorResponseToast(
					unbookResult,
					tString(`${apiCallName}Error`, tPrefixModal),
				)
			}

			sosShipmentProfileBroker.setRunningApiCall(apiCallName, false)
		} else {
			const unbookResult = await apiShipments.unbookShipment(() => {},
			rateBookingDetails.shipmentId)

			if (unbookResult.data) {
				sosShipmentProfileShipper.setShipmentReference(unbookResult.data)

				if (method === 'manual') {
					setIsNotifyProviderModalOpen(true)
				}

				onClose()
			} else if (unbookResult.error) {
				sosToast.sendApiErrorResponseToast(
					unbookResult,
					tString('unbookingShipmentError', tPrefix),
				)
			}
		}

		setIsLoading(false)
	}

	const onClose = (): void => {
		onModalClose()
	}

	const unbookingForm: IFormData<UnbookingCarrierForm> = {
		form: unbookingCarrierSaveForm,
		metadata: {
			unbookingOption: {
				options: [
					{
						value: 'electronically',
						label: tString('electronically', tPrefixModal),
					},
					{ value: 'manually', label: tString('manually', tPrefixModal) },
				],
			},
		},
		onUpdateForm: (field: string, value: any) => {
			const updatedFormData = l.cloneDeep(unbookingCarrierSaveForm)

			updatedFormData[field] = value
			setUnbookingCarrierSaveForm(updatedFormData)
		},
		tPrefix,
	}

	return (
		<Modal
			content={() => (
				<div
					className={classes.unbookCarrierModal}
					data-testid={'unbook-carrier-modal'}
				>
					<Spacer height={spacerHeight} />
					<Center>{t('howDoYouWantToUnbook', tPrefixModal)}</Center>
					<Spacer height={spacerHeight} />
					<FormStackedPillSwitch
						formData={unbookingForm}
						field={'unbookingOption'}
						hideOptional={true}
						className={classes.unbookCarrierFormStackedPillSwitch}
					/>
					<Spacer height={spacerHeight} />
					<AlignRight>
						<OkCancelButtons
							ok={t('unbook', tPrefixModal)}
							onOk={onUnbook}
							okColor='red'
							cancel={t('cancel', tPrefixModal)}
							onCancel={onClose}
							isSpinning={isLoading}
							isValid={true}
							okTestId={'unbook-carrier-modal-unbook-button'}
							cancelTestId={'unbook-carrier-modal-cancel-button'}
						/>
					</AlignRight>
				</div>
			)}
			isOpen={isModalOpen}
			onModalClose={onClose}
			closeModalX={true}
			title={t('confirmUnbooking', tPrefixModal)}
		/>
	)
}
