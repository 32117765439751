import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { Button } from 'ui/components/common/button'
import { Icon, solidIcons } from 'ui/components/common/icon'
import { DocumentViewer } from 'ui/components/common/modal'
import { PaperCard, PaperCardTitle } from 'ui/components/common/paperCard'
import { t, tCurrency, tCurrencyFromCents } from 'ui/components/i18n/i18n'
import { Spacer } from 'ui/components/layout/spacer'
import { IDataTableHeader, KeyValueTable } from 'ui/components/table'
import * as renderers from 'ui/components/table/commonDataCellRenderers'
import { _idx } from 'ui/lib/_idx'
import { l } from 'ui/lib/lodashImports'
import { sosProviderPortalInvoiceProfile } from 'ui/state'
import style from './DisputeDetails.module.scss'

const tPrefix = 'page.providerPortal.disputeDetails'

interface IDisputeDetails {
	disputeNumber?: string
	disputeDate?: string
	invoiceAmount?: string
	correctAmount?: string
	overchargeAmount?: string
	disputePhone?: string
	disputeEmail?: string
	invoicedCurrencyCode?: string
	overchargeCurrencyCode?: string
	correctCurrencyCode?: string
}

const table1Headers: IDataTableHeader<IDisputeDetails>[] = [
	{
		field: 'disputeNumber',
	},
	{
		field: 'disputeDate',
		renderer: renderers.shortDate,
	},
]

const table2Headers: IDataTableHeader<IDisputeDetails>[] = [
	{
		field: 'invoiceAmount',
		currencyCode: 'invoicedCurrencyCode',
		renderer: (invoiceAmount, row) =>
			tCurrency(invoiceAmount, row.invoicedCurrencyCode),
	},
	{
		field: 'correctAmount',
		currencyCode: 'correctCurrencyCode',
		renderer: (invoiceAmount, row) =>
			tCurrencyFromCents(invoiceAmount, row.invoicedCurrencyCode),
	},
	{
		field: 'overchargeAmount',
		currencyCode: 'overchargeCurrencyCode',
		renderer: (invoiceAmount, row) =>
			tCurrencyFromCents(invoiceAmount, row.invoicedCurrencyCode),
	},
]

const table3Headers: IDataTableHeader<IDisputeDetails>[] = [
	{
		field: 'disputePhone',
	},
	// {
	// 	field: 'disputeEmail',
	// 	renderer: (email) => {
	// 		let mailtoLink = 'mailto:' + email
	// 		return <a href={mailtoLink}>{email}</a>
	// 	},
	// },
]
const labelMinWidth = '150px'

export const DisputeDetails: FC = (props: {}) => {
	const invoiceProfileState = sosProviderPortalInvoiceProfile.useSubscribe()
	const { invoiceData } = invoiceProfileState

	const disputeDetailsData: IDisputeDetails = l.pick(
		invoiceData,
		'disputeNumber',
		'disputeDate',
		'invoiceAmount',
		'correctAmount',
		'overchargeAmount',
		'invoicedCurrencyCode',
		'overchargeCurrencyCode',
		'correctCurrencyCode',
	)

	l.assign(disputeDetailsData, {
		disputePhone: '855-737-3444',
	})

	return (
		<div>
			<PaperCard>
				<PaperCardTitle border={true}>{t('title', tPrefix)}</PaperCardTitle>
				<KeyValueTable
					tPrefix={tPrefix}
					headers={table1Headers}
					rawData={disputeDetailsData}
					borderless={true}
					labelMinWidth={labelMinWidth}
				/>
				<Spacer />
				<KeyValueTable
					tPrefix={tPrefix}
					headers={table2Headers}
					rawData={disputeDetailsData}
					borderless={true}
					labelMinWidth={labelMinWidth}
				/>
				<Spacer />
				<p className={style.correctedInvoiceRequested}>
					Corrected Invoice Requested
				</p>
				<KeyValueTable
					tPrefix={tPrefix}
					headers={table3Headers}
					rawData={disputeDetailsData}
					borderless={true}
					labelMinWidth={labelMinWidth}
				/>
				<Spacer />
				{_idx(() => invoiceProfileState.invoiceData.dispute.url) && (
					<>
						<DocumentViewer
							documentUrl={invoiceProfileState.invoiceData.dispute.url}
							onModalClose={() =>
								sosProviderPortalInvoiceProfile.setShowDisputeDocumentViewer(
									false,
								)
							}
							isOpen={invoiceProfileState.showDisputeDocumentViewer}
						/>
						<Button
							color={'blue'}
							onClick={() =>
								sosProviderPortalInvoiceProfile.setShowDisputeDocumentViewer(
									true,
								)
							}
						>
							<Icon icon={solidIcons.faSearch} /> {t(tPrefix + '.viewDispute')}
						</Button>
					</>
				)}
			</PaperCard>
		</div>
	)
}
