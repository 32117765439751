import { apiTypes } from 'ui/api'
import { ICalendarCard } from './DockSchedulerCalendar'
import { DateTime } from 'luxon'
import { log } from 'ui/lib/log'
import { l } from 'ui/lib/lodashImports'
import { getTimeSlotsByDate, ITimeSlot } from 'ui/lib/time/timeSlots'
import { zipToTimezone } from 'ui/lib/addresses'

export const getCalendarCards = (
	appointments: apiTypes.AppointmentResponse[],
	docks: apiTypes.DockResponse[],
	date: string, // date ISO String
	zipcode: string,
): { cardRows: ICalendarCard[][]; hourStarts: number[] } => {
	const start = Date.now()
	log('calendar-card-calculation-timing', 'starting')
	const cardRows: ICalendarCard[][] = []
	const hourStarts: number[] = []
	// ridiculous starting values ensure first time slot will overwrite them
	let minHourStart = 24
	let maxHourEnd = -1

	const timezone = zipToTimezone(zipcode)
	docks.forEach((dock) => {
		l.forEach(dock.schedule?.rules, (rule) => {
			const timeSlotsFromRule: ITimeSlot[] = getTimeSlotsByDate(
				rule,
				DateTime.fromFormat(date, 'yyyy-MM-dd').setZone(timezone).toISO(),
			)
			timeSlotsFromRule.forEach((timeSlotFromRule) => {
				const timeSlotFromRuleStartTime: DateTime = DateTime.fromISO(
					timeSlotFromRule.startTime,
				)
				if (timeSlotFromRuleStartTime.get('hour') < minHourStart) {
					minHourStart = timeSlotFromRuleStartTime.get('hour')
				}
				const timeSlotFromRuleEndTime: DateTime = timeSlotFromRuleStartTime.plus(
					{
						minutes: timeSlotFromRule.durationMinutes,
					},
				)

				const hourEnd: number =
					timeSlotFromRuleEndTime.get('hour') +
					(timeSlotFromRuleEndTime.get('minute') > 0 ? 1 : 0)
				if (hourEnd > maxHourEnd) {
					maxHourEnd = hourEnd
				}
			})
		})
	})

	const appointmentStatusOrder: {
		[K in apiTypes.AppointmentResponse['status']]: number
	} = {
		arrived: 0,
		loading: 1,
		unloading: 1, // Same sort key is OK
		loaded: 2,
		unloaded: 2, // Same sort key is OK
		'not-arrived': 3,
		departed: 3, // Same sort key is OK
	}

	if (appointments.length) {
		const sortedAppointments: apiTypes.AppointmentResponse[] = l.sortBy(
			appointments,
			[
				(appointment) => appointmentStatusOrder[appointment.status],
				(appointment) =>
					l.find(docks, (dock) => dock.id === appointment.dockId)?.nickName,
			],
		)
		sortedAppointments.forEach((appointment) => {
			const startTime = DateTime.fromISO(appointment.startTime).setZone(
				timezone,
			)
			const hourStart: number =
				startTime.get('hour') + startTime.get('minute') / 60
			if (Math.floor(hourStart) < minHourStart) {
				minHourStart = Math.floor(hourStart)
			}
			const endTime = startTime.plus({ minutes: appointment.scheduledDuration })
			const hourEnd: number = endTime.get('hour') + endTime.get('minute') / 60
			if (Math.ceil(hourEnd) > maxHourEnd) {
				maxHourEnd = Math.ceil(hourEnd)
			}
			let cardInserted = false
			const cardToInsert: ICalendarCard = {
				appointmentId: appointment.id,
				identifier: appointment.shipmentInfo?.proNumber,
				appointmentStatus: appointment.status,
				carrier: appointment.trailerInfo?.carrier,
				mode: appointment.shipmentInfo?.mode,
				flow: appointment.stopType,
				startHours: startTime.get('hour') + startTime.get('minute') / 60,
				durationMinutes: appointment.scheduledDuration,
				equipmentType: appointment.shipmentInfo?.equipmentType,
				dockName: appointment.dockId
					? l.find(docks, (dock) => dock.id === appointment.dockId)?.nickName
					: null,
				otherCompaniesOnAppointment:
					appointment.shipmentInfo?.otherCompanyNamesOnShipment || [],
			}
			for (let i = 0; i < cardRows.length; i++) {
				const cardRow = cardRows[i]
				for (let j = 0; j < cardRow.length; j++) {
					const card = cardRow[j]
					const cardEndHours = card.startHours + card.durationMinutes / 60
					if (cardEndHours <= hourStart) {
						if (j === cardRow.length - 1) {
							// the last card currently in the row is before the card that we are inserting, just push the card to the end of the row
							cardRow.push(cardToInsert)
							cardInserted = true
							break
						} else {
							// this card ends before the one that we want to insert starts, move on
							continue
						}
					} else if (card.startHours >= hourEnd) {
						// this card starts after the one that we want to insert ends, insert our current one before this card
						cardRow.splice(j, 0, cardToInsert)
						cardInserted = true
						break
					} else {
						// this card overlaps with the one that we want to insert, move to the next row
						break
					}
				}
				if (cardInserted) {
					break
				}
			}
			if (!cardInserted) {
				// couldn't find space in any previous row, make a new row
				cardRows.push([cardToInsert])
			}
		})
	}
	if (docks.length > 0 || appointments.length > 0) {
		for (let i = minHourStart; i < maxHourEnd; i++) {
			hourStarts.push(i)
		}
	}
	// create blank slots for where there are no appointment slots
	cardRows.forEach((cardRow) => {
		let previousSectionEndHour: number = hourStarts[0]

		for (let i = 0; i < cardRow.length; i++) {
			const card: ICalendarCard = cardRow[i]
			if (card.startHours > previousSectionEndHour) {
				// there is a space between the previous end and the current beginning, insert an empty slot
				cardRow.splice(i, 0, {
					startHours: previousSectionEndHour,
					durationMinutes: (card.startHours - previousSectionEndHour) * 60,
					appointmentId: null,
					identifier: null,
					appointmentStatus: null,
					carrier: null,
					mode: null,
					flow: null,
					equipmentType: null,
					dockName: null,
					otherCompaniesOnAppointment: null,
				})
				i++ // so we do not iterate over the same element again after we splice
			}
			previousSectionEndHour = card.startHours + card.durationMinutes / 60
		}
		if (previousSectionEndHour < hourStarts[hourStarts.length - 1] + 1) {
			cardRow.push({
				startHours: previousSectionEndHour,
				durationMinutes:
					(hourStarts[hourStarts.length - 1] + 1 - previousSectionEndHour) * 60,
				appointmentId: null,
				identifier: null,
				appointmentStatus: null,
				carrier: null,
				mode: null,
				flow: null,
				equipmentType: null,
				dockName: null,
				otherCompaniesOnAppointment: null,
			})
		}
	})

	log(
		'calendar-card-calculation-timing',
		`finshed, took ${Date.now() - start} ms`,
	)
	return { cardRows, hourStarts }
}
