import { FC } from 'app/FunctionalComponent'
import React, { ReactNode } from 'react'
import { fireAndForget } from 'ui/lib/async/fireAndForget'
import { l } from 'ui/lib/lodashImports'
import { sosScriptLoader } from 'ui/state/'
import { IScriptLoaderInfo } from 'ui/state/sosScriptLoader'
import { InfiniteLoader } from '../loader/InfiniteLoader'

export const ScriptLoader: FC = (props: {
	href: string | string[]
	render: () => ReactNode
}) => {
	const state = sosScriptLoader.useSubscribe()

	const states: IScriptLoaderInfo[] = []
	l.forEach(l.toArray(props.href), (c) => {
		states.push(sosScriptLoader.findScript(state.scripts, c))
	})

	React.useEffect(() => {
		l.forEach(l.toArray(props.href), (c) => {
			fireAndForget(async () => {
				await sosScriptLoader.loadScript(c)
			}, `loadingScript ${c}`)
		})
	}, [props.href])

	const errorState = l.find(states, (c) => c && c.state === 'error')
	if (errorState) {
		return <div>Error loading script {errorState.href}</div>
	}

	if (l.every(states, (c) => c && c.state === 'loaded')) {
		return <div>{props.render()}</div>
	}

	return (
		<div>
			<InfiniteLoader isLoading={true} />
		</div>
	)
}
