import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { apiTypes } from 'ui/api'
import { Autocomplete } from 'ui/components/common/autocomplete'
import { ISelectOptions } from 'ui/components/common/select'
import { tBillToParty } from 'ui/components/i18n/commonTranslations'
import { Col, Row } from 'react-bootstrap'
import classes from './BillTo.module.scss'
import newQuoteParcelClasses from '../NewQuoteParcel.module.scss'
import { t } from 'ui/components/i18n/i18n'

const tPrefix = 'page.newQuote.parcel.billTo'

const selections: apiTypes.BillTo['billToThisParty'][] = [
	'shipper',
	'consignee',
	'thirdParty',
]

export const BillToDropdown: FC = (props: {
	onUpdate: (view: apiTypes.BillTo['billToThisParty'] | string) => void
	value: apiTypes.BillTo['billToThisParty']
	isReadOnly?: boolean
}) => {
	return (
		<Row
			className={`${newQuoteParcelClasses.newQuoteParcelCol} ${newQuoteParcelClasses.locationDropDownNoMargin}`}
		>
			<Col>
				<Row className={classes.billToLabel}>{t('cardTitle', tPrefix)}</Row>
				<Row className={classes.billToDropdown}>
					<Autocomplete
						options={selections.map(
							(value): ISelectOptions => ({
								value,
								label: tBillToParty(value),
							}),
						)}
						onChange={(view) => props.onUpdate(view)}
						u
						value={props.value}
						type='select'
						isClearable={false}
						allowEmptyValue={false}
						testId='bill-to-this-party-selector'
						readOnly={props.isReadOnly}
					/>
				</Row>
			</Col>
		</Row>
	)
}
