import { IFormMetadataCollection } from 'ui/components/form/IFormMetadata'
import { classCodes } from 'ui/data/classCodes'

export interface IQuickRateForm {
	locationParentOrganizationId: string
	destinationZip: string
	weight: string
	classCode: string
	handlingUnits: string
	length: string
	width: string
	height: string
	pieces: string
}

export const quickRateFormMetadata: IFormMetadataCollection<IQuickRateForm> = {
	locationParentOrganizationId: {
		label: 'quickRateForm.location',
		options: [],
		required: true,
	},
	destinationZip: {
		label: 'quickRateForm.destinationZip',
		required: true,
	},
	weight: {
		label: 'quickRateForm.weightLbs',
		required: true,
		mustBeAnInteger: true,
	},
	classCode: {
		label: 'quickRateForm.class',
		options: classCodes,
	},
	handlingUnits: {
		label: 'quickRateForm.handlingUnits',
		required: true,
		mustBeAnInteger: true,
		mustBeGreaterThan: 0,
	},
	length: {
		label: 'quickRateForm.lengthIn',
		required: true,
		mustBeAnInteger: true,
	},
	width: {
		label: 'quickRateForm.widthIn',
		required: true,
		mustBeAnInteger: true,
	},
	height: {
		label: 'quickRateForm.heightIn',
		required: true,
		mustBeAnInteger: true,
	},
	pieces: {
		label: 'quickRateForm.pieces',
		required: true,
		mustBeAnInteger: true,
		mustBeGreaterThan: 0,
	},
}
