import { NotFound } from 'app/AppRouter'
import { FC } from 'app/FunctionalComponent'
import React from 'react'
import {
	IRoute,
	LoginModeType,
	SimpleRouter,
} from 'ui/components/common/router'
import { AccountingCodeCatalogPage } from '../accounting-code-catalog/AccountingCodeCatalogPage'
import { ManageAccountingCodeCatalogsPage } from '../accounting-code-catalog/ManageAccountingCodeCatalogsPage'
import { AddressBookPage } from '../address-book/AddressBookPage'
import { ManageAddressBooksPage } from '../address-book/ManageAddressBooksPage'
import {
	ManagePackageCatalogsPage,
	PackageCatalogPage,
} from '../package-catalog'
import {
	ManageProductCatalogsPage,
	ProductCatalogPage,
} from '../product-catalog'
import { BooksPage } from './BooksPage'

export const booksRoutes: IRoute[] = [
	{
		isDefault: true,
		urls: ['/404'],
		renderer: () => <NotFound />,
		anonymous: true,
	},
	{
		urls: ['/books-v3'],
		renderer: () => <BooksPage />,
	},
	{
		urls: ['/books-v3/package-catalog-v3/:bookId'],
		renderer: (_, { bookId }) => <PackageCatalogPage bookId={bookId} />,
	},
	{
		urls: ['/books-v3/product-catalog-v3/:bookId'],
		renderer: (_, { bookId }) => <ProductCatalogPage bookId={bookId} />,
	},
	{
		urls: ['/books-v3/manage-package-catalogs-v3'],
		renderer: () => <ManagePackageCatalogsPage />,
	},
	{
		urls: ['/books-v3/manage-product-catalogs-v3'],
		renderer: () => <ManageProductCatalogsPage />,
	},
	{
		urls: ['/books-v3/accounting-code-catalog-v3/:bookId'],
		renderer: (_, { bookId }) => <AccountingCodeCatalogPage bookId={bookId} />,
	},
	{
		urls: ['/books-v3/address-book-v3/:bookId'],
		renderer: (_, { bookId }) => <AddressBookPage bookId={bookId} />,
	},
	{
		urls: ['/books-v3/manage-accounting-code-catalogs-v3'],
		renderer: () => <ManageAccountingCodeCatalogsPage />,
	},
	{
		urls: ['/books-v3/manage-address-books-v3'],
		renderer: () => <ManageAddressBooksPage />,
	},
	{
		urls: ['/books-v3/manage-product-catalogs-v3'],
		renderer: () => <ManageProductCatalogsPage />,
	},
]

export const RouterBooks: FC = (props: {
	currentLocation: string
	loginMode: LoginModeType
}) => {
	return (
		<SimpleRouter
			routes={booksRoutes}
			currentLocation={props.currentLocation}
			loginMode={props.loginMode}
		/>
	)
}
