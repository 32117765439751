import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { Card } from 'ui/components/common/card/Card'
import { ValidatedSubmitButton } from 'ui/components/common/validated-submit-button'
import { FormStacked } from 'ui/components/form/FormStacked'
import { FormStackedCustom } from 'ui/components/form/FormStackedCustom'
import { FormStackedLayout } from 'ui/components/form/FormStackedLayout'
import { FormStackedSelect } from 'ui/components/form/FormStackedSelect'
import { FormStackedTextInput } from 'ui/components/form/FormStackedTextInput'
import { FormTextInput } from 'ui/components/form/FormTextInput'
import { IFormData } from 'ui/components/form/IFormData'
import { t } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight/AlignRight'
import { FlexRow } from 'ui/components/layout/flexRow/FlexRow'
import { IQuickRateForm, quickRateFormMetadata } from 'ui/forms/formQuickRate'
import { l } from 'ui/lib/lodashImports'
import { formValidator } from 'ui/lib/validation/'
import { sosQuickRateTms3 } from 'ui/state/'

const FormLengthWidthHeight: FC = (props: {
	formData: IFormData<IQuickRateForm>
}) => {
	const width = '32px'
	return (
		<FlexRow>
			<div>
				<FormTextInput
					width={width}
					form={props.formData.form}
					field={'length'}
					onUpdateForm={sosQuickRateTms3.updateQuickRateForm}
				/>
			</div>
			<div>in. x</div>
			<div>
				<FormTextInput
					width={width}
					form={props.formData.form}
					field={'width'}
					onUpdateForm={props.formData.onUpdateForm}
				/>
			</div>
			<div>in. x</div>
			<div>
				<FormTextInput
					width={width}
					form={props.formData.form}
					field={'height'}
					onUpdateForm={props.formData.onUpdateForm}
				/>
			</div>
			<div>in.</div>
		</FlexRow>
	)
}

const QuickRateForm: FC = (props: {
	locations: any[]
	form: IQuickRateForm
}) => {
	const formData: IFormData<IQuickRateForm> = {
		form: props.form,
		metadata: quickRateFormMetadata,
		onUpdateForm: sosQuickRateTms3.updateQuickRateForm,
		tPrefix: 'page.quickRate.quickRateForm',
	}

	const validationResult = formValidator.validateForm(
		formData.form,
		formData.metadata,
	)

	const locations = l.map(props.locations, (c) => ({
		label: c.name,
		value: c.id,
	}))

	return (
		<Card title={t('page.quickRate.cardTitle')} color='blue'>
			<FormStacked>
				<FormStackedLayout>
					<FormStackedSelect
						formData={formData}
						field='locationParentOrganizationId'
						options={locations}
					/>

					<FormStackedTextInput formData={formData} field='destinationZip' />

					<FormStackedTextInput formData={formData} field='weight' />

					<FormStackedSelect formData={formData} field='classCode' />

					<FormStackedTextInput formData={formData} field='handlingUnits' />

					<FormStackedCustom
						formData={formData}
						label={t('page.quickRate.dimensions')}
						fields={['length', 'width', 'height']}
					>
						<FormLengthWidthHeight formData={formData} />
					</FormStackedCustom>

					<FormStackedTextInput formData={formData} field='pieces' />
				</FormStackedLayout>

				<AlignRight>
					<ValidatedSubmitButton
						testId='submitQuickRateForm'
						onClick={sosQuickRateTms3.submitQuickRateForm}
						isValid={validationResult.isValid}
					>
						{t('page.quickRate.getRatesButton')}
					</ValidatedSubmitButton>
				</AlignRight>
			</FormStacked>
		</Card>
	)
}

export { QuickRateForm }
