import { createLazySos2 } from 'ui/lib/state/sos2/sos2'
import { apiTypes, apiDockScheduler, apiShipments } from 'ui/api'
import { IRequestState } from 'ui/api/requestState'
import { DateTime } from 'luxon'
import { l } from 'ui/lib/lodashImports'
import {
	IAppointmentDetailsCheckInForm,
	IAppointmentDetailsLoadStartForm,
	IAppointmentDetailsLoadEndForm,
	IAppointmentDetailsCheckOutForm,
	IAppointmentDetailsGeneralInformation,
	getLastAppointmentAuditLogsForStatusTransition,
	createGeneralInfoForm,
	createCheckInForm,
	createLoadStartForm,
	createLoadEndForm,
	createCheckOutForm,
} from '../appointment-details/DockSchedulerAppointmentDetailsForms'
import { sosDockScheduler } from '.'
import { tString, tArgz } from 'ui/components/i18n/i18n'
import { _idx } from 'ui/lib'
import { tPrefixDockSchedulerAppointmentDetails } from 'ui/pages/dock-scheduler/appointment-details'
import { elasticSearchBuilder } from 'ui/lib/elasticSearch'
import { fetchAppointmentsForLocationOnDay } from './appointmentFetchUtils'
import { findFormDataErrors, IFormData } from 'ui/components/form'
import {
	DockRestrictionCategory,
	validateDockWithEquipmentTypeFlowMode,
} from '../components/dockSchedulerTimeSlotsUtils'
import {
	tEquipmentType,
	tStopType,
	tMode,
} from 'ui/components/i18n/commonTranslations'
import { zipToTimezone } from 'ui/lib/addresses'
import { IScheduleTimeSlot } from '../components/DockSchedulerTimeSlots'

export type AppointmentDetailCard =
	| 'generalInformation'
	| 'checkIn'
	| 'loadStart'
	| 'loadEnd'
	| 'checkOut'

export interface IStateDockSchedulerAppointmentDetails {
	appointment: apiTypes.AppointmentResponse
	shipment: apiTypes.ShipmentResponse
	generalInfoForm: IAppointmentDetailsGeneralInformation
	checkInForm: IAppointmentDetailsCheckInForm
	checkInError: string
	loadStartForm: IAppointmentDetailsLoadStartForm
	loadEndForm: IAppointmentDetailsLoadEndForm
	checkOutForm: IAppointmentDetailsCheckOutForm
	checkOutErrors: Array<keyof IAppointmentDetailsCheckOutForm>
	submitting: boolean
	editingStage: AppointmentDetailCard
	fetchError: string
	editAppointmentModalOpen: boolean
	existingOtherAppointmentsForEditing: apiTypes.AppointmentResponse[]
	fetchingOtherAppointments: boolean
	dateOnEditAppointmentModal: string // ISO DateString
	selectedTimeSlotOnEditAppointmentModal: IScheduleTimeSlot
	invalidTimeslotReason: string
	deletingAppointment: boolean
}

export const getSos = createLazySos2<IStateDockSchedulerAppointmentDetails>(
	'sosDockSchedulerAppointmentDetails',
	1,
	() => ({
		appointment: {
			default: null,
			localStorage: false,
		},
		shipment: {
			default: null,
			localStorage: false,
		},
		generalInfoForm: {
			default: {
				slid: null,
				dateTime: null,
				dock: null,
				carrier: null,
				mode: null,
				stopType: null,
				equipmentType: null,
				proNumber: null,
				bolNumbers: [],
				poNumbers: [],
				soNumbers: [],
				handlingUnits: null,
				handlingUnitTypes: [],
				expectedWeight: null,
			},
			localStorage: false,
		},
		checkInForm: {
			default: {
				dock: null,
				tractorNumber: null,
				trailerNumber: null,
				weight: null,
				driverName: null,
				checkInComments: null,
				checkedLicense: null,
				checkInDateTime: null,
				checkedInBy: null,
			},
			localStorage: false,
		},
		checkInError: { default: null, localStorage: false },
		loadStartForm: {
			default: {
				loadedBy: null,
				loadStartComments: null,
				loadStartDateTime: null,
				loadStartRecordedBy: null,
			},
			localStorage: false,
		},
		loadEndForm: {
			default: {
				handlingUnitsLoaded: null,
				cargoWeight: null,
				sealNumber: null,
				loadEndComments: null,
				loadEndDateTime: null,
				loadEndRecordedBy: null,
			},
			localStorage: false,
		},
		checkOutForm: {
			default: {
				tractorNumber: null,
				trailerNumber: null,
				weight: null,
				driverName: null,
				checkOutComments: null,
				checkedLicense: null,
				checkOutDateTime: null,
				duration: null,
				checkedOutBy: null,
			},
			localStorage: false,
		},
		checkOutErrors: { default: [], localStorage: false },
		submitting: { default: false, localStorage: false },
		editingStage: { default: null, localStorage: false },
		fetchError: { default: null, localStorage: false },
		editAppointmentModalOpen: { default: false, localStorage: false },
		existingOtherAppointmentsForEditing: { default: [], localStorage: false },
		fetchingOtherAppointments: { default: false, localStorage: false },
		dateOnEditAppointmentModal: { default: null, localStorage: false },
		selectedTimeSlotOnEditAppointmentModal: {
			default: null,
			localStorage: false,
		},
		invalidTimeslotReason: { default: null, localStorage: false },
		deletingAppointment: { default: false, localStorage: false },
	}),
)

export function updateCheckIn(
	changes: Partial<IAppointmentDetailsCheckInForm>,
): void {
	getSos().change((ds) => {
		l.assign(ds.checkInForm, changes)
	})
}

export function updateLoadStart(
	changes: Partial<IAppointmentDetailsLoadStartForm>,
): void {
	getSos().change((ds) => {
		l.assign(ds.loadStartForm, changes)
	})
}

export function updateLoadEnd(
	changes: Partial<IAppointmentDetailsLoadEndForm>,
): void {
	getSos().change((ds) => {
		l.assign(ds.loadEndForm, changes)
	})
}

export function updateCheckOut(
	changes: Partial<IAppointmentDetailsCheckOutForm>,
): void {
	getSos().change((ds) => {
		l.assign(ds.checkOutForm, changes)
	})
}

export async function submitCheckIn(): Promise<void> {
	const { appointment, checkInForm } = getSos().getState()
	const dock = l.find(
		sosDockScheduler.getSos().getState().docks,
		(dock) => dock.id === checkInForm.dock,
	)
	const dockInvalidCategory: DockRestrictionCategory = validateDockWithEquipmentTypeFlowMode(
		dock,
		appointment.shipmentInfo.equipmentType,
		appointment.stopType,
		appointment.shipmentInfo.mode,
	)
	if (!dockInvalidCategory) {
		await sendCheckInToApi()
	} else {
		let error: string
		if (dockInvalidCategory === 'equipmentType') {
			error = tEquipmentType(appointment.shipmentInfo.equipmentType)
		} else if (dockInvalidCategory === 'flow') {
			error = tStopType(appointment.stopType)
		} else if (dockInvalidCategory === 'mode') {
			error = tMode(appointment.shipmentInfo.mode)
		}
		setCheckInError(
			tArgz(
				'checkInError',
				{ dockName: dock.nickName, error },
				tPrefixDockSchedulerAppointmentDetails,
			),
		)
	}
}

export function setCheckInError(error: string): void {
	getSos().change((ds) => {
		ds.checkInError = error
	})
}

export async function sendCheckInToApi(): Promise<void> {
	const state = getSos().getState()
	const form = state.checkInForm
	const updatedAppointment: apiTypes.AppointmentRequest = l.cloneDeep(
		state.appointment,
	)
	getSos().change((ds) => {
		ds.submitting = true
	})
	if (!updatedAppointment.trailerInfo) {
		updatedAppointment.trailerInfo = {}
	}
	updatedAppointment.dockId = form.dock
	updatedAppointment.trailerInfo.tractorNumber = form.tractorNumber
	updatedAppointment.trailerInfo.trailerNumber = form.trailerNumber
	updatedAppointment.arrivalWeight = form.weight
	updatedAppointment.trailerInfo.driverName = form.driverName
	updatedAppointment.trailerInfo.driversLicenseCheck = form.checkedLicense
	let appointmentEventUpdateId: string
	let appointmentEventUpdateRequest: apiTypes.AppointmentEventRequest
	if (updatedAppointment.status === 'not-arrived') {
		updatedAppointment.comment = form.checkInComments
		updatedAppointment.arrivalTime = DateTime.local().toISO()
		updatedAppointment.status = 'arrived'
	} else if (form.checkInComments) {
		// we are editing this stage from a future stage and have updated a comment
		appointmentEventUpdateId = getLastAppointmentAuditLogsForStatusTransition(
			state.appointment,
			['not-arrived'],
			['arrived'],
		).id
		appointmentEventUpdateRequest = {
			comment: form.checkInComments,
		}
	}

	await sendSubmissionToApi(
		state,
		updatedAppointment,
		appointmentEventUpdateId,
		appointmentEventUpdateRequest,
	)
}

export async function submitLoadStart(): Promise<void> {
	getSos().change((ds) => {
		ds.submitting = true
	})
	const state = getSos().getState()
	const form = state.loadStartForm
	const updatedAppointment: apiTypes.AppointmentRequest = l.cloneDeep(
		state.appointment,
	)
	const isPickupStopType = updatedAppointment.stopType === 'pickup'
	if (isPickupStopType) {
		updatedAppointment.loadedBy = form.loadedBy
	} else {
		updatedAppointment.unloadedBy = form.loadedBy
	}

	let appointmentEventUpdateId: string
	let appointmentEventUpdateRequest: apiTypes.AppointmentEventRequest
	if (updatedAppointment.status === 'arrived') {
		updatedAppointment.comment = form.loadStartComments
		updatedAppointment.status = isPickupStopType ? 'loading' : 'unloading'
	} else if (form.loadStartComments) {
		// we are editing this stage from a future stage and have updated a comment
		appointmentEventUpdateId = getLastAppointmentAuditLogsForStatusTransition(
			state.appointment,
			['arrived'],
			['loading', 'unloading'],
		).id
		appointmentEventUpdateRequest = {
			comment: form.loadStartComments,
		}
	}

	await sendSubmissionToApi(
		state,
		updatedAppointment,
		appointmentEventUpdateId,
		appointmentEventUpdateRequest,
	)
}

export async function submitLoadEnd(): Promise<void> {
	getSos().change((ds) => {
		ds.submitting = true
	})
	const state = getSos().getState()
	const form = state.loadEndForm
	const updatedAppointment: apiTypes.AppointmentRequest = l.cloneDeep(
		state.appointment,
	)
	if (!updatedAppointment.shipmentInfo) {
		updatedAppointment.shipmentInfo = {}
	}
	updatedAppointment.shipmentInfo.handlingUnits = form.handlingUnitsLoaded
	updatedAppointment.shipmentInfo.expectedWeight = form.cargoWeight
	updatedAppointment.trailerInfo.sealNumber = form.sealNumber

	let appointmentEventUpdateId: string
	let appointmentEventUpdateRequest: apiTypes.AppointmentEventRequest
	if (
		updatedAppointment.status === 'loading' ||
		updatedAppointment.status === 'unloading'
	) {
		const isPickupStopType = updatedAppointment.stopType === 'pickup'
		updatedAppointment.comment = form.loadEndComments
		updatedAppointment.status = isPickupStopType ? 'loaded' : 'unloaded'
	} else if (form.loadEndComments) {
		// we are editing this stage from a future stage and have updated a comment
		appointmentEventUpdateId = getLastAppointmentAuditLogsForStatusTransition(
			state.appointment,
			['loading', 'unloading'],
			['loaded', 'unloaded'],
		).id
		appointmentEventUpdateRequest = {
			comment: form.loadEndComments,
		}
	}

	await sendSubmissionToApi(
		state,
		updatedAppointment,
		appointmentEventUpdateId,
		appointmentEventUpdateRequest,
	)
}

export async function submitCheckOut(
	checkOutFormData: IFormData<IAppointmentDetailsCheckOutForm>,
): Promise<void> {
	const errors = findFormDataErrors(checkOutFormData)
	if (!errors.length) {
		await sendCheckOutToApi()
	} else {
		setCheckOutErrors(errors.map((c) => c.field))
	}
}

export function setCheckOutErrors(
	errors: Array<keyof IAppointmentDetailsCheckOutForm>,
): void {
	getSos().change((ds) => {
		ds.checkOutErrors = l.uniq(errors)
		ds.submitting = errors.length === 0 ? false : ds.submitting
	})
}

export async function sendCheckOutToApi(): Promise<void> {
	getSos().change((ds) => {
		ds.submitting = true
	})
	const state = getSos().getState()
	const form = state.checkOutForm
	const updatedAppointment: apiTypes.AppointmentRequest = l.cloneDeep(
		state.appointment,
	)
	updatedAppointment.trailerInfo.tractorNumber = form.tractorNumber
	updatedAppointment.trailerInfo.trailerNumber = form.trailerNumber
	updatedAppointment.departureWeight = form.weight
	updatedAppointment.trailerInfo.driverName = form.driverName
	let appointmentEventUpdateId: string
	let appointmentEventUpdateRequest: apiTypes.AppointmentEventRequest
	if (
		updatedAppointment.status === 'loaded' ||
		updatedAppointment.status === 'unloaded'
	) {
		updatedAppointment.comment = form.checkOutComments
		updatedAppointment.status = 'departed'
	} else if (form.checkOutComments) {
		// we are editing this stage from a future stage and have updated a comment
		appointmentEventUpdateId = getLastAppointmentAuditLogsForStatusTransition(
			state.appointment,
			['loaded', 'unloaded'],
			['departed'],
		).id
		appointmentEventUpdateRequest = {
			comment: form.checkOutComments,
		}
	}
	await sendSubmissionToApi(
		state,
		updatedAppointment,
		appointmentEventUpdateId,
		appointmentEventUpdateRequest,
	)
}

async function sendSubmissionToApi(
	state: IStateDockSchedulerAppointmentDetails,
	updatedAppointment: apiTypes.AppointmentRequest,
	appointmentEventUpdateId?: string,
	appointmentEventUpdateRequest?: apiTypes.AppointmentEventRequest,
): Promise<void> {
	if (appointmentEventUpdateId) {
		await apiDockScheduler.updateAppointmentEvent(
			() => {},
			state.appointment.id,
			appointmentEventUpdateId,
			appointmentEventUpdateRequest,
		)
	}

	const appointmentResponse = await apiDockScheduler.updateAppointment(
		() => {},
		state.appointment.id,
		updatedAppointment,
	)
	if (appointmentResponse.data) {
		setCheckInError(null)
		setCheckOutErrors([])
		getSos().change((ds) => {
			ds.appointment = appointmentResponse.data
			ds.editingStage = null
		})
		updateForms()
	}
}

export async function fetchAppointmentAndShipment(): Promise<void> {
	let appointmentFetchResult: IRequestState<apiTypes.AppointmentResponse>
	let shipmentFetchResult: IRequestState<apiTypes.ShipmentResponse>
	const urlState = sosDockScheduler.getUrlState()
	const { shipmentId } = urlState
	let { appointmentId } = urlState
	const dockSchedulerState = sosDockScheduler.getSos().getState()
	if (!shipmentId && !appointmentId) {
		getSos().change((ds) => {
			ds.fetchError = tString(
				'needAnId',
				tPrefixDockSchedulerAppointmentDetails,
			)
		})
	} else if (!shipmentId) {
		// see if we can find a shipment with the given appointmentId
		const fetches: Promise<IRequestState<any>>[] = [
			apiDockScheduler.fetchAppointment(() => {}, appointmentId),
			apiShipments.fetchShipments(() => {}, {
				nestedFieldQuery: elasticSearchBuilder()
					.andOr([
						['payloads.originStop.metaData.appointmentId', appointmentId],
						['payloads.destinationStop.metaData.appointmentId', appointmentId],
					])
					.toQuery(),
			}),
		]
		const responses = await Promise.all(fetches)
		appointmentFetchResult = responses[0]
		const shipmentQueryResponse: IRequestState<apiTypes.ShipmentListResponse> =
			responses[1]
		const matchingShipment: apiTypes.ShipmentResponse = _idx(
			() => shipmentQueryResponse.data.entities[0],
		)
		if (matchingShipment) {
			shipmentFetchResult = { data: matchingShipment }
		}
	} else if (!appointmentId) {
		// get the appointmentId off the shipment
		shipmentFetchResult = await apiShipments.fetchShipment(
			() => {},
			shipmentId,
			true,
		)
		const locationId: string = dockSchedulerState.currentLocation.id
		const payloadWithLocationOnOrigin = l.find(
			shipmentFetchResult.data.payloads,
			(payload) => payload.originStop.metaData.locationId === locationId,
		)
		if (payloadWithLocationOnOrigin) {
			appointmentId =
				payloadWithLocationOnOrigin.originStop.metaData.appointmentId
		} else {
			const payloadWithLocationOnDestination = l.find(
				shipmentFetchResult.data.payloads,
				(payload) => payload.destinationStop.metaData.locationId === locationId,
			)
			if (payloadWithLocationOnDestination) {
				appointmentId =
					payloadWithLocationOnDestination.destinationStop.metaData
						.appointmentId
			} else {
				getSos().change((ds) => {
					ds.fetchError = tString(
						'noAppointmentFoundOnShipment',
						tPrefixDockSchedulerAppointmentDetails,
					)
				})
			}
		}
		if (appointmentId) {
			appointmentFetchResult = await apiDockScheduler.fetchAppointment(() => {},
			appointmentId)
		}
	} else {
		const fetches: Promise<IRequestState<any>>[] = [
			apiDockScheduler.fetchAppointment(() => {}, appointmentId),
			apiShipments.fetchShipment(() => {}, shipmentId, true),
		]
		const responses = await Promise.all(fetches)
		appointmentFetchResult = responses[0]
		shipmentFetchResult = responses[1]
	}
	if (_idx(() => appointmentFetchResult.data)) {
		getSos().change((ds) => {
			ds.appointment = appointmentFetchResult.data
			ds.shipment = shipmentFetchResult ? shipmentFetchResult.data : null
			ds.fetchError = null
		})
		updateForms()
	}
}

function updateForms(): void {
	const state = getSos().getState()
	getSos().change((ds) => {
		ds.generalInfoForm = createGeneralInfoForm(state)
		ds.checkInForm = createCheckInForm(state)
		ds.loadStartForm = createLoadStartForm(state)
		ds.loadEndForm = createLoadEndForm(state)
		ds.checkOutForm = createCheckOutForm(state)
	})
}

export function setStageEdit(stage: AppointmentDetailCard): void {
	getSos().change((ds) => {
		ds.checkInForm.dock =
			stage === 'checkIn'
				? ds.appointment.dockId
				: l.find(
						sosDockScheduler.getSos().getState().docks,
						(dock) => dock.id === ds.appointment.dockId,
				  ).nickName
		ds.editingStage = stage
	})
}

export async function fetchOtherAppointmentsForCurrentAppointmentReschedule(
	date: string,
): Promise<void> {
	getSos().change((ds) => {
		ds.editAppointmentModalOpen = true
		ds.fetchingOtherAppointments = true
		ds.dateOnEditAppointmentModal = date
		ds.existingOtherAppointmentsForEditing = []
	})
	const appointments: apiTypes.AppointmentResponse[] = await fetchAppointmentsForLocationOnDay(
		sosDockScheduler.getSos().getState().currentLocation.id,
		date,
	)
	const currentAppointmentId = getSos().getState().appointment.id
	l.remove(
		appointments,
		(appointment: apiTypes.AppointmentResponse) =>
			appointment.id === currentAppointmentId,
	)
	getSos().change((ds) => {
		ds.fetchingOtherAppointments = false
		ds.existingOtherAppointmentsForEditing = appointments
	})
}

export function setEditAppointmnetModalOpen(isOpen: boolean): void {
	getSos().change((ds) => {
		ds.editAppointmentModalOpen = isOpen
	})
}

export function changeSelectedTimeSlotForAppointmentUpdate(
	updatedTimeSlot: IScheduleTimeSlot,
): void {
	getSos().change((ds) => {
		ds.selectedTimeSlotOnEditAppointmentModal = l.isEqual(
			updatedTimeSlot,
			ds.selectedTimeSlotOnEditAppointmentModal,
		)
			? null
			: updatedTimeSlot
		ds.invalidTimeslotReason =
			ds.selectedTimeSlotOnEditAppointmentModal?.dockIds.length === 0
				? ds.selectedTimeSlotOnEditAppointmentModal.slotUnavailableReason
				: null
	})
}

export async function updateAppointmentTime(): Promise<void> {
	getSos().change((ds) => {
		ds.submitting = true
	})
	const state = getSos().getState()
	const {
		appointment,
		selectedTimeSlotOnEditAppointmentModal,
		dateOnEditAppointmentModal,
	} = state
	const date = DateTime.fromISO(dateOnEditAppointmentModal)
	const newStartTime = DateTime.fromObject({
		zone: zipToTimezone(
			sosDockScheduler.getSos().getState().currentLocation.defaults
				.defaultDeliveryAddress.address.zip,
		),
		year: date.year,
		month: date.month,
		day: date.day,
		hour: selectedTimeSlotOnEditAppointmentModal.startHours,
		minute: selectedTimeSlotOnEditAppointmentModal.startMinutes,
	})
	const updatedAppointment: apiTypes.AppointmentRequest = l.cloneDeep(
		appointment,
	)
	updatedAppointment.startTime = newStartTime.toISO()
	updatedAppointment.scheduledDuration =
		selectedTimeSlotOnEditAppointmentModal.durationMinutes
	updatedAppointment.comment = state.invalidTimeslotReason
		? `Appointment created with warning: ${state.invalidTimeslotReason}`
		: undefined

	const appointmentResponse = await apiDockScheduler.updateAppointment(
		() => {},
		appointment.id,
		updatedAppointment,
	)
	if (appointmentResponse.data) {
		getSos().change((ds) => {
			ds.submitting = false
			ds.appointment = appointmentResponse.data
			ds.existingOtherAppointmentsForEditing = []
			ds.dateOnEditAppointmentModal = null
			ds.selectedTimeSlotOnEditAppointmentModal = null
			ds.dateOnEditAppointmentModal = null
			ds.editAppointmentModalOpen = false
			ds.invalidTimeslotReason = null
		})
		updateForms()
	}
}

export async function deleteAppointment(): Promise<void> {
	getSos().change((ds) => {
		ds.deletingAppointment = true
	})
	await apiDockScheduler.deleteAppointment(() => {},
	getSos().getState().appointment.id)

	getSos().change((ds) => {
		ds.deletingAppointment = false
	})
	sosDockScheduler.updateSelectedTab('calendar')
}
