import { FC } from 'app/FunctionalComponent'
import React, { useEffect } from 'react'
import {
	Breadcrumb,
	IBreadcrumbEntry,
} from 'ui/components/common/breadcrumb/Breadcrumb'
import { Debug } from 'ui/components/dev/Debug'
import { fireAndForget } from 'ui/lib/async'
import { Layout } from 'ui/pages/layout/Layout'
import { sosAccountingCodeCatalogEntry } from 'ui/state'
import { AccountingCodeCatalogEntryDataTable } from '.'

export const AccountingCodeCatalogPage: FC = (props: { bookId: string }) => {
	const state = sosAccountingCodeCatalogEntry.useSubscribe()

	useEffect(() => {
		fireAndForget(async () => {
			sosAccountingCodeCatalogEntry.navigateToAccountingCodeCatalog({
				bookId: props.bookId,
			})
		}, `navigation to accountingCodeBookCatalog ${props.bookId}`)
	}, [props.bookId])

	useEffect(() => {
		fireAndForget(async () => {
			await sosAccountingCodeCatalogEntry.fetchAccountingCodeCatalogEntries()
			await sosAccountingCodeCatalogEntry.fetchCatalog()
		}, 'fetching accountingCodeBookCatalogs and entries')
	}, [state.bookId])

	const bread: IBreadcrumbEntry[] = [
		{
			t: 'shared.accountingCodeCatalog.manageAccountingCodeCatalogs',
			url: '/books-v3/manage-accounting-code-catalogs',
		},
		{
			label: state.requestAccountingCodeCatalog.data
				? state.requestAccountingCodeCatalog.data.name
				: '',
			url: '',
		},
	]

	return (
		<Layout>
			<Breadcrumb entries={bread} />
			<AccountingCodeCatalogEntryDataTable
				fetchAccountingCodeCatalogEntries={
					state.requestAccountingCodeCatalogEntries
				}
				requestUpdateAccountingCodeCatalogEntry={
					state.requestUpdateAccountingCodeCatalogEntry
				}
				data={state.dataAccountingCodeCatalogEntries}
				dataTableState={state.dataTableAccountingCodeCatalogEntries}
				form={state.accountingCodeCatalogEntryForm}
			/>
			<Debug
				hide={true}
				label='All pages from the accounting code catalog'
				data={state.requestAccountingCodeCatalogEntries}
			/>
		</Layout>
	)
}
