import { FC } from 'app/FunctionalComponent'
import { DateTime } from 'luxon'
import React from 'react'
import { l } from 'ui/lib/lodashImports'
import { ITimeSlot } from 'ui/lib/time/timeSlots'
import * as classes from '../../dock-scheduler/DockScheduler.module.scss'
import { FifteenMinuteSlotBox, RowPositionType } from './FifteenMinuteSlotBox'

export const FifteenMinuteSlotBoxRow: FC = (props: {
	timeSlots: ITimeSlot[]
	rowPosition: RowPositionType
}) => {
	const selectedBoxes: number[] = []
	l.forEach(props.timeSlots, (timeSlot) => {
		const startTime = DateTime.fromISO(timeSlot.startTime)
		const endTime = DateTime.fromISO(timeSlot.endTime)
		const startHour: number = startTime.hour + startTime.minute / 60
		const endHour: number = endTime.hour + endTime.minute / 60

		const startBox = startHour * 4
		const endBox = endHour * 4

		for (let i = startBox; i < endBox; i++) {
			selectedBoxes.push(i)
		}
	})

	const boxes: React.ReactElement[] = []
	boxes.push(
		<FifteenMinuteSlotBox
			rowPosition={props.rowPosition}
			isSelected={l.includes(selectedBoxes, 0)}
			key={0}
		/>,
	)

	const hours = 24
	const quartersPerHour = 4
	const middleBoxCount: number = hours * quartersPerHour - 2 // subtract two for start and end boxes
	for (let i = 1; i <= middleBoxCount; i++) {
		boxes.push(
			<FifteenMinuteSlotBox
				rowPosition={props.rowPosition}
				isSelected={l.includes(selectedBoxes, i)}
				key={i}
			/>,
		)
	}

	boxes.push(
		<FifteenMinuteSlotBox
			rowPosition={props.rowPosition}
			isSelected={l.includes(selectedBoxes, middleBoxCount + 1)}
			key={middleBoxCount + 1}
		/>,
	)

	return <div className={classes.row}>{boxes}</div>
}
