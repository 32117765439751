import { IFormMetadataCollection } from 'ui/components/form/IFormMetadata'

export interface IFormPageConfig {
	configKey: string
	advanced: string
	hidden: string
	required: string
}

export const pageConfigFormMetadata: IFormMetadataCollection<IFormPageConfig> = {
	configKey: {},
	advanced: {},
	hidden: {},
	required: {},
}
