import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { l } from 'ui/lib/lodashImports'
import styles from './ApiExamples.module.scss'
import { JsonValue } from './JsonValue'

export const JsonSection: FC = (props: { json: any; indent: number }) => {
	// if (_.isArray(props.json)) {
	//   return <div>Array!</div>
	// }

	const keys = Object.keys(props.json)

	return (
		<div style={{ marginLeft: '10px' }}>
			{l.map(keys, (cKey, cIdx) => {
				const c = props.json[cKey]
				if (l.isArray(c)) {
					// Empty Array
					if (l.isEmpty(c)) {
						return (
							<div key={cKey}>
								<span className={styles.jsonKey}> {'' + cKey}</span>: []
								{cIdx + 1 < keys.length && ','}
							</div>
						)
					}

					return (
						<div key={cKey}>
							<span className={styles.jsonKey}> {'' + cKey}</span>: [
							<div style={{ marginLeft: '10px' }}>
								{l.map(c, (d, dIdx) => {
									if (l.isObjectLike(d)) {
										return (
											<div key={dIdx}>
												{'{'}
												<JsonSection json={d} indent={props.indent + 2} />
												{'}'}
												{dIdx + 1 < c.length && ','}
											</div>
										)
									}

									return (
										<div key={dIdx}>
											<JsonValue value={d} field={cKey} />
											{dIdx + 1 < c.length && ','}
										</div>
									)
								})}
							</div>
							]{cIdx + 1 < keys.length && ','}
						</div>
					)
				}

				if (l.isObject(c)) {
					if (l.isEmpty(c)) {
						return (
							<div key={cKey}>
								<span className={styles.jsonKey}> {'' + cKey}</span>: {'{}'}
								{cIdx + 1 < keys.length && ','}
							</div>
						)
					}

					return (
						<div key={cKey}>
							<span className={styles.jsonKey}> {'' + cKey}</span>: {'{'}
							{<JsonSection json={c} indent={props.indent + 1} />}
							{'}'}
							{cIdx + 1 < keys.length && ','}
						</div>
					)
				}

				return (
					<div key={cKey}>
						<span className={styles.jsonKey}> {'' + cKey}</span>:{' '}
						<JsonValue value={c} field={cKey} />
						{cIdx + 1 < keys.length && ','}
					</div>
				)
			})}
		</div>
	)
}
