import { routes } from 'app/AppRouter'
import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { theme } from 'ui/theme'
import { isInTMS2 } from 'ui/theme/theme'
import { getBestRoute, sosRouter } from '.'
import { Icon, solidIcons } from '../icon'
import classes from './SimpleLink.module.scss'

export const SimpleLink: FC = (props: {
	href?: string
	testId?: string
	children?: React.ReactNode
	noUnderline?: boolean
}) => {
	let className = classes.simpleLink
	if (props.noUnderline) {
		className = theme.addClass(className, classes.noUnderline)
	}
	className = theme.addClass(className, classes.noUnderline)

	if (!props.href) {
		return <div>{props.children}</div>
	}

	let warning = null

	if (theme.isInDev()) {
		const { route } = getBestRoute(routes, props.href)
		if (!route || route.isDefault) {
			warning = (
				<div>
					<Icon icon={solidIcons.faExclamationTriangle} />
					[Route not found for {props.href}]
				</div>
			)
		}
	}

	return (
		<div className={className} data-testid={props.testId}>
			<a
				href={isInTMS2() ? props.href : '/#' + props.href}
				onClick={(ev) => {
					if (props.href) {
						sosRouter.navigate(props.href)
						ev.preventDefault()
						ev.stopPropagation()
						return false
					}
				}}
			>
				{warning}
				{props.children || props.href}
			</a>
		</div>
	)
}
