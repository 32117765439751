import { Address } from 'ui/api/apiTypes'

export const addressTypes: Address['addressType'][] = [
	'commercial',
	'residential',
	'school',
	'prison',
	'military',
	'construction',
	'tradeshow',
	'government',
	'mine',
	'storage',
	'other',
	'church',
	'mixed',
	'university',
	'camp',
	'farm',
]
