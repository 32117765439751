import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { IconButton } from 'ui/components/common/icon/Icon'
import { regularIcons } from 'ui/components/common/icon/regularIcons'
import { solidIcons } from 'ui/components/common/icon/solidIcons'
import { Loader } from 'ui/components/common/loader'
import { Center } from 'ui/components/layout/center'
import * as classes from './InviteButtons.module.scss'

export const ConnectionActiveButton: FC = (props: {
	isActive: boolean
	isSpinning: boolean
	onClick: () => void
}) => {
	return (
		<div className={classes.iconButton}>
			{props.isSpinning && (
				<Center>
					<Loader isLoading={true} testId='active-button-spinner' />
				</Center>
			)}
			{!props.isSpinning && (
				<IconButton
					icon={props.isActive ? regularIcons.faUser : solidIcons.faTimes}
					color={props.isActive ? 'black' : 'red'}
					onClick={props.onClick}
					testId={'active-icon-button'}
				/>
			)}
		</div>
	)
}

export const InviteButton: FC = (props: {
	inviteSent: boolean
	isSpinning: boolean
	onClick: () => void
}) => {
	return (
		<div className={classes.iconButton}>
			{props.isSpinning && (
				<Center>
					<Loader isLoading={true} testId='invite-button-spinner' />
				</Center>
			)}
			{!props.isSpinning && (
				<IconButton
					icon={props.inviteSent ? solidIcons.faCheck : regularIcons.faEnvelope}
					color={props.inviteSent ? 'green' : 'black'}
					onClick={props.onClick}
					testId={'invite-icon-button'}
				/>
			)}
		</div>
	)
}
