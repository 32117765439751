import {
	faAddressBook,
	faAngleDoubleLeft,
	faAngleDoubleRight,
	faAngleDown,
	faAngleLeft,
	faAngleRight,
	faAngleUp,
	faArrowDown,
	faArrowLeft,
	faArrowRight,
	faBalanceScale,
	faBan,
	faBook,
	faCalculator,
	faCalendarAlt,
	faCaretLeft,
	faCaretRight,
	faCheck,
	faCheckCircle,
	faCheckSquare,
	faChevronCircleLeft,
	faChevronCircleRight,
	faChevronLeft,
	faChevronRight,
	faCircle,
	faCoffee,
	faDotCircle,
	faDownload,
	faEllipsisV,
	faExchangeAlt,
	faExclamationTriangle,
	faFilter,
	faFlag,
	faLevelDownAlt,
	faLongArrowAltRight,
	faMapPin,
	faMinus,
	faPencilAlt,
	faPlus,
	faPlusCircle,
	faQuestionCircle,
	faSave,
	faSearch,
	faSearchPlus,
	faSort,
	faSortDown,
	faSortUp,
	faSpinner,
	faStepBackward,
	faStepForward,
	faSyncAlt,
	faTimes,
	faTimesCircle,
	faTrash,
	faTrashAlt,
	faTruck,
	faTruckMoving,
	faMapMarker,
	faEye,
	faWindowClose,
} from '@fortawesome/free-solid-svg-icons'

export const solidIcons = {
	faAddressBook,
	faAngleDown,
	faAngleDoubleLeft,
	faAngleDoubleRight,
	faAngleLeft,
	faAngleRight,
	faAngleUp,
	faArrowDown,
	faArrowLeft,
	faArrowRight,
	faBalanceScale,
	faBan,
	faBook,
	faCalculator,
	faCalendarAlt,
	faCaretLeft,
	faCaretRight,
	faCheck,
	faCheckCircle,
	faCheckSquare,
	faChevronCircleLeft,
	faChevronCircleRight,
	faChevronLeft,
	faChevronRight,
	faCircle,
	faCoffee,
	faDotCircle,
	faDownload,
	faEllipsisV,
	faExchangeAlt,
	faExclamationTriangle,
	faFilter,
	faFlag,
	faLevelDownAlt,
	faLongArrowAltRight,
	faMapPin,
	faMinus,
	faPencilAlt,
	faPlus,
	faPlusCircle,
	faQuestionCircle,
	faSave,
	faSearch,
	faSearchPlus,
	faSort,
	faSortDown,
	faSortUp,
	faSpinner,
	faStepBackward,
	faStepForward,
	faSyncAlt,
	faTimes,
	faTimesCircle,
	faTrash,
	faTrashAlt,
	faTruck,
	faTruckMoving,
	faMapMarker,
	faEye,
	faWindowClose,
}
