import { sosToast, ToastState } from 'common/components/toast'
import { DateTime } from 'luxon'
import nanoid from 'nanoid'
import {
	apiAddressBook,
	apiBrokerAndNonBrokerBridge,
	apiShipments,
} from 'ui/api'
import * as apiBroker from 'ui/api/apiBroker'
import { BridgeShipmentResponseType } from 'ui/api/apiBrokerAndNonBrokerBridgeTypes'
import { formatTimeForApi } from 'ui/api/apiCommon'
import * as apiTypes from 'ui/api/apiTypes'
import { apiRates } from 'ui/api/providers'
import { IRequestState } from 'ui/api/requestState'
import { ISelectOptions } from 'ui/components/common/select'
import { tArgz } from 'ui/components/i18n/i18n'
import { IAccessorialState } from 'ui/components/shared/accessorials'
import {
	AddressEditModeType,
	AddressSelectorType,
	getEmptyAddress,
	IAddress,
} from 'ui/components/shared/address'
import {
	createDefaultAddress,
	createDefaultAddressState,
	IAddressState,
	LocationDropdownSourceType,
} from 'ui/components/shared/address/IAddressState'
import { UpdateAddressStateType } from 'ui/components/shared/address/UpdateAddressStateType'
import { getLocations } from 'ui/components/shared/location-selector/locationSelectorUtils'
import {
	createDefaultDesiredState,
	createDefaultTime,
	createTime,
	getDefaultTimesForLocationAndDate,
	IDateTimeDesiredState,
	TimeType,
} from 'ui/components/shared/ShipmentStopCard/DateTimeDesired'
import {
	freightNewQuoteDetailsFormMetadata,
	INewQuoteDetailsForm,
} from 'ui/forms/formNewQuoteDetails'
import { createDefaultFormStringData } from 'ui/forms/formUtils'
import { _idx } from 'ui/lib'
import { asyncMapParallel } from 'ui/lib/async'
import { splitEmail } from 'ui/lib/emailValidator'
import { dataUrlToBase64 } from 'ui/lib/files'
import { extractFileFormat } from 'ui/lib/files/exatractFileFormat'
import { jestFix } from 'ui/lib/jestTools/jestFix'
import { l } from 'ui/lib/lodashImports'
import { deepMergeRight } from 'ui/lib/misc'
import { toCents, toDollarsFormatted, toInteger } from 'ui/lib/numbers/toNumber'
import { r } from 'ui/lib/ramdaImports'
import { IStopDateTimeInformation } from 'ui/lib/shipments'
import { calculateClass } from 'ui/lib/shipments/shipmentUtils'
import { createSos2, IStateMeta } from 'ui/lib/state/sos2/sos2'
import { sosAddressBookModal } from 'ui/modals'
import {
	deliveryAccessorials,
	getAccessorialKeyPath,
	limitedAccessAccessorials,
	pickupAccessorials,
} from 'ui/pages/new-quote/accessorials'
import { INewQuoteCommoditiesForm } from 'ui/pages/new-quote/INewQuoteCommoditiesForm'
import {
	DefaultAddressType,
	getLocation,
	getStopTypeByAddressId,
	setAddressChangesByStopType,
	setDesiredDateTimeChanges,
} from 'ui/pages/new-quote/state/newQuoteUtils/locationDropdownUtils'
import { sosShipmentProfileBroker } from 'ui/pages/shipment-profile/broker'
import { applyDefaultMarkupLogic } from 'ui/pages/shipment-profile/broker/functions'
import { sosUser } from 'ui/state'
import { router } from 'ui/state/router/'
import { setSelectedClientConfig } from 'ui/state/sosUser'
import { IBrokerSellForm } from '..'
import {
	IAccessorialsAndInfo,
	IEquipmentTypeAccessorials,
	StopAccessorialsInfoType,
	StopAccessorialsType,
} from '../accessorialsTypes'
import { BillToSelectorType } from '../BillTo'
import { IBillToThirdPartyForm } from '../forms/formBillToThirdParty'
import {
	splitAccessorials,
	tryToGetLimitedAccessAccessorial,
} from './accessorialUtils'
import { defaultCommodity } from './commodities'
import { defaultLtlShipmentValidations } from './newQuoteUtils/validateShipment/shipmentValidations'
import { validateShipmentData } from './newQuoteUtils/validateShipment/validateShipmentUtils'

export type StopType = 'pickup' | 'delivery'
export type NewQuoteModeType = 'freight' | 'freight-light' | 'parcel' | 'vendor'

export interface IShippingDocument {
	id: string
	type: apiTypes.ShippingDocumentRequest['type']
	format: apiTypes.ShippingDocumentRequest['format']
	dataUrlContent: string
	fileName?: string
	showPdfDocumentViewer: boolean
}
export interface IStateStop {
	stopId: string
	addressState: IAddressState
	specialInstructions?: string
	stopType: StopType
	dateTimeDesiredState?: IDateTimeDesiredState
}

export interface IStateNewQuote {
	newQuoteMode?: NewQuoteModeType
	createShipmentErrors?: string[]
	fileUploadErrors?: string[]
	dataFetchErrors?: any[]
	loadingQuotes?: boolean
	formNewQuoteDetails: INewQuoteDetailsForm
	stops: IStateStop[]
	equipmentTypeAccessorials: IEquipmentTypeAccessorials
	commodities: INewQuoteCommoditiesForm[]
	showProductCatalog: boolean
	selectedBillToFilter: BillToSelectorType
	billTo: IAddressState
	billToThisParty: apiTypes.BillTo['billToThisParty']
	billToStopId: string
	billToThirdPartyForm: IBillToThirdPartyForm
	shippingDocuments: IShippingDocument[]
	editingShipmentResponse:
		| apiTypes.ShipmentResponse
		| apiTypes.BrokerShipmentResponse
	organizationRelationshipId: string
	contactInfo: Partial<apiTypes.ContactInfo>
	brokerSellOfferForm?: IBrokerSellForm
	brokerGetMarketRateIsSpinning?: boolean
	shipmentId: string // for when we are editing a shipment
	toastDispatcher?: ToastState
	isSubmitting?: boolean
	linearFeet?: number
	useCompanyLocationAddress?: boolean
}

export const createDefaultStops = (): IStateStop[] => {
	return [
		{
			stopId: nanoid(),
			addressState: createDefaultAddressState(null, 'companyLocation'),
			specialInstructions: '',
			stopType: 'pickup',
			dateTimeDesiredState: jestFix(createDefaultDesiredState),
		},
		{
			stopId: nanoid(),
			addressState: createDefaultAddressState(null, 'companyLocation'),
			specialInstructions: '',
			stopType: 'delivery',
			dateTimeDesiredState: jestFix(createDefaultDesiredState),
		},
	]
}

export function createDefaultShipmentState(): IStateMeta<IStateNewQuote> {
	const defaultStops = createDefaultStops()
	const defaultBillToFilter: BillToSelectorType = 'pickup'
	let defaultBillToStopId
	if (l.includes(['pickup', 'delivery'], defaultBillToFilter)) {
		defaultBillToStopId = _idx(
			() =>
				l.find(defaultStops, (stop) => stop.stopType === defaultBillToFilter)
					.stopId,
		)
	}
	return {
		newQuoteMode: {
			default: 'freight',
		},
		formNewQuoteDetails: {
			default: createDefaultFormStringData(freightNewQuoteDetailsFormMetadata),
		},
		stops: {
			default: defaultStops,
		},
		equipmentTypeAccessorials: {
			default: {
				tarp: false,
				continuousRefrigerationMode: false,
				temperatureRange: false,
				minimumTemperature: '',
				maximumTemperature: '',
			},
		},
		commodities: {
			default: [r.clone(defaultCommodity)],
		},
		showProductCatalog: {
			default: false,
		},
		billTo: {
			default: createDefaultAddressState(),
		},
		billToThisParty: {
			default: null,
		},
		billToStopId: {
			default: defaultBillToStopId || '',
		},
		billToThirdPartyForm: {
			default: {
				accountNumber: '',
				carrierName: '',
				carrierType: null,
				usingBroker: 'false',
				proNumber: '',
			},
		},
		selectedBillToFilter: {
			default: defaultBillToFilter,
			localStorage: true,
		},
		shippingDocuments: {
			default: [],
		},
		editingShipmentResponse: {
			default: null,
		},
		fileUploadErrors: {
			default: [],
		},
		dataFetchErrors: {
			default: [],
		},
		createShipmentErrors: {
			default: [],
		},
		loadingQuotes: {
			default: false,
		},
		organizationRelationshipId: {
			default: '',
		},
		contactInfo: {
			default: {},
		},
		brokerSellOfferForm: {
			default: {},
		},
		shipmentId: {
			default: null,
		},
		toastDispatcher: { default: null },
		linearFeet: {
			default: 0,
		},
		useCompanyLocationAddress: {
			default: false,
		},
	}
}

export const sos = createSos2<IStateNewQuote>(
	'sosNewQuote',
	1,
	createDefaultShipmentState(),
)

export const resetState = (): void => {
	sos.replace(r.map(r.prop('default'), createDefaultShipmentState() as any))
}

export const selectNewQuoteMode = (filter: NewQuoteModeType): void => {
	sos.change((ds) => {
		ds.newQuoteMode = filter
	})
}

export const addNewDocument = (dataUrl: string, filename: string): void => {
	sos.change(async (ds) => {
		ds.shippingDocuments.push({
			id: nanoid(),
			type: 'Packing Slip',
			format: extractFileFormat(
				filename,
			) as apiTypes.ShippingDocumentRequest['format'],
			fileName: filename,
			dataUrlContent: dataUrl,
			showPdfDocumentViewer: false,
		})
	})
}

export const removeShippingDocument = (documentId: string): void => {
	sos.change(async (ds) => {
		ds.shippingDocuments = l.remove(
			ds.shippingDocuments,
			(c) => c.id === documentId,
		)
	})
}

export function navigateToNewQuote(params: {
	shipment_id: string
	mode?: string
}): void {
	let url =
		'/shipments-v3/new-quote/' +
		(params.mode === 'parcel' ? 'parcel' : 'freight')
	if (params.shipment_id) {
		url += `/${params.shipment_id}`
	}
	router.navTo(url)
}

export function getLocationsBySource(
	locationsSource: LocationDropdownSourceType,
	stopType?: StopType,
): IRequestState<ISelectOptions[]> {
	const defaultAddressFilters: {
		[K in StopType]: DefaultAddressType
	} = {
		pickup: 'defaultPickupAddress',
		delivery: 'defaultDeliveryAddress',
	}
	const addressTypeRequired: DefaultAddressType =
		defaultAddressFilters[stopType] || 'defaultBillingAddress'

	const result = getLocations(
		sosUser.getSos().getState(),
		locationsSource,
		addressTypeRequired,
	)
	if (result.error) {
		setDataFetchErrors(result.error)
		return result
	}

	return result
}

export function updateBillToThirdPartyForm(
	key: keyof IBillToThirdPartyForm,
	newVal: any,
): void {
	sos.change((ds) => {
		ds.billToThirdPartyForm[key] = newVal
	})
}

export function updateNewQuoteDetailsForm(
	key: keyof INewQuoteDetailsForm,
	newVal: any,
): void {
	sos.change((ds) => {
		ds.formNewQuoteDetails[key] = newVal
	})
}

export function updateBrokerSellForm(
	key: keyof IBrokerSellForm,
	newVal: any,
): void {
	sos.change((ds) => {
		ds.brokerSellOfferForm[key] = newVal
	})
}

export const updateStopInstructions = (
	instructions: string,
	stopId: string,
): void => {
	sos.change((ds) => {
		const stop = l.find(ds.stops, (c) => c.stopId === stopId)
		stop.specialInstructions = instructions
	})
}

export function findCommodity(
	ds: IStateNewQuote,
	id: string,
): INewQuoteCommoditiesForm {
	return l.find(ds.commodities, (c) => c.id === id)
}

export function updateCommodity(
	id: string,
	changes: Partial<INewQuoteCommoditiesForm>,
): void {
	if (changes.type === 'palletCstm') {
		changes.length = '48'
		changes.width = '40'
	}
	sos.change((ds) => {
		const c = findCommodity(ds, id)
		l.assign(c, changes)
	})
}

export function addAdditionalCommodityLine(): void {
	sos.change((ds) => {
		ds.commodities.push(
			l.assign(l.cloneDeep(defaultCommodity), { id: nanoid() }),
		)
	})
}

export function deleteCommodity(id: string): void {
	sos.change((ds) => {
		l.remove(ds.commodities, (c) => c.id === id)
	})
}

export function setCreateError(errorMessages: string | string[]): void {
	if (l.isString(errorMessages)) {
		errorMessages = [errorMessages]
	}
	sos.change((ds) => {
		ds.createShipmentErrors = l.union(ds.createShipmentErrors, errorMessages)
	})
}

export function setDataFetchErrors(errorMessages: string | string[]): void {
	if (l.isString(errorMessages)) {
		errorMessages = [errorMessages]
	}

	sos.change((ds) => {
		ds.dataFetchErrors = l.union(ds.dataFetchErrors, errorMessages)
	})
}

export function loadingQuotes(value: boolean): void {
	sos.change((ds) => {
		ds.loadingQuotes = value
	})
}

export function addFileUploadError(errorMessage: string): void {
	sos.change((ds) => ds.fileUploadErrors.push(errorMessage))
}

export function wipeFileUploadErrors(): void {
	sos.change((ds) => {
		ds.fileUploadErrors = []
	})
}

export function showProductCatalog(show = true): void {
	sos.change((ds) => {
		ds.showProductCatalog = show
	})
}

export const updateDateTimeDesired = async (
	dateTimeChanges: Partial<IDateTimeDesiredState>,
	stopId?: string,
): Promise<void> => {
	const stop = l.find(sos.getState().stops, (c) => c.stopId === stopId)
	let alternateApiKey: string
	if (sosUser.isUserBroker()) {
		alternateApiKey = _idx(
			() =>
				sosUser.getSos().getState().selectedClientConfig.tmsCredentials.apiKey,
		)
	}
	const location =
		stop.addressState.locationId &&
		(await sosUser.getLocation(stop.addressState.locationId, alternateApiKey))

	sos.change((ds) => {
		const stopToUpdate = l.find(ds.stops, (c) => c.stopId === stopId)
		stopToUpdate.dateTimeDesiredState = r.mergeDeepRight(
			stopToUpdate.dateTimeDesiredState,
			getDefaultTimesForLocationAndDate(dateTimeChanges, location),
		)
	})
}

export const updateStopAccessorials = (
	accessorialChanges: Partial<IAccessorialState>,
	stopId: string,
): void => {
	sos.change((ds) => {
		const stop = l.find(ds.stops, (c) => c.stopId === stopId)
		stop.addressState.accessorials = r.mergeDeepRight(
			stop.addressState.accessorials,
			accessorialChanges,
		)
	})
}

export const updateEquipmentAccessorials = (
	changes: Partial<IEquipmentTypeAccessorials>,
): void => {
	sos.change((ds) => {
		ds.equipmentTypeAccessorials = r.mergeDeepRight(
			ds.equipmentTypeAccessorials,
			changes,
		)
	})
}

export const createStopDateTimeInformation = (
	dateTimeStore: IDateTimeDesiredState,
): IStopDateTimeInformation => {
	const { date1, date2, time1, time2, timeType } = dateTimeStore
	return {
		initialDate: timeType === 'standard' ? undefined : date1 || undefined,
		latestDate: date2 || undefined,
		initialTime: formatTimeForApi(time1),
		latestTime: formatTimeForApi(time2),
		timeType: timeType === 'standard' ? 'anyTime' : timeType,
	}
}

export const calculateClassNewQuote = (commodityId: string): void => {
	sos.change((ds) => {
		const commodity = ds.commodities.find((comm) => comm.id === commodityId)
		commodity.class = calculateClass(commodity)
	})
}

export function createOriginDestinationMetadata(
	stopState: IStateStop,
	stopType: StopType,
): IAccessorialsAndInfo {
	// Add additional 'hidden' accessorials
	const limitedAccessAccessorials = tryToGetLimitedAccessAccessorial(
		_idx(() => stopState.addressState.address.addressType),
		stopType,
	)
	const accessorials = deepMergeRight<
		StopAccessorialsType | StopAccessorialsInfoType
	>(limitedAccessAccessorials, stopState.addressState.accessorials)

	const _splitAccessorials = splitAccessorials(accessorials, stopType)

	return {
		specialInstructions: stopState.specialInstructions,
		locationId: stopState.addressState.locationId,
		accessorials: _splitAccessorials.accessorials,
		accessorialsInfo: _splitAccessorials.accessorialsInfo,
		desiredDateTimeInfo: createStopDateTimeInformation(
			stopState.dateTimeDesiredState,
		),
	}
}

export const isInEditMode = (): boolean => {
	return !!sos.getState().shipmentId
}

export function getClientConfigId(): string {
	const stateUser = sosUser.getSos().getState()
	return _idx(() => stateUser.selectedClientConfig.id)
}

export const uploadDocuments = async (
	shipmentResponse: IRequestState<apiTypes.ShipmentResponse>,
): Promise<boolean> => {
	wipeFileUploadErrors()
	const shipmentState = sos.getState()
	const fileUploadErrors = await asyncMapParallel(
		shipmentState.shippingDocuments,
		async (file): Promise<boolean> => {
			const shippingDocumentRequest: apiTypes.ShippingDocumentRequest = {
				shipmentId: shipmentResponse.data.id,
				payloadId: shipmentResponse.data.payloads[0].id,
				image: dataUrlToBase64(file.dataUrlContent),
				format: file.format,
				type: file.type,
				filename: file.fileName,
				uploadDate: DateTime.local().toISO(),
			}
			const documentResponse = await apiShipments.uploadShippingDocument(
				shippingDocumentRequest,
				() => {},
			)
			if (documentResponse.error) {
				addFileUploadError(documentResponse.error)
				return true
			}
		},
	)
	return !fileUploadErrors.some((errored) => errored === true)
}

export const upsertShipment = async (
	getRates: boolean,
	activeValidation: boolean,
	book?: boolean
): Promise<BridgeShipmentResponseType> => {
	const addressErrors = await updateWithLookupAddresses()

	const errors = addressErrors
		.filter((error) => error)
		.map((error) => error.error)
	if (errors.length) {
		return { error: [...new Set(errors)] }
	}
	const shipmentData: apiTypes.ShipmentRequest = createCreateShipmentRequest()
	if (!activeValidation) {
		const errorMessages = validateShipmentData(
			shipmentData,
			defaultLtlShipmentValidations,
		)
		if (errorMessages.length) {
			return { error: errorMessages }
		}
	}
	return apiBrokerAndNonBrokerBridge.upsertShipment(
		sos.getState().shipmentId,
		shipmentData,
		getRates,
		getClientConfigId(),
		book
	)
}

export const validateShipment = (): string[] => {
	const shipmentData: apiTypes.ShipmentRequest = createCreateShipmentRequest()
	return validateShipmentData(shipmentData, defaultLtlShipmentValidations)
}

export const createShipmentWorkflow = async (
	activeValidation: boolean,
	book?: boolean,
): Promise<BridgeShipmentResponseType> => {
	if (sos.getState().loadingQuotes) {
		return
	}
	loadingQuotes(true)
	setCreateError('')
	const shipmentResponse = await upsertShipment(!book, activeValidation, book) // Rating is taken care of in TMS3 Shipment Profile

	// removing this for now as the team is no longer going to work on broker features
	// if (shipmentResponse.error) {
	// 	setCreateError(shipmentResponse.error)
	// } else if (shipmentResponse.data) {
	// 	const uploadSuccess = await uploadDocuments(shipmentResponse)
	// 	const createBrokerOfferSuccess = await createBrokerOffer(
	// 		shipmentResponse.data.id,
	// 	)
	// 	if (uploadSuccess && createBrokerOfferSuccess) {
	// 		sosShipmentProfileBroker.navigateToShipmentProfile({
	// 			shipmentId: shipmentResponse.data.id,
	// 		})
	// 	}
	// }
	loadingQuotes(false)
	return shipmentResponse
}

export const createVendorShipment = async (): Promise<void> => {
	if (sos.getState().loadingQuotes) {
		return
	}
	loadingQuotes(true)
	setCreateError('')
	const shipmentResponse = await upsertShipment(false, false) // Rating is taken care of in TMS2 Vendor BOL
	if (shipmentResponse.error) {
		setCreateError(shipmentResponse.error)
	} else if (shipmentResponse.data) {
		const uploadSuccess = await uploadDocuments(shipmentResponse)
		if (uploadSuccess) {
			router.navTo('/vendor/bol/' + shipmentResponse.data.id)
		}
	}
	loadingQuotes(false)
}

export const setAddressStateToEmptyAddress = (
	addressState: IAddressState,
): void => {
	addressState.address = getEmptyAddress()
	addressState.address.country = 'US'
	addressState.locationId = ''
}

export const selectAddressFilter = (
	filter: AddressSelectorType,
	addressId: string,
): void => {
	sos.change(async (ds) => {
		const stop: IStateStop = r.find(
			(c) => c.addressState.id === addressId,
			ds.stops,
		)
		const intitalEditMode: AddressEditModeType =
			stop.addressState.addressEditMode
		if (intitalEditMode === 'companyLocation') {
			setAddressStateToEmptyAddress(stop.addressState)
		}
		stop.addressState.selectedAddressFilter = filter

		if (filter === 'addressBook') {
			stop.addressState.addressEditMode = 'customAddress'
			stop.addressState.selectedAddressFilter = 'customAddress'
			sosAddressBookModal.showModal(true, addressId)
		}
		if (filter === 'dropShip') {
			stop.addressState.addressEditMode = 'customAddress'
			ds.selectedBillToFilter = 'companyLocation'
			setAddressStateToEmptyAddress(ds.billTo)
			ds.billToStopId = ''
		}
		if (filter === 'customerDelivery') {
			stop.addressState.addressEditMode = 'companyLocation'
			ds.selectedBillToFilter = 'delivery'
			setAddressStateToEmptyAddress(ds.billTo)
			ds.billToStopId = ds.stops[1].stopId
		}
		if (filter === 'customAddress') {
			stop.addressState.addressEditMode = 'customAddress'
		}
		if (l.includes(['companyLocation', 'supplierLocation'], filter)) {
			stop.addressState.addressEditMode = 'companyLocation'
		}
	})
}

export const selectBillToFilter = (filter: BillToSelectorType): void => {
	sos.change((ds) => {
		ds.selectedBillToFilter = filter
		ds.billTo.address = getEmptyAddress()
		ds.billTo.address.country = 'US'
		ds.useCompanyLocationAddress = false

		if (filter === 'pickup') {
			ds.billToStopId = ds.stops[0].stopId
			ds.billTo.address = ds.stops[0].addressState.address
		} else if (filter === 'delivery') {
			ds.billToStopId = ds.stops[1].stopId
			ds.billTo.address = ds.stops[1].addressState.address
		} else if (filter === 'companyLocation') {
			ds.useCompanyLocationAddress = true
		} else {
			ds.billToStopId = ''
		}

		if (filter === 'addressBook') {
			sosAddressBookModal.showModal(true, ds.billTo.id)
		}
	})
}

export const setBillToThisParty = (
	value: apiTypes.BillTo['billToThisParty'],
): void => {
	sos.change((ds) => {
		ds.billToThisParty = value
	})
}

export const togglePdfDocumentViewer = (index, value): void => {
	sos.change(async (ds) => {
		ds.shippingDocuments[index].showPdfDocumentViewer = value
	})
}

export async function onSelectLocation(
	locationId: string,
	updateState: (
		addressChanges: Partial<IAddressState>,
		addressId?: string,
		requestToken?: string,
		stopChanges?: Partial<IStateStop>,
	) => void,
	organizationRelationshipId?: string,
	addressId?: string,
	stopType?: StopType,
): Promise<void> {
	const token = nanoid()
	updateState(
		{
			locationId: locationId,
			isLocationLoading: true,
			locationRequestToken: token,
		},
		addressId,
	)

	let alternateApiKey: string
	if (sosUser.isUserBroker()) {
		alternateApiKey = _idx(
			() =>
				sosUser.getSos().getState().selectedClientConfig.tmsCredentials.apiKey,
		)
	}
	const requestLocation = await getLocation(
		locationId,
		organizationRelationshipId,
		alternateApiKey,
	)
	if (requestLocation) {
		if (!stopType) {
			stopType = getStopTypeByAddressId(sos.getState().stops, addressId)
		}
		const addressChanges = setAddressChangesByStopType(
			requestLocation,
			stopType,
		)
		addressChanges.isLocationLoading = false

		const stopChanges = setDesiredDateTimeChanges(requestLocation)
		updateState(addressChanges, addressId, token, stopChanges)
	}
}

export const updateBillTo: UpdateAddressStateType = (
	changes: {
		billTo: Partial<IAddressState>
		billToStopId?: string
	},
	_addressId?: string, // unused key, needed to match signature
	requestToken?: string,
): void => {
	sos.change((ds) => {
		if (r.not(l.isNil(changes.billToStopId))) {
			ds.billToStopId = changes.billToStopId
		}
		if (
			l.isNil(requestToken) ||
			ds.billTo.locationRequestToken === requestToken
		) {
			l.assign(ds.billTo, changes.billTo)
		}
	})
}

export const updatePickupAddress: UpdateAddressStateType = (
	addressChanges: Partial<IAddressState>,
	_addressId?: string, // unused key, needed to match signature
	requestToken?: string,
) => {
	sos.change((ds) => {
		const pickupAddressState = l.first(ds.stops).addressState
		if (
			l.isNil(requestToken) ||
			pickupAddressState.locationRequestToken === requestToken
		) {
			l.assign(pickupAddressState, addressChanges)
		}
	})
}

export const updateAddress: UpdateAddressStateType = (
	addressChanges: Partial<IAddressState>,
	addressId?: string,
	requestToken?: string,
) => {
	sos.change((ds) => {
		const addressStates = l.concat(
			l.map(ds.stops, (c) => c.addressState),
			ds.billTo,
		)
		const addressState = l.find(addressStates, (c) => c.id === addressId)
		if (
			l.isNil(requestToken) ||
			addressState.locationRequestToken === requestToken
		) {
			l.assign(addressState, addressChanges)
		}
	})
}

export const updateBillToAddress = (addressChanges: IAddress): void => {
	sos.change((ds) => {
		ds.billTo.address = addressChanges
	})
}

export const updateStopByAddressId: UpdateAddressStateType = (
	addressChanges: Partial<IAddressState>,
	addressId?: string,
	requestToken?: string,
	stopChanges?: Partial<IStateStop>,
) => {
	const allStopChanges = deepMergeRight<IStateStop>(stopChanges, {
		addressState: addressChanges,
	})
	sos.change((ds) => {
		const stopIndex = l.findIndex(
			ds.stops,
			(c) => c.addressState.id === addressId,
		)
		if (stopIndex !== -1) {
			ds.stops[stopIndex] = deepMergeRight<IStateStop>(
				ds.stops[stopIndex],
				allStopChanges,
			)
		}
	})
}

export function setCompanyDefaults(): void {
	sos.change((ds) => {
		ds.stops.forEach((c) => {
			c.addressState.address.country = 'US'
			if (c.stopType === 'delivery') {
				c.addressState.selectedAddressFilter = 'customAddress'
				c.addressState.addressEditMode = 'customAddress'
			}
		})
		ds.commodities.forEach((c) => {
			c.type = 'palletCstm'
			c.piecesType = 'boxes'
			c.length = '48'
			c.width = '40'
			c.height = ''
			c.piecesLength = ''
			c.piecesWidth = ''
			c.piecesHeight = ''
			c.description = ''
			c.packedWeight = ''
		})
		ds.formNewQuoteDetails.equipmentTypes = 'V'
	})
}

export function setFreightDemoShipment(): void {
	sos.change((ds) => {
		ds.stops.forEach((c) => {
			c.addressState.addressEditMode = 'customAddress'
			c.addressState.selectedAddressFilter = 'customAddress'
			if (c.stopType === 'delivery') {
				c.addressState.address.name = 'ATTN: Jackie Robinson'
				c.addressState.address.company = 'SP RICHARDS'
				c.addressState.address.street1 = '3673 Corporate Center Dr'
				c.addressState.address.city = 'Earth City'
				c.addressState.address.state = 'MO'
				c.addressState.address.country = 'US'
				c.addressState.address.phone = '(314) 567-7726'
				c.addressState.address.email = 'email@email.com'
				c.addressState.address.zip = '63045'
			} else if (c.stopType === 'pickup') {
				c.addressState.address.name = 'Steve'
				c.addressState.address.company = 'Swanleap'
				c.addressState.address.street1 = '6325 Odana Rd.'
				c.addressState.address.street2 = '#2000'
				c.addressState.address.city = 'Madison'
				c.addressState.address.state = 'WI'
				c.addressState.address.country = 'US'
				c.addressState.address.phone = '(855) 737-3444'
				c.addressState.address.email = 'email@email.com'
				c.addressState.address.zip = '53719'
			}
		})
		ds.commodities.forEach((c) => {
			c.type = 'palletCstm'
			c.count = '6'
			c.length = '48'
			c.width = '40'
			c.height = '40'
			c.packedWeight = '1200'
			c.piecesType = 'boxes'
			c.piecesCount = '8'
			c.piecesLength = '24'
			c.piecesWidth = '20'
			c.piecesHeight = '20'
			c.description = 'Work Boots'
			c.class = '175'
			c.nmfc = '73238'
			c.notes = 'Batch S/N: 1288773993'
			c.bol = '493848485'
			c.so = '93029394'
			c.po = '3929394'
		})
		ds.formNewQuoteDetails.equipmentTypes = 'V'
	})
}

export const updateWithLookupAddresses = async (): Promise<{
	error: any
}[]> => {
	return await asyncMapParallel(sos.getState().stops, async (stop) => {
		const { zip, city, state } = stop.addressState.address
		if ((zip && !city && !state) || (!zip && city && state)) {
			const addressResult = await apiShipments.lookupAddress(
				() => {},
				zip,
				city,
				state,
			)
			if (addressResult.data) {
				sos.change((ds) => {
					const stateStop = l.find(ds.stops, (c) => c.stopId === stop.stopId)
					if (_idx(() => stateStop.addressState.address)) {
						stateStop.addressState.address.zip = addressResult.data.zip
						stateStop.addressState.address.city = addressResult.data.city
						stateStop.addressState.address.state = addressResult.data.state
					}
				})
			} else {
				return {
					error: tArgz('page.newQuote.fetchCityStateError', { city, state }),
				}
			}
		}
	})
}

//TODO: move this to its own file
export const createCreateShipmentRequest = ():
	| apiTypes.ShipmentRequest
	| apiTypes.BrokerShipmentRequest => {
	const state = sos.getState()
	const selectedClientConfig = sosUser.getSos().getState().selectedClientConfig
	const customerShipNotification =
		selectedClientConfig?.customerShipNotification
	const { equipmentTypeAccessorials } = state

	const shipmentLevelAccessorials: apiTypes.AccessorialsRequired = {}
	const shipmentLevelAccessorialsInfo: apiTypes.ShipmentAccessorialsInfo = {}

	if (equipmentTypeAccessorials.tarp) {
		shipmentLevelAccessorials.tarp = true
	}
	if (equipmentTypeAccessorials.continuousRefrigerationMode) {
		shipmentLevelAccessorials.continuousRefrigerationMode = true
	}
	if (
		equipmentTypeAccessorials.temperatureRange ||
		equipmentTypeAccessorials.minimumTemperature ||
		equipmentTypeAccessorials.maximumTemperature
	) {
		shipmentLevelAccessorialsInfo.temperatureInfo = {
			temperatureRange: equipmentTypeAccessorials.temperatureRange,
			minimumTemperature: Number.parseFloat(
				equipmentTypeAccessorials.minimumTemperature,
			)
				? Number.parseFloat(equipmentTypeAccessorials.minimumTemperature)
				: null,
			maximumTemperature: Number.parseFloat(
				equipmentTypeAccessorials.maximumTemperature,
			)
				? Number.parseFloat(equipmentTypeAccessorials.maximumTemperature)
				: null,
		}
	}

	const shipmentData:
		| apiTypes.ShipmentRequest
		| apiTypes.BrokerShipmentRequest = {
		flags: null,
		// identifier: null, TODO ADD BACK IN WHEN APITYPES ALLOWS FOR IT
		proNumber: state.billToThirdPartyForm?.proNumber,
		billTo: {
			address: state.billTo.address,
			locationId: state.billTo.locationId,
			carrierAccountNumber: state.billToThirdPartyForm?.accountNumber,
			carrierName: state.billToThirdPartyForm?.carrierName,
			carrierType: state.billToThirdPartyForm?.carrierType,
			usingBroker: state.billToThirdPartyForm?.usingBroker === 'true',
		},
		payloads: [],
		specialInstructions: state.formNewQuoteDetails.specialInstructions,
		equipmentType: state.formNewQuoteDetails
			.equipmentTypes as apiTypes.ShipmentRequest['equipmentType'],
		sealNumber: state.formNewQuoteDetails.sealNumber,
		trailerLength: Number.parseFloat(state.formNewQuoteDetails.trailerLength)
			? Number.parseFloat(state.formNewQuoteDetails.trailerLength)
			: null,
		bidDuration: Number(state.formNewQuoteDetails.bidDuration) || undefined,
		accessorials: shipmentLevelAccessorials,
		accessorialsInfo: shipmentLevelAccessorialsInfo,
		brokerReferenceNumber: state.formNewQuoteDetails.brokerReferenceNumber,
		linearFeet: state.linearFeet,
		shippingNotifications: {
			tracking: {
				sendShippingNotification:
					customerShipNotification?.sendShippingNotification,
				shippingNotificationEmails: customerShipNotification?.sendShippingNotification
					? splitEmail(state.formNewQuoteDetails.shippingNotificationEmails)
					: [],
			},
		},
	}

	if (state.billToStopId) {
		const billToStop = l.find(
			state.stops,
			(c) => c.stopId === state.billToStopId,
		)
		if (billToStop) {
			shipmentData.billTo.address = billToStop.addressState.address
			shipmentData.billTo.locationId = billToStop.addressState.locationId
		}
	}

	const shouldDefaultPieceDims = (commodity): boolean =>
		commodity.piecesLength === '' &&
		commodity.piecesWidth === '' &&
		commodity.piecesHeight === ''

	const originStop = {
		address: l.first(state.stops).addressState.address,
		metaData: createOriginDestinationMetadata(l.first(state.stops), 'pickup'),
	}

	const destinationStop = {
		address: l.last(state.stops).addressState.address,
		metaData: createOriginDestinationMetadata(l.last(state.stops), 'delivery'),
	}

	shipmentData.payloads = [
		{
			pickTicketNumber: state.formNewQuoteDetails.pickTicketNumber,
			organizationRelationshipId: state.organizationRelationshipId,
			originStop: originStop,
			destinationStop: destinationStop,
			bolIdentifier: state.commodities[0].bol, //TODO: find a better UI way to enter a BOL
			containers: [],
		} as apiTypes.ShipmentRequestPayload,
	] //TODO: setup multistop

	l.forEach(state.commodities, (commodity) => {
		shipmentData.payloads[0].containers.push({
			count: Number.parseInt(commodity.count, 10),
			class: commodity.class as apiTypes.Container['class'],
			description: commodity.description,
			type: commodity.type as apiTypes.Container['type'],
			length: Number.parseFloat(commodity.length),
			width: Number.parseFloat(commodity.width),
			height: Number.parseFloat(commodity.height),
			containerWeightEach: 0,
			packedWeight: Number.parseFloat(commodity.packedWeight),
			freightLevel: 'freight',
			containers: [
				{
					count: Number.parseInt(commodity.piecesCount, 10),
					class: commodity.class as apiTypes.Container['class'],
					description: commodity.description,
					type: commodity.piecesType as apiTypes.Container['type'],
					length: shouldDefaultPieceDims(commodity)
						? Number.parseFloat(commodity.length)
						: Number.parseFloat(commodity.piecesLength),
					width: shouldDefaultPieceDims(commodity)
						? Number.parseFloat(commodity.width)
						: Number.parseFloat(commodity.piecesWidth),
					height: shouldDefaultPieceDims(commodity)
						? Number.parseFloat(commodity.height)
						: Number.parseFloat(commodity.piecesHeight),
					containerWeightEach: 0,
					freightLevel: 'parcel',
					goods: [
						{
							hazmatClass: commodity.hazmatClass,
							hazmatUnCode: commodity.unNumber,
							hazmatPackingGroup: commodity.packagingGroup as apiTypes.Good['hazmatPackingGroup'],
							hazmat: commodity.hazmat === 'true',
							description: commodity.notes,
							purchaseOrder: commodity.po,
							salesOrder: commodity.so,
							nmfcCode: commodity.nmfc,
							nmfcSubCode: commodity.nmfcSub,
							weightEach:
								Number.parseFloat(commodity.packedWeight) /
								(Number.parseFloat(commodity.piecesCount) || 1),
						},
					],
				},
			],
		})
	})

	if (state.editingShipmentResponse) {
		return r.mergeDeepRight(state.editingShipmentResponse, shipmentData)
	}

	return shipmentData
}

export function mapBillToFilterAndStopIdToState(
	stops: IStateStop[],
	shipment: apiTypes.ShipmentResponse,
): {
	selectedBillToFilter: any
	billToStopId: string
	billToAddress: apiTypes.Address
	useCompanyLocationAddress: boolean
} {
	let selectedBillToFilter: BillToSelectorType
	let billToStopId = ''
	let billToAddress = createDefaultAddress()
	let useCompanyLocationAddress = false

	if (shipment.billTo.billToThisParty === 'shipper') {
		selectedBillToFilter = 'pickup'
	} else if (shipment.billTo.billToThisParty === 'consignee') {
		selectedBillToFilter = 'delivery'
	} else if (
		shipment.billTo.billToThisParty === 'thirdParty' &&
		shipment.billTo.locationId
	) {
		selectedBillToFilter = 'companyLocation'
	} else if (
		shipment.billTo.billToThisParty === 'thirdParty' &&
		!shipment.billTo.locationId
	) {
		selectedBillToFilter = 'customAddress'
	}

	if (
		shipment.billTo.address &&
		Object.keys(shipment.billTo.address).length > 0
	) {
		billToAddress = shipment.billTo.address
	} else {
		if (shipment.billTo.billToThisParty === 'shipper') {
			billToAddress = shipment.payloads[0].originStop.address
		} else if (shipment.billTo.billToThisParty === 'consignee') {
			billToAddress = shipment.payloads[0].destinationStop.address
		} else if (
			shipment.billTo.billToThisParty === 'thirdParty' &&
			shipment.billTo.locationId
		) {
			useCompanyLocationAddress = true
		}
	}

	if (l.isMatch(shipment.billTo.address, shipment.origin)) {
		billToStopId = l.find(stops, (stop) => stop.stopType === 'pickup').stopId
	} else if (l.isMatch(shipment.billTo.address, shipment.destination)) {
		billToStopId = l.find(stops, (stop) => stop.stopType === 'delivery').stopId
	}

	return {
		selectedBillToFilter,
		billToStopId,
		billToAddress,
		useCompanyLocationAddress,
	}
}

export function mapCommoditiesToState(
	shipment: apiTypes.ShipmentResponse,
): INewQuoteCommoditiesForm[] {
	let commodity: INewQuoteCommoditiesForm
	const commodities: INewQuoteCommoditiesForm[] = []

	const payload = l.cloneDeep(shipment.payloads[0])

	const freightIndex = payload.containers.findIndex(
		(container) => container.containers && container.freightLevel === 'freight',
	)

	let freightContainer: apiTypes.ContainerResponse = {
		id: undefined,
		count: 1,
		length: 48,
		width: 40,
		height: 40,
		containerWeightEach: 40,
		packedWeight: 40,
		freightLevel: 'freight',
		containers: [],
	}

	if (freightIndex > -1) {
		freightContainer = payload.containers[freightIndex]
		payload.containers.splice(freightIndex, 1)
	}

	const hasParcel = payload.containers.some(
		(_container) => _container.freightLevel === 'parcel',
	)

	if (hasParcel) {
		const nonParcelContainers: apiTypes.ContainerResponse[] = []

		payload.containers.forEach((container) => {
			if (container.freightLevel === 'parcel') {
				freightContainer.containers.push(container)
			} else {
				nonParcelContainers.push(container)
			}
		})

		payload.containers = nonParcelContainers
	}

	if (freightContainer.containers.length) {
		payload.containers.unshift(freightContainer)
	}

	shipment.payloads[0] = payload

	l.forEach(shipment.payloads, (payload) => {
		l.forEach(payload.containers, (container) => {
			// if (!(container.containers.length === 1)) {
			// 	throw new Error(
			// 		'You are attempting to edit a container that does not have exactly one nested container. You may only edit a container that contains exactly one nested container.',
			// 	)
			// }
			// if (!(_idx(() => container.containers[0].goods.length) === 1)) {
			// 	throw new Error(
			// 		'You are attempting to edit a container that does not have exactly one nested good. You may only edit a container that contains exactly one nested good.',
			// 	)
			// }
			if (container.type === 'pallet'){
				container.type = 'palletCstm'
			}

			const innerContainer = _idx(() => container.containers[0]) || {}
			const good = _idx(() => innerContainer.goods[0]) || {}
			commodity = {
				id: container.id,
				count: '' + (container.count || ''),
				class: container.class,
				length: '' + (container.length || '48'),
				width: '' + (container.width || '40'),
				height: '' + (container.height || '40'),
				packedWeight: '' + (container.packedWeight || ''),
				description: container.description,
				type: container.type || 'boxes',
				piecesCount: '' + innerContainer.count,
				piecesType: innerContainer.type,
				piecesLength: '' + (innerContainer.length || ''),
				piecesWidth: '' + (innerContainer.width || ''),
				piecesHeight: '' + (innerContainer.height || ''),
				hazmat: good.hazmat ? 'true' : 'false',
				nmfc: good.nmfcCode,
				nmfcSub: good.nmfcSubCode,
				hazmatClass: good.hazmatClass,
				unNumber: good.hazmatUnCode,
				packagingGroup: good.hazmatPackingGroup,
				bol: payload.bolIdentifier,
				so: good.salesOrder,
				po: good.purchaseOrder,
				notes: good.description,
			}
			commodities.push(commodity)
		})
	})
	return commodities
}

export const mapStopAccessorialsToState = (
	stopMetaData: apiTypes.OriginMetaData | apiTypes.DestinationMetaData,
	stopType: StopType,
): { [key: string]: boolean | string } => {
	const stateAccessorials =
		stopType === 'pickup' ? pickupAccessorials : deliveryAccessorials

	const stopAccessorialKeys = l.map(stateAccessorials, (c) =>
		getAccessorialKeyPath(c, stopType),
	)
	const limitedAccessKeys = l.map(
		limitedAccessAccessorials,
		(c) => c[getAccessorialKeyPath(c, stopType)],
	)
	const filteredStopAccessorialKeys = l.filter(
		stopAccessorialKeys,
		(c) => !l.includes(limitedAccessKeys, c),
	)

	const accessorialsInfoKeys = l.flatMapDeep(stateAccessorials, (acc) =>
		l.map(l.keys(acc.childrenAccessorials), (cKey) => cKey),
	)
	return r.mergeDeepRight(
		l.pick(stopMetaData.accessorials, filteredStopAccessorialKeys),
		l.pick(stopMetaData.accessorialsInfo, accessorialsInfoKeys),
	)
}

export function mapStopToAddressState(
	stop: apiTypes.OriginStop | apiTypes.DestinationStop,
	stopType: StopType,
): IAddressState {
	return {
		id: nanoid(),
		address: stop.address,
		selectedAddressFilter: stop.metaData.locationId
			? 'companyLocation'
			: 'customAddress',
		locationId: stop.metaData.locationId,
		accessorials: mapStopAccessorialsToState(stop.metaData, stopType),
		validation: {},
		isEditing: true,
		addressEditMode: stop.metaData.locationId
			? 'companyLocation'
			: 'customAddress',
	}
}

export function mapDateTimeToState(
	dateTimeInfo: apiTypes.DateTimeInfo,
): IDateTimeDesiredState {
	const timeType: TimeType = l.includes(
		['timeToBeDetermined', 'anyTime'],
		dateTimeInfo.timeType,
	)
		? 'standard'
		: (dateTimeInfo.timeType as TimeType)
	return {
		timeType: timeType || 'standard',
		date1: dateTimeInfo.initialDate || '',
		time1: createTime(dateTimeInfo.initialTime) || createDefaultTime(),
		date2: dateTimeInfo.latestDate || '',
		time2: createTime(dateTimeInfo.latestTime) || createDefaultTime(),
	}
}

export function mapStopToState(
	stop: apiTypes.OriginStop | apiTypes.DestinationStop,
	stopType: StopType,
): IStateStop {
	const dateTimeInfo = _idx(() => stop.metaData.desiredDateTimeInfo) || {}
	return {
		stopType: stopType,
		stopId: nanoid(),
		addressState: mapStopToAddressState(stop, stopType),
		specialInstructions: stop.metaData.specialInstructions,
		dateTimeDesiredState: mapDateTimeToState(dateTimeInfo),
	}
}

export async function setStateFromShipmentRequest(
	shipmentResponse: apiTypes.ShipmentResponse | apiTypes.BrokerShipmentResponse,
): Promise<IStateNewQuote> {
	if ('contractId' in shipmentResponse) {
		await setSelectedClientConfig(shipmentResponse.contractId)
	}
	const state = sos.getState()
	const shipment = shipmentResponse
	if (shipment.shipmentStatus === 'booked') {
		throw new Error('You can not edit a booked Shipment')
	}

	const commodities: INewQuoteCommoditiesForm[] = mapCommoditiesToState(
		shipment,
	)

	const { originStop, destinationStop } = shipment.payloads[0]
	const stops: IStateStop[] = [
		mapStopToState(originStop, 'pickup'),
		mapStopToState(destinationStop, 'delivery'),
	]

	const {
		selectedBillToFilter,
		billToStopId,
		billToAddress,
		useCompanyLocationAddress,
	} = mapBillToFilterAndStopIdToState(stops, shipment)

	const newState: IStateNewQuote = l.assign(
		r.map(r.prop('default'), createDefaultShipmentState()),
		{
			newQuoteMode: state.newQuoteMode ? state.newQuoteMode : 'freight',
			showProductCatalog: false,
			shippingDocuments: [],
			fileUploadErrors: [],
			loadingQuotes: false,
			organizationRelationshipId: state.organizationRelationshipId || '',
			contactInfo: state.contactInfo || {},
			editingShipmentResponse: shipmentResponse,
			formNewQuoteDetails: {
				pickTicketNumber: shipment.payloads[0].pickTicketNumber || '',
				specialInstructions: shipment.specialInstructions || '',
				identifier: shipment.identifier || '',
				bidDuration: shipment.bidDuration ? '' + shipment.bidDuration : '',
				sealNumber: shipment.sealNumber || '',
				equipmentTypes: shipment.equipmentType,
				trailerLength: shipment.trailerLength
					? '' + shipment.trailerLength
					: '',
			},
			equipmentTypeAccessorials: {
				tarp: shipment.accessorials?.tarp,
				continuousRefrigerationMode:
					shipment.accessorials?.continuousRefrigerationMode,
				temperatureRange: _idx(
					() => shipment.accessorialsInfo.temperatureInfo.temperatureRange,
				),
				minimumTemperature: _idx(
					() =>
						shipment.accessorialsInfo.temperatureInfo.minimumTemperature + '',
				),
				maximumTemperature: _idx(
					() =>
						shipment.accessorialsInfo.temperatureInfo.maximumTemperature + '',
				),
			},
			selectedBillToFilter: selectedBillToFilter,
			billTo: {
				id: nanoid(),
				address: billToAddress,
				locationId: shipment.billTo.locationId,
			},
			billToThisParty: shipment.billTo.billToThisParty,
			billToStopId: billToStopId,
			billToThirdPartyForm: {
				accountNumber: shipment.billTo.carrierAccountNumber,
				carrierName: shipment.billTo.carrierName,
				carrierType: shipment.billTo.carrierType,
				proNumber: shipment.proNumber,
				usingBroker: shipment.billTo.usingBroker ? 'true' : '',
			},
			commodities: commodities,
			stops: stops,
			shipmentId: shipment.id,
			dataFetchErrors: [],
			brokerSellOfferForm: {},
			toastDispatcher: null,
			linearFeet: shipment.linearFeet || 0,
			useCompanyLocationAddress: useCompanyLocationAddress,
		},
	)

	if ('brokerReferenceNumber' in shipment) {
		newState.formNewQuoteDetails.brokerReferenceNumber =
			shipment.brokerReferenceNumber
	}

	if ('shippingNotifications' in shipment) {
		newState.formNewQuoteDetails.shippingNotificationEmails = shipment.shippingNotifications?.tracking?.shippingNotificationEmails.join(
			', ',
		)
	}

	sos.replace(newState)
	return newState
}

export async function fetchShipment(
	shipmentId: string,
): Promise<apiTypes.ShipmentResponse | apiTypes.BrokerShipmentResponse> {
	const onChangeRequest = (
		rs: IRequestState<
			apiTypes.ShipmentResponse | apiTypes.BrokerShipmentResponse
		>,
	): void => {}
	let result: IRequestState<
		apiTypes.ShipmentResponse | apiTypes.BrokerShipmentResponse
	>
	if (sosUser.isUserBroker()) {
		result = await apiBroker.fetchBrokerShipment(onChangeRequest, shipmentId)
	} else {
		result = await apiShipments.fetchShipment(onChangeRequest, shipmentId)
	}
	if (result.data) {
		if (result.data.expectedMode === 'parcel') {
			return result.data
		} else {
			await setStateFromShipmentRequest(result.data)

			return null
		}
	}
	if (result.error) {
		throw new Error(result.error)
	}
}

export async function setRelationshipAndRelatedLocations(
	relationshipId: string,
): Promise<void> {
	if (!relationshipId) {
		setDataFetchErrors(
			'Without an organization relationship, this page will not function properly.' +
				' Please restart the workflow and ensure that you select an organization relationship.',
		)
		return
	}
	const relationshipResponse = await sosUser.fetchOrganizationRelationship(
		relationshipId,
	)
	if (relationshipResponse.error) {
		throw new Error(
			relationshipResponse.error.message ||
				`Unable to retrieve organization relationship with id ${relationshipId}`,
		)
	}
	sos.change((ds) => {
		ds.contactInfo = relationshipResponse.data.firstPartyContactInformation
	})
	await sosUser.fetchFirstPartyLocationInfo(relationshipResponse.data.id)
	await onSelectLocation(
		sosUser.getSos().getState().locationId,
		updatePickupAddress,
		undefined,
		undefined,
		'pickup',
	)
}

export async function setRelationshipIdAndOrderNumber(changes: {
	vendorOrderNumber: string
	vendorCompanyRelationshipId: string
}): Promise<void> {
	const relationshipChange =
		sos.getState().organizationRelationshipId !==
		changes.vendorCompanyRelationshipId
	sos.change((ds) => {
		ds.formNewQuoteDetails.pickTicketNumber = changes.vendorOrderNumber
		ds.organizationRelationshipId = changes.vendorCompanyRelationshipId
	})
	if (relationshipChange) {
		await setRelationshipAndRelatedLocations(
			changes.vendorCompanyRelationshipId,
		)
	}
}

export async function getMarketRateForBroker(): Promise<void> {
	sos.change((ds) => {
		ds.brokerGetMarketRateIsSpinning = true
	})
	const state = sos.getState()
	const pickupAddress: IAddress = _idx(
		() => state.stops[0].addressState.address,
	)
	const destinationAddress: IAddress = _idx(
		() => state.stops[state.stops.length - 1].addressState.address,
	)
	const response = await apiRates.getMarketRate(() => {}, {
		origin: pickupAddress,
		destination: destinationAddress,
		equipmentType: state.formNewQuoteDetails.equipmentTypes as any,
	})

	sos.change((ds) => {
		ds.brokerGetMarketRateIsSpinning = false
	})

	if (!response.error) {
		sos.change((ds) => {
			ds.brokerSellOfferForm.marketBuyPrice = toDollarsFormatted(
				_idx(() => response.data.historicalRates[0].averageTotalRate),
				'cents',
			)

			const selectedConfig = sosUser.getSos().getState().selectedClientConfig
			ds.brokerSellOfferForm.marketSellPrice = toDollarsFormatted(
				applyDefaultMarkupLogic(
					_idx(() => response.data.historicalRates[0].averageTotalRate),
					selectedConfig.defaultMarkupLogic,
				),
				'cents',
			)
		})
	}
}

const createBrokerOffer = async (shipmentId: string): Promise<boolean> => {
	if (!sosUser.isUserBroker()) {
		return true
	}

	const brokerOfferForm = sos.getState().brokerSellOfferForm
	if (
		l.isNil(brokerOfferForm.sellPrice && l.isNil(brokerOfferForm.transitDays))
	) {
		return true
	}

	const response = await apiBroker.createBrokerOffer(() => {}, shipmentId, {
		offerType: 'flatOffer',
		flatOffer: {
			grossRate: toCents(Number(brokerOfferForm.sellPrice)),
		},
		markupLogic: null,
		transit: !l.isNil(brokerOfferForm.transitDays)
			? Number(brokerOfferForm.transitDays)
			: null,
		quoteNumber: null,
		clientStatus: 'not-sent',
	})

	return !response.error
}

export const saveAddress = async (
	addressState: IAddressState,
): Promise<boolean> => {
	let addressBookId: string

	if (sosUser.isUserBroker()) {
		addressBookId = sosUser.getCurrentClientConfig().addressBookId
	} else {
		const response = await apiAddressBook.getAddressBooks(() => {})

		if (response.error) {
			sosToast.sendApiErrorResponseToast(response.error)
		} else {
			addressBookId = response.data[0]?.id
		}
	}

	const currentId = addressState.id
	sos.change((ds) => {
		ds.isSubmitting = true
	})
	return apiAddressBook
		.updateAddressBookEntry(
			'upsert',
			addressBookId,
			{
				address: addressState.address,
				id: addressState.isEditing ? addressState.id : undefined,
			},
			() => {},
		)
		.then(({ error, data }) => {
			sos.change((ds) => {
				ds.stops.forEach((stop) => {
					if (stop.addressState.id === currentId) {
						stop.addressState.id = data.id
						stop.addressState.isEditing = true
					}
				})
			})
			return !error
		})
		.finally(() => {
			sos.change((ds) => {
				ds.isSubmitting = false
			})
		})
}

export function updateTotalLinearFeet(newVal: string): void {
	const numberVal = toInteger(newVal)
	sos.change((ds) => {
		ds.linearFeet = Number.isNaN(numberVal) ? 0 : numberVal
	})
}

export const updateCustomerShipNotification = (emails: string): void => {
	sos.change((ds) => {
		ds.formNewQuoteDetails.shippingNotificationEmails = emails
	})
}

// TMS2 remote procedure call
global['sosNewQuote_setVendorInfo'] = setRelationshipIdAndOrderNumber
