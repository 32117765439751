import { ITransformationInformation } from 'ui/data/lightningIntegration/lightning_inbound'
import { l } from 'ui/lib/lodashImports'
import { requiredColumnsRfp } from 'ui/state/sosRfpDataUpload'
import { toWeight } from 'ui/lib/numbers/toNumber'
import { toPostalCode } from 'ui/lib/addresses'

export const rfpUploadValidations: ITransformationInformation[] = l.map(
	requiredColumnsRfp,
	(column) => {
		const validation: ITransformationInformation = {
			required: true,
			value_from_field: column,
		}

		if (column === 'total_weight' || column === 'weight_per_package') {
			validation.data_cleanup_method = toWeight
		} else if (column === 'origin_zip') {
			validation.data_cleanup_method = (
				zip: string,
				record: { [key: string]: string },
			) => toPostalCode(zip, record?.origin_country)
		} else if (column === 'destination_zip') {
			validation.data_cleanup_method = (
				zip: string,
				record: { [key: string]: string },
			) => toPostalCode(zip, record?.destination_country)
		}

		return validation
	},
)
