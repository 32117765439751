import { DateTime } from 'luxon'
import { apiFeatureToggles } from 'ui/api'
import { IFeatureToggleList } from 'ui/components/permissions'
import { fireAndForget } from 'ui/lib/async'
import { l } from 'ui/lib/lodashImports'
import { createSos2 } from 'ui/lib/state/sos2/sos2'
import { inJestUnitTest } from 'ui/lib/testing/inJestUnitTest'
import { isInDev } from 'ui/theme/theme'

const disableFetchingFeatureTogglesForDevelopment = true
const minutesFeatureToggleIsValid = 5

export interface IStateFeatureToggles {
	featureToggles: IFeatureToggleList
}

const permissionsList = l.uniqBy(
	l.sortBy(
		[
			'Test:*',
			'Test:ComponentExamples:*',
			'Test:ComponentExamples:Show',
			'Test:ComponentExamples:Example1',
			'Test:ComponentExamples:Example2',
		],
		(c) => c,
	),
	(d) => d.toLowerCase(),
)

const sos = createSos2<IStateFeatureToggles>('sosFeatureToggles', 6, {
	featureToggles: { default: {}, localStorage: false },
})

export const checkFeatureToggle = (featureToggle: string): boolean => {
	const state = sos.getState()

	const checkedFeatureToggle = state.featureToggles[featureToggle]

	if (
		checkedFeatureToggle &&
		DateTime.fromISO(checkedFeatureToggle.lastChecked).diffNow('minutes')
			.minutes > -minutesFeatureToggleIsValid
	) {
		return checkedFeatureToggle.enabled || false
	} else {
		if (
			inJestUnitTest() ||
			(isInDev() && disableFetchingFeatureTogglesForDevelopment)
		) {
			sos.change((ds) => {
				ds.featureToggles[featureToggle] = {
					enabled: true,
					lastChecked: DateTime.local().toISO(),
				}
			})
			return true
		} else {
			fireAndForget(async () => {
				await _fetchFeatureToggleThrottled(featureToggle)
			}, `fetching feature toggle ${featureToggle}`)

			// SINCE THIS FUNCTION CANNOT BE ASYNC CURRENTLY (due to how our links use this function), WHEN WE GET TO THIS ELSE BLOCK, WE HAVE TO RETURN WITHOUT KNOWING THE ACTUAL FEATURE TOGGLE
			// ANYTHING THAT USES THIS FUNCTION SHOULD ALSO SUBSCRIBE TO sosFeatureToggles, SO WHEN _fetchFeatureToggleThrottled FINISHES AND UPDATES THIS SOS, THE COMPONENT THAT DEPENDS ON THE FEATURE TOGGLE
			// WILL RE-RENDER ONCE WE HAVE OUR RESULT FROM THE API
			return undefined
		}
	}
}

const _fetchFeatureToggle = async (featureToggle: string): Promise<void> => {
	const result = await apiFeatureToggles.fetchFeatureToggles(featureToggle)
	if (result.data) {
		const featureToggleSet: IFeatureToggleList = {}
		featureToggleSet[featureToggle] = {
			enabled: result.data.features[featureToggle],
			lastChecked: DateTime.local().toISO(),
		}
		sos.change((ds) => {
			l.assign(ds.featureToggles, featureToggleSet)
		})
	}
}

const recentlyFetchedFeatureToggles: string[] = []

const _fetchFeatureToggleThrottled = async (
	featureToggle: string,
): Promise<void> => {
	if (!l.includes(recentlyFetchedFeatureToggles, featureToggle)) {
		await _fetchFeatureToggle(featureToggle)
		recentlyFetchedFeatureToggles.push(featureToggle)
		setTimeout(() => {
			l.pull(recentlyFetchedFeatureToggles, featureToggle)
		}, 5000)
	}
}

export const setDevFeatureToggleOverride = (
	featureToggle: string,
	enabled: boolean,
): void => {
	sos.change((ds) => {
		ds.featureToggles[featureToggle] = {
			enabled: enabled,
			lastChecked: DateTime.local().toISO(),
		}
	})
}

export { sos }
export { permissionsList }
