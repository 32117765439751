import { FC } from 'app/FunctionalComponent'
import React, { useState } from 'react'
import { Button } from 'ui/components/common/button'
import { Checkbox } from 'ui/components/common/checkbox/Checkbox'
import { Input } from 'ui/components/common/input'
import { Modal } from 'ui/components/common/modal/Modal'
import { Separator } from 'ui/components/common/separator'
import { SmallButton } from 'ui/components/common/small-button/SmallButton'
import { StackedItem } from 'ui/components/form/StackedItem'
import { t } from 'ui/components/i18n/i18n'
import { Spacer } from 'ui/components/layout/spacer'
import { ICarrier } from 'ui/lib/contracts/ICarrier'
import { l } from 'ui/lib/lodashImports'
import { sosRfpAnalysisPage } from 'ui/state'
import * as classes from './RfpAnalysisPage.module.scss'

export const RfpContractSelector: FC = (props: { carrierList: ICarrier[] }) => {
	const tPrefix = 'page.rfpAnalysis'
	const [contractSelectorOpen, setContractSelectorOpen] = useState(false)
	const [search, setSearch] = useState('')
	const renderCarrierSelectorCheckboxGroup = (
		carrier: ICarrier,
	): React.ReactElement => {
		return (
			<div key={carrier.id || carrier.name}>
				<Checkbox
					value={l.every(carrier.contracts, (c) => c.includedInAnalysis)}
					indeterminate={
						l.some(carrier.contracts, (c) => c.includedInAnalysis) &&
						!l.every(carrier.contracts, (c) => c.includedInAnalysis)
					}
					onChange={(checked: boolean) => {
						sosRfpAnalysisPage.toggleCarrier(carrier, checked)
						l.forEach(carrier.contracts, (c) => {
							sosRfpAnalysisPage.toggleContract(carrier, c, checked)
						})
					}}
					testId={'carrierSelect-' + carrier.name}
				>
					{carrier.name}
				</Checkbox>

				<div className={classes.contracts}>
					{l.map(carrier.contracts, (contract, carrierIdx) => (
						<div key={contract.id || carrierIdx}>
							<div>
								<Checkbox
									value={
										carrier.includedInAnalysis && contract.includedInAnalysis
									}
									onChange={(checked: boolean) => {
										sosRfpAnalysisPage.toggleContract(
											carrier,
											contract,
											checked,
										)
									}}
									testId={'carrierSelect-' + carrier.name + '-' + carrierIdx}
								>
									{contract.name}
								</Checkbox>
							</div>
						</div>
					))}
				</div>
			</div>
		)
	}

	const areNamesSameish = (a: string, b: string): boolean => {
		// Checks whether a containes b; used to filter contracts in RFP contract selector
		if (l.isNil(a) || l.isNil(b)) {
			return false
		}
		return a.toLowerCase().indexOf(b.toLowerCase()) !== -1
	}

	const filteredCarrierList = l.filter(props.carrierList, (c) =>
		areNamesSameish(c.name, search),
	)
	const modalContent = (): React.ReactElement => (
		<div>
			<div>
				<StackedItem label={t('searchForContractsAndCarriers', tPrefix)}>
					<Input
						testId='contractSelectorInput'
						value={search}
						onChange={(newVal) => {
							setSearch(newVal)
						}}
					/>
				</StackedItem>
				<SmallButton
					color='gray'
					onClick={() => sosRfpAnalysisPage.toggleAllCarriers(true)}
				>
					{t('selectAll', tPrefix)}
				</SmallButton>
				<SmallButton
					color='gray'
					onClick={() => sosRfpAnalysisPage.toggleAllCarriers(false)}
				>
					{t('selectNone', tPrefix)}
				</SmallButton>
			</div>
			<Spacer />
			<div className={classes.contractsContainer}>
				{l.map(filteredCarrierList, (carrier, carrierIdx) => (
					<div key={carrier.id || carrierIdx}>
						{renderCarrierSelectorCheckboxGroup(carrier)}
						{carrierIdx !== filteredCarrierList.length - 1 && <Separator />}
					</div>
				))}
			</div>
			<Separator />
			<div className={classes.right}>
				<Button
					color='gray'
					onClick={() => setContractSelectorOpen(false)}
					testId='closeRfpContractFilter'
				>
					{t('close', 'page.rfpAnalysis')}
				</Button>
			</div>
		</div>
	)

	return (
		<div>
			<Modal
				title='Contracts'
				onModalClose={() => {
					setContractSelectorOpen(false)
				}}
				isOpen={contractSelectorOpen}
				content={modalContent}
			/>
			<SmallButton
				onClick={() => {
					setContractSelectorOpen(true)
				}}
				color={'blue'}
				testId={'contract-selector'}
			>
				{t('page.rfpAnalysis.contractSelector')}
			</SmallButton>
		</div>
	)
}
