import { l } from '../lodashImports'

const toNumber = (input: string | number): number => {
	if (l.isNil(input)) {
		return null
	}

	const sanitizedInput = input
		.toString()
		.replace(/,/g, '')
		.trim()
	const outputNumber = Number(sanitizedInput)
	return isNaN(outputNumber) ? null : outputNumber
}

export const toInteger = (input: string | number): number => {
	const sanitizedInput = toNumber(input)
	return sanitizedInput && Math.round(sanitizedInput)
}

export const toFloat = (input: string | number, precision?: number): number => {
	let number = toNumber(input)
	if (precision && number) {
		number = toInteger(number * 10 ** precision) / 10 ** precision
	}
	return number
}

export const toCents = (dollars: string | number): number => {
	const sanitizedDollars = toDollars(dollars, 'dollars')
	return sanitizedDollars && toInteger(sanitizedDollars * 100)
}

export const toDollars = (
	input: string | number,
	from: 'dollars' | 'cents' = 'cents',
): number => {
	if (l.isNil(input)) {
		return null
	}

	if (from === 'cents') {
		const sanitizedCents = toInteger(input)
		return sanitizedCents && sanitizedCents / 100
	} else {
		const sanitizedInput = input.toString().replace(/\$/g, '')
		return toFloat(sanitizedInput, 2)
	}
}

export const toDollarsFormatted = (
	input: string | number,
	from: 'dollars' | 'cents' = 'cents',
	commas = true,
): string => {
	const dollars = toDollars(input, from)
	if (l.isNil(dollars)) {
		return null
	}
	const dollarAmount = dollars.toString().split('.')[0]
	const centsAmount = dollars.toString().split('.')[1]
	const dollarAmountCharacters = dollarAmount.split('')
	let formattedCentsAmount = centsAmount ? '.' + centsAmount : ''
	if (formattedCentsAmount.length === 2) {
		formattedCentsAmount += '0'
	}
	if (commas) {
		for (let i = dollarAmount.length - 3; i > 0; i -= 3) {
			dollarAmountCharacters.splice(i, 0, ',')
		}
	}
	const formattedDollarAmount = dollarAmountCharacters.join('')
	return '$' + formattedDollarAmount + formattedCentsAmount
}

export const toWeight = (input: number | string): number => {
	if (l.isNil(input)) {
		return null
	}

	const sanitizedInput = input
		.toString()
		.toLowerCase()
		.replace('lbs.', '')
		.replace('lbs', '')
	return toInteger(sanitizedInput)
}
