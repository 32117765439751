import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { IRequestState } from 'ui/api/requestState'
import { t } from 'ui/components/i18n/i18n'
import { DataTable } from 'ui/components/table/DataTable'
import { DataTableUpdate } from 'ui/components/table/DataTableUpdate'
import { IDataTableHeader } from 'ui/components/table/IDataTableHeader'
import { IDataTableState } from 'ui/components/table/IDataTableState'
import { IAccountingCodeCatalogForm } from 'ui/forms/formAccountingCodeCatalog'
import { IAccountingCodeCatalog } from 'ui/lib/books/IAccountingCodeCatalog'
import { sosAccountingCodeCatalog } from 'ui/state'
import { navigateToAccountingCodeCatalog } from 'ui/state/sosAccountingCodeCatalogEntry'
import { UpdateAccountingCodeCatalogForm } from '.'

export const AccountingCodeCatalogDataTable: FC = (props: {
	requestAddEditAccountingCodeCatalog: IRequestState<IAccountingCodeCatalog>
	requestGetAccountingCodeCatalogs: IRequestState<IAccountingCodeCatalog[]>
	dataTableState: IDataTableState<IAccountingCodeCatalog>
	data: IAccountingCodeCatalog[]
	form: IAccountingCodeCatalogForm
}) => {
	const {
		requestGetAccountingCodeCatalogs,
		requestAddEditAccountingCodeCatalog,
	} = props
	const { dataTableState } = props
	const headers: IDataTableHeader<IAccountingCodeCatalog>[] = [
		{
			field: 'id',
		},
		{
			field: 'name',
		},
		{
			field: 'pageType',
		},
	]
	return (
		<div>
			<DataTable
				testId='data-table-books'
				headers={headers}
				tPrefix='shared.accountingCodeCatalog.bookTable'
				isLoading={
					requestGetAccountingCodeCatalogs.isFetching ||
					requestAddEditAccountingCodeCatalog.isFetching
				}
				data={props.data}
				state={dataTableState}
				onEditRow={(row) => {
					sosAccountingCodeCatalog.catalogsTableSetUpdateMode('edit', row)
				}}
				onToggleHeader={(catalog) => {
					sosAccountingCodeCatalog.catalogsTableToggleHeader(catalog.field)
				}}
				onClickHeader={(header) => {
					sosAccountingCodeCatalog.catalogsTableSort(header.field)
				}}
				onRefresh={async () => {
					await sosAccountingCodeCatalog.getCatalogs()
				}}
				onClickRow={async (row) => {
					navigateToAccountingCodeCatalog({ bookId: row.id })
				}}
				onAddRow={() => {
					sosAccountingCodeCatalog.catalogsTableSetUpdateMode('add')
				}}
				addLabel={t('page.manageAccountingCodeCatalogs.bookTable.add')}
				rendererAdd={() => (
					<DataTableUpdate error={props.dataTableState.error}>
						<UpdateAccountingCodeCatalogForm mode='add' form={props.form} />
					</DataTableUpdate>
				)}
				rendererEdit={(row) => (
					<DataTableUpdate
						error={props.dataTableState.error}
						onDeleteRow={async () => {
							await sosAccountingCodeCatalog.updateCatalog('delete')
						}}
					>
						<UpdateAccountingCodeCatalogForm mode='edit' form={props.form} />
					</DataTableUpdate>
				)}
			/>
		</div>
	)
}
