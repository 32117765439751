export async function asyncMapParallel<T, K>(
	array: T[],
	iteratee: (value: T, index: number) => Promise<K>,
): Promise<K[]> {
	const promises: Promise<any>[] = []
	for (let index = 0; index < array.length; index++) {
		const value = array[index]
		promises.push(iteratee(value, index))
	}
	const results = await Promise.all<K>(promises)
	return results
}
