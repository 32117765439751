import React from 'react'
import { InfiniteLoader } from 'ui/components/common/loader/InfiniteLoader'
import { t } from 'ui/components/i18n/i18n'
import { l } from 'ui/lib/lodashImports'

import { DataTableBody } from './DataTableBody'
import { DataTableHeaderRows } from './DataTableHeaderRows'
import { DataTableInteractionOptions } from './DataTableInteractionOptions'
import { formatUtils } from './formatUtils'
import { IDataTableHeader } from './IDataTableHeader'
import { IDataTableHeaderGroup } from './IDataTableHeaderGroup'
import { IDataTableHeaderGroupProcessed } from './IDataTableHeaderGroupProcessed'
import { IDataTableHeaderProcessed } from './IDataTableHeaderProcessed'
import { IDataTableState } from './IDataTableState'
import { Row, Table, TableBody } from './Table'
import classes from './TableStyles.module.scss'
import { addClass, FontSizes } from 'ui/theme/theme'
import { theme } from 'ui/theme'
import { LoadingStatusType } from 'ui/pages/shipments/state/delayedTask'
import { getDefaultDateState, IDatePickerState } from 'ui/pages/shipments'
import { FlexRow } from '../layout/flexRow'

const defaultProps = {
	spacerCell: true,
}
export const DataTable = <T extends any>(props: {
	data: T[]
	state: IDataTableState<T>
	loadingStatus?: LoadingStatusType
	isPreLoading?: boolean
	isLoading?: boolean
	hideLoading?: boolean
	tPrefix?: string
	headers: IDataTableHeader<T>[]
	headerGroups?: IDataTableHeaderGroup<T>[]
	onClickRow?: (row: T) => void
	onClickRowZoom?: (row: T) => void
	onEditRow?: (row: T) => void
	rendererEdit?: (row: T) => React.ReactNode
	onAddRow?: () => void
	onResetTableHeaders?: () => void
	onDeleteRow?: () => void
	addLabel?: React.ReactNode
	rendererAdd?: () => React.ReactNode
	onToggleHeader?: (header: IDataTableHeader<T>) => void
	onClickHeader?: (header: IDataTableHeader<T>) => void
	onToggleSort?: (header: IDataTableHeader<T>) => void
	onClearFilter?: (headerKey: keyof T) => void
	onChangeDateFilter?: (
		headerKey: keyof T,
		changes: Partial<IDatePickerState>,
	) => void
	onClickHeaderGroup?: (headerGroup: IDataTableHeaderGroup<T>) => void
	onToggleHeaderActionBox?: (headerKey: keyof T) => void
	onChangeFilter?: (headerKey: keyof T, value: string) => void
	onRefresh?: () => void
	testId?: string
	onExport?: () => void
	exportTestId?: string
	customToolbar?: React.ReactNode
	spacerCell?: boolean
	fontSize?: FontSizes
	highlightedRows?: string[]
	fadedRowIdxs?: number[]
	customSearchTemplate?: React.ReactNode
	title?: string
	rowClassName?: string
}): React.ReactElement => {
	const { tPrefix, headers, headerGroups, data } = props
	const { hiddenHeaders, sortOn, sortReversed, filterChips } = props.state

	const isEmpty = !data || l.isEmpty(data)

	// const onClickRow = (row: T) => {
	//   if (props.onClickRow) {
	//     props.onClickRow(row)
	//   }
	// }

	const processedHeaders: IDataTableHeaderProcessed<
		T
	>[] = formatUtils.applyFormatFunctions(headers, filterChips)
	const processedHeaderGroups: IDataTableHeaderGroupProcessed<
		T
	>[] = l.cloneDeep(headerGroups)

	// if a column doesn't have a colorIndex, it should inherit its headerGroup's colorIndex
	let indexInHeaders = 0
	l.forEach(processedHeaderGroups, (c) => {
		if (c.colorIndex) {
			for (let i = indexInHeaders; i < indexInHeaders + c.colSpan; i++) {
				if (!processedHeaders[i].colorIndex) {
					processedHeaders[i].colorIndex = c.colorIndex
				}
			}
		}
		indexInHeaders += c.colSpan
	})

	l.forEach(hiddenHeaders, (c) => {
		const found = l.find(processedHeaders, (d) => d.field === c)
		if (found) {
			found.hide = true
		}
		// TODO: add support for other types of headers?
	})

	l.forEach(processedHeaders, (c) => {
		c.sorted = c.field === sortOn
		c.sortedReverse = sortReversed

		if (c.filterSelector === 'date') {
			c.filterDatePickerState = getDefaultDateState('on')
		}

		const existingChip = l.find(
			filterChips,
			(chip) => chip.columnKey === c.field,
		)
		if (existingChip) {
			c.showActionsModal = existingChip.isOpen
			c.filterValue = existingChip.filterValue
			c.filterDatePickerState =
				existingChip.filterDatePickerState || getDefaultDateState('on')
		}

		if (!c.hide) {
			c.hide = false
		}
	})

	let columnCount = l.reduce(
		processedHeaders,
		(sum, n) => sum + (n.hide ? 0 : 1),
		0,
	)
	if (props.onEditRow) {
		columnCount++
	}
	if (props.onClickRowZoom) {
		columnCount++
	}
	columnCount++

	const filteredHeaders = l.filter(processedHeaders, (c) => !c.hide)
	const filteredHeaderGroups = l.filter(processedHeaderGroups, (c) => !c.hide)

	// Calculate double-thick lines for headers
	const filteredHeaderGroupStartIndexes = []
	let runningTotal = 0
	filteredHeaderGroups.forEach((c, cIdx) => {
		if (cIdx !== filteredHeaderGroups.length - 1) {
			runningTotal += c.colSpan
			filteredHeaderGroupStartIndexes.push(runningTotal)
		}
	})

	l.forEach(filteredHeaders, (c, cIdx) => {
		if (filteredHeaderGroupStartIndexes.includes(cIdx)) {
			c.className = addClass(classes.firstInHeaderGroup, c.className)
		}
		c.headerClassName = addClass(
			theme.getColorTable(c.colorIndex),
			c.headerClassName,
		)

		c.headerClassName = theme.addClassIf(
			c.columnAlign && c.columnAlign !== 'left',
			classes[c.columnAlign],
			c.headerClassName,
		)
		c.headerClassName = theme.addClass(c.className, c.headerClassName)
	})

	let { isPreLoading, isLoading } = props
	// Override loading status
	if (props.loadingStatus) {
		if (props.loadingStatus === 'preparing') {
			isPreLoading = true
		}
		if (props.loadingStatus === 'in-progress') {
			isLoading = true
		}
	}

	const dataTableInteractionOptions = (
		<DataTableInteractionOptions
			processedHeaders={processedHeaders}
			onToggleHeader={props.onToggleHeader}
			onRefresh={props.onRefresh}
			onAddRow={props.onAddRow}
			addLabel={props.addLabel}
			onReset={props.onResetTableHeaders}
			testId={props.testId}
			tPrefix={props.tPrefix}
			onExport={props.onExport}
			exportTestId={props.exportTestId || 'export-to-csv'}
			customToolbar={props.customToolbar}
			loadingStatus={props.loadingStatus}
			onToggleHeaderActionBox={props.onToggleHeaderActionBox}
			customSearchTemplate={props.customSearchTemplate}
		/>
	)

	return (
		<div data-testid={props.testId}>
			{props.title && (
				<FlexRow mode='space-between' verticalAlign='bottom'>
					<div>{t(props.title, props.tPrefix)}</div>
					{dataTableInteractionOptions}
				</FlexRow>
			)}
			{!props.title && dataTableInteractionOptions}

			{!props.hideLoading && (
				<div className={classes.tableLoader}>
					<InfiniteLoader
						isPreLoading={isPreLoading}
						isLoading={isLoading}
						testId={props.testId + '-infinite-loader'}
					/>
				</div>
			)}

			{props.rendererAdd && props.state.isAdding && (
				<div>{props.rendererAdd()}</div>
			)}

			<Table
				grayed={props.isLoading || props.isPreLoading}
				testId={props.testId + '-table'}
				fontSize={props.fontSize}
			>
				<DataTableHeaderRows
					state={props.state}
					tPrefix={tPrefix}
					filteredHeaders={filteredHeaders}
					filteredHeaderGroups={filteredHeaderGroups}
					onClickRowZoom={props.onClickRowZoom}
					onEditRow={props.onEditRow}
					onClickHeader={props.onClickHeader}
					onClickHeaderGroup={props.onClickHeaderGroup}
					onToggleHeaderActionBox={props.onToggleHeaderActionBox}
					onChangeFilter={props.onChangeFilter}
					onChangeDateFilter={props.onChangeDateFilter}
					onToggleSort={props.onToggleSort}
					spacerCell={props.spacerCell}
					fontSize={props.fontSize}
				/>

				{isEmpty ? (
					<TableBody data-testid={`${props.testId}-body`} testId={props.testId}>
						<Row className={props.rowClassName}>
							<td className={classes.noData} colSpan={columnCount}>
								{props.isLoading || props.isPreLoading
									? t('component.table.loading')
									: t('component.table.noData')}
							</td>
						</Row>
					</TableBody>
				) : (
					<TableBody data-testid={`${props.testId}-body`} testId={props.testId}>
						<DataTableBody
							data={data}
							state={props.state}
							filteredHeaders={filteredHeaders}
							columnCount={columnCount}
							testId={props.testId}
							onClickRowZoom={props.onClickRowZoom}
							onClickRow={props.onClickRow}
							onEditRow={props.onEditRow}
							rendererEdit={props.rendererEdit}
							spacerCell={props.spacerCell}
							highlightedRows={props.highlightedRows}
							fadedRowIdxs={props.fadedRowIdxs}
							rowClassName={props.rowClassName}
						/>
					</TableBody>
				)}
			</Table>
		</div>
	)
}
DataTable.defaultProps = defaultProps
