import { apiTypes } from 'ui/api'
import { l } from '../lodashImports'

export const combineStopData = (
	stops: (apiTypes.OriginStopResponse | apiTypes.DestinationStopResponse)[],
): apiTypes.OriginStopResponse | apiTypes.DestinationStopResponse => {
	const specialInstructions: string[] = []
	const stopData = l.cloneDeep(stops.shift())
	specialInstructions.push(stopData.metaData.specialInstructions)

	stops.forEach(
		(stop: apiTypes.OriginStopResponse | apiTypes.DestinationStopResponse) => {
			for (const [key, value] of Object.entries(
				stop.metaData.accessorials || {},
			)) {
				if (value) {
					stopData.metaData.accessorials[key] = value
				}
			}
			specialInstructions.push(stop.metaData.specialInstructions)
		},
	)

	stopData.metaData.specialInstructions = l
		.compact(specialInstructions)
		.join(', ')

	return stopData
}
