import { IFormData } from './IFormData'
import { l } from 'ui/lib/lodashImports'

export const findFormDataErrors = <T>(
	formData: IFormData<T>,
): Array<{ field: keyof T; value: any; reason: string }> => {
	const errors = []
	const fields = Object.keys(formData.form)
	l.forEach(fields, (field) => {
		const metadata = formData.metadata[field] || {}
		const value = formData.form[field]

		if (metadata.required && !value) {
			errors.push({ field, value, reason: 'required' })
		}
		if (metadata.customValidator) {
			const error: string = metadata.customValidator(value, formData)
			if (error) {
				errors.push({ field, value, reason: error })
			}
		}
	})
	return errors
}

export const validateFormData = <T>(formData: IFormData<T>): boolean => {
	return findFormDataErrors(formData).length === 0
}
