import { IFormMetadataCollection } from 'ui/components/form/IFormMetadata'

export interface IFormTranslate {
	i18nKey: string
	en: string
	// es: string
	// 'es-MX': string
	// pirate: string
}

export const translateFormMetadata: IFormMetadataCollection<IFormTranslate> = {
	i18nKey: {
		label: 'key',
	},
	en: {
		label: 'english',
	},
}
