import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { theme } from 'ui/theme'
import { Colors } from 'ui/theme/theme'
import styles from './Separator.module.scss'

const defaultProps = { color: 'gray' }
export const Separator: FC = (props: { inset?: boolean; color?: Colors }) => {
	let className = styles.separator
	if (props.inset) {
		className += ' ' + styles.inset
	}
	if (props.color) {
		className += ' ' + theme.getBorderColor(props.color)
	}

	return <hr className={className} />
}
Separator.defaultProps = defaultProps

// Alias
export const Hr = Separator
