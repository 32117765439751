import { barkdown } from 'barkdown'
import { DateTime } from 'luxon'
import numeral from 'numeral'
import React from 'react'
import { testingLanguageCodes } from 'ui/components/i18n/state/sosI18n'
import { l } from 'ui/lib/lodashImports'
import { r } from 'ui/lib/ramdaImports'
import { sosTranslate } from 'ui/state/'
import { theme } from 'ui/theme'
import { ndash } from '../common'
import styles from './i18n.module.scss'
import { sosI18n } from './state'

const devVerifyAllArgsAreUsed = theme.isInIframe()

// Internationalized text
export function t(key: string, tPrefix?: string): React.ReactElement {
	if (!key) {
		if (!theme.isInDev()) {
			return <span />
		}
		return (
			<span className={styles.invalidT}>
				This key is not valid: {'[[' + key + ']]'}
			</span>
		)
	}

	if (tPrefix) {
		key = tPrefix + '.' + key
	}
	const text: string = sosI18n.translate(key)

	let className = ''
	if (sosI18n.isRightToLeft()) {
		className += styles.rtl + ' '
	}
	let onClick = null
	if (!text || sosI18n.isTranslateMode()) {
		className += styles.editable + ' '
		onClick = () => {
			if (!text || sosI18n.isTranslateMode()) {
				sosTranslate.openTranslateModal(key)
			}
		}
	}

	let node: React.ReactNode = text
	if (text && text.startsWith('#barkdown')) {
		node = barkdown.render(text)
	}

	if (!node && !theme.isInDev()) {
		const last = key.lastIndexOf('.')
		if (last !== -1) {
			node = key.substring(last + 1)
		}
	}

	return (
		<span
			className={className && className}
			onClick={onClick}
			data-testid={'t-' + key}
		>
			{node || '[[' + key + ']]'}
		</span>
	)
}

export function tIfNotTranslated(key: string): React.ReactElement {
	if (!key) {
		return null
	}
	const text: string = sosI18n.translate(key)

	if (!text) {
		const className = styles.editable
		return (
			<span
				className={className}
				onClick={() => {
					sosTranslate.openTranslateModal(key)
				}}
			>
				{'[[' + key + ']]'}
			</span>
		)
	}
	return null
}

export function tString(key: string, tPrefix?: string): string {
	if (!key) {
		return ''
	}
	if (tPrefix) {
		key = tPrefix + '.' + key
	}
	const text: string = sosI18n?.translate(key)
	return text || '[[' + key + ']]'
}

export function tDate(
	date: string,
	format: Intl.DateTimeFormatOptions = DateTime.DATE_FULL,
): string {
	let luxonDate = DateTime.fromISO(date)

	luxonDate = luxonDate.setLocale(sosI18n.getLocale())

	if (luxonDate.invalidReason) {
		return ndash
	}

	return luxonDate.toLocaleString(format)
}

export function tTime(
	time: string,
	format: Intl.DateTimeFormatOptions = DateTime.TIME_SIMPLE,
): string {
	let luxonDate = DateTime.fromISO(time)

	luxonDate = luxonDate.setLocale(sosI18n.getLocale())

	if (luxonDate.invalidReason) {
		return ndash
	}

	return luxonDate.toLocaleString(format)
}

export function tNumber(n: number, format = '0,0.00'): string {
	return numeral(n).format(format)
}

export function tCurrency(
	amount: number,
	format = 'USD',
	minDecimals = 0,
	maxDecimals = 2,
): string {
	let locale = sosI18n.getLocale()

	if (l.some(testingLanguageCodes, (c) => c === locale)) {
		locale = 'en'
	}

	return new Intl.NumberFormat(locale, {
		style: 'currency',
		currency: format,
	}).format(amount)
}

export function tCurrencyFromCents(
	amount: number,
	format = 'USD',
	minDecimals = 0,
	maxDecimals = 2,
): string {
	return tCurrency(amount / 100, format, minDecimals, maxDecimals)
}

export function testId(_testId: string): React.ReactElement {
	const locale = sosI18n.getLocale()
	if (_testId && (locale === 'test' || locale === 'test-ids')) {
		return <span className={styles.testId}>{_testId}</span>
	}
	return null
}

export const stringReplaceAll = (str: string, a: string, b: string): string => {
	while (str.indexOf(a) !== -1) {
		str = str.replace(a, b)
	}
	return str
}

export function tArgz(
	key: string,
	args: Record<string, any> = {},
	tPrefix?: string,
): any {
	if (!key) {
		return 'This key is not valid'
	}
	if (tPrefix) {
		key = tPrefix + '.' + key
	}
	let text: string = sosI18n.translate(key)
	if (!text) {
		return '[[' + key + ']]'
	}

	const interplify = (c: string): string => '${' + c + '}'

	if (devVerifyAllArgsAreUsed) {
		const missingArgs = r.filter(
			(c) => text.indexOf(interplify(c)) === -1,
			Object.keys(args),
		)
		if (missingArgs.length) {
			return '[[' + key + ':' + missingArgs.join('|') + ']]'
		}
	}

	l.forEach(Object.keys(args), (c) => {
		text = stringReplaceAll(text, interplify(c), '' + args[c])
	})

	const stringIntrpSyntaxMatch = /\$\{([^}]*)\}/gim
	if (text.match(stringIntrpSyntaxMatch)) {
		throw new Error('Translation variable interpolation was unsuccessful.')
	}

	return text
}
