import nanoid from 'nanoid'
import { NewQuoteAddressForm, NewQuotePackageForm } from './newQuoteForms'

export const getParcelDemoShipmentData = (): {
	pickupAddressForm: NewQuoteAddressForm
	deliveryAddressForm: NewQuoteAddressForm
	packagesForm: NewQuotePackageForm[]
} => {
	const pickupAddressForm: NewQuoteAddressForm = {
		name: 'ATTN: Jackie Robinson',
		street1: '3673 Corporate Center Dr',
		street2: '',
		city: 'Earth City',
		state: 'MO',
		zip: '63045',
		country: 'US',
		addressType: null,
		contactName: '',
		phone: '(314) 567-7726',
		email: 'email@email.com',
		locationId: '',
		company: 'Goose Spring',
	}
	const deliveryAddressForm: NewQuoteAddressForm = {
		name: 'Steve',
		street1: '6325 Odana Rd.',
		street2: '#2000',
		city: 'Madison',
		state: 'WI',
		zip: '53719',
		country: 'US',
		addressType: null,
		contactName: '',
		phone: '(855) 737-3444',
		email: 'email@email.com',
		locationId: '',
		company: 'SwanLeap',
	}
	const packagesForm: NewQuotePackageForm[] = [
		{
			quantity: 1,
			reference1: nanoid(),
			reference2: null,
			declaredValue: 0,
			packagePageId: nanoid(),
			type: 'boxes',
			length: 6,
			width: 4,
			height: 3,
			weight: 1,
		},
		{
			quantity: 1,
			reference1: null,
			reference2: null,
			declaredValue: 0,
			packagePageId: nanoid(),
			type: 'boxes',
			length: 6,
			width: 6,
			height: 5,
			weight: 10,
		},
	]

	return { pickupAddressForm, deliveryAddressForm, packagesForm }
}
