import { FC } from 'app/FunctionalComponent'
import { TypeaheadOption } from 'common/components/typeahead/TypeaheadOption'
import _ from 'lodash'
import React from 'react'
import { FilterItem, Filters } from 'ui/components/common/filters'
import { PaperCard } from 'ui/components/common/paperCard'
import { FlexRow } from 'ui/components/layout/flexRow'
import {
	Address,
	AddressEditModeType,
	createDefaultAddressState,
	EditAddressForm,
	SimpleAddress,
} from 'ui/components/shared/address'
import { LocationSelector } from 'ui/components/shared/location-selector/LocationSelector'
import { r } from 'ui/lib/ramdaImports'
import { sos2 } from 'ui/lib/state/sos2'
import * as sosNewQuote from 'ui/pages/new-quote/state/sosNewQuote'
import { Layout } from '../layout'
import { sosTestAddress } from './state'
import { sos } from './state/sosTestAddress'

export const TestAddress1: FC = (props: {
	state: sosTestAddress.IStateTestAddress
}) => {
	const { state } = props
	return (
		<div>
			<FlexRow>
				<div>
					No edit
					<Address
						addressState={r.mergeDeepRight(state.addresses[0], {
							addressEditMode: 'none' as AddressEditModeType,
						})}
						updateAddressState={sosTestAddress.updateAddressState}
					/>
				</div>
				<div>
					Contact edit
					<Address
						addressState={r.mergeDeepRight(state.addresses[0], {
							addressEditMode: 'contact-only' as AddressEditModeType,
						})}
						updateAddressState={sosTestAddress.updateAddressState}
					/>
				</div>
				<div>
					Full edit
					<Address
						addressState={r.mergeDeepRight(state.addresses[0], {
							addressEditMode: 'full' as AddressEditModeType,
						})}
						updateAddressState={sosTestAddress.updateAddressState}
					/>
				</div>
			</FlexRow>
		</div>
	)
}

export const TestLocationDropDown: FC = (props: {
	state: sosTestAddress.IStateTestAddress
}) => {
	const { state } = props
	return (
		<div>
			<LocationSelector
				onChange={async (selected: TypeaheadOption) => {
					await sosNewQuote.onSelectLocation(
						selected.value,
						sosTestAddress.updateAddressState,
						null,
						state.addresses[0].id,
					)
				}}
				includeAll={false}
				onlyShowLeafLocations={false}
			/>
		</div>
	)
}

export const TestAddressValidation: FC = (props: {
	state: sosTestAddress.IStateTestAddress
}) => {
	const { state } = props
	return (
		<div>
			<Address
				addressState={r.mergeDeepRight(state.addresses[0], {
					addressEditMode: 'full' as AddressEditModeType,
				})}
				updateAddressState={sosTestAddress.updateAddressState}
			/>
		</div>
	)
}

export const TestAddressBook: FC = (props: {
	state: sosTestAddress.IStateTestAddress
}) => {
	const { state } = props
	return (
		<div>
			<Address
				addressState={r.mergeDeepRight(state.addresses[0], {
					addressEditMode: 'full' as AddressEditModeType,
				})}
				updateAddressState={sosTestAddress.updateAddressState}
			/>
		</div>
	)
}

export const TestMap: FC = (props: {
	state: sosTestAddress.IStateTestAddress
}) => {
	const { state } = props

	const addressStateExample = createDefaultAddressState(
		{
			name: 'Bob',
			street1: '',
			street2: '',
			street3: '',
			city: '',
			state: '',
			zip: '',
			country: 'US',
		},
		'customAddress',
	)
	return (
		<div>
			<Address
				showMap={true}
				addressState={state.addresses[0]}
				updateAddressState={sosTestAddress.updateAddressState}
			/>
			<hr />
			No address example
			<Address
				showMap={true}
				addressState={addressStateExample}
				updateAddressState={sosTestAddress.updateAddressState}
			/>
		</div>
	)
}

export const TestSimpleAddress: FC = (props: {
	state: sosTestAddress.IStateTestAddress
}) => {
	const { state } = props
	return (
		<div>
			<div>
				Simple address
				<SimpleAddress address={state.addresses[0].address} />
			</div>
			<div>
				<PaperCard>
					Simple address w/ no state
					<SimpleAddress
						address={{ state: '', city: 'Madison', zip: '53714', street1: '' }}
					/>
				</PaperCard>
				<PaperCard>
					No address
					<SimpleAddress
						address={{ state: '', city: '', zip: '', street1: '' }}
					/>
				</PaperCard>
				<PaperCard>
					City / State
					<SimpleAddress
						address={{ state: 'WI', city: 'Madison', zip: '', street1: '' }}
					/>
				</PaperCard>
				<PaperCard>
					Zip
					<SimpleAddress
						address={{ state: '', city: '', zip: '53714', street1: '' }}
					/>
				</PaperCard>
				<PaperCard>
					All
					<SimpleAddress
						address={{
							state: 'WI',
							city: 'Madison',
							zip: '53714',
							street1: '',
						}}
					/>
				</PaperCard>
			</div>
		</div>
	)
}

export const TestSimpleEditAddress: FC = (props: {
	state: sosTestAddress.IStateTestAddress
}) => {
	const { state } = props
	return (
		<div>
			Simple address
			<SimpleAddress address={state.addresses[0].address} />
		</div>
	)
}

export const TestEditAddressForm: FC = (props: {
	state: sosTestAddress.IStateTestAddress
}) => {
	const { state } = props
	return (
		<div>
			Edit address form
			<FlexRow>
				<div>
					<EditAddressForm
						addressState={state.addresses[0]}
						updateAddressState={sosTestAddress.updateAddressState}
					/>
				</div>
				<div>
					<EditAddressForm
						addressState={state.addresses[1]}
						updateAddressState={sosTestAddress.updateAddressState}
					/>
				</div>
			</FlexRow>
		</div>
	)
}

export const TestBillTo: FC = (props: {
	state: sosTestAddress.IStateTestAddress
}) => {
	const { state } = props
	return (
		<div>
			Bill to address
			<SimpleAddress address={state.addresses[0].address} />
		</div>
	)
}

const filters = {
	TestAddress1,
	TestEditAddressForm,
	TestSimpleAddress,
	TestSimpleEditAddress,
	TestMap,
	TestAddressBook,
	TestAddressValidation,
	TestLocationDropDown,
	TestBillTo,
}

export const TestAddress: FC = () => {
	const state = sos2.useSubscription(sosTestAddress.sos)
	const Selected = filters[state.selectedTest]
	return (
		<Layout>
			<div>
				Test Address Controls
				<Filters>
					{_.map(r.keys(filters), (c) => (
						<FilterItem
							key={c}
							onClick={() => sos.patchDeep({ selectedTest: c })}
							isSelected={state.selectedTest === c}
						>
							{c}
						</FilterItem>
					))}
				</Filters>
				{Selected && <Selected state={state} />}
				<pre>{JSON.stringify(state, null, 2)}</pre>
				{/* <Debug data={state} label='state' /> */}
			</div>
		</Layout>
	)
}
