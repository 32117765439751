import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { PaperCard } from 'ui/components/common/paperCard/PaperCard'
import { PaperCardTitle } from 'ui/components/common/paperCard/PaperCardTitle'
import { t } from 'ui/components/i18n/i18n'
import { FlexItem, FlexRow } from 'ui/components/layout/flexRow'
import { sosComponentExamples } from 'ui/state'
import { Section } from './Section'

export const SectionPaperCard: FC = (props: {
	stateComponentExamples: sosComponentExamples.IStateComponentExamples
}) => {
	return (
		<Section
			title={t('page.componentExamples.paperCard')}
			stateComponentExamples={props.stateComponentExamples}
			name='paper-card'
		>
			<FlexRow>
				<FlexItem>
					<PaperCard>
						<PaperCardTitle>Title One</PaperCardTitle>
						<div style={{ width: '300px' }}>
							Card with title - Lorem ipsum dolor sit amet, consectetur
							adipiscing elit, sed do eiusmod tempor incididunt ut labore et
							dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
							exercitation ullamco laboris nisi ut aliquip ex ea commodo
							consequat. Duis aute irure dolor in reprehenderit in voluptate
							velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
							occaecat cupidatat non proident, sunt in culpa qui officia
							deserunt mollit anim id est laborum.
						</div>
					</PaperCard>
				</FlexItem>

				<FlexItem>
					<PaperCard>
						<PaperCardTitle border={true}>Title With border</PaperCardTitle>
						<div style={{ width: '300px' }}>
							Card with title - Lorem ipsum dolor sit amet, consectetur
							adipiscing elit, sed do eiusmod tempor incididunt ut labore et
							dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
							exercitation ullamco laboris nisi ut aliquip ex ea commodo
							consequat. Duis aute irure dolor in reprehenderit in voluptate
							velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
							occaecat cupidatat non proident, sunt in culpa qui officia
							deserunt mollit anim id est laborum.
						</div>
					</PaperCard>
				</FlexItem>

				<FlexItem>
					<PaperCard>
						<div style={{ width: '400px' }}>
							Simple card - Lorem ipsum dolor sit amet, consectetur adipiscing
							elit, sed do eiusmod tempor incididunt ut labore et dolore magna
							aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
							laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
							dolor in reprehenderit in voluptate velit esse cillum dolore eu
							fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
							proident, sunt in culpa qui officia deserunt mollit anim id est
							laborum.
						</div>
					</PaperCard>
				</FlexItem>

				<FlexItem>
					<PaperCard inset={true}>
						<div style={{ width: '300px' }}>
							Inset Card - Lorem ipsum dolor sit amet, consectetur adipiscing
							elit, sed do eiusmod tempor incididunt ut labore et dolore magna
							aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
							laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
							dolor in reprehenderit in voluptate velit esse cillum dolore eu
							fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
							proident, sunt in culpa qui officia deserunt mollit anim id est
							laborum.
						</div>
					</PaperCard>
				</FlexItem>
			</FlexRow>
		</Section>
	)
}
