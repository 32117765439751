import { l } from 'ui/lib/lodashImports'
import { TRenderInvoiceStatusKey } from 'ui/pages/provider-portal/providerPortalTypes'
import { setSubwayState } from 'ui/state/sosProviderPortalInvoiceProfile'

interface IInvoiceProfileStop {
	topLabel: string
	status: 'success' | 'waiting' | 'fail'
	bottomLabel?: string
}

export const mapSubwayToState = (state): void => {
	l.forEach(invoiceStatusToSubway[state.status], (c) => {
		setSubwayState(c.topLabel, c.status)
	})
}

export const invoiceStatusToSubway: {
	[k in TRenderInvoiceStatusKey]: IInvoiceProfileStop[]
} = {
	waitingOnInvoice: [
		{ topLabel: 'invoiceReceived', status: 'waiting' },
		{ topLabel: 'clearedToPay', status: 'waiting' },
		{ topLabel: 'fundingRequested', status: 'waiting' },
		{ topLabel: 'customerFunded', status: 'waiting' },
		{ topLabel: 'invoicePaid', status: 'waiting' },
	],
	undergoingAudit: [
		{ topLabel: 'invoiceReceived', status: 'success' },
		{ topLabel: 'clearedToPay', status: 'waiting' },
		{ topLabel: 'fundingRequested', status: 'waiting' },
		{ topLabel: 'customerFunded', status: 'waiting' },
		{ topLabel: 'invoicePaid', status: 'waiting' },
	],
	disputed: [
		{ topLabel: 'invoiceReceived', status: 'success' },
		{ topLabel: 'clearedToPay', status: 'fail' },
		{ topLabel: 'fundingRequested', status: 'waiting' },
		{ topLabel: 'customerFunded', status: 'waiting' },
		{ topLabel: 'invoicePaid', status: 'waiting' },
	],
	paid: [
		{ topLabel: 'invoiceReceived', status: 'success' },
		{ topLabel: 'clearedToPay', status: 'success' },
		{ topLabel: 'fundingRequested', status: 'success' },
		{ topLabel: 'customerFunded', status: 'success' },
		{ topLabel: 'invoicePaid', status: 'success' },
	],
	clearedToPay: [
		{ topLabel: 'invoiceReceived', status: 'success' },
		{ topLabel: 'clearedToPay', status: 'success' },
		{ topLabel: 'fundingRequested', status: 'waiting' },
		{ topLabel: 'customerFunded', status: 'waiting' },
		{ topLabel: 'invoicePaid', status: 'waiting' },
	],
	fundingRequested: [
		{ topLabel: 'invoiceReceived', status: 'success' },
		{ topLabel: 'clearedToPay', status: 'success' },
		{ topLabel: 'fundingRequested', status: 'success' },
		{ topLabel: 'customerFunded', status: 'waiting' },
		{ topLabel: 'invoicePaid', status: 'waiting' },
	],
	fundingReceived: [
		{ topLabel: 'invoiceReceived', status: 'success' },
		{ topLabel: 'clearedToPay', status: 'success' },
		{ topLabel: 'fundingRequested', status: 'success' },
		{ topLabel: 'customerFunded', status: 'success' },
		{ topLabel: 'invoicePaid', status: 'waiting' },
	],
}
