import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { AngryRedBox, ErrorBox } from 'ui/components/common/angry-red-box'
import { Icon, solidIcons } from 'ui/components/common/icon'
import { StackedItem } from 'ui/components/form/StackedItem'
import { t } from 'ui/components/i18n/i18n'
import { Spacer } from 'ui/components/layout/spacer/Spacer'
import { IStateComponentExamples } from 'ui/state/sosComponentExamples'
import { Section } from './Section'

export const SectionErrors: FC = (props: {
	stateComponentExamples: IStateComponentExamples
}) => {
	return (
		<Section
			title={t('page.componentExamples.errors')}
			name='errors'
			stateComponentExamples={props.stateComponentExamples}
		>
			<StackedItem label='Angry Red Box with Label' hideOptional={true}>
				<AngryRedBox
					label={
						<div>
							<Icon icon={solidIcons.faCoffee} />
							<Icon icon={solidIcons.faCoffee} />
							<Icon icon={solidIcons.faCoffee} />
							<Icon icon={solidIcons.faCoffee} />
							<Icon icon={solidIcons.faCoffee} />
						</div>
					}
				>
					Oh no an error. Look how angry this box is.
				</AngryRedBox>
			</StackedItem>

			<Spacer />

			<StackedItem label='Error Box with Label' hideOptional={true}>
				<ErrorBox
					label={
						<div>
							<Icon icon={solidIcons.faCoffee} />
							<Icon icon={solidIcons.faCoffee} />
							<Icon icon={solidIcons.faCoffee} />
							<Icon icon={solidIcons.faCoffee} />
							<Icon icon={solidIcons.faCoffee} />
						</div>
					}
					error={{
						status: 500,
						error: 'Oh no an angry. Look how error this box is.',
					}}
				/>
			</StackedItem>
		</Section>
	)
}
