import { FC } from 'app/FunctionalComponent'
import React from 'react'
import {
	addClass,
	addClassIf,
	getBackgroundWithTextColor,
	getBorderColor,
} from 'ui/theme/theme'
import * as classes from '../../dock-scheduler/DockScheduler.module.scss'

export type RowPositionType = 'top' | 'middle' | 'bottom'

export const FifteenMinuteSlotBox: FC = (props: {
	rowPosition: RowPositionType
	isSelected: boolean
}) => {
	let className = addClass(classes.box, getBorderColor('gray'))
	className = addClass(classes.fifteenMinuteSlotBox, className)

	className = addClassIf(
		props.rowPosition === 'top',
		classes.rowWithRoundedTopCorners,
		className,
	)
	className = addClassIf(
		props.rowPosition === 'bottom',
		classes.rowWithRoundedBottomCorners,
		className,
	)

	className = addClassIf(
		props.isSelected,
		getBackgroundWithTextColor('green'),
		className,
	)

	return <div className={className} />
}
