import { sosToast } from 'common/components/toast'
import { apiBroker, apiTypes } from 'ui/api'
import { IRequestState } from 'ui/api/requestState'
import { tString } from 'ui/components/i18n/i18n'
import { l } from 'ui/lib/lodashImports'
import { sosShipmentProfileBroker } from 'ui/pages/shipment-profile/broker'
import { applyDefaultMarkupLogic } from 'ui/pages/shipment-profile/broker/functions'
import { filterRatesForRatesTable } from 'ui/pages/shipment-profile/shared/functions/filterRatesForRatesTable'

export const selectBrokerRate = async (
	rateId: string,
	shipment: apiTypes.BrokerShipmentResponse,
	markupLogic: apiTypes.MarkupLogic,
): Promise<void> => {
	const selectedBrokerRate = l.find(
		filterRatesForRatesTable(shipment.rates),
		(rate) => rate.id === rateId,
	)
	if (selectedBrokerRate) {
		sosShipmentProfileBroker.setRunningApiCall('updatingBrokerOffer')
		let result: IRequestState<apiTypes.BrokerOfferResponse>
		const offer: apiTypes.BrokerOfferResponse = shipment.offers[0]
		if (!offer) {
			result = await apiBroker.createBrokerOffer(
				() => {},
				shipment.id,
				createBrokerOfferRequestFromBuyRate(selectedBrokerRate, markupLogic),
			)
		} else {
			let request: apiTypes.BrokerOfferRequest
			if (offer.flatOffer?.grossRate) {
				request = l.cloneDeep(offer)
				request.brokerRateId = rateId
			} else {
				request = createBrokerOfferRequestFromBuyRate(
					selectedBrokerRate,
					markupLogic,
				)
			}

			result = await apiBroker.updateBrokerOffer(
				() => {},
				shipment.id,
				offer.id,
				request,
			)
		}

		if (result.error) {
			sosToast.sendApiErrorResponseToast(
				result,
				tString('setOfferError', 'page.shipmentProfile'),
			)
		}
		await sosShipmentProfileBroker.fetchShipment(shipment.id)
		sosShipmentProfileBroker.setRunningApiCall('updatingBrokerOffer', false)
	}
}

const createBrokerOfferRequestFromBuyRate = (
	rate: apiTypes.RateResponse,
	markupLogic: apiTypes.MarkupLogic,
): apiTypes.BrokerOfferRequest => {
	return {
		brokerRateId: rate.id,
		offerType: 'markedUpRate',
		flatOffer: {
			grossRate: applyDefaultMarkupLogic(rate.costTotal, markupLogic),
		},
		markupLogic,
		quoteNumber: '',
		transit: rate.transit,
		clientStatus: 'not-sent',
	}
}
