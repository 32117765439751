import { IRequestState } from 'ui/api/requestState'
import { tString } from 'ui/components/i18n/i18n'
import { stripErrorCode } from 'ui/lib'
import { l } from 'ui/lib/lodashImports'
import { createLazySos2 } from 'ui/lib/state/sos2/sos2'
import { ToastState, toast, deleteToast, ToastDispatch } from './Toast'

export interface IStateToasts {
	dispatch: ToastDispatch
}

export const getSos = createLazySos2<IStateToasts>('toasts', 1, () => ({
	dispatch: { default: null },
}))

export const setToastDispatch = (dispatch: ToastDispatch): void => {
	getSos().change((ds) => {
		ds.dispatch = dispatch
	})
}

export const sendToast = (toastState: ToastState): number => {
	const state = l.cloneDeep(toastState)
	if (!state.key) {
		state.key = Date.now()
	}
	toast(getSos().getState().dispatch, state)
	return state.key
}

export const sendApiErrorResponseToast = <T>(
	apiResponse: IRequestState<T>,
	header?: string,
): void => {
	console.log('ERROR MESSAGE: ', apiResponse.error)

	const error = apiResponse.error?.message
		? apiResponse.error.message
		: apiResponse.error ===
		  'SyntaxError: Unexpected token e in JSON at position 0'
		? (apiResponse.error = 'SyntaxError: Error Processing Request')
		: apiResponse.error

	sendToast({
		type: 'danger',
		header: header || tString('error', 'common'),
		body: stripErrorCode(error),
	})
}

export const sendApiWarningResponseToast = <T>(
	apiResponse: IRequestState<T>,
	header?: string,
): void => {
	const error = apiResponse.error?.message
		? apiResponse.error.message
		: apiResponse.error
	sendToast({
		type: 'warning',
		header: header || tString('warning', 'common'),
		body: stripErrorCode(error),
		dataTestId: 'warning-response-toast',
	})
}

export const deleteToastByKey = (key: number): void => {
	deleteToast(getSos().getState().dispatch, key)
}
