import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { IRequestState } from 'ui/api/requestState'
import { t } from 'ui/components/i18n/i18n'
import { DataTable } from 'ui/components/table/DataTable'
import { DataTableUpdate } from 'ui/components/table/DataTableUpdate'
import { IDataTableHeader } from 'ui/components/table/IDataTableHeader'
import { IDataTableState } from 'ui/components/table/IDataTableState'
import { IPackageCatalogEntryForm } from 'ui/forms/formPackageCatalogEntry'
import { IPackageCatalogEntry } from 'ui/lib/books'
import { log } from 'ui/lib/log'
import { sosPackageCatalogEntry } from 'ui/state'
import { UpdatePackageCatalogEntryForm } from './UpdatePackageCatalogEntryForm'

export const PackageCatalogEntryDataTable: FC = (props: {
	fetchPackageCatalogEntries: IRequestState<IPackageCatalogEntry[]>
	requestUpdatePackageCatalogEntry: IRequestState<IPackageCatalogEntry>
	dataTableState: IDataTableState<IPackageCatalogEntry>
	data: IPackageCatalogEntry[]
	form: IPackageCatalogEntryForm
}) => {
	const { fetchPackageCatalogEntries, requestUpdatePackageCatalogEntry } = props
	const { dataTableState } = props
	const headers: IDataTableHeader<IPackageCatalogEntry>[] = [
		{
			field: 'name',
		},
		{
			field: 'length',
		},
		{
			field: 'width',
		},
		{
			field: 'height',
		},
		{
			field: 'barCode',
		},
	]
	return (
		<div>
			<DataTable
				testId='data-table-package-catalog'
				headers={headers}
				tPrefix={'shared.packageCatalog'}
				isLoading={
					fetchPackageCatalogEntries.isFetching ||
					requestUpdatePackageCatalogEntry.isFetching
				}
				data={props.data}
				state={dataTableState}
				onAddRow={() => {
					log('package-catalog', 'status', 'adding')
					sosPackageCatalogEntry.packageCatalogTableSetUpdateMode('add')
				}}
				addLabel={t('shared.packageCatalog.addPackageEntry')}
				onEditRow={(row) => {
					sosPackageCatalogEntry.packageCatalogTableSetUpdateMode('edit', row)
				}}
				onToggleHeader={(c) => {
					sosPackageCatalogEntry.toggleHeader(c.field)
				}}
				onClickHeader={(header) => {
					sosPackageCatalogEntry.sort(header.field)
				}}
				onRefresh={async () => {
					await sosPackageCatalogEntry.fetchPackageCatalogEntries()
				}}
				rendererAdd={() => (
					<DataTableUpdate error={dataTableState.error}>
						<UpdatePackageCatalogEntryForm mode='add' form={props.form} />
					</DataTableUpdate>
				)}
				rendererEdit={(row) => (
					<DataTableUpdate
						error={dataTableState.error}
						onDeleteRow={() =>
							sosPackageCatalogEntry.updatePackageCatalogEntry('delete')
						}
					>
						<UpdatePackageCatalogEntryForm mode='edit' form={props.form} />
					</DataTableUpdate>
				)}
			/>
		</div>
	)
}
