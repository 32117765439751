import { FC } from 'app/FunctionalComponent'
import { DateTime } from 'luxon'
import React from 'react'
import { ExternalLink } from 'ui/components/common/link/ExternalLink'
import { Calendar } from 'ui/components/date-time/Calendar'
import { t } from 'ui/components/i18n/i18n'
import { l } from 'ui/lib/lodashImports'
import { IStateComponentExamples } from 'ui/state/sosComponentExamples'
import { Section } from './Section'

const dateFormats = {
	DATETIME_FULL: DateTime.DATETIME_FULL,
	DATETIME_FULL_WITH_SECONDS: DateTime.DATETIME_FULL_WITH_SECONDS,
	DATETIME_HUGE: DateTime.DATETIME_HUGE,
	DATETIME_HUGE_WITH_SECONDS: DateTime.DATETIME_HUGE_WITH_SECONDS,
	DATETIME_MED: DateTime.DATETIME_MED,
	DATETIME_MED_WITH_SECONDS: DateTime.DATETIME_MED_WITH_SECONDS,
	DATETIME_SHORT: DateTime.DATETIME_SHORT,
	DATETIME_SHORT_WITH_SECONDS: DateTime.DATETIME_SHORT_WITH_SECONDS,
	DATE_FULL: DateTime.DATE_FULL,
	DATE_HUGE: DateTime.DATE_HUGE,
	DATE_MED: DateTime.DATE_MED,
	DATE_SHORT: DateTime.DATE_SHORT,
	TIME_24_SIMPLE: DateTime.TIME_24_SIMPLE,
	TIME_24_WITH_LONG_OFFSET: DateTime.TIME_24_WITH_LONG_OFFSET,
	TIME_24_WITH_SECONDS: DateTime.TIME_24_WITH_SECONDS,
	TIME_24_WITH_SHORT_OFFSET: DateTime.TIME_24_WITH_SHORT_OFFSET,
	TIME_SIMPLE: DateTime.TIME_SIMPLE,
	TIME_WITH_LONG_OFFSET: DateTime.TIME_WITH_LONG_OFFSET,
	TIME_WITH_SECONDS: DateTime.TIME_WITH_SECONDS,
	TIME_WITH_SHORT_OFFSET: DateTime.TIME_WITH_SHORT_OFFSET,
}

export const SectionDates: FC = (props: {
	stateComponentExamples: IStateComponentExamples
}) => {
	return (
		<Section
			title={t('page.componentExamples.dates')}
			name='dates'
			stateComponentExamples={props.stateComponentExamples}
		>
			<div>{t('page.componentExamples.whatDoesYourDateLookLike')}</div>
			<div>
				{t('common.see')}:{' '}
				<ExternalLink href='https://moment.github.io/luxon/docs/manual/tour.html' />
			</div>
			{
				<table>
					<thead>
						<tr>
							<th>Format</th>
							<th>Result</th>
						</tr>
					</thead>
					<tbody>
						{l.map(dateFormats, (c, cIdx) => (
							<tr key={cIdx}>
								<td>{cIdx}</td>
								<td>{DateTime.local().toLocaleString(c)} </td>
							</tr>
						))}
					</tbody>
				</table>
			}
			<Calendar
				dateString={props.stateComponentExamples.customDate}
				onSelectDate={() => {}}
			/>
			<Calendar dateString={'2018-04-01'} onSelectDate={() => {}} />
			<Calendar dateString={'2019-03-01'} onSelectDate={() => {}} />
		</Section>
	)
}
