import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { t, tCurrency, tDate, tNumber } from 'ui/components/i18n/i18n'
import { sosComponentExamples } from 'ui/state/'
import { Section } from './Section'

export const SectionLocalization: FC = (props: {
	stateComponentExamples: sosComponentExamples.IStateComponentExamples
}) => {
	return (
		<Section
			title={t('page.componentExamples.localization')}
			stateComponentExamples={props.stateComponentExamples}
			name='localization'
		>
			<div>Text: {t('page.componentExamples.localizationExample')}</div>
			<div>Date: {tDate('2018-05-15')}</div>
			<div>Number: {tNumber(123456.78)}</div>
			<div>Currency: {tCurrency(34.99)}</div>
		</Section>
	)
}
