import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import { ProviderDetails } from './ProviderDetails'
import { ProviderList } from './ProviderList'

// Route to ProviderList & ProviderDetails here!

export const Providers: FC = () => {
	return (
		<div className='provider details'>
			<Router>
				<Switch>
					<Route path='/marketplace/providers/:id'>
						<ProviderDetails />
					</Route>
					<Route path='/marketplace/providers'>
						<ProviderList />
					</Route>
				</Switch>
			</Router>
		</div>
	)
}
