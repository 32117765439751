import { apiTypes } from 'ui/api'
import { tString } from 'ui/components/i18n/i18n'
import { _idx } from 'ui/lib/_idx'
import { l } from 'ui/lib/lodashImports'
import { tPrefixStatusBoard } from './DockSchedulerStatusBoard'

export const getHandlingUnitDescription = (
	appointment: apiTypes.AppointmentResponse,
): string => {
	let handlingUnitQuantity: number =
		_idx(() => appointment.shipmentInfo.handlingUnits) || 0

	let handlingUnitType: string

	const handlingUnitTypes: apiTypes.AppointmentShipmentInfo['handlingUnitsTypes'] =
		_idx(() => Object.keys(appointment.shipmentInfo.handlingUnitTotals)) || []

	if (handlingUnitTypes.length) {
		if (handlingUnitTypes.length === 1) {
			handlingUnitType = handlingUnitTypes[0]
		} else {
			handlingUnitType = handlingUnitQuantity === 1 ? 'unit' : 'units'
		}

		if (handlingUnitQuantity === 0) {
			l.forEach(handlingUnitTypes, (type) => {
				handlingUnitQuantity +=
					appointment.shipmentInfo.handlingUnitTotals[type].count
			})
		}
	} else {
		handlingUnitType = handlingUnitQuantity === 1 ? 'unit' : 'units'
	}

	let handlingUnitDescription = handlingUnitQuantity
		? '' + handlingUnitQuantity + ' '
		: ''
	handlingUnitDescription += tString(
		handlingUnitType,
		tPrefixStatusBoard + '.handlingUnitType',
	)
	return handlingUnitDescription
}
