import React from 'react'
import { Optional } from '../common/badge'
import { Recommended } from '../common/badge/Recommended'
import classes from './FormStacked.module.scss'

export function StackedItem<T>(props: {
	label: React.ReactNode
	children: React.ReactNode
	hideOptional?: boolean
	showRequired?: boolean
	showRecommended?: boolean
	description?: React.ReactNode
	error?: React.ReactNode
	testId?: string
}): React.ReactElement {
	const {
		label,
		error,
		description,
		showRecommended,
		showRequired,
		hideOptional,
	} = props

	const showOptional = !showRequired && !hideOptional

	return (
		<>
			<div className={classes.item} data-testid={props.testId}>
				<div>
					<label className={classes.label}>
						{label}
						{showOptional && <Optional />}
						{showRecommended && <Recommended />}
					</label>
				</div>
				<div>
					<div>{props.children}</div>
				</div>
				{description && (
					<div className={classes.descriptionRow}>
						<div>
							<div className={classes.description}>{description}</div>
						</div>
					</div>
				)}
				{error && (
					<div className={classes.errorRow}>
						<div className={classes.td}>
							<div className={classes.error}>{error}</div>
						</div>
					</div>
				)}
			</div>
		</>
	)
}
