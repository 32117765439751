import { FC } from 'app/FunctionalComponent'
import React from 'react'
import styles from './ApiExamples.module.scss'

export const MenuSubItem: FC = (props: {
	children: React.ReactNode
	menu: string
	subMenu: string
	isSelected: boolean
	onClick: () => void
}) => {
	let className = styles.subMenuItem
	if (props.isSelected) {
		className += ' ' + styles.selected
	}

	return (
		<div className={className} onClick={props.onClick}>
			{props.children}
		</div>
	)
}
