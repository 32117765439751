import { FC } from 'app/FunctionalComponent'
import React, { useState } from 'react'
import { theme } from 'ui/theme'
import commonStyles from 'ui/theme/common.module.scss'
import { Colors } from 'ui/theme/theme'
import styles from './HoverHighlight.module.scss'

type tooltipPosition = 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight'
const tooltipPositionClassNames: { [k in tooltipPosition]: string } = {
	topLeft: styles.topLeft,
	topRight: styles.topRight,
	bottomLeft: styles.bottomLeft,
	bottomRight: styles.bottomRight,
}

const defaultProps = { tooltipPosition: 'topLeft', color: 'blue' }

export const HoverHighlight: FC = (props: {
	color?: Colors
	tooltip?: React.ReactNode
	tooltipPosition?: tooltipPosition
	children?: React.ReactNode
	onClick?: () => void
	testId?: string
}) => {
	const [showTooltip, setShowTooltip] = useState(false)

	let className = `${styles.highlightBox} ${theme.getHighlightColor(
		props.color,
	)}`
	if (props.onClick) {
		className += ` ${commonStyles.clickable}`
	}

	return (
		<div
			className={className}
			onClick={props.onClick}
			onMouseEnter={() => {
				setShowTooltip(true)
			}}
			onMouseLeave={() => {
				setShowTooltip(false)
			}}
			data-testid={props.testId}
		>
			{props.tooltip && showTooltip && (
				<div
					className={`${styles.tooltipText} ${
						tooltipPositionClassNames[props.tooltipPosition]
					} ${theme.getHighlightTooltip(props.color)}`}
				>
					{props.tooltip}
				</div>
			)}
			{props.children}
		</div>
	)
}
HoverHighlight.defaultProps = defaultProps
