import { FC } from 'app/FunctionalComponent'
import React from 'react'
import ReactModal from 'react-modal'
import { theme } from 'ui/theme'
import { IconButton, solidIcons } from '../icon'
import classes from './Modal.module.scss'

//Documentation: https://reactcommunity.org/react-modal/

// TODO Accessibility: if we don't set ariaHideApp={false} here then we sometimes get errors
// (particularly during unit test) about needing to set the app element for screen readers.
// Setting ariaHideApp removes this problem, but now screen readers will have issues with
// the modal
// https://reactcommunity.org/react-modal/accessibility/

export const Modal: FC = (props: {
	content: () => React.ReactNode
	isOpen: boolean
	onModalClose: () => void
	closeModalX?: boolean
	title: React.ReactNode
	fullScreen?: boolean
}) => {
	let contentClassName = classes.content
	contentClassName = theme.addClassIf(
		props.fullScreen,
		classes.fullScreen,
		contentClassName,
	)

	return (
		<div>
			<ReactModal
				isOpen={props.isOpen}
				onRequestClose={props.onModalClose}
				shouldCloseOnEsc={true}
				shouldCloseOnOverlayClick={true}
				style={{ overlay: { zIndex: 10000 } }}
				ariaHideApp={false}
				className={classes.modal}
			>
				<div className={classes.header}>
					<div className={classes.modalTitle}>{props.title || 'Modal'}</div>

					{props.closeModalX && (
						<div className={classes.closeX}>
							<IconButton
								icon={solidIcons.faTimes}
								onClick={props.onModalClose}
							/>
						</div>
					)}
				</div>
				<div className={contentClassName} data-testid='modalContent'>
					{props.content && props.content()}
				</div>
			</ReactModal>
		</div>
	)
}

export const ModalTitle: FC = (props: { message: React.ReactNode }) => {
	return <div className={classes.modalTitle}>{props.message}</div>
}
