import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { navParentTo } from 'ui/lib/IframeRpc'

export const MagLink: FC = (props: { url: string; id?: string }) => {
	const urlDestination = props.url + props.id
	async function handleclick(e): Promise<void> {
		e.preventDefault()
		await navParentTo(urlDestination)
	}
	return (
		<a href='/' onClick={handleclick}>
			<FontAwesomeIcon icon={faSearch} />
		</a>
	)
}
