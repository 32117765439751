import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { apiTypes } from 'ui/api'
import { Modal } from 'ui/components/common/modal'
import { ElasticSearchPager } from 'ui/components/common/pager'
import { SearchInput } from 'ui/components/common/search'
import { StackedItem } from 'ui/components/form'
import { t } from 'ui/components/i18n/i18n'
import { DataTable } from 'ui/components/table'
import { sos2 } from 'ui/lib/state/sos2'
import { INewQuoteCommoditiesForm } from 'ui/pages/new-quote/INewQuoteCommoditiesForm'
import { productCatalogHeaders } from 'ui/pages/product-catalog'
import { delayedTask } from 'ui/pages/shipments/state'
import { sosProductCatalogModal } from '.'
import classes from './modals.module.scss'

export const ModalProductCatalog: FC = (props: {
	onClickRow?: (id: string, changes: Partial<INewQuoteCommoditiesForm>) => void
}) => {
	const state = sos2.useSubscription(sosProductCatalogModal.sos)

	const delayed = delayedTask.useSubscription(
		sosProductCatalogModal.delayedSearchFor,
	)

	return (
		<Modal
			isOpen={state.isModalOpen}
			closeModalX={true}
			title={t('shared.productCatalog.modalTitle')}
			onModalClose={() => sosProductCatalogModal.showModal(false)}
			content={() => (
				<div className={classes.fullModal}>
					<StackedItem
						label={t('shared.productCatalog.search')}
						showRequired={true}
					>
						<SearchInput
							testId='productCatalog.searchFor'
							value={state.searchFor}
							onChange={sosProductCatalogModal.updateSearchFor}
							width='360px'
						/>
					</StackedItem>

					<DataTable
						testId='product-catalog-modal-data-table'
						headers={productCatalogHeaders}
						spacerCell={false}
						data={state.modalEntries}
						state={{}}
						loadingStatus={delayed.status}
						tPrefix='shared.productCatalog'
						onClickRow={(row) => {
							if (props.onClickRow) {
								props.onClickRow(state.updateCommodityId, {
									length: row.productLength ? '' + row.productLength : '',
									height: row.productHeight ? '' + row.productHeight : '',
									width: row.productWidth ? '' + row.productWidth : '',
									nmfc: row.nmfcCode,
									nmfcSub: row.nmfcSubCode,
									packedWeight: row.productWeight
										? String(row.productWeight)
										: '',
									class: row.freightClass as apiTypes.Container['class'],
									description: row.description,
								})
							}
							sosProductCatalogModal.showModal(false)
						}}
					/>
					<ElasticSearchPager
						pager={state.modalPager}
						onClickPage={sosProductCatalogModal.setCurrentPage}
					/>
				</div>
			)}
		/>
	)
}
