import { apiRfp, apiTypes } from 'ui/api'
import { IRequestState } from 'ui/api/requestState'
import { batch } from 'ui/lib/batch/batch'
import { asyncForEachSerial } from 'ui/lib/async/asyncForEachSerial'
import { asyncForEachParallel } from 'ui/lib/async/asyncForEachParallel'
import { l } from 'ui/lib/lodashImports'
import { toDollarsFormatted } from 'ui/lib/numbers/toNumber'
import { sosRfpAnalysisPage } from 'ui/state'
import { tString } from 'ui/components/i18n/i18n'
import { tPrefixRfp } from './RfpLayout'

interface FormattedShipment {
	uniqueShipmentNumber: string
	pickup: string
	carrierName: string
	weight: string
	class: string
	originCity: string
	originState: string
	originZip: string
	destinationCity: string
	destinationState: string
	destinationsZip: string
	distance: string
	oldCharge: string
	rates: FormattedRate[]
}

interface FormattedRate {
	carrierGrossRate: string
	fuel: string
	accessorialCost: string
	discount: string
	netCharge: string
	contractId?: string
}

interface FormattedContract {
	id: string
	contractNickname?: string
	providerName?: string
}

export async function formatContractName(
	contract: FormattedContract,
): Promise<string> {
	let formattedContract = contract?.providerName
	if (contract?.providerName && contract?.contractNickname) {
		formattedContract += ' -- '
	}
	formattedContract += contract?.contractNickname || ''
	if (formattedContract.includes(',')) {
		// surround comma with quotes so csv export doesn't think to separate this value into 2 columns
		formattedContract = `"${formattedContract}"`
	}
	if (!contract?.providerName && !contract?.contractNickname) {
		formattedContract += '(no provider name given)'
	}
	return formattedContract
}

export const formatShipments = (
	response: IRequestState<apiTypes.RfpShipmentListResponse>,
	formattedContracts: FormattedContract[],
): FormattedShipment[] => {
	return l.map(response.data.entities, (rfpShipment) => {
		const rates = l.cloneDeep(rfpShipment.rates)
		const truckstopRate = l.find(
			rates,
			(rate) =>
				rate.providerName ===
				('truckstop.com' as apiTypes.RateResponse['providerName']),
		)
		const ratesWithoutTruckstop = l.without(rates, truckstopRate)

		const formattedRates: FormattedRate[] = l.map(
			ratesWithoutTruckstop,
			(rate) => {
				let contract: FormattedContract = l.find(
					formattedContracts,
					(c) => c.id === rate.contractId,
				)
				if (!contract && rate.contractId) {
					// add it
					contract = {
						id: rate.contractId,
						contractNickname: rate.contractNickname,
						providerName: rate.carrier || rate.providerName,
					}

					formattedContracts.push(contract)
				}

				const { costTotal, costBreakdown, accessorialCostTotal } = rate
				const fuel = costBreakdown?.fuel || 0
				const discount = costBreakdown?.discount || 0
				const grossRate = costBreakdown?.grossRate || 0

				const formattedRate: FormattedRate = {
					carrierGrossRate: toDollarsFormatted(grossRate, 'cents', false),
					fuel: toDollarsFormatted(fuel, 'cents', false),
					accessorialCost: toDollarsFormatted(
						accessorialCostTotal || 0,
						'cents',
						false,
					),
					discount: toDollarsFormatted(discount, 'cents', false),
					netCharge: toDollarsFormatted(costTotal, 'cents', false),
					contractId: rate.contractId,
				}
				return formattedRate
			},
		)

		if (truckstopRate) {
			formattedRates.push({
				carrierGrossRate: toDollarsFormatted(
					truckstopRate.costBreakdown?.grossRate,
					'cents',
					false,
				),
				fuel: toDollarsFormatted(
					truckstopRate.costBreakdown?.fuel,
					'cents',
					false,
				),
				accessorialCost: toDollarsFormatted(
					truckstopRate.accessorialCostTotal || 0,
					'cents',
					false,
				),
				discount: toDollarsFormatted(
					truckstopRate.costBreakdown?.discount,
					'cents',
					false,
				),
				netCharge: toDollarsFormatted(truckstopRate.costTotal, 'cents', false),
				contractId: 'truckstop.com',
			})
		}

		const formattedShipment: FormattedShipment = {
			uniqueShipmentNumber: rfpShipment.identifier,
			pickup: rfpShipment.bookedRate?.pickup,
			carrierName: rfpShipment.bookedRate?.providerName,
			weight: rfpShipment.totalWeight?.toString(),
			class: rfpShipment.payloads[0]?.containers[0]?.class,
			originCity: rfpShipment.origin?.city,
			originState: rfpShipment.origin?.state,
			originZip: rfpShipment.origin?.zip,
			destinationCity: rfpShipment.destination?.city,
			destinationState: rfpShipment.destination?.state,
			destinationsZip: rfpShipment.destination?.zip,
			distance: rfpShipment.estimatedDistance?.toString(),
			oldCharge: toDollarsFormatted(
				rfpShipment.bookedRate?.costTotal,
				'cents',
				false,
			),

			rates: formattedRates,
		}
		return formattedShipment
	})
}

export const exportRfpShipments = async (
	rfpId: string,
	carrierCount: number,
): Promise<string> => {
	sosRfpAnalysisPage.resetExportCurrentStep()

	let formattedContracts: FormattedContract[] = []
	const formattedShipments: FormattedShipment[] = []

	const take = Math.floor(100 / Math.ceil(carrierCount / 10)) || 1
	const parallelGetRequests = 6

	const query = {
		skip: 0,
		take,
		query: `rfpId:${rfpId}`,
	}
	let response: IRequestState<apiTypes.RfpShipmentListResponse> = await apiRfp.getRfpShipments(
		(rs: IRequestState<apiTypes.RfpShipmentListResponse>) => {},
		query,
		true,
	)

	if (!response.data) {
		// try again
		response = await apiRfp.getRfpShipments(
			(rs: IRequestState<apiTypes.RfpShipmentListResponse>) => {},
			query,
			true,
		)
	}
	const additionalCalls =
		response.data.total > 0 ? Math.ceil(response.data.total / take) - 1 : 0
	sosRfpAnalysisPage.setExportTotalSteps(additionalCalls + 1)

	if (response.data) {
		formattedShipments.push(...formatShipments(response, formattedContracts))
	}
	sosRfpAnalysisPage.increaseExportCurrentStep()

	const skips = []
	for (let i = 1; i <= additionalCalls; i++) {
		skips.push(i * take)
	}

	const skipBatches = batch(skips, parallelGetRequests)
	let missingShipments = 0

	await asyncForEachSerial(skipBatches, async (skipBatch) => {
		await asyncForEachParallel(skipBatch, async (skip) => {
			let additionalCallResponse = await apiRfp.getRfpShipments(
				(rs: IRequestState<apiTypes.ShipmentListResponse>) => {},
				{ skip, take, query: `rfpId:${rfpId}` },
				true,
			)
			if (!additionalCallResponse.data) {
				// try again
				additionalCallResponse = await apiRfp.getRfpShipments(
					(rs: IRequestState<apiTypes.RfpShipmentListResponse>) => {},
					{ skip, take, query: `rfpId:${rfpId}` },
					true,
				)
			}

			if (additionalCallResponse.data) {
				formattedShipments.push(
					...formatShipments(additionalCallResponse, formattedContracts),
				)
			} else {
				missingShipments += take
			}

			sosRfpAnalysisPage.increaseExportCurrentStep()
		})
	})

	const headerGroups = [
		'Shipment Info',
		'',
		'',
		'',
		'',
		'',
		'',
		'',
		'',
		'',
		'',
		'',
		'',
	]
	const headers = [
		'Shipment Number',
		'Pickup Date',
		'Carrier',
		'Weight',
		'Class',
		'Origin City',
		'Origin State',
		'Origin Zip',
		'Destination City',
		'Destination State',
		'Destination Zip',
		'Distance',
		'Old Charge',
	]
	const headersPerContract = [
		'Carrier Gross Rate',
		'Fuel',
		'Accessorial Cost',
		'Discount',
		'Net Charge',
	]

	// sort so the export columns are in the same order every time
	formattedContracts = l.sortBy(formattedContracts, [
		(contract) => contract.providerName + contract.contractNickname,
	])

	const formattedContractNames = await Promise.all(
		formattedContracts.map(async (contract) => formatContractName(contract)),
	)
	formattedContractNames.push('truckstop.com')

	formattedContractNames.forEach((contractName) => {
		const contractHeaders = headersPerContract.map(
			(header) => contractName + ': ' + header,
		)
		headers.push(...contractHeaders)
		const contractHeaderGroup = [contractName, '', '', '', '']
		headerGroups.push(...contractHeaderGroup)
	})

	const shipmentRows: string[][] = l.map(
		formattedShipments,
		(formattedShipment) => {
			const shipmentRow: string[] = [
				formattedShipment.uniqueShipmentNumber,
				formattedShipment.pickup,
				formattedShipment.carrierName,
				formattedShipment.weight,
				formattedShipment.class,
				formattedShipment.originCity,
				formattedShipment.originState,
				formattedShipment.originZip,
				formattedShipment.destinationCity,
				formattedShipment.destinationState,
				formattedShipment.destinationsZip,
				formattedShipment.distance,
				formattedShipment.oldCharge,
			]

			const ratesByContract: { [contractId: string]: FormattedRate } = {}
			l.forEach(formattedContracts, (formattedContract) => {
				const rateForContract = l.find(
					formattedShipment.rates,
					(rate) => rate.contractId === formattedContract.id,
				)

				if (rateForContract) {
					ratesByContract[formattedContract.id] = {
						carrierGrossRate: rateForContract.carrierGrossRate,
						fuel: rateForContract.fuel,
						accessorialCost: rateForContract.accessorialCost,
						discount: rateForContract.discount,
						netCharge: rateForContract.netCharge,
					}
				} else {
					ratesByContract[formattedContract.id] = {
						carrierGrossRate: '',
						fuel: '',
						accessorialCost: '',
						discount: '',
						netCharge: '',
					}
				}
			})

			const truckstopRate = l.find(
				formattedShipment.rates,
				(rate) => rate.contractId === 'truckstop.com',
			)
			if (truckstopRate) {
				ratesByContract['truckstop.com'] = {
					carrierGrossRate: truckstopRate.carrierGrossRate,
					fuel: truckstopRate.fuel,
					accessorialCost: truckstopRate.accessorialCost,
					discount: truckstopRate.discount,
					netCharge: truckstopRate.netCharge,
				}
			} else {
				ratesByContract['truckstop.com'] = {
					carrierGrossRate: '',
					fuel: '',
					accessorialCost: '',
					discount: '',
					netCharge: '',
				}
			}

			l.forEach(ratesByContract, (rate) => {
				shipmentRow.push(...Object.values(rate))
			})

			return shipmentRow
		},
	)

	if (missingShipments > 0) {
		sosRfpAnalysisPage.addToErrors(
			`${missingShipments} ${tString('notIncludedInExport', tPrefixRfp)}`,
		)
	}

	const stringCsv: string =
		headerGroups.join(',') +
		'\n' +
		headers.join(',') +
		'\n' +
		shipmentRows.map((shipment) => shipment.join(',')).join('\n')

	return stringCsv
}
