import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { Colors } from 'ui/theme/theme'
import { Button } from '../button'

const defaultProps = {
	color: 'gray',
}
export const SmallButton: FC = (props: {
	color?: Colors
	type?: 'submit' | 'button'
	onClick?: () => void
	children?: React.ReactNode
	testId?: string
	minWidth?: string
	isSpinning?: boolean
	isDisabled?: boolean
	className?: string
	title?: string
	bootstrapStyles?: boolean
}) => {
	return (
		<Button
			color={props.color}
			type={props.type}
			onClick={props.onClick}
			testId={props.testId}
			minWidth={props.minWidth}
			isSpinning={props.isSpinning}
			isSmall={true}
			isDisabled={props.isDisabled}
			className={props.className}
			title={props.title}
			bootstrapStyles={props.bootstrapStyles}
		>
			{props.children}
		</Button>
	)
}
SmallButton.defaultProps = defaultProps
