import { FC } from 'app/FunctionalComponent'
import { TypeaheadOption } from 'common/components/typeahead/TypeaheadOption'
import React from 'react'
import { LocationSelector } from 'ui/components/shared/location-selector/LocationSelector'
import { fireAndForget } from 'ui/lib/async'
// import { sos2 } from 'ui/lib/state/sos2'
import { sosDockScheduler } from '../state'

export const DockSchedulerLocationSelector: FC = (props: {
	onChange?: (selected: TypeaheadOption) => void
}) => {
	// TO DO FIX NEXT TIME
	// const state = sos2.useSubscription(sosDockScheduler.getSos())

	// let defaultSelected: TypeaheadOption
	// if (state.currentLocation) {
	// 	defaultSelected = {
	// 		value: state.currentLocation.id,
	// 		label: state.currentLocation.name || state.currentLocation.id,
	// 	}
	// }

	return (
		<LocationSelector
			onChange={(selected: TypeaheadOption) => {
				fireAndForget(async () => {
					await sosDockScheduler.updateLocation(selected?.value)
					if (props.onChange) {
						props.onChange(selected)
					}
				}, 'updating location for dock scheduler location selector')
			}}
			preSearch={() => sosDockScheduler.setIsLoadingLocations(true)}
			postSearch={() => sosDockScheduler.setIsLoadingLocations(false)}
			// currentlySelectedLocation={defaultSelected}
			onlyShowLeafLocations={true}
		/>
	)
}
