import React, { useState } from 'react'
import { t } from 'ui/components/i18n/i18n'
import { l } from 'ui/lib/lodashImports'
import { theme } from 'ui/theme'
import commonStyles from 'ui/theme/common.module.scss'

import { IDataTableHeader } from './IDataTableHeader'
import { IDataTableHeaderGroup } from './IDataTableHeaderGroup'
import { IDataTableHeaderGroupProcessed } from './IDataTableHeaderGroupProcessed'
import { IDataTableHeaderProcessed } from './IDataTableHeaderProcessed'
import { IDataTableState } from './IDataTableState'
import { HeaderCell, Row, TableHeader } from './Table'
import classes from './TableStyles.module.scss'
import { addClass, FontSizes, addClassIf } from 'ui/theme/theme'
import { DataTableSortButtons } from 'ui/components/table/DataTableSortButtons'
import { IDatePickerState } from 'ui/pages/shipments'
import { FlexItem } from 'ui/components/layout/flexRow'
import { Input } from 'ui/components/common/input'
import { DateTime } from 'luxon'
import DatePicker from 'react-datepicker'
import { IconButton } from '../common/icon/Icon'
import { solidIcons } from 'ui/components/common/icon'

export const DataTableHeaderRows = <T extends any>(props: {
	state: IDataTableState<T>
	tPrefix?: string
	filteredHeaders: IDataTableHeaderProcessed<T>[]
	filteredHeaderGroups?: IDataTableHeaderGroupProcessed<T>[]
	onEditRow?: (row: T) => void
	onClickRowZoom?: (row: T) => void
	onClickHeader?: (header: IDataTableHeader<T>) => void
	onClickHeaderGroup?: (headerGroup: IDataTableHeaderGroup<T>) => void
	onToggleHeaderActionBox?: (headerKey: keyof T) => void
	onChangeFilter?: (headerKey: keyof T, value: string) => void
	onChangeDateFilter?: (
		headerKey: keyof T,
		changes: Partial<IDatePickerState>,
	) => void
	onToggleSort?: (header: IDataTableHeader<T>) => void
	spacerCell: boolean
	fontSize?: FontSizes
}): React.ReactElement => {
	const { tPrefix, filteredHeaders, filteredHeaderGroups } = props
	const showFilterRow = l.some(filteredHeaders, (c) => c.elasticSearchKey)
	const headerClass = commonStyles.clickable

	const onClickHeader = (header: IDataTableHeader<T>): void => {
		if (props.onClickHeader) {
			props.onClickHeader(header)
		}
	}

	const onClickHeaderGroup = (headerGroup: IDataTableHeaderGroup<T>): void => {
		if (props.onClickHeaderGroup) {
			props.onClickHeaderGroup(headerGroup)
		}
	}

	const renderHeaderLabel = (
		c: IDataTableHeaderProcessed<T>,
		showSorting?: boolean,
	): React.ReactElement => {
		let className = classes.headerLabel
		if (c.headerAlign && c.headerAlign !== 'left') {
			className = addClass(classes[c.headerAlign], className)
		}
		className = addClassIf(
			!l.isNil(props.fontSize),
			commonStyles[props.fontSize],
			className,
		)
		return (
			<div
				style={{
					flexWrap: 'nowrap',
					alignItems: 'center',
					display: 'flex',
				}}
				className={className}
			>
				{showSorting && (
					<DataTableSortButtons
						tableHeaderState={c}
						toggleSort={props.onToggleSort}
					/>
				)}
				{c.t && t(c.t, tPrefix)}
				{!c.t && tPrefix && !c.label && c.field && t('' + c.field, tPrefix)}
				{c.label}
			</div>
		)
	}

	const EditHeader = (props: { borderless?: boolean }): React.ReactElement => {
		const border = props.borderless ? 'none' : undefined
		return (
			<HeaderCell
				key='edit'
				style={{
					width: '2em',
					border,
				}}
			>
				{' '}
			</HeaderCell>
		)
	}

	const ZoomHeader = (props: { borderless?: boolean }): React.ReactElement => {
		const border = props.borderless ? 'none' : undefined
		return (
			<HeaderCell
				key='edit'
				style={{
					width: '2em',
					border,
				}}
			>
				{' '}
			</HeaderCell>
		)
	}

	return (
		<TableHeader>
			{/*header groups*/}
			{filteredHeaderGroups && filteredHeaderGroups.length > 0 && (
				<Row>
					{props.onEditRow && <EditHeader />}
					{props.onClickRowZoom && <ZoomHeader />}

					{l.map(filteredHeaderGroups, (headerGroup, headerGroupIdx) => {
						let headerGroupClass =
							headerClass + ' ' + theme.getColorTable(headerGroup.colorIndex)
						if (headerGroupIdx !== 0) {
							headerGroupClass += ' ' + classes.firstInHeaderGroup
						}

						return (
							<HeaderCell
								className={headerGroupClass}
								key={'hg' + headerGroupIdx}
								colSpan={headerGroup.colSpan}
								onClick={() => {
									onClickHeaderGroup(headerGroup)
								}}
							>
								<div className={classes.headerGroupLabel}>
									{headerGroup.t && t(headerGroup.t, tPrefix)}
									{headerGroup.label}
								</div>
								{headerGroup.renderer && headerGroup.renderer()}
							</HeaderCell>
						)
					})}
				</Row>
			)}

			{/*main headers*/}
			<Row>
				{props.onEditRow && <EditHeader borderless={showFilterRow} />}
				{props.onClickRowZoom && <ZoomHeader borderless={showFilterRow} />}

				{l.map(filteredHeaders, (header, headerIdx) => {
					let headerStyle: React.CSSProperties = {}

					if (header.width) {
						headerStyle = {
							minWidth: header.width,
							width: header.width,
							maxWidth: header.width,
							border: showFilterRow ? 'none' : undefined,
						}
					}

					return (
						<HeaderCell
							style={headerStyle}
							className={header.headerClassName}
							key={headerIdx}
							onClick={() => {
								onClickHeader(header)
							}}
						>
							{!header.hideLabel && renderHeaderLabel(header, header.sort)}
						</HeaderCell>
					)
				})}
				{props.spacerCell && <HeaderCell key='end' />}
			</Row>

			{/*headers with filters*/}
			{showFilterRow && (
				<Row>
					{props.onEditRow && <EditHeader />}
					{props.onClickRowZoom && <ZoomHeader />}

					{l.map(filteredHeaders, (header, headerIdx) => {
						const headerStyle: React.CSSProperties = {}

						if (header.width) {
							headerStyle.minWidth = header.width
							headerStyle.width = header.width
							headerStyle.maxWidth = header.width
						}

						return (
							<HeaderCell key={headerIdx}>
								{header.elasticSearchKey ? (
									<FlexItem>
										{header.filterSelector === 'date' ? (
											<DateFilter
												processedHeader={header}
												onChangeDateFilter={props.onChangeDateFilter}
											/>
										) : (
											<Input
												onChange={(newVal: string) => {
													props.onChangeFilter(header.field, newVal)
												}}
												onEnter={() =>
													props.onToggleHeaderActionBox(header.field)
												}
												value={
													header.filterValue ? '' + header.filterValue : ''
												}
												name={'' + header.field}
												testId={'filter-' + header.field}
												width={'100%'}
												inputType={'search'}
												notResizable={true}
											/>
										)}
									</FlexItem>
								) : (
									<>{/*empty element*/}</>
								)}
							</HeaderCell>
						)
					})}
				</Row>
			)}
		</TableHeader>
	)
}

export const DateFilter = <T extends any>(props: {
	processedHeader: IDataTableHeaderProcessed<T>
	onChangeDateFilter?: (
		headerKey: keyof T,
		changes: Partial<IDatePickerState>,
	) => void
}): React.ReactElement => {
	const [startDate, setStartDate] = useState(null)
	const [endDate, setEndDate] = useState(null)
	const [dateRange, setDateRange] = useState('')

	function formatDate(date): string {
		return DateTime.fromISO(date.toJSON()).toFormat('LL/dd/yyyy')
	}

	function formatDatesDisplay(startDate, endDate): string {
		let dateRange = ''
		if (startDate) {
			dateRange += formatDate(startDate)
		}
		if (endDate) {
			dateRange += ` - ${formatDate(endDate)}`
		}
		return dateRange
	}

	const onChange = (dates, field): void => {
		const [start, end] = dates
		setStartDate(start)
		setEndDate(end)
		setDateRange(formatDatesDisplay(start, end))
		props.onChangeDateFilter(field, {
			date1String: start ? formatDate(start) : null,
			date2String: end ? formatDate(end) : null,
		})
	}

	return (
		<div
			className={theme.addClass(
				classes.datePicker,
				'date-picker-wrapper bootstrap-wrapper',
			)}
		>
			<DatePicker
				className='form-control form-control-sm'
				onChange={(newVal) => {
					onChange(newVal, props.processedHeader.field)
				}}
				selected={startDate}
				startDate={startDate}
				endDate={endDate}
				selectsRange={true}
				customInput={
					<div
						className={theme.addClass(
							classes.datePickerInput1,
							classes.datePickerInput2,
						)}
					>
						{dateRange}
						{dateRange !== '' && (
							<IconButton
								icon={solidIcons.faTimes}
								buttonClassName={'float-right'}
								onClick={() => {
									onChange([undefined, undefined], props.processedHeader.field)
								}}
							/>
						)}
					</div>
				}
			/>
		</div>
	)
}
