import { IFormMetadataCollection } from 'ui/components/form/IFormMetadata'

export interface ILightningIntegrationConfigurationForm {
	upsId: string
	upsKey: string
	fedexId: string
	fedexKey: string
	uspsId: string
	uspsKey: string
	saiaId: string
	saiaKey: string
	truckload: string
}

export const lightningIntegrationConfigurationFormMetadata: IFormMetadataCollection<ILightningIntegrationConfigurationForm> = {
	upsId: {
		label: 'upsId',
		required: true,
	},
	upsKey: {
		label: 'upsKey',
		required: true,
	},
	fedexId: {
		label: 'fedexId',
		required: true,
	},
	fedexKey: {
		label: 'fedexKey',
		required: true,
	},
	uspsId: {
		label: 'uspsId',
		required: true,
	},
	uspsKey: {
		label: 'uspsKey',
		required: true,
	},
	saiaId: {
		label: 'saiaId',
		required: true,
	},
	saiaKey: {
		label: 'saiaKey',
		required: true,
	},
	truckload: {
		label: 'truckload',
		required: true,
	},
}
