// see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/freeze
export const deepFreeze = <T>(object: T): T => {
	// Retrieve the property names defined on object
	const propNames = Object.getOwnPropertyNames(object)

	// Freeze properties before freezing self
	for (const name of propNames) {
		const value = object[name]

		if (!Object.isFrozen(object[name])) {
			object[name] =
				value && typeof value === 'object' ? deepFreeze(value) : value
		}
	}

	return Object.freeze(object)
}
