import { FC } from 'app/FunctionalComponent'
import { sosToast } from 'common/components/toast'
import React, { useState } from 'react'
import { apiFeatureToggles, apiTypes } from 'ui/api'
import { Checkbox } from 'ui/components/common/checkbox/Checkbox'
import { Input } from 'ui/components/common/input/Input'
import { Modal } from 'ui/components/common/modal'
import { OkCancelButtons } from 'ui/components/common/okCancelButtons/OkCancelButtons'
import { ISelectOptions } from 'ui/components/common/select'
import { Select } from 'ui/components/common/select/Select'
import { t } from 'ui/components/i18n/i18n'
import { Spacer } from 'ui/components/layout/spacer/Spacer'
import { l } from 'ui/lib/lodashImports'

export type AddProviderModalMode = 'search' | 'warning' | 'form' | 'confirm'
export const tPrefixAddFeatureToggle = 'page.manageFeatureToggle.add'

const featureToggleTypeOptions: ISelectOptions[] = [
	{ value: 'Company' },
	{ value: 'User' },
	{ value: 'Domain' },
]

export const AddFeatureToggleModal: FC = (props: {
	isOpen: boolean
	onClose: () => void
	onCreate: () => void
}) => {
	const [featureName, setFeatureName] = useState<string>('')
	const [featureDescription, setFeatureDescription] = useState<string>('')
	const [sourceName, _setSourceName] = useState<
		apiTypes.FeatureToggleSourceName
	>()
	const [sourceId, setSourceId] = useState<string>('')
	const [allEntities, setAllEntities] = useState<boolean>(false)

	const clearAddFeatureForm = (): void => {
		setFeatureName('')
		setFeatureDescription('')
		_setSourceName(null)
		setSourceId('')
		setAllEntities(false)
	}

	const setSourceName = (
		newSourceName: apiTypes.FeatureToggleSourceName,
	): void => {
		if (newSourceName === 'Domain') {
			setAllEntities(true)
		}
		_setSourceName(newSourceName)
	}

	const createFeatureToggle = async (): Promise<void> => {
		const featureToggleRequest: apiTypes.CreateFeatureToggleRequest = {
			feature: featureName,
			description: featureDescription,
			sourceName,
			sourceId: allEntities ? 'all' : sourceId,
			enabled: true,
		}

		const result = await apiFeatureToggles.createFeatureToggle(() => {},
		featureToggleRequest)

		if (result.data) {
			props.onCreate()
		} else if (result.error) {
			sosToast.sendApiErrorResponseToast(result)
		}
		props.onClose()
		clearAddFeatureForm()
	}

	const isValid = (): boolean => {
		return (
			!l.isNil(featureName) &&
			!l.isNil(sourceName) &&
			(!l.isNil(sourceId) || allEntities)
		)
	}

	return (
		<Modal
			title={t('addNewFeatureToggle', tPrefixAddFeatureToggle)}
			isOpen={props.isOpen}
			onModalClose={() => {
				props.onClose()
				clearAddFeatureForm()
			}}
			closeModalX={true}
			content={() => (
				<div>
					{t('featureToggleName', tPrefixAddFeatureToggle)}
					<Input value={featureName} onChange={setFeatureName} />
					<Spacer />
					{t('featureToggleDescription', tPrefixAddFeatureToggle)}
					<Input value={featureDescription} onChange={setFeatureDescription} />
					<Spacer />
					{t('featureToggleType', tPrefixAddFeatureToggle)}
					<div>
						<Select
							options={featureToggleTypeOptions}
							value={sourceName}
							onChange={setSourceName}
							tPrefix={tPrefixAddFeatureToggle + '.typeOptions'}
							testId='feature-toggle-type-selector'
						/>
					</div>
					<Spacer />
					<div data-testid='feature-toggle-for-all-entities'>
						<Checkbox
							value={allEntities}
							onChange={setAllEntities}
							readOnly={sourceName === 'Domain'}
							testId='feature-toggle-for-all-entities-checkbox'
						>
							{t('allEntities', tPrefixAddFeatureToggle)}
						</Checkbox>
					</div>
					{!allEntities && (
						<div>
							<Spacer />
							{t('sourceId', tPrefixAddFeatureToggle)}
							<Input
								value={sourceId}
								onChange={setSourceId}
								testId='feature-toggle-source-id-input'
							/>
						</div>
					)}
					<Spacer />
					<OkCancelButtons
						ok={t('addFeatureToggle', tPrefixAddFeatureToggle)}
						okColor='blue'
						cancel={t('cancel', tPrefixAddFeatureToggle)}
						onOk={createFeatureToggle}
						onCancel={() => {
							props.onClose()
							clearAddFeatureForm()
						}}
						isValid={isValid()}
					/>
				</div>
			)}
		></Modal>
	)
}
