import { FC } from 'app/FunctionalComponent'
import { DateTime } from 'luxon'
import React from 'react'
import { apiTypes } from 'ui/api'
import { Popup } from 'ui/components/common/popup'
import { Separator } from 'ui/components/common/separator'
import { t } from 'ui/components/i18n/i18n'
import { SimpleAddress } from 'ui/components/shared/address/SimpleAddress'
import * as classes from 'ui/pages/load-optimizer/LoadOptimizer.module.scss'
import * as sosLoadOptimizer from 'ui/pages/load-optimizer/state/sosLoadOptimizer'
import * as commonClasses from 'ui/theme/common.module.scss'
import { addClass, getBorderColor } from 'ui/theme/theme'
import { tPrefixLoadOptimizer } from '../payloads-table/LoadOptimizerPayloadsTable'

export const ConsolidatedPayloadCard: FC = (props: {
	payload: apiTypes.ShipmentUpdatePayloadRequest
	shipmentId: string
	popupShowing: boolean
	testId?: string
}) => {
	let consolidatedPayloadCardClassName = addClass(
		getBorderColor('lightGray'),
		'list-group-item',
	)
	consolidatedPayloadCardClassName = addClass(
		classes.consolidatedPayloadCard,
		consolidatedPayloadCardClassName,
	)

	const popupContent = (
		<div className={classes.payloadPopup}>
			<div className='row'>
				<div className={addClass('col-sm-4', commonClasses.boldText)}>
					{t('pickupDate', tPrefixLoadOptimizer)}:
				</div>
				<div className='col-sm-7'>
					{props.payload?.originStop?.metaData?.datetimeDesired &&
						DateTime.fromISO(
							props.payload.originStop.metaData.datetimeDesired,
						).toFormat('MM/dd/y')}
				</div>
			</div>
			<div className='row'>
				<div className={addClass('col-sm-4', commonClasses.boldText)}>
					{t('pickup', tPrefixLoadOptimizer)}:
				</div>
				<div className='col-sm-7'>
					<div>
						<SimpleAddress address={props.payload?.originStop?.address} />
					</div>
				</div>
			</div>
			<div className='row'>
				<div className={addClass('col-sm-4', commonClasses.boldText)}>
					{t('delivery', tPrefixLoadOptimizer)}:
				</div>
				<div className='col-sm-7'>
					<div>
						<SimpleAddress address={props.payload?.destinationStop?.address} />
					</div>
				</div>
			</div>
			<Separator />
			<div className='row'>
				<div className={addClass('col-sm-3', commonClasses.boldText)}>
					{t('poNumber', tPrefixLoadOptimizer)}:
				</div>
				<div className='col-sm-3'>
					<div>{props.payload?.purchaseOrder || ''}</div>
				</div>
				<div className={addClass('col-sm-3', commonClasses.boldText)}>
					{t('weight', tPrefixLoadOptimizer)}:
				</div>
				<div className='col-sm-3'>
					<div>
						{props.payload.containers.reduce(
							(totalWeight, { containerWeightEach }) =>
								totalWeight + containerWeightEach || 0,
							0,
						)}
					</div>
				</div>
			</div>
			<div className='row'>
				<div className={addClass('col-sm-3', commonClasses.boldText)}>
					{t('soNumber', tPrefixLoadOptimizer)}:
				</div>
				<div className='col-sm-3'>
					<div>{props.payload?.salesOrder || ''}</div>
				</div>
				<div className={addClass('col-sm-3', commonClasses.boldText)}>
					{t('count', tPrefixLoadOptimizer)}:
				</div>
				<div className='col-sm-3'>
					<div>{props.payload?.containers?.length || ''}</div>
				</div>
			</div>
		</div>
	)

	return (
		<div
			className={consolidatedPayloadCardClassName}
			data-payloadid={props.payload?.id}
			onClick={() =>
				sosLoadOptimizer.updatePayloadWithPopupShowing(
					props.popupShowing ? null : props.payload?.id,
				)
			}
			style={{ backgroundColor: '#ddd', padding: '0 2px 0 0' }}
			data-testid={props.testId}
		>
			<div className='d-flex justify-content-between'>
				<Popup
					isOpen={props.popupShowing}
					content={popupContent}
					position='topRight'
					onClickOutside={() => {
						sosLoadOptimizer.updatePayloadWithPopupShowing(null)
					}}
					breakerClassName={classes.payloadPopupBreaker}
				>
					<div className='d-flex flex-row'>
						<div className='p-2'>
							<span className={commonClasses.boldText}>
								{t('cuid', tPrefixLoadOptimizer)}:
							</span>{' '}
							{props.payload?.customerIdentifier || ''}
						</div>
						<div className='p-2'>
							{/* TODO use tStopType (and make tStopType translate to things other than inbound/outbound) */}
							<div>
								<span className={commonClasses.boldText}>
									{t('pickup', tPrefixLoadOptimizer)}:
								</span>{' '}
								{props.payload?.originStop.address?.city || ''},{' '}
								{props.payload?.originStop.address?.state || ''}
							</div>
							<div>
								<span className={commonClasses.boldText}>
									{t('delivery', tPrefixLoadOptimizer)}:
								</span>{' '}
								{props.payload?.destinationStop.address?.city || ''},{' '}
								{props.payload?.destinationStop.address?.state || ''}
							</div>
						</div>
					</div>
				</Popup>
				<div
					className={addClass(commonClasses.clickable, classes.deleteIcon)}
					onClick={() =>
						sosLoadOptimizer.removePayloadFromShipment(
							props.payload?.id,
							props.shipmentId,
						)
					}
				>
					&times;
				</div>
			</div>
		</div>
	)
}
