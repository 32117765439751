import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { TimePicker } from 'ui/components/common/TimePicker'
import { t } from 'ui/components/i18n/i18n'
import { TimePickerSimple } from 'ui/pages/new-quote/TimePickerSimple'
import { IStateComponentExamples } from 'ui/state/sosComponentExamples'
import { Section } from './Section'

export const SectionTime: FC = (props: {
	stateComponentExamples: IStateComponentExamples
}) => {
	return (
		<Section
			title={t('page.componentExamples.time')}
			name='time'
			stateComponentExamples={props.stateComponentExamples}
		>
			<TimePicker
				intervalMinutes={5}
				state={{
					form: { hour: 12, minute: 0, isOpen: false },
					metadata: {
						hour: { required: true },
						minute: { required: true },
						isOpen: { required: true },
					},
					tPrefix: 'page.componentExamples',
					onUpdateForm: () => {},
				}}
			/>
			<TimePickerSimple />
		</Section>
	)
}
