import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { theme } from 'ui/theme'
import classes from './Radio.module.scss'

export const Radios: FC = (props: {
	children: React.ReactNode
	orientation: 'horizontal' | 'vertical'
}) => {
	let className = classes.radios
	className = theme.addClassIf(
		props.orientation === 'vertical',
		classes.vertical,
		className,
	)
	className = theme.addClassIf(
		props.orientation === 'horizontal',
		classes.horizontal,
		className,
	)

	return <div className={className}>{props.children}</div>
}
