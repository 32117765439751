import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { theme } from 'ui/theme'
import classes from './AlignRight.module.scss'

export const AlignRight: FC = (props: {
	children: React.ReactNode
	vertical?: boolean
	testId?: string
}) => {
	let className = classes.alignRight
	className = theme.addClassIf(props.vertical, classes.vertical, className)

	return (
		<div className={className} data-testid={props.testId}>
			{props.children}
		</div>
	)
}
