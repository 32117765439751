import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { useOnce } from 'ui/components/hooks'
import { t } from 'ui/components/i18n/i18n'
import { ILink, Sidebar } from 'ui/components/layout/sidebar/Sidebar'
import { fireAndForget } from 'ui/lib/async'
import { sos2 } from 'ui/lib/state/sos2'
import { ProviderPortalPage } from 'ui/pages/provider-portal/providerPortalTypes'
import { sosUser } from 'ui/state'
import { Layout } from '../layout'
import * as classes from './ProviderPortal.module.scss'

export const ProviderPortalLayout: FC = (props: {
	selectedPage?: ProviderPortalPage
	children: React.ReactNode
}) => {
	useOnce(() => {
		fireAndForget(
			sosUser.fetchLoggedInUserIsProvider,
			'finding out if logged in user is provider',
		)
	})
	const stateUser = sos2.useSubscription(sosUser.getSos())

	if (stateUser.userIsProvider === 'unknown') {
		return <div>{t('loading', 'page.providerPortal')}</div>
	} else if (stateUser.userIsProvider === 'disallowed') {
		return <div>{t('notAuthorized', 'page.providerPortal')}</div>
	}
	const links: ILink[] = [
		{
			name: 'provider-portal-invoices',
			pathname: '/provider-portal/invoices',
		},
		{
			name: 'provider-portal-customers',
			pathname: '/provider-portal/customers',
		},
	]

	const loginMode = sosUser.getLoginMode()

	return (
		<div className={classes.layout}>
			<Sidebar
				links={links}
				testId={'providerPortal.sidebar'}
				loginMode={loginMode}
			/>
			<Layout hideDefaultSidebar={true} fullWidth={true}>
				<div className={classes.container}>{props.children}</div>
			</Layout>
		</div>
	)
}
