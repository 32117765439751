import { FC } from 'app/FunctionalComponent'
import React, { useState } from 'react'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { apiTypes } from 'ui/api'
import { Loader } from 'ui/components/common/loader'
import { useOnce } from 'ui/components/hooks/useOnce'
import { t } from 'ui/components/i18n/i18n'
import { getRegionsForCountry } from 'ui/data/countryAndRegionUtils'
import { fireAndForget } from 'ui/lib/async'
import { Layout } from 'ui/pages/layout/Layout'
import { AddressCard } from './components'
import { fetchProvider } from './fetchProvider'

export const tPrefixProviderDetails = 'page.providers.providerDetails'

export const ProviderDetailsPage: FC = (props: {}) => {
	const [provider, setProvider] = useState<apiTypes.ProviderResponse>()
	const [isFetchingProvider, setIsFetchingProvider] = useState(false)

	const [billingAddress, setBillingAddress] = useState<apiTypes.Address>({
		name: '',
		email: '',
		street1: '',
		street2: '',
		city: '',
		state: null,
		zip: '',
		country: null,
		phone: '',
	})
	const [billingAddressRegions, setBillingAddressRegions] = useState([])
	const [remitToAddress, setRemitToAddress] = useState<apiTypes.Address>({
		name: '',
		email: '',
		street1: '',
		street2: '',
		city: '',
		state: null,
		zip: '',
		country: null,
		phone: '',
	})
	const [remitToAddressRegions, setRemitToAddressRegions] = useState([])

	useOnce(() => {
		fireAndForget(async () => {
			setIsFetchingProvider(true)
			const provider = await fetchProvider()

			setProvider(provider)

			setBillingAddress({
				name: provider?.billingAddress?.name,
				street1: provider?.billingAddress?.street1,
				street2: provider?.billingAddress?.street2,
				city: provider?.billingAddress?.city,
				state: provider?.billingAddress?.state,
				zip: provider?.billingAddress?.zip,
				country: provider?.billingAddress?.country,
				phone: provider?.billingAddress?.phone,
			})

			if (provider.billingAddress?.country) {
				setBillingAddressRegions(
					getRegionsForCountry(provider.billingAddress.country, true, true),
				)
			}

			setRemitToAddress({
				name: provider?.remitToAddress?.name,
				street1: provider?.remitToAddress?.street1,
				street2: provider?.remitToAddress?.street2,
				city: provider?.remitToAddress?.city,
				state: provider?.remitToAddress?.state,
				zip: provider?.remitToAddress?.zip,
				country: provider?.remitToAddress?.country,
				phone: provider?.remitToAddress?.phone,
			})

			if (provider.remitToAddress?.country) {
				setRemitToAddressRegions(
					getRegionsForCountry(provider.billingAddress.country, true, true),
				)
			}

			setIsFetchingProvider(false)
		}, 'Fetching Provider')
	})

	return (
		<Layout>
			<div className='bootstrap-wrapper'>
				<Card>
					<Card.Header>
						{t('providerDetails', tPrefixProviderDetails)}
					</Card.Header>

					<Card.Body>
						{isFetchingProvider ? (
							<Loader isLoading={isFetchingProvider} />
						) : (
							<Row>
								<Col xs={12} lg={6}>
									<AddressCard
										type='billingAddress'
										title={'generalInformation'}
										provider={provider}
										setProvider={setProvider}
										address={billingAddress}
										setAddress={setBillingAddress}
										regions={billingAddressRegions}
										setRegions={setBillingAddressRegions}
										tPrefix={tPrefixProviderDetails}
									/>
								</Col>
								<Col xs={12} lg={6}>
									<AddressCard
										type='remitToAddress'
										title={'RemitToAddress'}
										provider={provider}
										setProvider={setProvider}
										address={remitToAddress}
										setAddress={setRemitToAddress}
										regions={remitToAddressRegions}
										setRegions={setRemitToAddressRegions}
										tPrefix={tPrefixProviderDetails}
									/>
								</Col>
							</Row>
						)}
					</Card.Body>
				</Card>
			</div>
		</Layout>
	)
}
