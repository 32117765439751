import React, { useEffect, useState } from 'react'
import { IconButton } from 'ui/components/common/icon'
import { solidIcons } from 'ui/components/common/icon/solidIcons'
import { FC } from 'ui/FunctionalComponent'
import { Layout } from 'ui/pages/layout/Layout'
import { t, tString } from 'ui/components/i18n/i18n'
import * as classes from './CustomerInvoicePage.module.scss'
import { ReferenceInformation } from './reference-information'
import { ProviderInvoice } from './provider-invoice'
import { Input } from 'ui/components/common/input'
import { Card } from 'ui/components/common/card'
import { CustomerCharges } from './customer-invoice/customer-charges/CustomerCharges'
import { CustomerInvoiceNotes } from './customer-invoice/customer-invoice-notes/CustomerInvoiceNotes'
import {
	apiBrokerInvoice,
	apiCharges,
	apiTypes,
	apiBroker,
	apiInvoices,
	apiProviders,
} from 'ui/api'
import { sosToast } from 'common/components/toast'
import { fireAndForget } from 'ui/lib/async'
import { l } from 'ui/lib/lodashImports'
import { Col, Row } from 'react-bootstrap'
import { Spacer } from 'ui/components/layout/spacer'
import { BrokerShipmentProfileInvoicesSubway } from 'ui/pages/customer-invoice/components/invoice-subway'
import { Debug } from 'ui/components/dev'
import { useOnce } from 'ui/components/hooks'
import { CustomerInvoiceDetails } from './customer-invoice/customer-invoice-details'
import { InvoiceStatusButton } from './components/invoice-status-button'
import { elasticSearchBuilder } from 'ui/lib/elasticSearch'

const tPrefix = 'page.customerInvoice'

export interface UIProviderInvoice extends apiTypes.ProviderInvoiceResponse {
	providerName?: string
}

export interface UIBrokerInvoiceCharge
	extends apiTypes.BrokerInvoiceChargeResponse {
	selectedProviderName?: string
}

export const CustomerInvoicePage: FC = (props: {
	shipmentId?: string
	brokerInvoiceId: string
}) => {
	const { shipmentId, brokerInvoiceId } = props

	const [defaultProvider, setDefaultProvider] = useState<
		apiTypes.ProviderResponse
	>(null)

	const [brokerShipment, setBrokerShipment] = useState<
		apiTypes.BrokerShipmentResponse
	>(null)

	const [brokerInvoice, setBrokerInvoice] = useState<
		apiTypes.BrokerInvoiceResponse
	>(null)
	const [providerInvoices, setProviderInvoices] = useState<UIProviderInvoice[]>(
		null,
	)
	const [paymentAllocations, setPaymentAllocations] = useState<
		apiTypes.PaymentAllocationResponse[]
	>(null)

	const [isFetchingInvoices, setIsFetchingInvoices] = useState<boolean>(false)

	const [editBrokerInvoiceNumber, setEditBrokerInvoiceNumber] = useState<
		boolean
	>(false)

	const [isLoading, setIsLoading] = useState<boolean>(false)

	const [providerChargeCodes, setProviderChargeCodes] = useState<
		apiTypes.ChargeCodePageResponse[]
	>(() => [])

	const [brokerChargeCodes, setBrokerChargeCodes] = useState<
		apiTypes.ChargeCodePageResponse[]
	>(() => [])

	const [isDownloading, setIsDownloading] = useState<boolean>(false)

	useOnce(async () => {
		const brokerShipmentResponse = await apiBroker.fetchBrokerShipment(
			() => {},
			shipmentId,
			false,
			true,
		)

		if (brokerShipmentResponse.data) {
			setBrokerShipment(brokerShipmentResponse.data)

			const provider = await apiProviders.getProviderByName(() => {},
			brokerShipmentResponse.data.bookedRate?.providerName)

			if (provider.error) {
				sosToast.sendApiErrorResponseToast(provider)
			} else {
				setDefaultProvider(provider.data)
			}
		} else if (brokerShipmentResponse.error) {
			sosToast.sendApiErrorResponseToast(brokerShipmentResponse)
		}
	})

	const onSearchChargeCodes = async (): Promise<void> => {
		const response = await apiCharges.fetchAllChargeCodes({}, true)

		if (response.error) {
			sosToast.sendApiErrorResponseToast(response)
		} else {
			const providerChargeCodes = response.data.entities.filter(
				(charges) => charges.chargeCodeType.toLowerCase() === 'provider',
			)

			setProviderChargeCodes(providerChargeCodes)

			const customerChargeCodes = response.data.entities.filter(
				(charges) => charges.chargeCodeType.toLowerCase() === 'customer',
			)

			setBrokerChargeCodes(customerChargeCodes)
		}
	}

	useOnce(async () => {
		await onSearchChargeCodes()
	})

	const getInvoicesByShipmentId = async (): Promise<void> => {
		setIsFetchingInvoices(true)

		const shipmentInvoiceResponse = await apiInvoices.getInvoicesByShipmentId(
			shipmentId,
		)

		if (shipmentInvoiceResponse.error) {
			sosToast.sendApiErrorResponseToast(shipmentInvoiceResponse)
		} else {
			const brokerInvoice = shipmentInvoiceResponse.data.brokerInvoices.find(
				(brokerInvoice) => brokerInvoice.id === brokerInvoiceId,
			)

			setBrokerInvoice(brokerInvoice)

			const brokerInvoiceChargeProviders = await getProviderByIds(
				brokerInvoice.charges.map((charge) => charge.providerId),
			)

			const mappedBrokerCharges = brokerInvoice.charges.map((charge) => {
				const provider = brokerInvoiceChargeProviders.entities.find(
					(provider) => provider.id === charge.providerId,
				)

				const unitPrice = charge.unitPrice / 100
				const providerCost = charge.providerCost / 100
				const totalPrice = charge.totalPrice / 100

				return {
					...charge,
					providerName: provider?.name || provider?.providerName || ' ',
					unitPrice: unitPrice,
					providerCost: providerCost,
					totalPrice: totalPrice,
				} as UIBrokerInvoiceCharge
			})

			setBrokerInvoice({ ...brokerInvoice, charges: mappedBrokerCharges })

			const providerInvoices = shipmentInvoiceResponse.data.providerInvoices.filter(
				(providerInvoice) =>
					providerInvoice.brokerInvoiceId === brokerInvoiceId,
			)

			const providerInvoiceProviders = await getProviderByIds(
				providerInvoices.map((invoice) => invoice.providerId),
			)

			const mappedProviderInvoices = providerInvoices.map((invoice) => {
				const provider = providerInvoiceProviders.entities.find(
					(provider) => provider.id === invoice.providerId,
				)

				const charges = invoice.charges.map((charge) => {
					const priceToDollars = charge.unitPrice / 100

					return {
						...charge,
						unitPrice: priceToDollars,
					} as apiTypes.ProviderInvoiceChargeResponse
				})

				return {
					...invoice,
					charges: charges,
					providerName: provider?.name || provider?.providerName || ' ',
				} as UIProviderInvoice
			})

			setProviderInvoices(mappedProviderInvoices)
			setPaymentAllocations(shipmentInvoiceResponse.data.paymentAllocations)
		}

		setIsFetchingInvoices(false)
	}

	const getProviderByIds = async (
		id?: string[],
	): Promise<apiTypes.ProviderListResponse> => {
		const qb = elasticSearchBuilder()

		qb.andIn('id', id)

		const response = await apiProviders.getProviders(25, 0, qb.toQuery())

		if (response.error) {
			sosToast.sendApiErrorResponseToast(
				response,
				tString('providerFetchError', 'common'),
			)
		} else {
			return response.data
		}

		return null
	}

	useEffect(() => {
		fireAndForget(
			async () => getInvoicesByShipmentId(),
			`Fetching Broker Invoices`,
		)
	}, [brokerInvoiceId]) // eslint-disable-line react-hooks/exhaustive-deps

	const approveInvoice = async (): Promise<void> => {
		const providerInvoiceCharges = l.flatMap(
			providerInvoices,
			(providerInvoice) => providerInvoice.charges,
		)

		const hasProviderInvoiceCharge = providerInvoiceCharges.every(
			(providerInvoice) => {
				return !!(
					providerInvoice.chargeCode && providerInvoice.chargeDescription
				)
			},
		)

		const hasBrokerInvoiceCharge = brokerInvoice.charges.every(
			(invoiceCharge) => {
				return !!(invoiceCharge.chargeCode && invoiceCharge.chargeDescription)
			},
		)

		if (
			!(providerInvoiceCharges.length >= 0 && hasProviderInvoiceCharge) &&
			!(brokerInvoice.charges.length >= 0 && hasBrokerInvoiceCharge)
		) {
			sosToast.sendToast({
				header: tString('approveInvoiceErrorMessage', tPrefix),
				type: 'danger',
			})
		} else {
			await updateBrokerInvoice('approved')
		}
	}

	const updateBrokerInvoice = async (
		updatedInvoiceStatus: apiTypes.InvoiceStatus = null,
	): Promise<void> => {
		setIsLoading(true)

		const invoiceRequest: apiTypes.BrokerInvoiceUpdateRequest = {
			...brokerInvoice,
			clientConfigId: brokerShipment.contractId,
			invoiceStatus: updatedInvoiceStatus || brokerInvoice.invoiceStatus,
			invoiceNumber: brokerInvoice?.invoiceNumber || '-',
		}

		const invoiceResponse = await apiBrokerInvoice.updateBrokerInvoice(
			() => {},
			brokerInvoiceId,
			invoiceRequest,
		)

		if (invoiceResponse.error) {
			sosToast.sendApiErrorResponseToast(invoiceResponse)
		} else {
			const mappedBrokerCharges = invoiceResponse.data.charges.map((charge) => {
				const unitPrice = charge.unitPrice / 100
				const providerCost = charge.providerCost / 100
				const totalPrice = charge.totalPrice / 100

				return {
					...charge,
					unitPrice: unitPrice,
					providerCost: providerCost,
					totalPrice: totalPrice,
				} as UIBrokerInvoiceCharge
			})

			invoiceResponse.data.charges = mappedBrokerCharges

			setBrokerInvoice(invoiceResponse.data)

			// if (updatedInvoiceStatus === 'invoiced') {
			// 	await downloadPDF()
			// }
		}

		setIsLoading(false)
	}

	const downloadPDF = async (): Promise<void> => {
		setIsDownloading(true)
		const result = await apiBrokerInvoice.fetchBrokerInvoicePDF(
			brokerInvoice?.id,
		)
		setIsDownloading(false)
		const arrayBuffer = (await result.data.arrayBuffer()) as ArrayBuffer
		const base64 = Buffer.from(arrayBuffer).toString('base64')

		const a = document.createElement('a') //Create <a>
		a.href = 'data:application/pdf;base64,' + base64 //Image Base64 Goes here
		a.download = brokerInvoice?.invoiceNumber || brokerInvoice?.id //File name Here
		a.click() //Downloaded file
	}

	return (
		<Layout>
			<Row className={classes.noWrap}>
				<Col xs={7} />
				<Col
					xs={4}
					className={`${classes.subwayContainer} ${classes.noLeftPadding}`}
				>
					<BrokerShipmentProfileInvoicesSubway testid invoice={brokerInvoice} />
				</Col>
			</Row>
			<Row className={classes.noWrap}>
				<Col xs={7} className={classes.pageTitle}>
					<div>
						<h1>{t('customerInvoice', tPrefix)}:</h1>
						{!editBrokerInvoiceNumber ? (
							<span>{brokerInvoice?.invoiceNumber}</span>
						) : (
							<span>
								<Input
									value={brokerInvoice?.invoiceNumber}
									onChange={(newVal: string) =>
										setBrokerInvoice({
											...brokerInvoice,
											invoiceNumber: newVal,
										})
									}
									autofocus={editBrokerInvoiceNumber}
									readOnly={!editBrokerInvoiceNumber}
									width='350px'
									maxLength={20}
									testId={'customer-invoice-number'}
								/>
							</span>
						)}
					</div>
					<div>
						{brokerInvoice?.invoiceStatus === 'review' && (
							<React.Fragment>
								<IconButton
									icon={
										!editBrokerInvoiceNumber
											? solidIcons.faPencilAlt
											: solidIcons.faCheck
									}
									color={editBrokerInvoiceNumber ? 'green' : 'black'}
									spin={isLoading}
									iconClassName={classes.editSaveIcon}
									onClick={async () => {
										if (editBrokerInvoiceNumber) {
											await updateBrokerInvoice()
										}
										setEditBrokerInvoiceNumber(!editBrokerInvoiceNumber)
									}}
									testId={'customer-invoice-edit-save'}
								/>
								{editBrokerInvoiceNumber && (
									<IconButton
										icon={solidIcons.faWindowClose}
										color={'red'}
										iconClassName={classes.editCancelIcon}
										onClick={async () => {
											setBrokerInvoice({ ...brokerInvoice })
											setEditBrokerInvoiceNumber(!editBrokerInvoiceNumber)
										}}
										testId={'customer-invoice-edit-cancel'}
									/>
								)}
							</React.Fragment>
						)}
						{brokerInvoice?.invoiceStatus === 'void' && (
							<p className={classes.voidedInvoiceText}>VOIDED INVOICE</p>
						)}
					</div>
				</Col>
				<InvoiceStatusButton
					shipmentId={shipmentId}
					brokerInvoice={brokerInvoice}
					isUpdatingInvoiceStatus={isLoading}
					updateBrokerInvoiceStatus={async (
						invoiceStatus: apiTypes.InvoiceStatus = null,
					) => {
						await updateBrokerInvoice(invoiceStatus)
					}}
					approveInvoice={approveInvoice}
					downloadPDF={downloadPDF}
					isDownloading={isDownloading}
					paymentAllocations={paymentAllocations}
					setPaymentAllocations={setPaymentAllocations}
					tPrefix={tPrefix}
				/>
			</Row>
			<Spacer />
			<Row className={classes.noWrap}>
				<Col xs={7}>
					<Card
						title={t('customerInvoice', tPrefix)}
						color={'darkBlue'}
						testId={'customer-invoice-parent-card'}
					>
						<CustomerInvoiceDetails
							isFetchingInvoices={isFetchingInvoices}
							brokerInvoice={brokerInvoice}
							brokerInvoiceCharges={brokerInvoice?.charges}
						/>
						<CustomerCharges
							isFetchingInvoices={isFetchingInvoices}
							defaultProvider={defaultProvider}
							brokerInvoice={brokerInvoice}
							brokerChargeCodes={brokerChargeCodes}
							onUpdate={async (
								updateMode: 'add' | 'upsert' | 'delete',
								charges: any,
							) => {
								const updatedBrokerInvoice = { ...brokerInvoice }

								if (updateMode === 'add') {
									if (updatedBrokerInvoice?.charges.length) {
										updatedBrokerInvoice.charges.push(charges)
									} else {
										updatedBrokerInvoice.charges = [charges]
									}
								} else {
									updatedBrokerInvoice.charges = charges
								}

								setBrokerInvoice(updatedBrokerInvoice)
							}}
						/>
						<CustomerInvoiceNotes
							brokerInvoice={brokerInvoice}
							onUpdate={(note: string) => {
								setBrokerInvoice({ ...brokerInvoice, notes: note })
							}}
						/>
					</Card>
				</Col>

				<Col xs={4} className={classes.noLeftPadding}>
					<ReferenceInformation
						isFetchingInvoices={isFetchingInvoices}
						brokerShipment={brokerShipment}
						providerInvoices={providerInvoices}
					/>
					<Spacer />
					<ProviderInvoice
						shipmentId={shipmentId}
						brokerShipment={brokerShipment}
						isFetchingInvoices={isFetchingInvoices}
						brokerInvoice={brokerInvoice}
						providerInvoices={providerInvoices}
						setProviderInvoices={setProviderInvoices}
						providerChargeCodes={providerChargeCodes}
					/>
				</Col>
			</Row>
			<Debug data={{ shipmentId }} label={'shipmentId'} />
			<Debug data={brokerInvoice} label={'brokerInvoice'} />
		</Layout>
	)
}
