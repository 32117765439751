import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { Accessorials } from 'ui/components/shared/accessorials'
import { allAccessorialsMap } from './accessorialsData'
import { AccessorialMapType } from './accessorialsTypes'

export const FlatbedAccessorials: FC = (props: {
	accessorials: AccessorialMapType
	updateState: (changes: any, stopId?: string) => void
}) => {
	return (
		<Accessorials
			metadata={[allAccessorialsMap['tarp']]}
			accessorialObject={props.accessorials}
			updateState={props.updateState}
			tPrefix='page.newQuote.equipmentAccessorials'
		/>
	)
}
