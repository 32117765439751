import { AmPmType } from 'ui/components/common'

export const twentyFourHourToTwelveHourTime = (
	hour: number,
): { amPm: AmPmType; hour: number } => {
	if (hour < 0 || hour > 23) {
		return null
	}

	let twelveHour = hour
	const amPm: AmPmType = twelveHour >= 12 ? 'pm' : 'am'

	if (hour === 0) {
		twelveHour = 12
	} else if (hour > 12) {
		twelveHour -= 12
	}

	return { amPm, hour: twelveHour }
}
