import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { Icon, regularIcons } from 'ui/components/common/icon'

export const StationWaiting: FC = (props: {}) => {
	return (
		<div data-testid='subway-station-waiting'>
			<Icon icon={regularIcons.faCircle} />
		</div>
	)
}
