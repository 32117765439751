import { apiCommon, apiTypes } from 'ui/api'
import { IRequestState } from 'ui/api/requestState'
import { elasticSearchBuilder } from 'ui/lib/elasticSearch'

export async function getBookList(): Promise<
	IRequestState<apiTypes.ChargeCodeBookListResponse>
> {
	return await apiCommon.apiFetch(
		() => {},
		{ method: 'GET' },
		'books/charge-code',
	)
}

export async function getChargeCodePageList(
	bookId: string,
	query?: { take: number; skip: number },
	entireResponse = false,
): Promise<IRequestState<apiTypes.ChargeCodePageListResponse>> {
	return await apiCommon.apiFetch(
		() => {},
		{ method: 'GET', entireResponse },
		`books/charge-code/${bookId}/pages`,
		query,
	)
}

export const createChargesBook = async (
	name: string,
): Promise<IRequestState<apiTypes.ChargeCodeBookResponse>> => {
	const url = `books/charge-code`
	return apiCommon.apiFetch<apiTypes.ChargeCodeBookResponse>(
		() => {},
		{ method: 'POST', data: { name } },
		url,
	)
}

export const getChargesCode = async (
	bookId: string,
): Promise<IRequestState<apiTypes.ChargeCodePageResponse[]>> => {
	const url = `books/charge-code/${bookId}/pages`
	return apiCommon.apiFetch<apiTypes.ChargeCodePageResponse[]>(
		() => {},
		{ method: 'GET' },
		url,
	)
}

export const createChargeCode = async (
	bookId: string,
	data: apiTypes.ChargeCodePageRequest,
): Promise<IRequestState<apiTypes.ChargeCodePageResponse>> => {
	const url = `books/charge-code/${bookId}/pages`
	return apiCommon.apiFetch<apiTypes.ChargeCodePageResponse>(
		() => {},
		{
			method: 'POST',
			data,
		},
		url,
	)
}

export const updateChargeCode = async (
	bookId: string,
	pageId: string,
	data: apiTypes.ChargeCodePageRequest,
): Promise<IRequestState<apiTypes.ChargeCodePageResponse>> => {
	const url = `books/charge-code/${bookId}/pages/${pageId}`
	return apiCommon.apiFetch<apiTypes.ChargeCodePageResponse>(
		() => {},
		{
			method: 'PUT',
			data,
		},
		url,
	)
}

export const deleteChargeCode = async (
	bookId: string,
	pageId: string,
): Promise<IRequestState<apiTypes.ChargeCodePageResponse>> => {
	const url = `books/charge-code/${bookId}/pages/${pageId}`
	return apiCommon.apiFetch<apiTypes.ChargeCodePageResponse>(
		() => {},
		{
			method: 'DELETE',
		},
		url,
	)
}

export async function fetchAllChargeCodes(
	query?: {
		take?: number
		skip?: number
		sort?: string
	},
	entireResponse = false,
): Promise<IRequestState<apiTypes.ChargeCodePageListResponse>> {
	return await apiCommon.apiFetch(
		() => {},
		{ method: 'GET', entireResponse },
		`books/charge-code/pages`,
		query,
	)
}

export async function fetchChargeCodes(
	query?: {
		take: number
		skip: number
		sort?: string
		chargeCode?: string
		chargeCodeDescription?: string
		chargeCodeType?: string
	},
	entireResponse = false,
): Promise<IRequestState<apiTypes.ChargeCodePageListResponse>> {
	const qb = elasticSearchBuilder()

	const search = []

	if (query.chargeCode) {
		if (query.chargeCode.split(' ').length > 1) {
			search.push(['chargeCode', '"' + query.chargeCode + '"'])
		} else {
			search.push(['chargeCode', '*' + query.chargeCode + '*'])
		}
	}

	if (query.chargeCodeDescription) {
		if (query.chargeCodeDescription.split(' ').length > 1) {
			search.push([
				'chargeCodeDescription',
				'"' + query.chargeCodeDescription + '"',
			])
		} else {
			search.push([
				'chargeCodeDescription',
				'*' + query.chargeCodeDescription + '*',
			])
		}
	}

	if (query.chargeCodeType) {
		if (query.chargeCodeType.toLowerCase() !== 'both') {
			search.push(['chargeCodeType', '"' + query.chargeCodeType + '"'])
		}
	}

	qb.andOrAnd([search])

	const searchQuery = {
		take: query.take,
		skip: query.skip,
		sort: query.sort,
		query: undefined,
	}

	if (search.length) {
		searchQuery.query = qb.toQuery()
	}

	return await apiCommon.apiFetch(
		() => {},
		{ method: 'GET', entireResponse },
		`books/charge-code/pages`,
		searchQuery,
	)
}
