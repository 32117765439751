import React from 'react'
import styles from './FormVertical.module.scss'
import { IFormData } from './IFormData'

export function FormVerticalRow<T extends IFormData<T>>(props: {
	children: React.ReactNode
}): React.ReactElement {
	return (
		<div className={styles.tr}>
			<td colSpan={3} className={styles.td}>
				{props.children}
			</td>
		</div>
	)
}
