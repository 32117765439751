import { FC } from 'app/FunctionalComponent'
import { apiPrintNode, apiTypes } from 'ui/api'
import { sosToast } from 'common/components/toast'
import React, { RefObject, useRef, useState } from 'react'
import {
	getPackageCatalogEntries,
	getPackageCatalogs,
} from 'ui/api/apiPackageCatalog'
import {GoodResponse, PackagePageResponse} from 'ui/api/apiTypes'
import { Button } from 'ui/components/common/button'
import { Card } from 'ui/components/common/card'
import { IconButton } from 'ui/components/common/icon/Icon'
import { solidIcons } from 'ui/components/common/icon/solidIcons'
import { Modal } from 'ui/components/common/modal/Modal'
import { OkCancelButtons } from 'ui/components/common/okCancelButtons'
import {
	FormStackedItem,
	FormStackedSelect,
	FormStackedTextInput,
	IFormData,
} from 'ui/components/form'
import { useOnce } from 'ui/components/hooks'
import { t, tString } from 'ui/components/i18n/i18n'
import { AlignRight } from 'ui/components/layout/alignRight'
import { fireAndForget } from 'ui/lib/async'
import { l } from 'ui/lib/lodashImports'
import { emptyPackageForm, NewQuotePackageForm } from '../newQuoteForms'
import * as classes from './NewQuotePackages.module.scss'
import { NewQuotePackagingCatalog } from './NewQuotePackagingModal'
import { sosUser } from 'ui/state'
import { ISelectOptions } from 'ui/components/common/select'
import { getPrintNodeScaleWeight } from 'ui/pages/company-management/printers-and-scales/scales-table'
import {UIGoodsResponse} from 'ui/pages/new-quote/parcel/components'

const tPrefix = 'page.newQuote.parcel.newQuotePackageForm'

export const loadWeightFromScale = async (
	printNodeCredentials: apiTypes.PrintNodeCredentialsResponse,
	setPrintNodeScalesList: React.Dispatch<
		React.SetStateAction<apiPrintNode.PrintNodeScalesResponse[]>
	>,
	setIsFetchingPrintNodeScales: React.Dispatch<React.SetStateAction<boolean>>,
	overridePrintNodeScale?: apiPrintNode.PrintNodeScalesResponse,
): Promise<number> => {
	let printNodeScale: apiPrintNode.PrintNodeScalesResponse

	if (overridePrintNodeScale) {
		printNodeScale = overridePrintNodeScale
	} else {
		if (sosUser.getSos().getState().printStation && printNodeCredentials) {
			setIsFetchingPrintNodeScales(true)

			const scaleId = sosUser.getSos().getState().printStation
				?.printerGroupScale?.scaleId

			const scalesListResponse = await apiPrintNode.getScaleList(
				printNodeCredentials,
			)

			if (scalesListResponse) {
				const scalesLists = l.flatMap(
					scalesListResponse,
					(scalesList) => scalesList,
				)

				const filteredScalesList = scalesLists.filter(
					(scale) => scale !== undefined,
				)

				if (filteredScalesList?.length > 0) {
					printNodeScale = filteredScalesList.find(
						(scale) => scale.computerId.toString() === scaleId,
					)
				}

				setPrintNodeScalesList(filteredScalesList)
			}

			setIsFetchingPrintNodeScales(false)
		} else {
			sosToast.sendToast({
				header: tString('noPrintStationFound', tPrefix),
				type: 'warning',
			})
		}
	}

	return getPrintNodeScaleWeight(printNodeScale?.measurement)
}

export const tPackagingTypes = (
	packagingTypes: apiTypes.ContainerType,
): string => {
	return tString(packagingTypes, `${tPrefix}.packagingTypes`)
}

export interface PackageGoods extends GoodResponse {
	packageId: string
}

export const NewQuotePackages: FC = (props: {
	packagesForm: NewQuotePackageForm[]
	setPackagesForm: React.Dispatch<React.SetStateAction<NewQuotePackageForm[]>>
	isReadOnly?: boolean
	overridePrintNodeScale: apiPrintNode.PrintNodeScalesResponse
	printNodeScalesList: apiPrintNode.PrintNodeScalesResponse[]
	orderLoaded?: boolean
	printNodeCredentials: apiTypes.PrintNodeCredentialsResponse
	setPrintNodeScalesList: React.Dispatch<
		React.SetStateAction<apiPrintNode.PrintNodeScalesResponse[]>
	>
	setIsFetchingPrintNodeScales: React.Dispatch<React.SetStateAction<boolean>>
	isFetchingPrintNodeScales: boolean
	bookWithButton?: RefObject<HTMLButtonElement>
	getRatesButton?: RefObject<HTMLButtonElement>
	goods: UIGoodsResponse[]
	updateGoods: (index: string, packageId: string) => void
	splitGood: (index: string, count: number) => void
	isIPD: boolean
}) => {
	const {
		packagesForm,
		setPackagesForm,
		isReadOnly,
		overridePrintNodeScale,
		printNodeScalesList,
		printNodeCredentials,
		setPrintNodeScalesList,
		setIsFetchingPrintNodeScales,
		isFetchingPrintNodeScales,
		bookWithButton,
		getRatesButton,
		goods,
		updateGoods,
		splitGood,
		isIPD
	} = props
	const [indexOfRowToDelete, setIndexOfRowToDelete] = useState<number>(null)
	const [bookId, setBookId] = useState<string>(null)
	const [packagingPages, setPackagingPages] = useState<PackagePageResponse[]>(
		null,
	)
	const [
		isPackagingCatalogModalOpen,
		setIsPackagingCatalogModalOpen,
	] = useState<boolean>(false)
	const [scaleToLoad, setScaleToLoad] = useState<number>(null)
	const packagingTypeSelection: apiTypes.ContainerType[] = [
		'boxes',
		'envelopes',
	]

	const loadWeightButton = useRef<HTMLButtonElement[]>([])
	const boxBarCodeInput = useRef([])

	let formData: IFormData<NewQuotePackageForm>

	const fetchPackageCatalogs = (): void => {
		fireAndForget(async () => {
			const packageCatalogs = await getPackageCatalogs(() => {})

			if (packageCatalogs.data[0]) {
				setBookId(packageCatalogs.data[0].id)

				const packageCatalogEntries = await getPackageCatalogEntries(
					packageCatalogs.data[0].id,
					() => {},
				)

				if (packageCatalogEntries.data) {
					const packageCatalogEntriesData = packageCatalogEntries.data
					setPackagingPages(packageCatalogEntriesData)
				} else if (packageCatalogEntries.error) {
					sosToast.sendApiErrorResponseToast(packageCatalogEntries)
				}
			} else if (packageCatalogs.error) {
				sosToast.sendApiErrorResponseToast(packageCatalogs)
			}
		}, 'FETCHING PACKAGING PAGES')
	}

	useOnce(async () => {
		fetchPackageCatalogs()
	})

	const [isEditing, setIsEditing] = useState<string>(undefined)

	return (
		<div data-test-id={'new-quote-package-container'}>
			<NewQuotePackagingCatalog
				bookId={bookId}
				packagingPages={packagingPages}
				setPackagingPages={setPackagingPages}
				fetchPackageCatalogs={fetchPackageCatalogs}
				isModalOpen={isPackagingCatalogModalOpen}
				onModalClose={() => {
					fetchPackageCatalogs()
					setIsPackagingCatalogModalOpen(false)
				}}
			/>
			<Modal
				content={() => (
					<div
						className={classes.confirmDeleteModal}
						data-testid={'new-quote-package-delete-modal'}
					>
						<p>{t('deleteThisPackage?', tPrefix)}</p>
						<AlignRight>
							<OkCancelButtons
								isValid={true}
								ok={t('ok', tPrefix)}
								okColor={'green'}
								okTestId={'new-quote-package-delete-modal-ok'}
								onOk={() => {
									const retrievedPackages = l.cloneDeep(packagesForm)
									retrievedPackages.splice(indexOfRowToDelete, 1)
									setPackagesForm(retrievedPackages)
									setIndexOfRowToDelete(null)
								}}
								cancel={t('cancel', tPrefix)}
								onCancel={() => {
									setIndexOfRowToDelete(null)
								}}
								cancelTestId={'new-quote-package-delete-modal-cancel'}
							></OkCancelButtons>
						</AlignRight>
					</div>
				)}
				isOpen={!l.isNil(indexOfRowToDelete)}
				onModalClose={() => {}}
				title={t('confirmDelete', tPrefix)}
			/>

			<Card
				title={
					<div className={classes.titleButtonContainer}>
						{t('packages', tPrefix)}
						<div className={classes.buttonContainer}>
							<Button
								color={'lightBlue'}
								testId={'new-quote-packaging-catalog-open'}
								onClick={() => {
									setIsPackagingCatalogModalOpen(true)
								}}
								isDisabled={isReadOnly}
							>
								{t('packagingCatalog', tPrefix)}
							</Button>
							<Button
								color={'red'}
								testId={'new-quote-package-add'}
								onClick={() => {
									const newPackage = l.cloneDeep(emptyPackageForm)
									const updatedPackages = l.cloneDeep(packagesForm)

									updatedPackages.push(newPackage)

									setPackagesForm(updatedPackages)
								}}
								isDisabled={isReadOnly}
							>
								{t('addPackage', tPrefix)}
							</Button>
						</div>
					</div>
				}
				color={'darkBlue'}
				testId={'new-quote-parcel-packages-card'}
			>
				{l.map(packagesForm, (packageRow, idx) => {
					formData = {
						form: packageRow,
						metadata: {
							quantity: {},
							reference1: {},
							reference2: {},
							declaredValue: {},
							type: {
								options: packagingTypeSelection?.map(
									(value): ISelectOptions => ({
										value,
										label: tPackagingTypes(value),
									}),
								),
								// [
								// 	{ label: tString('Box', tPrefix), value: 'boxes' },
								// 	{ label: tString('Envelope', tPrefix), value: 'envelopes' },
								// ],
							},
							packagePageId: {
								options:
									packagingPages?.map((type) => {
										return {
											label: type.name,
											value: type.id,
										}
									}) || [],
							},
							boxBarcode: {},
							length: {},
							width: {},
							height: {},
							weight: {},
						},

						onUpdateForm: (field: string, value: any) => {
							const retrievedPackages = l.cloneDeep(packagesForm)

							const updatedPackage = packageRow
							updatedPackage[field] = value

							if (field === 'packagePageId') {
								const packagingPage = l.find(
									packagingPages,
									(page) => page.id === updatedPackage.packagePageId,
								)

								if (!l.isNil(packagingPage)) {
									updatedPackage['length'] = packagingPage.length
									updatedPackage['width'] = packagingPage.width
									updatedPackage['height'] = packagingPage.height
								}
							}
							retrievedPackages.splice(idx, 1, updatedPackage)
							setPackagesForm(retrievedPackages)
						},
						tPrefix,
					}

					return (
						<div>
							<div
								data-testid={'new-quote-package-list'}
								key={idx}
								className={classes.flexContainer}
							>
								<span className={classes.packageIndex}>{idx + 1}</span>
								<FormStackedTextInput
									formData={formData}
									field={'quantity'}
									hideOptional={true}
									className={classes.medInputBox}
									testId={`new-quote-packages-quantity-${idx}`}
									readOnly={isReadOnly}
								/>
								<FormStackedTextInput
									formData={formData}
									field={'reference1'}
									hideOptional={true}
									className={classes.medInputBox}
									testId={`new-quote-packages-reference1-${idx}`}
									readOnly={isReadOnly}
								/>
								<FormStackedTextInput
									formData={formData}
									field={'reference2'}
									hideOptional={true}
									className={classes.medInputBox}
									testId={`new-quote-packages-reference2-${idx}`}
									readOnly={isReadOnly}
								/>
								<FormStackedTextInput
									formData={formData}
									field={'declaredValue'}
									hideOptional={true}
									className={classes.medInputBox}
									testId={`new-quote-packages-declaredValue-${idx}`}
									readOnly={isReadOnly}
								/>
								<FormStackedSelect
									formData={formData}
									field={'type'}
									numListOptionsBeforeScroll={3}
									hideOptional={true}
									className={classes.medInputBox}
									testId={`new-quote-packages-packagingType-${idx}`}
									readOnly={isReadOnly}
									autocomplete={false}
								/>
								<FormStackedSelect
									formData={formData}
									field={'packagePageId'}
									numListOptionsBeforeScroll={3}
									hideOptional={true}
									className={classes.medInputBox}
									testId={`new-quote-packages-packagePageId-${idx}`}
									readOnly={isReadOnly}
								/>
								<FormStackedTextInput
									refProp={(elem: any) => {
										boxBarCodeInput.current[idx] = elem
									}}
									formData={formData}
									field={'boxBarcode'}
									hideOptional={true}
									autofocus={props.orderLoaded && idx === 0}
									className={classes.medInputBox}
									testId={`new-quote-packages-boxBarcode-${idx}`}
									readOnly={isReadOnly}
									onEnter={(value: string, field: string) => {
										const boxBarcodeDimensions: string[] = value
											?.toString()
											.toLowerCase()
											.split('x')
											.sort((a: string, b: string) => parseInt(b) - parseInt(a))
										const retrievedPackages = l.cloneDeep(packagesForm)
										const updatedPackage = packageRow
										updatedPackage[field] = value

										updatedPackage['length'] = parseInt(boxBarcodeDimensions[0])
										updatedPackage['width'] = parseInt(boxBarcodeDimensions[1])
										updatedPackage['height'] = parseInt(boxBarcodeDimensions[2])

										retrievedPackages.splice(idx, 1, updatedPackage)
										setPackagesForm(retrievedPackages)

										if (
											printNodeScalesList.length >= 1 &&
											(l.isNil(updatedPackage['weight']) ||
												updatedPackage['weight'].toString().trim() === '')
										) {
											setTimeout(() => {
												loadWeightButton.current[idx].focus()
											}, 100)
										} else {
											if (packagesForm.length === idx + 1) {
												if (bookWithButton.current !== null) {
													setTimeout(() => {
														bookWithButton.current.focus()
													}, 100)
												} else {
													setTimeout(() => {
														getRatesButton.current.focus()
													}, 100)
												}
											} else {
												setTimeout(() => {
													boxBarCodeInput.current[idx + 1].focus()
												}, 100)
											}
										}
									}}
								/>
								<FormStackedItem
									formData={formData}
									field={'length'}
									hideOptional={true}
								>
									<div className={classes.flexContainer}>
										<FormStackedTextInput
											formData={formData}
											field={'length'}
											hideOptional={true}
											label={' '}
											className={classes.smlInputBox}
											testId={`new-quote-packages-length-${idx}`}
											readOnly={isReadOnly}
										/>
										<FormStackedTextInput
											formData={formData}
											field={'width'}
											hideOptional={true}
											label={' '}
											className={classes.smlInputBox}
											testId={`new-quote-packages-width-${idx}`}
											readOnly={isReadOnly}
										/>
										<FormStackedTextInput
											formData={formData}
											field={'height'}
											hideOptional={true}
											label={' '}
											className={classes.smlInputBox}
											testId={`new-quote-packages-height-${idx}`}
											readOnly={isReadOnly}
										/>
									</div>
								</FormStackedItem>
								<FormStackedTextInput
									formData={formData}
									field={'weight'}
									hideOptional={true}
									className={classes.medInputBox}
									testId={`new-quote-packages-weight-${idx}`}
									readOnly={isReadOnly}
								/>
								<div
									className={`${classes.flexContainer} ${classes.iconButtonContainer}`}
								>
									<IconButton
										buttonRef={(element: HTMLButtonElement) => {
											loadWeightButton.current[idx] = element
										}}
										icon={solidIcons.faBalanceScale}
										color={'blue'}
										onClick={async () => {
											setScaleToLoad(idx)

											const weight = await loadWeightFromScale(
												printNodeCredentials,
												setPrintNodeScalesList,
												setIsFetchingPrintNodeScales,
												overridePrintNodeScale,
											)

											const updatedPackage = {
												...packageRow,
												weight,
											}

											const retrievedPackages = l.cloneDeep(packagesForm)
											retrievedPackages.splice(idx, 1, updatedPackage)
											setPackagesForm(retrievedPackages)

											if (packagesForm.length === idx + 1) {
												if (bookWithButton.current !== null) {
													setTimeout(() => {
														bookWithButton.current.focus()
													}, 100)
												} else {
													setTimeout(() => {
														getRatesButton.current.focus()
													}, 100)
												}
											} else {
												setTimeout(() => {
													boxBarCodeInput.current[idx + 1].focus()
												}, 100)
											}
										}}
										large={true}
										disabled={printNodeScalesList.length < 1}
										testId={'new-quote-package-modal-scale'}
										spin={idx === scaleToLoad && isFetchingPrintNodeScales}
									/>
									<IconButton
										icon={solidIcons.faTrash}
										color={'red'}
										onClick={() => {
											setIndexOfRowToDelete(idx)
										}}
										large={true}
										disabled={packagesForm.length === 1}
										testId={'new-quote-package-delete-button'}
									/>
								</div>
							</div>
						</div>
					)
				})}
			</Card>
			<br/>
			{
				(goods.length > 0 && isIPD) && (
					<Card title={'Goods'} color={'darkBlue'} testId={'new-quote-parcel-goods-card'}>
						{
							goods.map((_good, index) => {
								return (
									<div key={index} className={classes.goodsSelection}>
										<select onChange={(_event) => {
											_good.packageId = _event.target.value
											updateGoods(_good.id, _event.target.value)
										}} value={_good.packageId}>
											{
												packagesForm.map((_package, index) => (
													<option value={index} key={index}>Package #{index + 1}</option>
												))
											}
										</select>
										{
											 <Button
												color={'green'}
												isSmall={true}
												className={isEditing === _good.id ? classes.hideSplitButton : classes.splitButton}
												testId={'new-quote-packaging-catalog-open'}
												onClick={() => {
													setIsEditing(_good.id)
												}}
												isDisabled={(isEditing !== undefined && isEditing !== _good.id) ||_good.count === 1}
											>
												Split
											</Button>
										}
										{ isEditing === _good.id && (
											<div>
												<span className={classes.splitPlaceHolder}>How many items to split out?</span>
												<input autoFocus={true} width={'100px'} min={1} max={_good.count - 1} type="number" onKeyUp={(_event) => {
													if (_event.key === 'Enter') {
														if ((parseInt(_event.currentTarget.value) < _good.count) && parseInt(_event.currentTarget.value) >= 1) {
															splitGood(_good.id, parseInt(_event.currentTarget.value))
															setIsEditing(undefined)
														} else if (parseInt(_event.currentTarget.value) === _good.count) {
															setIsEditing(undefined)
														}
													}
													if (_event.key === 'Escape') {
														setIsEditing(undefined)
													}
												}}/>
											</div>
										) }
										<span>{ [_good.count,_good.description].join(' ')  }</span>
									</div>
								)
							})
						}
					</Card>
				)
			}
		</div>
	)
}
