import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { regularIcons } from 'ui/components/common/icon'
import { BadgeError } from '../common/badge/Badge'
import { IconButton } from '../common/icon/Icon'
import styles from './DataTableUpdate.module.scss'

const DataTableUpdate: FC = (props: {
	onDeleteRow?: () => void
	children: React.ReactNode
	error?: any
}) => {
	const { onDeleteRow } = props

	return (
		<div className={styles.dataTableUpdate}>
			{onDeleteRow && (
				<div>
					<IconButton
						icon={regularIcons.faTrashAlt}
						onClick={props.onDeleteRow}
						testId={'data-table-delete'}
					/>
				</div>
			)}
			{props.children}

			{props.error && (
				<div>
					<BadgeError />
					<pre>{JSON.stringify(props.error, null, 2)}</pre>
				</div>
			)}
		</div>
	)
}

export { DataTableUpdate }
