import React from 'react'
import { InputAutocompleteType, InputType } from '../common/input'
import { FormTextInput } from './FormTextInput'
import { FormVerticalItem } from './FormVerticalItem'
import { IFormData } from './IFormData'

export const FormVerticalTextInput = React.memo(
	<T extends any>(props: {
		field: keyof T
		formData: IFormData<T>
		autofocus?: boolean
		onEnter?: () => void
		readOnly?: boolean
		inputType?: InputType
		helpText?: React.ReactNode
		autocomplete?: InputAutocompleteType
		multiline?: boolean
		testId?: string
	}) => {
		const { field, formData, helpText } = props

		return (
			<FormVerticalItem field={field} formData={formData} helpText={helpText}>
				<FormTextInput
					name={'' + field}
					form={formData.form}
					field={field}
					onUpdateForm={formData.onUpdateForm}
					autofocus={props.autofocus}
					onEnter={props.onEnter}
					readOnly={props.readOnly}
					inputType={props.inputType}
					autocomplete={props.autocomplete}
					multiline={props.multiline}
					testId={props.testId}
				/>
			</FormVerticalItem>
		)
	},
)
