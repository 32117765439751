import { NotFound } from 'app/AppRouter'
import { FC } from 'app/FunctionalComponent'
import React from 'react'
import {
	IRoute,
	LoginModeType,
	SimpleRouter,
} from 'ui/components/common/router'
import { RfpAnalysisPage } from '.'
import { RfpDataUpload } from './RfpDataUpload'

export const rfpRoutes: IRoute[] = [
	{
		isDefault: true,
		urls: ['/rfp/404'],
		renderer: () => <NotFound />,
		anonymous: true,
	},
	{
		urls: ['/rfp/upload', '/rfp'],
		renderer: () => <RfpDataUpload />,
	},
	{
		urls: ['/rfp/analysis'],
		renderer: () => <RfpAnalysisPage />,
	},
]

export const RouterRfp: FC = (props: {
	currentLocation: string
	loginMode: LoginModeType
}) => {
	return (
		<SimpleRouter
			routes={rfpRoutes}
			currentLocation={props.currentLocation}
			loginMode={props.loginMode}
		/>
	)
}
