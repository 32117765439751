import { FC } from 'app/FunctionalComponent'
import { DateTime } from 'luxon'
import React from 'react'
import { Input } from 'ui/components/common/input/Input'
import { Popup } from 'ui/components/common/popup/Popup'
import { ISelectOptions } from 'ui/components/common/select/ISelectOptions'
import { Select } from 'ui/components/common/select/Select'
import { Calendar } from 'ui/components/date-time/Calendar'
import { t } from 'ui/components/i18n/i18n'
import { FlexItem } from 'ui/components/layout/flexRow/FlexItem'
import { FlexRow } from 'ui/components/layout/flexRow/FlexRow'
import { Spacer } from 'ui/components/layout/spacer'
import { log } from 'ui/lib/log'
import { r } from 'ui/lib/ramdaImports'
import classes from './ShipmentsListDatePicker.module.scss'

export type DatePickerMode = 'all' | 'on' | 'between' | 'in-the-last'
export interface IDatePickerState {
	mode: DatePickerMode
	date1String: string
	date2String: string
	showDate1Calendar: boolean
	showDate2Calendar: boolean
	inLastX: string
}

export function parseDateFromUSLocale(dateString: string): DateTime {
	const parts = dateString.trim().split('/')
	if (parts.length !== 3) {
		return null
	}
	const date = DateTime.fromObject({
		year: parseInt(parts[2].trim(), 10),
		month: parseInt(parts[0].trim(), 10),
		day: parseInt(parts[1].trim(), 10),
	})

	if (r.not(date.isValid)) {
		return null
	}

	return date
}

export const CalendarInput: FC = (props: {
	dateString: string
	isOpen: boolean
	onChangeText: (newVal: string) => void
	onChangeOpen: (isOpen) => void
	index?: number
	width?: string
}) => {
	return (
		<Popup
			content={
				<div>
					<Calendar
						inset={true}
						dateString={props.dateString}
						onSelectDate={(dateString: string) => {
							props.onChangeOpen(false)
							props.onChangeText(DateTime.fromISO(dateString).toLocaleString())
						}}
					/>
				</div>
			}
			onClickOutside={() => {
				log('calendar-input', 'click outside')
				props.onChangeOpen(false)
			}}
			isOpen={props.isOpen}
		>
			<div className={classes.preposition}>
				{t(`shared.datePicker.date${props.index ? props.index : ''}`)}
			</div>
			<Spacer />
			<Input
				value={props.dateString}
				width={props.width || '80px'}
				onChange={(newVal) => {
					props.onChangeText(newVal)
				}}
				onFocus={() => {
					props.onChangeOpen(true)
				}}
				className={classes.calendarInput}
			/>
		</Popup>
	)
}

export const ShipmentsListDatePicker: FC = (props: {
	datePickerState: IDatePickerState
	onChangeDatePickerState: (changes: Partial<IDatePickerState>) => void
}) => {
	const state = props.datePickerState
	const options: ISelectOptions[] = [
		{
			value: 'all',
		},
		{
			value: 'on',
		},
		{
			value: 'between',
		},
		{
			value: 'in-the-last',
		},
	]

	let numCalendars = 1
	let showInLastX = false
	if (state.mode === 'all') {
		numCalendars = 0
	}
	if (state.mode === 'between') {
		numCalendars = 2
	}
	if (state.mode === 'in-the-last') {
		numCalendars = 0
		showInLastX = true
	}

	return (
		<div className={classes.datePicker}>
			<FlexRow>
				{/* <FlexItem>
          <Icon icon={regularIcons.faCalendarAlt} />
        </FlexItem> */}
				<FlexItem>
					<Select
						tPrefix='page.shipmentsList.datePicker'
						value={state.mode}
						options={options}
						onChange={(newVal) => {
							props.onChangeDatePickerState({
								mode: newVal,
							})
						}}
						testId={'pickupDatePicker'}
						className={classes.prepositionSelector}
					/>
				</FlexItem>

				{numCalendars >= 1 && (
					<FlexItem>
						<CalendarInput
							dateString={state.date1String}
							isOpen={state.showDate1Calendar}
							index={numCalendars >= 2 ? 1 : null}
							onChangeOpen={(isOpen) => {
								props.onChangeDatePickerState({
									showDate1Calendar: isOpen,
								})
							}}
							onChangeText={(newVal) => {
								props.onChangeDatePickerState({
									date1String: newVal,
								})
							}}
						/>
					</FlexItem>
				)}

				{numCalendars >= 2 && (
					<FlexItem>
						<CalendarInput
							dateString={state.date2String}
							isOpen={state.showDate2Calendar}
							index={2}
							onChangeOpen={(isOpen) => {
								props.onChangeDatePickerState({
									showDate2Calendar: isOpen,
								})
							}}
							onChangeText={(newVal) => {
								props.onChangeDatePickerState({
									date2String: newVal,
								})
							}}
						/>
					</FlexItem>
				)}

				{showInLastX && (
					<FlexItem>
						<FlexRow>
							<FlexItem>
								<Input
									value={props.datePickerState.inLastX}
									width='40px'
									onChange={(newVal) => {
										props.onChangeDatePickerState({
											inLastX: newVal,
										})
									}}
								/>
							</FlexItem>
							<FlexItem>{t('shared.datePicker.days')}</FlexItem>
						</FlexRow>
					</FlexItem>
				)}
			</FlexRow>
		</div>
	)
}
