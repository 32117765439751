import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { apiTypes } from 'ui/api'
import { t } from 'ui/components/i18n/i18n'
import {
	StationSuccess,
	StationWaiting,
	Subway,
	SubwayArrow,
	SubwayStation,
} from 'ui/components/shared/subway'
import {
	appointmentDetailsStatusToNumberMap,
	tPrefixDockSchedulerAppointmentDetails,
} from './DockSchedulerAppointmentDetails'

export const DockSchedulerAppointmentDetailsSubway: FC = (props: {
	appointmentStatus: apiTypes.AppointmentResponse['status']
	flow: apiTypes.AppointmentResponse['stopType']
}) => {
	const { appointmentStatus, flow } = props
	const appointmentStatusStepNumber =
		appointmentDetailsStatusToNumberMap[appointmentStatus]
	return (
		<Subway>
			<SubwayStation
				top={t('subwayArrived', tPrefixDockSchedulerAppointmentDetails)}
				bottom=''
				dataTestId='appointment-details-subway-arrived'
			>
				{appointmentStatusStepNumber >=
				appointmentDetailsStatusToNumberMap['arrived'] ? (
					<StationSuccess />
				) : (
					<StationWaiting />
				)}
			</SubwayStation>
			<SubwayArrow />
			<SubwayStation
				top={t(
					flow === 'pickup' ? 'subwayLoading' : 'subwayUnloading',
					tPrefixDockSchedulerAppointmentDetails,
				)}
				bottom=''
				dataTestId={
					flow === 'pickup'
						? 'appointment-details-subway-loading'
						: 'appointment-details-subway-unloading'
				}
			>
				{appointmentStatusStepNumber >=
				appointmentDetailsStatusToNumberMap['loading'] ? (
					<StationSuccess />
				) : (
					<StationWaiting />
				)}
			</SubwayStation>
			<SubwayArrow />
			<SubwayStation
				top={t(
					flow === 'pickup' ? 'subwayLoaded' : 'subwayUnloaded',
					tPrefixDockSchedulerAppointmentDetails,
				)}
				bottom=''
				dataTestId={
					flow === 'pickup'
						? 'appointment-details-subway-loaded'
						: 'appointment-details-subway-unloaded'
				}
			>
				{appointmentStatusStepNumber >=
				appointmentDetailsStatusToNumberMap['loaded'] ? (
					<StationSuccess />
				) : (
					<StationWaiting />
				)}
			</SubwayStation>
			<SubwayArrow />
			<SubwayStation
				top={t('subwayDeparted', tPrefixDockSchedulerAppointmentDetails)}
				bottom=''
				dataTestId='appointment-details-subway-departed'
			>
				{appointmentStatusStepNumber >=
				appointmentDetailsStatusToNumberMap['departed'] ? (
					<StationSuccess />
				) : (
					<StationWaiting />
				)}
			</SubwayStation>
		</Subway>
	)
}
