import { RateRow } from 'ui/components/rates-table/RatesTable'
import { l } from 'ui/lib/lodashImports'

const rateStatusOrder = {
	booked: 1,
	awarded: 2,
	award_retracted: 3,
	offerMade: 3, // Same sort key is OK
	active: 4,
	retracted: 5,
	declined: 6,
	canceled: 7,
	void: 7, // Same sort key is OK
}

export const sortRateRows = (rateRows: RateRow[]): RateRow[] => {
	return l.sortBy(rateRows, [
		(rate) => rateStatusOrder[rate.offerStatus] || 3,
		(rate) => rate.costTotal,
		(rate) => rate.buyPrice,
	])
}
