import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { l } from 'ui/lib/lodashImports'
import { tString } from '../i18n/i18n'
import * as classes from './FormStacked.module.scss'

export const FormReadOnly: FC = <T extends any>(props: {
	form: T
	field: keyof T
	width?: string
	tPrefix?: string
}) => {
	const val = props.form[props.field] ? props.form[props.field] : ''

	if (Array.isArray(val)) {
		return (
			<div>
				<div style={{ width: props.width }} className={classes.readOnly}>
					{l.map(val, (subVal, subValIdx) => (
						<div
							key={subValIdx}
							data-testid={`form-${props.field}-${subValIdx}`}
						>
							{props.tPrefix && subVal
								? tString(subVal, props.tPrefix)
								: subVal}
						</div>
					))}
				</div>
			</div>
		)
	} else {
		return (
			<div>
				<div
					style={{ width: props.width }}
					data-testid={`form-${props.field}`}
					className={classes.readOnly}
				>
					{props.tPrefix && val ? tString('' + val, props.tPrefix) : val}
				</div>
			</div>
		)
	}
}
