import React from 'react'
import { FormReadOnly } from './FormReadOnly'
import { FormVerticalItem } from './FormVerticalItem'
import { IFormData } from './IFormData'

export function FormVerticalReadOnly<T>(props: {
	field: keyof T
	formData: IFormData<T>
}): React.ReactElement {
	const { field, formData } = props

	return (
		<FormVerticalItem field={field} formData={formData}>
			<FormReadOnly form={formData.form} field={field} />
		</FormVerticalItem>
	)
}
