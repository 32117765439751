import { IRequestState } from './requestState'
import { testRouteData } from './apiRoutingTestData'

export interface RoutingLocationRequest {
	lat: number
	lon: number
	type: 'break' | 'through' | 'via' | 'break_through'
	street?: string
	city?: string
	state?: string
}

export interface RoutingCostingOptions {
	country_crossing_cost?: number
	country_crossing_penalty?: number
	height?: number //of the truck in meters
	width?: number //of the truck in meters
	length?: number //of the truck in meters
	weight?: number //of the truck in in metric tons
	hazmat?: boolean
}

export interface RoutingDirectionsOptions {
	units?: 'miles' | 'mi' | 'kilometers' | 'km'
	directions_type?: 'none' | 'maneuvers' | 'instructions'
}

export interface RoutingParams {
	locations: RoutingLocationRequest[]
	costing: 'auto' | 'truck'
	costing_options?: RoutingCostingOptions
	directions_options?: RoutingDirectionsOptions
}

interface RoutingManeuverResponse {
	travel_type?: string
	verbal_pre_transition_instruction?: string
	verbal_post_transition_instruction?: string
	verbal_transition_alert_instruction?: string
	instruction?: string
	end_shape_index?: number
	type?: number
	time?: number
	begin_street_names?: string[]
	street_names?: string[]
	length?: number
	begin_shape_index?: number
	travel_mode?: string
	verbal_multi_cue?: boolean
	sign?: any
}

interface RoutingSummary {
	has_time_restrictions?: boolean
	max_lon?: number
	max_lat?: number
	time?: number
	length?: number
	min_lat?: number
	min_lon?: number
}

interface RoutingLegResponse {
	shape: string
	summary?: RoutingSummary
	maneuvers?: RoutingManeuverResponse[]
}

interface RoutingLocationResponse extends RoutingLocationRequest {
	original_index?: number
}

export interface RoutingResponse {
	trip: {
		language: string
		status: number
		units: string
		status_message: string
		legs: RoutingLegResponse[]

		summary: RoutingSummary
		locations: RoutingLocationResponse[]
	}
}

export const fetchRoutingInfo = async (
	onProgress: (rs: IRequestState<RoutingResponse>) => void,
	params: RoutingParams,
): Promise<IRequestState<RoutingResponse>> => {
	const fakeRs: IRequestState<RoutingResponse> = {
		data: testRouteData,
	}

	return fakeRs
}
