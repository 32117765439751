import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { ndash } from 'ui/components/common'
import { Button } from 'ui/components/common/button'
import { Icon, solidIcons } from 'ui/components/common/icon'
import { PaperCard, PaperCardTitle } from 'ui/components/common/paperCard'
import { windowOpen } from 'ui/components/common/router/windowUtils'
import { t } from 'ui/components/i18n/i18n'
import { Spacer } from 'ui/components/layout/spacer'
import { IDataTableHeader, KeyValueTable } from 'ui/components/table'
import { sosProviderPortalInvoiceProfile } from 'ui/state'

const tPrefix = 'page.providerPortal.paymentDetails'

interface IPaymentDetails {
	remittanceNumber: string
	datePaid: string
	paymentMethod: string
	referenceNumber: string
}

const table1Headers: IDataTableHeader<IPaymentDetails>[] = [
	{
		field: 'remittanceNumber',
	},
	{
		field: 'datePaid',
	},
]

const table2Headers: IDataTableHeader<IPaymentDetails>[] = [
	{
		field: 'paymentMethod',
	},
	{
		field: 'referenceNumber',
	},
]

const labelMinWidth = '150px'

export const PaymentDetails: FC = (props: {}) => {
	const invoiceProfileState = sosProviderPortalInvoiceProfile.useSubscribe()
	const { invoiceData } = invoiceProfileState

	const paymentDetailsData = {
		remittanceNumber:
			invoiceData.remittanceNumberBase +
			ndash +
			invoiceData.remittanceNumberSuffix,
		datePaid: invoiceData.datePaidToProvider,
		paymentMethod: invoiceData.paymentMethod,
		referenceNumber: invoiceData.paymentNumber,
	}

	return (
		<div>
			<PaperCard>
				<PaperCardTitle border={true}>{t(tPrefix + '.title')}</PaperCardTitle>
				<KeyValueTable
					tPrefix={tPrefix}
					headers={table1Headers}
					rawData={paymentDetailsData}
					borderless={true}
					labelMinWidth={labelMinWidth}
				/>
				<Spacer />
				<KeyValueTable
					tPrefix={tPrefix}
					headers={table2Headers}
					rawData={paymentDetailsData}
					borderless={true}
					labelMinWidth={labelMinWidth}
				/>
				<Spacer />
				{invoiceData.remittancePdfUrl && (
					<Button
						color={'blue'}
						onClick={() => windowOpen(invoiceData.remittancePdfUrl, '_blank')}
					>
						<div style={{ minWidth: '190px', textAlign: 'left' }}>
							<Icon icon={solidIcons.faDownload} />{' '}
							{t(tPrefix + '.downloadRemittancePdf')}
						</div>
					</Button>
				)}
				{invoiceData.remittanceExcelUrl && (
					<Button
						color={'blue'}
						onClick={() => windowOpen(invoiceData.remittanceExcelUrl, '_blank')}
					>
						<div style={{ minWidth: '190px', textAlign: 'left' }}>
							<Icon icon={solidIcons.faDownload} />{' '}
							{t(tPrefix + '.downloadRemittanceExcel')}
						</div>
					</Button>
				)}
			</PaperCard>
		</div>
	)
}
