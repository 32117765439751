import { RRule, Weekday } from 'rrule'
// import { apiTypes } from 'ui/api'
import { l } from '../lodashImports'
import { DateTime } from 'luxon'
import { toInteger, toFloat } from '../numbers/toNumber'
import { apiTypes } from 'ui/api'

const rruleDays: {
	[dayOfWeek: string]: { rruleDay: Weekday; rruleAbbreviation: string }
} = {
	0: { rruleDay: RRule.MO, rruleAbbreviation: 'MO' }, // rrule starts the week on Monday at 0
	1: { rruleDay: RRule.TU, rruleAbbreviation: 'TU' },
	2: { rruleDay: RRule.WE, rruleAbbreviation: 'WE' },
	3: { rruleDay: RRule.TH, rruleAbbreviation: 'TH' },
	4: { rruleDay: RRule.FR, rruleAbbreviation: 'FR' },
	5: { rruleDay: RRule.SA, rruleAbbreviation: 'SA' },
	6: { rruleDay: RRule.SU, rruleAbbreviation: 'SU' },
}

const dayOfWeekToNumber = {
	monday: 0,
	tuesday: 1,
	wednesday: 2,
	thursday: 3,
	friday: 4,
	saturday: 5,
	sunday: 6,
}

export interface ITimeSlot {
	startTime: string
	endTime: string
	durationMinutes?: number
}

interface IFormattedTimeSlot {
	startHours: number
	startMinutes: number
	durationMinutes: number
	dayOfWeek: number
}

export type DayType =
	| 'monday'
	| 'tuesday'
	| 'wednesday'
	| 'thursday'
	| 'friday'
	| 'saturday'
	| 'sunday'

export const getTimeSlotsByDate = (
	rrule: apiTypes.ScheduleRuleRequest,
	day: string,
): ITimeSlot[] => {
	const rule = RRule.fromString(rrule.rrule)

	const betweenStart: DateTime = DateTime.fromISO(day)
		.setZone('GMT')
		.set({
			hour: 0,
			minute: 0,
			second: 0,
			millisecond: 0,
		})
		.minus({ millisecond: 1 }) // include times starting at midnight

	const betweenEnd: DateTime = betweenStart.plus({ day: 1, millisecond: 2 })

	return rule
		.between(betweenStart.toJSDate(), betweenEnd.toJSDate())
		.map((startTime: Date) => {
			let timeSlotStartTime: DateTime = DateTime.fromJSDate(startTime)
			// rrule does not take into account time zone, so we must manually add the offset for timezone ourselves
			timeSlotStartTime = timeSlotStartTime.minus({
				minute: timeSlotStartTime.offset,
			})

			const timeSlotEndtime: DateTime = timeSlotStartTime.plus({
				minutes: rrule.durationMinutes,
			})

			return {
				startTime: timeSlotStartTime.toISO(),
				endTime: timeSlotEndtime.toISO(),
				durationMinutes: rrule.durationMinutes,
			}
		})
}

/**
 * @summary this converts from { sunday: 0, monday: 1 } days of week as used in JavaScript
 * to { monday: 0, tuesday: 1 } days of week as used in rrule
 * @param luxonDayOfWeek
 */
export const getRRuleDayOfWeek = (luxonDayOfWeek: number): number => {
	if (luxonDayOfWeek === 0) {
		return 6
	} else {
		return luxonDayOfWeek - 1
	}
}

export const getScheduleRules = (
	timeSlots: ITimeSlot[],
): apiTypes.ScheduleRuleRequest[] => {
	const scheduleRules: apiTypes.ScheduleRuleRequest[] = []
	const formattedTimeSlots: IFormattedTimeSlot[] = l.map(
		timeSlots,
		(timeSlot) => {
			const startTime = DateTime.fromISO(timeSlot.startTime)
			const endTime = DateTime.fromISO(timeSlot.endTime)

			return {
				startHours: startTime.hour,
				startMinutes: startTime.minute,
				durationMinutes:
					timeSlot.durationMinutes ||
					endTime.diff(startTime, 'minutes').minutes,
				dayOfWeek: getRRuleDayOfWeek(startTime.weekday),
			}
		},
	)

	const timeSlotsByDuration: {
		[durationMinutes: string]: IFormattedTimeSlot[]
	} = l.groupBy(formattedTimeSlots, 'durationMinutes')

	l.forEach(
		timeSlotsByDuration,
		(timeSlotsForDuration: IFormattedTimeSlot[], durationMinutes: string) => {
			const timeSlotsByStartMinutes: {
				[startMinutes: string]: IFormattedTimeSlot[]
			} = l.groupBy(timeSlotsForDuration, 'startMinutes')

			l.forEach(
				timeSlotsByStartMinutes,
				(
					timeSlotsForStartMinutes: IFormattedTimeSlot[],
					startMinutes: string,
				) => {
					const timeSlotsByDay: {
						[dayOfWeek: string]: IFormattedTimeSlot[]
					} = l.groupBy(timeSlotsForStartMinutes, 'dayOfWeek')

					l.forEach(
						timeSlotsByDay,
						(timeSlotsForDay: IFormattedTimeSlot[], dayOfWeek: string) => {
							const rule = new RRule({
								dtstart: DateTime.fromMillis(1).toJSDate(),
								byweekday: rruleDays[dayOfWeek].rruleDay,
								byminute: toInteger(startMinutes),
								byhour: l.map(timeSlotsForDay, (ts) => ts.startHours),
							})

							const scheduleRule: apiTypes.ScheduleRuleRequest = {
								durationMinutes: toFloat(durationMinutes, 2),
								rrule: rule.toString(),
							}
							scheduleRules.push(scheduleRule)
						},
					)
				},
			)
		},
	)
	return scheduleRules
}

export const getRuleForDifferentDay = (
	rule: apiTypes.ScheduleRuleRequest,
	day: DayType,
): apiTypes.ScheduleRuleRequest => {
	const rrule = RRule.fromString(rule.rrule)
	rrule.origOptions.byweekday = [dayOfWeekToNumber[day]]
	return { rrule: rrule.toString(), durationMinutes: rule.durationMinutes }
}

export const getRulesWithoutDay = (
	rules: apiTypes.ScheduleRuleRequest[],
	day: DayType,
): apiTypes.ScheduleRuleRequest[] => {
	const rulesWithoutDay: apiTypes.ScheduleRuleRequest[] = []
	l.forEach(rules, (rule) => {
		const rrule = RRule.fromString(rule.rrule)
		if (rrule.options.byweekday.indexOf(dayOfWeekToNumber[day]) < 0) {
			rulesWithoutDay.push(rule)
		} else if (rrule.options.byweekday.length > 1) {
			rrule.origOptions.byweekday = l.without(
				rrule.options.byweekday,
				dayOfWeekToNumber[day],
			)
			rulesWithoutDay.push({
				rrule: rrule.toString(),
				durationMinutes: rule.durationMinutes,
			})
		}
	})

	return rulesWithoutDay
}

export const getRulesWithoutTimeSlots = (
	rules: apiTypes.ScheduleRuleRequest[],
	timeSlot: ITimeSlot,
): apiTypes.ScheduleRuleRequest[] => {
	const rulesWithoutTimeSlot: apiTypes.ScheduleRuleRequest[] = []

	const startTime = DateTime.fromISO(timeSlot.startTime)
	const startHour = startTime.hour
	const startMinute = startTime.minute
	const duration =
		timeSlot.durationMinutes ||
		DateTime.fromISO(timeSlot.endTime).diff(startTime, 'minutes').minutes

	l.forEach(rules, (rule) => {
		const hoursForRRule = getHoursForRRule(rule.rrule)
		const minutesForRRule = getMinutesForRRule(rule.rrule)

		if (
			hoursForRRule.indexOf(startHour) >= 0 &&
			minutesForRRule.indexOf(startMinute) >= 0 &&
			duration === rule.durationMinutes
		) {
			const rrule = RRule.fromString(rule.rrule)

			const remainingStartHours = l.without(rrule.options.byhour, startHour)
			if (remainingStartHours.length > 0) {
				rrule.origOptions.byhour = remainingStartHours

				rulesWithoutTimeSlot.push({
					rrule: rrule.toString(),
					durationMinutes: duration,
				})
			}
		} else {
			rulesWithoutTimeSlot.push(rule)
		}
	})

	return rulesWithoutTimeSlot
}

export const getRulesWithoutTimeSlotsForSpecificDay = (
	rules: apiTypes.ScheduleRuleRequest[],
	timeSlot: ITimeSlot,
	day: DayType,
): apiTypes.ScheduleRuleRequest[] => {
	const newRules = getRulesWithoutDay(rules, day)
	l.forEach(rules, (rule) => {
		const ruleForDay = getRuleForDifferentDay(rule, day)
		const newRulesForDay = getRulesWithoutTimeSlots([ruleForDay], timeSlot)
		newRules.push(...newRulesForDay)
	})
	return newRules
}

export const getDaysForRRule = (rrule: string): string[] => {
	const rule = RRule.fromString(rrule)
	return rule.options.byweekday.map((day) => rruleDays[day].rruleAbbreviation)
}

export const getHoursForRRule = (rrule: string): number[] => {
	const rule = RRule.fromString(rrule)
	return rule.options.byhour
}

export const getMinutesForRRule = (rrule: string): number[] => {
	const rule = RRule.fromString(rrule)
	return rule.options.byminute
}
