import { FC } from 'app/FunctionalComponent'
import React, { useState } from 'react'
import { Button } from 'ui/components/common/button'
import { l } from 'ui/lib/lodashImports'
import { log } from 'ui/lib/log/log'

// existential protection
function idx<T>(callback: () => any): any {
	try {
		return callback()
	} catch (e) {
		if (e instanceof TypeError) {
			return
		}
		throw e
	}
}

function nullSafe<
	T,
	K0 extends keyof T,
	K1 extends keyof T[K0],
	K2 extends keyof T[K0][K1],
	K3 extends keyof T[K0][K1][K2],
	K4 extends keyof T[K0][K1][K2][K3],
	K5 extends keyof T[K0][K1][K2][K3][K4]
>(obj: T, k0: K0, k1?: K1, k2?: K2, k3?: K3, k4?: K4, k5?: K5): any {
	let result: any = obj
	const args = l.compact([k0, k1, k2, k3, k4, k5])

	const keysCount = args.length - 1
	for (let i = 1; i <= keysCount; i++) {
		if (result === null || result === undefined) {
			return result
		}
		result = result[args[i]]
	}

	return result
}

export const TestPerformance: FC = (props: {}) => {
	const [state, setState] = useState({})

	const runTest = (): void => {
		const objA = { a: { b: { c: { d: 1 } } } }
		const objB = { a: null }

		const n = 100000
		const markerNameA = 'example-marker-a'
		const markerNameB = 'example-marker-b'

		performance.mark(markerNameA)
		for (let i = 0; i < n; i++) {
			idx(() => objA.a.b.c.d)
			idx(() => objB.a.b.c.d)
		}
		performance.mark(markerNameB)
		performance.measure('measure idx', markerNameA, markerNameB)

		performance.mark(markerNameA)
		for (let i = 0; i < n; i++) {
			nullSafe(objA, 'a', 'b', 'c', 'd')
			nullSafe(objB, 'a', 'b', 'c', 'd')
		}
		performance.mark(markerNameB)
		performance.measure('measure nullsafe', markerNameA, markerNameB)

		performance.mark(markerNameA)
		for (let i = 0; i < n; i++) {
			l.get(objA, 'a.b.c.d')
			l.get(objB, 'a.b.c.d')
		}
		performance.mark(markerNameB)
		performance.measure('measure _.get', markerNameA, markerNameB)

		log(
			'performance',
			'measure entries',
			performance.getEntriesByType('measure'),
		)
		performance.clearMarks()
		performance.clearMeasures()
		setState({ test: 'ran!' })
	}

	return (
		<>
			Test Performance
			<Button color='gray' onClick={runTest}>
				Run test
			</Button>
			<pre>{JSON.stringify(state, null, 2)}</pre>
		</>
	)
}
