import { apiTypes } from 'ui/api'

export const getTrueAccessorials = (
	accessorials: apiTypes.AccessorialsRequired,
): (keyof apiTypes.AccessorialsRequired)[] => {
	return accessorials
		? (Object.keys(
				accessorials,
		  ) as (keyof apiTypes.AccessorialsRequired)[]).filter(
				(accessorialKey: keyof apiTypes.AccessorialsRequired) =>
					accessorials[accessorialKey],
		  )
		: []
}
