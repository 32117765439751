import { IRequestState } from 'ui/api/requestState'
import { formPageConfig } from 'ui/forms'
import { createSos2 } from 'ui/lib/state/sos2/sos2'

export interface IStatePageConfig {
	isPageConfigMode: boolean
	isConfigModalOpen: boolean
	formPageConfig: formPageConfig.IFormPageConfig
	requestSaving: IRequestState<any>
}

export const sos = createSos2<IStatePageConfig>('page-config', 2, {
	isPageConfigMode: { default: false, localStorage: true },
	isConfigModalOpen: { default: false, localStorage: true },
	formPageConfig: {
		default: {
			configKey: '',
			hidden: '',
			advanced: '',
			required: '',
		},
	},
	requestSaving: { default: {} },
})

export function isPageConfigMode(): boolean {
	return sos.getState().isPageConfigMode
}

export function openPageConfigModal(configKey: string): void {
	sos.change((ds) => {
		ds.formPageConfig.configKey = configKey
		ds.isConfigModalOpen = true
	})

	// let translation = sosI18n.getTranslationForKey(i18nKey)

	// stateManager.produce((ds) => {
	// 	ds.requestSaving = {}
	// 	ds.isTranslateModalOpen = true
	// 	// Set form info from data
	// 	ds.translateForm.i18nKey = i18nKey
	// 	ds.translateForm.en = translation.en || ''

	// 	if (!ds.translateForm.en) {
	// 		// Get a good basic value
	// 		let lastDot = i18nKey.lastIndexOf('.')
	// 		if (lastDot > -1) {
	// 			let lastKey = i18nKey.substring(lastDot, i18nKey.length)
	// 			ds.translateForm.en = _.startCase(lastKey)
	// 		}
	// 	}

	// 	// ds.translateForm.es = translation.es || ''
	// 	// ds.translateForm['es-MX'] = translation['es-MX'] || ''
	// 	// ds.translateForm.pirate = translation.pirate || ''
	// })
}

export function closePageConfigModal(): void {
	sos.change((ds) => {
		ds.isConfigModalOpen = false
	})
}

export function togglePageConfigMode(): void {
	sos.change((ds) => {
		ds.isPageConfigMode = !ds.isPageConfigMode
	})
}

// export async function saveTranslation() {
// 	// Post form to translate
// 	let state = stateManager.getState()
// 	let result = await apiCommon.externalFetch(
// 		(rs) => {
// 			stateManager.produce((ds) => {
// 				ds.requestSaving = rs
// 			})
// 		},
// 		'http://localhost:4218/key',
// 		{
// 			method: 'POST',
// 			data: state.translateForm,
// 		},
// 	)

// 	return result.error == null
// }

export function updatePageConfigForm(
	key: keyof formPageConfig.IFormPageConfig,
	newVal: string,
): void {
	sos.change((ds) => {
		ds.formPageConfig[key] = newVal
	})
}
