import { FC } from 'app/FunctionalComponent'
import React, { useEffect, useRef } from 'react'
import { _idx } from 'ui/lib/_idx'
import { documentExists, getDocument } from '../router/windowUtils'
import classes from './Popup.module.scss'

const popupPositions = {
	topLeft: classes.topLeft,
	topRight: classes.topRight,
	bottomLeft: classes.bottomLeft,
	bottomRight: classes.bottomRight,
	topLeftConnectedToBottomLeft: classes.topLeftConnectedToBottomLeft,
}

export type PopupBoxPosition = typeof popupPositions

const defaultProps = {
	position: 'topLeftConnectedToBottomLeft',
}

export const PopupBox: FC = (props: {
	children?: React.ReactNode
	isOpen: boolean
	onClickOutside?: () => void
	inset?: boolean
	position?: keyof PopupBoxPosition
}) => {
	const el = useRef<HTMLDivElement>(null)

	useEffect(() => {
		const handleClickOutside = (ev: any): void => {
			if (props.isOpen && !el.current.contains(ev.target as Node)) {
				_idx(() => props.onClickOutside())
			}
		}
		if (props.isOpen) {
			if (documentExists) {
				getDocument().addEventListener('mousedown', handleClickOutside)
			}
		}
		return () => {
			if (documentExists) {
				getDocument().removeEventListener('mousedown', handleClickOutside)
			}
		}
	})

	let className = classes.popup
	className += ' ' + popupPositions[props.position]
	className += ' ' + (props.isOpen ? classes.visible : classes.hidden)

	if (props.inset) {
		className += ' ' + classes.inset
	}

	return (
		<div ref={el} className={className}>
			{props.children}
		</div>
	)
}
PopupBox.defaultProps = defaultProps
