import { FC } from 'app/FunctionalComponent'
import { DateTime } from 'luxon'
import React, { useState } from 'react'
import { solidIcons } from 'ui/components/common/icon'
import { l } from 'ui/lib/lodashImports'
import { theme } from 'ui/theme'
import { IconButton } from '../common/icon/Icon'
import { AlignRight } from '../layout/alignRight/AlignRight'
import { Center } from '../layout/center/Center'
import { FlexItem } from '../layout/flexRow/FlexItem'
import { FlexRow } from '../layout/flexRow/FlexRow'
import classes from './Calendar.module.scss'

interface IDateEntry {
	day: { inMonth: boolean; num: number }
	date: DateTime
}

export const Calendar: FC = (props: {
	dateString: string
	onSelectDate: (dateString: string) => void
	inset?: boolean
	readOnly?: boolean
}) => {
	let date = DateTime.fromISO(props.dateString)
	if (!date.isValid) {
		date = DateTime.local()
	}

	const [curMonth, setCurMonth] = useState(date.month)

	const onPrevMonth = (): void => {
		setCurMonth(curMonth - 1)
	}
	const onNextMonth = (): void => {
		setCurMonth(curMonth + 1)
	}

	date = date.set({ month: curMonth })

	let className = classes.calendar
	if (props.inset) {
		className += ' ' + classes.inset
	}

	const startOfMonth = date.startOf('month')
	const monthDays = startOfMonth.daysInMonth

	const endOfMonth = startOfMonth.endOf('month')

	const days = []
	for (let i = 1; i <= monthDays; i++) {
		days.push({ inMonth: true, num: i })
	}

	let lastDate = null

	// Add beginning of week before months
	if (startOfMonth.weekday !== 7) {
		for (let i = 0; i < startOfMonth.weekday; i++) {
			days.unshift({
				inMonth: false,
				num: 0 - i,
			})
		}
	}
	// Add days to fill out calendar
	if (endOfMonth.weekday !== 6) {
		let startWeekday = endOfMonth.weekday
		if (startWeekday === 7) {
			startWeekday = 0
		}

		for (let i = 1; i + startWeekday < 7; i++) {
			days.push({ inMonth: false, num: i + endOfMonth.day })
		}
	}

	const weeks: IDateEntry[][] = []
	let curWeek: IDateEntry[] = []
	l.forEach(days, (c) => {
		const date2 = startOfMonth.plus({ days: c.num - 1 })

		if (date2.weekday === 7 && curWeek.length > 0) {
			weeks.push(curWeek)
			curWeek = []
		}
		curWeek.push({
			day: c,
			date: date2,
		})
		lastDate = date2
	})

	weeks.push(curWeek)

	while (weeks.length < 6) {
		curWeek = []
		for (let i = 1; i <= 7; i++) {
			lastDate = lastDate.plus({ day: 1 })
			curWeek.push({
				day: { inMonth: false, num: lastDate.dayOutOfMonth },
				date: lastDate,
			})
		}
		weeks.push(curWeek)
	}

	return (
		<div className={className}>
			<FlexRow mode='space-between' verticalAlign='center'>
				<FlexItem padding='2px'>
					<IconButton
						icon={solidIcons.faChevronCircleLeft}
						onClick={onPrevMonth}
					/>
				</FlexItem>
				<FlexItem>
					<Center>{date.toFormat('LLL yyyy')}</Center>{' '}
				</FlexItem>
				<FlexItem padding='2px'>
					<AlignRight>
						<IconButton
							icon={solidIcons.faChevronCircleRight}
							onClick={onNextMonth}
						/>
					</AlignRight>
				</FlexItem>
			</FlexRow>

			{l.map(weeks, (w, wIdx) => {
				return (
					<div key={wIdx} className={classes.week}>
						{l.map(w, (c, cIdx) => {
							let dayClassName = c.day.inMonth
								? classes.day
								: classes.dayOutOfMonth
							dayClassName = theme.addClassIf(
								props.readOnly,
								classes.disabled,
								dayClassName,
							)
							return (
								<div
									key={cIdx}
									onClick={() => {
										if (!props.readOnly) {
											props.onSelectDate(c.date.toISODate())
										}
									}}
									className={dayClassName}
								>
									{c.date.day}
								</div>
							)
						})}
					</div>
				)
			})}
		</div>
	)
}
