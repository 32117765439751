import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { apiTypes } from 'ui/api'
import { tString } from 'ui/components/i18n/i18n'
import { l } from 'ui/lib/lodashImports'
import { theme } from 'ui/theme'
import * as commonClasses from 'ui/theme/common.module.scss'
import * as classes from '../DockScheduler.module.scss'
import {
	formatHourStartIntoHeaderText,
	getTimeSlotsFromDocksAndAppointments,
} from './dockSchedulerTimeSlotsUtils'

export const tPrefixTimeSlots = 'page.dockScheduler.timeSlots'

export const minutesPerColumn = 15

export interface IScheduleTimeSlot {
	startHours: number
	startMinutes: number
	durationMinutes: number
	slotType: 'void' | 'unavailable' | 'available'
	dockIds: string[]
	slotUnavailableReason?: string
}

export const VoidTimeSlot: FC = (props: { relativeWidth: number }) => {
	return (
		<div
			className={classes.slotWrapper}
			style={{
				flexBasis: props.relativeWidth,
				flexGrow: props.relativeWidth,
				flexShrink: props.relativeWidth,
			}}
		>
			<div
				className={theme.addClass(
					classes.voidTimeSlot,
					theme.getBackgroundWithTextColor('white'),
				)}
			/>
		</div>
	)
}

const UnavailableTimeSlot: FC = (props: {
	relativeWidth: number
	testId: string
	unavailableReason: string
	onClick: () => void
	selected: boolean
}) => {
	let className = theme.addClass(
		classes.filledTimeSlot,
		theme.getBackgroundWithTextColor('gray'),
	)
	className = theme.addClass(commonClasses.clickable, className)

	className = theme.addClassIf(
		props.selected,
		classes.selectedTimeSlot,
		className,
	)
	return (
		<div
			className={classes.slotWrapper}
			style={{
				flexBasis: props.relativeWidth,
				flexGrow: props.relativeWidth,
				flexShrink: props.relativeWidth,
			}}
		>
			<div
				className={className}
				data-testid={props.testId}
				onClick={props.onClick}
			>
				<div
					className={classes.timeSlotText}
					data-testid={`${props.testId}-docks-unavailable-text`}
				>
					{props.unavailableReason}
				</div>
			</div>
		</div>
	)
}

const AvailableTimeSlot: FC = (props: {
	relativeWidth: number
	numDocks: number
	onClick: () => void
	selected: boolean
	testId: string
}) => {
	let className = theme.addClass(
		classes.availableTimeSlot,
		theme.getBorderColor('green'),
	)
	className = theme.addClass(commonClasses.clickable, className)
	className = theme.addClassIf(
		props.selected,
		classes.selectedTimeSlot,
		className,
	)
	const docksAvailableText = `${props.numDocks} ${
		props.numDocks !== 1
			? tString('docksAvailable', tPrefixTimeSlots)
			: tString('dockAvailable', tPrefixTimeSlots)
	}`
	return (
		<div
			className={classes.slotWrapper}
			style={{
				flexBasis: props.relativeWidth,
				flexGrow: props.relativeWidth,
				flexShrink: props.relativeWidth,
			}}
		>
			<div
				className={className}
				data-testid={props.testId}
				onClick={props.onClick}
			>
				<div
					className={classes.timeSlotText}
					data-testid={`${props.testId}-docks-available-text`}
				>
					{docksAvailableText}
				</div>
			</div>
		</div>
	)
}

export const TimeSlotHeaders: FC = (props: { hourStarts: number[] }) => {
	const { hourStarts } = props
	return (
		<div className={classes.timeSlotHourHeaderRow}>
			{l.map(hourStarts, (hourStart) => {
				const hourText = formatHourStartIntoHeaderText(
					hourStart,
					tPrefixTimeSlots,
				)
				return (
					<div
						key={hourStart}
						className={`${classes.timeSlotHourHeader} ${theme.getBorderColor(
							'gray',
						)}`}
					>
						<span>{hourText}</span>
					</div>
				)
			})}
		</div>
	)
}

export const DockSchedulerTimeSlots: FC = (props: {
	docks: apiTypes.DockResponse[]
	appointments: apiTypes.AppointmentResponse[]
	date: string // DateTime format yyyy-MM-dd
	equipmentType: apiTypes.AppointmentResponse['shipmentInfo']['equipmentType']
	flow: apiTypes.AppointmentResponse['stopType']
	mode: apiTypes.AppointmentResponse['shipmentInfo']['mode']
	zipcode: string
	selectedTimeSlot: IScheduleTimeSlot
	onSelectTimeSlot: (slot: IScheduleTimeSlot) => void
}) => {
	const { hourStarts, slotRows } = getTimeSlotsFromDocksAndAppointments(
		props.docks,
		props.appointments,
		props.date,
		props.equipmentType,
		props.flow,
		props.mode,
		props.zipcode,
	) // TODO this function also returns appointments that exist but cannot be placed in the schedule, what should this do?
	return (
		<div className={classes.timeSlotsTable}>
			<TimeSlotHeaders hourStarts={hourStarts} />
			<div>
				{l.map(slotRows, (slotRow, rowIdx) => {
					return (
						<div key={rowIdx} className={classes.timeSlotRow}>
							{l.map(slotRow, (slot, slotIdx) => {
								const slotWidth = slot.durationMinutes / minutesPerColumn
								if (slot.slotType === 'void') {
									return (
										<VoidTimeSlot key={slotIdx} relativeWidth={slotWidth} />
									)
								} else if (slot.slotType === 'unavailable') {
									return (
										<UnavailableTimeSlot
											key={slotIdx}
											testId={`slot-row-${rowIdx}-time-slot-${slotIdx}-filled`}
											relativeWidth={slotWidth}
											unavailableReason={slot.slotUnavailableReason}
											onClick={() => props.onSelectTimeSlot(slot)}
											selected={l.isEqual(slot, props.selectedTimeSlot)}
										/>
									)
								} else if (slot.slotType === 'available') {
									return (
										<AvailableTimeSlot
											key={slotIdx}
											testId={`slot-row-${rowIdx}-time-slot-${slotIdx}-available`}
											relativeWidth={slotWidth}
											numDocks={slot.dockIds.length}
											onClick={() => props.onSelectTimeSlot(slot)}
											selected={l.isEqual(slot, props.selectedTimeSlot)}
										/>
									)
								}
							})}
						</div>
					)
				})}
			</div>
		</div>
	)
}
