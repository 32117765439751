import React from 'react'
import { solidIcons } from 'ui/components/common/icon'
import { IconButton } from 'ui/components/common/icon/Icon'
import { IDataTableHeader } from 'ui/components/table/IDataTableHeader'
import { IDataTableHeaderProcessed } from 'ui/components/table/IDataTableHeaderProcessed'
import tableClasses from './TableStyles.module.scss'

export const DataTableSortButtons = <T extends any>(props: {
	tableHeaderState: IDataTableHeaderProcessed<T>
	toggleSort: (header: IDataTableHeader<T>) => void
	large?: boolean
}): React.ReactElement => {
	const { tableHeaderState, toggleSort } = props
	const { sorted, sortedReverse } = tableHeaderState
	return (
		<div className={tableClasses.sortIcons}>
			{sorted ? (
				<IconButton
					iconClassName={tableClasses.sortIcon}
					onClick={() => toggleSort(tableHeaderState)}
					icon={sortedReverse ? solidIcons.faSortDown : solidIcons.faSortUp}
					large={props.large}
				/>
			) : (
				<IconButton
					iconClassName={
						tableClasses.sortIcon + ' ' + tableClasses.grayedSortIcon
					}
					onClick={() => toggleSort(tableHeaderState)}
					icon={solidIcons.faSort}
					large={props.large}
				/>
			)}
		</div>
	)
}
