/**
 * Return a value after a delay
 * @param milliseconds
 * @param value
 */
export async function delay(milliseconds, value?): Promise<any> {
	return new Promise((resolve) =>
		setTimeout(() => resolve(value), milliseconds),
	)
}
