import { IDataTableHeader } from 'ui/components/table'
import { l } from 'ui/lib/lodashImports'

export const getActiveElasticsearchFields = <T extends any>(
	hiddenHeaders: string[],
	availableHeaders: IDataTableHeader<T>[],
): string[] => {
	return availableHeaders
		.filter(
			(c) =>
				!hiddenHeaders.includes(c.field) &&
				!l.isNil(c.elasticSearchKey) &&
				!c.absoluteSearch,
		)
		.map((c) => c.elasticSearchKey)
		.join(',')
		.split(',')
}
