// see: https://reactjs.org/docs/hooks-faq.html
import { useCallback, useState } from 'react'

export function useClientRect(): [ClientRect, (node: HTMLElement) => void] {
	const [rect, setRect] = useState<ClientRect>(null)
	const ref = useCallback((node: HTMLElement) => {
		if (node !== null) {
			setRect(node.getBoundingClientRect())
		}
	}, [])
	return [rect, ref]
}
