import { FC } from 'app/FunctionalComponent'
import { DateTime } from 'luxon'
import React from 'react'
import { ndash } from 'ui/components/common'
import { Input } from 'ui/components/common/input'
import { Pager } from 'ui/components/common/pager/Pager'
import { sosRouter } from 'ui/components/common/router'
import { Select } from 'ui/components/common/select'
import { SmallButton } from 'ui/components/common/small-button'
import { StackedItem } from 'ui/components/form'
import { useOnce } from 'ui/components/hooks'
import { t, tCurrency, tDate } from 'ui/components/i18n/i18n'
import {
	Left,
	Right,
	TwoColumnLeftRight,
} from 'ui/components/layout/twoColumnLeftRight/TwoColumnLeftRight'
import { DataTable, IDataTableHeader } from 'ui/components/table'
import { mapSubwayToState } from 'ui/pages/provider-portal/invoice-profile/subwayMapper'
import {
	sosProviderPortalInvoiceProfile,
	sosProviderPortalInvoices,
} from 'ui/state/'
import { PaymentStatusFilter } from '../PaymentStatusFilter'
import { ProviderPortalLayout } from '../ProviderPortalLayout'
import { defaultProviderPortalCellValue } from '../providerPortalTypes'
import * as classes from './Invoices.module.scss'

const pageTPrefix = 'page.providerPortal.invoices.'

export const renderDates = (cellValue: string): string => {
	if (!cellValue || cellValue === defaultProviderPortalCellValue) {
		return defaultProviderPortalCellValue
	} else {
		return tDate(cellValue, DateTime.DATE_SHORT)
	}
}

export const renderCityState = (cellValue: any, row): string => {
	if (!cellValue || (!cellValue.city && !cellValue.state)) {
		return defaultProviderPortalCellValue
	}
	let cityStateString = cellValue.city
	if (cityStateString && cellValue.state) {
		cityStateString += ', ' + cellValue.state
	} else if (cellValue.state) {
		cityStateString = cellValue.state
	}
	return cityStateString
}

const headers: IDataTableHeader<any>[] = [
	{
		field: 'invoiceNumber',
		sort: false,
		columnAlign: 'right',
		monospace: true,
	},
	{
		field: 'pro_number',
		sort: false,
		columnAlign: 'right',
		monospace: true,
	},
	{
		field: 'status',
		sort: false,
		renderer: (statusKey) =>
			t(statusKey, 'page.providerPortal.paymentStatusFilters'),
	},
	{
		field: 'invoiceDate',
		sort: false,
		renderer: renderDates,
	},
	{
		field: 'dueDate',
		sort: false,
		renderer: renderDates,
	},
	{
		field: 'invoiceAmount',
		sort: false,
		renderer: (cellValue: string, row: any) => {
			if (cellValue === defaultProviderPortalCellValue) {
				return cellValue
			} else {
				const parsed = Number.parseFloat(cellValue)
				return Number.isNaN(parsed)
					? ndash
					: tCurrency(parsed, row.currencyCode)
			}
		},
		columnAlign: 'right',
	},
	{
		field: 'initialOrigin',
		renderer: renderCityState,
		sort: false,
		columnAlign: 'right',
	},
	{
		field: 'finalDestination',
		renderer: renderCityState,
		sort: false,
		columnAlign: 'right',
	},
	{
		field: 'dateFundsRequested',
		sort: false,
		renderer: renderDates,
	},
	{
		field: 'dateFundsReceived',
		sort: false,
		renderer: renderDates,
	},
	{
		field: 'datePaidToProvider',
		sort: false,
		renderer: renderDates,
	},
	{
		field: 'remittanceNumberBase',
		sort: false,
		renderer: (cellValue: string, row: any) =>
			cellValue && row.remittanceNumberSuffix
				? `${cellValue}-${row.remittanceNumberSuffix}`
				: defaultProviderPortalCellValue,
		monospace: true,
		columnAlign: 'right',
		width: '125px',
	},
	{
		field: 'paymentMethod',
		sort: false,
	},
	{
		field: 'paymentNumber',
		sort: false,
	},
	{
		field: 'disputeNumber',
		sort: false,
	},
]

const shipmentsAreStale = 1000 * 60 * 5

export const Invoices: FC = (props: {}) => {
	const state = sosProviderPortalInvoices.useSubscribe()

	useOnce(async () => {
		await sosProviderPortalInvoices.getCustomerList()
		if (
			!state.shipmentsLastFetched ||
			Date.now() - state.shipmentsLastFetched > shipmentsAreStale
		) {
			sosProviderPortalInvoices.fetchThrottled()
		}
	})

	const project = sosProviderPortalInvoices.getMatchedDocumentAndShipmentData()

	return (
		<ProviderPortalLayout selectedPage='invoices'>
			<div>
				<TwoColumnLeftRight>
					<Left>
						<div className={classes.filterInputsContainer}>
							<div className={classes.inputs}>
								<StackedItem label={t(pageTPrefix + 'customer')}>
									<Select
										options={state.customers}
										value={state.currentCustomer}
										onChange={(newValue: string) =>
											sosProviderPortalInvoices.switchCustomer(newValue)
										}
									/>
								</StackedItem>
							</div>
							<StackedItem label={t('page.providerPortal.proNumber')}>
								<Input
									value={state.searchFor}
									onChange={sosProviderPortalInvoices.updateSearchFor}
								/>
							</StackedItem>
						</div>
					</Left>
					<Right>
						<SmallButton
							isSpinning={state.isExporting}
							color='blue'
							onClick={() => sosProviderPortalInvoices.exportData()}
						>
							{t(pageTPrefix + 'exportCurrentlyDisplayedTable')}
						</SmallButton>
					</Right>
				</TwoColumnLeftRight>
			</div>
			<PaymentStatusFilter
				onChange={(c) => sosProviderPortalInvoices.setPaymentStatusFilter(c)}
				selected={state.paymentStatusFilter}
			/>
			<DataTable
				testId={pageTPrefix}
				tPrefix={pageTPrefix + 'datatable'}
				data={project}
				state={{}}
				headers={headers}
				isLoading={state.isLoading}
				onClickRow={(row) => {
					sosProviderPortalInvoiceProfile.setInvoiceData(row)
					sosRouter.navigate('/provider-portal/invoice-profile')
					mapSubwayToState(row)
				}}
				spacerCell={false}
			/>
			<Pager
				numPages={state.pages}
				currentPage={state.currentPage}
				hasMorePages={
					!state.isLoading &&
					state.hasMorePages &&
					state.currentPage === state.pages - 1
				}
				onClickPage={sosProviderPortalInvoices.fetchPage}
				onClickLoadMore={sosProviderPortalInvoices.fetchNextPage}
			/>
		</ProviderPortalLayout>
	)
}
