import { FC } from 'app/FunctionalComponent'
import React from 'react'
import { Autocomplete } from 'ui/components/common/autocomplete'
import { ISelectOptions } from 'ui/components/common/select'
import { Col, Row } from 'react-bootstrap'
import classes from '../bill-to/BillTo.module.scss'
import newQuoteParcelClasses from '../NewQuoteParcel.module.scss'
import { t } from 'ui/components/i18n/i18n'
import { apiPrintNode } from 'ui/api'
import { sosUser } from 'ui/state'

const tPrefix = 'page.newQuote.parcel.overrideScaleLocation'

export const OverrideScaleLocationDropDown: FC = (props: {
	onUpdate: (view: string) => void
	value: string
	isReadOnly?: boolean
	scales: apiPrintNode.PrintNodeScalesResponse[]
}) => {
	const { onUpdate, isReadOnly, scales } = props
	const userSessionScaleId = sosUser.getSos().getState().sessionScaleId

	return (
		<Row
			className={`${newQuoteParcelClasses.newQuoteParcelCol} ${newQuoteParcelClasses.locationDropDownNoMargin}`}
		>
			<Col>
				<Row className={classes.billToLabel}>
					{t('overrideScaleLocation', tPrefix)}
				</Row>
				<Row className={classes.billToDropdown}>
					<Autocomplete
						type='select'
						value={userSessionScaleId}
						options={scales.map(
							(
								scale: apiPrintNode.PrintNodeScalesResponse,
							): ISelectOptions => ({
								value: scale.computerId,
								label: scale.deviceName,
							}),
						)}
						onChange={(view) => onUpdate(view)}
						testId='override-scale-location-selector'
						isClearable={false}
						readOnly={isReadOnly}
						allowEmptyValue={false}
					/>
				</Row>
			</Col>
		</Row>
	)
}
