import { apiTypes } from 'ui/api'
import { l } from 'ui/lib/lodashImports'

export const findMostRelevantBuyRate = (
	rates: apiTypes.RateResponse[],
	offer: apiTypes.BrokerOfferResponse,
): apiTypes.RateResponse => {
	return (
		l.find(rates, (rate) => rate.offerStatus === 'booked') ||
		l.find(rates, (rate) => rate.offerStatus === 'awarded') ||
		l.find(rates, (rate) => rate.id === offer?.brokerRateId)
	)
}
