import { l } from 'ui/lib/lodashImports'

export function isNotAValue(val: any): boolean {
	if (val == null) {
		return true
	}

	if (l.isString(val)) {
		if (val.trim() === '') {
			return true
		}
	}
	return false
}

export function isAValue(val: any): boolean {
	return !isNotAValue(val)
}

export function mustBeLowercase(
	val: any,
	message = 'Must be lowercase',
): string {
	const cleanVal = '' + val
	if (cleanVal.toLowerCase() !== val) {
		return message
	}
	return null
}

export function mustBeBoolean(val: any, message = 'Must be boolean'): string {
	const cleanVal = ('' + val).trim().toLowerCase()
	if (cleanVal === 'false' || cleanVal === 'true') {
		return null
	}
	return message
}
export function convertToBoolean(val: any): boolean {
	if (mustBeBoolean(val)) {
		throw mustBeBoolean(val)
	}
	const cleanVal = ('' + val).trim().toLowerCase()
	return cleanVal === 'true'
}

export function mustBeAnInteger(
	val: any,
	message = 'validation.error.mustBeAnInteger',
): string {
	const cleanVal = ('' + val).trim()
	const test = parseInt(cleanVal, 10)
	if (l.isNaN(test)) {
		return message
	}
	// Also ensure no funny characters
	const regEx = new RegExp(/^[-+]?\d+$/)
	if (!regEx.test(cleanVal)) {
		return message
	}
	return null
}

export function mustBeANumber(
	val: any,
	message = 'validation.error.mustBeANumber',
): string {
	const cleanVal = ('' + val).trim()
	const test = parseFloat(cleanVal)
	if (l.isNaN(test)) {
		return message
	}
	// Also ensure no funny characters
	const regEx = new RegExp(/^[+-]?[\d]*[\\.]?[\d]*$/)
	if (!regEx.test(cleanVal)) {
		return message
	}
	return null
}

export function mustBeGreaterThan(
	val: any,
	greaterThan: number,
	message = 'Must be greater than ' + greaterThan,
): string {
	const cleanVal = ('' + val).trim()
	const test = parseFloat(cleanVal)
	if (l.isNaN(test)) {
		return message
	}
	if (test > greaterThan) {
		return null
	}
	return message
}

export function mustMatch(
	val: any,
	matchVal: any,
	message = 'Values must match',
): string {
	if (val === matchVal) {
		return null
	}

	return message
}
